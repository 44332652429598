import { Helmet } from "react-helmet-async"
import { MySecurityLogs } from "./../../../Settings"

export default function MySecurityLogsPage() {
	return (
		<>
			<Helmet>
				<title>Security Logs | Settings</title>
			</Helmet>
			<MySecurityLogs />
		</>
	)
}
