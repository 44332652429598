import { Box, Container } from "@sembark-travel/ui/base"
import { Breadcrumbs } from "@sembark-travel/ui/router"
import { Helmet } from "react-helmet-async"
import { NewItemForm } from "../../../TransportServiceProviders"
import { generatePath, useNavigate } from "../../../router-utils"

export default function NewTransportServiceProvider() {
	const navigate = useNavigate()
	function navigateBackToList() {
		return navigate("/transport-service-providers")
	}
	return (
		<>
			<Helmet>
				<title>New Suppliers</title>
			</Helmet>
			<Breadcrumbs
				title="New Supplier"
				items={[
					[generatePath("/transport-service-providers"), "Suppliers"],
					["", "New"],
				]}
			/>
			<Box paddingX={{ md: "4" }} paddingY={{ md: "8" }}>
				<Box maxWidth="6xl" marginX="auto">
					<Box borderWidth={{ md: "1" }} rounded="md" bgColor="default">
						<Container paddingY="6">
							<NewItemForm
								onSuccess={(data) =>
									navigate(
										"/transport-service-providers/:transportServiceProviderId",
										{
											params: {
												transportServiceProviderId: data.id.toString(),
											},
											replace: true,
										}
									)
								}
								onCancel={navigateBackToList}
							/>
						</Container>
					</Box>
				</Box>
			</Box>
		</>
	)
}
