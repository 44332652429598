import { useAuthUser } from "../../../../../Auth"
import { useTenant } from "../../../../../TenantProfile/store"
import { Navigate } from "../../../../../router-utils"

export default function OrganizationProfileSettingsPage() {
	const { user } = useAuthUser()
	const { tenant } = useTenant(user?.tenant?.id)
	if (!tenant || !tenant.brands) return null
	return (
		<Navigate
			to="/org/profile/:id"
			params={{ id: tenant.brands[0].id.toString() }}
			replace
		/>
	)
}
