import {
	Inline,
	Box,
	Button,
	Icons,
	Stack,
	Divider,
	Table,
	TableHeaderDataCell,
	TableDataCell,
	Text,
	TableFooterDataCell,
	Tooltip,
	useDidMount,
	Money,
	Heading,
	Alert,
	DeferRender,
	Grid,
	Col,
} from "@sembark-travel/ui/base"
import { showSnackbar } from "@sembark-travel/ui/snackbar"
import { Dialog, useDialog } from "@sembark-travel/ui/dialog"
import {
	Form,
	TextAreaInputField,
	TextInputField,
	withServerErrors,
	validateFormValues,
	SubmissionError,
	useFieldValue,
	useForm,
	GetFieldValue,
	SwitchInputField,
	PercentageInputField,
	isTruthy,
	SelectInputField,
	useCurrencyPairRatioFieldValue,
	FieldArray,
	arrayMutators,
	GetCurrencyPairRatioFieldValue,
	InlineSelectInputField,
} from "@sembark-travel/ui/form"
import {
	normalizeNumber,
	numberToLocalString,
} from "@sembark-travel/number-utils"
import { useXHR, XHRInstance } from "@sembark-travel/xhr"
import { useEffect, useMemo, useState } from "react"
import * as Validator from "yup"
import config from "../config"
import {
	useTripRefresh,
	IGivenQuote,
	ITripQuote,
	TPricePerPersonAllocation,
} from "./store"
import {
	addMoney,
	convertMoneyToCurrency,
	currencyPairsArrayToObject,
	currencyPairsObjectToArray,
	currencyPairToHuman,
	formatMoneyByDecimal,
	getPercentageOfMoney,
	makeCurrencyConverter,
	moneyParseByDecimal,
	roundMoneyToPoint,
	subtractMoney,
	TCurrencyPair,
	TCurrencyPairsObject,
} from "@sembark-travel/money"
import {
	CurrencyPairInputFieldWithRates,
	useCurrenciesOfTenant,
	useFunctionalCurrencyOfTenant,
} from "../Currencies"
import {
	calculateTotalPrice,
	calculateMarginFromTotalPrice,
	calculateMarginPercentageFromTotalPrice,
} from "./utils"
import { getTotalPriceForExtras } from "../utils"
import { Required } from "utility-types"

function XHR(xhr: XHRInstance) {
	return {
		async giveQuote(data: unknown): Promise<IGivenQuote> {
			return xhr.post(`/given-quotes`, data).then((resp) => resp.data.data)
		},
	}
}

export function GiveQuote({
	onChange,
	tripQuote,
	onCancel,
	...props
}: {
	tripQuote: ITripQuote
	onChange: () => void
	onCancel: () => void
} & Omit<
	TGiveQuoteFormProps,
	| "initialValues"
	| "onSubmit"
	| "optionIndex"
	| "currencyPairs"
	| "costPriceCurrency"
	| "totalCostPrice"
	| "perPersonCostPriceAllocation"
	| "flightPrice"
>) {
	const tripQuotes = useMemo(
		() => [tripQuote].concat(tripQuote.options || []),
		[tripQuote]
	)
	const tripId = tripQuote.trip_id
	const costPriceCurrency = tripQuote.currency

	const refreshTrip = useTripRefresh()
	const xhr = useXHR()
	const functionalCurrency = useFunctionalCurrencyOfTenant(costPriceCurrency)
	const [initialValues] = useState<IGiveQuoteData>(() => {
		return getInitialValues({
			tripQuotes,
			functionalCurrency,
		})
	})
	return (
		<GiveQuoteForm
			initialValues={initialValues}
			{...props}
			onSubmit={async ({
				currency_pairs_obj,
				currency_pairs,
				options,
				...data
			}) => {
				const sharedData = {
					...data,
					currency_pairs: currencyPairsObjectToArray(currency_pairs_obj || {}),
					timezone_offset: config.timezoneOffset,
					gst_included: data.gst_included,
					tax_percentage: isTruthy(data.gst_included)
						? Number(data.tax_percentage || 0) || 0
						: null,
				}
				return XHR(xhr)
					.giveQuote({
						options: options.map(({ cost_prices_per_person, ...o }) => {
							return {
								...data,
								...o,
								...sharedData,
							}
						}),
					})
					.then(() => {
						refreshTrip(tripId.toString())
						onChange()
					})
			}}
			onCancel={onCancel}
		/>
	)
}

interface IGiveQuoteData {
	gst_included: "0" | "1"
	tax_percentage?: null | number
	currency_pairs: Array<TCurrencyPair>
	currency_pairs_obj?: TCurrencyPairsObject
	given_currency: string
	functional_currency: string
	costing_currency: string
	options: Array<{
		name: string
		trip_quote_id: number
		total_cost_price: number
		flight_price: number
		margins?: IGivenQuote["margins"]
		cost_prices_per_person?: Array<TPricePerPersonAllocation>
		given_prices_per_person?: Array<TPricePerPersonAllocation>
		given_price_in_costing_currency: number
		given_price: number
		comments: string
	}>
}

const defaultInitialValues: IGiveQuoteData = {
	gst_included: "1",
	tax_percentage: undefined,
	currency_pairs: [],
	currency_pairs_obj: {},
	costing_currency: "INR",
	functional_currency: "INR",
	given_currency: "INR",
	options: [
		{
			name: "",
			trip_quote_id: 0,
			total_cost_price: 0,
			flight_price: 0,
			margins: {
				total: 0,
				total_percentage: 0,
				use_percentage: 0,
				editing_total: 1,
				rounding_point: 5,
			},
			given_prices_per_person: undefined,
			given_price: 0,
			given_price_in_costing_currency: 0,
			comments: "",
		},
	],
}

function getInitialValues({
	tripQuotes,
	functionalCurrency,
}: {
	tripQuotes: Array<ITripQuote>
	functionalCurrency?: string
}): IGiveQuoteData {
	const firstTripQuote = tripQuotes[0]
	const existingGivenQuote = firstTripQuote.given_quote
	const gstIncluded = existingGivenQuote
		? existingGivenQuote.gst_included
			? "1"
			: "0"
		: "1"
	const tax_percentage =
		existingGivenQuote?.tax_percentage === null ||
		existingGivenQuote?.tax_percentage === undefined
			? 5
			: existingGivenQuote?.tax_percentage
	const quote = firstTripQuote.quote
	const costPriceCurrency = firstTripQuote.currency
	const currencyPairs =
		existingGivenQuote?.currency_pairs || quote.currency_pairs || []

	return {
		gst_included: gstIncluded,
		tax_percentage: tax_percentage,
		costing_currency: costPriceCurrency,
		given_currency: existingGivenQuote?.given_currency || costPriceCurrency,
		currency_pairs: currencyPairs,
		currency_pairs_obj: currencyPairsArrayToObject(currencyPairs),
		functional_currency: functionalCurrency || costPriceCurrency,
		options: tripQuotes.map(function (tripQuote, index) {
			const flightPrice = !tripQuote
				? 0
				: Number(
						formatMoneyByDecimal(
							makeCurrencyConverter(currencyPairs)(
								firstTripQuote.currency,
								getTotalPriceForExtras(
									firstTripQuote.quote?.flights.map((f) => ({
										...f,
										price: f.given_price,
									}))
								)
							)
						)
					)
			const existingGivenQuote = tripQuote.given_quote

			const totalCostPrice = tripQuote.cost_price

			const margin = existingGivenQuote
				? calculateMarginFromTotalPrice({
						givenPrice: existingGivenQuote.given_price,
						costPriceWithoutFlights: totalCostPrice - (flightPrice || 0),
						flightPrice,
						gstIncluded: Boolean(Number(gstIncluded)),
						taxPercentage: tax_percentage,
					})
				: 0

			const margin_percentage = calculateMarginPercentageFromTotalPrice({
				costPriceWithoutFlights: totalCostPrice - (flightPrice || 0),
				margin,
			})
			return {
				name: tripQuote.name || `Option ${index + 1}`,
				trip_quote_id: tripQuote.id,
				total_cost_price: totalCostPrice,
				flight_price: flightPrice,
				given_prices_per_person: existingGivenQuote?.given_prices_per_person,
				margins: {
					total: margin,
					total_percentage: margin_percentage,
					use_percentage: 1,
					editing_total: existingGivenQuote?.margins ? 0 : 1,
					...existingGivenQuote?.margins,
				},
				cost_prices_per_person: tripQuote.cost_prices_per_person,
				given_price: 0,
				given_price_in_costing_currency: 0,
				comments: "",
			}
		}),
	}
}

const giveQuoteSchema = Validator.object()
	.shape({
		// given_price: Validator.number()
		//   .positive("Selling price should a positive number")
		//   .required("Selling price field is required"),
		// comments: Validator.string(),
	})
	.required("Quote data is required")

type TGiveQuoteFormProps = {
	initialValues?: IGiveQuoteData
	onSubmit: (data: IGiveQuoteData) => Promise<void>
	onCancel?: () => void
} & Omit<
	React.ComponentProps<typeof MarginsAndTaxInputField>,
	| "costPriceWithoutFlights"
	| "flightPriceInCostingCurrency"
	| "optionIndex"
	| "perPersonCostPriceAllocation"
>

const validate = validateFormValues(giveQuoteSchema)

function GiveQuoteForm({
	initialValues = defaultInitialValues,
	onSubmit,
	onCancel,
	...props
}: TGiveQuoteFormProps) {
	return (
		<Form<IGiveQuoteData>
			initialValues={initialValues}
			validate={validate}
			onSubmit={withServerErrors(async ({ options, ...values }) => {
				if (options.filter((o) => !o.given_price).length) {
					throw new Error("Package price is invalid.")
				}
				await onSubmit({
					options,
					...values,
				})
				showSnackbar("Package price updated.")
			})}
			subscription={{
				submitting: true,
			}}
			mutators={{ ...arrayMutators }}
		>
			{({ submitting, handleSubmit }) => (
				<form noValidate onSubmit={handleSubmit}>
					<Stack gap="4">
						<SelectGivenCurrency />
						<FieldArray name="options">
							{({ fields }) => (
								<Stack gap="6">
									{fields.map((name, index) => (
										<Box key={name}>
											<Stack gap="4" position="relative">
												{Number(fields.length || 0) > 1 ? (
													<Box position="sticky" top="0" zIndex="10">
														<Box
															position="sticky"
															top="0"
															zIndex="10"
															bgColor="emphasis"
															color="on_emphasis"
															padding="2"
															rounded="md"
														>
															<Heading fontSize="md">
																Option {index + 1}:{" "}
																<GetFieldValue<string> name={`${name}.name`}>
																	{({ value }) => <>{value}</>}
																</GetFieldValue>
															</Heading>
														</Box>
													</Box>
												) : null}
												<Stack gap="4" flex="1" maxWidth="full">
													<GetFieldValue<
														IGiveQuoteData["options"][number]["total_cost_price"]
													>
														name={`${name}.total_cost_price`}
													>
														{({ value: totalCostPrice }) => (
															<GetFieldValue<
																IGiveQuoteData["options"][number]["flight_price"]
															>
																name={`${name}.flight_price`}
															>
																{({ value: flightPrice }) => (
																	<GetFieldValue<
																		IGiveQuoteData["options"][number]["cost_prices_per_person"]
																	>
																		name={`${name}.cost_prices_per_person`}
																	>
																		{({
																			value: perPersonCostPriceAllocation,
																		}) => (
																			<MarginsAndTaxInputField
																				costPriceWithoutFlights={
																					Number(totalCostPrice) -
																					Number(flightPrice || 0)
																				}
																				flightPriceInCostingCurrency={
																					flightPrice
																				}
																				perPersonCostPriceAllocation={
																					perPersonCostPriceAllocation
																				}
																				optionIndex={index}
																				{...props}
																			/>
																		)}
																	</GetFieldValue>
																)}
															</GetFieldValue>
														)}
													</GetFieldValue>
													<TextAreaInputField
														name={`${name}.comments`}
														label="Any Comments"
														placeholder="Write comments regarding prices or anything else..."
													/>
												</Stack>
											</Stack>
										</Box>
									))}
								</Stack>
							)}
						</FieldArray>
						<Stack
							borderWidth="2"
							borderColor="primary"
							rounded="md"
							padding="4"
							bgColor="accent"
							gap="4"
						>
							<Inline gap="2" as="header">
								<Box>
									<Box
										display="inlineFlex"
										size="12"
										alignItems="center"
										justifyContent="center"
										bgColor="primary_emphasis"
										color="on_emphasis"
										rounded="full"
									>
										<Icons.BankNotes size="6" />
									</Box>
								</Box>
								<Stack>
									<Heading as="h4" id="quote_selling_price">
										Preview Final Price
									</Heading>
									<Text color="muted">
										Here are the final prices for this option.
									</Text>
								</Stack>
							</Inline>
							<FieldArray name="options">
								{({ fields }) => (
									<Stack gap="6">
										{fields.map((name, index) => (
											<Grid gap="4" key={name} position="relative">
												{Number(fields.length || 0) > 1 ? (
													<Col
														style={{ position: "sticky" }}
														xs={12}
														lg={3}
														top="0"
														zIndex="10"
													>
														<Box
															position="sticky"
															top="0"
															zIndex="10"
															bgColor="emphasis"
															color="on_emphasis"
															padding="2"
															rounded="md"
														>
															<Text fontSize="sm">Option {index + 1}</Text>
															<Heading fontSize="md">
																<GetFieldValue<string> name={`${name}.name`}>
																	{({ value }) => <>{value}</>}
																</GetFieldValue>
															</Heading>
														</Box>
													</Col>
												) : null}
												<Col>
													<GetFieldValue<
														IGiveQuoteData["options"][number]["flight_price"]
													>
														name={`${name}.flight_price`}
													>
														{({ value: flightPrice }) => (
															<PackagePricePreview
																flightPriceInCostingCurrency={flightPrice}
																optionIndex={index}
															/>
														)}
													</GetFieldValue>
												</Col>
											</Grid>
										))}
									</Stack>
								)}
							</FieldArray>
						</Stack>
						<SubmissionError />
						<Divider sm />
						<Inline gap="4">
							<Button type="submit" disabled={submitting}>
								{submitting ? "Updating price..." : "Set Selling Price"}
							</Button>
							{onCancel ? (
								<Button onClick={onCancel} level="tertiary">
									Cancel
								</Button>
							) : null}
						</Inline>
					</Stack>
				</form>
			)}
		</Form>
	)
}

export function SelectGivenCurrency() {
	const allCurrencies = useCurrenciesOfTenant()
	const { value: costPriceCurrency } = useFieldValue<string>("costing_currency")
	const { value: givenPriceCurrency } = useFieldValue<string>("given_currency")
	if (!allCurrencies.length) return null
	return (
		<Inline gap="4">
			<SelectInputField label="Selling Currency" name="given_currency">
				<option value={givenPriceCurrency} key={givenPriceCurrency}>
					{givenPriceCurrency}
				</option>
				{allCurrencies.map((c) =>
					c === givenPriceCurrency ? null : (
						<option value={c} key={c}>
							{c}
						</option>
					)
				)}
			</SelectInputField>
			{costPriceCurrency !== givenPriceCurrency ? (
				<Box>
					<CurrencyPairInputFieldWithRates
						baseCurrency={costPriceCurrency}
						counterCurrency={givenPriceCurrency}
						name={`currency_pairs_obj`}
						readOnly={costPriceCurrency === givenPriceCurrency}
						style={{ maxWidth: "120px" }}
					/>
				</Box>
			) : null}
		</Inline>
	)
}

export function MarginsAndTaxInputField({
	optionIndex,
	costPriceWithoutFlights,
	flightPriceInCostingCurrency,
	perPersonCostPriceAllocation,
}: {
	optionIndex: number
	costPriceWithoutFlights: number
	flightPriceInCostingCurrency: number
	perPersonCostPriceAllocation: ITripQuote["cost_prices_per_person"]
}) {
	const { value: costPriceCurrency } = useFieldValue<string>("costing_currency")
	const { value: givenPriceCurrency } = useFieldValue<string>("given_currency")

	const hasPerPersonPrices = perPersonCostPriceAllocation?.length
	const roundOptions = useMemo(() => {
		return hasPerPersonPrices
			? [undefined, 1, 5, 10, 50, 100]
			: [undefined, 1, 5, 10, 50, 100, 500]
	}, [hasPerPersonPrices])

	return (
		<Stack gap="4">
			<Table bordered hover responsive>
				<thead>
					<tr>
						<TableHeaderDataCell></TableHeaderDataCell>
						<TableHeaderDataCell bgColor="inset" textAlign="right">
							<Inline gap="1" justifyContent="end" flexWrap="wrap">
								<Text>Cost Price</Text>
								<Text>({costPriceCurrency})</Text>
							</Inline>
						</TableHeaderDataCell>
						<TableHeaderDataCell bgColor="inset">
							<Inline
								gap="1"
								alignItems="center"
								justifyContent="center"
								flexWrap="wrap"
							>
								<Text>Markup</Text>
								<Tooltip
									content="Toggle between percentage and Absolute/Fixed Markups"
									placement="top"
								>
									<Box fontSize="sm">
										<SwitchInputField
											name={`options[${optionIndex}].margins.use_percentage`}
											label="%"
										/>
									</Box>
								</Tooltip>
							</Inline>
						</TableHeaderDataCell>
						<TableHeaderDataCell bgColor="inset">
							<Inline
								gap="1"
								alignItems="center"
								justifyContent="center"
								flexWrap="wrap"
							>
								<SwitchInputField
									name="gst_included"
									label={
										String(costPriceCurrency).toLowerCase() === "inr"
											? "GST"
											: "Taxes"
									}
								/>
								<Box>
									<GetFieldValue<boolean> name="gst_included">
										{({ value: gstIncluded }) =>
											isTruthy(gstIncluded) ? (
												<PercentageInputField
													name="tax_percentage"
													size="sm"
													style={{ width: "60px" }}
												/>
											) : (
												<Text>(exc)</Text>
											)
										}
									</GetFieldValue>
								</Box>
							</Inline>
						</TableHeaderDataCell>
						<TableHeaderDataCell bgColor="inset" textAlign="right">
							<Inline gap="1" justifyContent="end" flexWrap="wrap">
								<Text>Total</Text>
								<Text>({costPriceCurrency})</Text>
							</Inline>
						</TableHeaderDataCell>
						{givenPriceCurrency && givenPriceCurrency !== costPriceCurrency ? (
							<TableHeaderDataCell textAlign="right">
								<Inline gap="1" justifyContent="end" flexWrap="wrap">
									<Text>Total</Text>
									<Text>({givenPriceCurrency})</Text>
								</Inline>
							</TableHeaderDataCell>
						) : null}
						<TableHeaderDataCell
							textAlign="right"
							borderLeftWidth="1"
							bgColor="primary"
						>
							<Inline
								gap="1"
								justifyContent="end"
								alignItems="center"
								flexWrap="wrap"
							>
								<Text>Round</Text>
								<Box
									paddingX="2"
									bgColor="default"
									rounded="default"
									borderWidth="1"
								>
									<InlineSelectInputField
										name={`options[${optionIndex}].margins.rounding_point`}
										style={{ maxWidth: "90px" }}
									>
										{roundOptions.map((o, i) => (
											<option value={o || ""} key={i}>
												{o || "--"}
											</option>
										))}
									</InlineSelectInputField>
								</Box>
							</Inline>
						</TableHeaderDataCell>
					</tr>
				</thead>
				{perPersonCostPriceAllocation?.length ? (
					<tbody>
						{perPersonCostPriceAllocation.map(
							({ id, per_pax_label, total_pax_label, pax_count, price }) =>
								!Number(price) ? null : (
									<tr
										key={`options-${optionIndex}-${id}-${total_pax_label}-${price}`}
									>
										<TableDataCell bgColor="inset" fontWeight="semibold">
											<Stack>
												<Text>/{per_pax_label}</Text>
												<Text fontSize="sm" color="muted">
													{total_pax_label}
												</Text>
											</Stack>
										</TableDataCell>
										<TableDataCell textAlign="right">
											<Money
												currency={costPriceCurrency}
												amount={Number(price)}
											/>
											<Text color="muted" fontSize="sm">
												x {pax_count}
											</Text>
										</TableDataCell>
										<TableDataCell textAlign="center">
											<Stack alignItems="center">
												<PerPersonMarginInputField
													optionIndex={optionIndex}
													name={`options[${optionIndex}].margins.per_person_allocation.${id}`}
													costPrice={Number(price)}
												/>
											</Stack>
										</TableDataCell>
										<TableDataCell textAlign="center">
											<ComponentTaxedValue
												costPriceCurrency={costPriceCurrency}
												costPrice={Number(price)}
												marginName={`options[${optionIndex}].margins.per_person_allocation.${id}`}
											/>
										</TableDataCell>
										<TableDataCell textAlign="right" bgColor="inset">
											<PriceWithTaxAndMargin
												costPriceCurrency={costPriceCurrency}
												costPrice={Number(price)}
												marginName={`options[${optionIndex}].margins.per_person_allocation.${id}`}
											/>
										</TableDataCell>
										{givenPriceCurrency &&
										givenPriceCurrency !== costPriceCurrency ? (
											<TableDataCell textAlign="right">
												<PriceWithTaxAndMarginInGivenCurrency
													costPriceCurrency={costPriceCurrency}
													costPrice={Number(price)}
													marginName={`options[${optionIndex}].margins.per_person_allocation.${id}`}
													givenPriceCurrency={givenPriceCurrency}
												/>
											</TableDataCell>
										) : null}
										<TableDataCell
											textAlign="right"
											borderLeftWidth="1"
											bgColor="primary"
										>
											<RoundedComponentPriceWithTaxAndMarginInGivenCurrency
												costPriceCurrency={costPriceCurrency}
												costPrice={Number(price)}
												marginName={`options[${optionIndex}].margins.per_person_allocation.${id}`}
												roundingName={`options[${optionIndex}].margins.rounding_point`}
												givenPriceCurrency={givenPriceCurrency}
											/>
											<Text color="muted" fontSize="sm">
												x {pax_count}
											</Text>
										</TableDataCell>
									</tr>
								)
						)}
					</tbody>
				) : null}
				<tbody>
					<tr>
						<TableDataCell bgColor="inset" fontWeight="semibold">
							Total
						</TableDataCell>
						<TableDataCell textAlign="right" fontWeight="semibold">
							<Money
								currency={costPriceCurrency}
								amount={costPriceWithoutFlights}
							/>
						</TableDataCell>
						<TableDataCell textAlign="center">
							<Stack alignItems="center">
								<TotalMarginInputField
									optionIndex={optionIndex}
									name={`options[${optionIndex}].margins.total`}
									costPrice={costPriceWithoutFlights}
									hasPerPersonPrices={Boolean(
										perPersonCostPriceAllocation?.length
									)}
								/>
							</Stack>
						</TableDataCell>
						<TableDataCell textAlign="center" fontWeight="semibold">
							<ComponentTaxedValue
								costPriceCurrency={costPriceCurrency}
								costPrice={costPriceWithoutFlights}
								marginName={`options[${optionIndex}].margins.total`}
							/>
						</TableDataCell>
						<TableDataCell
							textAlign="right"
							fontWeight="semibold"
							bgColor="inset"
						>
							<PriceWithTaxAndMargin
								costPriceCurrency={costPriceCurrency}
								costPrice={costPriceWithoutFlights}
								marginName={`options[${optionIndex}].margins.total`}
							/>
							<Text fontWeight="normal" color="muted" fontSize="sm">
								{costPriceCurrency}
							</Text>
						</TableDataCell>
						{givenPriceCurrency && givenPriceCurrency !== costPriceCurrency ? (
							<TableDataCell textAlign="right" fontWeight="semibold">
								<PriceWithTaxAndMarginInGivenCurrency
									costPriceCurrency={costPriceCurrency}
									costPrice={costPriceWithoutFlights}
									marginName={`options[${optionIndex}].margins.total`}
									givenPriceCurrency={givenPriceCurrency}
								/>
								<Text fontWeight="normal" color="muted" fontSize="sm">
									{givenPriceCurrency}
								</Text>
							</TableDataCell>
						) : null}
						<TableDataCell
							textAlign="right"
							fontWeight="semibold"
							borderLeftWidth="1"
							bgColor="primary"
						>
							<PackagePriceWithoutFlightPrice
								costPriceCurrency={costPriceCurrency}
								givenPriceCurrency={givenPriceCurrency}
								optionIndex={optionIndex}
								flightPrice={flightPriceInCostingCurrency}
							/>
							<Text fontWeight="normal" color="muted" fontSize="sm">
								{givenPriceCurrency}
							</Text>
						</TableDataCell>
					</tr>
				</tbody>
				{flightPriceInCostingCurrency ? (
					<tfoot>
						<tr>
							<TableFooterDataCell colSpan={4} textAlign="right">
								<Icons.Airplane /> Flights:
							</TableFooterDataCell>
							<TableFooterDataCell textAlign="right" bgColor="inset">
								<Money
									currency={costPriceCurrency}
									amount={flightPriceInCostingCurrency}
								/>
							</TableFooterDataCell>
							{givenPriceCurrency &&
							costPriceCurrency !== givenPriceCurrency ? (
								<TableFooterDataCell textAlign="right">
									<GetCurrencyPairRatioFieldValue
										baseCurrency={costPriceCurrency}
										counterCurrency={givenPriceCurrency}
										name={"currency_pairs_obj"}
									>
										{({ value: exchange }) => (
											<Money
												money={convertMoneyToCurrency(
													moneyParseByDecimal(
														flightPriceInCostingCurrency,
														costPriceCurrency
													),
													givenPriceCurrency,
													exchange || 0
												)}
											/>
										)}
									</GetCurrencyPairRatioFieldValue>
								</TableFooterDataCell>
							) : null}
							<TableFooterDataCell></TableFooterDataCell>
						</tr>
					</tfoot>
				) : null}
			</Table>
			<WarnForSignificantRoundingIncrement
				costPriceCurrency={costPriceCurrency}
				givenPriceCurrency={givenPriceCurrency}
				optionIndex={optionIndex}
				costPrice={costPriceWithoutFlights}
				flightPrice={flightPriceInCostingCurrency}
				marginName={`options[${optionIndex}].margins.total`}
				roundingName={`options[${optionIndex}].margins.rounding_point`}
			/>
			<DeferRender by={500} noLoader>
				<SyncPackagePrice
					optionIndex={optionIndex}
					costPriceWithoutFlights={costPriceWithoutFlights}
					flightPriceInCostingCurrency={flightPriceInCostingCurrency}
					perPersonCostPriceAllocation={perPersonCostPriceAllocation}
				/>
				<SyncPerPersonMarginsWithTotalMargin
					name={`options[${optionIndex}].margins`}
					costPriceWithoutFlights={costPriceWithoutFlights}
					perPersonCostPriceAllocation={perPersonCostPriceAllocation}
				/>
			</DeferRender>
		</Stack>
	)
}

function PerPersonMarginInputField({
	optionIndex,
	name,
	costPrice,
}: {
	optionIndex: number
	name: string
	costPrice: number
}) {
	const { value: _use_percentage } = useFieldValue(
		`options[${optionIndex}].margins.use_percentage`
	)
	const use_percentage = isTruthy(_use_percentage)
	const { value: _editing_total, onChange: changeEditingTotal } = useFieldValue(
		`options[${optionIndex}].margins.editing_total`
	)
	const editing_total = isTruthy(_editing_total)
	const { value: margin, onChange: changeMargin } = useFieldValue<
		number | undefined | ""
	>(name)
	const marginPercentageName = name + "_percentage"
	const { value: marginPercentage } = useFieldValue<number | undefined | "">(
		marginPercentageName
	)
	const readOnlyForAbsolute = editing_total

	useDidMount(() => {
		if (!use_percentage) {
			// set the margin if we have margin percentage and absolute are not set
			if (
				!readOnlyForAbsolute &&
				marginPercentage &&
				(margin === undefined || margin === null || margin === "")
			) {
				changeMargin(
					normalizeNumber(
						(Number(marginPercentage || 0) * Number(costPrice || 0)) / 100,
						2
					)
				)
			}
		}
	})

	if (isTruthy(use_percentage)) {
		return (
			<ComponentPercentageMarginInputField
				name={name}
				costPrice={costPrice}
				readOnly
			/>
		)
	}
	return (
		<Inline alignItems="center" gap="2">
			<ComponentAbsoluteMarginInputField
				name={name}
				costPrice={costPrice}
				readOnly={readOnlyForAbsolute}
			/>
			{editing_total ? (
				<Box opacity="50">
					<Button
						size="sm"
						level="tertiary"
						onClick={() => {
							changeEditingTotal(editing_total ? 0 : 1)
						}}
					>
						<Icons.Pencil />
					</Button>
				</Box>
			) : null}
		</Inline>
	)
}

function TotalMarginInputField({
	optionIndex,
	name,
	costPrice,
	hasPerPersonPrices,
}: {
	optionIndex: number
	name: string
	costPrice: number
	hasPerPersonPrices: boolean
}) {
	const { value: _use_percentage } = useFieldValue(
		`options[${optionIndex}].margins.use_percentage`
	)
	const use_percentage = isTruthy(_use_percentage)
	const { value: _editing_total, onChange: changeEditingTotal } = useFieldValue(
		`options[${optionIndex}].margins.editing_total`
	)
	const editing_total = isTruthy(_editing_total)
	const { value: margin, onChange: changeMargin } = useFieldValue<
		number | undefined | ""
	>(name)
	const marginPercentageName = name + "_percentage"
	const { value: marginPercentage, onChange: changeMarginPercentage } =
		useFieldValue<number | undefined | "">(marginPercentageName)

	useDidMount(() => {
		if (use_percentage) {
			// set the margin percentage if we have absolute margin and percentage are not set
			if (
				margin &&
				(marginPercentage === undefined ||
					marginPercentage === null ||
					marginPercentage === "")
			) {
				changeMarginPercentage(
					normalizeNumber(
						(Number(margin || 0) * 100) / Number(costPrice || 1),
						4
					)
				)
			}
		} else {
			// set the margin if we have margin percentage and absolute are not set
			if (
				!hasPerPersonPrices &&
				marginPercentage &&
				(margin === undefined || margin === null || margin === "")
			) {
				changeMargin(
					normalizeNumber(
						(Number(marginPercentage || 0) * Number(costPrice || 0)) / 100,
						2
					)
				)
			}
		}
	})

	if (isTruthy(use_percentage)) {
		return (
			<ComponentPercentageMarginInputField name={name} costPrice={costPrice} />
		)
	}
	return (
		<Inline alignItems="center" gap="2">
			<ComponentAbsoluteMarginInputField
				name={name}
				costPrice={costPrice}
				readOnly={!editing_total && hasPerPersonPrices}
				showPercentage
			/>
			{!editing_total && hasPerPersonPrices ? (
				<Button
					size="sm"
					level="tertiary"
					onClick={() => {
						changeEditingTotal(editing_total ? 0 : 1)
					}}
				>
					<Icons.Pencil />
				</Button>
			) : null}
		</Inline>
	)
}

function ComponentAbsoluteMarginInputField({
	name,
	costPrice,
	readOnly,
	showPercentage,
}: {
	name: string
	costPrice: number
	readOnly?: boolean
	showPercentage?: boolean
}) {
	const { value: margin } = useFieldValue<number | undefined | "">(name)
	const marginPercentageName = name + "_percentage"
	const { value: marginPercentage, onChange: changeMarginPercentage } =
		useFieldValue<number | undefined | "">(marginPercentageName)

	// update the margin percentage if cost or percentage  changes
	useEffect(() => {
		// NOTE: We are using the timer as other components depends on this updated value
		// TODO: Remove this setTimeout
		const h = setTimeout(() => {
			changeMarginPercentage(
				normalizeNumber((Number(margin || 0) * 100) / Number(costPrice || 1), 4)
			)
		}, 100)
		return () => clearTimeout(h)
	}, [changeMarginPercentage, margin, costPrice])

	if (readOnly) {
		return <Text>{numberToLocalString(Number(margin || 0), 0)}</Text>
	}

	return (
		<TextInputField
			type="number"
			name={name}
			style={{ width: "100px" }}
			size="sm"
			min={0}
			help={
				showPercentage
					? `= ${normalizeNumber(Number(marginPercentage || 0), 2)}%`
					: null
			}
		/>
	)
}

function ComponentPercentageMarginInputField({
	name,
	costPrice,
	readOnly,
}: {
	name: string
	costPrice: number
	readOnly?: boolean
}) {
	const { value: margin, onChange: changeMargin } = useFieldValue<
		number | undefined | ""
	>(name)
	const marginPercentageName = name + "_percentage"
	const { value: marginPercentage } = useFieldValue<number | undefined | "">(
		marginPercentageName
	)
	// update the margin if cost or percentage  changes
	useEffect(() => {
		if (marginPercentage !== undefined && marginPercentage !== "") {
			// NOTE: We are using the timer as other components depends on this updated value
			// TODO: Remove this timer
			const h = setTimeout(() => {
				changeMargin(
					normalizeNumber(
						(Number(marginPercentage || 0) * Number(costPrice || 0)) / 100
					)
				)
			}, 100)
			return () => clearTimeout(h)
		}
	}, [changeMargin, marginPercentage, costPrice])

	if (readOnly) {
		return <Text>{numberToLocalString(Number(margin || 0), 0)}</Text>
	}

	return (
		<PercentageInputField
			name={marginPercentageName}
			help={`= ${numberToLocalString(Number(margin || 0), 0)}`}
			size="sm"
		/>
	)
}

function SyncPerPersonMarginsWithTotalMargin({
	name,
	costPriceWithoutFlights,
	perPersonCostPriceAllocation,
}: {
	name: string
	costPriceWithoutFlights: number
	perPersonCostPriceAllocation: ITripQuote["cost_prices_per_person"]
}) {
	const { value: margins } =
		useFieldValue<IGiveQuoteData["options"][number]["margins"]>(name)
	const form = useForm()
	const use_percentage = isTruthy(margins?.use_percentage)
	const editing_total = isTruthy(margins?.editing_total)
	const totalMargin = margins?.total
	const totalMarginPercentage = margins?.total_percentage
	const perPersonMarginAllocaton = margins?.per_person_allocation

	// sync the percentage per-person with total
	useEffect(() => {
		if (use_percentage && perPersonCostPriceAllocation?.length) {
			form.batch(() => {
				perPersonCostPriceAllocation.forEach(({ id }) => {
					form.change(
						`${name}.per_person_allocation.${id}_percentage`,
						totalMarginPercentage
					)
				})
			})
		}
	}, [
		form,
		name,
		costPriceWithoutFlights,
		totalMarginPercentage,
		perPersonCostPriceAllocation,
		use_percentage,
	])

	// sync the per-person with total if EDITING total (absolute) and per-person are set
	useEffect(() => {
		if (
			editing_total &&
			!use_percentage &&
			perPersonCostPriceAllocation?.length
		) {
			const factor =
				Number(totalMargin || 0) / (Number(costPriceWithoutFlights) || 1)
			form.batch(() => {
				perPersonCostPriceAllocation.forEach(({ id, price }) => {
					form.change(
						`${name}.per_person_allocation.${id}`,
						Number(price || 0) * factor
					)
				})
			})
		}
	}, [
		form,
		name,
		costPriceWithoutFlights,
		totalMargin,
		perPersonCostPriceAllocation,
		editing_total,
		use_percentage,
	])

	// sync the total with per-person if NOT EDITING total (absolute) and per-person is set
	useEffect(() => {
		if (
			!editing_total &&
			!use_percentage &&
			perPersonCostPriceAllocation?.length
		) {
			form.change(
				`${name}.total`,
				perPersonCostPriceAllocation.reduce<number>(
					(total, { id, pax_count }) =>
						total + Number(perPersonMarginAllocaton?.[id]) * pax_count,
					0
				)
			)
		}
	}, [
		form,
		name,
		editing_total,
		use_percentage,
		perPersonCostPriceAllocation,
		perPersonMarginAllocaton,
	])
	return null
}

function useComponentPriceWithMargin({
	costPriceCurrency,
	costPrice,
	marginName,
}: {
	costPriceCurrency: string
	costPrice: number
	marginName: string
}) {
	const { value: margin } = useFieldValue<number | undefined>(marginName)
	const price = Number(costPrice) + Number(margin || 0)
	return moneyParseByDecimal(price, costPriceCurrency)
}

function useComponentTaxedValue({
	costPriceCurrency,
	costPrice,
	marginName,
}: {
	costPriceCurrency: string
	costPrice: number
	marginName: string
}) {
	const { value: gstIncluded } = useFieldValue<boolean>("gst_included")
	const { value: tax_percentage } = useFieldValue<number | null | undefined>(
		"tax_percentage"
	)
	const tax = isTruthy(gstIncluded) && tax_percentage ? tax_percentage : 0
	const price = useComponentPriceWithMargin({
		costPriceCurrency,
		costPrice,
		marginName,
	})
	return tax ? getPercentageOfMoney(price, Number(tax_percentage)) : null
}

function ComponentTaxedValue({
	costPriceCurrency,
	costPrice,
	marginName,
}: {
	costPriceCurrency: string
	costPrice: number
	marginName: string
}) {
	const value = useComponentTaxedValue({
		costPriceCurrency,
		costPrice,
		marginName,
	})
	return <Text>{value ? <Money money={value} /> : "-"}</Text>
}

function useComponentPriceWithMarginAndTax({
	costPriceCurrency,
	costPrice,
	marginName,
}: {
	costPrice: number
	costPriceCurrency: string
	marginName: string
}) {
	const price = useComponentPriceWithMargin({
		costPriceCurrency,
		costPrice,
		marginName,
	})
	const taxValue = useComponentTaxedValue({
		costPrice,
		costPriceCurrency,
		marginName,
	})
	let money = price
	if (taxValue) {
		money = addMoney(price, taxValue)
	}
	return money
}

function PriceWithTaxAndMargin({
	costPriceCurrency,
	costPrice,
	marginName,
}: {
	costPrice: number
	costPriceCurrency: string
	marginName: string
}) {
	const money = useComponentPriceWithMarginAndTax({
		costPrice,
		costPriceCurrency,
		marginName,
	})
	return <Money money={money} />
}

function useComponentPriceWithMarginAndTaxInGivenCurrency({
	costPriceCurrency,
	costPrice,
	givenPriceCurrency,
	marginName,
}: {
	costPrice: number
	costPriceCurrency: string
	givenPriceCurrency: string
	marginName: string
}) {
	const money = useComponentPriceWithMarginAndTax({
		costPrice,
		costPriceCurrency,
		marginName,
	})
	const { value: costPriceGivenPriceCurrencyPairRatio } =
		useCurrencyPairRatioFieldValue(
			"currency_pairs_obj",
			costPriceCurrency,
			givenPriceCurrency
		)
	return convertMoneyToCurrency(
		money,
		givenPriceCurrency,
		costPriceGivenPriceCurrencyPairRatio || 0
	)
}

function PriceWithTaxAndMarginInGivenCurrency({
	costPriceCurrency,
	costPrice,
	marginName,
	givenPriceCurrency,
}: {
	costPrice: number
	costPriceCurrency: string
	marginName: string
	givenPriceCurrency: string
}) {
	const money = useComponentPriceWithMarginAndTaxInGivenCurrency({
		costPrice,
		costPriceCurrency,
		marginName,
		givenPriceCurrency,
	})
	return <Money money={money} />
}

function RoundedComponentPriceWithTaxAndMarginInGivenCurrency({
	costPriceCurrency,
	costPrice,
	marginName,
	givenPriceCurrency,
	roundingName,
}: {
	costPrice: number
	costPriceCurrency: string
	marginName: string
	givenPriceCurrency: string
	roundingName: string
}) {
	const money = useComponentPriceWithMarginAndTaxInGivenCurrency({
		costPrice,
		costPriceCurrency,
		marginName,
		givenPriceCurrency,
	})
	const { value: roundingPoint } = useFieldValue<number | undefined>(
		roundingName
	)
	const roundedMoney = roundMoneyToPoint(money, roundingPoint)
	return <Money money={roundedMoney} />
}

function usePackagePriceWithoutFlightPrice({
	flightPrice,
	optionIndex,
	costPriceCurrency,
	givenPriceCurrency,
}: {
	flightPrice: number
	optionIndex: number
	costPriceCurrency: string
	givenPriceCurrency: string
}) {
	const { value: costPriceGivenPriceCurrencyPairRatio } =
		useCurrencyPairRatioFieldValue(
			"currency_pairs_obj",
			costPriceCurrency,
			givenPriceCurrency
		)
	const { value: packagePriceInGivenCurrency } = useFieldValue<
		IGiveQuoteData["options"][number]["given_price"]
	>(`options[${optionIndex}].given_price`)

	const flightPriceInGivenCurrency = convertMoneyToCurrency(
		moneyParseByDecimal(flightPrice, costPriceCurrency),
		givenPriceCurrency,
		costPriceGivenPriceCurrencyPairRatio || 0
	)

	return subtractMoney(
		moneyParseByDecimal(packagePriceInGivenCurrency, givenPriceCurrency),
		flightPriceInGivenCurrency
	)
}

function PackagePriceWithoutFlightPrice({
	flightPrice,
	optionIndex,
	costPriceCurrency,
	givenPriceCurrency,
}: {
	flightPrice: number
	optionIndex: number
	costPriceCurrency: string
	givenPriceCurrency: string
}) {
	const price = usePackagePriceWithoutFlightPrice({
		flightPrice,
		optionIndex,
		costPriceCurrency,
		givenPriceCurrency,
	})

	return <Money money={price} />
}

function WarnForSignificantRoundingIncrement({
	costPrice,
	flightPrice,
	optionIndex,
	costPriceCurrency,
	givenPriceCurrency,
	marginName,
	roundingName,
}: {
	costPrice: number
	flightPrice: number
	optionIndex: number
	costPriceCurrency: string
	givenPriceCurrency: string
	marginName: string
	roundingName: string
}) {
	// all prices are excluding flight price
	const totalGivenPriceBeforeRounding =
		useComponentPriceWithMarginAndTaxInGivenCurrency({
			costPrice,
			costPriceCurrency,
			marginName,
			givenPriceCurrency,
		})
	const totalGivenPriceAfterRounding = usePackagePriceWithoutFlightPrice({
		flightPrice,
		optionIndex,
		costPriceCurrency,
		givenPriceCurrency,
	})
	const { value: roundingPoint } = useFieldValue<number | undefined>(
		roundingName
	)
	const percentageIncrement =
		((totalGivenPriceAfterRounding.amount -
			totalGivenPriceBeforeRounding.amount) *
			100) /
		(totalGivenPriceBeforeRounding.amount || 1)
	if (percentageIncrement < 1) {
		return null
	}
	return (
		<Alert status="warning">
			<Stack gap="2">
				<Text fontSize="md" fontWeight="semibold">
					Possible High Rounding ({Number(percentageIncrement).toFixed(2)}%
					increased)
				</Text>
				<Text>
					The rounding point ({roundingPoint}) has increased the price by{" "}
					<b>
						{Number(percentageIncrement).toFixed(2)}% from{" "}
						<Money money={totalGivenPriceBeforeRounding} showCurrency /> to{" "}
						<Money money={totalGivenPriceAfterRounding} showCurrency />
					</b>
					.
				</Text>
				<Text>Please reconfirm or reduce the rounding if incorrect.</Text>
			</Stack>
		</Alert>
	)
}

export function PackagePricePreview({
	optionIndex,
	flightPriceInCostingCurrency,
}: {
	optionIndex: number
	flightPriceInCostingCurrency: number
}) {
	const { value: gstIncluded } = useFieldValue<boolean>("gst_included")
	const { value: tax_percentage } = useFieldValue<number | null | undefined>(
		"tax_percentage"
	)
	const { value: givenPricesPerPerson } = useFieldValue<
		IGiveQuoteData["options"][number]["given_prices_per_person"]
	>(`options[${optionIndex}].given_prices_per_person`)
	const { value: packagePriceInGivenCurrency } = useFieldValue<
		IGiveQuoteData["options"][number]["given_price"]
	>(`options[${optionIndex}].given_price`)
	const { value: costPriceCurrency } = useFieldValue<string>("costing_currency")
	const { value: givenPriceCurrency } = useFieldValue<string>("given_currency")

	const { value: costPriceGivenPriceCurrencyPairRatio } =
		useCurrencyPairRatioFieldValue(
			"currency_pairs_obj",
			costPriceCurrency,
			givenPriceCurrency
		)

	return (
		<Stack gap="4" maxWidth="3xl">
			{givenPricesPerPerson ? (
				<Table
					bordered
					headers={["", "Per Pax", "Qnty.", "Sub Total"]}
					responsive
					alignCols={{ 1: "right", 2: "right", 3: "right" }}
				>
					<tbody>
						{(givenPricesPerPerson || []).map(
							({
								id,
								price,
								currency,
								per_pax_label,
								total_pax_label,
								pax_count,
							}) => (
								<tr key={`${id}-${total_pax_label}-${price}`}>
									<TableDataCell>
										<Text>
											{per_pax_label} x {total_pax_label}{" "}
										</Text>
									</TableDataCell>
									<TableDataCell textAlign="right" fontWeight="semibold">
										<Money currency={currency} amount={Number(price)} />
									</TableDataCell>
									<TableDataCell textAlign="right">{pax_count}</TableDataCell>
									<TableDataCell textAlign="right">
										<Money
											currency={currency}
											amount={Number(price) * pax_count}
										/>
									</TableDataCell>
								</tr>
							)
						)}
						{flightPriceInCostingCurrency ? (
							<tr>
								<TableDataCell colSpan={3}>Flights</TableDataCell>
								<TableDataCell textAlign="right" fontWeight="semibold">
									<Money
										money={convertMoneyToCurrency(
											moneyParseByDecimal(
												flightPriceInCostingCurrency,
												costPriceCurrency
											),
											givenPriceCurrency,
											Number(costPriceGivenPriceCurrencyPairRatio || 0)
										)}
									/>
								</TableDataCell>
							</tr>
						) : null}
					</tbody>
					<tfoot>
						<tr>
							<TableFooterDataCell colSpan={3} textAlign="right">
								<Stack gap="1">
									<Text fontWeight="semibold" fontSize="lg">
										Total
									</Text>
									<Text>
										{String(givenPriceCurrency).toLowerCase() === "inr"
											? "GST"
											: "Taxes"}
										:{" "}
										{Number(gstIncluded) === 1
											? `Included${
													tax_percentage ? ` (${tax_percentage}%)` : ``
												}`
											: "Excluded"}{" "}
									</Text>
								</Stack>
							</TableFooterDataCell>
							<TableFooterDataCell textAlign="right">
								<Text fontSize="lg" fontWeight="semibold" textAlign="right">
									<Money
										currency={givenPriceCurrency}
										amount={packagePriceInGivenCurrency}
										showCurrency
									/>
								</Text>
							</TableFooterDataCell>
						</tr>
					</tfoot>
				</Table>
			) : (
				<Stack gap="2">
					<Text fontSize="3xl" fontWeight="semibold">
						<Money
							currency={givenPriceCurrency}
							amount={packagePriceInGivenCurrency}
							showCurrency
						/>
					</Text>
					<Text fontWeight="semibold">
						{String(givenPriceCurrency).toLowerCase() === "inr"
							? "GST"
							: "Taxes"}
						:{" "}
						{Number(gstIncluded) === 1
							? `Included${tax_percentage ? ` (${tax_percentage}%)` : ``}`
							: "Excluded"}{" "}
					</Text>
				</Stack>
			)}
		</Stack>
	)
}

function SyncPackagePrice({
	optionIndex,
	costPriceWithoutFlights,
	flightPriceInCostingCurrency,
	perPersonCostPriceAllocation,
}: {
	optionIndex: number
	costPriceWithoutFlights: number
	flightPriceInCostingCurrency: number
	perPersonCostPriceAllocation: ITripQuote["cost_prices_per_person"]
}) {
	const { value: gstIncluded } = useFieldValue<boolean>("gst_included")
	const { value: tax_percentage } = useFieldValue<number | null | undefined>(
		"tax_percentage"
	)
	const { value: givenPricesPerPerson } = useFieldValue<
		IGiveQuoteData["options"][number]["given_prices_per_person"]
	>(`options[${optionIndex}].given_prices_per_person`)
	const { value: costPriceCurrency } = useFieldValue<string>("costing_currency")
	const { value: givenPriceCurrency } = useFieldValue<string>("given_currency")
	const { value: functionalCurrency } = useFieldValue<string>(
		"functional_currency"
	)

	const { value: margins } = useFieldValue<
		IGiveQuoteData["options"][number]["margins"]
	>(`options[${optionIndex}].margins`)
	const form = useForm()

	const roundingPoint = margins?.rounding_point

	const { value: costPriceGivenPriceCurrencyPairRatio } =
		useCurrencyPairRatioFieldValue(
			"currency_pairs_obj",
			costPriceCurrency,
			givenPriceCurrency
		)

	const { onChange: changeGivenPriceFunctionalCurrencyPairRatio } =
		useCurrencyPairRatioFieldValue(
			"currency_pairs_obj",
			givenPriceCurrency,
			functionalCurrency
		)

	const tax =
		isTruthy(gstIncluded) && Number(tax_percentage || 0)
			? Number(tax_percentage || 0)
			: 0

	const totalMargin = margins?.total || 0
	// update the per-person given prices
	useEffect(() => {
		if (!perPersonCostPriceAllocation?.length) {
			form.change(`options[${optionIndex}].given_prices_per_person`, undefined)
			return
		}
		const givenPricesPerPersonAfterRounding = perPersonCostPriceAllocation
			?.filter(
				(allocation) =>
					moneyParseByDecimal(Number(allocation.price), costPriceCurrency)
						.amount > 0
			)
			.map((allocation) => {
				let price =
					Number(allocation.price) +
					Number(margins?.per_person_allocation?.[allocation.id] || 0)
				if (tax) {
					price += (price * tax) / 100
				}
				const givenPrice = convertMoneyToCurrency(
					moneyParseByDecimal(price, costPriceCurrency),
					givenPriceCurrency,
					Number(costPriceGivenPriceCurrencyPairRatio || 0)
				)
				return {
					...allocation,
					currency: givenPriceCurrency,
					price: formatMoneyByDecimal(
						roundMoneyToPoint(givenPrice, roundingPoint)
					),
				}
			})
		form.change(
			`options[${optionIndex}].given_prices_per_person`,
			givenPricesPerPersonAfterRounding
		)
	}, [
		optionIndex,
		form,
		perPersonCostPriceAllocation,
		margins,
		tax,
		costPriceGivenPriceCurrencyPairRatio,
		costPriceCurrency,
		givenPriceCurrency,
		roundingPoint,
	])

	// calculate given_price_in_costing_currency using per-person
	useEffect(() => {
		if (perPersonCostPriceAllocation?.length) {
			const given_price =
				perPersonCostPriceAllocation.reduce<number>((total, allocation) => {
					let price =
						Number(allocation.price) +
						Number(margins?.per_person_allocation?.[allocation.id] || 0)
					if (tax) {
						price += (price * tax) / 100
					}
					return total + Number(price) * Number(allocation.pax_count)
				}, 0) + flightPriceInCostingCurrency
			form.change(
				`options[${optionIndex}].given_price_in_costing_currency`,
				formatMoneyByDecimal(
					moneyParseByDecimal(given_price, costPriceCurrency)
				)
			)
		}
	}, [
		optionIndex,
		flightPriceInCostingCurrency,
		costPriceCurrency,
		perPersonCostPriceAllocation,
		form,
		tax,
		margins,
	])
	const hasPerPersonCostPrices = perPersonCostPriceAllocation?.length
	// otherwise calculate given_price_in_costing_currency if per-person is not available
	useEffect(() => {
		if (!hasPerPersonCostPrices) {
			const given_price = calculateTotalPrice({
				costPriceCurrency: costPriceCurrency,
				costPriceWithoutFlights: costPriceWithoutFlights,
				flightPrice: flightPriceInCostingCurrency,
				gstIncluded,
				taxPercentage: tax,
				margin: totalMargin,
			})
			form.change(
				`options[${optionIndex}].given_price_in_costing_currency`,
				formatMoneyByDecimal(
					moneyParseByDecimal(given_price, costPriceCurrency)
				)
			)
		}
	}, [
		optionIndex,
		costPriceWithoutFlights,
		flightPriceInCostingCurrency,
		costPriceCurrency,
		gstIncluded,
		tax,
		hasPerPersonCostPrices,
		totalMargin,
		form,
	])

	// calculate given price using per-person
	useEffect(() => {
		if (givenPricesPerPerson?.length) {
			const givenPriceWithoutFlights = moneyParseByDecimal(
				givenPricesPerPerson.reduce<number>(
					(total, { price, pax_count }) => total + Number(price) * pax_count,
					0
				),
				givenPriceCurrency
			)
			const flightsGivenPrice = convertMoneyToCurrency(
				moneyParseByDecimal(flightPriceInCostingCurrency, costPriceCurrency),
				givenPriceCurrency,
				Number(costPriceGivenPriceCurrencyPairRatio || 0)
			)
			form.change(
				`options[${optionIndex}].given_price`,
				formatMoneyByDecimal(
					addMoney(givenPriceWithoutFlights, flightsGivenPrice)
				)
			)
		}
	}, [
		optionIndex,
		flightPriceInCostingCurrency,
		givenPricesPerPerson,
		costPriceGivenPriceCurrencyPairRatio,
		form,
		givenPriceCurrency,
		costPriceCurrency,
	])
	// otherwise calculate given price using total cost
	const hasPerPersonGivenPrices = givenPricesPerPerson?.length
	useEffect(() => {
		if (!hasPerPersonGivenPrices) {
			const givenPriceInCostingCurrency = Number(
				calculateTotalPrice({
					costPriceCurrency: costPriceCurrency,
					costPriceWithoutFlights: costPriceWithoutFlights,
					flightPrice: flightPriceInCostingCurrency,
					gstIncluded,
					taxPercentage: tax,
					margin: totalMargin,
				})
			)
			const givenPriceInGivenCurrency = convertMoneyToCurrency(
				moneyParseByDecimal(givenPriceInCostingCurrency, costPriceCurrency),
				givenPriceCurrency,
				Number(costPriceGivenPriceCurrencyPairRatio || 0)
			)
			form.change(
				`options[${optionIndex}].given_price`,
				formatMoneyByDecimal(
					roundMoneyToPoint(givenPriceInGivenCurrency, roundingPoint)
				)
			)
		}
	}, [
		optionIndex,
		hasPerPersonGivenPrices,
		costPriceWithoutFlights,
		flightPriceInCostingCurrency,
		costPriceCurrency,
		gstIncluded,
		tax,
		totalMargin,
		costPriceGivenPriceCurrencyPairRatio,
		form,
		givenPriceCurrency,
		roundingPoint,
	])

	// auto update the given<>functional_pair using cost<>given_pair if cost currency is same as functional currency
	useEffect(() => {
		if (
			costPriceGivenPriceCurrencyPairRatio &&
			functionalCurrency === costPriceCurrency &&
			givenPriceCurrency !== functionalCurrency
		) {
			changeGivenPriceFunctionalCurrencyPairRatio(
				Number(1 / (Number(costPriceGivenPriceCurrencyPairRatio) || 1)).toFixed(
					7
				)
			)
		}
	}, [
		costPriceGivenPriceCurrencyPairRatio,
		costPriceCurrency,
		givenPriceCurrency,
		functionalCurrency,
		changeGivenPriceFunctionalCurrencyPairRatio,
	])

	return null
}

export function GivenPackagePriceDetailsInDialog({
	children,
	...props
}: {
	children: (props: { show: () => void }) => React.ReactNode
} & React.ComponentProps<typeof GivenPackagePriceDetails>) {
	const [isOpen, openDialog, closeDialog] = useDialog()
	return (
		<>
			{children({ show: openDialog })}
			<Dialog open={isOpen} onClose={closeDialog} title="Package Price Details">
				<Dialog.Body>
					<GivenPackagePriceDetails {...props} />
				</Dialog.Body>
				<Dialog.Footer>
					<Button onClick={closeDialog}>Close</Button>
				</Dialog.Footer>
			</Dialog>
		</>
	)
}

function GivenPackagePriceDetails({
	tripQuotes,
}: {
	tripQuotes: Array<ITripQuote>
}) {
	return (
		<Stack gap="6">
			{tripQuotes.map((tripQuote, index, arr) =>
				tripQuote.given_quote ? (
					<Stack key={tripQuote.id} gap="4" position="relative">
						{arr.length > 1 ? (
							<Box position="sticky" top="0" zIndex="10">
								<Box
									position="sticky"
									top="0"
									zIndex="10"
									bgColor="emphasis"
									color="on_emphasis"
									padding="2"
									rounded="md"
								>
									<Heading fontSize="md">
										Option {index + 1}: {tripQuote.name}
									</Heading>
								</Box>
							</Box>
						) : null}
						<Box maxWidth="full">
							<GivenPackageOptionPriceDetails
								tripQuote={tripQuote as Required<ITripQuote, "given_quote">}
							/>
						</Box>
					</Stack>
				) : null
			)}
		</Stack>
	)
}

function GivenPackageOptionPriceDetails({
	tripQuote,
}: {
	tripQuote: Required<ITripQuote, "given_quote">
}) {
	const totalCostPrice = tripQuote.cost_price
	const givenQuote = tripQuote.given_quote

	const perPersonCostPriceAllocation = tripQuote.cost_prices_per_person
	const currencyPairs = useMemo(
		() => givenQuote.currency_pairs || tripQuote.quote?.currency_pairs || [],
		[givenQuote, tripQuote]
	)
	const flightPrice = useMemo(() => {
		if (!tripQuote) return 0
		return Number(
			formatMoneyByDecimal(
				makeCurrencyConverter(currencyPairs)(
					tripQuote.currency,
					getTotalPriceForExtras(
						tripQuote.quote?.flights.map((f) => ({
							...f,
							price: f.given_price,
						}))
					)
				)
			)
		)
	}, [tripQuote, currencyPairs])
	const {
		currency: costPriceCurrency,
		given_prices_per_person,
		gst_included,
		comments,
		margins,
		given_price,
		given_currency,
	} = givenQuote
	const costPriceGivenPriceCurrencyPairIndex = useMemo(
		() =>
			currencyPairs.findIndex(
				(pair) =>
					pair.baseCurrency === costPriceCurrency &&
					pair.counterCurrency === given_currency
			),
		[currencyPairs, costPriceCurrency, given_currency]
	)

	const costPriceGivenPriceCurrencyPair = currencyPairs.at(
		costPriceGivenPriceCurrencyPairIndex
	)

	const costPriceWithoutFlights =
		Number(totalCostPrice) - Number(flightPrice || 0)
	const taxPercentage =
		isTruthy(givenQuote.gst_included) && givenQuote.tax_percentage
			? Number(givenQuote.tax_percentage)
			: 0
	return (
		<Stack>
			<Table bordered hover responsive>
				<thead>
					<tr>
						<TableHeaderDataCell></TableHeaderDataCell>
						<TableHeaderDataCell bgColor="inset" textAlign="right">
							Cost ({costPriceCurrency})
						</TableHeaderDataCell>
						<TableHeaderDataCell bgColor="inset">
							<Inline gap="1" alignItems="center" justifyContent="center">
								<Text>Markup</Text>
								{isTruthy(margins?.use_percentage) ? (
									<Text>({margins?.total_percentage}%)</Text>
								) : null}
							</Inline>
						</TableHeaderDataCell>
						<TableHeaderDataCell bgColor="inset">
							<Inline gap="1" alignItems="center" justifyContent="center">
								<Text>
									{String(costPriceCurrency).toLowerCase() === "inr"
										? "GST"
										: "Taxes"}
								</Text>
								{isTruthy(gst_included) ? (
									<Text>({taxPercentage ? `${taxPercentage}%` : "inc"})</Text>
								) : (
									<Text>(exc)</Text>
								)}
							</Inline>
						</TableHeaderDataCell>
						<TableHeaderDataCell bgColor="inset" textAlign="right">
							Total ({costPriceCurrency})
						</TableHeaderDataCell>
					</tr>
				</thead>
				{perPersonCostPriceAllocation?.length ? (
					<tbody>
						{perPersonCostPriceAllocation.map(
							({ id, per_pax_label, total_pax_label, price: costPrice }) => {
								if (!Number(costPrice)) {
									return null
								}
								const margin = margins?.per_person_allocation?.[id]
								const priceWithMargin = Number(costPrice) + Number(margin || 0)
								const tax = (priceWithMargin * Number(taxPercentage)) / 100
								const total = priceWithMargin + tax
								return (
									<tr key={`${id}-${total_pax_label}-${costPrice}`}>
										<TableDataCell bgColor="inset" fontWeight="semibold">
											<Stack>
												<Text>/{per_pax_label}</Text>
												<Text fontSize="sm" color="muted">
													{total_pax_label}
												</Text>
											</Stack>
										</TableDataCell>
										<TableDataCell textAlign="right">
											<Money
												currency={costPriceCurrency}
												amount={Number(costPrice)}
											/>
										</TableDataCell>
										<TableDataCell textAlign="center">
											{margin ? (
												<Stack alignItems="center">
													<Money currency={costPriceCurrency} amount={margin} />
												</Stack>
											) : (
												"-"
											)}
										</TableDataCell>
										<TableDataCell textAlign="center">
											<Text>
												{tax ? (
													<Money currency={costPriceCurrency} amount={tax} />
												) : (
													"-"
												)}
											</Text>
										</TableDataCell>
										<TableDataCell textAlign="right">
											<Text>
												<Money currency={costPriceCurrency} amount={total} />
											</Text>
										</TableDataCell>
									</tr>
								)
							}
						)}
					</tbody>
				) : null}
				<tbody>
					<tr>
						<TableDataCell bgColor="inset" fontWeight="semibold">
							Total
						</TableDataCell>
						<TableDataCell textAlign="right" fontWeight="semibold">
							<Money
								currency={costPriceCurrency}
								amount={costPriceWithoutFlights}
							/>
						</TableDataCell>
						<TableDataCell textAlign="center" fontWeight="semibold">
							{margins?.total ? (
								<Inline alignItems="center" justifyContent="center" gap="1">
									{!isTruthy(margins?.editing_total) &&
									perPersonCostPriceAllocation?.length ? (
										<Tooltip content="Total Markup from Per-Pax Allocation">
											<Box
												fontSize="sm"
												color="muted"
												fontWeight="normal"
												paddingX="1"
											>
												=
											</Box>
										</Tooltip>
									) : null}
									<Money currency={costPriceCurrency} amount={margins.total} />
								</Inline>
							) : (
								"-"
							)}
						</TableDataCell>
						<TableDataCell textAlign="center" fontWeight="semibold">
							{taxPercentage ? (
								<Money
									currency={costPriceCurrency}
									amount={
										((costPriceWithoutFlights + Number(margins?.total || 0)) *
											taxPercentage) /
										100
									}
								/>
							) : (
								"-"
							)}
						</TableDataCell>
						<TableDataCell textAlign="right" fontWeight="semibold">
							<Money
								currency={costPriceCurrency}
								amount={
									costPriceWithoutFlights +
									Number(margins?.total || 0) +
									((costPriceWithoutFlights + Number(margins?.total || 0)) *
										taxPercentage) /
										100
								}
							/>
						</TableDataCell>
					</tr>
				</tbody>
				{flightPrice ? (
					<tfoot>
						<tr>
							<TableFooterDataCell colSpan={5}>
								<Inline gap="4" justifyContent="end" alignItems="center">
									<Box>
										<Icons.Airplane /> Flights:
									</Box>
									<Box>
										<Money currency={costPriceCurrency} amount={flightPrice} />
									</Box>
								</Inline>
							</TableFooterDataCell>
						</tr>
					</tfoot>
				) : null}
			</Table>
			<Stack
				borderLeftWidth="4"
				borderColor="success"
				marginY="4"
				bgColor="success"
				padding="4"
				gap="4"
			>
				{margins?.rounding_point ? (
					<Inline
						gap="1"
						alignItems="center"
						fontWeight="semibold"
						color="accent"
					>
						<Text>Rounding:</Text>
						<Text>{margins?.rounding_point}</Text>
					</Inline>
				) : null}
				<Inline flexWrap="wrap" gap="12">
					<Inline gap="12" flexWrap="wrap">
						<Stack gap="1">
							<Inline gap="4">
								<Text fontWeight="semibold">Quote/Selling Price</Text>
							</Inline>
							<Inline gap="2">
								<Text fontWeight="semibold" fontSize="lg">
									{given_currency}
								</Text>
								<Text fontSize="3xl" fontWeight="semibold">
									<Money currency={given_currency} amount={given_price} />
								</Text>
							</Inline>
							{costPriceCurrency !== given_currency ? (
								<Box>
									{costPriceGivenPriceCurrencyPair
										? currencyPairToHuman(costPriceGivenPriceCurrencyPair)
										: null}
								</Box>
							) : null}
						</Stack>
					</Inline>
					<Stack gap="1">
						<Text fontWeight="semibold">
							{String(given_currency).toLowerCase() === "inr" ? "GST" : "Taxes"}
						</Text>
						<Text fontWeight="semibold">
							{isTruthy(gst_included)
								? `Included${taxPercentage ? ` (${taxPercentage}%)` : ``}`
								: "Excluded"}{" "}
						</Text>
					</Stack>
				</Inline>
				{given_prices_per_person?.length ? (
					<Box>
						<table>
							<tbody>
								{given_prices_per_person.map(
									({ id, price, currency, per_pax_label, total_pax_label }) => (
										<tr key={`${id}-${total_pax_label}-${price}`}>
											<Box as="td" textAlign="right">
												<Money currency={currency} amount={Number(price)} />
											</Box>
											<td>
												&nbsp;/&nbsp;{per_pax_label} x {total_pax_label}
											</td>
										</tr>
									)
								)}
							</tbody>
						</table>
					</Box>
				) : null}
			</Stack>
			{comments ? (
				<Stack gap="1">
					<Text fontSize="sm" fontWeight="semibold" color="muted">
						Comments
					</Text>
					<blockquote>{comments}</blockquote>
				</Stack>
			) : null}
		</Stack>
	)
}
