import {
	Box,
	Button,
	Icons,
	Popover,
	Table,
	Inline,
	Tooltip,
	Heading,
	Stack,
	Component,
	joinAttributes,
	RelativeTime,
	TabContent,
	TabItem,
	Tabs,
	TabsList,
	Time,
	TimeDuration,
	Text,
	Badge,
	Money,
} from "@sembark-travel/ui/base"
import { Dialog } from "@sembark-travel/ui/dialog"
import { ButtonLink, Link, useLocationQuery } from "@sembark-travel/ui/router"
import {
	ListView,
	TSortOptions,
	areAdvancedFiltersAppliedDefault,
	Search,
	useSearch,
	TSearchParams,
} from "@sembark-travel/ui/list"
import {
	dateToQuery,
	dateToUTCString,
	endOf,
	parseDateFromQuery,
	startOf,
	utcTimestampToLocalDate,
} from "@sembark-travel/datetime-utils"
import { Fragment, useEffect } from "react"
import { PERMISSIONS, useCheckPermissions } from "../Auth"
import { Email, PhoneNumber } from "../Contacts"
import { generatePath } from "../router-utils"
import { EditTripOwners } from "../TripOwners"
import { EditScheduleInDialog } from "./EditItem"
import { ScheduleDetailsInDialog } from "./Item"
import { ShareSchedules } from "./ShareSchedules"
import { ITripCabSchedule } from "./store"
import { ILocation } from "../Locations"
import { ITransportServiceProvider } from "../TransportServiceProviders"
import { TTransportServiceLocationPoint } from "../TransportServices"
import { TTripDestination } from "../TripDestinations"
import { IUser } from "../Users"
import { ICabType } from "../CabTypes"
import { DatePickerField, SelectField } from "@sembark-travel/ui/form"
import { SelectCabTypes } from "../CabTypes"
import { SelectUsers } from "../Users"
import { SelectTripDestination } from "../TripDestinations"
import { SelectTransportServiceLocationPoints } from "../TransportServices"
import { SelectTransporServiceProviders } from "../TransportServiceProviders"
import { isNumeric } from "@sembark-travel/number-utils"
import { useTripQuoteBookingsDiffCount } from "../TripQuoteBookingsDiff"

const sortOptions: TSortOptions = [
	{
		label: "Start Date",
		name: "start_date",
		type: "date",
		order: "asc",
	},
]

type TFilters = TSearchParams & {
	transport_locations?: ILocation[]
	transport_service_providers?: Array<ITransportServiceProvider>
	transport_service_location_points?: Array<TTransportServiceLocationPoint>
	trip_destinations?: Array<TTripDestination>
	cab_types?: Array<ICabType>
	hide_past_trips?: boolean
	owners?: Array<IUser>
	status?: string
	start_date?: Date
	end_date?: Date
	sort?: string
}

type TFiltersInLocationQuery = TSearchParams & {
	tls?: string[]
	tsps?: Array<string>
	tslp?: Array<string>
	ct?: Array<string>
	tds?: Array<string>
	hpt?: 0 | 1
	status?: string
	owners?: string[]
	sd?: string
	ed?: string
	sort?: string
}

function paramsToLocationQuery(params: TFilters): TFiltersInLocationQuery {
	const {
		q,
		transport_locations,
		transport_service_providers,
		transport_service_location_points,
		trip_destinations,
		cab_types,
		hide_past_trips,
		status,
		owners,
		start_date,
		end_date,
		page,
		sort,
	} = params
	const filters: TFiltersInLocationQuery = {}
	if (q) filters.q = q
	if (page) filters.page = page
	if (transport_locations && transport_locations.length)
		filters.tls = transport_locations.map((t) => `${t.id}_${t.name}`)
	if (transport_service_providers && transport_service_providers.length)
		filters.tsps = transport_service_providers.map((t) => `${t.id}_${t.name}`)
	if (
		transport_service_location_points &&
		transport_service_location_points.length
	)
		filters.tslp = transport_service_location_points.map(
			(t) => `${t.id}_${t.name}`
		)
	if (cab_types && cab_types.length)
		filters.ct = cab_types.map((t) => `${t.id}_${t.name}`)
	if (trip_destinations && trip_destinations.length)
		filters.ct = trip_destinations.map((t) => `${t.id}_${t.name}`)
	if (hide_past_trips) {
		filters.hpt = 1
	}
	if (owners && owners.length) {
		filters.owners = owners.map((t) => `${t.id}_${t.name}`)
	}
	if (start_date) {
		filters.sd = dateToQuery(start_date)
	}
	if (end_date) {
		filters.sd = dateToQuery(end_date)
	}
	if (status) filters.status = status
	if (sort) filters.sort = sort

	return filters
}

function locationQueryToParams(query: TFiltersInLocationQuery): TFilters {
	const {
		q,
		tls,
		hpt,
		tsps,
		tslp,
		ct,
		tds,
		status,
		owners,
		sd,
		ed,
		page,
		sort,
	} = query
	const filters: TFilters = {}
	if (q) filters.q = q
	if (page) filters.page = page
	if (sd) filters.start_date = parseDateFromQuery(sd)
	if (ed) filters.end_date = parseDateFromQuery(ed)
	if (tls && tls.length)
		filters.transport_locations = tls.map((t) => {
			const [id, ...names] = t.split("_")
			return {
				id: parseInt(id),
				name: names.join("_"),
			} as unknown as ILocation
		})
	if (tsps && tsps.length)
		filters.transport_service_providers = tsps.map((t) => {
			const [id, ...names] = t.split("_")
			return {
				id: parseInt(id),
				name: names.join("_"),
			} as unknown as ITransportServiceProvider
		})
	if (tslp && tslp.length)
		filters.transport_service_location_points = tslp.map((t) => {
			const [id, ...names] = t.split("_")
			return {
				id: parseInt(id),
				name: names.join("_"),
			} as unknown as TTransportServiceLocationPoint
		})
	if (ct && ct.length)
		filters.cab_types = ct.map((t) => {
			const [id, ...names] = t.split("_")
			return {
				id: parseInt(id),
				name: names.join("_"),
			} as unknown as ICabType
		})
	if (tds && tds.length)
		filters.trip_destinations = tds.map((t) => {
			const [id, ...names] = t.split("_")
			return {
				id: parseInt(id),
				name: names.join("_"),
			} as unknown as TTripDestination
		})
	if (owners && owners.length)
		filters.owners = owners.map((t) => {
			const [id, ...names] = t.split("_")
			return {
				id: parseInt(id),
				name: names.join("_"),
			} as unknown as IUser
		})
	if (hpt && Number(hpt) === 1) {
		filters.hide_past_trips = true
	}
	if (status) {
		filters.status = status
	}
	if (sort) filters.sort = sort
	return filters
}

export function SchedulesListView() {
	const [query, setQueryParams] = useLocationQuery<
		TFilters,
		TFiltersInLocationQuery
	>({
		toQuery: paramsToLocationQuery,
		fromQuery: locationQueryToParams,
	})
	const [params, setParams] = useSearch({
		...query,
		status: query.status || "new",
	})
	const { hasPermission } = useCheckPermissions()
	useEffect(() => {
		setQueryParams(params)
	}, [params, setQueryParams])
	const tripQuoteBookingsDiffCount = useTripQuoteBookingsDiffCount({
		cabs_changed: true,
	})
	return (
		<Box>
			<Search
				title="Cab Schedules"
				initialParams={params}
				onSearch={(newParams) => setParams({ ...newParams, page: 1 })}
				areAdvancedFiltersApplied={(params) => {
					const { status, ...otherFilters } = params
					return areAdvancedFiltersAppliedDefault(otherFilters)
				}}
				Filters={Filters}
				actions={
					<Inline gap="2" alignItems="center">
						<ButtonLink
							to={generatePath("/cab-schedules/calendar")}
							title="Calendar View"
							level="primary"
						>
							<Icons.Calendar /> Calendar
						</ButtonLink>
						{tripQuoteBookingsDiffCount ? (
							<ButtonLink
								to={generatePath("/trip-quote-bookings-diff")}
								status="warning"
								title="Trip Quote & Cab Bookings Diff"
							>
								<Inline alignItems="center" as="span" gap="1">
									<Box as="span">
										<Icons.Attention />
									</Box>
									<Text as="span">{tripQuoteBookingsDiffCount}</Text>
								</Inline>
							</ButtonLink>
						) : null}
					</Inline>
				}
			>
				{({ searchParams, setSearchParamValue }) => (
					<Tabs>
						<TabsList>
							<TabItem
								onClick={() => {
									setSearchParamValue("status", "new")
								}}
								active={!searchParams.status || searchParams.status === "new"}
							>
								New
							</TabItem>
							<TabItem
								onClick={() => {
									setSearchParamValue("status", "in_progress")
								}}
								active={searchParams.status === "in_progress"}
							>
								In Progress
							</TabItem>
							<TabItem
								onClick={() => {
									setSearchParamValue("status", "booked")
									setSearchParamValue("sort", undefined)
								}}
								active={searchParams.status === "booked"}
							>
								Booked
							</TabItem>
							<TabItem
								onClick={() => {
									setSearchParamValue("status", "on_trip")
									setSearchParamValue("sort", undefined)
								}}
								active={searchParams.status === "on_trip"}
							>
								On Trip
							</TabItem>
							<TabItem
								onClick={() => {
									setSearchParamValue("status", "past")
									setSearchParamValue("sort", undefined)
								}}
								active={searchParams.status === "past"}
							>
								Past
							</TabItem>
							<TabItem
								onClick={() => {
									setSearchParamValue("status", "dropped")
									setSearchParamValue("sort", undefined)
								}}
								active={searchParams.status === "dropped"}
							>
								Dropped
							</TabItem>
							<TabItem
								onClick={() => {
									setSearchParamValue("status", "all")
									setSearchParamValue("sort", undefined)
								}}
								active={searchParams.status === "all"}
							>
								All
							</TabItem>
						</TabsList>
						<TabContent>
							<ListView<ITripCabSchedule, TFilters>
								pageKey="/trip-cab-schedules"
								params={params}
								sortOptions={
									searchParams.status === "new" ||
									searchParams.status === "in_progress"
										? sortOptions
										: undefined
								}
								onPageChange={(page, sort) =>
									setParams({ ...params, page, sort })
								}
								fetch={async (xhr, params) => {
									const {
										q,
										transport_locations,
										transport_service_providers,
										transport_service_location_points,
										cab_types,
										status,
										trip_destinations,
										owners,
										start_date,
										end_date,
										...others
									} = params
									return xhr
										.get("/trip-cab-schedules", {
											params: {
												...others,
												q: q ? q.trim() : null,
												transport_locations:
													transport_locations && transport_locations.length
														? transport_locations.map((t) => t.name)
														: [],
												transport_service_providers:
													transport_service_providers?.length
														? transport_service_providers.map((t) => t.id)
														: [],
												transport_service_location_points:
													transport_service_location_points?.length
														? transport_service_location_points.map((t) => t.id)
														: [],
												cab_types: cab_types?.length
													? cab_types.map((t) => t.id)
													: [],
												trip_destinations: trip_destinations?.length
													? trip_destinations.map((t) => t.id)
													: [],
												owners: owners?.length ? owners.map((o) => o.id) : [],
												status: status,
												start_date: start_date
													? dateToUTCString(startOf(start_date, "day"))
													: null,
												end_date: end_date
													? dateToUTCString(endOf(end_date, "day"))
													: null,
											},
										})
										.then((resp) => resp.data)
								}}
							>
								{({ items: trips, refresh }) => (
									<Table className="excel-style-table" responsive>
										<thead>
											<tr>
												<th>Basic Details</th>
												<th>Service</th>
												<th>Date</th>
												<th>Cab</th>
												<th>Supplier</th>
												<th>Price</th>
											</tr>
										</thead>
										<tbody>
											{trips.map((trip) => {
												const {
													id: tripId,
													trip_source,
													reference_id,
													destinations,
													no_of_adults,
													children,
													tourist,
													days,
													converted_at,
													converted_by,
													on_hold_at,
													on_hold_by,
													total_payments_amount,
													total_payments_amount_currency,
													schedules,
													cancellation_reason,
												} = trip
												const start_date = utcTimestampToLocalDate(
													trip.start_date
												)
												// + 1 for overview row
												const bookingRowsCount =
													schedules.reduce(
														(cabs, schedule) => cabs + schedule.cabs.length,
														0
													) + 1
												return (
													<Fragment key={`trip_${tripId}`}>
														<Box as="tr">
															<Box as="td" rowSpan={bookingRowsCount}>
																<Stack
																	maxWidth="xs"
																	gap="1"
																	style={{ minWidth: "200px" }}
																>
																	<Heading as="h4" fontSize="md">
																		<Link
																			to={generatePath(
																				"/trips/:tripId/services-bookings/:serviceType",
																				{
																					tripId: String(tripId),
																					serviceType: "cabs",
																				}
																			)}
																			anchored
																			color="accent"
																		>
																			{tourist
																				? tourist.name
																				: "Guest Details Pending"}{" "}
																			•{" "}
																			<Box display="inline" as="span">
																				{destinations
																					.map((l) => l.short_name)
																					.join(", ")}
																			</Box>
																		</Link>
																	</Heading>
																	<Box fontSize="sm">
																		{joinAttributes(
																			<Box display="inline">
																				<Box
																					display="inline"
																					marginRight="1"
																					color="muted"
																				>
																					#
																				</Box>
																				{tripId}
																			</Box>,
																			<Box
																				display="inline"
																				title={trip_source.name}
																			>
																				{trip_source.short_name}
																				{reference_id ? `-${reference_id}` : ``}
																			</Box>,
																			<Money
																				amount={total_payments_amount}
																				currency={
																					total_payments_amount_currency
																				}
																				showCurrency
																			/>
																		)}
																	</Box>
																	<Box>
																		{joinAttributes(
																			<Time value={start_date} />,
																			<TimeDuration days={days}>
																				{days}D
																			</TimeDuration>,
																			`${no_of_adults}A${
																				children.length
																					? `, ${children.length}C`
																					: ``
																			}`
																		)}
																	</Box>
																	{converted_at && converted_by ? (
																		<Box fontSize="sm" color="muted">
																			<Icons.ChevronDown
																				rotate="270"
																				title="Conversion"
																			/>{" "}
																			<RelativeTime
																				value={utcTimestampToLocalDate(
																					converted_at
																				)}
																			/>{" "}
																			by {converted_by.name}
																		</Box>
																	) : on_hold_at && on_hold_by ? (
																		<Box fontSize="sm" color="muted">
																			<Badge>On-Hold Trip</Badge>{" "}
																			<RelativeTime
																				value={utcTimestampToLocalDate(
																					on_hold_at
																				)}
																			/>{" "}
																			by {on_hold_by.name}
																		</Box>
																	) : (
																		<Box>
																			<Badge>On-Hold</Badge>
																		</Box>
																	)}
																	<Inline
																		marginTop="4"
																		gap="2"
																		alignItems="center"
																	>
																		<Box>
																			<Icons.Users title="Operations Team" />
																		</Box>
																		<Box fontSize="sm">
																			{trip.operations_team?.length ? (
																				<Text>
																					{trip.operations_team
																						.map((user) => user.name)
																						.join(", ")}
																				</Text>
																			) : (
																				<Text color="muted">Not Set</Text>
																			)}
																		</Box>
																		{hasPermission(
																			PERMISSIONS.MANAGE_TRIP_OWNERS
																		) ? (
																			<Component initialState={false}>
																				{({
																					state: isEditing,
																					setState: setIsEditing,
																				}) => (
																					<>
																						{!isEditing ? (
																							<Button
																								title="Edit operation teams"
																								inline
																								onClick={() => {
																									setIsEditing(true)
																								}}
																							>
																								<Icons.Pencil />
																							</Button>
																						) : null}
																						<Dialog
																							title="Edit Operations Team"
																							open={isEditing}
																							onClose={() =>
																								setIsEditing(false)
																							}
																							sm
																						>
																							<Dialog.Body>
																								<EditTripOwners
																									type="operations_team"
																									users={trip.operations_team}
																									itemId={trip.id}
																									onSuccess={() => {
																										setIsEditing(false)
																										refresh()
																									}}
																									onCancel={() => {
																										setIsEditing(false)
																									}}
																								/>
																							</Dialog.Body>
																						</Dialog>
																					</>
																				)}
																			</Component>
																		) : null}
																	</Inline>
																</Stack>
															</Box>
															<Box as="td" colSpan={5} bgColor="subtle">
																<Inline justifyContent="between">
																	<Box paddingY="1" fontWeight="semibold">
																		{joinAttributes(
																			<Box as="span">
																				<Box
																					as="span"
																					fontWeight="semibold"
																					fontSize="lg"
																				>
																					{
																						schedules.filter(
																							(b) => b.cabs_booked
																						).length
																					}
																				</Box>
																				<Box as="span" marginLeft="1">
																					/ {schedules.length} Booked
																				</Box>
																			</Box>
																		)}
																	</Box>
																</Inline>
															</Box>
														</Box>
														{schedules.map((schedule) => (
															<Fragment key={schedule.id}>
																{schedule.cabs.map(
																	(scheduledCab, index, allScheduleCabs) => {
																		const {
																			id,
																			cab_type,
																			driver,
																			transport_service_provider,
																			cab,
																			booked,
																			currency,
																			booked_price,
																		} = scheduledCab
																		return (
																			<Box as="tr" key={id}>
																				{index === 0 ? (
																					<>
																						<Box
																							as="td"
																							rowSpan={allScheduleCabs.length}
																						>
																							<Box
																								display="flex"
																								justifyContent="between"
																								alignItems="center"
																							>
																								<ScheduleDetailsInDialog
																									onRefresh={refresh}
																									schedule={schedule}
																								>
																									{({ onShow }) => (
																										<Box
																											onClick={onShow}
																											fontWeight="semibold"
																											as="a"
																											href={`#/${schedule.id}`}
																										>
																											{
																												schedule
																													.transport_service
																													.name
																											}
																										</Box>
																									)}
																								</ScheduleDetailsInDialog>
																								{!cancellation_reason ? (
																									<Inline gap="4">
																										<ShareSchedules
																											schedules={[schedule]}
																										>
																											{({ share }) => (
																												<Button
																													onClick={share}
																													title="Share Schedule Details with Traveler"
																													inline
																												>
																													<Icons.Share />
																												</Button>
																											)}
																										</ShareSchedules>
																										{schedule.can_modify ? (
																											<EditScheduleInDialog
																												scheduleId={schedule.id}
																												onSuccess={refresh}
																											>
																												{({ onEdit }) => (
																													<Button
																														onClick={() =>
																															onEdit()
																														}
																														inline
																													>
																														<Icons.Pencil />
																													</Button>
																												)}
																											</EditScheduleInDialog>
																										) : null}
																									</Inline>
																								) : null}
																							</Box>
																						</Box>
																						<Box
																							as="td"
																							rowSpan={allScheduleCabs.length}
																						>
																							<Time
																								value={utcTimestampToLocalDate(
																									schedule.start_date
																								)}
																								format="D MMM"
																								whiteSpace="preserve"
																							/>
																						</Box>
																					</>
																				) : null}
																				<Box as="td">
																					<Inline gap="1">
																						{cab?.name ? (
																							<Tooltip
																								content={cab.number_plate}
																							>
																								{cab.name}
																							</Tooltip>
																						) : (
																							<Text>
																								{cab_type.name}
																								{cab_type.deleted_at ? (
																									<Icons.Ban
																										color="warning"
																										title="Disabled"
																									/>
																								) : null}
																							</Text>
																						)}
																						{booked ? (
																							<Box display="inlineBlock">
																								<Icons.OkCircleSolid
																									color="success"
																									title="Cab Booked"
																								/>
																							</Box>
																						) : null}
																					</Inline>
																				</Box>
																				<Box as="td">
																					<Box
																						display="flex"
																						justifyContent="between"
																					>
																						<Box>
																							{transport_service_provider ? (
																								<Box display="flex">
																									<Box>
																										<Popover
																											placement="bottom-end"
																											interactive
																											trigger="click"
																											content={
																												<Box
																													style={{
																														width: "256px",
																													}}
																												>
																													<Box
																														paddingX="4"
																														paddingY="3"
																														bgColor="subtle"
																														roundedTop="lg"
																														fontWeight="semibold"
																													>
																														Supplier Details
																													</Box>
																													<Box
																														paddingX="4"
																														paddingY="3"
																													>
																														<Box
																															fontWeight="semibold"
																															marginBottom="3"
																															fontSize="lg"
																														>
																															{
																																transport_service_provider.name
																															}
																														</Box>
																														{driver ? (
																															<Box>
																																<Box
																																	fontWeight="semibold"
																																	color="muted"
																																	textTransform="uppercase"
																																	fontSize="sm"
																																	letterSpacing="wide"
																																	marginBottom="1"
																																>
																																	Driver
																																</Box>
																																<Box display="flex">
																																	<Box
																																		width="10"
																																		height="10"
																																		bgColor="inset"
																																		display="flex"
																																		justifyContent="center"
																																		alignItems="center"
																																		rounded="full"
																																		marginRight="2"
																																		borderWidth="1"
																																	>
																																		<Icons.Bus />
																																	</Box>
																																	<Box
																																		flex="1"
																																		minWidth="0"
																																	>
																																		<Box fontWeight="semibold">
																																			{
																																				driver.name
																																			}
																																		</Box>
																																		{joinAttributes(
																																			driver.phone_number ? (
																																				<PhoneNumber
																																					value={
																																						driver.phone_number
																																					}
																																					numberOnly
																																				/>
																																			) : null,
																																			driver.email ? (
																																				<Email
																																					value={
																																						driver.email
																																					}
																																					emailOnly
																																				/>
																																			) : null
																																		)}
																																	</Box>
																																</Box>
																															</Box>
																														) : null}
																													</Box>
																												</Box>
																											}
																										>
																											<Button
																												title="View Details"
																												inline
																											>
																												<Box
																													style={{
																														maxWidth: "120px",
																													}}
																													textOverflow="truncate"
																													title={
																														transport_service_provider.name
																													}
																												>
																													{
																														transport_service_provider.name
																													}
																												</Box>
																											</Button>
																										</Popover>
																									</Box>
																								</Box>
																							) : null}
																						</Box>
																					</Box>
																				</Box>
																				<Box as="td">
																					{isNumeric(booked_price) ? (
																						<Money
																							amount={booked_price || 0}
																							currency={currency}
																						/>
																					) : null}
																				</Box>
																			</Box>
																		)
																	}
																)}
															</Fragment>
														))}
													</Fragment>
												)
											})}
										</tbody>
									</Table>
								)}
							</ListView>
						</TabContent>
					</Tabs>
				)}
			</Search>
		</Box>
	)
}

function Filters() {
	return (
		<Stack gap="4">
			<Stack>
				<label htmlFor="start_date">During</label>
				<Box display="flex" alignItems="center">
					<DatePickerField name="start_date" dateFormat="DD MMM, YYYY" />
					<Box>
						<Icons.SwitchHorizontal />
					</Box>
					<DatePickerField
						name="end_date"
						rightAlign
						dateFormat="DD MMM, YYYY"
					/>
				</Box>
			</Stack>
			<SelectField
				select={SelectTripDestination}
				name="trip_destinations"
				multiple
				label="Trip Destinations"
				fetchOnMount
			/>
			<SelectField
				select={SelectTransporServiceProviders}
				name="transport_service_providers"
				multiple
				label="Suppliers"
				fetchOnMount
			/>
			<SelectField
				select={SelectTransportServiceLocationPoints}
				name="transport_service_location_points"
				label="Service Cities"
				multiple
			/>
			<SelectField
				select={SelectCabTypes}
				name="cab_types"
				label="Cab Types"
				multiple
				fetchOnMount
			/>
			<SelectField
				label="Team"
				select={SelectUsers}
				name="owners"
				multiple
				fetchOnMount
			/>
		</Stack>
	)
}
