import {
	Badge,
	Box,
	contains,
	Icons,
	Input,
	Inline,
	ownerDocument,
	Money,
	Text,
	joinAttributes,
	Time,
	TimeDuration,
	Ping,
	Stack,
	Table,
} from "@sembark-travel/ui/base"
import { Link } from "react-router-dom"
import { useXHR, isAbortError, extendXHRInstance } from "@sembark-travel/xhr"
import { useCallback, useEffect, useRef, useState } from "react"
import { tripListXHR, ITrip } from "./../Trips"
import { generatePath } from "../router-utils"
import { duration } from "@sembark-travel/datetime-utils"

const escapeKeyCode = 27

export default function GlobalSearch() {
	const [showDropdown, setDropdownVisibility] = useState(false)
	const containerRef = useRef<HTMLDivElement>(null)
	const [query, setQuery] = useState<string>("")
	const fetchCancelToken = useRef<NodeJS.Timeout>()
	const [trips, setTrips] = useState<ITrip[]>([])
	const [isFetching, setIsFetching] = useState(false)
	const xhr = useXHR()
	const abortController = useRef<AbortController>()
	useEffect(() => {
		setIsFetching(true)
		// fetch the data for various things for this query
		if (query && query.trim()) {
			fetchCancelToken.current = setTimeout(() => {
				abortController.current = new AbortController()
				const api = extendXHRInstance(xhr, {
					signal: abortController.current.signal,
				})
				// fetch the data
				tripListXHR(api)
					.getTrips({ q: query, limit: 4, pagination: "simple" })
					.then(({ data }) => {
						setTrips(data)
						setIsFetching(false)
					})
					.catch((error) => {
						if (!isAbortError(error)) setIsFetching(false)
					})
			}, 1000)
		}
		return () => {
			abortController.current?.abort()
			fetchCancelToken.current && clearTimeout(fetchCancelToken.current)
			setIsFetching(false)
		}
	}, [query, xhr])
	// handle the on query change
	const handleChange = useCallback(
		(q: string) => {
			if (!showDropdown) {
				setDropdownVisibility(true)
			}
			setQuery(q)
		},
		[setDropdownVisibility, showDropdown]
	)
	// manage the dropdown visibility
	useEffect(() => {
		const document = ownerDocument()
		const onClick = (e: MouseEvent) => {
			if (
				containerRef.current &&
				!contains(containerRef.current, e.target as unknown as HTMLElement)
			) {
				setDropdownVisibility(false)
			}
		}
		const onKeyUp = (e: KeyboardEvent) => {
			if (e.keyCode === escapeKeyCode) {
				setDropdownVisibility(false)
			} else {
				if (
					containerRef.current &&
					!contains(containerRef.current, e.target as unknown as HTMLElement)
				) {
					setDropdownVisibility(false)
				}
			}
		}
		if (document) {
			document.addEventListener("click", onClick)
			document.addEventListener("keyup", onKeyUp)
		}
		return () => {
			if (document) {
				document.removeEventListener("click", onClick)
				document.removeEventListener("keyup", onKeyUp)
			}
		}
	}, [containerRef, setDropdownVisibility])
	return (
		<Box width="full" position="relative" ref={containerRef}>
			<form
				role="search"
				action="#"
				aria-label="Search across trips, hotels, contacts etc."
				onSubmit={(e) => {
					e.preventDefault()
				}}
				data-color-theme="light"
			>
				<Inline
					width="full"
					rounded="md"
					style={{ background: "rgba(255,255,255,.2)" }}
					position="relative"
				>
					<Box
						color="muted"
						display="flex"
						alignItems="center"
						position="absolute"
						width="10"
						height="full"
						left="0"
						justifyContent="center"
					>
						<Icons.Search />
					</Box>
					<Input
						flex="1"
						name="q"
						placeholder="Search for trips..."
						type="search"
						size="sm"
						bgColor="transparent"
						borderColor="transparent"
						paddingLeft="10"
						autoComplete="off"
						style={{ maxWidth: "100%" }}
						onFocus={() => {
							setDropdownVisibility(true)
						}}
						value={query}
						onChange={(e) => {
							handleChange(e.currentTarget.value)
						}}
					/>
				</Inline>
			</form>
			{showDropdown && query && query.trim() ? (
				<Box
					position="absolute"
					width="full"
					bgColor="default"
					color="default"
					borderWidth="1"
					roundedBottom="md"
					overflow="auto"
					zIndex="50"
					style={{
						top: "100%",
						maxHeight: "70vh",
					}}
				>
					{trips.length ? (
						<Box as="ul">
							{trips.length ? (
								<Box as="li" padding="2">
									<Inline justifyContent="between">
										<Box
											color="muted"
											fontWeight="semibold"
											paddingBottom="1"
											paddingX="3"
										>
											Trips
										</Box>
										{isFetching ? <Icons.Refresh spin /> : null}
									</Inline>
									<Table
										bordered
										hover
										rows={trips.map((trip) => {
											const {
												id,
												destinations,
												start_date,
												nights: no_of_nights,
												days,
												latest_given_quote,
												converted_at,
												is_live,
												on_hold_at,
												is_end_date_passed,
												no_of_adults,
												no_of_children,
												cancellation_reason,
											} = trip
											return [
												<Stack
													as={Link}
													to={generatePath(`/trips/:tripId`, {
														tripId: String(id),
													})}
													onClick={() => {
														setDropdownVisibility(false)
														setQuery("")
													}}
													color="default"
												>
													<Inline gap="1">
														<Text color="accent" fontWeight="semibold">
															#{id}
														</Text>
														<Destinations destinations={destinations} />
													</Inline>
													<Box fontSize="sm">
														{joinAttributes(
															<Time format="DD MMM" timestamp={start_date} />,
															<TimeDuration value={duration(days, "days")}>
																{no_of_nights}N
															</TimeDuration>,
															<Text as="span">
																{no_of_adults}A
																{no_of_children ? `, ${no_of_children}C` : null}
															</Text>
														)}
													</Box>
												</Stack>,
												<TripTouristAndSource trip={trip} />,
												<Box>
													{latest_given_quote ? (
														<Box>
															<Money
																showCurrency
																amount={latest_given_quote.given_price}
																currency={latest_given_quote.given_currency}
															/>
														</Box>
													) : null}
													{cancellation_reason ? (
														<Badge warning outlined>
															Canceled
														</Badge>
													) : converted_at ? (
														is_live ? (
															<Badge accent>
																<Ping title="On Trip" /> On Trip
															</Badge>
														) : is_end_date_passed ? (
															<Badge>Past Trip</Badge>
														) : (
															<Badge success>Converted</Badge>
														)
													) : on_hold_at ? (
														<Badge warning>On-Hold</Badge>
													) : latest_given_quote ? (
														<Badge>In Progress</Badge>
													) : (
														<Badge>New Query</Badge>
													)}
												</Box>,
											]
										})}
									/>
								</Box>
							) : null}
						</Box>
					) : isFetching ? (
						<Box padding="4" textAlign="center">
							<Icons.Refresh spin />
						</Box>
					) : (
						<Box padding="4" textAlign="center">
							No results found
						</Box>
					)}
				</Box>
			) : null}
		</Box>
	)
}

function Destinations({
	destinations,
}: {
	destinations: ITrip["destinations"]
}) {
	return (
		<Text style={{ maxWidth: "140px" }} textOverflow="truncate">
			{destinations.map((d) => d.short_name).join(", ")}
		</Text>
	)
}

function TripTouristAndSource({ trip }: { trip: ITrip }) {
	const { tourist, trip_source, trip_source_contact } = trip
	return (
		<Stack gap="px">
			{tourist ? (
				<Box>{tourist.name}</Box>
			) : (
				<Text>{trip_source_contact?.name}</Text>
			)}
			<Text fontSize="sm">
				{joinAttributes(
					trip_source.short_name,
					tourist ? trip_source_contact?.name : null
				)}
			</Text>
		</Stack>
	)
}
