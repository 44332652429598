import {
	Box,
	Button,
	useId,
	Text,
	Stack,
	Divider,
	useOnce,
} from "@sembark-travel/ui/base"
import { refreshCSRFToken, useXHR, XHRInstance } from "@sembark-travel/xhr"
import { useCallback, useEffect, useRef } from "react"
import { Helmet } from "react-helmet-async"
import useSWR from "swr"
import * as Validator from "yup"
import config from "../config"
import {
	Form,
	withServerErrors,
	validateFormValues,
	SubmissionError,
	TextInputField,
	PhoneInputField,
	PhoneNumberValidator,
	TPhoneNumberField,
} from "@sembark-travel/ui/form"

// schemas
export interface IRegisterationCredentials {
	company_name: string
	name: string
	email: string
	password: string
	phone_number: TPhoneNumberField
}

const registrationCredentialsSchema = Validator.object().shape({
	company_name: Validator.string()
		.required("Please provide your company name.")
		.max(191, "Name can contain maximum 191 characters."),
	name: Validator.string()
		.required("Please provide your full name.")
		.max(191, "Name can contain maximum 191 characters."),
	email: Validator.string()
		.email("Please enter a valid email address.")
		.required("Please enter a valid email address."),
	password: Validator.string()
		.min(8, "Password should be atleast 8 characters long")
		.required("Please enter a password"),
	phone_number: PhoneNumberValidator().required(
		"Please entry the phone number"
	),
})

const initialValues: IRegisterationCredentials = {
	company_name: "",
	name: "",
	email: "",
	password: "",
	phone_number: {
		country_code: "IN",
		phone_number: "",
	},
}

// actions
function XHR(xhr: XHRInstance) {
	return {
		async getRegistrationPrerequisite(): Promise<{
			site_key: string
			form_id: string
			captcha_field_name: string
		}> {
			return xhr.get(`/register`).then((resp) => resp.data.data)
		},
		async register(
			data: IRegisterationCredentials & {
				[key: string]: unknown
			}
		): Promise<unknown> {
			// always refresh the token before login
			await refreshCSRFToken()
			return xhr.post(`/register`, data).then(() => data)
		},
	}
}

export function RegisterationForm({
	email,
	onSuccess,
}: {
	email?: string
	onSuccess?: () => void
}) {
	initialValues.email = email || ""
	const id = useId()
	const xhr = useXHR()
	const { data } = useSWR(
		`registration-prerequisite?${id}`,
		() => XHR(xhr).getRegistrationPrerequisite(),
		{
			isPaused: () => false,
			revalidateOnFocus: false,
		}
	)
	const site_key = data?.site_key
	const { validate, js } = useCaptcha({
		siteKey: site_key,
		containerId: "captcha_holder",
	})
	useOnce(() => {
		window.location.href =
			"https://sembark.com/request-demo?utm_source=sembark_app&utm_medium=web&utm_campaign=register"
	})
	// FOR NOW: always return null
	if (Math.random() < 0) return null
	return (
		<Box>
			{site_key ? <>{js}</> : null}
			<Form<typeof initialValues>
				initialValues={initialValues}
				validateOnBlur={false}
				validate={validateFormValues(registrationCredentialsSchema)}
				onSubmit={withServerErrors(async (values) => {
					if (!data)
						return Promise.reject(
							"Please wait while we verify that you are not a robot."
						)
					let token = "captcha_validation_token"
					if (site_key) {
						token = await validate()
					}
					await XHR(xhr).register({
						...values,
						[data.captcha_field_name]: token,
					})
					await Promise.resolve(onSuccess?.())
				})}
				subscription={{ submitting: true }}
			>
				{({ submitting, handleSubmit }) => (
					<form noValidate id={data?.form_id} onSubmit={handleSubmit}>
						<Stack gap="4">
							<TextInputField
								label="Company Name"
								name="company_name"
								type="text"
								required
								autoComplete="company-name"
								placeholder="ABC Travel Company"
								autoFocus
							/>
							<TextInputField
								label="Your Name"
								name="name"
								type="text"
								required
								autoComplete="full-name"
								placeholder="First Last"
							/>
							<TextInputField
								label="Email"
								name="email"
								type="email"
								placeholder="username@domain.com"
								autoComplete="username email"
								required
								help="We will send a verification code to this email address"
							/>
							<PhoneInputField
								label="Phone Number"
								name="phone_number"
								required
							/>
							<TextInputField
								label="Password"
								name="password"
								type="password"
								autoComplete="new-password"
								required
								help="Use 8 or more characters with a mix of letters, numbers & symbols"
								maxLength={50}
							/>
							<Divider sm />
							<SubmissionError />
							<Stack gap="4">
								<Button type="submit" disabled={submitting} size="lg" fullWidth>
									{submitting ? "Creating your account..." : "Create Account"}
								</Button>
								<Box>
									<div id="captcha_holder"></div>
									<Text fontSize="sm" color="muted">
										By creating an account, you accept our{" "}
										<Text as="a" href={config.privacyPolicyUrl} target="_blank">
											Privacy
										</Text>{" "}
										and{" "}
										<Text
											as="a"
											href={config.termsOfServiceUrl}
											target="_blank"
										>
											Terms of Service
										</Text>
										.
									</Text>
								</Box>
							</Stack>
						</Stack>
					</form>
				)}
			</Form>
		</Box>
	)
}

function useCaptcha({
	siteKey,
	containerId,
}: {
	siteKey?: string
	containerId: string
}) {
	const id = useId()
	const successCallbackRef = useRef<null | ((token: string) => void)>(null)
	const expiredCallbackRef = useRef<null | ((error: string) => void)>(null)
	const errorCallbackRef = useRef<null | ((error: string) => void)>(null)
	const onCaptchaScriptLoad = useCallback(() => {
		if (siteKey) {
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			;(window as any).grecaptcha.ready(async function () {
				await Promise.resolve(
					// eslint-disable-next-line @typescript-eslint/no-explicit-any
					(window as any).grecaptcha.render(containerId, {
						sitekey: siteKey,
						size: "invisible",
						// The name of your callback function, executed when the user submits a successful response. The g-recaptcha-response token is passed to your callback.
						callback: function (token: string) {
							successCallbackRef.current?.(token)
						},
						// The name of your callback function, executed when the reCAPTCHA response expires and the user needs to re-verify.
						"expired-callback": function () {
							expiredCallbackRef.current?.(
								"Captcha expired. Please resubmit the form."
							)
						},
						// The name of your callback function, executed when reCAPTCHA encounters an error (usually network connectivity) and cannot continue until connectivity is restored. If you specify a function here, you are responsible for informing the user that they should retry.
						"error-callback": function () {
							expiredCallbackRef.current?.(
								"Unable to validate captcha due the network connectivity."
							)
						},
					})
				)
			})
		}
	}, [siteKey, containerId])
	const onCaptchaScriptLoadGlobalFnName = `onCaptchaScriptLoad_${id}`
	// attach the onLoad handler so that grecaptcha can access it globally
	useEffect(() => {
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		;(window as any)[onCaptchaScriptLoadGlobalFnName] = onCaptchaScriptLoad
		return () => {
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			delete (window as any)[onCaptchaScriptLoadGlobalFnName]
		}
	}, [onCaptchaScriptLoadGlobalFnName, onCaptchaScriptLoad])

	/**
	 * This is the captcha validation function.
	 */
	const validate = useCallback(async (): Promise<string> => {
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		const grecaptcha: any = (window as any).grecaptcha
		if (!grecaptcha) {
			throw new Error("Captcha not loaded. Please refresh the page.")
		}
		// Programmatically invoke the reCAPTCHA check. Used if the invisible reCAPTCHA is on a div instead of a button.
		await Promise.resolve(grecaptcha.execute())
		// The above call will ask/auto verify the user
		return new Promise((resolve, reject) => {
			// here we will mutate the callback references as we don't have another way of capturing the success/failure
			// responses
			successCallbackRef.current = resolve
			expiredCallbackRef.current = reject
			errorCallbackRef.current = reject
		})
	}, [])
	const js = (
		<Helmet>
			<script
				src={`https://www.google.com/recaptcha/api.js?onload=${onCaptchaScriptLoadGlobalFnName}&render=explicit`}
				async
				defer
			></script>
		</Helmet>
	)
	return { validate, js }
}
