import { Amount } from "./amount"
import { ltrim } from "./utils"

export enum RoundingMode {
	/**
	 * Rounds (half) num away from zero when it is half way there.
	 * @example making 1.5 into 2 and -1.5 into -2.
	 **/
	ROUND_HALF_UP = 1,
	/**
	 * Rounds (half) num towards zero when it is half way there.
	 * @example 1.5 into 1 and -1.5 into -1.
	 **/
	ROUND_HALF_DOWN = 2,
	/**
	 * Rounds (half) num towards the nearest even value when it is half way there.
	 * @example both 1.5 and 2.5 into 2.
	 **/
	ROUND_HALF_EVEN = 3,
	/**
	 * Rounds (half) num towards the nearest odd value when it is half way there.
	 * @example 1.5 into 1 and 2.5 into 3.
	 **/
	ROUND_HALF_ODD = 4,

	/**
	 * Rounds num towards the nearest odd value when it is half way there.
	 * @example 1.5 into 1 and 2.5 into 3.
	 **/
	ROUND_UP = 5,

	/**
	 * Rounds num towards the nearest odd value when it is half way there.
	 * @example 1.5 into 1 and 2.5 into 3.
	 **/
	ROUND_DOWN = 6,
	/**
	 * Rounds (half) num towards the next integer in the direction of +Infinity
	 * @example -0.5 into 0 and .5 into 1.
	 **/
	ROUND_HALF_POSITIVE_INFINITY = 7,

	/**
	 * Rounds (half) num towards the next integer in the direction of -Infinity
	 * @example -0.5 into -1 and .5 into 0.
	 **/
	ROUND_HALF_NEGATIVE_INFINITY = 8,
}

export class Calculator {
	static SCALE = 14

	static compare(a: string, b: string): 0 | 1 | -1 {
		if (Number.isNaN(a) || Number.isNaN(b)) {
			throw new Error(`Invalid values in compatision ${a}, ${b}`)
		}
		const numA = Number(a)
		const numB = Number(a)
		return numA === numB ? 0 : numA > numB ? 1 : -1
	}

	static add(amount: string, addend: string): string {
		const a = Amount.fromString(amount)
		const b = Amount.fromString(addend)

		const fractionalPartLength = Math.max(
			a.getFractionalPart().length,
			b.getFractionalPart().length
		)
		// shift the decimal and add
		const resultWithoutFraction =
			Number(a.base10(-1 * fractionalPartLength).getIntegerPart()) +
			Number(b.base10(-1 * fractionalPartLength).getIntegerPart())

		// add the fractional part back
		return Amount.fromNumber(resultWithoutFraction)
			.base10(fractionalPartLength)
			.toString()
	}

	static subtract(amount: string, addend: string): string {
		const a = Amount.fromString(amount)
		const b = Amount.fromString(addend)

		const fractionalPartLength = Math.max(
			a.getFractionalPart().length,
			b.getFractionalPart().length
		)
		// shift the decimal and add
		const resultWithoutFraction =
			Number(a.base10(-1 * fractionalPartLength).getIntegerPart()) -
			Number(b.base10(-1 * fractionalPartLength).getIntegerPart())

		// add the fractional part back
		return Amount.fromNumber(resultWithoutFraction)
			.base10(fractionalPartLength)
			.toString()
	}

	static multiply(amount: string, multiplier: string): string {
		const a = Amount.fromString(amount)
		const m = Amount.fromString(multiplier)
		const result = String(
			Number(`${a.getIntegerPart()}${a.getFractionalPart()}`) *
				Number(`${m.getIntegerPart()}${m.getFractionalPart()}`)
		)
		const fractionalPartLength =
			a.getFractionalPart().length + m.getFractionalPart().length
		// now divide by the pow(10, fractionalPartLength)
		return new Amount(result).base10(fractionalPartLength).toString()
	}

	static divide(amount: string, divisor: string): string {
		const a = Amount.fromString(amount)
		const d = Amount.fromString(divisor)
		const result = String(
			Number(`${a.getIntegerPart()}${a.getFractionalPart()}`) /
				Number(`${d.getIntegerPart()}${d.getFractionalPart()}`)
		)
		const newBase10 =
			a.getFractionalPart().length - d.getFractionalPart().length
		// now divide by the pow(10, fractionalPartLength)
		return Amount.fromString(result).base10(newBase10).toString()
	}

	/**
	 * Get the share from amount of a given ratio amonht total
	 */
	static share(amount: string, ratio: string, total: string): string {
		// amount * ratio / total
		return Calculator.floor(
			Calculator.divide(Calculator.multiply(amount, ratio), total)
		)
	}

	static mod($amount: string, $divisor: string): string {
		if (Amount.fromString($divisor).isZero()) {
			throw new Error("Modulo by zero not possible")
		}
		return Amount.fromNumber(Number($amount) % Number($divisor)).toString()
	}

	static absolute(number: string): string {
		return ltrim(number, "-")
	}

	static ceil(amount: string): string {
		const a = Amount.fromString(amount)
		if (a.isInteger() || a.isNegative()) {
			return a.getIntegerPart()
		}

		// add one
		return Calculator.add(a.getIntegerPart(), "1")
	}

	static floor(amount: string): string {
		const a = Amount.fromString(amount)
		if (a.isInteger() || a.isPositive()) {
			return a.getIntegerPart()
		}

		// add one
		return Calculator.add(a.getIntegerPart(), "-1")
	}

	static round(amount: string, roundingMode: RoundingMode) {
		const a = Amount.fromString(amount)
		if (a.isInteger()) {
			// already rounded
			return a.toString()
		}

		if (roundingMode === RoundingMode.ROUND_UP) {
			return Calculator.ceil(amount)
		}

		if (roundingMode === RoundingMode.ROUND_DOWN) {
			return Calculator.floor(amount)
		}

		if (a.isHalf() === false) {
			// not .5 at the end, rounding to closest integer
			return Calculator.roundDigit(a)
		}

		if (roundingMode === RoundingMode.ROUND_HALF_UP) {
			return Calculator.add(
				a.getIntegerPart(),
				a.getIntegerRoundingMultiplier()
			)
		}

		if (roundingMode === RoundingMode.ROUND_HALF_DOWN) {
			return a.getIntegerPart()
		}

		if (roundingMode === RoundingMode.ROUND_HALF_EVEN) {
			if (a.isCurrentEven()) {
				return a.getIntegerPart()
			}
			return Calculator.add(
				a.getIntegerPart(),
				a.getIntegerRoundingMultiplier()
			)
		}

		if (roundingMode === RoundingMode.ROUND_HALF_ODD) {
			if (a.isCurrentEven()) {
				return Calculator.add(
					a.getIntegerPart(),
					a.getIntegerRoundingMultiplier()
				)
			}
			return a.getIntegerPart()
		}

		if (roundingMode === RoundingMode.ROUND_HALF_POSITIVE_INFINITY) {
			if (a.isNegative()) {
				return a.getIntegerPart()
			}
			return Calculator.add(
				a.getIntegerPart(),
				a.getIntegerRoundingMultiplier()
			)
		}

		if (roundingMode === RoundingMode.ROUND_HALF_NEGATIVE_INFINITY) {
			if (a.isNegative()) {
				return Calculator.add(
					a.getIntegerPart(),
					a.getIntegerRoundingMultiplier()
				)
			}
			return a.getIntegerPart()
		}

		throw new Error("Unknown rounding mode")
	}

	private static roundDigit(amount: Amount): string {
		if (amount.isCloserToNext()) {
			return Calculator.add(
				amount.getIntegerPart(),
				amount.getIntegerRoundingMultiplier()
			)
		}
		return amount.getIntegerPart()
	}
}
