import { Channel, TChannelConfig } from "./contract"
import createConsoleChannel from "./console"
import createGAChannel, { type TGAChannelConfig } from "./ga"
import createPosthogChannel, { type TPosthogChannelConfig } from "./posthog"

let enabledChannels: Array<ReturnType<Channel>> = []

type ChannelKey = "console" | "ga" | "posthog"

export function configureChannels<
	TConfig extends TChannelConfig & {
		ga?: Omit<TGAChannelConfig, keyof TChannelConfig>
		posthog?: Omit<TPosthogChannelConfig, keyof TChannelConfig>
	},
>({
	channels,
	config,
}: {
	channels: ChannelKey | Array<ChannelKey>
	config: TConfig
}) {
	enabledChannels = []
	if (typeof channels === "string") {
		channels = [channels]
	}
	const { ga, posthog, ...shared } = config
	channels.forEach((c) => {
		switch (c) {
			case "console":
				enabledChannels.push(createConsoleChannel(shared))
				break
			case "ga":
				enabledChannels.push(createGAChannel({ ...shared, ...ga }))
				break
			case "posthog":
				enabledChannels.push(createPosthogChannel({ ...shared, ...posthog }))
				break
			default:
				throw new Error(`Unknown channel [${c}]`)
		}
	})
}

export const channels: ReturnType<Channel> = {
	page(...args) {
		enabledChannels.forEach((c) => c.page(...args))
	},
	track(...args) {
		enabledChannels.forEach((c) => c.track(...args))
	},
	resetIdentity() {
		enabledChannels.forEach((c) => c.resetIdentity())
	},
	identify(...args) {
		enabledChannels.forEach((c) => c.identify(...args))
	},
}
