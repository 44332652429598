import {
	Alert,
	Box,
	Button,
	Icons,
	Stack,
	Heading,
	Inline,
	Text,
	Col,
	Divider,
	Grid,
	Spinner,
	Time,
	TimeDuration,
	Money,
	RadioInput,
} from "@sembark-travel/ui/base"
import { ButtonLink } from "@sembark-travel/ui/router"
import { showSnackbar } from "@sembark-travel/ui/snackbar"
import {
	utcTimestampToLocalDate,
	dateToUTCString,
	startOf,
	endOf,
	getDiff,
	formatDate,
} from "@sembark-travel/datetime-utils"
import { numberToLocalString } from "@sembark-travel/number-utils"
import { useXHR, XHRInstance } from "@sembark-travel/xhr"
import {
	Form,
	TextAreaInputField,
	validateFormValues,
	SubmissionError,
	arrayMutators,
	withServerErrors,
	SwitchInputField,
	useFieldValue,
	GetFieldValue,
} from "@sembark-travel/ui/form"
import pluralize from "pluralize"
import { useMemo, useRef, useState } from "react"
import useSWR from "swr"
import * as Validator from "yup"
import {
	InstalmentsInputField,
	PaymentDetails,
	sortInstalmentsByDueDate,
} from "../Payments"
import { Quote } from "./Quotes"
import { ITrip, ITripQuote } from "./store"
import { tripPaymentsXHR, useTripRefresh } from "./store"
import { useHasFeatureFlag } from "../Auth"
import { generatePath, useNavigate } from "../router-utils"
import {
	TFormattedMoney,
	formatMoneyByDecimal,
	moneyParseByDecimal,
} from "@sembark-travel/money"

interface IInstalment {
	amount: number
	due_at: string
	currency: string
	amount_money: TFormattedMoney
}

function XHR(xhr: XHRInstance) {
	return {
		async getInstalments(quoteId: number): Promise<{
			data: IInstalment[]
			meta: { total: number; currency: string }
		}> {
			return xhr.get(`/quote-instalments/${quoteId}`).then((resp) => resp.data)
		},
		async getGivenQuoteInstalments(givenQuoteId: number): Promise<{
			data: IInstalment[]
			meta: { total: number; currency: string }
		}> {
			return xhr
				.get(`/given-quote-instalments/${givenQuoteId}`)
				.then((resp) => resp.data)
		},
		async convertTrip(data: unknown): Promise<ITrip> {
			return xhr.post("/converted-trips", data).then((resp) => resp.data.data)
		},
	}
}

const tripConversionValidationSchema = validateFormValues(
	Validator.object()
		.shape({
			instalments: Validator.array()
				.of(
					Validator.object().shape({
						due_at: Validator.date().required(
							"Instalment due date is required"
						),
						amount: Validator.number()
							.positive("Instalment amount should a positive number")
							.required("Instalment amount is required"),
					})
				)
				.min(1, "Atleast one instalment should is required"),
			comments: Validator.string(),
			details_verified: Validator.boolean().required(
				"Please verify the details before conversion"
			),
		})
		.required("Conversion fields are required")
)

interface ITripConversionSchema {
	currency: string
	details_verified: boolean
	instalments: { percentage?: number; amount: number; due_at: Date }[]
	comments: string
}

export function ConvertTrip({
	quote: tripQuote,
	tripId,
}: {
	tripId: number | string
	quote: ITripQuote
}) {
	const navigate = useNavigate()
	const xhr = useXHR()
	const refreshTrip = useTripRefresh()
	const [selectedTripQuoteOption, setSelectedTripQuoteOption] =
		useState(tripQuote)
	const { trip } = tripQuote
	const {
		quote,
		given_quote: givenQuote,
		shared_by_tenant,
	} = selectedTripQuoteOption
	const hasTripHoldingFeatureFlag = useHasFeatureFlag("hold_trips")
	const { data: instalments, error } = useSWR(
		givenQuote ? `/given-quote-instalments/${givenQuote.id}` : null,
		async () =>
			!givenQuote
				? Promise.reject("No given quote for the trip")
				: XHR(xhr).getGivenQuoteInstalments(givenQuote.id)
	)
	const initialValues: ITripConversionSchema = useMemo(() => {
		return {
			comments: "",
			details_verified: false,
			currency:
				instalments?.meta?.currency || givenQuote?.given_currency || "INR",
			instalments: !instalments
				? []
				: instalments.data.map(({ amount, currency, due_at }) => {
						const money = moneyParseByDecimal(amount, currency)
						return {
							amount: Number(formatMoneyByDecimal(money)),
							currency,
							due_at: endOf(utcTimestampToLocalDate(due_at), "day"),
							percentage: givenQuote
								? parseFloat(
										((amount * 100) / givenQuote.given_price).toFixed(1)
									)
								: undefined,
						}
					}),
		}
	}, [instalments, givenQuote])
	const canSendToHolding =
		!shared_by_tenant && !trip?.on_hold_at && hasTripHoldingFeatureFlag
	const sendToHolding = useRef(canSendToHolding)
	if (!givenQuote || !quote) return null
	if (error && !instalments) {
		return (
			<Alert status="error">
				{error.message || "Something went wrong. Please try after sometime."}
			</Alert>
		)
	}
	const { start_date, end_date, pax_string, nights, days } = quote
	const isFetchingInstalments = !instalments
	if (isFetchingInstalments) return <Spinner alignCenter padding="4" />
	const allOptions = tripQuote.can_share_options
		? [tripQuote].concat(tripQuote.options || [])
		: [tripQuote]
	return (
		<>
			<Grid gap="4" paddingX="4">
				<Col md={3} sm={12}>
					<Stack gap="2">
						<Heading as="h3">Quote Used</Heading>
						<Text color="muted">
							{allOptions.length > 1
								? "Please select and verify the quote used in the process."
								: "Here are the quote details used in the process"}
						</Text>
					</Stack>
				</Col>
				<Col>
					<Stack gap="4">
						{allOptions.length > 1 ? (
							<Inline flexWrap="wrap" gap="4">
								{allOptions.map((o, index) => {
									const { given_quote } = o
									return (
										<Box
											as="label"
											key={o.id}
											borderWidth="1"
											rounded="md"
											bgColor={
												selectedTripQuoteOption.id === o.id
													? "default"
													: "subtle"
											}
											borderColor={
												selectedTripQuoteOption.id === o.id
													? "primary_emphasis"
													: "default"
											}
											padding="4"
										>
											<Inline gap="4">
												<Box paddingTop="1">
													<RadioInput
														checked={selectedTripQuoteOption.id === o.id}
														onChange={() =>
															given_quote
																? setSelectedTripQuoteOption(o)
																: undefined
														}
													/>
												</Box>
												<Stack gap="3">
													<Box>
														<Box
															display="inlineBlock"
															borderBottomWidth="2"
															borderColor="accent"
															color="accent"
														>
															<Text fontWeight="semibold">
																Option {index + 1}: {o.name}
															</Text>
														</Box>
													</Box>
													{!given_quote ? (
														<Text fontWeight="semibold" color="warning">
															Package Price Missing
														</Text>
													) : (
														<Box>
															<Money
																currency={given_quote.given_currency}
																amount={given_quote.given_price}
																showCurrency
																fontSize="xl"
																fontWeight="semibold"
															/>
														</Box>
													)}
												</Stack>
											</Inline>
										</Box>
									)
								})}
							</Inline>
						) : null}
						<Box bgColor="default" padding="4" rounded="lg" borderWidth="1">
							<Quote
								quoteId={selectedTripQuoteOption.id}
								readOnly
								hideOptions
							/>
						</Box>
					</Stack>
				</Col>
			</Grid>
			<Divider />
			<Form<typeof initialValues>
				key={selectedTripQuoteOption.id}
				initialValues={initialValues}
				validate={tripConversionValidationSchema}
				onSubmit={withServerErrors(async (values) => {
					const { details_verified, instalments, comments } = values
					const given_price = Number(givenQuote.given_price)
					if (!details_verified) {
						throw Error(
							"Please verify the details and select the checkbox when done."
						)
					}
					const totalInstalmentAmount = instalments.reduce(
						(totalAmount, { amount }) => Number(totalAmount) + Number(amount),
						0
					)
					if (!trip?.on_hold_at && totalInstalmentAmount !== given_price) {
						throw Error(
							`Total instalments amount (${numberToLocalString(
								totalInstalmentAmount
							)}) is not equal to given price (${numberToLocalString(
								given_price
							)}). If the given price is not correct, please update the given price for the quote and then convert/on-hold the trip.`
						)
					}
					if (
						window.confirm(
							`${
								totalInstalmentAmount > given_price && !trip?.on_hold_at
									? "Total instalment is greater then given quote's amount. "
									: ""
							}Are you sure you want to mark this trip as ${
								sendToHolding.current ? "On-Hold" : "Converted"
							} ?`
						)
					) {
						await XHR(xhr).convertTrip({
							send_to_holding: Number(sendToHolding.current),
							instalments: instalments.map(
								({ percentage, due_at, ...otherData }) => ({
									...otherData,
									due_at: dateToUTCString(endOf(due_at, "day")),
									due_at_local: formatDate(due_at, "YYYY-MM-DD"),
								})
							),
							comments,
							used_given_quote_id: givenQuote.id,
							trip_id: tripId,
						})
						navigate(`/trips/:tripId`, { params: { tripId: `${tripId}` } })
						showSnackbar(
							sendToHolding.current
								? "Trip sent for holding"
								: `Trip marked as Converted`
						)
						refreshTrip(tripId.toString())
					}
				})}
				mutators={{ ...arrayMutators }}
				subscription={{ submitting: true }}
			>
				{({ submitting, handleSubmit }) => {
					return (
						<form noValidate onSubmit={handleSubmit}>
							<Grid gap="4" paddingX="4">
								<Col md={3} sm={12}>
									<Stack gap="1">
										<Heading as="h3">Instalments</Heading>
										<Text color="muted">
											Please provide tentative instalments for this trip. Basic
											configuration have been created based on payment schedules
											for different services provides (hotels, cabs etc).
										</Text>
									</Stack>
								</Col>
								<Col>
									<Stack gap="8">
										<Box
											bgColor="default"
											padding="4"
											rounded="lg"
											borderWidth="1"
										>
											<Box display="flex" flexWrap="wrap" gap="8">
												<Stack gap="2">
													<Box
														textTransform="uppercase"
														letterSpacing="wide"
														fontSize="sm"
													>
														Trip Source
													</Box>
													<Box
														whiteSpace="preserve"
														fontSize="lg"
														fontWeight="semibold"
													>
														{trip?.trip_source?.name}
													</Box>
												</Stack>
												<Stack gap="2">
													<Box
														textTransform="uppercase"
														letterSpacing="wide"
														fontSize="sm"
													>
														Start Date
													</Box>
													<Box
														whiteSpace="preserve"
														fontSize="lg"
														fontWeight="semibold"
													>
														<Time value={utcTimestampToLocalDate(start_date)} />
													</Box>
												</Stack>
												<Stack gap="2">
													<Box
														textTransform="uppercase"
														letterSpacing="wide"
														fontSize="sm"
													>
														Duration
													</Box>
													<Box whiteSpace="preserve">
														<Stack gap="px">
															<TimeDuration
																days={days}
																fontSize="lg"
																fontWeight="semibold"
															>
																{pluralize("Night", nights, true)},{" "}
																{pluralize("Day", days, true)}
															</TimeDuration>
														</Stack>
													</Box>
												</Stack>
												<Stack gap="2">
													<Box
														textTransform="uppercase"
														letterSpacing="wide"
														fontSize="sm"
													>
														Pax
													</Box>
													<Box
														whiteSpace="preserve"
														fontSize="lg"
														fontWeight="semibold"
													>
														{pax_string}
													</Box>
												</Stack>
												<Stack gap="2">
													<Box
														textTransform="uppercase"
														letterSpacing="wide"
														fontSize="sm"
													>
														Package
														{allOptions.length > 1
															? ` (${selectedTripQuoteOption.name})`
															: null}
													</Box>
													<Box
														whiteSpace="preserve"
														fontSize="lg"
														fontWeight="semibold"
													>
														<Money
															currency={givenQuote.given_currency}
															amount={givenQuote.given_price}
															showCurrency
														/>
													</Box>
												</Stack>
											</Box>
										</Box>
										<Box>
											{trip?.on_hold_at ? (
												<Box>
													<TripIncomingInstalments trip={trip} />
												</Box>
											) : (
												<TripInstalments
													totalPaymentAmount={givenQuote.given_price}
													startDateUTC={start_date}
													endDateUTC={end_date}
												/>
											)}
										</Box>
										<TextAreaInputField
											name="comments"
											label="Comments"
											placeholder="Any comments regarding verification or prices etc.."
										/>
										<GetFieldValue<boolean> name="details_verified">
											{({ value }) => (
												<Alert
													status={value ? "success" : "warning"}
													title="Please make sure the above details are correct and have been verified with the client."
												>
													<Text>
														If verified, please confirm by selecting checkbox
														below
													</Text>
													<Divider sm />
													<SwitchInputField
														name="details_verified"
														label="Yes. I have verified the details"
													/>
												</Alert>
											)}
										</GetFieldValue>
									</Stack>
								</Col>
							</Grid>
							<Divider />
							<Grid>
								<Col md={{ offset: 3 }}>
									<Stack gap="4" paddingX={{ md: "4" }}>
										<SubmissionError />
										<Inline gap="4">
											{canSendToHolding ? (
												<Button
													type="submit"
													disabled={submitting}
													size="lg"
													status="warning"
												>
													{submitting && sendToHolding.current
														? "Sending..."
														: "Send for Holding"}
												</Button>
											) : null}
											<Button
												type="submit"
												onClick={() => {
													sendToHolding.current = false
												}}
												disabled={submitting}
												size="lg"
											>
												{submitting && !sendToHolding.current
													? "Converting..."
													: "Convert Trip"}
											</Button>
											<ButtonLink
												to={generatePath(`/trips/:tripId/quotes/:quoteId`, {
													tripId: String(tripId),
													quoteId: String(selectedTripQuoteOption.id),
												})}
												size="lg"
												level="tertiary"
												disabled={submitting}
											>
												Cancel
											</ButtonLink>
										</Inline>
									</Stack>
								</Col>
							</Grid>
						</form>
					)
				}}
			</Form>
		</>
	)
}

function TripInstalments({
	totalPaymentAmount,
	startDateUTC,
	endDateUTC,
}: {
	totalPaymentAmount: number
	startDateUTC: string
	endDateUTC: string
}) {
	const { value } =
		useFieldValue<ITripConversionSchema["instalments"]>("instalments")
	const { value: currency } =
		useFieldValue<ITripConversionSchema["currency"]>("currency")
	const conversionDate = new Date()
	const instalmentsByDueDate = sortInstalmentsByDueDate(value)
	const firstInstalment = instalmentsByDueDate[0]
	const lastInstalment = instalmentsByDueDate[instalmentsByDueDate.length - 1]
	const firstInstalmentDateDiffAfterTripConversion = firstInstalment
		? getDiff(firstInstalment.due_at, endOf(conversionDate, "day"), "days")
		: 0
	const isFirstInstalmentAfterTripConversion =
		firstInstalmentDateDiffAfterTripConversion > 0
	const lastInstalmentDateDiffAfterTripStartDate = lastInstalment
		? getDiff(
				endOf(lastInstalment.due_at, "day"),
				startOf(utcTimestampToLocalDate(startDateUTC), "day"),
				"days"
			)
		: 0
	const lastInstalmentDateDiffAfterTripEndDate = lastInstalment
		? getDiff(
				endOf(lastInstalment.due_at, "day"),
				endOf(utcTimestampToLocalDate(endDateUTC), "day"),
				"days"
			)
		: 0
	const isLastInstalmentAfterTripStartDate =
		lastInstalmentDateDiffAfterTripStartDate > 0
	const isLastInstalmentAfterTripEndDate =
		lastInstalmentDateDiffAfterTripEndDate > 0
	return (
		<Stack gap="4">
			<InstalmentsInputField
				name="instalments"
				label="Instalments"
				totalAmount={totalPaymentAmount}
				currency={currency}
			/>
			{isFirstInstalmentAfterTripConversion ||
			isLastInstalmentAfterTripStartDate ? (
				<Alert
					title="Please review following points regarding the instalments"
					status="warning"
				>
					<Box as="ol" listStyleType="disc" marginLeft="4">
						{isFirstInstalmentAfterTripConversion ? (
							<Box as="li" marginBottom="2">
								<Box marginBottom="1">
									The first instalment's due date is after the conversion date (
									{pluralize(
										"day",
										firstInstalmentDateDiffAfterTripConversion,
										true
									)}
									)
								</Box>
								<Box color="muted" fontSize="sm">
									Generally, this first instalment should be paid at the time of
									conversion as the services bookings (hotels/cabs etc) starts
									after the first payment is received.
								</Box>
							</Box>
						) : null}
						{isLastInstalmentAfterTripEndDate ? (
							<Box as="li" marginBottom="2">
								<Box marginBottom="1" color="danger">
									<Icons.AttentionSolid /> The last instalment's due date is
									after the trip's end date (
									{pluralize(
										"day",
										lastInstalmentDateDiffAfterTripEndDate,
										true
									)}
									)
								</Box>
								<Box color="muted" fontSize="sm">
									Try to keep instalment due dates before trip's end date for
									better trip operations and to avoid any payment issues.
								</Box>
							</Box>
						) : isLastInstalmentAfterTripStartDate ? (
							<Box as="li" marginBottom="2">
								<Box marginBottom="1">
									The last instalment's due date is after the trip's start date
									(
									{pluralize(
										"day",
										lastInstalmentDateDiffAfterTripStartDate,
										true
									)}
									)
								</Box>
								<Box color="muted" fontSize="sm">
									Generally, the full payment should be received before the trip
									starts to avoid payment issues during trip.
								</Box>
							</Box>
						) : null}
					</Box>
				</Alert>
			) : null}
		</Stack>
	)
}

function TripIncomingInstalments({ trip }: { trip: { id: number } }) {
	const xhr = useXHR()
	const { data, mutate: revalidate } = useSWR(
		`/trips/${trip.id}/payments`,
		() => tripPaymentsXHR(xhr).payments(trip.id)
	)
	if (!data) {
		return <Spinner padding="4" alignCenter />
	}
	const { customer_payments, incoming_payments_from_quote_request } = data
	return (
		<Stack gap="8">
			{customer_payments && customer_payments.length ? (
				<Box>
					{customer_payments.map((payment) => (
						<PaymentDetails
							payment={payment}
							onChange={revalidate}
							key={payment.id}
							disableLogging
						/>
					))}
				</Box>
			) : null}
			{incoming_payments_from_quote_request &&
			incoming_payments_from_quote_request.payments.length ? (
				<Box>
					{incoming_payments_from_quote_request.payments.map((payment) => (
						<PaymentDetails
							payment={payment}
							onChange={revalidate}
							key={payment.id}
							disableLogging
						/>
					))}
				</Box>
			) : null}
		</Stack>
	)
}
