import { TChildrenArray } from "./store"

/**
 * Converts the children array to string representation
 * For array [{count: 1, age: 4}, { count: 2, age: 7}]
 * returns 4,7,7yo (1, 4 year old and 2, 7 year old)
 */
export function childrenToQueryParams(children: TChildrenArray) {
	if (children.length === 0) return ""
	const ages = children
		.filter((c) => c.age && c.count)
		.reduce<number[]>((ages, children) => {
			let i = 0
			do {
				ages.push(children.age)
				i++
			} while (i < children.count)
			return ages
		}, [])
	// sort the ages
	ages.sort((a, b) => (a > b ? -1 : a === b ? 0 : 1))
	return ages.join(",") + "yo"
}

/**
 * Get the array representation of children from a string
 * String can have one of following formats
 *  - 1,3,2,2yo => ages are joined with ','
 *  - 1-1yo,1-3yo,2-2yo => ages are joined with count by '-' and then joined by ','
 */
export function childrenFromQueryParams(children: string): TChildrenArray {
	const childrenArray = children
		.replace(/yo/gi, "") // remove all "yo"
		.trim()
		.split(",") // get the individual parts => [1,3,2] or ['1-1', '1-3', '2-2']
		//                                        vvv             vvv
		.map((part) => (part.indexOf("-") === -1 ? [1, part] : part.split("-")))
		.map(([count, age]) => ({ count: Number(count), age: Number(age) }))
		.filter((c) => c.age && c.count)
	// sort by ages
	childrenArray.sort((a, b) => (a.age > b.age ? -1 : a.age === b.age ? 0 : 1))
	return childrenArray
}
