import { Helmet } from "react-helmet-async"
import { IHotel } from "../../../../Hotels"
import { HotelAccountings } from "../../../../Hotels/Item"
import { useOutletContext } from "react-router-dom"

export default function HotelAccountingsPage() {
	const { hotel } = useOutletContext<{ hotel: IHotel }>()
	return (
		<>
			<Helmet>
				<title>Accounting | Hotel</title>
			</Helmet>
			<HotelAccountings hotel={hotel} />
		</>
	)
}
