import { Box, Button, Stack, Heading, Text } from "@sembark-travel/ui/base"
import { Col, Divider, Grid } from "@sembark-travel/ui/base"
import { useXHR, XHRInstance } from "@sembark-travel/xhr"
import * as Validator from "yup"
import config from "../config"
import {
	Form,
	validateFormValues,
	withServerErrors,
	TextInputField,
	SubmissionError,
} from "@sembark-travel/ui/form"
import { useMemo } from "react"

// schemas
export interface IConnectionTenantSignupCredentials {
	company_name: string
	company_short_name: string
	name: string
	email: string
	password: string
	password_confirmation: string
	invite_token: string
	address_email: string
	email_verified_url: string
}
export interface IAuthToken {
	access_token: string
	expires_in: number
}

const tenantSignupCredentialsSchema = Validator.object().shape({
	company_name: Validator.string()
		.required("Please enter the company's name here")
		.max(191, "Max 191 Characters allowed"),
	company_short_name: Validator.string().required(
		"Please enter a short name for your company"
	),
	name: Validator.string()
		.required("Please enter your name")
		.max(191, "Max 191 Characters allowed"),
	email: Validator.string()
		.email("Please provide a valid email address")
		.required("Email address is required")
		.max(191, "Max 191 Characters allowed"),
	password: Validator.string()
		.min(8, "Please select a password that is atleast 8 characters long")
		.required("Please enter a password for your account"),
	password_confirmation: Validator.string()
		.required("Password Confirmation field is required")
		.when("password", (value: string, schema: Validator.StringSchema) => {
			if (!value) return schema
			return schema.test(
				"is-matching",
				"Passwords don't match! Please enter the exact value that you entered in password field",
				(password_confirmation) => password_confirmation === value
			)
		}),
})
const INITIAL_VALUES: IConnectionTenantSignupCredentials = {
	company_name: "",
	company_short_name: "",
	name: "",
	email: "",
	password: "",
	password_confirmation: "",
	invite_token: "",
	address_email: "",
	email_verified_url: "",
}

const validate = validateFormValues(tenantSignupCredentialsSchema)

// actions
function XHR(xhr: XHRInstance) {
	return {
		async accept(
			connectionId: number | string,
			data: IConnectionTenantSignupCredentials
		): Promise<{ message: string }> {
			return xhr
				.post(`/invite-connection-tenants/${connectionId}/accept`, data)
				.then(({ data }) => data)
		},
	}
}

export function ConnectionTenantSignupForm({
	companyName,
	companyShortName,
	inviteToken,
	name,
	email,
	addressEmail,
	emailVerifiedUrl,
	connectionId,
	onSucess,
}: {
	companyName: string
	companyShortName: string
	inviteToken: string
	name: string
	email: string
	addressEmail: string
	emailVerifiedUrl: string
	connectionId: number | string
	onSucess: (data: IConnectionTenantSignupCredentials) => void
}) {
	const xhr = useXHR()
	const initialValues = useMemo(() => {
		return {
			...INITIAL_VALUES,
			company_name: companyName,
			invite_token: inviteToken,
			company_short_name: companyShortName || companyName,
			name: name,
			email: email,
			address_email: addressEmail,
			email_verified_url: emailVerifiedUrl,
		}
	}, [
		companyName,
		companyShortName,
		inviteToken,
		name,
		email,
		addressEmail,
		emailVerifiedUrl,
	])
	return (
		<Form<IConnectionTenantSignupCredentials>
			validate={validate}
			initialValues={initialValues}
			subscription={{ submitting: true }}
			onSubmit={withServerErrors(async (values) => {
				const data = await XHR(xhr).accept(connectionId, values)
				alert(data.message || "Registered Successfully. You can now login.")
				onSucess(values)
			})}
		>
			{({ submitting, handleSubmit }) => (
				<form noValidate onSubmit={handleSubmit}>
					<Grid>
						<Col sm={12} md={4}>
							<Stack gap="1">
								<Heading as="h2" fontSize="md">
									Company Info
								</Heading>
								<Text color="muted" fontSize="sm">
									Please provide basic information about your company like name,
									short name etc. Other information like company logo, addresses
									can be updated after registration.
								</Text>
							</Stack>
						</Col>
						<Col sm={12} md={8}>
							<Stack gap="4">
								<TextInputField
									type="text"
									label="Company's Full Name"
									name="company_name"
									placeholder="Company Pvt Ltd"
									required
									help="This will be used on all printable documents like itineraries, reports and invoices etc."
								/>
								<TextInputField
									type="text"
									label="Company's Short/Display Name"
									name="company_short_name"
									placeholder="Company"
									help="This will be used in all emails and displays accross product. This should be name that is minimal and sufficient to identify your company e.g. Company's First Name"
									required
								/>
							</Stack>
						</Col>
					</Grid>
					<Divider />
					<Grid>
						<Col sm={12} md={4}>
							<Stack gap="1">
								<Heading as="h2" fontSize="md">
									Admin User Info
								</Heading>
								<Text color="muted" fontSize="sm">
									Please provide your name and email address. You are the first
									user to singup from this company and so, you will marked as
									the <b>Admin User</b>. You will be able to invite other users
									after registration.
								</Text>
							</Stack>
						</Col>
						<Col sm={12} md={8}>
							<Stack gap="4">
								<TextInputField
									label="Name"
									name="name"
									type="text"
									placeholder="John Ana"
									autoComplete="full-name"
									required
								/>
								<TextInputField
									label="Login Email"
									name="email"
									type="email"
									placeholder="username@domain.com"
									autoComplete="username email"
									help="This is the email that you/admin user will use to login into your account"
									required
								/>
								<TextInputField
									label="Setup Account Password"
									type="password"
									name="password"
									autoComplete="new-password"
									help="Please choose a strong password i.e. a combination of uppercase, lowercase and some special characters"
									required
								/>
								<TextInputField
									label="Retype Account Password"
									type="password"
									name="password_confirmation"
									autoComplete="new-password"
									help="This is the password that you entered in the above field. It is just to make sure that you did not mistyped the password."
									required
								/>
							</Stack>
							<input
								hidden
								type="hidden"
								name="invite_token"
								value={initialValues.invite_token}
							/>
							<input
								hidden
								type="hidden"
								name="address_email"
								value={initialValues.address_email}
							/>
						</Col>
					</Grid>
					<Divider />
					<Box>
						<Grid>
							<Col sm={12} md={{ span: 8, offset: 4 }}>
								<Stack gap="4">
									<SubmissionError />
									<Stack gap="8">
										<Button
											type="submit"
											size="lg"
											fullWidth
											disabled={submitting}
										>
											{submitting
												? "Creating account..."
												: "Register and Connect"}
										</Button>
										<Text fontSize="sm" color="muted">
											By creating an account, you accept our{" "}
											<Text
												color="accent"
												as="a"
												href={config.privacyPolicyUrl}
											>
												Privacy
											</Text>{" "}
											and{" "}
											<Text
												as="a"
												href={config.termsOfServiceUrl}
												color="accent"
											>
												Terms of Service
											</Text>
											.
										</Text>
									</Stack>
								</Stack>
							</Col>
						</Grid>
					</Box>
				</form>
			)}
		</Form>
	)
}
