import { Navigate, useParams } from "../../../../../router-utils"
import { Required } from "utility-types"
import { ITenant } from "../../../../../Admin/Tenants"
import { useOutletContext } from "react-router-dom"

export default function RedirectToBilling() {
	const { tenantId } = useParams("/admin/tenants/:tenantId")
	const { tenant } = useOutletContext<{
		tenant: Required<ITenant, "signup_at">
		refresh: () => void
	}>()
	if (tenant.signup_at) {
		return (
			<Navigate
				to="/admin/tenants/:tenantId/billing"
				params={{ tenantId }}
				replace
			/>
		)
	}
	return (
		<Navigate
			to="/admin/tenants/:tenantId/configuration"
			params={{ tenantId }}
			replace
		/>
	)
}
