import { Alert, Spinner } from "@sembark-travel/ui/base"
import { showSnackbar } from "@sembark-travel/ui/snackbar"
import { useXHR } from "@sembark-travel/xhr"
import { useId } from "react"
import { TTeam, XHR } from "./store"
import useSWR from "swr"
import { NewTeamForm } from "./NewItem"

export function EditTeam({
	teamId,
	onSuccess,
	...props
}: {
	teamId: number | string
	onSuccess: (team: TTeam) => void
} & Omit<
	React.ComponentProps<typeof NewTeamForm>,
	"onSubmit" | "initialValues"
>) {
	const xhr = useXHR()
	const id = useId()
	const { data, error } = useSWR<TTeam>(`/teams/${teamId}?${id}`, () =>
		XHR(xhr).show(teamId)
	)
	if (!data && error) {
		return <Alert status="error">{error.message}</Alert>
	}
	if (!data) {
		return <Spinner padding="4" alignCenter />
	}
	return (
		<NewTeamForm
			{...props}
			initialValues={{
				name: data.name,
				users: data.users,
				managers: data.managers,
				trip_destinations: data.trip_destinations,
			}}
			onSubmit={async (values) => {
				const { data } = await XHR(xhr).update(teamId, values)
				onSuccess(data)
				showSnackbar("Team details successfully updated.")
			}}
		/>
	)
}
