import { Box, Icons } from "@sembark-travel/ui/base"
import { useAuthUser } from "../../Auth"
import config from "../../config"
import { ButtonLink } from "@sembark-travel/ui/router"
import { useSearchParams } from "react-router-dom"
import { generatePath } from "../../router-utils"
import { Helmet } from "react-helmet-async"

export function EmailVerified() {
	const [searchParams] = useSearchParams()
	const email = searchParams.get("email") || ""
	const { user } = useAuthUser()
	return (
		<>
			<Helmet>
				<title>Email Verified</title>
			</Helmet>
			<Box paddingTop="16">
				<Box
					maxWidth="2xl"
					marginX="auto"
					textAlign="center"
					borderWidth="1"
					rounded="md"
					paddingY="16"
					paddingX="8"
					bgColor="default"
				>
					<Box>
						<Box marginBottom="10">
							<Box display="inlineBlock" color="success">
								<Icons.OkCircleSolid size="8" />
							</Box>
						</Box>
						<Box as="h1" fontWeight="semibold" fontSize="3xl">
							Your email address has been verified.
						</Box>
						<Box as="p" marginBottom="8">
							{!user
								? `You can now login to the ${config.appTitle} Dashboard.`
								: `Now you can continue using
            ${config.appTitle}`}
						</Box>
						<Box>
							{!user ? (
								<ButtonLink
									to={generatePath("/login") + `?email=${email}`}
									level="primary"
									status="primary"
								>
									Login <Icons.ChevronDown rotate="270" />
								</ButtonLink>
							) : (
								<ButtonLink
									to={generatePath(`/`)}
									level="primary"
									status="primary"
								>
									Continue to Your Dashboard <Icons.ChevronDown rotate="270" />
								</ButtonLink>
							)}
						</Box>
					</Box>
				</Box>
			</Box>
		</>
	)
}

export default EmailVerified
