import {
	Box,
	Icons,
	Stack,
	Heading,
	Text,
	Inline,
	Divider,
} from "@sembark-travel/ui/base"
import { ButtonLink } from "@sembark-travel/ui/router"
import { generatePath } from "../../../router-utils"
import { Helmet } from "react-helmet-async"

export default function OnboardingWelcomePage() {
	return (
		<>
			<Helmet>
				<title>Welcome | Onboarding</title>
			</Helmet>
			<Box
				padding="12"
				borderWidth="1"
				rounded="md"
				maxWidth="4xl"
				marginX="auto"
				bgColor="default"
				marginBottom="16"
			>
				<Stack gap="2">
					<Heading as="h2">Welcome to the Sembark</Heading>
					<Text color="muted">
						You are ready to manage and grow your sales, operations and
						accounting at one connected place.
					</Text>
				</Stack>
				<Divider />
				<Stack gap="6">
					<Text>
						To make onboarding as easy and smooth as possible, we have designed
						an onboarding process where your will learn by doing. You will:
					</Text>
					<Stack as="ul" fontWeight="semibold" gap="2">
						<Inline as="li" alignItems="center" gap="4">
							<Box
								display="inlineFlex"
								width="10"
								height="10"
								alignItems="center"
								justifyContent="center"
								rounded="full"
								bgColor="primary"
								borderWidth="1"
							>
								<Icons.Photograph />
							</Box>
							<Box>Upload organization logo</Box>
						</Inline>
						<Inline as="li" alignItems="center" gap="4">
							<Box
								display="inlineFlex"
								width="10"
								height="10"
								alignItems="center"
								justifyContent="center"
								rounded="full"
								bgColor="primary"
								borderWidth="1"
							>
								<Icons.LocationMarker />
							</Box>
							<Box>Setup your service destinations</Box>
						</Inline>
						<Inline as="li" alignItems="center" gap="4">
							<Box
								display="inlineFlex"
								width="10"
								height="10"
								alignItems="center"
								justifyContent="center"
								rounded="full"
								bgColor="primary"
								borderWidth="1"
							>
								<Icons.UserGroup />
							</Box>
							<Box>Invite your team members</Box>
						</Inline>
					</Stack>
				</Stack>
				<Divider />
				<Stack gap="6">
					<Text fontSize="lg">
						So, let's start with your organization logo.
					</Text>
					<Box>
						<ButtonLink
							to={generatePath("/onboarding/upload-logo")}
							level="primary"
							status="primary"
						>
							Upload Organization Logo <Icons.ChevronDown rotate="270" />
						</ButtonLink>
					</Box>
				</Stack>
			</Box>
		</>
	)
}
