import { Box, BoxOwnProps } from "./Box"
import { Button } from "./Button"
import Icons from "./Icons"
import { Inline } from "./Inline"

export type PaginateProps = BoxOwnProps & {
	/**
	 * Total number of items to paginate over
	 * @example 100
	 */
	total: number
	/**
	 * First item's count number in the current list
	 * @example 1
	 */
	from: number
	/**
	 * Last item's count number in the current list
	 * @example 10
	 */
	to: number
	/**
	 * Current page number on the list
	 * @example 1
	 */
	currentPage: number
	/**
	 * Last possible page number
	 * @example 10
	 */
	lastPage: number
	/**
	 * Is fetching data so that we can disable the page navigation
	 */
	isFetching: boolean
	/**
	 * Change handler for the pages
	 */
	onChange: (page: number) => void
	/**
	 * Hide when not needed
	 * @default true
	 */
	autoHide?: boolean
	previousPageLabel?: string
	nextPageLabel?: string
}

export function Paginate({
	total,
	from,
	to,
	currentPage,
	isFetching,
	lastPage,
	onChange,
	autoHide = true,
	previousPageLabel = "Previous",
	nextPageLabel = "Next",
	...props
}: PaginateProps) {
	if (!total || (autoHide && lastPage <= 1 && currentPage <= 1)) return null
	return (
		<Box as="nav" {...props}>
			<Inline as="ul" gap="4" justifyContent="center">
				<li>
					<Button
						disabled={isFetching || currentPage <= 1}
						onClick={() => onChange(currentPage - 1)}
						title="Previous Page"
						level={currentPage > 1 ? "secondary" : "tertiary"}
					>
						<Icons.ChevronDown rotate="90" /> {previousPageLabel}
					</Button>
				</li>
				<li>
					<Button
						disabled={isFetching || lastPage <= currentPage}
						onClick={() => onChange(currentPage + 1)}
						title="Next Page"
						level={lastPage > currentPage ? "secondary" : "tertiary"}
					>
						{nextPageLabel} <Icons.ChevronDown rotate="270" />
					</Button>
				</li>
			</Inline>
		</Box>
	)
}

export type CursorPaginateProps = BoxOwnProps & {
	/**
	 * Next Cursor
	 * @example eyJjYWJfcHJpY2VzLmlkIjoxMTE3NjIwLCJfcG9pbnRzVG9OZXh0SXRlbXMiOnRydWV9
	 */
	nextCursor: string | null
	/**
	 * Next Cursor
	 * @example eyJjYWJfcHJpY2VzLmlkIjoxMTE3NjIwLCJfcG9pbnRzVG9OZXh0SXRlbXMiOnRydWV9
	 */
	prevCursor: string | null

	/**
	 * Current Cursor
	 * @example eyJjYWJfcHJpY2VzLmlkIjoxMTE3NjIwLCJfcG9pbnRzVG9OZXh0SXRlbXMiOnRydWV9
	 */
	currentCursor: string | null

	/**
	 * Last possible page number
	 * @example 10
	 */
	perPage: number
	/**
	 * Is fetching data so that we can disable the page navigation
	 */
	isFetching: boolean
	/**
	 * Change handler for the pages
	 */
	onChange: (cursor: string | undefined | null) => void
	previousPageLabel?: string
	nextPageLabel?: string
}

export function CursorPaginate({
	nextCursor,
	prevCursor,
	perPage,
	isFetching,
	onChange,
	currentCursor,
	previousPageLabel = "Previous",
	nextPageLabel = "Next",
	...props
}: CursorPaginateProps) {
	if (!nextCursor && !prevCursor) return null
	return (
		<Box as="nav" {...props}>
			<Inline as="ul" gap="4" justifyContent="center">
				<li>
					<Button
						disabled={isFetching || !prevCursor}
						onClick={() => onChange(prevCursor)}
						title="Previous Page"
						level={prevCursor ? "secondary" : "tertiary"}
					>
						<Icons.ChevronDown rotate="90" /> {previousPageLabel}
					</Button>
				</li>
				<li>
					<Button
						disabled={isFetching || !nextCursor}
						onClick={() => onChange(nextCursor)}
						title="Next Page"
						level={nextCursor ? "secondary" : "tertiary"}
					>
						{nextPageLabel} <Icons.ChevronDown rotate="270" />
					</Button>
				</li>
			</Inline>
		</Box>
	)
}
