import { Breadcrumbs } from "@sembark-travel/ui/router"
import { Helmet } from "react-helmet-async"
import { UploadPricesForm } from "./../../../HotelPrices"
import { generatePath, useNavigate } from "../../../router-utils"
import { ForbidUnlessAuthorized, PERMISSIONS } from "../../../Auth"

export default function UploadHotelPrices() {
	const navigate = useNavigate()
	return (
		<>
			<Helmet>
				<title>Upload Hotel Prices</title>
			</Helmet>
			<ForbidUnlessAuthorized permission={PERMISSIONS.UPLOAD_BULK_HOTELS}>
				<Breadcrumbs
					title="Upload Hotel Prices"
					items={[
						[generatePath("/hotel-prices"), "Hotel Prices"],
						["", "Upload CSV"],
					]}
				/>
				<UploadPricesForm
					onSuccess={() => navigate("/hotel-prices")}
					onCancel={() => navigate("/hotel-prices")}
				/>
			</ForbidUnlessAuthorized>
		</>
	)
}
