import { Container, Box } from "@sembark-travel/ui/base"
import {
	useGetBackUrlFromLocation,
	Breadcrumbs,
} from "@sembark-travel/ui/router"
import { Helmet } from "react-helmet-async"
import { generatePath, useParams, useNavigate } from "../../../../router-utils"
import { EditInclusionExclusionPresetItem } from "../../../../InclusionExclusionPresets"
import { ForbidUnlessAuthorized, PERMISSIONS } from "../../../../Auth"

export default function EditInclusionExclusionPresetItemPage(
	props: Omit<
		React.ComponentProps<typeof EditInclusionExclusionPresetItem>,
		"presetId" | "onSuccess"
	>
) {
	const { presetId } = useParams("/inclusion-exclusion-presets/:presetId")
	const navigate = useNavigate()
	const backFromUrl = useGetBackUrlFromLocation()
	const detailsPage = generatePath("/inclusion-exclusion-presets/:presetId", {
		presetId,
	})
	return (
		<>
			<Helmet>
				<title>Edit Inc/Exclusions Preset</title>
			</Helmet>
			<ForbidUnlessAuthorized permission={PERMISSIONS.MANAGE_TRIP_OWNERS}>
				<Breadcrumbs
					title="Edit Inc/Exclusions"
					items={[
						[
							generatePath("/org/inclusion-exclusion-presets"),
							"Inc/Exclusions",
						],
						[detailsPage, presetId],
						["", "Edit"],
					]}
				/>
				<Container paddingY="8">
					<Box borderWidth="1" rounded="md" bgColor="default">
						<Container paddingY="6">
							<EditInclusionExclusionPresetItem
								presetId={presetId}
								{...props}
								onSuccess={(item) =>
									navigate("/inclusion-exclusion-presets/:presetId", {
										replace: true,
										params: {
											presetId: item.id.toString(),
										},
									})
								}
								onCancel={() =>
									backFromUrl
										? navigate(
												backFromUrl as "/org/inclusion-exclusion-presets",
												{ replace: true }
										  )
										: navigate("/inclusion-exclusion-presets/:presetId", {
												replace: true,
												params: {
													presetId,
												},
										  })
								}
							/>
						</Container>
					</Box>
				</Container>
			</ForbidUnlessAuthorized>
		</>
	)
}
