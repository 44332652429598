import { Helmet } from "react-helmet-async"
import { MealPlansList } from "./../../../MealPlans"

export default function MealPlansPage() {
	return (
		<>
			<Helmet>
				<title>Meal Plans</title>
			</Helmet>
			<MealPlansList />
		</>
	)
}
