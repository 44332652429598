import { Helmet } from "react-helmet-async"
import { InclusionExclusionPresetItemDetails } from "./../../../../InclusionExclusionPresets"
import { useParams } from "../../../../router-utils"

export default function InclusionExclusionPresetDetailsPage(
	props: Omit<
		React.ComponentProps<typeof InclusionExclusionPresetItemDetails>,
		"presetId"
	>
) {
	const { presetId } = useParams("/inclusion-exclusion-presets/:presetId")
	return (
		<>
			<Helmet>
				<title>Inc/Exclusion Preset</title>
			</Helmet>
			<InclusionExclusionPresetItemDetails presetId={presetId} {...props} />
		</>
	)
}
