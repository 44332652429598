import { useXHR } from "@sembark-travel/xhr"
import {
	NewIntegrationForm,
	PlatformIcon,
	type TIntegration,
} from "../../../../Integrations"
import {
	Box,
	Container,
	Heading,
	Text,
	Stack,
	Alert,
	Icons,
	Inline,
} from "@sembark-travel/ui/base"
import { Breadcrumbs, Link } from "@sembark-travel/ui/router"
import { Helmet } from "react-helmet-async"
import { generatePath, useNavigate } from "../../../../router-utils"

export default function NewGoogleAdsIntegrationPage() {
	const xhr = useXHR()
	const navigate = useNavigate()
	return (
		<>
			<Helmet>
				<title>New Google Ads Lead Form Integration</title>
			</Helmet>
			<Breadcrumbs
				title="New Integration"
				items={[
					[generatePath("/org/integrations"), "Integrations"],
					["", "Google Ads Integration"],
				]}
			/>
			<Box paddingX={{ md: "4" }} paddingY={{ md: "8" }}>
				<Box maxWidth="3xl" marginX="auto">
					<Box borderWidth={{ md: "1" }} rounded="md" bgColor="default">
						<Container paddingY="6">
							<Stack gap="4">
								<Stack gap="2">
									<Inline gap="4">
										<Box>
											<PlatformIcon size="60" platform="google_ads" />
										</Box>
										<Stack gap="1" flex="1">
											<Heading>About Lead Form on Google Ads</Heading>
											<Text color="muted">
												Lead forms help you generate leads by letting people
												submit their information in a form directly in your
												Google Ads which you can bring directly into your
												Sembark Dashboard.
											</Text>
											<Box>
												<Text
													color="accent"
													as="a"
													href="https://support.google.com/google-ads/answer/9423234?hl=en"
													target="_blank"
													rel="noopener noreferrer"
													textDecoration={"underline"}
												>
													Checkout official docs on Google Ads Lead Form and how
													to setup them <Icons.ArrowTopRightOnSquare />
												</Text>
											</Box>
										</Stack>
									</Inline>
								</Stack>
								<Alert
									status="warning"
									title="Not the Landing Page / Google Form Leads"
								>
									<Stack gap="1">
										<Text>
											Google Ads platform provides a different way to capture
											leads from your Ads using Ad Lead Form. These forms are
											different then your Landing Page Forms or Google Forms.
										</Text>
										<Text>
											Please use{" "}
											<Link
												to={generatePath("/integrations/api/new")}
												color="accent"
												textDecoration={"underline"}
											>
												API Integration
											</Link>{" "}
											for integrate with your landing pages.
										</Text>
									</Stack>
								</Alert>
								<NewIntegrationForm
									initialValues={{
										name: "",
										description: "Leads from Google Ads Leads Forms",
									}}
									onSubmit={async (values) => {
										const integration = await xhr
											.post<{ data: TIntegration }>("integrations", {
												...values,
												platform: "google_ads",
											})
											.then((resp) => resp.data.data)
										navigate("/integrations/:integrationId", {
											params: {
												integrationId: String(integration.id),
											},
											replace: true,
										})
									}}
									onCancel={() => {
										navigate("/org/integrations", { replace: true })
									}}
								/>
							</Stack>
						</Container>
					</Box>
				</Box>
			</Box>
		</>
	)
}
