import {
	Box,
	Icons,
	Heading,
	Text,
	Stack,
	Divider,
} from "@sembark-travel/ui/base"
import { ButtonLink } from "@sembark-travel/ui/router"
import config from "../config"
import { generatePath } from "../router-utils"

export default function Forbidden() {
	return (
		<Box
			paddingY="16"
			paddingX="4"
			bgColor="warning"
			rounded="md"
			borderWidth="1"
			borderColor="warning"
			minHeight={"screen"}
		>
			<Box maxWidth="2xl" marginX="auto">
				<Stack gap="4">
					<Heading as="h2" fontSize="3xl" fontWeight="normal" color="warning">
						<Icons.ShieldExclamation size="12" /> Can't access the Resource.
					</Heading>
					<Text color="warning" fontSize="lg">
						Either this page/resource doesn't exist or you don't have sufficient
						permissions to view it.
					</Text>
					<Text>
						If you have accessed this page by a link or by mistake, please visit
						your Dashboard.
					</Text>
					<Box>
						<ButtonLink Link to={generatePath("/")} level="primary">
							<Icons.Home /> Go to Dashboard
						</ButtonLink>
					</Box>
					<Divider sm />
					<Text>
						If you think this is due to an error, please drop a message in our
						support group or contact us at{" "}
						<Text
							as="a"
							color="accent"
							textDecoration="underline"
							href={`mailto:${config.supportEmail}`}
						>
							{config.supportEmail}
						</Text>
						.
					</Text>
				</Stack>
			</Box>
		</Box>
	)
}
