import {
	Button,
	Inline,
	Divider,
	Stack,
	Box,
	Text,
} from "@sembark-travel/ui/base"
import { useDialog, Dialog } from "@sembark-travel/ui/dialog"
import { useXHR } from "@sembark-travel/xhr"
import {
	Form,
	TextInputField,
	withServerErrors,
	SubmissionError,
	SwitchInputField,
} from "@sembark-travel/ui/form"
import React, { useMemo } from "react"
import { Optional } from "utility-types"
import { ITransportServiceProvider } from "./store"
import { FeatureFlag } from "../Auth"

type UpdateItemDataSchema = {
	name: string
	supplies_cabs: 0 | 1
	supplies_travel_activities: 0 | 1
}

type UpdateItemFormProps = {
	initialValues: UpdateItemDataSchema
	onSubmit: (data: UpdateItemDataSchema) => Promise<void>
	onCancel?: () => void
}

type UpdateItemProps = Omit<
	UpdateItemFormProps,
	"onSubmit" | "initialValues"
> & {
	onSuccess: (tsp: ITransportServiceProvider) => void
	transportServiceProvider: ITransportServiceProvider
}

export function UpdateItem({
	transportServiceProvider,
	onSuccess,
	...otherProps
}: UpdateItemProps) {
	const xhr = useXHR()
	const initialValues: UpdateItemDataSchema = useMemo(() => {
		return {
			name: transportServiceProvider.name,
			supplies_cabs: transportServiceProvider.supplies_cabs ? 1 : 0,
			supplies_travel_activities:
				transportServiceProvider.supplies_travel_activities ? 1 : 0,
		}
	}, [transportServiceProvider])
	return (
		<UpdateItemForm
			{...otherProps}
			initialValues={initialValues}
			onSubmit={async (data) => {
				const resp = await xhr.post(
					`/transport-service-providers/${transportServiceProvider.id}`,
					data
				)
				const tsp: ITransportServiceProvider = resp.data.data
				onSuccess(tsp)
			}}
		/>
	)
}

type UpdateItemInDialogProps = Omit<
	Optional<UpdateItemProps, "onSuccess">,
	"onCancel"
> & {
	children: (props: { edit: () => void }) => React.ReactNode
}

export function UpdateItemInDialog({
	children,
	onSuccess,
	...otherProps
}: UpdateItemInDialogProps) {
	const [isOpen, open, close] = useDialog()
	return (
		<>
			{children({ edit: open })}
			<Dialog
				open={isOpen}
				onClose={close}
				title="Update Service Provider Details"
				sm
			>
				<Dialog.Body>
					<UpdateItem
						{...otherProps}
						onSuccess={(...args) => {
							onSuccess?.(...args)
							close()
						}}
						onCancel={close}
					/>
				</Dialog.Body>
			</Dialog>
		</>
	)
}

function UpdateItemForm({
	initialValues,
	onSubmit,
	onCancel,
}: UpdateItemFormProps) {
	return (
		<Form<typeof initialValues>
			initialValues={initialValues}
			onSubmit={withServerErrors(async (values) => {
				await onSubmit(values)
			})}
			subscription={{ submitting: true }}
		>
			{({ submitting, handleSubmit }) => (
				<form noValidate onSubmit={handleSubmit}>
					<Stack gap="4">
						<FeatureFlag flag="travel_activities">
							<Stack gap="1">
								<Text fontWeight="semibold">Supplied Services</Text>
								<Inline gap="4" flexWrap="wrap">
									<Box
										paddingY="1"
										paddingX="4"
										borderWidth="1"
										rounded="md"
										bgColor="subtle"
									>
										<SwitchInputField name="supplies_cabs" label="Cabs" />
									</Box>
									<Box
										paddingY="1"
										paddingX="4"
										borderWidth="1"
										rounded="md"
										bgColor="subtle"
									>
										<SwitchInputField
											name="supplies_travel_activities"
											label="Travel Activites"
										/>
									</Box>
								</Inline>
							</Stack>
						</FeatureFlag>
						<TextInputField type="text" name="name" label="Supplier Name" />
					</Stack>
					<Divider />
					<SubmissionError />
					<Inline gap="4">
						<Button type="submit" disabled={submitting}>
							{submitting ? "Updating..." : "Update Details"}
						</Button>
						{onCancel ? (
							<Button onClick={() => onCancel()} disabled={submitting}>
								Cancel
							</Button>
						) : null}
					</Inline>
				</form>
			)}
		</Form>
	)
}
