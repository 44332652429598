import { IContact } from "../Contacts"
import type { IExtraService } from "../ExtraServices"
import type { IHotel } from "../Hotels"
import type { IMealPlan } from "../MealPlans"
import type { IRoomType } from "../RoomTypes"
import type { TChildrenArray } from "../Tourists"
import type { IUser } from "../Users"
import { useXHR } from "@sembark-travel/xhr"
import { useCallback } from "react"

export interface IHotelVoucher {
	id: number
	hotel: IHotel
	checkin: string
	checkout: string
	no_of_nights: number
	guest_name: string
	booking_confirmation_id?: string
	reference_id: string
	no_of_adults: number
	children: TChildrenArray
	children_ages: Array<number>
	children_string: string
	services: Array<IHotelVoucherService>
	extra_services: Array<IHotelVoucherExtraService>
	file_url?: string
	sent_at?: string
	sent_by?: IUser
	notes?: string
	is_generating: boolean
	hotel_contact?: IContact
	show_prices?: 0 | 1
}

export interface IHotelVoucherService {
	id: number
	date: string
	meal_plan: IMealPlan
	room_type: IRoomType
	no_of_rooms: number
	adults_with_extra_bed: number
	children_with_extra_bed: number
	children_without_extra_bed: number
}

export interface IHotelVoucherExtraService {
	id: number
	date: string
	service: IExtraService
}

export function useCreateHotelVoucher() {
	const xhr = useXHR()
	const create = useCallback(
		(data: unknown) => {
			return xhr.post(`/hotel-vouchers`, data)
		},
		[xhr]
	)
	return create
}
