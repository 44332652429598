import { Table, AsyncSelect, AsyncSelectProps } from "@sembark-travel/ui/base"
import { ListView, Search, TSearchParams } from "@sembark-travel/ui/list"
import { IListResponse, useXHR, XHRInstance } from "@sembark-travel/xhr"
import React, { Fragment } from "react"
import { Omit } from "utility-types"
import { ICity, ICountry, ICountryState, ILocation } from "./store"
import { TTripDestination } from "../TripDestinations"

function XHR(xhr: XHRInstance) {
	return {
		async getLocations(params?: unknown): Promise<IListResponse<ILocation>> {
			return xhr.get("/locations", { params }).then((resp) => resp.data)
		},
		async getCountries(params?: unknown): Promise<ICountry[]> {
			return xhr
				.get("/locations/countries", { params })
				.then(({ data }) => data.data)
		},
		async getStates(params?: unknown): Promise<ICountryState[]> {
			return xhr
				.get("/locations/states", { params })
				.then(({ data }) => data.data)
		},
		async getCities(params?: unknown): Promise<ICity[]> {
			return xhr
				.get("/locations/cities", { params })
				.then(({ data }) => data.data)
		},
	}
}

export function List<TParams extends TSearchParams>({
	params,
	setParams,
	actions,
}: {
	params: TParams
	setParams: (params: TParams) => void
	actions?: React.ReactNode
}) {
	return (
		<Fragment>
			<Search
				title="Locations"
				initialParams={params}
				onSearch={(params) => {
					setParams({ ...params, page: 1 })
				}}
				actions={actions}
			/>
			<ListView<ILocation, TParams>
				pageKey="locations"
				fetch={(xhr, params) => XHR(xhr).getLocations(params)}
				onPageChange={(page) => setParams({ ...params, page })}
				params={params}
			>
				{({ items: locations }) => (
					<Table
						striped
						bordered
						headers={["Name", "Short Name"]}
						responsive
						rows={locations.map((location) => [
							location.name,
							location.short_name,
						])}
					/>
				)}
			</ListView>
		</Fragment>
	)
}

type SelectProps = Omit<AsyncSelectProps, "fetch">

export function SelectLocations({
	addressableType,
	tripDestinations,
	...props
}: SelectProps & {
	tripDestinations?: Array<TTripDestination>
	addressableType?: "trip_sources" | "tourists"
}) {
	const xhr = useXHR()
	return (
		<AsyncSelect
			multiple
			placeholder="Types here..."
			creatable
			labelKey="full_name"
			{...props}
			fetch={(q) =>
				XHR(xhr)
					.getLocations({
						q,
						addressable_type: addressableType,
						// TODO: Backend has not yet implemented it !!
						trip_destinations: tripDestinations?.length
							? tripDestinations.map((t) => t.id)
							: null,
					})
					.then((resp) => resp.data)
			}
		/>
	)
}

export function SelectCountries(props: SelectProps) {
	const xhr = useXHR()
	return (
		<AsyncSelect
			multiple
			{...props}
			fetch={(q) => XHR(xhr).getCountries({ q })}
		/>
	)
}

export function SelectStates(props: SelectProps) {
	const xhr = useXHR()
	return (
		<AsyncSelect multiple {...props} fetch={(q) => XHR(xhr).getStates({ q })} />
	)
}

export function SelectCities(props: SelectProps) {
	const xhr = useXHR()
	return (
		<AsyncSelect multiple {...props} fetch={(q) => XHR(xhr).getCities({ q })} />
	)
}
