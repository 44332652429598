import { useEffect, useRef } from "react"
import { useDisableNavigationOnSlide } from "./DisableNavigationOnSlide"

const diffForSwipe = 50

function isSwipe(current: number, previous: number): boolean {
	return Math.abs(current - previous) >= diffForSwipe
}
/**
 * Listen to Left-Right swipe movements
 * -1 => Go to prev (left to right swipe)
 * 1 => Go to next (right to left swipe)
 */
export function useSwipe<T extends HTMLElement>(
	onSwipe: (direction: 1 | -1) => void
) {
	const startX = useRef<number>(-1)
	// lets use the ref returned from disable navigation on slide
	const [ref, setRef] = useDisableNavigationOnSlide<T>()
	useEffect(() => {
		if (ref) {
			const prev = ref.style.userSelect
			ref.style.setProperty("user-select", "none")
			return () => {
				if (ref) {
					ref.style.setProperty("user-select", prev)
				}
			}
		}
		return
	}, [ref])
	useEffect(() => {
		function setStart({ x }: { x: number }) {
			startX.current = x
		}
		function handleEnd({ x }: { x?: number }) {
			if (x !== undefined && isSwipe(x, startX.current)) {
				if (x > startX.current) {
					// left -> right slide
					// want to go to prev/left- slide
					onSwipe(-1)
				} else if (x < startX.current) {
					// right -> left slide
					// want to go to next/right- slide
					onSwipe(1)
				}
			}
		}
		function onTouchStart(event: TouchEvent) {
			const clientX = event.touches.item(0)?.clientX
			if (clientX !== undefined) {
				setStart({ x: clientX })
			}
		}
		function onMouseStart(event: MouseEvent) {
			const clientX = event.clientX
			if (clientX !== undefined) {
				setStart({ x: clientX })
			}
		}
		function onTouchEnd(event: TouchEvent) {
			const clientX = event.changedTouches.item(0)?.clientX
			handleEnd({ x: clientX })
		}
		function onMouseUp(event: MouseEvent) {
			const clientX = event.clientX
			handleEnd({ x: clientX })
		}
		if (ref) {
			ref.addEventListener("touchstart", onTouchStart, { passive: true })
			ref.addEventListener("touchend", onTouchEnd, { passive: true })
			ref.addEventListener("mousedown", onMouseStart, { passive: true })
			ref.addEventListener("mouseup", onMouseUp, { passive: true })
		}
		return () => {
			if (ref) {
				ref.removeEventListener("touchstart", onTouchStart)
				ref.removeEventListener("mousedown", onMouseStart)
				ref.removeEventListener("touchend", onTouchEnd)
				ref.removeEventListener("mouseup", onMouseUp)
			}
		}
	}, [ref, onSwipe])
	return [setRef]
}
