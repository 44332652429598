import { Helmet } from "react-helmet-async"
import { TripCheckoutReportsList } from "../../../TripCheckoutReports"

export default function TripCheckoutReportsListPage() {
	return (
		<>
			<Helmet>
				<title>Trip Checkout Report</title>
			</Helmet>
			<TripCheckoutReportsList />
		</>
	)
}
