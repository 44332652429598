export function normalizeNumber(
	n: number,
	digitsAfterDecimal: number | undefined = 2
): number {
	let str = n.toString()
	if (parseInt(str) === parseFloat(str)) {
		return parseInt(str)
	}
	if (digitsAfterDecimal !== undefined) {
		str = Number(str).toFixed(digitsAfterDecimal).toString()
	}
	return Number(str)
}

/**
 * Convert a number to local string (add commas)
 */
export function numberToLocalString(
	n: number | null | undefined,
	digitsAfterDecimal: number | undefined = 2
): string {
	if (n === null || n === undefined) return ""
	let str = normalizeNumber(n, digitsAfterDecimal).toString()
	if (parseInt(str) !== parseFloat(str)) {
		str = Number(str).toFixed(digitsAfterDecimal)
	}

	// we dont want to add commas in the after the decimal point
	const parts = str.split(".")
	// reading the regex
	// ?= means something MUST follow (called sometimes lookahead matches)
	//      e.g. "a?=b" => a follows b matches ab but not ac
	// ?! means something MUST NOT follow
	//      e.g  "a?!b" => a must not follow b matches ac but not ab
	// So below regex reads as follows
	// Other than boundries (\B), something (empty space) should be follow (?=)
	//  (\d{2}+\d{3})|(\d{3}) i.e. group of 2 digits with a group of 3 digit at end
	//  and MUST NOT (?!) follow a single digit (\d)
	// How this works ?
	// e.g. Suppose we a the input as 1234567 which should ouput 12,34,567
	// so we will start to find a match, looking at the first not boundry whitespce
	// e.g. space between 1,2, we will see that it is does not follows \d{2}+\d{3} pattern,
	// it follows \d{2}+\d{2} pattern, and so there will be no match
	// Next we move to the space between 2,3, we see that it has a match i.e. \d{2}+ => "34" and
	// \d{3} => '567', and so we put a "," at the place of this space.
	// Same goes for the space between 3,4 which does not pass the criteria.
	// Now we move to the space between 4,5. This does not passes the pattern of \d{2}+\d{3} but passes
	// \d{3} pattern (we have an OR statement for this case) and so we put a "," here.
	// Afterwards we find no match
	parts[0] = parts[0].replace(/\B(?=(((\d{2})+\d{3})|\d{3})(?!\d))/g, ",")
	return parts.join(".")
}

/**
 * st is used with numbers ending in 1 (e.g. 1st, pronounced first)
 * nd is used with numbers ending in 2 (e.g. 92nd, pronounced ninety-second)
 * rd is used with numbers ending in 3 (e.g. 33rd, pronounced thirty-third)
 * As an exception to the above rules, all the "teen" numbers ending with 11, 12 or 13 use -th (e.g. 11th, pronounced eleventh, 112th, pronounced one hundred [and] twelfth)
 * th is used for all other numbers (e.g. 9th, pronounced ninth).
 */
export function withOrdinalSuffix(n: string | number): string {
	let i = parseInt(n.toString(), 10)
	if (isNaN(i)) {
		console.warn(`Invalid number ${n} passed for ordinal suffix`)
		return ""
	}
	i = Math.abs(i)
	let suffix = "th"
	const j = i % 10
	const k = i % 100
	if (j === 1 && k !== 11) {
		suffix = "st"
	}
	if (j === 2 && k !== 12) {
		suffix = "nd"
	}
	if (j === 3 && k !== 13) {
		suffix = "rd"
	}
	return n + suffix
}

/**
 * Check wether or not a given value is numeric
 */
export function isNumeric(
	value?:
		| Array<unknown>
		| null
		| undefined
		| string
		| number
		| Record<string, unknown>
): value is number {
	if (value === undefined || value === null) return false
	if (Array.isArray(value)) return false
	return !isNaN(parseFloat(String(value))) && isFinite(Number(value))
}
