import {
	datetimeConfig,
	utcTimestampToLocalDate,
	parseDate,
	formatDate,
	toISOString,
	duration,
	getDiff,
	fromNow,
	isToday,
	isYesterday,
	isTomorrow,
} from "@sembark-travel/datetime-utils"
import type { Duration } from "@sembark-travel/datetime-utils"
import { Box, BoxProps } from "./Box"
import { Tooltip } from "./Overlays"

type TimeBaseProps = {
	/**
	 * Date time value
	 */
	value?: Date | string

	/**
	 * UTC Timestamp
	 */
	timestamp?: string
	/**
	 * To customize rendering
	 */
	children?:
		| React.ReactNode
		| ((props: { value: Date; format: string }) => React.ReactNode)
	/**
	 * Show date format
	 */
	dateFormat?: boolean | string
	/**
	 * Show time format
	 */
	timeFormat?: boolean | string
	/**
	 * Provide custom date format
	 */
	format?: string
}

type TimeProps = Omit<BoxProps<"time">, keyof TimeBaseProps> & TimeBaseProps

/**
 * Render a given time
 */
export function Time({
	value,
	children,
	format,
	dateFormat,
	timeFormat,
	timestamp,
	...props
}: TimeProps) {
	if (timestamp) {
		value = utcTimestampToLocalDate(timestamp)
	} else {
		if (!value) return null
		value = parseDate(value)
	}
	if (!format) {
		format =
			dateFormat && timeFormat
				? dateFormat === true && timeFormat === true
					? datetimeConfig.dateTimeDisplayFormat
					: dateFormat === true
						? datetimeConfig.dateDisplayFormat + " [at] " + timeFormat
						: dateFormat + " [at] " + datetimeConfig.timeDisplayFormat
				: timeFormat
					? timeFormat === true
						? datetimeConfig.timeDisplayFormat
						: timeFormat
					: dateFormat
						? dateFormat === true
							? datetimeConfig.dateDisplayFormat
							: dateFormat
						: datetimeConfig.dateDisplayFormat // fallback to date-only format
	}
	return (
		<Tooltip
			content={formatDate(value, datetimeConfig.dateTimeDayDisplayFormat)}
		>
			<Box<"time">
				as="time"
				dateTime={toISOString(value)}
				display="inline"
				{...props}
			>
				{children
					? typeof children === "function"
						? children({ value, format })
						: children
					: formatDate(value, format)}
			</Box>
		</Tooltip>
	)
}

/**
 * Render the time as Relative
 */
export function RelativeTime({
	minUnit,
	...props
}: TimeProps & {
	/**
	 * Minimum relative Unit
	 */
	minUnit?: "day"
}) {
	const children =
		props.children ||
		(({ value, format }) => {
			return Math.abs(getDiff(new Date(), value, "month")) > 0
				? `on ${formatDate(value, format)}`
				: minUnit === "day"
					? isToday(value)
						? "Today"
						: isYesterday(value)
							? "Yesterday"
							: isTomorrow(value)
								? "Tomorrow"
								: fromNow(value)
					: fromNow(value)
		})
	return <Time {...props}>{children}</Time>
}

/**
 * Render a time duration
 */
export function TimeDuration({
	value,
	days,
	children,
	...props
}: Omit<BoxProps<"time">, "value" | "children"> & {
	value?: Duration
	days?: number
	children: React.ReactNode
}) {
	if (!value && days) {
		value = duration(days, "days")
	}
	if (!value) return null
	return (
		<Tooltip content={value.humanize()}>
			<Box as="time" dateTime={value.toISOString()} display="inline" {...props}>
				{children}
			</Box>
		</Tooltip>
	)
}
