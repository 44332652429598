import {
	Divider,
	Stack,
	Text,
	Box,
	Button,
	Inline,
	Icons,
	Heading,
} from "@sembark-travel/ui/base"
import { ButtonLink } from "@sembark-travel/ui/router"
import { useXHR } from "@sembark-travel/xhr"
import {
	Form,
	validateFormValues,
	withServerErrors,
	SubmissionError,
	SelectField,
	TextAreaInputField,
} from "@sembark-travel/ui/form"
import { useMemo } from "react"
import * as Validator from "yup"
import { SelectTenantsInConnection } from "../ConnectionRequests"
import { IConnectionRequest } from "../ConnectionRequests"
import { generatePath, useNavigate } from "../router-utils"

const validationSchema = Validator.object().shape({
	trip: Validator.number().required("Please select a trip"),
	tenant: Validator.object().required("Please select a connected business"),
})

export function NewQuoteRequest({
	tripId,
	onChange,
}: {
	tripId: number | string
	onChange: () => void
}) {
	const navigate = useNavigate()
	const xhr = useXHR()
	const initialValues: {
		trip: number | string
		tenant?: IConnectionRequest
		comments?: string
	} = useMemo(() => {
		return { trip: tripId, tenant: undefined, commmets: "" }
	}, [tripId])
	return (
		<Box maxWidth="3xl" marginX="auto" marginBottom="16" marginTop="8">
			<Heading as="h2">Request Quote from connected Businesses</Heading>
			<Divider />
			<Form<typeof initialValues>
				initialValues={initialValues}
				validate={validateFormValues(validationSchema)}
				onSubmit={withServerErrors(
					async ({ tenant: connectionRequest, ...values }) => {
						if (!values.trip) {
							throw new Error("Please select a trip to request a quote for.")
						}
						await xhr.post("/quote-requests", {
							tenant: connectionRequest?.connected_to_uid,
							...values,
						})
						onChange()
						navigate("/trips/:tripId", {
							params: { tripId: String(tripId) },
							replace: true,
						})
					}
				)}
				subscription={{ submitting: true }}
			>
				{({ submitting, handleSubmit }) => (
					<form noValidate onSubmit={handleSubmit}>
						<Stack gap="4">
							<SelectField
								select={SelectTenantsInConnection}
								name="tenant"
								label="Select Connected Business"
								required
								fullWidth
							/>
							<TextAreaInputField
								name="comments"
								label="Comments regarding quotation or trip"
								secondaryLabel="optional"
							/>
							<SubmissionError />
							<Inline gap="4">
								<Button type="submit" disabled={submitting}>
									{submitting ? "Please wait..." : "Request Quote"}
								</Button>
								<ButtonLink
									to={generatePath("/trips/:tripId", {
										tripId: String(tripId),
									})}
									replace
									level="tertiary"
								>
									Cancel
								</ButtonLink>
							</Inline>
						</Stack>
					</form>
				)}
			</Form>
			<Divider />
			<Stack marginTop="16" gap="4">
				<Heading as="h4">
					Can not find the business you are looking for ?
				</Heading>
				<Text color="muted">
					Connect with your business friends and grow together.
				</Text>
				<Box>
					<ButtonLink to={generatePath("/connection-requests/new")} anchored>
						Connect with Business <Icons.ChevronDown rotate="270" />
					</ButtonLink>
				</Box>
			</Stack>
		</Box>
	)
}
