import {
	AsyncSelect,
	Inline,
	Box,
	Button,
	Table,
	Text,
	Stack,
	Heading,
	Col,
	Divider,
	Grid,
	Icons,
	Container,
	joinAttributes,
} from "@sembark-travel/ui/base"
import {
	ConfirmationDialog,
	ConfirmationDialogProvider,
	Dialog,
	useDialog,
} from "@sembark-travel/ui/dialog"
import { Link, useLocationQuery } from "@sembark-travel/ui/router"
import { ListView, Search, useSearch } from "@sembark-travel/ui/list"
import { useXHR } from "@sembark-travel/xhr"
import { Fragment, useEffect, useState } from "react"
import { Helmet } from "react-helmet-async"
import { Omit, Optional } from "utility-types"
import { SelectAccount } from "../Accounting"
import * as Validator from "yup"
import { generatePath } from "../router-utils"
import { IAccount } from "../Accounting"
import {
	Form,
	withServerErrors,
	validateFormValues,
	SubmissionError,
	SelectField,
	TextInputField,
	SwitchInputField,
	GetFieldValue,
	FormApi,
} from "@sembark-travel/ui/form"
import { showSnackbar } from "@sembark-travel/ui/snackbar"

import { type T_BankAccount, bankAccountsXHR } from "./store"
import { BankBranchDetailsForCode, TWorldBankBranch } from "../World"
import { useFunctionalCurrencyOfTenant } from "../Currencies"

export function BankAccountsList() {
	const [query, setQuery] = useLocationQuery()
	const [params, setParams] = useSearch(query)
	const [isAddAccountVisible, showAddNewAccount, hideAddNewAccount] =
		useDialog()
	useEffect(() => {
		setQuery(params)
	}, [params, setQuery])
	return (
		<Fragment>
			<Helmet>
				<title>Bank Accounts</title>
			</Helmet>
			<Search
				initialParams={params}
				onSearch={(params) => {
					setParams({ ...params, page: 1 })
				}}
				title="Bank Accounts"
			>
				<ListView<T_BankAccount>
					pageKey="bank-accounts"
					fetch={(xhr, params) => bankAccountsXHR(xhr).getBankAccounts(params)}
					params={params}
					onPageChange={(page) => setParams({ ...params, page })}
					emptyState={({ searched, defaultValue }) =>
						searched ? (
							defaultValue
						) : (
							<Container paddingY="8" textAlign="center">
								<Stack gap="4">
									<Heading>Bank Account</Heading>
									<Text>
										Save bank account details and easily share them with your
										clients.
									</Text>
									<Box>
										<Button level="primary" onClick={showAddNewAccount}>
											Add Bank Account Details
										</Button>
									</Box>
								</Stack>
							</Container>
						)
					}
				>
					{({ items: bankAccounts, refresh }) => (
						<BankAccountsTableView
							bankAccounts={bankAccounts}
							onChange={refresh}
						/>
					)}
				</ListView>
			</Search>
			<AddBankAccountInDialog
				open={isAddAccountVisible}
				onCancel={hideAddNewAccount}
				onSuccess={() => {
					setParams({ ...params, page: 1 })
					hideAddNewAccount()
				}}
			/>
		</Fragment>
	)
}

export function BankAccountsTableView({
	onChange,
	bankAccounts,
}: {
	onChange: () => unknown
	bankAccounts: Array<T_BankAccount>
}) {
	const xhr = useXHR()
	return (
		<Table
			headers={["Bank Branch", "Account Number", "Name", "Linked Account", ""]}
			bordered
			striped
			responsive
			alignCols={{ 4: "right" }}
			rows={bankAccounts.map(
				({
					id,
					account_number,
					beneficiary_name,
					bank_name,
					branch_name,
					branch_ifsc_code,
					branch_swift_code,
					accounting_account,
				}) => [
					<Stack>
						<Text fontWeight="semibold">{bank_name}</Text>
						<Text fontSize="sm">
							{joinAttributes(branch_name, branch_ifsc_code)}
						</Text>
						{branch_swift_code ? (
							<Text fontSize="sm">SWIFT Code: {branch_swift_code}</Text>
						) : null}
					</Stack>,
					<Stack>
						<Text>{account_number}</Text>
					</Stack>,
					beneficiary_name,
					accounting_account ? (
						<Stack>
							<Link
								to={generatePath("/accounting/accounts/:accountId", {
									accountId: accounting_account.id.toString(),
								})}
								anchored
								color="accent"
							>
								{accounting_account.name}
							</Link>
							{accounting_account.description ? (
								<Text fontSize="sm" color="muted" maxWidth="xs">
									{accounting_account.description}
								</Text>
							) : null}
						</Stack>
					) : (
						"N/A"
					),
					<ConfirmationDialogProvider>
						{({ confirm }) => (
							<>
								<Button
									onClick={() => {
										confirm().then(async (yes) => {
											if (!yes) return
											try {
												const { message } =
													await bankAccountsXHR(xhr).deleteBankAccount(id)
												showSnackbar(message)
												onChange()
											} catch (e) {
												showSnackbar(
													(e as Error).message ||
														"Something went wrong. Please try later."
												)
											}
										})
									}}
									size="sm"
									level="tertiary"
									status="danger"
								>
									<Icons.Trash />
								</Button>
								<ConfirmationDialog
									title="Delete Bank Account"
									destructiveAction
								>
									<Stack gap="4">
										<Stack gap="2">
											<Text color="danger" fontWeight="semibold">
												<Icons.Attention /> Once deleted, bank account can not
												be restored.
											</Text>
											<Text fontSize="sm" color="muted">
												You will be able to add new one.
											</Text>
										</Stack>
										<Text>
											Are you sure you want to delete this bank account ?
										</Text>
									</Stack>
								</ConfirmationDialog>
							</>
						)}
					</ConfirmationDialogProvider>,
				]
			)}
		/>
	)
}

type SelectBankAccountsProps = Omit<
	React.ComponentProps<typeof AsyncSelect>,
	"fetch"
>

export function SelectBankAccounts({ ...otherProps }: SelectBankAccountsProps) {
	const xhr = useXHR()
	const [newName, setNewName] = useState("")
	return (
		<Fragment>
			<AsyncSelect
				multiple
				onCreateNew={(query: string) => {
					setNewName(query)
				}}
				optionRenderer={({ option, created }) =>
					created ? <Box>Add "{option.name}"</Box> : <Box>{option.name}</Box>
				}
				{...otherProps}
				fetch={(q) =>
					bankAccountsXHR(xhr)
						.getBankAccounts({ q })
						.then((resp) =>
							resp.data.map((b) => ({ ...b, name: b.beneficiary_name }))
						)
				}
			/>
			<AddBankAccountInDialog
				open={!!newName}
				onCancel={() => {
					setNewName("")
				}}
			/>
		</Fragment>
	)
}

function AddBankAccountInDialog({
	onSuccess,
	onCancel,
	open,
	...props
}: Optional<React.ComponentProps<typeof AddBankAccount>, "onSuccess"> & {
	open: boolean
}) {
	return (
		<Dialog open={open} onClose={onCancel} title="Add Bank Account" xl>
			<Dialog.Body>
				<AddBankAccount
					onSuccess={() => {
						onSuccess ? onSuccess() : onCancel()
					}}
					onCancel={() => {
						onCancel()
					}}
					{...props}
				/>
			</Dialog.Body>
		</Dialog>
	)
}

function AddBankAccount({
	onSuccess,
	...props
}: Omit<React.ComponentProps<typeof AddBankAccountForm>, "onSubmit"> & {
	onSuccess: () => void
}) {
	const xhr = useXHR()
	return (
		<AddBankAccountForm
			onSubmit={async ({
				accounting_account,
				accounting_account_description,
				...values
			}) => {
				const data = await bankAccountsXHR(xhr).storeBankAccount({
					...values,
					accounting_account: accounting_account?.id || null,
					accounting_account_description: accounting_account
						? null
						: accounting_account_description,
				})
				onSuccess()
				return data
			}}
			{...props}
		/>
	)
}

const addBankAccountValidationSchema = Validator.object().shape({
	account_number: Validator.string()
		.required("Please provide your bank account number")
		.max(100, "Please use 100 or less characters for account number"),
	beneficiary_name: Validator.string().required(
		"Please provide account/beneficiary name for the account"
	),
	bank_name: Validator.string().required("Please enter the bank name"),
	bank_code: Validator.string().required(
		"Please enter the bank code or short name"
	),
	branch_name: Validator.string().required(
		"Please enter the name of the branch"
	),
	branch_country_name: Validator.string().required("Required"),
	branch_state_name: Validator.string().required("Required"),
	branch_city_name: Validator.string().required("Required"),
	branch_address: Validator.string().required(
		"Please enter the address of the branch"
	),
	new_accounting_account: Validator.number().required(
		"Please select or deselect the checkbox"
	),
	accounting_account: Validator.object().when(
		"new_accounting_account",
		(
			value: string | number | undefined | null,
			schema: Validator.AnyObjectSchema
		) => {
			const addNewAccount = Number(value)
			if (!addNewAccount)
				return schema.required(
					"Please select an account or select the checkbox to create new."
				)
			return schema
		}
	),
})

type T_BankAccountFormData = {
	account_number: string
	branch_ifsc_code: string
	branch_swift_code: string
	branch_micr_code: string
	beneficiary_name: string
	accounting_account?: IAccount
	accounting_account_description?: string
	new_accounting_account: 0 | 1
	branch_search_using: "ifsc" | "swift_code"
	branch_country_name: string
	bank_name?: string
	bank_code?: string
	branch_name?: string
	branch_state_name?: string
	branch_city_name?: string
	branch_address?: string
}

export function AddBankAccountForm({
	onSubmit,
	onCancel,
}: {
	onSubmit: (
		values: Omit<T_BankAccountFormData, "branch_search_using">
	) => Promise<T_BankAccount>
	onCancel: () => void
}) {
	const functionalCurrency = useFunctionalCurrencyOfTenant()
	const [initialValues] = useState<T_BankAccountFormData>(() => ({
		account_number: "",
		branch_ifsc_code: "",
		branch_swift_code: "",
		branch_micr_code: "",
		beneficiary_name: "",
		accounting_account: undefined,
		accounting_account_description: undefined,
		new_accounting_account: 0,
		country: undefined,
		branch_search_using: functionalCurrency === "INR" ? "ifsc" : "swift_code",
		bank_name: "",
		bank_code: "",
		branch_name: "",
		branch_city_name: "",
		branch_state_name: "",
		branch_country_name: "",
		branch_address: "",
	}))

	function autoFillBranchDetailsFromSearchResults(
		form: FormApi<T_BankAccountFormData>,
		branch: TWorldBankBranch
	) {
		form.change("bank_name", branch.bank.name)
		form.change("bank_code", branch.bank.code)
		form.change("branch_name", branch.name)
		form.change("branch_city_name", branch.city_name || "")
		form.change("branch_state_name", branch.state_name || "")
		form.change("branch_country_name", branch.country_name || "")
		form.change(
			"branch_ifsc_code",
			String(branch.ifsc_code || "").toUpperCase()
		)
		form.change(
			"branch_swift_code",
			String(branch.swift_code || "").toUpperCase()
		)
		form.change("branch_micr_code", branch.micr_code || "")
		form.change("branch_address", branch.address || "")
	}
	return (
		<Form<T_BankAccountFormData>
			initialValues={initialValues}
			validate={validateFormValues(addBankAccountValidationSchema)}
			onSubmit={withServerErrors(({ branch_search_using, ...values }) => {
				return onSubmit(values)
			})}
			subscription={{ submitting: true }}
		>
			{({ submitting, handleSubmit, form }) => (
				<form noValidate onSubmit={handleSubmit}>
					<Grid gap="4">
						<Col xs={12} sm={4}>
							<Stack gap="1">
								<Heading as="h4">Bank Branch Details</Heading>
								<Text color="muted">
									Please provide the respective bank details.
								</Text>
							</Stack>
						</Col>
						<Col>
							<Stack gap="4">
								<GetFieldValue<
									"ifsc" | "swift_code"
								> name="branch_search_using">
									{({ value: branchSearchUsing }) =>
										branchSearchUsing === "ifsc" ? (
											<Stack gap="4" key="ifsc">
												<TextInputField
													label="Search by IFSC Code"
													name="branch_ifsc_code"
													type="text"
													placeholder="e.g. HDFC0001719"
													minLength={11}
													maxLength={11}
												/>
												<GetFieldValue<string> name="branch_ifsc_code">
													{({ value: code }) =>
														String(code).length === 11 ? (
															<BankBranchDetailsForCode
																type="ifsc"
																code={code}
																onSelect={(branch) => {
																	autoFillBranchDetailsFromSearchResults(
																		form,
																		branch
																	)
																}}
															/>
														) : null
													}
												</GetFieldValue>
											</Stack>
										) : (
											<Stack gap="4" key="branch_swift_code">
												<TextInputField
													label="Search by SWIFT Code"
													name="swift_code"
													type="text"
													placeholder="e.g. HDFCINBBXXX"
													minLength={8}
													maxLength={11}
												/>
												<GetFieldValue<string> name="branch_swift_code">
													{({ value: code }) =>
														String(code).length >= 8 &&
														String(code).length <= 11 ? (
															<BankBranchDetailsForCode
																type="swift_code"
																code={code}
																onSelect={(branch) => {
																	autoFillBranchDetailsFromSearchResults(
																		form,
																		branch
																	)
																}}
															/>
														) : null
													}
												</GetFieldValue>
											</Stack>
										)
									}
								</GetFieldValue>
							</Stack>
							<Divider sm />
							<Stack gap="4">
								<Heading fontSize="md" color="primary">
									Bank Details
								</Heading>
								<Inline collapseBelow="sm" gap="4">
									<Box flex="1">
										<TextInputField
											name="bank_name"
											label="Name of the Bank"
											placeholder="e.g. HDFC Bank"
										/>
									</Box>
									<TextInputField
										name="bank_code"
										type="text"
										label="Bank Short Name / Code"
										placeholder="HDFC"
									/>
								</Inline>
								<Divider marginY="0" />
								<Heading fontSize="md" color="primary">
									Branch Details
								</Heading>
								<TextInputField
									name="branch_name"
									label="Branch Name"
									type="text"
									placeholder="e.g. City Center"
									required
								/>
								<Inline gap="4">
									{functionalCurrency === "INR" ? (
										<TextInputField
											name="branch_ifsc_code"
											type="text"
											label="IFSC"
											placeholder="e.g. HDFC0001719"
										/>
									) : null}
									<TextInputField
										name="branch_swift_code"
										type="text"
										label="SWIFT Code"
										placeholder="e.g. HDFCINBBXXX"
									/>
									<TextInputField
										name="branch_micr_code"
										type="number"
										label="MICR Code"
										secondaryLabel="optional"
										placeholder="e.g. 12121212"
									/>
								</Inline>
								<Inline gap="4" collapseBelow="sm">
									<TextInputField
										name="branch_country_name"
										label="Country"
										type="text"
										placeholder="e.g. India"
									/>
									<TextInputField
										name="branch_state_name"
										type="text"
										label="Branch State / Province"
										placeholder="e.g. Raj"
										required
									/>
									<TextInputField
										name="branch_city_name"
										label="Branch City / District Name"
										type="text"
										placeholder="e.g. Jaip"
										required
									/>
								</Inline>
								<TextInputField
									name="branch_address"
									label="Branch Address"
									type="text"
									placeholder="e.g. City Center, 123123"
									required
								/>
							</Stack>
						</Col>
					</Grid>
					<Divider />
					<Grid gap="4">
						<Col xs={12} sm={4}>
							<Stack gap="1">
								<Heading as="h4">Account Details</Heading>
								<Text color="muted">
									Please provide your bank's account number, name.
								</Text>
							</Stack>
						</Col>
						<Col>
							<Grid gap="4">
								<Col sm={12} md>
									<TextInputField
										label="Account Number"
										name="account_number"
										type="text"
										placeholder="e.g. 5010016324890"
										required
										width="full"
									/>
								</Col>
								<Col>
									<TextInputField
										label="Account/Beneficiary Name"
										name="beneficiary_name"
										type="text"
										placeholder="e.g. Your Business Account"
										required
									/>
								</Col>
							</Grid>
						</Col>
					</Grid>
					<Divider />
					<Grid gap="4">
						<Col xs={12} sm={4}>
							<Stack gap="1">
								<Heading as="h4">Linked Sembark Account</Heading>
								<Text color="muted">
									Please select/create the virtual Sembark account to keep track
									of all your transactions at one place.
								</Text>
							</Stack>
						</Col>
						<Col>
							<Stack gap="4">
								<Grid gap="4">
									<Col sm={12} md>
										<SwitchInputField
											name="new_accounting_account"
											label="Create new account on Sembark"
											help="An account will be automatically created for this bank account"
										/>
									</Col>
									<Col sm={12} md="auto">
										<Box textAlign="center" marginY="4" fontWeight="semibold">
											OR
										</Box>
									</Col>
									<Col>
										<GetFieldValue<
											boolean | undefined
										> name="new_accounting_account">
											{({ value: new_accounting_account }) => (
												<SelectField
													name="accounting_account"
													label="Select an Account"
													select={SelectAccount}
													params={{ entity_type: "tenants" }}
													disabled={Boolean(new_accounting_account)}
												/>
											)}
										</GetFieldValue>
									</Col>
								</Grid>
								<GetFieldValue<
									boolean | undefined
								> name="new_accounting_account">
									{({ value: new_accounting_account }) =>
										new_accounting_account ? (
											<TextInputField
												label="Account Description/Notes"
												secondaryLabel="optional"
												name="accounting_account_description"
												type="text"
												placeholder="e.g. This account is primary used for xyz."
											/>
										) : null
									}
								</GetFieldValue>
							</Stack>
						</Col>
					</Grid>
					<Divider />
					<Stack gap="4">
						<SubmissionError />
						<Inline gap="4">
							<Button type="submit" disabled={submitting}>
								{submitting ? "Saving..." : "Save Account Details"}
							</Button>
							<Button disabled={submitting} onClick={onCancel} level="tertiary">
								Cancel
							</Button>
						</Inline>
					</Stack>
				</form>
			)}
		</Form>
	)
}
