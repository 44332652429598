import { useXHR, XHRInstance } from "@sembark-travel/xhr"
import { useState } from "react"
import * as Validator from "yup"
import { Button, Stack, Divider, Box } from "@sembark-travel/ui/base"
import { Optional } from "utility-types"
import {
	Form,
	TextInputField,
	SubmissionError,
	withServerErrors,
	validateFormValues,
} from "@sembark-travel/ui/form"

// schemas
export interface IForgotPasswordCredentials {
	email: string
	reset_password_link: string
}
const forgotPasswordSchema = Validator.object().shape({
	email: Validator.string()
		.email("Please provide a valid email address")
		.required("Please provide the email address you use to log in"),
})

const validate = validateFormValues(forgotPasswordSchema)

// actions
function XHR(xhr: XHRInstance) {
	return {
		async forgotPassword(data: IForgotPasswordCredentials): Promise<void> {
			return xhr.post("/passwords/reset", data)
		},
	}
}

export function ForgotPassword({
	email,
	resetPasswordLink,
	onSucess,
}: {
	email: string
	resetPasswordLink: string
	onSucess: () => void
}) {
	const xhr = useXHR()
	const [initialValues] = useState(() => {
		return {
			email,
			reset_password_link: resetPasswordLink,
		}
	})
	return (
		<ForgotPasswordForm
			initialValues={initialValues}
			onSubmit={async (values: IForgotPasswordCredentials) => {
				await XHR(xhr).forgotPassword(values)
				alert(`Please check your inbox for password reset instructions.`)
				onSucess()
			}}
		/>
	)
}

export function ForgotPasswordForm({
	initialValues: propInitialValues,
	onSubmit,
}: {
	initialValues?: Optional<IForgotPasswordCredentials>
	onSubmit: (data: IForgotPasswordCredentials) => Promise<void>
}) {
	const [initialValues] = useState<IForgotPasswordCredentials>(() => {
		return {
			email: propInitialValues?.email || "",
			reset_password_link: propInitialValues?.reset_password_link || "",
		}
	})
	return (
		<Form<IForgotPasswordCredentials>
			initialValues={initialValues}
			validate={validate}
			onSubmit={withServerErrors(async (values) => {
				await onSubmit(values)
			})}
			subscription={{ submitting: true }}
		>
			{({ handleSubmit, submitting }) => (
				<form noValidate onSubmit={handleSubmit}>
					<Stack
						gap="4"
						padding="6"
						borderWidth="1"
						rounded="md"
						bgColor="default"
					>
						<SubmissionError />
						<Box>
							<TextInputField
								name="email"
								label="Email"
								placeholder="username@domain.com"
								autoComplete="username email"
								required
								autoFocus
								type="email"
								id="email"
								tabIndex={1}
							/>
							<input
								type="hidden"
								name="reset_password_link"
								hidden
								value={initialValues.reset_password_link}
							/>
						</Box>
						<Divider sm />
						<Box>
							<Button tabIndex={2} type="submit" disabled={submitting}>
								{submitting ? "Please wait" : "Get Instructions"}
							</Button>
						</Box>
					</Stack>
				</form>
			)}
		</Form>
	)
}
