import { IntegrationsList } from "../../../../Integrations"
import { Helmet } from "react-helmet-async"
import { ForbidUnlessAuthorized, PERMISSIONS } from "../../../../Auth"

export default function OrganizationIntegrationsSettingsPage() {
	return (
		<>
			<Helmet>
				<title>Integrations and APIs</title>
			</Helmet>
			<ForbidUnlessAuthorized permission={PERMISSIONS.MANAGE_INTEGRATIONS}>
				<IntegrationsList />
			</ForbidUnlessAuthorized>
		</>
	)
}
