import { TenantProfileLayout } from "../../../TenantProfile"
import { Outlet } from "react-router-dom"

export default function Layout() {
	return (
		<TenantProfileLayout active="repository">
			<Outlet />
		</TenantProfileLayout>
	)
}
