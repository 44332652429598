import { Box, Button, Icons, ButtonProps } from "@sembark-travel/ui/base"
import { Spinner } from "@sembark-travel/ui/base"
import { useXHR } from "@sembark-travel/xhr"
import React, { useCallback, useEffect, useRef, useState } from "react"
import useSWR from "swr"
import config from "../config"

function DefaultGenerateItineraryRender({
	onGenerate,
}: {
	onGenerate: () => void
}) {
	return <Button onClick={onGenerate}>Regenerate Itinerary</Button>
}

export function GenerateItineraryAssets({
	itineraryId,
	onChange,
	children = DefaultGenerateItineraryRender,
}: {
	itineraryId: number
	onChange: () => void
	children?: (
		props: React.ComponentProps<typeof DefaultGenerateItineraryRender>
	) => React.ReactNode
}) {
	const xhr = useXHR()
	const generate = useCallback(() => {
		xhr
			.post("/generate-itineraries", {
				items: [itineraryId],
				timezone_offset: config.timezoneOffset,
			})
			.then(() => {
				onChange()
			})
	}, [itineraryId, xhr, onChange])
	return <>{children({ onGenerate: generate })}</>
}

export function GeneratedItineraryAsset({
	id,
	isGenerating,
	fileUrl,
	onChange,
	downloadLabel = <Icons.DocumentDownload />,
	regenerateLabel = (
		<Box display="inline">
			<Icons.Refresh title="File not Generated" /> PDF
		</Box>
	),
	...props
}: {
	id: number
	isGenerating?: boolean
	fileUrl?: string
	downloadLabel?: React.ReactNode
	regenerateLabel?: React.ReactNode
	onChange: () => void
} & Omit<ButtonProps<"a">, "id" | "onChage" | "children">) {
	const xhr = useXHR()
	const [refreshInterval, setRefreshInterval] = useState(5000)
	const { data } = useSWR(
		isGenerating ? `/itinerary-generation-status/${id}` : null,
		(key) =>
			xhr
				.get<{
					data: {
						done: boolean
						in_progress: boolean
						failed: boolean
					}
				}>(key)
				.then((resp) => {
					if (!resp.data.data.done) {
						setRefreshInterval((interval) => interval + 1000)
					}
					return resp.data.data
				}),
		{
			refreshInterval: isGenerating ? refreshInterval : 0,
		}
	)
	const onChangeRef = useRef(onChange)
	onChangeRef.current = onChange
	useEffect(() => {
		if (data) {
			if (data.in_progress === false && isGenerating) {
				onChangeRef.current()
			}
		}
	}, [data, isGenerating])
	return isGenerating ? (
		<Button title="Generating Quote Document File..." disabled {...props}>
			<Spinner />
		</Button>
	) : fileUrl ? (
		<Button
			as="a"
			href={fileUrl}
			title="Download Quote"
			target="_blank"
			rel="noopener noreferrer"
			{...props}
		>
			{downloadLabel}
		</Button>
	) : (
		<GenerateItineraryAssets onChange={onChange} itineraryId={id}>
			{({ onGenerate }) => (
				<Button type="button" onClick={onGenerate} {...props}>
					{regenerateLabel}
				</Button>
			)}
		</GenerateItineraryAssets>
	)
}
