import { useDispatch } from "react-redux"
import { ThunkDispatch } from "./types"
import { isSame, isAfter } from "@sembark-travel/datetime-utils"
import {
	TMoney,
	addMoneyFromDifferentCurrencies,
	createMoney,
	moneyParseByDecimal,
} from "@sembark-travel/money"

export function useThunkDispatch() {
	return useDispatch<ThunkDispatch>()
}

export interface IDaywisePrice {
	date: Date
	currency: string
	price?: number
	given_price?: number
	booked_price?: number
	how?: string
	edited_given_price?: boolean
	edited_booked_price?: boolean
}

export interface IDaywiseHotelPrice extends IDaywisePrice {
	per_person_price?: null | {
		room: number
		adult: number
		adult_with_extra_bed: number
		child_with_extra_bed: number
		child_without_extra_bed: number
	}
	per_room_given_price?: null | number
	per_adult_given_price?: null | number
	per_adult_with_extra_bed_given_price?: null | number
	per_child_with_extra_bed_given_price?: null | number
	per_child_without_extra_bed_given_price?: null | number
	per_room_booked_price?: null | number
	per_adult_booked_price?: null | number
	per_adult_with_extra_bed_booked_price?: null | number
	per_child_with_extra_bed_booked_price?: null | number
	per_child_without_extra_bed_booked_price?: null | number
}

/**
 * Get total price for services (hotels/cabs)
 */
export function getTotalPriceForServices(
	data?: Array<{
		date_wise_prices?: Array<Pick<IDaywisePrice, "currency" | "given_price">>
		similar_hotel_options?: Array<{
			date_wise_prices?: Array<Pick<IDaywisePrice, "currency" | "given_price">>
		}>
		travel_activities?: Array<{
			ticket_tourist_configurations: Array<{
				date_wise_prices?: Array<
					Pick<IDaywisePrice, "currency" | "given_price">
				>
			}>
		}>
		cabs?: Array<{
			date_wise_prices?: Array<Pick<IDaywisePrice, "currency" | "given_price">>
		}>
	}>
): Array<TMoney> {
	return (data || []).reduce<Array<TMoney>>(
		(price, item) =>
			addMoneyFromDifferentCurrencies(
				price
					.concat(
						(item.date_wise_prices || []).reduce<Array<TMoney>>(
							(total, { currency, given_price }, index) =>
								addMoneyFromDifferentCurrencies(
									total.concat([
										(item.similar_hotel_options || []).reduce<TMoney>(
											(maxPrice, j) =>
												createMoney(
													Math.max(
														maxPrice.amount,
														moneyParseByDecimal(
															Number(
																j.date_wise_prices?.[index]?.given_price
															) || 0,
															currency
														).amount
													),
													currency
												),
											moneyParseByDecimal(given_price || 0, currency)
										),
									])
								),
							[]
						)
					)
					.concat(
						(item.travel_activities || []).reduce<Array<TMoney>>(
							(total, j) =>
								addMoneyFromDifferentCurrencies(
									total.concat(
										getTotalPriceForServices(j.ticket_tourist_configurations)
									)
								),
							[]
						)
					)
					.concat(getTotalPriceForServices(item.cabs))
			),
		[]
	)
}

/**
 * Get the total prices for extras (hotel extra/cab extra/other extra)
 */
export function getTotalPriceForExtras(
	data?: Array<{ price?: number; currency?: string; given_price?: number }>
): Array<TMoney> {
	return (data || []).reduce<Array<TMoney>>(
		(price, item) =>
			addMoneyFromDifferentCurrencies(
				price.concat(
					moneyParseByDecimal(
						item.price || item.given_price || 0,
						item.currency || "INR"
					)
				)
			),
		[]
	)
}

export class Collection<T> {
	protected items: Array<T>

	constructor(items: Array<T>) {
		this.items = items
	}

	public unique(fn: (item: T) => string | number = (item) => String(item)) {
		return new Collection(Object.values(this.keyBy(fn)))
	}

	/**
	 * Group the items with a given key
	 */
	public groupBy(fn: (item: T) => string | number = (item) => String(item)) {
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		const [__indexByKey, groupedItems] = this.items.reduce<
			[{ [key: string]: number }, Array<Array<T>>]
		>(
			([indexByKey, groupedItems], item) => {
				const key = fn(item)
				if (indexByKey[key] === undefined) {
					// set the last index + 1 to the next index
					indexByKey[key] = groupedItems.length
				}
				const index = indexByKey[key]
				if (!groupedItems[index]) {
					groupedItems[index] = []
				}
				groupedItems[index].push(item)
				return [indexByKey, groupedItems]
			},
			[{}, []]
		)
		return new Collection(groupedItems)
	}

	/**
	 * Create Keyed object of items with a given key
	 */
	public keyBy(
		fn: (item: T, index: number) => string | number = (item) => String(item)
	) {
		const itemsByKey = this.items.reduce<{ [key: string]: T }>(
			(itemsByKey, item, index) => {
				const key = fn(item, index)
				if (!itemsByKey[key]) {
					itemsByKey[key] = item
				}
				return itemsByKey
			},
			{}
		)
		return itemsByKey
	}

	public partition<TSuccess extends T = T>(
		partitioner: ((item: T) => item is TSuccess) | ((item: T) => boolean)
	) {
		return this.items.reduce<[passed: Array<TSuccess>, remaining: Array<T>]>(
			([passed, remaining], item) => {
				partitioner(item) ? passed.push(item as never) : remaining.push(item)
				return [passed, remaining]
			},
			[[], []]
		)
	}

	/**
	 * Transform the collection to array
	 */
	public toArray() {
		return this.items
	}
	/**
	 * Transform the collection to string
	 */
	public toString() {
		return JSON.stringify(this.items)
	}
	/**
	 * Number of items in collection
	 */
	public count() {
		return this.items.length
	}
}

export function collect<T>(items: Array<T>) {
	return new Collection(items)
}

export function getRemainingDates(
	allDates: Array<Date>,
	selectedDates: Array<Date>
): Array<Date> {
	if (!selectedDates.length) return allDates
	const dates = allDates.filter(
		(date) => !selectedDates.find((d) => isSame(d, date, "day"))
	)
	const date = selectedDates[selectedDates.length - 1]
	const [afterDates, beforeDates] = collect(dates).partition((d) =>
		isAfter(d, date)
	)
	return afterDates.concat(beforeDates)
}
