import { Fragment } from "react"
import { Helmet } from "react-helmet-async"
import { TravelActivitiesList } from "./../../../TravelActivities"

export default function TravelActivitiesListPage() {
	return (
		<Fragment>
			<Helmet>
				<title>Travel Activities</title>
			</Helmet>
			<TravelActivitiesList />
		</Fragment>
	)
}
