import { useParams } from "../../../../../router-utils"
import { CountryDetails } from "../../../../../Admin/World"
import { Helmet } from "react-helmet-async"

export default function CountryDetailsPage() {
	const { countryId } = useParams("/admin/world/countries/:countryId")
	return (
		<>
			<Helmet>
				<title>Country Details</title>
			</Helmet>
			<CountryDetails countryId={countryId} />
		</>
	)
}
