import { Helmet } from "react-helmet-async"
import { useNavigate, useParams } from "../../../../router-utils"
import { EditRoleForm } from "./../../../../Roles"

export default function EditRole() {
	const { roleId } = useParams("/roles/:roleId/edit")
	const navigate = useNavigate()
	function navigateBackToDetails() {
		return navigate("/roles/:roleId", { params: { roleId } })
	}
	return (
		<>
			<Helmet>
				<title>Edit Role</title>
			</Helmet>
			<EditRoleForm
				roleId={roleId}
				onSuccess={(role) =>
					navigate("/roles/:roleId", { params: { roleId: role.id.toString() } })
				}
				onCancel={navigateBackToDetails}
			/>
		</>
	)
}
