import { useAppInstaller } from "./utils"

export function AppUpdater({
	children,
}: {
	children: (props: ReturnType<typeof useAppInstaller>) => React.ReactNode
}) {
	const props = useAppInstaller()
	return <>{children(props)}</>
}
