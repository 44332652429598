import { Box, BoxProps } from "./Box"
import { useBreakpoints } from "./utils"
import classNames from "classnames"
import { createContext, useContext, useMemo, useState } from "react"
import { tabsListClassName } from "./tabs.css"

const TabsContext = createContext<{
	layout?: "row" | "col"
	activeTab?: string
	setActiveTab: (activeTab: string) => void
}>({
	layout: undefined,
	activeTab: undefined,
	setActiveTab: () => undefined,
})

function useTabsContext() {
	return useContext(TabsContext)
}

export type TabsProps = BoxProps & {
	layout?: "row" | "col"
	collapseBelowXs?: boolean
	collapseBelow?: "xs" | "sm" | "md"
	defaultActive?: string
}

export function Tabs({
	layout = "row",
	collapseBelowXs = true,
	collapseBelow,
	defaultActive,
	...props
}: TabsProps) {
	const { xs, sm, md } = useBreakpoints()
	if (collapseBelowXs && !collapseBelow) {
		collapseBelow = "xs"
	}
	switch (collapseBelow) {
		case "xs":
			if (xs && layout === "row") {
				layout = "col"
			}
			break
		case "sm":
			if ((xs || sm) && layout === "row") {
				layout = "col"
			}
			break
		case "md":
			if ((xs || sm || md) && layout === "row") {
				layout = "col"
			}
			break
	}
	const [activeTab, setActiveTab] = useState<string | undefined>(defaultActive)
	const context = useMemo(() => {
		return {
			layout: layout,
			activeTab,
			setActiveTab,
		}
	}, [layout, setActiveTab, activeTab])
	return (
		<TabsContext.Provider value={context}>
			<Box {...props} display="flex" flexDirection={layout} />
		</TabsContext.Provider>
	)
}

export function TabsList(props: BoxProps) {
	const { layout } = useTabsContext()
	const isVertical = layout === "row"
	return (
		<Box
			as="ul"
			flexDirection={isVertical ? "col" : undefined}
			borderRightWidth={isVertical ? "1" : undefined}
			borderBottomWidth={!isVertical ? "1" : undefined}
			className={classNames("tabs", {
				[tabsListClassName.horizontal]: !isVertical,
				[tabsListClassName.vertical]: isVertical,
			})}
			{...props}
		/>
	)
}

export function TabContent({
	active,
	id,
	unmountOnInactive,
	...props
}: React.ComponentProps<typeof Box> & {
	active?: boolean
	id?: string
	unmountOnInactive?: boolean
}) {
	const { activeTab } = useTabsContext()
	if (active === undefined && activeTab && id) {
		active = activeTab === String(id)
	}
	if (!active && unmountOnInactive) return null
	return <Box flex="1" minWidth="0" hidden={active === false} {...props} />
}

export function TabItem({
	active,
	onClick,
	id,
	...props
}: React.ComponentProps<typeof Box> & { active?: boolean; id?: string }) {
	const { activeTab, setActiveTab } = useTabsContext()
	if (active === undefined && activeTab && id) {
		active = activeTab === String(id)
	}
	return (
		<Box as="li" className={active ? "active" : undefined}>
			<Box<"a">
				href="#"
				onClick={
					onClick
						? (e) => {
								e.preventDefault()
								onClick(e as never)
						  }
						: id
						? (e) => {
								e.preventDefault()
								setActiveTab(String(id))
						  }
						: undefined
				}
				{...props}
				as="a"
			/>
		</Box>
	)
}
