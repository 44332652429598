import { Outlet } from "react-router-dom"
import { Quotes } from "../../../../../Trips"
import { useTripDetailsOutletContext } from "../_trip-details-context"

export default function TripQuotesLayoutPage() {
	const { trip, onChange } = useTripDetailsOutletContext()
	return (
		<Quotes trip={trip}>
			{({ defaultQuoteId }) => {
				return <Outlet context={{ trip, defaultQuoteId, onChange }} />
			}}
		</Quotes>
	)
}
