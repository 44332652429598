import { Box } from "@sembark-travel/ui/base"
import { Breadcrumbs } from "@sembark-travel/ui/router"
import { Helmet } from "react-helmet-async"
import { AddPriceForm } from "./../../../TransportServicePrices"
import { generatePath, useNavigate } from "../../../router-utils"

export default function NewTransportService() {
	const navigate = useNavigate()
	function navigateToList() {
		return navigate("/transport-service-prices", { replace: true })
	}
	return (
		<>
			<Helmet>
				<title>Add Transport Service Prices</title>
			</Helmet>
			<Breadcrumbs
				title="Add Price"
				items={[
					[generatePath("/transport-service-prices"), "Transportation Prices"],
					["", "Add Price"],
				]}
			/>
			<Box>
				<AddPriceForm onSuccess={navigateToList} onCancel={navigateToList} />
			</Box>
		</>
	)
}
