import React, { useEffect, useMemo } from "react"
import { useTransition, animated, config } from "@react-spring/web"
import { formatDate } from "@sembark-travel/datetime-utils"

import { Input } from "../Input"
import { useRootClose, useId } from "../react-hooks"
import DateTime from "./DateTime"
import { getDateTimeFormats } from "./utils"
import { pickerClassName, pickerDropdownClassName } from "./datetime.css"

interface IMenuProps {
	value?: string
	isVisible: boolean
	toggle: () => void
	show: () => void
	hide: () => void
	format: string
	dateFormat: string
	timeFormat: string
	id?: string
	readOnly?: boolean
	disabled?: boolean
}

function DefaultMenu({ value, toggle }: IMenuProps) {
	return (
		<button className="tpdt-picker-toggle btn" onClick={toggle}>
			{value || "Select A Date"}
		</button>
	)
}

interface IDateTimePickerProps extends React.ComponentProps<typeof DateTime> {
	renderMenu?: typeof DefaultMenu
	id?: string
	rightAlign?: boolean
	readOnly?: boolean
	disabled?: boolean
}

export default function DateTimePicker({
	renderMenu: Menu = DefaultMenu,
	id: propsId,
	rightAlign,
	readOnly,
	disabled,
	...props
}: IDateTimePickerProps) {
	const {
		value,
		dateFormat: configDateFormat,
		timeFormat: configTimeFormat,
	} = props

	const [isDropdownOpen, setDropdownVisibility] = React.useState<boolean>(false)
	const _id = useId()
	const id = propsId || _id

	// handle the outside close
	const containerRef = React.useRef<HTMLDivElement>(null)
	useRootClose(containerRef, () => {
		setDropdownVisibility(false)
	})

	const { dateTimeFormat, dateFormat, timeFormat } = React.useMemo(
		() => getDateTimeFormats(configDateFormat, configTimeFormat),
		[configDateFormat, configTimeFormat]
	)

	const formattedValue = useMemo(
		() => (value ? formatDate(value, dateFormat) : value),
		[value, dateFormat]
	)
	// hide the dropdown when values changes
	useEffect(() => {
		if (formattedValue && !timeFormat && dateFormat) {
			const id = setTimeout(() => {
				setDropdownVisibility(false)
			}, 100)
			return () => {
				clearTimeout(id)
			}
		}
		return () => undefined
	}, [formattedValue, dateFormat, timeFormat])

	const transitions = useTransition(isDropdownOpen, {
		config: config.stiff,
		from: { opacity: 0, transform: "translateY(-5px)" },
		enter: { opacity: 1, transform: "translateY(0)" },
		leave: { opacity: 0, transform: "translateY(-5px)" },
	})
	return (
		<div
			className={pickerClassName}
			data-placement={rightAlign ? "right" : "left"}
			ref={containerRef}
		>
			<Menu
				isVisible={isDropdownOpen}
				value={value ? formatDate(value, dateTimeFormat) : undefined}
				show={() => setDropdownVisibility(true)}
				hide={() => setDropdownVisibility(false)}
				format={dateTimeFormat}
				dateFormat={dateFormat}
				timeFormat={timeFormat}
				id={id}
				data-testid={id}
				readOnly={readOnly}
				disabled={disabled}
				toggle={() => {
					setDropdownVisibility(!isDropdownOpen)
				}}
			/>
			{transitions((style, item) =>
				item ? (
					<animated.div
						key={Number(item)}
						style={style}
						className={pickerDropdownClassName}
					>
						<label htmlFor={id}>
							<DateTime {...props} />
						</label>
					</animated.div>
				) : null
			)}
		</div>
	)
}

interface IDateInputMenuProps
	extends Pick<
		React.HTMLProps<HTMLInputElement>,
		"onBlur" | "placeholder" | "name" | "id"
	> {
	name?: string
}

function DateInputMenu({
	format,
	value,
	show,
	hide,
	toggle,
	isVisible,
	dateFormat,
	timeFormat,
	readOnly,
	disabled,
	...props
}: IDateInputMenuProps & IMenuProps) {
	return (
		<Input
			{...props}
			disabled={disabled}
			type="text"
			value={value}
			readOnly
			placeholder={format}
			onClick={(e) => {
				if (typeof document !== "undefined" && !readOnly && !disabled) {
					if (document.activeElement === e.target && !isVisible) {
						show()
					}
				}
			}}
			onFocus={() => {
				if (!readOnly && !disabled) {
					show()
				}
			}}
		/>
	)
}

interface IDateTimeInputProps
	extends IDateTimePickerProps,
		IDateInputMenuProps {}

export function DateTimeInput({
	onBlur,
	placeholder,
	name,
	...props
}: IDateTimeInputProps) {
	return (
		<DateTimePicker
			renderMenu={(props) => (
				<DateInputMenu
					{...props}
					onBlur={onBlur}
					name={name}
					placeholder={placeholder}
				/>
			)}
			{...props}
		/>
	)
}
