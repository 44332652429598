import { TenantRepositoryLayout } from "../../../../TenantProfile"
import { Outlet } from "react-router-dom"

export default function Layout() {
	return (
		<TenantRepositoryLayout>
			<Outlet />
		</TenantRepositoryLayout>
	)
}
