import { Container, Box, Spinner } from "@sembark-travel/ui/base"
import { Breadcrumbs } from "@sembark-travel/ui/router"
import { Helmet } from "react-helmet-async"
import CancelTrip from "../../../Trips/CancelTrip"
import { useParams, generatePath } from "../../../router-utils"
import { useTrip } from "../../../Trips"

export default function CancelTripRoute(
	props: Omit<
		React.ComponentProps<typeof CancelTrip>,
		"tripId" | "trip" | "navigate"
	>
) {
	const { tripId } = useParams("/trips/:tripId/cancel")
	const { trip } = useTrip(tripId)
	return (
		<>
			<Helmet>
				<title>Cancel Trip</title>
			</Helmet>
			<Breadcrumbs
				title="Cancel Trip"
				items={[
					[generatePath("/trips"), "Trips"],
					[generatePath("/trips/:tripId", { tripId }), trip?.name || tripId],
					["", "Cancel"],
				]}
			/>
			<Box>
				<Box paddingX="4" paddingY="8">
					<Box maxWidth="6xl" marginX="auto">
						<Box
							borderWidth="1"
							rounded="md"
							bgColor="warning"
							borderColor="warning"
						>
							<Container paddingY="6">
								{trip ? (
									<CancelTrip {...props} tripId={String(tripId)} trip={trip} />
								) : (
									<Spinner alignCenter padding="4" />
								)}
							</Container>
						</Box>
					</Box>
				</Box>
			</Box>
		</>
	)
}
