import {
	Spinner,
	Table,
	Stack,
	Text,
	Heading,
	Container,
	Button,
	Component,
	Inline,
	Icons,
	Box,
	Tabs,
	TabsList,
	TabItem,
	TabContent,
	joinAttributes,
} from "@sembark-travel/ui/base"
import { ListView, Search, useSearch } from "@sembark-travel/ui/list"
import { Breadcrumbs, useLocationQuery, Link } from "@sembark-travel/ui/router"
import { useXHR } from "@sembark-travel/xhr"
import pluralize from "pluralize"
import { useEffect, useState } from "react"
import useSWR from "swr"
import { generatePath } from "../../router-utils"
import { Dialog } from "@sembark-travel/ui/dialog"
import {
	Form,
	SubmissionError,
	TextInputField,
	withServerErrors,
} from "@sembark-travel/ui/form"
import { Optional } from "utility-types"
import {
	LatLongInputFields,
	MapLink,
	SynonumsNamesInputField,
} from "./FormInputFields"
import { worldXHR, TWorldCountry, TWorldState } from "../../World"
import { AirportsList } from "./Airports"
import { TimezonesList } from "./Timezones"
import { CurrenciesList } from "./Currencies"

export function CountryDetails({ countryId }: { countryId: string | number }) {
	const xhr = useXHR()
	const { data } = useSWR(`/admin/countries/${countryId}`, () =>
		worldXHR(xhr).getCountry<
			TWorldCountry & { states_count: number; cities_count: number }
		>(countryId, {
			include: "states_count,cities_count",
		})
	)
	if (!data) {
		return <Spinner alignCenter padding="4" />
	}
	const {
		id,
		name,
		region,
		iso2,
		subregion,
		emoji,
		states_count,
		cities_count,
	} = data
	return (
		<>
			<Breadcrumbs
				title={`${name}`}
				items={[
					[generatePath("/admin/world/countries"), "Countries"],
					["", iso2],
				]}
			/>
			<Stack gap="4">
				<Container paddingY="4" bgColor="default">
					<Stack gap="1">
						<Heading>
							{emoji} {name} ({iso2})
						</Heading>
						<Text>
							{joinAttributes(
								[subregion, region].filter(Boolean).join(", "),
								pluralize("State", states_count, true),
								pluralize("City", cities_count, true),
								`PH: ${data.phone_code}`,
								data.iso3,
								`Emoji Unc.: ${data.emojiU}`,
								data.latitude && data.longitude ? (
									<MapLink lat={data.latitude} long={data.longitude}>
										<Icons.LocationMarker />
									</MapLink>
								) : null
							)}
						</Text>
					</Stack>
				</Container>
				<Tabs defaultActive="states">
					<TabsList>
						<TabItem id="states">States</TabItem>
						<TabItem id="airports">Airports</TabItem>
						<TabItem id="timezones">Timezones</TabItem>
						<TabItem id="currencies">Currencies</TabItem>
					</TabsList>
					<TabContent id="states" unmountOnInactive key="states">
						<CountryStatesList countryId={id} key="states" />
					</TabContent>
					<TabContent id="airports" unmountOnInactive key="airports">
						<AirportsList country={data} key="airports" />
					</TabContent>
					<TabContent id="timezones" unmountOnInactive key="timezones">
						<TimezonesList countryId={id} key="timezones" />
					</TabContent>
					<TabContent id="currencies" unmountOnInactive key="currencies">
						<CurrenciesList countryId={id} />
					</TabContent>
				</Tabs>
			</Stack>
		</>
	)
}

function CountryStatesList({ countryId }: { countryId: string | number }) {
	const [query, setQuery] = useLocationQuery()
	const [searchParams, setSearchParams] = useSearch(query)
	useEffect(() => {
		setQuery(searchParams)
	}, [searchParams, setQuery])
	return (
		<Search
			title="States"
			initialParams={searchParams}
			onSearch={(params) => setSearchParams(params)}
			actions={() => (
				<Component initialState={false}>
					{({ state, setState }) => (
						<>
							<Button onClick={() => setState(true)}>New State</Button>
							<Dialog
								open={state}
								onClose={() => setState(false)}
								sm
								title="Add New state"
							>
								<Dialog.Body>
									<AddNewState
										countryId={countryId}
										onCancel={() => setState(false)}
										onSuccess={() => setState(false)}
									/>
								</Dialog.Body>
							</Dialog>
						</>
					)}
				</Component>
			)}
		>
			{() => (
				<ListView<TWorldState & { cities_count: number }>
					pageKey={`admin/world/countries/${countryId}/states`}
					params={searchParams}
					onPageChange={(page) => setSearchParams({ ...searchParams, page })}
					fetch={(xhr, params) =>
						worldXHR(xhr).getStates({
							...params,
							country_id: countryId,
							include: "cities_count",
						})
					}
				>
					{({ items, refresh }) => (
						<Table
							headers={["Code", "Name", "Cities", "Map", ""]}
							responsive
							hover
							bordered
							alignCols={{ 4: "right" }}
							rows={items.map((item) => [
								item.state_code,
								<Link
									to={generatePath("/admin/world/states/:stateId", {
										stateId: item.id.toString(),
									})}
									color="accent"
									fontWeight="semibold"
								>
									{item.name}
								</Link>,
								item.cities_count,
								<MapLink lat={item.latitude} long={item.longitude}>
									<Icons.LocationMarker />
								</MapLink>,
								<Component initialState={false}>
									{({ state, setState }) => (
										<>
											<Button
												onClick={() => setState(true)}
												size="sm"
												level="tertiary"
											>
												<Icons.Pencil />
											</Button>
											<Dialog
												open={state}
												onClose={() => setState(false)}
												sm
												title={`Edit State "${item.name}"`}
											>
												<Dialog.Body>
													<EditState
														state={item}
														onCancel={() => setState(false)}
														onSuccess={() => {
															refresh()
															setState(false)
														}}
													/>
												</Dialog.Body>
											</Dialog>
										</>
									)}
								</Component>,
							])}
						/>
					)}
				</ListView>
			)}
		</Search>
	)
}

function AddNewState(props: Omit<NewStateFormProps, "onSubmit">) {
	const xhr = useXHR()
	return (
		<NewStateForm
			onSubmit={(payload) => worldXHR(xhr).storeState(payload)}
			{...props}
		/>
	)
}

export function EditState({
	state,
	...props
}: Omit<NewStateFormProps, "onSubmit" | "countryId"> & { state: TWorldState }) {
	const xhr = useXHR()
	return (
		<NewStateForm
			countryId={state.country_id}
			initialValues={{
				name: state.name,
				state_code: state.state_code,
				synonyms_1: state.synonyms_1,
				synonyms_2: state.synonyms_2,
				synonyms_3: state.synonyms_3,
				latitude: state.latitude,
				longitude: state.longitude,
			}}
			onSubmit={(payload) => worldXHR(xhr).updateState(state.id, payload)}
			{...props}
		/>
	)
}
type NewStateFormData = {
	name: string
	state_code: string
	synonyms_1?: string | null
	synonyms_2?: string | null
	synonyms_3?: string | null
	country_id: number | string
	latitude?: string
	longitude?: string
}

type NewStateFormProps = {
	countryId: string | number
	onSubmit: (payload: object) => Promise<unknown>
	onSuccess?: () => void
	onCancel: () => void
	initialValues?: Optional<NewStateFormData>
}

function NewStateForm({
	countryId,
	onSubmit,
	onCancel,
	onSuccess,
	initialValues: propInitialValues,
}: NewStateFormProps) {
	const [initialValues] = useState<NewStateFormData>(() => ({
		name: "",
		synonyms_1: "",
		synonyms_2: "",
		synonyms_3: "",
		country_id: countryId,
		state_code: "",
		latitude: "",
		longitude: "",
		...propInitialValues,
	}))
	return (
		<Form<NewStateFormData>
			initialValues={initialValues}
			onSubmit={withServerErrors(async (values) => {
				await onSubmit(values)
				onSuccess?.()
			})}
			subscription={{ submitting: true }}
		>
			{({ submitting, handleSubmit }) => (
				<form onSubmit={handleSubmit} noValidate>
					<Stack gap="4">
						<Inline gap="4" flexWrap="wrap">
							<Box flex="1">
								<TextInputField
									name="name"
									type="text"
									label="Name"
									required
									placeholder="e.g. Rajasthan"
								/>
							</Box>
							<TextInputField
								name="state_code"
								type="text"
								label="State Code"
								required
								placeholder="e.g. RJ"
								style={{ maxWidth: "150px" }}
							/>
						</Inline>
						<LatLongInputFields />
						<SynonumsNamesInputField />
						<SubmissionError />
						<Inline gap="4">
							<Button type="submit" disabled={submitting}>
								{submitting ? "Please wait.." : "Save Details"}
							</Button>
							<Button disabled={submitting} onClick={() => onCancel()}>
								Cancel
							</Button>
						</Inline>
					</Stack>
				</form>
			)}
		</Form>
	)
}
