import { Breadcrumbs } from "@sembark-travel/ui/router"
import { Helmet } from "react-helmet-async"
import { UploadPricesForm } from "./../../../TransportServicePrices"
import { generatePath, useNavigate } from "../../../router-utils"
import { ForbidUnlessAuthorized, PERMISSIONS } from "../../../Auth"

export default function NewTransportService() {
	const navigate = useNavigate()
	function navigateToList() {
		return navigate("/transport-service-prices", { replace: true })
	}
	return (
		<>
			<Helmet>
				<title>Upload Transport Service Prices</title>
			</Helmet>
			<ForbidUnlessAuthorized
				permission={PERMISSIONS.UPLOAD_BULK_TRANSPORT_SERVICES}
			>
				<Breadcrumbs
					title="Upload Transportation Prices"
					items={[
						[
							generatePath("/transport-service-prices"),
							"Transportation Prices",
						],
						["", "Upload CSV"],
					]}
				/>
				<UploadPricesForm
					onSuccess={navigateToList}
					onCancel={navigateToList}
				/>
			</ForbidUnlessAuthorized>
		</>
	)
}
