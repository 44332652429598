import { Helmet } from "react-helmet-async"
import { useNavigate, useParams } from "../../../../router-utils"
import { EditRolePermissionsFrom } from "./../../../../Roles"

export default function EditRolePermissions() {
	const { roleId } = useParams("/roles/:roleId/edit-permissions")
	const navigate = useNavigate()
	function navigateBackToDetails() {
		return navigate("/roles/:roleId", { params: { roleId } })
	}
	return (
		<>
			<Helmet>
				<title>Edit Role's Permissions</title>
			</Helmet>
			<EditRolePermissionsFrom
				roleId={roleId}
				onSuccess={navigateBackToDetails}
				onCancel={navigateBackToDetails}
			/>
		</>
	)
}
