import { Box, Button, Text, Stack, Divider } from "@sembark-travel/ui/base"
import { useXHR, XHRInstance } from "@sembark-travel/xhr"
import * as Validator from "yup"
import config from "../config"
import {
	Form,
	validateFormValues,
	withServerErrors,
	SubmissionError,
	TextInputField,
} from "@sembark-travel/ui/form"
import { useMemo } from "react"

// schemas
export interface ISignupCredentials {
	name: string
	email: string
	password: string
	password_confirmation: string
	invite_token: string
}
export interface IAuthToken {
	access_token: string
	expires_in: number
}

const tenantSignupCredentialsSchema = Validator.object().shape({
	name: Validator.string()
		.required("Name field is required")
		.max(191, "Max 191 Characters allowed"),
	email: Validator.string()
		.email("Please provide a valid email address")
		.required("Email field is required"),
	password: Validator.string()
		.min(8, "Please select a password that is atleast 8 characters long")
		.required("Password field is required"),
	password_confirmation: Validator.string()
		.required("Password Confirmation field is required")
		.when("password", (value: string, schema: Validator.StringSchema) => {
			if (!value) return schema
			return schema.test(
				"is-matching",
				"Passwords don't match! Please enter the exact value that you entered in password field",
				(password_confirmation) => password_confirmation === value
			)
		}),
})
const INITIAL_VALUES: ISignupCredentials = {
	name: "",
	email: "",
	password: "",
	password_confirmation: "",
	invite_token: "",
}

// actions
function XHR(xhr: XHRInstance) {
	return {
		async signup(data: ISignupCredentials): Promise<{ message: string }> {
			return xhr.patch("/invited-users", data).then(({ data }) => data)
		},
	}
}

export function InvitedSignupForm({
	inviteToken,
	email,
	name,
	onSucess,
}: {
	inviteToken: string
	email: string
	name: string
	onSucess: (values: ISignupCredentials) => void
}) {
	const xhr = useXHR()
	const initialValues = useMemo(() => {
		return {
			...INITIAL_VALUES,
			invite_token: inviteToken,
			email: email,
			name: name,
		}
	}, [inviteToken, email, name])
	return (
		<Form<typeof initialValues>
			initialValues={initialValues}
			validate={validateFormValues(tenantSignupCredentialsSchema)}
			onSubmit={withServerErrors(async (values) => {
				const data = await XHR(xhr).signup(values)
				alert(data.message || "Registered Successfully. You can now login.")
				onSucess(values)
			})}
			subscription={{ submitting: true }}
		>
			{({ submitting, handleSubmit }) => (
				<form noValidate onSubmit={handleSubmit}>
					<input
						hidden
						type="hidden"
						name="invite_token"
						value={initialValues.invite_token}
					/>
					<Stack gap="4">
						<SubmissionError />
						<TextInputField
							label="Your full Name"
							name="name"
							autoFocus
							placeholder="John Ana"
							autoComplete="full-name"
							type="text"
							required
						/>
						<TextInputField
							label="Login Email"
							name="email"
							type="email"
							placeholder="username@domain.com"
							autoComplete="username email"
							help="This is the email that you will use to login into your account"
							disabled
						/>
						<TextInputField
							label="New Password"
							type="password"
							name="password"
							autoComplete="new-password"
							help="Please choose a strong password i.e. a combination of uppercase, lowercase and some special characters"
							required
						/>
						<TextInputField
							label="Retype New Password"
							type="password"
							name="password_confirmation"
							autoComplete="new-password"
							help="This is the password that you entered in the above field. It is just to make sure that you did not mistyped the password."
							required
						/>
						<Divider sm />
						<Box>
							<Button type="submit" fullWidth disabled={submitting}>
								{submitting ? "Please wait..." : "Complete Registration"}
							</Button>
						</Box>
					</Stack>
					<Box marginTop="8">
						<Text fontSize="sm" color="muted">
							By creating an account, you accept our{" "}
							<Text
								as="a"
								color="accent"
								href={config.privacyPolicyUrl}
								target="_blank"
							>
								Privacy
							</Text>{" "}
							and{" "}
							<Text
								as="a"
								href={config.termsOfServiceUrl}
								color="accent"
								target="_blank"
							>
								Terms of Service
							</Text>
							.
						</Text>
					</Box>
				</form>
			)}
		</Form>
	)
}
