import { useNavigate } from "./../../router-utils"
import { Box, Stack, Heading, Text } from "@sembark-travel/ui/base"
import { Link } from "@sembark-travel/ui/router"
import { Helmet } from "react-helmet-async"
import { ForgotPassword } from "./../../ForgotPassword"
import { useSearchParams } from "react-router-dom"

export default function ForgotPasswordPage() {
	const [searchParams] = useSearchParams()
	const navigate = useNavigate()
	const email = searchParams.get("email") || ""
	return (
		<>
			<Helmet>
				<title>Forgot Password</title>
			</Helmet>
			<Box paddingY="16">
				<Stack gap="2" textAlign="center" paddingBottom="8">
					<Heading as="h1">Forgot Your Password?</Heading>
					<Text>
						No worries. Just enter your email address and we will send
						instructions to reset your password. <br />
						Or{" "}
						<Link to="/login" color="accent" fontWeight="semibold">
							Login
						</Link>{" "}
						if you remember your password!
					</Text>
				</Stack>
				<Box maxWidth="sm" marginX="auto">
					<ForgotPassword
						email={email}
						resetPasswordLink={`${window.location.origin}/reset-password`}
						onSucess={() => {
							navigate("/login")
						}}
					/>
				</Box>
			</Box>
		</>
	)
}
