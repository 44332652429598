import { type ITenant as IBaseTenant } from "./../Admin/Tenants"
import { IAddress } from "./../Addresses"
import { TTripDestination } from "../TripDestinations"
import useSWR from "swr"
import { useXHR, XHRInstance } from "@sembark-travel/xhr"
import { TTenantBrand } from "../Brands"

export type ITenantAddress = IAddress & {
	trip_destinations?: Array<TTripDestination>
	tenant_brand?: TTenantBrand
}

export interface ITenant extends IBaseTenant {
	brands?: Array<TTenantBrand>
}

function XHR(xhr: XHRInstance) {
	return {
		async show(id: number | string): Promise<ITenant> {
			return xhr
				.get(`/tenants/${id}`, {
					params: {
						include: "users_count,brands",
					},
				})
				.then((resp) => resp.data.data)
		},
	}
}

export function useTenant(tenantId?: number | string) {
	const xhr = useXHR()
	const {
		data,
		error,
		mutate: revalidate,
		isValidating,
	} = useSWR(tenantId ? `/api/tenants/${tenantId}` : null, () =>
		tenantId ? XHR(xhr).show(tenantId) : Promise.reject("Please select an id")
	)
	const isFetching = !data && !error
	return {
		tenant: data,
		isFetching,
		isValidating,
		refresh: revalidate,
		error,
	}
}
