import { useXHR } from "@sembark-travel/xhr"
import {
	NewCustomFBAppIntegrationForm,
	NewIntegrationForm,
	type TIntegration,
} from "../../../../Integrations"
import { Box, Button, Container } from "@sembark-travel/ui/base"
import { Breadcrumbs } from "@sembark-travel/ui/router"
import { Helmet } from "react-helmet-async"
import { generatePath, useNavigate } from "../../../../router-utils"
import { useState } from "react"

export default function NewFacebookIntegrationPage() {
	const xhr = useXHR()
	const navigate = useNavigate()
	const [usingCustomAppFlow, setUsingCustomAppFlow] = useState<boolean>(true)
	return (
		<>
			<Helmet>
				<title>New FB Integration</title>
			</Helmet>
			<Breadcrumbs
				title="New FB Integration"
				items={[
					[generatePath("/org/integrations"), "Integrations"],
					["", "Facebook Integration"],
				]}
			/>
			<Box paddingX={{ md: "4" }} paddingY={{ md: "8" }}>
				{!usingCustomAppFlow ? (
					<Box maxWidth="3xl" marginX="auto">
						<Box paddingBottom="4" textAlign="right">
							<Button
								onClick={() => setUsingCustomAppFlow(true)}
								level="secondary"
								status="primary"
							>
								Use Custom FB App
							</Button>
						</Box>
						<Box borderWidth={{ md: "1" }} rounded="md" bgColor="default">
							<Container paddingY="6">
								<NewIntegrationForm
									initialValues={{
										name: "FB Ads",
										description: "Leads from Facebook Page's Ads",
									}}
									onSubmit={async (values) => {
										const integration = await xhr
											.post<{ data: TIntegration }>("integrations", {
												...values,
												facebook: {
													default: 1,
												},
											})
											.then((resp) => resp.data.data)
										navigate("/integrations/:integrationId", {
											params: {
												integrationId: String(integration.id),
											},
											replace: true,
										})
									}}
									onCancel={() => {
										navigate("/org/integrations", { replace: true })
									}}
								/>
							</Container>
						</Box>
					</Box>
				) : (
					<Box maxWidth="6xl" marginX="auto">
						<Box borderWidth={{ md: "1" }} rounded="md" bgColor="default">
							<Container paddingY="6">
								<NewCustomFBAppIntegrationForm
									initialValues={{
										name: "FB Ads",
										description: "Leads from Facebook Page's Ads",
									}}
									onSubmit={async (values) => {
										const integration = await xhr
											.post<{ data: TIntegration }>("integrations", {
												...values,
											})
											.then((resp) => resp.data.data)
										navigate("/integrations/:integrationId", {
											params: {
												integrationId: String(integration.id),
											},
											replace: true,
										})
									}}
									onCancel={() => {
										navigate("/org/integrations", { replace: true })
									}}
								/>
							</Container>
						</Box>
					</Box>
				)}
			</Box>
		</>
	)
}
