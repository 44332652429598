import { Container, Box } from "@sembark-travel/ui/base"
import {
	useGetBackUrlFromLocation,
	Breadcrumbs,
} from "@sembark-travel/ui/router"
import { Helmet } from "react-helmet-async"
import { generatePath, useNavigate, useParams } from "../../../../router-utils"
import { EditTermsAndConditionItem } from "../../../../TermsAndConditions"
import { ForbidUnlessAuthorized, PERMISSIONS } from "../../../../Auth"

export default function EditTermsAndConditionItemPage(
	props: Omit<
		React.ComponentProps<typeof EditTermsAndConditionItem>,
		"tncId" | "onSuccess"
	>
) {
	const { tncId } = useParams("/terms-and-conditions/:tncId")
	const navigate = useNavigate()
	const backFromUrl = useGetBackUrlFromLocation()
	return (
		<>
			<Helmet>
				<title>Edit Terms and Condition</title>
			</Helmet>
			<ForbidUnlessAuthorized permission={PERMISSIONS.MANAGE_TRIP_OWNERS}>
				<Breadcrumbs
					title="Edit Terms"
					items={[
						[generatePath("/org/terms-and-conditions"), "Terms"],
						[generatePath("/terms-and-conditions/:tncId", { tncId }), tncId],
						["", "Edit"],
					]}
				/>
				<Box paddingX="4" paddingY="8">
					<Box maxWidth="4xl" marginX="auto">
						<Box borderWidth="1" rounded="md" bgColor="default">
							<Container paddingY="6">
								<EditTermsAndConditionItem
									tncId={tncId}
									{...props}
									onSuccess={(tnc) =>
										navigate("/terms-and-conditions/:tncId", {
											params: {
												tncId: tnc.id.toString(),
											},
											replace: true,
										})
									}
									onCancel={() =>
										backFromUrl
											? navigate(backFromUrl as "/org/terms-and-conditions", {
													replace: true,
											  })
											: navigate("/terms-and-conditions/:tncId", {
													params: { tncId },
													replace: true,
											  })
									}
								/>
							</Container>
						</Box>
					</Box>
				</Box>
			</ForbidUnlessAuthorized>
		</>
	)
}
