import { Stack, Text } from "@sembark-travel/ui/base"
import { CommentableComments as CommentsList } from "./../Comments"

export function AllComments({ tripId }: { tripId: number }) {
	return (
		<Stack gap="4">
			<Text color="muted">
				All trip related comments (actionable, resolved, unresolved etc)
			</Text>
			<CommentsList commentableId={tripId} commentableType="trips" />
		</Stack>
	)
}

export function DemandingComment({ tripId }: { tripId: number }) {
	return (
		<Stack gap="4">
			<Text color="muted">
				Comments that requires attention and needs to be resolved
			</Text>
			<CommentsList commentableId={tripId} commentableType="trips" demanding />
		</Stack>
	)
}

export function ActionableComment({ tripId }: { tripId: number }) {
	return (
		<Stack gap="4">
			<Text color="muted">
				All actionable comments (resolved and unresolved)
			</Text>
			<CommentsList commentableId={tripId} commentableType="trips" actionable />
		</Stack>
	)
}

export function OverdueComment({ tripId }: { tripId: number }) {
	return (
		<Stack gap="4">
			<Text color="muted">
				Comments that are overdue and needs to be resolved
			</Text>
			<CommentsList commentableId={tripId} commentableType="trips" overdue />
		</Stack>
	)
}
