import {
	Stack,
	Button,
	Heading,
	Inline,
	Box,
	Spinner,
	useTimeout,
	CheckboxInput,
	RadioInput,
	Text,
	joinAttributes,
	Grid,
	Col,
	Divider,
	Badge,
	Icons,
} from "@sembark-travel/ui/base"
import {
	ErrorMessage,
	Form,
	FormSpy,
	GetFieldValue,
	SubmissionError,
	TextInputField,
	useFieldValue,
	validateFormValues,
	withServerErrors,
} from "@sembark-travel/ui/form"
import { extendXHRInstance, IListResponse, useXHR } from "@sembark-travel/xhr"
import { useEffect, useRef, useState } from "react"
import useSWR from "swr"
import { IHotel } from "./store"
import { Address } from "./Item"
import { showSnackbar } from "@sembark-travel/ui/snackbar"
import * as Validator from "yup"
import {
	ConfirmationDialogProvider,
	ConfirmationDialog,
} from "@sembark-travel/ui/dialog"

type TMergeHotelsFormData = {
	q?: string
	primary: IHotel
	duplicates: Array<IHotel>
}

const validate = validateFormValues(
	Validator.object().shape({
		duplicates: Validator.array().min(
			2,
			"Please select atleast 2 duplicate hotels"
		),
		primary: Validator.mixed().required("Please select a primary hotel"),
	})
)

export function MergeHotels({
	onSuccess,
	onCancel,
}: {
	onSuccess: (primary: IHotel) => void
	onCancel: () => void
}) {
	const initialValues: Partial<TMergeHotelsFormData> = useRef({
		q: "",
		primary: undefined,
		duplicates: [],
	}).current
	const xhr = useXHR()
	const [mergeMoreCount, incrementMergeMoreCount] = useState<number>(0)
	return (
		<Stack gap="6">
			<Heading>Merge Duplicate Hotels</Heading>
			<ConfirmationDialogProvider key={mergeMoreCount}>
				{({ confirm }) => (
					<Form<TMergeHotelsFormData>
						validate={validate}
						initialValues={initialValues}
						onSubmit={withServerErrors(async (values) => {
							const { primary, duplicates } = values
							if (await confirm()) {
								const { message } = await xhr
									.post<{ message: string }>("/hotels/merge", {
										primary: primary?.id,
										duplicates: duplicates.map((d) => d.id),
									})
									.then((resp) => resp.data)
								showSnackbar(message)
								if (
									window.confirm("Do you want to merge more duplicate hotels ?")
								) {
									incrementMergeMoreCount((count) => count + 1)
								} else {
									onSuccess(primary)
								}
							}
						})}
						subscription={{ submitting: true }}
					>
						{({ submitting, handleSubmit }) => (
							<form onSubmit={handleSubmit}>
								<Stack gap="4">
									<Grid gap="8">
										<Col sm={12} md>
											<Stack gap="4" position="relative">
												<Stack
													gap="4"
													position="sticky"
													top="0"
													bgColor="default"
												>
													<Stack gap="1">
														<Heading fontSize="md">Duplicate Hotels</Heading>
														<Text color="muted">
															Please select the duplicate hotels.
														</Text>
													</Stack>
													<TextInputField
														type="text"
														name="q"
														placeholder="Type 3 or more characters to search..."
														autoFocus
													/>
												</Stack>
												<GetFieldValue<
													TMergeHotelsFormData["duplicates"]
												> name="duplicates">
													{({
														value: duplicateHotels,
														onChange: setDuplicateHotels,
													}) => (
														<Stack>
															<GetFieldValue<string> name="q">
																{({ value: q }) => (
																	<HotelsList q={q}>
																		{({ hotels }) => (
																			<Stack
																				borderWidth="1"
																				rounded="md"
																				style={{
																					maxHeight: "300px",
																					overflow: "auto",
																				}}
																			>
																				{duplicateHotels.map((h, index) => (
																					<Box
																						key={h.id}
																						borderTopWidth={
																							index > 0 ? "1" : undefined
																						}
																					>
																						<Inline
																							gap="4"
																							as="label"
																							paddingY="2"
																							paddingX="4"
																						>
																							<CheckboxInput
																								checked
																								onChange={() =>
																									setDuplicateHotels(
																										duplicateHotels.filter(
																											(s) => s.id !== h.id
																										)
																									)
																								}
																							/>
																							<Box flex="1">{h.name}</Box>
																						</Inline>
																					</Box>
																				))}
																				{hotels
																					// remove selected items
																					.filter(
																						(h) =>
																							!duplicateHotels.find(
																								(d) => d.id === h.id
																							)
																					)
																					.map((h, index) => (
																						<Box
																							key={h.id}
																							borderTopWidth={
																								index > 0 ||
																								duplicateHotels.length
																									? "1"
																									: undefined
																							}
																						>
																							<Inline
																								gap="4"
																								as="label"
																								paddingY="2"
																								paddingX="4"
																							>
																								<CheckboxInput
																									checked={false}
																									onChange={() =>
																										setDuplicateHotels(
																											duplicateHotels.concat([
																												h,
																											])
																										)
																									}
																								/>
																								<Box flex="1">
																									<HotelItem hotel={h} />
																								</Box>
																							</Inline>
																						</Box>
																					))}
																			</Stack>
																		)}
																	</HotelsList>
																)}
															</GetFieldValue>
														</Stack>
													)}
												</GetFieldValue>
												<ErrorMessage name="duplicates" />
											</Stack>
										</Col>
										<Col sm={12} md={6}>
											<SelectPrimaryHotelField />
										</Col>
									</Grid>
									<Divider sm />
									<SubmissionError />
									<Inline gap="4">
										<Button type="submit" disabled={submitting}>
											{submitting ? "Merging..." : "Merge Hotels"}
										</Button>
										<Button onClick={() => onCancel()} disabled={submitting}>
											Cancel
										</Button>
									</Inline>
									<ConfirmationDialog
										title="Merge Hotels Confirmation"
										acceptActionLabel="Yes, Merge these hotels"
									>
										<Stack gap="6">
											<FormSpy<TMergeHotelsFormData>
												subscription={{ values: true }}
											>
												{({ values }) => (
													<Stack borderWidth="1" rounded="md" overflow="hidden">
														{[values.primary]
															.concat(
																values.duplicates.filter(
																	(d) => d.id !== values.primary.id
																)
															)
															.map((d, i) => {
																const isPrimary = d.id === values.primary.id
																return (
																	<Box
																		key={d.id}
																		bgColor={isPrimary ? "primary" : undefined}
																		padding="2"
																		borderTopWidth={i > 0 ? "1" : undefined}
																	>
																		<Stack gap="1">
																			{isPrimary ? (
																				<Text>
																					<Badge primary>Primary Hotel</Badge>
																				</Text>
																			) : null}
																			<HotelItem hotel={d} />
																		</Stack>
																	</Box>
																)
															})}
													</Stack>
												)}
											</FormSpy>
											<Stack gap="2">
												<Heading fontSize="md" color="warning">
													Merging Hotels will:
												</Heading>
												<Stack
													gap="2"
													as="ul"
													paddingLeft="4"
													listStyleType="disc"
												>
													{[
														"Replace all existing usage of duplicates with primary Hotels (quotes, hotel bookings, payments and accounting)",
														"Disable duplicate hotels",
													].map((result) => (
														<Box as="li" key={result}>
															<Text>{result}</Text>
														</Box>
													))}
													<Box as="li" color="warning">
														<Icons.AttentionSolid /> This is an irreversible
														process.
													</Box>
												</Stack>
											</Stack>
											<Text fontWeight="semibold" color="warning">
												Are you you sure you want to continue ?
											</Text>
										</Stack>
									</ConfirmationDialog>
								</Stack>
							</form>
						)}
					</Form>
				)}
			</ConfirmationDialogProvider>
		</Stack>
	)
}

function SelectPrimaryHotelField() {
	const { value: duplicateHotels } =
		useFieldValue<TMergeHotelsFormData["duplicates"]>("duplicates")
	const { value: primary_hotel, onChange: changePrimaryHotel } =
		useFieldValue<TMergeHotelsFormData["primary"]>("primary")
	const resetPrimaryHotel = Boolean(
		duplicateHotels.length <= 1 && primary_hotel
	)
	useEffect(() => {
		if (resetPrimaryHotel) {
			changePrimaryHotel(undefined)
		}
	}, [resetPrimaryHotel, changePrimaryHotel])

	return (
		<Stack gap="4" position="relative">
			<Stack gap="4" position="sticky" bgColor="default" top="0" zIndex="10">
				<Stack gap="1">
					<Heading fontSize="md">Primary/Master Hotel</Heading>
					<Text color="muted">
						Please select the primary/master hotel of duplicates
					</Text>
				</Stack>
			</Stack>
			{duplicateHotels.length <= 1 ? (
				<Box padding="4" bgColor="inset" rounded="md">
					<Text>Please select some duplicates to select a primary hotel</Text>
				</Box>
			) : (
				<Stack borderWidth="1" rounded="md">
					{duplicateHotels.map((h, index) => {
						const selected = h.id === primary_hotel?.id
						return (
							<Box key={h.id} borderTopWidth={index > 0 ? "1" : undefined}>
								<Inline
									gap="4"
									as="label"
									paddingY="2"
									paddingX="4"
									bgColor={selected ? "primary" : { hover: "subtle" }}
								>
									<RadioInput
										checked={selected}
										onChange={(e) => {
											const checked = e.currentTarget.checked
											changePrimaryHotel(checked ? h : undefined)
										}}
									/>
									<Box flex="1">
										<HotelItem hotel={h} />
									</Box>
								</Inline>
							</Box>
						)
					})}
				</Stack>
			)}
			<ErrorMessage name="primary" />
		</Stack>
	)
}

function HotelsList({
	q: propsQ,
	children,
}: {
	q: string
	children: (props: { hotels: Array<IHotel> }) => React.ReactNode
}) {
	const [q, setQ] = useState(propsQ)
	const { set: setTimeout, clear: clearTimeout } = useTimeout()
	useEffect(() => {
		setTimeout(() => {
			setQ(propsQ)
		}, 1000)
		return clearTimeout
	}, [propsQ, setTimeout, clearTimeout])
	const xhr = useXHR()
	const abortController = useRef<AbortController>()
	const { data: resp } = useSWR(
		q.length > 2 ? `/hotel-merge?${q}` : null,
		async () => {
			// abort any pending requests
			abortController.current?.abort()
			abortController.current = new AbortController()
			const apiInstance = extendXHRInstance(xhr, {
				signal: abortController.current.signal,
			})
			return apiInstance
				.get<IListResponse<IHotel>>(`/hotels`, { params: { q, limit: 10 } })
				.then((resp) => resp.data)
		}
	)
	if (q.length <= 2) {
		return <>{children({ hotels: [] })}</>
	}
	if (!resp) {
		return <Spinner padding="4" alignCenter />
	}
	const { data } = resp
	return <>{children({ hotels: data })}</>
}

function HotelItem({ hotel }: { hotel: IHotel }) {
	const { name, address, stars_string, group } = hotel
	return (
		<Inline gap="2" justifyContent="between" fontWeight="normal">
			<Stack gap="px">
				<Text fontWeight="semibold">{name}</Text>
				<Text color="muted">{joinAttributes(stars_string, group?.name)}</Text>
			</Stack>
			<Address address={address} />
		</Inline>
	)
}
