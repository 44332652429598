import {
	Button,
	Inline,
	Stack,
	Heading,
	Text,
	Col,
	Divider,
	Grid,
} from "@sembark-travel/ui/base"
import { Dialog } from "@sembark-travel/ui/dialog"
import { useXHR } from "@sembark-travel/xhr"
import {
	Form,
	TextInputField,
	withServerErrors,
	SubmissionError,
	validateFormValues,
} from "@sembark-travel/ui/form"
import { useMemo } from "react"
import { Omit, Optional, Required } from "utility-types"
import * as Validator from "yup"
import { ICab } from "./store"

type NewCabFormProps = {
	onSubmit: (values: NewCabCredentials) => Promise<ICab>
	onCancel?: () => void
	numberPlate?: string
}

type NewCabProps = Omit<NewCabFormProps, "onSubmit"> & {
	onSuccess: (cab: ICab) => void
}

export function NewCab({ onSuccess, ...props }: NewCabProps) {
	const xhr = useXHR()
	return (
		<NewCabForm
			onSubmit={async (values) => {
				const { number_plate, name } = values
				const resp = await xhr.post("/cabs", {
					name,
					number_plate,
				})
				const cab: ICab = resp.data.data
				onSuccess?.(cab)
				return cab
			}}
			{...props}
		/>
	)
}

export interface NewCabCredentials {
	name: string
	number_plate: string
}
const newCabSchema = validateFormValues(
	Validator.object().shape({
		name: Validator.string().required("Name for the cab is required."),
		number_plate: Validator.string()
			.required("Number Plate is required")
			.max(299, "Maximum 299 characters allowed"),
	})
)

function NewCabForm({ onSubmit, onCancel, numberPlate }: NewCabFormProps) {
	const initialValues: NewCabCredentials = useMemo(() => {
		return {
			name: "",
			number_plate: numberPlate || "",
		}
	}, [numberPlate])
	return (
		<Form<NewCabCredentials>
			initialValues={initialValues}
			validate={newCabSchema}
			onSubmit={withServerErrors(async (values) => {
				await onSubmit(values)
			})}
			subscription={{ submitting: true }}
		>
			{({ submitting, handleSubmit, form }) => (
				<form noValidate onSubmit={handleSubmit}>
					<Grid gap="4">
						<Col xs={12} sm={4}>
							<Stack gap="1">
								<Heading as="h4">Cab Identification</Heading>
								<Text color="muted">
									Please provide the number plate and exact name details for the
									cab.
								</Text>
							</Stack>
						</Col>
						<Col>
							<Grid gap="4">
								<Col xs={12} sm={6}>
									<TextInputField
										label="Number Plate"
										name="number_plate"
										required
										placeholder="RJ18 CC 7838"
										type="text"
										onChange={(e) => {
											form.change(
												"number_plate",
												(e.currentTarget.value || "").toUpperCase()
											)
										}}
									/>
								</Col>
								<Col xs={12} sm="auto">
									<TextInputField
										label="Exact Name"
										name="name"
										required
										placeholder="Wagon R"
										type="text"
									/>
								</Col>
							</Grid>
						</Col>
					</Grid>
					<Divider sm />
					<Grid>
						<Col xs={12} sm={{ offset: 4, span: 8 }}>
							<Stack gap="4">
								<SubmissionError />
								<Inline gap="4">
									<Button type="submit" disabled={submitting}>
										{submitting ? "Saving.." : "Save Details"}
									</Button>
									{onCancel ? (
										<Button
											onClick={onCancel}
											level="tertiary"
											disabled={submitting}
										>
											Cancel
										</Button>
									) : null}
								</Inline>
							</Stack>
						</Col>
					</Grid>
				</form>
			)}
		</Form>
	)
}

export function AddCabInDialog({
	open,
	onSuccess,
	onCancel,
	...props
}: Required<Optional<NewCabProps, "onSuccess">, "onCancel"> & {
	open: boolean
}) {
	return (
		<Dialog open={open} onClose={onCancel} title="Add New Cab">
			<Dialog.Body>
				<NewCab
					onSuccess={onSuccess || onCancel}
					onCancel={onCancel}
					{...props}
				/>
			</Dialog.Body>
		</Dialog>
	)
}
