import {
	Alert,
	Badge,
	Box,
	Button,
	Inline,
	Table,
	Heading,
	Stack,
	TableDataCell,
	Card,
	Col,
	Divider,
	Grid,
	Spinner,
	Icons,
	Money,
	Text,
} from "@sembark-travel/ui/base"
import { useDialog, Dialog } from "@sembark-travel/ui/dialog"
import {
	utcTimestampToLocalDate,
	utcTimestampToLocalDateString,
	dateToUTCString,
	fromNow,
	isBefore,
	formatDate,
	endOf,
} from "@sembark-travel/datetime-utils"
import { numberToLocalString } from "@sembark-travel/number-utils"
import { useXHR, XHRInstance } from "@sembark-travel/xhr"
import {
	Form,
	SelectField,
	DatePickerField,
	TextInputField,
	SubmissionError,
	SelectInputField,
	validateFormValues,
	withServerErrors,
	GetFieldValue,
	EmptyNumberValidator,
} from "@sembark-travel/ui/form"
import React, { useMemo } from "react"
import useSWR from "swr"
import * as Validator from "yup"
import { SelectAccount, IAccount } from "../Accounting"
import { InstalmentContact } from "./InstalmentContact"
import { IInstalment, IPayment } from "./store"
import { useAuthUser } from "../Auth"
import {
	convertMoneyToCurrency,
	currencyPairToHuman,
	formatMoneyByDecimal,
	makeCurrencyPair,
	moneyParseByDecimal,
} from "@sembark-travel/money"

function XHR(xhr: XHRInstance) {
	return {
		async editableInstalmentDetails(id: number, params?: unknown) {
			return xhr
				.get(`/instalments/${id}/edit`, { params })
				.then((resp) => resp.data.data)
		},
		async logTransaction(data: unknown): Promise<IPayment> {
			return xhr
				.post("/payment-transactions", data)
				.then((resp) => resp.data.data)
		},
	}
}

export function LogTransaction({
	instalment,
	onChange,
	children = ({ log }) => (
		<Button level="secondary" onClick={log} size="sm">
			Log Payment
		</Button>
	),
}: {
	instalment: IInstalment
	onChange?: () => void
	children?: (props: { log: () => void }) => React.ReactNode
}) {
	const xhr = useXHR()
	const [dialogOpen, open, close] = useDialog()
	return (
		<>
			{children({ log: open })}
			<Dialog open={dialogOpen} onClose={close} title="Log Payment">
				<Dialog.Body>
					<LogTransactionForm
						instalmentId={instalment.id}
						onSubmit={async (data) => {
							await XHR(xhr).logTransaction(data)
							close()
							onChange && onChange()
						}}
						onCancel={close}
					/>
				</Dialog.Body>
			</Dialog>
		</>
	)
}

interface ILogData {
	instalment_id: number
	paid_amount: number
	functional_currency: string
	paid_amount_in_functional_currency: number
	paid_at: string
	reference_id?: string
	move_remaining_to?: number
	next_due_at?: string
	next_due_at_local?: string
	debit_account_id: number
	credit_account_id: number
}

export function LogTransactionForm({
	instalmentId,
	onSubmit,
	onCancel,
}: {
	instalmentId: number
	onSubmit: (data: ILogData) => Promise<void>
	onCancel?: () => void
}) {
	const xhr = useXHR()
	const { data: instalment } = useSWR<
		IInstalment & {
			debitable_accounts: Array<IAccount>
			creditable_accounts: Array<IAccount>
			can_edit_amount: boolean
		}
	>(
		`/instalments/${instalmentId}/edit`,
		() => XHR(xhr).editableInstalmentDetails(instalmentId),
		{ revalidateOnFocus: false }
	)
	const { user } = useAuthUser()
	const functional_currency = user?.tenant?.functional_currency
	const { initialValues, otherDueInstalments } = useMemo(() => {
		const otherDueInstalments = getOtherDueInstalment(instalment)
		return {
			initialValues: {
				currency: instalment?.currency || "INR",
				paid_amount: instalment?.amount || 0,
				functional_currency: functional_currency || "INR",
				currency_to_functional_exchange_rate:
					instalment?.currency === functional_currency ? 1 : 0,
				paid_amount_in_functional_currency:
					instalment?.currency === functional_currency
						? instalment?.amount || 0
						: 0,
				paid_at: undefined as Date | undefined,
				reference_id: "",
				debit_account: instalment?.debitable_accounts[0],
				credit_account: instalment?.creditable_accounts[0],
				move_remaining_to: otherDueInstalments.length
					? otherDueInstalments[0].id
					: "",
				next_due_at: new Date(),
			},
			otherDueInstalments,
		}
	}, [instalment, functional_currency])
	if (!instalment) {
		return <Spinner alignCenter padding="4" />
	}
	const { can_edit_amount, amount } = instalment
	return (
		<Box key={amount}>
			<Card marginBottom="8">
				<Box padding="4" roundedTop="md" bgColor="subtle" borderBottomWidth="1">
					<Heading as="h4" fontSize="base">
						Please review the full payment details
					</Heading>
				</Box>
				<Box padding="4">
					<InstalmentOverview instalment={instalment} />
				</Box>
			</Card>
			<Stack gap="4">
				<Heading as="h4">Payment Transaction details</Heading>
				<Form<typeof initialValues>
					initialValues={initialValues}
					validate={validateFormValues(
						Validator.object()
							.required()
							.shape({
								currency: Validator.string().required(
									"Please provide the currency of the instalment."
								),
								functional_currency: Validator.string().required(
									"Please provide a currency of payment/transaction"
								),
								paid_amount: EmptyNumberValidator()
									.positive("Amount should be positive")
									.max(
										initialValues.paid_amount,
										"Amount can not be greater than instalment"
									),
								paid_amount_in_functional_currency: EmptyNumberValidator()
									.required("Please provide the amount in mentioned currency.")
									.positive("Please provide a value."),
								paid_at: Validator.date().required(
									"Please provide a date when the payment was made"
								),
								reference_id: Validator.string(),
								move_remaining_to: EmptyNumberValidator(),
								debit_account: Validator.object().required(
									"Please select a debit account from which the payment is made"
								),
								credit_account: Validator.object().required(
									"Please select a credit account where the payment is made"
								),
								next_due_at: Validator.date().when(
									["paid_amount", "move_remaining_to"],
									((
										paidAmount: number,
										move_remaining_to: number | "",
										schema: Validator.AnyObjectSchema
									) => {
										if (
											Number(paidAmount) < initialValues.paid_amount &&
											!move_remaining_to
										) {
											return schema.required(
												"Please provide a due date for the remaining due amount"
											)
										}
										return schema.nullable()
									}) as never
								),
							})
					)}
					onSubmit={withServerErrors(
						async ({
							currency,
							paid_amount,
							functional_currency,
							paid_amount_in_functional_currency,
							reference_id,
							move_remaining_to,
							next_due_at,
							debit_account,
							credit_account,
							paid_at,
						}) => {
							if (!debit_account || !credit_account) {
								throw Error(
									"Please fill the required data e.g. debit/credit accounts."
								)
							}
							await onSubmit({
								instalment_id: instalment.id,
								paid_amount,
								functional_currency,
								paid_amount_in_functional_currency:
									currency === functional_currency
										? paid_amount
										: paid_amount_in_functional_currency,
								paid_at: paid_at
									? dateToUTCString(paid_at)
									: dateToUTCString(new Date()),
								reference_id,
								move_remaining_to:
									typeof move_remaining_to === "number"
										? move_remaining_to
										: undefined,
								debit_account_id: debit_account.id,
								credit_account_id: credit_account.id,
								next_due_at: move_remaining_to
									? undefined
									: next_due_at
										? dateToUTCString(endOf(next_due_at, "day"))
										: undefined,
								next_due_at_local: move_remaining_to
									? undefined
									: next_due_at
										? formatDate(next_due_at, "YYYY-MM-DD")
										: undefined,
							})
						}
					)}
					subscription={{ submitting: true }}
				>
					{({ submitting, handleSubmit, form }) => (
						<form noValidate onSubmit={handleSubmit}>
							<Stack gap="4">
								<SubmissionError />
								<Grid gap="4">
									<Col>
										<SelectField
											label="Debit Account"
											select={SelectAccount}
											name="debit_account"
											defaultOptions={instalment.debitable_accounts}
										/>
									</Col>
									<Col>
										<SelectField
											label="Credit Account"
											select={SelectAccount}
											name="credit_account"
										/>
									</Col>
								</Grid>
								<Divider sm />
								<Grid gap="4">
									<Col xs={12} sm="auto">
										<GetFieldValue<string> name="currency">
											{({ value: currency }) => (
												<Inline gap="4" collapseBelow="md">
													<Box flex="1" style={{ maxWidth: "150px" }}>
														<TextInputField
															label={`Paid Amount (${currency})`}
															name="paid_amount"
															type="number"
															min={0}
															max={initialValues.paid_amount}
															required
															readOnly={!can_edit_amount}
														/>
													</Box>
													<GetFieldValue<string> name="functional_currency">
														{({ value: functional_currency }) =>
															currency !== functional_currency ? (
																<GetFieldValue<number> name="paid_amount">
																	{({ value: paidAmount }) => (
																		<Inline>
																			<TextInputField
																				label={`Exchange (${currency}/${functional_currency})`}
																				name="currency_to_functional_exchange_rate"
																				type="number"
																				placeholder="Exchange Rate"
																				min={0}
																				required
																				style={{ maxWidth: "150px" }}
																				readOnly={!can_edit_amount}
																				help={({ value }) =>
																					value !== "" ? (
																						<Text>
																							{currencyPairToHuman(
																								makeCurrencyPair(
																									currency,
																									functional_currency,
																									Number(value) || 0
																								)
																							)}
																						</Text>
																					) : null
																				}
																				onChange={(e) => {
																					const value = e.currentTarget.value
																					form.change(
																						"currency_to_functional_exchange_rate",
																						value as never
																					)
																					if (!value) {
																						form.change(
																							"paid_amount_in_functional_currency",
																							0
																						)
																					} else {
																						form.change(
																							"paid_amount_in_functional_currency",
																							formatMoneyByDecimal(
																								convertMoneyToCurrency(
																									moneyParseByDecimal(
																										paidAmount || 0,
																										currency
																									),
																									functional_currency,
																									Number(value || 0) || 0
																								)
																							) as never
																						)
																					}
																				}}
																			/>
																			<Box paddingX="2" paddingTop="6">
																				<Icons.SwitchHorizontal />
																			</Box>
																			<TextInputField
																				label={`Amount (${functional_currency})`}
																				name="paid_amount_in_functional_currency"
																				type="number"
																				min={0}
																				required
																				placeholder="Amount"
																				style={{ maxWidth: "150px" }}
																				readOnly={!can_edit_amount}
																				onChange={(e) => {
																					const value = e.currentTarget.value
																					form.change(
																						"paid_amount_in_functional_currency",
																						value as never
																					)
																					if (!value) {
																						form.change(
																							"currency_to_functional_exchange_rate",
																							0
																						)
																					} else {
																						form.change(
																							"currency_to_functional_exchange_rate",
																							Number(
																								Number(value || 0) /
																									Number(paidAmount || 1)
																							).toFixed(3) as never
																						)
																					}
																				}}
																			/>
																		</Inline>
																	)}
																</GetFieldValue>
															) : null
														}
													</GetFieldValue>
												</Inline>
											)}
										</GetFieldValue>
									</Col>
									<Col>
										<DatePickerField
											label="Paid on"
											name="paid_at"
											dateFormat
											timeFormat
											required
											rightAlign
										/>
									</Col>
								</Grid>
								<GetFieldValue<number> name="paid_amount">
									{({ value: paidAmount }) =>
										paidAmount < initialValues.paid_amount ? (
											<Stack
												borderWidth="1"
												padding="4"
												rounded="lg"
												gap="4"
												bgColor="primary"
											>
												<Box>
													What to do with the remaining{" "}
													<Money
														fontWeight="semibold"
														currency={initialValues.currency}
														showCurrency
														amount={initialValues.paid_amount - paidAmount}
													/>{" "}
													?
												</Box>
												{otherDueInstalments.length ? (
													<Box>
														<SelectInputField
															name="move_remaining_to"
															label="Select a due instalment to move the remaining amount"
														>
															<option value="">Create new instalment</option>
															{otherDueInstalments.map((i) => (
																<option value={i.id} key={i.id}>
																	#{i.id} -{" "}
																	{utcTimestampToLocalDateString(i.due_at)} (
																	{numberToLocalString(i.amount)} +{" "}
																	{initialValues.paid_amount - paidAmount})
																</option>
															))}
														</SelectInputField>
													</Box>
												) : null}
												<GetFieldValue<
													number | undefined
												> name="move_remaining_to">
													{({ value: moveRemainingTo }) =>
														!moveRemainingTo ? (
															<Stack gap="4">
																<Alert>
																	{!otherDueInstalments.length
																		? `There are no other due instalments for this payment. `
																		: ""}
																	A new instalment will be created with the
																	remaining amount. Please provide a due date
																	for the same.
																</Alert>
																<Box>
																	<DatePickerField
																		name="next_due_at"
																		label="Due date for the New Instalment"
																	/>
																</Box>
															</Stack>
														) : null
													}
												</GetFieldValue>
											</Stack>
										) : null
									}
								</GetFieldValue>
								<TextInputField
									name="reference_id"
									label="Reference Id"
									secondaryLabel="optional"
									type="text"
									placeholder="Reference Id of the payment"
								/>
								<Divider sm />
								<Inline gap="4">
									<Button disabled={submitting} type="submit">
										{submitting ? "Saving..." : "Save"}
									</Button>
									{onCancel ? (
										<Button
											onClick={onCancel}
											level="tertiary"
											disabled={submitting}
										>
											Cancel
										</Button>
									) : null}
								</Inline>
							</Stack>
						</form>
					)}
				</Form>
			</Stack>
		</Box>
	)
}

function InstalmentOverview({ instalment }: { instalment: IInstalment }) {
	const { amount, due_at, payment } = instalment
	const { amount: totalPaymentAmount, instalments, currency } = payment
	return (
		<Box>
			<Box display={{ sm: "flex" }} gap="4" justifyContent="between">
				<OverviewItem label={`Due Amount (${currency})`}>
					<Stack>
						<Box fontSize="md" fontWeight="semibold">
							{numberToLocalString(amount)}
						</Box>
						<Box fontSize="sm" color="muted">
							{numberToLocalString(totalPaymentAmount)}
						</Box>
					</Stack>
				</OverviewItem>
				<OverviewItem label="Due Date">
					<Stack>
						<Box fontSize="md" fontWeight="semibold">
							{utcTimestampToLocalDateString(due_at)}
						</Box>
						<Box fontSize="sm" color="muted">
							{fromNow(utcTimestampToLocalDate(due_at))}
						</Box>
					</Stack>
				</OverviewItem>
				<OverviewItem label="Contact">
					<Box>
						<InstalmentContact instalment={instalment} />
					</Box>
				</OverviewItem>
			</Box>
			<Divider sm />
			<Table
				headers={["# ID", "Due", <Box>Amount ({currency})</Box>, "Status"]}
				bordered
				caption="All instalments for this payment"
				hover
				responsive
				alignCols={{ 2: "right" }}
				rows={instalments.map(({ id, due_at, amount, paid_at }) => [
					<Box>
						{id}
						{instalment.id === id ? <Badge primary>You are here</Badge> : null}
					</Box>,
					<Box>
						{utcTimestampToLocalDateString(due_at)}
						<Box as="span" fontSize="sm" color="muted" marginLeft="1">
							({fromNow(utcTimestampToLocalDate(due_at))})
						</Box>
					</Box>,
					<Box>{numberToLocalString(amount)}</Box>,
					<Box>
						{paid_at ? (
							<Badge
								title={`Paid on ${utcTimestampToLocalDateString(paid_at)}`}
								success
								outlined
							>
								Paid
							</Badge>
						) : isBefore(utcTimestampToLocalDate(due_at)) ? (
							<Badge warning outlined>
								Overdue
							</Badge>
						) : (
							<Badge outlined>Due</Badge>
						)}
					</Box>,
				])}
			>
				<tfoot>
					<tr>
						<TableDataCell colSpan={2} textAlign="right">
							Total ({currency})
						</TableDataCell>
						<TableDataCell textAlign="right">
							{numberToLocalString(payment.amount)}
						</TableDataCell>
						<TableDataCell>
							<Badge success outlined title="Paid Amount">
								+
								{numberToLocalString(
									instalments
										.filter((i) => i.paid_at)
										.reduce<number>(
											(paid, { amount }) => paid + Number(amount),
											0
										)
								)}
							</Badge>
							<Badge warning outlined title="Due Amount">
								{numberToLocalString(
									instalments
										.filter((i) => !i.paid_at)
										.reduce<number>(
											(paid, { amount }) => paid + Number(amount),
											0
										)
								)}
							</Badge>
						</TableDataCell>
					</tr>
				</tfoot>
			</Table>
		</Box>
	)
}

function OverviewItem({
	label,
	children,
}: {
	label: React.ReactNode
	children: React.ReactNode
}) {
	return (
		<Box>
			<Box
				fontSize="sm"
				marginBottom="1"
				fontWeight="semibold"
				letterSpacing="wider"
				textTransform="uppercase"
				color="muted"
				whiteSpace="preserve"
			>
				{label}
			</Box>
			<Box>{children}</Box>
		</Box>
	)
}

function getOtherDueInstalment(instalment?: IInstalment): Array<IInstalment> {
	if (!instalment) return []
	const { id, payment } = instalment
	const otherDueInstalments = payment.instalments.filter(
		(instalment) => instalment.id !== id && !instalment.paid_at
	)
	if (!otherDueInstalments.length) return []
	otherDueInstalments.sort((a, b) => (a.due_at > b.due_at ? 1 : -1))
	return otherDueInstalments
}
