import { Box } from "@sembark-travel/ui/base"
import { useRef, useEffect } from "react"
import { useLocation } from "react-router-dom"

export function AnchoredSection({
	id,
	...props
}: React.ComponentProps<typeof Box> & {
	id: string
}) {
	const location = useLocation()
	const hash = location.hash
	const sectionRef = useRef<HTMLDivElement>(null)
	useEffect(() => {
		const isActive = hash === `#${id}`
		if (isActive && sectionRef.current) {
			sectionRef.current.scrollIntoView({ behavior: "smooth" })
			const highlightedBackground = "#fef08a"
			sectionRef.current.style.background = "transparent"
			const intervalHandler = setInterval(() => {
				if (sectionRef.current) {
					if (sectionRef.current.style.background === "transparent") {
						sectionRef.current.style.background = highlightedBackground
					} else {
						sectionRef.current.style.background = "transparent"
					}
				}
			}, 1000)
			setTimeout(
				() => {
					clearInterval(intervalHandler)
					if (sectionRef.current) {
						sectionRef.current.style.background = "transparent"
					}
				},
				1000 * 2 * 2
			)
			return () => {
				clearInterval(intervalHandler)
			}
		}
	}, [hash, id])
	return <Box id={id} ref={sectionRef} {...props} />
}
