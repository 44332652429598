import { Box, Button, Stack, Inline, Divider } from "@sembark-travel/ui/base"
import * as Validator from "yup"
import { IUser, ILoginCredentials } from "./store"
import {
	Form,
	TextInputField,
	withServerErrors,
	validateFormValues,
	SubmissionError,
} from "@sembark-travel/ui/form"
import { AutoWebAuthn } from "./WebAuthn"
import { useSynedStorageState } from "../storage"
import { useMemo } from "react"

const loginCredentialsSchema = Validator.object().shape({
	email: Validator.string()
		.email("Please enter a valid email address")
		.required("The email address field is required"),
	password: Validator.string().required("Password field is required"),
})

const validate = validateFormValues(loginCredentialsSchema)

const INITIAL_VALUES: ILoginCredentials = {
	email: "",
	password: "",
}

export function LoginForm({
	userId,
	email,
	readOnlyEmail,
	onSubmit,
}: {
	userId?: number | string
	email?: string
	readOnlyEmail?: boolean
	onSubmit: (method: "password" | "webauthn", data: unknown) => Promise<IUser>
}) {
	const initialValues = useMemo(() => {
		return {
			...INITIAL_VALUES,
			email: email || "",
		}
	}, [email])
	const [deviceWebAuthnUserId] = useSynedStorageState<number>(
		"webauthn_uid",
		0,
		(val) => String(val),
		(val) => (val ? Number(val) : 0)
	)
	return (
		<Stack gap="4">
			<Form<ILoginCredentials>
				initialValues={initialValues}
				validateOnBlur={false}
				onSubmit={withServerErrors(
					async (values) => await onSubmit("password", values)
				)}
				validate={validate}
				subscription={{ submitting: true }}
			>
				{({ submitting, handleSubmit }) => (
					<form noValidate onSubmit={handleSubmit}>
						<Stack gap="4">
							<Box>
								<TextInputField
									label="Email Address"
									name="email"
									type="email"
									id="email"
									readOnly={readOnlyEmail}
									placeholder="username@domain.com"
									autoComplete="username email"
									required
									tabIndex={1}
									size="lg"
								/>
							</Box>
							<Box>
								<TextInputField
									label="Password"
									name="password"
									type="password"
									id="password"
									required
									size="lg"
									autoComplete="current-password"
									tabIndex={2}
								/>
							</Box>
							<SubmissionError />
							<Inline gap="4">
								<Button
									fullWidth
									type="submit"
									disabled={submitting}
									tabIndex={4}
									size="lg"
								>
									{submitting ? "Please wait... " : "Login"}
								</Button>
							</Inline>
						</Stack>
					</form>
				)}
			</Form>
			{(
				userId
					? Number(userId) === Number(deviceWebAuthnUserId)
					: deviceWebAuthnUserId
			) ? (
				<>
					<Divider marginY="0" />
					<AutoWebAuthn
						userId={userId || deviceWebAuthnUserId}
						onSubmit={(payload) => onSubmit("webauthn", payload)}
					/>
				</>
			) : null}
		</Stack>
	)
}
