import {
	Box,
	Text,
	Heading,
	Stack,
	Inline,
	Button,
	Icons,
	Popover,
	TextArea,
	Col,
	Grid,
	Component,
	Tip,
} from "@sembark-travel/ui/base"
import { Dialog } from "@sembark-travel/ui/dialog"
import {
	TrackingShareContentId,
	TrackingShareContentType,
} from "@sembark-travel/tracking"
import { useXHR } from "@sembark-travel/xhr"
import { useCallback, useEffect, useMemo, useState } from "react"
import { Omit } from "utility-types"
import {
	CopyToClipboardButton,
	useCopyTextToClipboard,
} from "@sembark-travel/ui/copy-to-clipboard"
import WhatsAppLogoSrc from "./WhatsApp_Logo.png"

type IPhoneNumber = {
	phone_number?: string
}

type TShareFnArgs = {
	/**
	 * Message Title/Email Subject
	 */
	title?: string
	/**
	 * Message Body/Email Body
	 */
	text?: string
	/**
	 * Url to share
	 */
	url?: string
	/**
	 * Files to attach, only a handful of files are supported
	 * PDFs are not supported yet :(
	 */
	files?: Array<File>
}

const allowedFileByExtensions: { [key: string]: string } = {
	flac: "audio/flac",
	m4a: "audio/x-m4a",
	mp3: "audio/mp3",
	oga: "audio/ogg",
	ogg: "audio/ogg",
	opus: "audio/ogg",
	wav: "audio/wav",
	weba: "audio/webm",
	bmp: "image/bmp",
	gif: "image/gif",
	ico: "image/x-icon",
	jfif: "image/jpeg",
	jpeg: "image/jpeg",
	jpg: "image/jpeg",
	pjp: "image/jpeg",
	pjpeg: "image/jpeg",
	png: "image/png",
	svg: "image/svg+xml",
	svgz: "image/svg+xml",
	tif: "image/tiff",
	tiff: "image/tiff",
	webp: "image/webp",
	xbm: "image/x-xbitmap",
	css: "text/css",
	csv: "text/csv",
	ehtml: "text/html",
	htm: "text/html",
	html: "text/html",
	shtm: "text/html",
	shtml: "text/html",
	text: "text/plain",
	txt: "text/plain",
	m4v: "video/mp4",
	mp4: "video/mp4",
	mpeg: "video/mpeg",
	mpg: "video/mpeg",
	ogm: "video/ogg",
	ogv: "video/ogg",
	webm: "video/webm",
}

const allowedExtentions = Object.keys(allowedFileByExtensions)

const allowedFilesByType = allowedExtentions.reduce<{ [key: string]: string }>(
	(byType, key) => {
		const type = allowedFileByExtensions[key]
		byType[type] = key
		return byType
	},
	{}
)

function isFileTypeAllowed(key: string): boolean {
	return Boolean(allowedFilesByType[key] || allowedFileByExtensions[key])
}

type TShareFn = (data: TShareFnArgs) => void

function canShare(data?: TShareFnArgs) {
	if (typeof navigator === "undefined") return false
	if (!(navigator as unknown as { share: TShareFn }).share) return false
	if (data && data.files) {
		if (
			!(
				(
					navigator as unknown as {
						canShare?: (data: { files: Array<File> }) => void
					}
				).canShare &&
				(
					navigator as unknown as {
						canShare: (data: { files: Array<File> }) => void
					}
				).canShare({ files: data.files })
			)
		) {
			return false
		}
		const areAllFilesAllowed = data.files.every((file) =>
			isFileTypeAllowed(file.type)
		)
		if (!areAllFilesAllowed) {
			console.warn("Some of the file types are not supported")
			return false
		}
	}
	return true
}

async function share(data: TShareFnArgs) {
	const can = canShare(data)
	if (!can) throw Error("503")
	try {
		;(navigator as unknown as { share: TShareFn }).share(data)
	} catch (e) {
		const error = e as Error
		if (error.message === "503") {
			// service not available
		} else {
			throw error
		}
	}
}

interface IShareProps
	extends TShareFnArgs,
		Omit<React.ComponentProps<typeof Button>, "children" | "onClick"> {
	children?:
		| React.ReactNode
		| ((data: { onShare: () => void }) => React.ReactNode)
	phoneNumber?: string | IPhoneNumber | Array<IPhoneNumber>
	/**
	 * Only share when native sharing available
	 */
	nativeOnly?: boolean
}

export function Share({
	text,
	url,
	files,
	title,
	children,
	phoneNumber: phoneNumberProp,
	nativeOnly,
	...props
}: IShareProps) {
	const [sharingAvailable, updateCanShare] = useState(
		canShare({ text, url, files, title })
	)
	let phoneNumber: string | undefined = undefined
	if (phoneNumberProp) {
		if (Array.isArray(phoneNumberProp)) {
			if (phoneNumberProp.length) {
				phoneNumber = phoneNumberProp[0].phone_number
			}
		} else if (typeof phoneNumberProp === "object") {
			phoneNumber = phoneNumberProp.phone_number
		} else if (typeof phoneNumberProp === "string") {
			phoneNumber = phoneNumberProp
		}
	}
	useEffect(() => {
		updateCanShare(canShare({ text, url, files, title }))
	}, [text, url, files, title])
	const handleSend = useCallback(() => {
		share({ text, url, files, title }).catch((e) => {
			alert(e.message || e)
		})
	}, [text, url, files, title])
	return sharingAvailable ? (
		typeof children === "function" ? (
			<>{children({ onShare: handleSend })}</>
		) : (
			<Button {...props} onClick={handleSend}>
				{children || (
					<>
						<Icons.Share /> Share
					</>
				)}
			</Button>
		)
	) : nativeOnly ? null : (
		<>
			<Component initialState={false}>
				{({ state, setState }) => (
					<>
						{typeof children === "function" ? (
							children({ onShare: () => setState(true) })
						) : (
							<Button {...props} onClick={() => setState(true)}>
								{children || (
									<>
										<Icons.Share /> Share
									</>
								)}
							</Button>
						)}
						<Dialog
							open={state}
							onClose={() => setState(false)}
							sm
							title="Share Via"
						>
							<Dialog.Body>
								{text || url ? (
									<Box
										as="a"
										onClick={() => setState(false)}
										href={getWhatsAppSharingLink({ text, url, phoneNumber })}
										target="_blank"
										rel="noopener noreferrer"
										display="inlineBlock"
										rounded="lg"
										overflow="hidden"
									>
										<Box
											as="img"
											src={WhatsAppLogoSrc}
											alt="WhatsApp Logo"
											style={{ width: "16rem" }}
											display="inlineBlock"
										/>
									</Box>
								) : null}
							</Dialog.Body>
						</Dialog>
					</>
				)}
			</Component>
		</>
	)
}

export function ShareFileFromUrl({
	url,
	fileName,
	fileType,
	...props
}: {
	url: string
	fileName: string
	fileType?: string
} & Omit<IShareProps, "files" | "title" | "url">) {
	const [file, setFile] = useState<File | null>(null)
	const [sharingAvailable, updateCanShare] = useState(canShare)
	const xhr = useXHR()
	useEffect(() => {
		if (!sharingAvailable) return
		let isCanceled = false
		xhr
			.get(url, { responseType: "blob" })
			.then((resp) => resp.data)
			.then((file: Blob) => {
				if (!isCanceled) {
					if (isFileTypeAllowed(fileType || file.type)) {
						setFile(new File([file], fileName, { type: fileType || file.type }))
					} else {
						console.warn(`File type ${fileType || file.type} is not sharable.`)
					}
				}
			})
		return () => {
			isCanceled = true
		}
	}, [url, fileName, sharingAvailable, xhr, fileType])
	useEffect(() => {
		if (file) {
			updateCanShare(canShare({ files: [file] }))
		}
	}, [file])
	const files = useMemo(() => (file ? [file] : undefined), [file])
	return file && sharingAvailable ? (
		<Share title={fileName} files={files} {...props} />
	) : null
}

export function ShareText(props: Omit<IShareProps, "files">) {
	return <Share {...props} />
}

export function getWhatsAppSharingLink({
	text,
	url,
	phoneNumber,
}: {
	phoneNumber?: string
	text?: string
	url?: string
}) {
	const domain = phoneNumber
		? `https://wa.me/${phoneNumber.replace(/[^\d]/gi, "")}`
		: "https://api.whatsapp.com/send"
	const message = composeMessage({ text, url })
	return `${domain}?${[message ? "text=" + encodeURIComponent(message) : null]
		.filter(Boolean)
		.join("&")}`
}

function composeMessage({ text, url }: { text?: string; url?: string }) {
	return text || url
		? (text || "") +
				(url
					? `
${url}
`
					: "")
		: ""
}

export function ShareViaWhatsApp({
	text: initialText,
	url,
	phoneNumber: phoneNumberProp,
	editable,
	showTitle,
	disabled,
}: {
	text: string
	url?: string
	phoneNumber?: string | IPhoneNumber | Array<IPhoneNumber>
	editable?: boolean
	showTitle?: boolean
	trackingContentType: TrackingShareContentType
	trackingContentId: TrackingShareContentId
	disabled?: boolean
}) {
	const [currentText, setText] = useState(initialText)
	const text = !editable ? initialText : currentText
	const [copyTextToClipboard] = useCopyTextToClipboard()
	let phoneNumber: string | undefined = undefined
	if (phoneNumberProp) {
		if (Array.isArray(phoneNumberProp)) {
			if (phoneNumberProp.length) {
				phoneNumber = phoneNumberProp[0].phone_number
			}
		} else if (typeof phoneNumberProp === "object") {
			phoneNumber = phoneNumberProp.phone_number
		} else if (typeof phoneNumberProp === "string") {
			phoneNumber = phoneNumberProp
		}
	}
	return (
		<Box>
			{showTitle ? (
				<Stack gap="1">
					<Heading as="h4">WhatsApp Message</Heading>
					<Text color="muted">
						You can share a properly formatted message via WhatsApp. The
						formatting options are not applicable to other sharing services.
					</Text>
				</Stack>
			) : null}
			<Grid borderTopWidth="1">
				{editable ? (
					<Col xs={12} sm={6} paddingY="4">
						<label>Message</label>
						<TextArea
							marginTop="1"
							marginBottom="4"
							name="text"
							required
							rows={15}
							value={text}
							onChange={(e) => setText(e.currentTarget.value)}
						/>
						{url ? (
							<Text fontSize="sm" color="muted">
								Link will be automatically added to the message.
							</Text>
						) : null}
						<Box marginTop="2">
							<Popover
								placement="top"
								trigger={"click"}
								content={
									<Stack padding="4" gap="2">
										<Box as="ol" listStyleType="decimal" paddingLeft="4">
											<Box as="li">
												*bold* &#8594; <b>bold</b>
											</Box>
											<Box as="li">
												_italic_ &#8594; <i>italic</i>
											</Box>
											<Box as="li">
												~strikethrough~ &#8594; <s>strikethrough</s>
											</Box>
											<Box as="li">
												```monospace``` &#8594; <code>monospace</code>
											</Box>
										</Box>
										<Text
											as="a"
											href="https://faq.whatsapp.com/general/chats/how-to-format-your-messages/?lang=fb"
											target="_blank"
											rel="noopener noreferrer"
											fontSize="sm"
											color="muted"
										>
											Read more on WhatsApp official docs.
										</Text>
									</Stack>
								}
							>
								<Button level="tertiary" size="sm">
									<Icons.Info /> See formatting options
								</Button>
							</Popover>
						</Box>
					</Col>
				) : null}
				<Col
					xs={12}
					sm={!editable ? 12 : 6}
					style={{ background: "#faf7eb", color: "black" }}
				>
					<Box maxWidth={!editable ? "lg" : "full"} marginX="auto" paddingY="4">
						{editable ? <Box fontWeight="semibold">Preview</Box> : null}
						<Box>
							<Box
								marginTop="1"
								paddingY="2"
								paddingX="4"
								rounded="lg"
								whiteSpace="preserveLine"
								style={{
									backgroundColor: "#DCF8C6",
									fontFamily:
										"SF Pro Text,SF Pro Icons,system,-apple-system,system-ui,BlinkMacSystemFont,Helvetica Neue,Helvetica,Lucida Grande,Kohinoor Devanagari,sans-serif",
									boxShadow: "0 1px .5px rgba(0, 0, 0,.13)",
								}}
							>
								<Box
									dangerouslySetInnerHTML={{
										__html: `<div style="white-space: pre-wrap">${whatsappPreview(text)}<div>${
											url
												? `
<a href="${url}" rel="noreferrer noopener" target="_blank" style="word-break: break-all;">${url}</a></div></div>`
												: ``
										}`,
									}}
								></Box>
							</Box>
						</Box>
						{!disabled ? (
							<Inline marginTop="4" gap="4" alignItems="center">
								<Box
									as="a"
									target="_blank"
									rel="noopener noreferrer"
									href={getWhatsAppSharingLink({
										text,
										url,
										phoneNumber,
									})}
									borderWidth="1"
									style={{
										background: "#25D366",
										borderColor: "#25D366",
										color: "white",
									}}
									paddingY="2"
									display="inlineBlock"
									paddingX="4"
									rounded="md"
									fontWeight="semibold"
								>
									Send via WhatsApp <Icons.PaperAirplane rotate="90" />
								</Box>
								<CopyToClipboardButton
									status={"primary"}
									level={"secondary"}
									data-testid="copy_whatsapp_message"
									disabled={disabled}
									onClick={async () => {
										return copyTextToClipboard(
											text +
												(url
													? `
${url}`
													: ``)
										)
									}}
								>
									<Icons.ClipboardCopy /> Copy
								</CopyToClipboardButton>
							</Inline>
						) : null}
						<Box fontSize="sm">
							<Tip>
								Install{" "}
								<Text
									as="a"
									href={`https://www.whatsapp.com/download`}
									target="_blank"
									rel="noopener noreferrer"
									textDecoration="underline"
								>
									WhatsApp <Icons.ArrowTopRightOnSquare />
								</Text>{" "}
								(mobile or desktop application) for easy messaging.
							</Tip>
						</Box>
					</Box>
				</Col>
			</Grid>
		</Box>
	)
}

const WHATSAPP_TAGS = [
	["*", "b"],
	["_", "i"],
	["~", "del"],
	["```", "code"],
]
export function whatsappPreview(text: string): string {
	// sanitise text
	let html = text.replace(/>/g, "&gt;").replace(/</g, "&lt;").trim()
	WHATSAPP_TAGS.forEach(([tagSymbol, tagHtmlElm]) => {
		// replace all the tagSymbol with tagHtmlElm
		// e.g. *this* is *something* => <b>this</b> is <b>something</b>
		let start = html.indexOf(tagSymbol),
			end = html.indexOf(tagSymbol, start + tagSymbol.length)
		while (start > -1 && end > -1) {
			const innertPart = html.substring(start + tagSymbol.length, end)
			let canFormat = true
			if (["*", "_", "~"].indexOf(tagSymbol) !== -1) {
				// also, monospace pair dominates over *,_,~
				if (innertPart.indexOf("```") !== -1) {
					if (innertPart.indexOf("```", innertPart.indexOf("```") + 3) !== -1) {
						// move to next
						canFormat = false
					}
				}
				if (innertPart.indexOf("\n") !== -1) {
					// skip the start
					start = end
					end = html.indexOf(tagSymbol, start + tagSymbol.length)
					continue
				}
				if (canFormat) {
					if (end === start + tagSymbol.length) {
						// nothing in-between
						// skip the closing
						end = html.indexOf(tagSymbol, end + tagSymbol.length)
						continue
					}
					// there can not be a surrounding space inside *,_,~
					if (
						// if these is a space after
						innertPart.charAt(0) === " " ||
						// or there is alphanumeric character before
						(start !== 0 &&
							new RegExp(/[a-z0-9]/, "i").test(
								html.charAt(start - tagSymbol.length)
							))
					) {
						// skip the start
						start = end
						end = html.indexOf(tagSymbol, start + tagSymbol.length)
						continue
					}
					if (
						// if these is a space before
						innertPart.charAt(innertPart.length - tagSymbol.length) === " " ||
						// if these is alphanumeric character after
						(end !== html.length - tagSymbol.length &&
							new RegExp(/[a-z0-9]/, "i").test(
								html.charAt(end + tagSymbol.length)
							))
					) {
						// skip the closing
						end = html.indexOf(tagSymbol, end + tagSymbol.length)
						continue
					}
				}
			} else {
				// ```
				if (end === start + tagSymbol.length) {
					// nothing in-between
					// skip the opening
					start = end
					end = html.indexOf(tagSymbol, start + tagSymbol.length)
					continue
				}
			}
			if (canFormat) {
				html =
					html.substring(0, start) +
					"<" +
					tagHtmlElm +
					">" +
					innertPart +
					"</" +
					tagHtmlElm +
					">" +
					html.substring(end + tagSymbol.length)
			}
			start = html.indexOf(tagSymbol, end + tagSymbol.length)
			end = html.indexOf(tagSymbol, start + tagSymbol.length)
		}
	})
	return html.replace(/\n/g, "<br />")
}
