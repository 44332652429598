import { trackEvent, TrackingEvents } from "@sembark-travel/tracking"
import { useSyncExternalStore } from "react"

let swRegistration: undefined | ServiceWorkerRegistration = undefined

export function setCurrentSWRegistration(
	registration: ServiceWorkerRegistration
) {
	swRegistration = registration
}

let state = {
	isUpdating: false,
	updateAvailable: false,
	updateRequiredWithMinimumVersion: undefined as undefined | string,
	isDownloadingUpdate: false,
}

const listeners: Array<() => void> = []

function subscribe(fn: () => void) {
	listeners.push(fn)
	return () => {
		const index = listeners.indexOf(fn)
		if (index >= 0) {
			listeners.splice(index, 1)
		}
	}
}

function setState(newState: Partial<typeof state>) {
	state = {
		...state,
		...newState,
	}
	listeners.forEach((l) => l())
}

function getState() {
	return state
}

export function setAppUpdateAvailable(
	registration: undefined | ServiceWorkerRegistration
) {
	trackEvent(TrackingEvents.web_app_update_available)
	swRegistration = registration
	setState({ updateAvailable: true })
}

export function showAppUpdateRequiredNotification(version: string) {
	if (state.updateRequiredWithMinimumVersion !== version) {
		setTimeout(() => {
			if (state.updateRequiredWithMinimumVersion !== version) {
				setState({ updateRequiredWithMinimumVersion: version })
			}
		}, 3000)
	}
}

async function updateApp() {
	if (!state.updateAvailable) {
		setState({ isDownloadingUpdate: true })
		// try to get the new worker
		if (swRegistration) {
			await swRegistration.update()
			setTimeout(() => {
				// reload the page if unable to update
				if (!state.updateAvailable) {
					window.alert(
						"Unable to auto-download the update. Please refresh the page to download the update"
					)
					window.location.reload()
				}
			}, 5000)
		} else {
			window.location.reload()
		}
		return
	}
	setState({ isUpdating: true })
	if (swRegistration) {
		trackEvent(TrackingEvents.web_app_update_clicked)
		swRegistration.waiting?.postMessage({ type: "SKIP_WAITING" })
	} else {
		window.alert(
			"The update was not correctly installed. Please close and reopen the tabs."
		)
		window.location.reload()
	}
}

export function useAppInstaller() {
	const { updateAvailable, ...props } = useSyncExternalStore(
		subscribe,
		getState
	)
	return {
		isUpdateAvailable: updateAvailable,
		updateApp,
		...props,
	}
}
