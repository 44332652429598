import { Helmet } from "react-helmet-async"
import { ITenant, TenantConfiguration } from "../../../../../Admin/Tenants"
import { useOutletContext } from "react-router-dom"

export default function TenantConfigurationPage() {
	const { tenant } = useOutletContext<{
		tenant: ITenant
		refresh: () => void
	}>()
	return (
		<>
			<Helmet>
				<title>Tenant Configuration</title>
			</Helmet>
			<TenantConfiguration tenantUid={tenant.uid} />
		</>
	)
}
