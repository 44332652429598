import {
	Box,
	Spinner,
	ShadowRoot,
	Icons,
	Text,
	Inline,
	CheckboxInput,
	Button,
	ButtonOwnProps,
	useId,
	Alert,
} from "@sembark-travel/ui/base"
import {
	CopyToClipboardButton,
	CopyToClipboard,
} from "@sembark-travel/ui/copy-to-clipboard"
import useSWR from "swr"
import { useSynedStorageState } from "./../storage"
import config from "../config"
import { XHRLink } from "@sembark-travel/ui/router"

export type TEmailInclusionOptions = Array<{
	name: string
	included: 1 | 0
	options?: Array<{ name: string; included: 1 | 0 }>
}>

export function EmailContent({
	maxWidth,
	copyTrackId,
	fetchContent,
	contentCacheKey,
	inclusionOptionsCacheKey,
	defaultInclusionOptions = [],
	copyLabel = "Copy to Clipboard",
	actions,
	paddingY,
	copyBtnLevel,
	downloadUrl,
	pdfUrl,
	disabled,
}: {
	/**
	 * Set the max width of the email content and toolbar
	 */
	maxWidth?: string
	/**
	 * trackId used when copy-to-clipboard happens
	 */
	copyTrackId: string
	/**
	 * Fetcher to get the email content
	 */
	fetchContent: (params: {
		timezone_offset: number
		inclusions: string | null
	}) => Promise<
		| { content: string; options: TEmailInclusionOptions; warning?: string }
		| undefined
	>
	/**
	 * Key for caching email content response
	 */
	contentCacheKey?: string
	/**
	 * Key to store email inclusion options to local storage
	 */
	inclusionOptionsCacheKey?: string
	/**
	 * Default value of inclusion options
	 * @default []
	 */
	defaultInclusionOptions?: TEmailInclusionOptions
	/**
	 * Custom label for copy-to-clipboard action
	 * @default Copy to Clipboard
	 */
	copyLabel?: string
	actions?: (props: { refresh: () => void }) => React.ReactNode
	paddingY?: React.ComponentProps<typeof Box>["paddingY"]
	copyBtnLevel?: ButtonOwnProps["level"]
	downloadUrl?: string
	pdfUrl?: string
	disabled?: boolean
}) {
	const id = useId()
	const [inclusionOptions, onChangeInclusionOptions] =
		useSynedStorageState<TEmailInclusionOptions>(
			inclusionOptionsCacheKey,
			defaultInclusionOptions
		)
	const {
		data: emailContent,
		isValidating,
		mutate: refresh,
		error,
	} = useSWR(
		`${contentCacheKey || id}&inc=${inclusionOptions
			.filter((o) => o.included)
			.map((o) => `${o.name}`)
			.join("_")}`,
		() =>
			fetchContent({
				timezone_offset: config.timezoneOffset,
				inclusions: inclusionOptions.length
					? inclusionOptions
							.filter((o) => o.included)
							.map((o) => o.name)
							.join(",")
					: null,
			}).then((data) => {
				if (data?.options) {
					onChangeInclusionOptions(data.options || [])
				}
				return data
			})
	)
	const { content, warning } = emailContent || {}
	return (
		<CopyToClipboard<HTMLDivElement>>
			{({ nodeToCopy, copy }) => (
				<Box bgColor="white" position="relative" key={copyTrackId}>
					<Box position="sticky" top="0" zIndex="10">
						{inclusionOptions?.length ? (
							<Box
								paddingY="4"
								borderBottomWidth="1"
								bgColor={warning ? "warning" : "subtle"}
							>
								{warning ? (
									<Inline
										style={{ maxWidth }}
										marginX="auto"
										gap="4"
										alignItems="center"
										paddingX="4"
									>
										<Box>
											<Icons.Attention size="6" color="warning" />
										</Box>
										<Text color="warning" fontWeight="semibold">
											{warning}
										</Text>
									</Inline>
								) : (
									<Inline
										gap="4"
										style={{ maxWidth }}
										marginX="auto"
										paddingX="4"
									>
										{inclusionOptions.map((option, index) => {
											const optionId = option.name
												.replace(/\s/g, "_")
												.toLowerCase()
											return (
												<Inline key={option.name} gap="1" alignItems="center">
													<CheckboxInput
														checked={Boolean(option.included)}
														id={`${optionId}_checkbox`}
														onChange={() =>
															onChangeInclusionOptions(
																inclusionOptions.map((o, i) => ({
																	...o,
																	included:
																		i === index
																			? o.included
																				? 0
																				: 1
																			: o.included,
																}))
															)
														}
													/>
													{option.options &&
													Number(option.options?.length || 0) > 1 ? (
														<Box position="relative">
															<Box
																as="label"
																htmlFor={`${optionId}_select_option`}
															>
																{option.name} <Icons.ChevronDown />
															</Box>
															<Box
																position="absolute"
																overflow="hidden"
																inset="0"
																title="Select More options"
															>
																<select
																	value={option.name}
																	id={`${optionId}_select_option`}
																	style={{
																		background: "transparent",
																		opacity: 0,
																	}}
																	onChange={(e) => {
																		const value = e.currentTarget.value
																		onChangeInclusionOptions(
																			inclusionOptions.map((o, i) => ({
																				...o,
																				// update the name to selected value
																				name: i === index ? value : o.name,
																				// always set included to 1
																				included: i === index ? 1 : o.included,
																			}))
																		)
																	}}
																>
																	{option.options.map((o) => (
																		<option value={o.name} key={o.name}>
																			{o.name}
																		</option>
																	))}
																</select>
															</Box>
														</Box>
													) : (
														<label htmlFor={`${optionId}_checkbox`}>
															{option.name}
														</label>
													)}
												</Inline>
											)
										})}
									</Inline>
								)}
							</Box>
						) : null}
						{content || error ? (
							<Box
								position="absolute"
								right="0"
								style={{ top: "100%" }}
								padding="2"
								bgColor="default"
								roundedBottomLeft="md"
								borderLeftWidth="1"
								borderBottomWidth="1"
							>
								<Inline gap="4" alignItems="center">
									<Button
										onClick={() => refresh()}
										disabled={isValidating}
										size="sm"
										level="tertiary"
									>
										<Icons.Refresh size="4" spin={isValidating} opacity="70" />
									</Button>
									{!error && content ? (
										<>
											{!warning && !disabled ? (
												<Inline gap="2" alignItems="center">
													<CopyToClipboardButton
														level={copyBtnLevel || "primary"}
														size="sm"
														disabled={isValidating}
														onClick={async () => {
															return copy()
														}}
													>
														<Icons.ClipboardCopy /> {copyLabel}
													</CopyToClipboardButton>
													{downloadUrl ? (
														<Button
															size="sm"
															level="tertiary"
															as={XHRLink}
															href={downloadUrl}
															query={{
																_doc: 1,
																timezone_offset: config.timezoneOffset,
																inclusions: inclusionOptions.length
																	? inclusionOptions
																			.filter((o) => o.included)
																			.map((o) => o.name)
																			.join(",")
																	: null,
															}}
															title="Download Word Doc File"
														>
															<Icons.DocumentDownload />
														</Button>
													) : null}
													{pdfUrl ? (
														<Button
															size="sm"
															level="tertiary"
															as={XHRLink}
															href={pdfUrl}
															download
															target="_blank"
															query={{
																_pdf: 1,
																timezone_offset: config.timezoneOffset,
																inclusions: inclusionOptions.length
																	? inclusionOptions
																			.filter((o) => o.included)
																			.map((o) => o.name)
																			.join(",")
																	: null,
															}}
															title="Download PDF File"
														>
															<Icons.DocumentDownload /> PDF
														</Button>
													) : null}
												</Inline>
											) : null}
											{actions ? actions({ refresh: () => refresh() }) : null}
										</>
									) : null}
								</Inline>
							</Box>
						) : null}
					</Box>
					{error ? (
						<Alert status="error">
							{error.message ||
								"Something went wrong!!. Please try again later."}
						</Alert>
					) : null}
					{content ? (
						<Box
							overflow="auto"
							paddingY={paddingY === undefined ? "12" : paddingY}
							marginX="auto"
							paddingX="4"
							style={{
								maxWidth,
								color: "black",
							}}
							cursor={isValidating ? "wait" : undefined}
						>
							<ShadowRoot>
								<Box
									ref={nodeToCopy}
									style={{
										margin: 0,
										padding: 0,
									}}
									dangerouslySetInnerHTML={{
										__html: content,
									}}
								/>
							</ShadowRoot>
						</Box>
					) : !error ? (
						<Box style={{ minHeight: "500px" }}>
							<Spinner alignCenter padding="4" />
						</Box>
					) : null}
				</Box>
			)}
		</CopyToClipboard>
	)
}
