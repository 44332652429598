import { Spinner, Alert } from "@sembark-travel/ui/base"
import { Dialog, useDialog } from "@sembark-travel/ui/dialog"
import { useXHR } from "@sembark-travel/xhr"
import { useId } from "react"
import { TTravelActivity } from "./store"
import { AddNewTravelActivityItemForm } from "./NewItem"
import useSWR from "swr"
import { showSnackbar } from "@sembark-travel/ui/snackbar"

export function EditTravelActivityItemInDialog({
	children,
	onSuccess,
	...props
}: {
	children: (props: { add: () => void }) => React.ReactNode
	onSuccess?: (activity: TTravelActivity) => void
} & Omit<
	React.ComponentProps<typeof EditTravelActivityItem>,
	"onCancel" | "onSuccess"
>) {
	const [isOpen, open, close] = useDialog()
	return (
		<>
			{children({ add: open })}
			<Dialog open={isOpen} onClose={close} title="Edit Activity Details" lg>
				<Dialog.Body>
					<EditTravelActivityItem
						onSuccess={(...args) => {
							onSuccess?.(...args)
							close()
						}}
						onCancel={() => close()}
						{...props}
					/>
				</Dialog.Body>
			</Dialog>
		</>
	)
}

export function EditTravelActivityItem({
	onSuccess,
	travelActivityId,
	...props
}: {
	travelActivityId: number | string
	onSuccess: (activity: TTravelActivity) => void
} & Omit<
	React.ComponentProps<typeof AddNewTravelActivityItemForm>,
	"onSubmit"
>) {
	const xhr = useXHR()
	const id = useId()
	const { data: travelActivity, error } = useSWR<TTravelActivity>(
		`/api/travel-activities/${travelActivityId}/edit?${id}`,
		() =>
			xhr
				.get(`/travel-activities/${travelActivityId}`, {
					params: {
						include: "trip_destinations",
					},
				})
				.then((resp) => resp.data.data)
	)
	if (!travelActivity) {
		return <Spinner padding="4" alignCenter />
	}
	if (error) {
		return (
			<Alert status="error">
				{error?.message || "Something went wrong. Please try again later."}
			</Alert>
		)
	}
	return (
		<AddNewTravelActivityItemForm
			{...props}
			initialValues={{
				name: travelActivity.name,
				description: travelActivity.description,
				ticket_types: travelActivity.ticket_types?.map((ticket_type) => ({
					name: ticket_type.name,
					description: ticket_type.description,
					durations: !ticket_type.durations?.length
						? [
								{
									iso: "",
									slots: "",
								},
						  ]
						: ticket_type.durations.map((duration) => ({
								iso: duration.iso,
								slots: duration.slots?.join(", ") || "",
						  })),
				})),
				ticket_tourist_configurations:
					travelActivity.ticket_tourist_configurations
						.map((config) => config.name)
						.join(", "),
				trip_destinations: travelActivity.trip_destinations || [],
			}}
			onSubmit={async (data) => {
				const resp = await xhr.patch<{ data: TTravelActivity }>(
					`/travel-activities/${travelActivity.id}`,
					data
				)
				showSnackbar("Activity details updated")
				onSuccess(resp.data.data)
			}}
		/>
	)
}
