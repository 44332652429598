import {
	AsyncSelect,
	AsyncSelectProps,
	Box,
	Icons,
	Table,
	Dropdown,
	joinAttributes,
	Stack,
	Select,
	Text,
	Inline,
} from "@sembark-travel/ui/base"
import { Link } from "@sembark-travel/ui/router"
import { ListView, Search, TSearchParams } from "@sembark-travel/ui/list"
import { IListResponse, useXHR, XHRInstance } from "@sembark-travel/xhr"
import React, { Fragment, useCallback, useState } from "react"
import { Omit } from "utility-types"
import { Email, PhoneNumber } from "../Contacts"
import { AddTransportServiceProviderDialog } from "./NewItem"
import { ITransportServiceProvider, TSupplyType } from "./store"
import { UpdateItemInDialog } from "./UpdateItem"
import { SelectField } from "@sembark-travel/ui/form"
import { useHasFeatureFlag } from "../Auth"

function XHR(xhr: XHRInstance) {
	return {
		async get(
			params?: unknown
		): Promise<IListResponse<ITransportServiceProvider>> {
			return xhr
				.get("/transport-service-providers", { params })
				.then((resp) => resp.data)
		},
	}
}

type TFilters = TSearchParams & {
	supplies?: Array<string>
}

export function TransportServiceProvidersList<TParams extends TFilters>({
	params,
	setParams,
	actions,
}: {
	params: TParams
	setParams: (params: TParams) => void
	actions?: React.ReactNode
}) {
	const hasTravelActivities = useHasFeatureFlag("travel_activities")
	return (
		<Search
			initialParams={params}
			onSearch={(params) => {
				setParams({ ...params, page: 1 })
			}}
			title={
				hasTravelActivities
					? "Transport & Activity Suppliers"
					: "Transport Service Providers"
			}
			actions={actions}
			Filters={Filters}
		>
			<ListView<ITransportServiceProvider, TFilters>
				pageKey="/transport-service-providers-list"
				params={params}
				fetch={(xhr, params) =>
					XHR(xhr).get({
						...params,
						include: "contacts",
						// convert to the request params
						supplies: params.supplies
							?.map((s) => {
								switch (s.toLowerCase()) {
									case "cabs":
										return "cabs"
									case "travel activities":
										return "travel_activities"
								}
								return undefined
							})
							.filter(Boolean),
					})
				}
				onPageChange={(page) => setParams({ ...params, page })}
			>
				{({ items: transportServiceProviders, refresh }) => (
					<Table
						responsive
						bordered
						hover
						headers={["Name", "Contacts", ""]}
						alignCols={{ 2: "right" }}
						rows={transportServiceProviders.map((transportServiceProvider) => [
							<Link
								to={transportServiceProvider.id.toString()}
								state={{ params }}
								color="accent"
								fontWeight="semibold"
								anchored
							>
								<Inline gap="4" flexWrap="wrap">
									<Text>{transportServiceProvider.name}</Text>
									<Text as="span" color="muted">
										{joinAttributes(
											<Box display="inlineBlock">
												{transportServiceProvider.solo_driver ? (
													<Icons.Driver title="Single Driver" color="muted" />
												) : (
													<Icons.UserGroup title="Company" color="muted" />
												)}
											</Box>,
											transportServiceProvider.supplies_cabs &&
												hasTravelActivities ? (
												<Icons.Taxi title="Cab Supplier" />
											) : null,
											transportServiceProvider.supplies_travel_activities ? (
												<Icons.Ticket title="Travel Activity Supplier" />
											) : null
										)}
									</Text>
								</Inline>
							</Link>,
							<Box>
								{transportServiceProvider.contacts?.length ? (
									<Box>
										{joinAttributes(
											transportServiceProvider.contacts[0].name,
											transportServiceProvider.contacts[0].phone_numbers
												?.length ? (
												<PhoneNumber
													value={
														transportServiceProvider.contacts[0].phone_numbers
													}
													iconOnly
												/>
											) : null,
											transportServiceProvider.contacts[0].email ? (
												<Email
													value={transportServiceProvider.contacts[0].email}
													iconOnly
												/>
											) : null
										)}
									</Box>
								) : null}
							</Box>,
							<Box>
								<Dropdown alignRight>
									<Dropdown.ToggleButton size="sm" level="tertiary">
										<Icons.DotsVertical />
									</Dropdown.ToggleButton>
									<Dropdown.Menu>
										<UpdateItemInDialog
											transportServiceProvider={transportServiceProvider}
											onSuccess={() => refresh()}
										>
											{({ edit }) => (
												<Dropdown.MenuItem onClick={() => edit()}>
													<Icons.Pencil /> Edit Details
												</Dropdown.MenuItem>
											)}
										</UpdateItemInDialog>
									</Dropdown.Menu>
								</Dropdown>
							</Box>,
						])}
					/>
				)}
			</ListView>
		</Search>
	)
}

const SUPPLIES_OPTIONS = ["Cabs", "Travel Activities"]

function Filters() {
	return (
		<Stack>
			<SelectField
				label="Supplies"
				select={Select}
				name="supplies"
				multiple
				options={SUPPLIES_OPTIONS}
			/>
		</Stack>
	)
}

export function SelectServiceProviders({
	supplies,
	...otherProps
}: Omit<AsyncSelectProps, "fetch"> & {
	supplies?: TSupplyType | Array<TSupplyType>
}) {
	const xhr = useXHR()
	const [newName, setNewName] = useState("")
	const handleDialogClose = useCallback(() => {
		setNewName("")
	}, [setNewName])
	return (
		<Fragment>
			<AsyncSelect
				onCreateNew={(query: string) => {
					setNewName(query)
				}}
				optionRenderer={({ option, created }) =>
					created ? <Box>Add "{option.name}"</Box> : <Box>{option.name}</Box>
				}
				{...otherProps}
				fetch={(q) =>
					XHR(xhr)
						.get({ q, include: "drivers,cabs", limit: 30, supplies })
						.then((resp) => resp.data)
				}
			/>
			<AddTransportServiceProviderDialog
				open={!!newName}
				initialValues={{
					name: newName,
					supplies_cabs: supplies?.indexOf("cabs") !== -1 ? 1 : 0,
					supplies_travel_activities:
						supplies?.indexOf("travel_activities") !== -1 ? 1 : 0,
				}}
				keepInitialSuppliesSelected
				onClose={handleDialogClose}
				onSuccess={(tsp) => {
					handleDialogClose()
					otherProps.onChange?.(tsp, otherProps.name || "field_name")
				}}
			/>
		</Fragment>
	)
}

export function SelectTransporServiceProviders(
	props: Omit<React.ComponentProps<typeof SelectServiceProviders>, "supplies">
) {
	return <SelectServiceProviders {...props} supplies={"cabs"} />
}

export function SelectTravelActivitySuppliers(
	props: Omit<React.ComponentProps<typeof SelectServiceProviders>, "supplies">
) {
	return <SelectServiceProviders {...props} supplies={"travel_activities"} />
}
