import { Button, Component, Spinner } from "@sembark-travel/ui/base"
import { Dialog } from "@sembark-travel/ui/dialog"
import { useXHR } from "@sembark-travel/xhr"
import { useCallback, useMemo } from "react"
import useSWR from "swr"
import { IData, NewItemForm } from "./NewItem"
import { IHotelVoucher } from "./store"

interface IEditHotelVoucherProps {
	voucherId: number
	onSuccess?: () => void
	onCancel?: () => void
}
export function EditHotelVoucher({
	voucherId,
	onSuccess,
	onCancel,
}: IEditHotelVoucherProps) {
	const xhr = useXHR()
	const { data: voucher } = useSWR<IHotelVoucher>(
		`/hotel-vouchers/${voucherId}`,
		() =>
			xhr.get(`/hotel-vouchers/${voucherId}`).then((resp) => resp.data.data),
		{ revalidateOnFocus: false }
	)
	const update = useCallback(
		async (data: unknown) => {
			const resp = await xhr.patch(`/hotel-vouchers/${voucherId}`, data)
			onSuccess && onSuccess()
			return resp.data.data
		},
		[voucherId, onSuccess, xhr]
	)
	const initialValues = useMemo<IData | undefined>(() => {
		if (!voucher) return
		const {
			guest_name,
			no_of_adults,
			children,
			hotel,
			checkin,
			checkout,
			services,
			extra_services,
			booking_confirmation_id,
			reference_id,
			notes,
		} = voucher
		return {
			guest_name,
			no_of_adults,
			children,
			hotel,
			checkin,
			checkout,
			extra_services,
			booking_confirmation_id,
			reference_id,
			notes,
			...(services.length
				? {
						meal_plan: services[0].meal_plan,
						room_type: services[0].room_type,
						no_of_rooms: services[0].no_of_rooms,
				  }
				: {}),
		}
	}, [voucher])
	if (!initialValues) {
		return <Spinner alignCenter padding="4" />
	}
	return (
		<>
			<NewItemForm
				onSubmit={update}
				onCancel={onCancel}
				initialValues={initialValues}
			/>
		</>
	)
}

export function EditHotelVoucherInDialog({
	onCancel,
	onSuccess,
	...props
}: IEditHotelVoucherProps) {
	return (
		<Component initialState={false}>
			{({ state, setState }) => (
				<>
					<Button onClick={() => setState(true)}>Edit Voucher</Button>
					<Dialog open={state} onClose={() => setState(false)} lg>
						<Dialog.Header>
							<Dialog.Title>Edit Hotel Voucher</Dialog.Title>
						</Dialog.Header>
						<Dialog.Body>
							<EditHotelVoucher
								{...props}
								onSuccess={() => {
									onSuccess && onSuccess()
									setState(false)
								}}
								onCancel={() => {
									onCancel && onCancel()
									setState(false)
								}}
							/>
						</Dialog.Body>
					</Dialog>
				</>
			)}
		</Component>
	)
}
