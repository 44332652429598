import {
	AsyncSelect,
	Text,
	Stack,
	Inline,
	Spinner,
	RadioInput,
	Box,
} from "@sembark-travel/ui/base"
import { useXHR } from "@sembark-travel/xhr"
import { TWorldBankBranch, worldXHR } from "./store"
import useSWR from "swr"
import { useRef } from "react"

type SelectProps = Omit<React.ComponentProps<typeof AsyncSelect>, "fetch">

export function SelectWorldCountries(props: SelectProps) {
	const xhr = useXHR()
	return (
		<AsyncSelect
			{...props}
			fetch={(q) =>
				worldXHR(xhr)
					.getCountries({ q })
					.then((resp) => resp.data)
			}
		/>
	)
}

export function SelectWorldStates({
	countryId,
	...props
}: SelectProps & {
	countryId?: number | string
}) {
	const xhr = useXHR()
	return (
		<AsyncSelect
			{...props}
			fetch={(q) =>
				worldXHR(xhr)
					.getStates({ q, country_id: countryId || null })
					.then((resp) => resp.data)
			}
		/>
	)
}

export function SelectWorldCities({
	stateId,
	...props
}: SelectProps & {
	stateId?: string | number
}) {
	const xhr = useXHR()
	return (
		<AsyncSelect
			{...props}
			fetch={(q) =>
				worldXHR(xhr)
					.getCities({ q, state_id: stateId || null })
					.then((resp) => resp.data)
			}
		/>
	)
}

export function SelectWorldTimezones({
	countryId,
	...props
}: SelectProps & { countryId?: number | string }) {
	const xhr = useXHR()
	return (
		<AsyncSelect
			{...props}
			fetch={(q) =>
				xhr
					.get<{ data: Array<{ id: string; name: string }> }>(
						"/world/timezones-list",
						{
							params: { q },
						}
					)
					.then((resp) => resp.data.data)
			}
		/>
	)
}

export function SelectWorldCurrencies({
	countryId,
	...props
}: SelectProps & { countryId?: number | string }) {
	const xhr = useXHR()
	return (
		<AsyncSelect
			{...props}
			fetch={(q) =>
				worldXHR(xhr)
					.getCurrencies({ q, country_id: countryId || null })
					.then((resp) => resp.data)
			}
		/>
	)
}

export function SelectWorldAirports(props: SelectProps) {
	const xhr = useXHR()
	return (
		<AsyncSelect
			cacheKey="world-airports"
			{...props}
			perFetchLimit={30}
			fetch={(q, { page }) =>
				worldXHR(xhr)
					.getAirports({ q, page, limit: 30 })
					.then((resp) => resp.data)
			}
		/>
	)
}

export function SelectWorldBanks({
	countryId,
	...props
}: SelectProps & { countryId?: number | string }) {
	const xhr = useXHR()
	return (
		<AsyncSelect
			{...props}
			fetch={(q) =>
				worldXHR(xhr)
					.getBanks({ q, country_id: countryId || null })
					.then((resp) => resp.data)
			}
		/>
	)
}

export function BankBranchDetailsForCode({
	type,
	code,
	onSelect,
}: {
	type: "swift_code" | "ifsc"
	code: string
	onSelect: (branch: TWorldBankBranch) => undefined
}) {
	const xhr = useXHR()
	const abortController = useRef<AbortController | undefined>(undefined)
	const { data, error } = useSWR(
		`/bank_details_for_${type}/${code}`,
		() => {
			if (abortController.current) {
				abortController.current.abort()
			}
			abortController.current = new AbortController()
			return worldXHR(xhr).getBankBranchForCode(type, code, {
				signal: abortController.current.signal,
			})
		},
		{
			shouldRetryOnError: false,
		}
	)
	if (!data) {
		if (error) {
			return (
				<Stack
					padding="2"
					borderWidth="1"
					gap="1"
					alignItems="center"
					rounded="md"
					bgColor="subtle"
				>
					<Text color="muted" fontSize="sm">
						No details found for the given code.
					</Text>
					<Text fontSize="sm">
						Please manually enter the bank-branch details.
					</Text>
				</Stack>
			)
		}
		return <Spinner alignCenter />
	}
	return (
		<Stack gap="4">
			{data.map((branch) => (
				<Inline
					key={branch.id}
					gap="6"
					borderWidth="1"
					rounded="md"
					padding="4"
				>
					<Box as="label" paddingTop="4">
						<RadioInput
							name={`selected_branch_name_${type}_${code}`}
							onChange={() => {
								onSelect(branch)
							}}
						/>
					</Box>
					<Stack gap="2">
						<Inline flexWrap="wrap" gap="4">
							<Stack gap="px">
								<Text fontSize="sm" color="muted">
									Bank Name
								</Text>
								<Text fontSize="md" fontWeight="semibold">
									{branch.bank.name}
								</Text>
							</Stack>
							<Stack gap="px">
								<Text fontSize="sm" color="muted">
									City Name
								</Text>
								<Text fontSize="md" fontWeight="semibold">
									{branch.city_name}
								</Text>
							</Stack>
							<Stack gap="px">
								<Text fontSize="sm" color="muted">
									Branch Name
								</Text>
								<Text>{branch.name}</Text>
							</Stack>
						</Inline>
						<Inline flexWrap="wrap" gap="4">
							{branch.ifsc_code ? (
								<Stack gap="px">
									<Text fontSize="sm" color="muted">
										IFSC Code
									</Text>
									<Text
										as="a"
										href={`https://ifsc.bankifsccode.com/${code}`}
										rel="noopener noreferrer"
										target="_blank"
										color="accent"
									>
										{branch.ifsc_code}
									</Text>
								</Stack>
							) : null}
							<Stack gap="px">
								<Text fontSize="sm" color="muted">
									SWIFT Code
								</Text>
								<Text>{branch.swift_code || "N/A"}</Text>
							</Stack>
							<Stack gap="px">
								<Text fontSize="sm" color="muted">
									MICR No
								</Text>
								{branch.micr_code ? (
									<Text
										as="a"
										href={`https://micr.bankifsccode.com/${branch.micr_code}`}
										rel="noopener noreferrer"
										target="_blank"
										color="accent"
									>
										{branch.micr_code}
									</Text>
								) : (
									<Text color="muted">N/A</Text>
								)}
							</Stack>
						</Inline>
					</Stack>
				</Inline>
			))}
		</Stack>
	)
}
