import type {
	Modifier,
	Options,
	Instance,
	Placement,
	VirtualElement,
	State,
	Rect,
	Obj,
} from "@popperjs/core"
import { usePopper as usePopperBase } from "react-popper"

export type {
	Modifier,
	Options,
	Instance,
	Placement,
	VirtualElement,
	State,
	Rect,
	Obj,
}

export type Modifiers<TName, TOptions extends Obj> =
	| Options["modifiers"]
	| Record<string, Partial<Modifier<TName, TOptions>>>

export type OffsetValue = [number | null | undefined, number | null | undefined]
export type OffsetFunction = (details: {
	popper: Rect
	reference: Rect
	placement: Placement
}) => OffsetValue

export type Offset = OffsetFunction | OffsetValue

export type DOMContainer<T extends HTMLElement = HTMLElement> =
	| T
	| React.RefObject<T>
	| null
	| (() => T | React.RefObject<T> | null)

export type UsePopperOptions = Omit<Options, "placement" | "strategy"> & {
	enabled?: boolean
	placement?: Options["placement"]
	strategy?: Options["strategy"]
	modifiers?: Options["modifiers"]
}

export interface UsePopperState {
	placement: Placement
	update: () => void
	forceUpdate: () => void
	attributes: Record<string, Record<string, unknown>>
	styles: Record<string, Partial<CSSStyleDeclaration>>
	state?: State
}

export function usePopper(
	referenceElm: VirtualElement | null | undefined,
	popperElm: HTMLElement | null | undefined,
	{
		enabled = true,
		placement = "bottom",
		strategy = "absolute",
		...options
	}: UsePopperOptions
) {
	return usePopperBase(referenceElm, popperElm, {
		placement,
		strategy,
		...options,
	})
}

export type Config = {
	flip?: boolean
	show?: boolean
	alignEnd?: boolean
	enabled?: boolean
	containerPadding?: number
	arrowElement?: Element | null
	enableEvents?: boolean
	offset?: Offset
	placement?: Placement
	popperConfig?: UsePopperOptions
}
