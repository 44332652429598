import { useParams } from "./../../../../../../router-utils"
import { LogTripTransaction } from "../../../../../../Trips"
import { Helmet } from "react-helmet-async"

export default function LogTripTransactionPage() {
	const { tripId } = useParams("/trips/:tripId/accounting/transactions")
	return (
		<>
			<Helmet>
				<title>Log Trip Transaction</title>
			</Helmet>
			<LogTripTransaction tripId={tripId} />
		</>
	)
}
