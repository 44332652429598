import type { ElementType, ForwardedRef } from "react"
import { forwardRef } from "react"
import type {
	PolymorphicForwardRefExoticComponent,
	PolymorphicPropsWithoutRef,
	PolymorphicPropsWithRef,
} from "./polymorphic-types"
import "./css/preflight.css"
import { boxStyles, TBoxStyles } from "./css/box.css"
import { colorStyles, TColorStyles } from "./css/color.css"
import { sizeStyles, TSizeStyles } from "./css/size.css"
import { textStyles, TTextStyles } from "./css/text.css"
import { transformStyles, TTransformStyles } from "./css/transform.css"
import { animationStyles, TAnimationStyles } from "./css/animations.css"
import { borderStyles, TBorderStyles } from "./css/border.css"
import { transitionStyles, TTransitionStyles } from "./css/transition.css"
import classnames from "classnames"

const DefaultBoxElement = "div"

export type BoxOwnProps = TColorStyles &
	TSizeStyles &
	TTextStyles &
	TBoxStyles &
	TBorderStyles &
	Omit<TTransformStyles, "transform"> &
	TTransitionStyles &
	TAnimationStyles

export type BoxProps<T extends ElementType = typeof DefaultBoxElement> =
	PolymorphicPropsWithRef<BoxOwnProps, T>

export const Box: PolymorphicForwardRefExoticComponent<
	BoxOwnProps,
	typeof DefaultBoxElement
> = forwardRef(function Box<T extends ElementType = typeof DefaultBoxElement>(
	{
		as,
		color,
		padding,
		paddingTop,
		paddingRight,
		paddingBottom,
		paddingLeft,
		paddingX,
		paddingY,
		margin,
		marginTop,
		marginRight,
		marginBottom,
		marginLeft,
		marginX,
		marginY,
		bgColor,
		backgroundColor,
		textAlign,
		position,
		top,
		right,
		bottom,
		left,
		insetX,
		insetY,
		inset,
		cursor,
		pointerEvents,
		zIndex,
		flexDirection,
		flexGrow,
		flexWrap,
		alignItems,
		alignSelf,
		justifyContent,
		display,
		gap,
		className,
		size,
		width,
		height,
		borderTopWidth,
		borderRightWidth,
		borderBottomWidth,
		borderLeftWidth,
		borderWidth,
		borderXWidth,
		borderYWidth,
		borderColor,
		borderTopColor,
		borderRightColor,
		borderBottomColor,
		borderLeftColor,
		borderTopLeftRadius,
		borderTopRightRadius,
		borderBottomRightRadius,
		borderBottomLeftRadius,
		borderRadius,
		rounded,
		roundedTopRight,
		roundedTopLeft,
		roundedBottomRight,
		roundedBottomLeft,
		roundedTop,
		roundedRight,
		roundedBottom,
		roundedLeft,
		minWidth,
		maxWidth,
		minHeight,
		flex,
		flexShrink,
		overflowX,
		overflowY,
		overflow,
		opacity,
		fontSize,
		fontWeight,
		fontFamily,
		textTransform,
		letterSpacing,
		textOverflow,
		textDecoration,
		wordBreak,
		rotate,
		scaleX,
		scaleY,
		scale,
		whiteSpace,
		fontVariantNumeric,
		listStyleType,
		verticalAlign,
		appearance,
		boxShadow,
		animation,
		animationDuration,
		transition,
		transitionDelay,
		transitionDuration,
		transitionTimingFunction,
		transitionProperty,
		...restProps
	}: PolymorphicPropsWithoutRef<BoxOwnProps, T>,
	ref: ForwardedRef<Element>
) {
	const Element: ElementType = as || DefaultBoxElement
	const transform =
		scale !== undefined ||
		scaleX !== undefined ||
		scaleY !== undefined ||
		rotate !== undefined
			? "1"
			: undefined
	transition =
		transition ||
		(transitionProperty !== undefined ||
		transitionDelay !== undefined ||
		transitionDuration !== undefined ||
		transitionTimingFunction !== undefined
			? "1"
			: undefined)
	return (
		<Element
			ref={ref}
			{...restProps}
			className={classnames(
				colorStyles({ color }),
				textStyles({
					fontSize,
					fontWeight,
					fontFamily,
					textTransform,
					whiteSpace,
					fontVariantNumeric,
					letterSpacing,
					textOverflow,
					textDecoration,
					wordBreak,
				}),
				sizeStyles({
					size,
					width,
					height,
				}),
				transformStyles({ scale, scaleX, scaleY, rotate, transform }),
				animationStyles({ animation, animationDuration }),
				borderStyles({
					borderTopColor,
					borderRightColor,
					borderBottomColor,
					borderLeftColor,
					borderColor,
					borderTopWidth,
					borderRightWidth,
					borderBottomWidth,
					borderLeftWidth,
					borderWidth,
					borderXWidth,
					borderYWidth,
					borderTopLeftRadius,
					borderTopRightRadius,
					borderBottomRightRadius,
					borderBottomLeftRadius,
					borderRadius,
					rounded,
					roundedTopRight,
					roundedTopLeft,
					roundedBottomRight,
					roundedBottomLeft,
					roundedTop,
					roundedRight,
					roundedBottom,
					roundedLeft,
				}),
				boxStyles({
					padding,
					paddingTop,
					paddingRight,
					paddingBottom,
					paddingLeft,
					paddingX,
					paddingY,
					margin,
					marginTop,
					marginRight,
					marginBottom,
					marginLeft,
					marginX,
					marginY,
					bgColor,
					backgroundColor,
					textAlign,
					position,
					top,
					right,
					bottom,
					left,
					insetX,
					insetY,
					inset,
					cursor,
					pointerEvents,
					display,
					alignItems,
					alignSelf,
					justifyContent,
					zIndex,
					flexDirection,
					flexGrow,
					flexWrap,
					gap,
					minWidth,
					maxWidth,
					minHeight,
					flex,
					flexShrink,
					overflowX,
					overflowY,
					overflow,
					opacity,
					listStyleType,
					verticalAlign,
					appearance,
					boxShadow,
				}),
				transitionStyles({
					transition,
					transitionDelay,
					transitionDuration,
					transitionTimingFunction,
					transitionProperty,
				}),
				className
			)}
		/>
	)
})
