import { Helmet } from "react-helmet-async"
import { HotelPricesList } from "./../../../HotelPrices"

export default function Prices() {
	return (
		<>
			<Helmet>
				<title>Hotel Prices</title>
			</Helmet>
			<HotelPricesList />
		</>
	)
}
