import { Box } from "@sembark-travel/ui/base"
import { Breadcrumbs } from "@sembark-travel/ui/router"
import { Helmet } from "react-helmet-async"
import DropTrip from "../../../Trips/DropTrip"
import { useParams, generatePath } from "../../../router-utils"

export default function DropTripRoute() {
	const { tripId } = useParams("/trips/:tripId/cancel")
	return (
		<>
			<Helmet>
				<title>Drop Trip</title>
			</Helmet>
			<Breadcrumbs
				title="Drop Trip"
				items={[
					[generatePath("/trips"), "Trips"],
					[generatePath("/trips/:tripId", { tripId }), tripId],
					["", "Drop"],
				]}
			/>
			<Box>
				<DropTrip tripId={tripId} />
			</Box>
		</>
	)
}
