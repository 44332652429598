import { Badge, TabContent, Tabs, TabsList } from "@sembark-travel/ui/base"
import { NavLink } from "@sembark-travel/ui/router"
import { generatePath } from "../../../router-utils"
import { Outlet } from "react-router-dom"

export default function Layout() {
	return (
		<Tabs>
			<TabsList>
				<NavLink to={generatePath("/settings")} end>
					Your Profile
				</NavLink>
				<NavLink to={generatePath("/settings/security")}>
					Password and Authentication
				</NavLink>
				<NavLink to={generatePath("/settings/security-logs")}>
					Security Logs
				</NavLink>
				<NavLink to={generatePath("/settings/apperance")}>
					Apperance <Badge warning>BETA</Badge>
				</NavLink>
			</TabsList>
			<TabContent bgColor="subtle">
				<Outlet />
			</TabContent>
		</Tabs>
	)
}
