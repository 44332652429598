import {
	Box,
	Icons,
	Popover,
	RelativeTime,
	Table,
	Text,
	Button,
	Heading,
	Stack,
} from "@sembark-travel/ui/base"
import { ListView, Search, useSearch } from "@sembark-travel/ui/list"
import { useLocationQuery } from "@sembark-travel/ui/router"
import { useEffect } from "react"
import { TWebhookClientCall } from "./store"

export function WebhookClientCallsList() {
	const [query, setQuery] = useLocationQuery()
	const [params, setParams] = useSearch(query)
	useEffect(() => {
		setQuery(params)
	}, [params, setQuery])
	return (
		<>
			<Search
				title="Webhook Client Calls"
				initialParams={params}
				onSearch={(params) => setParams({ ...params, page: 1 })}
			>
				{() => (
					<ListView<TWebhookClientCall>
						pageKey="webhook-client-calls"
						params={params}
						swrConfig={{ revalidateOnFocus: true }}
						onPageChange={(page) => setParams({ ...params, page })}
						fetch={(xhr, params) =>
							xhr
								.get("webhook-client-calls", { params })
								.then((resp) => resp.data)
						}
					>
						{({ items }) => (
							<Table
								bordered
								hover
								responsive
								headers={["", "DateTime", "Name", ""]}
								rows={items.map((call) => [
									call.exception ? (
										<Popover
											trigger={"click"}
											placement="right"
											content={
												<Stack gap="1">
													<Box padding="2">
														<Heading fontSize="md">
															Exception in Webhook Handling
														</Heading>
													</Box>
													<Box
														style={{ maxHeight: "300px" }}
														overflow="auto"
														color="danger"
														padding="2"
														bgColor="danger"
													>
														<pre>{JSON.stringify(call.exception, null, 2)}</pre>
													</Box>
												</Stack>
											}
										>
											<Button
												level="secondary"
												status="danger"
												size="sm"
												title="Exception"
											>
												<Icons.Attention />
											</Button>
										</Popover>
									) : (
										<Box paddingX="2" paddingY="1" title="Successfully Handled">
											<Icons.Ok />
										</Box>
									),
									<RelativeTime timestamp={call.created_at} />,
									call.name,
									<Popover
										trigger="click"
										placement="left"
										content={
											<Stack gap="4" padding="4">
												<Stack gap="1">
													<Heading fontSize="base">Url</Heading>
													<Box padding="2" bgColor="inset">
														{call.url}
													</Box>
												</Stack>
												<Stack gap="1">
													<Heading fontSize="base">Payload</Heading>
													{call.payload ? (
														<Box
															style={{ maxHeight: "300px" }}
															overflow="auto"
															bgColor="inset"
															padding="2"
														>
															<pre>{JSON.stringify(call.payload, null, 2)}</pre>
														</Box>
													) : (
														<Text color="muted">N/A</Text>
													)}
												</Stack>
											</Stack>
										}
									>
										<Button level="tertiary" size="sm" title="Details">
											<Icons.ArrowExpand />
										</Button>
									</Popover>,
								])}
							/>
						)}
					</ListView>
				)}
			</Search>
		</>
	)
}
