import { Stack, Icons, Table, Text } from "@sembark-travel/ui/base"
import {
	utcTimestampToLocalDate,
	utcTimestampToLocalDateString,
	dateToUTCString,
	fromNow,
	parseDateFromQuery,
	dateToQuery,
	startOf,
} from "@sembark-travel/datetime-utils"
import { numberToLocalString } from "@sembark-travel/number-utils"
import { ButtonLink, useLocationQuery } from "@sembark-travel/ui/router"
import {
	ListView,
	Search,
	TSearchParams,
	useSearch,
} from "@sembark-travel/ui/list"
import { IListResponse, XHRInstance } from "@sembark-travel/xhr"
import { useEffect } from "react"
import { $PropertyType } from "utility-types"
import { generatePath } from "../router-utils"
import { SelectUsers } from "../Users"
import { ISuspenseTransaction } from "./store"
import { DatePickerField, SelectField } from "@sembark-travel/ui/form"

async function fetchTransactions(
	xhr: XHRInstance,
	params: IFilters
): Promise<IListResponse<ISuspenseTransaction>> {
	return xhr
		.get("/accounting/suspense-transactions", {
			params: filtersToRequestParams(params),
		})
		.then((resp) => resp.data)
}

export function SuspenseTransactions({
	params: paramsProps = {},
	pageKey = "accounting/suspense-transactions",
	title = "Suspense Transactions",
}: {
	title?: string
	params?: IFilters
	pageKey?: string
}) {
	const [query, setQuery] = useLocationQuery({
		fromQuery: locationQueryToParams,
		toQuery: paramsToLocationQuery,
	})
	const [params, setParams] = useSearch(query)
	useEffect(() => {
		setQuery(params)
	}, [params, setQuery])
	return (
		<>
			<Search
				initialParams={params}
				onSearch={setParams}
				title={title}
				Filters={Filters}
				actions={
					<>
						<ButtonLink
							to={generatePath("/accounting/suspense-transactions/new")}
							level="primary"
						>
							<Icons.Plus /> Log
						</ButtonLink>
					</>
				}
			>
				<ListView<ISuspenseTransaction>
					params={params}
					pageKey={pageKey}
					fetch={(xhr, params = {}) =>
						fetchTransactions(xhr, { ...paramsProps, ...params })
					}
					onPageChange={(page) => setParams({ ...params, page })}
				>
					{({ items: transactions }) => (
						<SuspenseTransactionList transactions={transactions} />
					)}
				</ListView>
			</Search>
		</>
	)
}

export function SuspenseTransactionList({
	transactions,
}: {
	transactions: Array<ISuspenseTransaction>
}) {
	return (
		<Table
			bordered
			striped
			responsive
			headers={["Date", "Narration", "Amount", "Debit", "Credit", "By"]}
			alignCols={{ 2: "right" }}
			rows={transactions.map((p) => [
				<Stack>
					{utcTimestampToLocalDateString(p.date)}
					<Text fontSize="sm" color="muted">
						{fromNow(utcTimestampToLocalDate(p.date))}
					</Text>
				</Stack>,
				<Stack>
					{p.narration}
					{p.reference_id ? (
						<Text fontSize="sm" color="muted">
							{p.reference_id}
						</Text>
					) : null}
				</Stack>,
				numberToLocalString(p.amount),
				p.debit_account,
				p.credit_account,
				<Stack>
					{p.created_by.name}
					<Text fontSize="sm" color="muted">
						{fromNow(utcTimestampToLocalDate(p.created_at))}
					</Text>
				</Stack>,
			])}
		/>
	)
}

interface IFilters extends TSearchParams {
	page?: number
	after?: string | Date
	before?: string | Date
	created_by?: Array<$PropertyType<ISuspenseTransaction, "created_by">>
}

interface IFiltersInLocationQuery extends TSearchParams {
	page?: number
	after?: string
	before?: string
	created_by?: Array<string>
}

function paramsToLocationQuery(params: IFilters): IFiltersInLocationQuery {
	const { q, page, after, before, created_by } = params
	const filters: IFiltersInLocationQuery = {}
	if (q) {
		filters.q = q
	}
	if (page) {
		filters.page = page
	}
	if (after) {
		filters.after = dateToQuery(after)
	}
	if (before) {
		filters.before = dateToQuery(before)
	}
	if (created_by?.length) {
		filters.created_by = created_by.map((a) => `${a.id}_${a.name}`)
	}
	return filters
}

function locationQueryToParams(query: IFiltersInLocationQuery): IFilters {
	const { q, page, after, before, created_by } = query
	const filters: IFilters = {}
	if (q) {
		filters.q = q
	}
	if (page) {
		filters.page = page
	}
	if (after) filters.after = parseDateFromQuery(after)
	if (before) filters.before = parseDateFromQuery(before)
	if (created_by?.length) {
		filters.created_by = created_by.map((s: string) => {
			const [id, ...name] = s.split("_")
			return {
				id: parseInt(id),
				name: name.join("_"),
			}
		}) as unknown as $PropertyType<IFilters, "created_by">
	}
	return filters
}

function filtersToRequestParams(filters: IFilters) {
	const { after, before, page = 1, created_by, ...otherParams } = filters
	return {
		page,
		...otherParams,
		after: after ? dateToUTCString(startOf(after, "day")) : null,
		before: before ? dateToUTCString(startOf(before, "day")) : null,
		created_by: created_by ? created_by.map((a) => a.id) : null,
	}
}

function Filters() {
	return (
		<Stack gap="4">
			<SelectField select={SelectUsers} name="created_by" label="Created By" />
			<DatePickerField label="After" name="after" />
			<DatePickerField label="Before" name="before" />
		</Stack>
	)
}
