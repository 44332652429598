import {
	Stack,
	Button,
	Heading,
	Inline,
	Box,
	Spinner,
	useTimeout,
	CheckboxInput,
	RadioInput,
	Text,
	Grid,
	Col,
	Divider,
	Badge,
	joinAttributes,
	Alert,
} from "@sembark-travel/ui/base"
import {
	ErrorMessage,
	Form,
	FormSpy,
	GetFieldValue,
	SubmissionError,
	TextInputField,
	useFieldValue,
	validateFormValues,
	withServerErrors,
} from "@sembark-travel/ui/form"
import { extendXHRInstance, IListResponse, useXHR } from "@sembark-travel/xhr"
import { useEffect, useRef, useState } from "react"
import useSWR from "swr"
import { ITripSource } from "./store"
import { showSnackbar } from "@sembark-travel/ui/snackbar"
import * as Validator from "yup"
import {
	ConfirmationDialogProvider,
	ConfirmationDialog,
} from "@sembark-travel/ui/dialog"

type TMergeTripSourcesFormData = {
	q?: string
	primary: ITripSource
	duplicates: Array<ITripSource>
}

const validate = validateFormValues(
	Validator.object().shape({
		duplicates: Validator.array().min(
			2,
			"Please select atleast 2 duplicate Trip Sources"
		),
		primary: Validator.mixed().required("Please select a primary Trip Source"),
	})
)

export function MergeTripSources({
	onSuccess,
	onCancel,
}: {
	onSuccess: (primary: ITripSource) => void
	onCancel: () => void
}) {
	const initialValues: Partial<TMergeTripSourcesFormData> = useRef({
		q: "",
		primary: undefined,
		duplicates: [],
	}).current
	const xhr = useXHR()
	const [mergeMoreCount, incrementMergeMoreCount] = useState<number>(0)
	return (
		<Stack gap="6">
			<Heading>Merge Duplicate Trip Sources</Heading>
			<ConfirmationDialogProvider key={mergeMoreCount}>
				{({ confirm }) => (
					<Form<TMergeTripSourcesFormData>
						validate={validate}
						initialValues={initialValues}
						onSubmit={withServerErrors(async (values) => {
							const { primary, duplicates } = values
							if (await confirm()) {
								const { message } = await xhr
									.post<{ message: string }>("/trip-sources/merge", {
										primary: primary?.id,
										duplicates: duplicates.map((d) => d.id),
									})
									.then((resp) => resp.data)
								showSnackbar(message)
								if (
									window.confirm(
										"Do you want to merge more duplicate Trip Sources ?"
									)
								) {
									incrementMergeMoreCount((count) => count + 1)
								} else {
									onSuccess(primary)
								}
							}
						})}
						subscription={{ submitting: true }}
					>
						{({ submitting, handleSubmit }) => (
							<form onSubmit={handleSubmit}>
								<Stack gap="4">
									<Grid gap="8">
										<Col sm={12} md>
											<Stack gap="4" position="relative">
												<Stack
													gap="4"
													position="sticky"
													top="0"
													bgColor="default"
												>
													<Stack gap="1">
														<Heading fontSize="md">
															Duplicate Trip Sources
														</Heading>
														<Text color="muted">
															Please select the duplicate trip sources.
														</Text>
													</Stack>
													<TextInputField
														type="text"
														name="q"
														placeholder="Type 3 or more characters to search..."
														autoFocus
													/>
												</Stack>
												<GetFieldValue<
													TMergeTripSourcesFormData["duplicates"]
												> name="duplicates">
													{({
														value: duplicateTripSources,
														onChange: setDuplicateTripSources,
													}) => (
														<Stack>
															<GetFieldValue<string> name="q">
																{({ value: q }) => (
																	<TripSourcesList q={q}>
																		{({ tripSources }) => (
																			<Stack
																				borderWidth="1"
																				rounded="md"
																				style={{
																					maxHeight: "300px",
																					overflow: "auto",
																				}}
																			>
																				{duplicateTripSources.map(
																					(h, index) => (
																						<Box
																							key={h.id}
																							borderTopWidth={
																								index > 0 ? "1" : undefined
																							}
																						>
																							<Inline
																								gap="4"
																								as="label"
																								paddingY="2"
																								paddingX="4"
																							>
																								<CheckboxInput
																									checked
																									onChange={() =>
																										setDuplicateTripSources(
																											duplicateTripSources.filter(
																												(s) => s.id !== h.id
																											)
																										)
																									}
																								/>
																								<Box flex="1">{h.name}</Box>
																							</Inline>
																						</Box>
																					)
																				)}
																				{tripSources
																					// remove selected items
																					.filter(
																						(h) =>
																							!duplicateTripSources.find(
																								(d) => d.id === h.id
																							)
																					)
																					.map((h, index) => (
																						<Box
																							key={h.id}
																							borderTopWidth={
																								index > 0 ||
																								duplicateTripSources.length
																									? "1"
																									: undefined
																							}
																						>
																							<Inline
																								gap="4"
																								as="label"
																								paddingY="2"
																								paddingX="4"
																							>
																								<CheckboxInput
																									checked={false}
																									onChange={() =>
																										setDuplicateTripSources(
																											duplicateTripSources.concat(
																												[h]
																											)
																										)
																									}
																								/>
																								<Box flex="1">
																									<TripSourceItem
																										tripSource={h}
																									/>
																								</Box>
																							</Inline>
																						</Box>
																					))}
																			</Stack>
																		)}
																	</TripSourcesList>
																)}
															</GetFieldValue>
														</Stack>
													)}
												</GetFieldValue>
												<ErrorMessage name="duplicates" />
											</Stack>
										</Col>
										<Col sm={12} md={6}>
											<SelectPrimaryTripSourceField />
										</Col>
									</Grid>
									<Divider sm />
									<SubmissionError />
									<Inline gap="4">
										<Button type="submit" disabled={submitting}>
											{submitting ? "Merging..." : "Merge Trip Sources"}
										</Button>
										<Button onClick={() => onCancel()} disabled={submitting}>
											Cancel
										</Button>
									</Inline>
									<ConfirmationDialog
										title="Merge Trip Sources Confirmation"
										acceptActionLabel="Yes, Merge these Trip Sources"
									>
										<Stack gap="6">
											<FormSpy<TMergeTripSourcesFormData>
												subscription={{ values: true }}
											>
												{({ values }) => (
													<Stack borderWidth="1" rounded="md" overflow="hidden">
														{[values.primary]
															.concat(
																values.duplicates.filter(
																	(d) => d.id !== values.primary.id
																)
															)
															.map((d, i) => {
																const isPrimary = d.id === values.primary.id
																return (
																	<Box
																		key={d.id}
																		bgColor={isPrimary ? "primary" : undefined}
																		padding="2"
																		borderTopWidth={i > 0 ? "1" : undefined}
																	>
																		<Stack gap="1">
																			{isPrimary ? (
																				<Text>
																					<Badge primary>
																						Primary Trip Source
																					</Badge>
																				</Text>
																			) : null}
																			<TripSourceItem tripSource={d} />
																		</Stack>
																	</Box>
																)
															})}
													</Stack>
												)}
											</FormSpy>
											<Stack gap="2">
												<Heading fontSize="md" color="warning">
													Merging Trip Sources will:
												</Heading>
												<Stack
													gap="2"
													as="ul"
													paddingLeft="4"
													listStyleType="disc"
												>
													{[
														"Replace all existing usage of duplicates with primary Trip Sources (queries, payments and accounting)",
														"Move all contacts to primary Trip Source",
														"Permanently Delete duplicate Trip Sources",
													].map((result) => (
														<Box as="li" key={result}>
															<Text>{result}</Text>
														</Box>
													))}
												</Stack>
											</Stack>
											<Alert
												status="warning"
												title="Are you you sure you want to continue ?"
											>
												This is an irreversible process.
											</Alert>
										</Stack>
									</ConfirmationDialog>
								</Stack>
							</form>
						)}
					</Form>
				)}
			</ConfirmationDialogProvider>
		</Stack>
	)
}

function SelectPrimaryTripSourceField() {
	const { value: duplicateTripSources } =
		useFieldValue<TMergeTripSourcesFormData["duplicates"]>("duplicates")
	const { value: primaryTripSource, onChange: changePrimaryTripSource } =
		useFieldValue<TMergeTripSourcesFormData["primary"]>("primary")
	const resetPrimaryTripSource = Boolean(
		duplicateTripSources.length <= 1 && primaryTripSource
	)
	useEffect(() => {
		if (resetPrimaryTripSource) {
			changePrimaryTripSource(undefined)
		}
	}, [resetPrimaryTripSource, changePrimaryTripSource])

	return (
		<Stack gap="4" position="relative">
			<Stack gap="4" position="sticky" bgColor="default" top="0" zIndex="10">
				<Stack gap="1">
					<Heading fontSize="md">Primary/Master Trip Source</Heading>
					<Text color="muted">
						Please select the primary/master Trip Source of duplicates
					</Text>
				</Stack>
			</Stack>
			{duplicateTripSources.length <= 1 ? (
				<Box padding="4" bgColor="inset" rounded="md">
					<Text>
						Please select some duplicates to select a primary Trip Source
					</Text>
				</Box>
			) : (
				<Stack borderWidth="1" rounded="md" overflow="hidden">
					{duplicateTripSources.map((h, index) => {
						const selected = h.id === primaryTripSource?.id
						return (
							<Box key={h.id} borderTopWidth={index > 0 ? "1" : undefined}>
								<Inline
									gap="4"
									as="label"
									paddingY="2"
									paddingX="4"
									bgColor={selected ? "primary" : { hover: "subtle" }}
								>
									<RadioInput
										checked={selected}
										onChange={(e) => {
											const checked = e.currentTarget.checked
											changePrimaryTripSource(checked ? h : undefined)
										}}
									/>
									<Box flex="1">
										<TripSourceItem tripSource={h} />
									</Box>
								</Inline>
							</Box>
						)
					})}
				</Stack>
			)}
			<ErrorMessage name="primary" />
		</Stack>
	)
}

function TripSourcesList({
	q: propsQ,
	children,
}: {
	q: string
	children: (props: { tripSources: Array<ITripSource> }) => React.ReactNode
}) {
	const [q, setQ] = useState(propsQ)
	const { set: setTimeout, clear: clearTimeout } = useTimeout()
	useEffect(() => {
		setTimeout(() => {
			setQ(propsQ)
		}, 1000)
		return clearTimeout
	}, [propsQ, setTimeout, clearTimeout])
	const xhr = useXHR()
	const abortController = useRef<AbortController>()
	const { data: resp } = useSWR(
		q.length > 2 ? `/trip-sources-merge?${q}` : null,
		async () => {
			// abort any pending requests
			abortController.current?.abort()
			abortController.current = new AbortController()
			const apiInstance = extendXHRInstance(xhr, {
				signal: abortController.current.signal,
			})
			return apiInstance
				.get<IListResponse<ITripSource>>(`/trip-sources`, {
					params: { q, limit: 10, include: "created_by,address" },
				})
				.then((resp) => resp.data)
		}
	)
	if (q.length <= 2) {
		return <>{children({ tripSources: [] })}</>
	}
	if (!resp) {
		return <Spinner padding="4" alignCenter />
	}
	const { data } = resp
	return <>{children({ tripSources: data })}</>
}

function TripSourceItem({ tripSource }: { tripSource: ITripSource }) {
	const { name, short_name, is_agent, address } = tripSource
	return (
		<Stack gap="1" justifyContent="between" fontWeight="normal">
			<Text fontWeight="semibold">
				{name} ({short_name})
			</Text>
			<Inline fontSize="sm" color="muted">
				{joinAttributes(
					address ? address.location.name : null,
					is_agent ? "B2B Agent" : null
				)}
			</Inline>
		</Stack>
	)
}
