import {
	Box,
	Container,
	Stack,
	Text,
	MoneySum,
	Inline,
	CheckboxInput,
} from "@sembark-travel/ui/base"
import { Breadcrumbs } from "@sembark-travel/ui/router"
import { useState } from "react"
import { CalculateTravelActivityPriceForm } from "./../../../TravelActivityPrices"
import { generatePath } from "../../../router-utils"
import { TMoney } from "@sembark-travel/money"
import { Helmet } from "react-helmet-async"
import { PERMISSIONS, useCheckPermissions } from "../../../Auth"

const children: Array<never> = []
const tripDestinations: Array<never> = []

export default function CalculatePrice() {
	const [price, setPrice] = useState<Array<TMoney>>([])
	const [showBookedPrices, setShowBookedPrices] = useState(false)
	const { hasPermission } = useCheckPermissions()
	return (
		<>
			<Helmet>
				<title>Calculate Travel Activity Price</title>
			</Helmet>
			<Breadcrumbs
				title="Calculate Price"
				items={[
					[generatePath("/travel-activity-prices"), "Travel Activity Prices"],
					["", "Calculator"],
				]}
			/>
			<Container bgColor="default" paddingY="8">
				<Stack gap="4">
					<Text>
						Please enter your travel activities to get the updated prices.
					</Text>
					{hasPermission(PERMISSIONS.VIEW_HOTEL_BOOKINGS) ? (
						<Inline as="label" gap="1">
							<CheckboxInput
								name="show_booked_prices"
								checked={showBookedPrices}
								onChange={() => setShowBookedPrices((b) => !b)}
							/>
							<Text as="span">Show Booking Prices</Text>
						</Inline>
					) : null}
					<Box>
						<CalculateTravelActivityPriceForm
							noOfAdults={1}
							children={children}
							onChange={({ given_price }) => {
								setPrice(given_price || [])
							}}
							tripDestinations={tripDestinations}
							showBookedPrices={showBookedPrices}
						/>
					</Box>
					{price ? (
						<Box as="footer" textAlign="right">
							<Box
								as="mark"
								display="inlineBlock"
								fontSize="xl"
								fontWeight="semibold"
								rounded="md"
								paddingX="4"
								paddingY="2"
							>
								Total Price: <MoneySum money={price} />
							</Box>
						</Box>
					) : null}
				</Stack>
			</Container>
		</>
	)
}
