import { numberToLocalString } from "@sembark-travel/number-utils"
import {
	Alert,
	Box,
	Icons,
	Container,
	Inline,
	Heading,
	Col,
	Grid,
	Stack,
	Spinner,
	Button,
	Badge,
	Text,
	RelativeTime,
} from "@sembark-travel/ui/base"
import { Breadcrumbs, Link, queryToSearch } from "@sembark-travel/ui/router"
import { useXHR, XHRInstance } from "@sembark-travel/xhr"
import pluralize from "pluralize"
import useSWR from "swr"
import { AccountsList } from "../Accounting"
import { PERMISSIONS, useCheckPermissions } from "../Auth"
import { ContactsList, IContact, SelectContact } from "../Contacts"
import { generatePath } from "../router-utils"
import { EditItemInDialog } from "./EditItem"
import { ITripSource } from "./store"
import { AddressItem } from "./../Addresses"

function XHR(xhr: XHRInstance) {
	return {
		async get(id: string | number, params: unknown) {
			return xhr
				.get<{ data: ITripSource }>(`/trip-sources/${id}`, { params })
				.then((resp) => resp.data.data)
		},
		async storeContact(id: string | number, data: object) {
			return xhr
				.post<{ data: IContact }>("/trip-source-contacts", {
					...data,
					trip_source_id: id,
				})
				.then((resp) => resp.data.data)
		},
	}
}

export function ItemDetails({
	tripSourceId,
}: {
	tripSourceId: string | number
}) {
	const xhr = useXHR()
	const { hasPermission } = useCheckPermissions()
	const {
		data,
		error,
		mutate: revalidate,
	} = useSWR(`/trip-sources/${tripSourceId}`, () =>
		XHR(xhr).get(tripSourceId, {
			include: "trips_count,connected_tenant,contacts,accounts",
		})
	)
	if (!data && error) {
		return <Alert status="error">{error.message}</Alert>
	}
	if (!data) {
		return <Spinner padding="4" alignCenter />
	}
	const {
		id,
		name,
		short_name,
		accounting_accounts = [],
		contacts = [],
		trips_count = 0,
		connected_tenant,
		address,
		is_agent,
		created_by,
		created_at,
		updated_by,
		updated_at,
	} = data
	return (
		<Box key={id}>
			<Breadcrumbs
				title="Details"
				items={[
					[generatePath("/org/trip-sources"), "Trip Sources"],
					["", name],
				]}
				actions={
					hasPermission(PERMISSIONS.ADD_TRIP_SOURCES) ? (
						<EditItemInDialog tripSource={data} onSuccess={() => revalidate()}>
							{({ edit }) => (
								<Button onClick={() => edit()} size="sm">
									<Icons.Pencil /> Edit
								</Button>
							)}
						</EditItemInDialog>
					) : null
				}
			/>
			<Container paddingY="6" bgColor="default" borderBottomWidth="1">
				<Grid gap="6">
					<Col>
						<Inline alignItems="center" gap="4" flex="1">
							{connected_tenant?.profile_image_url ? (
								<Box
									as="img"
									src={connected_tenant.profile_image_url}
									alt="Logo"
									width="16"
									height="16"
								/>
							) : (
								<Box
									width="16"
									height="16"
									display="flex"
									alignItems="center"
									justifyContent="center"
									rounded="full"
									boxShadow="base"
									bgColor="subtle"
									fontSize="lg"
									fontWeight="semibold"
									borderWidth="1"
									color="muted"
									letterSpacing="wider"
								>
									{short_name.slice(0, 2)}
								</Box>
							)}
							<Stack gap="1">
								<Stack gap="1">
									<Inline gap="4" alignItems="end">
										<Heading as="h2">
											{name} ({short_name})
										</Heading>
										{is_agent ? (
											<Box>
												<Badge>B2B</Badge>
											</Box>
										) : null}
									</Inline>
								</Stack>
								<Box>
									<Link
										fontWeight="semibold"
										to={
											generatePath("/trips") +
											queryToSearch({
												sources: [`${id}_${name}`],
												status: "all",
											})
										}
									>
										{numberToLocalString(trips_count)}{" "}
										{pluralize("Trip", trips_count)}
									</Link>
								</Box>
							</Stack>
						</Inline>
					</Col>
					<Col xs={12} sm={6}>
						<Inline flexWrap="wrap" gap="6">
							{address ? <AddressItem address={address} /> : null}
							<Inline gap="4">
								<Stack gap="1">
									<Text fontSize="sm">Created By</Text>
									<Text>{created_by?.name}</Text>
									<Text fontSize="sm" color="muted">
										<RelativeTime timestamp={created_at} />
									</Text>
								</Stack>
								{updated_at && updated_at !== created_at && updated_by ? (
									<Stack gap="1">
										<Text fontSize="sm">Last Updated By</Text>
										<Text>{updated_by.name}</Text>
										<Text fontSize="sm" color="muted">
											<RelativeTime timestamp={updated_at} />
										</Text>
									</Stack>
								) : null}
							</Inline>
						</Inline>
					</Col>
				</Grid>
			</Container>
			<Container paddingY="6">
				<Grid gap="8">
					<Col xs={12} md={6}>
						<Stack gap="4">
							{is_agent && !contacts.length ? (
								<Alert status="warning" title="B2B Agent without Contacts!!">
									Consider adding atleast one contact to ease query creation and
									communication with this business.
								</Alert>
							) : null}
							<ContactsList
								contacts={contacts}
								onChange={revalidate}
								phoneNumberRequired
								emailRequired
								onCreate={async (data) => {
									const contact = await XHR(xhr).storeContact(id, data)
									revalidate()
									return contact
								}}
							/>
						</Stack>
					</Col>
					{accounting_accounts?.length &&
					hasPermission(PERMISSIONS.MANAGE_ACCOUNTING) ? (
						<Col>
							<Stack gap="1">
								<Heading fontSize="md">Accounts</Heading>
								<AccountsList accounts={accounting_accounts} />
							</Stack>
						</Col>
					) : null}
				</Grid>
			</Container>
		</Box>
	)
}

export function SelectTripSourceContact({
	tripSourceId,
	onCreateSuccess,
	...props
}: Omit<React.ComponentProps<typeof SelectContact>, "onCreate" | "options"> & {
	tripSourceId: number | string
	onCreateSuccess?: (contact: IContact) => void
}) {
	const xhr = useXHR()
	const { data, mutate: revalidate } = useSWR(
		`/trip-sources/${tripSourceId}?select-contact`,
		() =>
			XHR(xhr).get(tripSourceId, {
				include: "contacts",
			})
	)
	return (
		<>
			<SelectContact
				{...props}
				phoneNumberRequired
				emailRequired
				options={data?.contacts || []}
				onCreate={async (data) => {
					const contact = await XHR(xhr).storeContact(tripSourceId, data)
					revalidate()
					onCreateSuccess?.(contact)
					return contact
				}}
			/>
		</>
	)
}
