import { useEffect, useSyncExternalStore } from "react"
import config from "../config"
import Storage from "../storage"
import {
	useMounted,
	Stack,
	Text,
	Box,
	Button,
	Icons,
	Alert,
} from "@sembark-travel/ui/base"
import {
	ConfirmationDialog,
	ConfirmationDialogProvider,
} from "@sembark-travel/ui/dialog"
import { TrackingEvents, trackEvent } from "@sembark-travel/tracking"

const subscribers: Array<(state: string) => void> = []

function getReleaseNotesChecked(): string {
	return Storage.get("release_notes_checked") || "0"
}

const LATEST_VERSION = config.appVersion

function useReleaseNotesCheckedState(): boolean {
	const checkedVersion = useSyncExternalStore<string>((s) => {
		subscribers.push(s)
		return () => subscribers.filter((sub) => sub !== s)
	}, getReleaseNotesChecked)
	return String(checkedVersion) === LATEST_VERSION
}

function setReleaseNotesChecked(version: string) {
	trackEvent(TrackingEvents.release_notes_opened)
	Storage.put("release_notes_checked", version)
	subscribers.forEach((s) => s(version))
}

export function ShouldHighlightLatestReleaseNotesAction({
	children,
}: {
	children: (props: { shouldHighlight: boolean }) => React.ReactNode
}) {
	const releaseNotesChecked = useReleaseNotesCheckedState()
	return <>{children({ shouldHighlight: !releaseNotesChecked })}</>
}

export function LatestReleaseNotes() {
	const isMounted = useMounted()
	const releaseNotesChecked = useReleaseNotesCheckedState()
	useEffect(() => {
		trackEvent(TrackingEvents.release_notes_opened)
	}, [])
	return (
		<Stack gap="8">
			<iframe
				width="100%"
				height="100%"
				title="Release Notes"
				src={config.latestReleaseNotesUrl + `#${LATEST_VERSION}`}
				style={{ minHeight: "100vh" }}
				onLoad={() => {
					setTimeout(
						() => {
							if (isMounted()) {
								setReleaseNotesChecked(LATEST_VERSION)
							}
						},
						2 * 60 * 1000 // give atleast 2 minutes of reading time
					)
				}}
			></iframe>
			<Box>
				{releaseNotesChecked ? (
					<Alert
						status="success"
						title="Great! You have read the release notes."
					>
						<Text>
							Congratulation and thank you for going through the release notes.
							Now, you are ready to utilize these updates into your workflow.
						</Text>
					</Alert>
				) : (
					<ConfirmationDialogProvider>
						{({ confirm }) => (
							<>
								<Box textAlign="right">
									<Button
										size="sm"
										status="primary"
										onClick={() => {
											confirm().then((yes) => {
												if (yes) {
													setReleaseNotesChecked(LATEST_VERSION)
												}
											})
										}}
									>
										<Icons.Ok /> Mark as Read
									</Button>
								</Box>
								<ConfirmationDialog
									title="Read the release notes ?"
									acceptActionLabel="Yes, I read it"
								>
									<Stack gap="4">
										<Text fontSize="md" fontWeight="semibold" color="primary">
											We request you to always go through the release notes
											atleast once.
										</Text>
										<Text>
											This way, you can stay up-to-date with the latest changes
											in your software and utilize the complete software
											features.
										</Text>
										<Text color="warning">
											Please confirm that you have read the documentation.
										</Text>
									</Stack>
								</ConfirmationDialog>
							</>
						)}
					</ConfirmationDialogProvider>
				)}
			</Box>
		</Stack>
	)
}
