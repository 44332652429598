import { Box, BoxOwnProps } from "./Box"
import classNames from "classnames"
import type { ElementType, ForwardedRef } from "react"
import { forwardRef } from "react"
import type {
	PolymorphicForwardRefExoticComponent,
	PolymorphicPropsWithoutRef,
	PolymorphicPropsWithRef,
} from "./polymorphic-types"
import { fixedWidthContainerClassName } from "./container.css"

const DefaultElement = "div"

export type ContainerOwnProps = BoxOwnProps & {
	fluid?: boolean
}

export type ContainerProps<T extends ElementType = typeof DefaultElement> =
	PolymorphicPropsWithRef<ContainerOwnProps, T>

export const Container: PolymorphicForwardRefExoticComponent<
	ContainerOwnProps,
	typeof DefaultElement
> = forwardRef(function Container<
	T extends ElementType = typeof DefaultElement,
>(
	{
		as,
		className,
		fluid = true,
		...restProps
	}: PolymorphicPropsWithoutRef<ContainerOwnProps, T>,
	ref: ForwardedRef<Element>
) {
	const Element: ElementType = as || DefaultElement
	return (
		<Box
			paddingX={{ xs: "4", xl: "8" }}
			width="full"
			marginX="auto"
			className={classNames(className, {
				[fixedWidthContainerClassName]: !fluid,
			})}
			{...restProps}
			as={Element}
			ref={ref}
		/>
	)
})
