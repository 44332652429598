import {
	generatePath as typeUnsafeGeneratePath,
	useLocation,
} from "react-router-dom"
import { Path, Params } from "./router"

export { useLocation }

export * from "./router"

type ParamPath = keyof Params

type RedirectOptions<P> = P extends ParamPath ? [Params[P]] : []

export function generatePath<P extends Path>(
	url: P,
	...options: RedirectOptions<P>
) {
	const params = options?.[0]
	return params ? typeUnsafeGeneratePath(url, params as never) : url
}

/**
 * Join the url
 */
export function joinPaths(...paths: Array<string>): string {
	return paths.reduce((path, next) => {
		if (path.endsWith("/")) {
			path = path.slice(0, -1)
		}
		if (next.startsWith("/")) {
			next = next.slice(1)
		}
		if (!next) return path
		return path + "/" + next
	})
}

export function removeTrailingSlashes(str: string) {
	return str.replace(/\/+$/, "")
}
