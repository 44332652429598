import { useXHR, type XHRInstance } from "@sembark-travel/xhr"
import useSWR from "swr"

export interface IPermission {
	id: number
	name: string
}

export interface IRole {
	id: number
	name: string
	internal_name: string
	created_at: string
	updated_at: string
	permissions?: IPermission[]
}

export function RolesXHR(xhr: XHRInstance) {
	return {
		async getRole(id: number | string): Promise<IRole> {
			return xhr.get(`/roles/${id}`).then(({ data }) => data.data)
		},
	}
}

export function useRole(roleId: string | number) {
	const xhr = useXHR()
	const {
		data,
		error,
		isValidating,
		mutate: revalidate,
	} = useSWR(`/api/roles/${roleId}`, () => RolesXHR(xhr).getRole(roleId))
	const isFetching = !data && !error
	const isRefreshing = isValidating
	return {
		role: data,
		isFetching,
		isRefreshing,
		fetchRole: revalidate,
	}
}
