import {
	Box,
	Button,
	Icons,
	Stack,
	Component,
	Divider,
	Inline,
	Container,
} from "@sembark-travel/ui/base"
import { useXHR } from "@sembark-travel/xhr"
import { useAuthUser } from "./../Auth"
import { NewImageMediaForm } from "./../Media"
import { PhoneNumber } from "../Contacts"

export default function Profile() {
	const { user, fetchUser } = useAuthUser()
	const xhr = useXHR()
	if (!user) {
		return (
			<Box padding="3">
				Can not retrieve the profile at the moment. Please try after some time.
			</Box>
		)
	}
	const { id, tenant, profile_image_url } = user
	return (
		<Container paddingY="4">
			<Inline flexWrap="wrap" gap="6">
				<Stack gap="4">
					{profile_image_url ? (
						<Box>
							<img
								src={profile_image_url}
								style={{ width: "200px", height: "200px" }}
								alt="Profile"
							/>
						</Box>
					) : (
						<Box
							style={{ width: "200px", height: "200px" }}
							display="flex"
							justifyContent="center"
							alignItems="center"
							bgColor="subtle"
							as="label"
							htmlFor="file"
						>
							<Icons.Photograph />
						</Box>
					)}
					<Box>
						<Component initialState={!profile_image_url}>
							{({ state, setState }) =>
								!state ? (
									<Button onClick={() => setState(true)}>
										Update Profile Image
									</Button>
								) : (
									<NewImageMediaForm
										onCancel={() => setState(false)}
										onSubmit={async (data) => {
											await xhr.patch(`/users/${id}/profile-image`, data)
											setState(false)
											await fetchUser(false)
										}}
										help="Image must be 400x400 JPEG/PNG file with less than 1 MB in size."
										crop={{
											aspect: 1,
											minWidth: 100,
											minHeight: 100,
										}}
									/>
								)
							}
						</Component>
					</Box>
				</Stack>
				<Stack gap="4">
					<Stack gap="1">
						<Box
							color="muted"
							fontWeight="semibold"
							letterSpacing="wider"
							textTransform="uppercase"
						>
							Name
						</Box>
						<Box fontSize="lg" fontWeight="semibold">
							{user.name}
						</Box>
					</Stack>
					<Stack gap="1">
						<Box
							color="muted"
							fontWeight="semibold"
							letterSpacing="wider"
							textTransform="uppercase"
						>
							Email
						</Box>
						<Box fontSize="lg">{user.email}</Box>
					</Stack>
					{user.phone_numbers?.length ? (
						<Stack gap="1">
							<Box
								color="muted"
								fontWeight="semibold"
								letterSpacing="wider"
								textTransform="uppercase"
							>
								Contact Number(s)
							</Box>
							<Box fontSize="lg">
								<PhoneNumber value={user.phone_numbers} />
							</Box>
						</Stack>
					) : null}
					{user.roles?.length ? (
						<Stack gap="1">
							<Box
								color="muted"
								fontWeight="semibold"
								letterSpacing="wider"
								textTransform="uppercase"
							>
								Role
							</Box>
							<Box fontSize="lg">
								{user.roles?.map((t) => t.name).join(", ")}
							</Box>
						</Stack>
					) : null}
					{user.manages_users?.length ? (
						<Stack gap="1">
							<Box
								color="muted"
								fontWeight="semibold"
								letterSpacing="wider"
								textTransform="uppercase"
							>
								Manages
							</Box>
							<Box fontSize="lg">
								{user.manages_users?.map((t) => t.name).join(", ")}
							</Box>
						</Stack>
					) : null}
				</Stack>
			</Inline>
			{tenant ? (
				<Stack gap="4">
					<Divider sm />
					<Box as="h3" fontSize="base">
						Business Details
					</Box>
					<Box display={{ xs: "block", sm: "flex" }} gap="4">
						<Box marginBottom="4">
							<Box
								color="muted"
								fontWeight="semibold"
								letterSpacing="wider"
								textTransform="uppercase"
								marginBottom="1"
							>
								Name
							</Box>
							<Box fontSize="lg">{tenant.name}</Box>
						</Box>
						<Box marginBottom="4">
							<Box
								color="muted"
								fontWeight="semibold"
								letterSpacing="wider"
								textTransform="uppercase"
								marginBottom="1"
							>
								Business ID
							</Box>
							<Box
								id="business_id"
								fontSize="lg"
								fontWeight="semibold"
								letterSpacing="wider"
							>
								{tenant.identity_name}
							</Box>
						</Box>
					</Box>
				</Stack>
			) : null}
		</Container>
	)
}
