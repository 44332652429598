import { Helmet } from "react-helmet-async"
import { CommentsList } from "../../../Comments"

export default function FollowupsListingPage() {
	return (
		<>
			<Helmet>
				<title>Follow-Ups</title>
			</Helmet>
			<CommentsList />
		</>
	)
}
