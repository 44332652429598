import { forwardRef } from "react"
import { Root } from "@radix-ui/react-separator"
import { Box, BoxOwnProps } from "./Box"

type SeparatorProps = Omit<
	React.ComponentPropsWithoutRef<typeof Root> & Pick<BoxOwnProps, "bgColor">,
	"children" | "asChild"
>

const Separator = forwardRef<React.ElementRef<typeof Root>, SeparatorProps>(
	({ orientation = "horizontal", decorative = true, ...props }, ref) => (
		<Root
			ref={ref}
			decorative={decorative}
			orientation={orientation}
			asChild
			{...props}
		>
			<Box
				bgColor="inset"
				height={orientation === "horizontal" ? "px" : "full"}
				width={orientation !== "horizontal" ? "px" : "full"}
			/>
		</Root>
	)
)

Separator.displayName = Root.displayName

export { Separator, type SeparatorProps }
