import { $Values } from "utility-types"
import { channels, configureChannels } from "./channels"

export function bootstrap(config: Parameters<typeof configureChannels>[0]) {
	configureChannels(config)
}

export const TrackingEvents = {
	login: "login",
	share: "share",
	logout: "logout",
	user_signed_up: "user_signed_up",
	tenant_signed_up: "tenant_signed_up",
	web_app_update_available: "web_app_update_available",
	web_app_update_clicked: "web_app_update_clicked",
	web_app_opened_via_pwa_install: "web_app_opened_via_pwa_install",
	web_app_opened_via_ms_install: "web_app_opened_via_ms_install",
	api_response_time: "api_response_time",
	incorrect_timezone: "incorrect_timezone",
	hotkey: "hotkey",
	release_notes_opened: "release_notes_opened",
	release_notes_read: "release_notes_read",
} as const

type TTrackingEvents = typeof TrackingEvents

type TAction = $Values<TTrackingEvents>

export function trackEvent(
	action: TTrackingEvents["login"],
	data: { in_app: boolean }
): void
export function trackEvent(
	action:
		| TTrackingEvents["logout"]
		| TTrackingEvents["web_app_update_available"]
		| TTrackingEvents["web_app_update_clicked"]
		| TTrackingEvents["web_app_opened_via_pwa_install"]
		| TTrackingEvents["web_app_opened_via_ms_install"]
		| TTrackingEvents["user_signed_up"]
		| TTrackingEvents["tenant_signed_up"]
): void
export function trackEvent(
	action: TTrackingEvents["share"],
	data: {
		method: "email" | "whatsapp"
		content_type: string
		content_id: string
	}
): void
export function trackEvent(
	action: TTrackingEvents["api_response_time"],
	data: unknown
): void
export function trackEvent(
	action: TTrackingEvents["incorrect_timezone"],
	data: {
		user_timezone_offset: number
		tenant_timezone_offset: number
	}
): void
export function trackEvent(
	action: TTrackingEvents["hotkey"],
	data: {
		label: string
	}
): void
export function trackEvent(
	action:
		| TTrackingEvents["release_notes_opened"]
		| TTrackingEvents["release_notes_read"]
): void
export function trackEvent(event: TAction, data?: unknown): void {
	channels.track(event, data)
}

/**
 * =============================================================
 * ==========
 * ========== Here are commonly used tracking events ===========
 * ==========
 * =============================================================
 * These are simply high-order proxy functions to track different
 * events.
 */

/**
 * All values are in milli-seconds
 */
export function trackApiResponseTime({
	totalTime,
	url,
	search,
	method,
	backendAppTime,
	backendServerTime,
}: {
	totalTime: number | string
	url: string
	search: string
	method: string
	backendAppTime: string
	backendServerTime: string
}): void {
	const backend_bucket =
		Number(backendServerTime) >= 10
			? "10s+"
			: Number(backendServerTime) >= 5
				? "5-10s"
				: Number(backendServerTime) >= 2
					? "2-5s"
					: "<2s"
	if (backend_bucket === "10s+") {
		trackEvent(TrackingEvents.api_response_time, {
			value: String(totalTime),
			backend_bucket,
			bucket:
				Number(totalTime) >= 10
					? "10s+"
					: Number(totalTime) >= 5
						? "5-10s"
						: Number(totalTime) >= 2
							? "2-5s"
							: "<2s",
			method,
			endpoint: url,
			search,
			backend_app_time: backendAppTime,
			backend_server_time: backendServerTime,
		})
	}
}

export type TrackingShareContentType =
	| "cab_schedules"
	| "quotes"
	| "hotel_bookings"
	| "trip_voucher"
	| "trip_hotels_voucher"
	| "trip_holding_email"
	| "error_report"

export type TrackingShareContentId =
	| "booking_enquiry"
	| "driver"
	| "guest"
	| "transport_service_provider"
	| "sembark_support"

export function trackLogin(type?: "re_authenticate") {
	trackEvent(TrackingEvents.login, { in_app: type === "re_authenticate" })
}

export function trackSignup(type: "user" | "tenant") {
	trackEvent(
		type === "user"
			? TrackingEvents.user_signed_up
			: TrackingEvents.tenant_signed_up
	)
}

export function trackLogout() {
	trackEvent(TrackingEvents.logout)
	setAnanlyticsIdentity(null)
}

let USER_ID: string | undefined

export function setUserProperties(properties: {
	tenant_name?: string
	user_id: string
}) {
	channels.identify(properties.user_id, {
		tenantName: properties.tenant_name
			? properties.tenant_name
					.split(" ")
					.map((t) =>
						t
							.split("")
							.map((t, i) => (i % 4 >= 2 ? "*" : t))
							.join("")
					)
					.join(" ")
			: undefined,
	})
}

export function setAnanlyticsIdentity(
	user: { uid: string; tenant?: { name?: string } } | null | undefined
) {
	if (user && USER_ID === user.uid) return
	USER_ID = user?.uid
	// analyticsManager("config", config.gaMeasurementId, { user_id: USER_ID })
	if (user) {
		if (user.tenant) {
			setUserProperties({
				// mask the name
				tenant_name: user.tenant.name || "",
				user_id: user.uid,
			})
		} else {
			setUserProperties({ tenant_name: undefined, user_id: user.uid })
		}
	} else {
		channels.resetIdentity()
	}
}

export function trackWebVitals({
	id,
	name,
	value,
}: {
	id: string
	name: string
	value: number
}) {
	channels.track(name as never, {
		category: "Web Vitals",
		label: id, // id unique to current page load
		value: Math.round(name === "CLS" ? value * 1000 : value), // values must be integers
		nonInteraction: true, // avoids affecting bounce rate
	})
}

export function trackHotkey(hotkey: string) {
	trackEvent(TrackingEvents.hotkey, {
		label: hotkey,
	})
}
