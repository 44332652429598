import { Button, ownerDocument, Icons } from "@sembark-travel/ui/base"
import { showSnackbar } from "@sembark-travel/ui/snackbar"
import { logError, logInfo } from "@sembark-travel/logging"
import React, { useCallback, useEffect, useRef, useState } from "react"

function useCopyProgressState() {
	const [status, changeStatus] = useState<
		"default" | "in_progress" | "completed"
	>("default")
	useEffect(() => {
		if (status === "completed") {
			const handler = setTimeout(() => {
				changeStatus("default")
			}, 3000)
			return () => {
				clearTimeout(handler)
			}
		}
		return
	}, [status])
	return {
		status,
		changeStatus,
	}
}

export function useCopyTextToClipboard(): [
	copyTextToClipboard: (text: string) => Promise<boolean>,
	status: "default" | "in_progress" | "completed",
] {
	const { status, changeStatus } = useCopyProgressState()
	const copyTextToClipboard = useCallback(
		// copy text to clipboard
		// NOTE: promise will always resolve to truthy value
		//  - true -> copied
		//  - false -> not copied
		async (text: string) => {
			const errorContext: { copy_to_clipboard_api?: string } = {}
			try {
				await new Promise((resolve, reject) => {
					Promise.resolve().then(async () => {
						try {
							if (typeof navigator !== "undefined" && navigator.clipboard) {
								// try to copy using the native clipboard api
								errorContext["copy_to_clipboard_api"] = "navigator.clipboard"
								try {
									await navigator.clipboard.writeText(text)
									resolve(true)
								} catch (e) {
									console.error(
										"Copy-to-Clipboard Async: Could not copy text: "
									)
									throw e
								}
							} else {
								throw new Error("Navigator-Clipboard api not available")
							}
						} catch (e) {
							// if the native api not available or is not working,
							// use the fallback-node mechanism
							errorContext["copy_to_clipboard_api"] = "fallback-node"
							if (typeof document !== "undefined") {
								const textArea = document.createElement("textarea")
								textArea.value = text
								// Avoid scrolling to bottom
								textArea.style.top = "0"
								textArea.style.left = "0"
								textArea.style.position = "fixed"
								document.body.appendChild(textArea)
								try {
									const copied = await copyNodeToClipboard(textArea)
									if (copied) {
										resolve(true)
									} else {
										throw new Error(
											"Unable to copy-to-clipboard using textarea node"
										)
									}
									document.body.removeChild(textArea)
								} catch (err) {
									console.error(
										"Copy-to-Clipboard Fallback: Oops, unable to copy",
										err
									)
									reject(err)
								}
							} else {
								reject(
									"Unable to access DOM apis for fallback-node copy-to-clipboard."
								)
							}
						}
					})
				})
				changeStatus("completed")
				return true
			} catch (err) {
				changeStatus("default")
				logError(err, (scope) => {
					Object.keys(errorContext).forEach((key) => {
						scope.setTag(key, errorContext[key as keyof typeof errorContext])
					})
					return scope
				})
				showSnackbar("Sorry! This browser doesn't support copy-to-clipboard.")
				return false
			}
		},
		[changeStatus]
	)
	return [copyTextToClipboard, status]
}

export function useCopyNodeToClipboard<T extends HTMLElement = HTMLElement>(
	includeSelector = false
): [
	copyNodeToClipboard: (node: T) => Promise<boolean>,
	status: "default" | "in_progress" | "completed",
	nodeToCopy: React.MutableRefObject<T | null>,
] {
	const nodeRef = useRef<T>(null)
	const { status, changeStatus } = useCopyProgressState()
	const copy = useCallback(
		async (nodeToCopy: T) => {
			changeStatus("in_progress")
			try {
				await copyNodeToClipboard(nodeToCopy, includeSelector)
				changeStatus("completed")
				return true
			} catch (e) {
				changeStatus("default")
				showSnackbar(
					"Sorry! Could not copy to clipboard. Please manually select the content and use Ctrl+c or Cmd+c to copy"
				)
				logError(e, (scope) => {
					scope.setTag("copy_to_clipboard_api", "node")
					return scope
				})
			}
			return false
		},
		[changeStatus, includeSelector]
	)
	return [copy, status, nodeRef]
}

/**
 * Copy a HTML Element node to clipboard
 */
export function CopyToClipboard<T extends HTMLElement = HTMLElement>({
	children,
	includeSelector = false,
}: {
	children: (props: {
		nodeToCopy: React.MutableRefObject<T | null>
		/**
		 * Children are passed with a copy method. You can optionally
		 * pass a HTMLElement as override the `node` or to handle
		 * multiple copy using sinle wrapper component
		 */
		copy: (nodeToCopy?: React.RefObject<T | null>) => Promise<boolean>
		copyNodeTextToClipboard: (
			nodeToCopy?: React.RefObject<T | null>
		) => Promise<boolean>
		copyTextToClipboard: (text: string) => Promise<boolean>
		/**
		 * To show a success message when copied
		 */
		copied: boolean
		/**
		 * To show a success message when copied
		 */
		copying: boolean
	}) => React.ReactNode
	includeSelector?: boolean
}) {
	const [copyText, copyStatusOfText] = useCopyTextToClipboard()
	const [copyNode, copyStatusOfNode, nodeRef] =
		useCopyNodeToClipboard<T>(includeSelector)
	return (
		<>
			{children({
				nodeToCopy: nodeRef,
				copy: async (newRef) => {
					const nodeToCopy = newRef?.current || nodeRef.current
					if (nodeToCopy) {
						return copyNode(nodeToCopy)
					}
					return false
				},
				copyTextToClipboard: copyText,
				copyNodeTextToClipboard: async (newRef) => {
					const nodeToCopy = newRef?.current || nodeRef.current
					if (nodeToCopy) {
						return copyText(nodeToCopy.innerText)
					}
					return false
				},
				copying:
					copyStatusOfText === "in_progress" ||
					copyStatusOfNode === "in_progress",
				copied:
					copyStatusOfNode === "completed" || copyStatusOfText === "completed",
			})}
		</>
	)
}

export function CopyToClipboardButton({
	onClick,
	children,
	disabled,
	status: btnStatus,
	iconOnly = false,
	...props
}: Omit<React.ComponentProps<typeof Button>, "onClick"> & {
	onClick: () => Promise<boolean>
	iconOnly?: boolean
}) {
	const { status, changeStatus } = useCopyProgressState()
	return (
		<Button
			{...props}
			disabled={disabled || status === "in_progress" || status === "completed"}
			status={
				status === "in_progress"
					? undefined
					: status === "completed"
						? "success"
						: btnStatus
			}
			onClick={() => {
				if (disabled) return
				changeStatus("in_progress")
				onClick()
					.then((copied) => {
						if (copied) {
							changeStatus("completed")
						} else {
							changeStatus("default")
						}
					})
					.catch(() => changeStatus("default"))
			}}
		>
			{status === "in_progress" ? (
				<Icons.Refresh spin />
			) : status === "completed" ? (
				<>
					<Icons.Ok />
					{!iconOnly ? " Copied" : null}
				</>
			) : (
				children
			)}
		</Button>
	)
}

async function copyNodeToClipboard(
	selector: HTMLElement | string,
	includeSelector = false
): Promise<boolean> {
	const document = ownerDocument()
	let node: HTMLElement | null
	if (!document || !window) {
		throw new Error(
			"HTMLDOM and Window must be in context for selection to work"
		)
	}
	if (typeof selector === "string") {
		node = document.querySelector(selector)
	} else {
		node = selector
	}
	if (!node) {
		throw new Error("Node not found")
	}
	try {
		const tagName = node.tagName.toLowerCase()
		if (tagName === "textarea" || tagName === "input") {
			// copy the value
			await navigator.clipboard.writeText((node as HTMLTextAreaElement).value)
			return true
		}
		if (!includeSelector && node.innerText.trim() === node.innerHTML.trim()) {
			// copy the inner text
			await navigator.clipboard.writeText(node.innerText)
			return true
		}
		// copy the inner html
		const content = includeSelector ? node.outerHTML : node.innerHTML
		const blobInput = new Blob([content], { type: "text/html" })
		const clipboardItemInput = new ClipboardItem({ "text/html": blobInput })
		await navigator.clipboard.write([clipboardItemInput])
		return true
	} catch (e) {
		const error = e as Error
		logInfo("Copy-to-Clipboard Failed", {
			tags: { message: error.message || "Unknown" },
		})
		// create a range that selects the root node
		const selection = window.getSelection()
		if (selection) {
			selection.removeAllRanges()
			const range = document.createRange()
			if (includeSelector) {
				range.selectNode(node)
			} else {
				range.selectNodeContents(node)
			}
			// select the range
			selection.addRange(range)
			document.execCommand("copy")
			return true
		}
	}
	return false
}
