import {
	Box,
	Icons,
	Heading,
	Text,
	Stack,
	Inline,
	Divider,
} from "@sembark-travel/ui/base"
import { ButtonLink, Link } from "@sembark-travel/ui/router"
import { generatePath, useLocation } from "../../../router-utils"
import { Helmet } from "react-helmet-async"

export default function OnboardingDonePage() {
	const location = useLocation()
	const NEXT_STEPS = [
		{
			to: generatePath("/trips/new"),
			Icon: Icons.Plus,
			title: "Add New Query",
			description: "Start adding new query to handle your sales leads",
		},
		{
			to: generatePath("/org/profile"),
			Icon: Icons.Identification,
			title: "Add Office Address",
			description:
				"Add office addresses which can be used in communications to guests and your business parters",
		},
		{
			to: generatePath("/hotels/new"),
			Icon: Icons.Bed,
			title: "Add Hotels and Prices",
			description:
				"Add used hotels with prices for easy quote creation with automatic price calculation",
		},
		{
			to: generatePath("/users/new"),
			Icon: Icons.Users,
			title: "Invite Team Members",
			description:
				"Invite your team member to work together with restricted access to modules based on roles.",
		},
	]
	return (
		<>
			<Helmet>
				<title>Done | Onboarding</title>
			</Helmet>
			<Box
				maxWidth="4xl"
				marginX="auto"
				padding="8"
				borderWidth="1"
				rounded="lg"
				bgColor="default"
				marginBottom="16"
			>
				<Stack gap="2">
					<Heading as="h3">
						<Icons.OkCircleSolid size="6" color="success" /> Nicely Done.
					</Heading>
					<Text>
						You have successfully completed your onboarding process and you are
						ready to add queries, create quotes, manage operations and
						accounting.
					</Text>
					<Divider />
					<Heading as="h3">
						<Icons.Support size="6" /> Sembark Support
					</Heading>
					<Text>
						We have documented all our features and frequently asked questions.
						If you want to learn more , you can visit{" "}
						<a
							href={`https://docs.sembark.com?ref=${encodeURIComponent(
								location.pathname
							)}`}
							target="_blank"
							rel="noopener noreferrer"
						>
							https://docs.sembark.com
						</a>{" "}
						any time. You can also email us at{" "}
						<a href="mailto:support@sembark.com">support@sembark.com</a> if you
						need any assistant.
					</Text>
				</Stack>
				<Divider />
				<Stack gap="4">
					<Heading as="h4">What's Next ?</Heading>
					<Text>
						Now you can explore other features and learn more by doing yourself.
						Here are some things you can start with:
					</Text>
					<Stack gap="4" as="ul">
						{NEXT_STEPS.map(({ to, Icon, title, description }) => (
							<Box as="li" key={title}>
								<Link to={to} color={{ hover: "accent" }}>
									<Inline gap="4" alignItems="center">
										<Box
											size="12"
											display="inlineFlex"
											justifyContent="center"
											alignItems="center"
											bgColor="primary"
											borderWidth="1"
											rounded="full"
										>
											<Icon size="6" />
										</Box>
										<Stack>
											<Heading as="h4" fontSize="base">
												{title}
											</Heading>
											<Text color="muted">{description}</Text>
										</Stack>
									</Inline>
								</Link>
							</Box>
						))}
					</Stack>
					<Divider sm />
					<Box textAlign="center">
						<ButtonLink
							to={generatePath("/trips") + "?from=onboarding"}
							level="primary"
						>
							<Icons.Home /> Visit Queries Page
						</ButtonLink>
					</Box>
				</Stack>
			</Box>
		</>
	)
}
