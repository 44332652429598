import { Box, BoxProps } from "./Box"
import { Heading, HeadingProps } from "./Text"

export function Card({
	title,
	children,
	...props
}: Omit<BoxProps, "title"> & {
	title?: React.ReactNode
}) {
	return (
		<Box
			rounded="md"
			borderWidth="1"
			marginBottom="4"
			bgColor="default"
			{...props}
		>
			{title ? (
				<CardHeader>
					<CardTitle>{title}</CardTitle>
				</CardHeader>
			) : null}
			{children}
		</Box>
	)
}

export function CardHeader(props: BoxProps) {
	return (
		<Box
			roundedTop="md"
			paddingX="4"
			paddingY="3"
			fontWeight="semibold"
			fontSize="lg"
			bgColor="inset"
			borderBottomWidth="1"
			{...props}
		/>
	)
}

export function CardBody(props: BoxProps) {
	return <Box paddingX="4" paddingY="3" {...props} />
}

export function CardFooter(props: BoxProps) {
	return (
		<Box
			roundedBottom="md"
			paddingX="4"
			paddingY="3"
			borderTopWidth="1"
			{...props}
		/>
	)
}

export function CardTitle(props: HeadingProps) {
	return <Heading as="h4" {...props} />
}
