import { Helmet } from "react-helmet-async"
import { IHotel } from "../../../../Hotels"
import { HotelPayments } from "../../../../Hotels/Item"
import { useOutletContext } from "react-router-dom"

export default function HotelPaymentsPage() {
	const { hotel } = useOutletContext<{ hotel: IHotel }>()
	return (
		<>
			<Helmet>
				<title>Payments | Hotel</title>
			</Helmet>
			<HotelPayments hotel={hotel} />
		</>
	)
}
