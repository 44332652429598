import {
	applyMiddleware,
	legacy_createStore as createStore,
	Middleware,
	Reducer,
	Action,
	Store,
	StoreEnhancer,
} from "redux"
import { composeWithDevTools } from "@redux-devtools/extension"
import { withExtraArgument as withExtraThunkArgument } from "redux-thunk"

let store: Store<unknown> | undefined = undefined

export function configureStore<TState, TAction extends Action<string>>(
	rootReducer: Reducer<TState, TAction>,
	{
		initialState,
		thunkExtraAgrs,
	}: { initialState?: TState; thunkExtraAgrs?: unknown }
) {
	const thunkMiddleware = withExtraThunkArgument(thunkExtraAgrs)
	const middlewares: Middleware[] = [thunkMiddleware]
	const middlewareEnhancer = applyMiddleware(...middlewares)
	const enhancers: StoreEnhancer[] = [middlewareEnhancer]
	store = createStore(
		rootReducer,
		initialState as never,
		composeWithDevTools(...enhancers)
	)
	return store as Store<TState>
}

export function getStore() {
	return store
}

export default configureStore
