import { Helmet } from "react-helmet-async"
import { SuspenseTransactions } from "./../../../../Accounting"

export default function TransactionsPage(
	props: React.ComponentProps<typeof SuspenseTransactions>
) {
	return (
		<>
			<Helmet>
				<title>Suspense Transactions</title>
			</Helmet>
			<SuspenseTransactions {...props} />
		</>
	)
}
