import {
	Alert,
	Badge,
	Box,
	Button,
	Inline,
	Icons,
	Table,
	Divider,
	Stack,
	TableDataCell,
	Heading,
	useBreakpoints,
} from "@sembark-travel/ui/base"
import { useDialog, Dialog } from "@sembark-travel/ui/dialog"
import {
	numberToLocalString,
	withOrdinalSuffix,
} from "@sembark-travel/number-utils"
import { useXHR, XHRInstance } from "@sembark-travel/xhr"
import {
	Form,
	withServerErrors,
	useFieldValue,
	TextInputField,
	DatePickerField,
	validateFormValues,
	SubmissionError,
	TextAreaInputField,
	FieldArray,
	arrayMutators,
	GetFieldValue,
	EmptyNumberValidator,
} from "@sembark-travel/ui/form"
import React, { useEffect, useMemo, useRef } from "react"
import * as Validator from "yup"
import { IPayment } from "./store"
import {
	utcTimestampToLocalDate,
	dateToUTCString,
	endOf,
	formatDate,
} from "@sembark-travel/datetime-utils"

import { sortInstalmentsByDueDate } from "./utils"

function XHR(xhr: XHRInstance) {
	return {
		async updatePayment(paymentId: number, data: unknown): Promise<IPayment> {
			return xhr
				.patch(`/payments/${paymentId}`, data)
				.then((resp) => resp.data.data)
		},
	}
}

export function UpdatePayment({
	payment,
	onChange,
}: {
	payment: IPayment
	onChange?: () => void
}) {
	const xhr = useXHR()
	const [dialogOpen, open, close] = useDialog()
	return (
		<>
			<Button onClick={open} level="secondary">
				Update Payment / Instalments
			</Button>
			<Dialog
				open={dialogOpen}
				onClose={close}
				title="Update Instalments Amount"
			>
				<Dialog.Body>
					<PaymentForm
						paidAmount={payment.paid_amount}
						initialValues={{
							currency: payment.currency,
							amount: payment.amount,
							instalments: (payment.instalments || []).map(
								({ id, amount, due_at, currency, paid_at }) => ({
									id,
									amount: Number(amount),
									paid_at,
									due_at: utcTimestampToLocalDate(due_at),
									currency,
								})
							),
						}}
						onSubmit={async (data) =>
							XHR(xhr)
								.updatePayment(payment.id, data)
								.then(onChange)
								.then(close)
						}
						onCancel={close}
					/>
				</Dialog.Body>
			</Dialog>
		</>
	)
}

const paymentValidationSchema = validateFormValues(
	Validator.object()
		.required()
		.shape({
			amount: EmptyNumberValidator()
				.required("Total amount is required")
				.min(0, "Total amount should not be negative"),
			instalments: Validator.array()
				.min(1, "Please create atleast one instalment")
				.of(
					Validator.object().shape({
						amount: EmptyNumberValidator()
							.required("Instalment amount is required")
							.min(0, "Instalment amount should not be nagative"),
						due_at: Validator.date().required("Due date is required"),
					})
				),
		})
)

interface IInstalmentFieldValue {
	id?: number
	due_at: string | Date
	amount: number
	currency: string
	paid_at?: string
	percentage?: number | string
}

export function PaymentForm({
	initialValues,
	paidAmount,
	onSubmit,
	onCancel,
}: {
	paidAmount?: number
	initialValues: {
		currency: string
		amount: number
		comments?: string
		instalments?: Array<IInstalmentFieldValue>
	}
	onSubmit: (data: {
		currency: string
		amount: number
		instalments: Array<Record<string, unknown>>
		comments?: string
	}) => Promise<void>
	onCancel: () => void
}) {
	return (
		<Form<typeof initialValues>
			initialValues={initialValues}
			validate={paymentValidationSchema}
			onSubmit={withServerErrors(
				async ({ amount, instalments = [], currency, comments }) => {
					await onSubmit({
						amount,
						comments,
						currency,
						instalments:
							instalments?.map(({ id, amount, currency, due_at }) => ({
								id,
								amount,
								due_at: dateToUTCString(endOf(due_at, "day")),
								due_at_local: formatDate(endOf(due_at, "day"), "YYYY-MM-DD"),
								currency,
							})) || [],
					})
				}
			)}
			mutators={{ ...arrayMutators }}
			subscription={{ submitting: true }}
		>
			{({ submitting, handleSubmit }) => {
				return (
					<form noValidate onSubmit={handleSubmit}>
						<Stack gap="4">
							<SubmissionError />
							<Box padding="4" bgColor="primary" rounded="lg" boxShadow="inner">
								<Box maxWidth="xs">
									<TextInputField
										label="Total Payment Amount"
										secondaryLabel={initialValues.currency}
										name="amount"
										type="number"
										min={paidAmount || 1}
										required
									/>
								</Box>
							</Box>
							<GetFieldValue<number | undefined> name="amount">
								{({ value }) => (
									<Stack gap="2">
										<Heading as="h5">Instalments</Heading>
										<InstalmentsInputField
											name="instalments"
											totalAmount={value || 0}
											canNotAddEmpty
											currency={initialValues.currency}
										/>
									</Stack>
								)}
							</GetFieldValue>
							<TextAreaInputField
								label="Comments"
								name="comments"
								placeholder="Regarding payment or instalment changes..."
							/>
						</Stack>
						<Divider sm />
						<Inline gap="4">
							<Button disabled={submitting} type="submit">
								{submitting ? "Please wait..." : "Save"}
							</Button>
							<Button onClick={onCancel} level="tertiary" disabled={submitting}>
								Cancel
							</Button>
						</Inline>
					</form>
				)
			}}
		</Form>
	)
}

const emptyArray: Array<never> = []

export function InstalmentsInputField({
	name,
	totalAmount = 0,
	label,
	min = 1,
	canNotAddEmpty,
	currency,
}: {
	name: string
	totalAmount?: number | ""
	label?: React.ReactNode
	min?: number
	canNotAddEmpty?: boolean
	currency: string
}) {
	const { value, onChange } = useFieldValue<Array<IInstalmentFieldValue>>(name)
	const { xs, sm } = useBreakpoints()
	const instalments = useMemo(() => value || emptyArray, [value])
	const totalInstalmentAmount = useMemo(
		() =>
			instalments.reduce<number>(
				(instalmentAmount, { amount }) =>
					instalmentAmount + Number(amount || 0),
				0
			),
		[instalments]
	)
	// make sure all instalments have a percentage attribute
	const instalmentsRef = useRef(instalments)
	instalmentsRef.current = instalments
	useEffect(() => {
		if (instalmentsRef.current.length) {
			onChange(
				instalmentsRef.current.map((i) => {
					const percentage = Number(
						(i.amount / Number(totalAmount)) * 100
					).toFixed(1)
					return {
						...i,
						percentage,
					}
				})
			)
		}
	}, [totalAmount, onChange])
	const remainingInstalmentAmount = Number(
		Number((totalAmount || 0) - totalInstalmentAmount).toFixed(2)
	)
	const canAddMore = canNotAddEmpty ? remainingInstalmentAmount !== 0 : true
	const instalmentsByDueDate = useMemo(() => {
		return sortInstalmentsByDueDate(instalments)
	}, [instalments])
	return (
		<Box style={{ fontVariantNumeric: "tabular-nums" }}>
			{label ? (
				<Box fontWeight="semibold" marginBottom="2">
					{label}
				</Box>
			) : null}
			{Number(totalAmount || 0) < 0 ? (
				<Alert status="warning">Invalid total amount ({totalAmount} /-)</Alert>
			) : null}
			<FieldArray name="instalments">
				{({ fields }) => {
					if (xs || sm)
						return (
							<Stack as="ol" gap="4">
								{instalments.map((instalment, index, instalments) => {
									const instalmentOrderId =
										instalmentsByDueDate.indexOf(instalment)
									const incorrectOrder = index !== instalmentOrderId
									return (
										<Stack
											key={index}
											as="li"
											padding="4"
											borderWidth="1"
											rounded="md"
											bgColor="default"
											gap="2"
										>
											<Inline
												justifyContent="between"
												gap="4"
												alignItems="center"
											>
												<Box fontFamily="mono" marginBottom="2">
													{withOrdinalSuffix(instalmentOrderId + 1)}{" "}
													{incorrectOrder ? (
														<Button
															level="tertiary"
															title={
																incorrectOrder
																	? `Should be on ${withOrdinalSuffix(
																			instalmentOrderId + 1
																		)} row but is on ${withOrdinalSuffix(
																			index + 1
																		)} row. Click to auto arrange all.`
																	: undefined
															}
															onClick={() => onChange(instalmentsByDueDate)}
														>
															<Icons.SwitchHorizontal rotate="90" />
														</Button>
													) : null}{" "}
													<Badge>
														{instalments.length > 2
															? instalmentOrderId === 0
																? "First"
																: instalmentOrderId === instalments.length - 1
																	? "Last"
																	: null
															: null}
													</Badge>{" "}
												</Box>
												{instalment.paid_at ? (
													<Box>
														<Badge warning>Already Paid</Badge>
													</Box>
												) : typeof instalment.id !== "number" &&
												  instalments.length > min ? (
													<Box>
														<Button
															onClick={() => fields.remove(index)}
															title="Remove This Instalment"
															size="sm"
															status="warning"
														>
															<Icons.Cancel />
														</Button>
													</Box>
												) : null}
											</Inline>
											<Inline gap="4" flexWrap="wrap">
												<Inline>
													<TextInputField
														name={`${name}.${index}.amount`}
														type="number"
														min={0}
														label={`Amount (${currency})`}
														max={Number(totalAmount) * 100}
														disabled={Boolean(instalment.paid_at)}
														required
														onChange={({ currentTarget: { value } }) => {
															onChange(
																instalments.map((ins, i) =>
																	i !== index
																		? ins
																		: {
																				...ins,
																				percentage: !value.trim()
																					? 0
																					: Number(
																							Number(
																								(Number(value) * 100) /
																									Number(totalAmount)
																							).toFixed(1)
																						),
																				amount:
																					value.trim() as unknown as number,
																			}
																)
															)
														}}
													/>
													<Box paddingX="1" alignSelf="center">
														<Icons.SwitchHorizontal />
													</Box>
													<Inline>
														<TextInputField
															name={`${name}.${index}.percentage`}
															type="number"
															min={0}
															max={10000}
															label="Percentage"
															disabled={Boolean(instalment.paid_at)}
															onChange={({ currentTarget: { value } }) => {
																onChange(
																	instalments.map((ins, i) =>
																		i !== index
																			? ins
																			: {
																					...ins,
																					amount: !value.trim()
																						? 0
																						: Number(
																								Number(
																									(Number(value) *
																										Number(totalAmount)) /
																										100
																								).toFixed(2)
																							),
																					percentage: value.trim(),
																				}
																	)
																)
															}}
														/>
														<Box alignSelf="center" padding="1">
															%
														</Box>
													</Inline>
												</Inline>
												<Box>
													<DatePickerField
														label="Due Date"
														name={`${name}.${index}.due_at`}
														disabled={Boolean(instalment.paid_at)}
														required
														dateFormat="D MMM, YYYY (ddd)"
													/>
												</Box>
											</Inline>
											{!instalment.paid_at &&
											remainingInstalmentAmount !== 0 &&
											Number(instalment.amount) + remainingInstalmentAmount >=
												0 ? (
												<Box>
													<Button
														level="tertiary"
														onClick={() =>
															onChange(
																instalments.map((instalment, i) =>
																	i !== index
																		? instalment
																		: {
																				...instalment,
																				amount:
																					Number(instalment.amount) +
																					remainingInstalmentAmount,
																				percentage: Number(
																					Number(
																						((Number(instalment.amount) +
																							remainingInstalmentAmount) *
																							100) /
																							Number(totalAmount)
																					).toFixed(1)
																				),
																			}
																)
															)
														}
													>
														{remainingInstalmentAmount > 0 ? "Add" : "Remove"}{" "}
														{numberToLocalString(
															Math.abs(remainingInstalmentAmount)
														)}
														{" /-"}
													</Button>
												</Box>
											) : null}
										</Stack>
									)
								})}
								{canAddMore ? (
									<Box>
										<Box>
											{remainingInstalmentAmount < 0 ? (
												<Alert status="error" title="Amount exceeds">
													Total instalment amount exceeds the payment amount by{" "}
													{numberToLocalString(
														Math.abs(remainingInstalmentAmount)
													)}{" "}
													/-
												</Alert>
											) : (
												<Box>
													<Button
														size="sm"
														level={
															remainingInstalmentAmount > 0
																? "primary"
																: undefined
														}
														status={
															remainingInstalmentAmount > 0
																? "primary"
																: undefined
														}
														onClick={() => {
															fields.push({
																id: "new",
																currency,
																amount: remainingInstalmentAmount,
																due_at: new Date(),
																percentage: Number(
																	(remainingInstalmentAmount /
																		Number(totalAmount)) *
																		100
																).toFixed(1),
															})
														}}
													>
														<Icons.Plus /> Add Another Instalment
													</Button>{" "}
													{remainingInstalmentAmount > 0 ? (
														<Badge warning>
															(Remaining Amount:{" "}
															{numberToLocalString(remainingInstalmentAmount)}{" "}
															/-)
														</Badge>
													) : null}
												</Box>
											)}
										</Box>
									</Box>
								) : null}
							</Stack>
						)
					return (
						<Table
							hover
							bordered
							headers={["#", <Box>Amount ({currency})</Box>, "Due Date", ""]}
						>
							<tbody>
								{instalments.map((instalment, index, instalments) => {
									const instalmentOrderId =
										instalmentsByDueDate.indexOf(instalment)
									const incorrectOrder = index !== instalmentOrderId
									return (
										<tr key={index}>
											<TableDataCell fontFamily="mono">
												{withOrdinalSuffix(instalmentOrderId + 1)}{" "}
												{incorrectOrder ? (
													<Button
														level="tertiary"
														size="sm"
														title={
															incorrectOrder
																? `Should be on ${withOrdinalSuffix(
																		instalmentOrderId + 1
																	)} row but is on ${withOrdinalSuffix(
																		index + 1
																	)} row. Click to auto arrange all.`
																: undefined
														}
														onClick={() => onChange(instalmentsByDueDate)}
													>
														<Icons.SwitchHorizontal rotate="90" />
													</Button>
												) : null}{" "}
												<Badge>
													{instalments.length > 2
														? instalmentOrderId === 0
															? "First"
															: instalmentOrderId === instalments.length - 1
																? "Last"
																: null
														: null}
												</Badge>{" "}
											</TableDataCell>
											<TableDataCell>
												<Inline>
													<TextInputField
														name={`${name}.${index}.amount`}
														type="number"
														min={0}
														max={Number(totalAmount) * 100}
														disabled={Boolean(instalment.paid_at)}
														required
														onChange={({ currentTarget: { value } }) => {
															onChange(
																instalments.map((ins, i) =>
																	i !== index
																		? ins
																		: {
																				...ins,
																				percentage: !value.trim()
																					? 0
																					: Number(
																							Number(
																								(Number(value) * 100) /
																									Number(totalAmount)
																							).toFixed(1)
																						),
																				amount:
																					value.trim() as unknown as number,
																			}
																)
															)
														}}
													/>
													<Box paddingX="1" alignSelf="center">
														<Icons.SwitchHorizontal />
													</Box>
													<Inline>
														<TextInputField
															name={`${name}.${index}.percentage`}
															type="number"
															min={0}
															max={10000}
															disabled={Boolean(instalment.paid_at)}
															onChange={({ currentTarget: { value } }) => {
																onChange(
																	instalments.map((ins, i) =>
																		i !== index
																			? ins
																			: {
																					...ins,
																					amount: !value.trim()
																						? 0
																						: Number(
																								Number(
																									(Number(value) *
																										Number(totalAmount)) /
																										100
																								).toFixed(2)
																							),
																					percentage: value.trim(),
																				}
																	)
																)
															}}
														/>
														<Box padding="1" alignSelf="center">
															%
														</Box>
													</Inline>
												</Inline>
												{!instalment.paid_at &&
												remainingInstalmentAmount !== 0 &&
												Number(instalment.amount) + remainingInstalmentAmount >=
													0 ? (
													<Box>
														<Button
															level="tertiary"
															size="sm"
															onClick={() =>
																onChange(
																	instalments.map((instalment, i) =>
																		i !== index
																			? instalment
																			: {
																					...instalment,
																					amount:
																						Number(instalment.amount) +
																						remainingInstalmentAmount,
																					percentage: Number(
																						Number(
																							((Number(instalment.amount) +
																								remainingInstalmentAmount) *
																								100) /
																								Number(totalAmount)
																						).toFixed(1)
																					),
																				}
																	)
																)
															}
														>
															{remainingInstalmentAmount > 0 ? "Add" : "Remove"}{" "}
															{numberToLocalString(
																Math.abs(remainingInstalmentAmount)
															)}
															{" /-"}
														</Button>
													</Box>
												) : null}
											</TableDataCell>
											<TableDataCell>
												<DatePickerField
													name={`${name}.${index}.due_at`}
													disabled={Boolean(instalment.paid_at)}
													rightAlign
													required
													dateFormat="D MMM, YYYY (ddd)"
												/>
											</TableDataCell>
											<TableDataCell>
												{instalment.paid_at ? (
													<Box>
														<Badge warning>Already Paid</Badge>
													</Box>
												) : (
													<Box>
														{typeof instalment.id !== "number" &&
														instalments.length > min ? (
															<Button
																onClick={() => fields.remove(index)}
																title="Remove This Instalment"
																size="sm"
															>
																<Icons.Cancel />
															</Button>
														) : null}
													</Box>
												)}
											</TableDataCell>
										</tr>
									)
								})}
								{canAddMore ? (
									<tr>
										<TableDataCell colSpan={4}>
											{remainingInstalmentAmount < 0 ? (
												<Alert status="error" title="Amount exceeds">
													Total instalment amount exceeds the payment amount by{" "}
													{numberToLocalString(
														Math.abs(remainingInstalmentAmount)
													)}{" "}
													/-
												</Alert>
											) : (
												<Box>
													<Button
														level={
															remainingInstalmentAmount > 0
																? "primary"
																: undefined
														}
														status={
															remainingInstalmentAmount > 0
																? "primary"
																: undefined
														}
														size="sm"
														onClick={() => {
															fields.push({
																id: "new",
																currency,
																amount: remainingInstalmentAmount,
																due_at: new Date(),
																percentage: Number(
																	(remainingInstalmentAmount /
																		Number(totalAmount)) *
																		100
																).toFixed(1),
															})
														}}
													>
														<Icons.Plus /> Add Another Instalment
													</Button>{" "}
													{remainingInstalmentAmount > 0 ? (
														<Badge warning>
															(Remaining Amount:{" "}
															{numberToLocalString(remainingInstalmentAmount)}{" "}
															/-)
														</Badge>
													) : null}
												</Box>
											)}
										</TableDataCell>
									</tr>
								) : null}
							</tbody>
						</Table>
					)
				}}
			</FieldArray>
		</Box>
	)
}
