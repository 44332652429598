import { Breadcrumbs } from "@sembark-travel/ui/router"
import { Helmet } from "react-helmet-async"
import { NewQuote, useTrip } from "../../../Trips"
import { useParams, generatePath } from "../../../router-utils"
import { Spinner } from "@sembark-travel/ui/base"

export default function TripDetails() {
	const { tripId } = useParams("/trips/:tripId/new-quote")
	const { trip } = useTrip(tripId)
	return (
		<>
			<Helmet>
				<title>Create Quote</title>
			</Helmet>
			<Breadcrumbs
				title="New Quote"
				items={[
					[generatePath("/trips"), "Trips"],
					[generatePath("/trips/:tripId", { tripId }), trip?.name || tripId],
					["", "Create Quote"],
				]}
			/>
			{!trip ? <Spinner alignCenter padding="4" /> : <NewQuote trip={trip} />}
		</>
	)
}
