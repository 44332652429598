import {
	AsyncSelect,
	AsyncSelectProps,
	Box,
	Button,
	Icons,
	Table,
	Tooltip,
	Time,
} from "@sembark-travel/ui/base"
import { utcTimestampToLocalDate } from "@sembark-travel/datetime-utils"
import { Link, useLocationQuery } from "@sembark-travel/ui/router"
import {
	ListView,
	Search,
	TSearchParams,
	useSearch,
} from "@sembark-travel/ui/list"
import { IListResponse, useXHR, XHRInstance } from "@sembark-travel/xhr"
import React, { Fragment, useEffect } from "react"
import { Omit } from "utility-types"
import { TransactionDetailsInDialog } from "../Accounting"
import { generatePath } from "../router-utils"
import { IBankAccountStatement } from "./store"
import { SwitchInputField } from "@sembark-travel/ui/form"

function XHR(xhr: XHRInstance) {
	return {
		async get(
			params?: TFilters
		): Promise<IListResponse<IBankAccountStatement>> {
			const { suspense, ...otherParams } = params || {}
			const transformedParams: typeof otherParams & {
				suspense?: 1
			} = { ...otherParams }
			if (suspense) {
				transformedParams.suspense = 1
			}
			return xhr
				.get("/accounting/bank-account-statements", {
					params: transformedParams,
				})
				.then((resp) => resp.data)
		},
	}
}

type TFilters = TSearchParams & {
	suspense?: boolean
}

type TFilterInQuery = TSearchParams & {
	suspense?: 1
}

function filtersToQuery(filters: TFilters): TFilterInQuery {
	const { suspense, q, page } = filters
	const query: TFilterInQuery = {}
	if (suspense) {
		query.suspense = 1
	}
	if (q) {
		query.q = q
	}
	if (page) {
		query.page = page
	}
	return query
}

function queryToFilters(query: TFilterInQuery): TFilters {
	const { suspense, q, page } = query
	const filters: TFilters = {}
	if (suspense) {
		filters.suspense = true
	}
	if (q) {
		filters.q = ""
	}
	if (page) {
		filters.page = page
	}
	return filters
}

export function List({ actions }: { actions?: React.ReactNode }) {
	const [query, setQuery] = useLocationQuery({
		toQuery: filtersToQuery,
		fromQuery: queryToFilters,
	})
	const [params, setParams] = useSearch(query)
	useEffect(() => {
		setQuery(params)
	}, [params, setQuery])
	return (
		<Fragment>
			<Search
				initialParams={params}
				onSearch={(params) => {
					setParams({ ...params, page: 1 })
				}}
				title="Bank Account Statements"
				resetParams={{ q: "" }}
				actions={actions}
				Filters={Filters}
			>
				<ListView<IBankAccountStatement>
					pageKey="bank-account-statements"
					fetch={(xhr, params) => XHR(xhr).get(params)}
					onPageChange={(page) => setParams({ ...params, page })}
					params={params}
				>
					{({ items: statements }) => (
						<Table
							bordered
							responsive
							hover
							headers={[
								"Date",
								"Ref #",
								"Narration",
								"Debit",
								"Credit",
								"Amount",
								"Txn",
							]}
							alignCols={{ 5: "right" }}
							rows={statements.map(
								({
									date,
									narration,
									reference_id,
									account,
									debit_amount,
									credit_amount,
									journal,
									is_suspense,
								}) => {
									let credit_account = journal?.postings?.find(
										(posting) => posting.is_credit
									)?.account
									let debit_account = journal?.postings?.find(
										(posting) => !posting.is_credit
									)?.account
									const is_credit = credit_amount
									if (!journal) {
										if (is_credit) {
											credit_account = account
										} else {
											debit_account = account
										}
									}
									return [
										<Box whiteSpace="preserve">
											<Time value={utcTimestampToLocalDate(date)} />
											{is_suspense ? (
												<Tooltip content="Suspense entry">
													<Box
														color="warning"
														display="inlineBlock"
														marginLeft="1"
													>
														<Icons.Info />
													</Box>
												</Tooltip>
											) : null}
										</Box>,
										reference_id,
										<Box fontSize="sm">{narration}</Box>,
										<Box>
											{debit_account ? (
												<Link
													to={generatePath("/accounting/accounts/:accountId", {
														accountId: debit_account.id.toString(),
													})}
												>
													{debit_account.name}
												</Link>
											) : null}
										</Box>,
										<Box>
											{credit_account ? (
												<Link
													to={generatePath("/accounting/accounts/:accountId", {
														accountId: credit_account.id.toString(),
													})}
												>
													{credit_account.name}
												</Link>
											) : null}
										</Box>,
										<Box color={!is_credit ? "danger" : "success"}>
											{debit_amount?.formatted || credit_amount?.formatted}
										</Box>,
										journal ? (
											<Box>
												<TransactionDetailsInDialog transaction={journal}>
													{({ showDetails }) => (
														<Button
															onClick={showDetails}
															level="tertiary"
															size="sm"
															title="View Transaction Details"
														>
															{journal.short_txn_id}
														</Button>
													)}
												</TransactionDetailsInDialog>
											</Box>
										) : null,
									]
								}
							)}
						/>
					)}
				</ListView>
			</Search>
		</Fragment>
	)
}

function Filters() {
	return (
		<>
			<SwitchInputField name="suspense" label="Suspense Only" />
		</>
	)
}

export function SelectBankAccountStatement(
	props: Omit<AsyncSelectProps, "fetch">
) {
	const xhr = useXHR()
	return (
		<AsyncSelect
			multiple
			{...props}
			fetch={(q) =>
				XHR(xhr)
					.get({ q })
					.then((resp) => resp.data.map((b) => ({ ...b, name: b.narration })))
			}
		/>
	)
}
