import {
	Alert,
	Box,
	Container,
	Stack,
	Inline,
	Heading,
	Spinner,
	Icons,
	Text,
	RelativeTime,
} from "@sembark-travel/ui/base"
import { Breadcrumbs, ButtonLink } from "@sembark-travel/ui/router"
import { Markdown } from "@sembark-travel/ui/markdown"
import { useXHR } from "@sembark-travel/xhr"
import useSWR from "swr"
import { PERMISSIONS, useCheckPermissions } from "../Auth"
import { generatePath } from "../router-utils"
import { ITermsAndCondition } from "./store"

export function TermsAndConditionItemDetails({
	tncId,
}: {
	tncId: string | number
}) {
	const xhr = useXHR()
	const { hasPermission } = useCheckPermissions()
	const { data, error } = useSWR<ITermsAndCondition>(
		`/terms-and-conditions/${tncId}`,
		() =>
			xhr.get(`/terms-and-conditions/${tncId}`).then((resp) => resp.data.data)
	)
	if (!data && error) {
		return <Alert status="error">{error.message}</Alert>
	}
	if (!data) {
		return <Spinner padding="4" alignCenter />
	}
	const {
		id,
		name,
		description,
		created_at,
		updated_at,
		created_by,
		updated_by,
		deleted_at,
		deleted_by,
	} = data
	return (
		<Box key={id}>
			<Breadcrumbs
				title="Details"
				items={[
					[generatePath("/org/terms-and-conditions"), "TnC"],
					["", name],
				]}
				actions={
					hasPermission(PERMISSIONS.MANAGE_TRIP_OWNERS) && !deleted_at ? (
						<ButtonLink
							to={generatePath("/terms-and-conditions/:tncId/edit", {
								tncId: id.toString(),
							})}
							level="tertiary"
							size="sm"
						>
							<Icons.Pencil /> Edit
						</ButtonLink>
					) : null
				}
			/>
			<Container paddingY="6" bgColor="default" borderBottomWidth="1">
				<Inline justifyContent="between" gap="6" collapseBelow="md">
					<Stack gap="px">
						<Text color="muted">Name</Text>
						<Heading>{name}</Heading>
					</Stack>
					<Inline gap="8" flexWrap="wrap">
						<Stack gap="px">
							<Text color="muted">Created by</Text>
							<Inline gap="2" alignItems="baseline">
								<Text fontWeight="semibold" fontSize="md">
									{created_by?.name}
								</Text>
								{created_at ? <RelativeTime timestamp={created_at} /> : null}
							</Inline>
						</Stack>
						{updated_by ? (
							<Stack gap="px">
								<Text color="muted">Updated by</Text>
								<Inline gap="2" alignItems="baseline">
									<Text fontWeight="semibold" fontSize="md">
										{updated_by.name}
									</Text>
									{updated_at ? <RelativeTime timestamp={updated_at} /> : null}
								</Inline>
							</Stack>
						) : null}
						{deleted_by ? (
							<Stack gap="px">
								<Text color="muted">Disabled by</Text>
								<Inline gap="2" alignItems="baseline">
									<Text fontWeight="semibold" fontSize="md">
										{deleted_by.name}
									</Text>
									{deleted_at ? <RelativeTime timestamp={deleted_at} /> : null}
								</Inline>
							</Stack>
						) : null}
					</Inline>
				</Inline>
			</Container>
			<Container paddingY="8" bgColor="default" borderBottomWidth="1">
				<Markdown>{description}</Markdown>
			</Container>
		</Box>
	)
}
