import {
	Alert,
	Box,
	Tabs,
	TabsList,
	TabContent,
	Text,
	Stack,
	CheckboxInput,
	Inline,
	Divider,
} from "@sembark-travel/ui/base"
import { NavLink } from "@sembark-travel/ui/router"
import { Outlet } from "react-router-dom"
import { useTripDetailsOutletContext } from "../_trip-details-context"
import { useDiffBetweenLatestQuoteAndBookings } from "../../../../../Trips"
import { useState } from "react"

export default function TripDocumentsLayoutPage() {
	const { trip, onChange } = useTripDetailsOutletContext()
	const { diff } = useDiffBetweenLatestQuoteAndBookings(trip.id)
	const [hasConfirmedDiff, setHasConfirmedDiff] = useState(false)
	if (!trip.converted_at && !trip.on_hold_at) {
		return (
			<Alert
				status="warning"
				title="Please convert the trip to view the documents"
			/>
		)
	}
	return (
		<Box paddingTop="4">
			<Tabs>
				<TabsList>
					{trip.converted_at ? (
						<>
							<NavLink to="trip-voucher">Trip Voucher</NavLink>
							<NavLink to="hotels-voucher">Hotels Voucher</NavLink>
						</>
					) : (
						<>
							<NavLink to="trip-holding-email">Holding Email</NavLink>
						</>
					)}
				</TabsList>
				<TabContent>
					{!trip.cancellation_reason && diff && diff.changed ? (
						<Alert
							status="warning"
							title="Services details (Hotels/Transports) from Latest Quote and Latest Bookings are not up-to-date with each other."
							rounded="none"
						>
							<Stack gap="3">
								<Text>
									Please check the services in quote and bookings, and update
									the details as per the requirements. If checked, please click
									on the below checkbox.
								</Text>
								<Divider marginY="0" borderColor="warning" />
								<Inline gap="2" as="label">
									<CheckboxInput
										checked={hasConfirmedDiff}
										onChange={() => setHasConfirmedDiff(!hasConfirmedDiff)}
									/>
									<Text>
										I understand that it may results in profit loss or
										miscommunication. I have checked the details and everything
										is ok.
									</Text>
								</Inline>
							</Stack>
						</Alert>
					) : null}
					<Outlet
						context={{
							trip,
							onChange,
							hasChangesBetweenQuoteAndBookings:
								diff && diff.changed ? !hasConfirmedDiff : false,
						}}
					/>
				</TabContent>
			</Tabs>
		</Box>
	)
}
