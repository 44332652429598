import { Box, Container, Stack, Text, Icons } from "@sembark-travel/ui/base"
import { ButtonLink } from "@sembark-travel/ui/router"
import { AccountsList } from "../Accounting"
import { IHotelGroup } from "./store"
import { generatePath } from "../router-utils"

export function HotelGroupAccountings({ group }: { group: IHotelGroup }) {
	const { accounting_accounts } = group
	return (
		<Container paddingY="8">
			{!accounting_accounts?.length ? (
				<Stack gap="2">
					<Text fontSize="md" fontWeight="semibold">
						No accounting activities for this hotel group
					</Text>
					<Text fontSize="sm" color="muted">
						Log payments regarding bookings or services for this hotel group to
						manage the accounting.
					</Text>
					<Box>
						<ButtonLink
							to={generatePath("/hotel-groups/:groupId/payments", {
								groupId: String(group.id),
							})}
							size="sm"
						>
							View Due Payments <Icons.ChevronDown rotate="270" />
						</ButtonLink>
					</Box>
				</Stack>
			) : (
				<Box>
					<AccountsList accounts={accounting_accounts} />
				</Box>
			)}
		</Container>
	)
}
