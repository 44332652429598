import {
	addUnit,
	startOf,
	getDiff,
	formatDate,
} from "@sembark-travel/datetime-utils"
import { withOrdinalSuffix } from "@sembark-travel/number-utils"
import {
	TTransportServiceDatewisePricesFieldValue,
	TValidTransportServiceDatewisePricesFieldValue,
} from "./TransportServiceDatewisePricesInputField"
import {
	TTravelActivityDatewisePricesInputFieldValue,
	TValidTravelActivityDatewisePricesInputFieldValue,
	getValidTravelActivityData,
} from "../TravelActivityPrices"
import { Required } from "utility-types"
import * as Validator from "yup"
import { collect } from "../utils"

export function createDatesFromStartDateAndDays(
	from: Date,
	days = 1
): Array<Date> {
	const dates = []
	for (let i = 0; i < days; i++) {
		dates.push(startOf(addUnit(from, i, "days"), "day"))
	}
	return dates
}

export function createBookingDateSelectionOptionsFromStartAndEndDates(
	bookingFrom: Date | string | undefined,
	bookingTo: Date | string | undefined
) {
	const dates: Array<{ id: string; name: string; value: Date }> = []
	if (!bookingTo || !bookingFrom) return dates
	const days = getDiff(bookingTo, bookingFrom, "days")
	for (let i = 0; i <= days; i++) {
		const date = addUnit(bookingFrom, i, "day")
		dates.push({
			id: formatDate(date, "YYYY-MM-DD"),
			name: `${withOrdinalSuffix(i + 1)} Day (${formatDate(
				date,
				"ddd D MMM"
			)})`,
			value: startOf(date, "day"),
		})
	}
	return dates
}

export function getValidTransportServiceData(
	c: TTransportServiceDatewisePricesFieldValue
): TValidTransportServiceDatewisePricesFieldValue | undefined {
	const { cabs, transport_service, transport_service_location, ...others } = c
	if (!cabs?.length || !transport_service || !transport_service_location) {
		return undefined
	}
	const validCabs = (c.cabs || []).filter(
		(c): c is Required<typeof c, "__id" | "cab_type" | "no_of_cabs"> =>
			Boolean(c.cab_type && c.no_of_cabs)
	)
	if (!validCabs.length) return undefined
	return {
		...others,
		transport_service,
		transport_service_location,
		cabs: validCabs,
	}
}

export type TDateswiseTransportsAndActivitiesInputFieldValue = Array<{
	__id: number
	dates?: Array<Date>
	// this is only used when we don't have date range to select from
	no_of_days?: number
	services?: Array<
		| {
				__id: number
				type: "transport_service"
				service: TTransportServiceDatewisePricesFieldValue
		  }
		| {
				__id: number
				type: "travel_activity"
				service: TTravelActivityDatewisePricesInputFieldValue
		  }
	>
}>

export type TValidDateswiseTransportsAndActivitiesInputFieldValue = Array<
	Omit<
		Required<TDateswiseTransportsAndActivitiesInputFieldValue[number], "dates">,
		"services"
	> & {
		services: Array<
			| {
					__id: number
					type: "transport_service"
					service: TValidTransportServiceDatewisePricesFieldValue
			  }
			| {
					__id: number
					type: "travel_activity"
					service: TValidTravelActivityDatewisePricesInputFieldValue
			  }
		>
	}
>

export const validDateswiseTransportsAndActivitiesSchema = Validator.array().of(
	Validator.object().shape({
		dates: Validator.array().min(1, "Please select atleast one day"),
		services: Validator.array()
			.min(1, "Please select atleast one service")
			.of(
				Validator.object().shape({
					type: Validator.string(),
				})
			),
	})
)

export function getValidDateswiseTransportsAndActivitiesData(
	daywise_services: TDateswiseTransportsAndActivitiesInputFieldValue | undefined
): TValidDateswiseTransportsAndActivitiesInputFieldValue {
	return (daywise_services || [])
		.filter((s): s is Required<typeof s, "dates" | "services"> =>
			Boolean(s.dates?.length && s.services?.length)
		)
		.map((s) => ({
			...s,
			services: s.services
				.map((s) => {
					let service:
						| TValidTransportServiceDatewisePricesFieldValue
						| TValidTravelActivityDatewisePricesInputFieldValue
						| undefined = undefined
					switch (s.type) {
						case "transport_service":
							service = getValidTransportServiceData(s.service)
							if (!service) return undefined
							return {
								...s,
								service,
							}
						case "travel_activity":
							service = getValidTravelActivityData(s.service)
							if (!service) return undefined
							return {
								...s,
								service,
							}
						default:
							return undefined
					}
				})
				.filter((s): s is Exclude<typeof s, undefined> => {
					return Boolean(s)
				}),
		}))
}

export function groupAndNormalizePriceForDateswiseServicesByDate(
	dateswiseServices: TValidDateswiseTransportsAndActivitiesInputFieldValue
) {
	let sortOrder = 0
	const datewiseServices = dateswiseServices.flatMap(
		({ dates, services }, currentIndex) => {
			const groupId = currentIndex + 1
			return dates.map((date, i) => ({
				date,
				groupId,
				services: services.map(({ type, service, ...others }) => {
					sortOrder++
					if (type === "transport_service") {
						const { cabs, comments, ...s } = service
						const cabsWithPrices = cabs.map((cab) => {
							const { date_wise_prices, ...c } = cab
							const {
								price,
								per_quantity_given_price,
								given_price,
								per_quantity_booked_price,
								booked_price,
								currency,
							} = date_wise_prices?.at(i) ||
								date_wise_prices?.at(0) || {
									currency: "INR",
									price: 0,
									given_price: 0,
									per_quantity_given_price: 0,
									booked_price: 0,
									per_quantity_booked_price: 0,
								}
							return {
								...c,
								currency,
								calculated_price: price,
								per_quantity_given_price: per_quantity_given_price || 0,
								given_price: given_price || 0,
								booked_price: booked_price || 0,
								per_quantity_booked_price: per_quantity_booked_price || 0,
								comments: comments || "",
							}
						})
						return {
							...others,
							type,
							service: {
								...s,
								cabs: cabsWithPrices,
								comments,
								group_id: groupId,
								sort_order: sortOrder,
							},
						}
					}
					const { ticket_tourist_configurations, ...s } = service
					const ticketTouristConfigurationsWithPrices =
						ticket_tourist_configurations.map((config) => {
							const { date_wise_prices, ...c } = config
							const {
								price,
								given_price,
								currency,
								per_quantity_given_price,
								per_quantity_booked_price,
								booked_price,
							} = date_wise_prices?.at(i) ||
								date_wise_prices?.at(0) || {
									currency: "INR",
									calculated_price: 0,
									given_price: 0,
									booked_price: 0,
									per_quantity_given_price: 0,
									per_quantity_booked_price: 0,
								}
							return {
								...c,
								quantity: config.quantity || 0,
								currency,
								calculated_price: price,
								given_price: given_price || 0,
								per_quantity_given_price: per_quantity_given_price || 0,
								per_quantity_booked_price: per_quantity_booked_price || 0,
								booked_price: booked_price || 0,
							}
						})
					return {
						...others,
						type,
						service: {
							...s,
							ticket_tourist_configurations:
								ticketTouristConfigurationsWithPrices,
							group_id: groupId,
							sort_order: sortOrder,
						},
					}
				}),
			}))
		}
	)

	return collect(datewiseServices)
		.groupBy((s) => startOf(s.date, "day").toISOString())
		.toArray()
		.map((data) => ({
			date: data[0].date,
			services: data.flatMap((s) => s.services),
		}))
}
