import { Helmet } from "react-helmet-async"
import { SecuritySettings } from "./../../../Settings"

export default function SecuritySettingsPage() {
	return (
		<>
			<Helmet>
				<title>Account Security | Settings</title>
			</Helmet>
			<SecuritySettings />
		</>
	)
}
