import { AsyncSelect } from "@sembark-travel/ui/base"
import { useXHR } from "@sembark-travel/xhr"
import React from "react"

export function SelectAirline(
	props: Omit<React.ComponentProps<typeof AsyncSelect>, "fetch">
) {
	const xhr = useXHR()
	return (
		<AsyncSelect
			{...props}
			cacheKey="airlines"
			fetchOnMount
			creatable
			fetch={(q) =>
				xhr.get("/airlines", { params: { q } }).then((resp) => resp.data.data)
			}
		/>
	)
}
