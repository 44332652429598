import { useState, useRef, useId } from "react"
import {
	Stack,
	Box,
	Inline,
	Table,
	useMergedRefs,
	Text,
	TextArea,
	Tooltip,
	Button,
	Icons,
} from "@sembark-travel/ui/base"
import { useDialog, Dialog } from "@sembark-travel/ui/dialog"
import { Markdown } from "./Markdown"
import {
	BoldToolbarButton,
	HeaderToolbarButton,
	ItalicToolbarButton,
	LinkToolbarButton,
	OrderedListToolbarButton,
	UnorderedListToolbarButton,
} from "./MarkdownToolbar"

export function MarkdownEditor({
	previewStyles,
	...props
}: React.ComponentProps<typeof TextArea> & {
	previewStyles?: React.CSSProperties
}) {
	const [
		isFormattingOptionDialogOpen,
		openFormattingOptionDialog,
		closeFormattingOptionDialog,
	] = useDialog()
	const defaultId = useId()
	const inputId = props.id || defaultId
	const inputRef = useRef<HTMLTextAreaElement>(null)
	const mergedRef = useMergedRefs<HTMLTextAreaElement | null>(null, inputRef)
	const [isMarkdownPreviewVisible, setMarkdownPreviewVisibility] =
		useState<boolean>(false)
	return (
		<Stack>
			<Box borderWidth="1" roundedTop="md" bgColor="subtle" paddingX="4">
				<Inline justifyContent="between">
					<Inline style={{ marginBottom: "-1px" }} paddingTop="1">
						<Box
							as="button"
							borderTopWidth="1"
							borderLeftWidth="1"
							borderRightWidth="1"
							borderColor={
								!isMarkdownPreviewVisible ? "default" : "transparent"
							}
							bgColor={!isMarkdownPreviewVisible ? "default" : "transparent"}
							color={isMarkdownPreviewVisible ? "muted" : undefined}
							onClick={() => {
								setMarkdownPreviewVisibility(false)
								setTimeout(() => {
									inputRef.current?.focus()
								}, 100)
							}}
							paddingX="4"
							paddingY="2"
							roundedTop="md"
							fontWeight="semibold"
							fontSize="sm"
							type="button"
						>
							Write
						</Box>
						<Box
							as="button"
							borderColor={isMarkdownPreviewVisible ? "default" : "transparent"}
							bgColor={isMarkdownPreviewVisible ? "default" : "transparent"}
							color={!isMarkdownPreviewVisible ? "muted" : undefined}
							borderTopWidth="1"
							borderLeftWidth="1"
							borderRightWidth="1"
							roundedTop="md"
							onClick={() => setMarkdownPreviewVisibility(true)}
							paddingX="4"
							paddingY="2"
							fontWeight="semibold"
							fontSize="sm"
							type="button"
						>
							Preview
						</Box>
					</Inline>
					<Inline alignItems="center" justifyContent="center" gap="6">
						{!isMarkdownPreviewVisible ? (
							<EditorToolbar inputRef={inputRef} />
						) : null}
						<Box>
							<Tooltip content="View Formatting Options">
								<Button size="sm" onClick={() => openFormattingOptionDialog()}>
									<Icons.SparklesSolid aria-hidden="true" size="4" />
								</Button>
							</Tooltip>
							<Dialog
								open={isFormattingOptionDialogOpen}
								title="Formatting Options"
								onClose={() => closeFormattingOptionDialog()}
							>
								<Dialog.Body>
									<MarkdownFormattingOptions />
								</Dialog.Body>
								<Dialog.Footer>
									<Button
										level="primary"
										onClick={() => closeFormattingOptionDialog()}
									>
										Ok, Got it.
									</Button>
								</Dialog.Footer>
							</Dialog>
						</Box>
					</Inline>
				</Inline>
			</Box>
			<Box hidden={isMarkdownPreviewVisible}>
				<TextArea
					rows={5}
					{...props}
					id={inputId}
					borderTopWidth="0"
					roundedTop="none"
					ref={mergedRef}
				/>
			</Box>
			{isMarkdownPreviewVisible ? (
				<Box>
					<Box
						paddingX="4"
						paddingY="2"
						borderWidth="1"
						borderTopWidth="0"
						roundedBottom="md"
						bgColor="default"
						style={{ minHeight: "100px", ...previewStyles }}
					>
						{props.value ? (
							<Markdown>{String(props.value)}</Markdown>
						) : (
							<Stack gap="2" paddingY="6">
								<Text fontSize="md" color="muted" textAlign="center">
									--- Nothing to preview ---
								</Text>
							</Stack>
						)}
					</Box>
				</Box>
			) : null}
		</Stack>
	)
}

function EditorToolbar({
	inputRef,
}: {
	inputRef: React.RefObject<HTMLTextAreaElement>
}) {
	return (
		<Inline gap="1">
			<HeaderToolbarButton level={3} textareaRef={inputRef} />
			<BoldToolbarButton textareaRef={inputRef} />
			<ItalicToolbarButton textareaRef={inputRef} />
			<LinkToolbarButton textareaRef={inputRef} />
			<OrderedListToolbarButton textareaRef={inputRef} />
			<UnorderedListToolbarButton textareaRef={inputRef} />
		</Inline>
	)
}

function MarkdownFormattingOptions() {
	return (
		<Table
			responsive
			headers={["Fomatting Syntax", "Output Style"]}
			bordered
			hover
			rows={[
				`### Heading`,
				`**Bold Text**`,
				`*Italic Text*`,
				`- Bullet point\n- Another point\n    - Sub-bullet point`,
				`1. Numeric List\n2. Next Item\n    1. Sub numeric point`,
				`Horizontal Divider Line\n\n---\n\nAfter divider`,
				`[Link Text](https://sembark.com).  \nAlways start with http:// or https://`,
				`Soft Line Break[space][space][enter]  \nLine 2`,
				`Hard Line Break\n\nLine 2`,
			].map((markdownSyntax) => [
				<code>
					<pre>{markdownSyntax}</pre>
				</code>,
				<Markdown>
					{markdownSyntax.replace(/(\[space\])+\[enter\]/g, "")}
				</Markdown>,
			])}
		/>
	)
}
