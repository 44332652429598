import {
	Alert,
	Box,
	Container,
	Stack,
	Inline,
	Heading,
	Spinner,
	Icons,
	Text,
	RelativeTime,
	Grid,
	Col,
} from "@sembark-travel/ui/base"
import { Breadcrumbs, ButtonLink } from "@sembark-travel/ui/router"
import { useXHR } from "@sembark-travel/xhr"
import useSWR from "swr"
import { PERMISSIONS, useCheckPermissions } from "../Auth"
import { generatePath } from "../router-utils"
import { TInclusionExclusionPreset } from "./store"

export function InclusionExclusionPresetItemDetails({
	presetId,
}: {
	presetId: string | number
}) {
	const xhr = useXHR()
	const { hasPermission } = useCheckPermissions()
	const { data, error } = useSWR<TInclusionExclusionPreset>(
		`/inclusion-exclusion-presets/${presetId}`,
		() =>
			xhr
				.get(`/inclusion-exclusion-presets/${presetId}`)
				.then((resp) => resp.data.data)
	)
	if (!data && error) {
		return <Alert status="error">{error.message}</Alert>
	}
	if (!data) {
		return <Spinner padding="4" alignCenter />
	}
	const {
		id,
		name,
		created_at,
		updated_at,
		created_by,
		updated_by,
		deleted_at,
		deleted_by,
		inclusions,
		exclusions,
		default_exclusion_comment,
	} = data
	return (
		<Box key={id}>
			<Breadcrumbs
				title="Details"
				items={[
					[generatePath("/org/inclusion-exclusion-presets"), "Inc/Exclusions"],
					["", name],
				]}
				actions={
					hasPermission(PERMISSIONS.MANAGE_TRIP_OWNERS) && !deleted_at ? (
						<ButtonLink
							to={generatePath("/inclusion-exclusion-presets/:presetId/edit", {
								presetId: id.toString(),
							})}
							level="tertiary"
							size="sm"
						>
							<Icons.Pencil /> Edit
						</ButtonLink>
					) : null
				}
			/>
			<Container paddingY="6" bgColor="default" borderBottomWidth="1">
				<Inline justifyContent="between" gap="6" collapseBelow="md">
					<Stack gap="px">
						<Text color="muted">Name</Text>
						<Heading>{name}</Heading>
					</Stack>
					<Inline gap="8" flexWrap="wrap">
						<Stack gap="px">
							<Text color="muted">Created by</Text>
							<Inline gap="2" alignItems="baseline">
								<Text fontWeight="semibold" fontSize="md">
									{created_by?.name}
								</Text>
								{created_at ? <RelativeTime timestamp={created_at} /> : null}
							</Inline>
						</Stack>
						{updated_by ? (
							<Stack gap="px">
								<Text color="muted">Updated by</Text>
								<Inline gap="2" alignItems="baseline">
									<Text fontWeight="semibold" fontSize="md">
										{updated_by.name}
									</Text>
									{updated_at ? <RelativeTime timestamp={updated_at} /> : null}
								</Inline>
							</Stack>
						) : null}
						{deleted_by ? (
							<Stack gap="px">
								<Text color="muted">Disabled by</Text>
								<Inline gap="2" alignItems="baseline">
									<Text fontWeight="semibold" fontSize="md">
										{deleted_by.name}
									</Text>
									{deleted_at ? <RelativeTime timestamp={deleted_at} /> : null}
								</Inline>
							</Stack>
						) : null}
					</Inline>
				</Inline>
			</Container>
			<Container borderTopWidth="1" paddingY="6">
				<Grid>
					<Col sm={12} md={6}>
						<Stack gap="4">
							<Stack gap="1">
								<Heading fontSize="md" color="success">
									Inclusions
								</Heading>
								<Text fontSize="sm" color="muted">
									List of inclusions in this preset
								</Text>
							</Stack>
							<Stack gap="4" as="ul" marginLeft="4" listStyleType="disc">
								{inclusions?.map((description) => (
									<Text as="li" key={description}>
										{description}
									</Text>
								))}
							</Stack>
						</Stack>
					</Col>
					<Col>
						<Stack gap="4">
							<Stack gap="1">
								<Heading fontSize="md" color="danger">
									Exclusions
								</Heading>
								<Text fontSize="sm" color="muted">
									List of exclusions in this preset
								</Text>
							</Stack>
							<Stack gap="4" as="ul" marginLeft="4" listStyleType="disc">
								{exclusions?.map((description) => (
									<Text as="li" key={description}>
										{description}
									</Text>
								))}
								<Text as="li" color="danger">
									{default_exclusion_comment}
								</Text>
							</Stack>
						</Stack>
					</Col>
				</Grid>
			</Container>
		</Box>
	)
}
