import { Inline, Stack, Icons, Box, Text } from "@sembark-travel/ui/base"
import { TextInputField, useFieldValue } from "@sembark-travel/ui/form"

export function LatLongInputFields({
	latName = "latitude",
	longName = "longitude",
}: {
	latName?: string
	longName?: string
}) {
	const { value: lat } = useFieldValue<string | undefined>(latName)
	const { value: long } = useFieldValue<string | undefined>(longName)
	return (
		<Stack gap="2">
			<Inline gap="4" flexWrap="wrap">
				<TextInputField
					name={latName}
					type="text"
					label="Latitude"
					placeholder="e.g. 11.74008670"
					secondaryLabel="optional"
				/>
				<TextInputField
					name={longName}
					type="text"
					label="Longitude"
					placeholder="e.g. 92.65864010"
					secondaryLabel="optional"
				/>
			</Inline>
			{lat && long ? (
				<Box>
					<MapLink lat={lat} long={long} />
				</Box>
			) : null}
		</Stack>
	)
}

export function MapLink({
	lat,
	long,
	children,
}: {
	lat?: string
	long?: string
	children?: React.ReactNode
}) {
	if (!lat || !long) return null
	return (
		<Text
			as="a"
			fontSize="sm"
			href={`https://www.google.com/maps/search/?api=1&query=${lat},${long}`}
			target="_blank"
			rel="noopener noreferer"
			color="accent"
			textAlign="right"
		>
			{children ? (
				children
			) : (
				<>
					View on Google Maps <Icons.ArrowTopRightOnSquare />
				</>
			)}
		</Text>
	)
}

export function SynonumsNamesInputField() {
	return (
		<Stack gap="1">
			<label>Other Names / Synonyms (optional)</label>
			<TextInputField
				name="synonyms_1"
				type="text"
				size="sm"
				placeholder="Synonyms 1"
			/>
			<TextInputField
				name="synonyms_2"
				type="text"
				size="sm"
				placeholder="Synonyms 2"
			/>
			<TextInputField
				name="synonyms_3"
				type="text"
				size="sm"
				placeholder="Synonyms 3"
			/>
		</Stack>
	)
}
