import { Helmet } from "react-helmet-async"
import { Profile } from "./../../../Settings"

export default function ProfileSettingsPage() {
	return (
		<>
			<Helmet>
				<title>Your Profile | Settings</title>
			</Helmet>
			<Profile />
		</>
	)
}
