import { Breadcrumbs, NavLink } from "@sembark-travel/ui/router"
import { Helmet } from "react-helmet-async"
import {
	TenantDetailsOverview,
	useTenant,
} from "./../../../../../Admin/Tenants"
import { useParams, Navigate, generatePath } from "../../../../../router-utils"
import { Spinner, Tabs, TabsList, TabContent } from "@sembark-travel/ui/base"
import { Outlet } from "react-router-dom"
import { PERMISSIONS, useCheckPermissions } from "../../../../../Auth"

export default function Tenant() {
	const { tenantId } = useParams("/admin/tenants/:tenantId")
	const { tenant, isFetching, refresh } = useTenant(tenantId.toString(), true)
	const { hasPermission } = useCheckPermissions()
	if (isFetching) {
		return <Spinner padding="4" alignCenter />
	}
	if (!tenant) {
		return <Navigate to="/admin/tenants" replace />
	}
	return (
		<>
			<Helmet>
				<title>Tenant Details</title>
			</Helmet>
			<Breadcrumbs
				title="Tenant Details"
				items={[
					[generatePath("/admin/tenants"), "Tenants"],
					["", "Details"],
				]}
			/>
			<TenantDetailsOverview tenant={tenant} refresh={refresh} />
			<Tabs layout="col">
				<TabsList>
					{!tenant.signup_at ? null : (
						<>
							<NavLink
								to={generatePath("/admin/tenants/:tenantId/billing", {
									tenantId,
								})}
							>
								Billing
							</NavLink>
							<NavLink
								to={generatePath("/admin/tenants/:tenantId/modules", {
									tenantId,
								})}
							>
								Modules
							</NavLink>
						</>
					)}
					<NavLink
						to={generatePath("/admin/tenants/:tenantId/configuration", {
							tenantId,
						})}
					>
						Config
					</NavLink>
					<NavLink
						to={generatePath("/admin/tenants/:tenantId/comments", {
							tenantId,
						})}
					>
						Follow-ups
					</NavLink>
					<NavLink
						to={generatePath("/admin/tenants/:tenantId/login-activites", {
							tenantId,
						})}
					>
						Logins
					</NavLink>
					{hasPermission(PERMISSIONS.MANAGE_TENANTS) ? (
						<NavLink
							to={generatePath(
								"/admin/tenants/:tenantId/subscription-details",
								{
									tenantId,
								}
							)}
						>
							Subscriptions
						</NavLink>
					) : null}
				</TabsList>
				<TabContent>
					<Outlet context={{ tenant, refresh }} />
				</TabContent>
			</Tabs>
		</>
	)
}
