import {
	Alert,
	Container,
	Box,
	Button,
	Inline,
	Icons,
	Stack,
	Heading,
	Component,
	Spinner,
	Tabs,
	TabsList,
	TabItem,
} from "@sembark-travel/ui/base"
import { Dialog } from "@sembark-travel/ui/dialog"
import { useXHR } from "@sembark-travel/xhr"
import {
	Form,
	withServerErrors,
	FileInputField,
	SubmissionError,
	validateFormValues,
	checkFileTypes,
	checkFileSize,
} from "@sembark-travel/ui/form"
import * as Validator from "yup"
import { useAuthUser, useHasFeatureFlag } from "../Auth"
import { ITenant, useTenant } from "./store"
import { NavLink } from "@sembark-travel/ui/router"
import { useNavigate } from "react-router-dom"
import { AddTenantBrand } from "../Brands"

export function ProfileSettingsLayout({
	children,
}: {
	children?: React.ReactNode
}) {
	const { user } = useAuthUser()
	const { tenant, isFetching, error, refresh, isValidating } = useTenant(
		user?.tenant?.id
	)
	const hasMultiBranding = useHasFeatureFlag("multi_branding")
	const navigate = useNavigate()
	if (!user || !user.tenant) {
		return null
	}
	if (isFetching) {
		return <Spinner padding="4" alignCenter />
	}
	if (!tenant) {
		if (error) {
			return <Alert status="error">{error.message}</Alert>
		}
		return null
	}
	return (
		<Box>
			{!hasMultiBranding ? (
				<Box borderBottomWidth="1" bgColor="default">
					<Container paddingY="4">
						<Heading as="h3">Organization Profile</Heading>
					</Container>
				</Box>
			) : (
				<Tabs layout="col">
					<TabsList>
						{tenant.brands?.map((brand) => (
							<NavLink to={`${brand.id}`} key={brand.id}>
								{brand.short_name}
							</NavLink>
						))}
						<Component initialState={false}>
							{({ state, setState }) => (
								<>
									{Number(tenant.brands?.length) >= 5 ? null : (
										<TabItem onClick={() => setState(true)}>
											<Icons.Plus /> Add Brand
										</TabItem>
									)}
									<Dialog
										open={state}
										onClose={() => setState(false)}
										title="Add New Brand"
									>
										<Dialog.Body>
											<AddTenantBrand
												onCancel={() => setState(false)}
												onSuccess={async (brand) => {
													setState(false)
													await refresh()
													navigate(brand.id.toString())
												}}
											/>
										</Dialog.Body>
									</Dialog>
								</>
							)}
						</Component>
					</TabsList>
				</Tabs>
			)}
			<Container paddingY="6" cursor={isValidating ? "wait" : undefined}>
				{children}
			</Container>
		</Box>
	)
}

export function UploadTenantLogo({
	onSuccess,
	cancelLabel = "Cancel",
	onCancel,
}: {
	onSuccess: (tenant: ITenant) => void
	cancelLabel?: string
	onCancel?: () => void
}) {
	const { user } = useAuthUser()
	const xhr = useXHR()
	if (!user || !user.tenant) return null
	const { id, short_name, name, profile_image_url } = user.tenant
	return (
		<Stack gap="4">
			<Box>
				{profile_image_url ? (
					<Box>
						<img
							src={profile_image_url}
							style={{ width: "auto", maxWidth: "100%", height: "150px" }}
							alt={short_name || name}
						/>
					</Box>
				) : (
					<Box
						style={{ width: "200px", height: "200px" }}
						display="flex"
						justifyContent="center"
						alignItems="center"
						bgColor="inset"
						as="label"
						htmlFor="file"
					>
						<Icons.Photograph />
					</Box>
				)}
			</Box>
			<Box>
				<Component initialState={!profile_image_url}>
					{({ state, setState }) =>
						!state ? (
							<Button onClick={() => setState(true)}>Update Logo</Button>
						) : (
							<Form<{ file?: FileList }>
								initialValues={{ file: undefined }}
								validate={validateFormValues(
									Validator.object().shape({
										file: Validator.mixed()
											.required("Please select a file")
											.test(
												"type-is-correct",
												"Please select an png/jpeg image file",
												checkFileTypes(["image/png", "image/jpeg"])
											)
											.test(
												"size-not-big",
												"Please select a file that is less then 500 KB in size",
												checkFileSize(500)
											),
									})
								)}
								onSubmit={withServerErrors(async (values) => {
									const data = new FormData()
									if (values.file) {
										data.append("file", values.file as never)
									}
									const tenantData = await xhr.post(
										`/tenants/${id}/profile-image`,
										data
									)
									onSuccess(tenantData.data.data)
									setState(false)
								})}
								subscription={{ submitting: true }}
							>
								{({ submitting, handleSubmit }) => (
									<form noValidate onSubmit={handleSubmit}>
										<Stack gap="4">
											<FileInputField
												label="Select an image"
												name="file"
												accept="image/jpeg,image/png"
												help="Image must be 400x400 JPEG/PNG file with less than 500 KB in size."
											/>
											<SubmissionError />
											<Inline gap="4">
												<Button type="submit" disabled={submitting}>
													{submitting ? "Uploading..." : "Upload"}
												</Button>
												<Button
													disabled={submitting}
													onClick={() => {
														setState(false)
														onCancel?.()
													}}
												>
													{cancelLabel}
												</Button>
											</Inline>
										</Stack>
									</form>
								)}
							</Form>
						)
					}
				</Component>
			</Box>
		</Stack>
	)
}
