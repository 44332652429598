import { Button, Inline, Text, Divider, Stack } from "@sembark-travel/ui/base"
import { useXHR } from "@sembark-travel/xhr"
import { SelectRoles } from "./../Roles"
import {
	Form,
	withServerErrors,
	SubmissionError,
	SelectField,
	validateFormValues,
} from "@sembark-travel/ui/form"
import * as Validator from "yup"
import { IUser, XHR } from "./store"

interface EditRolesProps {
	user: IUser
	onSuccess: () => void
	onCancel?: () => void
}

export function EditUserRolesForm({
	user,
	onSuccess,
	onCancel,
}: EditRolesProps) {
	const xhr = useXHR()
	const { name, roles = [] } = user
	const initialValues = {
		roles: roles,
	}
	return (
		<Form<typeof initialValues>
			initialValues={initialValues}
			validate={validateFormValues(
				Validator.object().shape({
					roles: Validator.array().min(
						1,
						"Please select atleast one role for the member"
					),
				})
			)}
			onSubmit={withServerErrors(async (values) => {
				await XHR(xhr).updateRoles(user.id, {
					roles: values.roles.map(({ internal_name }) => ({
						name: internal_name,
					})),
				})
				onSuccess()
			})}
			subscription={{ submitting: true }}
		>
			{({ submitting, handleSubmit }) => (
				<form noValidate onSubmit={handleSubmit}>
					<Stack gap="4">
						<Text fontSize="md">
							Please select roles for <b>{name}</b>
						</Text>
						<SelectField
							select={SelectRoles}
							name="roles"
							fetchOnMount
							multiple
							label="Select Role(s)"
						/>
						<Divider sm />
						<SubmissionError />
						<Inline gap="4">
							<Button type="submit" disabled={submitting}>
								{submitting ? "Updating..." : "Assign Role"}
							</Button>
							{onCancel ? (
								<Button
									onClick={onCancel}
									level="tertiary"
									disabled={submitting}
								>
									Cancel
								</Button>
							) : null}
						</Inline>
					</Stack>
				</form>
			)}
		</Form>
	)
}
