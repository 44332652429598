import {
	RelativeTime,
	Spinner,
	Table,
	Stack,
	Box,
	Button,
	Inline,
	Text,
	joinAttributes,
	Ping,
} from "@sembark-travel/ui/base"
import { Dialog, useDialog } from "@sembark-travel/ui/dialog"
import {
	Form,
	SubmissionError,
	TextInputField,
	validateFormValues,
	withServerErrors,
} from "@sembark-travel/ui/form"
import { showSnackbar } from "@sembark-travel/ui/snackbar"
import { useXHR } from "@sembark-travel/xhr"
import useSWR from "swr"
import * as Validator from "yup"

type TAuthSession = {
	agent: {
		platform?: string
		browser?: string
		browser_version?: string
		is_desktop?: boolean
	}
	ip_address: string
	is_current_device: boolean
	last_activity_at: string
}

export function AuthSessionsList() {
	const xhr = useXHR()
	const { data, mutate } = useSWR(
		`browser-sessions`,
		() =>
			xhr
				.get<{ data: Array<TAuthSession> }>("/other-browser-sessions")
				.then((resp) => resp.data.data),
		{ revalidateOnFocus: true }
	)
	if (!data) return <Spinner padding="4" alignCenter />
	return (
		<Stack gap="4">
			<Table
				headers={["", "Platform", "Browser", "IP", "Last Activity"]}
				bordered
				responsive
				rows={data.map(({ agent, ...session }) => [
					session.is_current_device ? <Ping title="This Device" /> : null,
					joinAttributes(
						agent.platform || "Unknown",
						agent.is_desktop ? "Desktop" : "Mobile"
					),
					joinAttributes(agent.browser || "Unknown", agent.browser_version),
					session.ip_address,
					<RelativeTime timestamp={session.last_activity_at} />,
				])}
			/>
			{data.length > 1 ? (
				<Box textAlign="right">
					<LogoutFromOtherSessions onSuccess={() => mutate()} />
				</Box>
			) : null}
		</Stack>
	)
}

export function LogoutFromOtherSessions({
	onSuccess,
}: {
	onSuccess: () => void
}) {
	const xhr = useXHR()
	const [isOpen, open, close] = useDialog()
	return (
		<>
			<Button onClick={open}>Logout from Other Sessions</Button>
			<Dialog
				open={isOpen}
				onClose={close}
				title="Logout from Other Sessions"
				sm
			>
				<Form<{ password: string }>
					initialValues={{ password: "" }}
					validate={validateFormValues(
						Validator.object().shape({
							password: Validator.string().required(
								"Please provide your current password"
							),
						})
					)}
					onSubmit={withServerErrors(async (values) => {
						await xhr.delete("/other-browser-sessions", {
							data: values,
						})
						onSuccess()
						showSnackbar("Successfully logged out from other browser sessions")
						close()
					})}
				>
					{({ submitting, handleSubmit }) => (
						<form onSubmit={handleSubmit} noValidate>
							<Dialog.Body>
								<Stack gap="4">
									<Text>
										Please enter your password to confirm you would like to log
										out of your other browser sessions across all of your
										devices.
									</Text>
									<TextInputField
										name="password"
										label="Current Password"
										type="password"
										autoComplete="off"
									/>
								</Stack>
							</Dialog.Body>
							<Dialog.Footer>
								<Stack gap="4">
									<SubmissionError />
									<Inline gap="4">
										<Button type="submit" disabled={submitting}>
											{submitting
												? "Please wait..."
												: "Logout from Other Sessions"}
										</Button>
										<Button onClick={close}>Cancel</Button>
									</Inline>
								</Stack>
							</Dialog.Footer>
						</form>
					)}
				</Form>
			</Dialog>
		</>
	)
}
