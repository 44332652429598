import { Box, Button, Icons } from "@sembark-travel/ui/base"
import { Spinner } from "@sembark-travel/ui/base"
import { useXHR } from "@sembark-travel/xhr"
import React, { useCallback, useEffect, useRef } from "react"
import useSWR from "swr"
import config from "../config"

function DefaultGenerateVoucherRender({
	onGenerate,
}: {
	onGenerate: () => void
}) {
	return <Button onClick={onGenerate}>Regenerate Voucher</Button>
}

export function GenerateVoucherAssets({
	voucherId,
	onChange,
	children = DefaultGenerateVoucherRender,
}: {
	voucherId: number
	onChange: () => void
	children?: (
		props: React.ComponentProps<typeof DefaultGenerateVoucherRender>
	) => React.ReactNode
}) {
	const xhr = useXHR()
	const generate = useCallback(() => {
		xhr
			.post("/generate-hotel-vouchers", {
				items: [voucherId],
				timezone_offset: config.timezoneOffset,
			})
			.then(() => {
				onChange()
			})
	}, [voucherId, xhr, onChange])
	return <>{children({ onGenerate: generate })}</>
}

export function GeneratedVoucherAsset({
	id,
	isGenerating,
	fileUrl,
	onChange,
	size,
	...props
}: {
	id: number
	isGenerating?: boolean
	fileUrl?: string
	onChange: () => void
} & Omit<React.ComponentProps<typeof Box>, "id" | "onChage">) {
	const xhr = useXHR()
	const { data } = useSWR<{
		done: boolean
		in_progress: boolean
		failed: boolean
	}>(
		isGenerating ? `/hotel-voucher-generation-status/${id}` : null,
		(key) => xhr.get(key).then((resp) => resp.data.data),
		{
			refreshInterval: isGenerating ? 2000 : 0,
		}
	)
	// cache the onchange
	const onChangeRef = useRef(onChange)
	onChangeRef.current = onChange
	useEffect(() => {
		if (data) {
			if (data.in_progress === false && isGenerating) {
				onChangeRef.current()
			}
		}
	}, [data, isGenerating])
	return isGenerating ? (
		<Button inline disabled title="Generating Voucher" {...props}>
			<Spinner />
		</Button>
	) : fileUrl ? (
		<Button<"a">
			as="a"
			inline
			href={fileUrl}
			title="Download Voucher"
			target="_blank"
			rel="noopener noreferrer"
			{...props}
		>
			<Icons.DocumentDownload />
		</Button>
	) : (
		<GenerateVoucherAssets onChange={onChange} voucherId={id}>
			{({ onGenerate }) => (
				<Button inline type="button" onClick={onGenerate} {...props}>
					<Icons.Refresh title="File not generated" />
				</Button>
			)}
		</GenerateVoucherAssets>
	)
}
