import { useParams } from "../../../../router-utils"
import { HotelGroupDetails } from "./../../../../HotelGroups"
import { Outlet } from "react-router-dom"

export default function HotelGroupDetailsPage() {
	const { groupId } = useParams("/hotel-groups/:groupId")
	return (
		<HotelGroupDetails groupId={groupId}>
			{({ group }) => <Outlet context={{ group }} />}
		</HotelGroupDetails>
	)
}
