import {
	Table,
	AsyncSelect,
	AsyncSelectProps,
	Select,
} from "@sembark-travel/ui/base"
import { Link } from "react-router-dom"
import { ListView, Search, TSearchParams } from "@sembark-travel/ui/list"
import { IListResponse, useXHR, XHRInstance } from "@sembark-travel/xhr"
import React, { Fragment, useCallback, useState } from "react"
import { Helmet } from "react-helmet-async"
import { Omit } from "utility-types"
import { AddCabInDialog } from "./New"
import { ICab } from "./store"

function XHR(xhr: XHRInstance) {
	return {
		async getCabs(params?: unknown): Promise<IListResponse<ICab>> {
			return xhr.get("/cabs", { params }).then((resp) => resp.data)
		},
	}
}

export function CabsList<TParams extends TSearchParams = TSearchParams>({
	params,
	setParams,
	actions,
}: {
	params: TParams
	setParams: (params: TParams) => void
	actions?: React.ReactNode
}) {
	return (
		<Fragment>
			<Helmet>
				<title>Cabs</title>
			</Helmet>
			<Search
				title="Cabs"
				initialParams={params}
				onSearch={(params) => {
					setParams({ ...params, page: 1 })
				}}
				actions={actions}
			/>
			<ListView<ICab, TParams>
				pageKey="/cabs-listing"
				params={params}
				fetch={(xhr, params) => XHR(xhr).getCabs(params)}
				onPageChange={(page) => setParams({ ...params, page })}
			>
				{({ items: cabs }) => (
					<Table
						headers={["Name", "Number Plate"]}
						striped
						bordered
						rows={cabs.map((r) => [
							<Link to={r.id.toString()}>{r.name}</Link>,
							r.number_plate,
						])}
					/>
				)}
			</ListView>
		</Fragment>
	)
}

export function SelectCabs({
	options,
	...otherProps
}: Omit<AsyncSelectProps, "fetch">) {
	const xhr = useXHR()
	const [newName, setNewName] = useState("")
	const handleDialogClose = useCallback(() => {
		setNewName("")
	}, [setNewName])
	return (
		<>
			{options ? (
				<Select
					onCreateNew={(query: string) => {
						setNewName(query)
					}}
					{...otherProps}
					options={options}
					labelKey="full_name"
				/>
			) : (
				<AsyncSelect
					onCreateNew={(query: string) => {
						setNewName(query)
					}}
					{...otherProps}
					labelKey="full_name"
					fetch={(q) =>
						XHR(xhr)
							.getCabs({ q })
							.then((resp) => resp.data)
					}
				/>
			)}
			<AddCabInDialog
				open={!!newName}
				onCancel={handleDialogClose}
				numberPlate={newName}
				onSuccess={(cab) => {
					handleDialogClose()
					otherProps.onChange?.(cab, otherProps.name || "field_name")
				}}
			/>
		</>
	)
}
