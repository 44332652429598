import React, { useMemo } from "react"
import {
	AttentionIcon,
	AttentionSolidIcon,
	OkCircleSolidIcon,
	InfoIcon,
} from "./Icons"
import { Box, BoxOwnProps } from "./Box"
import { Heading } from "./Text"
import { Stack } from "./Stack"
import { Inline } from "./Inline"

type TStatus = "info" | "error" | "success" | "warning"

type TType = "assertive" | "polite"

export type AlertProps = {
	status?: TStatus
	children?: React.ReactNode
	hideIcon?: boolean
	type?: TType
	title?: string
	inline?: boolean
	rounded?: React.ComponentProps<typeof Inline>["rounded"]
}

export function Alert({
	status: statusProp,
	type: typeProp,
	children,
	hideIcon,
	title,
	inline,
	rounded = "md",
}: AlertProps) {
	const type = typeProp || getTypeBasedOnStatus(statusProp)
	const status = statusProp || getStatusBasedOnType(type)
	const Icon = getIconBasedOnStatus(status)
	const stylesForStatus = useMemo(() => getStylesForStatus(status), [status])
	if (!children && !title) return null
	return (
		<Inline
			alignItems="start"
			padding={inline ? "1" : "4"}
			gap={inline ? "1" : "3"}
			borderWidth="1"
			rounded={rounded}
			{...stylesForStatus}
			role={type === "assertive" ? "alert" : "status"}
			aria-live={type}
		>
			{!hideIcon ? (
				<Box>
					<Icon
						size={inline ? "4" : "6"}
						style={inline ? undefined : { transform: "translateY(2px)" }}
					/>
				</Box>
			) : null}
			<Stack flex="1" gap="2">
				{title ? (
					<Heading as="h4" fontSize="base" fontWeight="semibold">
						{title}
					</Heading>
				) : null}
				<Box>{children}</Box>
			</Stack>
		</Inline>
	)
}

function getTypeBasedOnStatus(status?: TStatus): TType {
	if (status === "error") {
		return "assertive"
	}
	return "polite"
}

function getStatusBasedOnType(type?: TType): TStatus {
	if (type === "assertive") {
		return "error"
	}
	return "info"
}

function getIconBasedOnStatus(status: TStatus) {
	let Icon
	switch (status) {
		case "warning":
			Icon = AttentionIcon
			break
		case "error":
			Icon = AttentionSolidIcon
			break
		case "success":
			Icon = OkCircleSolidIcon
			break
		default:
			Icon = InfoIcon
			break
	}
	return Icon
}

function getStylesForStatus(status: TStatus) {
	switch (status) {
		case "error":
			return getStylesForColor("danger")
		case "success":
			return getStylesForColor("success")
		case "warning":
			return getStylesForColor("warning")
		default:
			return getStylesForColor("primary")
	}
}

function getStylesForColor(
	color: "danger" | "success" | "warning" | "primary"
): Pick<BoxOwnProps, "borderColor" | "bgColor" | "color"> {
	return {
		borderColor: color,
		bgColor: color,
		color: color,
	}
}
