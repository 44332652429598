import { Box, Icons, Stack, Text } from "@sembark-travel/ui/base"
import { Email, PhoneNumber } from "./../Contacts"
import { IAddress } from "./store"

export function AddressText({ address }: { address: IAddress }) {
	const { text } = address
	return (
		<Box as="address" whiteSpace="preserveLine">
			{text}
		</Box>
	)
}

export function AddressItem({ address }: { address: IAddress }) {
	return (
		<Stack gap="2">
			<AddressLocation address={address} />
			<AddressContactDetails address={address} />
			<AddressBillingDetails address={address} />
		</Stack>
	)
}

export function AddressLocation({ address }: { address: IAddress }) {
	const location = address.location
	const city_state_names = [location.city, location.state]
		.map((d) => d?.name)
		.filter((x): x is string => Boolean(x))
	const city_state_line = city_state_names.join(", ")
	let line_1 = address.line_1?.replace(city_state_line, "").trim() || ""
	let line_2 = address.line_2?.replace(city_state_line, "").trim() || ""
	let landmark = address.landmark?.replace(city_state_line, "").trim() || ""
	if (city_state_names.indexOf(line_1) !== -1) {
		line_1 = ""
	}
	if (city_state_names.indexOf(line_2) !== -1) {
		line_2 = ""
	}
	if (city_state_names.indexOf(landmark) !== -1) {
		landmark = ""
	}
	return (
		<Box as="address">
			{line_1 || line_2 || landmark ? (
				<Text>{[line_1, line_2, landmark].filter(Boolean).join(", ")}</Text>
			) : null}
			{city_state_line ? <Text>{city_state_line}</Text> : location.name}
			<Text>
				{[address.location?.country?.name, address.postcode]
					.filter(Boolean)
					.join(", ")}
			</Text>
		</Box>
	)
}

export function AddressBillingDetails({
	address,
	hideName,
}: {
	address: IAddress
	hideName?: boolean
}) {
	const { addressing_name, billing_details } = address
	return addressing_name || billing_details ? (
		<Stack gap="px">
			<Text fontSize="sm" color="muted" fontWeight="semibold">
				<Icons.BankNotes opacity="70" /> Billing Details
			</Text>
			{hideName ? null : <Text>{addressing_name}</Text>}
			<Text fontSize="sm" whiteSpace="preserveLine">
				{billing_details}
			</Text>
		</Stack>
	) : null
}

export function AddressContactDetails({ address }: { address: IAddress }) {
	const { phone_numbers, email } = address
	return phone_numbers?.length || email ? (
		<Stack gap="1">
			{phone_numbers?.length ? (
				<Box>
					<PhoneNumber value={phone_numbers} />
				</Box>
			) : null}
			{email ? (
				<Box>
					<Email value={email} />
				</Box>
			) : null}
		</Stack>
	) : null
}
