import { Box } from "@sembark-travel/ui/base"
import { Divider } from "@sembark-travel/ui/base"
import { Helmet } from "react-helmet-async"
import { LogSuspenseTransaction } from "./../../../../Accounting"
import { useNavigate } from "../../../../router-utils"

export default function LogNewTransactionPage() {
	const navigate = useNavigate()
	return (
		<>
			<Helmet>
				<title>Log New Suspense Transaction</title>
			</Helmet>
			<Box marginBottom="16">
				<Box marginBottom="8">
					<Box as="h2">Log Suspense Transaction(s)</Box>
					<Divider />
				</Box>
				<LogSuspenseTransaction
					onSuccess={() => {
						navigate("/accounting/suspense-transactions")
					}}
					onCancel={() => {
						navigate("/accounting/suspense-transactions")
					}}
				/>
			</Box>
		</>
	)
}
