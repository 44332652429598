import { ITrip } from "./store"
import { TripOperationalBookingsItem } from "../TripOperationalBookings"

type TOperationalBookingProps = {
	trip: ITrip
}

export default function OperationalBookings(props: TOperationalBookingProps) {
	const { trip } = props
	return <TripOperationalBookingsItem tripId={trip.id} />
}
