import { XHRInstance, IListResponse } from "@sembark-travel/xhr"
import { IUser } from "../Auth"
import { TTripDestination } from "../TripDestinations"

export type TTeam = {
	id: number
	name: string
	users?: Array<IUser>
	managers?: Array<IUser>
	users_count?: number
	trip_destinations?: Array<TTripDestination>
	created_at: string
}

// actions
export function XHR(xhr: XHRInstance) {
	return {
		async store(data: unknown): Promise<{
			data: TTeam
		}> {
			return xhr.post("/teams", data).then((resp) => resp.data)
		},
		async get(params: unknown): Promise<IListResponse<TTeam>> {
			return xhr.get("/teams", { params }).then((resp) => resp.data)
		},
		async show(teamId: string | number): Promise<TTeam> {
			return xhr.get(`/teams/${teamId}`).then((resp) => resp.data.data)
		},
		async update(
			teamId: string | number,
			data: unknown
		): Promise<{
			data: TTeam
		}> {
			return xhr.patch(`/teams/${teamId}`, data).then((resp) => resp.data)
		},
		async destroy(teamId: string | number) {
			return xhr
				.delete<{ message: string }>(`/teams/${teamId}`)
				.then((resp) => resp.data)
		},
	}
}
