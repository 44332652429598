import { Alert, Box, Button, Inline } from "@sembark-travel/ui/base"
import { dateToUTCString, addUnit, endOf } from "@sembark-travel/datetime-utils"
import {
	Col,
	Divider,
	Stack,
	Heading,
	Text,
	Grid,
} from "@sembark-travel/ui/base"
import { useXHR } from "@sembark-travel/xhr"
import * as Validator from "yup"
import { ITenant } from "./store"
import { useRef } from "react"
import {
	Form,
	validateFormValues,
	SubmissionError,
	TextInputField,
	SelectInputField,
	DatePickerField,
	withServerErrors,
	GetFieldValue,
	SwitchInputField,
	SelectField,
} from "@sembark-travel/ui/form"
import { useAllAvailableCurrencies } from "../../Currencies"
import { SelectWorldTimezones } from "../../World/List"
import { SelectUsers, IUser } from "../../Users"

export interface NewTenantCredentials {
	name: string
	description?: string
	address_name: string
	address_email: string
	send_invite: boolean
	tenant_signup_link: string
	trial_ends_at?: Date
	functional_currency: string
	timezone?: { id: string; name: string }
	supported_by_users?: Array<IUser>
}
const validate = validateFormValues(
	Validator.object().shape({
		name: Validator.string()
			.required("Name for the tenant is required.")
			.max(299, "Maximum 299 characters allowed"),
		description: Validator.string().max(299, "Maximum 299 characters allowed"),
		address_name: Validator.string().required("Addressing name is required"),
		address_email: Validator.string()
			.email("Invalid email address")
			.required("Addressing email is required"),
		send_invite: Validator.boolean(),
		trial_ends_at: Validator.date().required(
			"Please select a trial ending date"
		),
		functional_currency: Validator.string().required(
			"Please select a functional currency of this business"
		),
		timezone: Validator.mixed().required("Please select a timezone"),
	})
)

export function NewTenantForm({
	onSuccess,
	onCancel,
	signupUrl,
}: {
	onSuccess: (tenant: ITenant) => void
	onCancel?: () => void
	signupUrl: string
}) {
	const initialValues: NewTenantCredentials = useRef({
		name: "",
		description: "",
		address_name: "",
		address_email: "",
		send_invite: true,
		trial_ends_at: addUnit(new Date(), 15, "days"),
		functional_currency: "INR",
		timezone: undefined,
		tenant_signup_link: signupUrl,
		supported_by_users: [],
	}).current
	const xhr = useXHR()
	const { data: allAvailableCurrencies } = useAllAvailableCurrencies()
	return (
		<Form<NewTenantCredentials>
			initialValues={initialValues}
			validate={validate}
			onSubmit={withServerErrors(async (values) => {
				const { data } = await xhr.post("/tenants", {
					...values,
					send_invite: Number(values.send_invite || false),
					trial_ends_at: values.trial_ends_at
						? dateToUTCString(endOf(values.trial_ends_at, "day"))
						: null,
					timezone: values.timezone?.id,
					supported_by_users: (values.supported_by_users || []).map(
						(s) => s.uid
					),
				})
				const tenant = data.data
				onSuccess(tenant)
			})}
			subscription={{ submitting: true }}
		>
			{({ submitting, handleSubmit }) => (
				<form noValidate onSubmit={handleSubmit}>
					<Grid gap="4">
						<Col sm={12} md={4}>
							<Stack gap="1">
								<Heading as="h3">Company Details</Heading>
								<Text color="muted">
									Please provide minimal company details.
								</Text>
							</Stack>
						</Col>
						<Col>
							<Stack gap="4">
								<TextInputField
									label="Name"
									name="name"
									type="text"
									required
									placeholder="Raj Tourism Holidays"
								/>
								<Inline gap="4" width="full">
									<SelectInputField
										name="functional_currency"
										label="Business/Functional Currency"
										style={{ maxWidth: "100px" }}
									>
										<option value="">---</option>
										{allAvailableCurrencies?.map((c) => (
											<option key={c.id} value={c.iso}>
												{c.name}
											</option>
										))}
									</SelectInputField>
									<Box flex="1">
										<SelectField
											name="timezone"
											select={SelectWorldTimezones}
											label="Timezone"
											fetchOnMount
										/>
									</Box>
								</Inline>
								<Alert status="warning">
									<Text>
										The <b>Currency</b> and <b>Timezone</b> can not be edited
										after invitation. Please check with the business to ensure
										correctness.
									</Text>
								</Alert>
							</Stack>
						</Col>
					</Grid>
					<Divider />
					<Grid gap="4">
						<Col sm={12} md={4}>
							<Stack gap="1">
								<Heading as="h3">Contact/Admin Info</Heading>
								<Text color="muted">
									Please provide contact details the new tenant. Invitation will
									be sent to these details.
								</Text>
							</Stack>
						</Col>
						<Col>
							<Grid gap="4">
								<Col>
									<TextInputField
										label="Addressing Name"
										name="address_name"
										type="text"
										placeholder="Admin Name"
										required
									/>
								</Col>
								<Col>
									<TextInputField
										label="Addressing Email"
										name="address_email"
										type="email"
										placeholder="admin@domain.com"
										required
									/>
								</Col>
							</Grid>
						</Col>
					</Grid>
					<Divider />
					<Grid gap="4">
						<Col sm={12} md={4}>
							<Stack gap="1">
								<Heading as="h3">Invitation Settings</Heading>
								<Text color="muted">
									Configure settings like trial ending date, invitation email
									etc.
								</Text>
							</Stack>
						</Col>
						<Col>
							<Stack gap="4">
								<DatePickerField
									label="Trail Ends On"
									name="trial_ends_at"
									required
								/>
								<SwitchInputField
									label="Send Invitation email also"
									name="send_invite"
								/>
							</Stack>
						</Col>
					</Grid>
					<Divider />
					<Grid gap="4">
						<Col sm={12} md={4}>
							<Stack gap="1">
								<Heading as="h3">Application Support</Heading>
								<Text color="muted">
									Select the members which will be providing the onboarding and
									application support to this tenant.
								</Text>
							</Stack>
						</Col>
						<Col>
							<SelectField
								select={SelectUsers}
								name="supported_by_users"
								label="Application Support Members"
								multiple
								fetchOnMount
								secondaryLabel="optional"
							/>
						</Col>
					</Grid>
					<Divider />
					<Grid gap="4">
						<Col sm={12} md={{ offset: 4, span: 8 }}>
							<Stack gap="4" paddingLeft={{ md: "4" }}>
								<SubmissionError />
								<Inline gap="4">
									<GetFieldValue<boolean> name="send_invite">
										{({ value: sendInvite }) => (
											<Button type="submit" disabled={submitting}>
												{submitting ? (
													"Please wait..."
												) : (
													<>Save{sendInvite ? " and Invite" : ""} Tenant</>
												)}
											</Button>
										)}
									</GetFieldValue>
									{onCancel ? (
										<Button onClick={onCancel} level="tertiary">
											Cancel
										</Button>
									) : null}
								</Inline>
							</Stack>
						</Col>
					</Grid>
				</form>
			)}
		</Form>
	)
}
