import { Helmet } from "react-helmet-async"
import { InclusionExclusionPresetsList } from "../../../../InclusionExclusionPresets"

export default function OrganizationInclusionExclusionPresetsPage() {
	return (
		<>
			<Helmet>
				<title>Inc-Exclusion Presets</title>
			</Helmet>
			<InclusionExclusionPresetsList />
		</>
	)
}
