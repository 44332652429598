import {
	Alert,
	Box,
	Button,
	Inline,
	Table,
	Container,
	Divider,
	Stack,
	Heading,
	Text,
	Icons,
} from "@sembark-travel/ui/base"
import { Link } from "@sembark-travel/ui/router"
import { useXHR } from "@sembark-travel/xhr"
import {
	Form,
	FileInputField,
	withServerErrors,
	SubmissionError,
	validateFormValues,
} from "@sembark-travel/ui/form"
import * as Validator from "yup"
import config from "../config"
import { generatePath } from "../router-utils"
import {
	CopyToClipboard,
	CopyToClipboardButton,
} from "@sembark-travel/ui/copy-to-clipboard"

const initialValues: { file: File | null; timezone_offset: number } = {
	file: null,
	timezone_offset: config.timezoneOffset,
}

const validationSchema = validateFormValues(
	Validator.object().shape({
		timezone_offset: Validator.string().required(),
		file: Validator.mixed().required("File field is required"),
	})
)

export function UploadHotels({
	onSuccess,
	onCancel,
}: {
	onSuccess: () => void
	onCancel?: () => void
}) {
	const xhr = useXHR()
	return (
		<>
			<Form<typeof initialValues>
				initialValues={initialValues}
				validate={validationSchema}
				onSubmit={withServerErrors(async (values) => {
					if (!values.file) {
						throw Error("Please select a file to upload")
					}
					const data = new FormData()
					data.set("file", values.file)
					data.set("timezone_offset", values.timezone_offset.toString())
					await xhr.post("/upload-hotels", data, {
						headers: {
							"Content-Type": "multipart/form-data",
						},
					})
					onSuccess()
				})}
				subscription={{ submitting: true }}
			>
				{({ submitting, handleSubmit }) => (
					<form
						noValidate
						encType="multipart/form-data"
						onSubmit={handleSubmit}
					>
						<Container bgColor="default" paddingY="6">
							<Stack gap="4">
								<Box as="footer">
									<Alert
										status="warning"
										title="Want to upload hotels with prices ?"
									>
										<Box>
											If you have prices in your CSV file, you probably wants to{" "}
											<Link
												to={generatePath("/hotel-prices/upload-prices")}
												color="accent"
												textDecoration="underline"
											>
												upload hotel prices
											</Link>{" "}
											which also uploads hotels.
										</Box>
									</Alert>
								</Box>
								<FileInputField
									label="Select a csv file"
									name="file"
									accept=".csv"
								/>
								<Divider sm />
								<SubmissionError />
								<Inline gap="4" flexWrap="wrap">
									<Button type="submit" disabled={submitting}>
										Upload Hotels CSV
									</Button>
									{onCancel ? (
										<Button onClick={onCancel} level="tertiary">
											Cancel
										</Button>
									) : null}
								</Inline>
							</Stack>
						</Container>
					</form>
				)}
			</Form>
			<Container paddingY="6">
				<CopyToClipboard<HTMLDivElement>>
					{({ copy, nodeToCopy }) => (
						<Stack gap="4">
							<Heading as="h4">CSV File Format</Heading>
							<Text>
								When creating your CSV file for hotels, please follow this
								format only. You can find more information about each column by
								hovering over the words with{" "}
								<b>
									<abbr title="You will get information like this. Please read each information carefully for best and accurate results.">
										dotted underlines
									</abbr>
								</b>
								. If you are unsure about what/why a column is, please feel free
								to contact our support team.
							</Text>
							<Box ref={nodeToCopy}>
								<Table
									aria-label="CSV file format for hotels"
									responsive
									className="excel-style-table"
								>
									<Box as="thead" whiteSpace="preserve">
										<tr>
											<th>
												<abbr title="The name of group of hotels. Use 'NA' if a hotel has no group.">
													Group Name
												</abbr>
											</th>
											<th>
												<abbr title="Location of the hotel. Please provide in `District, State, Country` for better searching and mapping.">
													Location
												</abbr>
											</th>
											<th>
												<abbr title="The name of the hotel. Please avoid suffixing/prefixing with `Hotel` keyword if possible.">
													Name
												</abbr>
											</th>
											<th>
												<abbr title="Star rating of the hotel">Star</abbr>{" "}
											</th>
											<th>
												<abbr title="Street / Area details of Hotel Adddress">
													Address Line 1
												</abbr>
											</th>
											<th>
												<abbr title="Nearby Landmark of Hotel (optional)">
													Landmark
												</abbr>
											</th>
											<th>
												<abbr title="Area Pin Code">Pin Code</abbr>
											</th>
											<th>
												<abbr title="Contact Number of Hotel without Country Code">
													Contact Number
												</abbr>
											</th>
											<th>
												<abbr title="[Optional] Secondary Contact Number of Hotel">
													Contact Number 2
												</abbr>
											</th>
											<th>
												<abbr title="Email Id of Hotel">Email Id</abbr>
											</th>
											<th>
												<abbr title="Check-In Time (24Hrs format) for Hotel">
													Checkin Time
												</abbr>
											</th>
											<th>
												<abbr title="Check-Out Time (24Hrs format) for Hotel">
													Checkout Time
												</abbr>
											</th>
											<th>
												<abbr title="Hotel's Photos / More Details link">
													Url
												</abbr>
											</th>
										</tr>
									</Box>
									<tbody>
										<tr>
											<td>The MRS Group</td>
											<td>Jaipur, Rajasthan, India</td>
											<td>Nahargarh Haveli</td>
											<td>3</td>
											<td>B - 4, Ajmer Rd, Gopalbari</td>
											<td>Behind Corporate Park</td>
											<td>302001</td>
											<td>9810997861</td>
											<td>9998889988</td>
											<td>support@sembark.com</td>
											<td>14:00</td>
											<td>11:00</td>
											<td>https://hotelxyz.com</td>
										</tr>
										<tr>
											<td>
												<abbr title="Leave empty means: Use the same group as mentioned above">
													&nbsp;&nbsp;&nbsp;&nbsp;
												</abbr>
											</td>
											<td>
												<abbr title="Leave empty means: Use the same location as mentioned above">
													&nbsp;&nbsp;&nbsp;&nbsp;
												</abbr>
											</td>
											<td>Hilton Jaipur</td>
											<td>3</td>
											<td>B - 4, Ajmer Rd, Gopalbari</td>
											<td>Behind Corporate Park</td>
											<td>302001</td>
											<td>9810997861</td>
											<td></td>
											<td>support@sembark.com</td>
											<td>14:00</td>
											<td>11:00</td>
											<td></td>
										</tr>
										<tr>
											<td>Sky Stays</td>
											<td>Ahmedabad, Gujarat, India</td>
											<td>Royal Square</td>
											<td>4</td>
											<td>
												4th Floor, Sparsh Arcade, Zundal - Tragad Rd, Chandkheda
											</td>
											<td>Near Bagga Hyundai Showroom Near Jundal</td>
											<td>382424</td>
											<td>8511084939</td>
											<td>8988899888</td>
											<td>support@sembark.com</td>
											<td>13:00</td>
											<td>10:00</td>
											<td>https://mmt.com/hotel/1212</td>
										</tr>
									</tbody>
								</Table>
							</Box>
							<Box>
								<CopyToClipboardButton onClick={() => copy()} level="primary">
									<Icons.ClipboardCopy />
									Copy Template Format
								</CopyToClipboardButton>
							</Box>
						</Stack>
					)}
				</CopyToClipboard>
			</Container>
		</>
	)
}
