import { Box, Container } from "@sembark-travel/ui/base"
import { Breadcrumbs } from "@sembark-travel/ui/router"
import { Helmet } from "react-helmet-async"
import { NewServicesForm } from "./../../../TransportServices"
import { generatePath, useNavigate } from "../../../router-utils"

export default function NewTransportServicePage() {
	const navigate = useNavigate()
	return (
		<>
			<Helmet>
				<title>Add Transport Service</title>
			</Helmet>
			<Breadcrumbs
				title="New Services"
				items={[
					[generatePath("/transport-services"), "Transport Services"],
					["", "New"],
				]}
			/>
			<Box paddingX="4" paddingY="8">
				<Box maxWidth="6xl" marginX="auto">
					<Box borderWidth="1" rounded="md" bgColor="default">
						<Container paddingY="6">
							<NewServicesForm
								onSuccess={() => navigate("/transport-services")}
								onCancel={() => navigate("/transport-services")}
							/>
						</Container>
					</Box>
				</Box>
			</Box>
		</>
	)
}
