import { Required } from "utility-types"
import {
	TTravelActivityDatewisePricesInputFieldValue,
	TValidTravelActivityDatewisePricesInputFieldValue,
} from "./TravelActivityDatewisePricesInputField"

export function getValidTravelActivityData(
	c: TTravelActivityDatewisePricesInputFieldValue
): TValidTravelActivityDatewisePricesInputFieldValue | undefined {
	const { dates, activity, ticket_tourist_configurations, ...others } = c
	if (!dates?.length || !activity || !ticket_tourist_configurations?.length) {
		return undefined
	}
	const validConfigurations = (ticket_tourist_configurations || []).filter(
		(c): c is Required<typeof c, "quantity" | "configuration"> =>
			Boolean(c.quantity && c.configuration)
	)
	if (!validConfigurations?.length) return undefined
	return {
		...others,
		dates,
		activity,
		ticket_tourist_configurations: validConfigurations,
	}
}
