import {
	Box,
	Icons,
	Stack,
	Heading,
	Text,
	Divider,
} from "@sembark-travel/ui/base"
import { ButtonLink } from "@sembark-travel/ui/router"
import { useState } from "react"
import { generatePath } from "../../../router-utils"
import { NewUser, IUser } from "../../../Users"
import { Helmet } from "react-helmet-async"

export default function OnboardingInviteUsersPage() {
	const [invitedUser, setInvitedUser] = useState<IUser | null>()
	return (
		<>
			<Helmet>
				<title>Invite Users | Onboarding</title>
			</Helmet>
			<Box
				maxWidth="4xl"
				marginX="auto"
				padding="8"
				borderWidth="1"
				rounded="lg"
				bgColor="default"
				marginBottom="16"
			>
				<Box
					borderBottomWidth="1"
					display="inlineBlock"
					fontWeight="semibold"
					marginBottom="8"
					color="primary"
					fontSize="lg"
				>
					Onboarding &gt; Invite Members
				</Box>
				{!invitedUser ? (
					<Box>
						<Stack gap="2">
							<Heading as="h3">Invite Team Members</Heading>
							<Text>
								Invite your team members for better work allocations and team
								management. Assign appropriate role(s) to control access
								permissions.
							</Text>
						</Stack>
						<Divider />
						<NewUser
							origin={window.location.origin}
							onSuccess={(user) => {
								setInvitedUser(user)
							}}
						/>
						<Stack
							borderWidth="1"
							padding="4"
							marginTop="8"
							rounded="md"
							bgColor="subtle"
							gap="2"
						>
							<Text>
								Don't have a team or want to invite later ? You can invite later
								from your organization's profile page.
							</Text>
							<Box>
								<ButtonLink
									to={generatePath("/onboarding/done")}
									level="primary"
									status="primary"
								>
									Done <Icons.ChevronDown rotate="270" />
								</ButtonLink>
							</Box>
						</Stack>
					</Box>
				) : (
					<Stack gap="4">
						<Stack gap="4">
							<Heading as="h3">
								<Icons.Mail color="success" size="6" /> Invitation Sent!
							</Heading>
							<Text>
								<b>{invitedUser.name}</b> has been invited. An email has been
								send to{" "}
								<a href={`mailto:${invitedUser.email}`}>{invitedUser.email}</a>{" "}
								for registration process.
							</Text>
							<Box>
								If you want to invite more members,{" "}
								<Text<"a">
									as="a"
									href="#invite-more-users"
									color="accent"
									onClick={(e) => {
										e.preventDefault()
										setInvitedUser(null)
									}}
								>
									click here
								</Text>
								. Otherwise let move on to the next step. You can always invite
								your team members from your organization's profile page.
							</Box>
						</Stack>
						<Divider />
						<Stack gap="4">
							<Box>
								<ButtonLink
									to={generatePath("/onboarding/done")}
									level="primary"
									status="primary"
								>
									Done <Icons.ChevronDown rotate="270" />
								</ButtonLink>
							</Box>
						</Stack>
					</Stack>
				)}
			</Box>
		</>
	)
}
