import { AsyncSelect, AsyncSelectProps } from "@sembark-travel/ui/base"
import { useXHR } from "@sembark-travel/xhr"
import { hotelPaymentPreferencesXHR as XHR } from "./store"

export function SelectHotelPaymentReferences({
	...otherProps
}: Omit<AsyncSelectProps, "fetch">) {
	const xhr = useXHR()
	return (
		<AsyncSelect
			multiple
			{...otherProps}
			fetch={(q) =>
				XHR(xhr)
					.getHotelPaymentReferences({ q })
					.then((resp) => resp.data)
			}
		/>
	)
}
