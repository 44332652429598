import { useParams } from "../../../../../router-utils"
import { StateDetails } from "../../../../../Admin/World"
import { Helmet } from "react-helmet-async"

export default function StateDetailsPage() {
	const { stateId } = useParams("/admin/world/states/:stateId")
	return (
		<>
			<Helmet>
				<title>State Details</title>
			</Helmet>
			<StateDetails stateId={stateId} />
		</>
	)
}
