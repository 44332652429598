import { Helmet } from "react-helmet-async"
import { TripPayments } from "../../../../../Trips"
import { useTripDetailsOutletContext } from "../_trip-details-context"

export default function TripPaymentsPage() {
	const { trip } = useTripDetailsOutletContext()
	return (
		<>
			<Helmet>
				<title>Trip Payments</title>
			</Helmet>
			<TripPayments trip={trip} />
		</>
	)
}
