import { Box, Stack, Heading, Text } from "@sembark-travel/ui/base"
import { Link, queryToSearch } from "@sembark-travel/ui/router"
import { Helmet } from "react-helmet-async"
import { generatePath, useNavigate, Navigate } from "../../router-utils"
import { ResetPasswordForm } from "./../../ResetPassword"
import { useSearchParams } from "react-router-dom"

export default function ResetPassword() {
	const [searchParams] = useSearchParams()
	const navigate = useNavigate()
	const email = searchParams.get("email") || ""
	const token = searchParams.get("token") || ""
	if (!(email && token)) {
		return <Navigate to="/" replace />
	}
	return (
		<Box paddingY="1">
			<Helmet>
				<title>Reset Password</title>
			</Helmet>
			<Stack gap="8">
				<Stack textAlign="center" marginTop="16" gap="2">
					<Heading as="h1">Reset Password</Heading>
					<Text>
						Just enter your new password to reset the password for your email
						address ({email})
					</Text>
				</Stack>
				<Box maxWidth="sm" marginX="auto" width="full">
					<ResetPasswordForm
						email={email}
						token={token}
						onSucess={() => {
							navigate("/login")
						}}
					/>
					<Box textAlign="center">
						Get instructions{" "}
						<Link
							to={generatePath("/forgot-password") + queryToSearch({ email })}
							color="accent"
						>
							again
						</Link>{" "}
						<br />
						OR
						<br />
						<Link
							to={generatePath("/login") + queryToSearch({ email })}
							color="accent"
						>
							Login
						</Link>{" "}
						if you remember your password!
					</Box>
				</Box>
			</Stack>
		</Box>
	)
}
