import { Helmet } from "react-helmet-async"
import { WebhookClientCallsList } from "../../../Admin/WebhookClientCalls"

export default function WebhookClientCallsListPage() {
	return (
		<>
			<Helmet>
				<title>Webhook Client Calls</title>
			</Helmet>
			<WebhookClientCallsList />
		</>
	)
}
