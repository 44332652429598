import { Helmet } from "react-helmet-async"
import { TeamItem } from "./../../../../Teams"
import { useParams } from "./../../../../router-utils"

export default function TeamItemDetailsPage() {
	const { teamId } = useParams("/teams/:teamId")
	return (
		<>
			<Helmet>
				<title>Team Details</title>
			</Helmet>
			<TeamItem teamId={teamId} />
		</>
	)
}
