import { IAccount, IAsset } from "./store"

/**
 * Get the total assets from given accounts
 */
export function getAssetsFromAccounts(
	accountsWithAssets: Array<IAccount>
): Array<IAsset> {
	const mergedAssets: { [key: string]: IAsset } = {}
	accountsWithAssets.forEach((account) => {
		account.assets?.forEach((asset) => {
			const { type, amount, description } = asset
			if (mergedAssets[type]) {
				const newAmount = mergedAssets[type].amount + amount
				mergedAssets[type].amount = newAmount
				mergedAssets[type].description = description.replace(
					new RegExp(`${amount}`, "gi"),
					String(newAmount)
				)
			} else {
				mergedAssets[type] = { ...asset }
			}
		})
	})
	return Object.values(mergedAssets)
}
