import {
	createContext,
	useCallback,
	useContext,
	useMemo,
	useRef,
	useState,
} from "react"
import { Dialog } from "./Dialog"
import { Button } from "@sembark-travel/ui/base"

function noContext() {
	return () => {
		throw new Error(`ConfirmationDialogContext is missing`)
	}
}

type TConfirmationDialogContext = {
	confirm: () => Promise<boolean>
	isConfirmationVisible: boolean
	accept: () => void
	reject: () => void
}

const ConfirmationDialogContext = createContext<TConfirmationDialogContext>({
	confirm: noContext(),
	isConfirmationVisible: false,
	accept: noContext(),
	reject: noContext(),
})

export function ConfirmationDialogProvider({
	children,
}: {
	children: (props: {
		confirm: TConfirmationDialogContext["confirm"]
	}) => React.ReactNode
}) {
	const [state, setState] = useState<boolean>(false)
	const fn = useRef<((result: boolean) => void) | undefined>(undefined)
	const confirm = useCallback(() => {
		setState(true)
		return new Promise<boolean>((resolve) => {
			fn.current = (result: boolean) => {
				resolve(result)
				setState(false)
			}
		})
	}, [setState])
	const accept = useCallback(() => {
		fn.current?.(true)
	}, [])
	const reject = useCallback(() => {
		fn.current?.(false)
	}, [])
	const context = useMemo(
		() => ({
			confirm,
			isConfirmationVisible: state,
			accept,
			reject,
		}),
		[confirm, state, accept, reject]
	)
	return (
		<ConfirmationDialogContext.Provider value={context}>
			{children({ confirm: context.confirm })}
		</ConfirmationDialogContext.Provider>
	)
}

export function ConfirmationDialog({
	children,
	title,
	acceptActionLabel: acceptLabel = "Yes, continue",
	rejectActionLabel: rejectLabel = "Cancel",
	destructiveAction: destructive,
}: {
	children: React.ReactNode
	title: string
	acceptActionLabel?: string
	rejectActionLabel?: string
	destructiveAction?: boolean
}) {
	const { isConfirmationVisible, accept, reject } = useContext(
		ConfirmationDialogContext
	)
	return (
		<Dialog open={isConfirmationVisible} onClose={reject} sm>
			<Dialog.Header>
				<Dialog.Title color={destructive ? "danger" : undefined}>
					{title}
				</Dialog.Title>
			</Dialog.Header>
			<Dialog.Body>{children}</Dialog.Body>
			<Dialog.Footer>
				<Button
					onClick={accept}
					level="primary"
					status={destructive ? "danger" : "primary"}
					autoFocus={!destructive}
				>
					{acceptLabel}
				</Button>
				<Button onClick={reject} autoFocus={destructive} level="tertiary">
					{rejectLabel}
				</Button>
			</Dialog.Footer>
		</Dialog>
	)
}
