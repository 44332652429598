import { Alert, Spinner, useId } from "@sembark-travel/ui/base"
import { showSnackbar } from "@sembark-travel/ui/snackbar"
import { useXHR } from "@sembark-travel/xhr"
import useSWR from "swr"
import { TIntegration, XHR } from "./store"
import { NewIntegrationForm } from "./NewItem"

export function EditIntegrationItem({
	integrationId,
	onSuccess,
	onCancel,
}: {
	integrationId: string | number
	onSuccess: (data: TIntegration) => void
	onCancel: () => void
}) {
	const xhr = useXHR()
	const id = useId()
	const { data, error } = useSWR(
		`/api/integrations/${integrationId}?${id}`,
		() => XHR(xhr).show(integrationId)
	)
	if (!data) {
		if (!error) {
			return <Spinner alignCenter padding="8" />
		}
		return (
			<Alert status="error">{error.message || "Something went wrong"}</Alert>
		)
	}
	return (
		<NewIntegrationForm
			initialValues={{
				name: data.name,
				description: data.description,
				abilities: data.abilities,
			}}
			onSubmit={async (values) =>
				XHR(xhr)
					.update(data.id, values)
					.then((data) => {
						showSnackbar("Integration details updated successfully")
						onSuccess(data)
					})
			}
			onCancel={onCancel}
		/>
	)
}
