import {
	Button,
	Inline,
	Card,
	CardBody,
	CardFooter,
	CardHeader,
	Col,
	Grid,
} from "@sembark-travel/ui/base"
import { useXHR } from "@sembark-travel/xhr"
import {
	Form,
	validateFormValues,
	withServerErrors,
	SelectField,
	TextInputField,
	SubmissionError,
} from "@sembark-travel/ui/form"
import { Fragment } from "react"
import { Helmet } from "react-helmet-async"
import * as Validator from "yup"
import { SelectCities, SelectCountries, SelectStates } from "./List"
import { ICity, ICountry, ICountryState } from "./store"

const validationSchema = Validator.object().shape({
	country: Validator.object().required("Country field is required"),
	latitue: Validator.string(),
	longitude: Validator.string(),
})

interface NewItemCredentials {
	country?: ICountry
	state?: ICountryState
	city?: ICity
	latitude?: string
	longitude?: string
}
const initialValues: NewItemCredentials = {
	city: undefined,
	state: undefined,
	country: undefined,
	latitude: "",
	longitude: "",
}

export function NewItemForm({
	onSuccess,
	onCancel,
}: {
	onSuccess: () => void
	onCancel?: () => void
}) {
	const xhr = useXHR()
	return (
		<Fragment>
			<Helmet>
				<title>New Location</title>
			</Helmet>
			<Form<NewItemCredentials>
				initialValues={initialValues}
				validate={validateFormValues(validationSchema)}
				onSubmit={withServerErrors(async (values) => {
					const { country, state, city, latitude, longitude } = values
					await xhr.post("/locations", {
						country: country ? country.id : undefined,
						state: state ? state.name : undefined,
						city: city ? city.name : undefined,
						latitude,
						longitude,
					})
					onSuccess()
				})}
				subscription={{ submitting: true }}
			>
				{({ submitting, handleSubmit }) => (
					<form noValidate onSubmit={handleSubmit}>
						<Card>
							<CardHeader>Add New Location</CardHeader>
							<CardBody>
								<SubmissionError />
								<Grid>
									<Col sm="auto">
										<SelectField
											select={SelectCities}
											name="city"
											multiple={false}
											label="City"
											placeholder="Type to search.. (e.g. Jaipur)"
											creatable
										/>
									</Col>
									<Col sm="auto">
										<SelectField
											name="state"
											select={SelectStates}
											multiple={false}
											label="State"
											placeholder="Type to search.. (e.g. Rajasthan)"
											creatable
										/>
									</Col>
									<Col sm="auto">
										<SelectField
											select={SelectCountries}
											name="country"
											multiple={false}
											label="Country"
											placeholder="Type to search.. (e.g. India)"
										/>
									</Col>
									<Col sm="auto">
										<TextInputField
											type="text"
											name="latitude"
											label="Latitude"
											placeholder="27° 2' 9.6252'' N"
										/>
									</Col>
									<Col sm="auto">
										<TextInputField
											type="text"
											name="longitude"
											label="Longitude"
											placeholder="88° 15' 45.6192'' E"
										/>
									</Col>
								</Grid>
							</CardBody>
							<CardFooter>
								<Inline gap="4">
									<Button type="submit" disabled={submitting}>
										{submitting ? "Saving..." : "Save Location"}
									</Button>
									{onCancel ? (
										<Button onClick={onCancel} level="tertiary">
											Cancel
										</Button>
									) : null}
								</Inline>
							</CardFooter>
						</Card>
					</form>
				)}
			</Form>
		</Fragment>
	)
}
