import { Box, BoxOwnProps } from "./Box"

export type BadgeProps = Omit<
	React.AllHTMLAttributes<HTMLElement>,
	keyof BoxOwnProps
> & {
	status?: "info" | "primary" | "danger" | "warning" | "success" | "accent"
	outlined?: boolean
	fullRounded?: boolean
	children?: React.ReactNode
	/*
	 * @deprecated  Please use `status`
	 */
	primary?: boolean
	/*
	 * @deprecated  Please use `status`
	 */
	danger?: boolean
	/*
	 * @deprecated  Please use `status`
	 */
	warning?: boolean
	/*
	 * @deprecated  Please use `status`
	 */
	success?: boolean
	/*
	 * @deprecated  Please use `status`
	 */
	accent?: boolean
}

export function Badge({
	children,
	primary,
	danger,
	warning,
	success,
	accent,
	status,
	outlined,
	fullRounded,
	...props
}: BadgeProps) {
	if (
		children === null ||
		children === undefined ||
		(typeof children === "string" && !children.trim())
	)
		return null
	primary = primary || status === "primary"
	warning = warning || status === "warning"
	danger = danger || status === "danger"
	accent = accent || status === "accent"
	success = success || status === "success"
	return (
		<Box
			{...props}
			style={{
				lineHeight: 1,
				verticalAlign: "baseline",
			}}
			bgColor={
				outlined
					? "default"
					: primary
					? "primary"
					: success
					? "success"
					: warning
					? "warning"
					: danger
					? "danger"
					: accent
					? "accent"
					: "subtle"
			}
			borderColor={
				primary
					? "primary"
					: success
					? "success"
					: warning
					? "warning"
					: danger
					? "danger"
					: accent
					? "accent"
					: "default"
			}
			color={
				primary
					? "primary"
					: success
					? "success"
					: warning
					? "warning"
					: danger
					? "danger"
					: accent
					? "accent"
					: "default"
			}
			borderWidth="1"
			rounded={fullRounded ? "full" : "md"}
			paddingX="2"
			paddingY="1"
			fontSize="sm"
			fontWeight="semibold"
			whiteSpace="nowrap"
			textAlign="center"
			display="inlineBlock"
			as="span"
		>
			{children}
		</Box>
	)
}
