import { TSearchParams } from "@sembark-travel/ui/list"
import { queryToSearch } from "@sembark-travel/ui/router"
import { IListResponse, XHRInstance, useXHR } from "@sembark-travel/xhr"
import useSWR from "swr"
import { ITrip } from "../Trips"

export type TTripQuoteBookingsDiff = {
	id: number
	trip_id: number
	hotels_changed: boolean
	cabs_changed: boolean
	created_at: string
	updated_at?: string
	trip: ITrip
}

type TRequestParams = TSearchParams & {
	hotels_changed?: boolean
	cabs_changed?: boolean
}

export function tripQuoteBookingsDiffXHR(xhr: XHRInstance) {
	return {
		async get(params?: TRequestParams) {
			return xhr
				.get<IListResponse<TTripQuoteBookingsDiff>>(
					`/quote-diff-from-booking`,
					{
						params,
					}
				)
				.then((resp) => resp.data)
		},
	}
}

export function useTripQuoteBookingsDiffCount(params?: TRequestParams) {
	const xhr = useXHR()
	const { data } = useSWR(
		"/trip-quote-bookings-diff-count" + queryToSearch(params),
		() =>
			tripQuoteBookingsDiffXHR(xhr).get({
				...params,
				limit: 1,
			})
	)

	return data?.meta.total
}
