import {
	Box,
	Button,
	Inline,
	Card,
	CardBody,
	CardFooter,
	CardHeader,
	Spinner,
} from "@sembark-travel/ui/base"
import { useXHR } from "@sembark-travel/xhr"
import * as Validator from "yup"
import { IRole, useRole } from "./store"
import {
	Form,
	withServerErrors,
	validateFormValues,
	SubmissionError,
	TextInputField,
} from "@sembark-travel/ui/form"

const newRoleSchema = Validator.object().shape({
	name: Validator.string()
		.required("Name is required")
		.min(4, "Minimum 4 characters required")
		.max(199, "Maximum 199 characters allowed"),
})

interface EditRoleProps {
	roleId: string | number
	onSuccess: (data: IRole) => void
	onCancel?: () => void
}

export function EditRoleForm({ roleId, onSuccess, onCancel }: EditRoleProps) {
	const xhr = useXHR()
	const { role, isFetching } = useRole(roleId.toString())
	if (isFetching) return <Spinner alignCenter padding="4" />
	if (!role) return <Box>Role does not exist.</Box>
	const { id, name } = role
	const initialValues = {
		name,
	}
	type RoleCredentials = typeof initialValues
	return (
		<Form<RoleCredentials>
			initialValues={initialValues}
			validate={validateFormValues(newRoleSchema)}
			onSubmit={withServerErrors(async (values) => {
				const { data } = await xhr.put(`/roles/${id}`, values)
				const { data: role } = data
				onSuccess(role)
			})}
			subscription={{ submitting: true }}
		>
			{({ submitting, handleSubmit }) => (
				<form noValidate onSubmit={handleSubmit}>
					<SubmissionError />
					<Card>
						<CardHeader>Edit Role</CardHeader>
						<CardBody>
							<TextInputField
								type="text"
								label="Name"
								name="name"
								placeholder="Manager"
								required
							/>
						</CardBody>
						<CardFooter>
							<Inline gap="4">
								<Button type="submit" disabled={submitting}>
									{submitting ? "Updating..." : "Update Role"}
								</Button>
								{onCancel ? (
									<Button onClick={onCancel} disabled={submitting}>
										Cancel
									</Button>
								) : null}
							</Inline>
						</CardFooter>
					</Card>
				</form>
			)}
		</Form>
	)
}
