import {
	Table,
	Time,
	Container,
	Stack,
	Heading,
	Text,
	Box,
	Icons,
} from "@sembark-travel/ui/base"
import { useEffect } from "react"
import { TTeam, XHR } from "./store"
import { generatePath } from "../router-utils"
import { ButtonLink, useLocationQuery, Link } from "@sembark-travel/ui/router"
import { useSearch, Search, ListView } from "@sembark-travel/ui/list"

export function TeamsList() {
	const [query, setQuery] = useLocationQuery()
	const [params, setParams] = useSearch(query)
	useEffect(() => {
		setQuery(params)
	}, [setQuery, params])
	return (
		<Search
			initialParams={params}
			onSearch={(params) => setParams({ ...params, page: 1 })}
			title="Teams"
			resetParams={{
				q: "",
				page: 1,
			}}
			actions={
				<ButtonLink to={generatePath("/teams/new")} level="secondary">
					New Team
				</ButtonLink>
			}
		>
			<ListView<TTeam>
				pageKey="teams"
				fetch={(xhr, params) => XHR(xhr).get(params)}
				onPageChange={(page) => setParams({ ...params, page })}
				params={params}
				emptyState={function ({ searched, defaultValue }) {
					return searched ? (
						<>{defaultValue}</>
					) : (
						<Container paddingY="8" textAlign="center">
							<Stack gap="4">
								<Box>
									<Icons.UserGroup size="12" />
								</Box>
								<Stack gap="2" alignItems="center">
									<Heading>Increase efficiency with Teams</Heading>
									<Text color="muted" maxWidth="md">
										Create teams for specific destinations and permissions so
										that everyone works on their responsibilities and no-more.
									</Text>
								</Stack>
								<Box>
									<ButtonLink to={generatePath("/teams/new")} level="primary">
										<Icons.Plus /> Create Team
									</ButtonLink>
								</Box>
							</Stack>
						</Container>
					)
				}}
			>
				{({ items }) => (
					<Table
						bordered
						hover
						headers={["Name", "Members", "Created On"]}
						responsive
						rows={items.map((item) => [
							<Link
								to={generatePath("/teams/:teamId", { teamId: String(item.id) })}
								color="accent"
								fontWeight="semibold"
								anchored
							>
								{item.name}
							</Link>,
							item.users_count,
							<Time timestamp={item.created_at} />,
						])}
					/>
				)}
			</ListView>
		</Search>
	)
}
