import { Helmet } from "react-helmet-async"
import { useParams } from "../../../../../router-utils"
import { AllComments } from "../../../../../Trips"

export default function TripAllCommentsPage() {
	const { tripId } = useParams("/trips/:tripId/comments")
	return (
		<>
			<Helmet>
				<title>Trip Comments</title>
			</Helmet>
			<AllComments tripId={Number(tripId)} />
		</>
	)
}
