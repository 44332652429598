import { Helmet } from "react-helmet-async"
import { generatePath, useParams } from "../../../../router-utils"
import { CabSchedulesForDate } from "../../../../CabScheduling"
import { Alert, Stack, Text } from "@sembark-travel/ui/base"
import { Link } from "@sembark-travel/ui/router"

export default function CabSchedulesForDatePage(
	props: Omit<React.ComponentProps<typeof CabSchedulesForDate>, "date">
) {
	const { date } = useParams("/cab-schedules/date/:date")
	return (
		<>
			<Helmet>
				<title>Cab Schedules for Date</title>
			</Helmet>
			<Alert status="warning" title="Deprecated" rounded="none">
				<Stack gap="1">
					<Text>
						Cab scheduling have been moved to{" "}
						<Link
							to={generatePath("/operational-bookings/date/:date", { date })}
							fontWeight="semibold"
							textDecoration={"underline"}
						>
							Operational Bookings
						</Link>
						. This section will be removed in upcoming updates.
					</Text>
					<Text>Please provide your feedback in our support channel.</Text>
				</Stack>
			</Alert>
			<CabSchedulesForDate date={date} {...props} />
		</>
	)
}
