import { useField, useFormState } from "react-final-form"
import { InputValidationErrorMessage, Alert } from "@sembark-travel/ui/base"

export function ErrorMessage({ name }: { name: string }) {
	const {
		meta: { error, touched, submitError },
	} = useField(name, {
		subscription: { error: true, touched: true, submitError: true },
	})
	const dotedFieldName = name.replace(/\[/g, ".").replace(/\]/g, "")
	return (
		<>
			{(error && touched) || submitError ? (
				<InputValidationErrorMessage>
					{submitError ? <RenderError message={submitError} /> : null}
					{error ? <RenderError message={error} /> : null}
				</InputValidationErrorMessage>
			) : null}
			{name !== dotedFieldName ? (
				<FieldSubmitErrorMessage name={dotedFieldName} />
			) : null}
		</>
	)
}

function FieldSubmitErrorMessage({ name }: { name: string }) {
	const { submitErrors } = useFormState({
		subscription: { submitErrors: true },
	})
	return (
		<>
			{submitErrors && submitErrors[name] ? (
				<InputValidationErrorMessage>
					<RenderError message={submitErrors[name]} />
				</InputValidationErrorMessage>
			) : null}
		</>
	)
}

function RenderError({ message }: { message?: unknown }) {
	if (!message) return null
	if (typeof message === "string" || typeof message === "number") {
		return <>{message}</>
	}
	if (Array.isArray(message)) {
		return (
			<>
				{message.map((m, i) => (
					<RenderError key={i} message={m} />
				))}
			</>
		)
	}
	return null
}

export function SubmissionError() {
	const { submitError } = useFormState({ subscription: { submitError: true } })
	return submitError ? (
		<Alert status="error" hideIcon>
			<RenderError message={submitError} />
		</Alert>
	) : null
}
