import { useParams } from "../../../../../router-utils"
import { AccountItem } from "../../../../../Accounting"
import { Helmet } from "react-helmet-async"

export default function AccountDetails(
	props: Omit<React.ComponentProps<typeof AccountItem>, "accountId">
) {
	const { accountId } = useParams("/accounting/accounts/:accountId")
	return (
		<>
			<Helmet>
				<title>Account Details</title>
			</Helmet>
			<AccountItem accountId={accountId} {...props} />
		</>
	)
}
