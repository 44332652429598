import {
	Box,
	Button,
	Icons,
	Table,
	Inline,
	Stack,
} from "@sembark-travel/ui/base"
import { dateToUTCString } from "@sembark-travel/datetime-utils"
import {
	DatePickerField,
	EmptyNumberValidator,
	SelectField,
	TextInputField,
	withServerErrors,
	Form,
	FieldArray,
	arrayMutators,
	validateFormValues,
	SubmissionError,
	GetFieldValue,
} from "@sembark-travel/ui/form"
import { useXHR, XHRInstance } from "@sembark-travel/xhr"
import { useRef } from "react"
import * as Validator from "yup"
import { SelectAccount } from "../Accounting"
import { IAccount, ISuspenseTransaction } from "./store"
import { Required } from "utility-types"

function XHR(xhr: XHRInstance) {
	return {
		async log(data: unknown): Promise<Array<ISuspenseTransaction>> {
			return xhr
				.post("/accounting/suspense-transactions", data)
				.then((resp) => resp.data.data)
		},
	}
}

export function LogSuspenseTransaction({
	onCancel,
	onSuccess,
}: {
	onCancel?: () => void
	onSuccess: () => void
}) {
	const xhr = useXHR()
	return (
		<>
			<LogSuspenseTransactionForm
				onSubmit={async (data) => {
					await XHR(xhr).log(data)
					onSuccess()
				}}
				onCancel={onCancel}
			/>
		</>
	)
}

interface ILogData {
	data: Array<{
		amount: number
		date: string
		narration: string
		reference_id?: string
		debit_account?: string
		credit_account?: string
	}>
}

interface ILogSuspenseTransactionParams {
	data?: Array<{
		amount: number
		date: Date | string
		narration: string
		reference_id?: string
		debit_account?: IAccount
		credit_account?: IAccount
	}>
}

export function LogSuspenseTransactionForm({
	onSubmit,
	onCancel,
}: {
	onSubmit: (data: ILogData) => Promise<unknown>
	onCancel?: () => void
}) {
	const initialValues: Required<ILogSuspenseTransactionParams, "data"> = useRef(
		{
			data: [
				{
					amount: 0,
					date: new Date(),
					narration: "",
					reference_id: "",
					debit_account: undefined,
					credit_account: undefined,
				},
			],
		}
	).current
	return (
		<Form<ILogSuspenseTransactionParams>
			initialValues={initialValues}
			validate={validateFormValues(
				Validator.object()
					.required()
					.shape({
						data: Validator.array()
							.min(1, "Please provide atleast one suspense transaction to log")
							.of(
								Validator.object()
									.shape({
										amount: EmptyNumberValidator().positive(
											"Amount should be positive"
										),
										date: Validator.date().required(
											"Please provide a date when the payment was made"
										),
										narration: Validator.string()
											.required(
												"Please provide a description/narration for this transaction"
											)
											.max(191, "Please use 191 or lesser characters"),
										reference_id: Validator.string().max(
											191,
											"Please use 191 or lesser characters for reference id"
										),
										debit_account: Validator.object(),
										credit_account: Validator.object(),
									})
									.required()
							),
					})
			)}
			onSubmit={withServerErrors(async ({ data }) => {
				const validData = data?.map(
					({
						amount,
						reference_id,
						narration,
						debit_account,
						credit_account,
						date,
					}) => ({
						amount,
						date: date ? dateToUTCString(date) : dateToUTCString(new Date()),
						narration,
						reference_id,
						debit_account: debit_account?.name,
						credit_account: credit_account?.name,
					})
				)
				return await onSubmit({
					data: validData || [],
				})
			})}
			subscription={{ submitting: true }}
			mutators={{ ...arrayMutators }}
		>
			{({ submitting, handleSubmit }) => (
				<form noValidate onSubmit={handleSubmit}>
					<FieldArray<
						Required<ILogSuspenseTransactionParams>["data"][number]
					> name="data">
						{({ fields }) => (
							<Table
								hover
								responsive
								caption="Fields marked with * are required"
								headers={[
									"Date *",
									"Amount *",
									"Narration *",
									"Reference ID",
									"Debit Account",
									"Credit Account",
									"",
								]}
								rows={fields.map((name, index) => [
									<DatePickerField name={`${name}.date`} dateFormat required />,
									<TextInputField
										name={`${name}.amount`}
										type="number"
										min={0}
										max={99999999}
										required
									/>,
									<TextInputField
										name={`${name}.narration`}
										type="text"
										placeholder="Narration/Description/Details"
										required
										max={191}
									/>,
									<TextInputField
										name={`${name}.reference_id`}
										type="text"
										placeholder="OS123123231ABD"
										max={191}
									/>,
									<SelectField
										select={SelectAccount}
										name={`${name}.debit_account`}
										creatable
									/>,
									<SelectField
										select={SelectAccount}
										name={`${name}.credit_account`}
										creatable
									/>,
									<Inline gap="1">
										<GetFieldValue<
											Required<ILogSuspenseTransactionParams>["data"][number]
										>
											name={name}
										>
											{({ value: suspense }) => (
												<Button
													onClick={() => {
														fields.push(suspense)
													}}
													title="Duplicate this entry"
												>
													<Icons.Duplicate />
												</Button>
											)}
										</GetFieldValue>
										{Number(fields.length || 0) > 1 ? (
											<Button
												onClick={() => fields.remove(index)}
												title="Remove this entry"
											>
												<Icons.Cancel />
											</Button>
										) : null}
									</Inline>,
								])}
							>
								<tfoot>
									<tr>
										<td colSpan={7}>
											<Box
												marginTop="12"
												paddingTop="4"
												paddingBottom="4"
												borderTopWidth="1"
											>
												<Button
													level="primary"
													onClick={() => fields.push(initialValues.data[0])}
												>
													<Icons.Plus /> Add More
												</Button>
											</Box>
										</td>
									</tr>
								</tfoot>
							</Table>
						)}
					</FieldArray>
					<Stack gap="4">
						<SubmissionError />
						<Inline gap="4">
							<Button disabled={submitting} type="submit">
								{submitting ? "Saving..." : "Save"}
							</Button>
							{onCancel ? (
								<Button onClick={onCancel} disabled={submitting}>
									Cancel
								</Button>
							) : null}
						</Inline>
					</Stack>
				</form>
			)}
		</Form>
	)
}
