import { AsyncSelect, Table } from "@sembark-travel/ui/base"
import { CursorListView, Search, TSearchParams } from "@sembark-travel/ui/list"
import {
	ISimpleListResponse,
	ICursorListResponse,
	useXHR,
	XHRInstance,
} from "@sembark-travel/xhr"
import React, { Fragment } from "react"
import { Omit } from "utility-types"
import { TTripDestination } from "../TripDestinations"
import { IRoomType } from "./store"

function XHR(xhr: XHRInstance) {
	return {
		async getRoomTypes(
			params?: unknown
		): Promise<ISimpleListResponse<IRoomType>> {
			return xhr.get("/room-types", { params }).then((resp) => resp.data)
		},
		async getRoomTypesWithTotal(
			params?: Record<string, unknown>
		): Promise<ICursorListResponse<IRoomType>> {
			return xhr
				.get("/room-types", { params: { ...params, pagination: "cursor" } })
				.then((resp) => resp.data)
		},
	}
}

export function List<TParams extends TSearchParams = TSearchParams>({
	params = {} as TParams,
	setParams,
	actions,
}: {
	params?: TParams
	setParams: (params: TParams) => void
	actions?: React.ReactNode
}) {
	return (
		<Fragment>
			<Search
				initialParams={params}
				onSearch={(params) => {
					setParams({ ...params, cursor: null })
				}}
				title="Room Types"
				actions={actions}
			/>
			<CursorListView<IRoomType>
				pageKey="room-types"
				fetch={(xhr, params) => XHR(xhr).getRoomTypesWithTotal(params)}
				onCursorChange={(cursor) => setParams({ ...params, cursor })}
				params={params}
			>
				{({ items: roomTypes }) => (
					<Table
						striped
						bordered
						headers={["Name", "Description"]}
						rows={roomTypes.map((roomType) => [
							roomType.name,
							roomType.description,
						])}
					/>
				)}
			</CursorListView>
		</Fragment>
	)
}

export function SelectRoomTypes({
	tripDestinations,
	...props
}: Omit<React.ComponentProps<typeof AsyncSelect>, "fetch"> & {
	tripDestinations?: Array<TTripDestination>
}) {
	const xhr = useXHR()
	return (
		<AsyncSelect
			multiple
			cacheKey={`room-types-${tripDestinations?.map((t) => t.id).join("-")}`}
			{...props}
			fetch={(q) =>
				XHR(xhr)
					.getRoomTypes({
						q,
						trip_destinations: tripDestinations?.length
							? tripDestinations.map((t) => t.id)
							: null,
					})

					.then((resp) => resp.data)
			}
		/>
	)
}
