import { ltrim, rtrim } from "./utils"
export class Amount {
	private integerPart: string
	private fractionalPart: string
	constructor(integerPart: string, fractionalPart = "") {
		if (integerPart === "" && fractionalPart === "") {
			throw new Error("Empty number is invalid")
		}
		this.integerPart = Amount.parseIntegerPart(integerPart)
		this.fractionalPart = Amount.parseFractionalPart(fractionalPart)
	}

	toString() {
		if (this.fractionalPart === "") {
			return this.integerPart
		}
		return this.integerPart + "." + this.fractionalPart
	}

	getIntegerPart(): string {
		return this.integerPart
	}

	getFractionalPart(): string {
		return this.fractionalPart
	}

	isInteger(): boolean {
		return this.fractionalPart === ""
	}

	isDecimal(): boolean {
		return this.fractionalPart !== ""
	}

	isNegative(): boolean {
		return this.integerPart[0] === "-"
	}

	isPositive(): boolean {
		return this.integerPart[0] !== "-"
	}

	isHalf() {
		return this.fractionalPart === "5"
	}

	isCurrentEven() {
		const lastIntegerPartNumber = Number(
			this.integerPart[this.integerPart.length - 1]
		)
		return lastIntegerPartNumber % 2 === 0
	}

	isCloserToNext() {
		if (this.fractionalPart === "") {
			return false
		}

		return Number(this.fractionalPart[0]) >= 5
	}

	isZero() {
		if (!this.isInteger()) {
			return false
		}
		const integerPart = this.integerPart
		return integerPart === "0" || integerPart === "-0"
	}

	getIntegerRoundingMultiplier(): "1" | "-1" {
		if (this.integerPart[0] === "-") {
			return "-1"
		}

		return "1"
	}

	/**
	 * Move the base 10 (divide) by a given number.
	 * It doesn't divide the amount. It simply moves the decimal point.
	 * @example 12.base(1) => 12/10 => 1.2, 12.base(-1) => 12/10^-1 => 120
	 */
	base10(power: number) {
		if (this.integerPart === "0" && !this.fractionalPart) {
			return this
		}

		let sign = ""
		let integerPart = this.integerPart

		if (integerPart[0] === "-") {
			sign = "-"
			integerPart = integerPart.slice(1)
		}

		if (power >= 0) {
			integerPart = ltrim(integerPart, "0")
			const lengthIntegerPart = integerPart.length
			const integers = lengthIntegerPart - Math.min(power, lengthIntegerPart)
			const zeroPad = power - Math.min(power, lengthIntegerPart)

			return new Amount(
				sign + integerPart.slice(0, integers),
				rtrim(
					String("").padStart(zeroPad, "0") +
						integerPart.slice(integers) +
						this.fractionalPart,
					"0"
				)
			)
		}

		power = Math.abs(power)
		const lengthFractionalPart = this.fractionalPart.length
		const fractions =
			lengthFractionalPart - Math.min(power, lengthFractionalPart)
		const zeroPad = power - Math.min(power, lengthFractionalPart)

		return new Amount(
			sign +
				ltrim(
					integerPart +
						this.fractionalPart.slice(0, lengthFractionalPart - fractions) +
						String("").padStart(zeroPad, "0"),
					"0"
				),
			this.fractionalPart.slice(lengthFractionalPart - fractions)
		)
	}

	static fromString(str: string): Amount {
		if (/e[+-]?\d+/.test(str)) {
			// handle exponent
			const [baseStr, powerStr] = str.split("e", 2)
			return Amount.fromString(baseStr).base10(-1 * Number(powerStr))
		}
		const [integerPart, fractionalPart] = str.toString().split(".", 2)
		return new Amount(integerPart, rtrim(fractionalPart || "", "0"))
	}

	static fromNumber(str: string | number) {
		if (Number.isSafeInteger(str)) {
			return new Amount(String(str))
		}
		return Amount.fromString(String(str))
	}

	static parseIntegerPart(str: string): string {
		if (str === "" || str === "0") {
			return "0"
		}

		if (str === "-") {
			return "-0"
		}

		if (/e[+-]?\d+/.test(str)) {
			// has exponents
			const [baseStr, powerStr] = str.split("e", 2)
			return Amount.fromString(baseStr)
				.base10(-1 * Number(powerStr))
				.getIntegerPart()
		}

		// Happy path performance optimization: number can be used as-is if it is within
		// the platform's integer capabilities.
		if (str === String(parseInt(str))) {
			return str
		}

		let nonZero = false
		const characters = str.length
		for (let position = 0; position < characters; ++position) {
			const digit = str[position]
			/** we are, on purpose, checking if the digit is valid against a fixed structure */
			if (!NUMBERS[digit] && !(position === 0 && digit === "-")) {
				throw new Error(
					`Invalid integer part ${str}. Invalid digit ${digit} found`
				)
			}
			if (nonZero === false && digit === "0") {
				throw new Error("Leading zeros are not allowed")
			}
			nonZero = true
		}

		return str
	}

	static parseFractionalPart(str: string): string {
		if (str === "") {
			return str
		}

		const intFraction = parseInt(str)

		// Happy path performance optimization: number can be used as-is if it is within
		// the platform's integer capabilities, and it starts with zeroes only.
		if (intFraction > 0 && ltrim(str, "0") === String(intFraction)) {
			return str
		}
		const characters = str.length
		for (let position = 0; position < characters; ++position) {
			const digit = str[position]

			/** @psalm-suppress InvalidArrayOffset we are, on purpose, checking if the digit is valid against a fixed structure */
			if (!NUMBERS[digit]) {
				throw new Error(
					`Invalid fractional part ${str}. Invalid digit ${digit} found`
				)
			}
		}
		return str
	}

	/**
	 * Given a money amount (without decimal), truncate it's existing fraction digits to a given target
	 */
	static roundMoneyValue(
		moneyAmount: string,
		targetFractionalDigits: number,
		existingFractionalDigits: number
	): string {
		const moneyAmountChars: Array<string | number> = [...moneyAmount]
		const moneyAmountCharsLength = moneyAmountChars.length
		let position =
			moneyAmountCharsLength - existingFractionalDigits + targetFractionalDigits
		const shouldRound =
			targetFractionalDigits < existingFractionalDigits && position > 0

		if (shouldRound && Number(moneyAmountChars[position]) >= 5) {
			let carry = 1 // >= 5, so expected increment in position-1 = 1

			while (position > 0) {
				const newValue = String(Number(moneyAmountChars[position - 1]) + carry)
				if (Number(newValue) >= 10) {
					moneyAmountChars[position - 1] = String(newValue)[1]
					carry = Number(newValue[0])
					// we have a carry, take it to next digit (on left)
					--position
					if (position === 0) {
						moneyAmountChars.unshift(carry)
					}
				} else {
					if (moneyAmountChars[position - 1] === "-") {
						moneyAmountChars[position - 1] = newValue[0]
						moneyAmountChars.unshift("-")
					} else {
						moneyAmountChars[position - 1] = newValue[0]
					}
					break
				}
			}
		}
		return moneyAmountChars.join("")
	}
}

/** Valid Digits */
const NUMBERS: Record<string, 1> = {
	0: 1,
	1: 1,
	2: 1,
	3: 1,
	4: 1,
	5: 1,
	6: 1,
	7: 1,
	8: 1,
	9: 1,
}
