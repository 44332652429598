import {
	AsyncSelect,
	AsyncSelectProps,
	Alert,
	Badge,
	Box,
	Inline,
	Button,
	Icons,
	Table,
	Stack,
	Text,
	Heading,
	Divider,
	Dropdown,
	joinAttributes,
	RelativeTime,
	TabContent,
	TabItem,
	Tabs,
	TabsList,
	Time,
} from "@sembark-travel/ui/base"
import { useDialog, Dialog } from "@sembark-travel/ui/dialog"
import { showSnackbar } from "@sembark-travel/ui/snackbar"
import { Link, useLocationQuery } from "@sembark-travel/ui/router"
import {
	areAdvancedFiltersAppliedDefault,
	ListView,
	Search,
	TSearchParams,
	useSearch,
} from "@sembark-travel/ui/list"
import {
	utcTimestampToLocalDate,
	isBefore,
} from "@sembark-travel/datetime-utils"
import { useXHR } from "@sembark-travel/xhr"
import React, { Fragment, useEffect, useMemo } from "react"
import { Omit } from "utility-types"
import * as Validator from "yup"
import { Email } from "../../Contacts"
import { generatePath, joinPaths } from "../../router-utils"
import { ITenant, TenantsXHR } from "./store"
import {
	Form,
	SelectField,
	SelectInputField,
	SubmissionError,
	TextInputField,
	validateFormValues,
	withServerErrors,
} from "@sembark-travel/ui/form"
import { PERMISSIONS, useCheckPermissions } from "../../Auth"
import { IUser, SelectUsers } from "../../Users"

type TFilters = TSearchParams & {
	status?:
		| "all"
		| "disabled"
		| "not_signed_up"
		| "archived"
		| "in_trial"
		| "paying"
		| "on_hold"
		| "free_plan"
		| "open_signup"
	supported_by_users?: Array<IUser>
}

type TFiltersInQuery = TSearchParams & {
	status?:
		| "all"
		| "disabled"
		| "not_signed_up"
		| "archived"
		| "in_trial"
		| "paying"
		| "on_hold"
		| "free_plan"
		| "open_signup"
	sps?: Array<string>
}

function filtersToQuery({
	status,
	supported_by_users,
	...others
}: TFilters): TFiltersInQuery {
	const query: TFiltersInQuery = { status: status, ...others }
	if (supported_by_users?.length) {
		query.sps = supported_by_users.map((s) => `${s.id}#${s.name}`)
	}
	return query
}

function queryToFilters({ status, sps, ...others }: TFiltersInQuery): TFilters {
	const filters: TFilters = { status: status || "paying", ...others }
	if (sps?.length) {
		filters.supported_by_users = sps.map((s) => {
			const [id, name] = s.split("#")
			return { id, name } as never as IUser
		})
	}
	return filters
}

export function TenantsList({ actions }: { actions?: React.ReactNode }) {
	const [query, setQuery] = useLocationQuery<TFilters>({
		toQuery: filtersToQuery,
		fromQuery: queryToFilters,
	})
	const [params, setParams] = useSearch({
		...query,
		status: query.status || "paying",
	})
	useEffect(() => {
		setQuery(params)
	}, [params, setQuery])
	return (
		<Fragment>
			<Search
				initialParams={params}
				onSearch={(params) => {
					setParams({ ...params, page: 1 })
				}}
				title="Tenants"
				resetParams={(params) => ({
					q: "",
					status: params.status,
				})}
				actions={actions}
				Filters={Filters}
				areAdvancedFiltersApplied={(params) => {
					const { status, ...otherParams } = params
					return areAdvancedFiltersAppliedDefault(otherParams)
				}}
			>
				{({ searchParams, setSearchParams }) => (
					<Tabs>
						<TabsList>
							<TabItem
								active={searchParams.status === "paying"}
								onClick={() =>
									setSearchParams({
										...searchParams,
										status: "paying",
									})
								}
							>
								Paying
							</TabItem>
							<TabItem
								active={searchParams.status === "on_hold"}
								onClick={() =>
									setSearchParams({
										...searchParams,
										status: "on_hold",
									})
								}
							>
								On Hold
							</TabItem>
							<TabItem
								active={searchParams.status === "in_trial"}
								onClick={() =>
									setSearchParams({
										...searchParams,
										status: "in_trial",
									})
								}
							>
								In Trial
							</TabItem>
							<TabItem
								active={searchParams.status === "disabled"}
								onClick={() =>
									setSearchParams({
										...searchParams,
										status: "disabled",
									})
								}
							>
								Disabled
							</TabItem>
							<TabItem
								active={searchParams.status === "not_signed_up"}
								onClick={() =>
									setSearchParams({
										...searchParams,
										status: "not_signed_up",
									})
								}
							>
								Not Signed Up
							</TabItem>
							<TabItem
								active={searchParams.status === "all"}
								onClick={() =>
									setSearchParams({ ...searchParams, status: "all" })
								}
							>
								All
							</TabItem>
							<TabItem
								active={searchParams.status === "archived"}
								onClick={() =>
									setSearchParams({
										...searchParams,
										status: "archived",
									})
								}
							>
								Archived
							</TabItem>
						</TabsList>
						<TabContent>
							<ListView<ITenant, TFilters>
								pageKey={`admin/tenants`}
								params={params}
								fetch={(xhr, params) =>
									TenantsXHR(xhr).getTenants({
										...params,
										supported_by_users: (params.supported_by_users || []).map(
											(s) => s.id
										),
										include: ["users_count", "supported_by_users"].join(","),
									})
								}
								onPageChange={(page) => setParams({ ...params, page })}
							>
								{({ items: tenants, refresh }) =>
									params.status === "in_trial" ? (
										<InTrialTenantsList tenants={tenants} refresh={refresh} />
									) : params.status === "paying" ? (
										<ConvertedTenantsList tenants={tenants} refresh={refresh} />
									) : params.status === "on_hold" ? (
										<OnHoldTenantsList tenants={tenants} refresh={refresh} />
									) : params.status === "not_signed_up" ? (
										<NotSignedupTenantsList
											tenants={tenants}
											refresh={refresh}
										/>
									) : params.status === "disabled" ? (
										<DisabledTenantsList tenants={tenants} refresh={refresh} />
									) : params.status === "archived" ? (
										<ArchivedTenantsList tenants={tenants} />
									) : (
										<Table
											headers={["Name", "Contact", "Signup", "Users", ""]}
											hover
											bordered
											responsive
											alignCols={{ 3: "right", 4: "right" }}
											rows={tenants.map((r) => [
												<Box display="flex" alignItems="center">
													<Name tenant={r} />
													{r.is_disabled && params.status !== "disabled" ? (
														<Box display="inlineBlock" marginLeft="1">
															<Icons.Ban
																color="warning"
																title="Access disabled"
															/>
														</Box>
													) : null}
												</Box>,
												<Box>
													<Box>
														{joinAttributes(
															r.address_name,
															r.address_email ? (
																<Email value={r.address_email} iconOnly />
															) : null
														)}
													</Box>
												</Box>,
												<Box>
													{r.signup_at ? (
														<Time
															value={utcTimestampToLocalDate(r.signup_at)}
														/>
													) : r.invited_at ? (
														<Box>
															Not Signuped Yet!
															<Box fontSize="xs" color="muted">
																Invited{" "}
																<RelativeTime
																	value={utcTimestampToLocalDate(r.invited_at)}
																/>
															</Box>
														</Box>
													) : null}
												</Box>,
												r.users_count,
												<Box>
													<ActionMenu tenant={r} onChange={refresh} />
												</Box>,
											])}
										/>
									)
								}
							</ListView>
						</TabContent>
					</Tabs>
				)}
			</Search>
		</Fragment>
	)
}

function InTrialTenantsList({
	tenants,
	refresh,
}: {
	tenants: Array<ITenant>
	refresh: () => void
}) {
	return (
		<Table
			headers={["Name", "Contact", "Signup", "Users", "Trial Ends", ""]}
			hover
			bordered
			alignCols={{ 3: "right", 5: "right" }}
			rows={tenants.map((r) => [
				<Name tenant={r} />,
				<Box>
					<Box>
						{joinAttributes(
							r.address_name,
							r.address_email ? (
								<Email value={r.address_email} iconOnly />
							) : null
						)}
					</Box>
				</Box>,
				<Box>
					{r.signup_at ? (
						<Time value={utcTimestampToLocalDate(r.signup_at)} />
					) : r.invited_at ? (
						<Box>
							Not Signuped Yet!
							<Box fontSize="xs" color="muted">
								Invited{" "}
								<RelativeTime value={utcTimestampToLocalDate(r.invited_at)} />
							</Box>
						</Box>
					) : null}
				</Box>,
				r.users_count,
				r.trial_ends_at ? (
					<Box>
						<RelativeTime value={utcTimestampToLocalDate(r.trial_ends_at)} />
						{isBefore(utcTimestampToLocalDate(r.trial_ends_at), new Date()) ? (
							<Badge warning>Expired</Badge>
						) : null}
					</Box>
				) : (
					<Box color="muted">Not Set</Box>
				),
				<Box>
					<ActionMenu tenant={r} onChange={refresh} />
				</Box>,
			])}
		/>
	)
}

function ConvertedTenantsList({
	tenants,
	refresh,
}: {
	tenants: Array<ITenant>
	refresh: () => void
}) {
	return (
		<Table
			headers={["Name", "Contact", "Converted At", "Users", ""]}
			hover
			bordered
			alignCols={{ 3: "right", 4: "right" }}
			rows={tenants.map((r) => [
				<Name tenant={r} />,
				<Box>
					<Box>
						{joinAttributes(
							r.address_name,
							r.address_email ? (
								<Email value={r.address_email} iconOnly />
							) : null
						)}
					</Box>
				</Box>,
				r.converted_at ? (
					<Box>
						<Time value={utcTimestampToLocalDate(r.converted_at)} />
					</Box>
				) : null,
				r.users_count,
				<Box>
					<ActionMenu tenant={r} onChange={refresh} />
				</Box>,
			])}
		/>
	)
}

function OnHoldTenantsList({
	tenants,
	refresh,
}: {
	tenants: Array<ITenant>
	refresh: () => void
}) {
	return (
		<Table
			headers={["Name", "Contact", "On Hold At", "Users", ""]}
			hover
			bordered
			alignCols={{ 3: "right", 4: "right" }}
			rows={tenants.map((r) => [
				<Name tenant={r} />,
				<Box>
					<Box>
						{joinAttributes(
							r.address_name,
							r.address_email ? (
								<Email value={r.address_email} iconOnly />
							) : null
						)}
					</Box>
				</Box>,
				r.on_hold_at ? (
					<Box>
						<Time value={utcTimestampToLocalDate(r.on_hold_at)} />
					</Box>
				) : null,
				r.users_count,
				<Box>
					<ActionMenu tenant={r} onChange={refresh} />
				</Box>,
			])}
		/>
	)
}

function NotSignedupTenantsList({
	tenants,
	refresh,
}: {
	tenants: Array<ITenant>
	refresh: () => void
}) {
	return (
		<Table
			headers={["Name", "Contact", "Invited", ""]}
			hover
			bordered
			alignCols={{ 3: "right" }}
			rows={tenants.map((r) => [
				<Name tenant={r} />,
				<Box>
					<Box>
						{joinAttributes(
							r.address_name,
							r.address_email ? (
								<Email value={r.address_email} iconOnly />
							) : null
						)}
					</Box>
				</Box>,
				r.invited_at ? (
					<RelativeTime value={utcTimestampToLocalDate(r.invited_at)} />
				) : r.is_open_signup ? (
					"Open Signup"
				) : null,
				<Box>
					<ActionMenu tenant={r} onChange={refresh} />
				</Box>,
			])}
		/>
	)
}

function DisabledTenantsList({
	tenants,
	refresh,
}: {
	tenants: Array<ITenant>
	refresh: () => void
}) {
	return (
		<Table
			headers={["Name", "Contact", "Disabled", "Reason", "Users", ""]}
			hover
			bordered
			alignCols={{ 4: "right", 5: "right" }}
			rows={tenants.map((r) => [
				<Box display="flex" alignItems="center">
					<Name tenant={r} />
				</Box>,
				<Box>
					<Box>
						{joinAttributes(
							r.address_name,
							r.address_email ? (
								<Email value={r.address_email} iconOnly />
							) : null
						)}
					</Box>
				</Box>,
				<Stack gap="1">
					<Box>
						{r.disabled_at ? <RelativeTime timestamp={r.disabled_at} /> : null}
					</Box>
					<Text fontSize="sm" color="muted">
						by {r.disabled_by ? r.disabled_by.name : null}
					</Text>
				</Stack>,
				<Text color="warning" fontWeight="semibold">
					{r.disabled_reason}
				</Text>,
				r.users_count,
				<Box>
					<ActionMenu tenant={r} onChange={refresh} />
				</Box>,
			])}
		/>
	)
}

function ArchivedTenantsList({ tenants }: { tenants: Array<ITenant> }) {
	return (
		<Table
			headers={["Name", "Contact", "Archived By", "Users"]}
			hover
			bordered
			alignCols={{ 3: "right" }}
			rows={tenants.map((r) => [
				<Box display="flex" alignItems="center">
					<Name tenant={r} />
				</Box>,
				<Box>
					<Box>
						{joinAttributes(
							r.address_name,
							r.address_email ? (
								<Email value={r.address_email} iconOnly />
							) : null
						)}
					</Box>
				</Box>,
				<Stack gap="1">
					<Box>
						{r.deleted_at ? <RelativeTime timestamp={r.deleted_at} /> : null}
					</Box>
					<Text fontSize="sm" color="muted">
						by {r.deleted_by ? r.deleted_by.name : null}
					</Text>
				</Stack>,
				r.users_count,
			])}
		/>
	)
}

function Name({ tenant }: { tenant: ITenant }) {
	return (
		<Box title={tenant.description} display="flex" alignItems="center">
			{tenant.profile_image_url ? (
				<Box
					as="img"
					src={tenant.profile_image_url}
					marginRight="2"
					style={{ width: "56px", maxHeight: "40px", background: "white" }}
				/>
			) : null}
			<Stack gap="1">
				<Inline gap="1" flexWrap="wrap" alignItems={"baseline"}>
					<Link
						to={tenant.id.toString()}
						anchored
						color="accent"
						fontWeight="semibold"
					>
						{tenant.name || tenant.short_name || tenant.address_name}
					</Link>
					<Text fontSize="xs" color="muted">
						({tenant.short_name})
					</Text>
				</Inline>
				{tenant.supported_by_users ? (
					<Text fontSize="sm" color="muted">
						{tenant.supported_by_users.map((s) => s.name).join(", ")}
					</Text>
				) : null}
			</Stack>
		</Box>
	)
}

export function ActionMenu({
	tenant,
	onChange,
}: {
	tenant: ITenant
	onChange: () => void
}) {
	const xhr = useXHR()
	const { hasPermission } = useCheckPermissions()
	return !tenant.deleted_at && hasPermission(PERMISSIONS.MANAGE_TENANTS) ? (
		<Dropdown alignRight>
			<Dropdown.ToggleButton size="sm">
				<Icons.DotsVertical />
			</Dropdown.ToggleButton>
			<Dropdown.Menu>
				<ResendInvitation tenant={tenant} onChange={onChange}>
					{({ resend }) => (
						<Dropdown.MenuItem onClick={resend}>
							<Icons.Mail /> Resend Invitation
						</Dropdown.MenuItem>
					)}
				</ResendInvitation>
				{!tenant.disabled_at && !tenant.deleted_at && tenant.signup_at ? (
					tenant.conversion_status !== "on_hold" ? (
						<Dropdown.MenuItem
							onClick={() => {
								if (
									window.confirm(
										"Are you sure you want to put this tenant on-hold?"
									)
								) {
									xhr
										.post("/on-hold-tenants", { tenant_id: tenant.id })
										.then(() => {
											showSnackbar(
												`${tenant.name} successfully moved to On-Hold`
											)
											onChange()
										})
								}
							}}
						>
							<Icons.HandRaised /> Put On-Hold
						</Dropdown.MenuItem>
					) : (
						<Dropdown.MenuItem
							onClick={() => {
								if (
									window.confirm(
										"Are you sure you want to revert this tenant from on-hold?"
									)
								) {
									xhr
										.delete(`/on-hold-tenants`, {
											params: {
												tenant_id: tenant.id,
											},
										})
										.then(() => {
											showSnackbar(
												`${tenant.name} successfully reverted from On-Hold`
											)
											onChange()
										})
								}
							}}
						>
							<Icons.HandThumbUp /> Revert On-Hold
						</Dropdown.MenuItem>
					)
				) : null}
				{!tenant.disabled_at ? <Dropdown.MenuItemDivider /> : null}
				<DisableTenant tenant={tenant} onChange={onChange}>
					{({ disable }) => (
						<Dropdown.MenuItem color="warning" onClick={disable}>
							<Icons.Ban /> Disable Access
						</Dropdown.MenuItem>
					)}
				</DisableTenant>
				{tenant.is_disabled ? (
					<Dropdown.MenuItem
						onClick={async () => {
							if (
								window.confirm(
									`Are you sure you want to enable account access for "${tenant.name}" ?`
								)
							) {
								try {
									await xhr.delete(`/disabled-tenants/${tenant.uid}`)
									showSnackbar(`Access enabled for ${tenant.name}`)
									onChange()
								} catch (e) {
									const error = e as Error
									window.alert(
										error.message ||
											"Sorry! Something went wrong. Please try again after some time."
									)
								}
							}
						}}
					>
						<Icons.Refresh /> Enable Access
					</Dropdown.MenuItem>
				) : null}
				<Dropdown.MenuItem
					color="danger"
					onClick={async () => {
						if (
							window.confirm(`Archiving an account will:
- disable it's login and access
- move it to "Archived" tab only
- is irreversible action

Are you sure you want to archive account for "${tenant.name}"
`)
						) {
							try {
								await xhr.delete(`/tenants/${tenant.uid}`)
								showSnackbar(`Archived account for ${tenant.name}`)
								onChange()
							} catch (e) {
								const error = e as Error
								window.alert(
									error.message ||
										"Sorry! Something went wrong. Please try again after some time."
								)
							}
						}
					}}
				>
					<Icons.Trash /> Archive Account
				</Dropdown.MenuItem>
			</Dropdown.Menu>
		</Dropdown>
	) : null
}

const resendInvitationValidationSchema = Validator.object()
	.shape({
		address_name: Validator.string().required(
			"Please provide a contact person's name for addressing email"
		),
		address_email: Validator.string()
			.email("Please provide a valid email address")
			.required("Please provide an email address to send invitation to."),
	})
	.required()

export function ResendInvitation({
	tenant,
	onChange,
	children = ({ resend }) => (
		<Button onClick={resend}>
			<Icons.Mail /> Resend Invitation
		</Button>
	),
}: {
	tenant: ITenant
	onChange: () => void
	children?: (props: { resend: () => void }) => React.ReactNode
}) {
	const { signup_at, is_disabled, address_email, address_name } = tenant
	const xhr = useXHR()
	const origin = window.location.origin || ""
	const initialValues = useMemo(() => {
		return {
			tenant_signup_link: origin
				? joinPaths(origin, generatePath("/tenant-signup"))
				: "",
			address_name,
			address_email,
		}
	}, [origin, address_name, address_email])
	const [isOpen, open, close] = useDialog()
	if (signup_at || is_disabled) return null
	return (
		<>
			{children({ resend: () => open() })}
			<Dialog
				open={isOpen}
				onClose={close}
				title={`Resend Invitation to ${tenant.name}`}
				sm
			>
				<Form<typeof initialValues>
					initialValues={initialValues}
					validate={validateFormValues(resendInvitationValidationSchema)}
					onSubmit={withServerErrors(async (values) => {
						if (
							window.confirm(
								`Are you sure you want to resend invitation to "${tenant.name}" at "${values.address_email}"`
							)
						) {
							const { data } = await xhr.post(
								`/invited-tenants/${tenant.uid}/resend`,
								values
							)
							showSnackbar(
								data.message || `Invitation resent to ${tenant.name}`
							)
							onChange()
							close()
						}
					})}
					subscription={{ submitting: true }}
				>
					{({ submitting, handleSubmit }) => (
						<form noValidate onSubmit={handleSubmit}>
							<Dialog.Body>
								<input
									hidden
									type="hidden"
									name="tenant_signup_link"
									value={initialValues.tenant_signup_link}
								/>
								<Stack gap="4">
									<Text color="muted">
										Please update following details if necessary
									</Text>
									<TextInputField
										label="Addressing Name"
										name="address_name"
										type="text"
										required
									/>
									<TextInputField
										label="Addressing Email"
										name="address_email"
										type="email"
										required
									/>
									<SubmissionError />
								</Stack>
							</Dialog.Body>
							<Dialog.Footer>
								<Button type="submit" disabled={submitting}>
									<Icons.Mail />{" "}
									{submitting ? "Resending... " : "Resend Invitation"}
								</Button>
								<Button
									disabled={submitting}
									onClick={() => close()}
									level="tertiary"
								>
									Cancel
								</Button>
							</Dialog.Footer>
						</form>
					)}
				</Form>
			</Dialog>
		</>
	)
}

export function SelectTenants(props: Omit<AsyncSelectProps, "fetch">) {
	const xhr = useXHR()
	return (
		<AsyncSelect
			multiple
			cacheKey="tenants"
			fetch={(q) =>
				TenantsXHR(xhr)
					.getTenants({ q })
					.then((resp) => resp.data)
			}
			{...props}
		/>
	)
}

const disablingReasons = [
	"Overdue Subscription Payment",
	"Not Interested",
	"Price issues",
	"Missing features issue",
]

const disableTenantValidationSchema = Validator.object().shape({
	reason: Validator.string().required(
		"Please provide a reason of disabling the account"
	),
})

export function DisableTenant({
	tenant,
	onChange,
	children = ({ disable }) => (
		<Button onClick={disable} status="warning">
			<Icons.Ban /> Disable Access
		</Button>
	),
}: {
	tenant: ITenant
	onChange: () => void
	children?: (props: { disable: () => void }) => React.ReactNode
}) {
	const { is_disabled } = tenant
	const xhr = useXHR()
	const initialValues = useMemo(() => {
		return {
			reason: "",
		}
	}, [])
	const [isOpen, open, close] = useDialog()
	if (is_disabled) return null
	return (
		<>
			{children({ disable: () => open() })}
			<Dialog
				open={isOpen}
				onClose={close}
				title={`Disable ${tenant.name}'s Access`}
				sm
			>
				<Form<typeof initialValues>
					initialValues={initialValues}
					validate={validateFormValues(disableTenantValidationSchema)}
					onSubmit={withServerErrors(async (values) => {
						if (
							window.confirm(
								`Are you sure you want to disable account access for "${tenant.name}" and it's users`
							)
						) {
							const { data } = await xhr.post(
								`/disabled-tenants/${tenant.uid}`,
								values
							)
							showSnackbar(data.message || `Account disable for ${tenant.name}`)
							onChange()
							close()
						}
					})}
					subscription={{ submitting: true }}
				>
					{({ submitting, handleSubmit }) => (
						<form noValidate onSubmit={handleSubmit}>
							<Dialog.Body>
								<Stack gap="2">
									<Text color="muted">
										Please verify the details before disabling
									</Text>
									<Box
										padding="4"
										rounded="lg"
										borderWidth="1"
										borderColor="warning"
										marginBottom="4"
									>
										<Stack gap="1">
											<Box fontSize="sm">Name</Box>
											<Heading fontSize="md">{tenant.name}</Heading>
											<Text fontSize="sm" color="muted">
												{tenant.short_name}
											</Text>
										</Stack>
									</Box>
								</Stack>
								<Divider sm />
								<SelectInputField
									name="reason"
									label="Reason of disabling"
									required
								>
									<option key="Select a reason" value="">
										Select a reason...
									</option>
									{disablingReasons.map((reason) => (
										<option key={reason} value={reason}>
											{reason}
										</option>
									))}
								</SelectInputField>
							</Dialog.Body>
							<Dialog.Footer>
								<Stack gap="4">
									<SubmissionError />
									<Alert status="warning">
										Once disabled, users from this tenant will not be able to
										access their account.
									</Alert>
									<Inline gap="4">
										<Button
											type="submit"
											status="warning"
											disabled={submitting}
										>
											<Icons.Ban /> I understand. disable it
										</Button>
										<Button
											level="tertiary"
											disabled={submitting}
											onClick={() => close()}
										>
											Cancel
										</Button>
									</Inline>
								</Stack>
							</Dialog.Footer>
						</form>
					)}
				</Form>
			</Dialog>
		</>
	)
}

function Filters() {
	return (
		<Stack>
			<SelectField
				label="Supported By Member"
				select={SelectUsers}
				name="supported_by_users"
				multiple
				fetchOnMount
			/>
		</Stack>
	)
}
