import { TCurrencyPair, TFormattedMoney } from "@sembark-travel/money"
import { IActivityLog } from "../../ActivityLogs"
import { IPermission, IRole } from "../../Roles"
import { IUser } from "./../../Users"
import { TCurrency } from "@sembark-travel/money"
import {
	ICursorListResponse,
	IListResponse,
	useXHR,
	XHRInstance,
} from "@sembark-travel/xhr"
import useSWR from "swr"
import { dateToUTCString, endOf, startOf } from "@sembark-travel/datetime-utils"

export type TFeatureFlags = {
	quote_as_email?: boolean
	share_quote_pdf?: boolean
	share_quote_word_doc?: boolean
	trip_voucher?: boolean
	attach_services_to_destinations?: boolean
	hold_trips?: boolean
	travel_activities?: boolean
	advanced_hotel_selection_during_quote?: boolean
	add_quote_cab_prices_at_once?: boolean
	quote_partner_sharing?: boolean
	fb_app_integration?: boolean
	push_notifications?: boolean
	multi_branding?: boolean
	multi_currency?: boolean
	multi_timezone?: boolean
}

export interface ITenant {
	id: number
	uid: string
	name: string
	short_name?: string
	description: string
	address_name: string
	address_email: string
	invited_at?: string
	signup_at?: string
	users?: Array<IUser>
	owners?: Array<Pick<IUser, "id" | "uid" | "name" | "email">>
	/**
	 * Identity Name/Business ID of the tenant.
	 * Only available for signed up tenants
	 */
	identity_name?: string
	last_activity?: Pick<IActivityLog, "description" | "created_at">
	users_count?: number
	permissions?: Array<IPermission>
	roles?: Array<IRole>
	is_disabled?: boolean
	disabled_at?: string
	disabled_by?: IUser
	disabled_reason?: string
	is_converted?: boolean
	is_in_trial?: boolean
	deleted_at?: string
	deleted_by?: IUser
	trial_ends_at?: string
	is_trial_expired?: boolean
	converted_at?: string
	conversion_detail?: TConversionDetail
	conversion_details?: Array<TConversionDetail>
	profile_image_url?: string
	is_open_signup: boolean
	feature_flags?: TFeatureFlags
	configuration?: null | Record<string, string | undefined | null | number>
	gstin?: string | null
	functional_currency: string
	currency_pairs?: Array<TCurrencyPair>
	all_currencies: Array<TCurrency>
	timezone: string
	/**
	 * Diff(timezone - utc) in minutes
	 * @example 330 For timezone as 'Asia/Kolkata'
	 */
	timezone_offset: number
	timezone_offset_string: string
	conversion_status?: "converted" | "in_trial" | "on_hold"
	on_hold_at?: string
	on_hold_by?: IUser
	/**
	 * List of users how provide support to it
	 */
	supported_by_users?: Array<IUser>
	active_users_today?: number
}

export type TConversionDetail = {
	id: number
	created_by: IUser
	created_at: string
	currency: string
	selected_plan: string
	billing_type: string
	monthly_payment_date: number
	minimum_amount: TFormattedMoney
	base_amount?: TFormattedMoney
	base_users?: number
	amount_per_unit: TFormattedMoney
	users_per_unit: number
	valid_from_date: string
	valid_till_date?: string
	valid_till_users?: number
	comments?: string
	deleted_by?: IUser
	deleted_at?: string
	is_valid_today: boolean
}

type TTenantConfig = {
	name: string
	key: string
	value: string // one of the id from the options
	default: string
	options: Array<{
		id: string
		name: string
		deprecated?: boolean
	}>
}

export function useTenant(tenantId?: string, shouldFetch = false) {
	const xhr = useXHR()
	const {
		data,
		error,
		mutate: revalidate,
	} = useSWR(shouldFetch && tenantId ? `/api/tenants/${tenantId}` : null, () =>
		tenantId
			? TenantsXHR(xhr).getTenant(tenantId, {
					include:
						"users_count,owners,users,supported_by_users,active_users_today",
					today: [
						dateToUTCString(startOf(new Date(), "day")),
						dateToUTCString(endOf(new Date(), "day")),
					],
				})
			: Promise.reject({ message: "Please provide a tenant" })
	)
	return {
		tenant: data,
		isFetching: !data && !error,
		error,
		refresh: revalidate,
	}
}

export function TenantsXHR(xhr: XHRInstance) {
	return {
		async getTenants(params?: unknown): Promise<IListResponse<ITenant>> {
			return xhr.get("/tenants", { params }).then((resp) => resp.data)
		},
		async getTenant(id: string, params?: unknown): Promise<ITenant> {
			return xhr.get(`/tenants/${id}`, { params }).then(({ data }) => data.data)
		},
		async getTenantConfiguration(id: string): Promise<Array<TTenantConfig>> {
			return xhr
				.get(`/tenants/${id}/configuration`)
				.then(({ data }) => data.data)
		},
		async getTenantUsageStatus(id: number, params?: unknown) {
			return xhr
				.get<{
					data: {
						id: number
						name: string
						enquiries: number
						quotes: number
						conversions: number
						revenue: number
					}
				}>(`/tenants/usage-stats/${id}`, { params })
				.then(({ data }) => data.data)
		},
		async getSubscriptionActiveUsers(
			uid: string,
			params?: unknown
		): Promise<{
			active_users: Array<{
				id: number
				name: string
				signup_at?: string
				last_activity_at?: string
				new_signup: 0 | 1
				new_deleted: 0 | 1
				is_included: 0 | 1
				deleted_at?: string
			}>
			total_new_signups: number
			total_active_users: number
			bill_amount?: {
				amount: number
				currency: "INR" | "USD"
				formatted: string
			}
			conversion_detail?: TConversionDetail
		}> {
			return xhr
				.get(`/subscription-active-users/${uid}`, { params })
				.then((resp) => resp.data.data)
		},
		async getLoginActivities(
			uid: string,
			params?: unknown
		): Promise<{
			logs: Array<{
				causer_id: number
				causer_name: string
				created_at: string
				id: number
			}>
			users: Array<{
				id: number
				name: string
				deleted_at?: string
				signup_at?: string
			}>
		}> {
			return xhr
				.get(`/tenant-login-activities/${uid}`, { params })
				.then((resp) => resp.data.data)
		},

		async getSubscriptionDetails(
			params?: unknown
		): Promise<ICursorListResponse<TConversionDetail>> {
			return xhr
				.get(`/tenant-conversion-details`, { params })
				.then((resp) => resp.data)
		},
	}
}
