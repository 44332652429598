import {
	dateToUTCString,
	endOf,
	formatDate,
	startOf,
} from "@sembark-travel/datetime-utils"
import { Box, Button, Inline, Stack, Icons } from "@sembark-travel/ui/base"
import {
	Card,
	CardBody,
	CardFooter,
	Col,
	Divider,
	Grid,
} from "@sembark-travel/ui/base"
import { useXHR, XHRInstance } from "@sembark-travel/xhr"
import {
	arrayMutators,
	FieldArray,
	Form,
	SubmissionError,
	validateFormValues,
	withServerErrors,
	SelectField,
	DatePickerField,
	TextInputField,
	EmptyNumberValidator,
	GetFieldValue,
} from "@sembark-travel/ui/form"
import * as Validator from "yup"
import { SelectCabTypes, ICabType } from "./../CabTypes"
import { ILocation } from "./../Locations"
import {
	SelectTransportServices as SelectServices,
	ITransportService,
} from "./../TransportServices"
import { ITransportServicePrice } from "./store"
import {
	SelectCabPriceCalculationMetric,
	TCabPriceCalculationMetric,
} from "../CabPriceCalculationMetrics"
import { useState } from "react"
import {
	SelectTenantCurrencyInputField,
	useFunctionalCurrencyOfTenant,
} from "../Currencies"
import { Required } from "utility-types"

function XHR(xhr: XHRInstance) {
	return {
		async storePrice(data: unknown): Promise<ITransportServicePrice> {
			return xhr.post("/cab-prices", data).then((resp) => resp.data.cab_price)
		},
	}
}

const validationSchema = Validator.object().shape({
	prices: Validator.array().of(
		Validator.object().shape({
			start_date: Validator.date()
				.typeError("Please select a valid date")
				.required("Start date is required"),
			end_date: Validator.date()
				.typeError("Please select a valid date")
				.required("End date is required"),
			cab_type: Validator.object().required("Cab type is required"),
			transport_service: Validator.object().nullable(),
			cab_price_calculation_metric: Validator.object().nullable(),
			cab_locality: Validator.object(),
			currency: Validator.mixed().required("Please select a currency"),
			price: EmptyNumberValidator(),
		})
	),
})

interface AddPriceCredentials {
	prices?: Array<{
		start_date?: Date
		end_date?: Date
		cab_type?: ICabType
		transport_service?: ITransportService
		cab_price_calculation_metric?: TCabPriceCalculationMetric
		cab_locality?: ILocation
		price?: number
		currency: string
	}>
}

export function AddPriceForm({
	onSuccess,
	onCancel,
}: {
	onSuccess: () => void
	onCancel?: () => void
}) {
	const xhr = useXHR()
	const addingForTransportServices = false
	const functionalCurrency = useFunctionalCurrencyOfTenant()
	const [initialValues] = useState<Required<AddPriceCredentials, "prices">>(
		() => ({
			prices: [
				{
					start_date: undefined,
					end_date: undefined,
					cab_type: undefined,
					transport_service: undefined,
					cab_price_calculation_metric: undefined,
					cab_locality: undefined,
					currency: functionalCurrency,
					price: undefined,
				},
			],
		})
	)
	return (
		<Form<AddPriceCredentials>
			initialValues={initialValues}
			validate={validateFormValues(validationSchema)}
			onSubmit={withServerErrors(async (values) => {
				const payload: Array<{
					start_date: string
					start_date_local: string
					end_date: string
					end_date_local: string
					cab_locality?: string
					cab_type_id: number
					transport_service_id?: number
					calculation_metric_id?: number
					currency: string
					price: number
				}> = []
				values.prices?.forEach((values) => {
					const {
						cab_type,
						transport_service,
						cab_price_calculation_metric,
						cab_locality,
						start_date,
						end_date,
						currency,
						price,
					} = values
					if (
						start_date &&
						end_date &&
						cab_type &&
						price &&
						currency &&
						(transport_service || cab_price_calculation_metric)
					) {
						payload.push({
							start_date: dateToUTCString(startOf(start_date, "day")),
							start_date_local: formatDate(
								startOf(start_date, "day"),
								"YYYY-MM-DD"
							),
							end_date: dateToUTCString(endOf(end_date, "day")),
							end_date_local: formatDate(endOf(end_date, "day"), "YYYY-MM-DD"),
							cab_type_id: cab_type.id,
							transport_service_id: transport_service?.id,
							calculation_metric_id: cab_price_calculation_metric?.id,
							cab_locality: cab_locality && cab_locality.name,
							currency,
							price,
						})
					}
				})
				await XHR(xhr).storePrice({ prices: payload })
				onSuccess()
			})}
			subscription={{ submitting: true }}
			mutators={{ ...arrayMutators }}
		>
			{({ submitting, handleSubmit }) => (
				<form noValidate onSubmit={handleSubmit}>
					<Card style={{ minInlineSize: "auto" }}>
						<CardBody>
							<FieldArray<
								Required<AddPriceCredentials, "prices">["prices"][number]
							> name="prices">
								{({ fields }) => (
									<Box as="ol" className="list">
										{fields.map((name, index) => (
											<Box as="li" key={name}>
												<Stack gap="4">
													<Grid gap="4">
														<Col>
															<DatePickerField
																label="Start Date"
																name={`${name}.start_date`}
																required
															/>
														</Col>
														<Col>
															<DatePickerField
																label="End Date"
																name={`${name}.end_date`}
																required
															/>
														</Col>
														<Col>
															<SelectField
																select={SelectCabTypes}
																name={`${name}.cab_type`}
																label="Cab Type"
																multiple={false}
																required
																fetchOnMount
															/>
														</Col>
														{addingForTransportServices ? (
															<Col>
																<SelectField
																	select={SelectServices}
																	name={`${name}.transport_service`}
																	label="Transport Service"
																	multiple={false}
																	required
																	creatable
																/>
															</Col>
														) : (
															<Col>
																<SelectField
																	select={SelectCabPriceCalculationMetric}
																	name={`${name}.cab_price_calculation_metric`}
																	label="Calculation Metric"
																	multiple={false}
																	required
																	creatable
																/>
															</Col>
														)}
														<Col>
															<Inline gap="1">
																<SelectTenantCurrencyInputField
																	name={`${name}.currency`}
																	label="Currency"
																	required
																/>
																<TextInputField
																	label="Price/Qnty."
																	name={`${name}.price`}
																	type="number"
																	min={0}
																	placeholder="e.g. 20"
																/>
															</Inline>
														</Col>
													</Grid>
													<Inline gap="4">
														<GetFieldValue<
															Required<
																AddPriceCredentials,
																"prices"
															>["prices"][number]
														>
															name={name}
														>
															{({ value }) => (
																<Button
																	size="sm"
																	onClick={() =>
																		fields.push({
																			...value,
																			price: undefined as never,
																		})
																	}
																>
																	<Icons.Duplicate /> Duplicate
																</Button>
															)}
														</GetFieldValue>
														{Number(fields.length) > 1 ? (
															<Button
																size="sm"
																onClick={() => fields.remove(index)}
																level="tertiary"
															>
																<Icons.Cancel /> Remove
															</Button>
														) : null}
													</Inline>
												</Stack>
											</Box>
										))}
										<Divider />
										<Box marginBottom="4">
											<Button
												status="primary"
												onClick={() => fields.push(initialValues.prices[0])}
											>
												+ Add More Prices
											</Button>
										</Box>
									</Box>
								)}
							</FieldArray>
						</CardBody>
						<CardFooter>
							<Stack gap="4">
								<SubmissionError />
								<Inline gap="4">
									<Button type="submit" disabled={submitting} size="lg">
										{submitting ? "Please wait..." : "Save Prices"}
									</Button>
									{onCancel ? (
										<Button onClick={onCancel} level="tertiary" size="lg">
											Cancel
										</Button>
									) : null}
								</Inline>
							</Stack>
						</CardFooter>
					</Card>
				</form>
			)}
		</Form>
	)
}
