import {
	Alert,
	Box,
	Button,
	Icons,
	Table,
	Tooltip,
	Inline,
	Heading,
	Stack,
	Text,
	Container,
	Col,
	Divider,
	Grid,
	Time,
} from "@sembark-travel/ui/base"
import { utcTimestampToLocalDate } from "@sembark-travel/datetime-utils"
import { numberToLocalString } from "@sembark-travel/number-utils"
import { useXHR, XHRInstance } from "@sembark-travel/xhr"
import React, { useMemo, useReducer, useRef } from "react"
import { $PropertyType, Optional } from "utility-types"
import * as Validator from "yup"
import config from "../config"
import { IInstalment } from "../Payments"
import { SelectTrips } from "../Trips/List"
import { ITrip } from "../Trips"
import { SelectAccount } from "./Accounts"
import { IAccount } from "./store"
import {
	validateFormValues,
	withServerErrors,
	Form,
	FileInputField,
	SelectField,
	SubmissionError,
} from "@sembark-travel/ui/form"

function XHR(xhr: XHRInstance) {
	return {
		async uploadFile(
			data: unknown
		): Promise<{ data: TParsedResponse; suspense_account: IAccount }> {
			return xhr
				.post("/accounting/upload-transactions", data)
				.then((resp) => resp.data)
		},
		async saveAccountStatement(data: unknown): Promise<unknown> {
			return xhr
				.post("/accounting/bank-account-statements", data)
				.then((resp) => resp.data)
		},
	}
}

type TRow = {
	id: number
	date: string
	parsed_date: string
	debit_account: string
	credit_account: string
	amount: number
	reference_id?: string
	narration?: string
	debit_amount?: number
	credit_amount?: number
}

type TParsedResponse = Array<{
	row: TRow
	mapped: {
		id: number
		date: string
		debit_account?: IAccount
		credit_account?: IAccount
		amount: number
		trip?: ITrip
		reference_id?: string
		narration?: string
		instalment?: IInstalment
	}
}>

type TValidData = Array<{
	row: TRow
	mapped: {
		id: number
		date: string
		debit_account: IAccount
		credit_account: IAccount
		amount: number
		reference_id?: string
		narration: string
		trip?: ITrip
		instalment?: IInstalment
	}
}>

type TSuspenseData = Array<{
	row: TRow
	mapped: {
		id: number
		date: string
		debit_account?: IAccount
		credit_account?: IAccount
		amount: number
		reference_id?: string
		narration: string
		trip?: ITrip
		instalment?: IInstalment
	}
}>

type TState = {
	step: number
	parsedData: TParsedResponse
	validData: TValidData
	suspenseData: TSuspenseData
	isLogged: boolean
	savedSuspense: boolean
	associatedAccount?: IAccount
	suspenseAccount?: IAccount
}

const INITIAL_STATE: TState = {
	step: 1,
	parsedData: [],
	validData: [],
	suspenseData: [],
	isLogged: false,
	savedSuspense: false,
	associatedAccount: undefined,
	suspenseAccount: undefined,
}

export function UploadTransactionsCsv({
	onCancel,
	onSuccess,
	account,
}: {
	onCancel?: () => void
	onSuccess: () => void
	account?: IAccount
}) {
	const xhr = useXHR()
	const [
		{
			step,
			parsedData,
			validData,
			suspenseData,
			isLogged,
			savedSuspense,
			associatedAccount,
			suspenseAccount,
		},
		dispatch,
	] = useReducer<
		React.Reducer<
			TState,
			| {
					type: "FILE_UPLOADED"
					payload: {
						parsedData: TParsedResponse
						associatedAccount: IAccount
						suspenseAccount: IAccount
					}
			  }
			| {
					type: "ATTRIBUTES_MAPPED"
					payload: TParsedResponse
			  }
			| {
					type: "REMAP_ATTRIBUTES"
			  }
			| {
					type: "EDIT_FILE"
			  }
			| {
					type: "LOGGED_TRANSACTIONS"
			  }
		>
	>((state, action) => {
		switch (action.type) {
			case "FILE_UPLOADED":
				return {
					...state,
					step: 2,
					parsedData: action.payload.parsedData,
					associatedAccount: action.payload.associatedAccount,
					suspenseAccount: action.payload.suspenseAccount,
					isLogged: false,
				}
			case "ATTRIBUTES_MAPPED":
				return {
					...state,
					isLogged: false,
					step: 3,
					parsedData: action.payload,
					// now cache the valid and suspense data
					validData: action.payload.filter(
						(p) => !isSuspenseTransaction(p.mapped, state.suspenseAccount)
					) as TValidData,
					suspenseData: action.payload.filter(({ mapped }) =>
						isSuspenseTransaction(mapped, state.suspenseAccount)
					) as TSuspenseData,
				}
			case "LOGGED_TRANSACTIONS":
				return {
					...state,
					isLogged: true,
					// we don't remove valid data from the UI (state.validData)
					// remove the valid data from parsed results
					parsedData: state.parsedData.filter(
						(d) => !!isSuspenseTransaction(d.mapped, state.suspenseAccount)
					),
				}
			case "REMAP_ATTRIBUTES":
				return {
					...state,
					step: 2,
				}
			case "EDIT_FILE":
				return INITIAL_STATE
			default:
				return state
		}
	}, INITIAL_STATE)
	return (
		<Box marginBottom="16">
			<Box as="header" paddingY="4" borderBottomWidth="2">
				<Inline>
					<Box paddingX="4" flex="1">
						<Step on={1} active={step} total={3}>
							Upload csv file and attach accounts.
						</Step>
					</Box>
					<Box paddingX="4" flex="1">
						<Step on={2} active={step} total={3}>
							Map accounts and associate attributes
						</Step>
					</Box>
					<Box paddingX="4" flex="1">
						<Step on={3} active={step} total={3}>
							Preview and Submit
						</Step>
					</Box>
				</Inline>
			</Box>
			{step === 1 ? (
				<UploadTransactionsCsvForm
					onSubmit={async (data, account) => {
						const resp = await XHR(xhr).uploadFile(data)
						dispatch({
							type: "FILE_UPLOADED",
							payload: {
								parsedData: resp.data,
								associatedAccount: account,
								suspenseAccount: resp.suspense_account,
							},
						})
					}}
					onCancel={onCancel}
					account={account}
				/>
			) : !associatedAccount || !suspenseAccount ? (
				<Container paddingY="6">
					<Alert
						status="error"
						title="Please select the account associate with the account statement"
					></Alert>
				</Container>
			) : step === 2 ? (
				<Container paddingY="6">
					<Stack gap="4">
						<Heading>Map accounts and Associate Attributes</Heading>
						<Text color="muted" maxWidth="4xl">
							Please map debit and credit accounts with the system accounts.
							Entries with a debit account, credit account, and narration will
							be <b>valid</b> for logging. Entries without a debit or credit
							account will be moved logged to suspense account.
						</Text>
						<Text fontSize="md" fontWeight="semibold">
							<Text as="span" color="muted">
								<Icons.Info size="6" /> Selected Company Account:
							</Text>{" "}
							{associatedAccount.name}
						</Text>
						{!parsedData.length ? (
							<Alert
								status="warning"
								type="assertive"
								title="No entries to map"
							>
								<Stack gap="2">
									<Box>
										The result set is empty. Please upload a new valid CSV file.
									</Box>
									<Box>
										<Inline gap="4">
											<Button
												level="primary"
												onClick={() => dispatch({ type: "EDIT_FILE" })}
											>
												<Icons.ChevronDown rotate="90" /> Upload Again
											</Button>
											<Button onClick={onSuccess}>Goto Listing</Button>
										</Inline>
									</Box>
								</Stack>
							</Alert>
						) : (
							<AccountingAttributesMappingForm
								initialValues={{ data: parsedData }}
								onSubmit={async (data) => {
									dispatch({ type: "ATTRIBUTES_MAPPED", payload: data })
								}}
								onReupload={() => {
									dispatch({ type: "EDIT_FILE" })
								}}
								associatedAccount={associatedAccount}
								suspenseAccount={suspenseAccount}
								onCancel={onCancel}
							/>
						)}
					</Stack>
				</Container>
			) : step === 3 ? (
				<Container paddingY="6">
					<Box>
						<Box marginBottom="16">
							{parsedData.length ? (
								<LogEntriesForm
									associatedAccount={associatedAccount}
									entries={parsedData}
									onBack={() => dispatch({ type: "REMAP_ATTRIBUTES" })}
									onUploadAgain={() => dispatch({ type: "EDIT_FILE" })}
									onCancel={onCancel}
									suspenseAccount={suspenseAccount}
									isLogged={isLogged}
									onSubmit={async ({
										validEntries,
										suspenseEntries,
										invalidEntries,
									}) => {
										await XHR(xhr).saveAccountStatement({
											mapped: validEntries
												.concat(suspenseEntries)
												.map(
													({
														mapped: {
															date,
															debit_account,
															credit_account,
															amount,
															trip,
															reference_id,
															narration,
															instalment,
														},
														row,
													}) => ({
														transaction: {
															paid_at: date,
															debit_account_id: debit_account.id,
															credit_account_id: credit_account.id,
															amount,
															trip_id: trip?.id,
															narration,
															reference_id,
															instalment_id: instalment?.id,
														},
														row: {
															...row,
															date: row.parsed_date,
														},
													})
												),
											unmapped: invalidEntries.map((e) => ({
												...e.row,
												date: e.row.parsed_date,
											})),
											associated_account_id: associatedAccount.id,
										})
										dispatch({ type: "LOGGED_TRANSACTIONS" })
									}}
								/>
							) : (
								<Box>
									<Alert status="warning" title="No valid entries">
										Sorry! There were no valid entries in the uploaded file. If
										this is unexpected or there is an error, please contact our
										support.
									</Alert>
								</Box>
							)}
						</Box>
						<Divider />
						<Button
							onClick={onSuccess}
							level="primary"
							status={
								(isLogged && savedSuspense) ||
								(isLogged && !suspenseData.length) ||
								(savedSuspense && !validData.length)
									? "primary"
									: undefined
							}
						>
							Goto Listing
						</Button>
					</Box>
				</Container>
			) : (
				<Box>Invalid step: [{step}]</Box>
			)}
		</Box>
	)
}

function Step({
	on,
	active,
	total,
	children,
}: {
	on: number
	active: number
	total: number
	children: React.ReactNode
}) {
	const completed = active > on
	const isThisStateActive = active === on
	return (
		<Box
			padding="4"
			backgroundColor={
				isThisStateActive ? "default" : completed ? "success" : undefined
			}
			borderWidth="1"
			boxShadow={isThisStateActive ? "lg" : undefined}
			borderColor={completed ? "success" : "transparent"}
			rounded="lg"
		>
			<Inline gap="2">
				{completed ? <Icons.OkCircleSolid size="6" color="success" /> : null}
				<Stack gap="1">
					<Heading as="h3">
						Step {on}{" "}
						<Text as="span" fontSize="base" color="muted">
							of {total}
						</Text>
					</Heading>
					<Text>{children}</Text>
				</Stack>
			</Inline>
		</Box>
	)
}

interface IUploadTransactionsCsvParams {
	file: Blob | string
	associated_account: IAccount
	timezone_offset: number
}

const uploadTransactionsCsvValidationSchema = Validator.object()
	.required()
	.shape({
		file: Validator.mixed().required(
			"Please provide a file containing the transactions"
		),
		associated_account: Validator.object().required(
			"Please select the account for which you are uploading the statement"
		),
		timezone_offset: Validator.number().required(
			"Please provide your timezone offset from UTC"
		),
	})

function UploadTransactionsCsvForm({
	onSubmit,
	onCancel,
	account,
}: {
	onSubmit: (data: FormData, account: IAccount) => Promise<unknown>
	onCancel?: () => void
	account?: IAccount
}) {
	const initialValues: Optional<IUploadTransactionsCsvParams> = useMemo(() => {
		return {
			file: undefined,
			associated_account: account || undefined,
			timezone_offset: config.timezoneOffset,
		}
	}, [account])
	return (
		<>
			<Form<IUploadTransactionsCsvParams>
				initialValues={initialValues}
				validate={validateFormValues(uploadTransactionsCsvValidationSchema)}
				onSubmit={withServerErrors(
					async ({ file, timezone_offset, associated_account }) => {
						if (!file) {
							throw Error("Please select a CSV file")
						}
						if (!associated_account) {
							throw Error(
								"Please select the account for which you are uploading the account statement"
							)
						}
						const data = new FormData()
						data.set("file", file)
						data.set("timezone_offset", String(timezone_offset))
						data.set("associated_account_id", String(associated_account.id))
						return await onSubmit(data, associated_account)
					}
				)}
				subscription={{ submitting: true }}
			>
				{({ submitting, handleSubmit }) => (
					<form noValidate onSubmit={handleSubmit}>
						<Container paddingY="6" bgColor="default">
							<Grid marginBottom="12" gap="4">
								<Col sm={12} md={4}>
									<Stack gap="1">
										<Heading as="h3">Transactions CSV File</Heading>
										<Text color="muted">
											Please select the CSV file containing the transactions
											(e.g. accounts statement).
										</Text>
									</Stack>
								</Col>
								<Col>
									<FileInputField
										name="file"
										accept=".csv"
										required
										help={
											<Box>
												Please see the{" "}
												<Box
													as="span"
													fontWeight="semibold"
													textDecoration="underline"
												>
													<a href="#csv_format">csv file format</a>
												</Box>{" "}
												for reference.
											</Box>
										}
									/>
								</Col>
							</Grid>
							<Grid marginBottom="12" gap="4">
								<Col sm={12} md={4}>
									<Stack gap="1">
										<Heading as="h3">Associated Account</Heading>
										<Text>
											Associated account e.g. Owner Account for Account
											Statements. It will set as debit account for debit entries
											(-ve) and credit account for credit entries (+ve).
										</Text>
									</Stack>
								</Col>
								<Col>
									<SelectField
										label="Select Company Account"
										select={SelectAccount}
										multiple={false}
										name="associated_account"
										params={{
											entity_type: "tenants",
										}}
										fetchOnMount
									/>
								</Col>
							</Grid>
							<Divider sm />
							<Grid>
								<Col sm={12} md={{ offset: 4, span: 8 }}>
									<Stack gap="4">
										<SubmissionError />
										<Inline gap="4">
											<Button disabled={submitting} type="submit" size="lg">
												Next: Preview Accounts{" "}
												<Icons.ChevronDown rotate="270" />
											</Button>
											{onCancel ? (
												<Button
													onClick={onCancel}
													disabled={submitting}
													level="tertiary"
													size="lg"
												>
													Cancel
												</Button>
											) : null}
										</Inline>
									</Stack>
								</Col>
							</Grid>
						</Container>
					</form>
				)}
			</Form>
			<Container paddingY="8">
				<Stack gap="4">
					<Heading as="h4" id="csv_format">
						CSV File Format
					</Heading>
					<Text maxWidth="4xl">
						<Text>
							When creating your CSV file for hotels, please follow this format
							only. You can find more information about each column by hovering
							over the words with{" "}
							<b>
								<abbr title="You will get information like this. Please read each information carefully for best and accurate results.">
									dotted underlines
								</abbr>
							</b>
							. If you are unsure about what/why a column is, please feel free
							to contact our support team.
						</Text>
					</Text>
					<Table
						aria-label="CSV file format for account statement"
						responsive
						className="excel-style-table"
					>
						<Box as="thead" whiteSpace="preserve">
							<tr>
								<th>
									<abbr title="The date of transaction in 'YYYY-MM-DD' or 'MM/DD/YYYY' format">
										Date
									</abbr>
								</th>
								<th>
									<abbr title="Description/Narration of the transaction">
										Description
									</abbr>
								</th>
								<th>
									<abbr title="Debit amount in the transaction. Leave empty in case of credit entries.">
										Debit
									</abbr>
								</th>
								<th>
									<abbr title="Credit amount in the transaction. Leave empty in case of debit entries.">
										Credit
									</abbr>
								</th>
								<th>
									<abbr title="Reference ID/Number for this transaction">
										Ref
									</abbr>
								</th>
							</tr>
						</Box>
						<tbody>
							<tr>
								<td>
									<abbr title="12 November, 2020">2020-11-12</abbr>
								</td>
								<td>
									<abbr title="Description/Narration for this transaction">
										HOTEL_MONTLY_CLEARANCE 12/20
									</abbr>
								</td>
								<td>
									<abbr title="This is a debit entry">50000</abbr>
								</td>
								<td></td>
								<td>1231231231</td>
							</tr>
							<tr>
								<td>
									<abbr title="10 September, 2020. You can provide date in this format ('DD/MM/YYYY') well">
										10/09/2020
									</abbr>
								</td>
								<td>ACCOUNT_PAYMENT_123</td>
								<td></td>
								<td>
									<abbr title="This is a credit entry">23000</abbr>
								</td>
								<td>REF123131ASF1</td>
							</tr>
						</tbody>
					</Table>
					<Stack gap="4">
						<Text maxWidth="4xl">
							<b>Note:</b> Some of these column names (date, description etc.)
							will be different for different banks. For this reason, following
							mappings have been provided. All the column below a column will
							map to the heading column. You do not need to update your account
							statement if the column names are matching.
						</Text>
						<Table
							aria-label="CSV file format for account statement"
							responsive
							className="excel-style-table"
						>
							<Box as="thead" whiteSpace="preserve">
								<tr>
									<th>Date</th>
									<th>Description</th>
									<th>Debit</th>
									<th>Credit</th>
									<th>Ref</th>
								</tr>
							</Box>
							<tbody>
								<tr>
									<td rowSpan={2}>Txn Date</td>
									<td rowSpan={2}>Narration</td>
									<td rowSpan={2}>Withdrawal Amt.</td>
									<td rowSpan={2}>Deposit Amt.</td>
									<td>Ref No./Cheque No.</td>
								</tr>
								<tr>
									<td>Chq./Ref.No.</td>
								</tr>
							</tbody>
						</Table>
						<Box>
							<Text maxWidth="4xl" fontSize="md">
								If your csv file contains <b>Txn Date</b> column, it will be
								mapped to <b>Date</b>.
							</Text>
						</Box>
					</Stack>
				</Stack>
			</Container>
		</>
	)
}

interface IAccountingAttributesMappingParams {
	data: TParsedResponse
}

function AccountingAttributesMappingForm({
	onSubmit,
	onCancel,
	initialValues: initialValuesProps,
	onReupload,
	associatedAccount,
	suspenseAccount,
}: {
	onSubmit: (data: TParsedResponse) => Promise<unknown>
	onCancel?: () => void
	initialValues?: IAccountingAttributesMappingParams
	onReupload: () => void
	associatedAccount: IAccount
	suspenseAccount: IAccount
}) {
	const initialValues: IAccountingAttributesMappingParams = useRef(
		initialValuesProps || {
			data: [],
		}
	).current
	return (
		<Form<IAccountingAttributesMappingParams>
			initialValues={initialValues}
			onSubmit={withServerErrors(async (values) => {
				return await onSubmit(values.data)
			})}
			subscription={{ submitting: true }}
		>
			{({ submitting, form }) => (
				<form noValidate>
					<Table
						bordered
						hover
						data-testid="mapping-table"
						headers={[
							"#",
							"Date",
							"Narration",
							"Amount",
							"Accounts Mapping",
							"Association",
						]}
						rows={initialValues.data.map(({ row, mapped }, i) => {
							return [
								<Stack>
									<Text>{row.id}</Text>
									{!isLoggableTransaction(mapped) ? (
										<Tooltip
											content="Invalid transaction entry"
											placement="right"
										>
											<Box>
												<Icons.CancelCircleSolid color="danger" size="6" />
											</Box>
										</Tooltip>
									) : isSuspenseTransaction(mapped, suspenseAccount) ? (
										<Tooltip content="Suspense entry" placement="right">
											<Box>
												<Icons.InfoSolid color="warning" size="6" />
											</Box>
										</Tooltip>
									) : null}
								</Stack>,
								<Stack gap="2">
									<Text>{row.date}</Text>
									{row.reference_id ? (
										<Text fontSize="xs" title="Reference Id" color="muted">
											{row.reference_id}
										</Text>
									) : null}
								</Stack>,
								<Box>
									<Text maxWidth="sm" wordBreak="words">
										{row.narration}
									</Text>
								</Box>,
								<Box>
									<Tooltip
										content={
											row.credit_amount
												? `Credit to ${associatedAccount.name}`
												: `Debit from ${associatedAccount.name}`
										}
									>
										<Box color={row.credit_amount ? "success" : "danger"}>
											{row.credit_amount ? "+" : "-"}
											{numberToLocalString(row.amount)}
										</Box>
									</Tooltip>
								</Box>,
								<Box>
									<Grid>
										{initialValues.data[i].row.credit_amount ? (
											<Col>
												<SelectField
													label="Debit Account"
													name={`data[${i}].mapped.debit_account`}
													select={SelectAccount}
													multiple={false}
												/>
											</Col>
										) : null}
										{initialValues.data[i].row.debit_amount ? (
											<Col>
												<SelectField
													label="Credit Account"
													name={`data[${i}].mapped.credit_account`}
													select={SelectAccount}
													multiple={false}
												/>
											</Col>
										) : null}
									</Grid>
								</Box>,
								<Box>
									<SelectField
										select={SelectTrips}
										label="Trip"
										name={`data[${i}].mapped.trip`}
										multiple={false}
										onChange={(value, name) => {
											form.batch(() => {
												form.change(name as never, value as never) // remove the instalment
												form.change(
													`data[${i}].mapped.instalment` as never,
													undefined
												)
											})
										}}
									/>
									{/**
                   // TODO: Uncomment when we have proper management of instalment for trips
                  {mapped.trip ? (
                    <SelectTripInstalment
                      label="Select Instalment to Clear"
                      tripId={mapped.trip.id}
                      name={`data[${i}].mapped.instalment`}
                    />
                  ) : null} */}
								</Box>,
							]
						})}
					/>
					<Stack gap="4">
						<SubmissionError />
						<Inline gap="4">
							<Button disabled={submitting} onClick={onReupload}>
								<Icons.ChevronDown rotate="90" /> Upload Again
							</Button>
							<Button disabled={submitting} type="submit">
								Next: Preview And Submit <Icons.ChevronDown rotate="270" />
							</Button>
							{onCancel ? (
								<Button onClick={onCancel} level="tertiary">
									Cancel
								</Button>
							) : null}
						</Inline>
					</Stack>
				</form>
			)}
		</Form>
	)
}

/**
function SelectTripInstalment({
  tripId,
  ...props
}: Omit<React.ComponentProps<typeof SelectField>, "select"> & {
  tripId: number | string
}) {
  const xhr = useXHR()
  const fetchInstalments = useCallback(
    async (q) => {
      const {
        customer_payments,
        hotel_payments,
      }: {
        customer_payments: Array<IPayment>
        hotel_payments: Array<
          hotelBookingStore.IBooking & {
            payments: Array<IPayment>
          }
        >
        cab_payments: Array<IPayment>
      } = await xhr
        .get(`/trips/${tripId}/payments`, { params: { q } })
        .then((resp) => resp.data)

      let instalments: Array<{ id: number; name: string }> = []
      customer_payments.forEach((payment) => {
        instalments = instalments.concat(
          payment.instalments
            .filter((i) => !i.paid_at)
            .map((i) => ({
              id: i.id,
              name: `Guest-${numberToLocalString(i.amount)}-${fromNow(utcTimestampToLocalDate(
                i.due_at
              ))}`,
            }))
        )
      })
      hotel_payments.forEach((booking) => {
        instalments = instalments.concat(
          ...booking.payments.map((payment) =>
            payment.instalments
              .filter((i) => !i.paid_at)
              .map((i) => ({
                id: i.id,
                name: `${booking.hotel.name}-${numberToLocalString(
                  i.amount
                )}-${fromNow(utcTimestampToLocalDate(i.due_at))}`,
              }))
          )
        )
      })
      return instalments
    },
    [xhr, tripId]
  )
  return (
    <SelectField
      select={AsyncSelect}
      fetchOnMount
      key={tripId}
      {...props}
      fetch={fetchInstalments}
    />
  )
}
**/

function LogEntriesForm({
	entries,
	onSubmit,
	onBack,
	associatedAccount,
	suspenseAccount,
	onCancel,
	onUploadAgain,
	isLogged,
}: {
	entries: TParsedResponse
	onSubmit: (data: {
		invalidEntries: TParsedResponse
		suspenseEntries: TValidData
		validEntries: TValidData
	}) => Promise<unknown>
	onBack: () => void
	onCancel?: () => void
	onUploadAgain: () => void
	isLogged: boolean
	associatedAccount: IAccount
	suspenseAccount: IAccount
}) {
	const { validEntries, suspenseEntries, invalidEntries } = useMemo(() => {
		const data = entries.reduce<{
			invalidEntries: TParsedResponse
			suspenseEntries: TValidData
			validEntries: TValidData
		}>(
			function (data, entry) {
				let {
					mapped: { debit_account, credit_account },
				} = entry
				if (!isLoggableTransaction(entry.mapped)) {
					data.invalidEntries.push(entry)
				} else if (isSuspenseTransaction(entry.mapped, suspenseAccount)) {
					if (!debit_account) {
						debit_account = suspenseAccount
					}
					if (!credit_account) {
						credit_account = suspenseAccount
					}
					data.suspenseEntries.push({
						...entry,
						mapped: { ...entry.mapped, debit_account, credit_account },
					} as never)
				} else {
					data.validEntries.push(entry as never)
				}
				return data
			},
			{ invalidEntries: [], suspenseEntries: [], validEntries: [] }
		)
		return data
	}, [entries, suspenseAccount])
	if (!entries.length) {
		return (
			<Alert title="No Entries to Log" status="warning">
				There are no entries that contains valid data which can be logged.
			</Alert>
		)
	}
	type TValues = {
		validEntries: typeof validEntries
		suspenseEntries: typeof suspenseEntries
		invalidEntries: typeof invalidEntries
	}
	return (
		<Form<TValues>
			initialValues={{ validEntries, suspenseEntries, invalidEntries }}
			onSubmit={withServerErrors(async (values) => {
				return await onSubmit(values)
			})}
			subscription={{ submitting: true, values: true }}
		>
			{({
				submitting,
				values: { validEntries, suspenseEntries },
				handleSubmit,
			}) => (
				<form noValidate onSubmit={handleSubmit}>
					{validEntries.length ? (
						<Box>
							<Stack gap="1">
								<Heading as="h4" color="success">
									<Icons.OkCircleSolid /> Valid Entries ({validEntries.length})
								</Heading>
								<Text>
									These entries are correctly mapped to final their accounts and
									will be logged in <b>{associatedAccount.name}</b> account.
								</Text>
							</Stack>
							<Table
								bordered
								hover
								alignCols={{ 3: "right" }}
								responsive
								headers={[
									"#",
									"Date",
									"Account",
									"Amount",
									"Narration",
									"Ref #",
									"Association",
								]}
								rows={validEntries.map(({ mapped, row }) => {
									return [
										mapped.id,
										<Time value={utcTimestampToLocalDate(mapped.date)} />,
										<Text>
											{row.debit_amount
												? mapped.credit_account.name
												: mapped.debit_account.name}
										</Text>,
										<Text color={row.credit_amount ? "success" : "danger"}>
											{numberToLocalString(mapped.amount)}
										</Text>,
										<Text>{mapped.narration}</Text>,
										mapped.reference_id,
										<Text>
											{mapped.trip ? `Trip: ${mapped.trip.name}` : null}
											{mapped.instalment
												? `, Inst: ${
														(mapped.instalment as unknown as { name: string })
															.name
													}`
												: ""}
										</Text>,
									]
								})}
							/>
						</Box>
					) : null}
					{suspenseEntries.length ? (
						<Box>
							<Stack gap="1">
								<Heading as="h4" color="warning">
									<Icons.AttentionSolid /> Suspense Entries (
									{suspenseEntries.length})
								</Heading>
								<Text>
									These entries will be temporarily allocated between{" "}
									<b>
										{suspenseAccount.name} and {associatedAccount.name}
									</b>{" "}
									accounts and you can later update them to their final
									accounts.
								</Text>
							</Stack>
							<Table
								bordered
								hover
								alignCols={{ 2: "right" }}
								responsive
								headers={[
									"#",
									"Date",
									"Amount",
									"Narration",
									"Ref #",
									"Association",
								]}
								rows={suspenseEntries.map(({ mapped, row }) => {
									return [
										mapped.id,
										<Time value={utcTimestampToLocalDate(mapped.date)} />,
										<Text color={row.credit_amount ? "success" : "danger"}>
											{numberToLocalString(mapped.amount)}
										</Text>,
										<Text>{mapped.narration}</Text>,
										mapped.reference_id,
										<Text>
											{mapped.trip ? `Trip: ${mapped.trip.name}` : null}
											{mapped.instalment
												? `, Inst: ${
														(mapped.instalment as unknown as { name: string })
															.name
													}`
												: ""}
										</Text>,
									]
								})}
							/>
						</Box>
					) : null}
					{invalidEntries.length ? (
						<Box>
							<Stack gap="1">
								<Heading as="h4" color="warning">
									<Icons.CancelCircleSolid /> Invalid Entries (
									{invalidEntries.length})
								</Heading>
								<Text>
									These are invalid entries as they are missing required data
									like debit/credit accounts, amount or narration. This entries
									will be dumped at it is without logging a transaction.
								</Text>
							</Stack>
							<Table
								bordered
								hover
								alignCols={{ 4: "right" }}
								headers={["#", "Date", "Amount", "Ref #", "Narration"]}
								rows={invalidEntries.map(({ row }) => {
									return [
										row.id,
										<Time value={utcTimestampToLocalDate(row.parsed_date)} />,
										<Text color={row.credit_amount ? "success" : "danger"}>
											{numberToLocalString(row.amount)}
										</Text>,
										row.reference_id,
										row.narration,
									]
								})}
							/>
						</Box>
					) : null}
					<Divider sm />
					<Stack gap="4">
						<SubmissionError />
						{!isLogged ? (
							<Inline gap="4">
								<Button onClick={onBack} disabled={submitting}>
									<Icons.ChevronDown rotate="90" /> Edit Mapping
								</Button>
								<Button type="submit" disabled={submitting}>
									{submitting ? (
										<>Saving...</>
									) : (
										<>
											<Icons.Ok /> Save Data
										</>
									)}
								</Button>
								{onCancel ? (
									<Button
										disabled={submitting}
										onClick={onCancel}
										level="tertiary"
									>
										Cancel
									</Button>
								) : null}
							</Inline>
						) : (
							<Box>
								<Alert status="success" title="Data successfully saved">
									<Text>
										All data has been successfully saved. You can "Upload More"
										statements or click on "Done" to go to the transactions
										page.
									</Text>
									<Inline gap="4">
										<Button onClick={onUploadAgain}>
											<Icons.ChevronDown rotate="90" /> Upload More
										</Button>
										{onCancel ? (
											<Button
												disabled={submitting}
												onClick={onCancel}
												level="primary"
											>
												<Icons.Ok /> Done
											</Button>
										) : null}
									</Inline>
								</Alert>
							</Box>
						)}
					</Stack>
				</form>
			)}
		</Form>
	)
}

function isLoggableTransaction({
	debit_account,
	credit_account,
	amount,
	narration,
}: $PropertyType<TParsedResponse[0], "mapped">) {
	return (debit_account || credit_account) && amount && narration
}

function isSuspenseTransaction(
	transaction: $PropertyType<TParsedResponse[0], "mapped">,
	suspenseAccount?: IAccount
) {
	const { debit_account, credit_account } = transaction
	if ((credit_account && !debit_account) || (debit_account && !credit_account))
		return true
	if (!suspenseAccount) return true
	return (
		suspenseAccount.id === debit_account?.id ||
		suspenseAccount.id === credit_account?.id
	)
}
