import { TCurrency, TCurrencyPairsObject } from "@sembark-travel/money"
import { IListResponse, useXHR, XHRInstance } from "@sembark-travel/xhr"
import useSWR from "swr"
import { useAuthUser } from "../Auth"
import { IUser } from "../Users"

export type TCurrencyExchangeRate = {
	id: number
	base_currency: string
	counter_currency: string
	rate: number
	created_at: string
	created_by?: IUser
}

export function currenciesXHR(xhr: XHRInstance) {
	return {
		async getAllAvailableCurrencies(params?: unknown) {
			return xhr
				.get<{ data: Array<{ id: string; name: string; iso: string }> }>(
					`/currencies/all-available`,
					{ params }
				)
				.then((resp) => resp.data)
		},
	}
}

export function currenciesExchangeRatesXHR(xhr: XHRInstance) {
	return {
		async get(params?: unknown) {
			return xhr
				.get<IListResponse<TCurrencyExchangeRate>>(`/currency-exchange-rates`, {
					params,
				})
				.then((resp) => resp.data)
		},
		async store(payload: unknown) {
			return xhr
				.post<{ data: TCurrencyExchangeRate }>(
					`/currency-exchange-rates`,
					payload
				)
				.then((resp) => resp.data)
		},
		async destroy(id: number) {
			return xhr
				.delete<{ data: { message: string } }>(`/currency-exchange-rates/${id}`)
				.then((resp) => resp.data)
		},
		async getRatesForPairs(pairs: Array<[string, string]>) {
			return xhr
				.get<{ data: TCurrencyPairsObject }>(
					`/currency-exchange-rates-for-pairs`,
					{
						params: { pairs },
					}
				)
				.then((resp) => resp.data.data)
		},
	}
}

export function useCurrenciesOfTenant(): Array<TCurrency> {
	const { user } = useAuthUser()
	return (
		user?.tenant?.all_currencies || [user?.tenant?.functional_currency || "INR"]
	)
}

export function useFunctionalCurrencyOfTenant(fallback = "INR"): TCurrency {
	const { user } = useAuthUser()
	return user?.tenant?.functional_currency || fallback
}

export function useAllAvailableCurrencies() {
	const xhr = useXHR()
	return useSWR(
		`/api/currencies/all-available`,
		() =>
			currenciesXHR(xhr)
				.getAllAvailableCurrencies()
				.then((resp) => resp.data),
		{ revalidateOnFocus: false }
	)
}
