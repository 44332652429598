import {
	Box,
	Container,
	Divider,
	Heading,
	Inline,
	Stack,
	Text,
} from "@sembark-travel/ui/base"
import { Breadcrumbs, ButtonLink, Link } from "@sembark-travel/ui/router"
import { Helmet } from "react-helmet-async"
import { generatePath } from "../../../router-utils"
import { PlatformIcon, type TIntegration } from "../../../Integrations"
import { useHasFeatureFlag } from "../../..//Auth"

export default function NewIntegrationPage() {
	const canIntegrateFB = useHasFeatureFlag("fb_app_integration")
	return (
		<>
			<Helmet>
				<title>New Integration</title>
			</Helmet>
			<Breadcrumbs
				title="New Integration"
				items={[
					[generatePath("/org/integrations"), "Integrations"],
					["", "New"],
				]}
			/>
			<Box paddingX={{ md: "4" }} paddingY={{ md: "8" }}>
				<Box maxWidth="3xl" marginX="auto">
					<Box borderWidth={{ md: "1" }} rounded="md" bgColor="default">
						<Container paddingY="6">
							<Stack gap="4">
								<Stack>
									<Heading as="h2">Platform Integrations</Heading>
									<Text color="muted">
										Use the official integrations to bring leads from following
										Ads platforms.
									</Text>
								</Stack>
								<Inline gap="4">
									{[
										{
											url: generatePath("/integrations/google-ads/new"),
											platform: "google_ads" as TIntegration["platform"],
											title: "Google Ads Lead Form",
											description: "Add webhook for Lead Form on Ads",
										},
									]
										.concat(
											canIntegrateFB
												? [
														{
															url: generatePath("/integrations/fb/new"),
															platform: "meta" as const,
															title: "Facebook Page Leads",
															description: "Bring leads from FB Pages",
														},
													]
												: []
										)
										.map(({ url, platform, title, description }) => (
											<Link
												key={platform}
												width={"1/2"}
												to={url}
												padding="4"
												borderWidth="1"
												rounded="md"
												display="block"
												backgroundColor={{ hover: "subtle" }}
											>
												<Inline gap="4" alignItems="center">
													<Box>
														<PlatformIcon size="48" platform={platform} />
													</Box>
													<Stack flex="1">
														<Heading as="h3" fontSize="md">
															{title}
														</Heading>
														<Text color="muted">{description}</Text>
													</Stack>
												</Inline>
											</Link>
										))}
								</Inline>
							</Stack>
							<Divider />
							<Stack gap="4">
								<Stack>
									<Heading as="h2">Custom API Integrations</Heading>
									<Text color="muted">
										Use the APIs to integrate with any platform such as Website,
										Landing Pages, ChatBots etc.
									</Text>
								</Stack>
								<Link
									to={generatePath("/integrations/api/new")}
									padding="4"
									borderWidth="1"
									rounded="md"
									display="block"
									backgroundColor={{ hover: "subtle" }}
								>
									<Inline gap="4" alignItems="center">
										<Box>
											<PlatformIcon size="48" platform={"api"} />
										</Box>
										<Stack flex="1">
											<Heading as="h3" fontSize="md">
												API Integration
											</Heading>
											<Text color="muted">
												Integrate with Landing Page, ChatBots using API and
												Tokens.
											</Text>
										</Stack>
									</Inline>
								</Link>
							</Stack>
							<Divider />
							<ButtonLink to={generatePath("/org/integrations")} replace>
								Cancel
							</ButtonLink>
						</Container>
					</Box>
				</Box>
			</Box>
		</>
	)
}
