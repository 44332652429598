import { IListResponse, XHRInstance } from "@sembark-travel/xhr"
import { IActivityLog } from "../ActivityLogs"
import { TTeam } from "../Teams"
import { TTripDestination } from "../TripDestinations"
import { IRole } from "./../Roles"
import { IPhoneNumber } from "../Contacts"

export interface IUser {
	id: number
	uid: string
	name: string
	email: string
	email_verified_at?: string
	signup_at?: string
	invited_at: string
	created_at: string
	updated_at: string
	roles?: IRole[]
	last_login_activity?: Pick<IActivityLog, "description" | "created_at">
	last_activity?: Pick<IActivityLog, "description" | "created_at">
	last_activity_at?: string
	deleted_at?: string
	deleted_by?: IUser
	is_owner: boolean
	profile_image_url?: string | null
	profile_thumb_image_url?: string | null
	teams?: Array<TTeam>
	trip_destinations?: Array<TTripDestination>
	disabled_at?: string
	disabled_by?: IUser
	password_login_disabled_at?: string | null
	phone_numbers?: Array<IPhoneNumber<Omit<IUser, "phone_numbers">>>
	tenant_id?: number | null
}

export function XHR(xhr: XHRInstance) {
	return {
		async getUsers(params?: unknown) {
			return xhr
				.get<IListResponse<IUser>>("/users", { params })
				.then((resp) => resp.data)
		},
		async getUser(id: string | number) {
			return xhr
				.get<{ data: IUser }>(`/users/${id}`)
				.then(({ data }) => data.data)
		},
		async invite(data: unknown): Promise<IUser> {
			return xhr
				.post<{ data: IUser }>(`/invited-users`, data)
				.then(({ data }) => data.data)
		},
		async update(id: string | number, data: unknown): Promise<IUser> {
			return xhr
				.patch<{ data: IUser }>(`/users/${id}`, data)
				.then(({ data }) => data.data)
		},
		async updateRoles(id: string | number, data: unknown) {
			return xhr.post(`/users/${id}/roles`, data)
		},
	}
}
