import {
	Button,
	Inline,
	Icons,
	Stack,
	Text,
	Heading,
	Col,
	Divider,
	Grid,
} from "@sembark-travel/ui/base"
import { useXHR } from "@sembark-travel/xhr"
import * as Validator from "yup"
import { SelectRoles } from "../Roles"
import { IRole } from "../Roles"
import { IUser, XHR } from "./store"
import {
	Form,
	validateFormValues,
	TextInputField,
	SelectField,
	withServerErrors,
	SubmissionError,
	PhoneInputField,
} from "@sembark-travel/ui/form"

export interface NewUserCredentials {
	name: string
	email: string
	invited_signup_link: string
	roles: Array<IRole>
	phone_numbers?: Array<{
		country_code: string
		phone_number?: string
		number?: string
	}>
}
const newUserSchema = Validator.object().shape({
	name: Validator.string()
		.required("Name is required")
		.min(4, "Minimum 4 characters required")
		.max(199, "Maximum 199 characters allowed"),
	email: Validator.string()
		.email("Email must be a valid email address")
		.required("Email field is required"),
	roles: Validator.array()
		.required("Please assign role(s) to the new user")
		.min(1, "Please assign role(s) to the new user"),
})

const validate = validateFormValues(newUserSchema)

export function NewUser({
	onSuccess,
	onCancel,
	origin,
}: {
	onSuccess: (user: IUser) => void
	onCancel?: () => void
	origin?: string
}) {
	const xhr = useXHR()
	return (
		<NewUserForm
			origin={origin}
			onSubmit={async (values: NewUserCredentials) => {
				const requestData = { ...values, roles: values.roles.map((r) => r.id) }
				const user = await XHR(xhr).invite(requestData)
				onSuccess(user)
			}}
			onCancel={onCancel}
		/>
	)
}

export function NewUserForm({
	onSubmit,
	onCancel,
	origin,
}: {
	onSubmit: (data: NewUserCredentials) => Promise<void>
	onCancel?: () => void
	origin?: string
}) {
	const initialValues: NewUserCredentials = {
		name: "",
		email: "",
		invited_signup_link: origin ? `${origin}/invited-signup` : "",
		roles: [],
		phone_numbers: [
			{
				country_code: "IN",
				phone_number: "+91",
				number: "",
			},
		],
	}
	return (
		<Form<NewUserCredentials>
			initialValues={initialValues}
			validate={validate}
			onSubmit={withServerErrors(async ({ phone_numbers, ...values }) => {
				await onSubmit({
					...values,
					phone_numbers: phone_numbers
						?.filter((p) => p.number)
						.map((p) => ({
							...p,
							number: String(p.number).replace(/[^\d]/gi, ""),
						})),
				})
			})}
			subscription={{ submitting: true }}
		>
			{({ handleSubmit, submitting }) => (
				<form noValidate onSubmit={handleSubmit}>
					<Grid gap="4">
						<Col xs={12} sm={5}>
							<Stack gap="1">
								<Heading as="h4" fontSize="lg">
									<Icons.User size="6" /> Basic Details
								</Heading>
								<Text color="muted">
									Provide contact details like name and email address of the
									team member.
								</Text>
							</Stack>
						</Col>
						<Col>
							<Stack gap="4">
								<TextInputField
									label="Name"
									name="name"
									type="text"
									required
									placeholder="First Last"
									autoComplete="name"
									help="This will be used to address the invited user"
								/>
								<TextInputField
									label="Email"
									type="email"
									name="email"
									autoComplete="username"
									placeholder="username@domain.com"
									required
									help="User will receive an invitation to this email address"
								/>
								<PhoneInputField
									label="Contact Number(s)"
									name="phone_numbers"
									secondaryLabel="optional"
									help="Used for sharing contact details in Quotes"
								/>
							</Stack>
						</Col>
					</Grid>
					<Divider sm />
					<Grid gap="4">
						<Col xs={12} sm={5}>
							<Stack gap="1">
								<Heading as="h4" fontSize="lg">
									<Icons.ShieldCheck size="6" /> Roles
								</Heading>
								<Text color="muted">
									Manage access permissions for different modules by assigning
									appropriate role(s).
								</Text>
							</Stack>
						</Col>
						<Col>
							<SelectField
								label="Select Role(s)"
								select={SelectRoles}
								name="roles"
								multiple
								fetchOnMount
								required
							/>
						</Col>
					</Grid>
					<input
						hidden
						type="hidden"
						name="invited_signup_link"
						value={initialValues.invited_signup_link}
					/>
					<Divider />
					<Grid gap="4">
						<Col xs={12} sm={{ offset: 5, span: 7 }}>
							<Stack gap="4" paddingLeft={{ sm: "4" }}>
								<SubmissionError />
								<Inline gap="4">
									<Button type="submit" disabled={submitting}>
										{submitting ? (
											"Inviting..."
										) : (
											<>
												<Icons.Mail /> Send Invitation
											</>
										)}
									</Button>
									{onCancel ? (
										<Button
											onClick={onCancel}
											level="tertiary"
											disabled={submitting}
										>
											Cancel
										</Button>
									) : null}
								</Inline>
							</Stack>
						</Col>
					</Grid>
				</form>
			)}
		</Form>
	)
}
