import { Table, AsyncSelect, AsyncSelectProps } from "@sembark-travel/ui/base"
import { Link } from "react-router-dom"
import { IListResponse, useXHR, XHRInstance } from "@sembark-travel/xhr"
import { Fragment } from "react"
import { Omit } from "utility-types"
import { ListView, Search, TSearchParams } from "@sembark-travel/ui/list"
import { IPermission, IRole } from "./store"

function XHR(xhr: XHRInstance) {
	return {
		async getRoles(params?: unknown): Promise<IListResponse<IRole>> {
			return xhr.get("/roles", { params }).then((resp) => resp.data)
		},
		async getPermissions(params?: unknown): Promise<IPermission[]> {
			return xhr.get("/permissions", { params }).then(({ data }) => data.data)
		},
	}
}

export function RolesList<TParams extends TSearchParams = TSearchParams>({
	params,
	setParams,
	actions,
}: {
	params: TParams
	setParams: (params: TParams) => void
	actions: React.ReactNode
}) {
	return (
		<Fragment>
			<Search
				initialParams={params}
				onSearch={(params) => {
					setParams({ ...params, page: 1 })
				}}
				title="Roles"
				actions={actions}
			/>
			<ListView<IRole, TParams>
				pageKey="/roles-list"
				params={params}
				fetch={(xhr, params) => XHR(xhr).getRoles(params)}
				onPageChange={(page) => setParams({ ...params, page })}
			>
				{({ items: roles }) => (
					<Table
						headers={["Name", "Permissions"]}
						striped
						bordered
						rows={roles.map((r) => [
							<Link to={r.id.toString()}>{r.name}</Link>,
							(r.permissions || []).map((p) => p.name).join(" • "),
						])}
					/>
				)}
			</ListView>
		</Fragment>
	)
}

export function SelectRoles({
	permissions,
	...props
}: Omit<AsyncSelectProps, "fetch"> & {
	permissions?: Array<string>
}) {
	const xhr = useXHR()
	return (
		<AsyncSelect
			multiple
			cacheKey="roles"
			fetch={(q) =>
				XHR(xhr)
					.getRoles({
						q,
						permissions: permissions?.length ? permissions : null,
					})
					.then((resp) => resp.data)
			}
			{...props}
		/>
	)
}

export function SelectPermissions(props: Omit<AsyncSelectProps, "fetch">) {
	const xhr = useXHR()
	return (
		<AsyncSelect
			multiple
			fetch={(q) => XHR(xhr).getPermissions({ q })}
			{...props}
		/>
	)
}
