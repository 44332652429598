import { Table, AsyncSelect, RelativeTime } from "@sembark-travel/ui/base"
import { Link, ButtonLink } from "@sembark-travel/ui/router"
import {
	ListView,
	Search,
	TSearchParams,
	useSearch,
} from "@sembark-travel/ui/list"
import { IListResponse, useXHR, XHRInstance } from "@sembark-travel/xhr"
import { Fragment } from "react"
import { Omit } from "utility-types"
import { generatePath } from "../router-utils"
import { TInclusionExclusionPreset } from "./store"

function XHR(xhr: XHRInstance) {
	return {
		async getList(
			params?: unknown
		): Promise<IListResponse<TInclusionExclusionPreset>> {
			return xhr
				.get("/inclusion-exclusion-presets", { params })
				.then((resp) => resp.data)
		},
	}
}

export function List() {
	const [params, setParams] = useSearch<TSearchParams>()
	return (
		<Fragment>
			<Search
				initialParams={params}
				onSearch={(params) => {
					setParams({ ...params, page: 1 })
				}}
				title="Inc/Exclusion Presets"
				actions={
					<>
						<ButtonLink to={generatePath("/inclusion-exclusion-presets/new")}>
							Add New
						</ButtonLink>
					</>
				}
			/>
			<ListView<TInclusionExclusionPreset>
				pageKey="inclusion-exclusion-presets"
				fetch={(xhr, params) => XHR(xhr).getList({ ...params })}
				onPageChange={(page) => setParams({ ...params, page })}
				params={params}
			>
				{({ items }) => (
					<Table
						striped
						bordered
						headers={["Name", "Created On"]}
						rows={items.map((group) => [
							<Link
								color="accent"
								fontWeight="semibold"
								to={generatePath("/inclusion-exclusion-presets/:presetId", {
									presetId: group.id.toString(),
								})}
								anchored
							>
								{group.name}
							</Link>,
							<RelativeTime timestamp={group.created_at} />,
						])}
					/>
				)}
			</ListView>
		</Fragment>
	)
}

export function SelectInclusionExclusionPreset(
	props: Omit<React.ComponentProps<typeof AsyncSelect>, "fetch">
) {
	const xhr = useXHR()
	return (
		<AsyncSelect
			{...props}
			fetch={(q) =>
				XHR(xhr)
					.getList({ q })
					.then((resp) => resp.data)
			}
		/>
	)
}
