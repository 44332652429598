import { useParams, generatePath } from "../../../../router-utils"
import { useXHR } from "@sembark-travel/xhr"
import useSWR from "swr"
import { cabPriceCalculationMetricPresetsXHR } from "../../../../CabPriceCalculationMetrics"
import {
	Container,
	Heading,
	Spinner,
	Text,
	Stack,
	Time,
	Inline,
} from "@sembark-travel/ui/base"
import { Breadcrumbs, ButtonLink } from "@sembark-travel/ui/router"
import { Helmet } from "react-helmet-async"

export default function CabPriceCalculationMetricPresetItemPage() {
	const { presetId } = useParams(
		"/cab-price-calculation-metric-presets/:presetId"
	)
	return (
		<>
			<Helmet>
				<title>Metric Preset Details</title>
			</Helmet>
			<Details presetId={presetId} />
		</>
	)
}

function Details({ presetId }: { presetId: number | string }) {
	const xhr = useXHR()
	const { data } = useSWR(
		`/api/cab-price-calculation-metric-presets/${presetId}`,
		() => cabPriceCalculationMetricPresetsXHR(xhr).show(presetId)
	)
	if (!data) {
		return <Spinner alignCenter padding="4" />
	}
	const { name, metrics, created_at, created_by, updated_at, updated_by } =
		data.data
	return (
		<>
			<Breadcrumbs
				title="Metric Preset Details"
				items={[
					[
						generatePath("/cab-price-calculation-metric-presets"),
						"Metric Presets",
					],
					["", name],
				]}
				actions={
					<ButtonLink
						to={generatePath(
							"/cab-price-calculation-metric-presets/:presetId/edit",
							{
								presetId: String(presetId),
							}
						)}
						size="sm"
						level="secondary"
					>
						Edit
					</ButtonLink>
				}
			/>
			<Container paddingY="6">
				<Stack gap="2">
					<Heading>{name}</Heading>
					<Inline gap="6" flexWrap="wrap">
						<Stack>
							<Text fontSize="sm" fontWeight="semibold" color="muted">
								Metrics
							</Text>
							<Text>{metrics.map((m) => m.name).join(", ")}</Text>
						</Stack>
						{updated_at && updated_by ? (
							<Stack>
								<Text fontSize="sm" fontWeight="semibold" color="muted">
									Updated
								</Text>
								<Text>
									by {updated_by.name} on <Time timestamp={updated_at} />
								</Text>
							</Stack>
						) : null}
						{created_by ? (
							<Stack>
								<Text fontSize="sm" fontWeight="semibold" color="muted">
									Created
								</Text>
								<Text>
									by {created_by.name} on <Time timestamp={created_at} />
								</Text>
							</Stack>
						) : null}
					</Inline>
				</Stack>
			</Container>
		</>
	)
}
