import { Box, Container } from "@sembark-travel/ui/base"
import {
	Breadcrumbs,
	useGetBackUrlFromLocation,
} from "@sembark-travel/ui/router"
import { Helmet } from "react-helmet-async"
import { NewHotelPaymentPreferenceForm } from "../../../HotelPaymentPreferences"
import { generatePath, useNavigate } from "../../../router-utils"
import { ForbidUnlessAuthorized, PERMISSIONS } from "../../../Auth"

export default function NewHotelPaymentPreference() {
	const navigate = useNavigate()
	const backFromUrl = useGetBackUrlFromLocation()
	const listingPage = generatePath("/org/hotel-payment-preferences")
	function navigateBackOrToList() {
		if (backFromUrl) {
			return navigate(backFromUrl as "/org/hotel-payment-preferences")
		}
		return navigate("/org/hotel-payment-preferences")
	}
	return (
		<>
			<Helmet>
				<title>Add Hotel Payment Preference</title>
			</Helmet>
			<ForbidUnlessAuthorized permission={PERMISSIONS.VIEW_HOTEL_BOOKINGS}>
				<Breadcrumbs
					title="New Preference"
					items={[
						[listingPage, "Hotel Payment Preferences"],
						["", "New"],
					]}
				/>
				<Box paddingX={{ md: "4" }} paddingY={{ md: "8" }}>
					<Box maxWidth="6xl" marginX="auto">
						<Box borderWidth={{ md: "1" }} rounded="md" bgColor="default">
							<Container paddingY="6">
								<NewHotelPaymentPreferenceForm
									onSuccess={navigateBackOrToList}
									onCancel={navigateBackOrToList}
								/>
							</Container>
						</Box>
					</Box>
				</Box>
			</ForbidUnlessAuthorized>
		</>
	)
}
