import { Box, Tabs, TabsList, TabContent } from "@sembark-travel/ui/base"
import { NavLink } from "@sembark-travel/ui/router"
import { Outlet } from "react-router-dom"

export default function TripCommentsLayoutPage() {
	return (
		<Box marginY="4" rounded="lg">
			<Tabs>
				<TabsList>
					<NavLink to="" replace end>
						All
					</NavLink>
					<NavLink to="demanding" replace>
						Demanding
					</NavLink>
					<NavLink to="actionable" replace>
						Actionable
					</NavLink>
					<NavLink to="overdue" replace>
						Overdue
					</NavLink>
				</TabsList>
				<TabContent bgColor="default" padding="4">
					<Outlet />
				</TabContent>
			</Tabs>
		</Box>
	)
}
