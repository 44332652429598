import { Box, Container, Heading, Stack, Text } from "@sembark-travel/ui/base"
import { LatestReleaseNotes } from "../../AppInstaller"
import { Helmet } from "react-helmet-async"

export default function LatestReleaseNotesPage() {
	return (
		<>
			<Helmet>
				<title>Latest Release Notes</title>
			</Helmet>
			<Stack>
				<Container borderBottomWidth="1">
					<Box paddingY="4">
						<Heading>What's New</Heading>
						<Text color="muted">
							Here are the release notes from latest update.
						</Text>
					</Box>
				</Container>
				<Box bgColor="default" data-color-theme="light">
					<Container paddingY="4">
						<LatestReleaseNotes />
					</Container>
				</Box>
			</Stack>
		</>
	)
}
