import { Helmet } from "react-helmet-async"
import { useParams } from "../../../router-utils"
import { TravelActivityDetails } from "./../../../TravelActivities"

export default function TravelActivityDetailsPage() {
	const { travelActivityId } = useParams("/travel-activities/:travelActivityId")
	return (
		<>
			<Helmet>
				<title>Travel Activity Details</title>
			</Helmet>
			<TravelActivityDetails travelActivityId={travelActivityId} />
		</>
	)
}
