import { Box, Heading, Icons, Stack, Text } from "@sembark-travel/ui/base"
import { Link } from "@sembark-travel/ui/router"
import { Helmet } from "react-helmet-async"
import Logo from "../../images/Logo"
import { LoginForm, useLogin } from "./../../Auth"
import { generatePath } from "./../../router-utils"
import { useSearchParams } from "react-router-dom"

export default function LoginPage() {
	const [searchParams] = useSearchParams()
	const email = searchParams.get("email") || ""
	const login = useLogin()
	return (
		<>
			<Helmet>
				<title>Login</title>
			</Helmet>
			<Box
				display="flex"
				justifyContent="center"
				alignItems="center"
				paddingY="12"
				bgColor="subtle"
				style={{
					backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 80 80' width='80' height='80'%3E%3Cpath fill='currentColor' fill-opacity='0.03' d='M14 16H9v-2h5V9.87a4 4 0 1 1 2 0V14h5v2h-5v15.95A10 10 0 0 0 23.66 27l-3.46-2 8.2-2.2-2.9 5a12 12 0 0 1-21 0l-2.89-5 8.2 2.2-3.47 2A10 10 0 0 0 14 31.95V16zm40 40h-5v-2h5v-4.13a4 4 0 1 1 2 0V54h5v2h-5v15.95A10 10 0 0 0 63.66 67l-3.47-2 8.2-2.2-2.88 5a12 12 0 0 1-21.02 0l-2.88-5 8.2 2.2-3.47 2A10 10 0 0 0 54 71.95V56zm-39 6a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm40-40a2 2 0 1 1 0-4 2 2 0 0 1 0 4zM15 8a2 2 0 1 0 0-4 2 2 0 0 0 0 4zm40 40a2 2 0 1 0 0-4 2 2 0 0 0 0 4z'%3E%3C/path%3E%3C/svg%3E")`,
					minHeight: "calc(-64px + 100vh)",
				}}
			>
				<Stack
					position="relative"
					zIndex="10"
					width="full"
					maxWidth="md"
					gap="6"
				>
					<Box
						textAlign="center"
						display="flex"
						justifyContent="center"
						alignItems="center"
						gap="2"
					>
						<Logo width="32" height="32" />
						<Box fontSize="xl">Sembark</Box>
					</Box>
					<Box
						boxShadow="base"
						paddingX={{ xs: "8", lg: "16" }}
						paddingY={{ xs: "4", lg: "12" }}
						bgColor="default"
						rounded="md"
					>
						<Stack gap="10">
							<Heading as="h1" textAlign="center" fontSize="xl">
								Login to Sembark
							</Heading>
							<LoginForm email={email} onSubmit={(...args) => login(...args)} />
							<Link
								to={generatePath("/forgot-password")}
								tabIndex={3}
								fontSize="sm"
								color="accent"
							>
								Forgot password ? Click here to reset.
							</Link>
						</Stack>
					</Box>
					<Box paddingX="4">
						<Text color="muted" fontSize="sm">
							Need Account ?{" "}
							<Text
								as="a"
								href="https://sembark.com/register?utm_source=app_login"
								color="accent"
								textDecoration="underline"
								target="_blank"
							>
								Click Here <Icons.ArrowTopRightOnSquare />
							</Text>
						</Text>
					</Box>
				</Stack>
			</Box>
		</>
	)
}
