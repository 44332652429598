import { Button, Inline, Divider, Stack } from "@sembark-travel/ui/base"
import { Dialog, useDialog } from "@sembark-travel/ui/dialog"
import { useXHR, XHRInstance } from "@sembark-travel/xhr"
import {
	Form,
	SelectField,
	withServerErrors,
	SubmissionError,
} from "@sembark-travel/ui/form"
import React from "react"
import { Optional } from "utility-types"
import { SelectTags } from "./List"
import { ITag, TType } from "./store"

function XHR(xhr: XHRInstance, type: TType) {
	return {
		async storeTags(
			items: Array<number>,
			tags: Array<string>
		): Promise<{ data: Array<ITag> }> {
			return xhr
				.post(`/${type}-tags`, { items, tags })
				.then((resp) => resp.data)
		},
	}
}

export interface EditTagsProps {
	tags?: Array<ITag>
	type: TType
	itemId: number
	onCancel: () => void
	onSuccess: () => void
}

export default function EditTags({
	type,
	itemId,
	tags,
	onCancel,
	onSuccess,
}: EditTagsProps) {
	const xhr = useXHR()
	const initialValues = { items: [itemId], tags: tags || [] }
	return (
		<Form<typeof initialValues>
			initialValues={initialValues}
			onSubmit={withServerErrors(async (values) => {
				await XHR(xhr, type).storeTags(
					values.items,
					values.tags.map((t) => t.name)
				)
				onSuccess()
			})}
			subscription={{ submitting: true }}
		>
			{({ submitting, handleSubmit }) => (
				<form noValidate onSubmit={handleSubmit}>
					<Stack gap="4">
						<SubmissionError />
						<SelectField
							select={SelectTags}
							name="tags"
							fetchOnMount
							label="Select existing or create new tags"
							type={type}
							multiple
						/>
						<Divider sm />
						<Inline gap="4">
							<Button type="submit" disabled={submitting}>
								{submitting ? "Saving..." : "Save"}
							</Button>
							<Button disabled={submitting} onClick={onCancel} level="tertiary">
								Cancel
							</Button>
						</Inline>
					</Stack>
				</form>
			)}
		</Form>
	)
}

export function EditTagsInDialog({
	children,
	...props
}: Optional<EditTagsProps, "onSuccess" | "onCancel"> & {
	children: (props: { onEdit: () => void }) => React.ReactNode
}) {
	const [isOpen, open, close] = useDialog()
	return (
		<>
			{children({ onEdit: open })}
			<Dialog open={isOpen} onClose={close} title="Edit Tags" sm>
				<Dialog.Body>
					<EditTags
						{...props}
						onSuccess={() => {
							close()
							props.onSuccess?.()
						}}
						onCancel={() => {
							close()
							props.onCancel?.()
						}}
					/>
				</Dialog.Body>
			</Dialog>
		</>
	)
}
