import {
	createContext,
	forwardRef,
	useContext,
	useId,
	useMemo,
	useState,
} from "react"
import { Box, BoxOwnProps } from "./Box"
import { btnHeightClassName } from "./button.css"
import { Inline } from "./Inline"
import { Text } from "./Text"
import {
	textInputClassName,
	selectClassName,
	fileInputClassName,
} from "./input.css"
import classNames from "classnames"
import { duration, BaseUnitOfTime } from "@sembark-travel/datetime-utils"
import Icons from "./Icons"

const InputGroupContext = createContext<{
	hasError?: boolean
}>({
	hasError: false,
})

function useInputGroupContext() {
	return useContext(InputGroupContext)
}

export function InputGroup({
	hasError,
	children,
}: {
	hasError?: boolean
	children?: React.ReactNode
}) {
	const context = useMemo(() => {
		return { hasError: hasError || false }
	}, [hasError])
	return (
		<InputGroupContext.Provider value={context}>
			{children}
		</InputGroupContext.Provider>
	)
}

export type BaseInputProps = Omit<BoxOwnProps, "size"> & {
	hasError?: boolean
}

const baseInputProps: Omit<BoxOwnProps, "size"> = {
	bgColor: "default",
	borderWidth: "1",
	rounded: "md",
	borderColor: { default: "emphasis", focus: "primary_emphasis" },
	paddingY: "2",
	paddingLeft: "4",
	paddingRight: "4",
	maxWidth: "full",
	display: "block",
}

export type InputProps = BaseInputProps & {
	size?: keyof typeof btnHeightClassName
} & Omit<React.HTMLProps<HTMLInputElement>, keyof BoxOwnProps | "as">

export const Input = forwardRef<HTMLInputElement, InputProps>(
	function InputForwarded(
		{ type, hasError, className, size = "base", disabled, ...props },
		ref
	) {
		const context = useInputGroupContext()
		hasError = hasError || context.hasError
		return (
			<Box<"input">
				{...baseInputProps}
				disabled={disabled}
				width={
					type === "email" ||
					type === "text" ||
					type === "password" ||
					type === "search"
						? "full"
						: undefined
				}
				paddingRight={
					type === "number"
						? "1"
						: size === "sm"
							? "2"
							: baseInputProps.paddingRight
				}
				paddingLeft={size === "sm" ? "2" : baseInputProps.paddingRight}
				borderColor={
					hasError
						? "danger_emphasis"
						: {
								default: "emphasis",
								focus: "primary_emphasis",
							}
				}
				{...props}
				backgroundColor={disabled ? "subtle" : props.backgroundColor}
				opacity={disabled ? "70" : props.opacity}
				className={classNames(
					btnHeightClassName[size],
					textInputClassName,
					className
				)}
				appearance="none"
				as="input"
				ref={ref}
				type={type}
			/>
		)
	}
)
Input.displayName = "Input"

type RadioInputProps = Omit<InputProps, "size" | "type">
export const RadioInput = forwardRef<HTMLInputElement, RadioInputProps>(
	function RadioInputForwarded({ hasError, ...props }, ref) {
		return (
			<Box<"input">
				{...baseInputProps}
				display="inlineBlock"
				paddingX="0"
				paddingY="0"
				rounded="full"
				verticalAlign="middle"
				{...props}
				as="input"
				ref={ref}
				type="radio"
			/>
		)
	}
)
RadioInput.displayName = "RadioInput"

type CheckboxInputProps = Omit<InputProps, "size" | "type">
export const CheckboxInput = forwardRef<HTMLInputElement, CheckboxInputProps>(
	function CheckboxInputForwarded({ hasError, ...props }, ref) {
		return (
			<Box<"input">
				{...baseInputProps}
				display="inlineBlock"
				paddingX="0"
				paddingY="0"
				rounded="full"
				verticalAlign="middle"
				{...props}
				as="input"
				ref={ref}
				type="checkbox"
				cursor={props.disabled ? "disabled" : undefined}
			/>
		)
	}
)
CheckboxInput.displayName = "CheckboxInput"

type SwitchInputProps = Omit<CheckboxInputProps, "onChange"> & {
	onChange?: (checked: 0 | 1) => void
}
export const SwitchInput = forwardRef<HTMLInputElement, SwitchInputProps>(
	function SwitchInputForwarded(
		{ hasError, onChange, checked, ...props },
		ref
	) {
		return (
			<CheckboxInput
				{...props}
				ref={ref}
				checked={checked}
				onChange={() =>
					props.readOnly || props.disabled
						? undefined
						: onChange?.(checked ? 0 : 1)
				}
			/>
		)
	}
)
SwitchInput.displayName = "SwitchInput"

type SelectInputProps = BaseInputProps & {
	size?: keyof typeof btnHeightClassName
} & Omit<React.HTMLProps<HTMLSelectElement>, keyof BoxOwnProps | "as">
export const SelectInput = forwardRef<HTMLSelectElement, SelectInputProps>(
	function SelectForwared(
		{ hasError, className, size = "base", ...props },
		ref
	) {
		const context = useInputGroupContext()
		hasError = hasError || context.hasError
		return (
			<Box<"select">
				{...baseInputProps}
				className={classNames(
					textInputClassName,
					selectClassName,
					btnHeightClassName[size]
				)}
				paddingY="0"
				borderColor={
					hasError
						? "danger_emphasis"
						: { default: "emphasis", focus: "primary_emphasis" }
				}
				{...props}
				appearance="none"
				as="select"
				ref={ref}
			/>
		)
	}
)
SelectInput.displayName = "SelectInput"

type InlineSelectInputProps = BaseInputProps & {
	noCaret?: boolean
} & Omit<React.HTMLProps<HTMLSelectElement>, keyof BoxOwnProps | "as">
export const InlineSelectInput = forwardRef<
	HTMLSelectElement,
	InlineSelectInputProps
>(function SelectForwared(
	{ hasError, value, children, onChange, noCaret, disabled },
	ref
) {
	const context = useInputGroupContext()
	hasError = hasError || context.hasError
	const id = `inline_select_input_` + useId()
	return (
		<Box position="relative" display="inlineBlock">
			<Box
				as="label"
				style={{ fontWeight: "inherit" }}
				htmlFor={id}
				cursor={disabled ? "disabled" : "pointer"}
			>
				{value || "--"}
				{noCaret ? null : (
					<>
						{" "}
						<Icons.ChevronDown />
					</>
				)}
			</Box>
			<Box
				position="absolute"
				overflow="hidden"
				inset="0"
				title="Select options"
			>
				<select
					value={value}
					id={id}
					ref={ref}
					style={{
						background: "transparent",
						opacity: 0,
						cursor: !disabled ? "pointer" : "not-allowed",
					}}
					onChange={onChange}
					disabled={disabled}
				>
					{children}
				</select>
			</Box>
		</Box>
	)
})
InlineSelectInput.displayName = "InlineSelectInput"

type TextAreaProps = BaseInputProps &
	Omit<React.HTMLProps<HTMLTextAreaElement>, keyof BoxOwnProps | "as">
export const TextArea = forwardRef<HTMLTextAreaElement, TextAreaProps>(
	function TextAreaForwared({ hasError, className, ...props }, ref) {
		const context = useInputGroupContext()
		hasError = hasError || context.hasError
		return (
			<Box<"textarea">
				{...baseInputProps}
				width="full"
				borderColor={
					hasError
						? "danger_emphasis"
						: { default: "emphasis", focus: "primary_emphasis" }
				}
				{...props}
				className={classNames(textInputClassName, className)}
				as="textarea"
				ref={ref}
			/>
		)
	}
)
TextArea.displayName = "TextArea"

export const FileInput = forwardRef<
	HTMLInputElement,
	Omit<InputProps, "type" | "onChange"> & {
		onChange?: (files: FileList | null) => void
	}
>(function InputForwarded(
	{
		hasError,
		id,
		size = "base",
		onFocus,
		onBlur,
		onChange,
		disabled,
		readOnly,
		accept,
		multiple,
	},
	ref
) {
	const context = useInputGroupContext()
	const [fieldValue, setFieldValue] = useState<string>("")
	const [files, setFiles] = useState<FileList | null>(null)
	hasError = hasError || context.hasError
	const outputLabelId = `${id || "file_input_"}_output`
	return (
		<Inline
			flexWrap="nowrap"
			pointerEvents={disabled ? "none" : undefined}
			alignItems="stretch"
			className={btnHeightClassName[size]}
			{...baseInputProps}
			paddingY="0"
			width="auto"
			overflow="hidden"
			paddingX="0"
			borderColor={
				hasError
					? "danger_emphasis"
					: { default: "emphasis", focus: "primary_emphasis" }
			}
			maxWidth="xl"
		>
			<Inline
				as="label"
				position="relative"
				alignItems="center"
				justifyContent="center"
				htmlFor={id}
				borderRightWidth="1"
			>
				<Inline alignItems="center" paddingX="4" whiteSpace="preserve">
					Choose File{multiple ? "s" : ""}
				</Inline>
				<Box<"input">
					as="input"
					type="file"
					id={id}
					accept={accept}
					multiple={multiple}
					disabled={disabled}
					readOnly={readOnly}
					className={fileInputClassName}
					value={fieldValue}
					onChange={({ currentTarget }) => {
						let files = currentTarget.files
						if (files && !files.length) {
							// if no file selected, set the values as null
							files = null
						}
						const fieldValue = currentTarget.value
						onChange?.(files)
						setFiles(files)
						setFieldValue(fieldValue)
					}}
					onFocus={onFocus}
					onBlur={onBlur}
					ref={ref}
					aria-describedby={outputLabelId}
				/>
			</Inline>
			<Inline
				aria-hidden="true"
				paddingX="4"
				alignItems="center"
				bgColor="subtle"
				flex="1"
				minWidth="0"
			>
				<Text
					maxWidth="full"
					fontSize="sm"
					textOverflow="truncate"
					id={outputLabelId}
				>
					{multiple && files && files.length > 1
						? `${files.length} files selected`
						: fieldValue
							? fieldValue.replace(/(\w+:?[\\\\/])/g, "")
							: "No file selected"}
				</Text>
			</Inline>
		</Inline>
	)
})
Input.displayName = "Input"

export function InputValidationErrorMessage(
	props: React.ComponentProps<typeof Box>
) {
	return (
		<Box
			backgroundColor="danger_emphasis"
			color="on_emphasis"
			display="block"
			rounded="md"
			fontSize="sm"
			paddingY="1"
			paddingX="2"
			role="alert"
			style={{
				width: "fit-content",
			}}
			{...props}
		/>
	)
}

type DurationInputProps = BaseInputProps & {
	size?: keyof typeof btnHeightClassName
	onChange: (value: string) => void
} & Omit<
		React.HTMLProps<HTMLInputElement>,
		keyof BoxOwnProps | "as" | "onChange"
	>
export const DurationInput = forwardRef<HTMLDivElement, DurationInputProps>(
	function DurationInputForwared(
		{
			hasError,
			className,
			size = "base",
			value,
			onChange,
			ref: _ref,
			...props
		},
		ref
	) {
		const context = useInputGroupContext()
		hasError = hasError || context.hasError
		const dur = String(value).trim().length
			? duration(String(value))
			: undefined
		const [unit, setUnit] = useState<BaseUnitOfTime | undefined>(() => {
			if (dur?.isValid()) {
				const minutes = dur.asMinutes()
				if (minutes) {
					return "minutes"
				}
				const hours = dur.asHours()
				if (hours) {
					return "hours"
				}
				const days = dur.asDays()
				if (days) {
					return "days"
				}
				return "minutes"
			}
			return
		})
		function handleChange(
			d: ReturnType<typeof duration> | undefined = undefined
		) {
			onChange(d?.toISOString() || "")
		}
		return (
			<Inline ref={ref} gap="2">
				<Input
					{...baseInputProps}
					className={classNames(textInputClassName, btnHeightClassName[size])}
					paddingY="0"
					borderColor={
						hasError
							? "danger_emphasis"
							: { default: "emphasis", focus: "primary_emphasis" }
					}
					{...props}
					type="text"
					onChange={({ currentTarget: { value } }) => {
						if (String(value)) {
							if (!unit) {
								setUnit("minutes")
							}
							if (!dur?.isValid()) {
								handleChange(duration(value, "minutes"))
							} else {
								handleChange(duration(value, unit || "minutes"))
							}
						} else {
							handleChange()
						}
					}}
					value={dur?.isValid() ? dur.as(unit || "minutes") : ""}
					style={{ width: "80px" }}
					placeholder="30"
				/>
				<SelectInput
					{...baseInputProps}
					className={classNames(
						textInputClassName,
						selectClassName,
						btnHeightClassName[size]
					)}
					paddingY="0"
					borderColor={
						hasError
							? "danger_emphasis"
							: { default: "emphasis", focus: "primary_emphasis" }
					}
					{...(props as object)}
					appearance="none"
					placeholder="Min"
					onChange={({ currentTarget }) => {
						const value = currentTarget.value as BaseUnitOfTime
						const existingUnit = unit
						setUnit(value)
						if (!value) {
							handleChange()
							return
						}
						// change the unit, keeping the same amount
						handleChange(
							duration(dur?.as(existingUnit || "minutes") || 0, value)
						)
					}}
					value={unit}
				>
					<option></option>
					<option value="minutes">Mins</option>
					<option value="hours">Hrs</option>
					<option value="days">Days</option>
				</SelectInput>
			</Inline>
		)
	}
)
DurationInput.displayName = "DurationInput"
