import { Box, Button, useTimeout, SROnly } from "@sembark-travel/ui/base"
import { useCallback, useMemo, useRef, useEffect } from "react"
import { Required } from "utility-types"
import * as Validator from "yup"
import { getTotalPriceForServices } from "../utils"
import { ITransportService } from "./../TransportServices"
import {
	Form,
	validateFormValues,
	GetFieldValue,
	useFieldValue,
	withServerErrors,
	useFormState,
	arrayMutators,
	isTruthy,
	addKeyToFieldArrayItem,
} from "@sembark-travel/ui/form"
import { type TTripDestination } from "../TripDestinations"
import { type TChildrenArray } from "../Tourists"
import {
	addMoneyFromDifferentCurrencies,
	moneyParseByDecimal,
	type TMoney,
} from "@sembark-travel/money"
import { useHasFeatureFlag } from "../Auth"
import {
	CabPriceCalculationField,
	type TCabPriceCalculationFormData,
	type TCabPriceCalculationConfiguration,
} from "./../CabPriceCalculationMetrics"
import { TCabsMetricsWiseCalculatedPrices } from "../CabPriceCalculationMetrics/CalculatePrice"
import { TUsedCabTypes, UsedCabTypesInputField } from "./UsedCabTypesInputField"
import { AddPricesAtOnceSwitchInputField } from "./TransportServiceDatewisePricesInputField"
import {
	createBookingDateSelectionOptionsFromStartAndEndDates,
	getValidDateswiseTransportsAndActivitiesData,
} from "./utils"

import {
	TDateswiseTransportsAndActivitiesInputFieldValue,
	DateswiseTransportAndActivityServicesInputFieldArray,
	TValidDateswiseTransportsAndActivitiesInputFieldValue,
} from "./DatewiseTransportAndActivityServicesInputFieldArray"

const validationSchema = Validator.object().shape({
	daywise_services: Validator.array().of(
		Validator.object().shape({
			dates: Validator.array()
				.of(Validator.date().required("Please select a valid day/date"))
				.required("Days field id required")
				.min(1, "Days field is required"),
			services: Validator.array().of(
				Validator.object().shape({
					type: Validator.string().required(),
					service: Validator.mixed(),
				})
			),
		})
	),
})

const validate = validateFormValues(validationSchema)

export type CalculatePriceSchema = {
	add_cab_prices_at_once?: boolean
	used_cab_types?: TUsedCabTypes
	daywise_services?: TDateswiseTransportsAndActivitiesInputFieldValue
} & TCabPriceCalculationFormData

const INITIAL_VALUES: Required<CalculatePriceSchema, "daywise_services"> = {
	add_cab_prices_at_once: false,
	used_cab_types: [],
	cabs_price_calculations: undefined,
	daywise_services: [
		addKeyToFieldArrayItem({
			dates: [],
			no_of_days: 1,
			services: [
				addKeyToFieldArrayItem({
					type: "transport_service",
					service: {
						transport_service_location: undefined,
						transport_service: undefined,
						comments: "",
						cabs: [],
					},
				}),
			],
		}),
	],
}

type TValidData = Omit<
	CalculatePriceSchema,
	"cabs_price_calculations" | "daywise_services"
> & {
	daywise_services: TValidDateswiseTransportsAndActivitiesInputFieldValue
	cabs_price_calculations?: Array<
		Omit<TCabsMetricsWiseCalculatedPrices[number], "metric_wise_prices"> & {
			metric_wise_prices: Array<
				Required<
					Required<
						TCabsMetricsWiseCalculatedPrices[number]
					>["metric_wise_prices"][number],
					"metric"
				>
			>
		}
	>
	cabs_tax_percentage?: number
	cabs_price_calculation_configurations?: Array<TCabPriceCalculationConfiguration>
	given_price: Array<TMoney>
	given_price_of_cabs_only: Array<TMoney>
	given_price_of_activities_only: Array<TMoney>
}

interface CalculatePriceFormProps {
	initialValues?: CalculatePriceSchema
	onChange?: (data: TValidData) => void
	bookingFrom?: string
	bookingTo?: string
	shouldEmptyInitialValues?: boolean
	tripDestinations: Array<TTripDestination>
	noOfAdults: number
	children: TChildrenArray
}

export function CalculatePriceForm({
	initialValues: initialValuesProp,
	shouldEmptyInitialValues = false,
	onChange,
	bookingFrom,
	bookingTo,
	tripDestinations,
	noOfAdults,
	children,
}: CalculatePriceFormProps) {
	const currency = useMemo(
		() => tripDestinations.map((d) => d.currency).at(0) || "INR",
		[tripDestinations]
	)
	const canAddCabPricesAtOnce = useHasFeatureFlag(
		"add_quote_cab_prices_at_once"
	)
	const initialValues = useMemo(() => {
		const data: CalculatePriceSchema =
			initialValuesProp ||
			(shouldEmptyInitialValues
				? {
						add_cab_prices_at_once: false,
						used_cab_types: [],
						cabs_price_calculations: [],
					}
				: INITIAL_VALUES)
		return {
			...data,
			daywise_services: data.daywise_services?.map((data) => ({
				...data,
				services: (data.services || []).map((s) =>
					s.type === "transport_service"
						? {
								...s,
								service: {
									...s.service,
									// change the transport service to add the locations field
									transport_service_location:
										s.service.transport_service?.locations,
									transport_service: s.service.transport_service
										? transformServiceOption(s.service.transport_service)
										: undefined,
								},
							}
						: s
				),
			})),
			add_cab_prices_at_once: canAddCabPricesAtOnce
				? Boolean(data.cabs_price_calculations?.length)
				: false,
		}
	}, [initialValuesProp, shouldEmptyInitialValues, canAddCabPricesAtOnce])
	const notifyOnChange = useCallback(
		(flattenValues: CalculatePriceSchema) => {
			if (onChange) {
				const validDaywiseServices =
					getValidDateswiseTransportsAndActivitiesData(
						flattenValues.daywise_services
					)

				const cabs_price_calculations = !flattenValues.cabs_price_calculations
					?.length
					? undefined
					: flattenValues.cabs_price_calculations
							.map((c) => ({
								...c,
								metric_wise_prices:
									c.metric_wise_prices?.filter((t) => {
										return Boolean(
											t.metric && t.given_quantity && t.per_quantity_given_price
										)
									}) || [],
							}))
							.filter((c) => Boolean(c.metric_wise_prices?.length))

				const givenPriceForCabsOnly = isTruthy(
					flattenValues.add_cab_prices_at_once
				)
					? addMoneyFromDifferentCurrencies(
							(cabs_price_calculations || []).reduce<Array<TMoney>>(
								(total, { currency, given_price }) =>
									addMoneyFromDifferentCurrencies(
										total.concat([
											moneyParseByDecimal(given_price || 0, currency),
										])
									),
								[]
							)
						)
					: getTotalPriceForServices(
							validDaywiseServices.flatMap((s) =>
								s.services.flatMap((s) =>
									s.type === "transport_service" ? s.service.cabs : []
								)
							)
						)

				const givenPriceForActivitiesOnly = getTotalPriceForServices(
					validDaywiseServices.flatMap((s) =>
						s.services.flatMap((s) =>
							s.type === "travel_activity"
								? s.service.ticket_tourist_configurations
								: []
						)
					)
				)

				const totalGivenPrice = addMoneyFromDifferentCurrencies(
					givenPriceForCabsOnly.concat(givenPriceForActivitiesOnly)
				)

				onChange({
					...flattenValues,
					daywise_services: validDaywiseServices,
					cabs_price_calculations: flattenValues.cabs_price_calculations
						?.map((c) => ({
							...c,
							metric_wise_prices: (c.metric_wise_prices || []).filter(
								(c): c is Required<typeof c, "metric"> => Boolean(c.metric)
							),
						}))
						?.filter((c) => Boolean(c.metric_wise_prices?.length)),
					given_price: validDaywiseServices?.length ? totalGivenPrice : [],
					given_price_of_cabs_only: validDaywiseServices?.length
						? givenPriceForCabsOnly
						: [],
					given_price_of_activities_only: validDaywiseServices?.length
						? givenPriceForActivitiesOnly
						: [],
				})
			}
		},
		[onChange]
	)
	const bookingDates = useMemo(() => {
		return createBookingDateSelectionOptionsFromStartAndEndDates(
			bookingFrom,
			bookingTo
		)
	}, [bookingFrom, bookingTo])
	return (
		<Form<CalculatePriceSchema>
			initialValues={initialValues}
			validate={validate}
			onSubmit={withServerErrors(console.log)}
			subscription={{}}
			mutators={{ ...arrayMutators }}
		>
			{({ form, handleSubmit }) => {
				return (
					<form noValidate onSubmit={handleSubmit}>
						<Box paddingBottom="6">
							<Box
								paddingX="4"
								paddingY="2"
								bgColor="accent"
								rounded="md"
								borderWidth="1"
								borderColor="accent"
							>
								<Box>
									<UsedCabTypesInputField
										name="used_cab_types"
										tripDestinations={tripDestinations}
										getInitialCabs={() => {
											const daywiseServices =
												form.getFieldState("daywise_services")?.value || []
											return daywiseServices.flatMap((s) =>
												(s.services || []).map((s) =>
													s.type === "transport_service" ? s.service : {}
												)
											)
										}}
									/>
								</Box>
								{canAddCabPricesAtOnce ? (
									<Box>
										<AddPricesAtOnceSwitchInputField
											name="add_cab_prices_at_once"
											onSetPriceCalculations={(calculations) => {
												form.change("cabs_price_calculations", calculations)
											}}
											onRemoveServiceWisePrice={() => {
												// remove the prices from cabs
												const daywise_services =
													form.getFieldState("daywise_services")?.value
												if (daywise_services) {
													form.change(
														"daywise_services",
														daywise_services.map((d) => ({
															...d,
															services: !d.services
																? []
																: d.services.map((c) =>
																		c.type !== "transport_service"
																			? c
																			: {
																					...c,
																					service: {
																						...c.service,
																						cabs: c.service?.cabs?.map((c) => ({
																							...c,
																							date_wise_prices: [],
																						})),
																					},
																				}
																	),
														}))
													)
												}
											}}
										/>
									</Box>
								) : null}
							</Box>
						</Box>
						<GetFieldValue<
							CalculatePriceSchema["used_cab_types"]
						> name="used_cab_types">
							{({ value: selectedCabTypes }) => {
								return (
									<GetFieldValue<
										boolean | undefined
									> name="add_cab_prices_at_once">
										{({ value: add_cab_prices_at_once }) => (
											<DateswiseTransportAndActivityServicesInputFieldArray
												name="daywise_services"
												bookingDates={
													bookingFrom && bookingTo ? bookingDates : undefined
												}
												selectedCabTypes={selectedCabTypes}
												add_cab_prices_at_once={add_cab_prices_at_once}
												tripDestinations={tripDestinations}
												currency={currency}
												noOfAdults={noOfAdults}
												children={children}
											/>
										)}
									</GetFieldValue>
								)
							}}
						</GetFieldValue>
						<GetFieldValue<boolean | undefined> name="add_cab_prices_at_once">
							{({ value }) =>
								isTruthy(value) ? (
									<Box paddingY="6">
										<CabPriceCalculator
											currency={currency}
											tripDestinations={tripDestinations}
										/>
									</Box>
								) : null
							}
						</GetFieldValue>
						<SubmitOnChange onChange={notifyOnChange} />
						<SROnly>
							<Button type="submit">Get Prices</Button>
						</SROnly>
					</form>
				)
			}}
		</Form>
	)
}

function transformServiceOption(service: ITransportService) {
	return {
		...service,
		label: service.service,
	}
}

function CabPriceCalculator({
	currency,
	tripDestinations,
}: {
	currency: string
	tripDestinations: Array<TTripDestination>
}) {
	const { value: daywiseServices } = useFieldValue<
		TDateswiseTransportsAndActivitiesInputFieldValue | ""
	>("daywise_services")
	const { value: used_cab_types } =
		useFieldValue<CalculatePriceSchema["used_cab_types"]>("used_cab_types")
	const usingSameCabTypes = Boolean(used_cab_types?.length)
	const existingServices = useMemo(() => {
		return (daywiseServices || []).flatMap((s) =>
			(s.services || []).flatMap((service) =>
				service.type === "transport_service"
					? [
							{
								dates: s.dates,
								...service.service,
							},
						]
					: []
			)
		)
	}, [daywiseServices])
	if (!existingServices?.length) return null
	return (
		<CabPriceCalculationField
			currency={currency}
			tripDestinations={tripDestinations}
			services={existingServices}
			usingSameCabTypes={usingSameCabTypes}
		/>
	)
}

function SubmitOnChange({
	onChange,
}: {
	onChange: (values: CalculatePriceSchema) => void
}) {
	const { values } = useFormState<CalculatePriceSchema>({
		subscription: { values: true, valid: true },
	})
	const { set: setNotifyParentTimeout, clear: clearNotifyParentTimeout } =
		useTimeout()

	const onChangeRef = useRef(onChange)
	onChangeRef.current = onChange

	// notify parent
	useEffect(() => {
		setNotifyParentTimeout(() => {
			onChangeRef.current(values)
		}, 500)
		return () => clearNotifyParentTimeout()
	}, [values, setNotifyParentTimeout, clearNotifyParentTimeout])

	return null
}
