import { useMemo } from "react"
import {
	getDiff,
	startOf,
	addUnit,
	formatDate,
	parseDate,
} from "@sembark-travel/datetime-utils"
import { withOrdinalSuffix } from "@sembark-travel/number-utils"
import {
	TMoney,
	createMoney,
	divideMoneyBy,
	moneyParseByDecimal,
} from "@sembark-travel/money"

export function useGetBookingDates(
	bookingFrom?: Date | string,
	bookingTo?: Date | string
) {
	const bookingDates: Array<{ id: string; name: string; value: Date }> =
		useMemo(() => {
			if (!bookingFrom || !bookingTo) return []
			const dates = []
			const days = getDiff(bookingTo, bookingFrom, "days")
			for (let i = 0; i <= days; i++) {
				const date = startOf(addUnit(bookingFrom, i, "day"), "day")
				dates.push({
					id: formatDate(date, "YYYY-MM-DD"),
					name: `${withOrdinalSuffix(i + 1)}`,
					value: date,
				})
			}
			return dates
		}, [bookingFrom, bookingTo])
	return bookingDates
}

export type TSelectedHotel = {
	date: string
	similar_hotel_options?: Array<Omit<TSelectedHotel, "similar_hotel_options">>
	currency: string
	persons_per_room: number
	no_of_rooms: number
	per_room_given_price?: number
	adults_with_extra_bed: number
	per_adult_with_extra_bed_given_price?: number
	children_with_extra_bed: number
	per_child_with_extra_bed_given_price?: number
	children_without_extra_bed: number
	per_child_without_extra_bed_given_price?: number
	hotel: {
		extra_bed_child_age_start?: number
	}
	given_price: number
}

export type TPax = {
	id: number
	type: "adult" | "child"
	age?: number
	accommodation?: "base" | "extra_bed" | "no_extra_bed" | "complimentary"
	room_sharing_type?: number
	hotels_price: Array<TMoney>
	price: Array<TMoney>
}

export function allocatePaxInHotels<
	Pax extends TPax,
	SelectedHotel extends TSelectedHotel,
>(
	pax: Array<Pax>,
	hotels: Array<SelectedHotel>,
	{
		defaultCurrency,
		addPriceToPax,
	}: {
		defaultCurrency: string
		addPriceToPax: (p: Pax, price: Array<TMoney>) => void
	}
) {
	if (!hotels.length || !pax.length) return
	const sheets: Array<
		Pick<Pax, "type" | "accommodation" | "room_sharing_type" | "price"> & {
			booked?: boolean
			complimentary_till_age?: number
		}
	> = []
	const date = parseDate(hotels[0].date)
	hotels.forEach((h) => {
		const similarHotelWithMorePrices = h.similar_hotel_options?.find(
			(s) => s.date === h.date && s.given_price > h.given_price
		)
		if (similarHotelWithMorePrices) {
			h = similarHotelWithMorePrices as never
		}
		const sharing = h.persons_per_room
		const no_of_rooms = h.no_of_rooms
		for (let i = 0; i < sharing * no_of_rooms; i++) {
			sheets.push({
				type: "adult",
				accommodation: "base",
				room_sharing_type: sharing,
				price: [
					divideMoneyBy(
						moneyParseByDecimal(h.per_room_given_price || 0, h.currency),
						sharing,
						"ceil"
					),
				],
			})
		}
		for (let i = 0; i < h.adults_with_extra_bed; i++) {
			sheets.push({
				type: "adult",
				accommodation: "extra_bed",
				price: [
					moneyParseByDecimal(
						h.per_adult_with_extra_bed_given_price || 0,
						h.currency
					),
				],
			})
		}
		for (let i = 0; i < h.children_with_extra_bed; i++) {
			sheets.push({
				type: "child",
				accommodation: "extra_bed",
				price: [
					moneyParseByDecimal(
						h.per_child_with_extra_bed_given_price || 0,
						h.currency
					),
				],
			})
		}
		for (let i = 0; i < h.children_without_extra_bed; i++) {
			sheets.push({
				type: "child",
				accommodation: "no_extra_bed",
				price: [
					moneyParseByDecimal(
						h.per_child_without_extra_bed_given_price || 0,
						h.currency
					),
				],
			})
		}
	})
	hotels.forEach((h) => {
		const sharing = h.persons_per_room
		const no_of_rooms = h.no_of_rooms
		for (let i = 0; i < sharing * no_of_rooms; i++) {
			sheets.push({
				type: "child",
				accommodation: "complimentary",
				price: [createMoney(0, defaultCurrency)],
				complimentary_till_age: Math.max(
					0,
					(h.hotel.extra_bed_child_age_start || 0) - 1
				),
			})
		}
	})
	const allocatedPaxId: {
		[key: number]: (typeof sheets)[0]
	} = {}

	// First: Try to allocate the adults first
	pax.forEach((p) => {
		if (p.type === "child") return
		const emptySheetsForPaxType = sheets.filter(
			(s) =>
				!s.booked && s.type === p.type && s.accommodation !== "complimentary"
		)
		// For Adults, we will try to find a matching accommodation type from earlier nights
		if (!p.accommodation) {
			// try to set the accommodation
			const sheet = emptySheetsForPaxType.at(0)
			if (sheet) {
				sheet.booked = true
				p.accommodation = sheet.accommodation
				p.room_sharing_type = sheet.room_sharing_type
				addPriceToPax(p, sheet.price)
				allocatedPaxId[p.id] = sheet
			}
		} else {
			// find the extract match of orientaion
			const sheet = emptySheetsForPaxType.find(
				(sheet) =>
					p.accommodation === sheet.accommodation &&
					p.room_sharing_type === sheet.room_sharing_type
			)
			if (sheet) {
				addPriceToPax(p, sheet.price)
				sheet.booked = true
				allocatedPaxId[p.id] = sheet
			}
		}
	})

	// Second: Now try to allocated the remaining pax (adult/child) if possible
	// Adults are prioritized as they first in the list
	pax.forEach((p) => {
		if (allocatedPaxId[p.id]) return
		// adult sheet for adults, and all unbooked sheet for children (except complimentary)
		const emptySheetsForPaxType = sheets.filter(
			(s) =>
				!s.booked &&
				(p.type === "adult" ? s.type === p.type : true) &&
				s.accommodation !== "complimentary"
		)
		// we don't care about accommodation type assigned from earlier nights
		// just pick the first empty sheet
		const sheet = emptySheetsForPaxType.at(0)
		if (sheet) {
			// keep the accommodation same if the same type of sheet is allocated
			p.accommodation = !p.accommodation
				? sheet.accommodation
				: p.accommodation === sheet.accommodation
					? p.accommodation
					: "base"
			p.room_sharing_type = undefined
			addPriceToPax(p, sheet.price)
			sheet.booked = true
			allocatedPaxId[p.id] = sheet
		}
	})

	// If some unallocated children are remaining, try to allocate the pax in complimentary
	pax.forEach((p) => {
		if (allocatedPaxId[p.id]) return
		if (p.type !== "child") return
		const complimentarySheet = sheets.find(
			(sheet) =>
				sheet.accommodation === "complimentary" &&
				p.age &&
				sheet.complimentary_till_age &&
				p.age <= sheet.complimentary_till_age
		)
		if (complimentarySheet) {
			// keep it as complimentary if it was complimentary earlier
			p.accommodation = !p.accommodation
				? complimentarySheet.accommodation
				: p.accommodation === complimentarySheet.accommodation
					? p.accommodation
					: "base"
			p.room_sharing_type = undefined
			addPriceToPax(p, complimentarySheet.price)
			complimentarySheet.booked = true
			allocatedPaxId[p.id] = complimentarySheet
		}
	})
	// now throw errors for unallocated pax
	const unallocatedPax = pax.filter((p) => !allocatedPaxId[p.id])
	if (unallocatedPax.length) {
		throw new Error(
			`Can not assign accommodation for ${unallocatedPax.length} Pax for date ${formatDate(date, "DD MMM")}: ${unallocatedPax.map((p) => `${p.age ? ` ${p.age}yrs` : ``} ${p.type}`).join(", ")}`
		)
	}

	// throw if empty extra sheets are available
	const emptySheets = sheets.filter(
		(s) =>
			(s.accommodation === "extra_bed" || s.accommodation === "no_extra_bed") &&
			!s.booked
	)
	if (emptySheets.length) {
		throw new Error(
			`${emptySheets.length} unused extra bed/mattress available for date ${formatDate(date, "DD MMM")}`
		)
	}
}
