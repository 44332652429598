import {
	ITenant,
	TenantSubscriptionDetails,
} from "../../../../../Admin/Tenants"
import { useOutletContext } from "react-router-dom"
import { Helmet } from "react-helmet-async"
import { ForbidUnlessAuthorized, PERMISSIONS } from "../../../../../Auth"

export default function TenantSubscriptionDetailsPage() {
	const { tenant, refresh } = useOutletContext<{
		tenant: ITenant
		refresh: () => void
	}>()
	return (
		<>
			<Helmet>
				<title>Tenant Subscription Details</title>
			</Helmet>
			<ForbidUnlessAuthorized permission={PERMISSIONS.MANAGE_TENANTS}>
				<TenantSubscriptionDetails tenant={tenant} onChange={refresh} />
			</ForbidUnlessAuthorized>
		</>
	)
}
