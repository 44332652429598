import { Stack, Text } from "@sembark-travel/ui/base"
import { CommentableComments as CommentsList } from "./../../Comments"

export function AllComments({ tenantId }: { tenantId: number }) {
	return (
		<Stack gap="4">
			<Text color="muted">
				All tenant related comments (actionable, resolved, unresolved etc)
			</Text>
			<CommentsList commentableId={tenantId} commentableType="tenants" />
		</Stack>
	)
}

export function DemandingComment({ tenantId }: { tenantId: number }) {
	return (
		<Stack gap="4">
			<Text color="muted">
				Comments that requires attention and needs to be resolved
			</Text>
			<CommentsList
				commentableId={tenantId}
				commentableType="tenants"
				demanding
			/>
		</Stack>
	)
}

export function ActionableComment({ tenantId }: { tenantId: number }) {
	return (
		<Stack gap="4">
			<Text color="muted">
				All actionable comments (resolved and unresolved)
			</Text>
			<CommentsList
				commentableId={tenantId}
				commentableType="tenants"
				actionable
			/>
		</Stack>
	)
}

export function OverdueComment({ tenantId }: { tenantId: number }) {
	return (
		<Stack gap="4">
			<Text color="muted">
				Comments that are overdue and needs to be resolved
			</Text>
			<CommentsList
				commentableId={tenantId}
				commentableType="tenants"
				overdue
			/>
		</Stack>
	)
}
