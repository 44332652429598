import { Helmet } from "react-helmet-async"
import { PaymentsList } from "./../../../Payments"
import { ForbidUnlessAuthorized, PERMISSIONS } from "../../../Auth"

export default function OutgoingPaymentsRoute(
	props: Omit<React.ComponentProps<typeof PaymentsList>, "title" | "debit">
) {
	return (
		<>
			<Helmet>
				<title>Outgoing Payments</title>
			</Helmet>
			<ForbidUnlessAuthorized permission={PERMISSIONS.VIEW_OUTGOING_PAYMENTS}>
				<PaymentsList {...props} title="Outgoing Payments" debit />
			</ForbidUnlessAuthorized>
		</>
	)
}
