import {
	Text,
	Stack,
	Inline,
	Heading,
	Box,
	Icons,
	MoneySum,
} from "@sembark-travel/ui/base"
import { useXHR } from "@sembark-travel/xhr"
import { Link } from "@sembark-travel/ui/router"
import { generatePath } from "../router-utils"
import {
	dateToQuery,
	dateToUTCString,
	endOf,
	startOf,
	subtractUnit,
} from "@sembark-travel/datetime-utils"
import useSWR from "swr"
import { TFormattedMoney } from "@sembark-travel/money"

function useTotalDueInstalmentAmount(
	debit: boolean,
	{ dueAfter, dueBefore }: { dueAfter: Date; dueBefore: Date }
) {
	const xhr = useXHR()
	const due_after_utc = dateToUTCString(startOf(dueAfter, "day"))
	const due_before_utc = dateToUTCString(endOf(dueBefore, "day"))
	const { data } = useSWR(
		`instalments-total-amount?${debit ? "outgoing" : "incoming"}&due_after=${due_after_utc}&due_after=${due_before_utc}`,
		() =>
			xhr
				.get<{ data: Array<TFormattedMoney> }>("/instalments-total", {
					params: {
						is_due: 1,
						is_credit: !debit ? 1 : 0,
						is_debit: debit ? 1 : 0,
						due_after: due_after_utc,
						due_before: due_before_utc,
					},
				})
				.then((resp) => resp.data)
	)
	return { data }
}

function Stats({
	debit = false,
	label,
}: {
	debit?: boolean
	label: "Today" | "Yesterday"
}) {
	const date =
		label === "Today" ? new Date() : subtractUnit(new Date(), 1, "day")
	const { data } = useTotalDueInstalmentAmount(debit, {
		dueAfter: date,
		dueBefore: date,
	})
	return (
		<Stack
			as={Link}
			to={
				debit
					? generatePath("/payments/outgoing")
					: generatePath("/payments/incoming")
			}
			query={{
				status: label === "Today" ? "future_due" : "overdue",
				due_after: dateToQuery(date),
				due_before: dateToQuery(date),
			}}
			padding="2"
			rounded="md"
			bgColor={{ hover: "subtle" }}
			gap="1"
		>
			<Text fontWeight="semibold" color="muted">
				{label} <Icons.ChevronDown rotate="270" />
			</Text>
			<Text fontSize="lg" fontWeight="semibold">
				{data ? (
					data.data.length ? (
						<MoneySum money={data.data} />
					) : (
						<Icons.Ok color="success" size="6" title="All Done" />
					)
				) : (
					"..."
				)}
			</Text>
		</Stack>
	)
}

export function InstalmentsWidget({ debit }: { debit?: boolean }) {
	return (
		<Stack bgColor="default" rounded="md" borderWidth="1">
			<Box paddingX="4" paddingY="2" borderBottomWidth="1">
				<Inline gap="2" alignItems="center">
					<Heading fontSize="md" as="h3">
						Due {debit ? "Outgoing" : "Incoming"}
					</Heading>
					<Box>
						<Link
							padding="2"
							to={
								debit
									? generatePath("/payments/outgoing")
									: generatePath("/payments/incoming")
							}
							color="accent"
						>
							<Icons.ArrowLeft rotate="180" />
						</Link>
					</Box>
				</Inline>
			</Box>
			<Inline padding="2" gap="1" flexWrap="wrap">
				<Stats label="Today" debit={debit} />
				<Stats label="Yesterday" debit={debit} />
			</Inline>
		</Stack>
	)
}
