import { Helmet } from "react-helmet-async"
import { HotelBookings } from "../../../../Hotels/Item"
import { useParams } from "../../../../router-utils"

export default function HotelBookingsPage() {
	const { hotelId } = useParams("/hotels/:hotelId")
	return (
		<>
			<Helmet>
				<title>Bookings | Hotel</title>
			</Helmet>
			<HotelBookings params={{ hotels: [Number(hotelId)] }} />
		</>
	)
}
