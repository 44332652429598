import {
	Alert,
	Box,
	Button,
	Icons,
	Inline,
	Stack,
	Text,
	Heading,
} from "@sembark-travel/ui/base"
import { Dialog } from "@sembark-travel/ui/dialog"
import { Col, Divider, Grid } from "@sembark-travel/ui/base"
import { useXHR } from "@sembark-travel/xhr"
import {
	Form,
	TextInputField,
	SelectField,
	PhoneInputField,
	validateFormValues,
	SubmissionError,
	withServerErrors,
	PhoneNumberValidator,
} from "@sembark-travel/ui/form"
import { useMemo, useState } from "react"
import { Optional, Required } from "utility-types"
import * as Validator from "yup"
import {
	SelectTransporServiceProviders,
	ITransportServiceProvider,
} from "../TransportServiceProviders"
import { TDriver } from "./store"

export type NewDriverDataSchema = {
	transport_service_provider?: ITransportServiceProvider
	name: string
	phone_numbers: Array<{
		country_code: string
		phone_number: string
		number: string
	}>
	email?: string
}

export type NewDriverFormProps = {
	onSubmit: (data: NewDriverDataSchema) => Promise<void>
	onCancel?: () => void
	transportServiceProvider?: ITransportServiceProvider
	initialValues?: Optional<NewDriverDataSchema>
}

type NewDriverProps = Optional<NewDriverFormProps, "onSubmit"> & {
	onSuccess?: (driver: TDriver) => void
}

export function NewDriver({ onSuccess, ...props }: NewDriverProps) {
	const xhr = useXHR()
	return (
		<NewDriverForm
			onSubmit={async (data) => {
				const resp = await xhr.post("/drivers", {
					...data,
					transport_service_provider: data.transport_service_provider?.id,
				})
				const driver: TDriver = resp.data.data
				onSuccess?.(driver)
			}}
			{...props}
		/>
	)
}

type AddDriverInDialogProps = Required<NewDriverProps, "onCancel"> & {
	open: boolean
}

export function AddDriverInDialog({
	open,
	onSuccess,
	onCancel,
	...props
}: AddDriverInDialogProps) {
	return (
		<Dialog open={open} onClose={onCancel} title="Add New Driver">
			<Dialog.Body>
				<NewDriver
					onSuccess={onSuccess || onCancel}
					onCancel={onCancel}
					{...props}
				/>
			</Dialog.Body>
		</Dialog>
	)
}

const newDriverDataSchemaValidation = Validator.object()
	.required()
	.shape({
		name: Validator.string()
			.required("Please provider the name of the driver")
			.max(191, "Please use fewer then 191 characters for the name."),
		email: Validator.string()
			.email("Please provide a valid email address.")
			.nullable()
			.max(191, "Please use fewer than 191 characters for the email"),
		phone_numbers: Validator.array()
			.of(PhoneNumberValidator())
			.min(1, "Please provide atleast one phone number"),
	})

const validate = validateFormValues(newDriverDataSchemaValidation)

export function NewDriverForm({
	onSubmit,
	onCancel,
	transportServiceProvider,
	initialValues: initialValuesProps,
}: NewDriverFormProps) {
	const initialValues: NewDriverDataSchema = useMemo(() => {
		return {
			transport_service_provider:
				initialValuesProps?.transport_service_provider ||
				transportServiceProvider,
			name: initialValuesProps?.name || "",
			phone_numbers: initialValuesProps?.phone_numbers || [
				{
					country_code: "IN",
					phone_number: "+91",
					number: "",
				},
			],
			email: initialValuesProps?.email || "",
		}
	}, [transportServiceProvider, initialValuesProps])
	const [showSelectProvider, setSelectProviderVisibility] = useState(false)
	return (
		<Form<NewDriverDataSchema>
			initialValues={initialValues}
			validate={validate}
			onSubmit={withServerErrors(async (values) => {
				await onSubmit(values)
			})}
			subscription={{ submitting: true }}
		>
			{({ submitting, form, handleSubmit }) => (
				<form noValidate onSubmit={handleSubmit}>
					{initialValues.transport_service_provider ? null : (
						<>
							{!showSelectProvider ? (
								<Box marginBottom="8">
									<Alert
										status="warning"
										title="Single Driver Service Provider"
									>
										<Stack gap="1">
											<Text>
												You are adding a single driver. This will create a
												service provider with the same name and attach the
												driver to it.
											</Text>
											<Text>
												<Text<"a">
													as="a"
													href="#select-tsp"
													color="accent"
													fontWeight="semibold"
													onClick={(e) => {
														e.preventDefault()
														setSelectProviderVisibility(true)
													}}
												>
													Click here
												</Text>{" "}
												if you want to attach the driver to an existing
												transport service provider.
											</Text>
										</Stack>
									</Alert>
								</Box>
							) : (
								<>
									<Grid>
										<Col sm={12} md={4}>
											<Stack gap="1">
												<Heading as="h4" fontSize="base">
													<Icons.Users color="muted" /> Company
												</Heading>
												<Box color="muted">
													Please select the transport service provider company
													where the driver works.
												</Box>
											</Stack>
										</Col>
										<Col>
											<SelectField
												label="Transport Service Provider"
												select={SelectTransporServiceProviders}
												name="transport_service_provider"
											/>
											<Box>
												<Alert title="Single Driver Service Provider ?">
													<Box marginTop="1">
														<Text<"a">
															as="a"
															href="#select-tsp"
															color="primary"
															fontWeight="semibold"
															onClick={(e) => {
																e.preventDefault()
																setSelectProviderVisibility(false)
																form.change(
																	"transport_service_provider",
																	undefined
																)
															}}
														>
															Click here
														</Text>{" "}
														if you want to add a single driver service provider.
													</Box>
												</Alert>
											</Box>
										</Col>
									</Grid>
									<Divider sm />
								</>
							)}
						</>
					)}
					<Grid gap="6">
						<Col sm={12} md={4}>
							<Stack gap="1">
								<Heading as="h4" fontSize="base">
									<Icons.User color="muted" /> Contact Details
								</Heading>
								<Box as="p" color="muted">
									Please provide contact details of driver like name and phone
									number.
								</Box>
							</Stack>
						</Col>
						<Col>
							<Stack gap="4">
								<TextInputField
									name="name"
									label="Name"
									placeholder="Mahendra Singh G"
									maxWidth="sm"
									type="text"
								/>
								<PhoneInputField
									label="Phone Number(s)"
									name="phone_numbers"
									required
									multi
								/>
								<TextInputField
									label="Email"
									secondaryLabel="optional"
									name="email"
									placeholder="domain@example.com"
									type="email"
									maxWidth="xs"
								/>
							</Stack>
						</Col>
					</Grid>
					<Divider sm />
					<Grid>
						<Col sm={12} md={{ offset: 4, span: 8 }}>
							<Stack gap="4">
								<SubmissionError />
								<Inline gap="4">
									<Button type="submit" disabled={submitting}>
										Save Details
									</Button>
									{onCancel ? (
										<Button
											disabled={submitting}
											level="tertiary"
											onClick={() => onCancel()}
										>
											Cancel
										</Button>
									) : null}
								</Inline>
							</Stack>
						</Col>
					</Grid>
				</form>
			)}
		</Form>
	)
}
