import {
	formatMoneyByIntl,
	moneyParseByDecimal,
	TMoney,
} from "@sembark-travel/money"
import { Text, TextOwnProps } from "./Text"
import { Fragment } from "react"
import { Box } from "./Box"

type TMoneyProps = TextOwnProps &
	(
		| {
				currency: string
				amount: number
				money?: undefined
		  }
		| {
				currency?: undefined
				amount?: undefined
				money: TMoney
		  }
	) &
	Parameters<typeof formatMoneyByIntl>[1] & {
		title?: string
	}

export function Money({
	currency,
	amount,
	money,
	keepTrailingZero = false,
	showCurrency = false,
	roundOff = false,
	...props
}: TMoneyProps) {
	const m = money ? money : moneyParseByDecimal(amount, currency)
	const formattedValue = formatMoneyByIntl(m, {
		keepTrailingZero: keepTrailingZero,
		showCurrency: false,
		roundOff,
	})
	return (
		<Text
			as="span"
			fontVariantNumeric="tabularNums"
			title={`${m.currency} ${formattedValue}`}
			{...props}
		>
			{showCurrency ? (
				<Box
					as="sup"
					opacity="70"
					fontWeight="normal"
					fontFamily="mono"
					paddingRight="1"
				>
					{m.currency}
				</Box>
			) : null}
			{formattedValue}
		</Text>
	)
}

export function MoneySum({
	money,
	separator = " + ",
	...props
}: {
	money: Array<TMoney>
	separator?: React.ReactNode
} & Omit<TMoneyProps, "money" | "showCurrency" | "currency" | "amount">) {
	if (!money.length) return <Text as="span">N/A</Text>
	return (
		<>
			{money.map((m, i) => (
				<Fragment key={i}>
					{i > 0 ? separator : null}
					<Money money={m} showCurrency {...props} />
				</Fragment>
			))}
		</>
	)
}
