import {
	Box,
	Button,
	Select,
	Stack,
	Text,
	joinAttributes,
} from "@sembark-travel/ui/base"
import {
	MarkdownInputField,
	SelectField,
	SwitchInputField,
	isTruthy,
	useForm,
} from "@sembark-travel/ui/form"
import { showSnackbar } from "@sembark-travel/ui/snackbar"
import { TVariable } from "./store"
import { FontFamily, TFontFamily } from "./FontFamily"

export function SelectFontFamilyField({
	fontFamilies,
	help,
}: {
	fontFamilies: Array<TFontFamily>
	help?: string
}) {
	return (
		<SelectField
			select={Select}
			name="settings.fontFamily"
			label="Font Family"
			options={fontFamilies}
			optionRenderer={({ option }: { option: TFontFamily }) => (
				<FontFamily family={option}>{option.name}</FontFamily>
			)}
			clearable={false}
			help={help}
		/>
	)
}

export function JustifiedContentToggleField() {
	return (
		<SwitchInputField
			name="settings.justifiedText"
			label="Justify Text"
			help="Text should be spaced to line up its left and right edges to the left and right edges of the line box, except for the last line."
		/>
	)
}

export function GreetingsTemplateField({
	fontFamily,
	justifiedText,
	variables,
}: {
	fontFamily: TFontFamily
	justifiedText?: boolean | 0 | 1
	variables: Array<TVariable>
}) {
	const form = useForm()
	return (
		<Stack gap="4">
			<FontFamily family={fontFamily}>
				<MarkdownInputField
					name="greetings"
					previewStyles={{
						textAlign: isTruthy(justifiedText) ? "justify" : undefined,
					}}
				/>
			</FontFamily>
			<Stack>
				<Text>You can use following placeholder variables in the greeting</Text>
				<Box>
					{joinAttributes(
						...variables.map((variable) => (
							<Button
								key={variable.id}
								inline
								title={variable.description}
								onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
									e.preventDefault()
									form.change(
										"greetings",
										form.getState().values.greetings +
											` ${variable.placeholder}`
									)
									showSnackbar("Added variable to content.")
								}}
							>
								{variable.placeholder}
							</Button>
						))
					)}
				</Box>
			</Stack>
		</Stack>
	)
}
