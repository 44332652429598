import { Icons, Container } from "@sembark-travel/ui/base"
import { Breadcrumbs, ButtonLink } from "@sembark-travel/ui/router"
import { Helmet } from "react-helmet-async"
import { AddPricesForm } from "../../../HotelPrices"
import { generatePath, useNavigate } from "../../../router-utils"

export default function AddHotelPrices() {
	const navigate = useNavigate()
	function navigateBackToList() {
		return navigate("/hotel-prices")
	}
	return (
		<>
			<Helmet>
				<title>Add Hotel Prices</title>
			</Helmet>
			<Breadcrumbs
				title="Add Prices"
				items={[
					[generatePath("/hotel-prices"), "Hotel Prices"],
					["", "Add New"],
				]}
				actions={
					<ButtonLink
						to={generatePath("/hotel-prices/upload-prices")}
						level="primary"
					>
						<Icons.Upload /> Upload via CSV
					</ButtonLink>
				}
			/>
			<Container paddingY="6" bgColor="default">
				<AddPricesForm
					onSuccess={navigateBackToList}
					onCancel={navigateBackToList}
				/>
			</Container>
		</>
	)
}
