import { Helmet } from "react-helmet-async"
import { useParams } from "../../../router-utils"
import { TripSourceItemDetails } from "./../../../TripSources"

export default function TripSourceDetailsPage(
	props: Omit<
		React.ComponentProps<typeof TripSourceItemDetails>,
		"tripSourceId"
	>
) {
	const { tripSourceId } = useParams("/trip-sources/:tripSourceId")
	return (
		<>
			<Helmet>
				<title>Trip Source Details</title>
			</Helmet>
			<TripSourceItemDetails tripSourceId={tripSourceId} {...props} />
		</>
	)
}
