const name = "@sembark-turbo/web"
const appEnv = import.meta.env.REACT_APP_ENV
const version = import.meta.env.REACT_APP_VERSION || "1.0.0"

const config = {
	/**
	 * Date display format
	 */
	dateDisplayFormat: "D MMM, YYYY",
	/**
	 * Date time format for server
	 */
	timestampDateFormat: "YYYY-MM-DD HH:mm:ss",
	/**
	 * Time display format
	 */
	timeDisplayFormat: "hh:mm A",
	/**
	 * Date and Time display format
	 */
	dateTimeDisplayFormat: "D MMM, YYYY [at] hh:mm A",
	/**
	 * Date and Time with Day (Sunday, Monday etc.) display format
	 */
	dateTimeDayDisplayFormat: "dddd D MMM, YYYY [at] hh:mm A",
	/**
	 * Time format from server
	 */
	timeFormat: "HH:mm:ss",
	/**
	 * Timezone offset of utc from local (utc-local)
	 * @example -330 for 'Asia/Kolkata'
	 */
	timezoneOffset: new Date().getTimezoneOffset(),
	/**
	 * Application environment
	 */
	appEnv,
	/**
	 * Is application under development environment
	 */
	dev: import.meta.env.DEV,
	/**
	 * Application domain url
	 */
	appUrl: import.meta.env.APP_URL,
	/**
	 * Name/Code Name of the application
	 */
	appName: name,
	/**
	 * Version of the application
	 */
	appVersion: version,
	/**
	 * Title of the application
	 */
	appTitle: import.meta.env.REACT_APP_TITLE,
	/**
	 * Base url for the server
	 */
	serverBase: import.meta.env.REACT_APP_SERVER_BASE,
	/**
	 * Base url for the apis
	 */
	apiBaseUrl: import.meta.env.REACT_APP_API_BASE_URL,
	quotes: {
		/**
		 * Default tax rate (%) for when calculating effective multiplication factor in Given Quote Flow
		 */
		defaultTax: 5,
		/**
		 * Default multiplication factor for given price
		 */
		defaultFactor: 1.3,
	},
	/**
	 * Error reporting configuration
	 */
	errorReporting: {
		sentry: {
			dsn: "https://82838d665c5d4c209bd3d61fb97989b1@o464110.ingest.sentry.io/5471363",
		},
		reporter: (appEnv === "development" ? "log" : "sentry") as "log" | "sentry",
	},

	defaultCheckinAt: "12:00:00",
	defaultCheckoutAt: "11:59:00",

	supportEmail: "support@sembark.com",

	/**
	 * Commit hash
	 */
	sha: import.meta.env.REACT_APP_GIT_SHA,

	/**
	 * Google Analytics Measurement Id
	 */
	gaMeasurementId: import.meta.env.REACT_APP_GA_MEASUREMENT_ID,
	privacyPolicyUrl: "https://sembark.com/legal/privacy",
	termsOfServiceUrl: "https://sembark.com/legal/terms",
	latestReleaseNotesUrl: import.meta.env.REACT_APP_LATEST_RELEASE_NOTES_URL,
	services: {
		pusher_beams: {
			instanceId: import.meta.env.REACT_APP_PUSHER_BEAMS_INSTANCE_ID,
		},
		posthog: {
			api_host: "https://us.i.posthog.com",
			api_key: import.meta.env.REACT_APP_POSTHOG_API_KEY,
		},
	},
} as const

export default config
