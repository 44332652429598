import { Box, Container, Spinner } from "@sembark-travel/ui/base"
import { Breadcrumbs } from "@sembark-travel/ui/router"
import { Helmet } from "react-helmet-async"
import { EditUserForm, IUser, UsersXHR } from "./../../../../Users"
import {
	LoaderFunctionArgs,
	useLoaderData,
	defer,
	Await,
} from "react-router-dom"
import { redirect, useNavigate, generatePath } from "../../../../router-utils"
import { xhr } from "@sembark-travel/xhr"
import { Suspense } from "react"

export async function Loader({ params }: LoaderFunctionArgs) {
	if (!params.userId) {
		return redirect("/org/users")
	}
	const user = UsersXHR(xhr).getUser(params.userId)
	return defer({ user })
}

export default function EditUser() {
	const navigate = useNavigate()
	const { user } = useLoaderData() as { user: Promise<IUser> }
	return (
		<>
			<Helmet>
				<title>Edit User</title>
			</Helmet>
			<Suspense fallback={<Spinner padding="4" alignCenter />}>
				<Await resolve={user}>
					{(user: IUser) => (
						<>
							<Breadcrumbs
								title={`Edit ${user.name}'s Details`}
								items={[
									[generatePath("/org/users"), "Users"],
									[
										generatePath("/users/:userId", {
											userId: user.id.toString(),
										}),
										"Details",
									],
									["", "Edit"],
								]}
							/>
							<Box paddingX="4" paddingY="8">
								<Box maxWidth="xl" marginX="auto">
									<Box borderWidth="1" rounded="md" bgColor="default">
										<Container paddingY="6">
											<EditUserForm
												user={user}
												onSuccess={(user) =>
													navigate("/users/:userId", {
														params: { userId: user.id.toString() },
													})
												}
												onCancel={() =>
													navigate("/users/:userId", {
														params: { userId: user.id.toString() },
													})
												}
											/>
										</Container>
									</Box>
								</Box>
							</Box>
						</>
					)}
				</Await>
			</Suspense>
		</>
	)
}
