import { Container, Box } from "@sembark-travel/ui/base"
import {
	useGetBackUrlFromLocation,
	Breadcrumbs,
} from "@sembark-travel/ui/router"
import { Helmet } from "react-helmet-async"
import { generatePath, useNavigate } from "../../../router-utils"
import { NewTermsAndConditionItem } from "../../../TermsAndConditions"
import { ForbidUnlessAuthorized, PERMISSIONS } from "../../../Auth"

export default function NewTermsAndConditionItemPage() {
	const navigate = useNavigate()
	const backFromUrl = useGetBackUrlFromLocation()
	return (
		<>
			<Helmet>
				<title>New Terms and Condition</title>
			</Helmet>
			<ForbidUnlessAuthorized permission={PERMISSIONS.MANAGE_TRIP_OWNERS}>
				<Breadcrumbs
					title="New Terms"
					items={[
						[generatePath("/org/terms-and-conditions"), "Terms"],
						["", "New"],
					]}
				/>
				<Box paddingX={{ md: "4" }} paddingY={{ md: "8" }}>
					<Box maxWidth="4xl" marginX="auto">
						<Box borderWidth={{ md: "1" }} rounded="md" bgColor="default">
							<Container paddingY="6">
								<NewTermsAndConditionItem
									onSuccess={(tnc) =>
										navigate("/terms-and-conditions/:tncId", {
											params: {
												tncId: tnc.id.toString(),
											},
											replace: true,
										})
									}
									onCancel={() =>
										backFromUrl
											? navigate(backFromUrl as "/org/terms-and-conditions", {
													replace: true,
											  })
											: navigate("/org/terms-and-conditions", { replace: true })
									}
								/>
							</Container>
						</Box>
					</Box>
				</Box>
			</ForbidUnlessAuthorized>
		</>
	)
}
