import { Helmet } from "react-helmet-async"
import { TeamsList } from "../../../../Teams"
import { ForbidUnlessAuthorized, PERMISSIONS } from "../../../../Auth"

export default function OrganizationTeamsSettingsPage() {
	return (
		<>
			<Helmet>
				<title>Teams and Groups</title>
			</Helmet>
			<ForbidUnlessAuthorized permission={PERMISSIONS.CREATE_TEAM}>
				<TeamsList />
			</ForbidUnlessAuthorized>
		</>
	)
}
