import { Helmet } from "react-helmet-async"
import { useNavigate } from "../../../router-utils"
import { LocationForm } from "../../../Locations"

export default function NewLocation() {
	const navigate = useNavigate()
	return (
		<>
			<Helmet>
				<title>New Location</title>
			</Helmet>
			<LocationForm
				onSuccess={() => navigate("/locations")}
				onCancel={() => navigate("/locations")}
			/>
		</>
	)
}
