import { Box, Icons, Container } from "@sembark-travel/ui/base"
import { Breadcrumbs, ButtonLink } from "@sembark-travel/ui/router"
import { Helmet } from "react-helmet-async"
import { PERMISSIONS, useCheckPermissions } from "../../../Auth"
import { CreateHotel } from "../../../Hotels"
import { generatePath, useNavigate } from "../../../router-utils"

export default function NewHotel() {
	const navigate = useNavigate()
	const { hasPermission } = useCheckPermissions()
	return (
		<>
			<Helmet>
				<title>New Hotel</title>
			</Helmet>
			<Breadcrumbs
				title="New Hotel"
				items={[
					[generatePath("/hotels"), "Hotels"],
					["", "New Hotel"],
				]}
				actions={
					hasPermission(PERMISSIONS.UPLOAD_BULK_HOTELS) ? (
						<Box>
							<ButtonLink
								to={generatePath("/hotels/upload")}
								level="tertiary"
								size="sm"
							>
								<Icons.Upload /> Upload via CSV
							</ButtonLink>
						</Box>
					) : null
				}
			/>
			<Box paddingX={{ md: "4" }} paddingY={{ md: "8" }}>
				<Box maxWidth="6xl" marginX="auto">
					<Box borderWidth={{ md: "1" }} rounded="md" bgColor="default">
						<Container paddingY="6">
							<CreateHotel
								onSuccess={(hotel) =>
									navigate("/hotels/:hotelId", {
										params: { hotelId: hotel.id.toString() },
									})
								}
								onCancel={() => navigate("/hotels")}
							/>
						</Container>
					</Box>
				</Box>
			</Box>
		</>
	)
}
