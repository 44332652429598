import {
	AsyncSelect,
	AsyncSelectProps,
	Badge,
	Box,
	Button,
	Inline,
	Table,
	Stack,
	Component,
	joinAttributes,
	RelativeTime,
	TabContent,
	TabItem,
	Tabs,
	TabsList,
	Time,
} from "@sembark-travel/ui/base"
import { Dialog } from "@sembark-travel/ui/dialog"
import { ButtonLink, useLocationQuery } from "@sembark-travel/ui/router"
import {
	areAdvancedFiltersAppliedDefault,
	Search,
	useSearch,
	TSearchParams,
	ListView,
} from "@sembark-travel/ui/list"
import { utcTimestampToLocalDate } from "@sembark-travel/datetime-utils"
import { IListResponse, useXHR, XHRInstance } from "@sembark-travel/xhr"
import { Fragment, useEffect, useState } from "react"
import { Omit } from "utility-types"
import { generatePath } from "../router-utils"
import { IConnectionRequest } from "./store"
import { SwitchInputField } from "@sembark-travel/ui/form"

function XHR(xhr: XHRInstance) {
	return {
		async get(params?: unknown): Promise<IListResponse<IConnectionRequest>> {
			return xhr
				.get("/connection-requests", { params })
				.then((resp) => resp.data)
		},
	}
}

type TFilters = TSearchParams & {
	status: "initiated" | "accepted" | "rejected"
	incoming?: boolean
	outgoing?: boolean
}

type TQueryFilters = TSearchParams & {
	status: "initiated" | "accepted" | "rejected"
	incoming?: 1
	outgoing?: 1
}

function filtersToLocationQuery(filters: TFilters): TQueryFilters {
	const { q, page, status, incoming, outgoing } = filters
	const query: TQueryFilters = { status }
	if (q) query.q = q
	if (page) query.page = page
	if (incoming) query.incoming = 1
	if (outgoing) query.outgoing = 1
	return query
}

function queryToFilters(query: TQueryFilters): TFilters {
	const { q, page, status, incoming, outgoing } = query
	const filters: TFilters = { status }
	if (q) filters.q = q
	if (page) filters.page = page
	if (incoming) filters.incoming = true
	if (outgoing) filters.outgoing = true
	return filters
}

export function List() {
	const [query, setQuery] = useLocationQuery<TFilters, TQueryFilters>({
		toQuery: filtersToLocationQuery,
		fromQuery: queryToFilters,
	})
	const [params, setParams] = useSearch({
		...query,
		status: query.status || "accepted",
	})
	useEffect(() => {
		setQuery(params)
	}, [params, setQuery])
	return (
		<Fragment>
			<Search
				initialParams={params}
				onSearch={(params) => {
					setParams({ ...params, page: 1 })
				}}
				title="B2B Connection"
				Filters={Filters}
				resetParams={(params) => ({
					q: "",
					page: 1,
					status: params.status || "accepted",
				})}
				areAdvancedFiltersApplied={(params) => {
					const { status, incoming, outgoing, ...otherParams } = params
					return (
						areAdvancedFiltersAppliedDefault(otherParams) ||
						Boolean(incoming) ||
						Boolean(outgoing)
					)
				}}
				actions={
					<>
						<ButtonLink
							to={generatePath("/connection-requests/new")}
							level="primary"
						>
							New Request
						</ButtonLink>
					</>
				}
			>
				{({ searchParams, setSearchParams }) => (
					<Tabs layout="col">
						<TabsList>
							<TabItem
								active={searchParams.status === "accepted"}
								onClick={() => {
									setSearchParams({ ...searchParams, status: "accepted" })
								}}
							>
								Connected
							</TabItem>
							<TabItem
								active={searchParams.status === "initiated"}
								onClick={() => {
									setSearchParams({ ...searchParams, status: "initiated" })
								}}
							>
								Pending
							</TabItem>
							<TabItem
								active={searchParams.status === "rejected"}
								onClick={() => {
									setSearchParams({ ...searchParams, status: "rejected" })
								}}
							>
								Rejected
							</TabItem>
						</TabsList>
						<TabContent>
							<ListView<IConnectionRequest>
								pageKey="tenant-connection-requests"
								fetch={(xhr, params) => XHR(xhr).get(params)}
								onPageChange={(page) => setParams({ ...params, page })}
								params={searchParams}
							>
								{({ items, refresh }) =>
									searchParams.status === "initiated" ? (
										<PendingTenantsList items={items} refresh={refresh} />
									) : searchParams.status === "accepted" ? (
										<ConnectedTenantsList items={items} refresh={refresh} />
									) : searchParams.status === "rejected" ? (
										<RejectedTenantsList items={items} refresh={refresh} />
									) : null
								}
							</ListView>
						</TabContent>
					</Tabs>
				)}
			</Search>
		</Fragment>
	)
}

function PendingTenantsList({
	items,
	refresh,
}: {
	items: Array<IConnectionRequest>
	refresh: () => void
}) {
	const xhr = useXHR()
	return (
		<Table
			striped
			bordered
			headers={["Name", "Comments", ""]}
			responsive
			rows={items.map((item) => {
				const tenant = item.is_incoming ? item.from : item.to
				return [
					<Box>
						<Box>
							<Box>{tenant?.name || item.name}</Box>
							<Box fontSize="sm" color="muted">
								{joinAttributes(
									<Box as="span">
										by{" "}
										<Box as="a" href={`mailto:${item.created_by.email}`}>
											{item.created_by?.name}
										</Box>
									</Box>,
									<RelativeTime
										value={utcTimestampToLocalDate(item.created_at)}
									/>
								)}
							</Box>
						</Box>
					</Box>,
					<Box>
						<Box whiteSpace="preserveAndWrap" maxWidth="2xl" fontSize="sm">
							<Box>{item.comments}</Box>
						</Box>
					</Box>,
					<Box>
						{item.is_incoming ? (
							<>
								<Component initialState={false}>
									{({ state: wait, setState }) => (
										<Inline gap="2">
											<Button
												level="primary"
												disabled={wait}
												onClick={async () => {
													if (
														window.confirm(
															"Are you sure you want to accept this connection request ?"
														)
													) {
														setState(true)
														try {
															await xhr.post("/connected-tenants", {
																requests: [item.id],
															})
														} catch (e) {
															const error = e as Error
															window.alert(error.message)
														}
														setState(false)
														refresh()
													}
												}}
											>
												Accept
											</Button>
											<Button
												status="warning"
												disabled={wait}
												onClick={async () => {
													const comments = window.prompt(
														"Please provide a reason to reject so that requester can act on it"
													)
													if (comments) {
														setState(true)
														try {
															await xhr.post(
																`/connection-requests/${item.id}/reject`,
																{ comments }
															)
														} catch (e) {
															const error = e as Error
															window.alert(error.message)
														}
														setState(false)
														refresh()
													}
												}}
											>
												Reject
											</Button>
										</Inline>
									)}
								</Component>
							</>
						) : item.is_outgoing ? (
							<>
								{item.can_delete ? (
									<Component initialState={false}>
										{({ state: wait, setState }) => (
											<Button
												status="warning"
												disabled={wait}
												onClick={async () => {
													if (
														window.confirm(
															"Are you sure you want to delete this connection request?"
														)
													) {
														setState(true)
														try {
															await xhr.delete(
																`/connection-requests/${item.id}`
															)
														} catch (e) {
															console.log(e)
														}
														setState(false)
														refresh()
													}
												}}
											>
												Delete
											</Button>
										)}
									</Component>
								) : null}
							</>
						) : null}
					</Box>,
				]
			})}
		/>
	)
}

function ConnectedTenantsList({
	items,
}: {
	items: Array<IConnectionRequest>
	refresh: () => void
}) {
	return (
		<Table
			striped
			bordered
			headers={["Name", "Since"]}
			responsive
			rows={items.map((item) => {
				const tenant = item.is_incoming ? item.from : item.to
				return [
					<Box>
						<Box display="flex">
							{tenant?.profile_image_url ? (
								<Box
									as="img"
									src={tenant.profile_image_url}
									alt="Logo"
									width="8"
									height="8"
									marginRight="2"
								/>
							) : null}
							<Box>
								<Box>
									<Box marginBottom="1" fontWeight="semibold">
										{tenant?.name || item.name}
									</Box>
									<Box fontSize="sm">{tenant?.description}</Box>
								</Box>
							</Box>
						</Box>
					</Box>,
					item.accepted_at ? (
						<Box>
							<Time value={utcTimestampToLocalDate(item.accepted_at)} />
						</Box>
					) : null,
				]
			})}
		/>
	)
}

function RejectedTenantsList({
	items,
}: {
	items: Array<IConnectionRequest>
	refresh: () => void
}) {
	return (
		<Table
			striped
			bordered
			headers={["Name", "Reason", "Your Comments"]}
			responsive
			rows={items.map((item) => {
				const tenant = item.is_incoming ? item.from : item.to
				return [
					<Box>
						<Box>
							<Box>{tenant?.name || item.name}</Box>
							<Box fontSize="sm" color="muted">
								{joinAttributes(
									<Box as="span">
										by{" "}
										<Box as="a" href={`mailto:${item.created_by.email}`}>
											{item.created_by?.name}
										</Box>
									</Box>,
									<RelativeTime
										value={utcTimestampToLocalDate(item.created_at)}
									/>
								)}
							</Box>
						</Box>
					</Box>,
					<Box>
						<Box whiteSpace="preserveAndWrap" maxWidth="2xl" fontSize="sm">
							{item.rejected_at && item.rejection_reason ? (
								<Box color="warning">{item.rejection_reason}</Box>
							) : null}
							<Box fontSize="sm" color="muted">
								<RelativeTime timestamp={item.updated_at || item.created_at} />
							</Box>
						</Box>
					</Box>,
					<Box>{item.comments}</Box>,
				]
			})}
		/>
	)
}

function Filters() {
	return (
		<Stack gap="4">
			<SwitchInputField label="Incoming only" name="incoming" />
			<SwitchInputField label="Outgoing only" name="outgoing" />
		</Stack>
	)
}

export function SelectTenantsInConnection(
	props: Omit<AsyncSelectProps, "fetch">
) {
	const xhr = useXHR()
	const [newName, setNewName] = useState("")
	return (
		<>
			<AsyncSelect
				creatable
				onCreateNew={(query: string) => {
					setNewName(query)
				}}
				fetch={(q) =>
					XHR(xhr)
						.get({ q, connected_first: 1 })
						.then((resp) =>
							resp.data.map((d) => ({
								...d,
								disabled: !d.accepted_at,
							}))
						)
				}
				optionRenderer={({
					option,
					created,
				}: {
					option: IConnectionRequest
					created?: boolean
				}) => {
					if (created) {
						return <Box>Connect with "{option.name}"</Box>
					}
					return (
						<Box display="flex" gap="2">
							<Box flex="1">
								<Box>{option.name}</Box>
								<Box fontSize="sm" color="muted" marginTop="1">
									<Box textOverflow="truncate">{option.description}</Box>
								</Box>
							</Box>
							<Box textTransform="capitalize">
								<Badge>{option.status}</Badge>
							</Box>
						</Box>
					)
				}}
				{...props}
			/>
			<Dialog
				open={!!newName}
				title="Connect with a Business"
				onClose={() => {
					setNewName("")
				}}
				lg
			>
				<Dialog.Body>Form here</Dialog.Body>
			</Dialog>
		</>
	)
}
