import { IUser } from "../Auth"
import { IListResponse, XHRInstance } from "@sembark-travel/xhr"

export interface IHotelPaymentPreferenceBreakdown {
	amount_share: number
	day_offset: number
	id: number
	name: string
	reference_name: string
}

export interface IHotelPaymentPreference {
	breakdowns: IHotelPaymentPreferenceBreakdown[]
	id: number
	name: string
	hotels_count: number
	created_at: string
	created_by?: IUser
}

export function hotelPaymentPreferencesXHR(xhr: XHRInstance) {
	return {
		async getHotelPaymentPreferences(
			params?: unknown
		): Promise<IListResponse<IHotelPaymentPreference>> {
			return xhr
				.get("/hotel-payment-preferences", { params })
				.then((resp) => resp.data)
		},
		async getHotelPaymentReferences(
			params?: unknown
		): Promise<IListResponse<{ id: number; name: string }>> {
			return xhr
				.get("/hotel-payment-preferences/references", { params })
				.then((resp) => resp.data)
		},
	}
}
