// Excellent color scales
// => https://www.radix-ui.com/docs/colors/palette-composition/understanding-the-scale
import { slate, indigo, tomato, grass, amber } from "@radix-ui/colors"

export const TEXT_COLORS = {
	white: "white",
	black: slate.slate12,
	transparent: "transparent",
} as const

/**
 * 1-2 are designed for app backgrounds and subtle component backgrounds.
 * e.g.
 * - Main app background
 * - Striped table background
 * - Code block background
 * - Card background
 * - Sidebar background
 * - Canvas area background
 *
 * 3-5 and sometimes (6) are designed for UI component backgrounds.
 * - Step 3 is for normal states.
 * - Step 4 is for hover states.
 * - Step 5 is for pressed or selected states.
 * NOTE: For call-to-action components that you want to draw particular attention
 * to, you can bump each color one step higher to steps 4, 5, and 6.
 *
 * 9-10 are designed for solid backgrounds. Step 9 has the highest chroma of all steps in the scale.
 * e.g.
 * - Website/App backgrounds
 * - Website section background
 * - Header backgrounds
 * - Component backgrounds
 *   - Graphics/Logos
 *   - Overlays
 *   - Coloured shadows
 *   - Accent borders
 *
 * NOTE: Most step 9 colors are designed for white foreground text.
 * Accent and Yellow are designed for black foreground text and steps 9 and 10
 */
export const BACKGROUND_COLORS = {
	gray1: slate.slate1,
	gray2: slate.slate2,
	primary1: indigo.indigo1,
	red1: tomato.tomato1,
	red2: tomato.tomato2,
	green1: grass.grass1,
	green2: grass.grass2,
	yellow1: amber.amber1,
	yellow2: amber.amber2,
} as const

export const SIZES = {
	"0": 0,
	px: "1px",
	"1": ".25rem",
	"2": ".5rem",
	"3": ".75rem",
	"4": "1rem",
	"5": "1.25rem",
	"6": "1.5rem",
	"7": "1.75rem",
	"8": "2rem",
	"10": "2.5rem",
	"12": "3rem",
	"16": "4rem",
	"20": "5rem",
	full: "100%",
	screenHeight: "100vh",
	screenWidth: "100vw",
	auto: "auto",
	"1/2": "50%",
	"1/3": "33.333333%",
	"2/3": "66.666667",
	"1/4": "25%",
} as const

export const SPACING = {
	none: SIZES[0],
	"0": SIZES[0],
	px: SIZES.px,
	"1": SIZES[1],
	"2": SIZES[2],
	"3": SIZES[3],
	"4": SIZES[4],
	"6": SIZES[6],
	"8": SIZES[8],
	"10": SIZES[10],
	"12": SIZES[12],
	"16": SIZES[16],
	auto: "auto",
} as const

export const FONT_FAMILY = {
	sans: '"Inter", ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"',
	mono: 'ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace',
}

export const FONT_WEIGHT = {
	normal: 400,
	semibold: 600,
}

export const SCREENS = {
	sm: "640px",
	md: "768px",
	lg: "1024px",
	xl: "1280px",
}

export const BREAKPOINTS = {
	xs: {},
	sm: { "@media": `screen and (min-width: ${SCREENS.sm})` },
	md: { "@media": `screen and (min-width: ${SCREENS.md})` },
	lg: { "@media": `screen and (min-width: ${SCREENS.lg})` },
	xl: { "@media": `screen and (min-width: ${SCREENS.xl})` },
} as const

export const BREAKPOINTS_NAMES = Object.keys(BREAKPOINTS) as never as Array<
	keyof typeof BREAKPOINTS
>
