import {
	Box,
	Icons,
	Heading,
	Text,
	Stack,
	Inline,
	Divider,
} from "@sembark-travel/ui/base"
import { ButtonLink } from "@sembark-travel/ui/router"
import { useState } from "react"
import { useAuthUser } from "../../../Auth"
import { generatePath } from "../../../router-utils"
import { UploadTenantLogo } from "../../../TenantProfile"
import { Helmet } from "react-helmet-async"

export default function OnboardingUploadLogoPage() {
	const { user, fetchUser } = useAuthUser()
	const profileUrl = user?.tenant?.profile_image_url
	const [uploadedUrl, setUploadedUrl] = useState<string | undefined>(undefined)
	return (
		<>
			<Helmet>
				<title>Upload Logo | Onboarding</title>
			</Helmet>
			<Box
				maxWidth="4xl"
				marginX="auto"
				padding="8"
				borderWidth="1"
				rounded="md"
				bgColor="default"
				marginBottom="16"
			>
				<Box
					borderBottomWidth="1"
					display="inlineBlock"
					fontWeight="semibold"
					marginBottom="8"
					color="primary"
					fontSize="lg"
				>
					Onboarding &gt; Organization Logo
				</Box>
				{!uploadedUrl ? (
					<Box>
						<Heading as="h3">Upload your organization logo</Heading>
						<Text>
							Upload a logo which will be used in PDFs (quote, vouchers etc.)
							and email conversations.
						</Text>
						<Divider />
						<UploadTenantLogo
							onSuccess={(tenant) => {
								fetchUser(true)
								setUploadedUrl(tenant.profile_image_url)
							}}
						/>
						{profileUrl ? (
							<Stack
								borderWidth="1"
								padding="4"
								marginTop="8"
								rounded="md"
								bgColor="subtle"
								gap="4"
							>
								<Text>
									You have already uploaded a logo for your organization so you
									can skip this step if you want.
								</Text>
								<Box>
									<ButtonLink
										to={generatePath("/onboarding/add-destinations")}
										level="primary"
									>
										Skip to: Add Service Destinations{" "}
										<Icons.ChevronDown rotate="270" />
									</ButtonLink>
								</Box>
							</Stack>
						) : null}
					</Box>
				) : (
					<Stack gap="4">
						<Stack gap="2">
							<Heading as="h3">
								<Icons.Ok color="success" size="6" /> Logo uploaded.
							</Heading>
							<Text>
								Here is how the logo will look on different sizes (width x
								height).
							</Text>
						</Stack>
						<Inline alignItems="baseline" marginBottom="8" gap="6">
							<Stack gap="4">
								<Box>
									<img
										src={uploadedUrl}
										style={{ width: "100px", height: "100px" }}
										alt="100x100 Logo"
									/>
								</Box>
								<Stack>
									<Text fontWeight="semibold">100 x 100</Text>
									<Text fontSize="sm">Profile Page</Text>
								</Stack>
							</Stack>
							<Stack gap="4">
								<Box>
									<img
										src={uploadedUrl}
										style={{ width: "auto", height: "60px" }}
										alt="autox60 Logo"
									/>
								</Box>
								<Stack>
									<Text fontWeight="semibold">auto x 60</Text>
									<Text fontSize="sm">In PDFs</Text>
								</Stack>
							</Stack>
							<Stack gap="4">
								<Box>
									<img
										src={uploadedUrl}
										style={{ width: "36px", height: "36px" }}
										alt="36x36 Logo"
									/>
								</Box>
								<Stack>
									<Text fontWeight="semibold">36 x 36</Text>
									<Text fontSize="sm">Icon Image</Text>
								</Stack>
							</Stack>
						</Inline>
						<Box>
							If the logo is not correct, you can{" "}
							<Text<"a">
								as="a"
								color="accent"
								href="#update-logo-now"
								onClick={(e) => {
									e.preventDefault()
									setUploadedUrl(undefined)
								}}
							>
								update it now
							</Text>{" "}
							or you can update it later by visiting your organization's
							settings page.
						</Box>
						<Divider />
						<Stack gap="4">
							<Stack gap="1">
								<Heading as="h3">
									<Icons.Map opacity="50" size="6" /> Service Destinations
								</Heading>
								<Text>
									Now lets add the destinations where you provide your services
									like Sikkim, Rajasthan etc.
								</Text>
							</Stack>
							<Box>
								<ButtonLink
									to={generatePath("/onboarding/add-destinations")}
									level="primary"
								>
									Next: Add Service Destinations{" "}
									<Icons.ChevronDown rotate="270" />
								</ButtonLink>
							</Box>
						</Stack>
					</Stack>
				)}
			</Box>
		</>
	)
}
