import { AsyncSelect, Box, Inline, Text } from "@sembark-travel/ui/base"
import { IListResponse, useXHR } from "@sembark-travel/xhr"

export type TBrandColorTheme = {
	id: string
	name: string
}

export function SelectBrandColorTheme({
	...props
}: Omit<React.ComponentProps<typeof AsyncSelect>, "fetch">) {
	const xhr = useXHR()
	return (
		<AsyncSelect
			cacheKey="tenant-brand-color-themes"
			optionRenderer={({ option }: { option: TBrandColorTheme }) => (
				<Inline gap="2" alignItems="center">
					<Box
						as="span"
						display="inlineBlock"
						size="4"
						rounded="full"
						style={{
							backgroundColor: option.id,
						}}
					></Box>
					<Text>{option.name}</Text>
				</Inline>
			)}
			{...props}
			fetch={(q) =>
				xhr
					.get<IListResponse<TBrandColorTheme>>("tenant-brand-color-themes", {
						params: { q },
					})
					.then((resp) => resp.data.data)
			}
		/>
	)
}
