import { Outlet } from "react-router-dom"
import { RedirectIfAuthenticated } from "../../Auth"

export default function Layout() {
	return (
		<RedirectIfAuthenticated>
			<Outlet />
		</RedirectIfAuthenticated>
	)
}
