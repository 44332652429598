import { Box, Stack, Heading } from "@sembark-travel/ui/base"
import { ActivityLogs } from "../../../../ActivityLogs"
import { useTripDetailsOutletContext } from "./_trip-details-context"
import { Helmet } from "react-helmet-async"

export default function TripActivitiesPage() {
	const { trip } = useTripDetailsOutletContext()
	return (
		<>
			<Helmet>
				<title>Activities | Trip</title>
			</Helmet>
			<Box padding="4" bgColor="default" roundedBottom="md">
				<Stack gap="4">
					<Heading as="h5">Activities</Heading>
					<ActivityLogs subjectType="trips" subjectId={Number(trip.id)} />
				</Stack>
			</Box>
		</>
	)
}
