import { Helmet } from "react-helmet-async"
import { BasicDetailsRoute } from "../../../../Trips"
import { useTripDetailsOutletContext } from "./_trip-details-context"

export default function TripDetailsIndexPage() {
	const { trip, onChange } = useTripDetailsOutletContext()
	return (
		<>
			<Helmet>
				<title>Trip Details</title>
			</Helmet>
			<BasicDetailsRoute trip={trip} isFetching={false} onChange={onChange} />
		</>
	)
}
