import { useMemo } from "react"
import { TFontFamily } from "./store"

export type { TFontFamily }

export function FontFamily({
	family,
	children,
}: {
	family: TFontFamily
	children: React.ReactNode
}) {
	const { name, url } = family
	const classId = useMemo(
		() => ("fontFamilyContainer_" + family.id).replace(/[^\w]/, "_"),
		[family.id]
	)
	const css = useMemo(() => {
		const importUrl = url ? `@import url(${url});` : ""
		return `<style>${importUrl} .${classId} { font-family: "${name}", sans-serif; }</style>`
	}, [name, url, classId])
	return (
		<div className={classId}>
			<div dangerouslySetInnerHTML={{ __html: css }}></div>
			{children}
		</div>
	)
}
