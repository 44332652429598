// ref: https://github.com/github/markdown-toolbar-element/tree/main
import { Box, Button, ButtonProps } from "@sembark-travel/ui/base"
import { useHotkey, useNormalizedHotKey } from "@sembark-travel/ui/hotkey"

type Style = {
	prefix?: string
	suffix?: string
	trimFirst?: boolean
	multiline?: boolean
	surroundWithNewlines?: boolean
	blockPrefix?: string
	blockSuffix?: string
	replaceNext?: string
	scanFor?: string
	orderedList?: boolean
	unorderedList?: boolean
	prefixSpace?: boolean
}

type StyleArgs = Required<Style>

const manualStyles = {
	"header-1": { prefix: "# " },
	"header-2": { prefix: "## " },
	"header-3": { prefix: "### " },
	"header-4": { prefix: "#### " },
	"header-5": { prefix: "##### " },
	"header-6": { prefix: "###### " },
	bold: { prefix: "**", suffix: "**", trimFirst: true },
	italic: { prefix: "_", suffix: "_", trimFirst: true },
	quote: {
		prefix: "> ",
		multiline: true,
		surroundWithNewlines: true,
	},
	code: {
		prefix: "`",
		suffix: "`",
		blockPrefix: "```",
		blockSuffix: "```",
	},
	link: {
		prefix: "[",
		suffix: "](url)",
		replaceNext: "url",
		scanFor: "https?://",
	},
	image: {
		prefix: "![",
		suffix: "](url)",
		replaceNext: "url",
		scanFor: "https?://",
	},
	"unordered-list": {
		prefix: "- ",
		multiline: true,
		unorderedList: true,
	},
	"ordered-list": {
		prefix: "1. ",
		multiline: true,
		orderedList: true,
	},
	"task-list": {
		prefix: "- [ ] ",
		multiline: true,
		surroundWithNewlines: true,
	},
	mention: { prefix: "@", prefixSpace: true },
	ref: { prefix: "#", prefixSpace: true },
	strikethrough: {
		prefix: "~~",
		suffix: "~~",
		trimFirst: true,
	},
} as const

function keydown<T extends React.SyntheticEvent>(fn: (event: T) => void) {
	return function (event: T) {
		const key = (event as never as KeyboardEvent).key
		if (key === " " || key === "Enter") {
			fn(event)
		}
	}
}

function isMultipleLines(string: string) {
	return string.trim().split("\n").length > 1
}
function repeat(string: string, n: number) {
	return Array(n + 1).join(string)
}
function wordSelectionStart(text: string, i: number) {
	let index = i
	while (
		text[index] &&
		text[index - 1] != null &&
		!text[index - 1].match(/\s/)
	) {
		index--
	}
	return index
}
function wordSelectionEnd(text: string, i: number, multiline: boolean) {
	let index = i
	const breakpoint = multiline ? /\n/ : /\s/
	while (text[index] && !text[index].match(breakpoint)) {
		index++
	}
	return index
}

type SelectionRange = {
	text: string
	selectionStart: number | undefined
	selectionEnd: number | undefined
}

let canInsertText: boolean | null = null

function insertText(
	textarea: HTMLTextAreaElement,
	{ text, selectionStart, selectionEnd }: SelectionRange
) {
	const originalSelectionStart = textarea.selectionStart
	const before = textarea.value.slice(0, originalSelectionStart)
	const after = textarea.value.slice(textarea.selectionEnd)
	if (canInsertText === null || canInsertText === true) {
		textarea.contentEditable = "true"
		try {
			canInsertText = document.execCommand("insertText", false, text)
		} catch (error) {
			canInsertText = false
		}
		textarea.contentEditable = "false"
	}
	if (
		canInsertText &&
		!textarea.value.slice(0, textarea.selectionStart).endsWith(text)
	) {
		canInsertText = false
	}
	if (!canInsertText) {
		try {
			document.execCommand("ms-beginUndoUnit")
		} catch (e) {
			// ignore
		}
		textarea.value = before + text + after
		try {
			document.execCommand("ms-endUndoUnit")
		} catch (e) {
			// ignore
		}
		textarea.dispatchEvent(
			new CustomEvent("input", { bubbles: true, cancelable: true })
		)
	}
	if (selectionStart != null && selectionEnd != null) {
		textarea.setSelectionRange(selectionStart, selectionEnd)
	} else {
		textarea.setSelectionRange(originalSelectionStart, textarea.selectionEnd)
	}
}

function styleSelectedText(
	textarea: HTMLTextAreaElement,
	styleArgs: StyleArgs
) {
	const text = textarea.value.slice(
		textarea.selectionStart,
		textarea.selectionEnd
	)
	let result
	if (styleArgs.orderedList || styleArgs.unorderedList) {
		result = listStyle(textarea, styleArgs)
	} else if (styleArgs.multiline && isMultipleLines(text)) {
		result = multilineStyle(textarea, styleArgs)
	} else {
		result = blockStyle(textarea, styleArgs)
	}
	insertText(textarea, result)
}

function expandSelectionToLine(textarea: HTMLTextAreaElement) {
	const lines = textarea.value.split("\n")
	let counter = 0
	for (let index = 0; index < lines.length; index++) {
		const lineLength = lines[index].length + 1
		if (
			textarea.selectionStart >= counter &&
			textarea.selectionStart < counter + lineLength
		) {
			textarea.selectionStart = counter
		}
		if (
			textarea.selectionEnd >= counter &&
			textarea.selectionEnd < counter + lineLength
		) {
			textarea.selectionEnd = counter + lineLength - 1
		}
		counter += lineLength
	}
}

function expandSelectedText(
	textarea: HTMLTextAreaElement,
	prefixToUse: string,
	suffixToUse: string,
	multiline = false
): string {
	if (textarea.selectionStart === textarea.selectionEnd) {
		textarea.selectionStart = wordSelectionStart(
			textarea.value,
			textarea.selectionStart
		)
		textarea.selectionEnd = wordSelectionEnd(
			textarea.value,
			textarea.selectionEnd,
			multiline
		)
	} else {
		const expandedSelectionStart = textarea.selectionStart - prefixToUse.length
		const expandedSelectionEnd = textarea.selectionEnd + suffixToUse.length
		const beginsWithPrefix =
			textarea.value.slice(expandedSelectionStart, textarea.selectionStart) ===
			prefixToUse
		const endsWithSuffix =
			textarea.value.slice(textarea.selectionEnd, expandedSelectionEnd) ===
			suffixToUse
		if (beginsWithPrefix && endsWithSuffix) {
			textarea.selectionStart = expandedSelectionStart
			textarea.selectionEnd = expandedSelectionEnd
		}
	}
	return textarea.value.slice(textarea.selectionStart, textarea.selectionEnd)
}

type Newlines = {
	newlinesToAppend: string
	newlinesToPrepend: string
}

function newlinesToSurroundSelectedText(
	textarea: HTMLTextAreaElement
): Newlines {
	const beforeSelection = textarea.value.slice(0, textarea.selectionStart)
	const afterSelection = textarea.value.slice(textarea.selectionEnd)
	const breaksBefore = beforeSelection.match(/\n*$/)
	const breaksAfter = afterSelection.match(/^\n*/)
	const newlinesBeforeSelection = breaksBefore ? breaksBefore[0].length : 0
	const newlinesAfterSelection = breaksAfter ? breaksAfter[0].length : 0
	let newlinesToAppend
	let newlinesToPrepend
	if (beforeSelection.match(/\S/) && newlinesBeforeSelection < 2) {
		newlinesToAppend = repeat("\n", 2 - newlinesBeforeSelection)
	}
	if (afterSelection.match(/\S/) && newlinesAfterSelection < 2) {
		newlinesToPrepend = repeat("\n", 2 - newlinesAfterSelection)
	}
	if (newlinesToAppend == null) {
		newlinesToAppend = ""
	}
	if (newlinesToPrepend == null) {
		newlinesToPrepend = ""
	}
	return { newlinesToAppend, newlinesToPrepend }
}

function blockStyle(
	textarea: HTMLTextAreaElement,
	arg: StyleArgs
): SelectionRange {
	let newlinesToAppend
	let newlinesToPrepend
	const {
		prefix,
		suffix,
		blockPrefix,
		blockSuffix,
		replaceNext,
		prefixSpace,
		scanFor,
		surroundWithNewlines,
	} = arg
	const originalSelectionStart = textarea.selectionStart
	const originalSelectionEnd = textarea.selectionEnd
	let selectedText = textarea.value.slice(
		textarea.selectionStart,
		textarea.selectionEnd
	)
	let prefixToUse =
		isMultipleLines(selectedText) && blockPrefix.length > 0
			? `${blockPrefix}\n`
			: prefix
	let suffixToUse =
		isMultipleLines(selectedText) && blockSuffix.length > 0
			? `\n${blockSuffix}`
			: suffix
	if (prefixSpace) {
		const beforeSelection = textarea.value[textarea.selectionStart - 1]
		if (
			textarea.selectionStart !== 0 &&
			beforeSelection != null &&
			!beforeSelection.match(/\s/)
		) {
			prefixToUse = ` ${prefixToUse}`
		}
	}
	selectedText = expandSelectedText(
		textarea,
		prefixToUse,
		suffixToUse,
		arg.multiline
	)
	let selectionStart = textarea.selectionStart
	let selectionEnd = textarea.selectionEnd
	const hasReplaceNext =
		replaceNext.length > 0 &&
		suffixToUse.indexOf(replaceNext) > -1 &&
		selectedText.length > 0
	if (surroundWithNewlines) {
		const ref = newlinesToSurroundSelectedText(textarea)
		newlinesToAppend = ref.newlinesToAppend
		newlinesToPrepend = ref.newlinesToPrepend
		prefixToUse = newlinesToAppend + prefix
		suffixToUse += newlinesToPrepend
	}
	if (
		selectedText.startsWith(prefixToUse) &&
		selectedText.endsWith(suffixToUse)
	) {
		const replacementText = selectedText.slice(
			prefixToUse.length,
			selectedText.length - suffixToUse.length
		)
		if (originalSelectionStart === originalSelectionEnd) {
			let position = originalSelectionStart - prefixToUse.length
			position = Math.max(position, selectionStart)
			position = Math.min(position, selectionStart + replacementText.length)
			selectionStart = selectionEnd = position
		} else {
			selectionEnd = selectionStart + replacementText.length
		}
		return { text: replacementText, selectionStart, selectionEnd }
	} else if (!hasReplaceNext) {
		let replacementText = prefixToUse + selectedText + suffixToUse
		selectionStart = originalSelectionStart + prefixToUse.length
		selectionEnd = originalSelectionEnd + prefixToUse.length
		const whitespaceEdges = selectedText.match(/^\s*|\s*$/g)
		if (arg.trimFirst && whitespaceEdges) {
			const leadingWhitespace = whitespaceEdges[0] || ""
			const trailingWhitespace = whitespaceEdges[1] || ""
			replacementText =
				leadingWhitespace +
				prefixToUse +
				selectedText.trim() +
				suffixToUse +
				trailingWhitespace
			selectionStart += leadingWhitespace.length
			selectionEnd -= trailingWhitespace.length
		}
		return { text: replacementText, selectionStart, selectionEnd }
	} else if (scanFor.length > 0 && selectedText.match(scanFor)) {
		suffixToUse = suffixToUse.replace(replaceNext, selectedText)
		const replacementText = prefixToUse + suffixToUse
		selectionStart = selectionEnd = selectionStart + prefixToUse.length
		return { text: replacementText, selectionStart, selectionEnd }
	} else {
		if (scanFor.length > 0) {
			// request for scan
			let replacement: string | null
			do {
				replacement = window.prompt(
					"Please enter the resouce link url.\n\n*NOTE*: URL MUST start with http:// or https://"
				)
			} while (replacement && !replacement.match(scanFor))
			if (replacement) {
				suffixToUse = suffixToUse.replace(replaceNext, replacement || "")
				const replacementText = prefixToUse + selectedText + suffixToUse
				selectionStart = selectionEnd = selectionStart + replacementText.length
				return { text: replacementText, selectionStart, selectionEnd }
			}
		}
		const replacementText = prefixToUse + selectedText + suffixToUse
		selectionStart =
			selectionStart +
			prefixToUse.length +
			selectedText.length +
			suffixToUse.indexOf(replaceNext)
		selectionEnd = selectionStart + replaceNext.length
		return { text: replacementText, selectionStart, selectionEnd }
	}
}
function multilineStyle(textarea: HTMLTextAreaElement, arg: StyleArgs) {
	const { prefix, suffix, surroundWithNewlines } = arg
	let text = textarea.value.slice(
		textarea.selectionStart,
		textarea.selectionEnd
	)
	let selectionStart = textarea.selectionStart
	let selectionEnd = textarea.selectionEnd
	const lines = text.split("\n")
	const undoStyle = lines.every(
		(line) => line.startsWith(prefix) && line.endsWith(suffix)
	)
	if (undoStyle) {
		text = lines
			.map((line) => line.slice(prefix.length, line.length - suffix.length))
			.join("\n")
		selectionEnd = selectionStart + text.length
	} else {
		text = lines.map((line) => prefix + line + suffix).join("\n")
		if (surroundWithNewlines) {
			const { newlinesToAppend, newlinesToPrepend } =
				newlinesToSurroundSelectedText(textarea)
			selectionStart += newlinesToAppend.length
			selectionEnd = selectionStart + text.length
			text = newlinesToAppend + text + newlinesToPrepend
		}
	}
	return { text, selectionStart, selectionEnd }
}

type UndoResult = {
	text: string
	processed: boolean
}

function undoOrderedListStyle(text: string): UndoResult {
	const lines = text.split("\n")
	const orderedListRegex = /^\d+\.\s+/
	const shouldUndoOrderedList = lines.every((line) =>
		orderedListRegex.test(line)
	)
	let result = lines
	if (shouldUndoOrderedList) {
		result = lines.map((line) => line.replace(orderedListRegex, ""))
	}
	return {
		text: result.join("\n"),
		processed: shouldUndoOrderedList,
	}
}

function undoUnorderedListStyle(text: string): UndoResult {
	const lines = text.split("\n")
	const unorderedListPrefix = "- "
	const shouldUndoUnorderedList = lines.every((line) =>
		line.startsWith(unorderedListPrefix)
	)
	let result = lines
	if (shouldUndoUnorderedList) {
		result = lines.map((line) =>
			line.slice(unorderedListPrefix.length, line.length)
		)
	}
	return {
		text: result.join("\n"),
		processed: shouldUndoUnorderedList,
	}
}

function makePrefix(index: number, unorderedList?: boolean): string {
	if (unorderedList) {
		return "- "
	} else {
		return `${index + 1}. `
	}
}

function clearExistingListStyle(
	style: StyleArgs,
	selectedText: string
): [UndoResult, UndoResult, string] {
	let undoResultOpositeList: UndoResult
	let undoResult: UndoResult
	let pristineText: string
	if (style.orderedList) {
		undoResult = undoOrderedListStyle(selectedText)
		undoResultOpositeList = undoUnorderedListStyle(undoResult.text)
		pristineText = undoResultOpositeList.text
	} else {
		undoResult = undoUnorderedListStyle(selectedText)
		undoResultOpositeList = undoOrderedListStyle(undoResult.text)
		pristineText = undoResultOpositeList.text
	}
	return [undoResult, undoResultOpositeList, pristineText]
}

function listStyle(
	textarea: HTMLTextAreaElement,
	style: StyleArgs
): SelectionRange {
	const noInitialSelection = textarea.selectionStart === textarea.selectionEnd
	let selectionStart = textarea.selectionStart
	let selectionEnd = textarea.selectionEnd
	expandSelectionToLine(textarea)
	const selectedText = textarea.value.slice(
		textarea.selectionStart,
		textarea.selectionEnd
	)
	const [undoResult, undoResultOpositeList, pristineText] =
		clearExistingListStyle(style, selectedText)
	const prefixedLines = pristineText.split("\n").map((value, index) => {
		return `${makePrefix(index, style.unorderedList)}${value}`
	})
	const totalPrefixLength = prefixedLines.reduce(
		(previousValue, _currentValue, currentIndex) => {
			return (
				previousValue + makePrefix(currentIndex, style.unorderedList).length
			)
		},
		0
	)
	const totalPrefixLengthOpositeList = prefixedLines.reduce(
		(previousValue, _currentValue, currentIndex) => {
			return (
				previousValue + makePrefix(currentIndex, !style.unorderedList).length
			)
		},
		0
	)
	if (undoResult.processed) {
		if (noInitialSelection) {
			selectionStart = Math.max(
				selectionStart - makePrefix(0, style.unorderedList).length,
				0
			)
			selectionEnd = selectionStart
		} else {
			selectionStart = textarea.selectionStart
			selectionEnd = textarea.selectionEnd - totalPrefixLength
		}
		return { text: pristineText, selectionStart, selectionEnd }
	}
	const { newlinesToAppend, newlinesToPrepend } =
		newlinesToSurroundSelectedText(textarea)
	const text = newlinesToAppend + prefixedLines.join("\n") + newlinesToPrepend
	if (noInitialSelection) {
		selectionStart = Math.max(
			selectionStart +
				makePrefix(0, style.unorderedList).length +
				newlinesToAppend.length,
			0
		)
		selectionEnd = selectionStart
	} else {
		if (undoResultOpositeList.processed) {
			selectionStart = Math.max(
				textarea.selectionStart + newlinesToAppend.length,
				0
			)
			selectionEnd =
				textarea.selectionEnd +
				newlinesToAppend.length +
				totalPrefixLength -
				totalPrefixLengthOpositeList
		} else {
			selectionStart = Math.max(
				textarea.selectionStart + newlinesToAppend.length,
				0
			)
			selectionEnd =
				textarea.selectionEnd + newlinesToAppend.length + totalPrefixLength
		}
	}
	return { text, selectionStart, selectionEnd }
}

function applyStyle(textarea: HTMLTextAreaElement, stylesToApply: Style) {
	const defaults = {
		prefix: "",
		suffix: "",
		blockPrefix: "",
		blockSuffix: "",
		multiline: false,
		replaceNext: "",
		prefixSpace: false,
		scanFor: "",
		surroundWithNewlines: false,
		orderedList: false,
		unorderedList: false,
		trimFirst: false,
	}
	const style: StyleArgs = { ...defaults, ...stylesToApply }
	textarea.focus()
	styleSelectedText(textarea, style)
}

function ToolbarButton({
	styles,
	children,
	textareaRef,
	hotkey,
	title,
	...props
}: {
	hotkey?: string
	styles: Style
	children: React.ReactNode
	textareaRef: React.RefObject<HTMLTextAreaElement>
} & ButtonProps) {
	const normalizedHotKey = useNormalizedHotKey(hotkey)
	const buttonRef = useHotkey<HTMLButtonElement>(hotkey)
	return (
		<Button
			size="sm"
			level="tertiary"
			ref={buttonRef}
			title={normalizedHotKey ? `${title} [${normalizedHotKey}]` : title}
			data-hotkey-scope={textareaRef.current?.id}
			{...props}
			onClick={(event: React.SyntheticEvent) => {
				event.preventDefault()
				if (textareaRef.current) {
					applyStyle(textareaRef.current, styles)
				}
			}}
			onKeyDown={keydown((event) => {
				event.preventDefault()
				if (textareaRef.current) {
					applyStyle(textareaRef.current, styles)
				}
			})}
		>
			{children}
		</Button>
	)
}

type TToolbarButtonProps = Omit<
	React.ComponentProps<typeof ToolbarButton>,
	"styles" | "children" | "hotkey"
> & {
	children?: React.ReactNode
}

export function HeaderToolbarButton({
	level = 3,
	...props
}: { level: 1 | 2 | 3 | 4 } & Omit<TToolbarButtonProps, "level">) {
	return (
		<ToolbarButton
			title="Heading"
			{...props}
			styles={manualStyles[`header-${level}`]}
		>
			<SvgContainer>
				<path d="M3.75 2a.75.75 0 0 1 .75.75V7h7V2.75a.75.75 0 0 1 1.5 0v10.5a.75.75 0 0 1-1.5 0V8.5h-7v4.75a.75.75 0 0 1-1.5 0V2.75A.75.75 0 0 1 3.75 2Z"></path>
			</SvgContainer>
		</ToolbarButton>
	)
}

export function BoldToolbarButton(props: TToolbarButtonProps) {
	return (
		<ToolbarButton
			title="Bold"
			{...props}
			styles={manualStyles["bold"]}
			hotkey="Mod+b"
		>
			<SvgContainer>
				<path d="M4 2h4.5a3.501 3.501 0 0 1 2.852 5.53A3.499 3.499 0 0 1 9.5 14H4a1 1 0 0 1-1-1V3a1 1 0 0 1 1-1Zm1 7v3h4.5a1.5 1.5 0 0 0 0-3Zm3.5-2a1.5 1.5 0 0 0 0-3H5v3Z"></path>
			</SvgContainer>
		</ToolbarButton>
	)
}

export function ItalicToolbarButton(props: TToolbarButtonProps) {
	return (
		<ToolbarButton
			title="Italic"
			{...props}
			styles={manualStyles["italic"]}
			hotkey="Mod+i"
		>
			<SvgContainer>
				<path d="M6 2.75A.75.75 0 0 1 6.75 2h6.5a.75.75 0 0 1 0 1.5h-2.505l-3.858 9H9.25a.75.75 0 0 1 0 1.5h-6.5a.75.75 0 0 1 0-1.5h2.505l3.858-9H6.75A.75.75 0 0 1 6 2.75Z"></path>
			</SvgContainer>
		</ToolbarButton>
	)
}

export function LinkToolbarButton(props: TToolbarButtonProps) {
	return (
		<ToolbarButton
			title="Link"
			{...props}
			styles={manualStyles["link"]}
			hotkey="Mod+k"
		>
			<SvgContainer>
				<path d="m7.775 3.275 1.25-1.25a3.5 3.5 0 1 1 4.95 4.95l-2.5 2.5a3.5 3.5 0 0 1-4.95 0 .751.751 0 0 1 .018-1.042.751.751 0 0 1 1.042-.018 1.998 1.998 0 0 0 2.83 0l2.5-2.5a2.002 2.002 0 0 0-2.83-2.83l-1.25 1.25a.751.751 0 0 1-1.042-.018.751.751 0 0 1-.018-1.042Zm-4.69 9.64a1.998 1.998 0 0 0 2.83 0l1.25-1.25a.751.751 0 0 1 1.042.018.751.751 0 0 1 .018 1.042l-1.25 1.25a3.5 3.5 0 1 1-4.95-4.95l2.5-2.5a3.5 3.5 0 0 1 4.95 0 .751.751 0 0 1-.018 1.042.751.751 0 0 1-1.042.018 1.998 1.998 0 0 0-2.83 0l-2.5 2.5a1.998 1.998 0 0 0 0 2.83Z"></path>
			</SvgContainer>
		</ToolbarButton>
	)
}

export function UnorderedListToolbarButton(props: TToolbarButtonProps) {
	return (
		<ToolbarButton
			title="Unordered List"
			{...props}
			styles={manualStyles["unordered-list"]}
			hotkey="Mod+Shift+8"
		>
			<SvgContainer>
				<path d="M5.75 2.5h8.5a.75.75 0 0 1 0 1.5h-8.5a.75.75 0 0 1 0-1.5Zm0 5h8.5a.75.75 0 0 1 0 1.5h-8.5a.75.75 0 0 1 0-1.5Zm0 5h8.5a.75.75 0 0 1 0 1.5h-8.5a.75.75 0 0 1 0-1.5ZM2 14a1 1 0 1 1 0-2 1 1 0 0 1 0 2Zm1-6a1 1 0 1 1-2 0 1 1 0 0 1 2 0ZM2 4a1 1 0 1 1 0-2 1 1 0 0 1 0 2Z"></path>
			</SvgContainer>
		</ToolbarButton>
	)
}

export function OrderedListToolbarButton(props: TToolbarButtonProps) {
	return (
		<ToolbarButton
			title="Numbered List"
			{...props}
			styles={manualStyles["ordered-list"]}
			hotkey="Mod+Shift+7"
		>
			<SvgContainer>
				<path d="M5 3.25a.75.75 0 0 1 .75-.75h8.5a.75.75 0 0 1 0 1.5h-8.5A.75.75 0 0 1 5 3.25Zm0 5a.75.75 0 0 1 .75-.75h8.5a.75.75 0 0 1 0 1.5h-8.5A.75.75 0 0 1 5 8.25Zm0 5a.75.75 0 0 1 .75-.75h8.5a.75.75 0 0 1 0 1.5h-8.5a.75.75 0 0 1-.75-.75ZM.924 10.32a.5.5 0 0 1-.851-.525l.001-.001.001-.002.002-.004.007-.011c.097-.144.215-.273.348-.384.228-.19.588-.392 1.068-.392.468 0 .858.181 1.126.484.259.294.377.673.377 1.038 0 .987-.686 1.495-1.156 1.845l-.047.035c-.303.225-.522.4-.654.597h1.357a.5.5 0 0 1 0 1H.5a.5.5 0 0 1-.5-.5c0-1.005.692-1.52 1.167-1.875l.035-.025c.531-.396.8-.625.8-1.078a.57.57 0 0 0-.128-.376C1.806 10.068 1.695 10 1.5 10a.658.658 0 0 0-.429.163.835.835 0 0 0-.144.153ZM2.003 2.5V6h.503a.5.5 0 0 1 0 1H.5a.5.5 0 0 1 0-1h.503V3.308l-.28.14a.5.5 0 0 1-.446-.895l1.003-.5a.5.5 0 0 1 .723.447Z"></path>
			</SvgContainer>
		</ToolbarButton>
	)
}

function SvgContainer({ children }: { children: React.ReactNode }) {
	return (
		<Box
			as="svg"
			aria-hidden="true"
			viewBox="0 0 16 16"
			version="1.1"
			data-view-component="true"
			size="4"
			children={children}
			color="muted"
			fill="currentColor"
		/>
	)
}
