import { Helmet } from "react-helmet-async"
import { NewQuoteRequest } from "../../../../../Trips"
import { useTripDetailsOutletContext } from "../_trip-details-context"

export default function TripNewQuoteRequestPage() {
	const { trip, onChange } = useTripDetailsOutletContext()
	return (
		<>
			<Helmet>
				<title>Request Quote | Trip</title>
			</Helmet>
			<NewQuoteRequest tripId={trip.id} onChange={onChange} />
		</>
	)
}
