import {
	Text,
	Button,
	Inline,
	Stack,
	Divider,
	Table,
	TableDataCell,
	Alert,
	Heading,
} from "@sembark-travel/ui/base"
import { useXHR } from "@sembark-travel/xhr"
import { useMemo, useState } from "react"
import { Optional } from "utility-types"
import * as Validator from "yup"
import { ITermsAndCondition } from "./store"
import {
	Form,
	TextInputField,
	MarkdownInputField,
	validateFormValues,
	withServerErrors,
	SubmissionError,
	GetFieldValue,
	FieldArray,
	arrayMutators,
} from "@sembark-travel/ui/form"
import { Dialog } from "@sembark-travel/ui/dialog"
import { showSnackbar } from "@sembark-travel/ui/snackbar"

export function NewTermsAndConditionItem({
	onSuccess,
	onCancel,
}: {
	onSuccess: (tripSource: ITermsAndCondition) => void
	onCancel?: () => void
}) {
	const xhr = useXHR()
	return (
		<NewItemForm
			onCancel={onCancel}
			onSubmit={async (values: NewItemCredentials) => {
				const tnc = await xhr.post("/terms-and-conditions", values)
				onSuccess(tnc.data.data)
				return tnc
			}}
		/>
	)
}

const validationSchema = Validator.object().shape({
	name: Validator.string().required("Please provide name for the terms"),
	description: Validator.string().required(
		"Please provide some content for these terms"
	),
})

type NewItemCredentials = {
	name: string
	description: string
}

function getNonASCIIRegex(): RegExp {
	return /[^\x20-\x7E\r\t\n\s]/g
}

function isNonASCII(char: string): boolean {
	const regex = getNonASCIIRegex()
	return Boolean(regex.test(char))
}

export function NewItemForm({
	onSubmit,
	onCancel,
	initialValues: propInitialValues,
}: {
	onSubmit: (data: NewItemCredentials) => Promise<unknown>
	onCancel?: () => void
	initialValues?: Optional<NewItemCredentials>
}) {
	const initialValues = useMemo(() => {
		return {
			name: propInitialValues?.name || "",
			description: propInitialValues?.description || "",
		}
	}, [propInitialValues])
	const [nonAsciiChars, setNonAsciiChars] = useState<
		Array<{ sampleText: string; char: string }>
	>([])
	return (
		<Form<NewItemCredentials>
			initialValues={initialValues}
			validate={validateFormValues(validationSchema)}
			onSubmit={withServerErrors(async (values) => {
				// https://utf8-chartable.de/unicode-utf8-table.pl
				const regex = getNonASCIIRegex()
				let match = regex.exec(values.description)
				const nonAsciiCharsInThisSubmit: typeof nonAsciiChars = []
				while (match) {
					const char = match[0]
					const sampleText = values.description
						.slice(
							Math.max(match.index - 20, 0),
							Math.min(match.index + 20, values.description.length - 1)
						)
						.replace(/\n/g, " ")
						.trim()
					if (!nonAsciiCharsInThisSubmit.find((n) => n.char === char)) {
						nonAsciiCharsInThisSubmit.push({ sampleText, char })
					}
					match = regex.exec(values.description)
				}
				if (nonAsciiCharsInThisSubmit.length) {
					setNonAsciiChars(nonAsciiCharsInThisSubmit)
					return
				}
				return await onSubmit(values)
			})}
			subscription={{ submitting: true }}
		>
			{({ submitting, handleSubmit }) => (
				<form noValidate onSubmit={handleSubmit}>
					<Stack gap="4">
						<TextInputField
							label="Name"
							name="name"
							type="text"
							placeholder="Destination/Region Name"
							required
						/>
						<MarkdownInputField
							label="Terms and Conditions Text"
							name="description"
							placeholder="Type terms and conditions here"
							required
							rows={10}
						/>
						{nonAsciiChars?.length ? (
							<GetFieldValue<string> name="description">
								{({ value: description, onChange: changeDescription }) => (
									<Dialog
										open
										onClose={() => setNonAsciiChars([])}
										title="Replace Non-ASCII characters"
										sm
									>
										<Form<{
											data?: Array<{
												sampleText: string
												char: string
												replacer: string
											}>
										}>
											initialValues={{
												data: nonAsciiChars.map(({ sampleText, char }) => ({
													sampleText,
													char,
													replacer: char,
												})),
											}}
											validate={validateFormValues(
												Validator.object().shape({
													data: Validator.array().of(
														Validator.object().shape({
															replacer: Validator.string()
																.label("Replacer")
																.nullable()
																.test(
																	"is-ascii",
																	({ label }) =>
																		`The ${label} must be an ASCII char`,
																	(value) => {
																		if (value && isNonASCII(value)) return false
																		return true
																	}
																),
														})
													),
												})
											)}
											onSubmit={({ data }) => {
												data?.forEach(({ char, replacer }) => {
													description = description.replaceAll(char, replacer)
												})
												setNonAsciiChars([])
												changeDescription(description)
												showSnackbar("Non-ASCII replaced with given values.")
											}}
											mutators={{ ...arrayMutators }}
											subscription={{}}
										>
											{({ handleSubmit }) => (
												<form onSubmit={handleSubmit} noValidate>
													<Dialog.Body>
														<Stack gap="4">
															<Alert
																status="warning"
																title="The text contains following Non-ASCII characters."
															>
																<Stack gap="4">
																	<Stack
																		as="ul"
																		paddingLeft="6"
																		listStyleType="disc"
																	>
																		{nonAsciiChars.map((s) => (
																			<li key={s.char}>
																				<code>{s.char}</code>&nbsp;:&nbsp;
																				{s.sampleText}
																			</li>
																		))}
																	</Stack>
																	<Text>
																		These are incompatible with the software.
																	</Text>
																</Stack>
															</Alert>
															<Heading
																as="h4"
																fontWeight="semibold"
																fontSize="md"
															>
																Please provide replacers for these Non-ASCII
																characters.
															</Heading>
															<FieldArray name="data">
																{({ fields }) => (
																	<Table
																		bordered
																		striped
																		headers={["Non-ASCII", "Replace By"]}
																		alignCols={{ 0: "right" }}
																		responsive
																	>
																		<tbody>
																			{fields.map((name) => (
																				<tr key={name}>
																					<TableDataCell textAlign="right">
																						<GetFieldValue<string>
																							name={`${name}.char`}
																						>
																							{({ value }) => (
																								<>
																									<Text
																										as="span"
																										color="muted"
																										opacity="50"
																									>
																										Text
																									</Text>
																									<Text as="span">{value}</Text>
																								</>
																							)}
																						</GetFieldValue>
																					</TableDataCell>
																					<TableDataCell>
																						<TextInputField
																							name={`${name}.replacer`}
																							placeholder="Please type replace here"
																							size="sm"
																						/>
																					</TableDataCell>
																				</tr>
																			))}
																		</tbody>
																	</Table>
																)}
															</FieldArray>
														</Stack>
													</Dialog.Body>
													<Dialog.Footer>
														<Button type="submit">Replace</Button>
														<Button onClick={() => setNonAsciiChars([])}>
															Cancel
														</Button>
													</Dialog.Footer>
												</form>
											)}
										</Form>
									</Dialog>
								)}
							</GetFieldValue>
						) : null}
						<Text color="muted">
							Please avoid using your business name in Terms and Conditions.
							Business signature along with contact details will be
							automatically added to the itinerary. This also allows easy
							sharing of Terms between businesses when sharing packages.
						</Text>
						<Divider sm />
						<SubmissionError />
						<Inline gap="4">
							<Button type="submit" disabled={submitting}>
								{submitting ? "Saving..." : "Save Details"}
							</Button>
							{onCancel ? (
								<Button onClick={onCancel} level="tertiary">
									Cancel
								</Button>
							) : null}
						</Inline>
					</Stack>
				</form>
			)}
		</Form>
	)
}
