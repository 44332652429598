import { moneyConfig } from "./config"
import { formatMoneyByDecimal } from "./formatters"
import { Amount } from "./amount"
import { TCurrencyPair, TCurrency, TMoney, TCurrencyPairsObject } from "./types"
import { Calculator } from "./calculator"
import { arrayFill, getMaximumFractionDigits } from "./utils"

export function makeCurrencyPair(
	baseCurrency: TCurrency,
	counterCurrency: TCurrency,
	ratio: number
): TCurrencyPair {
	return {
		baseCurrency,
		counterCurrency,
		ratio,
	}
}

export function currencyExchangeRatioToString(ratio: number) {
	return Amount.fromNumber(Number(ratio || 0)).toString()
}

export function currencyPairToISO(pair: TCurrencyPair) {
	return `${pair.baseCurrency}/${pair.counterCurrency} ${currencyExchangeRatioToString(pair.ratio)}`
}

export function currencyPairToHuman(pair: TCurrencyPair) {
	return `1 ${pair.baseCurrency} = ${currencyExchangeRatioToString(pair.ratio)} ${pair.counterCurrency}`
}

/**
 * Convert array of pairs to object
 */
export function currencyPairsArrayToObject(
	pairs: TCurrencyPair[]
): TCurrencyPairsObject {
	return pairs.reduce<TCurrencyPairsObject>((obj, pair) => {
		if (!obj[pair.baseCurrency]) {
			obj[pair.baseCurrency] = {}
		}
		obj[pair.baseCurrency][pair.counterCurrency] = pair.ratio
		return obj
	}, {})
}

export function currencyPairsObjectToArray(
	obj: TCurrencyPairsObject
): Array<TCurrencyPair> {
	return Object.keys(obj).flatMap((baseCurrency) => {
		const rates = obj[baseCurrency]
		return Object.keys(rates).map((counterCurrency) => {
			const ratio =
				baseCurrency === counterCurrency ? 1 : Number(rates[counterCurrency])
			return makeCurrencyPair(baseCurrency, counterCurrency, ratio)
		})
	})
}

function getSubunitForCurrency(currency: TCurrency): number {
	const formatter = new Intl.NumberFormat("en-IN", {
		style: "currency",
		currency,
		currencyDisplay: "code",
	})

	return getMaximumFractionDigits(formatter)
}

export function makeCurrencyConverter(
	currencyPairs: Array<TCurrencyPair>,
	ignoreMissingExchange = false
) {
	const exchange = createExchangeFromCurrencyPairs(currencyPairs)
	return function convert(
		counterCurrency: TCurrency,
		money: TMoney | Array<TMoney>
	): TMoney {
		const arrayOfMoney = Array.isArray(money) ? money : [money]
		const counterCurrencySubunit = getSubunitForCurrency(counterCurrency)
		let amount = "0"
		arrayOfMoney.forEach((money) => {
			const baseCurrency = money.currency || moneyConfig.defaultCurrency
			let ratio = 0
			if (baseCurrency === counterCurrency || money.amount === 0) {
				ratio = 1
			} else {
				if (
					!exchange[baseCurrency] ||
					!exchange[baseCurrency][counterCurrency]
				) {
					if (!ignoreMissingExchange) {
						throw new Error(
							`Currency exchange not available from "${baseCurrency}" to "${counterCurrency}"`
						)
					}
					ratio = 0
				} else {
					ratio = exchange[baseCurrency][counterCurrency]
				}
			}
			const baseCurrencySubunit = getSubunitForCurrency(baseCurrency)
			// We CAN NOT simply multiply the base amount with ratio because
			// there can be different between the subunits
			// e.g. 100 INR => INR/JPY = 1.8 => 180 JPY => correct
			// But in our case 10000 * 1.8 => 18000 JPY => incorrect
			// To get the amount with correct subunit, we also need to handle the
			// difference between subunits and use it as division
			// JPY = INR * ratio / (10 ^ diff in base and counter subunits)
			const subunitDifference = baseCurrencySubunit - counterCurrencySubunit
			const parsedRatio = Amount.fromNumber(Number(ratio)).base10(
				subunitDifference
			)

			amount = Calculator.add(
				amount,
				Calculator.multiply(money.amount.toString(), parsedRatio.toString())
			)
		})

		return createMoney(
			Number(Calculator.ceil(amount)), // we always go the next amount for decimals
			counterCurrency
		)
	}
}

export function convertMoneyToCurrency(
	money: TMoney,
	counterCurrency: TCurrency,
	ratio: number
) {
	const counterCurrencySubunit = getSubunitForCurrency(counterCurrency)
	const baseCurrencySubunit = getSubunitForCurrency(money.currency)
	const subunitDifference = baseCurrencySubunit - counterCurrencySubunit
	const parsedRatio = Amount.fromNumber(Number(ratio)).base10(subunitDifference)
	const amount = Calculator.multiply(
		money.amount.toString(),
		parsedRatio.toString()
	)
	return createMoney(
		Number(Calculator.ceil(amount)), // we always go the next amount for decimals
		counterCurrency
	)
}

function createExchangeFromCurrencyPairs(currencyPairs: Array<TCurrencyPair>) {
	return currencyPairs.reduce<Record<string, Record<string, number>>>(
		(exchange, { baseCurrency, counterCurrency, ratio }) => {
			if (!exchange[baseCurrency]) {
				exchange[baseCurrency] = {}
			}
			exchange[baseCurrency][counterCurrency] = ratio
			return exchange
		},
		{}
	)
}

/**
 * Create a money instance from amount (in lowest currency/integer e.g. paisa for INR)
 */
export function createMoney(amount: number, currency: string): TMoney {
	const intAmount = parseInt(String(amount))
	if (isNaN(intAmount)) {
		throw new Error(
			`Invalid amount for money constructor: ${amount}, ${currency}`
		)
	}
	return {
		amount: intAmount,
		currency: currency || moneyConfig.defaultCurrency,
	}
}

export function moneyParseByDecimal(amount: number, currency: string): TMoney {
	const formatter = new Intl.NumberFormat("en-IN", {
		style: "currency",
		currency: currency || moneyConfig.defaultCurrency,
	})
	amount = Math.round(
		(Number(amount || 0) *
			Math.pow(10, getMaximumFractionDigits(formatter) + 1)) /
			10
	)
	return createMoney(amount, currency)
}

export function roundMoney(money: TMoney): TMoney {
	const formatter = new Intl.NumberFormat("en-IN", {
		style: "currency",
		currency: money.currency || moneyConfig.defaultCurrency,
	})
	const fractionDigits = getMaximumFractionDigits(formatter)
	const amount = Math.round(money.amount / Math.pow(10, fractionDigits))
	return createMoney(amount * Math.pow(10, fractionDigits), money.currency)
}

export function multipleMoneyBy(money: TMoney, by: number): TMoney {
	return createMoney(Math.round(Number(money.amount * by)), money.currency)
}

export function divideMoneyBy(
	money: TMoney,
	by: number,
	decimalRounding: "round" | "ceil" | "floor" = "round"
): TMoney {
	const newAmount = Number(money.amount / by)
	const rounder =
		decimalRounding === "ceil"
			? Math.ceil
			: decimalRounding === "floor"
				? Math.floor
				: Math.round
	return createMoney(rounder(newAmount), money.currency)
}

export function getPercentageOfMoney(
	money: TMoney,
	percentage: number
): TMoney {
	return divideMoneyBy(multipleMoneyBy(money, percentage), 100)
}

export function addPercentageToMoney(
	money: TMoney,
	percentage: number
): TMoney {
	return addMoney(money, getPercentageOfMoney(money, percentage))
}

export function isSameMoney(money: TMoney, money2: TMoney): boolean {
	return money.amount === money2.amount && money.currency === money2.currency
}

export function addMoney(money: TMoney, ...others: Array<TMoney>): TMoney {
	return [money].concat(others).reduce<TMoney>(
		(total, n) => {
			if (total.currency !== n.currency) {
				throw new Error(
					`Can not add money width different currencies, ${total.currency} & ${n.currency}`
				)
			}
			return createMoney(
				Number(total.amount) + Number(n.amount),
				total.currency
			)
		},
		createMoney(0, money.currency)
	)
}

export function subtractMoney(money1: TMoney, money2: TMoney): TMoney {
	return createMoney(
		Number(money1.amount) - Number(money2.amount),
		money1.currency
	)
}

export function addMoneyFromDifferentCurrencies(
	values: Array<TMoney>
): Array<TMoney> {
	const money = Object.values(
		values.reduce<{ [currency: string]: TMoney }>((values, money) => {
			if (values[money.currency] !== undefined) {
				values[money.currency] = addMoney(
					values[money.currency],
					createMoney(Number(money.amount) || 0, money.currency)
				)
			} else {
				values[money.currency] = createMoney(
					Number(money.amount) || 0,
					money.currency
				)
			}
			return values
		}, {})
	)

	return money.filter((money) => !isZeroMoney(money))
}

export function isZeroMoney(money: TMoney): money is TMoney & { amount: 0 } {
	return !Number(money.amount)
}

export function getMoneyRatio(
	numerator: TMoney,
	denominator: TMoney,
	fallback?: number
): number {
	if (isZeroMoney(denominator)) {
		if (arguments.length === 2) {
			throw new Error("Can not get ratio with zero denominator")
		}
		return fallback || 0
	}
	return numerator.amount / denominator.amount
}

export function roundMoneyToPoint(
	money: TMoney,
	roundingPoint: number | undefined
): TMoney {
	if (!money.amount) return money
	roundingPoint = Math.ceil(Number(roundingPoint))
	if (isNaN(roundingPoint) || !roundingPoint) return money
	const n = Math.ceil(Number(formatMoneyByDecimal(money)))
	const r = n % roundingPoint
	if (!r) return moneyParseByDecimal(n, money.currency)
	return moneyParseByDecimal(n - r + roundingPoint, money.currency)
}

export function allocateMoneyTo(money: TMoney, n: number): Array<TMoney> {
	return allocateMoneyToRatio(money, arrayFill(n, 1))
}

function allocateMoneyToRatio(
	money: TMoney,
	ratios: Array<number>
): Array<TMoney> {
	let remainder = Amount.fromNumber(money.amount)
	const results: Array<TMoney> = []
	const total = ratios.reduce((total, n) => total + n, 0)
	if (total <= 0) {
		throw new Error(
			"Cannot allocate to none, sum of ratios must be greater than zero"
		)
	}

	ratios.forEach((ratio) => {
		if (ratio < 0) {
			throw new Error("Cannot allocate to none, ratio must be zero or positive")
		}
		const share = Calculator.share(
			money.amount.toString(),
			String(ratio),
			String(total)
		)
		results.push(createMoney(Number(share), money.currency))
		remainder = Amount.fromString(
			Calculator.subtract(remainder.toString(), share)
		)
	})

	if (remainder.isZero()) {
		return results
	}

	const amount = money.amount
	const fractions = ratios.map((ratio) => {
		const share = (ratio / total) * amount
		return share - Math.floor(share)
	})

	function maxFractionIndex(fs: typeof fractions) {
		let maxIndex = 0
		fs.forEach((a, i) => {
			if (fs[maxIndex] < a) {
				maxIndex = i
			}
		})
		return maxIndex
	}
	while (!remainder.isZero() && remainder.isPositive()) {
		const index = maxFractionIndex(fractions)
		results[index] = createMoney(
			Number(Calculator.add(String(results[index].amount), "1")),
			results[index].currency
		)
		remainder = Amount.fromString(Calculator.subtract(String(remainder), "1"))
		fractions[index] = 0
	}
	return results
}
