import {
	addDuration,
	clone,
	createDuration,
	formatDate,
	parseDate,
	startOf,
} from "@sembark-travel/datetime-utils"

export function getStartEndDateTimesFromDateAndSlot(
	date: Date,
	start_time_str?: string,
	durationIso?: string
) {
	let start_date = startOf(parseDate(date), "day")
	let start_time: Date | null = null
	let duration: ReturnType<typeof createDuration> | null = null
	if (start_time_str) {
		start_time = parseDate(start_time_str, "HH:mm")
		const interval = createDuration(formatDate(start_time, "HH:mm:ss"))
		start_date = addDuration(start_date, interval)
	}
	let end_date = clone(start_date)
	let end_time: Date | null = null
	if (durationIso) {
		duration = createDuration(durationIso)
		if (start_time) {
			end_date = addDuration(start_date, duration)
			end_time = addDuration(start_time, duration)
		}
	}

	return {
		start_date,
		end_date,
		start_time: start_time ? formatDate(start_time, "HH:mm:ss") : null,
		end_time: end_time ? formatDate(end_time, "HH:mm:ss") : null,
		duration: durationIso,
	} as const
}
