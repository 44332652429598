import { Fragment } from "react"
import { Link } from "./NavLink"
import { Heading, Icons, Inline, Box, Container } from "@sembark-travel/ui/base"

type Item = [to: string, title: string]

type BreadcrumbsProps = {
	title: string
	items: Array<Item>
	actions?: React.ReactNode
}
export function Breadcrumbs({ title, items, actions }: BreadcrumbsProps) {
	let lastItemWithTo: Item | null = null
	for (let i = items.length - 1; i >= 0; i--) {
		const item = items[i]
		if (item[0]) {
			lastItemWithTo = item
			break
		}
	}
	return (
		<Container paddingY="3" borderBottomWidth="1">
			<Inline
				justifyContent="between"
				gap="4"
				flexWrap="wrap"
				alignItems={{ md: "center" }}
			>
				<Inline as="nav" gap="4" alignItems="center">
					<Inline gap="2" alignItems="center">
						<Link
							to={lastItemWithTo?.[0] || "/"}
							toBack
							padding="1"
							bgColor={{ hover: "subtle" }}
							rounded="md"
						>
							<Icons.ArrowLeft size="5" />
						</Link>
						<Heading as="h1" fontSize="md">
							{title}
						</Heading>
					</Inline>
					<Box display={{ xs: "none", md: "block" }}>
						<Inline
							color="muted"
							gap="2"
							alignItems="center"
							borderLeftWidth="1"
							paddingLeft="4"
						>
							{items.map(([to, title], index) => (
								<Fragment key={`${to}-${title}`}>
									{index !== 0 ? (
										<Box>
											<Icons.ChevronDown rotate="270" size="4" />
										</Box>
									) : null}
									<Link to={to} color={{ hover: to ? "accent" : undefined }}>
										{title}
									</Link>
								</Fragment>
							))}
						</Inline>
					</Box>
				</Inline>
				{actions ? (
					<Inline justifyContent="end" alignItems="center" flex="1">
						<Box>{actions}</Box>
					</Inline>
				) : null}
			</Inline>
		</Container>
	)
}
