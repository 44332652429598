import { Outlet } from "react-router-dom"
import { ForbidUnlessAuthorized, PERMISSIONS } from "../../../Auth"

export default function Layout() {
	return (
		<ForbidUnlessAuthorized permission={PERMISSIONS.VIEW_TENANT_CONNECTIONS}>
			<Outlet />
		</ForbidUnlessAuthorized>
	)
}
