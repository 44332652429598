import {
	Box,
	Icons,
	RadioInput,
	Paginate,
	Button,
	Heading,
	Inline,
	Stack,
	PaginateProps,
	Text,
	Container,
	Alert,
	Spinner,
	Dropdown,
	CursorPaginateProps,
	CursorPaginate,
} from "@sembark-travel/ui/base"
import {
	IListResponse,
	useXHR,
	XHRInstance,
	extendXHRInstance,
	isAbortError,
	ICursorListResponse,
} from "@sembark-travel/xhr"
import { stringify } from "qs"
import { useRef, useCallback, useMemo } from "react"
import useSWR, { SWRConfiguration } from "swr"
import { areAdvancedFiltersAppliedDefault, TSearchParams } from "./Search"
import { Required } from "utility-types"

function noItemRenderer({
	searched,
	onRefresh,
	isRefreshing,
}: {
	searched?: boolean
	onRefresh: () => void
	isRefreshing: boolean
}) {
	return (
		<Box paddingY="16">
			<Stack gap="6" alignItems="center">
				<Icons.Info size="12" color="muted" />
				<Heading as="h3" fontSize="lg" fontWeight="normal">
					{searched
						? "No results matched your search."
						: "There are not items in the list."}
				</Heading>
				<Box>
					<Button onClick={onRefresh}>
						<Icons.Refresh />{" "}
						{isRefreshing ? "Please wait..." : "Refresh Results"}
					</Button>
				</Box>
			</Stack>
		</Box>
	)
}

export type TSortOptions = Array<{
	label: string
	name: string
	type: "number" | "date"
	order: "desc" | "asc"
	removeOrderSuffix?: boolean
}>

type ListPropsWithoutPagination<Item> = {
	isFetching: boolean
	items?: Item[]
	render?: (items?: Item[]) => React.ReactNode
	children?: React.ReactNode
	emptyState?: (props: {
		searched?: boolean
		defaultValue: React.ReactNode
		onRefresh: () => void
		isRefreshing: boolean
	}) => React.ReactNode
	onRefresh?: () => void
	/**
	 * If the list searched (has some filters). Used when rendering the EmptyState
	 */
	searched?: boolean
	isRefreshing?: boolean
	sortOptions?: TSortOptions
	/**
	 * Currently select sorting option
	 */
	sortValue?: string
	onSortChange?: (sortValue?: string) => void
	removeContainerPadding?: boolean
	actions?:
		| React.ReactNode
		| ((props: { isFetching: boolean; total?: number }) => React.ReactNode)
	paginationDisabled?: boolean
}

export type ListProps<Item> = ListPropsWithoutPagination<Item> & PaginateProps

export function List<Item>({
	isFetching,
	isRefreshing = false,
	from,
	to,
	total,
	currentPage,
	onChange,
	lastPage,
	onRefresh,
	paginationDisabled,
	previousPageLabel,
	nextPageLabel,
	...props
}: ListProps<Item>) {
	const initialFetching = isFetching && total === 0
	const noItem = total === 0
	const paginationOverview = paginationDisabled ? (
		`Showing ${to} Items`
	) : (
		<>
			Showing {from} - {to} of {total} Items
		</>
	)
	const paginate =
		!paginationDisabled && (lastPage > 1 || currentPage > lastPage) ? (
			<Box textAlign="center">
				<Paginate
					total={total}
					from={from}
					to={to}
					isFetching={isRefreshing || isFetching}
					currentPage={currentPage}
					onChange={onChange}
					lastPage={lastPage}
					previousPageLabel={previousPageLabel}
					nextPageLabel={nextPageLabel}
				/>
			</Box>
		) : null
	function refresh() {
		onRefresh ? onRefresh() : onChange(currentPage)
	}

	return (
		<ListContainer<Item>
			onRefresh={refresh}
			paginate={paginate}
			paginationOverview={paginationOverview}
			noItem={noItem}
			initialFetching={initialFetching}
			isFetching={isFetching}
			isRefreshing={isRefreshing}
			total={total}
			{...props}
		/>
	)
}

type TListViewProps<IItem, TParams extends TSearchParams> = {
	pageKey: string
	params: TParams
	onPageChange: (page: number, sort?: string) => void
	children: (props: {
		items: Array<IItem>
		meta: IListResponse<IItem>["meta"]
		refresh: () => void
		error?: Error | null
		xhr: XHRInstance
	}) => React.ReactNode
	fetch: (xhr: XHRInstance, params: TParams) => Promise<IListResponse<IItem>>
	sortOptions?: TSortOptions
	swrConfig?: SWRConfiguration
} & Pick<
	ListProps<IItem>,
	| "emptyState"
	| "removeContainerPadding"
	| "actions"
	| "paginationDisabled"
	| "previousPageLabel"
	| "nextPageLabel"
>

export function ListView<IItem, TParams extends TSearchParams = TSearchParams>({
	pageKey,
	params,
	onPageChange,
	children,
	fetch,
	sortOptions,
	swrConfig,
	...props
}: TListViewProps<IItem, TParams>) {
	const xhr = useXHR()
	const abortController = useRef<AbortController>()
	const { data, mutate, isValidating, error } = useSWR<IListResponse<IItem>>(
		`/${pageKey}/${stringify(params)}`,
		async () => {
			// abort any pending requests
			abortController.current?.abort()
			abortController.current = new AbortController()
			const apiInstance = extendXHRInstance(xhr, {
				signal: abortController.current.signal,
			})
			const data = await fetch(apiInstance, params)
			abortController.current = undefined
			return data
		},
		swrConfig
	)
	const refresh = useCallback(() => mutate(), [mutate])
	if (!data && error) {
		return (
			<ErrorDisplay error={error} retry={mutate} isRetrying={isValidating} />
		)
	}
	if (!data) return <Spinner alignCenter padding="4" />
	const { data: items, meta } = data
	const {
		total,
		from,
		to,
		current_page: currentPage,
		last_page: lastPage,
	} = meta
	const { page, limit, sort, ...otherParams } = params
	return (
		<List
			total={total}
			from={from}
			to={to}
			currentPage={currentPage}
			lastPage={lastPage}
			isFetching={!data}
			isRefreshing={isValidating}
			onChange={(page) => onPageChange(page, sort)}
			onRefresh={refresh}
			searched={Boolean(
				areAdvancedFiltersAppliedDefault(otherParams as TParams)
			)}
			sortOptions={sortOptions}
			sortValue={sort}
			onSortChange={(sort) => onPageChange(currentPage, sort)}
			{...props}
		>
			<Stack gap="4">
				<ErrorDisplay error={error} retry={refresh} isRetrying={isValidating} />
				<Box cursor={isValidating ? "wait" : undefined}>
					{children({ items, refresh, error, xhr, meta })}
				</Box>
			</Stack>
		</List>
	)
}

export type CursorListProps<Item> = Required<
	ListPropsWithoutPagination<Item>,
	"items"
> &
	CursorPaginateProps

export function CursorList<Item>({
	isFetching,
	isRefreshing = false,
	nextCursor,
	prevCursor,
	currentCursor,
	perPage,
	onChange,
	onRefresh,
	paginationDisabled,
	previousPageLabel,
	nextPageLabel,
	...props
}: CursorListProps<Item>) {
	const initialFetching = isFetching && !nextCursor && !prevCursor
	const noItem = !props.items?.length
	const paginationOverview = `Showing ${Math.min(
		perPage,
		props.items.length
	)} Items`
	const paginate =
		!paginationDisabled && (nextCursor || prevCursor) ? (
			<Box textAlign="center">
				<CursorPaginate
					prevCursor={prevCursor}
					nextCursor={nextCursor}
					currentCursor={currentCursor}
					perPage={perPage}
					isFetching={isRefreshing || isFetching}
					onChange={onChange}
					previousPageLabel={previousPageLabel}
					nextPageLabel={nextPageLabel}
				/>
			</Box>
		) : null
	function refresh() {
		onRefresh ? onRefresh() : onChange(currentCursor)
	}

	return (
		<ListContainer<Item>
			onRefresh={refresh}
			paginate={paginate}
			paginationOverview={paginationOverview}
			noItem={noItem}
			initialFetching={initialFetching}
			isFetching={isFetching}
			isRefreshing={isRefreshing}
			{...props}
		/>
	)
}

type TCursorListViewProps<IItem, TParams extends TSearchParams> = {
	pageKey: string
	params: TParams
	onCursorChange: (cursor: string | null | undefined, sort?: string) => void
	children: (props: {
		items: Array<IItem>
		meta: ICursorListResponse<IItem>["meta"]
		refresh: () => void
		error?: Error | null
		xhr: XHRInstance
	}) => React.ReactNode
	fetch: (
		xhr: XHRInstance,
		params: TParams
	) => Promise<ICursorListResponse<IItem>>
	sortOptions?: TSortOptions
	swrConfig?: SWRConfiguration
} & Pick<
	CursorListProps<IItem>,
	| "emptyState"
	| "removeContainerPadding"
	| "actions"
	| "paginationDisabled"
	| "previousPageLabel"
	| "nextPageLabel"
>

export function CursorListView<
	IItem,
	TParams extends TSearchParams = TSearchParams,
>({
	pageKey,
	params,
	onCursorChange,
	children,
	fetch,
	sortOptions,
	swrConfig,
	...props
}: TCursorListViewProps<IItem, TParams>) {
	const xhr = useXHR()
	const abortController = useRef<AbortController>()
	const { cursor: currentCursor, limit, sort, ...otherParams } = params
	const { data, mutate, isValidating, error } = useSWR<
		ICursorListResponse<IItem>
	>(
		`/${pageKey}/${stringify(params)}`,
		async () => {
			// abort any pending requests
			abortController.current?.abort()
			abortController.current = new AbortController()
			const apiInstance = extendXHRInstance(xhr, {
				signal: abortController.current.signal,
			})
			const data = await fetch(apiInstance, params)
			abortController.current = undefined
			return data
		},
		swrConfig
	)
	const refresh = useCallback(() => {
		if (currentCursor) {
			// always go to first page because cursor uses ordering fields and any newer data will not be visible
			onCursorChange(undefined, sort)
		} else {
			mutate()
		}
	}, [mutate, currentCursor, sort, onCursorChange])
	if (!data && error) {
		return (
			<ErrorDisplay error={error} retry={mutate} isRetrying={isValidating} />
		)
	}
	if (!data) return <Spinner alignCenter padding="4" />
	const { data: items, meta } = data
	const {
		next_cursor: nextCursor,
		prev_cursor: prevCursor,
		per_page: perPage,
	} = meta
	return (
		<CursorList<IItem>
			nextCursor={nextCursor}
			prevCursor={prevCursor}
			perPage={perPage}
			currentCursor={currentCursor || null}
			isFetching={!data}
			isRefreshing={isValidating}
			onChange={(cursor) => {
				onCursorChange(cursor, sort)
			}}
			onRefresh={refresh}
			searched={Boolean(
				areAdvancedFiltersAppliedDefault(otherParams as TParams)
			)}
			sortOptions={sortOptions}
			sortValue={sort}
			onSortChange={(sort) => onCursorChange(undefined, sort)}
			items={items}
			{...props}
		>
			<Stack gap="4">
				<ErrorDisplay error={error} retry={refresh} isRetrying={isValidating} />
				<Box cursor={isValidating ? "wait" : undefined}>
					{children({ items, refresh, error, xhr, meta })}
				</Box>
			</Stack>
		</CursorList>
	)
}

function ListContainer<Item>({
	isFetching,
	isRefreshing = false,
	items,
	render,
	children,
	emptyState,
	onRefresh: refresh,
	searched,
	sortOptions,
	sortValue,
	onSortChange,
	removeContainerPadding,
	actions,
	initialFetching,
	noItem,
	paginationOverview,
	paginate,
	total,
}: Omit<
	ListPropsWithoutPagination<Item>,
	| "total"
	| "to"
	| "from"
	| "lastPage"
	| "currentPage"
	| "onRefresh"
	| "onChange"
> & {
	total?: number
	initialFetching: boolean
	noItem: boolean
	paginationOverview?: React.ReactNode
	paginate?: React.ReactNode
	onRefresh: () => void
}) {
	return initialFetching ? (
		<Spinner padding="8" alignCenter />
	) : noItem ? (
		<>
			{emptyState
				? emptyState({
						searched,
						defaultValue: noItemRenderer({
							searched,
							onRefresh: refresh,
							isRefreshing: isRefreshing,
						}),
						onRefresh: refresh,
						isRefreshing: isRefreshing,
					})
				: noItemRenderer({
						searched,
						onRefresh: refresh,
						isRefreshing: isRefreshing,
					})}
		</>
	) : children || render ? (
		<Container
			fluid
			paddingLeft={removeContainerPadding ? "0" : undefined}
			paddingRight={removeContainerPadding ? "0" : undefined}
			paddingTop={removeContainerPadding ? "0" : "4"}
			paddingBottom={removeContainerPadding ? "0" : "6"}
		>
			<Stack gap="4">
				<Stack gap="3">
					<Inline justifyContent="between" gap="4" alignItems="center">
						<Inline gap="4" height="6" alignItems="center">
							<Text color="muted" fontSize="sm" fontWeight="semibold">
								{paginationOverview}
							</Text>
							<Button inline disabled={isFetching} onClick={refresh}>
								<Icons.Refresh spin={isFetching || isRefreshing} />
							</Button>
						</Inline>
						{sortOptions?.length || actions ? (
							<Inline gap="2">
								<SortOptions
									options={sortOptions}
									value={sortValue}
									onChange={onSortChange}
								/>
								{actions ? (
									typeof actions === "function" ? (
										<>{actions({ total, isFetching })}</>
									) : (
										<>{actions}</>
									)
								) : null}
							</Inline>
						) : null}
					</Inline>
					<Box position="relative" cursor={isFetching ? "wait" : undefined}>
						{isFetching ? (
							<Box
								position="absolute"
								width="full"
								height="full"
								top="0"
								left="0"
								backgroundColor="subtle"
								opacity="20"
								pointerEvents="none"
								zIndex="10"
							/>
						) : null}
						{children || (render && render(items))}
					</Box>
				</Stack>
				{paginate}
			</Stack>
		</Container>
	) : null
}

function SortOptions({
	options,
	value: sortValue,
	onChange,
}: {
	options?: TSortOptions
	value?: string
	onChange?: (value?: string) => void
}) {
	const [transformedOptions, selectedOption] = useMemo(() => {
		if (!options?.length) return [[], undefined]
		let selectedOption
		const transformedOptions = options.map((option) => {
			const value = `${option.order === "desc" ? "-" : ""}${option.name}`
			const isSelected = value === sortValue
			const transformedOption = {
				...option,
				value,
				isSelected,
				title: `${option.label} ${option.removeOrderSuffix ? "" : `: ${option.order === "desc" ? "Descending" : "Ascending"}`}`,
			}
			if (isSelected) {
				selectedOption = transformedOption
			}
			return transformedOption
		})
		return [
			transformedOptions,
			selectedOption as undefined | (typeof transformedOptions)[number],
		]
	}, [options, sortValue])
	if (!transformedOptions?.length) return null
	return (
		<Dropdown alignRight>
			<Dropdown.ToggleButton title="Change Sorting Order" inline>
				Sort By <Icons.SwitchHorizontal rotate="90" />
				{!selectedOption ? null : `: ${selectedOption.title}`}
			</Dropdown.ToggleButton>
			<Dropdown.Menu>
				{transformedOptions.map(({ value, isSelected, title }) => {
					return (
						<Dropdown.MenuItem
							key={value}
							onClick={(e: React.SyntheticEvent) => {
								e.preventDefault()
								if (onChange) {
									if (isSelected) {
										onChange()
									} else {
										onChange(value)
									}
								}
							}}
						>
							<Inline gap="2">
								<RadioInput name="sort" checked={isSelected} readOnly />
								<Box flex="1">{title}</Box>
							</Inline>
						</Dropdown.MenuItem>
					)
				})}
			</Dropdown.Menu>
		</Dropdown>
	)
}

function ErrorDisplay({
	error,
	retry,
	isRetrying,
}: {
	error?: Error
	retry: () => void
	isRetrying: boolean
}) {
	return error && !isAbortError(error) ? (
		<Alert status="error">
			<Stack gap="2">
				<Text>
					{typeof error === "string"
						? error
						: typeof error === "object"
							? error.message
							: null}
				</Text>
				<Box>
					<Button
						onClick={() => retry()}
						disabled={isRetrying}
						size="sm"
						level="primary"
					>
						<Icons.Refresh spin={isRetrying} /> Try Again
					</Button>
				</Box>
			</Stack>
		</Alert>
	) : null
}
