import { Container } from "@sembark-travel/ui/base"
import { EditOrCreateItinerary } from "../../../../../../Trips/CreateItinerary"
import { Navigate, useParams } from "../../../../../../router-utils"
import { useTripDetailsOutletContext } from "../../_trip-details-context"
import { Helmet } from "react-helmet-async"

export default function EditQuoteItinerary() {
	const { trip } = useTripDetailsOutletContext()
	const { quoteId } = useParams("/trips/:tripId/quotes/:quoteId/edit-itinerary")
	if (trip.cancellation_reason) return <Navigate to="/trips" replace />
	return (
		<>
			<Helmet>
				<title>Edit Itinerary | Trip</title>
			</Helmet>
			<Container paddingY="4">
				<EditOrCreateItinerary quoteId={quoteId} />
			</Container>
		</>
	)
}
