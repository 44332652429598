import {
	Box,
	Icons,
	BoxProps,
	Tooltip,
	Button,
	Inline,
	Stack,
} from "@sembark-travel/ui/base"
import {
	CopyToClipboard,
	CopyToClipboardButton,
} from "@sembark-travel/ui/copy-to-clipboard"
import {
	Form,
	PhoneInputField,
	SubmissionError,
	withServerErrors,
} from "@sembark-travel/ui/form"
import { useRef } from "react"
import type { IPhoneNumber } from "./store"

export function PhoneNumber({
	value,
	iconOnly,
	numberOnly,
	...props
}: Omit<BoxProps<"a">, "href" | "value"> & {
	value?: string | IPhoneNumber<unknown> | Array<IPhoneNumber<unknown>>
	iconOnly?: boolean
	numberOnly?: boolean
}) {
	if (!value) return null

	let phoneNumbers: Array<string>
	if (Array.isArray(value)) {
		phoneNumbers = value.map((v) => v.phone_number)
	} else if (typeof value === "object") {
		phoneNumbers = [value.phone_number]
	} else {
		phoneNumbers = [value]
	}

	if (!phoneNumbers.length) return null

	return (
		<>
			<Tooltip
				interactive
				content={
					<Box as="ol">
						{phoneNumbers.map((value, i) => (
							<CopyToClipboard key={value}>
								{({ copyTextToClipboard }) => (
									<Box
										display="flex"
										alignItems="center"
										marginTop={i > 0 ? "2" : "0"}
										as="li"
									>
										<Box
											as="a"
											display="inlineBlock"
											color="on_emphasis"
											href={`tel:${value}`}
										>
											<Box marginRight="2" as="span" fontFamily="mono">
												{value}
											</Box>
										</Box>
										<CopyToClipboardButton
											inline
											iconOnly
											onClick={() => copyTextToClipboard(value)}
										>
											<Icons.Duplicate />
										</CopyToClipboardButton>
									</Box>
								)}
							</CopyToClipboard>
						))}
					</Box>
				}
			>
				<Box display="inlineFlex" alignItems="center">
					<Box
						as="a"
						href={`tel:${phoneNumbers[0]}`}
						display="inlineBlock"
						color="muted"
						{...props}
					>
						{!numberOnly ? <Icons.Phone opacity="50" /> : null}{" "}
						{!iconOnly ? phoneNumbers[0] : null}
					</Box>
					{phoneNumbers.length > 1 ? (
						<Box
							display="inlineBlock"
							marginLeft={!iconOnly ? "2" : "1"}
							color="muted"
							as="button"
							bgColor="transparent"
						>
							+{phoneNumbers.length - 1}
						</Box>
					) : null}
				</Box>
			</Tooltip>
		</>
	)
}

type TPhoneNumbersFormData = {
	phone_numbers: Array<{
		number?: string
		country_code: string
		is_primary: boolean
		phone_number: string
	}>
}

export function PhoneNumbersForm({
	phoneNumbers,
	onSubmit,
	onCancel,
}: {
	phoneNumbers?: Array<IPhoneNumber<unknown>>
	onSubmit: (data: {
		phone_numbers: Array<
			Omit<
				Required<TPhoneNumbersFormData["phone_numbers"][number]>,
				"phone_number"
			>
		>
	}) => Promise<unknown>
	onCancel?: () => void
}) {
	const initialValues: TPhoneNumbersFormData = useRef({
		phone_numbers: (phoneNumbers || []).map((p) => ({
			number: p.number,
			country_code: p.country_code,
			is_primary: p.is_primary,
			phone_number: p.phone_number,
		})),
	}).current
	return (
		<Form
			initialValues={initialValues}
			onSubmit={withServerErrors(async (values) => {
				await onSubmit({
					phone_numbers: (
						values.phone_numbers?.filter((p): p is Required<typeof p> =>
							Boolean(p.number)
						) || []
					).map((p) => ({
						country_code: p.country_code,
						number: p.number,
						is_primary: p.is_primary,
					})),
				})
			})}
			subscription={{ submitting: true }}
		>
			{({ submitting, handleSubmit }) => (
				<form onSubmit={handleSubmit}>
					<Stack gap="4">
						<PhoneInputField
							name="phone_numbers"
							multi
							label="Phone Number(s)"
						/>
						<SubmissionError />
						<Inline gap="4">
							<Button type="submit" disabled={submitting}>
								{submitting ? "Saving..." : "Save"}
							</Button>
							{onCancel ? (
								<Button onClick={onCancel} disabled={submitting}>
									Cancel
								</Button>
							) : null}
						</Inline>
					</Stack>
				</form>
			)}
		</Form>
	)
}
