import {
	Box,
	Button,
	Inline,
	Icons,
	Input,
	InputGroup,
	SelectInput,
	Stack,
	InputValidationErrorMessage,
} from "@sembark-travel/ui/base"
import { Field, useField } from "react-final-form"
import type { FieldRenderProps } from "react-final-form"
import { useEffect, forwardRef, useMemo } from "react"
import { isPossiblePhoneNumber } from "react-phone-number-input"
import PhoneInput, {
	getCountries,
	getCountryCallingCode,
	parsePhoneNumber,
} from "react-phone-number-input/input"
import en from "react-phone-number-input/locale/en.json"
import "react-phone-number-input/style.css"
import { Omit } from "utility-types"
import {
	AnySchema,
	object as validateObject,
	string as validateString,
} from "yup"

type CountryCode = Parameters<typeof getCountryCallingCode>[0]

export { parsePhoneNumber }

type TSingleValue = {
	number?: string
	phone_number: string
	country_code?: string
	is_primary: 1 | 0
}

export function PhoneInputField({
	label,
	secondaryLabel,
	multi,
	name,
	help,
}: {
	label?: React.ReactNode
	secondaryLabel?: React.ReactNode
	help?: React.ReactNode
	name: string
	multi?: boolean
	required?: boolean
}) {
	const {
		input: { value, onChange },
		meta: { error, touched },
	} = useField<TSingleValue | Array<TSingleValue>>(name, {
		subscription: { value: true, error: true, touched: true },
	})
	// Ensure we have atleast one item in the phone numbers when
	// adding multiple entries
	useEffect(() => {
		if (multi && (!value || (Array.isArray(value) && !value?.length))) {
			// NOTE: This will trigger an error in the component which will update it as we have subscribed to error
			onChange([
				{
					number: "",
					country_code: "IN",
					is_primary: 1,
					phone_number: `+${getCountryCallingCode("IN")}`,
				},
			])
		}
	}, [multi, value, onChange])
	// Ensure one of phone numbers is primary when adding multiple numbers
	useEffect(() => {
		if (multi && Array.isArray(value) && value?.length) {
			const arr: Array<TSingleValue> = value
			if (arr.some((item) => item.is_primary)) {
				// one of them is primary
				return
			}
			// Ensure one phone is primary (first)
			onChange(
				arr.map((a, i) =>
					i === 0 ? { ...a, is_primary: 1 } : { ...a, is_primary: 0 }
				)
			)
		}
	}, [multi, value, onChange])
	if (multi || Array.isArray(value)) {
		return (
			<Stack gap="1">
				{label ? (
					<Inline gap="2" alignItems="baseline">
						<label htmlFor={`${name}.0.phone_number`}>{label}</label>
						{secondaryLabel ? (
							<Box fontSize="sm" color="muted">
								({secondaryLabel})
							</Box>
						) : null}
					</Inline>
				) : null}
				<Stack gap="1" as="ul">
					{((value || []) as Array<TSingleValue>).map((val, index, arr) => (
						<Stack as="li" key={index} gap="1">
							<InputGroup hasError={Boolean(error) && touched}>
								<Inline width="auto">
									<Field<CountryCode>
										name={`${name}.${index}.country_code`}
										subscription={{ value: true }}
									>
										{({ input }) => (
											<CountrySelect
												name={input.name}
												labels={en}
												value={val?.country_code as CountryCode}
												onBlur={input.onBlur}
												onChange={(country_code) => {
													onChange(
														arr.map((v, i) =>
															i !== index
																? v
																: {
																		...val,
																		country_code,
																		phone_number: `+${getCountryCallingCode(
																			(country_code || "IN") as CountryCode
																		)}${val.number}`,
																		number: val.number,
																	}
														)
													)
												}}
											/>
										)}
									</Field>
									<Field
										name={`${name}.${index}.phone_number`}
										subscription={{ value: true }}
									>
										{({ input }: FieldRenderProps<string>) => (
											<PhoneInput
												id={input.name}
												data-testid={input.name}
												onBlur={input.onBlur as never}
												name={input.name}
												value={String(input.value || "")}
												defaultCountry="IN"
												country={val?.country_code as never}
												placeholder="e.g. 9779212232"
												type="tel"
												international
												onChange={(phone_number) => {
													onChange(
														arr.map((v, i) =>
															i !== index
																? v
																: {
																		...val,
																		number: phone_number
																			? parsePhoneNumber(phone_number)
																					?.nationalNumber
																			: "",
																		phone_number: phone_number as string,
																	}
														)
													)
												}}
												inputComponent={PhoneInputRaw as never}
											/>
										)}
									</Field>
									{arr.length > 1 ? (
										<>
											<Button
												title={
													val.is_primary
														? "Primary Number"
														: "Click to Make it Primary"
												}
												type="button"
												onClick={() => {
													onChange(
														arr.map((val, i) =>
															i === index
																? { ...val, is_primary: 1 }
																: { ...val, is_primary: 0 }
														)
													)
												}}
											>
												{val.is_primary ? (
													<Icons.FlagSolid color="primary" />
												) : (
													<Icons.Flag />
												)}
											</Button>
											<Button
												title="Remove this Number"
												type="button"
												onClick={() => {
													// and remove this item
													onChange(arr.filter((_, i) => i !== index))
												}}
											>
												<Icons.Cancel />
											</Button>
										</>
									) : (
										<Button
											title="Add More"
											type="button"
											onClick={() => {
												onChange(
													arr.concat([
														{
															number: "",
															country_code: val.country_code,
															is_primary: 0,
															phone_number: `+${getCountryCallingCode(
																(val.country_code || "IN") as CountryCode
															)}`,
														},
													])
												)
											}}
										>
											<Icons.PlusCircle />
										</Button>
									)}
								</Inline>
							</InputGroup>
							<ErrorMessage name={`${name}.${index}.country_code`} />
							<ErrorMessage name={`${name}.${index}.phone_number`} />
						</Stack>
					))}
					{Array.isArray(value) && value?.length > 1 ? (
						<Box as="li">
							<Button
								size="sm"
								title="Add More"
								onClick={() => {
									onChange(
										value.concat([
											{
												number: "",
												country_code: value[value.length - 1]?.country_code,
												is_primary: 0,
												phone_number: `+${getCountryCallingCode(
													(value[value.length - 1]?.country_code ||
														"IN") as CountryCode
												)}`,
											},
										])
									)
								}}
							>
								Add More
							</Button>
						</Box>
					) : null}
				</Stack>
				{help ? (
					<Box
						color="muted"
						fontSize="sm"
						letterSpacing="wide"
						fontWeight="normal"
					>
						{help}
					</Box>
				) : null}
			</Stack>
		)
	}
	return (
		<Stack gap="1">
			{label ? (
				<Inline gap="2" alignItems="baseline">
					<label htmlFor={`${name}.phone_number`}>{label}</label>
					{secondaryLabel ? (
						<Box fontSize="sm" color="muted">
							({secondaryLabel})
						</Box>
					) : null}
				</Inline>
			) : null}
			<Stack gap="1">
				<InputGroup hasError={Boolean(error) && touched}>
					<Inline width="auto">
						<Field<CountryCode>
							name={`${name}.country_code`}
							subscription={{ value: true }}
						>
							{({ input }) => (
								<CountrySelect
									labels={en}
									{...input}
									onChange={(country_code) =>
										onChange({
											...value,
											is_primary: 1,
											country_code,
											phone_number: `+${getCountryCallingCode(
												(country_code || "IN") as CountryCode
											)}${value.number}`,
										})
									}
								/>
							)}
						</Field>
						<Field name={`${name}.phone_number`} subscription={{ value: true }}>
							{({ input }: FieldRenderProps<string>) => (
								<PhoneInput
									{...input}
									defaultCountry="IN"
									type="tel"
									country={value?.country_code as CountryCode}
									international
									placeholder="9779212232"
									onChange={(phoneNumber) =>
										onChange({
											...value,
											is_primary: 1,
											number: phoneNumber
												? parsePhoneNumber(phoneNumber)?.nationalNumber
												: "",
											phone_number: phoneNumber as string,
										})
									}
									inputComponent={PhoneInputRaw as never}
								/>
							)}
						</Field>
					</Inline>
				</InputGroup>
				<ErrorMessage name={`${name}.country_code`} />
				<ErrorMessage name={`${name}.phone_number`} />
			</Stack>
			{help ? (
				<Box
					color="muted"
					fontSize="sm"
					letterSpacing="wide"
					fontWeight="normal"
				>
					{help}
				</Box>
			) : null}
		</Stack>
	)
}

function ErrorMessage({ name }: { name: string }) {
	const {
		meta: { error, touched },
	} = useField(name, { subscription: { error: true, touched: true } })
	return error && touched ? (
		<InputValidationErrorMessage>
			{Array.isArray(error) ? error.join(", ") : error}
		</InputValidationErrorMessage>
	) : null
}

const PhoneInputRaw = forwardRef<
	HTMLInputElement,
	React.ComponentProps<typeof Input>
>(function PhoneInputRaw(props, ref) {
	return (
		<Input
			minWidth="0"
			style={{ maxWidth: "10em" }}
			maxWidth="xs"
			{...props}
			ref={ref}
		/>
	)
})

function CountrySelect({
	value,
	onChange,
	labels,
	...rest
}: Omit<React.ComponentProps<typeof SelectInput>, "value" | "onChange"> & {
	value?: CountryCode
	onChange: (value?: CountryCode) => void
	labels: typeof en
}) {
	const countries = useMemo(() => {
		const countries = getCountries().map((c) => {
			const label = labels[c as unknown as keyof typeof en]
			const dial_code = getCountryCallingCode(c)
			return {
				id: c,
				code: c,
				name: `+${dial_code} - ${label}`,
				dial_code,
			}
		})
		countries.sort((a, b) => (a.dial_code > b.dial_code ? 1 : -1))
		return countries
	}, [labels])
	return (
		<SelectInput
			{...rest}
			value={value}
			width="auto"
			onChange={(event) => onChange(event.currentTarget.value as CountryCode)}
		>
			{countries.map((country) => (
				<option key={country.code} value={country.code} title={country.name}>
					{country.dial_code}-{country.code}
				</option>
			))}
		</SelectInput>
	)
}

export type TPhoneNumberField = {
	country_code: string
	phone_number: string
}

export function PhoneNumberValidator(
	label = "Phone Number",
	notRequired = false
) {
	return validateObject()
		.typeError(`Please enter a valid ${label}`)
		.shape({
			country_code: validateString().required("Invalid code"),
			phone_number: (notRequired
				? validateString().nullable()
				: validateString().required("Please enter valid number")
			).when(
				"country_code",
				(country_code: string | undefined, schema: AnySchema) => {
					return schema.test({
						name: `validate-phone-number`,
						test: (value: string | null | undefined) => {
							const currentLength = value ? String(value).trim().length : 0
							if (currentLength === 0) {
								// required attribute should handle the empty case
								return true
							}
							if (notRequired && value === country_code) return false
							return isPossiblePhoneNumber(String(value))
						},
						message: function test(params) {
							const currentLength = params.value
								? String(params.value).trim().length
								: 0
							if (
								!currentLength ||
								(notRequired && params.value === params.originalValue)
							)
								return undefined
							return `${label} is invalid.`
						},
					})
				}
			),
		})
}
