import { useLocationQuery } from "@sembark-travel/ui/router"
import { useSearch } from "@sembark-travel/ui/list"
import { useEffect } from "react"
import { Helmet } from "react-helmet-async"
import { HotelGroupList } from "./../../../HotelGroups"

export default function HotelGroupsListPage() {
	const [query, setQuery] = useLocationQuery()
	const [params, setParams] = useSearch(query)
	useEffect(() => {
		setQuery(params)
	}, [params, setQuery])
	return (
		<>
			<Helmet>
				<title>Hotel Groups</title>
			</Helmet>
			<HotelGroupList params={params} setParams={setParams} />
		</>
	)
}
