import { Helmet } from "react-helmet-async"
import { TripOperationalBookingsList } from "./../../../TripOperationalBookings"

export default function TripOperationalBookingsListPage() {
	return (
		<>
			<Helmet>
				<title>Trip Operational Bookings List</title>
			</Helmet>
			<TripOperationalBookingsList />
		</>
	)
}
