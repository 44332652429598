import { AsyncSelect, AsyncSelectProps } from "@sembark-travel/ui/base"
import { useXHR, XHRInstance } from "@sembark-travel/xhr"
import { ITag, TType } from "./store"

function XHR(xhr: XHRInstance, type: TType) {
	return {
		async getTags(params?: unknown): Promise<{ data: Array<ITag> }> {
			return xhr.get(`/${type}-tags`, { params }).then((resp) => resp.data)
		},
	}
}

interface SelectTagsProps extends Omit<AsyncSelectProps, "fetch"> {
	type: TType
}

export function SelectTags({ type, ...otherProps }: SelectTagsProps) {
	const xhr = useXHR()
	return (
		<AsyncSelect
			multiple
			creatable
			{...otherProps}
			fetch={(q) =>
				XHR(xhr, type)
					.getTags({ q })
					.then((resp) => resp.data)
			}
		/>
	)
}
