import { Box, Icons, BoxProps, Tooltip } from "@sembark-travel/ui/base"
import {
	CopyToClipboard,
	CopyToClipboardButton,
} from "@sembark-travel/ui/copy-to-clipboard"
import { queryToSearch } from "@sembark-travel/ui/router"
import { IEmail } from "./store"

export function Email({
	value,
	iconOnly,
	emailOnly,
	subject,
	body,
	children,
	...props
}: Omit<BoxProps<"a">, "value" | "href"> & {
	value?: string | IEmail<unknown>
	iconOnly?: boolean
	emailOnly?: boolean
	subject?: string
	body?: string
}) {
	if (!value) return null

	let email = ""

	if (typeof value === "object") {
		email = value.email
	} else {
		email = value
	}
	return (
		<Tooltip
			interactive
			content={
				<CopyToClipboard>
					{({ copyTextToClipboard }) => (
						<Box display="flex" alignItems="center">
							<Box marginRight="2">
								<>{email}</>
							</Box>
							<CopyToClipboardButton
								onClick={() => copyTextToClipboard(email)}
								inline
								iconOnly
							>
								<Icons.Duplicate />
							</CopyToClipboardButton>
						</Box>
					)}
				</CopyToClipboard>
			}
		>
			<Box
				as="a"
				href={`mailto:${email}${queryToSearch({
					subject,
					body,
				})}`}
				display="inlineBlock"
				color="muted"
				{...props}
			>
				{children ? (
					children
				) : (
					<>
						{!emailOnly ? <Icons.Mail opacity="50" /> : null}{" "}
						{!iconOnly ? email : null}
					</>
				)}
			</Box>
		</Tooltip>
	)
}
