import { Stack, Divider } from "@sembark-travel/ui/base"
import {
	HotelBookings,
	FlightBookings,
	TripOperationBookings,
} from "../../../../../Trips"
import { AnchoredSection } from "../../../../../shared/AnchoredSection"
import { useTripDetailsOutletContext } from "../_trip-details-context"

export default function TripServicesBookingsPage() {
	const { trip } = useTripDetailsOutletContext()
	return (
		<Stack>
			<AnchoredSection id="hotel_bookings" padding="4">
				<HotelBookings tripId={trip.id.toString()} />
			</AnchoredSection>
			{trip.converted_at ? (
				<>
					<Divider sm />
					<AnchoredSection id="operational_bookings" padding="4">
						<TripOperationBookings trip={trip} />
					</AnchoredSection>
					<Divider sm />
					<AnchoredSection id="flight_bookings" padding="4">
						<FlightBookings tripId={trip.id.toString()} />
					</AnchoredSection>
				</>
			) : null}
		</Stack>
	)
}
