import {
	Await,
	Outlet,
	ShouldRevalidateFunction,
	useLoaderData,
	ScrollRestoration,
} from "react-router-dom"
import { getStore } from "./../configureStore"
import { getUserAction } from "./../Auth"
import { xhr } from "@sembark-travel/xhr"
import {
	Box,
	Container,
	Inline,
	Spinner,
	SkipContentLink,
	MainContent,
	Stack,
} from "@sembark-travel/ui/base"
import { Suspense } from "react"
import Logo from "../images/Logo"
import { Link } from "@sembark-travel/ui/router"
import config from "../config"
import { AppFooter } from "../AppFooter"
import { Helmet } from "react-helmet-async"

export async function Loader() {
	const store = getStore()
	return {
		user: store
			? getUserAction()(store.dispatch, store.getState as never, {
					xhr,
				})
					.then(() => {
						return ""
					})
					.catch(() => {
						// IGNORE IT
						return {}
					})
			: Promise.resolve({}),
	}
}

const rootStyles: React.CSSProperties = {
	minHeight: "calc(100vh - 64px)",
	paddingBottom: ".1px",
}

export default function App() {
	const { user } = useLoaderData() as { user: Promise<unknown> }
	return (
		<>
			<Helmet
				titleTemplate={`%s | ${config.appTitle}`}
				defaultTitle={config.appTitle}
			/>
			<SkipContentLink />
			<Suspense fallback={<Skeleton />}>
				<Await resolve={user}>
					{() => (
						<Box style={rootStyles}>
							<Outlet />
						</Box>
					)}
				</Await>
			</Suspense>
			<AppFooter />
			<ScrollRestoration />
		</>
	)
}

export const ShouldRevalidate: ShouldRevalidateFunction = () => {
	return false
}

function Skeleton() {
	return (
		<Box style={rootStyles}>
			<Box as="header" position="relative" zIndex="50">
				<Box
					bgColor="black"
					color="white"
					className="app-header"
					position="relative"
					zIndex="10"
				>
					<Container fluid>
						<Inline as="nav" gap="6">
							<Box alignItems="center">
								<Box
									as={Link}
									to={"/"}
									display="inlineFlex"
									alignItems="center"
									style={{ color: "inherit", height: "3.5rem" }}
								>
									<Logo width="32" height="32" />
								</Box>
							</Box>
							<Inline flex="1" as="ul" alignItems="center">
								{config.appTitle}
							</Inline>
						</Inline>
					</Container>
				</Box>
			</Box>
			<MainContent
				style={{ height: "calc(100vh - 114px)", paddingBottom: ".1px" }}
			>
				<Stack height="full" alignItems="center" justifyContent="center">
					<Spinner padding="4" alignCenter />
					<Box padding="4" textAlign="center">
						Loading account details...
					</Box>
				</Stack>
			</MainContent>
		</Box>
	)
}
