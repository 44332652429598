import { Container, Heading, Stack, Text, Box } from "@sembark-travel/ui/base"
import { ActivityLogs } from "../ActivityLogs"
import { useAuthUser } from "../Auth"

export function MySecurityLogs() {
	const { user } = useAuthUser()
	if (!user) return null
	return (
		<Container paddingY="6">
			<Stack gap="4">
				<Stack gap="1">
					<Heading>Security Logs</Heading>
					<Text>Here are some recent security logs from your account.</Text>
				</Stack>
				<Box bgColor="default" padding="4" borderWidth="1" rounded="md">
					<ActivityLogs
						causerId={user.id}
						causerType="users"
						causerIsAuthUser
						securityLogs
					/>
				</Box>
			</Stack>
		</Container>
	)
}
