import { useSearch } from "@sembark-travel/ui/list"
import { ButtonLink, useLocationQuery } from "@sembark-travel/ui/router"
import { useEffect } from "react"
import { Helmet } from "react-helmet-async"
import { LocationsList } from "./../../../Locations"
import { generatePath } from "./../../../router-utils"

export default function Locations() {
	const [query, setQuery] = useLocationQuery()
	const [params, setParams] = useSearch(query)
	useEffect(() => {
		setQuery(params)
	}, [params, setQuery])
	return (
		<>
			<Helmet>
				<title>Locations</title>
			</Helmet>
			<LocationsList
				params={params}
				setParams={setParams}
				actions={
					<ButtonLink to={generatePath("/locations/new")}>
						New Location
					</ButtonLink>
				}
			/>
		</>
	)
}
