import { Box, Container, Stack, Heading } from "@sembark-travel/ui/base"
import { Breadcrumbs } from "@sembark-travel/ui/router"
import { Helmet } from "react-helmet-async"
import { EditTeam } from "./../../../../Teams"
import {
	generatePath,
	useNavigate,
	useParams,
} from "./../../../../router-utils"

export default function EditTeamPage() {
	const { teamId } = useParams("/teams/:teamId")
	const navigate = useNavigate()
	return (
		<>
			<Helmet>
				<title>Edit Team</title>
			</Helmet>
			<Breadcrumbs
				title="Edit Team"
				items={[
					[generatePath("/org/teams"), "Teams"],
					[generatePath("/teams/:teamId", { teamId }), teamId],
					["", "Edit"],
				]}
			/>
			<Box paddingX="4" paddingY="8">
				<Box maxWidth="3xl" marginX="auto">
					<Stack gap="4">
						<Stack gap="1">
							<Heading>Edit Team's Details</Heading>
						</Stack>
						<Box borderWidth="1" rounded="md" bgColor="default">
							<Container paddingY="6">
								<EditTeam
									teamId={teamId}
									onSuccess={(team) =>
										navigate("/teams/:teamId", {
											params: {
												teamId: team.id.toString(),
											},
											replace: true,
										})
									}
									onCancel={() =>
										navigate("/teams/:teamId", {
											params: {
												teamId,
											},
											replace: true,
										})
									}
								/>
							</Container>
						</Box>
					</Stack>
				</Box>
			</Box>
		</>
	)
}
