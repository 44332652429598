import {
	Button,
	Inline,
	Icons,
	Stack,
	Col,
	Grid,
	Divider,
	Text,
} from "@sembark-travel/ui/base"
import { useDialog, Dialog } from "@sembark-travel/ui/dialog"
import { useXHR, XHRInstance } from "@sembark-travel/xhr"
import {
	Form,
	TextInputField,
	SelectField,
	validateFormValues,
	SubmissionError,
	TextAreaInputField,
	withServerErrors,
	EmptyNumberValidator,
	DatePickerField,
} from "@sembark-travel/ui/form"
import { useMemo } from "react"
import { Optional } from "utility-types"
import * as Validator from "yup"
import { SelectTripDestinationFromRepository } from "./NewItem"
import { TTripDestination } from "./store"
import { SelectTenantCurrencyInputField } from "../Currencies"
import { showSnackbar } from "@sembark-travel/ui/snackbar"
import { formatDate, parseDate } from "@sembark-travel/datetime-utils"

function XHR(xhr: XHRInstance) {
	return {
		async update(
			destinationId: number | string,
			data: unknown
		): Promise<TTripDestination> {
			const destinationsData = await xhr.patch(
				`/trip-destinations/${destinationId}`,
				data
			)
			return destinationsData.data.data
		},
	}
}

type TFormParams = {
	name: TTripDestination
	short_name: string
	description?: string
	currency: string
	max_child_age: number
	hotel_checkin_at: Date
	hotel_checkout_at: Date
}

export function EditTripDestination({
	onSuccess,
	onCancel,
	destination,
}: {
	onSuccess: (destination: TTripDestination) => void
	onCancel?: () => void
	destination: TTripDestination
}) {
	const xhr = useXHR()

	const initialValues: TFormParams = useMemo(() => {
		return {
			name: destination,
			short_name: destination.short_name,
			description: destination.description,
			currency: destination.currency,
			max_child_age: destination.max_child_age,
			hotel_checkin_at: parseDate(destination.hotel_checkin_at, "HH:mm:ss"),
			hotel_checkout_at: parseDate(destination.hotel_checkout_at, "HH:mm:ss"),
		}
	}, [destination])
	return (
		<TripDestinationEditForm
			initialValues={initialValues}
			onSubmit={async (data) => {
				const updatedDestination = await XHR(xhr).update(destination.id, data)
				return await Promise.resolve(onSuccess(updatedDestination))
			}}
			onCancel={onCancel}
		/>
	)
}

const validate = validateFormValues(
	Validator.object().shape({
		name: Validator.mixed().required(
			"Please provide a name for the destination"
		),
		short_name: Validator.string().required(
			"Please provide a short name for the destination"
		),
		destination: Validator.string().nullable(),
		currency: Validator.string().required(
			"Please select a operational currency for this destination"
		),
		max_child_age: EmptyNumberValidator()
			.required("Please provide the maximum child age")
			.min(0, "Child age should be non-negative")
			.integer("Child age should a integer value"),

		hotel_checkin_at: Validator.date().required(
			"Please provide a check-in time"
		),
		hotel_checkout_at: Validator.date()
			.required("Please provide a check-out time")
			.when(
				"hotel_checkin_at",
				(checkinAt: Date, schema: Validator.DateSchema) => {
					return schema.max(
						checkinAt,
						"Checkout should not be greater then checkin"
					)
				}
			),
	})
)

export function TripDestinationEditForm({
	onCancel,
	onSubmit,
	initialValues,
}: {
	onSubmit: (data: unknown) => Promise<void>
	onCancel?: () => void
	initialValues: TFormParams
}) {
	return (
		<Form<TFormParams>
			initialValues={initialValues}
			validate={validate}
			onSubmit={withServerErrors(async (values) => {
				return await onSubmit({
					...values,
					name: values.name?.name,
					hotel_checkin_at: formatDate(values.hotel_checkin_at, "HH:mm:ss"),
					hotel_checkout_at: formatDate(values.hotel_checkout_at, "HH:mm:ss"),
				})
			})}
		>
			{({ submitting, handleSubmit, form }) => {
				return (
					<form noValidate onSubmit={handleSubmit}>
						<Stack gap="4">
							<Grid gap="4">
								<Col xs={12} sm={6}>
									<SelectField
										label="Destination Name"
										placeholder="Select destination or add new one..."
										select={SelectTripDestinationFromRepository}
										name={`name`}
										creatable
										fetchOnMount
										onChange={(value?: TTripDestination) => {
											form.change(`name`, value)
											if (value?.short_name) {
												form.change(`short_name`, value.short_name)
											}
											if (value?.description) {
												form.change(`description`, value.description)
											}
										}}
									/>
								</Col>
								<Col>
									<Inline gap="4">
										<TextInputField
											name={`short_name`}
											label="Short Name"
											style={{ width: "100px" }}
											required
											type="text"
											placeholder="SK"
											help="Used for displaying this destination across app"
											onChange={(e) => {
												form.change(
													"short_name",
													(e.currentTarget.value || "")
														.toUpperCase()
														.replace(/\s/g, "")
												)
											}}
										/>
									</Inline>
								</Col>
							</Grid>
							<TextAreaInputField
								name="description"
								label="Description"
								placeholder="e.g. It includes Region X, and Region Y regions."
								help="You can mention included regions and other useful info in this field"
							/>
							<Divider marginY="0" />
							<Grid gap="4">
								<Col xs={12} sm={4}>
									<SelectTenantCurrencyInputField
										name="currency"
										label="Currency"
										style={{ width: "100px" }}
										required
										help="Operations currency on this destination"
									/>
								</Col>
								<Col>
									<TextInputField
										label="Maximum age for Child"
										type="number"
										min={0}
										name="max_child_age"
										help="Pax this age can be added as children during query creation."
										required
										style={{ maxWidth: "100px" }}
									/>
								</Col>
							</Grid>
							<Inline gap="4">
								<DatePickerField
									name="hotel_checkin_at"
									label="Default Check-in Time"
									dateFormat={false}
									timeFormat
									required
								/>
								<DatePickerField
									name="hotel_checkout_at"
									label="Default Check-out Time"
									dateFormat={false}
									timeFormat
									required
								/>
							</Inline>
							<SubmissionError />
							<Divider sm />
							<Inline gap="4">
								<Button disabled={submitting} type="submit">
									{submitting ? (
										"Updating..."
									) : (
										<>
											<Icons.Ok /> Save
										</>
									)}
								</Button>
								{onCancel ? (
									<Button
										disabled={submitting}
										onClick={onCancel}
										level="tertiary"
									>
										Cancel
									</Button>
								) : null}
							</Inline>
						</Stack>
					</form>
				)
			}}
		</Form>
	)
}

export function EditTripDestinationInDialog({
	children,
	onSuccess,
	...props
}: Optional<
	Omit<React.ComponentProps<typeof EditTripDestination>, "onCancel">,
	"onSuccess"
> & { children: (props: { onEdit: () => void }) => React.ReactNode }) {
	const [isOpen, open, close] = useDialog()
	return (
		<>
			{children({ onEdit: open })}
			<Dialog open={isOpen} onClose={close} title="Edit Destination Details">
				<Dialog.Body>
					<EditTripDestination
						{...props}
						onSuccess={(data) => {
							close()
							onSuccess?.(data)
						}}
						onCancel={close}
					/>
				</Dialog.Body>
			</Dialog>
		</>
	)
}

export function DisableTripDestination({
	destination,
	onCancel,
	onSuccess,
}: {
	destination: TTripDestination
	onCancel: () => void
	onSuccess: () => void
}) {
	const xhr = useXHR()
	return (
		<Form
			initialValues={{ destinationId: destination.id }}
			onSubmit={withServerErrors(async ({ destinationId }) => {
				await xhr.delete(`/trip-destinations/${destinationId}`)
				showSnackbar("Trip Destination disabled successfully.")
				onSuccess()
			})}
			subscription={{ submitting: true }}
		>
			{({ submitting, handleSubmit }) => (
				<form onSubmit={handleSubmit} noValidate>
					<Stack gap="4">
						<Inline
							padding="4"
							gap="4"
							flexWrap="wrap"
							borderWidth="1"
							rounded="md"
							bgColor="subtle"
						>
							<Stack>
								<Text fontSize="sm" color="muted">
									Name
								</Text>
								<Text fontSize="md" fontWeight="semibold">
									{destination.name}
								</Text>
							</Stack>
							<Stack>
								<Text fontSize="sm" color="muted">
									Short Name
								</Text>
								<Text fontSize="md" fontWeight="semibold">
									{destination.short_name}
								</Text>
							</Stack>
						</Inline>
						<Text>
							Once disabled, this destination CAN NOT be used in any future
							trips.
						</Text>
						<Text color="warning" fontWeight="semibold">
							Are you sure you want to disable this Trip Destination ? This
							action CAN NOT BE reversed!
						</Text>
						<SubmissionError />
						<Divider marginY="0" />
						<Inline gap="4">
							<Button type="submit" disabled={submitting} status="danger">
								I understand, disable it.
							</Button>
							<Button disabled={submitting} onClick={() => onCancel()}>
								No, Cancel
							</Button>
						</Inline>
					</Stack>
				</form>
			)}
		</Form>
	)
}
