import { Breadcrumbs } from "@sembark-travel/ui/router"
import { Helmet } from "react-helmet-async"
import { UploadHotels } from "../../../Hotels"
import { generatePath, useNavigate } from "../../../router-utils"
import { ForbidUnlessAuthorized, PERMISSIONS } from "../../../Auth"

export default function UploadHotelsPage() {
	const navigate = useNavigate()
	function navigateToList() {
		return navigate("/hotels")
	}
	return (
		<>
			<Helmet>
				<title>Upload Hotels</title>
			</Helmet>
			<ForbidUnlessAuthorized permission={PERMISSIONS.UPLOAD_BULK_HOTELS}>
				<Breadcrumbs
					title="Upload Hotels"
					items={[
						[generatePath("/hotels"), "Hotels"],
						["", "Upload CSV"],
					]}
				/>
				<UploadHotels onSuccess={navigateToList} onCancel={navigateToList} />
			</ForbidUnlessAuthorized>
		</>
	)
}
