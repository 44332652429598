import { Button, Inline, Divider, Stack } from "@sembark-travel/ui/base"
import { useXHR } from "@sembark-travel/xhr"
import {
	Form,
	withServerErrors,
	SubmissionError,
	SelectField,
} from "@sembark-travel/ui/form"
import { SelectUsers, type IUser } from "./../Users"

export interface EditOwnersProps {
	users?: Array<IUser>
	type: "sales_team" | "operations_team" | "reservations_team"
	itemId: number
	onCancel: () => void
	onSuccess: () => void
}

export function EditTripOwners({
	type,
	itemId,
	users,
	onCancel,
	onSuccess,
}: EditOwnersProps) {
	const xhr = useXHR()
	const initialValues = {
		items: [itemId],
		users: users || [],
	}
	return (
		<Form<typeof initialValues>
			initialValues={initialValues}
			onSubmit={withServerErrors(async (values) => {
				await xhr.put("/trip-owners", {
					items: values.items,
					users: values.users.map((u) => u.id),
					type,
				})
				onSuccess()
			})}
			subscription={{ submitting: true }}
		>
			{({ submitting, handleSubmit }) => (
				<form noValidate onSubmit={handleSubmit}>
					<Stack gap="4">
						<SubmissionError />
						<SelectField
							select={SelectUsers}
							name="users"
							label="Select User(s)"
							required
							fetchOnMount
							multiple
						/>
						<Divider sm />
						<Inline gap="4">
							<Button type="submit" disabled={submitting}>
								{submitting ? "Saving..." : "Save"}
							</Button>
							<Button
								disabled={submitting}
								onClick={() => {
									onCancel()
								}}
							>
								Cancel
							</Button>
						</Inline>
					</Stack>
				</form>
			)}
		</Form>
	)
}
