import {
	Stack,
	AsyncSelect,
	Alert,
	Box,
	Button,
	Icons,
	Table,
	Inline,
	Text,
	Dropdown,
	joinAttributes,
	RelativeTime,
	Time,
	Heading,
	Component,
} from "@sembark-travel/ui/base"
import { Link, ButtonLink, useLocationQuery } from "@sembark-travel/ui/router"
import { Dialog, useDialog } from "@sembark-travel/ui/dialog"
import { showSnackbar } from "@sembark-travel/ui/snackbar"
import { ListView, Search, useSearch } from "@sembark-travel/ui/list"
import { utcTimestampToLocalDate } from "@sembark-travel/datetime-utils"
import { useXHR } from "@sembark-travel/xhr"
import pluralize from "pluralize"
import React, { Fragment, useEffect, useMemo } from "react"
import { Helmet } from "react-helmet-async"
import * as Validator from "yup"
import { generatePath } from "../router-utils"
import { IUser, XHR } from "./store"
import {
	Form,
	SubmissionError,
	SwitchInputField,
	validateFormValues,
	withServerErrors,
	TextInputField,
} from "@sembark-travel/ui/form"
import { IRole } from "../Roles"

export function List({ title = "Users" }: { title?: string }) {
	const [query, setQuery] = useLocationQuery()
	const [params, setParams] = useSearch(query)
	useEffect(() => {
		setQuery(params)
	}, [params, setQuery])
	return (
		<Fragment>
			{title ? (
				<Helmet>
					<title>{title}</title>
				</Helmet>
			) : null}
			<Search
				title={title}
				initialParams={params}
				onSearch={(params) => {
					setParams({ ...params, page: 1 })
				}}
				Filters={Filters}
				actions={
					<ButtonLink to={generatePath("/users/new")} level="primary" anchored>
						Invite Member
					</ButtonLink>
				}
			>
				<ListView<IUser>
					pageKey="users-list"
					params={params}
					onPageChange={(page) => setParams({ ...params, page })}
					fetch={(xhr, params) =>
						XHR(xhr).getUsers({
							...params,
							include: "media,roles,last_activity_at",
						})
					}
				>
					{({ items: users, refresh, xhr }) => (
						<Table
							striped
							bordered
							responsive
							headers={["Name", "Roles", "User Since", "Recent Activity", ""]}
							rows={users.map((r) => [
								<Inline gap="2">
									{r.profile_thumb_image_url ? (
										<Box
											as="img"
											src={r.profile_thumb_image_url}
											size="12"
											alt="Avatar"
											rounded="lg"
											borderWidth="1"
											borderColor="default"
										/>
									) : null}
									<Stack>
										<Link
											to={generatePath("/users/:userId", {
												userId: r.id.toString(),
											})}
											color="accent"
											fontWeight="semibold"
											anchored
										>
											{r.name}
										</Link>
										<Text fontSize="sm">
											{r.email}{" "}
											{r.disabled_at ? (
												<Icons.Ban color="warning" title="Access Disabled" />
											) : null}
										</Text>
									</Stack>
								</Inline>,
								<Box>
									{r.roles
										? joinAttributes(...r.roles.map((r) => r.name))
										: null}
								</Box>,
								<Box>
									{r.email_verified_at ? (
										<Time
											value={utcTimestampToLocalDate(r.email_verified_at)}
										/>
									) : (
										<Box>Email Not Verified</Box>
									)}
									<Box fontSize="sm" color="muted">
										Invited{" "}
										<RelativeTime
											value={utcTimestampToLocalDate(r.invited_at)}
										/>
									</Box>
								</Box>,
								<Box>
									{r.last_activity_at ? (
										<RelativeTime
											value={utcTimestampToLocalDate(r.last_activity_at)}
										/>
									) : (
										"-"
									)}
								</Box>,
								!r.deleted_at ? (
									r.is_owner && r.email_verified_at ? null : (
										<Box textAlign="right">
											<Dropdown alignRight>
												<Button level="tertiary" as={Dropdown.Toggle} size="sm">
													<Icons.DotsVertical />
												</Button>
												<Dropdown.Menu>
													<ResendInvitation user={r} onChange={() => refresh()}>
														{({ onResend }) => (
															<Dropdown.MenuItem onClick={onResend}>
																<Icons.Mail /> Resend Invitation
															</Dropdown.MenuItem>
														)}
													</ResendInvitation>
													<DisableAccount user={r} onSuccess={() => refresh()}>
														{({ onDisable }) => (
															<Dropdown.MenuItem onClick={onDisable}>
																<Icons.Ban opacity="50" /> Disable Account
															</Dropdown.MenuItem>
														)}
													</DisableAccount>
													<EnableAccount user={r} onSuccess={() => refresh()}>
														{({ onEnable }) => (
															<Dropdown.MenuItem onClick={onEnable}>
																<Icons.Refresh opacity="50" /> Enable Account
															</Dropdown.MenuItem>
														)}
													</EnableAccount>
													<DeleteAccount user={r} onSuccess={() => refresh()}>
														{({ onDelete }) => (
															<Dropdown.MenuItem
																onClick={onDelete}
																color="warning"
															>
																<Icons.Trash opacity="50" /> Delete Account
															</Dropdown.MenuItem>
														)}
													</DeleteAccount>
													<Component initialState={false}>
														{({ state, setState }) => (
															<Dropdown.MenuItem
																onClick={async () => {
																	if (state) return
																	if (
																		!window.confirm(
																			`Are you sure you want to ${
																				r.password_login_disabled_at
																					? "Enabled"
																					: "Disable"
																			} login via Password`
																		)
																	) {
																		return
																	}
																	setState(true)
																	try {
																		await xhr.patch(
																			`/users/${r.id}/toggle-password-login`
																		)
																		refresh()
																	} catch (e) {
																		const error = e as Error
																		alert(
																			error.message ||
																				"Something went wrong. Please try after sometime."
																		)
																	}
																	setState(false)
																}}
															>
																{r.password_login_disabled_at
																	? "Enable"
																	: "Disable"}{" "}
																Password Login
															</Dropdown.MenuItem>
														)}
													</Component>
												</Dropdown.Menu>
											</Dropdown>
										</Box>
									)
								) : (
									<Box>
										<Box color="warning">
											<Icons.Trash /> by {r.deleted_by?.name}
										</Box>
										<Box fontSize="sm">
											<RelativeTime
												value={utcTimestampToLocalDate(r.deleted_at)}
											/>
										</Box>
									</Box>
								),
							])}
						/>
					)}
				</ListView>
			</Search>
		</Fragment>
	)
}

function Filters() {
	return (
		<>
			<SwitchInputField label="Only Deleted" name="only_deleted" />
		</>
	)
}

export function ResendInvitation({
	user,
	onChange,
	children,
}: {
	user: IUser
	onChange: () => void
	children?: (props: { onResend: () => void }) => React.ReactNode
}) {
	const xhr = useXHR()
	const origin = window.location.origin || ""
	const initialValues = useMemo(() => {
		return {
			invited_signup_link: origin ? `${origin}/invited-signup` : "",
			email: user.email,
			name: user.name,
		}
	}, [origin, user])
	const [isOpen, open, close] = useDialog()
	if (user.email_verified_at || user.deleted_at) return null
	return (
		<>
			{children ? (
				children({ onResend: open })
			) : (
				<Button level="secondary" onClick={open}>
					Resend Invitation
				</Button>
			)}
			<Dialog open={isOpen} onClose={close} title="Resend Invitation" sm>
				<Form<typeof initialValues>
					initialValues={initialValues}
					onSubmit={withServerErrors(async (values) => {
						const { data } = await xhr.patch(
							`/invited-users/${user.uid}/resend`,
							values
						)
						close()
						onChange()
						showSnackbar(data.message || `Invitation resent to ${values.name}`)
					})}
					validate={validateFormValues(
						Validator.object().shape({
							name: Validator.string()
								.required("Please provide a name.")
								.max(191, "Please use 191 or fewer characters."),
							email: Validator.string()
								.email("Please provide a valid email address")
								.required("Please provide an email address."),
						})
					)}
					subscription={{ submitting: true }}
				>
					{({ submitting, handleSubmit }) => (
						<form onSubmit={handleSubmit} noValidate>
							<Dialog.Body>
								<Stack gap="4">
									<Text color="muted">
										<Icons.Info /> You can update the name/email if necessary.
									</Text>
									<TextInputField
										name="name"
										label="Name"
										type="text"
										required
									/>
									<TextInputField
										name="email"
										label="Email"
										type="email"
										required
									/>
									<input
										hidden
										type="hidden"
										name="invited_signup_link"
										value={initialValues.invited_signup_link}
									/>
									<SubmissionError />
								</Stack>
							</Dialog.Body>
							<Dialog.Footer>
								<Button type="submit" disabled={submitting} autoFocus>
									<Icons.Mail />{" "}
									{submitting ? "Resending... " : "Resend Invitation"}
								</Button>
								<Button onClick={close} disabled={submitting}>
									Cancel
								</Button>
							</Dialog.Footer>
						</form>
					)}
				</Form>
			</Dialog>
		</>
	)
}

export function DeleteAccount({
	user,
	onSuccess,
	children,
}: {
	user: IUser
	onSuccess: () => void
	children?: (props: { onDelete: () => void }) => React.ReactNode
}) {
	const xhr = useXHR()
	const initialValues = useMemo(() => {
		return {
			confirmed_email: "",
		}
	}, [])
	const [isOpen, open, close] = useDialog()
	if (user.deleted_at) return null
	return (
		<>
			{children ? (
				children({ onDelete: open })
			) : (
				<Button level="secondary" status="warning" onClick={open}>
					<Icons.Trash /> Delete Account
				</Button>
			)}
			<Dialog open={isOpen} onClose={close} title="Delete Account" sm>
				<Form<typeof initialValues>
					initialValues={initialValues}
					validate={validateFormValues(
						Validator.object().shape({
							confirmed_email: Validator.string()
								.equals(
									[user.email],
									`Please type "${user.email}" to confirm the deletion`
								)
								.required(
									"Please type the user's email address to confirm the deletion"
								),
						})
					)}
					onSubmit={withServerErrors(async () => {
						if (
							window.confirm(
								"Once deleted, this account can not be restored. Are you sure you want to continue ?"
							)
						) {
							const { data } = await xhr.delete(`/users/${user.uid}`)
							close()
							onSuccess()
							showSnackbar(
								data.message || `${user.name}'s account has been deleted.`
							)
						}
					})}
					subscription={{ submitting: true }}
				>
					{({ submitting, handleSubmit }) => (
						<form noValidate onSubmit={handleSubmit}>
							<Dialog.Body>
								<Stack gap="6">
									<Stack gap="2">
										<Heading as="h5" fontSize="md">
											Please review account details:
										</Heading>
									</Stack>
									<Inline
										borderWidth="1"
										rounded="lg"
										flexWrap="wrap"
										bgColor="primary"
										gap="4"
										padding="4"
									>
										<Stack gap="1">
											<Box
												textTransform="uppercase"
												fontSize="sm"
												fontWeight="semibold"
												color="muted"
											>
												Name
											</Box>
											<Box fontWeight="semibold">{user.name}</Box>
											<Box fontSize="sm">{user.email}</Box>
										</Stack>
										{user.roles ? (
											<Stack gap="1">
												<Box
													textTransform="uppercase"
													fontSize="sm"
													fontWeight="semibold"
													color="muted"
												>
													{pluralize("Role", user.roles?.length || 0)}
												</Box>
												<Box fontWeight="semibold">
													{user.roles.map((r) => r.name).join(", ")}
												</Box>
											</Stack>
										) : null}
									</Inline>
									<Alert
										status="warning"
										title="Once deleted, this account CAN NOT be restored."
									>
										<Stack gap="2">
											<Text>
												Following things will happen when the account is
												deleted.
											</Text>
											<Stack
												as="ul"
												marginLeft="4"
												listStyleType="disc"
												gap="1"
											>
												<li>
													<b>{user.name} will NOT</b> be able to login using
													this account.
												</li>
												<li>
													All activity if any (e.g. sales, operations,
													accounting related) done by {user.name} will remain as
													is for future audits.
												</li>
												<li>
													Email address will be updated to free the {user.email}{" "}
													for further usage.
												</li>
											</Stack>
										</Stack>
									</Alert>
									<Text fontWeight="semibold" color="warning">
										Only delete the account if the user has left organization or
										no longer using the system.
									</Text>
									<TextInputField
										label={`Type '${user.email}' to confirm`}
										name="confirmed_email"
										type="email"
										placeholder="Enter Email Address"
										aria-label="Type in the user's email to confirm that you want delete the account"
										required
									/>
									<SubmissionError />
								</Stack>
							</Dialog.Body>
							<Dialog.Footer>
								<Button
									type="submit"
									level="primary"
									status="warning"
									disabled={submitting}
								>
									<Icons.Trash />{" "}
									{submitting
										? "Archiving... "
										: "I understand, delete this account."}
								</Button>
								<Button onClick={close}>Cancel</Button>
							</Dialog.Footer>
						</form>
					)}
				</Form>
			</Dialog>
		</>
	)
}

export function DisableAccount({
	user,
	onSuccess,
	children,
}: {
	user: IUser
	onSuccess: () => void
	children?: (props: { onDisable: () => void }) => React.ReactNode
}) {
	const xhr = useXHR()
	const [isOpen, open, close] = useDialog()
	if (user.disabled_at) return null
	return (
		<>
			{children ? (
				children({ onDisable: open })
			) : (
				<Button level="secondary" onClick={open}>
					<Icons.Ban /> Disable Account
				</Button>
			)}
			<Dialog open={isOpen} onClose={close} title="Disable Account" sm>
				<Form<object>
					onSubmit={withServerErrors(async () => {
						const { data } = await xhr.post(`/disabled-users/${user.uid}`)
						close()
						onSuccess()
						showSnackbar(
							data.message || `${user.name}'s account has been disabled.`
						)
					})}
					subscription={{ submitting: true }}
				>
					{({ submitting, handleSubmit }) => (
						<form noValidate onSubmit={handleSubmit}>
							<Dialog.Body>
								<Stack gap="6">
									<Stack gap="2">
										<Heading as="h5" fontSize="md">
											Please review account details:
										</Heading>
									</Stack>
									<Inline
										borderWidth="1"
										rounded="lg"
										flexWrap="wrap"
										bgColor="primary"
										gap="4"
										padding="4"
									>
										<Stack gap="1">
											<Box
												textTransform="uppercase"
												fontSize="sm"
												fontWeight="semibold"
												color="muted"
											>
												Name
											</Box>
											<Box fontWeight="semibold">{user.name}</Box>
											<Box fontSize="sm">{user.email}</Box>
										</Stack>
										{user.roles ? (
											<Stack gap="1">
												<Box
													textTransform="uppercase"
													fontSize="sm"
													fontWeight="semibold"
													color="muted"
												>
													{pluralize("Role", user.roles.length)}
												</Box>
												<Box fontWeight="semibold">
													{user.roles.map((r) => r.name).join(", ")}
												</Box>
											</Stack>
										) : null}
									</Inline>
									<Alert status="warning">
										<Stack gap="2">
											<Text>
												Following things will happen when the account is
												disabled.
											</Text>
											<Stack
												as="ul"
												marginLeft="4"
												listStyleType="disc"
												gap="1"
											>
												<li>
													<b>
														{user.name} &lt;{user.email}&gt; will NOT
													</b>{" "}
													be able to login using this account.
												</li>
												<li>
													All activity if any (e.g. sales, operations,
													accounting related) done by {user.name} will remain as
													is for future audits.
												</li>
											</Stack>
										</Stack>
									</Alert>
									<SubmissionError />
								</Stack>
							</Dialog.Body>
							<Dialog.Footer>
								<Button
									type="submit"
									level="primary"
									status="warning"
									disabled={submitting}
								>
									<Icons.Ban />{" "}
									{submitting
										? "Disabling... "
										: "I understand, disable this account."}
								</Button>
								<Button onClick={close}>Cancel</Button>
							</Dialog.Footer>
						</form>
					)}
				</Form>
			</Dialog>
		</>
	)
}

export function EnableAccount({
	user,
	onSuccess,
	children,
}: {
	user: IUser
	onSuccess: () => void
	children?: (props: { onEnable: () => void }) => React.ReactNode
}) {
	const xhr = useXHR()
	const [isOpen, open, close] = useDialog()
	if (!user.disabled_at) return null
	return (
		<>
			{children ? (
				children({ onEnable: open })
			) : (
				<Button level="secondary" onClick={open}>
					<Icons.Refresh /> Enable Account
				</Button>
			)}
			<Dialog open={isOpen} onClose={close} title="Enable Account" sm>
				<Form<object>
					onSubmit={withServerErrors(async () => {
						const { data } = await xhr.delete(`/disabled-users/${user.uid}`)
						close()
						onSuccess()
						showSnackbar(
							data.message || `${user.name}'s account has been enabled.`
						)
					})}
					subscription={{ submitting: true }}
				>
					{({ submitting, handleSubmit }) => (
						<form noValidate onSubmit={handleSubmit}>
							<Dialog.Body>
								<Stack gap="6">
									<Stack gap="2">
										<Heading as="h5" fontSize="md">
											Please review account details:
										</Heading>
									</Stack>
									<Inline
										borderWidth="1"
										rounded="lg"
										flexWrap="wrap"
										bgColor="primary"
										gap="4"
										padding="4"
									>
										<Stack gap="1">
											<Box
												textTransform="uppercase"
												fontSize="sm"
												fontWeight="semibold"
												color="muted"
											>
												Name
											</Box>
											<Box fontWeight="semibold">{user.name}</Box>
											<Box fontSize="sm">{user.email}</Box>
										</Stack>
										{user.roles ? (
											<Stack gap="1">
												<Box
													textTransform="uppercase"
													fontSize="sm"
													fontWeight="semibold"
													color="muted"
												>
													{pluralize("Role", user.roles.length)}
												</Box>
												<Box fontWeight="semibold">
													{user.roles.map((r) => r.name).join(", ")}
												</Box>
											</Stack>
										) : null}
									</Inline>
									<Alert status="warning">
										<Stack gap="2">
											<Text>
												Following things will happen when the account is
												enabled.
											</Text>
											<Stack
												as="ul"
												marginLeft="4"
												listStyleType="disc"
												gap="1"
											>
												<li>
													{user.name} &lt;{user.email}&gt; will be able to login
													using this account.
												</li>
											</Stack>
										</Stack>
									</Alert>
									<SubmissionError />
								</Stack>
							</Dialog.Body>
							<Dialog.Footer>
								<Button
									type="submit"
									level="primary"
									status="warning"
									disabled={submitting}
								>
									<Icons.Refresh />{" "}
									{submitting
										? "Enabling... "
										: "I understand, Enable this account."}
								</Button>
								<Button onClick={close}>Cancel</Button>
							</Dialog.Footer>
						</form>
					)}
				</Form>
			</Dialog>
		</>
	)
}

type SelectUsersProps = Omit<
	React.ComponentProps<typeof AsyncSelect>,
	"fetch"
> & {
	roles?: Array<IRole>
}

export function SelectUsers({ roles, ...props }: SelectUsersProps) {
	const xhr = useXHR()
	return (
		<AsyncSelect
			multiple
			{...props}
			fetch={(q) =>
				XHR(xhr)
					.getUsers({ q, roles: roles?.length ? roles.map((r) => r.id) : null })
					.then((resp) => resp.data)
			}
		/>
	)
}
