import { IListResponse, XHRInstance } from "@sembark-travel/xhr"
import { Required } from "utility-types"
import { IUser } from "../Auth"

export type TCabPriceCalculationMetric = {
	id: number
	name: string
	system_quantity_metric?: "kms" | "days" | "nights" | "extra_kms" | "total"
	min_quantity_per_day?: number
	created_at: string
	created_by?: IUser
	updated_at?: string
	updated_by: IUser
	deleted_at?: string
	deleted_by?: IUser
}

export type TCabPriceCalculationMetricPreset = {
	id: number
	name: string
	metrics: Array<TCabPriceCalculationMetric>
	created_at: string
	created_by?: IUser
	updated_at?: string
	updated_by: IUser
}

export const LABEL_FOR_CAB_PRICE_CALCULATION_SYSTEM_QUANTITY_METRIC: Record<
	Required<TCabPriceCalculationMetric>["system_quantity_metric"],
	string
> = {
	kms: "Kms",
	days: "Days",
	nights: "Nights",
	extra_kms: "Extra Kms",
	total: "Total",
}

export function cabPriceCalculationMetricsXHR(xhr: XHRInstance) {
	return {
		async get(params: unknown) {
			return xhr
				.get<IListResponse<TCabPriceCalculationMetric>>(
					"/cab-price-calculation-metrics",
					{ params }
				)
				.then((resp) => resp.data)
		},
		async store(data: unknown) {
			return xhr
				.post<{ data: TCabPriceCalculationMetric }>(
					"/cab-price-calculation-metrics",
					data
				)
				.then((resp) => resp.data.data)
		},
		async show(id: number | string, params?: unknown) {
			return xhr
				.get<{ data: TCabPriceCalculationMetric }>(
					`/cab-price-calculation-metrics/${id}`,
					{ params }
				)
				.then((resp) => resp.data)
		},
		async destroy(id: number | string) {
			return xhr
				.delete<{ message: string }>(`/cab-price-calculation-metrics/${id}`)
				.then((resp) => resp.data)
		},
		async restore(id: number | string) {
			return xhr
				.post<{ data: TCabPriceCalculationMetric }>(
					`/cab-price-calculation-metrics/${id}/restore`
				)
				.then((resp) => resp.data.data)
		},
	}
}

export function cabPriceCalculationMetricPresetsXHR(xhr: XHRInstance) {
	return {
		async get(params: unknown) {
			return xhr
				.get<IListResponse<TCabPriceCalculationMetricPreset>>(
					"/cab-price-calculation-metric-presets",
					{ params }
				)
				.then((resp) => resp.data)
		},
		async store(data: unknown) {
			return xhr
				.post<{ data: TCabPriceCalculationMetricPreset }>(
					"/cab-price-calculation-metric-presets",
					data
				)
				.then((resp) => resp.data.data)
		},
		async show(id: number | string, params?: unknown) {
			return xhr
				.get<{ data: TCabPriceCalculationMetricPreset }>(
					`/cab-price-calculation-metric-presets/${id}`,
					{ params }
				)
				.then((resp) => resp.data)
		},
		async update(id: number | string, data: unknown) {
			return xhr
				.patch<{ data: TCabPriceCalculationMetricPreset }>(
					`/cab-price-calculation-metric-presets/${id}`,
					data
				)
				.then((resp) => resp.data.data)
		},
	}
}
