import { Helmet } from "react-helmet-async"
import { TermsAndConditionsList } from "../../../../TermsAndConditions"

export default function OrganizationTermsAndConditionsPage() {
	return (
		<>
			<Helmet>
				<title>Terms and Conditions</title>
			</Helmet>
			<TermsAndConditionsList />
		</>
	)
}
