import {
	Stack,
	Text,
	Box,
	Button,
	Inline,
	Icons,
	CheckboxInput,
	Table,
	Spinner,
	Heading,
	Dropdown,
	Component,
	DeferRender,
	joinAttributes,
	RelativeTime,
	Stars,
	Time,
	Money,
} from "@sembark-travel/ui/base"
import { Dialog } from "@sembark-travel/ui/dialog"
import {
	utcTimestampToLocalDate,
	utcTimestampToLocalDateTimeString,
	getDiff,
} from "@sembark-travel/datetime-utils"
import { isNumeric, withOrdinalSuffix } from "@sembark-travel/number-utils"
import { useXHR, XHRInstance } from "@sembark-travel/xhr"
import React, { useMemo, useReducer } from "react"
import useSWR from "swr"
import { $PropertyType } from "utility-types"
import { GeneratedVoucherAsset, MarkVoucherAsSent } from "../HotelVouchers"
import {
	IBooking,
	ITripHotelBooking,
	EditHotelBookingDetailsInDialog,
	HotelBookingDetailsForm,
	HotelBookingCurrentStage,
	UpdateBookingTagInDialog,
	ComposeHotelBookingEmail,
	GenerateVoucher,
} from "./../HotelBookings"
import { TTripDestination } from "../TripDestinations"
import { HotelBookingItemInDialog } from "../HotelBookings/Item"
import { moneyParseByDecimal } from "@sembark-travel/money"
import { PhoneNumber } from "../Contacts"

function XHR(xhr: XHRInstance) {
	return {
		async show(tripId: string): Promise<ITripHotelBooking> {
			return xhr
				.get(`/trip-hotel-bookings/${tripId}`, {})
				.then((resp) => resp.data.data)
		},
	}
}

interface IHotelBookings {
	tripId: string
}

export default function HotelBookings({ tripId }: IHotelBookings) {
	const xhr = useXHR()
	const {
		data,
		error,
		mutate: revalidate,
		isValidating,
	} = useSWR(tripId ? `trip-hotel-bookings/${tripId}` : null, () =>
		tripId ? XHR(xhr).show(tripId) : undefined
	)
	const [filters, setFilters] = useReducer<
		React.Reducer<
			{ showDropped: boolean; showChanged: boolean },
			{ showDropped?: boolean; showChanged?: boolean }
		>
	>((state, action) => ({ ...state, ...action }), {
		showDropped: true,
		showChanged: true,
	})
	const changedBookings = useMemo(() => {
		if (!data) return []
		return data.bookings.filter((b) => b.booking_stage.state === "changed")
	}, [data])
	const droppedBookings = useMemo(() => {
		if (!data) return []
		return data.bookings.filter((b) => b.booking_stage.state === "dropped")
	}, [data])
	const tripBookings = useMemo(() => {
		if (!data) return undefined
		const { showChanged, showDropped } = filters
		return {
			...data,
			bookings: data.bookings.filter((b) => {
				if (!showChanged && b.booking_stage.state === "changed") return false
				if (!showDropped && b.booking_stage.state === "dropped") return false
				return true
			}),
		}
	}, [filters, data])
	const isFetching: boolean = !tripBookings && !error
	if (isFetching) return <Spinner alignCenter padding="4" />
	if (!tripBookings) {
		return null
	}
	const {
		bookings = [],
		can_modify,
		is_shared,
		can_view_prices,
		destinations,
	} = tripBookings
	const tripStartDate = utcTimestampToLocalDate(tripBookings.start_date)
	const tripEndDate = utcTimestampToLocalDate(tripBookings.end_date)
	return (
		<Stack gap="4" cursor={isValidating ? "wait" : undefined}>
			<Inline gap="4" collapseBelow="sm" justifyContent="between">
				<Heading>Hotel Bookings</Heading>
				<Box display="flex" justifyContent="end" gap="4">
					{droppedBookings.length ? (
						<Box as="label" marginLeft="4" title="Show/Hide Dropped Hotels">
							<CheckboxInput
								checked={filters.showDropped}
								onChange={() =>
									setFilters({ showDropped: !filters.showDropped })
								}
							/>{" "}
							{droppedBookings.length} Dropped
						</Box>
					) : null}
					{changedBookings.length ? (
						<Box as="label" marginLeft="4" title="Show/Hide Changed Hotels">
							<CheckboxInput
								checked={filters.showChanged}
								onChange={() =>
									setFilters({ showChanged: !filters.showChanged })
								}
							/>{" "}
							{changedBookings.length} Changed
						</Box>
					) : null}
					{can_modify ? (
						<Component initialState={false}>
							{({ state, setState }) => (
								<>
									<Dropdown alignRight marginLeft="2">
										<Dropdown.ToggleButton level="tertiary">
											<Icons.DotsVertical />
										</Dropdown.ToggleButton>
										<Dropdown.Menu>
											<Dropdown.MenuItem onClick={() => setState(true)}>
												Add More Hotels/Services
											</Dropdown.MenuItem>
										</Dropdown.Menu>
									</Dropdown>
									<Dialog
										open={state}
										onClose={() => setState(false)}
										fitContainer
										title="Add More Hotel/Services Bookings"
									>
										<Dialog.Body>
											<DeferRender>
												<AddMoreHotels
													tripId={tripBookings.id}
													tripDestinations={destinations}
													startDate={tripStartDate}
													endDate={tripEndDate}
													onCancel={() => setState(false)}
													children={tripBookings.children}
													noOfAdults={tripBookings.no_of_adults}
													onSuccess={() => {
														setState(false)
														setTimeout(() => {
															revalidate()
														}, 1000)
													}}
												/>
											</DeferRender>
										</Dialog.Body>
									</Dialog>
								</>
							)}
						</Component>
					) : null}
				</Box>
			</Inline>
			{!bookings.length ? (
				<Text>No hotel bookings for this trip</Text>
			) : (
				<Box>
					<Table className="excel-style-table" responsive>
						<thead>
							<tr>
								<th>Hotel</th>
								<th>Stay and Services</th>
								<th>Status</th>
								<th>Tag/Comments</th>
								{can_view_prices ? (
									<th style={{ textAlign: "right" }}>Price</th>
								) : null}
							</tr>
						</thead>
						<tbody>
							{bookings.map((booking) => {
								const {
									id,
									hotel,
									booking_stage,
									booked,
									checkin: bookingStartDate,
									no_of_nights,
									voucher,
									booked_at,
									booking_confirmation_id,
									hotel_contact,
									currency,
									booked_price,
									payments_amount,
									paid_payments_amount,
									refunding_payments_amount,
									paid_refunding_payments_amount,
									cancellation_charges,
								} = booking
								const checkin = utcTimestampToLocalDate(bookingStartDate)
								const stay_nights = [
									getDiff(checkin, tripStartDate, "days") + 1,
								]
								for (let i = 1; i < no_of_nights; i++) {
									stay_nights.push(stay_nights[i - 1] + 1)
								}
								return (
									<Box as="tr" key={id}>
										<Box as="td" verticalAlign="top">
											<Box
												display="flex"
												alignItems="center"
												justifyContent="between"
											>
												<Box>
													<Box fontWeight="semibold">
														<HotelBookingItemInDialog
															hotelBookingId={booking.id}
														>
															{({ showDetails }) => (
																<Text<"a">
																	as="a"
																	href="hotel-booking-details"
																	fontWeight="semibold"
																	color="accent"
																	onClick={(e) => {
																		e.preventDefault()
																		showDetails()
																	}}
																>
																	{hotel?.name}
																</Text>
															)}
														</HotelBookingItemInDialog>
													</Box>
													<Box fontSize="sm">
														{joinAttributes(
															hotel?.location?.short_name,
															hotel.stars ? <Stars stars={hotel.stars} /> : null
														)}
													</Box>
												</Box>
												<Box marginLeft="1">
													<Inline gap="4">
														<EditHotelBookingDetailsInDialog
															tripDestinations={destinations}
															booking={booking}
															startDate={tripStartDate}
															endDate={tripEndDate}
															onSuccess={() => revalidate()}
															minimal
														/>
														<ComposeHotelBookingEmail
															tripDestinations={destinations}
															booking={booking}
															tripStartDate={tripStartDate}
															onChange={() => revalidate()}
															tripEndDate={tripEndDate}
															minimal
														/>
													</Inline>
												</Box>
											</Box>
											{booked && booking_confirmation_id ? (
												<Box
													title="Booking Confirmation ID"
													fontSize="sm"
													marginTop="2"
												>
													CNF: <b>{booking_confirmation_id}</b>
												</Box>
											) : null}
											{booked && hotel_contact ? (
												<Box
													title="Booking Confirmation By Contact"
													fontSize="sm"
													marginTop="1"
												>
													<Icons.ArrowLeft rotate="180" size="3" />{" "}
													{hotel_contact.name}{" "}
													<PhoneNumber
														value={hotel_contact.phone_number}
														iconOnly
													/>
												</Box>
											) : null}
										</Box>
										<Box as="td">
											<Box>
												<Box>
													<Time value={checkin} format="Do MMMM" />
													<Box display="inline" marginLeft="1">
														-{" "}
														{stay_nights
															.map((s) => withOrdinalSuffix(s))
															.join(",")}{" "}
														N
													</Box>
												</Box>
												{booking.details?.length ? (
													<Box marginTop="1" paddingTop="1" fontSize="xs">
														{booking.details.map((bookingDetail, i) => {
															const {
																date,
																meal_plan,
																room_type,
																no_of_rooms,
																comments,
																adults_with_extra_bed,
																children_with_extra_bed,
																children_without_extra_bed,
															} = bookingDetail
															const night =
																getDiff(
																	utcTimestampToLocalDate(date),
																	checkin,
																	"days"
																) + 1
															return (
																<Box
																	key={`${bookingDetail.id}-${id}-${i}`}
																	title={`${withOrdinalSuffix(
																		night
																	)} Night Services`}
																>
																	{stay_nights.length > 1 ? (
																		<Box display="inline">
																			{night}N{" - "}
																		</Box>
																	) : null}
																	<Box display="inline">
																		{joinAttributes(
																			meal_plan.name,
																			`${no_of_rooms} ${room_type.name}`,
																			[
																				!!adults_with_extra_bed,
																				`${adults_with_extra_bed} AWEB`,
																			],
																			[
																				!!children_with_extra_bed,
																				`${children_with_extra_bed} CWEB`,
																			],
																			[
																				!!children_without_extra_bed,
																				`${children_without_extra_bed} CNB`,
																			]
																		)}
																	</Box>
																	{!is_shared && comments ? (
																		<Box maxWidth="sm">
																			<blockquote>{comments}</blockquote>
																		</Box>
																	) : null}
																</Box>
															)
														})}
													</Box>
												) : (
													<Box fontSize="xs" color="muted" marginTop="1">
														No Room Services
													</Box>
												)}
												{booking.extras?.length ? (
													<Box marginTop="2">
														<Box fontWeight="semibold">Extras</Box>
														<Box fontSize="xs" marginTop="1">
															{booking.extras.map((extra, i) => {
																const { date, service, comments } = extra
																return (
																	<Box key={`${extra.id}-${id}-${i}`}>
																		{stay_nights.length > 1 && date ? (
																			<Box display="inline">
																				{getDiff(
																					date,
																					booking.checkin,
																					"days"
																				) + 1}{" "}
																				N{" - "}
																			</Box>
																		) : null}
																		<Box display="inline">
																			{joinAttributes(service.name)}
																		</Box>
																		{!is_shared && comments ? (
																			<blockquote>{comments}</blockquote>
																		) : null}
																	</Box>
																)
															})}
														</Box>
													</Box>
												) : null}
											</Box>
										</Box>
										<Box
											as="td"
											title={utcTimestampToLocalDateTimeString(
												booking_stage.created_at
											)}
											verticalAlign="top"
										>
											<HotelBookingCurrentStage
												tripDestinations={destinations}
												booking={booking}
												tripStartDate={tripStartDate}
												tripEndDate={tripEndDate}
												onChangeSuccess={() => revalidate()}
												minimal
											/>
											{booking_stage.created_by && booking_stage.created_at ? (
												<Box fontSize="sm" color="muted" marginTop="1">
													{joinAttributes(
														`by ${booking_stage.created_by.name}`,
														<RelativeTime
															value={utcTimestampToLocalDate(
																booking_stage.created_at
															)}
														/>
													)}
												</Box>
											) : null}
											{booked ? (
												<>
													{!voucher ? (
														<>
															{can_modify ? (
																<GenerateVoucher
																	hotel={hotel}
																	booking={booking}
																	isBooked={!!booked_at}
																	bookingConfirmationId={
																		booking_confirmation_id
																	}
																	onGenerate={() => revalidate()}
																	minimal
																>
																	<Box as="span" color="warning">
																		<Icons.Document /> Generate Voucher
																	</Box>
																</GenerateVoucher>
															) : null}
														</>
													) : (
														<Inline marginTop="2" alignItems="center">
															{joinAttributes(
																<GeneratedVoucherAsset
																	id={voucher.id}
																	fileUrl={voucher.file_url}
																	isGenerating={voucher.is_generating}
																	onChange={() => revalidate()}
																	fontSize="sm"
																/>,
																!can_modify ? null : voucher.sent_at ? (
																	<Box
																		title={`Voucher sent on ${utcTimestampToLocalDateTimeString(
																			voucher.sent_at
																		)} by ${voucher.sent_by?.name}`}
																		display="inline"
																		fontSize="sm"
																	>
																		<Icons.Ok color="success" /> Voucher Sent
																	</Box>
																) : (
																	<MarkVoucherAsSent
																		voucherId={voucher.id}
																		onSuccess={() => revalidate()}
																		minimal
																	>
																		<Box as="span" color="warning">
																			<Icons.AttentionSolid /> Voucher Pending
																		</Box>
																	</MarkVoucherAsSent>
																),
																can_modify ? (
																	<GenerateVoucher
																		hotel={hotel}
																		booking={booking}
																		isBooked={!!booked_at}
																		bookingConfirmationId={
																			booking_confirmation_id
																		}
																		onGenerate={() => revalidate()}
																		isShared={is_shared}
																		minimal
																	>
																		<Icons.Refresh opacity="50" />
																	</GenerateVoucher>
																) : null
															)}
														</Inline>
													)}
												</>
											) : null}
										</Box>
										<Box as="td" verticalAlign="top">
											<Inline gap="4">
												<Box>{booking_stage.tag || null}</Box>
												<UpdateBookingTagInDialog
													booking={booking}
													onSuccess={() => revalidate()}
												>
													{({ onEdit }) => (
														<Button onClick={() => onEdit()} inline>
															<Icons.Pencil />
														</Button>
													)}
												</UpdateBookingTagInDialog>
											</Inline>
											{booking_stage.comments ? (
												<Box
													fontSize="sm"
													style={{ maxWidth: "200px" }}
													verticalAlign="top"
													color="muted"
													marginTop="1"
												>
													<Box
														textOverflow="truncate"
														title={booking_stage.comments}
													>
														{booking_stage.comments}
													</Box>
												</Box>
											) : null}
										</Box>
										{can_view_prices ? (
											<Box
												as="td"
												verticalAlign="top"
												style={{ textAlign: "right" }}
											>
												{isNumeric(booked_price) ? (
													<Inline
														fontWeight="semibold"
														alignItems="center"
														justifyContent="end"
														gap="1"
													>
														<Box>Booking:</Box>
														<Money
															money={moneyParseByDecimal(
																booked_price,
																currency
															)}
															showCurrency
														/>
													</Inline>
												) : null}
												{isNumeric(payments_amount) ? (
													<Box fontWeight="semibold">
														{isNumeric(refunding_payments_amount) &&
														isNumeric(cancellation_charges) ? (
															<Box>
																Chargable:{" "}
																<Money
																	amount={cancellation_charges}
																	currency={currency}
																/>
															</Box>
														) : isNumeric(booked_price) &&
														  Number(payments_amount || 0) ===
																Number(booked_price || 0) ? null : (
															<Box>
																Chargable:{" "}
																<Money
																	amount={payments_amount}
																	currency={currency}
																/>
															</Box>
														)}
													</Box>
												) : null}
												{isNumeric(paid_payments_amount) ? (
													<Box fontWeight="semibold">
														Paid:{" "}
														<Money
															amount={paid_payments_amount}
															currency={currency}
														/>
													</Box>
												) : null}
												{isNumeric(refunding_payments_amount) ? (
													<Box fontWeight="semibold">
														Refund:{" "}
														<Money
															amount={paid_refunding_payments_amount || 0}
															currency={currency}
														/>
														{" / "}
														<Money
															amount={refunding_payments_amount}
															currency={currency}
														/>
													</Box>
												) : null}
											</Box>
										) : null}
									</Box>
								)
							})}
						</tbody>
					</Table>
				</Box>
			)}
		</Stack>
	)
}

function AddMoreHotels({
	startDate,
	endDate,
	children,
	tripId,
	noOfAdults,
	onCancel,
	onSuccess,
	tripDestinations,
}: {
	startDate: Date
	endDate: Date
	tripId: number
	children: $PropertyType<IBooking, "children">
	noOfAdults: number
	onCancel?: () => void
	onSuccess?: () => void
	tripDestinations: Array<TTripDestination>
}) {
	const xhr = useXHR()
	return (
		<HotelBookingDetailsForm
			tripDestinations={tripDestinations}
			startDate={startDate}
			endDate={endDate}
			children={children}
			noOfAdults={noOfAdults}
			onCancel={onCancel}
			onSubmit={async (data) => {
				return xhr
					.post(`/hotel-bookings`, {
						details: data.hotels.map((data) => ({
							...data,
							trip_id: tripId,
						})),
						extras: data.extras.map((data) => ({
							...data,
							trip_id: tripId,
						})),
					})
					.then(onSuccess)
			}}
		/>
	)
}
