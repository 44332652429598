import {
	Badge,
	Box,
	Button,
	Inline,
	Stack,
	Text,
	Icons,
	TableDataCell,
	Table,
	Container,
	Col,
	Component,
	Grid,
	joinAttributes,
	Spinner,
	Time,
	useBreakpoints,
	Money,
} from "@sembark-travel/ui/base"
import { Dialog } from "@sembark-travel/ui/dialog"
import {
	useSearch,
	Search,
	areAdvancedFiltersAppliedDefault,
} from "@sembark-travel/ui/list"
import {
	Breadcrumbs,
	useGetBackUrlFromLocation,
	useLocationQuery,
	queryToSearch,
	ButtonLink,
	XHRLink,
} from "@sembark-travel/ui/router"
import { Link } from "react-router-dom"
import {
	utcTimestampToLocalDate,
	dateToUTCString,
	parseDateFromQuery,
	startOf,
	endOf,
	formatDate,
	dateToQuery,
	subtractUnit,
	addUnit,
} from "@sembark-travel/datetime-utils"
import { isNumeric } from "@sembark-travel/number-utils"
import { useXHR } from "@sembark-travel/xhr"
import React, { Fragment, useEffect, useMemo } from "react"
import useSWR from "swr"
import { $PropertyType } from "utility-types"
import { ICabType } from "../CabTypes"
import config from "../config"
import { Email, PhoneNumber } from "../Contacts"
import { generatePath } from "../router-utils"
import { EditScheduleInDialog } from "./EditItem"
import { ScheduleDetails } from "./Item"
import {
	ICabSchedulesFilters,
	ICabSchedulesFiltersInLocationQuery,
	cabScheduleFilterParamsToLocationQuery,
	cabSchedulesLocationQueryToParams,
} from "./utils"
import { ShareSchedules } from "./ShareSchedules"
import { ICabSchedule, IScheduledCab, schedulesXHR } from "./store"
import { SelectField } from "@sembark-travel/ui/form"
import { SelectCabTypes } from "../CabTypes"
import { SelectUsers } from "../Users"
import { SelectTransportServiceLocationPoints } from "../TransportServices"
import { SelectTransporServiceProviders } from "../TransportServiceProviders"
import { SelectTripDestination } from "../TripDestinations"

export function CabSchedulesForDate({ date }: { date: string }) {
	const xhr = useXHR()
	const parsedDate = useMemo(() => {
		return parseDateFromQuery(date)
	}, [date])
	const [queryFilters, setQueryFilters] = useLocationQuery<
		ICabSchedulesFilters,
		ICabSchedulesFiltersInLocationQuery
	>({
		toQuery: cabScheduleFilterParamsToLocationQuery,
		fromQuery: cabSchedulesLocationQueryToParams,
	})
	const [params, setParams] = useSearch<ICabSchedulesFilters>({
		q: "",
		...queryFilters,
	})
	useEffect(() => {
		setQueryFilters(params)
	}, [params, setQueryFilters])
	const filters = useMemo(() => {
		const {
			q,
			transport_locations,
			transport_service_providers,
			transport_service_location_points,
			cab_types,
			status,
			trip_destinations,
			owners,
		} = params
		return {
			q: q ? q.trim() : null,
			start_date: dateToUTCString(startOf(parsedDate, "day")),
			end_date: dateToUTCString(endOf(parsedDate, "day")),
			transport_locations:
				transport_locations && transport_locations.length
					? transport_locations.map((t) => t.name)
					: [],
			transport_service_providers: transport_service_providers?.length
				? transport_service_providers.map((t) => t.id)
				: [],
			transport_service_location_points:
				transport_service_location_points?.length
					? transport_service_location_points.map((t) => t.id)
					: [],
			cab_types: cab_types?.length ? cab_types.map((t) => t.id) : [],
			trip_destinations: trip_destinations?.length
				? trip_destinations.map((t) => t.id)
				: [],
			owners: owners?.length ? owners.map((o) => o.id) : [],
			status: status?.id,
		}
	}, [params, parsedDate])
	const { data, mutate: revalidate } = useSWR(
		`cab_schedules_for_date_${date}${queryToSearch(filters)}`,
		() => {
			return schedulesXHR(xhr).getSchedules(filters)
		}
	)
	const overview = useMemo(() => {
		return getOverview(data ? data : [])
	}, [data])
	const back = useGetBackUrlFromLocation()
	const { xs, sm } = useBreakpoints()
	return (
		<>
			<Breadcrumbs
				title="Cab Schedules"
				items={[
					[
						generatePath("/cab-schedules/calendar") +
							queryToSearch({ date: dateToQuery(parsedDate) }),
						"Calendar",
					],
					[
						"",
						parsedDate
							? formatDate(parsedDate, config.dateDisplayFormat)
							: "Date",
					],
				]}
				actions={
					<Inline gap="4">
						{data?.length ? (
							<ShareSchedules
								schedules={data}
								canShareWith={["guest", "transportServiceProvider", "driver"]}
							>
								{({ share }) => (
									<Button level="primary" onClick={() => share()}>
										<Icons.Share /> Share
									</Button>
								)}
							</ShareSchedules>
						) : null}
						<ButtonLink
							to={generatePath("/cab-schedules/new")}
							query={{ date }}
							level="secondary"
							status="primary"
							anchored
						>
							<Icons.Plus /> Add New Schedule
						</ButtonLink>
						{parsedDate ? (
							<Button
								as={XHRLink}
								query={{
									...filters,
									timezone_offset: config.timezoneOffset,
								}}
								href="/trip-cab-schedules/download"
							>
								<Icons.DocumentDownload /> Download
							</Button>
						) : null}
					</Inline>
				}
			/>
			<Search
				title={formatDate(parsedDate, config.dateDisplayFormat)}
				initialParams={params}
				placeholder="Search by id, destination, guest..."
				Filters={Filters}
				onSearch={setParams}
				resetParams={(params) => ({
					q: "",
					date: params.date,
				})}
				areAdvancedFiltersApplied={(params) => {
					const { date, ...otherParams } = params
					return areAdvancedFiltersAppliedDefault(otherParams)
				}}
				actions={
					<>
						<Inline gap="4" alignItems="center">
							<ButtonLink
								title="Previous Day"
								to={
									generatePath("/cab-schedules/date/:date", {
										date: dateToQuery(subtractUnit(parsedDate, 1, "day")),
									}) + queryToSearch({ back })
								}
							>
								<Icons.ChevronDown rotate="90" />
							</ButtonLink>
							<ButtonLink
								title="Next Day"
								to={
									generatePath("/cab-schedules/date/:date", {
										date: dateToQuery(addUnit(parsedDate, 1, "day")),
									}) + queryToSearch({ back })
								}
							>
								<Icons.ChevronDown rotate="270" />
							</ButtonLink>
						</Inline>
					</>
				}
			>
				<Container paddingY="6">
					{!data ? (
						<Spinner padding="4" alignCenter />
					) : data.length ? (
						<Box>
							<Box marginBottom="8">
								<Box as="h5" fontSize="lg" fontWeight="semibold" color="muted">
									Overview
								</Box>
								<Box overflow="auto">
									<Table
										autoWidth
										hover
										borderWidth="1"
										whiteSpace="preserveAndWrap"
										headers={[""]
											.concat(overview.cabsPerPickup.map((c) => c.pickup))
											.concat(["Total"])}
										rows={overview.cabTypes.map((c) => {
											const cabsForThisType = overview.cabsPerPickup.reduce<
												Array<IScheduledCab>
											>(
												(cabsForThisType, { cabs }) =>
													cabsForThisType.concat(
														cabs.filter((cab) => cab.cab_type.id === c.id)
													),
												[]
											)
											return [c.name as React.ReactNode]
												.concat(
													overview.cabsPerPickup.map(({ cabs }) => {
														const cabsForThisTypeForThisPickup = cabs.filter(
															(cab) => cab.cab_type.id === c.id
														)
														if (cabsForThisTypeForThisPickup.length === 0)
															return null
														return (
															<Box>
																<Box color="success" as="span">
																	{
																		cabsForThisTypeForThisPickup.filter(
																			(c) => c.booked
																		).length
																	}
																</Box>
																{" + "}
																<Box color="warning" as="span">
																	{
																		cabsForThisTypeForThisPickup.filter(
																			(c) => !c.booked
																		).length
																	}
																</Box>
															</Box>
														)
													})
												)
												.concat([
													<Box>
														<Box color="success" as="span">
															{cabsForThisType.filter((c) => c.booked).length}
														</Box>
														{" + "}
														<Box color="warning" as="span">
															{cabsForThisType.filter((c) => !c.booked).length}
														</Box>
														{" = "}
														<Box as="span">{cabsForThisType.length}</Box>
													</Box>,
												])
										})}
									>
										<tfoot>
											<tr>
												<TableDataCell textAlign="right">Total</TableDataCell>
												{overview.cabsPerPickup.map(({ pickup, cabs }) => (
													<TableDataCell key={pickup}>
														<Box color="success" as="span">
															{cabs.filter((c) => c.booked).length}
														</Box>
														{" + "}
														<Box color="warning" as="span">
															{cabs.filter((c) => !c.booked).length}
														</Box>
														{" = "}
														<Box as="span">{cabs.length}</Box>
													</TableDataCell>
												))}
												<TableDataCell>
													<Box color="success" as="span">
														{overview.bookedCabsCount}
													</Box>
													{" + "}
													<Box color="warning" as="span">
														{overview.notBookedCabsCount}
													</Box>
													{" = "}
													<Box as="span">{overview.cabsCount}</Box>
												</TableDataCell>
											</tr>
										</tfoot>
									</Table>
								</Box>
							</Box>
							<Box>
								{!xs && !sm ? (
									<Table
										className="excel-style-table"
										headers={[
											"Service",
											"Guest",
											"Pickup",
											"Cab",
											"Provider",
											"Driver",
											"Number Plate",
											"Price",
										]}
									>
										<tbody>
											{data.map((schedule) => (
												<Fragment key={schedule.id}>
													{schedule.cabs.map((cab, index) => (
														<Box as="tr" key={cab.id}>
															{index === 0 ? (
																<>
																	<Box as="td" rowSpan={schedule.cabs.length}>
																		<Box
																			display="flex"
																			justifyContent="between"
																			alignItems="center"
																		>
																			<Box marginRight="2">
																				<Component initialState={false}>
																					{({ state, setState }) => (
																						<>
																							<Text<"a">
																								as="a"
																								href={`#{schedule.id}`}
																								color="accent"
																								fontWeight="semibold"
																								onClick={(e) => {
																									e.preventDefault()
																									setState(true)
																								}}
																							>
																								{
																									schedule.transport_service
																										.from_to
																								}
																							</Text>
																							{state ? (
																								<Dialog
																									open
																									onClose={() =>
																										setState(false)
																									}
																									title="Schedule Details"
																									lg
																								>
																									<Dialog.Body>
																										<ScheduleDetails
																											onRefresh={revalidate}
																											schedule={schedule}
																										/>
																									</Dialog.Body>
																								</Dialog>
																							) : null}
																						</>
																					)}
																				</Component>
																				<Text fontSize="sm">
																					{schedule.transport_service.service}
																				</Text>
																			</Box>
																			<Inline gap="2">
																				<ShareSchedules schedules={[schedule]}>
																					{({ share }) => (
																						<Button
																							level="tertiary"
																							size="sm"
																							onClick={share}
																							title="Share Schedule Details with Guest and Transporter"
																						>
																							<Icons.Share />
																						</Button>
																					)}
																				</ShareSchedules>
																				{schedule.can_modify ? (
																					<EditScheduleInDialog
																						scheduleId={schedule.id}
																						onSuccess={() => revalidate()}
																					>
																						{({ onEdit }) => (
																							<Button
																								onClick={() => onEdit()}
																								level="tertiary"
																								size="sm"
																							>
																								<Icons.Pencil />
																							</Button>
																						)}
																					</EditScheduleInDialog>
																				) : null}
																			</Inline>
																		</Box>
																	</Box>
																	<Box as="td" rowSpan={schedule.cabs.length}>
																		{schedule.trip ? (
																			<Box>
																				<Box fontWeight="semibold">
																					{schedule.trip.contact.name}{" "}
																					<Box
																						color="muted"
																						fontSize="sm"
																						as="span"
																						title="Pax Details"
																					>
																						({schedule.trip.no_of_adults}A)
																					</Box>
																				</Box>
																				<Box fontSize="sm">
																					{joinAttributes(
																						<Link
																							to={generatePath(
																								"/trips/:tripId",
																								{
																									tripId:
																										schedule.trip.id.toString(),
																								}
																							)}
																						>
																							Trip ID {schedule.trip.id}
																						</Link>,
																						schedule.trip.trip_source.short_name
																					)}
																				</Box>
																			</Box>
																		) : null}
																	</Box>
																	<Box as="td" rowSpan={schedule.cabs.length}>
																		<Time
																			value={utcTimestampToLocalDate(
																				schedule.start_date
																			)}
																			format="HH:mm"
																		/>
																	</Box>
																</>
															) : null}
															<Box as="td">
																{cab.cab?.name || cab.cab_type.name}
																{!cab.cab && cab.cab_type.deleted_at ? (
																	<Icons.Ban color="warning" title="Disabled" />
																) : null}
																{cab.booked ? (
																	<Box display="inlineBlock" marginLeft="1">
																		<Icons.OkCircleSolid
																			color="success"
																			title="Cab Booked"
																		/>
																	</Box>
																) : null}
															</Box>
															<Box as="td">
																{cab.transport_service_provider ? (
																	<Box>
																		{cab.transport_service_provider.name}
																		{cab.transport_service_provider.contacts
																			?.length
																			? joinAttributes(
																					cab.transport_service_provider
																						.contacts[0].phone_numbers
																						?.length ? (
																						<PhoneNumber
																							value={
																								cab.transport_service_provider
																									.contacts[0].phone_numbers
																							}
																							iconOnly
																							marginLeft="1"
																						/>
																					) : null,
																					cab.transport_service_provider
																						.contacts[0].email ? (
																						<Email
																							value={
																								cab.transport_service_provider
																									.contacts[0].email
																							}
																							iconOnly
																							marginLeft="1"
																						/>
																					) : null
																				)
																			: null}
																	</Box>
																) : null}
															</Box>
															<Box as="td">
																{cab.driver ? (
																	<Box>
																		{cab.driver.name}
																		{joinAttributes(
																			cab.driver.phone_number ? (
																				<PhoneNumber
																					value={cab.driver.phone_number}
																					iconOnly
																					marginLeft="1"
																				/>
																			) : null,
																			cab.driver.email ? (
																				<Email
																					value={cab.driver.email}
																					iconOnly
																					marginLeft="1"
																				/>
																			) : null
																		)}
																	</Box>
																) : null}
															</Box>
															<Box as="td">{cab.cab?.number_plate}</Box>
															<Box as="td">
																{schedule.can_modify &&
																isNumeric(cab.booked_price) ? (
																	<Money
																		amount={cab.booked_price}
																		currency={cab.currency}
																	/>
																) : null}
															</Box>
														</Box>
													))}
												</Fragment>
											))}
										</tbody>
									</Table>
								) : (
									<Box as="ol">
										{data.map((schedule) => (
											<Box
												as="li"
												key={schedule.id}
												marginBottom="8"
												borderWidth="1"
												rounded="lg"
												bgColor="default"
											>
												<Box padding="4">
													<Box>
														<Box>
															<Box fontSize="lg" fontWeight="semibold">
																<Component initialState={false}>
																	{({ state, setState }) => (
																		<>
																			<a
																				href={`#{schedule.id}`}
																				onClick={(e) => {
																					e.preventDefault()
																					setState(true)
																				}}
																			>
																				{schedule.transport_service.from_to}
																			</a>
																			{state ? (
																				<Dialog
																					open
																					onClose={() => setState(false)}
																					title="Schedule Details"
																					lg
																				>
																					<Dialog.Body>
																						<ScheduleDetails
																							onRefresh={revalidate}
																							schedule={schedule}
																						/>
																					</Dialog.Body>
																				</Dialog>
																			) : null}
																		</>
																	)}
																</Component>
															</Box>
															<Box fontWeight="semibold" color="accent">
																{schedule.transport_service.service}
															</Box>
														</Box>
													</Box>
													<Box marginTop="2" display="flex" alignItems="center">
														<Box display="inlineBlock" marginRight="2">
															<Icons.Calendar color="muted" />
														</Box>
														<Time
															value={utcTimestampToLocalDate(
																schedule.start_date
															)}
															format="HH:mm"
														/>
													</Box>
													{schedule.trip ? (
														<Box
															marginTop="2"
															display="flex"
															alignItems="center"
														>
															<Box display="inlineBlock" marginRight="2">
																<Icons.UserGroup color="muted" />
															</Box>
															<Box>
																<Box fontWeight="semibold">
																	{schedule.trip.contact.name}{" "}
																	<Box
																		color="muted"
																		fontSize="sm"
																		as="span"
																		title="Pax Details"
																	>
																		({schedule.trip.no_of_adults}A)
																	</Box>
																</Box>
																<Box fontSize="sm">
																	{joinAttributes(
																		<Link
																			to={generatePath("/trips/:tripId", {
																				tripId: schedule.trip.id.toString(),
																			})}
																		>
																			Trip ID {schedule.trip.id}
																		</Link>,
																		schedule.trip.trip_source.short_name
																	)}
																</Box>
															</Box>
														</Box>
													) : null}
												</Box>
												<Box as="ul" paddingX="4">
													{schedule.cabs.map((cab, i) => (
														<Box
															key={cab.id}
															as="li"
															padding="4"
															bgColor="subtle"
															marginTop={i > 0 ? "4" : "0"}
															rounded="lg"
														>
															<Box display="flex">
																<Box fontWeight="semibold">
																	{cab.cab?.name || cab.cab_type.name}
																	{!cab.cab && cab.cab_type.deleted_at ? (
																		<Icons.Ban
																			color="warning"
																			title="Disabled"
																		/>
																	) : null}
																</Box>
																{cab.cab?.number_plate ? (
																	<Box marginLeft="4">
																		<Badge warning outlined>
																			{cab.cab.number_plate}
																		</Badge>
																	</Box>
																) : null}
																{cab.booked ? (
																	<Box marginLeft="2">
																		{schedule.can_modify ? (
																			<Badge success outlined>
																				{cab.booked_price ? (
																					<Money
																						amount={cab.booked_price}
																						currency={cab.currency}
																					/>
																				) : (
																					"Booked"
																				)}
																			</Badge>
																		) : null}
																	</Box>
																) : null}
															</Box>
															<Grid>
																{cab.driver ? (
																	<Col marginTop="2">
																		<Box
																			textTransform="uppercase"
																			fontWeight="semibold"
																			letterSpacing="wider"
																			color="muted"
																			fontSize="xs"
																		>
																			Driver
																		</Box>
																		<Box fontWeight="semibold" marginBottom="1">
																			{cab.driver.name}
																		</Box>
																		<Box fontSize="sm" color="muted">
																			{joinAttributes(
																				cab.driver.phone_number ? (
																					<PhoneNumber
																						value={cab.driver.phone_number}
																					/>
																				) : null,
																				cab.driver.email ? (
																					<Email
																						value={cab.driver.email}
																						iconOnly
																					/>
																				) : null
																			)}
																		</Box>
																	</Col>
																) : null}
																{cab.transport_service_provider ? (
																	<Col marginTop="2">
																		<Box
																			textTransform="uppercase"
																			fontWeight="semibold"
																			letterSpacing="wider"
																			color="muted"
																			fontSize="xs"
																		>
																			Provider
																		</Box>
																		<Box fontWeight="semibold" marginBottom="1">
																			{cab.transport_service_provider.name}
																		</Box>
																		{cab.transport_service_provider.contacts
																			?.length
																			? joinAttributes(
																					cab.transport_service_provider
																						.contacts[0].phone_numbers
																						?.length ? (
																						<PhoneNumber
																							value={
																								cab.transport_service_provider
																									.contacts[0].phone_numbers
																							}
																							iconOnly
																							marginLeft="1"
																						/>
																					) : null,
																					cab.transport_service_provider
																						.contacts[0].email ? (
																						<Email
																							value={
																								cab.transport_service_provider
																									.contacts[0].email
																							}
																							iconOnly
																							marginLeft="1"
																						/>
																					) : null
																				)
																			: null}
																	</Col>
																) : null}
															</Grid>
														</Box>
													))}
												</Box>
												<Inline padding="4" gap="4">
													<ShareSchedules schedules={[schedule]}>
														{({ share }) => (
															<Button
																level="tertiary"
																size="sm"
																onClick={share}
																title="Share Schedule Details with Guest and Transporter"
															>
																<Icons.Share /> Share
															</Button>
														)}
													</ShareSchedules>
													{schedule.can_modify ? (
														<EditScheduleInDialog
															scheduleId={schedule.id}
															onSuccess={() => revalidate()}
														>
															{({ onEdit }) => (
																<Button
																	onClick={() => onEdit()}
																	level="tertiary"
																	size="sm"
																>
																	<Icons.Pencil /> Edit
																</Button>
															)}
														</EditScheduleInDialog>
													) : null}
												</Inline>
											</Box>
										))}
									</Box>
								)}
							</Box>
						</Box>
					) : (
						<Stack paddingY="8">
							<Text fontSize="lg" fontWeight="semibold" textAlign="center">
								No cabs scheduled for{" "}
								{formatDate(parsedDate, config.dateDisplayFormat)}
							</Text>
						</Stack>
					)}
				</Container>
			</Search>
		</>
	)
}

function getOverview(schedules: Array<ICabSchedule> = []) {
	const cabsPerPickup: {
		[key: string]: Array<$PropertyType<ICabSchedule, "cabs">[0]>
	} = {}
	const cabTypes: Array<ICabType> = []
	let cabsCount = 0
	let bookedCabsCount = 0
	for (const schedule of schedules) {
		for (const scheduledCab of schedule.cabs) {
			const cabType = scheduledCab.cab_type
			if (cabTypes.findIndex((c) => cabType.id === c.id) === -1) {
				cabTypes.push(cabType)
			}
		}
		cabsCount += schedule.cabs.length
		bookedCabsCount += schedule.booked_cabs_count
		const pickup = schedule.transport_service.locations?.from
		if (!pickup) continue
		if (!cabsPerPickup[pickup.name]) {
			cabsPerPickup[pickup.name] = []
		}
		cabsPerPickup[pickup.name] = cabsPerPickup[pickup.name].concat(
			schedule.cabs
		)
	}
	const cabsCountWithPickupLocations: Array<{
		pickup: string
		cabs: Array<$PropertyType<ICabSchedule, "cabs">[0]>
	}> = Object.keys(cabsPerPickup).map((pickup) => ({
		pickup,
		cabs: cabsPerPickup[pickup],
	}))
	cabsCountWithPickupLocations.sort(function (a, b) {
		return a.cabs.length > b.cabs.length ? -1 : 1
	})
	return {
		cabsCount,
		bookedCabsCount,
		notBookedCabsCount: cabsCount - bookedCabsCount,
		cabTypes,
		cabsPerPickup: cabsCountWithPickupLocations,
	}
}

function Filters() {
	return (
		<Stack gap="4">
			<SelectField
				select={SelectTripDestination}
				name="trip_destinations"
				multiple
				label="Trip Destinations"
				fetchOnMount
			/>
			<SelectField
				select={SelectTransporServiceProviders}
				name="transport_service_providers"
				multiple
				label="Service Providers"
				fetchOnMount
			/>
			<SelectField
				select={SelectTransportServiceLocationPoints}
				name="transport_service_location_points"
				label="Service Cities"
				multiple
			/>
			<SelectField
				select={SelectCabTypes}
				name="cab_types"
				label="Cab Types"
				multiple
				fetchOnMount
			/>
			<SelectField
				label="Team"
				select={SelectUsers}
				name="owners"
				multiple
				fetchOnMount
			/>
		</Stack>
	)
}
