import { Box, Container } from "@sembark-travel/ui/base"
import { Breadcrumbs } from "@sembark-travel/ui/router"
import { Helmet } from "react-helmet-async"
import { MergeHotels } from "../../../Hotels"
import { generatePath, useNavigate } from "../../../router-utils"

export default function MergeHotelsPage() {
	const navigate = useNavigate()
	const listPage = generatePath("/hotels")
	function navigateToList() {
		return navigate("/hotels")
	}
	return (
		<>
			<Helmet>
				<title>Merge Hotels</title>
			</Helmet>
			<Breadcrumbs
				title="Merge Hotels"
				items={[
					[listPage, "Hotels"],
					["", "Merge"],
				]}
			/>
			<Box paddingX={{ md: "4" }} paddingY={{ md: "8" }}>
				<Box maxWidth="6xl" marginX="auto">
					<Box borderWidth={{ md: "1" }} rounded="md" bgColor="default">
						<Container paddingY="6">
							<MergeHotels
								onSuccess={navigateToList}
								onCancel={navigateToList}
							/>
						</Container>
					</Box>
				</Box>
			</Box>
		</>
	)
}
