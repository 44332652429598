import { Box, BoxOwnProps } from "./Box"
import {
	containerClassName,
	layer1ClassName,
	layer2ClassName,
	layer3ClassName,
	layer4ClassName,
	circleClassName,
	gapPatchClasName,
	circleClipperVariants,
} from "./spinner.css"

export function Spinner({
	alignCenter,
	padding,
}: Pick<BoxOwnProps, "padding"> & {
	alignCenter?: boolean
}) {
	const spinner = (
		<Box className={containerClassName}>
			<Layer className={layer1ClassName} />
			<Layer className={layer2ClassName} />
			<Layer className={layer3ClassName} />
			<Layer className={layer4ClassName} />
		</Box>
	)
	if (!alignCenter || !padding) return spinner
	return (
		<Box textAlign={alignCenter ? "center" : undefined} padding={padding}>
			{spinner}
		</Box>
	)
}

function Layer({ className }: { className: string }) {
	return (
		<Box className={className}>
			<Box className={circleClipperVariants.left}>
				<Box className={circleClassName} />
			</Box>
			<Box className={gapPatchClasName}>
				<Box className={circleClassName} />
			</Box>
			<Box className={circleClipperVariants.right}>
				<Box className={circleClassName} />
			</Box>
		</Box>
	)
}
