import { TViews } from "./types"

export const VIEWS: { [key: string]: TViews } = {
	YEARS: "years",
	MONTHS: "months",
	DAYS: "days",
	TIME: "time",
}

export function getDaysOfWeek() {
	return [
		{ short: "Sun", full: "Sunday" },
		{ short: "Mon", full: "Monday" },
		{ short: "Tue", full: "Tuesday" },
		{ short: "Wed", full: "Wednesday" },
		{ short: "Thu", full: "Thursday" },
		{ short: "Fri", full: "Friday" },
		{ short: "Sat", full: "Saturday" },
	]
}

export function alwaysValidDate(..._: Array<unknown>) {
	return true
}

export const DEFAULT_TIME_FORMAT = "h:mm A"

export const DEFAULT_DATE_FORMAT = "MMMM D, YYYY"

export function getDateTimeFormats(
	configDateFormat: string | boolean = true,
	configTimeFormat?: string | boolean
) {
	// convert the date/timeFormat to string
	const timeFormat = !configTimeFormat
		? ""
		: typeof configTimeFormat === "string"
		? configTimeFormat
		: DEFAULT_TIME_FORMAT

	const dateFormat = !configDateFormat
		? ""
		: typeof configDateFormat === "string"
		? configDateFormat
		: DEFAULT_DATE_FORMAT

	const dateTimeFormat =
		dateFormat && timeFormat
			? `${dateFormat} ${timeFormat}`
			: dateFormat || timeFormat

	return { timeFormat, dateFormat, dateTimeFormat }
}

export function formatHasHours(timeFormat: string) {
	return timeFormat.toLowerCase().indexOf("h") !== -1
}

export function formatHasMinutes(timeFormat: string) {
	return timeFormat.indexOf("m") !== -1
}

export function formatHasSeconds(timeFormat: string) {
	return timeFormat.indexOf("s") !== -1
}

export function formatHasAmPm(timeFormat: string) {
	return timeFormat.toLowerCase().indexOf("a") !== -1
}
