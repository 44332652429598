import { useParams, Navigate } from "../../../../../router-utils"
import { useOutletContext } from "react-router-dom"

export default function RedirectToDefaultQuote() {
	const { tripId } = useParams("/trips/:tripId/quotes")
	const { defaultQuoteId } = useOutletContext<{ defaultQuoteId: number }>()
	if (!defaultQuoteId)
		return <Navigate replace to="/trips/:tripId" params={{ tripId }} />
	return (
		<Navigate
			replace
			to="/trips/:tripId/quotes/:quoteId"
			params={{ tripId, quoteId: defaultQuoteId.toString() }}
		/>
	)
}
