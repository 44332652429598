import { AsyncSelect } from "@sembark-travel/ui/base"
import { TTripDestination } from "../TripDestinations"
import { useXHR } from "@sembark-travel/xhr"
import { hotelsXHR as XHR } from "./store"

export function SelectHotelLocation({
	tripDestinations,
	...props
}: Omit<React.ComponentProps<typeof AsyncSelect>, "fetch"> & {
	tripDestinations?: Array<TTripDestination>
}) {
	const xhr = useXHR()
	return (
		<AsyncSelect
			{...props}
			cacheKey={`hotel-locations-${tripDestinations
				?.map((t) => t.id)
				.join("-")}`}
			perFetchLimit={30}
			fetch={(q, { page }) =>
				XHR(xhr).getHotelLocations({
					limit: 30,
					q,
					page,
					trip_destinations: tripDestinations?.length
						? tripDestinations.map((t) => t.id)
						: null,
				})
			}
		/>
	)
}
