import { refreshCSRFToken } from "@sembark-travel/xhr"
import React from "react"
import useSWR from "swr"

// CSRF comes with 120 mins expiry time but
// I'm not able to parse the cookie
// TODO: parse the cookie and then set interval
//
// Until then, we will refresh on every 60 minutes
const INTERVAL = 60 * 60 * 1000

export function CSRFTokenProvider({ children }: { children: React.ReactNode }) {
	// auto refresh csrf token
	useSWR("x-csrf-token", refreshCSRFToken, {
		focusThrottleInterval: INTERVAL,
		revalidateOnFocus: true,
	})
	return <>{children}</>
}
