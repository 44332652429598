import { Helmet } from "react-helmet-async"
import { BankAccountsList } from "./../../../../Admin/BankAccounts"

export default function Tenants(
	props: React.ComponentProps<typeof BankAccountsList>
) {
	return (
		<>
			<Helmet>
				<title>Bank Accounts</title>
			</Helmet>
			<BankAccountsList {...props} />
		</>
	)
}
