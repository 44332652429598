import {
	Box,
	Button,
	Inline,
	Icons,
	Stack,
	Heading,
	Text,
	Col,
	Divider,
	Grid,
} from "@sembark-travel/ui/base"
import { Link } from "@sembark-travel/ui/router"
import { useXHR } from "@sembark-travel/xhr"
import { Fragment } from "react"
import * as Validator from "yup"
import { generatePath } from "../router-utils"
import { IConnectionRequest } from "./store"
import {
	Form,
	validateFormValues,
	withServerErrors,
	TextInputField,
	SubmissionError,
	TextAreaInputField,
} from "@sembark-travel/ui/form"

const validationSchema = Validator.object().shape({
	tenant_addressing_name: Validator.string()
		.max(191, "Maximum 191 characters are allowed for the name field")
		.required("Please provide name of the business you want to connect with"),
	tenant_addressing_email: Validator.string()
		.email("Please provide a valid email address")
		.required(
			"Please provide your contact's email address to send connection request"
		),
	reference_id: Validator.string().required(
		"Please provide the business ID of the connecting business"
	),
	comments: Validator.string(),
})

const initialValues = {
	tenant_addressing_name: "",
	tenant_addressing_email: "",
	reference_id: "",
	comments: "",
}

type NewItemCredentials = typeof initialValues

export function NewItem({
	onSuccess,
	onCancel,
}: {
	onSuccess: (request: IConnectionRequest) => void
	onCancel?: () => void
}) {
	const xhr = useXHR()
	return (
		<Fragment>
			<Form<NewItemCredentials>
				initialValues={initialValues}
				validate={validateFormValues(validationSchema)}
				onSubmit={withServerErrors(async (values) => {
					const request = await xhr.post("/connection-requests", values)
					onSuccess(request.data.data)
					return request
				})}
				subscription={{ submitting: true }}
			>
				{({ submitting, handleSubmit, form }) => (
					<form noValidate onSubmit={handleSubmit}>
						<SubmissionError />
						<Grid gap="4">
							<Col xs={12} sm={6}>
								<Stack gap="1">
									<Heading as="h3">Business Details</Heading>
									<Text color="muted">
										Provide ID, name and a contact email of the business you are
										connecting with. Ask your contact to provide their Sembark
										Business ID.
									</Text>
								</Stack>
							</Col>
							<Col>
								<Stack gap="4">
									<TextInputField
										label="Sembark Business ID"
										name="reference_id"
										placeholder="XYZWEQ"
										type="text"
										required
										help={
											<span>
												Business ID can be found on settings page of every
												account.
											</span>
										}
										onChange={(e) => {
											form.change(
												"reference_id",
												e.currentTarget.value.toUpperCase()
											)
										}}
									/>
									<Box fontWeight="semibold">
										<Icons.Info opacity="70" /> Not on Sembark ?{" "}
										<Link
											to={generatePath("/connection-requests/invite")}
											anchored
											title="Invite to Sembark"
											color="accent"
										>
											Click here to invite.
										</Link>
									</Box>
									<TextInputField
										label="Name"
										name="tenant_addressing_name"
										type="text"
										placeholder="XYZ Travels"
										required
									/>
									<TextInputField
										label="Email"
										name="tenant_addressing_email"
										type="email"
										placeholder="user@xyztravels.com"
										required
									/>
								</Stack>
							</Col>
						</Grid>
						<Divider />
						<Grid gap="4">
							<Col xs={12} sm={6}>
								<Stack gap="1">
									<Heading as="h3">Comments</Heading>
									<Text color="muted">
										Please provide any comments that will help other business
										identify you easily and make connection faster.
									</Text>
								</Stack>
							</Col>
							<Col>
								<TextAreaInputField
									label="Comments"
									name="comments"
									placeholder={`e.g. We met at the Travel Conference where you were giving talk about your business growth and areas of expertise. We shared our contact details for any future business with each other.
                `}
								/>
							</Col>
						</Grid>
						<Divider />
						<Grid>
							<Col xs={12} sm={{ span: 6, offset: 6 }}>
								<Inline gap="4">
									<Button type="submit" disabled={submitting}>
										{submitting ? "Sending..." : "Send Request"}
									</Button>
									{onCancel ? (
										<Button
											onClick={onCancel}
											level="tertiary"
											disabled={submitting}
										>
											Cancel
										</Button>
									) : null}
								</Inline>
							</Col>
						</Grid>
					</form>
				)}
			</Form>
		</Fragment>
	)
}
