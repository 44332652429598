import {
	Box,
	Button,
	Inline,
	Icons,
	Table,
	TableDataCell,
	Stack,
	Col,
	Grid,
	useTimeout,
	Text,
	Heading,
	joinAttributes,
	TableHeaderDataCell,
} from "@sembark-travel/ui/base"
import { useXHR, isAbortError } from "@sembark-travel/xhr"
import React, { useMemo, useRef, useEffect, useState } from "react"
import { Required } from "utility-types"
import { SelectTransportServices } from "../TransportServices/List"
import { collect } from "../utils"
import { ICabType } from "./../CabTypes"
import { ITransportService } from "./../TransportServices"
import {
	dateToUTCString,
	formatDate,
	isSame,
} from "@sembark-travel/datetime-utils"

import {
	Form,
	TextInputField,
	SelectField,
	GetFieldValue,
	useFieldValue,
	useForm,
	arrayMutators,
	FieldArray,
	isTruthy,
	PercentageInputField,
	SelectInputField,
	RadioInputField,
	SubmissionError,
	withServerErrors,
} from "@sembark-travel/ui/form"
import { type TTripDestination } from "../TripDestinations"
import { Money, TableFooterDataCell } from "@sembark-travel/ui/base"
import {
	addMoney,
	formatMoneyByDecimal,
	moneyParseByDecimal,
	multipleMoneyBy,
} from "@sembark-travel/money"
import { Dialog, useDialog } from "@sembark-travel/ui/dialog"
import {
	SelectCabPriceCalculationMetric,
	SelectCabPriceCalculationMetricPreset,
	type TCabPriceCalculationMetric,
	type TCabPriceCalculationMetricPreset,
} from "./../CabPriceCalculationMetrics"
import { InlineSelectTenantCurrencyInputField } from "../Currencies"

type TTransportServicesInputFieldValue = Array<{
	dates?: Array<Date>
	transport_service?: ITransportService
	cabs?: Array<{
		no_of_cabs: number
		cab_type?: ICabType
	}>
}>

export type TCabsMetricsWiseCalculatedPrices = Array<{
	configuration_id: number
	cab_type: ICabType
	no_of_cabs: number
	services?: Array<{
		date?: Date
		transport_service: ITransportService
		is_addon?: 0 | 1
	}>
	metric_quantities: {
		total_kms?: number
		total_days: number
	}
	currency: string
	per_quantity_excluding_tax_given_price: number
	per_quantity_given_price: number
	given_price: number
	metric_wise_prices?: Array<{
		metric?: TCabPriceCalculationMetric
		quantity?: number
		given_quantity?: number
		edited_given_quantity?: boolean
		currency: string
		fetching_prices?: 0 | 1
		per_quantity_price?: number
		per_quantity_given_price?: number
		edited_given_price?: boolean
	}>
}>

export type TCabPriceCalculationConfiguration = {
	id: number
	metric_preset?: TCabPriceCalculationMetricPreset
	services: Array<{
		date?: Date
		transport_service: ITransportService
		is_addon: 0 | 1
	}>
}

export type TCabPriceCalculationFormData = {
	cabs_tax_percentage?: number
	cabs_price_calculations?: TCabsMetricsWiseCalculatedPrices
	cabs_price_calculation_configurations?: Array<TCabPriceCalculationConfiguration>
}

export function CabPriceCalculationField({
	services,
	currency: defaultCurrency,
	tripDestinations,
	usingSameCabTypes,
}: {
	currency: string
	tripDestinations: Array<TTripDestination>
	services: TTransportServicesInputFieldValue
	usingSameCabTypes: boolean
}) {
	const form = useForm<TCabPriceCalculationFormData>()
	if (!services?.length) return null
	return (
		<Stack gap="8">
			<Inline justifyContent="between" gap="4" collapseBelow="sm">
				<Box>
					<SyncCabPriceCalculatorWithLatestServicesButton
						services={services}
						currency={defaultCurrency}
						usingSameCabTypes={usingSameCabTypes}
					/>
				</Box>
				<Inline gap="2" alignItems="center">
					<Text as="label" htmlFor="field_cabs_tax_percentage">
						Tax
					</Text>
					<PercentageInputField name="cabs_tax_percentage" size="sm" />
				</Inline>
			</Inline>
			<FieldArray<
				TCabsMetricsWiseCalculatedPrices[number]
			> name="cabs_price_calculations">
				{({ fields }) => (
					<Stack gap="6">
						{fields.map((cabFieldName, cabFieldIndex) => (
							<Grid gap="6" key={cabFieldName}>
								<Col sm={12} md={4}>
									<Stack gap="1">
										<GetFieldValue<ICabType> name={`${cabFieldName}.cab_type`}>
											{({ value: cab_type }) => (
												<GetFieldValue<number>
													name={`${cabFieldName}.no_of_cabs`}
												>
													{({ value: no_of_cabs }) => (
														<Heading color="primary" fontSize="md">
															{no_of_cabs}-{cab_type.name}
														</Heading>
													)}
												</GetFieldValue>
											)}
										</GetFieldValue>
										<GetFieldValue<
											TCabsMetricsWiseCalculatedPrices[number]["services"]
										>
											name={`${cabFieldName}.services`}
										>
											{({ value }) => (
												<Stack gap="1" fontSize="sm">
													{(value || []).map((v, i) => (
														<Inline key={i} gap="1">
															{v.is_addon ? <Text>(Garage Src.)</Text> : null}
															<Text color="muted">
																{v.transport_service.name}
															</Text>
															<Text>
																- {v.transport_service.distance || "n/a"} Kms
															</Text>
														</Inline>
													))}
												</Stack>
											)}
										</GetFieldValue>
									</Stack>
									<Box>
										<GetFieldValue<
											TCabsMetricsWiseCalculatedPrices[number]["metric_quantities"]
										>
											name={`${cabFieldName}.metric_quantities`}
										>
											{({ value }) => {
												const { total_kms, total_days } = value || {}
												return (
													<Text>
														{joinAttributes(
															<Text as="span">Kms: {total_kms || "n/a"}</Text>,
															<Text as="span">Days: {total_days || "n/a"}</Text>
														)}
													</Text>
												)
											}}
										</GetFieldValue>
									</Box>
								</Col>
								<Col>
									<FieldArray<
										Required<
											TCabsMetricsWiseCalculatedPrices[number]
										>["metric_wise_prices"][number]
									>
										name={`${cabFieldName}.metric_wise_prices`}
									>
										{({ fields }) => (
											<Table
												headers={[
													<Inline gap="4">
														<Text>Metric</Text>
														{cabFieldIndex > 0 ? (
															<Box>
																<Button
																	inline
																	title="Match Items"
																	onClick={() => {
																		const data = form.getFieldState(
																			"cabs_price_calculations"
																		)?.value
																		if (data) {
																			const first = data[0]
																			const others = data.slice(1)
																			others.forEach((o) => {
																				o.metric_wise_prices =
																					first.metric_wise_prices?.map((p) => {
																						const existing =
																							o.metric_wise_prices?.find(
																								(o) =>
																									o.metric?.id === p.metric?.id
																							)
																						if (existing) {
																							return { ...existing }
																						}
																						return {
																							...p,
																							per_quantity_given_price:
																								undefined,
																						}
																					}) || []
																			})
																			// now set these prices
																			form.change(
																				"cabs_price_calculations",
																				[first].concat(others)
																			)
																		}
																	}}
																>
																	<Icons.Refresh />
																</Button>
															</Box>
														) : null}
													</Inline>,
													"System Qty",
													"Quantity",
													"System Rate",
													"Given Rate/Qty.",
													<>
														Total (
														<InlineSelectTenantCurrencyInputField
															name={`${cabFieldName}.currency`}
															noCaret
															defaultValue={defaultCurrency}
														/>
														)
													</>,
													"",
												]}
												alignCols={{ 1: "center", 3: "center", 5: "right" }}
												bordered
												hover
												responsive
											>
												<GetFieldValue<
													TCabsMetricsWiseCalculatedPrices[number]["currency"]
												>
													name={`${cabFieldName}.currency`}
												>
													{({ value: currency }) => (
														<>
															<tbody>
																{fields.map((priceFieldName, indx) => (
																	<tr key={priceFieldName}>
																		<TableDataCell>
																			<SelectField
																				select={SelectCabPriceCalculationMetric}
																				name={`${priceFieldName}.metric`}
																				creatable
																				size="sm"
																				placeholder="e.g. Per Km."
																				required
																				fetchOnMount
																				maxWidth="sm"
																				minWidth="100px"
																				tripDestinations={tripDestinations}
																			/>
																		</TableDataCell>
																		<TableDataCell textAlign="center">
																			<SystemQuantityForMetric
																				name={priceFieldName}
																				cabFieldName={cabFieldName}
																			/>
																		</TableDataCell>
																		<TableDataCell>
																			<GivenQuantityForMetric
																				name={priceFieldName}
																			/>
																		</TableDataCell>
																		<TableDataCell textAlign="center">
																			<SystemRateForMetric
																				name={priceFieldName}
																				cabFieldName={cabFieldName}
																				currency={currency}
																			/>
																		</TableDataCell>
																		<TableDataCell>
																			<GivenPriceForMetric
																				name={priceFieldName}
																			/>
																		</TableDataCell>
																		<TableDataCell textAlign="right">
																			<TotalPriceForMetric
																				name={priceFieldName}
																			/>
																		</TableDataCell>
																		<TableDataCell verticalAlign="middle">
																			<Button
																				onClick={() => fields.remove(indx)}
																				inline
																				title="Remove Item"
																			>
																				<Icons.Cancel />
																			</Button>
																		</TableDataCell>
																	</tr>
																))}
															</tbody>
															<tfoot>
																<tr>
																	<TableFooterDataCell colSpan={3}>
																		<Button
																			size="sm"
																			onClick={() =>
																				fields.push({
																					metric: undefined,
																					quantity: undefined,
																					given_quantity: undefined,
																					per_quantity_given_price: undefined,
																					currency,
																				})
																			}
																		>
																			<Icons.Plus /> Add More
																		</Button>
																	</TableFooterDataCell>
																	{fields.length ? (
																		<>
																			<TableFooterDataCell
																				colSpan={2}
																				textAlign="right"
																			>
																				Total
																			</TableFooterDataCell>
																			<TableFooterDataCell textAlign="right">
																				<TotalGivenPriceExclusingTaxForCabMetrics
																					name={cabFieldName}
																				/>
																			</TableFooterDataCell>
																		</>
																	) : (
																		<TableFooterDataCell
																			colSpan={3}
																		></TableFooterDataCell>
																	)}
																	<TableFooterDataCell></TableFooterDataCell>
																</tr>
																<GetFieldValue<number | undefined>
																	name={`cabs_tax_percentage`}
																>
																	{({ value: cabs_tax_percentage }) =>
																		fields.length ? (
																			<>
																				{cabs_tax_percentage ? (
																					<tr>
																						<TableFooterDataCell
																							colSpan={5}
																							textAlign="right"
																						>
																							<Text
																								as="label"
																								htmlFor="field_cabs_tax_percentage"
																							>
																								Tax: {cabs_tax_percentage}%
																							</Text>
																						</TableFooterDataCell>
																						<TableFooterDataCell textAlign="right">
																							<GetFieldValue<number>
																								name={`${cabFieldName}.per_quantity_excluding_tax_given_price`}
																							>
																								{({
																									value:
																										per_quantity_excluding_tax_given_price,
																								}) => (
																									<Money
																										showCurrency
																										amount={
																											(per_quantity_excluding_tax_given_price *
																												Number(
																													cabs_tax_percentage ||
																														0
																												)) /
																											100
																										}
																										currency={currency}
																									/>
																								)}
																							</GetFieldValue>
																						</TableFooterDataCell>
																						<TableFooterDataCell></TableFooterDataCell>
																					</tr>
																				) : null}
																				<GetFieldValue<number>
																					name={`${cabFieldName}.no_of_cabs`}
																				>
																					{({ value: no_of_cabs }) => (
																						<tr>
																							<TableFooterDataCell
																								colSpan={5}
																								textAlign="right"
																							>
																								Grand Total{" "}
																								{no_of_cabs > 1
																									? `(${no_of_cabs} Cabs)`
																									: null}
																							</TableFooterDataCell>
																							<TableFooterDataCell textAlign="right">
																								<TotalGivenPriceForCabMetrics
																									name={cabFieldName}
																									currency={currency}
																									taxPercentage={
																										cabs_tax_percentage
																									}
																								/>
																							</TableFooterDataCell>
																							<TableFooterDataCell></TableFooterDataCell>
																						</tr>
																					)}
																				</GetFieldValue>
																			</>
																		) : null
																	}
																</GetFieldValue>
															</tfoot>
														</>
													)}
												</GetFieldValue>
											</Table>
										)}
									</FieldArray>
									{/* Keep it at the end. These is sync issue in StrictMode in react-final-form */}
									<SyncTotalMetricQuantitiesForCab
										cabFieldName={cabFieldName}
									/>
								</Col>
							</Grid>
						))}
					</Stack>
				)}
			</FieldArray>
		</Stack>
	)
}

function getCabsFromExistingServices(
	value: TTransportServicesInputFieldValue,
	currency: string,
	cabs_price_calculation_configurations: Required<
		TCabPriceCalculationFormData,
		"cabs_price_calculation_configurations"
	>["cabs_price_calculation_configurations"],
	existingData?: TCabsMetricsWiseCalculatedPrices
): TCabsMetricsWiseCalculatedPrices {
	return cabs_price_calculation_configurations
		.map(({ services, id, metric_preset }) => {
			const cabs = value
				// ensure transport_service
				.filter((s): s is Required<typeof s, "transport_service"> =>
					Boolean(s.transport_service && s.dates?.length)
				)
				// flatten with dates
				.flatMap(({ dates, ...s }) =>
					!dates ? [] : dates.map((date) => ({ ...s, date }))
				)
				// filter services for this group
				.filter((c) =>
					services.find((s) =>
						s.is_addon
							? false
							: s.date &&
								s.transport_service &&
								isSame(s.date, c.date, "day") &&
								s.transport_service.id === c.transport_service.id
					)
				)
				// flatten with cabs
				.flatMap(({ cabs, ...s }) =>
					!cabs
						? []
						: cabs.map((cab) => ({
								...s,
								cab_type: cab.cab_type,
								no_of_cabs: cab.no_of_cabs,
							}))
				)
				.filter((c): c is Required<typeof c, "cab_type"> =>
					Boolean(c.cab_type && c.no_of_cabs)
				)

			const existingDataForThisGroup = existingData
				? existingData.filter((d) => d.configuration_id === id)
				: []

			// group by cab type
			return collect(cabs)
				.groupBy((c) => c.cab_type?.name + "-" + c.no_of_cabs)
				.toArray()
				.map((s) => {
					const { cab_type, no_of_cabs } = s[0]
					const servicesForThisCab: TCabsMetricsWiseCalculatedPrices[number]["services"] =
						(
							[] as Required<
								TCabsMetricsWiseCalculatedPrices[number]
							>["services"]
						)
							.concat(
								s
									.filter(
										(
											d
										): d is Required<typeof d, "date" | "transport_service"> =>
											Boolean(d.date && d.transport_service)
									)
									.map(({ date, transport_service }) => ({
										date,
										transport_service,
										is_addon: 0,
									}))
							)
							.concat(
								services
									.filter((s) => s.is_addon)
									.map((s) => ({
										date: undefined,
										transport_service: s.transport_service,
										is_addon: 1,
									}))
							)
					const metric_quantities =
						calculateSystemMetricQuantitiesFromServices(servicesForThisCab)
					const cab: TCabsMetricsWiseCalculatedPrices[number] = {
						configuration_id: id,
						cab_type: cab_type as never,
						no_of_cabs,
						services: servicesForThisCab,
						metric_wise_prices: metric_preset?.metrics?.map((metric) => {
							const quantity = getSystemQuantityForMetric(
								metric,
								metric_quantities
							)
							return {
								metric,
								quantity,
								given_quantity: quantity,
								per_quantity_given_price: 0,
								currency,
								edited_given_price: false,
								edited_given_quantity: false,
							}
						}) || [
							{
								metric: undefined,
								quantity: undefined,
								given_quantity: 0,
								per_quantity_given_price: 0,
								currency,
								edited_given_price: false,
								edited_given_quantity: false,
							},
						],
						metric_quantities,
						currency,
						per_quantity_excluding_tax_given_price: 0,
						per_quantity_given_price: 0,
						given_price: 0,
					}

					const existingDataForThisCab = existingDataForThisGroup.find(
						(c) => c.cab_type.id === cab_type?.id
					)
					if (existingDataForThisCab) {
						// update the details with existing data
						cab.metric_wise_prices = (
							existingDataForThisCab.metric_wise_prices || []
						).concat([])
					}

					return cab
				})
		})
		.flatMap((cabs) => cabs)
}

type TCabPriceConfigurationFormData = {
	transport_services: Array<{
		date: Date
		transport_service: ITransportService
		group: string
		cabs: Array<{
			cab_type: ICabType
			no_of_cabs: number
		}>
	}>
	addon_transport_services?: Array<Array<ITransportService>>
	no_of_groups: number
	metric_presets: Array<TCabPriceCalculationMetricPreset | undefined>
}

function SyncCabPriceCalculatorWithLatestServicesButton({
	currency,
	services,
	usingSameCabTypes,
}: {
	currency: string
	services: TTransportServicesInputFieldValue
	usingSameCabTypes: boolean
}) {
	const [currentServicesHashWithoutUsingSameCabTypes, currentServicesHash] =
		useMemo(() => {
			return [
				services
					.map((v) =>
						[
							`DATES_${(v.dates || [])
								.map((d) => formatDate(d || new Date(), "YYYY-MM-DD"))
								.join("+")}`,
							`SERVICE_${v.transport_service?.id || -1}`,
							`CABS_${(v.cabs || []).map(
								(c) => `${c.cab_type?.id || -1}-${c.no_of_cabs || 0}`
							)}`,
						].join("<>")
					)
					.join("##"),
				services
					.map((v) =>
						[
							`DATES_${(v.dates || [])
								.map((d) => formatDate(d || new Date(), "YYYY-MM-DD"))
								.join("+")}`,
							`SERVICE_${v.transport_service?.id || -1}`,
							`CABS_${(v.cabs || []).map(
								(c) =>
									`${c.cab_type?.id || -1}-${
										usingSameCabTypes ? "0" : c.no_of_cabs || 0
									}`
							)}`,
						].join("<>")
					)
					.join("##"),
			]
		}, [services, usingSameCabTypes])
	const [previousValuesHash, setPreviousValuesHash] =
		useState(currentServicesHash)
	const prevServicesHashWithoutUsingSameCabTypesRef = useRef(
		currentServicesHashWithoutUsingSameCabTypes
	)
	const form = useForm<TCabPriceCalculationFormData>()
	const [isDialogOpen, openDialog, closeDialog] = useDialog()
	const { value: cabs_price_calculation_configurations } = useFieldValue<
		TCabPriceCalculationFormData["cabs_price_calculation_configurations"]
	>("cabs_price_calculation_configurations")
	const { value: cabs_price_calculations } =
		useFieldValue<TCabsMetricsWiseCalculatedPrices>("cabs_price_calculations")
	const serviceMetricGroupingConfigInitialValues =
		useMemo((): TCabPriceConfigurationFormData => {
			if (!isDialogOpen) {
				return {
					transport_services: [],
					no_of_groups: 1,
					addon_transport_services: [],
					metric_presets: [],
				}
			}
			const existingGroupingByService = collect(
				(cabs_price_calculation_configurations || [])
					.flatMap((item) =>
						item.services.map((s) => ({ group: item.id, ...s }))
					)
					.filter((s): s is Required<typeof s, "date"> =>
						Boolean(s.date && !s.is_addon)
					)
			).keyBy((s) => new Date(s.date).toISOString() + s.transport_service.id)
			const groupIndexById = (
				cabs_price_calculation_configurations || []
			).reduce<{
				[key: string]: number
			}>((indexById, { id }) => {
				if (indexById[id]) return indexById
				indexById[id] = Object.keys(indexById).length + 1
				return indexById
			}, {})
			const services_grouping = services
				.filter((s): s is Required<typeof s, "transport_service"> =>
					Boolean(s.transport_service)
				)
				.flatMap(({ dates, transport_service, cabs }) =>
					!dates?.length
						? []
						: dates.map((date) => {
								const existingGrouping =
									existingGroupingByService[
										new Date(date).toISOString() + transport_service.id
									]
								return {
									date,
									transport_service,
									group: String(
										existingGrouping
											? groupIndexById[existingGrouping.group]
											: "1"
									),
									cabs: !cabs
										? []
										: cabs.filter((c): c is Required<typeof c, "cab_type"> =>
												Boolean(c.cab_type && c.no_of_cabs)
											),
								}
							})
				)

			return {
				transport_services: services_grouping,
				no_of_groups:
					collect(services_grouping)
						.groupBy((g) => g.group)
						.toArray().length || 1,
				metric_presets: cabs_price_calculation_configurations
					? cabs_price_calculation_configurations.map((s) => s.metric_preset)
					: [],
				addon_transport_services: cabs_price_calculation_configurations
					? cabs_price_calculation_configurations.map((s) =>
							s.services
								.filter((s) => s.is_addon)
								.map((s) => s.transport_service)
						)
					: [],
			}
		}, [isDialogOpen, services, cabs_price_calculation_configurations])
	const hasTransportationDataChangedAfterLastSave =
		previousValuesHash !== currentServicesHash
	const havePricesBeenSet = cabs_price_calculations?.length
	useEffect(() => {
		if (hasTransportationDataChangedAfterLastSave && havePricesBeenSet) {
			form.change("cabs_price_calculations", undefined)
		}
	}, [hasTransportationDataChangedAfterLastSave, form, havePricesBeenSet])
	// update the prices if only the cab types has changed for all
	useEffect(() => {
		if (
			!hasTransportationDataChangedAfterLastSave &&
			currentServicesHashWithoutUsingSameCabTypes !==
				prevServicesHashWithoutUsingSameCabTypesRef.current &&
			cabs_price_calculations?.length &&
			cabs_price_calculation_configurations
		) {
			prevServicesHashWithoutUsingSameCabTypesRef.current =
				currentServicesHashWithoutUsingSameCabTypes
			form.change(
				"cabs_price_calculations",
				getCabsFromExistingServices(
					services,
					currency,
					cabs_price_calculation_configurations,
					cabs_price_calculations
				)
			)
		}
	}, [
		services,
		currentServicesHashWithoutUsingSameCabTypes,
		form,
		cabs_price_calculations,
		hasTransportationDataChangedAfterLastSave,
		cabs_price_calculation_configurations,
		currency,
	])
	return (
		<>
			<Inline gap="4">
				<Button
					level="primary"
					status={!havePricesBeenSet ? "danger" : "primary"}
					onClick={() => {
						if (cabs_price_calculation_configurations && havePricesBeenSet) {
							form.change(
								"cabs_price_calculations",
								getCabsFromExistingServices(
									services,
									currency,
									cabs_price_calculation_configurations,
									cabs_price_calculations
								)
							)
							setPreviousValuesHash(currentServicesHash)
						} else {
							openDialog()
						}
					}}
				>
					Add Cumulative Prices
				</Button>
				{havePricesBeenSet ? (
					<Button onClick={openDialog}>
						<Icons.Cog />
					</Button>
				) : null}
			</Inline>
			<Dialog
				open={isDialogOpen}
				onClose={closeDialog}
				title="Select Service and Metrics Configuration"
			>
				<Form<TCabPriceConfigurationFormData>
					initialValues={serviceMetricGroupingConfigInitialValues}
					onSubmit={withServerErrors(async (values) => {
						const grouping: TCabPriceCalculationFormData["cabs_price_calculation_configurations"] =
							[]
						for (let i = 0; i < values.no_of_groups; i++) {
							grouping.push({
								id: i + 1,
								metric_preset: values.metric_presets[i],
								services: ([] as TCabPriceCalculationConfiguration["services"])
									.concat(
										values.transport_services
											.filter((s) => String(s.group) === String(i + 1))
											.map((s) => ({ ...s, is_addon: 0 }))
									)
									.concat(
										values.addon_transport_services
											?.at(i)
											?.map((s) => ({ transport_service: s, is_addon: 1 })) ||
											[]
									),
							})
						}
						if (
							havePricesBeenSet &&
							!window.confirm(
								"This will replace any existing prices set for these services for this quote."
							)
						) {
							return
						}
						form.batch(() => {
							form.change("cabs_price_calculation_configurations", grouping)
							const cabs = getCabsFromExistingServices(
								services,
								currency,
								grouping
							)
							form.change("cabs_price_calculations", cabs)
						})
						setPreviousValuesHash(currentServicesHash)
						closeDialog()
					})}
					subscription={{ submitting: true }}
					mutators={{ ...arrayMutators }}
				>
					{({ handleSubmit, submitting, form }) => (
						<form onSubmit={handleSubmit}>
							<Dialog.Body>
								<Stack gap="6">
									<Stack gap="2">
										<Stack gap="1">
											<Heading fontSize="md">
												Transport Services Grouping
											</Heading>
											<Text color="muted">
												Using different groups, you can provide prices based on
												different metrics e.g. unexpected pickups/drops,
												mutli-zoned travel destinations.
											</Text>
										</Stack>
										<Table bordered hover>
											<thead>
												<tr>
													<TableHeaderDataCell>Service</TableHeaderDataCell>
													<TableHeaderDataCell>
														<Inline gap="2" alignItems="baseline">
															<Text>Groups</Text>
															<SelectInputField
																name="no_of_groups"
																size="sm"
																style={{ width: "70px" }}
																onChange={(e) => {
																	const value = Number(
																		e.currentTarget.value || 1
																	)
																	form.batch(() => {
																		form.change("no_of_groups", value)
																		// also change the group on individual service
																		form.change(
																			"transport_services",
																			form
																				.getFieldState("transport_services")
																				?.value?.map((v) => ({
																					...v,
																					group:
																						Number(v.group) > value
																							? String(value)
																							: v.group,
																				}))
																		)
																	})
																}}
															>
																<option value={1}>1</option>
																<option value={2}>2</option>
																<option value={3}>3</option>
																<option value={4}>4</option>
															</SelectInputField>
														</Inline>
													</TableHeaderDataCell>
												</tr>
											</thead>
											<FieldArray<
												TCabPriceConfigurationFormData["transport_services"][number]
											> name="transport_services">
												{({ fields }) => (
													<tbody>
														{fields.map((name) => (
															<tr key={name}>
																<TableDataCell>
																	<Stack gap="1">
																		<GetFieldValue<
																			TCabPriceConfigurationFormData["transport_services"][number]["transport_service"]
																		>
																			name={`${name}.transport_service`}
																		>
																			{({ value }) => (
																				<Text fontWeight="semibold">
																					{value.name}
																				</Text>
																			)}
																		</GetFieldValue>
																		<Inline gap="1" color="muted" fontSize="sm">
																			<GetFieldValue<
																				TCabPriceConfigurationFormData["transport_services"][number]["cabs"]
																			>
																				name={`${name}.cabs`}
																			>
																				{({ value: cabs }) => (
																					<Text>
																						{cabs
																							.map(
																								(c) =>
																									`${c.no_of_cabs}-${c.cab_type.name}`
																							)
																							.join(" + ")}
																					</Text>
																				)}
																			</GetFieldValue>
																			{"•"}
																			<GetFieldValue<
																				TCabPriceConfigurationFormData["transport_services"][number]["date"]
																			>
																				name={`${name}.date`}
																			>
																				{({ value: date }) => (
																					<Text>
																						{formatDate(date, "DD MMM, YYYY")}
																					</Text>
																				)}
																			</GetFieldValue>
																		</Inline>
																	</Stack>
																</TableDataCell>
																<TableDataCell>
																	<GetFieldValue<number> name="no_of_groups">
																		{({ value: no_of_groups }) => (
																			<Inline gap="6">
																				{Array.from(
																					Array(no_of_groups).keys()
																				).map((_, index) => (
																					<Box as="label" key={index}>
																						<RadioInputField
																							name={`${name}.group`}
																							value={String(index + 1)}
																						/>
																					</Box>
																				))}
																			</Inline>
																		)}
																	</GetFieldValue>
																</TableDataCell>
															</tr>
														))}
													</tbody>
												)}
											</FieldArray>
										</Table>
									</Stack>
									<GetFieldValue<number> name="no_of_groups">
										{({ value: no_of_groups }) => (
											<Stack gap="2">
												<Heading fontSize="md">
													Group-wise Configuration
												</Heading>
												<Text color="muted">
													Please provide Metric Preset (optional) and Garage
													Services (optional) for each group if applicable
												</Text>
												<Table
													headers={[
														"Group",
														"Metric Preset",
														"Garage Service(s)",
													]}
													alignCols={{ 0: "center" }}
													bordered
													hover
												>
													<tbody>
														{Array.from(Array(no_of_groups).keys()).map(
															(_, index) => (
																<tr key={index}>
																	<TableDataCell textAlign="center">
																		{index + 1}
																	</TableDataCell>
																	<TableDataCell>
																		<SelectField
																			select={
																				SelectCabPriceCalculationMetricPreset
																			}
																			name={`metric_presets[${index}]`}
																			creatable
																			fetchOnMount
																			size="sm"
																		/>
																	</TableDataCell>
																	<TableDataCell>
																		<SelectField
																			select={SelectTransportServices}
																			name={`addon_transport_services[${index}]`}
																			multiple
																			size="sm"
																		/>
																	</TableDataCell>
																</tr>
															)
														)}
													</tbody>
												</Table>
											</Stack>
										)}
									</GetFieldValue>
									<SubmissionError />
								</Stack>
							</Dialog.Body>
							<Dialog.Footer>
								<Button type="submit" disabled={submitting}>
									{submitting ? "Please wait..." : "Save"}
								</Button>
								<Button
									onClick={closeDialog}
									level="tertiary"
									disabled={submitting}
								>
									Cancel
								</Button>
							</Dialog.Footer>
						</form>
					)}
				</Form>
			</Dialog>
		</>
	)
}

function calculateSystemMetricQuantitiesFromServices(
	services: Array<{ date?: Date; transport_service: ITransportService }>
): TCabsMetricsWiseCalculatedPrices[number]["metric_quantities"] {
	const total_kms = services.reduce<number | undefined>(
		(kms, { transport_service }) =>
			transport_service.distance && kms !== undefined
				? kms + Number(transport_service.distance)
				: undefined,
		0
	)
	const total_days = services.reduce<Array<Date>>(
		(days, { date }) =>
			!date
				? days
				: days.concat(days.find((d) => isSame(d, date, "day")) ? [] : [date]),
		[]
	).length
	return { total_kms, total_days }
}

function SyncTotalMetricQuantitiesForCab({
	cabFieldName,
}: {
	cabFieldName: string
}) {
	const { value: cab_services } = useFieldValue<
		TCabsMetricsWiseCalculatedPrices[number]["services"]
	>(`${cabFieldName}.services`)
	const form = useForm()
	useEffect(() => {
		const { total_kms, total_days } =
			calculateSystemMetricQuantitiesFromServices(cab_services || [])
		form.batch(() => {
			form.change(`${cabFieldName}.metric_quantities.total_kms`, total_kms)
			form.change(`${cabFieldName}.metric_quantities.total_days`, total_days)
			form.change(
				`${cabFieldName}.metric_quantities.total_nights`,
				Math.max(total_days - 1, 0)
			)
		})
	}, [form, cabFieldName, cab_services])
	return null
}

function getSystemQuantityForMetric(
	metric: Pick<
		TCabPriceCalculationMetric,
		"system_quantity_metric" | "min_quantity_per_day"
	>,
	system_metric_quantities: TCabsMetricsWiseCalculatedPrices[number]["metric_quantities"]
): number | undefined {
	const { system_quantity_metric, min_quantity_per_day } = metric || {}
	const { total_kms, total_days } = system_metric_quantities || {}
	if (!system_quantity_metric) return
	let quantity: number | undefined = undefined
	switch (system_quantity_metric) {
		case "kms":
			quantity = total_kms
				? Math.max(
						Number(total_kms),
						Number(total_days || 0) * Number(min_quantity_per_day || 0)
					)
				: undefined
			break
		case "extra_kms":
			quantity = total_kms
				? Math.max(
						0,
						Number(total_kms) -
							Number(total_days || 0) * Number(min_quantity_per_day || 0)
					)
				: undefined
			break
		case "days":
			quantity = total_days
			break
		case "nights":
			quantity = Math.max(0, (total_days || 0) - 1)
			break
		case "total":
			quantity = 1
			break
	}
	return quantity
}

function SystemQuantityForMetric({
	name: priceFieldName,
	cabFieldName,
}: {
	name: string
	cabFieldName: string
}) {
	const { value: metric } = useFieldValue<
		TCabPriceCalculationMetric | undefined
	>(`${priceFieldName}.metric`)

	const { value: total_kms } = useFieldValue<
		TCabsMetricsWiseCalculatedPrices[number]["metric_quantities"]["total_kms"]
	>(`${cabFieldName}.metric_quantities.total_kms`)
	const { value: total_days } = useFieldValue<
		TCabsMetricsWiseCalculatedPrices[number]["metric_quantities"]["total_days"]
	>(`${cabFieldName}.metric_quantities.total_days`)

	const { system_quantity_metric, min_quantity_per_day } = metric || {}
	const form = useForm()

	const quantity = useMemo(() => {
		return getSystemQuantityForMetric(
			{ system_quantity_metric, min_quantity_per_day },
			{ total_kms, total_days }
		)
	}, [system_quantity_metric, min_quantity_per_day, total_kms, total_days])

	useEffect(() => {
		form.change(`${priceFieldName}.quantity`, quantity)
	}, [quantity, priceFieldName, form])

	return (
		<Text>
			{quantity !== undefined ? (
				quantity
			) : (
				<Text as="span" color="warning" fontSize="sm">
					N/A
				</Text>
			)}
		</Text>
	)
}

function GivenQuantityForMetric({ name }: { name: string }) {
	const form = useForm()
	const { value: edited_given_quantity } = useFieldValue<boolean | undefined>(
		`${name}.edited_given_quantity`
	)
	const { value: system_quantity } = useFieldValue<boolean | undefined>(
		`${name}.quantity`
	)

	const { value: metric } = useFieldValue<
		TCabPriceCalculationMetric | undefined
	>(`${name}.metric`)
	// sync the given quantity if given prices have not been manually updated
	useEffect(() => {
		if (!isTruthy(edited_given_quantity)) {
			form.change(`${name}.given_quantity`, system_quantity || 0)
		}
	}, [name, system_quantity, edited_given_quantity, form])

	// reset the given_quantity when metric changes
	const lastMetricRef = useRef(metric)
	useEffect(() => {
		if (lastMetricRef.current !== metric) {
			lastMetricRef.current = metric
			form.change(`${name}.edited_given_quantity`, false)
		}
	}, [metric, name, form])
	return (
		<TextInputField
			name={`${name}.given_quantity`}
			type="number"
			min={1}
			required
			placeholder="100"
			size="sm"
			style={{ maxWidth: "100px" }}
			onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
				const value = e.currentTarget.value
				form.batch(() => {
					form.change(`${name}.edited_given_quantity` as never, true as never)
					form.change(`${name}.given_quantity` as never, value as never)
				})
			}}
		/>
	)
}

function SystemRateForMetric({
	name: priceFieldName,
	cabFieldName,
	currency,
}: {
	name: string
	cabFieldName: string
	currency: string
}) {
	const { value: metric } = useFieldValue<
		TCabPriceCalculationMetric | undefined
	>(`${priceFieldName}.metric`)

	const { value: cab_type } = useFieldValue<
		TCabsMetricsWiseCalculatedPrices[number]["cab_type"]
	>(`${cabFieldName}.cab_type`)

	const { value: services } = useFieldValue<
		TCabsMetricsWiseCalculatedPrices[number]["services"]
	>(`${cabFieldName}.services`)

	const form = useForm()
	const xhr = useXHR()
	const abortController = useRef<AbortController>()
	const { set: setTimer, clear: clearTimer } = useTimeout()
	const dates = useMemo(
		() =>
			(services || [])
				.filter((s): s is Required<typeof s, "date"> => Boolean(s.date))
				.map((s) => s.date)
				.reduce<Array<Date>>(
					(uniqueDates, date) =>
						!date
							? uniqueDates
							: uniqueDates.concat(
									uniqueDates.find((d) => isSame(d, date, "day")) ? [] : [date]
								),
					[]
				),
		[services]
	)
	useEffect(() => {
		// abort any pending requests
		abortController.current = new AbortController()
		if (!metric || !cab_type || !dates.length) {
			abortController.current?.abort()
			clearTimer()
			return () => undefined
		}
		setTimer(() => {
			// fetch the prices and update
			Promise.resolve()
				.then(async () => {
					form.change(`${priceFieldName}.fetching_prices`, 1)
					try {
						const prices = await Promise.all(
							dates.map((date) =>
								xhr
									.get<{
										data: {
											price: number | null
											per_quantity_price: number | null
											currency: string | null
										}
									}>(`/cab-prices/calculate`, {
										params: {
											date: dateToUTCString(date),
											calculation_metric_id: metric.id,
											cab_type: cab_type.id,
											no_of_cabs: 1,
											currency,
										},
										signal: abortController.current?.signal,
									})
									.then((resp) => resp.data.data)
							)
						)
						abortController.current = undefined
						form.batch(() => {
							form.change(`${priceFieldName}.currency`, currency)
							const total = prices.reduce<null | number>(
								(total, { per_quantity_price }) =>
									total !== null && per_quantity_price !== null
										? total + Number(per_quantity_price)
										: null,
								0
							)
							// devide the total by dates to get average per quantity price
							form.change(
								`${priceFieldName}.per_quantity_price`,
								total
									? formatMoneyByDecimal(
											moneyParseByDecimal(total / prices.length, currency)
										)
									: undefined
							)
						})
					} catch (e) {
						if (isAbortError(e)) {
							// IGNORE
						}
						console.error(e)
					}
				})
				.then(() => {
					form.change(`${priceFieldName}.fetching_prices`, 0)
				})
				.catch((e) => {
					form.change(`${priceFieldName}.fetching_prices`, 0)
					throw e
				})
		}, 300)
		return () => {
			// Cancel any pending fetch
			abortController.current?.abort()
			clearTimer()
		}
	}, [
		metric,
		cab_type,
		form,
		dates,
		xhr,
		setTimer,
		clearTimer,
		priceFieldName,
		currency,
	])

	const { value: isFetching } = useFieldValue<undefined | 0 | 1>(
		`${priceFieldName}.fetching_prices`
	)
	const { value: perQuantityPrice } = useFieldValue<number | null>(
		`${priceFieldName}.per_quantity_price`
	)

	return isFetching ? (
		<Text>
			<Icons.Refresh spin opacity="50" />
		</Text>
	) : perQuantityPrice ? (
		<Money amount={perQuantityPrice} currency={currency} showCurrency />
	) : (
		<Text color="warning" title="Not Available" as="span">
			N/A
		</Text>
	)
}

function GivenPriceForMetric({ name }: { name: string }) {
	const { value: metric } = useFieldValue<
		TCabPriceCalculationMetric | undefined
	>(`${name}.metric`)
	const { value: edited_given_price } = useFieldValue<boolean | undefined>(
		`${name}.edited_given_price`
	)
	const { value: system_per_quantity_price } = useFieldValue<
		boolean | undefined
	>(`${name}.per_quantity_price`)
	const form = useForm()
	// sync the given prices if given prices have not been manually updated
	useEffect(() => {
		if (!isTruthy(edited_given_price)) {
			form.batch(() => {
				form.change(
					`${name}.per_quantity_given_price`,
					system_per_quantity_price || 0
				)
			})
		}
	}, [name, system_per_quantity_price, edited_given_price, form])

	// reset the given_prices when metric changes
	const lastMetricRef = useRef(metric)
	useEffect(() => {
		if (lastMetricRef.current !== metric) {
			lastMetricRef.current = metric
			form.batch(() => {
				form.change(`${name}.edited_given_price`, false)
			})
		}
	}, [metric, name, form])
	return (
		<TextInputField
			name={`${name}.per_quantity_given_price`}
			type="number"
			min={1}
			placeholder="100"
			size="sm"
			style={{ maxWidth: "100px" }}
			onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
				const value = e.currentTarget.value
				form.batch(() => {
					form.change(`${name}.edited_given_price` as never, true as never)
					form.change(
						`${name}.per_quantity_given_price` as never,
						value as never
					)
				})
			}}
		/>
	)
}

function TotalPriceForMetric({ name: priceFieldName }: { name: string }) {
	const { value: quantity } = useFieldValue<number>(
		`${priceFieldName}.given_quantity`
	)
	const { value: per_quantity_given_price } = useFieldValue<number>(
		`${priceFieldName}.per_quantity_given_price`
	)
	const { value: currency } = useFieldValue<string>(
		`${priceFieldName}.currency`
	)
	return (
		<Text>
			<Money
				currency={currency}
				amount={Number(per_quantity_given_price || 0) * Number(quantity || 0)}
				showCurrency
			/>
		</Text>
	)
}

function getPerQuantityExcludingGivenTaxGivenPrice(
	prices: TCabsMetricsWiseCalculatedPrices[number]["metric_wise_prices"]
) {
	return (prices || []).reduce(
		(total, { metric, given_quantity, per_quantity_given_price }) =>
			total +
			(metric
				? Number(given_quantity || 0) * Number(per_quantity_given_price || 0)
				: 0),
		0
	)
}

function TotalGivenPriceExclusingTaxForCabMetrics({ name }: { name: string }) {
	const { value: prices } = useFieldValue<
		TCabsMetricsWiseCalculatedPrices[number]["metric_wise_prices"]
	>(`${name}.metric_wise_prices`)
	const { value: currency } = useFieldValue<
		TCabsMetricsWiseCalculatedPrices[number]["currency"]
	>(`${name}.currency`)
	const per_quantity_excluding_tax_given_price = useMemo(() => {
		return getPerQuantityExcludingGivenTaxGivenPrice(prices || [])
	}, [prices])
	const form = useForm()

	useEffect(() => {
		const total = moneyParseByDecimal(
			per_quantity_excluding_tax_given_price,
			currency
		)
		form.batch(() => {
			form.change(`${name}.currency`, currency)
			form.change(
				`${name}.per_quantity_excluding_tax_given_price`,
				formatMoneyByDecimal(total)
			)
		})
	}, [per_quantity_excluding_tax_given_price, currency, name, form])
	return (
		<Text>
			<Money
				currency={currency}
				amount={per_quantity_excluding_tax_given_price}
				showCurrency
			/>
		</Text>
	)
}

function TotalGivenPriceForCabMetrics({
	name,
	currency,
	taxPercentage,
}: {
	name: string
	currency: string
	taxPercentage?: number
}) {
	const { value: no_of_cabs } = useFieldValue<
		TCabsMetricsWiseCalculatedPrices[number]["no_of_cabs"]
	>(`${name}.no_of_cabs`)
	const { value: per_quantity_excluding_tax_given_price } = useFieldValue<
		TCabsMetricsWiseCalculatedPrices[number]["per_quantity_excluding_tax_given_price"]
	>(`${name}.per_quantity_excluding_tax_given_price`)
	const per_quantity_given_price = useMemo(() => {
		const total = moneyParseByDecimal(
			per_quantity_excluding_tax_given_price || 0,
			currency
		)
		return addMoney(
			total,
			multipleMoneyBy(total, Number(taxPercentage || 0) / 100)
		)
	}, [per_quantity_excluding_tax_given_price, taxPercentage, currency])
	const form = useForm()
	useEffect(() => {
		form.batch(() => {
			form.change(`${name}.currency`, currency)
			form.change(
				`${name}.per_quantity_given_price`,
				formatMoneyByDecimal(per_quantity_given_price)
			)
			form.change(
				`${name}.given_price`,
				formatMoneyByDecimal(
					multipleMoneyBy(per_quantity_given_price, Number(no_of_cabs || 0))
				)
			)
		})
	}, [per_quantity_given_price, currency, name, form, no_of_cabs])
	return (
		<Text>
			<Money
				showCurrency
				money={multipleMoneyBy(
					per_quantity_given_price,
					Number(no_of_cabs || 0)
				)}
			/>
		</Text>
	)
}
