import {
	Alert,
	Badge,
	Box,
	Button,
	Icons,
	Text,
	Stack,
	Heading,
	Inline,
	Container,
	Dropdown,
	Col,
	Component,
	Grid,
	joinAttributes,
	Ping,
	RelativeTime,
	Time,
	TimeDuration,
	Money,
} from "@sembark-travel/ui/base"
import { Dialog } from "@sembark-travel/ui/dialog"
import { ButtonLink, Link } from "@sembark-travel/ui/router"
import {
	utcTimestampToLocalDate,
	utcTimestampToLocalDateString,
} from "@sembark-travel/datetime-utils"
import pluralize from "pluralize"
import { PERMISSIONS, useAuthManagedUsers, useCheckPermissions } from "../Auth"
import { CommentableComments } from "../Comments"
import { Email, PhoneNumber } from "../Contacts"
import { generatePath } from "../router-utils"
import { EditTagsInDialog } from "../Tags"
import { EditTripOwners } from "./../TripOwners"
import { Quote } from "./Quotes"
import { SelectQuoteCreationFlow } from "./SelectQuoteCreationFlow"
import { ITrip } from "./store"
import { UpdateArrivalDepartureDetails } from "./ArrivalDepartureDetails"
import { RevertTripCancellationInDialog } from "./CancelTrip"
import { AddTouristInDialog, UpdateTouristInDialog } from "./../Tourists"

export function BasicDetailsRoute({
	trip,
	isFetching,
	onChange,
}: {
	trip: ITrip
	isFetching: boolean
	onChange: () => void
}) {
	const { hasAnyPermission } = useCheckPermissions()
	return (
		<Box>
			<Grid>
				<Col paddingTop="4">
					{trip.latest_given_quote ? (
						<Stack borderWidth="1" rounded="md">
							<Box
								paddingX="4"
								paddingY="2"
								bgColor="subtle"
								roundedTop="md"
								borderBottomWidth="1"
							>
								<Heading fontSize="md">Latest Quote</Heading>
							</Box>
							<Box padding="4" bgColor="default">
								<Quote
									quoteId={trip.latest_given_quote.trip_quote_id}
									canBeConverted={!trip.converted_at}
									canSendToHolding={!trip.on_hold_at}
									readOnly={Boolean(trip.cancellation_reason)}
									onChange={onChange}
								/>
							</Box>
						</Stack>
					) : trip.total_quotes && trip.total_quotes > 0 ? (
						<Box marginTop="12">
							<Alert
								status="success"
								title="Given/Selling price not set"
								hideIcon
							>
								<Box marginBottom="4">
									{pluralize("Quote", trip.total_quotes, true)}{" "}
									{pluralize("has", trip.total_quotes)} been created for this
									trip but none has a selling price set.
								</Box>
								{!trip.cancellation_reason ? (
									<ButtonLink
										to={generatePath("/trips/:tripId/quotes", {
											tripId: String(trip.id),
										})}
										status="success"
									>
										View {pluralize("Quote", trip.total_quotes)} and Set Price{" "}
										<Icons.ChevronDown rotate="270" />
									</ButtonLink>
								) : null}
							</Alert>
						</Box>
					) : !isFetching &&
					  !trip.latest_given_quote &&
					  !trip.cancellation_reason &&
					  hasAnyPermission(
							PERMISSIONS.CREATE_QUOTE,
							PERMISSIONS.VIEW_TENANT_CONNECTIONS
					  ) ? (
						<Box>
							<SelectQuoteCreationFlow trip={trip} isFetching={false} />
						</Box>
					) : null}
				</Col>
				<Col sm={12} md={3} paddingTop="4">
					<Stack gap="4">
						{trip.converted_at ? (
							<>
								<ArrivalDepartureDetails
									tripId={trip.id}
									type="arrival"
									details={trip.arrival_details}
									onChange={onChange}
									defaultUTCDate={trip.start_date}
								/>
								<ArrivalDepartureDetails
									tripId={trip.id}
									type="departure"
									details={trip.departure_details}
									onChange={onChange}
									defaultUTCDate={trip.end_date}
								/>
							</>
						) : null}
						<Stack borderWidth="1" rounded="md">
							<Box
								paddingX="4"
								paddingY="2"
								bgColor="subtle"
								roundedTop="md"
								borderBottomWidth="1"
							>
								<Heading fontSize="md">Follow-ups</Heading>
							</Box>
							<Box padding="4" bgColor="default">
								<CommentableComments
									commentableType="trips"
									commentableId={trip.id}
									limit={20}
									viewAllLink={
										<Link
											to={generatePath("/trips/:tripId/comments", {
												tripId: String(trip.id),
											})}
										>
											View All Comments
										</Link>
									}
								/>
							</Box>
						</Stack>
					</Stack>
				</Col>
			</Grid>
		</Box>
	)
}

function ArrivalDepartureDetails({
	tripId,
	details,
	onChange,
	type,
	defaultUTCDate,
}: {
	tripId: number
	details?: ITrip["arrival_details"] | ITrip["departure_details"] | null
	onChange: () => void
	type: "arrival" | "departure"
	defaultUTCDate: string
}) {
	const label = type === "arrival" ? "Arrival" : "Departure"
	return (
		<Stack borderWidth="1" rounded="md" bgColor="default">
			<UpdateArrivalDepartureDetails
				details={details}
				type={type}
				tripId={tripId}
				onSuccess={() => onChange()}
				defaultUTCDate={defaultUTCDate}
			>
				{({ update }) => (
					<>
						<Box
							paddingX="3"
							paddingY="1"
							borderBottomWidth="1"
							bgColor="subtle"
							roundedTop="md"
						>
							<Inline justifyContent="between" gap="4" alignItems="center">
								<Box paddingY="1">
									<Text fontWeight="semibold">{label} Details</Text>
								</Box>
								<Button size="sm" level="tertiary" onClick={() => update()}>
									<Icons.Pencil title="Update Details" />
								</Button>
							</Inline>
						</Box>
						<Box paddingX="3" paddingY="2">
							{details?.length ? (
								<Stack gap="1">
									{details.map((d) => (
										<Box key={d.id}>
											<Time
												timestamp={d.date_time}
												dateFormat
												timeFormat="HH:mm [hrs]"
											/>
											<Text whiteSpace="preserveLine" fontSize="sm">
												{d.description}
											</Text>
										</Box>
									))}
								</Stack>
							) : (
								<Text color="muted">Not Set</Text>
							)}
						</Box>
					</>
				)}
			</UpdateArrivalDepartureDetails>
		</Stack>
	)
}

export function Header({
	trip,
	onChange,
}: {
	trip: ITrip
	onChange: () => void
}) {
	const { hasPermission } = useCheckPermissions()
	const managedUsers = useAuthManagedUsers()
	const {
		id,
		contact,
		trip_source_contact,
		tourist,
		destinations,
		days,
		nights,
		pax_string,
		trip_source,
		reference_id,
		tags,
		cancellation_reason,
		converted_at,
		on_hold_at,
		owner_quote_request,
		operated_by_connected_tenant,
		sales_team = [],
		operations_team = [],
		reservations_team = [],
		total_quotes,
		is_live,
		is_end_date_passed,
		can_update,
		can_cancel,
		can_drop,
		latest_stage,
	} = trip
	const start_date = utcTimestampToLocalDate(trip.start_date)
	return (
		<Box>
			{cancellation_reason ? (
				<Container paddingY="4">
					<Stack
						bgColor="warning"
						padding="4"
						rounded="md"
						borderWidth="1"
						borderColor="warning"
						gap="2"
					>
						<Inline gap="4" alignItems="center">
							<Box>
								<Icons.Ban size="8" color="warning" />
							</Box>
							<Stack gap="px">
								<Text
									textTransform="uppercase"
									fontWeight="semibold"
									color="muted"
								>
									{converted_at || on_hold_at ? "Dropped" : "Canceled"}
								</Text>
								<Heading as="h3" color="warning">
									{cancellation_reason.reason}
								</Heading>
							</Stack>
						</Inline>
						<Stack gap="2">
							{cancellation_reason.comments ? (
								<Inline gap="2">
									<Box paddingTop="px">
										<Icons.Annotation size="4" />
									</Box>
									<Text whiteSpace="preserveLine" fontWeight="semibold">
										{cancellation_reason.comments}
									</Text>
								</Inline>
							) : null}
							<Inline gap="2" alignItems="center" title="Canceled by">
								<Box>
									<Icons.User size="4" />
								</Box>
								<Inline gap="2" alignItems="center">
									<Text>{cancellation_reason.created_by.name}</Text>
									<Box>
										(
										<RelativeTime
											fontSize="sm"
											value={utcTimestampToLocalDate(
												cancellation_reason.created_at
											)}
										/>
										)
									</Box>
								</Inline>
							</Inline>
							{cancellation_reason.contact_remind_at ? (
								<Inline gap="2">
									<Text color="muted" fontWeight="semibold">
										<Icons.Calendar /> Contact Reminder:
									</Text>
									<RelativeTime
										value={utcTimestampToLocalDate(
											cancellation_reason.contact_remind_at
										)}
									/>
								</Inline>
							) : null}
						</Stack>
						{!converted_at && !on_hold_at ? (
							<Box marginTop="4">
								<RevertTripCancellationInDialog
									trip={trip}
									onSuccess={() => onChange()}
								>
									{({ onCancel }) => (
										<Button
											onClick={onCancel}
											status="warning"
											level="secondary"
											size="sm"
										>
											<Icons.Reply /> Revert Cancellation
										</Button>
									)}
								</RevertTripCancellationInDialog>
							</Box>
						) : null}
					</Stack>
				</Container>
			) : null}
			<Box bgColor="default" paddingY="4" position="relative">
				<Box
					position="absolute"
					insetY="0"
					left="0"
					width="2"
					bgColor={
						cancellation_reason
							? "warning_emphasis"
							: converted_at
								? "success_emphasis"
								: Number(total_quotes) > 0
									? "accent_emphasis"
									: Number(total_quotes) === 0
										? "emphasis"
										: undefined
					}
				></Box>
				<Container>
					<Box marginBottom="2">
						<Inline gap="2" flexWrap="wrap">
							{is_live && converted_at && !cancellation_reason ? (
								<Box alignSelf={"center"}>
									<Ping title="On Trip" />
								</Box>
							) : null}
							<Heading as="h2" fontSize="lg">
								<Text as="span" title="Trip ID">
									<Text as="span" opacity="50">
										#
									</Text>
									&nbsp;<Text as="span">{id}</Text>
								</Text>{" "}
								• {contact.name} • {destinations.map((t) => t.name).join(", ")}
							</Heading>
							<Inline fontWeight="semibold" alignItems="center" gap="4">
								<Inline
									gap="1"
									alignItems="center"
									flexWrap="wrap"
									fontSize="sm"
								>
									{joinAttributes(
										trip_source.name,
										reference_id ? `Ref: ${reference_id}` : null,
										owner_quote_request ? (
											<Box display="inlineBlock">
												<Box display="inline" color="success" marginRight="1">
													<Icons.LightningBoltSolid />
												</Box>
												{!converted_at ? "Requested by" : "Sold to"}{" "}
												{owner_quote_request.from.short_name}
											</Box>
										) : null,
										operated_by_connected_tenant ? (
											<Box display="inlineBlock">
												<Box display="inline" color="muted" marginRight="1">
													<Icons.LightningBolt />
												</Box>
												Bought from {operated_by_connected_tenant.short_name}
											</Box>
										) : null,
										<Inline gap="2">
											{cancellation_reason ? (
												<Badge
													danger={Boolean(converted_at)}
													warning={!converted_at}
													title={cancellation_reason.reason}
												>
													{converted_at ? "Dropped" : "Canceled"}
												</Badge>
											) : (
												<>
													{is_end_date_passed ? (
														<Badge
															warning
															title={utcTimestampToLocalDateString(
																trip.end_date
															)}
														>
															End Date Passed
														</Badge>
													) : null}
													{converted_at ? (
														<Badge
															success
															title={utcTimestampToLocalDateString(
																converted_at
															)}
														>
															Converted
														</Badge>
													) : total_quotes === 0 ? (
														<Badge>Initiated</Badge>
													) : (
														<Badge accent>{latest_stage.name}</Badge>
													)}
												</>
											)}
										</Inline>
									)}
								</Inline>
								{can_cancel || can_update || can_drop ? (
									<Dropdown alignRight>
										<Dropdown.ToggleButton size="sm">
											<Icons.DotsVertical />
										</Dropdown.ToggleButton>
										<Dropdown.Menu>
											{can_update ? (
												<Dropdown.MenuItem
													as={Link}
													to={generatePath("/trips/:tripId/edit", {
														tripId: trip.id.toString(),
													})}
												>
													<Icons.Pencil /> Edit Details
												</Dropdown.MenuItem>
											) : null}
											{can_cancel ? (
												<Dropdown.MenuItem
													as={Link}
													to={generatePath("/trips/:tripId/cancel", {
														tripId: trip.id.toString(),
													})}
													anchored
												>
													<Icons.Ban /> Cancel Trip
												</Dropdown.MenuItem>
											) : null}
											{can_drop ? (
												<Dropdown.MenuItem
													as={Link}
													to={generatePath("/trips/:tripId/drop", {
														tripId: trip.id.toString(),
													})}
													anchored
												>
													<Icons.Ban /> Drop Trip
												</Dropdown.MenuItem>
											) : null}
										</Dropdown.Menu>
									</Dropdown>
								) : null}
							</Inline>
						</Inline>
					</Box>
					<Grid gap="4">
						<Col sm={12} md>
							<Stack gap="2">
								<Box whiteSpace="preserve">
									<Inline
										alignItems={{ md: "center" }}
										collapseBelow="sm"
										gap="2"
									>
										<Inline gap="2" alignItems="center">
											<Icons.Calendar color="muted" />
											<Box>
												{joinAttributes(
													<Time value={start_date} />,
													<TimeDuration days={days}>
														{nights}N, {days}D
													</TimeDuration>
												)}
											</Box>
										</Inline>
										<Inline gap="2" alignItems="center">
											<Icons.UserGroup color="muted" />
											<Box>{pax_string}</Box>
										</Inline>
									</Inline>
								</Box>
								<Stack gap="2">
									<Inline alignItems="center" gap="2">
										<Box color="muted" title="Guest Details">
											<Icons.User />
										</Box>
										{tourist ? (
											<Inline collapseBelow="sm" gap="2">
												<Text
													title={tourist.name}
													style={{ maxWidth: "150px" }}
													textOverflow="truncate"
												>
													{tourist.name}
												</Text>
												{tourist.phone_numbers?.length ? (
													<PhoneNumber
														value={tourist.phone_numbers}
														numberOnly
													/>
												) : null}
												{tourist.email ? (
													<Email value={tourist.email} iconOnly />
												) : null}
											</Inline>
										) : (
											<Text color="muted">Guest: N/A</Text>
										)}
										{!cancellation_reason && tourist ? (
											<UpdateTouristInDialog
												tourist={tourist}
												onSuccess={() => {
													onChange()
												}}
											>
												{({ update }) => (
													<Button
														onClick={update}
														title="Edit Guest Details"
														inline
														size="sm"
													>
														<Icons.Pencil />
													</Button>
												)}
											</UpdateTouristInDialog>
										) : null}
									</Inline>
									{trip_source_contact ? (
										<Inline
											alignItems="center"
											gap="2"
											title="Source Contact Details"
										>
											<Box color="muted">
												<Icons.ArrowLeft rotate="180" />
											</Box>
											<Inline gap="2" alignItems="center">
												<Box>
													<Text>{trip_source_contact.name}</Text>
												</Box>
												<Inline gap="2" alignItems="center">
													{trip_source_contact.phone_numbers?.length ? (
														<PhoneNumber
															value={trip_source_contact.phone_numbers}
															iconOnly
														/>
													) : null}
													{trip_source_contact.email ? (
														<Email value={trip_source_contact.email} iconOnly />
													) : null}
												</Inline>
											</Inline>
										</Inline>
									) : null}
									{!tourist &&
									!cancellation_reason &&
									(converted_at || on_hold_at) ? (
										<Inline gap="2" alignItems="center" color="warning">
											<Box>
												<Icons.Attention />
											</Box>
											<Text>Missing guest details.</Text>
											<Box>
												<AddTouristInDialog
													tripId={trip.id.toString()}
													onSuccess={() => {
														onChange()
													}}
												>
													{({ add }) => (
														<Button
															onClick={add}
															title="Add Guest Details"
															inline
															size="sm"
														>
															<Icons.Pencil />
														</Button>
													)}
												</AddTouristInDialog>
											</Box>
										</Inline>
									) : null}
								</Stack>
							</Stack>
						</Col>
						<Col>
							<Grid gap="4">
								{trip.converted_at && trip.cancellation_reason ? (
									<Col>
										<Box>
											<Inline fontSize="sm" gap="1">
												<Box display="inlineBlock">
													Chargable (
													{trip.latest_given_quote?.given_currency || "INR"})
												</Box>
											</Inline>
											<Box>
												<Money
													fontSize="lg"
													fontWeight="semibold"
													amount={trip.cancellation_charges || 0}
													currency={
														trip.latest_given_quote?.given_currency || "INR"
													}
												/>
											</Box>
										</Box>
									</Col>
								) : null}
								{trip.latest_given_quote ? (
									<Col>
										<Box>
											<Inline fontSize="sm">
												Package ({trip.latest_given_quote.given_currency})
											</Inline>
											<Box>
												<Money
													fontSize="lg"
													fontWeight="semibold"
													amount={trip.latest_given_quote.given_price}
													currency={trip.latest_given_quote.given_currency}
												/>
												<Text fontSize="xs">
													{trip.latest_given_quote.gst_included
														? trip.latest_given_quote.tax_percentage
															? `${trip.latest_given_quote.tax_percentage}%`
															: "inc."
														: "exc."}{" "}
													{trip.latest_given_quote.tax_label}
												</Text>
											</Box>
										</Box>
									</Col>
								) : null}
								<Col>
									<Box>
										<Box>
											<Box fontSize="sm" color="muted">
												<EditTagsInDialog
													type="trip"
													tags={tags}
													itemId={trip.id}
													onSuccess={() => {
														onChange()
													}}
												>
													{({ onEdit }) => (
														<Button
															onClick={onEdit}
															title="Edit tags"
															inline
															size="sm"
														>
															<Box display="inlineBlock" marginRight="2">
																Tags
															</Box>
															<Icons.Pencil opacity="50" />
														</Button>
													)}
												</EditTagsInDialog>
											</Box>
											<Box>
												{tags && tags.length ? (
													<Inline gap="1">
														{tags.map((t) => (
															<Badge
																key={t.id}
																title={
																	t.pivot?.created_at
																		? utcTimestampToLocalDateString(
																				t.pivot.created_at
																			)
																		: undefined
																}
															>
																{t.name}
															</Badge>
														))}
													</Inline>
												) : (
													<Box
														color="muted"
														fontSize="sm"
														display="inline"
														marginTop="1"
														title={`Tag trips to quickly indentify and group trips`}
													>
														No Tags
													</Box>
												)}
											</Box>
										</Box>
									</Box>
								</Col>
								<Col>
									<Box>
										<Inline color="muted" gap="2" alignItems="center">
											<Box fontSize="sm" fontWeight="semibold">
												Sales Team
											</Box>
											{(hasPermission(PERMISSIONS.MANAGE_TRIP_OWNERS) ||
												managedUsers.length) &&
											!cancellation_reason ? (
												<Component initialState={false}>
													{({ state: isEditing, setState: setIsEditing }) => (
														<>
															{!isEditing ? (
																<Button
																	onClick={() => {
																		setIsEditing(true)
																	}}
																	title="Edit Sales Team"
																	inline
																>
																	<Icons.Pencil opacity="50" />
																</Button>
															) : null}
															<Dialog
																title="Edit Sales Team"
																open={isEditing}
																onClose={() => setIsEditing(false)}
																sm
															>
																<Dialog.Body>
																	<EditTripOwners
																		type="sales_team"
																		users={sales_team}
																		itemId={trip.id}
																		onSuccess={() => {
																			setIsEditing(false)
																			onChange()
																		}}
																		onCancel={() => {
																			setIsEditing(false)
																		}}
																	/>
																</Dialog.Body>
															</Dialog>
														</>
													)}
												</Component>
											) : null}
										</Inline>
										<Box>
											{sales_team.length ? (
												sales_team.map((user) => user.name).join(", ")
											) : (
												<Box fontSize="sm" color="muted" marginTop="1">
													Not Set
												</Box>
											)}
										</Box>
									</Box>
								</Col>
								{converted_at || on_hold_at ? (
									<Col>
										<Box>
											<Inline color="muted" gap="2" alignItems="center">
												<Box fontSize="sm" fontWeight="semibold">
													Resv. Team
												</Box>
												{(hasPermission(PERMISSIONS.MANAGE_TRIP_OWNERS) ||
													managedUsers.length) &&
												!cancellation_reason ? (
													<Component initialState={false}>
														{({ state: isEditing, setState: setIsEditing }) => (
															<>
																{!isEditing ? (
																	<Button
																		inline
																		onClick={() => {
																			setIsEditing(true)
																		}}
																		title="Edit Reservations Teams"
																	>
																		<Icons.Pencil opacity="50" />
																	</Button>
																) : null}
																<Dialog
																	title="Edit Reservations Team"
																	open={isEditing}
																	onClose={() => setIsEditing(false)}
																	sm
																>
																	<Dialog.Body>
																		<EditTripOwners
																			type="reservations_team"
																			users={reservations_team}
																			itemId={trip.id}
																			onSuccess={() => {
																				setIsEditing(false)
																				onChange()
																			}}
																			onCancel={() => {
																				setIsEditing(false)
																			}}
																		/>
																	</Dialog.Body>
																</Dialog>
															</>
														)}
													</Component>
												) : null}
											</Inline>
											<Box>
												{reservations_team.length ? (
													reservations_team.map((user) => user.name).join(", ")
												) : (
													<Box fontSize="sm" color="muted" marginTop="1">
														Not Set
													</Box>
												)}
											</Box>
										</Box>
									</Col>
								) : null}
								{converted_at || on_hold_at ? (
									<Col>
										<Box>
											<Inline color="muted" gap="2" alignItems="center">
												<Box fontSize="sm" fontWeight="semibold">
													Ops Team
												</Box>
												{(hasPermission(PERMISSIONS.MANAGE_TRIP_OWNERS) ||
													managedUsers.length) &&
												!cancellation_reason ? (
													<Component initialState={false}>
														{({ state: isEditing, setState: setIsEditing }) => (
															<>
																{!isEditing ? (
																	<Button
																		inline
																		onClick={() => {
																			setIsEditing(true)
																		}}
																		title="Edit Operation Teams"
																	>
																		<Icons.Pencil opacity="50" />
																	</Button>
																) : null}
																<Dialog
																	title="Edit Operations Team"
																	open={isEditing}
																	onClose={() => setIsEditing(false)}
																	sm
																>
																	<Dialog.Body>
																		<EditTripOwners
																			type="operations_team"
																			users={operations_team}
																			itemId={trip.id}
																			onSuccess={() => {
																				setIsEditing(false)
																				onChange()
																			}}
																			onCancel={() => {
																				setIsEditing(false)
																			}}
																		/>
																	</Dialog.Body>
																</Dialog>
															</>
														)}
													</Component>
												) : null}
											</Inline>
											<Box>
												{operations_team.length ? (
													operations_team.map((user) => user.name).join(", ")
												) : (
													<Box fontSize="sm" color="muted" marginTop="1">
														Not Set
													</Box>
												)}
											</Box>
										</Box>
									</Col>
								) : null}
							</Grid>
						</Col>
					</Grid>
				</Container>
			</Box>
		</Box>
	)
}
