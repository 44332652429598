import { Box } from "./Box"
import { LightBulbIcon } from "./Icons"
import React from "react"

export function Tip({ children, ...props }: React.ComponentProps<typeof Box>) {
	if (!children) return null
	return (
		<Box textAlign="center" marginY="8" {...props}>
			<LightBulbIcon color="muted" /> <strong>ProTip!</strong> {children}
		</Box>
	)
}
