import { Box, Spinner } from "@sembark-travel/ui/base"
import { Breadcrumbs, searchToQuery } from "@sembark-travel/ui/router"
import { Helmet } from "react-helmet-async"
import { ConvertTrip, useFetchQuote } from "../../../Trips"
import {
	Navigate,
	useParams,
	useLocation,
	generatePath,
} from "../../../router-utils"

export default function ConvertTripRoute() {
	const { tripId } = useParams("/trips/:tripId/convert")
	const location = useLocation()
	const { using: quoteId } = searchToQuery<{ using: string }>(location?.search)
	const { data: quote } = useFetchQuote(quoteId)
	if (!quoteId) {
		return <Navigate to="/trips/:tripId/quotes" params={{ tripId }} replace />
	}
	return (
		<>
			<Helmet>
				<title>Convert-Hold Trip</title>
			</Helmet>
			<Breadcrumbs
				title="Conversion"
				items={[
					[generatePath("/trips"), "Trips"],
					[generatePath("/trips/:tripId", { tripId }), tripId],
					[generatePath("/trips/:tripId/quotes", { tripId }), "Quotes"],
					[
						generatePath("/trips/:tripId/quotes/:quoteId", { tripId, quoteId }),
						quoteId,
					],
				]}
			/>
			<Box paddingY="8">
				{!quote ? (
					<Box>
						<Spinner padding="4" alignCenter />
					</Box>
				) : (
					<ConvertTrip tripId={tripId} quote={quote} />
				)}
			</Box>
		</>
	)
}
