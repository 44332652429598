import {
	Table,
	Stack,
	Button,
	Component,
	Inline,
	Icons,
	Text,
	Box,
	Heading,
} from "@sembark-travel/ui/base"
import { CursorListView, Search, useSearch } from "@sembark-travel/ui/list"
import { useLocationQuery } from "@sembark-travel/ui/router"
import { useXHR } from "@sembark-travel/xhr"
import { useEffect, useState } from "react"
import { Dialog } from "@sembark-travel/ui/dialog"
import {
	Form,
	GetFieldValue,
	SelectField,
	SubmissionError,
	TextInputField,
	withServerErrors,
} from "@sembark-travel/ui/form"
import { Optional } from "utility-types"
import {
	LatLongInputFields,
	MapLink,
	SynonumsNamesInputField,
} from "./FormInputFields"
import {
	worldXHR,
	TWorldAirport,
	TWorldCountry,
	TWorldState,
	TWorldCity,
	TWorldTimezone,
} from "../../World"
import {
	SelectWorldCities,
	SelectWorldCountries,
	SelectWorldStates,
	SelectWorldTimezones,
} from "../../World/List"

export function AirportsList({ country }: { country?: TWorldCountry }) {
	const [query, setQuery] = useLocationQuery()
	const [searchParams, setSearchParams] = useSearch(query)
	useEffect(() => {
		setQuery(searchParams)
	}, [searchParams, setQuery])
	return (
		<Search
			title="Airports"
			initialParams={searchParams}
			onSearch={(params) => setSearchParams({ ...params, cursor: null })}
			actions={() => (
				<Component initialState={false}>
					{({ state, setState }) => (
						<>
							<Button onClick={() => setState(true)}>New Airport</Button>
							<Dialog
								open={state}
								onClose={() => setState(false)}
								title="Add New Airport"
							>
								<Dialog.Body>
									<AddNewAirport
										country={country}
										onCancel={() => setState(false)}
										onSuccess={() => setState(false)}
									/>
								</Dialog.Body>
							</Dialog>
						</>
					)}
				</Component>
			)}
		>
			{() => (
				<CursorListView<TWorldAirport>
					pageKey={`admin/world/airports-cursor`}
					params={searchParams}
					onCursorChange={(cursor) =>
						setSearchParams({ ...searchParams, cursor })
					}
					fetch={(xhr, params) =>
						worldXHR(xhr).getAirportsWithPagination({
							country_id: country?.id,
							...params,
							include: "city,state,country,timezone",
						})
					}
				>
					{({ items, refresh }) => (
						<Table
							headers={[
								"ICAO",
								"IATA",
								"Name",
								"City",
								"State",
								"Country",
								"Timezone",
								"Map",
								"",
							]}
							responsive
							hover
							bordered
							alignCols={{ 8: "right" }}
							rows={items.map((item) => [
								item.icao,
								item.iata,
								<Stack>
									<Text>{item.name}</Text>
									{[item.synonyms_1, item.synonyms_2, item.synonyms_3]
										.filter(Boolean)
										.map((a) => (
											<Text key={a} fontSize="sm" color="muted">
												{a}
											</Text>
										))}
								</Stack>,
								item.city?.name,
								item.state?.name,
								item.country?.name,
								item.timezone?.name,
								<MapLink lat={item.latitude} long={item.longitude} />,
								<Component initialState={false}>
									{({ state, setState }) => (
										<>
											<Button
												onClick={() => setState(true)}
												size="sm"
												level="tertiary"
											>
												<Icons.Pencil />
											</Button>
											<Dialog
												open={state}
												onClose={() => setState(false)}
												title={`Edit Airport "${item.name}"`}
											>
												<Dialog.Body>
													<EditAirport
														airport={item}
														onCancel={() => setState(false)}
														onSuccess={() => {
															refresh()
															setState(false)
														}}
													/>
												</Dialog.Body>
											</Dialog>
										</>
									)}
								</Component>,
							])}
						/>
					)}
				</CursorListView>
			)}
		</Search>
	)
}

function AddNewAirport({
	country,
	...props
}: Omit<NewAirportFormProps, "onSubmit"> & { country?: TWorldCountry }) {
	const xhr = useXHR()
	return (
		<NewAirportForm
			initialValues={{ country }}
			onSubmit={(payload) => worldXHR(xhr).storeAirport(payload)}
			{...props}
		/>
	)
}

function EditAirport({
	airport,
	...props
}: Omit<NewAirportFormProps, "onSubmit" | "stateId"> & {
	airport: TWorldAirport
}) {
	const xhr = useXHR()
	return (
		<NewAirportForm
			initialValues={{
				icao: airport.icao,
				iata: airport.iata,
				name: airport.name,
				synonyms_1: airport.synonyms_1,
				synonyms_2: airport.synonyms_2,
				synonyms_3: airport.synonyms_3,
				latitude: airport.latitude,
				longitude: airport.longitude,
				elevation: airport.elevation,
				country: airport.country,
				state: airport.state,
				city: airport.city,
				timezone: airport.timezone,
			}}
			onSubmit={(payload) => worldXHR(xhr).updateAirport(airport.id, payload)}
			{...props}
		/>
	)
}
type NewAirportFormData = {
	icao?: string
	iata?: string
	name?: string
	synonyms_1?: string
	synonyms_2?: string
	synonyms_3?: string
	latitude?: string
	longitude?: string
	elevation?: string
	country?: TWorldCountry
	state?: TWorldState
	city?: TWorldCity
	timezone?: TWorldTimezone
}

type NewAirportFormProps = {
	onSubmit: (payload: object) => Promise<unknown>
	onSuccess?: () => void
	onCancel: () => void
	initialValues?: Optional<NewAirportFormData>
}

function NewAirportForm({
	onSubmit,
	onCancel,
	onSuccess,
	initialValues: propInitialValues,
}: NewAirportFormProps) {
	const [initialValues] = useState<NewAirportFormData>(() => ({
		name: "",
		iata: "",
		icao: "",
		synonyms_1: "",
		synonyms_2: "",
		synonyms_3: "",
		latitude: "",
		longitude: "",
		elevation: "",
		...propInitialValues,
	}))
	return (
		<Form<NewAirportFormData>
			initialValues={initialValues}
			onSubmit={withServerErrors(
				async ({ country, state, city, timezone, ...values }) => {
					await onSubmit({
						...values,
						country: country?.id,
						state: state?.id,
						city: city?.id,
						timezone: timezone?.id,
					})
					onSuccess?.()
				}
			)}
			subscription={{ submitting: true }}
		>
			{({ submitting, handleSubmit }) => (
				<form onSubmit={handleSubmit} noValidate>
					<Stack gap="4">
						<Inline gap="4" flexWrap="wrap">
							<TextInputField
								name="icao"
								type="text"
								label="ICAO"
								required
								placeholder="Four-letter Code"
								style={{ maxWidth: "100px" }}
							/>
							<TextInputField
								name="iata"
								type="text"
								label="IATA"
								placeholder="Three-letter GeoCode"
								style={{ maxWidth: "100px" }}
								secondaryLabel="optional"
							/>
							<Box flex="1">
								<TextInputField
									name="name"
									type="text"
									label="Name"
									required
									placeholder="e.g. Jaipur Internation Airport"
								/>
							</Box>
						</Inline>
						<SynonumsNamesInputField />
						<Stack gap="1">
							<Heading>Location</Heading>
							<Text color="muted">Provide ariport's city/state/countries</Text>
						</Stack>
						<Inline gap="4" flexWrap="wrap">
							<SelectField
								select={SelectWorldCountries}
								name="country"
								label="Country"
							/>
							<GetFieldValue<TWorldCountry | undefined> name="country">
								{({ value }) =>
									!value ? null : (
										<SelectField
											select={SelectWorldStates}
											countryId={value.id}
											name="state"
											label="State"
											secondaryLabel="optional"
										/>
									)
								}
							</GetFieldValue>
							<GetFieldValue<TWorldState | undefined> name="state">
								{({ value }) =>
									!value ? null : (
										<SelectField
											select={SelectWorldCities}
											stateId={value.id}
											name="city"
											label="City"
											secondaryLabel="optional"
										/>
									)
								}
							</GetFieldValue>
							<GetFieldValue<TWorldCountry | undefined> name="country">
								{({ value }) =>
									!value ? null : (
										<SelectField
											select={SelectWorldTimezones}
											countryId={value.id}
											name="timezone"
											label="Timezone"
											fetchOnMount
											secondaryLabel="optional"
										/>
									)
								}
							</GetFieldValue>
						</Inline>
						<Inline flexWrap="wrap" gap="4">
							<LatLongInputFields />
							<TextInputField
								name="elevation"
								label="Elevation"
								secondaryLabel="optional"
								type="text"
								style={{ maxWidth: "100px" }}
							/>
						</Inline>
						<SubmissionError />
						<Inline gap="4">
							<Button type="submit" disabled={submitting}>
								{submitting ? "Please wait.." : "Save Details"}
							</Button>
							<Button disabled={submitting} onClick={() => onCancel()}>
								Cancel
							</Button>
						</Inline>
					</Stack>
				</form>
			)}
		</Form>
	)
}
