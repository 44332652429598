import { Helmet } from "react-helmet-async"
import { Currencies } from "../../../../TenantProfile/Currencies"
import { FeatureFlag } from "../../../../Auth"

export default function OrganizationCurrenciesSettingsPage() {
	return (
		<>
			<Helmet>
				<title>Currencies & Exchange Rates</title>
			</Helmet>
			<FeatureFlag flag="multi_currency" show404>
				<Currencies />
			</FeatureFlag>
		</>
	)
}
