import { Box } from "@sembark-travel/ui/base"
import {
	queryToSearch,
	searchToQuery,
	useNavigateToBack,
} from "@sembark-travel/ui/router"
import { useMemo } from "react"
import { Helmet } from "react-helmet-async"
import { EditSchedulePage } from "../../../../CabScheduling"
import { useParams, generatePath, useLocation } from "../../../../router-utils"
import { dateToQuery, parseDateFromQuery } from "@sembark-travel/datetime-utils"
import { ForbidUnlessAuthorized, PERMISSIONS } from "../../../../Auth"

export default function EditCabSchedulePage() {
	const location = useLocation()
	const { scheduleId } = useParams("/cab-schedules/:scheduleId")
	const { date } = searchToQuery<{
		date: string
		readOnlyTrip: boolean
	}>(location?.search)
	const selectedDate = useMemo(() => {
		return (date ? parseDateFromQuery(date) : new Date()) || new Date()
	}, [date])
	const goToBack = useNavigateToBack(
		generatePath("/cab-schedules") +
			queryToSearch({ date: dateToQuery(selectedDate) })
	)
	return (
		<>
			<Helmet>
				<title>Edit Cab Schedule</title>
			</Helmet>
			<ForbidUnlessAuthorized permission={PERMISSIONS.MODIFY_CAB_SCHEDULES}>
				<Box marginBottom="16">
					<Box paddingBottom="4" marginBottom="8" borderBottomWidth="4">
						<Box as="h2" margin="0">
							Edit Cab Schedule
						</Box>
					</Box>
					<EditSchedulePage
						scheduleId={scheduleId}
						onSuccess={goToBack}
						onCancel={goToBack}
						readOnlyTrip
					/>
				</Box>
			</ForbidUnlessAuthorized>
		</>
	)
}
