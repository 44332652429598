import { Helmet } from "react-helmet-async"
import {
	ITransportServiceProvider,
	TransportServiceProviderBasicDetails,
} from "./../../../../TransportServiceProviders"
import { useOutletContext } from "react-router-dom"

export default function TransportServiceProvider() {
	const { transportServiceProvider, revalidate } = useOutletContext<{
		transportServiceProvider: ITransportServiceProvider
		revalidate: () => void
	}>()
	return (
		<>
			<Helmet>
				<title>TSP Details</title>
			</Helmet>
			<TransportServiceProviderBasicDetails
				transportServiceProvider={transportServiceProvider}
				onChange={revalidate}
			/>
		</>
	)
}
