import {
	Box,
	Button,
	Icons,
	Inline,
	Heading,
	Stack,
	Text,
	RadioInput,
	Col,
	Divider,
	Grid,
} from "@sembark-travel/ui/base"
import { Dialog } from "@sembark-travel/ui/dialog"
import { useXHR, XHRInstance } from "@sembark-travel/xhr"
import {
	FieldArray,
	Form,
	withServerErrors,
	SubmissionError,
	TextInputField,
	validateFormValues,
	GetFieldValue,
	useFieldValue,
	SelectField,
	arrayMutators,
} from "@sembark-travel/ui/form"
import React, { Fragment, useCallback, useMemo, useState } from "react"
import { $PropertyType, Optional, Required } from "utility-types"
import * as Validator from "yup"
import { SelectTags } from "../Tags"
import { ITag } from "../Tags"
import { SelectUsers } from "../Users"
import { IUser } from "../Users"
import { SelectAccountType } from "./AccountTypes"
import { IAccount, IAccountType, TEntityTypes } from "./store"

async function createAccount(
	xhr: XHRInstance,
	data: unknown
): Promise<IAccount> {
	return xhr.post("/accounting/accounts", data).then((resp) => resp.data.data)
}

export function NewAccount({
	onCancel,
	onSuccess,
	entityType,
}: {
	entityType?: TEntityTypes
	onSuccess: ({ entity_type }: { entity_type: string }) => void
	onCancel?: () => void
}) {
	const xhr = useXHR()
	return (
		<NewAccountForm
			entityType={entityType}
			onSubmit={async (data, entityType) => {
				await createAccount(xhr, data)
				onSuccess({ entity_type: entityType })
			}}
			onCancel={onCancel}
		/>
	)
}

type NewAccountFormProps = {
	onSubmit: (
		data: {
			data: Array<{
				name: string
				description?: string
				is_public: 0 | 1
				user_id?: number
				entity_type: TEntityTypes
				tags?: Array<string>
				types?: Array<string>
			}>
		},
		entityType: TEntityTypes
	) => Promise<unknown>
	onCancel?: () => void
	entityType?: TEntityTypes
	disableBulk?: boolean
	initialValues?: Optional<
		$PropertyType<Required<INewAccountFormValues>, "accounts">[number]
	>
}

export function NewAccountForm({ entityType, ...props }: NewAccountFormProps) {
	const [entity_type, setEntityType] = useState(entityType)
	const [showDetailsForm, setShowDetailsForm] = useState<boolean>(false)
	const updateEntityType = useCallback(
		({ entityType }: { entityType: TEntityTypes }) => {
			setEntityType(entityType)
			setShowDetailsForm(true)
		},
		[]
	)
	return (
		<Box>
			{!entity_type || !showDetailsForm ? (
				<SelectAccountEntityType
					entityType={entity_type}
					onChange={updateEntityType}
					onCancel={props.onCancel}
				/>
			) : (
				<>
					<Heading as="h2" color="accent">
						Add{" "}
						{entity_type === "tenants"
							? "Company"
							: entity_type === "third_party"
								? "Third Party"
								: "Users"}{" "}
						Account
					</Heading>
					<Divider />
					<Box>
						<AccountDetailsForm {...props} entityType={entity_type} />
					</Box>
				</>
			)}
		</Box>
	)
}

function SelectAccountEntityType({
	onChange,
	onCancel,
	entityType: propsEntityType,
}: {
	entityType?: TEntityTypes
	onChange: (data: { entityType: TEntityTypes }) => void
	onCancel?: () => void
}) {
	const [entityType, setEntityType] = useState<TEntityTypes>(
		propsEntityType || "tenants"
	)
	const goToNextStep = useCallback(() => {
		onChange({
			entityType,
		})
	}, [entityType, onChange])
	return (
		<Box>
			<Stack gap="4">
				<Heading fontSize="lg" as="h3">
					Account Association
				</Heading>
				<Text fontSize="sm">
					Please select the entity type to attach the account.
				</Text>
				<Grid>
					<Col sm={12} md>
						<Option
							name="entity_type"
							title="Company Account"
							value="tenants"
							selectedValue={entityType}
							description="Create company account to manage the company wide transactions or restricted transactions."
							onChange={setEntityType}
						/>
					</Col>
					<Col sm={12} md>
						<Option
							name="entity_type"
							title="Employee / User Account"
							value="users"
							selectedValue={entityType}
							description="Create employee/user account to hold the user specific accounting e.g. salary accounts, team lead accounts etc"
							onChange={setEntityType}
						/>
					</Col>
					<Col sm={12} md>
						<Option
							name="entity_type"
							title="Third Party Account"
							value="third_party"
							selectedValue={entityType}
							description="Create accounts for third parties e.g. Travel Expense, ISP etc. to manage expenses and other transactions"
							onChange={setEntityType}
						/>
					</Col>
				</Grid>
			</Stack>
			<Divider />
			<Inline gap="4" flexDirection="rowReverse">
				<Button level="primary" onClick={goToNextStep} data-testid="next-btn">
					Next: Add Account Details <Icons.ChevronDown rotate="270" />
				</Button>
				{onCancel ? <Button onClick={onCancel}>Cancel</Button> : null}
			</Inline>
		</Box>
	)
}

type INewAccountFormValues = {
	accounts?: Array<{
		name: string
		description?: string
		who_can_log: "all" | "owners"
		user?: IUser
		entity_type: TEntityTypes
		tags?: Array<ITag>
		types?: Array<IAccountType>
	}>
}

const accountFormValidationSchema = Validator.object().shape({
	accounts: Validator.array()
		.min(1, "Please provide atleast one account details to create")
		.of(
			Validator.object().shape({
				name: Validator.string().required(
					"Please provide a name for the account e.g. Office Expense"
				),
				entity_type: Validator.string().required(
					"Please select an entity type to associate the account"
				),
				user: Validator.object().when(
					"entity_type",
					(entityType: TEntityTypes, userSchema: Validator.AnyObjectSchema) =>
						entityType === "users"
							? userSchema.required(
									"Please select a team member to associate the account"
								)
							: userSchema.nullable()
				),
				description: Validator.string(),
				who_can_log: Validator.string().required(
					"Please select one of logging permissions"
				),
			})
		),
})

function AccountDetailsForm({
	onSubmit,
	onCancel,
	entityType,
	disableBulk,
	initialValues: initialValuesProp,
}: Omit<NewAccountFormProps, "entityType"> & { entityType: TEntityTypes }) {
	const initialValues: Required<INewAccountFormValues, "accounts"> =
		useMemo(() => {
			return {
				accounts: [
					{
						name: "",
						description: "",
						who_can_log: entityType === "users" ? "owners" : "all",
						user: undefined,
						entity_type: entityType,
						tags: [],
						types: [],
						...(initialValuesProp || {}),
					},
				],
			}
		}, [entityType, initialValuesProp])
	return (
		<Form<INewAccountFormValues>
			initialValues={initialValues}
			validate={validateFormValues(accountFormValidationSchema)}
			onSubmit={withServerErrors(async (data) =>
				onSubmit(
					{
						data:
							data.accounts?.map(
								({ who_can_log, user, tags, types, ...values }) => ({
									...values,
									user_id: user ? user.id : undefined,
									tags: tags ? tags.map((t) => t.name) : [],
									types: types ? types.map((t) => t.name) : [],
									is_public: who_can_log === "all" ? 1 : 0,
								})
							) || [],
					},
					entityType
				)
			)}
			subscription={{ submitting: true }}
			mutators={{ ...arrayMutators }}
		>
			{({ submitting, handleSubmit }) => {
				return (
					<form noValidate onSubmit={handleSubmit}>
						<FieldArray<
							Required<INewAccountFormValues>["accounts"][number]
						> name="accounts">
							{({ fields }) => {
								const isBulk = Number(fields.length || 0) > 1
								return (
									<>
										{fields.map((name, index) => {
											return (
												<Fragment key={name}>
													<Grid gap="4">
														<Col xs={12} sm={isBulk ? true : 12}>
															<Grid gap="4">
																{!isBulk ? (
																	<Col xs={12} sm={4}>
																		<Stack gap="1">
																			<Heading as="h4">
																				Basic Information
																			</Heading>
																			<Text>
																				Please provide basic identity
																				information for the account like name
																				and description.
																			</Text>
																		</Stack>
																	</Col>
																) : null}
																<Col>
																	<Stack gap="4">
																		{entityType === "users" ? (
																			<SelectField
																				label="Select User"
																				select={SelectUsers}
																				fetchOnMount
																				multiple={false}
																				name={`${name}.user`}
																			/>
																		) : null}
																		<TextInputField
																			name={`${name}.name`}
																			label="Name"
																			type="text"
																			required
																			placeholder="Credit Account X-8944"
																		/>
																		<TextInputField
																			name={`${name}.description`}
																			label="Description"
																			secondaryLabel="optional"
																			type="text"
																			placeholder="This account should only hold credit transactions"
																		/>
																		<Grid gap="4">
																			<Col xs={12} sm>
																				<SelectField
																					select={SelectAccountType}
																					label="Account Type"
																					name={`${name}.types`}
																					creatable
																					fetchOnMount
																					multiple
																				/>
																			</Col>
																			<Col xs={12} sm>
																				<SelectField
																					select={SelectTags}
																					label="Assign Tags"
																					type="accounts"
																					name={`${name}.tags`}
																					creatable
																					fetchOnMount
																					secondaryLabel="optional"
																					multiple
																				/>
																			</Col>
																		</Grid>
																	</Stack>
																</Col>
															</Grid>
														</Col>
														{!isBulk ? (
															<Col xs={12}>
																<Divider />
															</Col>
														) : null}
														<Col xs={12} sm={isBulk ? true : 12}>
															<Grid gap="4">
																{!isBulk ? (
																	<Col xs={12} sm={4}>
																		<Stack gap="1">
																			<Heading as="h4">Settings</Heading>
																			<Text>
																				Please set visibility permissions for
																				this account.
																			</Text>
																		</Stack>
																	</Col>
																) : null}
																<Col>
																	<Stack gap="4">
																		<Stack gap="2">
																			<Heading fontSize="md">
																				Who can log transactions (credit/debit)
																				for this account?
																			</Heading>
																			{!isBulk ? (
																				<Text>
																					Please select, who can view the
																					account in select options for
																					debit/credit accounts when logging a
																					transaction.
																				</Text>
																			) : null}
																		</Stack>
																		<Inline gap="6" collapseBelow="md">
																			<WhoCanLogOption
																				value="all"
																				name={`${name}.who_can_log`}
																				title="Everyone in the company"
																				description="Preferred for accounts that have a high frequency of transactions e.g. company or team lead accounts"
																			/>
																			<WhoCanLogOption
																				value="owners"
																				name={`${name}.who_can_log`}
																				title={
																					entityType === "users"
																						? "User only"
																						: "Accounting Team only"
																				}
																				description="Preferred for accounts that have a low frequency of transactions e.g. individual accounts"
																			/>
																		</Inline>
																		{!isBulk ? (
																			<Inline gap="2">
																				<Box>
																					<Icons.Info />
																				</Box>
																				<Text fontSize="sm">
																					Please note that it will{" "}
																					<b>
																						<em>not</em>
																					</b>{" "}
																					change the account details visibility
																					(e.g. transactions and balance
																					visibility). Only the account owner(s)
																					will have access to view/edit the full
																					account details.
																				</Text>
																			</Inline>
																		) : null}
																		{isBulk ? (
																			<Inline gap="2">
																				<GetFieldValue<
																					Required<INewAccountFormValues>["accounts"][number]
																				>
																					name={`${name}`}
																				>
																					{({ value: account }) => (
																						<Button
																							onClick={() =>
																								fields.push(account)
																							}
																							size="sm"
																						>
																							<Icons.Duplicate /> Duplicate
																						</Button>
																					)}
																				</GetFieldValue>
																				<Button
																					onClick={() => fields.remove(index)}
																					size="sm"
																					level="tertiary"
																				>
																					<Icons.Cancel /> Remove
																				</Button>
																			</Inline>
																		) : null}
																	</Stack>
																</Col>
															</Grid>
														</Col>
													</Grid>
													<Divider />
												</Fragment>
											)
										})}
										<Grid>
											<Col xs={12} sm={isBulk ? 12 : { offset: 4, span: 8 }}>
												<SubmissionError />
												<Inline gap="4">
													<Button type="submit" disabled={submitting}>
														Save Account
														{Number(fields.length || 0) > 1 ? "s" : ""}
													</Button>
													{!disableBulk && !submitting ? (
														<Button
															disabled={submitting}
															status="primary"
															onClick={() =>
																fields.push(initialValues.accounts[0])
															}
														>
															<Icons.Plus /> Add More
														</Button>
													) : null}
													{onCancel ? (
														<Button onClick={onCancel} level="tertiary">
															Cancel
														</Button>
													) : null}
												</Inline>
											</Col>
										</Grid>
									</>
								)
							}}
						</FieldArray>
					</form>
				)
			}}
		</Form>
	)
}

function WhoCanLogOption({
	value,
	title,
	name,
	description,
}: {
	name: string
	value: string
	title: React.ReactNode
	description: React.ReactNode
}) {
	const { value: selectedValue, onChange: setValue } =
		useFieldValue<string>(name)
	return (
		<Option
			name={name}
			value={value}
			selectedValue={selectedValue}
			title={title}
			description={description}
			onChange={setValue}
		/>
	)
}

function Option<T>({
	value,
	selectedValue,
	title,
	description,
	onChange,
	name,
}: {
	name: string
	value: T
	selectedValue: T
	title: React.ReactNode
	description: React.ReactNode
	onChange: (value: T) => void
}) {
	const isSelected = selectedValue === value
	const id = `${name}_${value}`
	return (
		<Inline
			as="label"
			bgColor="subtle"
			borderWidth="1"
			gap="4"
			rounded="md"
			textAlign="left"
			width="full"
			padding="4"
			borderColor={isSelected ? "primary" : "default"}
			marginBottom="4"
			htmlFor={id}
			cursor="pointer"
			boxShadow="inner"
		>
			<Box>
				<RadioInput
					name={name}
					id={id}
					checked={isSelected}
					onChange={() => onChange(value)}
				/>
			</Box>
			<Stack gap="1">
				<Text fontWeight="semibold">{title}</Text>
				<Text color="muted" fontSize="sm">
					{description}
				</Text>
			</Stack>
		</Inline>
	)
}

export function AddAccountDialog({
	open,
	onClose,
}: {
	open: boolean
	onClose: () => void
	// initialValues?: Optional<INewAccountFormValues>
}) {
	return (
		<Dialog open={open} onClose={onClose} lg title="Add new Account">
			<Dialog.Body>
				<NewAccount onSuccess={onClose} onCancel={onClose} />
			</Dialog.Body>
		</Dialog>
	)
}
