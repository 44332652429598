import { Icons, Table } from "@sembark-travel/ui/base"
import { ListView, Search, useSearch } from "@sembark-travel/ui/list"
import { useLocationQuery, Link } from "@sembark-travel/ui/router"
import { useEffect } from "react"
import { generatePath } from "../../router-utils"
import { TWorldCountry, worldXHR } from "../../World"
import { MapLink } from "./FormInputFields"

export function CountriesList() {
	const [query, setQuery] = useLocationQuery()
	const [searchParams, setSearchParams] = useSearch(query)
	useEffect(() => {
		setQuery(searchParams)
	}, [searchParams, setQuery])
	return (
		<Search title="Countries" onSearch={(params) => setSearchParams(params)}>
			{() => (
				<ListView<TWorldCountry>
					pageKey="admin/world/countries"
					params={searchParams}
					onPageChange={(page) => setSearchParams({ ...searchParams, page })}
					fetch={(xhr, params) =>
						worldXHR(xhr).getCountries({
							...params,
							include: "states_count",
						})
					}
				>
					{({ items }) => (
						<Table
							headers={[
								"ISO2",
								"Name",
								"ISO3",
								"Region",
								"Phone",
								"States",
								"Map",
							]}
							responsive
							hover
							bordered
							rows={items.map((item) => [
								item.iso2,
								<Link
									to={generatePath("/admin/world/countries/:countryId", {
										countryId: item.id.toString(),
									})}
									color="accent"
									fontWeight="semibold"
								>
									{item.emoji} {item.name}
								</Link>,
								item.iso3,
								[item.subregion, item.region].filter(Boolean).join(", "),
								item.phone_code,
								item.states_count,
								<MapLink lat={item.latitude} long={item.longitude}>
									<Icons.LocationMarker />
								</MapLink>,
							])}
						/>
					)}
				</ListView>
			)}
		</Search>
	)
}
