import { ForbidUnlessAuthorized, PERMISSIONS } from "../../../Auth"
import { Outlet } from "react-router-dom"

export default function Layout() {
	return (
		<ForbidUnlessAuthorized
			permission={PERMISSIONS.VIEW_TRANSPORT_SERVICE_PROVIDERS}
		>
			<Outlet />
		</ForbidUnlessAuthorized>
	)
}
