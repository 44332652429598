import { TChildrenArray } from "./store"
import {
	SelectInput,
	InputGroup,
	Button,
	Icons,
	Inline,
	Stack,
} from "@sembark-travel/ui/base"
import { useField, ErrorMessage } from "@sembark-travel/ui/form"
import { useMemo } from "react"

export function ChildrenInputField({
	name = "children",
	disabled,
	maxAge = 12,
}: {
	name?: string
	disabled?: boolean
	maxAge?: number
}) {
	const field = useField<TChildrenArray>(name, {
		subscription: { value: true },
	})
	const { value: children, onChange } = field.input
	const allowedChildrenAges = useMemo(
		() => Array.from(Array(maxAge)).map((_, index) => index + 1),
		[maxAge]
	)
	return (
		<InputGroup>
			<Stack gap="1">
				<label
					htmlFor={`${name}.${children.length - 1}.age`}
					onClick={() => {
						if (children.length === 0 && !disabled) {
							onChange([{ count: 1, age: "" as unknown as number }])
						}
					}}
				>
					Add Children and their Ages
				</label>
				<Inline flexWrap="wrap">
					{(children || []).map((child, index, arr) => (
						<SelectInput
							key={index}
							name={`${name}.${index}.age`}
							value={child.age}
							data-testid={`${name}.${index}.age`}
							type="number"
							min={1}
							max={20}
							placeholder="6"
							width="auto"
							disabled={disabled}
							onChange={(e) => {
								const { value: ageValue } = e.currentTarget
								const newChildren = [...children]
								newChildren[index].age = Number(ageValue)
								if (
									arr.every((child, i) => i === index || Boolean(child.age)) &&
									ageValue
								) {
									// TS throws error
									// we need to set an empty age here
									// find a default value
									newChildren.push({ count: 1, age: "" as never })
								}
								onChange(newChildren)
							}}
						>
							<option value=""></option>
							{allowedChildrenAges.map((age) => (
								<option value={age} key={age}>
									{age}y
								</option>
							))}
							)
						</SelectInput>
					))}
					<Button
						onClick={() => {
							!disabled &&
								onChange(children.concat([{ count: 1, age: "" as never }]))
						}}
						disabled={disabled}
						data-testid={`${name}.add`}
						title="Click to add Chilren"
					>
						<Icons.PlusCircle />
					</Button>
					{!disabled && children.length >= 1 ? (
						<Button
							data-testid={`${name}.remove`}
							onClick={() => onChange(children.concat([]).slice(0, -1))}
							title="Inputs without age will be auto-discarded."
						>
							<Icons.MinusCircle />
						</Button>
					) : null}
				</Inline>
				<ErrorMessage name={name} />
			</Stack>
		</InputGroup>
	)
}
