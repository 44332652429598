import { useTenant } from "../../../../../TenantProfile/store"
import { useAuthUser, useHasFeatureFlag } from "../../../../../Auth"
import { Outlet } from "react-router-dom"
import { useNavigate } from "../../../../../router-utils"
import {
	Alert,
	Box,
	Spinner,
	Container,
	Heading,
	Tabs,
	TabsList,
	TabItem,
	Component,
	Icons,
} from "@sembark-travel/ui/base"
import { NavLink } from "@sembark-travel/ui/router"
import { Dialog } from "@sembark-travel/ui/dialog"
import { AddTenantBrand } from "../../../../../Brands"
import { Helmet } from "react-helmet-async"

export default function Layout() {
	const { user } = useAuthUser()
	const { tenant, isFetching, error, refresh, isValidating } = useTenant(
		user?.tenant?.id
	)
	const hasMultiBranding = useHasFeatureFlag("multi_branding")
	const navigate = useNavigate()
	if (!user || !user.tenant) {
		return null
	}
	if (isFetching) {
		return <Spinner padding="4" alignCenter />
	}
	if (!tenant) {
		if (error) {
			return <Alert status="error">{error.message}</Alert>
		}
		return null
	}
	return (
		<Box>
			<Helmet>
				<title>Organization Profile</title>
			</Helmet>
			{!hasMultiBranding ? (
				<Box borderBottomWidth="1" bgColor="default">
					<Container paddingY="4">
						<Heading as="h3">Organization Profile</Heading>
					</Container>
				</Box>
			) : (
				<Tabs layout="col">
					<TabsList>
						{tenant.brands?.map((brand) => (
							<NavLink to={`${brand.id}`} key={brand.id}>
								{brand.short_name}
							</NavLink>
						))}
						<Component initialState={false}>
							{({ state, setState }) => (
								<>
									{Number(tenant.brands?.length) >= 5 ? null : (
										<TabItem onClick={() => setState(true)}>
											<Icons.Plus /> Add Brand
										</TabItem>
									)}
									<Dialog
										open={state}
										onClose={() => setState(false)}
										title="Add New Brand"
									>
										<Dialog.Body>
											<AddTenantBrand
												onCancel={() => setState(false)}
												onSuccess={async (brand) => {
													setState(false)
													await refresh()
													navigate("/org/profile/:id", {
														params: { id: brand.id.toString() },
													})
												}}
											/>
										</Dialog.Body>
									</Dialog>
								</>
							)}
						</Component>
					</TabsList>
				</Tabs>
			)}
			<Container paddingY="6" cursor={isValidating ? "wait" : undefined}>
				<Outlet />
			</Container>
		</Box>
	)
}
