import {
	Button,
	Inline,
	Col,
	Divider,
	Stack,
	Heading,
	Text,
	Grid,
} from "@sembark-travel/ui/base"
import { useXHR } from "@sembark-travel/xhr"
import {
	Form,
	SubmissionError,
	TextInputField,
	validateFormValues,
	withServerErrors,
} from "@sembark-travel/ui/form"
import * as Validator from "yup"
import { useRef } from "react"

export function InviteTenantToConnect({
	onSuccess,
	onCancel,
	signupUrl,
}: {
	onSuccess: () => void
	onCancel?: () => void
	signupUrl: string
}) {
	const xhr = useXHR()
	const initialValues = useRef({
		tenant_name: "",
		tenant_addressing_name: "",
		tenant_addressing_email: "",
		signup_url: signupUrl,
	}).current
	return (
		<Form<typeof initialValues>
			initialValues={initialValues}
			validate={validateFormValues(
				Validator.object()
					.shape({
						tenant_name: Validator.string().required(
							"Please provide a name of the business"
						),
						tenant_addressing_name: Validator.string().required(
							"Please provide a contact person's name"
						),
						tenant_addressing_email: Validator.string().required(
							"Please provide a contact person's email"
						),
					})
					.required("Please provide required fields")
			)}
			onSubmit={withServerErrors(async (values) => {
				await xhr.post("/invite-connection-tenants", values)
				onSuccess()
			})}
			subscription={{ submitting: true }}
		>
			{({ submitting, handleSubmit }) => (
				<form noValidate onSubmit={handleSubmit}>
					<Grid gap="4">
						<Col xs={12} sm={4}>
							<Stack gap="1">
								<Heading as="h3">Contact Details</Heading>
								<Text color="muted">
									Please provider business name and your contact person's
									details to emailing purpose.
								</Text>
							</Stack>
						</Col>
						<Col>
							<Stack gap="4">
								<TextInputField
									name="tenant_name"
									label="Business Name"
									required
									type="text"
									placeholder="ABC Holidays"
								/>
								<Grid gap="4">
									<Col>
										<TextInputField
											name="tenant_addressing_name"
											label="Contact Person Name"
											required
											type="text"
											placeholder="Sudhir M"
										/>
									</Col>
									<Col>
										<TextInputField
											name="tenant_addressing_email"
											label="Contact Person Email"
											required
											type="email"
											placeholder="contact@domain.com"
										/>
									</Col>
								</Grid>
							</Stack>
						</Col>
					</Grid>
					<input
						name="signup_url"
						value={initialValues.signup_url}
						readOnly
						hidden
					/>
					<Divider sm />
					<Grid>
						<Col xs={12} sm={{ offset: 4, span: 8 }}>
							<Stack gap="4">
								<SubmissionError />
								<Inline gap="4">
									<Button type="submit" disabled={submitting}>
										{submitting ? "Sending..." : "Send Invitation"}
									</Button>
									<Button
										onClick={() => onCancel?.()}
										disabled={submitting}
										level="tertiary"
									>
										Cancel
									</Button>
								</Inline>
							</Stack>
						</Col>
					</Grid>
				</form>
			)}
		</Form>
	)
}
