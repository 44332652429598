import { Breadcrumbs } from "@sembark-travel/ui/router"
import { Helmet } from "react-helmet-async"
import { UploadTravelActivityPrices } from "./../../../TravelActivityPrices"
import { generatePath, useNavigate } from "../../../router-utils"
import { ForbidUnlessAuthorized, PERMISSIONS } from "../../../Auth"

export default function UploadTravelActivityPricesPage() {
	const navigate = useNavigate()
	function navigateBackToList() {
		return navigate("/travel-activity-prices", { replace: true })
	}
	return (
		<>
			<Helmet>
				<title>Upload Travel Activity Prices</title>
			</Helmet>
			<ForbidUnlessAuthorized
				permission={PERMISSIONS.UPLOAD_BULK_TRANSPORT_SERVICES}
			>
				<Breadcrumbs
					title="Upload Activity Prices"
					items={[
						[generatePath("/travel-activity-prices"), "Activity Prices"],
						["", "Upload CSV"],
					]}
				/>
				<UploadTravelActivityPrices
					onSuccess={navigateBackToList}
					onCancel={navigateBackToList}
				/>
			</ForbidUnlessAuthorized>
		</>
	)
}
