import { Alert, Box } from "@sembark-travel/ui/base"
import { Divider, Spinner } from "@sembark-travel/ui/base"
import { useXHR } from "@sembark-travel/xhr"
import { NewAccountForm } from "./NewItem"
import { IAccount, AccountsXHR, useEditAccount } from "./store"

export function EditAccount({
	onCancel,
	accountId,
	onSuccess,
}: {
	accountId: number | string
	onCancel?: () => void
	onSuccess: (account: IAccount) => void
}) {
	const { account, error } = useEditAccount(accountId)
	const xhr = useXHR()
	if (!account && error) {
		return <Alert status="error">{error.message}</Alert>
	}
	if (!account) {
		return <Spinner alignCenter padding="4" />
	}
	const { id, users, entity_type } = account
	return (
		<Box>
			<Box as="h2">
				Edit Account:{" "}
				<Box as="span" color="muted">
					{account.name}
				</Box>
			</Box>
			<Divider />
			<NewAccountForm
				onSubmit={async (accounts) => {
					const data = accounts.data[0]
					const account = await AccountsXHR(xhr).update(id, data)
					onSuccess(account)
				}}
				initialValues={{
					name: account.name,
					description: account.description || "",
					who_can_log: account.is_public ? "all" : "owners",
					tags: account.tags || [],
					types: account.types || [],
					user: users?.length ? users[0] : undefined,
				}}
				onCancel={onCancel}
				entityType={entity_type}
				disableBulk
			/>
		</Box>
	)
}
