import { Stack, Button, Inline } from "@sembark-travel/ui/base"
import { useXHR, XHRInstance } from "@sembark-travel/xhr"
import {
	Form,
	TextInputField,
	withServerErrors,
	SubmissionError,
	validateFormValues,
} from "@sembark-travel/ui/form"
import * as Validator from "yup"
import { useAuthUser } from "./../Auth"

// schemas
export interface IChangePasswordCredentials {
	current: string
	password: string
	password_confirmation: string
}

const changePasswordSchema = validateFormValues(
	Validator.object().shape({
		current: Validator.string().required("Current password field is required"),
		password: Validator.string().required("Password field is required"),
		password_confirmation: Validator.string().required(
			"Password confirmation field is required"
		),
	})
)

// actions
function XHR(xhr: XHRInstance) {
	return {
		async changePassword(data: IChangePasswordCredentials): Promise<void> {
			return xhr.patch("/passwords", data)
		},
	}
}

const changePasswordInitialValues: IChangePasswordCredentials = {
	current: "",
	password: "",
	password_confirmation: "",
}

export function ChangePasswordForm({ onSuccess }: { onSuccess: () => void }) {
	const xhr = useXHR()
	const { user } = useAuthUser()
	return (
		<Form<IChangePasswordCredentials>
			initialValues={changePasswordInitialValues}
			validate={changePasswordSchema}
			onSubmit={withServerErrors(async (values: IChangePasswordCredentials) => {
				await XHR(xhr).changePassword(values)
				alert("Password updated successfully")
				onSuccess()
			})}
			subscription={{ submitting: true }}
		>
			{({ submitting, handleSubmit }) => (
				<form noValidate onSubmit={handleSubmit}>
					<Stack gap="4">
						{user ? (
							<input
								type="email"
								name="email"
								value={user.email}
								hidden
								readOnly
								autoComplete="username"
							/>
						) : null}
						<TextInputField
							label="Current Password"
							name="current"
							type="password"
							required
							id="current"
							autoComplete="current-password"
						/>
						<TextInputField
							name="password"
							label="New Password"
							type="password"
							required
							id="password"
							autoComplete="new-password"
						/>
						<TextInputField
							label="Confirm new password"
							name="password_confirmation"
							type="password"
							required
							id="password_confirmation"
							autoComplete="new-password"
						/>
						<SubmissionError />
						<Inline gap="4">
							<Button type="submit" disabled={submitting}>
								{submitting ? "Updating..." : "Update Password"}
							</Button>
						</Inline>
					</Stack>
				</form>
			)}
		</Form>
	)
}
