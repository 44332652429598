import {
	Box,
	Button,
	Inline,
	Table,
	Stack,
	Text,
	Container,
	Heading,
	Alert,
	Time,
	Icons,
} from "@sembark-travel/ui/base"
import { useXHR } from "@sembark-travel/xhr"
import {
	Form,
	FileInputField,
	withServerErrors,
	SubmissionError,
	validateFormValues,
	SelectField,
	GetFieldValue,
} from "@sembark-travel/ui/form"
import * as Validator from "yup"
import config from "../config"
import { SelectTripDestination, TTripDestination } from "../TripDestinations"
import { useHasFeatureFlag } from "../Auth"
import { useRef } from "react"
import {
	SelectTenantCurrencyInputField,
	useFunctionalCurrencyOfTenant,
} from "../Currencies"
import { parseDate } from "@sembark-travel/datetime-utils"
import {
	CopyToClipboard,
	CopyToClipboardButton,
} from "@sembark-travel/ui/copy-to-clipboard"

interface IFormData {
	timezone_offset: number
	files: FileList | null
	trip_destinations?: Array<TTripDestination>
	currency: string
}

const THIS_YEAR = new Date().getFullYear()

export function UploadPricesForm({
	onSuccess,
	onCancel,
}: {
	onSuccess: () => void
	onCancel?: () => void
}) {
	const xhr = useXHR()
	const can_attach_services_to_destinations = useHasFeatureFlag(
		"attach_services_to_destinations"
	)
	const validationSchema = validateFormValues(
		Validator.object().shape({
			timezone_offset: Validator.string().required(),
			files: Validator.mixed().required("Please selected some csv files"),
			trip_destinations: can_attach_services_to_destinations
				? Validator.array()
						.required("Please select a Destinations")
						.min(1, "Please select atleast one Destinations")
				: Validator.array().nullable(),
			currency: Validator.string().required(
				"Please select a currency for price input."
			),
		})
	)
	const functionalCurrency = useFunctionalCurrencyOfTenant()
	const initialValues: IFormData = useRef({
		timezone_offset: config.timezoneOffset,
		files: null,
		trip_destinations: [],
		currency: functionalCurrency,
	}).current

	return (
		<>
			<Form<typeof initialValues>
				initialValues={initialValues}
				validate={validationSchema}
				onSubmit={withServerErrors(async (values) => {
					const data = new FormData()
					data.set("timezone_offset", values.timezone_offset.toString())
					const files = values.files
					const trip_destinations = values.trip_destinations || []
					if (!files || !files.length) {
						throw new Error("Please select some csv files")
					}
					data.set("currency", values.currency)
					trip_destinations.forEach((d, i) =>
						data.set(`trip_destinations[${i}]`, d.id.toString())
					)
					for (let i = 0; i < files.length; i++) {
						data.set(`files[${i}]`, files[i])
					}
					await xhr.post("/hotel-prices", data, {
						headers: {
							"Content-Type": "multipart/form-data",
						},
					})
					onSuccess()
				})}
				subscription={{ submitting: true, submitErrors: true }}
			>
				{({ submitting, submitErrors, handleSubmit, form }) => (
					<form
						noValidate
						encType="multipart/form-data"
						onSubmit={handleSubmit}
					>
						<Container bgColor="default">
							<Stack gap="4" paddingY="6">
								<SubmissionError />
								{submitErrors ? (
									<Stack gap="4">
										{submitErrors ? (
											<Box as="ul" listStyleType="disc">
												{Object.keys(submitErrors).map((field) => (
													<Box as="li" key={field}>
														<Text>
															{
																(submitErrors as { [field: string]: string })[
																	field
																]
															}
														</Text>
													</Box>
												))}
											</Box>
										) : null}
										<Text>
											Please verify your CSV File format with the format given
											below. Or contact our support if you need any help.
										</Text>
									</Stack>
								) : null}
								{can_attach_services_to_destinations ? (
									<SelectField
										select={SelectTripDestination}
										name="trip_destinations"
										label="Trip Destinations"
										multiple
										fetchOnMount
										closeOnSelect
										onChange={(
											destinations: Array<TTripDestination> | undefined
										) => {
											form.change("trip_destinations", destinations)
											if (destinations?.length) {
												form.change("currency", destinations[0].currency)
											}
										}}
									/>
								) : null}
								<GetFieldValue<
									IFormData["trip_destinations"]
								> name="trip_destinations">
									{({ value: destinations }) => {
										if (!destinations?.length) return null
										const hotelCheckinAt = destinations[0].hotel_checkin_at
										const hotelCheckoutAt = destinations[0].hotel_checkout_at
										return (
											<Alert title="For NEW Hotels uploaded via CSV files, following settings will be applied:">
												<Stack gap="2">
													<Inline gap="4" flexWrap="wrap">
														<Stack>
															<Text fontSize="sm">Check-in</Text>
															<Text fontWeight="semibold">
																<Time
																	value={parseDate(hotelCheckinAt, "HH:mm:ss")}
																	format="HH:mm"
																/>{" "}
																hrs
															</Text>
														</Stack>
														<Stack>
															<Text fontSize="sm">Check-out</Text>
															<Text fontWeight="semibold">
																<Time
																	value={parseDate(hotelCheckoutAt, "HH:mm:ss")}
																	format="HH:mm"
																/>{" "}
																hrs
															</Text>
														</Stack>
													</Inline>
													<Text>
														These default settings are configured in the
														selected destination. You can also update these
														settings per-hotel basis if applicable, after
														upload.
													</Text>
												</Stack>
											</Alert>
										)
									}}
								</GetFieldValue>
								<Inline gap="4" collapseBelow="sm">
									<SelectTenantCurrencyInputField
										name="currency"
										label="Currency"
										style={{ maxWidth: "150px" }}
									/>
									<Box flex="1">
										<FileInputField
											label="Select csv file(s)"
											name="files"
											accept=".csv"
											multiple
											help={
												<GetFieldValue<string> name="currency">
													{({ value: currency }) => (
														<Text>
															All prices will be saved in "{currency}" currency.
														</Text>
													)}
												</GetFieldValue>
											}
										/>
									</Box>
								</Inline>
								<Inline gap="4" flexWrap="wrap">
									<Button type="submit" disabled={submitting}>
										{submitting ? "Uploading..." : "Upload Hotel Prices CSV"}
									</Button>
									{onCancel ? (
										<Button
											onClick={onCancel}
											level="tertiary"
											disabled={submitting}
										>
											Cancel
										</Button>
									) : null}
								</Inline>
							</Stack>
						</Container>
					</form>
				)}
			</Form>
			<Container paddingY="6">
				<CopyToClipboard<HTMLDivElement>>
					{({ copy, nodeToCopy }) => (
						<Stack gap="4">
							<Heading as="h4">CSV File Format</Heading>
							<Text maxWidth="4xl">
								When creating your CSV file for hotel prices, please follow this
								format only. You can find more information about each column by
								hovering over the words with{" "}
								<b>
									<abbr title="You will get information like this. Please read each information carefully for best and accurate results.">
										dotted underlines
									</abbr>
								</b>
								. If you are unsure about what/why a column is, please feel free
								to contact our support team. You can keep all your prices in one
								CSV file or you can create separate file for each group to share
								them with Hotel Group as well.
							</Text>
							<Box ref={nodeToCopy}>
								<Table
									aria-label="CSV file format for hotel prices"
									responsive
									className="excel-style-table"
								>
									<Box as="thead" whiteSpace="preserve">
										<tr>
											<th rowSpan={4}>
												<abbr title="The name of group of hotels. Use 'NA' if a hotel has no group.">
													Group Name
												</abbr>
											</th>
											<th rowSpan={4}>
												<abbr title="Location of the hotel. Please provide in `District, State, Country` for better searching and mapping.">
													Location
												</abbr>
											</th>
											<th rowSpan={4}>
												<abbr title="The name of the hotel. Please avoid suffixing/prefixing with `Hotel` keyword if possible.">
													Name
												</abbr>
											</th>
											<th rowSpan={4}>
												<abbr title="Star rating of the hotel">Star</abbr>
											</th>
											<th rowSpan={4}>
												<abbr title="Room Category">Room</abbr>
											</th>
											<th colSpan={2}>
												Season 1{" "}
												<abbr title="(Optional) Add rates for Bookings">
													(Ops)
												</abbr>
											</th>
											<th colSpan={2}>
												<abbr title="Same season sales rates, used during quotation">
													Season 1
												</abbr>
											</th>
											<th colSpan={2}>
												<abbr title="Not adding any suffix (e.g. (Ops)) will use these rates for sales as well as for Bookings">
													Season 2
												</abbr>
											</th>
										</tr>
										<tr>
											<th colSpan={2}>
												<abbr title="These date range should follow the format of 'D MMM YYYY - D MMM YYYY' only otherwise you may loose some data">
													1 Jul {THIS_YEAR} - 30 Sep {THIS_YEAR}
												</abbr>
											</th>
											<th colSpan={2}>
												<abbr>
													1 Jul {THIS_YEAR} - 30 Sep {THIS_YEAR}
												</abbr>
											</th>
											<th colSpan={2}>
												13 Oct {THIS_YEAR} - 24 Oct {THIS_YEAR}{" "}
												<abbr title="You can also add week-day rates by specifying the week day names as shown.">
													(Sat,Sun)
												</abbr>
											</th>
										</tr>
										<tr>
											<th colSpan={2}>
												24 Dec {THIS_YEAR} - 4 Jan {THIS_YEAR + 1}
											</th>
											<th colSpan={2}>
												24 Dec {THIS_YEAR} - 4 Jan {THIS_YEAR + 1}
											</th>
											<th colSpan={2}>
												5 Jan {THIS_YEAR + 1} - 31 Mar {THIS_YEAR + 1}
											</th>
										</tr>
										<tr>
											<td>
												<abbr title="Meal Plans provides by hotels and their rates below">
													CP
												</abbr>
											</td>
											<td>MAP</td>
											<td>CP</td>
											<td>MAP</td>
											<td>CP</td>
											<td>MAP</td>
										</tr>
									</Box>
									<tbody>
										<tr>
											<td rowSpan={10}>Golden Hotels</td>
											<td rowSpan={3}>Gangtok, Sikkim, India</td>
											<td rowSpan={3}>Golden Star Continental</td>
											<td rowSpan={3}>3</td>
											<td>
												<abbr title="You can provide any room category for which you have prices for this hotel">
													Deluxe
												</abbr>{" "}
												<abbr title="Number of base persons in this room category for which prices are provided">
													(2P)
												</abbr>
											</td>
											<td>
												<abbr title="This is the purchage/buying/ops rate for one Room and a meal plan in a hotel for a given date range. Prices for extras can be provided in 'Extra' section.">
													1200
												</abbr>
											</td>
											<td>1800</td>
											<td>
												<abbr title="This is the selling/quotation rate for one Room and a meal plan in a hotel for a given date range. Prices for extras can be provided in 'Extra' section.">
													1600
												</abbr>
											</td>
											<td>2000</td>
											<td>2200</td>
											<td>2800</td>
										</tr>
										<tr>
											<td>
												Super Deluxe{" "}
												<abbr title="Add multiple base occupancies if rates are same.">
													(1P, 2P)
												</abbr>
											</td>
											<td>1800</td>
											<td>2400</td>
											<td>2000</td>
											<td>2400</td>
											<td>2500</td>
											<td>2800</td>
										</tr>
										<tr>
											<td>Family Room - 3 Bedded (3P)</td>
											<td>1200</td>
											<td>1800</td>
											<td>1600</td>
											<td>2000</td>
											<td>2200</td>
											<td>2800</td>
										</tr>
										<tr>
											<td rowSpan={4}>Darjeeling, West Bengal, India</td>
											<td rowSpan={2}>White Yak</td>
											<td rowSpan={2}>3</td>
											<td>Deluxe (2P)</td>
											<td>1600</td>
											<td>1900</td>
											<td>1600</td>
											<td>2000</td>
											<td>2200</td>
											<td>2800</td>
										</tr>
										<tr>
											<td>Super Deluxe (2P)</td>
											<td>1800</td>
											<td>2000</td>
											<td>2000</td>
											<td>2400</td>
											<td>2500</td>
											<td>2800</td>
										</tr>
										<tr>
											<td rowSpan={2}>Golden Dolma</td>
											<td rowSpan={2}>3</td>
											<td>Deluxe (2P)</td>
											<td>1600</td>
											<td>2000</td>
											<td>1600</td>
											<td>2000</td>
											<td>2200</td>
											<td>2800</td>
										</tr>
										<tr>
											<td>Super Deluxe (2P)</td>
											<td>1900</td>
											<td>1800</td>
											<td>2000</td>
											<td>2400</td>
											<td>2500</td>
											<td>2800</td>
										</tr>
										<tr>
											<td></td>
											<td rowSpan={3}>
												<abbr title="Extra prices for extra adults/children. Please ensure it is in this column only ('Name'/3rd column)">
													Extras
												</abbr>
											</td>
											<td></td>
											<td>
												<abbr title="Prices per adult with extra bed">
													Extra Bed (Adult)
												</abbr>
											</td>
											<td>1800</td>
											<td>2000</td>
											<td>2000</td>
											<td>2400</td>
											<td>2500</td>
											<td>2800</td>
										</tr>
										<tr>
											<td></td>
											<td></td>
											<td>
												<abbr title="Prices per children with extra bed">
													Extra Bed (Child)
												</abbr>
											</td>
											<td>2000</td>
											<td>2400</td>
											<td>2000</td>
											<td>2400</td>
											<td>2500</td>
											<td>2800</td>
										</tr>
										<tr>
											<td></td>
											<td></td>
											<td>
												<abbr title="Prices per children without extra beds (e.g. for meals etc)">
													Child without Bed (CNB)
												</abbr>
											</td>
											<td>2000</td>
											<td>2400</td>
											<td>2000</td>
											<td>2400</td>
											<td>2500</td>
											<td>2800</td>
										</tr>
									</tbody>
									<tbody>
										<tr>
											<td rowSpan={6}>Summit Hotels</td>
											<td rowSpan={2}>Gangtok, Sikkim, India</td>
											<td rowSpan={2}>Mount Himalayan hotel </td>
											<td rowSpan={2}>3</td>
											<td>
												Deluxe (2P){" "}
												<abbr title="(Optional) Configure the number of extra beds allowed for this room type.">
													(0EB)
												</abbr>
											</td>
											<td>1200</td>
											<td>1500</td>
											<td>1600</td>
											<td>2000</td>
											<td>2200</td>
											<td>2800</td>
										</tr>
										<tr>
											<td>
												Super Deluxe (2P){" "}
												<abbr title="(Optional) Configure the number of rooms of this type in the Hotel">
													(12R)
												</abbr>{" "}
												<abbr title="(Optional) Configure the number of allowed adults with extra beds for this room type.">
													(1AWEB)
												</abbr>{" "}
												<abbr title="(Optional) Configure the number of allowed children with extra beds for this room type.">
													(1CWEB)
												</abbr>{" "}
												<abbr title="(Optional) Configure the number of allowed children no-beds for this room type.">
													(1CNB)
												</abbr>
											</td>
											<td>2000</td>
											<td>2400</td>
											<td>2000</td>
											<td>2400</td>
											<td>2500</td>
											<td>2800</td>
										</tr>
										<tr>
											<td></td>
											<td rowSpan={3}>Extras</td>
											<td></td>
											<td>Extra Bed (Adult)</td>
											<td>1800</td>
											<td>2000</td>
											<td>2000</td>
											<td>2400</td>
											<td>2500</td>
											<td>2800</td>
										</tr>
										<tr>
											<td></td>
											<td></td>
											<td>
												Extra Bed (Child){" "}
												<abbr title="(Optional) Child age rage for Extra Bed Charges, defaults to 6-12">
													(6-12)
												</abbr>
											</td>
											<td>2000</td>
											<td>2400</td>
											<td>2000</td>
											<td>2400</td>
											<td>2500</td>
											<td>2800</td>
										</tr>
										<tr>
											<td></td>
											<td></td>
											<td>Child without Bed (CNB)</td>
											<td>2000</td>
											<td>2400</td>
											<td>2000</td>
											<td>2400</td>
											<td>2500</td>
											<td>2800</td>
										</tr>
									</tbody>
								</Table>
							</Box>
							<Box>
								<CopyToClipboardButton onClick={() => copy()} level="primary">
									<Icons.ClipboardCopy />
									Copy Template Format
								</CopyToClipboardButton>
							</Box>
						</Stack>
					)}
				</CopyToClipboard>
			</Container>
		</>
	)
}
