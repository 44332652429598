import {
	Box,
	Button,
	Icons,
	Table,
	RelativeTime,
	Dropdown,
	joinAttributes,
} from "@sembark-travel/ui/base"
import { utcTimestampToLocalDateString } from "@sembark-travel/datetime-utils"
import { ListView, Search, TSearchParams } from "@sembark-travel/ui/list"
import { IListResponse, useXHR, XHRInstance } from "@sembark-travel/xhr"
import React, { Fragment } from "react"
import { EditHotelVoucherInDialog } from "./Edit"
import {
	GeneratedVoucherAsset,
	GenerateVoucherAssets,
} from "./GenerateVoucherAssets"
import { IHotelVoucher } from "./store"
import pluralize from "pluralize"

function XHR(xhr: XHRInstance) {
	return {
		async get(params?: unknown): Promise<IListResponse<IHotelVoucher>> {
			return xhr.get("/hotel-vouchers", { params }).then((resp) => resp.data)
		},
	}
}

export function HotelVouchersList<TParams extends TSearchParams>({
	params = {} as TParams,
	setParams,
	actions,
}: {
	params?: TSearchParams
	setParams: (params: TSearchParams) => void
	actions?: React.ReactNode
}) {
	return (
		<Fragment>
			<Search
				initialParams={params}
				onSearch={(params) => {
					setParams({ ...params, page: 1 })
				}}
				title="Hotel Vouchers"
				actions={actions}
			/>
			<ListView<IHotelVoucher, TSearchParams>
				pageKey="hotel-vouchers-list"
				fetch={(xhr, params) => XHR(xhr).get(params)}
				onPageChange={(page) => setParams({ ...params, page })}
				params={params}
			>
				{({ items: vouchers, refresh }) => (
					<Table
						striped
						bordered
						headers={[
							"Ref#",
							"Hotel",
							"Guest",
							"Stay",
							"Services",
							"Status",
							"",
						]}
						rows={vouchers.map(
							({
								id,
								reference_id,
								file_url,
								booking_confirmation_id,
								hotel,
								guest_name,
								no_of_adults,
								children_string,
								checkin,
								checkout,
								no_of_nights,
								services,
								extra_services,
								sent_at,
								sent_by,
								is_generating,
							}) => [
								<Box>
									<Box display="flex">
										<GeneratedVoucherAsset
											id={id}
											fileUrl={file_url}
											isGenerating={is_generating}
											onChange={refresh}
											width="8"
											height="8"
											display="flex"
											alignItems="center"
											justifyContent="center"
											borderWidth="1"
											rounded="md"
											marginRight="2"
										/>
										<Box>
											{reference_id}
											{booking_confirmation_id || file_url ? (
												<Box fontSize="sm" color="muted">
													{joinAttributes([
														booking_confirmation_id,
														<Box
															display="inline"
															title="Booking confirmation number"
														>
															{booking_confirmation_id}
														</Box>,
													])}
												</Box>
											) : null}
										</Box>
									</Box>
								</Box>,
								<Box>
									<Box>{hotel.name}</Box>
									<Box fontSize="sm" color="muted">
										{hotel.location.short_name}
									</Box>
								</Box>,
								<Box>
									<Box>{guest_name}</Box>
									<Box fontSize="sm" color="muted">
										{joinAttributes(
											pluralize("Adult", no_of_adults, true),
											children_string ? `${children_string}` : null
										)}
									</Box>
								</Box>,
								<Box>
									{joinAttributes(
										utcTimestampToLocalDateString(checkin),
										<Box
											display="inline"
											title={utcTimestampToLocalDateString(checkout)}
										>
											{no_of_nights}N
										</Box>
									)}
								</Box>,
								<Box>
									{services.length ? (
										<Box>
											{joinAttributes(
												services[0].meal_plan.name,
												`${services[0].no_of_rooms} ${services[0].room_type.name}`
											)}
										</Box>
									) : null}
									{extra_services.length ? (
										<Box fontSize={services.length ? "sm" : "base"}>
											{extra_services.map(({ id, date, service }) => (
												<Box display="inline" key={id}>
													{date
														? `(${utcTimestampToLocalDateString(date)}) `
														: null}
													{service.name}
												</Box>
											))}
										</Box>
									) : null}
								</Box>,
								<Box>
									{sent_at && sent_by ? (
										<Box>
											Sent by {sent_by.name}
											<Box fontSize="sm" color="muted">
												<RelativeTime timestamp={sent_at} />
											</Box>
										</Box>
									) : (
										<Box>
											<MarkVoucherAsSent voucherId={id} onSuccess={refresh} />
										</Box>
									)}
								</Box>,
								<Box>
									<Dropdown alignRight>
										<Button as={Dropdown.Toggle} level="tertiary">
											<Icons.ChevronDown />
										</Button>
										<Dropdown.Menu>
											<li>
												<EditHotelVoucherInDialog
													voucherId={id}
													onSuccess={refresh}
												/>
											</li>
											<GenerateVoucherAssets voucherId={id} onChange={refresh}>
												{({ onGenerate }) => (
													<Dropdown.MenuItem onClick={onGenerate}>
														Regenerate Voucher
													</Dropdown.MenuItem>
												)}
											</GenerateVoucherAssets>
										</Dropdown.Menu>
									</Dropdown>
								</Box>,
							]
						)}
					/>
				)}
			</ListView>
		</Fragment>
	)
}

export function MarkVoucherAsSent({
	voucherId,
	onSuccess,
	confirmationMessage = "You will be marked as the Voucher sender for this booking.",
	children,
	minimal,
}: {
	voucherId: number
	onSuccess?: () => void
	confirmationMessage?: string
	children?: React.ReactNode
	minimal?: boolean
}) {
	const xhr = useXHR()
	return (
		<Button
			onClick={() => {
				if (window.confirm(confirmationMessage)) {
					xhr.post(`/sent-hotel-vouchers/${voucherId}`).then(onSuccess)
				}
			}}
			title="Mark voucher as Sent"
			{...(minimal
				? {
						inline: true,
						size: "sm",
				  }
				: {})}
		>
			{children || "Mark as Sent"}
		</Button>
	)
}
