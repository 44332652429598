import { Channel, TChannelConfig } from "./contract"

declare global {
	function analyticsManager(
		tag: "config",
		measurement_id: string,
		data?: {
			cookie_prefix?: string
			cookie_domain?: string
			cookie_expires?: number
			user_id?: unknown
		}
	): void
	function analyticsManager(
		tag: "set",
		type: "user_properties",
		data?: unknown
	): void
	function analyticsManager(tag: "event", action: unknown, data?: unknown): void
}

export type TGAChannelConfig = TChannelConfig & {
	gaMeasurementId?: string
}

const createGAChannel: Channel = function createGAChannel(
	config: TGAChannelConfig
) {
	const { gaMeasurementId } = config
	if (!gaMeasurementId) {
		throw new Error("Missing measurement id for GA channel.")
	}
	return {
		page(...args) {
			console.log("TODO: page", ...args)
		},
		track(action: unknown, data?: unknown) {
			analyticsManager("event", action, data)
		},
		resetIdentity() {
			analyticsManager("set", "user_properties", {
				tenant_name: undefined,
				user_id: undefined,
				app_version: config.appVersion,
			})
		},
		identify(userId, properties) {
			analyticsManager("config", gaMeasurementId, {
				user_id: userId,
			})
			analyticsManager("set", "user_properties", {
				tenant_name: properties?.tenantName?.replace(
					/\b(\w{3})(\w+)(\w)\b/g,
					(_, first, middle, last) =>
						`${first}${"*".repeat(middle.length)}${last}`
				),
				user_id: userId,
				app_version: config.appVersion,
			})
		},
	}
}

export default createGAChannel
