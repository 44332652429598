import { Alert, useId } from "@sembark-travel/ui/base"
import { useXHR } from "@sembark-travel/xhr"
import useSWR from "swr"
import { Spinner } from "@sembark-travel/ui/base"
import { NewItemForm } from "./NewItem"
import { ITermsAndCondition } from "./store"

export function EditTermsAndConditionItem({
	onSuccess,
	onCancel,
	tncId,
}: {
	onSuccess: (tnc: ITermsAndCondition) => void
	onCancel?: () => void
	tncId: string
}) {
	const xhr = useXHR()
	const id = useId()
	const { data, error } = useSWR<ITermsAndCondition>(
		`/terms-and-conditions/${tncId}/edit?${id}`,
		() =>
			xhr
				.get(`/terms-and-conditions/${tncId}/edit`)
				.then((resp) => resp.data.data)
	)
	if (!data && error) {
		return <Alert status="error">{error.message}</Alert>
	}
	if (!data) {
		return <Spinner padding="4" alignCenter />
	}
	return (
		<NewItemForm
			initialValues={{
				name: data.name,
				description: data.description,
			}}
			onCancel={onCancel}
			onSubmit={async (values) => {
				if (values.description !== data.description) {
					if (
						!window.confirm(
							`You have changed the terms. This will result in creation of new terms and archive the existing terms.\nAre you sure you want to continue ?`
						)
					) {
						throw new Error(
							"You have declined the prompt! To continue, please accept prompt."
						)
					}
				}
				const tnc = await xhr.patch(`/terms-and-conditions/${tncId}`, values)
				onSuccess(tnc.data.data)
				return tnc
			}}
		/>
	)
}
