import { Helmet } from "react-helmet-async"
import { Apperance } from "./../../../Settings"

export default function AppearanceSettingsPage() {
	return (
		<>
			<Helmet>
				<title>Appearance | Settings</title>
			</Helmet>
			<Apperance />
		</>
	)
}
