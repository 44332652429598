import { ButtonLink } from "@sembark-travel/ui/router"
import { Helmet } from "react-helmet-async"
import { TenantsList } from "./../../../../Admin/Tenants"
import { generatePath } from "./../../../../router-utils"
import { PERMISSIONS, useCheckPermissions } from "./../../../../Auth"

export default function Tenants(
	props: Omit<React.ComponentProps<typeof TenantsList>, "actions">
) {
	const { hasPermission } = useCheckPermissions()
	return (
		<>
			<Helmet>
				<title>Tenants</title>
			</Helmet>
			<TenantsList
				{...props}
				actions={
					hasPermission(PERMISSIONS.MANAGE_TENANTS) ? (
						<ButtonLink
							to={generatePath("/admin/tenants/new")}
							level="primary"
							status="primary"
						>
							Invite Tenant
						</ButtonLink>
					) : null
				}
			/>
		</>
	)
}
