import { forwardRef, createContext, useContext, useMemo } from "react"
import classNames from "classnames"
import { Box, BoxProps } from "./Box"
import { tableClassName, tableVariants } from "./table.css"

export interface TableProps extends BoxProps<"table"> {
	/**
	 * Table has a fixed layout
	 * @default false
	 */
	fixedLayout?: boolean
	/**
	 * Headers for the table
	 * @default undefined
	 */
	headers?: React.ReactNode[]
	/**
	 * Rows in the table
	 * @default undefined
	 */
	rows?: React.ReactNode[][]
	/**
	 * Align the columns on right or center. Pass the column number(index) as key and right | center as it's value in the object
	 * @default undefined
	 */
	alignCols?: { [key: number]: "center" | "right" }
	/**
	 * Does the table requires responsiveness?
	 * @default false
	 */
	responsive?: boolean
	/**
	 * Caption for the table
	 * @default ""
	 */
	caption?: string
	/**
	 * Width Auto
	 */
	autoWidth?: boolean
	/**
	 * Add zebra-striping
	 */
	striped?: boolean
	/**
	 * Add border around each data cell
	 */
	bordered?: boolean
	/**
	 * Change row's background color when hovered
	 */
	hover?: boolean

	/**
	 * Stick the header to the top
	 */
	stickyHeader?: boolean
	stickyFooter?: boolean

	/**
	 * Stick the header to the top
	 */
	stickyFirstColumn?: boolean
	stickyLastColumn?: boolean
}

const TableContext = createContext({
	fixedLayout: false,
	striped: false,
	bordered: false,
	hover: false,
	responsive: false,
})

function useTableContext() {
	return useContext(TableContext)
}

export const Table = forwardRef<HTMLTableElement, TableProps>(
	(
		{
			fixedLayout,
			className,
			headers,
			children,
			alignCols = {},
			rows,
			responsive,
			caption,
			autoWidth,
			striped,
			bordered,
			hover,
			stickyHeader,
			stickyFooter,
			stickyFirstColumn,
			stickyLastColumn,
			style,
			...otherProps
		},
		ref
	) => {
		const context = useMemo(() => {
			return {
				fixedLayout: fixedLayout || false,
				striped: striped || false,
				bordered: bordered || false,
				hover: hover || false,
				responsive: responsive || false,
			}
		}, [fixedLayout, striped, bordered, hover, responsive])
		const $table = (
			<TableContext.Provider value={context}>
				<Box<"table">
					as="table"
					cellSpacing={0}
					position={stickyFirstColumn || stickyHeader ? "relative" : undefined}
					ref={ref}
					width="full"
					className={classNames(
						tableClassName,
						{
							[tableVariants.fixedLayout]: fixedLayout,
							[tableVariants.autoWidth]: autoWidth ? true : undefined,
							[tableVariants.striped]: striped ? true : undefined,
							[tableVariants.bordered]: bordered ? true : undefined,
							[tableVariants.hover]: hover ? true : undefined,
							[tableVariants.stickyHeader]: stickyHeader ? true : undefined,
							[tableVariants.stickyFooter]: stickyFooter ? true : undefined,
							[tableVariants.stickyFirstColumn]: stickyFirstColumn
								? true
								: undefined,
							[tableVariants.stickyLastColumn]: stickyLastColumn
								? true
								: undefined,
						},
						className
					)}
					{...otherProps}
					style={responsive ? undefined : style}
				>
					{caption ? (
						<Box as="caption" textAlign="left" paddingY="2" paddingX="0">
							{caption}
						</Box>
					) : null}
					{headers ? (
						<thead>
							<tr>
								{headers.map((header, h) => (
									<TableHeaderDataCell key={h} textAlign={alignCols[h]}>
										{header}
									</TableHeaderDataCell>
								))}
							</tr>
						</thead>
					) : null}
					{rows ? (
						<tbody>
							{rows.map((row, r) => (
								<tr key={r}>
									{row.map((data, d) => (
										<TableDataCell as="td" key={d} textAlign={alignCols[d]}>
											{data}
										</TableDataCell>
									))}
								</tr>
							))}
						</tbody>
					) : null}
					{children}
				</Box>
			</TableContext.Provider>
		)
		if (responsive) {
			return (
				<Box
					display="block"
					width="full"
					maxWidth="full"
					overflowX="auto"
					style={style}
				>
					{$table}
				</Box>
			)
		}
		return $table
	}
)
Table.displayName = "Table"

export function TableHeaderDataCell(props: BoxProps<"th">) {
	const { responsive } = useTableContext()
	return (
		<TableDataCell
			as="th"
			whiteSpace={responsive ? "preserve" : undefined}
			paddingY="2"
			verticalAlign="bottom"
			borderBottomWidth="1"
			fontWeight="semibold"
			{...props}
		/>
	)
}

export function TableFooterDataCell(props: BoxProps<"td" | "th">) {
	const { responsive } = useTableContext()
	return (
		<TableDataCell
			whiteSpace={responsive ? "preserve" : undefined}
			paddingY="2"
			verticalAlign="top"
			borderTopWidth="2"
			borderColor="emphasis"
			fontWeight="semibold"
			{...props}
		/>
	)
}

export function TableDataCell(props: BoxProps<"td" | "th">) {
	return (
		<Box
			verticalAlign="top"
			textAlign="left"
			fontVariantNumeric="tabularNums"
			paddingX="4"
			paddingY="3"
			as="td"
			{...props}
		/>
	)
}
