import {
	Box,
	Icons,
	Stack,
	Heading,
	Text,
	Divider,
} from "@sembark-travel/ui/base"
import { ButtonLink } from "@sembark-travel/ui/router"
import { useXHR } from "@sembark-travel/xhr"
import pluralize from "pluralize"
import { useState } from "react"
import useSWR from "swr"
import { generatePath } from "../../../router-utils"
import {
	NewTripDestination,
	tripDestinationListXHR,
} from "../../../TripDestinations"
import { Helmet } from "react-helmet-async"

export default function OnboardingAddDestinationsPage() {
	const xhr = useXHR()
	const { data, mutate: revalidate } = useSWR("/trip-destinations", () =>
		tripDestinationListXHR(xhr).get()
	)
	const [destinationsCreated, setDestinationsCreated] = useState<boolean>(false)
	return (
		<>
			<Helmet>
				<title>Add Destination | Onboarding</title>
			</Helmet>
			<Box
				maxWidth="4xl"
				marginX="auto"
				padding="8"
				borderWidth="1"
				rounded="md"
				bgColor="default"
				marginBottom="16"
			>
				<Box
					borderBottomWidth="1"
					display="inlineBlock"
					fontWeight="semibold"
					marginBottom="8"
					color="primary"
					fontSize="lg"
				>
					Onboarding &gt; Destinations
				</Box>
				{!destinationsCreated ? (
					<Stack gap="4">
						<Stack gap="2">
							<Heading as="h3">Add Your Service Destinations</Heading>
							<Text>
								Add your service destination(s) e.g. Sikkim, Nepal, Bhutan etc.
								You can add multiple destinations in one go.
							</Text>
						</Stack>
						<Divider sm />
						<NewTripDestination
							onSuccess={async () => {
								await revalidate()
								setDestinationsCreated(true)
							}}
						/>
						{data?.meta.total ? (
							<Stack
								padding="4"
								bgColor="subtle"
								borderWidth="1"
								rounded="md"
								gap="2"
							>
								<Text>
									You have successfully added{" "}
									<b>{data.data.map((d) => d.name).join(", ")}</b> as your
									service {pluralize("destination", data?.data.length)}.
								</Text>
								<Box>
									<ButtonLink to={generatePath("/onboarding/invite-users")}>
										Skip to: Invite Team Members{" "}
										<Icons.ChevronDown rotate="270" />
									</ButtonLink>
								</Box>
							</Stack>
						) : null}
					</Stack>
				) : (
					<Stack gap="4">
						<Heading as="h3">
							<Icons.Ok color="success" size="8" /> Great Job!
						</Heading>
						<Text>
							You have successfully added{" "}
							<b>{data?.data.map((d) => d.name).join(", ")}</b> as your service{" "}
							{pluralize("destination", data?.data.length)}.
						</Text>
						<Text>
							If you want to add more destinations,{" "}
							<Text<"a">
								as="a"
								href="#add-more-destinations"
								color="accent"
								onClick={(e) => {
									e.preventDefault()
									setDestinationsCreated(false)
								}}
							>
								click here
							</Text>
							. Otherwise let move on to the next step.
						</Text>
						<Divider />
						<Box>
							<Box
								fontWeight="semibold"
								display="inlineBlock"
								borderBottomWidth="1"
								marginBottom="2"
								textTransform="uppercase"
								fontSize="sm"
								color="muted"
							>
								Next
							</Box>
						</Box>
						<Heading as="h3">Team Members</Heading>
						<Text>
							If you have team for Sales, Operations and Accounting, you should
							invite team members for better work allocation and profitability.
						</Text>
						<Box>
							<ButtonLink
								to={generatePath("/onboarding/invite-users")}
								level="primary"
							>
								Invite Team Members <Icons.ChevronDown rotate="270" />
							</ButtonLink>
						</Box>
					</Stack>
				)}
			</Box>
		</>
	)
}
