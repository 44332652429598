import {
	Stack,
	Inline,
	Table,
	Divider,
	Heading,
	Text,
	Container,
	Icons,
	Box,
	RelativeTime,
} from "@sembark-travel/ui/base"
import { ListView, useSearch, Search } from "@sembark-travel/ui/list"
import { ButtonLink, Link, useLocationQuery } from "@sembark-travel/ui/router"
import { useEffect } from "react"
import { TIntegration, XHR } from "./store"
import { generatePath } from "../router-utils"
import { PlatformIcon } from "./PlatformIcon"

export function IntegrationsList() {
	const [query, setQuery] = useLocationQuery()
	const [params, setParams] = useSearch(query)
	useEffect(() => {
		setQuery(params)
	}, [setQuery, params])
	return (
		<Search
			initialParams={params}
			onSearch={(params) => setParams({ ...params, page: 1 })}
			title="Integrations"
			resetParams={{
				q: "",
				page: 1,
			}}
			actions={
				<ButtonLink to={generatePath("/integrations/new")} status="primary">
					New Integration
				</ButtonLink>
			}
		>
			<ListView<TIntegration>
				pageKey="integrations"
				fetch={(xhr, params) => XHR(xhr).get(params)}
				onPageChange={(page) => setParams({ ...params, page })}
				params={params}
				emptyState={function ({ searched, defaultValue }) {
					return searched ? (
						<>{defaultValue}</>
					) : (
						<Container paddingY="8">
							<Stack gap="4">
								<Text fontSize="md" fontWeight="semibold">
									<Link to={generatePath("/integrations/new")} color="accent">
										Create integrations
									</Link>{" "}
									that interact with Sembark using the{" "}
									<Text
										as="a"
										href="https://sembark.com/travel-software/apis"
										target="_blank"
										rel="noopener noreferrer"
										color="accent"
									>
										REST API
									</Text>
									.
								</Text>
								<Divider sm />
								<Heading as="h3">What are Integrations ?</Heading>
								<Text>
									As the name suggests, with integrations you can interact with
									your Sembark software using REST Apis. For example, you can
									create an integration to bring enquiries from your marketing
									site directly into your Sembark Dashboard.
								</Text>
								<Divider sm />
								<Heading as="h3">Get Started</Heading>
								<Text>Here are some integration to get you started with.</Text>
								<Link
									to={generatePath("/integrations/api/new")}
									maxWidth="md"
									query={{
										name: "Marketing Site",
										description:
											"Automate enquiries creation by bringing leads from marketing site into Sembark Dashboard.",
									}}
								>
									<Stack
										bgColor="default"
										padding="4"
										rounded="md"
										borderWidth="1"
										gap="1"
									>
										<Inline gap="4" color="accent">
											<Text fontSize="md" fontWeight="semibold">
												Get enquiry from your landing pages
											</Text>
											<Box>
												<Icons.ArrowLeft rotate="180" size="6" />
											</Box>
										</Inline>
										<Text color="muted">
											Automate enquiries creation by bringing leads from your
											landing pages into Sembark Dashboard.
										</Text>
									</Stack>
								</Link>
							</Stack>
						</Container>
					)
				}}
			>
				{({ items }) => (
					<>
						<Table
							bordered
							headers={["Name", "Created By", "Created On"]}
							alignCols={{ 2: "right" }}
							hover
							responsive
							rows={items.map((item) => [
								<Link
									to={generatePath("/integrations/:integrationId", {
										integrationId: item.uid,
									})}
									color="accent"
									fontWeight="semibold"
									anchored
								>
									<Inline gap="2" alignItems="center">
										<Box>
											<PlatformIcon platform={item.platform} size="24px" />
										</Box>
										<Stack>
											<Text as="span">{item.name}</Text>
											<Text color="muted" fontWeight="normal" fontSize="sm">
												{item.description}
											</Text>
										</Stack>
									</Inline>
								</Link>,
								<Text>{item.created_by?.name || "You"}</Text>,
								<RelativeTime timestamp={item.created_at} />,
							])}
						/>
					</>
				)}
			</ListView>
		</Search>
	)
}
