import {
	Box,
	Money,
	Button,
	Stack,
	Inline,
	Table,
	TableHeaderDataCell,
	TableDataCell,
	Icons,
	Heading,
	Grid,
	Col,
	Text,
	AsyncSelect,
	Divider,
} from "@sembark-travel/ui/base"
import { useXHR } from "@sembark-travel/xhr"
import {
	TMoney,
	addMoney,
	getPercentageOfMoney,
	createMoney,
	multipleMoneyBy,
	roundMoney,
	subtractMoney,
} from "@sembark-travel/money"
import {
	DatePickerField,
	EmptyNumberValidator,
	FieldArray,
	Form,
	FormSpy,
	GetFieldValue,
	PercentageInputField,
	SelectField,
	SubmissionError,
	SwitchInputField,
	TextAreaInputField,
	TextInputField,
	arrayMutators,
	isTruthy,
	validateFormValues,
	withServerErrors,
} from "@sembark-travel/ui/form"
import { Optional, Required } from "utility-types"
import {
	formatMoneyByDecimal,
	moneyParseByDecimal,
} from "@sembark-travel/money"
import { dateToUTCString, endOf } from "@sembark-travel/datetime-utils"
import { useState } from "react"
import config from "../config"
import * as Validator from "yup"
import {
	AddressBillingDetails,
	AddressContactDetails,
	AddressLocation,
	IAddress,
} from "../Addresses"
import { ILocation, SelectLocations } from "../Locations"
import { TInvoice, invoicesXHR } from "./store"
import { ButtonLink } from "@sembark-travel/ui/router"
import { generatePath } from "../router-utils"

export type InvoiceFormData = {
	currency: string
	due_at?: Date
	issued_at?: Date
	total_amount?: number
	reference_id?: string
	notes?: string
	terms_and_conditions?: string
	items?: Array<{
		description?: string
		hsn?: string
		quantity: number
		rate_per_quantity?: number
		currency?: string
		taxes?: Array<{
			name?: string
			short_name?: string
			percentage?: number
		}>
	}>
	seller_name: string
	seller_address?: IAddress
	seller_functional_currency: string
	buyer_name: string
	buyer_address?: IAddress
	location_of_supply?: ILocation
	confirmed?: 0 | 1
}

export function InvoiceForm<T extends TInvoice = TInvoice>({
	onSuccess,
	onCancel,
	initialValues: propInitialValues,
	sellerFunctionalCurrency,
	currency,
	onSubmit,
	onEditBuyerAddress,
}: {
	onSuccess: (invoice: T) => void
	onCancel: () => void
	sellerFunctionalCurrency: string
	currency: string
	initialValues?: Optional<InvoiceFormData>
	onSubmit: (
		payload: Omit<
			InvoiceFormData,
			| "due_at"
			| "seller_name"
			| "seller_address"
			| "seller_functional_currency"
			| "buyer_name"
			| "buyer_address"
			| "location_of_supply"
		> & {
			due_at: string | null
			timezone_offset: number
			location_of_supply: string | number | null
		}
	) => Promise<T>
	onEditBuyerAddress?: () => Promise<{ address?: IAddress }>
}) {
	const [initialValues, setInitialValues] = useState<Optional<InvoiceFormData>>(
		() => {
			return {
				...propInitialValues,
				seller_functional_currency: sellerFunctionalCurrency,
				currency,
				confirmed: 0,
			}
		}
	)
	return (
		<Form<InvoiceFormData>
			initialValues={initialValues}
			validate={validateFormValues(
				Validator.object().shape({
					due_at: Validator.mixed().required("Please select a due date"),
					items: Validator.array()
						.required("Please provide atleast one item")
						.min(1, "Please provide atleast one item")
						.of(
							Validator.object().shape({
								description: Validator.string().required(
									"Please provide description of the item"
								),
								hsn: EmptyNumberValidator().nullable(),
								rate_per_quantity: EmptyNumberValidator().required(
									"Please provide the base price of the item"
								),
								taxes: Validator.array()
									.nullable()
									.of(
										Validator.object().shape({
											short_name: Validator.string().required("Required"),
											percentage: EmptyNumberValidator()
												.required("Required")
												.positive("Must be positive"),
										})
									),
							})
						),
				})
			)}
			onSubmit={withServerErrors(
				async ({
					seller_address,
					buyer_address,
					seller_name,
					seller_functional_currency,
					buyer_name,
					location_of_supply,
					confirmed,
					...values
				}) => {
					if (!isTruthy(confirmed)) {
						throw new Error("Please accept the details correctness.")
					}
					const payload = {
						...values,
						due_at: values.due_at
							? dateToUTCString(endOf(values.due_at, "day"))
							: null,
						timezone_offset: config.timezoneOffset,
						location_of_supply: location_of_supply
							? location_of_supply.id || location_of_supply.name
							: null,
					}
					const invoice = await onSubmit(payload)
					onSuccess(invoice)
				}
			)}
			subscription={{ submitting: true }}
			mutators={{ ...arrayMutators }}
		>
			{({ handleSubmit, submitting, form }) => (
				<form onSubmit={handleSubmit} noValidate>
					<Stack gap="4">
						<Stack
							gap="4"
							borderWidth="1"
							padding="4"
							rounded="lg"
							bgColor="subtle"
						>
							<Grid gap="6">
								<Col sm={12} md>
									<Stack gap="1" maxWidth="sm">
										<Inline gap="4">
											<Heading fontSize="md">
												Seller Billing/Address Details
											</Heading>
											<ButtonLink
												to={
													generatePath("/org/profile") +
													"#address" +
													initialValues.seller_address?.id
												}
												level="tertiary"
												size="sm"
											>
												<Icons.Pencil />
											</ButtonLink>
										</Inline>
										<Text>Name: {initialValues?.seller_name}</Text>
										{initialValues?.seller_address ? (
											<Inline gap="4" flexWrap="wrap">
												<Stack gap="2">
													<AddressLocation
														address={initialValues.seller_address}
													/>
													<AddressContactDetails
														address={initialValues.seller_address}
													/>
												</Stack>
												<AddressBillingDetails
													hideName
													address={initialValues.seller_address}
												/>
											</Inline>
										) : (
											<Text>N/A</Text>
										)}
									</Stack>
								</Col>
								<Col sm={12} md>
									<Stack gap="1" maxWidth="sm">
										<Inline gap="4">
											<Heading fontSize="md">
												Buyer Billing/Address Details
											</Heading>
											{onEditBuyerAddress ? (
												<Button
													onClick={() =>
														onEditBuyerAddress().then(({ address }) => {
															if (address) {
																setInitialValues({
																	...initialValues,
																	buyer_name:
																		address.addressing_name ||
																		initialValues.buyer_name,
																	buyer_address:
																		address || initialValues.buyer_address,
																})
															}
														})
													}
													size="sm"
													level="tertiary"
												>
													<Icons.Pencil />
												</Button>
											) : null}
										</Inline>
										<Text>Name: {initialValues?.buyer_name}</Text>
										{initialValues?.buyer_address ? (
											<Inline gap="4" flexWrap="wrap">
												<Stack gap="2">
													<AddressLocation
														address={initialValues.buyer_address}
													/>
													<AddressContactDetails
														address={initialValues.buyer_address}
													/>
												</Stack>
												<AddressBillingDetails
													hideName
													address={initialValues.buyer_address}
												/>
											</Inline>
										) : (
											<Text>N/A</Text>
										)}
									</Stack>
								</Col>
							</Grid>
						</Stack>
						<Grid gap="4">
							<Col>
								{sellerFunctionalCurrency === "INR" ? (
									<SelectField
										currency={sellerFunctionalCurrency}
										select={SelectPlaceOfSupply}
										fetchOnMount
										name="location_of_supply"
										label="Place of Supply"
										multiple={false}
										onChange={(value: ILocation | undefined) => {
											form.change("location_of_supply", value || undefined)
											if (currency === "INR") {
												if (initialValues?.seller_address && value) {
													const is_integrated =
														initialValues.seller_address?.location?.state_id !==
														value.state_id
													if (
														window.confirm(
															`Do you also want to update tax details (${
																is_integrated ? "IGST" : "CGST+SGST"
															}) based on the "Place of Supply"`
														)
													) {
														const formValues = form.getState().values
														const items = (formValues.items || [])?.map(
															({ taxes, ...item }) => {
																const percentage = (taxes || []).reduce<number>(
																	(total, { percentage }) =>
																		total + Number(percentage || 0),
																	0
																)
																taxes = []
																if (is_integrated) {
																	taxes.push({
																		percentage,
																		name: "IGST",
																		short_name: "IGST",
																	})
																} else {
																	const center_tax =
																		Math.round((percentage * 100) / 2) / 100
																	taxes.push(
																		{
																			percentage: center_tax,
																			name: "CGST",
																			short_name: "CGST",
																		},
																		{
																			percentage: percentage - center_tax,
																			name: "SGST",
																			short_name: "SGST",
																		}
																	)
																}
																return {
																	...item,
																	taxes,
																}
															}
														)
														form.change("items", items)
													}
												}
											}
										}}
									/>
								) : (
									<SelectField
										select={SelectLocations}
										name="location_of_supply"
										label="Place of Supply"
										secondaryLabel={"optional"}
										multiple={false}
										creatable
									/>
								)}
							</Col>
							<Col>
								<DatePickerField name="due_at" label="Due Date" />
							</Col>
						</Grid>
						<FieldArray<
							Required<InvoiceFormData>["items"][number]
						> name="items">
							{({ fields }) => (
								<Stack gap="4">
									<Inline gap="4" justifyContent="between" alignItems="center">
										<Heading fontSize="md">Particulars</Heading>
										<Button
											onClick={() =>
												fields.push({ currency: "INR", quantity: 1 })
											}
											size="sm"
											status="primary"
										>
											<Icons.Plus /> Add Item
										</Button>
									</Inline>
									<Table bordered hover responsive>
										<thead>
											<tr>
												<TableHeaderDataCell>#</TableHeaderDataCell>
												<TableHeaderDataCell>Particular</TableHeaderDataCell>
												<TableHeaderDataCell>HSN/SAC</TableHeaderDataCell>
												<GetFieldValue<string | undefined> name="currency">
													{({ value: currency }) => (
														<TableHeaderDataCell>
															Amount ({currency})
														</TableHeaderDataCell>
													)}
												</GetFieldValue>
												<TableHeaderDataCell textAlign="right">
													&nbsp;
												</TableHeaderDataCell>
											</tr>
										</thead>
										<tbody>
											{fields.map((name, index) => (
												<tr key={name}>
													<TableDataCell>{index + 1}</TableDataCell>
													<TableDataCell>
														<TextAreaInputField
															name={`${name}.description`}
															placeholder="Details regarding the item"
															rows={index === 0 ? 5 : undefined}
														/>
													</TableDataCell>
													<TableDataCell>
														<TextInputField
															type="text"
															name={`${name}.hsn`}
															placeholder="e.g. 998555"
															style={{ maxWidth: "100px" }}
														/>
													</TableDataCell>
													<TableDataCell>
														<Stack gap="2" style={{ maxWidth: "300px" }}>
															<TextInputField
																type="number"
																name={`${name}.rate_per_quantity`}
																placeholder="e.g. 0"
															/>
															<FieldArray<
																Required<
																	Required<InvoiceFormData>["items"][number]
																>["taxes"][number]
															>
																name={`${name}.taxes`}
															>
																{({ fields }) => (
																	<GetFieldValue<number | undefined>
																		name={`${name}.rate_per_quantity`}
																	>
																		{({ value: ratePerQuantity }) =>
																			ratePerQuantity ? (
																				<>
																					{!fields.length ? (
																						<Box>
																							<Button
																								onClick={() =>
																									fields.push({
																										short_name: "",
																										name: "",
																									})
																								}
																								size="sm"
																							>
																								Add Tax
																							</Button>
																						</Box>
																					) : (
																						<Table bordered hover responsive>
																							<thead>
																								<tr>
																									<TableHeaderDataCell>
																										Tax Name
																									</TableHeaderDataCell>
																									<TableHeaderDataCell>
																										Percentage
																									</TableHeaderDataCell>
																									<TableHeaderDataCell textAlign="right">
																										<Button
																											onClick={() =>
																												fields.push({
																													short_name: "",
																													name: "",
																												})
																											}
																											inline
																										>
																											<Icons.Plus />
																										</Button>
																									</TableHeaderDataCell>
																								</tr>
																							</thead>
																							<tbody>
																								{fields.map(
																									(taxFieldName, index) => (
																										<tr key={taxFieldName}>
																											<TableDataCell>
																												<TextInputField
																													name={`${taxFieldName}.short_name`}
																													placeholder="e.g. GST"
																													type="text"
																													style={{
																														maxWidth: "100px",
																													}}
																													size="sm"
																												/>
																											</TableDataCell>
																											<TableDataCell>
																												<Stack gap="1">
																													<PercentageInputField
																														type="number"
																														name={`${taxFieldName}.percentage`}
																														placeholder="10.00"
																														size="sm"
																													/>
																													<GetFieldValue<
																														number | undefined
																													>
																														name={`${taxFieldName}.percentage`}
																													>
																														{({
																															value: percentage,
																														}) =>
																															percentage ? (
																																<Text color="muted">
																																	<Money
																																		money={moneyParseByDecimal(
																																			(Number(
																																				percentage
																																			) *
																																				Number(
																																					ratePerQuantity
																																				)) /
																																				100,
																																			currency
																																		)}
																																	/>
																																</Text>
																															) : null
																														}
																													</GetFieldValue>
																												</Stack>
																											</TableDataCell>
																											<TableDataCell textAlign="right">
																												<Inline
																													gap="2"
																													justifyContent="end"
																												>
																													<Button
																														inline
																														onClick={() =>
																															fields.remove(
																																index
																															)
																														}
																													>
																														<Icons.Cancel />
																													</Button>
																												</Inline>
																											</TableDataCell>
																										</tr>
																									)
																								)}
																							</tbody>
																						</Table>
																					)}
																				</>
																			) : null
																		}
																	</GetFieldValue>
																)}
															</FieldArray>
														</Stack>
													</TableDataCell>
													<TableDataCell textAlign="right">
														<Inline gap="2" justifyContent="end">
															<Button
																size="sm"
																onClick={() => fields.remove(index)}
															>
																<Icons.Cancel />
															</Button>
														</Inline>
													</TableDataCell>
												</tr>
											))}
										</tbody>
									</Table>
								</Stack>
							)}
						</FieldArray>
						<FormSpy<InvoiceFormData> subscription={{ values: true }}>
							{({ values, form }) => {
								const { currency, items } = values
								const total = (items || []).reduce<TMoney>(
									(total, item) => {
										const ratePerQuantity = moneyParseByDecimal(
											item.rate_per_quantity || 0,
											currency
										)
										const quantity = item.quantity || 1
										const taxableAmountOfItem = multipleMoneyBy(
											ratePerQuantity,
											quantity
										)
										const taxAmountOfItem = (item.taxes || []).reduce<TMoney>(
											(taxAmount, { percentage }) =>
												addMoney(
													taxAmount,
													getPercentageOfMoney(
														taxableAmountOfItem,
														Number(percentage || 0)
													)
												),
											createMoney(0, currency)
										)
										return addMoney(total, taxableAmountOfItem, taxAmountOfItem)
									},
									createMoney(0, currency)
								)
								return (
									<Stack
										padding="4"
										bgColor="primary"
										textAlign="right"
										gap="1"
										rounded="lg"
									>
										<Text fontSize="lg" fontWeight="semibold">
											Total:{" "}
											<Money money={total} showCurrency keepTrailingZero />
										</Text>
										{total.amount &&
										formatMoneyByDecimal(total) !==
											String(parseInt(formatMoneyByDecimal(total))) ? (
											<Box>
												<Button
													size="sm"
													onClick={() =>
														form.change(
															"items",
															(values.items || []).concat([
																{
																	description: "Round Off",
																	currency,
																	quantity: 1,
																	rate_per_quantity: Number(
																		formatMoneyByDecimal(
																			subtractMoney(roundMoney(total), total)
																		)
																	),
																},
															])
														)
													}
												>
													Round Off
												</Button>
											</Box>
										) : null}
									</Stack>
								)
							}}
						</FormSpy>
						<Divider marginY="0" />
						<Grid gap="4">
							<Col sm={6} md>
								<TextAreaInputField
									label="Special Notes"
									secondaryLabel="optional"
									name="notes"
									placeholder="Any special notes here"
								/>
							</Col>
							<Col sm={6} md>
								<TextAreaInputField
									label="Terms and Conditions"
									secondaryLabel="optional"
									name="terms_and_conditions"
									placeholder="Any terms here"
								/>
							</Col>
						</Grid>
						<Divider sm />
						<GetFieldValue<0 | 1 | boolean> name="confirmed">
							{({ value }) => {
								const selected = isTruthy(value)
								return (
									<Stack
										padding="4"
										bgColor={selected ? "success" : "warning"}
										rounded="lg"
										borderWidth="1"
										gap="4"
									>
										<Text fontWeight="semibold">
											Please cross check all the details and correct it if
											something is not as per rules and regulations.
										</Text>
										<SwitchInputField
											name="confirmed"
											label="I confirm that all the details of this proforma invoice are correct"
										/>
									</Stack>
								)
							}}
						</GetFieldValue>
						<Divider marginY="0" />
						<SubmissionError />
						<Inline gap="4">
							<Button disabled={submitting} type="submit">
								{submitting ? "Please wait..." : "Save Details"}
							</Button>
							<Button disabled={submitting} onClick={() => onCancel()}>
								Cancel
							</Button>
						</Inline>
					</Stack>
				</form>
			)}
		</Form>
	)
}

function SelectPlaceOfSupply({
	currency,
	...props
}: Omit<React.ComponentProps<typeof AsyncSelect>, "fetch"> & {
	currency: string
}) {
	const xhr = useXHR()
	return (
		<AsyncSelect
			{...props}
			cacheKey={`/invoices/locations-of-supply/${currency}`}
			fetch={(q) =>
				invoicesXHR(xhr)
					.getLocationsOfSupply({ q, currency })
					.then((resp) => resp)
			}
		/>
	)
}
