import { useXHR } from "@sembark-travel/xhr"
import { Omit } from "utility-types"
import { TTenantBrand } from "./store"
import {
	AddBankAccountForm,
	bankAccountsXHR,
	BankAccountsTableView,
} from "../BankAccounts"
import {
	Box,
	Button,
	Heading,
	Stack,
	Text,
	Inline,
	Icons,
} from "@sembark-travel/ui/base"
import { Dialog, useDialog } from "@sembark-travel/ui/dialog"
import { showSnackbar } from "@sembark-travel/ui/snackbar"

export function TenantBrandBankAccounts({
	tenantBrand,
	onChange,
}: {
	tenantBrand: TTenantBrand
	onChange: () => unknown
}) {
	const bankAccounts = tenantBrand.bank_accounts || []
	const [isDialogOpen, openDialog, closeDialog] = useDialog()
	return (
		<Stack gap="4">
			<Inline gap="4" justifyContent="between">
				<Stack gap="2">
					<Heading as="h4">Bank Account Details</Heading>
					<Text color="muted">
						Bank account details to be shared with your client in payments
						related communcations
					</Text>
				</Stack>
				{bankAccounts?.length && bankAccounts.length < 4 ? (
					<Box>
						<Button onClick={openDialog} level="tertiary" size="sm">
							<Icons.Plus /> Add More
						</Button>
					</Box>
				) : null}
			</Inline>
			{!bankAccounts?.length ? (
				<Box padding="4" bgColor="default" rounded="lg">
					<Stack gap="2">
						<Text color="muted" fontSize="md">
							No bank account details added.
						</Text>
						<Box>
							<Button onClick={openDialog} status="primary" size="sm">
								Add Bank Account
							</Button>
						</Box>
					</Stack>
				</Box>
			) : (
				<BankAccountsTableView
					bankAccounts={bankAccounts}
					onChange={() => onChange()}
				/>
			)}
			<Dialog
				open={isDialogOpen}
				onClose={closeDialog}
				title="Add Bank Account"
				lg
			>
				<Dialog.Body>
					<AddTenantBrandBankAccount
						tenantBrand={tenantBrand}
						onCancel={closeDialog}
						onSuccess={() => {
							onChange()
							closeDialog()
						}}
					/>
				</Dialog.Body>
			</Dialog>
		</Stack>
	)
}

export function AddTenantBrandBankAccount({
	tenantBrand,
	onSuccess,
	...props
}: Omit<React.ComponentProps<typeof AddBankAccountForm>, "onSubmit"> & {
	tenantBrand: TTenantBrand
	onSuccess: () => void
}) {
	const xhr = useXHR()
	return (
		<AddBankAccountForm
			onSubmit={async ({ accounting_account, ...values }) => {
				const data = await bankAccountsXHR(xhr).storeBankAccount({
					...values,
					accounting_account: accounting_account?.id || null,
					tenant_brand: tenantBrand.id,
				})
				onSuccess()
				showSnackbar("Bank account details added.")
				return data
			}}
			{...props}
		/>
	)
}
