import {
	Badge,
	Box,
	Button,
	Inline,
	Icons,
	Table,
	Component,
	joinAttributes,
	RelativeTime,
} from "@sembark-travel/ui/base"
import { Link, useLocationQuery } from "@sembark-travel/ui/router"
import { ListView, Search, useSearch } from "@sembark-travel/ui/list"
import { utcTimestampToLocalDateString } from "@sembark-travel/datetime-utils"
import { useEffect } from "react"
import { generatePath } from "../router-utils"
import { childrenToQueryParams } from "../Tourists"
import { IQuoteRequest } from "./store"

export function QuoteRequests({
	pageKey = "quote-requests",
	filters,
	title,
	incoming,
}: {
	pageKey?: string
	filters?: Record<string, unknown>
	title?: string
	incoming: boolean
}) {
	const [query, setQuery] = useLocationQuery()
	const [params, setParams] = useSearch(query)
	useEffect(() => {
		setQuery(params)
	}, [params, setQuery])
	return (
		<Search
			initialParams={params}
			onSearch={(params) => setParams(params)}
			title={title}
		>
			<ListView<IQuoteRequest>
				pageKey={pageKey}
				params={params}
				fetch={(xhr, params) =>
					xhr
						.get("/quote-requests", {
							params: {
								...params,
								...(filters || {}),
								incoming: incoming ? 1 : undefined,
								outgoing: !incoming ? 1 : undefined,
							},
						})
						.then((resp) => resp.data)
				}
				onPageChange={(page) => setParams({ ...params, page })}
			>
				{({ items, xhr, refresh }) => (
					<Table
						headers={[incoming ? "From" : "To"]
							.concat(incoming ? ["Trip"] : [])
							.concat(["Status", "Comments", ""])}
						bordered
						striped
						rows={items.map((item) =>
							[
								<Box>
									<Box>
										{incoming ? item.from.short_name : item.to.short_name}
									</Box>
									<Box fontSize="sm" color="muted">
										<RelativeTime timestamp={item.created_at} />
										{incoming && item.created_by ? (
											<Box as="span"> by {item.created_by.name}</Box>
										) : null}
									</Box>
								</Box>,
							]
								.concat(
									incoming
										? [
												<Box>
													{item.created_trip_id ? (
														<Link
															to={generatePath("/trips/:tripId", {
																tripId: item.created_trip_id.toString(),
															})}
															color="accent"
															fontWeight="semibold"
														>
															{item.forTrip.destinations
																.map((l) => l.name)
																.join(", ")}
														</Link>
													) : (
														<Box fontWeight="semibold">
															{item.forTrip.destinations
																.map((l) => l.name)
																.join(", ")}
														</Box>
													)}
													<Box color="muted">
														{joinAttributes(
															`${utcTimestampToLocalDateString(
																item.forTrip.start_date
															)}, ${item.forTrip.days}D`,
															`${item.forTrip.no_of_adults}A${
																item.forTrip.children.length
																	? `, ${childrenToQueryParams(
																			item.forTrip.children
																	  )}`
																	: ""
															}`
														)}
													</Box>
												</Box>,
										  ]
										: []
								)
								.concat([
									<Box>
										{item.accepted_at ? (
											<Badge success>Accepted</Badge>
										) : item.rejected_at ? (
											<Badge warning>Rejected</Badge>
										) : (
											<Badge>Pending</Badge>
										)}
										{item.updated_at && item.updated_at !== item.created_at ? (
											<Box fontSize="sm" color="muted">
												<RelativeTime timestamp={item.updated_at} />
												{item.updated_by ? (
													<Box as="span"> by {item.updated_by.name}</Box>
												) : null}
											</Box>
										) : null}
									</Box>,
									<Box
										whiteSpace="preserveAndWrap"
										maxWidth="sm"
										fontSize="sm"
										color="muted"
									>
										{item.accepted_at && !item.quote_created ? (
											<Box color="warning" marginBottom="2" fontSize="base">
												<Icons.Attention /> Quote creation in progress.
											</Box>
										) : null}
										{item.rejection_reason ? (
											<Box
												color="warning"
												marginBottom="2"
												title="Rejection reason"
											>
												<Box></Box>
												{item.rejection_reason}
											</Box>
										) : null}
										{item.comments}
									</Box>,
									<Box>
										{incoming ? (
											<Box>
												{!item.accepted_at && !item.rejected_at ? (
													<Component initialState={false}>
														{({ state: wait, setState }) => (
															<Inline gap="2">
																<Button
																	status="primary"
																	title="Accept Request"
																	disabled={wait}
																	onClick={async () => {
																		if (
																			window.confirm(
																				"Are you sure you want to accept this package request ?"
																			)
																		) {
																			setState(true)
																			try {
																				await xhr.post(
																					`/quote-requests/accept`,
																					{ requests: [item.id] }
																				)
																				refresh()
																			} catch (e) {
																				const error = e as Error
																				window.alert(error.message)
																			}
																			setState(false)
																		}
																	}}
																>
																	<Icons.Ok />
																</Button>
																<Button
																	status="warning"
																	title="Reject Request"
																	disabled={wait}
																	level="tertiary"
																	onClick={async () => {
																		const reason = window.prompt(
																			"Please provide a reason to reject the request"
																		)
																		if (reason?.trim().length) {
																			setState(true)
																			try {
																				await xhr.post(
																					`/quote-requests/${item.id}/reject`,
																					{
																						comments: reason,
																					}
																				)
																				refresh()
																			} catch (e) {
																				const error = e as Error
																				window.alert(error.message)
																			}
																			setState(false)
																		}
																	}}
																>
																	<Icons.Cancel />
																</Button>
															</Inline>
														)}
													</Component>
												) : null}
											</Box>
										) : (
											<Box>
												{!item.accepted_at && !item.rejected_at ? (
													<Component initialState={false}>
														{({ state: wait, setState }) => (
															<Inline gap="4">
																<Button
																	status="warning"
																	title="Delete Request"
																	level="tertiary"
																	disabled={wait}
																	onClick={async () => {
																		if (
																			window.confirm(
																				"Are you sure you want to delete this request ?"
																			)
																		) {
																			setState(true)
																			try {
																				await xhr.delete(
																					`/quote-requests/${item.id}`
																				)
																				refresh()
																			} catch (e) {
																				const error = e as Error
																				window.alert(error.message)
																			}
																			setState(false)
																		}
																	}}
																>
																	<Icons.Trash />
																</Button>
															</Inline>
														)}
													</Component>
												) : null}
											</Box>
										)}
									</Box>,
								])
						)}
					/>
				)}
			</ListView>
		</Search>
	)
}
