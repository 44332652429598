import { Table } from "@sembark-travel/ui/base"
import { ListView, Search, useSearch } from "@sembark-travel/ui/list"
import { useLocationQuery } from "@sembark-travel/ui/router"
import { useEffect } from "react"
import { TWorldTimezone, worldXHR } from "../../World"

export function TimezonesList({ countryId }: { countryId?: number | string }) {
	const [query, setQuery] = useLocationQuery()
	const [searchParams, setSearchParams] = useSearch(query)
	useEffect(() => {
		setQuery(searchParams)
	}, [searchParams, setQuery])
	return (
		<Search
			title="Timezones"
			initialParams={searchParams}
			onSearch={(params) => setSearchParams(params)}
		>
			{() => (
				<ListView<TWorldTimezone>
					pageKey="admin/world/timezones"
					params={searchParams}
					onPageChange={(page) => setSearchParams({ ...searchParams, page })}
					fetch={(xhr, params) =>
						worldXHR(xhr).getTimezones({
							country_id: countryId || null,
							...params,
							include: "country",
						})
					}
				>
					{({ items }) => (
						<Table
							headers={["Name", "Country"]}
							responsive
							hover
							bordered
							rows={items.map((item) => [item.name, item.country?.name])}
						/>
					)}
				</ListView>
			)}
		</Search>
	)
}
