import {
	Button,
	Inline,
	Card,
	CardBody,
	CardFooter,
	CardHeader,
} from "@sembark-travel/ui/base"
import { useXHR } from "@sembark-travel/xhr"
import * as Validator from "yup"
import { IRole } from "./store"
import {
	Form,
	validateFormValues,
	SubmissionError,
	withServerErrors,
	TextInputField,
} from "@sembark-travel/ui/form"

export interface NewRoleCredentials {
	name: string
}
const newRoleSchema = Validator.object().shape({
	name: Validator.string()
		.required("Name is required")
		.min(4, "Minimum 4 characters required")
		.max(199, "Maximum 199 characters allowed"),
})
const initialValues = {
	name: "",
}

export function NewRoleForm({
	onSuccess,
	onCancel,
}: {
	onSuccess: (role: IRole) => void
	onCancel?: () => void
}) {
	const xhr = useXHR()
	return (
		<Form<NewRoleCredentials>
			initialValues={initialValues}
			validationSchema={validateFormValues(newRoleSchema)}
			onSubmit={withServerErrors(async (values) => {
				const { data } = await xhr.post("/roles", values)
				const { data: role } = data
				onSuccess(role)
			})}
			subscription={{ submitting: true }}
		>
			{({ submitting, handleSubmit }) => (
				<form noValidate onSubmit={handleSubmit}>
					<Card>
						<CardHeader>Add New Role</CardHeader>
						<CardBody>
							<SubmissionError />
							<TextInputField
								type="text"
								label="Name"
								name="name"
								required
								placeholder="Manager"
							/>
						</CardBody>
						<CardFooter>
							<Inline gap="4">
								<Button type="submit" disabled={submitting}>
									{submitting ? "Creating..." : "Create Role"}
								</Button>
								{onCancel ? (
									<Button onClick={onCancel} disabled={submitting}>
										Cancel
									</Button>
								) : null}
							</Inline>
						</CardFooter>
					</Card>
				</form>
			)}
		</Form>
	)
}
