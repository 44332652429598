import {
	Box,
	Button,
	Inline,
	Card,
	CardBody,
	CardFooter,
	CardHeader,
	Spinner,
} from "@sembark-travel/ui/base"
import { useXHR } from "@sembark-travel/xhr"
import { Fragment } from "react"
import { Helmet } from "react-helmet-async"
import { SelectPermissions } from "./List"
import { IRole, useRole } from "./store"
import {
	Form,
	SelectField,
	withServerErrors,
	SubmissionError,
} from "@sembark-travel/ui/form"

interface EditPermissionsProps {
	roleId: number | string
	onSuccess: (role: IRole) => void
	onCancel?: () => void
}

export function EditRolePermissionsFrom({
	roleId,
	onSuccess,
	onCancel,
}: EditPermissionsProps) {
	const { role, isFetching } = useRole(roleId.toString())
	const xhr = useXHR()
	if (isFetching) return <Spinner alignCenter padding="4" />
	if (!role) {
		return <Box>Role does not exist</Box>
	}
	const { name, permissions = [] } = role
	const initialValues = {
		permissions,
	}
	type EditPermissionsCredentials = typeof initialValues
	return (
		<Fragment>
			<Helmet>
				<title>Edit {name}'s permissions</title>
			</Helmet>
			<Form<EditPermissionsCredentials>
				initialValues={initialValues}
				onSubmit={withServerErrors(async (values) => {
					await xhr.post(`/roles/${roleId}/permissions`, {
						permissions: values.permissions.map(({ name }) => ({
							name,
						})),
					})
					onSuccess(role)
				})}
				subscription={{ submitting: true }}
			>
				{({ submitting, handleSubmit }) => (
					<form noValidate onSubmit={handleSubmit}>
						<Card>
							<CardHeader>Editing {name}'s permissions</CardHeader>
							<CardBody>
								<SubmissionError />
								<SelectField
									select={SelectPermissions}
									name="permissions"
									label="Select permission(s)"
									multiple
								/>
							</CardBody>
							<CardFooter>
								<Inline gap="4">
									<Button type="submit" disabled={submitting}>
										{submitting ? "Updating..." : "Save Permissions"}
									</Button>
									{onCancel ? (
										<Button onClick={onCancel} disabled={submitting}>
											Cancel
										</Button>
									) : null}
								</Inline>
							</CardFooter>
						</Card>
					</form>
				)}
			</Form>
		</Fragment>
	)
}
