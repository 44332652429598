import {
	Box,
	Button,
	Inline,
	Stack,
	Heading,
	Text,
} from "@sembark-travel/ui/base"
import { Dialog } from "@sembark-travel/ui/dialog"
import {
	dateToUTCString,
	utcTimeToLocalTimeString,
	startOf,
	addUnit,
} from "@sembark-travel/datetime-utils"
import { Col, Component, Divider, Grid } from "@sembark-travel/ui/base"
import {
	Form,
	FieldArray,
	TextInputField,
	TextAreaInputField,
	SelectField,
	DatePickerField,
	validateFormValues,
	SubmissionError,
	withServerErrors,
	GetFieldValue,
	arrayMutators,
	EmptyNumberValidator,
} from "@sembark-travel/ui/form"
import * as Validator from "yup"
import { AddressText } from "../Addresses"
import { SelectHotelExtraServices } from "../ExtraServices"
import { SelectHotels, UpdateHotel } from "../Hotels"
import { type IHotel } from "../Hotels"
import { SelectMealPlans, type IMealPlan } from "../MealPlans"
import { SelectRoomTypes, type IRoomType } from "../RoomTypes"
import { ChildrenInputField, type TChildrenArray } from "../Tourists"
import { type IHotelVoucherExtraService } from "./store"
import config from "../config"
import { Required } from "utility-types"

export interface IData {
	guest_name: string
	no_of_adults: number
	children: TChildrenArray
	hotel?: IHotel
	checkin: string | Date
	checkout: string | Date
	meal_plan?: IMealPlan
	room_type?: IRoomType
	no_of_rooms?: number
	extra_services?: Array<Pick<IHotelVoucherExtraService, "service" | "date">>
	booking_confirmation_id?: string
	notes?: string
	reference_id?: string
	timezone_offset?: number
}

const INITIAL_DATA: Required<IData, "extra_services"> = {
	guest_name: "",
	no_of_adults: 2,
	children: [],
	hotel: undefined,
	checkin: new Date(),
	checkout: addUnit(new Date(), 1, "day"),
	meal_plan: undefined,
	room_type: undefined,
	no_of_rooms: undefined,
	extra_services: [],
	booking_confirmation_id: "",
	notes: "",
	timezone_offset: config.timezoneOffset,
}

export function NewItemForm({
	onSubmit,
	onCancel,
	initialValues = INITIAL_DATA,
}: {
	onSubmit: (data: unknown) => Promise<unknown>
	onCancel?: () => void
	initialValues?: IData
}) {
	return (
		<Form<IData>
			initialValues={{
				...INITIAL_DATA,
				...initialValues,
			}}
			validate={validate}
			mutators={{ ...arrayMutators }}
			onSubmit={withServerErrors(async (values) => {
				const {
					hotel,
					checkin,
					checkout,
					meal_plan,
					room_type,
					extra_services,
					...otherData
				} = values
				const formattedData = {
					...otherData,
					hotel_id: hotel?.id,
					checkin: checkin
						? dateToUTCString(startOf(checkin, "day"))
						: undefined,
					checkout: checkout
						? dateToUTCString(startOf(checkout, "day"))
						: undefined,
					meal_plan_id: meal_plan?.id,
					room_type: room_type?.name,
					extra_services: extra_services?.map(({ date, service, ...data }) => ({
						...data,
						date: date ? dateToUTCString(startOf(date, "day")) : undefined,
						service: service.name,
					})),
				}
				await onSubmit(formattedData)
			})}
			subscription={{ submitting: true }}
		>
			{({ submitting, form, handleSubmit }) => (
				<form noValidate onSubmit={handleSubmit}>
					<Grid gap="4">
						<Col sm={12} md={3}>
							<Stack gap="1">
								<Heading>Guest Details</Heading>
								<Text color="muted">
									Provide guest details with number of adults and children with
									ages.
								</Text>
							</Stack>
						</Col>
						<Col>
							<Grid gap="4">
								<Col xs={12} sm="auto">
									<TextInputField
										label="Guest Name"
										name="guest_name"
										type="text"
										placeholder="Sem Das"
									/>
								</Col>
								<Col xs={12} sm="auto">
									<TextInputField
										name="no_of_adults"
										type="number"
										min={0}
										max={10000}
										label="No of Adults"
									/>
								</Col>
								<Col xs={12} sm="auto">
									<ChildrenInputField name="children" />
								</Col>
							</Grid>
						</Col>
					</Grid>
					<Divider marginY="8" />
					<Grid gap="4">
						<Col sm={12} md={3}>
							<Stack gap="1">
								<Heading>Hotel and Services</Heading>
								<Text color="muted">
									Please provide hotel details with the checkin-checkout and
									booked services.
								</Text>
							</Stack>
						</Col>
						<Col>
							<Grid gap="4">
								<Col xs={12} md={4}>
									<SelectField
										label="Hotel Name"
										name="hotel"
										select={SelectHotels}
										multiple={false}
										creatable
										required
										placeholder="Select or Add..."
									/>
								</Col>
								<Col xs={12} sm="auto">
									<DatePickerField label="Checkin" name="checkin" required />
								</Col>
								<Col xs={12} sm="auto">
									<DatePickerField label="Checkout" name="checkout" required />
								</Col>
							</Grid>
							<GetFieldValue<IData["hotel"]> name="hotel">
								{({ value: hotel }) =>
									hotel ? (
										<Box marginY="4">
											<Box as="p" color="muted">
												Please verify hotel details that will be used in
												voucher. Edit if required.
											</Box>
											<Grid gap="4">
												<Col xs={12} md={4}>
													<Box marginBottom="4">
														<Box
															textTransform="uppercase"
															letterSpacing="wider"
															marginBottom="1"
															fontWeight="semibold"
															color="muted"
														>
															Hotel Address
														</Box>
														<Box>
															<AddressText address={hotel.address} />
														</Box>
													</Box>
												</Col>
												<Col xs={12} sm="auto">
													<Box marginBottom="4">
														<Box
															textTransform="uppercase"
															letterSpacing="wider"
															marginBottom="1"
															fontWeight="semibold"
															color="muted"
														>
															Checkin Time
														</Box>
														{utcTimeToLocalTimeString(hotel.checkin_at)}
													</Box>
												</Col>
												<Col xs={12} sm="auto">
													<Box marginBottom="4">
														<Box
															textTransform="uppercase"
															letterSpacing="wider"
															marginBottom="1"
															fontWeight="semibold"
															color="muted"
														>
															Checkout Time
														</Box>
														{utcTimeToLocalTimeString(hotel.checkout_at)}
													</Box>
												</Col>
											</Grid>
											<Component initialState={false}>
												{({ state, setState }) => (
													<>
														<Button onClick={() => setState(true)}>
															Edit Hotel details
														</Button>
														<Dialog
															open={state}
															onClose={() => setState(false)}
															lg
														>
															<Dialog.Header>
																<Dialog.Title>Edit Hotel Details</Dialog.Title>
															</Dialog.Header>
															<Dialog.Body>
																{hotel ? (
																	<UpdateHotel
																		hotel={hotel}
																		showFullAddress
																		onCancel={() => setState(false)}
																		onSuccess={(hotel) => {
																			form.change("hotel", hotel)
																			setState(false)
																		}}
																	/>
																) : null}
															</Dialog.Body>
														</Dialog>
													</>
												)}
											</Component>
										</Box>
									) : null
								}
							</GetFieldValue>
							<hr />
							<Stack marginTop="8" gap="4">
								<Stack gap="2">
									<Heading as="h4" fontSize="md">
										Service Details
									</Heading>
									<Text color="muted">
										Please provide booked services like meal plan and rooms.
										Skip these if only extra services are provided
									</Text>
								</Stack>
								<Grid gap="4">
									<Col>
										<SelectField
											label="Meal Plan"
											name="meal_plan"
											fetchOnMount
											select={SelectMealPlans}
											multiple={false}
											placeholder="Select..."
										/>
									</Col>
									<Col>
										<SelectField
											label="Room Type"
											name="room_type"
											fetchOnMount
											select={SelectRoomTypes}
											multiple={false}
											creatable
											placeholder="Select or Add..."
										/>
									</Col>
									<Col>
										<TextInputField
											label="Number of Rooms"
											name="no_of_rooms"
											type="number"
											placeholder="1"
											min={1}
											max={10000}
										/>
									</Col>
								</Grid>
							</Stack>
							<hr />
							<Stack marginTop="8" gap="4">
								<Stack gap="2">
									<Heading as="h4" fontSize="md">
										Any Extra Services
									</Heading>
									<Text color="muted">
										Please provide details regarding extra services e.g.
										Honeymoon cake, Special dinner etc if included in the
										booking.
									</Text>
								</Stack>
								<FieldArray<
									Required<IData, "extra_services">["extra_services"][number]
								> name="extra_services">
									{({ fields }) => (
										<Stack gap="4">
											{fields.map((name, i) => (
												<Box key={name}>
													<Grid gap="4">
														<Col>
															<SelectField
																label="Service"
																name={`${name}.service`}
																select={SelectHotelExtraServices}
																multiple={false}
																creatable
																fetchOnMount
																required
																placeholder="Select or Add..."
															/>
														</Col>
														<Col>
															<DatePickerField
																label="Date"
																secondaryLabel="optional"
																name={`${name}.date`}
															/>
														</Col>
														<Col paddingTop="6">
															<Button
																onClick={() => fields.remove(i)}
																size="sm"
															>
																Remove
															</Button>
														</Col>
													</Grid>
												</Box>
											))}
											<GetFieldValue<
												IData["extra_services"]
											> name="extra_services">
												{({ value: extra_services }) => (
													<Box>
														<Button
															onClick={() =>
																fields.push({
																	service: undefined as never,
																	date: "",
																})
															}
															size="sm"
														>
															{extra_services?.length ? "Add More" : "Add"}
														</Button>
													</Box>
												)}
											</GetFieldValue>
										</Stack>
									)}
								</FieldArray>
							</Stack>
						</Col>
					</Grid>
					<hr />
					<Grid gap="4">
						<Col sm={12} md={3}>
							<Stack gap="1">
								<Heading>Booking Details</Heading>
								<Text color="muted">
									Please provides details regarding booking confirmation or any
									notes to be included in voucher.
								</Text>
							</Stack>
						</Col>
						<Col>
							<Stack gap="4">
								<Box maxWidth="sm">
									<TextInputField
										label="Booking Confirmation Id"
										secondaryLabel="optional"
										name="booking_confirmation_id"
										placeholder="GYPS322"
										help="Our reference number will be auto generated"
									/>
								</Box>
								<TextAreaInputField
									label="Any Notes"
									secondaryLabel="optional"
									name="notes"
								/>
							</Stack>
						</Col>
					</Grid>
					<Divider sm />
					<Stack gap="4">
						<SubmissionError />
						<Inline gap="4">
							<Button type="submit" disabled={submitting}>
								{submitting ? "Creating..." : "Create Voucher"}
							</Button>
							{onCancel ? (
								<Button onClick={onCancel} disabled={submitting}>
									Cancel
								</Button>
							) : null}
						</Inline>
					</Stack>
				</form>
			)}
		</Form>
	)
}

const validationSchema = Validator.object().shape({
	guest_name: Validator.string().required("Please provide the guest's name"),
	no_of_adults: EmptyNumberValidator()
		.typeError("Number of adults should be a positive integer e.g. 1,2,3")
		.positive("Number of adults should be a positive integer e.g. 1,2,3")
		.integer("Number of adults should be a positive integer e.g. 1,2,3"),
	children: Validator.array(),
	hotel: Validator.object().required("Please select/create hotel details"),
	checkin: Validator.date().required("Please select a date of checkin"),
	checkout: Validator.date().required("Please select a date of checkout"),
	meal_plan: Validator.object().when(
		"extra_services",
		(value: Array<string>, schema: Validator.AnyObjectSchema) => {
			if (value && value.length) {
				return schema
			}
			return schema.required(
				"Meals/Rooms are required if extra services are not provided"
			)
		}
	),
	room_type: Validator.object().when(
		"meal_plan",
		(value: Record<string, string>, schema: Validator.AnyObjectSchema) => {
			if (!value) return schema
			return schema.required("Room type is required")
		}
	),
	no_of_rooms: EmptyNumberValidator()
		.typeError("Number of rooms should be a positive number e.g. 1,2,3")
		.when(
			"room_type",
			(
				value: Record<string, string>,
				schema: Validator.NumberSchema<number | undefined | null>
			) => {
				if (!value) return schema
				return schema
					.required("Number of rooms should be a positive number e.g. 1,2,3")
					.positive("Number of rooms should be a positive number e.g. 1,2,3")
					.integer("Number of rooms should be a positive number e.g. 1,2,3")
			}
		),
	extra_services: Validator.array().of(
		Validator.object().shape({
			service: Validator.object().required(
				"Please a select a service or remove it"
			),
			date: Validator.date().nullable(),
		})
	),
})

const validate = validateFormValues(validationSchema)
