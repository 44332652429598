import { Helmet } from "react-helmet-async"
import { TripDestinationsList } from "../../../../TripDestinations"

export default function OrganizationTripDestinationsPage() {
	return (
		<>
			<Helmet>
				<title>Trip Destinations</title>
			</Helmet>
			<TripDestinationsList />
		</>
	)
}
