import {
	Table,
	Icons,
	Stack,
	Text,
	Money,
	Box,
	Dropdown,
	joinAttributes,
	RelativeTime,
} from "@sembark-travel/ui/base"
import {
	CursorListView,
	Search,
	TSearchParams,
	useSearch,
} from "@sembark-travel/ui/list"
import { Link, useLocationQuery, XHRLink } from "@sembark-travel/ui/router"
import { useEffect } from "react"
import config from "../config"
import { PhoneNumber, Email } from "../Contacts"
import { generatePath } from "../router-utils"
import { TTourist } from "./store"
import { ILocation, SelectLocations } from "../Locations"
import {
	dateToQuery,
	dateToUTCString,
	endOf,
	parseDateFromQuery,
	startOf,
} from "@sembark-travel/datetime-utils"
import { DateRangePickerField, SelectField } from "@sembark-travel/ui/form"

type TFilters = TSearchParams & {
	created_after?: Date
	created_before?: Date
	locations?: Array<ILocation>
}

type TFiltersInQuery = TSearchParams & {
	ca?: string
	cb?: string
	locations?: Array<string>
}

function filtersToQuery(filters: TFilters): TFiltersInQuery {
	const { q, page, created_after, created_before, locations } = filters
	const query: TFiltersInQuery = {}
	if (page) {
		query.page = page
	}
	if (q) {
		query.q = q
	}
	if (created_after) {
		query.ca = dateToQuery(created_after)
	}
	if (created_before) {
		query.cb = dateToQuery(created_before)
	}
	if (locations?.length) {
		query.locations = locations.map((l) => `${l.id}__${l.full_name}`)
	}
	return query
}

function queryToFilters(query: TFiltersInQuery): TFilters {
	const { q, page, ca, cb, locations } = query
	const filters: TFilters = {}
	if (page) {
		filters.page = page
	}
	if (q) {
		filters.q = q
	}
	if (ca) {
		filters.created_after = parseDateFromQuery(ca)
	}
	if (cb) {
		filters.created_before = parseDateFromQuery(cb)
	}
	if (locations?.length) {
		filters.locations = locations.map((l) => {
			const [id, ...name] = l.split("__")
			return {
				id,
				full_name: name.join("__"),
			} as never as ILocation
		})
	}
	return filters
}

function filtersToRequest({
	created_after,
	created_before,
	locations,
	...params
}: TFilters) {
	return {
		...params,
		created_after: created_after
			? dateToUTCString(startOf(created_after, "day"))
			: null,
		created_before: created_before
			? dateToUTCString(endOf(created_before, "day"))
			: null,
		locations: locations?.length ? locations.map((l) => l.id) : [],
	}
}

export function TouristsList() {
	const [query, setQuery] = useLocationQuery<TFilters, TFiltersInQuery>({
		toQuery: filtersToQuery,
		fromQuery: queryToFilters,
	})
	const [params, setParams] = useSearch<TFilters>(query)
	useEffect(() => {
		setQuery(params)
	}, [params, setQuery])
	return (
		<Search
			initialParams={params}
			title="Tourists"
			onSearch={(params) => setParams({ ...params, cursor: null })}
			Filters={Filters}
			actions={
				<Dropdown alignRight="sm">
					<Dropdown.ToggleButton>
						<Icons.DotsVertical />
					</Dropdown.ToggleButton>
					<Dropdown.Menu>
						<Dropdown.MenuItem
							as={XHRLink}
							href="/tourists/download"
							title="Download as CSV/Excel"
							query={{
								...filtersToRequest(params),
								timezone_offset: config.timezoneOffset,
							}}
							download
						>
							<Icons.DocumentDownload /> Download as CSV/Excel
						</Dropdown.MenuItem>
					</Dropdown.Menu>
				</Dropdown>
			}
		>
			<CursorListView<TTourist, TFilters>
				pageKey="tourists-list"
				params={params}
				fetch={(xhr, params) =>
					xhr
						.get(`/tourists`, {
							params: {
								...filtersToRequest(params),
								include: "latest_given_quote,address",
							},
						})
						.then((resp) => resp.data)
				}
				onCursorChange={(cursor) => setParams({ ...params, cursor })}
			>
				{({ items }) => (
					<Table
						bordered
						responsive
						striped
						headers={["Name", "Contact", "Destinations", "Quotation"]}
						rows={items.map(
							({
								trip_id,
								name,
								phone_numbers,
								email,
								address,
								created_at,
								trip,
							}) => [
								<Link
									color="accent"
									fontWeight="semibold"
									to={generatePath("/trips/:tripId", {
										tripId: trip_id.toString(),
									})}
								>
									<Stack>
										<Text>{name}</Text>
										<Text fontSize="sm">
											{joinAttributes(
												`# ${trip_id}`,
												address ? address.location.name : "",
												<RelativeTime timestamp={created_at} />
											)}
										</Text>
									</Stack>
								</Link>,
								<Stack gap="px">
									<Box>
										<PhoneNumber value={phone_numbers} />
									</Box>
									{email ? (
										<Box>
											<Email value={email} />
										</Box>
									) : null}
								</Stack>,
								<Box>
									{trip?.destinations?.map((d) => d.name).join(", ") || null}
								</Box>,
								<Stack gap="px">
									{trip?.latest_given_quote ? (
										<Box>
											<Money
												amount={trip.latest_given_quote.given_price}
												currency={trip.latest_given_quote.given_currency}
											/>
										</Box>
									) : null}
									{trip?.current_status ? (
										<Text fontSize="sm" color="muted">
											{trip.current_status}
										</Text>
									) : null}
								</Stack>,
							]
						)}
					/>
				)}
			</CursorListView>
		</Search>
	)
}

function Filters() {
	return (
		<Stack gap="4">
			<DateRangePickerField
				fromName="created_after"
				toName="created_before"
				label="Created Between"
				dateFormat="DD MMM, YYYY"
			/>
			<SelectField
				select={SelectLocations}
				name="locations"
				multiple
				label="Cities/Locations"
				creatable={false}
				addressableType="tourists"
			/>
		</Stack>
	)
}
