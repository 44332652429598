import { Box, Card, CardBody } from "@sembark-travel/ui/base"
import { Link, queryToSearch, searchToQuery } from "@sembark-travel/ui/router"
import { Helmet } from "react-helmet-async"
import { ConnectionTenantSignupForm } from "../../Auth"
import Logo from "../../images/Logo"
import { generatePath, useLocation, useNavigate } from "../../router-utils"

export default function ConnectionTenantSignupPage() {
	const location = useLocation()
	const navigate = useNavigate()
	const query = searchToQuery<{
		ref: string
		email: string
		name: string
		"tenant-name": string
		"short-name": string
		ftn: string
		fun: string
		cid: string
	}>(location && location.search)
	const invite_token = query["ref"] || ""
	const email = query["email"] || ""
	const name = query["name"] || ""
	const tenantName = query["tenant-name"] || ""
	const shortName = query["short-name"] || ""
	const fromTenantName = query["ftn"] || ""
	const fromUserName = query["fun"] || ""
	const connectionId = query["cid"] || ""
	return (
		<>
			<Helmet>
				<title>Accept Connection Request</title>
			</Helmet>
			<Box paddingY="8">
				<Box textAlign="center" marginBottom="16">
					<Box>
						<Box as={Logo} marginBottom="2" display="inline" />
						<Box as="h1" fontSize="xl" fontWeight="semibold" margin="0">
							Register and Connect on Sembark
						</Box>
						<Box as="p" marginTop="4" marginBottom="0">
							<b>{fromUserName}</b> from <b>{fromTenantName}</b> wants to
							connect with you to share packages and bookings on Sembark.
						</Box>
					</Box>
				</Box>
				<Box maxWidth="4xl" marginX="auto">
					<Card>
						<CardBody paddingX="8" paddingY="8">
							<ConnectionTenantSignupForm
								companyName={tenantName}
								companyShortName={shortName}
								inviteToken={invite_token}
								name={name}
								email={email}
								addressEmail={email}
								connectionId={connectionId}
								emailVerifiedUrl={`${window.location.origin}${generatePath(
									"/email-verified"
								)}`}
								onSucess={(values) => {
									navigate(
										{
											pathname: "/login",
											search: queryToSearch({
												email: values.email,
												next: generatePath("/onboarding"),
											}),
										},
										{ replace: true }
									)
								}}
							/>
						</CardBody>
					</Card>
				</Box>
				<Box marginTop="8" textAlign="center" fontSize="sm">
					<Box as="b">Registered user?</Box>{" "}
					<Link to={generatePath("/")} color="accent">
						Visit your dashboard
					</Link>
					. OR <a href="mailto:contact@sembark.com">contact us</a> if you are
					having facing issues.
				</Box>
			</Box>
		</>
	)
}
