import { Helmet } from "react-helmet-async"
import { AirportsList } from "../../../../Admin/World"

export default function AirportsListPage() {
	return (
		<>
			<Helmet>
				<title>Airports</title>
			</Helmet>
			<AirportsList />
		</>
	)
}
