import { TChildrenArray } from "../Tourists"
import { TTripDestination } from "../TripDestinations"
import { TTripOperationalBooking } from "./store"
import {
	DateswiseTransportAndActivityServicesInputFieldArray,
	TDaywiseTransportsAndActivitiesInputFieldValue,
	TValidDaywiseTransportsAndActivitiesInputFieldValue,
	createBookingDateSelectionOptionsFromStartAndEndDates,
	getValidDaywiseTransportsAndActivitiesData,
	groupAndNormalizePriceForDateswiseServicesByDate,
} from "./../TransportServicePrices"
import {
	Form,
	withServerErrors,
	arrayMutators,
	addKeyToFieldArrayItem,
	SubmissionError,
} from "@sembark-travel/ui/form"
import { useMemo, useState } from "react"
import {
	addUnit,
	clone,
	createDuration,
	dateToUTCString,
	endOf,
	formatDate,
	startOf,
	utcTimestampToLocalDate,
} from "@sembark-travel/datetime-utils"
import {
	Button,
	Divider,
	Inline,
	Stack,
	Grid,
	Col,
	Box,
	Icons,
	Heading,
	Text,
	Time,
} from "@sembark-travel/ui/base"
import { Dialog, useDialog } from "@sembark-travel/ui/dialog"
import { useXHR } from "@sembark-travel/xhr"
import { getStartEndDateTimesFromDateAndSlot } from "../TravelActivityBookings"
import { useHasFeatureFlag } from "../Auth"

export function AddOperationalBookingsForTripInDialog({
	children,
	onSuccess,
	...props
}: Omit<
	React.ComponentProps<typeof AddOperationalBookingsForTrip>,
	"onCancel"
> & {
	children: (props: { onAdd: () => void }) => React.ReactNode
}) {
	const [isOpen, openDialog, closeDialog] = useDialog()
	return (
		<>
			{children({ onAdd: openDialog })}
			<Dialog
				open={isOpen}
				onClose={closeDialog}
				title="Add Operational Bookings"
				fitContainer
			>
				<Dialog.Body>
					<TripOverview trip={props.trip} />
					<Divider />
					<AddOperationalBookingsForTrip
						{...props}
						onCancel={closeDialog}
						onSuccess={(...args) => {
							closeDialog()
							onSuccess(...args)
						}}
					/>
				</Dialog.Body>
			</Dialog>
		</>
	)
}

export function AddOperationalBookingsForTrip({
	trip,
	onCancel,
	onSuccess,
	forDate,
}: {
	trip: Pick<
		TTripOperationalBooking,
		| "id"
		| "start_date"
		| "end_date"
		| "days"
		| "no_of_adults"
		| "children"
		| "destinations"
		| "pax_string"
	>
	onCancel: () => void
	onSuccess: () => void
	forDate?: Date
}) {
	const startDate = useMemo(() => {
		return utcTimestampToLocalDate(trip.start_date).toISOString()
	}, [trip.start_date])
	const endDate = useMemo(() => {
		return endOf(addUnit(startDate, trip.days - 1, "days"), "day").toISOString()
	}, [startDate, trip.days])
	const xhr = useXHR()
	return (
		<AddOperationalBookingsForm
			startDate={startDate}
			endDate={endDate}
			defaultDate={forDate}
			noOfAdults={trip.no_of_adults}
			children={trip.children}
			tripDestinations={trip.destinations}
			onCancel={onCancel}
			onSubmit={async (data) => {
				const normalizedData = groupAndNormalizePriceForDateswiseServicesByDate(
					data.dates_wise_services
				)
				await xhr.patch(`/trip-operational-bookings/${trip.id}`, {
					data: normalizedData.flatMap(({ date, services }) => {
						date = startOf(date, "day")
						return services.map((s) => {
							if (s.type === "transport_service") {
								const {
									sort_order,
									group_id,
									transport_service,
									transport_service_location,
									cabs,
								} = s.service
								const data = getStartEndDateTimesFromDateAndSlot(
									date,
									transport_service.start_time,
									transport_service.duration
										? createDuration(
												transport_service.duration,
												"minutes"
											).toISOString()
										: undefined
								)
								const { start_time, end_time } = data
								let { start_date, end_date } = data
								if (!start_time) {
									start_date = addUnit(start_date, 1, "minutes")
									end_date = clone(start_date)
								}
								return {
									type: "cab_schedule",
									cab_schedule: {
										sort_order,
										group_id,
										start_date: dateToUTCString(start_date),
										start_date_local: formatDate(start_date, "YYYY-MM-DD"),
										start_time_local: start_time,
										end_date: dateToUTCString(end_date),
										end_date_local: formatDate(end_date, "YYYY-MM-DD"),
										end_time_local: end_time,
										transport_service_id: transport_service.id,
										transport_service_location_id:
											transport_service_location.id,
										cabs: cabs.map(
											({
												cab_type,
												no_of_cabs,
												currency,
												calculated_price,
												per_quantity_given_price,
												given_price,
												per_quantity_booked_price,
												booked_price,
											}) => ({
												no_of_cabs,
												cab_type: cab_type.name,
												currency,
												calculated_price,
												per_quantity_given_price,
												given_price,
												per_quantity_booked_price,
												booked_price,
											})
										),
									},
								}
							}

							const {
								activity,
								ticket_type,
								slot,
								ticket_tourist_configurations,
								sort_order,
								group_id,
								comments,
							} = s.service

							const { start_date, end_date, start_time, end_time, duration } =
								getStartEndDateTimesFromDateAndSlot(
									date,
									slot,
									s.service.duration?.iso
								)
							return {
								type: "travel_activity_booking",
								travel_activity_booking: {
									sort_order,
									group_id,
									start_date: dateToUTCString(start_date),
									end_date: dateToUTCString(end_date),
									start_date_local: formatDate(start_date, "YYYY-MM-DD"),
									start_time_local: start_time,
									end_date_local: formatDate(end_date, "YYYY-MM-DD"),
									end_time_local: end_time,
									duration: duration,
									activity_id: activity.id,
									ticket_type_id: ticket_type?.id,
									comments,
									ticket_tourist_configurations:
										ticket_tourist_configurations.map(
											({
												configuration,
												quantity,
												currency,
												per_quantity_given_price,
												calculated_price,
												given_price,
												per_quantity_booked_price,
												booked_price,
											}) => ({
												configuration_id: configuration.id,
												quantity,
												currency,
												calculated_price,
												per_quantity_given_price,
												given_price,
												per_quantity_booked_price,
												booked_price,
											})
										),
								},
							}
						})
					}),
				})
				onSuccess()
			}}
		/>
	)
}

type TFormParams = {
	dates_wise_services?: TDaywiseTransportsAndActivitiesInputFieldValue
}

type TValidFormParams = {
	dates_wise_services: TValidDaywiseTransportsAndActivitiesInputFieldValue
}

function AddOperationalBookingsForm({
	startDate,
	endDate,
	tripDestinations,
	noOfAdults,
	children,
	onSubmit,
	onCancel,
	defaultDate,
}: {
	startDate: string
	endDate: string
	noOfAdults: number
	children: TChildrenArray
	tripDestinations: Array<TTripDestination>
	onSubmit: (data: TValidFormParams) => Promise<unknown>
	onCancel: () => void
	defaultDate?: Date
}) {
	const bookingDates = useMemo(
		() =>
			createBookingDateSelectionOptionsFromStartAndEndDates(startDate, endDate),
		[startDate, endDate]
	)
	const currency = useMemo(
		() => tripDestinations.map((d) => d.currency).at(0) || "INR",
		[tripDestinations]
	)
	const hasActivities = useHasFeatureFlag("travel_activities")
	const [DEFAULT_VALUES] = useState<TFormParams>(() => {
		return {
			dates_wise_services: [
				addKeyToFieldArrayItem({
					dates: [],
					services: hasActivities
						? []
						: [
								addKeyToFieldArrayItem({
									type: "transport_service",
									service: {
										transport_service: undefined,
										transport_service_location: undefined,
										cabs: undefined,
									},
								}),
							],
				}),
			],
		}
	})
	const [initialValues] = useState<TFormParams>(() => {
		return {
			dates_wise_services: (DEFAULT_VALUES.dates_wise_services || []).map(
				(s) => ({
					...s,
					dates: defaultDate ? [defaultDate] : [],
				})
			),
		}
	})
	return (
		<Form<TFormParams>
			initialValues={initialValues}
			onSubmit={withServerErrors(async (values) => {
				await onSubmit({
					dates_wise_services: getValidDaywiseTransportsAndActivitiesData(
						values.dates_wise_services
					),
				})
			})}
			subscription={{ submitting: true }}
			mutators={{ ...arrayMutators }}
		>
			{({ handleSubmit, submitting }) => (
				<form onSubmit={handleSubmit} noValidate>
					<DateswiseTransportAndActivityServicesInputFieldArray
						currency={currency}
						name="dates_wise_services"
						noOfAdults={noOfAdults}
						children={children}
						bookingDates={bookingDates}
						tripDestinations={tripDestinations}
						defaultValue={DEFAULT_VALUES["dates_wise_services"]}
						showBookedPrices
						transportServiceTitle="Cab Schedule"
						travelActivityServiceTitle="Travel Activity Booking"
					/>
					<Divider />
					<Stack gap="4">
						<SubmissionError />
						<Inline gap="4">
							<Button type="submit" disabled={submitting}>
								{submitting ? "Saving..." : "Save Details"}
							</Button>
							<Button
								type="button"
								onClick={() => onCancel()}
								disabled={submitting}
							>
								Cancel
							</Button>
						</Inline>
					</Stack>
				</form>
			)}
		</Form>
	)
}

function TripOverview({
	trip,
}: Pick<React.ComponentProps<typeof AddOperationalBookingsForTrip>, "trip">) {
	return (
		<Grid gap="4">
			<Col sm={12} md={3}>
				<Inline gap="2">
					<Box>
						<Icons.Cog opacity="50" size="6" />
					</Box>
					<Stack gap="1">
						<Heading as="h3">Trip Details</Heading>
					</Stack>
				</Inline>
			</Col>
			<Col>
				<Inline gap="6" flexWrap="wrap">
					<Stack gap="px">
						<Text fontSize="sm" color="muted" fontWeight="semibold">
							Trip ID
						</Text>
						<Text>{trip.id}</Text>
					</Stack>
					<Stack gap="px">
						<Text fontSize="sm" color="muted" fontWeight="semibold">
							Destinations
						</Text>
						<Text>{trip.destinations.map((t) => t.name).join(", ")}</Text>
					</Stack>
					<Stack gap="px">
						<Text fontSize="sm" color="muted" fontWeight="semibold">
							Start Date
						</Text>
						<Text>
							<Time timestamp={trip.start_date} />
						</Text>
					</Stack>
					<Stack gap="px">
						<Text fontSize="sm" color="muted" fontWeight="semibold">
							End Date
						</Text>
						<Text>
							<Time timestamp={trip.end_date} />
						</Text>
					</Stack>
					<Stack gap="px">
						<Text fontSize="sm" color="muted" fontWeight="semibold">
							Pax
						</Text>
						<Text>{trip.pax_string}</Text>
					</Stack>
				</Inline>
			</Col>
		</Grid>
	)
}
