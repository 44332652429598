import { useState } from "react"
import { Helmet } from "react-helmet-async"
import { IntegrationItem } from "./../../../../Integrations"
import { useLocation, useParams } from "../../../../router-utils"

export default function IntegrationItemDetailsPage() {
	const location = useLocation()
	const { integrationId } = useParams("/integrations/:integrationId")
	const [lastCreatedToken, setLastCreatedToken] = useState<string | undefined>(
		typeof location.state === "string" ? location.state : undefined
	)
	return (
		<>
			<Helmet>
				<title>Integration Details</title>
			</Helmet>
			<IntegrationItem
				lastCreatedToken={lastCreatedToken}
				integrationId={integrationId}
				setLastCreatedToken={setLastCreatedToken}
			/>
		</>
	)
}
