import {
	Stack,
	Button,
	Inline,
	Divider,
	Grid,
	Col,
	Heading,
	Text,
	Box,
} from "@sembark-travel/ui/base"
import { useXHR } from "@sembark-travel/xhr"
import { useState } from "react"
import { Optional } from "utility-types"
import * as Validator from "yup"
import { TNewIntegrationSchema, TIntegration, XHR } from "./store"
import {
	Form,
	withServerErrors,
	validateFormValues,
	TextInputField,
	SubmissionError,
	EmptyNumberValidator,
} from "@sembark-travel/ui/form"

const newIntegrationSchemaValidator = Validator.object().shape({
	name: Validator.string()
		.required("Please give a name to your integration")
		.max(50, "Please use 50 or fewer characters for the name"),
	description: Validator.string()
		.required("Please provide an description for this integration")
		.max(500, "Please use 500 or fewer characters for the description"),
})

export function NewIntegration({
	onSuccess,
	...props
}: Omit<React.ComponentProps<typeof NewIntegrationForm>, "onSubmit"> & {
	onSuccess: (data: {
		integration: TIntegration
		lastCreatedToken?: string
	}) => void
}) {
	const xhr = useXHR()
	return (
		<NewIntegrationForm
			{...props}
			onSubmit={async (values) => {
				const { data: integration, meta } = await XHR(xhr).store(values)
				onSuccess({ integration, lastCreatedToken: meta?.last_created_token })
			}}
		/>
	)
}

export function NewIntegrationForm({
	onSubmit,
	onCancel,
	initialValues: propInitialValues,
}: {
	initialValues?: Optional<TNewIntegrationSchema>
	onSubmit: (data: TNewIntegrationSchema) => Promise<unknown>
	onCancel: () => void
}) {
	const [newIntegrationInitialValues] = useState<TNewIntegrationSchema>(() => ({
		name: propInitialValues?.name || "",
		description: propInitialValues?.description || "",
		abilities: propInitialValues?.abilities || [],
	}))
	return (
		<Form<TNewIntegrationSchema>
			initialValues={newIntegrationInitialValues}
			validate={validateFormValues(newIntegrationSchemaValidator)}
			onSubmit={withServerErrors(async (values) => {
				await onSubmit(values)
			})}
			subscription={{ submitting: true }}
		>
			{({ submitting, handleSubmit }) => (
				<form noValidate onSubmit={handleSubmit}>
					<Stack gap="4">
						<TextInputField
							label="Name"
							name="name"
							type="text"
							required
							autoComplete="false"
							placeholder="e.g. My Integration, Marketing Site"
						/>
						<TextInputField
							label="Description"
							name="description"
							type="text"
							required
							autoComplete="false"
							placeholder="e.g. Automate enquiry creation by bringing leads from our marketing site into Sembark."
							help="Some description or overview of your integration"
						/>
						<SubmissionError />
						<Divider sm />
						<Inline gap="4">
							<Button type="submit" disabled={submitting}>
								{submitting ? "Please wait..." : "Save Details"}
							</Button>
							<Button disabled={submitting} onClick={onCancel}>
								Cancel
							</Button>
						</Inline>
					</Stack>
				</form>
			)}
		</Form>
	)
}

type TNewCustomFBAppIntegrationSchema = TNewIntegrationSchema & {
	facebook: {
		app_id: string
		app_secret: string
	}
}

const newCustomFBAppIntegrationSchemaValidator = Validator.object().shape({
	name: Validator.string()
		.required("Please give a name to your integration")
		.max(50, "Please use 50 or fewer characters for the name"),
	description: Validator.string()
		.required("Please provide an description for this integration")
		.max(500, "Please use 500 or fewer characters for the description"),
	facebook: Validator.object().shape({
		app_id: EmptyNumberValidator().required(
			"Please provide your custom App's ID"
		),
		app_secret: Validator.string().required(
			"Please provide your custom App's secret"
		),
	}),
})

export function NewCustomFBAppIntegrationForm({
	onSubmit,
	onCancel,
	initialValues: propInitialValues,
}: {
	initialValues?: Optional<TNewCustomFBAppIntegrationSchema>
	onSubmit: (data: TNewCustomFBAppIntegrationSchema) => Promise<unknown>
	onCancel: () => void
}) {
	const [newIntegrationInitialValues] = useState<TNewIntegrationSchema>(() => ({
		name: propInitialValues?.name || "",
		description: propInitialValues?.description || "",
		abilities: propInitialValues?.abilities || [],
	}))
	return (
		<Form<TNewCustomFBAppIntegrationSchema>
			initialValues={newIntegrationInitialValues}
			validate={validateFormValues(newCustomFBAppIntegrationSchemaValidator)}
			onSubmit={withServerErrors(async (values) => {
				await onSubmit(values)
			})}
			subscription={{ submitting: true }}
		>
			{({ submitting, handleSubmit }) => (
				<form noValidate onSubmit={handleSubmit}>
					<Stack gap="4">
						<Grid gap="6">
							<Col sm={12} md={6}>
								<Stack gap="2">
									<Heading>Facebook App Details</Heading>
									<Text>
										Follow these instruction to create a new Facebook App
									</Text>
									<Stack
										as="ol"
										listStyleType="decimal"
										paddingLeft="4"
										gap="1"
									>
										<Box as="li">
											Visit{" "}
											<Text
												as="a"
												href="https://developers.facebook.com/apps"
												rel="noopener noreferrer"
												target="_blank"
												color="accent"
											>
												Facebook Developers
											</Text>{" "}
											page.
										</Box>
										<Box as="li">
											Click on <mark>Create App</mark>. Select <b>Other</b> from
											use case and click <mark>Next</mark>.
										</Box>
										<Box as="li">
											Now select <b>Business</b> for the app type and click{" "}
											<mark>Next</mark>.
										</Box>
										<Box as="li">
											Next, provide a Name, Email and select a Business Account
											and save.
										</Box>
									</Stack>
								</Stack>
							</Col>
							<Col>
								<Stack gap="4">
									<Text>
										From Facebook App Dashboard, click on{" "}
										<b>Settings &gt; Basic</b>. Copy the <mark>App ID</mark>,
										and <mark>App Secret</mark> and paste them in the following
										required fields.
									</Text>
									<TextInputField
										label="App ID"
										name="facebook.app_id"
										type="text"
										required
										autoComplete="false"
										placeholder="e.g. 131114111511151"
									/>
									<TextInputField
										label="App secret"
										name="facebook.app_secret"
										type="text"
										required
										autoComplete="false"
										placeholder="e.g. dd1dd3dddd123123dd4ddd12312322dd"
									/>
								</Stack>
							</Col>
						</Grid>
						<Divider />
						<Grid gap="6">
							<Col sm={12} md={6}>
								<Stack gap="2">
									<Heading>Integration Details</Heading>
									<Text>
										Please provide a name and description for this integration.
									</Text>
								</Stack>
							</Col>
							<Col>
								<Stack gap="4">
									<TextInputField
										label="Name"
										name="name"
										type="text"
										required
										autoComplete="false"
										placeholder="e.g. My Integration, Marketing Site"
									/>
									<TextInputField
										label="Description"
										name="description"
										type="text"
										required
										autoComplete="false"
										placeholder="e.g. Automate enquiry creation by bringing leads from our marketing site into Sembark."
										help="Some description or overview of your integration"
									/>
								</Stack>
							</Col>
						</Grid>
						<SubmissionError />
						<Divider sm />
						<Inline gap="4">
							<Button type="submit" disabled={submitting}>
								{submitting ? "Please wait..." : "Save Details"}
							</Button>
							<Button disabled={submitting} onClick={onCancel}>
								Cancel
							</Button>
						</Inline>
					</Stack>
				</form>
			)}
		</Form>
	)
}
