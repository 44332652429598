import {
	Alert,
	Badge,
	Text,
	Container,
	Box,
	Inline,
	Icons,
	Stack,
	Heading,
	Spinner,
	TabContent,
	Tabs,
	TabsList,
	Time,
	joinAttributes,
} from "@sembark-travel/ui/base"
import { NavLink } from "@sembark-travel/ui/router"
import { utcTimestampToLocalDate, endOf } from "@sembark-travel/datetime-utils"
import {
	FeatureFlag,
	PERMISSIONS,
	useAuthUser,
	useCheckPermissions,
} from "../Auth"
import { useTenant } from "./store"
import { UploadTenantLogo } from "./Profile"
import { generatePath, useLocation } from "../router-utils"
import { useMemo } from "react"
import pluralize from "pluralize"

export { UploadTenantLogo }

export function TenantBillingSettings() {
	const { user } = useAuthUser()
	const { tenant, isFetching, error } = useTenant(user?.tenant?.id)
	if (!user || !user.tenant) {
		return null
	}
	if (isFetching) {
		return <Spinner padding="4" alignCenter />
	}
	if (!tenant) {
		if (error) {
			return <Alert status="error">{error.message}</Alert>
		}
		return null
	}
	const {
		is_in_trial,
		is_trial_expired,
		is_converted,
		trial_ends_at,
		conversion_detail,
	} = tenant
	return (
		<>
			<Box borderBottomWidth="1" bgColor="default">
				<Container paddingY="4">
					<Heading as="h3">Billing</Heading>
				</Container>
			</Box>
			<Container paddingY="6">
				<Box maxWidth="2xl">
					{is_converted && conversion_detail ? (
						<Box>
							<Box display="inlineBlock" fontWeight="semibold">
								Billed <Badge warning>{conversion_detail.billing_type}</Badge> (
								{conversion_detail.currency})
							</Box>
							<Box
								marginTop="2"
								padding="4"
								bgColor="default"
								rounded="md"
								boxShadow="base"
							>
								<Box display={{ sm: "flex" }} alignItems="center">
									<Box>
										{joinAttributes(
											`Min: ${conversion_detail.minimum_amount.formatted}`,
											conversion_detail.base_amount
												? `${conversion_detail.base_amount.formatted} for ${pluralize(
														"User",
														conversion_detail.base_users,
														true
													)}`
												: null,
											`${conversion_detail.amount_per_unit} / ${pluralize(
												"User",
												conversion_detail.users_per_unit || 0,
												true
											)}`
										)}
									</Box>
									<Box margin="2">
										<Icons.Plus fontSize="xl" />
									</Box>
									<Box>
										<Text fontSize="3xl" fontWeight="semibold" as="span">
											{conversion_detail.amount_per_unit.formatted}
										</Text>{" "}
										/month/user onwards
									</Box>
								</Box>
								<Box fontSize="sm" marginTop="2" fontWeight="semibold">
									GST Extra
								</Box>
							</Box>
						</Box>
					) : is_trial_expired ? (
						<Alert status="error" title="Your FREE Trial has expired">
							<Stack gap="2">
								<Text>
									Please upgrade to any plan to continue using the software
									without any disruption. Visit our{" "}
									<a href="https://sembark.com/#pricing">pricing page</a> to
									view all the plans and prices.
								</Text>
								<Box>
									For more details, please contact us at{" "}
									<a href="mailto:sales@sembark.com">sales@sembark.com</a>.
								</Box>
							</Stack>
						</Alert>
					) : is_in_trial ? (
						<Alert status="warning" title="FREE Trial Account">
							<Text>
								You are on a FREE trial account which will expired on{" "}
								<Time
									value={
										trial_ends_at
											? utcTimestampToLocalDate(trial_ends_at)
											: endOf(new Date(), "day")
									}
								/>
								. Till then, thoroughly test the application to find it's best
								fit for your business.{" "}
							</Text>
						</Alert>
					) : null}
				</Box>
			</Container>
		</>
	)
}

export function TenantProfileLayout({
	children,
}: {
	children: React.ReactNode
	active: "users" | "repository" | "settings"
}) {
	const { user } = useAuthUser()
	const { hasPermission, hasAnyPermission } = useCheckPermissions()
	const { tenant, isFetching, error } = useTenant(user?.tenant?.id)
	const location = useLocation()
	const { pathname } = location
	const active = useMemo((): "users" | "repository" | "settings" => {
		switch (true) {
			case /(trip-destinations|trip-sources|hotel-payment-preferences|terms-and-conditions|inclusion-exclusion-presets|cab-types|tourists)/.test(
				pathname
			):
				return "repository"
			case /(users|teams)/.test(pathname):
				return "users"
			case /(profile|billing|integrations|connections|currencies)/.test(
				pathname
			):
				return "settings"
		}
		return "repository"
	}, [pathname])
	if (!user || !user.tenant) {
		return null
	}
	if (isFetching) {
		return <Spinner padding="4" alignCenter />
	}
	if (!tenant) {
		if (error) {
			return <Alert status="error">{error.message}</Alert>
		}
		return null
	}
	const {
		id,
		name,
		short_name,
		identity_name,
		profile_image_url,
		functional_currency,
		timezone,
		timezone_offset_string,
	} = tenant
	return (
		<Box key={id}>
			<Box as="header" padding="4" borderBottomWidth="1" bgColor="subtle">
				<Inline
					flexWrap="wrap"
					collapseBelow={profile_image_url ? "sm" : undefined}
				>
					<Box marginRight="8" marginBottom="4">
						{profile_image_url ? (
							<Box>
								<img
									src={profile_image_url}
									style={{
										width: "auto",
										height: "100px",
										background: "white",
									}}
									alt={short_name || name}
								/>
							</Box>
						) : (
							<Box
								style={{ width: "96px", height: "96px" }}
								display="flex"
								justifyContent="center"
								alignItems="center"
								bgColor="emphasis"
								color="white"
								fontSize="3xl"
								fontWeight="semibold"
								rounded="full"
							>
								{(short_name || name).slice()[0].toUpperCase()}
							</Box>
						)}
					</Box>
					<Stack flex="1" minWidth="0" gap="1" paddingTop="2">
						<Inline gap="1" alignItems="baseline" flexWrap="wrap">
							<Heading as="h2" fontSize="xl">
								{short_name || name}
							</Heading>
							{short_name ? <Text>({name})</Text> : null}
						</Inline>
						<Box>
							{joinAttributes(
								identity_name ? (
									<Text title="Your Business ID" as="span">
										<Icons.LightningBolt opacity="50" /> {identity_name}
									</Text>
								) : null,
								functional_currency,
								`${timezone} (GMT${timezone_offset_string})`
							)}
						</Box>
					</Stack>
				</Inline>
			</Box>
			<Tabs layout="col" gap="4">
				<TabsList>
					<NavLink
						to={generatePath("/org/trip-destinations")}
						active={active === "repository"}
					>
						<Icons.ViewList /> Repository
					</NavLink>
					{hasAnyPermission(
						PERMISSIONS.MANAGE_USERS,
						PERMISSIONS.CREATE_TEAM
					) ? (
						hasPermission(PERMISSIONS.MANAGE_USERS) ? (
							<NavLink
								to={generatePath("/org/users")}
								active={active === "users"}
							>
								<Icons.Users /> Users
							</NavLink>
						) : (
							<NavLink
								to={generatePath("/org/teams")}
								active={active === "users"}
							>
								<Icons.Cog /> Teams
							</NavLink>
						)
					) : null}
					{hasPermission(PERMISSIONS.MANAGE_USERS) ? (
						<NavLink
							to={generatePath("/org/profile")}
							active={active === "settings"}
						>
							<Icons.Cog /> Settings
						</NavLink>
					) : null}
				</TabsList>
				<TabContent>{children}</TabContent>
			</Tabs>
		</Box>
	)
}

export function TenantRepositoryLayout({
	children,
}: {
	children: React.ReactNode
}) {
	const { hasPermission } = useCheckPermissions()
	return (
		<Tabs>
			<TabsList>
				<NavLink to={generatePath("/org/trip-destinations")}>
					Destinations
				</NavLink>
				<NavLink to={generatePath("/org/trip-sources")}>Trip Sources</NavLink>
				<NavLink to={generatePath("/org/templates")}>
					Email/PDF Templates
				</NavLink>
				{hasPermission(PERMISSIONS.VIEW_HOTEL_BOOKINGS) ? (
					<NavLink to={generatePath("/org/hotel-payment-preferences")}>
						Hotel Payment Prefs
					</NavLink>
				) : null}
				<NavLink to={generatePath("/org/terms-and-conditions")}>
					T&amp;C
				</NavLink>
				<NavLink to={generatePath("/org/inclusion-exclusion-presets")}>
					Inc/Exclusions
				</NavLink>
				{hasPermission(PERMISSIONS.MANAGE_TRIP_OWNERS) ? (
					<NavLink to={generatePath("/org/cab-types")}>Cab Types</NavLink>
				) : null}
				{hasPermission(PERMISSIONS.MANAGE_USERS) ? (
					<NavLink to={generatePath("/org/tourists")}>Tourists</NavLink>
				) : null}
			</TabsList>
			<TabContent paddingBottom="16">{children}</TabContent>
		</Tabs>
	)
}

export function TenantUsersLayout({ children }: { children: React.ReactNode }) {
	const { hasPermission } = useCheckPermissions()
	return (
		<Tabs>
			<TabsList>
				{hasPermission(PERMISSIONS.MANAGE_USERS) ? (
					<NavLink to={generatePath("/org/users")}>Users</NavLink>
				) : null}
				{hasPermission(PERMISSIONS.CREATE_TEAM) ? (
					<NavLink to={generatePath("/org/teams")}>Teams</NavLink>
				) : null}
			</TabsList>
			<TabContent>{children}</TabContent>
		</Tabs>
	)
}

export function TenantSettingsLayout({
	children,
}: {
	children: React.ReactNode
}) {
	const { hasPermission } = useCheckPermissions()
	return (
		<Tabs>
			<TabsList>
				<NavLink to={generatePath("/org/profile")}>Profile</NavLink>
				{hasPermission(PERMISSIONS.MANAGE_INTEGRATIONS) ? (
					<NavLink to={generatePath("/org/integrations")}>Integrations</NavLink>
				) : null}
				{hasPermission(PERMISSIONS.CONNECT_WITH_TENANTS) ? (
					<NavLink to={generatePath("/org/connections")}>Connections</NavLink>
				) : null}
				<FeatureFlag flag="multi_currency">
					<NavLink to={generatePath("/org/currencies")}>Currencies</NavLink>
				</FeatureFlag>
			</TabsList>
			<TabContent>{children}</TabContent>
		</Tabs>
	)
}
