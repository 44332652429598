import { Helmet } from "react-helmet-async"
import { SelectQuoteCreationFlow } from "../../../../Trips"
import { useTripDetailsOutletContext } from "./_trip-details-context"

export default function SelectQuoteCreationFlowPage() {
	const { trip } = useTripDetailsOutletContext()
	return (
		<>
			<Helmet>
				<title>Select Quote</title>
			</Helmet>
			<SelectQuoteCreationFlow trip={trip} isFetching={false} />
		</>
	)
}
