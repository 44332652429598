import {
	Box,
	Spinner,
	Money,
	Button,
	Stack,
	Inline,
	Icons,
	Heading,
	Text,
	Tabs,
	TabsList,
	TabItem,
	TabContent,
	Component,
} from "@sembark-travel/ui/base"
import { XHRInstance, useXHR } from "@sembark-travel/xhr"
import { type ITrip, tripInvoicesXHR, TTripInvoice } from "./store"
import { createMoney } from "@sembark-travel/money"
import { Required } from "utility-types"
import useSWR from "swr"
import { useEffect, useId, useRef, useState } from "react"
import { Dialog, useDialog } from "@sembark-travel/ui/dialog"
import config from "../config"
import { Pick } from "@react-spring/web"
import { InvoiceItemDetails, InvoiceForm, InvoiceFormData } from "../Invoices"
import { UpdateTourist } from "../Tourists"
import { EditTripSourceItem } from "../TripSources"
import { IAddress } from "../Addresses"

export function TripInvoices({
	trip,
	onChange: revalidateTrip,
}: {
	trip: ITrip
	onChange: () => void
}) {
	const xhr = useXHR()
	const [selectedInvoice, setSelectedInvoice] = useState<
		TTripInvoice | undefined
	>(undefined)
	const { data, mutate: revalidate } = useSWR(
		`/trips/${trip.id}/invoices`,
		() => tripInvoicesXHR(xhr).get(trip.id)
	)
	useEffect(() => {
		if (!selectedInvoice && data?.data?.length) {
			setSelectedInvoice(data.data[0])
		}
	}, [data, selectedInvoice])
	const [
		isOpenCreateInvoiceDialog,
		openCreateInvoiceDialog,
		closeCreateInvoiceDialog,
	] = useDialog()
	if (!data) {
		return <Spinner padding="4" alignCenter />
	}
	const invoices = data.data
	const canCreate = true
	return (
		<>
			<Stack gap="4">
				<Box padding="4" bgColor="default">
					<Inline justifyContent="between" gap="4" alignItems="center">
						<Heading>Proforma Invoices</Heading>
						{canCreate ? (
							<Box>
								<Button
									onClick={() => openCreateInvoiceDialog()}
									size="sm"
									level="tertiary"
								>
									<Icons.Plus /> New
								</Button>
							</Box>
						) : null}
					</Inline>
				</Box>
				{!invoices.length ? (
					<Box padding="4" textAlign="center">
						<Stack gap="4">
							<Text fontSize="lg">No Invoices created for this Trip!</Text>
							{canCreate ? (
								<Box>
									<Button onClick={() => openCreateInvoiceDialog()}>
										Create Invoice
									</Button>
								</Box>
							) : null}
						</Stack>
					</Box>
				) : (
					<Tabs layout="col">
						{invoices.length > 1 ? (
							<TabsList>
								{invoices.map((i) => (
									<TabItem
										key={i.id}
										onClick={() => setSelectedInvoice(i)}
										active={selectedInvoice?.id === i.id}
									>
										<Stack gap="1">
											<Money
												money={createMoney(i.total_amount, i.currency)}
												showCurrency
												fontSize="md"
											/>
										</Stack>
									</TabItem>
								))}
							</TabsList>
						) : null}
						<TabContent>
							{!selectedInvoice ? (
								<Spinner padding="4" alignCenter />
							) : (
								<InvoiceDetails
									invoice={selectedInvoice}
									trip={trip}
									canChange={canCreate}
									revalidateTrip={revalidateTrip}
									onChange={(invoice) => {
										setSelectedInvoice(invoice)
										revalidate()
									}}
									onDelete={async () => {
										await revalidate()
										setSelectedInvoice(undefined)
									}}
								/>
							)}
						</TabContent>
					</Tabs>
				)}
			</Stack>
			<Dialog
				open={isOpenCreateInvoiceDialog}
				onClose={closeCreateInvoiceDialog}
				title="Create New Invoice"
				lg
			>
				<Dialog.Body>
					<CreateInvoice
						trip={trip}
						revalidateTrip={revalidateTrip}
						onSuccess={(invoice) => {
							setSelectedInvoice(invoice)
							revalidate()
							closeCreateInvoiceDialog()
						}}
						onCancel={closeCreateInvoiceDialog}
					/>
				</Dialog.Body>
			</Dialog>
		</>
	)
}

function InvoiceDetails({
	trip,
	revalidateTrip,
	...props
}: React.ComponentProps<typeof InvoiceItemDetails> & {
	trip: Pick<ITrip, "id" | "trip_source" | "tourist">
	revalidateTrip: () => void
}) {
	return (
		<InvoiceItemDetails
			{...props}
			actions={({ refresh }) => (
				<Component initialState={false}>
					{({ state, setState }) => (
						<>
							<Button size="sm" status="primary" onClick={() => setState(true)}>
								<Icons.Pencil /> Edit
							</Button>
							<Dialog
								open={state}
								onClose={() => setState(false)}
								title="Edit Invoice"
								lg
							>
								<Dialog.Body>
									<EditInvoice
										invoice={props.invoice}
										trip={trip}
										revalidateTrip={revalidateTrip}
										onSuccess={(invoice) => {
											props.onChange(invoice)
											setState(false)
											refresh()
										}}
										onCancel={() => setState(false)}
									/>
								</Dialog.Body>
							</Dialog>
						</>
					)}
				</Component>
			)}
		/>
	)
}

function CreateInvoice({
	trip,
	...props
}: Omit<
	React.ComponentProps<typeof TripInvoiceForm>,
	| "initialValues"
	| "onSubmit"
	| "currency"
	| "fetchPayload"
	| "sellerFunctionalCurrency"
>) {
	const xhr = useXHR()
	return (
		<TripInvoiceForm
			trip={trip}
			{...props}
			fetchPayload={(xhr, params) =>
				xhr
					.get<{
						data: Required<
							InvoiceFormData,
							"currency" | "items" | "total_amount"
						>
					}>(`/trips/${trip.id}/invoices/create`, {
						params,
					})
					.then((resp) => resp.data.data)
			}
			onSubmit={async (values) => {
				const invoice = await tripInvoicesXHR(xhr).store(trip.id, values)
				return invoice
			}}
		/>
	)
}

function EditInvoice({
	invoice,
	...props
}: Omit<
	React.ComponentProps<typeof TripInvoiceForm>,
	"initialValues" | "onSubmit" | "currency" | "fetchPayload"
> & {
	invoice: TTripInvoice
}) {
	const xhr = useXHR()
	return (
		<TripInvoiceForm
			{...props}
			fetchPayload={(xhr, params) =>
				xhr
					.get<{
						data: Required<
							InvoiceFormData,
							"currency" | "items" | "total_amount"
						>
					}>(`/invoices/${invoice.id}/edit`, { params })
					.then((resp) => ({ ...resp.data.data }))
			}
			onSubmit={async (values) => {
				return await tripInvoicesXHR(xhr).update(
					props.trip.id,
					invoice.id,
					values
				)
			}}
		/>
	)
}

function TripInvoiceForm({
	trip,
	fetchPayload,
	revalidateTrip,
	...props
}: Omit<
	React.ComponentProps<typeof InvoiceForm>,
	"currency" | "sellerFunctionalCurrency" | "initialValues"
> & {
	trip: Pick<ITrip, "id" | "trip_source" | "tourist">
	fetchPayload: (
		xhr: XHRInstance,
		params: unknown
	) => Promise<Required<InvoiceFormData, "currency" | "items" | "total_amount">>
	revalidateTrip: () => void
}) {
	const xhr = useXHR()
	const id = useId()
	const { data: payload } = useSWR(
		`/api/trips/${trip.id}/invoices/form?${id}`,
		() =>
			fetchPayload(xhr, { timezone_offset: config.timezoneOffset }).then(
				(payload) => ({ ...payload, issued_at: new Date() })
			)
	)
	const [editingBuyer, setEditBuyer] = useState(false)
	const resolveRef = useRef<
		undefined | ((value: { address?: IAddress }) => unknown)
	>(undefined)
	if (!payload) {
		return <Spinner padding="4" alignCenter />
	}
	return (
		<>
			<InvoiceForm
				{...props}
				sellerFunctionalCurrency={payload.seller_functional_currency}
				currency={payload.currency}
				initialValues={payload}
				onEditBuyerAddress={() => {
					setEditBuyer(true)
					return new Promise<{ address?: IAddress }>((resolve) => {
						resolveRef.current = resolve
					})
				}}
			/>
			<Dialog
				open={editingBuyer}
				onClose={() => setEditBuyer(false)}
				title={
					trip.trip_source.is_agent
						? "Edit Trip Source Details"
						: "Edit Guest Details"
				}
			>
				<Dialog.Body>
					{!trip.trip_source.is_agent && trip.tourist ? (
						<UpdateTourist
							tourist={trip.tourist}
							onCancel={() => setEditBuyer(false)}
							onSuccess={(tourist) => {
								resolveRef.current?.(tourist)
								revalidateTrip()
								setEditBuyer(false)
							}}
						/>
					) : (
						<EditTripSourceItem
							tripSourceId={trip.trip_source.id}
							onCancel={() => setEditBuyer(false)}
							hideTypeToggle
							onSuccess={(source) => {
								resolveRef.current?.(source)
								revalidateTrip()
								setEditBuyer(false)
							}}
						/>
					)}
				</Dialog.Body>
			</Dialog>
		</>
	)
}
