import {
	install,
	uninstall,
	normalizeHotkey,
	NormalizedHotkeyString,
} from "@github/hotkey"
import { useEffect, useMemo, useRef } from "react"

export { normalizeHotkey }

const config: {
	tracker: (path: string) => void
} = {
	tracker: () => undefined,
}

export function configureHotkey(c: Partial<typeof config>) {
	if (c.tracker) {
		config.tracker = c.tracker
	}
}

// a custom event fired by @github/hotkey to inspect the hotkeys
function trackHotkeyFireEvent(e: Event) {
	const event = e as CustomEvent<{ path: Array<NormalizedHotkeyString> }>
	const path = event.detail.path
	const hotkey = path.join(" ")
	config.tracker(hotkey)
}

export function useNormalizedHotKey(hotkey?: string | undefined) {
	return useMemo(() => (hotkey ? normalizeHotkey(hotkey) : undefined), [hotkey])
}

export function useHotkey<T extends HTMLElement>(hotkey?: string) {
	const ref = useRef<T>(null)
	useEffect(() => {
		const elm = ref.current
		if (elm && hotkey) {
			install(elm, hotkey)
			elm.addEventListener("hotkey-fire", trackHotkeyFireEvent)
			return () => {
				elm.removeEventListener("hotkey-fire", trackHotkeyFireEvent)
				uninstall(elm)
			}
		}
	}, [hotkey])
	return ref
}

export function useTriggerOnHotkey(onTrigger: () => void, hotkey?: string) {
	const ref = useRef(document?.body || null)
	const triggerRef = useRef(onTrigger)
	triggerRef.current = onTrigger
	useEffect(() => {
		const elm = ref.current
		if (elm && hotkey) {
			install(elm, hotkey)
			const tracker: typeof trackHotkeyFireEvent = function track(e) {
				triggerRef.current?.()
				trackHotkeyFireEvent(e)
			}
			elm.addEventListener("hotkey-fire", tracker)
			return () => {
				elm.removeEventListener("hotkey-fire", tracker)
				uninstall(elm)
			}
		}
	}, [hotkey])
}
