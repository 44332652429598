import { Button, Stack, Inline } from "@sembark-travel/ui/base"
import {
	Form,
	ImageCropInputField,
	ImageCropInputFieldProps,
	validateFormValues,
	SubmissionError,
	withServerErrors,
	checkFileTypes,
	checkFileSize,
} from "@sembark-travel/ui/form"
import { mixed as mixedValidator, object as objectValidator } from "yup"
import { $PropertyType } from "utility-types"

const defaultAccept = ["image/png", "image/jpeg"]

export function NewImageMediaForm({
	name = "file",
	help,
	crop,
	onSubmit,
	onCancel,
	allowedTypes = defaultAccept,
	maxSize = 1000,
	canRemove,
}: {
	name?: string
	allowedTypes?: typeof defaultAccept
	maxSize?: number
	help?: string
	onSubmit: (data: FormData) => Promise<unknown>
	onCancel: () => void
	crop: $PropertyType<ImageCropInputFieldProps, "crop">
	canRemove?: boolean
}) {
	return (
		<Form<{ [key: string]: File | null }>
			initialValues={{ [name]: null }}
			validate={validateFormValues(
				objectValidator().shape({
					[name]: mixedValidator()
						.required("Please select a file")
						.test(
							"type-is-correct",
							`Please select an ${allowedTypes.join(", ")} image file`,
							checkFileTypes(allowedTypes)
						)
						.test(
							"size-not-big",
							`Please select a file that is less then ${maxSize}kB in size`,
							checkFileSize(maxSize)
						),
				})
			)}
			onSubmit={withServerErrors(async (values) => {
				const data = new FormData()
				data.append(name, values[name] || "")
				await onSubmit(data)
			})}
			subscription={{ submitting: true }}
		>
			{({ submitting, handleSubmit }) => (
				<form noValidate onSubmit={handleSubmit}>
					<Stack gap="4">
						<ImageCropInputField
							label="Select an image"
							crop={crop}
							name={name}
							accept={allowedTypes.join(",")}
							help={help}
						/>
						<SubmissionError />
						<Inline gap="4">
							<Button type="submit" disabled={submitting}>
								{submitting ? "Uploading..." : "Upload"}
							</Button>
							{canRemove ? (
								<Button
									disabled={submitting}
									onClick={async () => {
										if (
											window.confirm(
												`Are you sure you want to remove this image`
											)
										) {
											const data = new FormData()
											data.append(name, "")
											await onSubmit(data)
										}
									}}
									status="warning"
								>
									Remove
								</Button>
							) : null}
							<Button disabled={submitting} onClick={onCancel} level="tertiary">
								Cancel
							</Button>
						</Inline>
					</Stack>
				</form>
			)}
		</Form>
	)
}
