import { Helmet } from "react-helmet-async"
import { NotificationList } from "../../../Notifications"

export default function NotificationListPage() {
	return (
		<>
			<Helmet>
				<title>Notifications</title>
			</Helmet>
			<NotificationList />
		</>
	)
}
