import {
	Stack,
	Heading,
	Text,
	Box,
	Button,
	Inline,
	Icons,
	Card,
	CardBody,
	Col,
	Component,
	DeferRender,
	Grid,
} from "@sembark-travel/ui/base"
import { useDialog, Dialog } from "@sembark-travel/ui/dialog"
import {
	utcTimestampToLocalDate,
	clone,
	isBefore,
	addUnit,
	formatDate,
	getDiff,
	parseDate,
	subtractUnit,
	startOf,
	endOf,
} from "@sembark-travel/datetime-utils"
import { useXHR } from "@sembark-travel/xhr"
import React, { useCallback, useMemo, useState } from "react"
import { $PropertyType } from "utility-types"
import * as Validator from "yup"
import { CalculatePriceForm, ExtraServicesForm } from "../HotelPrices"
import { IHotel } from "../Hotels"
import { IBooking } from "./store"
import { ChildrenInputField, childrenToQueryParams } from "./../Tourists"
import {
	getEditableHotelExtras,
	getEditableHotels,
	IQuoteHotelExtraParams,
	IQuoteHotelParams,
	transformHotelExtrasToRequestData,
	transformHotelsToRequestData,
} from "./../Trips/store"
import {
	Form,
	TextInputField,
	withServerErrors,
	validateFormValues,
	SubmissionError,
	GetFieldValue,
	EmptyNumberValidator,
} from "@sembark-travel/ui/form"
import { TTripDestination } from "../TripDestinations"

interface EditDetailsProps {
	booking: IBooking
	startDate: Date
	endDate: Date
	onCancel?: () => void
	onSuccess?: () => void
	tripDestinations: Array<TTripDestination>
}

export function EditHotelBookingDetailsInDialog({
	level,
	minimal,
	children,
	...props
}: EditDetailsProps &
	Pick<React.ComponentProps<typeof Button>, "level"> & {
		minimal?: boolean
		children?: (props: { edit: () => void }) => React.ReactNode
	}) {
	const {
		booking: { can_modify, booking_stage },
	} = props
	const [isOpen, openDialog, closeDialog] = useDialog()
	if (
		!can_modify ||
		(booking_stage.state !== "initialized" &&
			booking_stage.state !== "booked" &&
			booking_stage.state !== "in_progress")
	)
		return null
	return (
		<>
			{children ? (
				children({ edit: openDialog })
			) : (
				<Button
					level={minimal ? "tertiary" : level}
					inline={minimal}
					onClick={openDialog}
				>
					{minimal ? <Icons.Pencil title="Edit Details" /> : "Edit Details"}
				</Button>
			)}
			<Dialog
				open={isOpen}
				onClose={closeDialog}
				fitContainer
				title={`Edit Booking Details for ${props.booking.hotel.name}`}
			>
				<Dialog.Body>
					<DeferRender>
						<EditDetails
							{...props}
							onCancel={() => {
								closeDialog()
								props.onCancel && props.onCancel()
							}}
							onSuccess={() => {
								closeDialog()
								props.onSuccess && props.onSuccess()
							}}
						/>
					</DeferRender>
				</Dialog.Body>
			</Dialog>
		</>
	)
}

function EditDetails({
	booking,
	startDate,
	endDate,
	onCancel,
	onSuccess,
	tripDestinations,
}: EditDetailsProps) {
	// just so hooks can verify, we are converting these to string
	const startDateISO = startDate.toISOString()
	const endDateISO = endDate.toISOString()
	const quoteHotels = useMemo(() => {
		const startDate = parseDate(startDateISO)
		const endDate = parseDate(endDateISO)
		return getEditableHotels(
			(booking.details || []).map(({ comments, ...details }) => {
				return {
					dates: getDatesFromInterval(
						utcTimestampToLocalDate(booking.checkin),
						utcTimestampToLocalDate(booking.checkout)
					),
					hotel: booking.hotel,
					comments: comments || "",
					...details,
				}
			}),
			startOf(clone(startDate), "day"),
			startOf(clone(startDate), "day"),
			getDiff(endOf(endDate, "day"), startOf(startDate, "day"), "days") + 1
		)
	}, [booking, startDateISO, endDateISO])
	const quoteHotelExtras = useMemo(() => {
		const startDate = parseDate(startDateISO)
		const endDate = parseDate(endDateISO)
		return getEditableHotelExtras(
			(booking.extras || []).map(({ comments, ...details }) => {
				return {
					hotel: booking.hotel,
					comments: comments || "",
					...details,
				}
			}),
			startOf(clone(startDate), "day"),
			startOf(clone(startDate), "day"),
			getDiff(endOf(endDate, "day"), startOf(startDate, "day"), "days") + 1
		)
	}, [booking, startDateISO, endDateISO])
	const xhr = useXHR()
	return (
		<HotelBookingDetailsForm
			tripDestinations={tripDestinations}
			startDate={startDate}
			endDate={endDate}
			children={booking.children}
			noOfAdults={booking.no_of_adults}
			initialHotels={quoteHotels}
			initialHotelExtras={quoteHotelExtras}
			onCancel={onCancel}
			hotel={booking.hotel}
			onSubmit={async (details, { children, noOfAdults }) => {
				return xhr
					.patch(`/hotel-bookings/${booking.id}`, {
						children: children,
						no_of_adults: noOfAdults,
						details: details.hotels,
						extras: details.extras,
					})
					.then(onSuccess)
			}}
		/>
	)
}

export function AddReplacementHotelForm({
	booking,
	startDate,
	endDate,
	...props
}: Omit<EditDetailsProps, "onSuccess"> &
	Pick<React.ComponentProps<typeof HotelBookingDetailsForm>, "onSubmit">) {
	// just so hooks can verify, we are converting these to string
	const startDateISO = startDate.toISOString()
	const endDateISO = endDate.toISOString()
	const quoteHotels = useMemo(() => {
		const startDate = parseDate(startDateISO)
		const endDate = parseDate(endDateISO)
		return getEditableHotels(
			(booking.details || []).map(({ comments, ...details }) => {
				return {
					dates: getDatesFromInterval(
						utcTimestampToLocalDate(booking.checkin),
						utcTimestampToLocalDate(booking.checkout)
					),
					hotel: booking.hotel,
					comments: comments || "",
					...details,
				}
			}),
			clone(startDate),
			clone(startDate),
			getDiff(endDate, startDate, "days") + 1
		).map(({ hotel, ...otherDetails }) => ({
			hotel: undefined as never,
			...otherDetails,
		}))
	}, [booking, startDateISO, endDateISO])
	const quoteHotelExtras = useMemo(() => {
		const startDate = parseDate(startDateISO)
		const endDate = parseDate(endDateISO)
		return getEditableHotelExtras(
			(booking.extras || []).map(({ comments, ...details }) => {
				return {
					hotel: booking.hotel,
					comments: comments || "",
					...details,
				}
			}),
			clone(startDate),
			clone(startDate),
			getDiff(endDate, startDate, "days") + 1
		).map(({ hotel, ...otherDetails }) => ({
			hotel: undefined as never,
			...otherDetails,
		}))
	}, [booking, startDateISO, endDateISO])
	return (
		<HotelBookingDetailsForm
			{...props}
			startDate={startDate}
			endDate={endDate}
			children={booking.children}
			noOfAdults={booking.no_of_adults}
			initialHotels={quoteHotels}
			initialHotelExtras={quoteHotelExtras}
		/>
	)
}

const hoteDetailsFormValidationSchems = Validator.object().shape({
	noOfAdults: EmptyNumberValidator()
		.positive("Number of adults should be a positive integer")
		.integer("Number of adults should be a positive integer")
		.required("Number of adults field is required"),
	children: Validator.array().of(
		Validator.object().shape({
			count: EmptyNumberValidator()
				.positive("Number of children should be positive integer")
				.integer("Number of children should be positive integer"),
			age: EmptyNumberValidator().positive(
				"Child age should a positive number"
			),
		})
	),
})

const validate = validateFormValues(hoteDetailsFormValidationSchems)

export function HotelBookingDetailsForm({
	startDate,
	endDate,
	children,
	noOfAdults,
	onCancel,
	onSubmit,
	initialHotels,
	initialHotelExtras,
	hotel,
	tripDestinations,
}: {
	startDate: Date
	endDate: Date
	children: $PropertyType<IBooking, "children">
	noOfAdults: number
	onCancel?: () => void
	onSubmit: (
		data: {
			hotels: Array<
				ReturnType<typeof transformHotelsToRequestData>[number] & {
					children: $PropertyType<IBooking, "children">
					no_of_adults: number
				}
			>
			extras: Array<
				ReturnType<typeof transformHotelExtrasToRequestData>[number] & {
					children: $PropertyType<IBooking, "children">
					no_of_adults: number
				}
			>
		},
		basic: {
			noOfAdults: number
			children: $PropertyType<IBooking, "children">
		},
		details: {
			details: IQuoteHotelParams
			extras: IQuoteHotelExtraParams
		}
	) => Promise<void>
	hotel?: IHotel
	initialHotels?: ReturnType<typeof getEditableHotels>
	initialHotelExtras?: ReturnType<typeof getEditableHotelExtras>
	tripDestinations: Array<TTripDestination>
}) {
	const [details, setDetails] = useState<IQuoteHotelParams>([])
	const [extras, setExtras] = useState<IQuoteHotelExtraParams>([])
	const handleHotelChange = useCallback(
		(details: IQuoteHotelParams) => {
			setDetails(details)
		},
		[setDetails]
	)
	const handleHotelExtrasChange = useCallback(
		(details: IQuoteHotelExtraParams) => {
			setExtras(details)
		},
		[setExtras]
	)
	const guestDetailsInitialValues = useMemo(() => {
		return { children: children, noOfAdults }
	}, [children, noOfAdults])
	return (
		<Box>
			<CalculatePriceForm
				noOfAdults={noOfAdults}
				children={children}
				bookingFrom={formatDate(startDate, "YYYY-MM-DD HH:mm:ss")}
				bookingTo={formatDate(
					subtractUnit(endDate, 1, "day"),
					"YYYY-MM-DD HH:mm:ss"
				)}
				onChange={handleHotelChange as never}
				showBookedPrices
				disableHotels={Boolean(hotel)}
				tripDestinations={tripDestinations}
				initialValues={initialHotels ? { hotels: initialHotels } : undefined}
			/>
			<br />
			<ExtraServicesForm
				bookingFrom={formatDate(startDate, "YYYY-MM-DD HH:mm:ss")}
				bookingTo={formatDate(
					subtractUnit(endDate, 1, "day"),
					"YYYY-MM-DD HH:mm:ss"
				)}
				tripDestinations={tripDestinations}
				onChange={handleHotelExtrasChange as never}
				showBookedPrices
				hotels={hotel ? [hotel] : undefined}
				initialValues={
					initialHotelExtras ? { hotel_extras: initialHotelExtras } : undefined
				}
			/>
			<br />
			<Form<typeof guestDetailsInitialValues>
				initialValues={guestDetailsInitialValues}
				validate={validate}
				onSubmit={withServerErrors(async (values) => {
					const hotels = transformHotelsToRequestData(details)
					const hotelExtras = transformHotelExtrasToRequestData(extras)
					if (!hotels.length && !hotelExtras.length) {
						throw new Error("Please provide valid details for services")
					}
					return await onSubmit(
						{
							hotels: hotels.map((data) => ({
								...data,
								no_of_adults: values.noOfAdults,
								children: values.children,
							})),
							extras: hotelExtras.map((data) => ({
								...data,
								given_price: data.price,
								no_of_adults: values.noOfAdults,
								children: values.children,
							})),
						},
						values,
						{ details, extras }
					)
				})}
				subscription={{ submitting: true }}
			>
				{({ handleSubmit, submitting, form }) => (
					<form noValidate onSubmit={handleSubmit}>
						<Stack gap="4">
							<SubmissionError />
							<Card marginBottom="12">
								<CardBody bgColor="subtle">
									<Stack gap="2">
										<Heading fontSize="md" as="h4">
											Guest Details
										</Heading>
										<Text color="muted" fontSize="sm">
											if necessary or beneficial, you can edit guest details for
											this particular booking.
										</Text>
										<Component initialState={false}>
											{({ state, setState }) =>
												!state ? (
													<Inline gap="4" alignItems="center">
														<Box>
															<GetFieldValue<number> name="noOfAdults">
																{({ value: noOfAdults }) => (
																	<>{noOfAdults} Adults</>
																)}
															</GetFieldValue>
															<GetFieldValue<
																(typeof guestDetailsInitialValues)["children"]
															> name="children">
																{({ value: children }) =>
																	children?.length ? (
																		<>
																			{" "}
																			with {childrenToQueryParams(
																				children
																			)}{" "}
																			Children
																		</>
																	) : null
																}
															</GetFieldValue>
														</Box>
														<Button
															onClick={() => setState(true)}
															size="sm"
															level="tertiary"
															title="Edit Guest Details"
															data-testid="hb_edit_guest_details"
														>
															<Icons.Pencil />
														</Button>
													</Inline>
												) : (
													<Grid gap="8">
														<Col xs="auto">
															<TextInputField
																label="No of Adults"
																name="noOfAdults"
																type="number"
																min={1}
																max={1000000}
															/>
														</Col>
														<Col xs={12} sm="auto">
															<ChildrenInputField
																name="children"
																maxAge={
																	tripDestinations.length
																		? Math.max(
																				...tripDestinations.map(
																					(d) => d.max_child_age || 12
																				)
																			)
																		: undefined
																}
															/>
														</Col>
														<Col xs={12} sm="auto" paddingTop="4">
															<Button
																onClick={() => {
																	form.initialize(guestDetailsInitialValues)
																	setState(false)
																}}
															>
																Cancel
															</Button>
														</Col>
													</Grid>
												)
											}
										</Component>
									</Stack>
								</CardBody>
							</Card>
							<Inline gap="4">
								<Button type="submit" disabled={submitting}>
									Save
								</Button>
								<Button onClick={onCancel}>Cancel</Button>
							</Inline>
						</Stack>
					</form>
				)}
			</Form>
		</Box>
	)
}

function getDatesFromInterval(
	startDate: string | Date,
	endDate: string | Date
) {
	let date = clone(startDate)
	const dates: Array<string> = []
	while (isBefore(date, endDate, "day")) {
		dates.push(formatDate(date, "YYYY-MM-DD"))
		date = addUnit(date, 1, "day")
	}
	dates.push(formatDate(date, "YYYY-MM-DD"))
	return dates
}
