import { ITrip } from "../../../../Trips"
import { useOutletContext } from "react-router-dom"

export function useTripDetailsOutletContext() {
	const { trip, onChange, hasChangesBetweenQuoteAndBookings } =
		useOutletContext<{
			trip: ITrip
			onChange: () => void
			hasChangesBetweenQuoteAndBookings?: boolean
		}>()
	return { trip, onChange, hasChangesBetweenQuoteAndBookings }
}
