import {
	Alert,
	Box,
	Stack,
	Spinner,
	TabContent,
	Tabs,
	TabsList,
	Container,
	Heading,
	Text,
} from "@sembark-travel/ui/base"
import { Breadcrumbs, NavLink } from "@sembark-travel/ui/router"
import { useXHR, XHRInstance } from "@sembark-travel/xhr"
import pluralize from "pluralize"
import useSWR from "swr"
import { generatePath } from "../router-utils"
import { IHotelGroup } from "./store"
import { useCheckPermissions, PERMISSIONS } from "../Auth"

function XHR(xhr: XHRInstance) {
	return {
		async getDetails(itemId: string | number): Promise<IHotelGroup> {
			return xhr
				.get(`/hotel-groups/${itemId}`, { params: { with_accounts: 1 } })
				.then((resp) => resp.data.data)
		},
	}
}

export function HotelGroupDetails({
	groupId,
	children,
}: {
	groupId: number | string
	children?: (props: {
		group: IHotelGroup
		revalidate: () => void
	}) => React.ReactNode
}) {
	const xhr = useXHR()
	const { data, error, mutate } = useSWR(`/hotel-groups/${groupId}`, () =>
		XHR(xhr).getDetails(groupId)
	)
	const { hasPermission, hasAnyPermission } = useCheckPermissions()
	if (!data && error) {
		return <Alert status="error">{error.toString()}</Alert>
	}
	if (!data) {
		return <Spinner alignCenter padding="4" />
	}
	const { name, hotels } = data
	return (
		<Box>
			<Breadcrumbs
				title="Group Details"
				items={[
					[generatePath("/hotel-groups"), "Hotel Groups"],
					["", name],
				]}
			/>
			<Container paddingY="8" bgColor="default">
				<Stack gap="1">
					<Heading as="h2">{name}</Heading>
					<Text>{pluralize("Hotel", hotels?.length || 0, true)}</Text>
				</Stack>
			</Container>
			<Tabs layout="col">
				<TabsList>
					<NavLink
						to={generatePath("/hotel-groups/:groupId", {
							groupId: String(data.id),
						})}
						end
					>
						Basic Details
					</NavLink>
					{hasAnyPermission(
						PERMISSIONS.VIEW_HOTEL_BOOKINGS,
						PERMISSIONS.VIEW_OUTGOING_PAYMENTS
					) ? (
						<NavLink
							to={generatePath("/hotel-groups/:groupId/bookings", {
								groupId: String(data.id),
							})}
						>
							Bookings
						</NavLink>
					) : null}
					{hasAnyPermission(PERMISSIONS.VIEW_OUTGOING_PAYMENTS) ? (
						<NavLink
							to={generatePath("/hotel-groups/:groupId/payments", {
								groupId: String(data.id),
							})}
						>
							Payments
						</NavLink>
					) : null}
					{hasPermission(PERMISSIONS.MANAGE_ACCOUNTING) ? (
						<NavLink
							to={generatePath("/hotel-groups/:groupId/accountings", {
								groupId: String(data.id),
							})}
						>
							Accounts
						</NavLink>
					) : null}
				</TabsList>
				<TabContent>
					{children
						? children({
								group: data,
								revalidate: mutate,
							})
						: null}
				</TabContent>
			</Tabs>
		</Box>
	)
}
