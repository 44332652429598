import { Alert, useId } from "@sembark-travel/ui/base"
import { useXHR } from "@sembark-travel/xhr"
import useSWR from "swr"
import { Spinner } from "@sembark-travel/ui/base"
import { NewItemForm } from "./NewItem"
import { TInclusionExclusionPreset } from "./store"

export function EditInclusionExclusionPresetItem({
	onSuccess,
	onCancel,
	presetId,
}: {
	onSuccess: (group: TInclusionExclusionPreset) => void
	onCancel?: () => void
	presetId: string
}) {
	const xhr = useXHR()
	const id = useId()
	const { data, error } = useSWR<TInclusionExclusionPreset>(
		`/inclusion-exclusion-presets/${presetId}?${id}`,
		() =>
			xhr
				.get(`/inclusion-exclusion-presets/${presetId}`)
				.then((resp) => resp.data.data)
	)
	if (!data && error) {
		return <Alert status="error">{error.message}</Alert>
	}
	if (!data) {
		return <Spinner padding="4" alignCenter />
	}
	return (
		<NewItemForm
			initialValues={{
				name: data.name,
				inclusions: data?.inclusions,
				exclusions: data?.exclusions,
			}}
			onCancel={onCancel}
			onSubmit={async (values) => {
				const resp = await xhr.patch(
					`/inclusion-exclusion-presets/${presetId}`,
					values
				)
				onSuccess(resp.data.data)
			}}
		/>
	)
}
