import {
	Box,
	TabContent,
	Tabs,
	TabsList,
	Text,
	Stack,
} from "@sembark-travel/ui/base"
import { ListView, Search, TSearchParams } from "@sembark-travel/ui/list"
import { queryToSearch, useLocationQuery } from "@sembark-travel/ui/router"
import classNames from "classnames"
import React from "react"
import { HotelsTableView, IHotel } from "../../Hotels"
import { TripsTableView, ITrip } from "../../Trips"
import { Helmet } from "react-helmet-async"

type TFilters = TSearchParams & {
	type?: "trips" | "hotels"
}

export default function SearchPage() {
	const [query, setQuery] = useLocationQuery<TFilters>()
	return (
		<>
			<Helmet>
				<title>Global Search</title>
			</Helmet>
			<Box width="full">
				<Stack gap="4" paddingY="8" width="full">
					<Box width="full" maxWidth="3xl" marginX="auto">
						<Search
							placeholder="Type here..."
							title="Search Trips"
							autoFocus
							initialParams={{
								q: query?.q || "",
								type: query?.type || "trips",
							}}
							onSearch={(params) => {
								setQuery({ ...params, type: query.type || "trips" })
							}}
						/>
					</Box>
					{query?.q ? (
						<Box maxWidth="6xl" width="full" marginX="auto">
							<Tabs gap="0">
								<TabsList>
									<TabLink type="trips" params={query} onClick={setQuery}>
										Trips
									</TabLink>
									<TabLink type="hotels" params={query} onClick={setQuery}>
										Hotels
									</TabLink>
								</TabsList>
								<TabContent bgColor="default">
									{!query.type || query.type === "trips" ? (
										<ListView<ITrip>
											pageKey="/search/trips"
											params={query}
											onPageChange={(page) =>
												setQuery({
													...query,
													page,
												})
											}
											fetch={(xhr, params) =>
												xhr.get("/trips", { params }).then((resp) => resp.data)
											}
										>
											{({ items }) => (
												<Box paddingTop="4">
													<TripsTableView items={items} />
												</Box>
											)}
										</ListView>
									) : query.type === "hotels" ? (
										<ListView<IHotel>
											pageKey="/search/hotels"
											params={query}
											onPageChange={(page) =>
												setQuery({
													...query,
													page,
												})
											}
											fetch={(xhr, params) =>
												xhr.get("/hotels", { params }).then((resp) => resp.data)
											}
										>
											{({ items }) => (
												<Box paddingTop="4">
													<HotelsTableView hotels={items} />
												</Box>
											)}
										</ListView>
									) : null}
								</TabContent>
							</Tabs>
						</Box>
					) : (
						<Text textAlign="center" color="muted">
							Type in the search input to view the results.
						</Text>
					)}
				</Stack>
			</Box>
		</>
	)
}

function TabLink<TParams extends { type?: string; [key: string]: unknown }>({
	type = "trips",
	params,
	onClick,
	children,
}: {
	type?: string
	params: TParams
	onClick: (params: TParams) => void
	children: React.ReactNode
}) {
	return (
		<li className={classNames({ active: params?.type === type })}>
			<a
				href={queryToSearch({ ...params, type })}
				onClick={(e) => {
					e.preventDefault()
					onClick({ ...params, type })
				}}
			>
				{children}
			</a>
		</li>
	)
}
