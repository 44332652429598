import { Box, Container } from "@sembark-travel/ui/base"
import { Breadcrumbs } from "@sembark-travel/ui/router"
import { useCallback } from "react"
import { Helmet } from "react-helmet-async"
import { EditAccount } from "../../../../../Accounting"
import { IAccount } from "../../../../../Accounting/store"
import { Navigate, useParams, useNavigate } from "../../../../../router-utils"

export default function AccountDetails() {
	const navigate = useNavigate()
	const { accountId } = useParams("/accounting/accounts/:accountId")
	const back = useCallback(() => {
		navigate("/accounting/accounts/:accountId", { params: { accountId } })
	}, [navigate, accountId])
	const onSuccess = useCallback(
		(account: IAccount) => {
			navigate("/accounting/accounts/:accountId", {
				params: {
					accountId: account.id.toString(),
				},
			})
		},
		[navigate]
	)
	if (!accountId) {
		return <Navigate to={"/accounting/accounts"} replace />
	}
	return (
		<>
			<Helmet>
				<title>Edit Account</title>
			</Helmet>
			<Breadcrumbs
				title="Edit Account"
				items={[
					["/accounting/accounts", "Accounts"],
					[`/accounting/accounts/${accountId}`, accountId],
					["", "Edit"],
				]}
			/>
			<Box paddingX="4" paddingY="8">
				<Box maxWidth="6xl" marginX="auto">
					<Box borderWidth="1" rounded="md" bgColor="default">
						<Container paddingY="6">
							<EditAccount
								accountId={accountId}
								onCancel={back}
								onSuccess={onSuccess}
							/>
						</Container>
					</Box>
				</Box>
			</Box>
		</>
	)
}
