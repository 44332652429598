import {
	Box,
	Stack,
	Heading,
	Container,
	Col,
	Grid,
	joinAttributes,
	Stars,
} from "@sembark-travel/ui/base"
import { Link } from "@sembark-travel/ui/router"
import { numberToLocalString } from "@sembark-travel/number-utils"
import pluralize from "pluralize"
import { useMemo } from "react"
import { getAssetsFromAccounts } from "../Accounting/utils"
import { generatePath } from "../router-utils"
import { IHotelGroup } from "./store"
import { useCheckPermissions, PERMISSIONS } from "../Auth"

export function HotelGroupItemBasicDetails({ group }: { group: IHotelGroup }) {
	const { hotels = [], accounting_accounts = [] } = group
	const assets = useMemo(
		() => getAssetsFromAccounts(accounting_accounts || []),
		[accounting_accounts]
	)
	const { hasPermission } = useCheckPermissions()
	return (
		<Container bgColor="default" paddingY="6">
			<Grid gap="8">
				<Col>
					<Stack gap="4">
						<Heading as="h3" fontSize="md">
							Hotels
						</Heading>
						<Stack as="ul" gap="2">
							{hotels.length === 0 ? (
								<Box fontSize="sm" color="muted">
									No hotels in this group
								</Box>
							) : (
								hotels.map((h) => (
									<Stack key={h.id} as="li" gap="1">
										<Link
											color="accent"
											to={generatePath("/hotels/:hotelId", {
												hotelId: h.id.toString(),
											})}
										>
											{h.name}
										</Link>
										<Box fontSize="sm">
											{joinAttributes(
												h.location.short_name,
												h.stars ? <Stars stars={h.stars} /> : null
											)}
										</Box>
									</Stack>
								))
							)}
						</Stack>
					</Stack>
				</Col>
				{hasPermission(PERMISSIONS.MANAGE_ACCOUNTING) ? (
					<Col>
						<Stack gap="4">
							<Heading as="h3" fontSize="md">
								Accounting Assets
							</Heading>
							<Stack as="ul" gap="2">
								{assets.length === 0 ? (
									<Box as="li" fontSize="sm" color="muted">
										n/a
									</Box>
								) : (
									assets.map((a) => (
										<Box key={a.type} as="li">
											<Box fontSize="xl" as="span">
												{numberToLocalString(a.amount)}
											</Box>{" "}
											{pluralize(a.type, a.amount)}
										</Box>
									))
								)}
							</Stack>
						</Stack>
					</Col>
				) : null}
			</Grid>
		</Container>
	)
}
