import { Box, Stack, Heading, Text } from "@sembark-travel/ui/base"
import { AddOperationalBookingsForTrip } from "../../../../../TripOperationalBookings"
import { useTripDetailsOutletContext } from "../_trip-details-context"
import { Helmet } from "react-helmet-async"
import { useNavigateToBack } from "@sembark-travel/ui/router"
import { generatePath } from "../../../../../router-utils"

export default function AddOperationalBookingsForTripPage() {
	const { trip } = useTripDetailsOutletContext()
	const navigate = useNavigateToBack(
		generatePath("/trips/:tripId/services-bookings/:serviceType", {
			tripId: trip.id.toString(),
			serviceType: "operational#operational_bookings",
		})
	)
	return (
		<>
			<Helmet>
				<title>New Operational Bookings | Trip</title>
			</Helmet>
			<Box padding="4" bgColor="default">
				<Stack gap="6">
					<Stack gap="2">
						<Heading as="h5">Add Operational Bookings</Heading>
						<Text color="muted">
							Please add required Transportation / Activity Services. Also,
							ensure that these details match with the quotation.
						</Text>
					</Stack>
					<AddOperationalBookingsForTrip
						trip={trip}
						onCancel={() => navigate()}
						onSuccess={() => navigate()}
					/>
				</Stack>
			</Box>
		</>
	)
}
