import { Helmet } from "react-helmet-async"
import { TermsAndConditionItemDetails } from "./../../../../TermsAndConditions"
import { useParams } from "../../../../router-utils"

export default function TermsAndConditionItemDetailsPage(
	props: Omit<
		React.ComponentProps<typeof TermsAndConditionItemDetails>,
		"tncId"
	>
) {
	const { tncId } = useParams("/terms-and-conditions/:tncId")
	return (
		<>
			<Helmet>
				<title>Terms and Condition Details</title>
			</Helmet>
			<TermsAndConditionItemDetails tncId={tncId} {...props} />
		</>
	)
}
