import pluralize from "pluralize"
import { Optional } from "utility-types"
import moment from "moment"
import type { unitOfTime, Duration } from "moment"

export type DurationConstructor = unitOfTime.DurationConstructor

export type BaseUnitOfTime = unitOfTime.Base

export type { Duration }

export const duration = moment.duration

export { duration as createDuration }

export function fromNow(date: Date | string, withoutSuffix = false) {
	return moment(date).fromNow(withoutSuffix)
}

export function parseDate(date: string | Date, format?: string) {
	return moment(date, format).toDate()
}

export function clone(date: Date | string) {
	return moment(date).clone().toDate()
}

export function getDaysInMonth(date: Date | string) {
	return moment(date).daysInMonth()
}

export function getDaysInYear(date: Date) {
	return (
		moment(date)
			.clone()
			.endOf("year")
			.diff(moment(date).clone().startOf("year"), "days") + 1
	)
}

export function formatDate(date: Date | string, template: string) {
	return moment(date).format(template)
}

/** @deprecated Please use formatDate */
export function format(date: Date | string, template: string) {
	return moment(date).format(template)
}

export function toISOString(date: Date | string) {
	return moment(date).clone().toISOString()
}

export function isToday(date: Date | string) {
	return isSame(parseDate(date), new Date(), "day")
}

export function isYesterday(date: Date | string) {
	return isSame(parseDate(date), subtractUnit(new Date(), 1, "day"), "day")
}

export function isTomorrow(date: Date | string) {
	return isSame(parseDate(date), addUnit(new Date(), 1, "day"), "day")
}

export function isSame(
	date1: Date | string,
	date2: Date | string,
	unit?: unitOfTime.StartOf
) {
	return moment(date1).isSame(date2, unit)
}

export function isAfter(
	date1: Date | string,
	date2: Date | string,
	unit?: unitOfTime.StartOf
) {
	return moment(date1).isAfter(date2, unit)
}

export function getUnit(date: Date | string, unit: unitOfTime.All) {
	return moment(date).get(unit)
}

export function setUnit(
	date: Date | string,
	unit: unitOfTime.All,
	value: number
) {
	return moment(date).clone().set(unit, value).toDate()
}

export function addDuration(
	date: Date | string,
	d: ReturnType<typeof duration>
) {
	return moment(date).add(d).toDate()
}

export function setTimeFrom(date: Date | string, from: Date | string) {
	from = parseDate(from)
	return setUnit(
		setUnit(
			setUnit(parseDate(date), "hours", getUnit(from, "hours")),
			"minutes",
			getUnit(from, "minutes")
		),
		"seconds",
		getUnit(from, "seconds")
	)
}

export function addUnit(
	date: Date | string,
	value: number,
	unit: unitOfTime.DurationConstructor
) {
	return moment(date).clone().add(value, unit).toDate()
}

export function subtractUnit(
	date: Date | string,
	value: number,
	unit: unitOfTime.DurationConstructor
) {
	return moment(date).clone().subtract(value, unit).toDate()
}

export function isBetween(
	date: Date | string,
	min: Date | string,
	max: Date | string,
	unit?: unitOfTime.StartOf,
	d?: "[]" | "()" | "(]" | "[)"
) {
	return moment(date).isBetween(min, max, unit, d)
}

export function isSameOrAfter(
	date: Date | string,
	comparedTo: Date | string,
	unit?: unitOfTime.StartOf
) {
	return moment(date).isSameOrAfter(comparedTo, unit)
}

export function isSameOrBefore(
	date: Date | string,
	comparedTo: Date | string,
	unit?: unitOfTime.StartOf
) {
	return moment(date).isSameOrBefore(comparedTo, unit)
}

export function isBefore(
	date: Date | string,
	comparedTo: Date | string = new Date(),
	unit?: unitOfTime.StartOf
) {
	return moment(date).isBefore(comparedTo, unit)
}

export function startOf(date: Date | string, unit: unitOfTime.StartOf) {
	return moment(date).clone().startOf(unit).toDate()
}

export function endOf(date: Date | string, unit: unitOfTime.StartOf) {
	return moment(date).clone().endOf(unit).toDate()
}

export const datetimeConfig = {
	dateDisplayFormat: "D MMM, YYYY",
	dateTimeDayDisplayFormat: "dddd D MMM, YYYY [at] hh:mm A",
	dateTimeDisplayFormat: "D MMM, YYYY [at] hh:mm A",
	timeDisplayFormat: "hh:mm A",
	timeFormat: "HH:mm:ss",
	timestampDateFormat: "YYYY-MM-DD HH:mm:ss",
}

export function configureDatetime(config: typeof datetimeConfig) {
	datetimeConfig.dateDisplayFormat = config.dateDisplayFormat
	datetimeConfig.timeDisplayFormat = config.timeDisplayFormat
	datetimeConfig.timestampDateFormat = config.timestampDateFormat
	datetimeConfig.timeFormat = config.timeFormat
}

/**
 * Converts a given utc timestamp to local datetime
 */
export function utcTimestampToLocalDate(utcDate: string) {
	return moment.utc(utcDate).clone().local().toDate()
}

/**
 * Given a UTC date, convert it to local string
 */
export function utcTimestampToLocalDateString(
	utcDate: string,
	template: string = datetimeConfig.dateDisplayFormat
) {
	return moment(utcTimestampToLocalDate(utcDate)).format(template)
}

/**
 * Given a UTC date, convert it to local date/time string
 */
export function utcTimestampToLocalDateTimeString(utcDate: string) {
	return moment(utcTimestampToLocalDate(utcDate)).format(
		datetimeConfig.dateDisplayFormat +
			" [at] " +
			datetimeConfig.timeDisplayFormat
	)
}

/**
 * Given a UTC date, convert it to local time string
 */
export function utcTimestampToLocalTimeString(utcDate: string) {
	return moment(utcTimestampToLocalDate(utcDate)).format(
		datetimeConfig.timeDisplayFormat
	)
}

/**
 * Formats a given date to server's timestamp Y-m-d H:i:s
 */
export function dateToTimestampFormat(date: string | Date): string {
	return moment(date).format(datetimeConfig.timestampDateFormat)
}

/**
 * Converts a given local date to UTC timestamp string
 */
export function dateToUTCString(
	localDate: string | Date,
	format = datetimeConfig.timestampDateFormat
): string {
	return moment(localDate).clone().utc().format(format)
}

export function utcTimeToLocalDate(utcTime: string) {
	return moment.utc(utcTime, datetimeConfig.timeFormat).clone().local().toDate()
}

export function utcTimeToLocalTimeString(utcTime: string) {
	return moment(utcTimeToLocalDate(utcTime)).format(
		datetimeConfig.timeDisplayFormat
	)
}

export function timeToUTCString(localTime: string | Date) {
	return moment(localTime, datetimeConfig.timeFormat)
		.clone()
		.utc()
		.format(datetimeConfig.timeFormat)
}

export function parseDateFromQuery(
	date: string,
	format = "YYYY-MM-DD",
	fallback?: Date
): Date {
	const parsedDate = moment(date, format)
	if (parsedDate.isValid()) return parsedDate.toDate()
	return fallback || new Date()
}

/**
 * Converts a given date to query (location) params
 */
export function dateToQuery(
	date: Date | string,
	format = "YYYY-MM-DD"
): string {
	return moment(date).format(format)
}

export function getDiff(
	date1: Date | string,
	date2: Date | string,
	unit?: unitOfTime.Diff,
	precise?: boolean
) {
	return moment(date1).diff(date2, unit, precise)
}

type TOptions = {
	/**
	 * Use short names
	 */
	short: boolean
}
const unitMeasures = {
	y: 31557600000,
	mo: 2629800000,
	w: 604800000,
	d: 86400000,
	h: 3600000,
	m: 60000,
	s: 1000,
	ms: 1,
}
const units: Array<keyof typeof unitMeasures> = [
	"y",
	"mo",
	"w",
	"d",
	"h",
	"m",
	"s",
]

function getNameForUnit(
	unit: (typeof units)[0],
	value: number,
	short = false
): string {
	if (short) return unit
	let fullName: string
	switch (unit) {
		case "m":
			fullName = "minute"
			break
		case "s":
			fullName = "second"
			break
		case "h":
			fullName = "hour"
			break
		case "d":
			fullName = "day"
			break
		case "w":
			fullName = "week"
			break
		case "ms":
			fullName = "month"
			break
		case "y":
			fullName = "year"
			break
		default:
			fullName = ""
	}
	return pluralize(fullName, value)
}

export function humanizeDuration(
	ms: number,
	options?: Optional<TOptions>
): string {
	const mergedOptions: TOptions = { short: true, ...options }
	ms = Math.abs(ms)
	const pieces: Array<{ unitCount: number; unitName: (typeof units)[0] }> = []
	units.forEach((unitName) => {
		const unitMS: number = unitMeasures[unitName]
		const unitCount = Math.floor(ms / unitMS)
		if (unitCount > 0) {
			pieces.push({
				unitCount: unitCount,
				unitName: unitName,
			})
		}
		ms -= unitCount * unitMS
	})
	return pieces
		.map(
			({ unitCount, unitName }) =>
				`${unitCount} ${getNameForUnit(
					unitName,
					unitCount,
					mergedOptions.short
				)}`
		)
		.join(" ")
}
