import { Outlet } from "react-router-dom"
import {
	Stack,
	Tabs,
	TabsList,
	TabContent,
	Heading,
	Container,
} from "@sembark-travel/ui/base"
import { NavLink } from "@sembark-travel/ui/router"
import { generatePath } from "../../../../../router-utils"
import { FeatureFlag } from "../../../../../Auth"

export default function Layout() {
	return (
		<Stack gap="4">
			<Container>
				<Heading>Template Configurations</Heading>
			</Container>
			<Tabs layout="col" gap="6">
				<TabsList>
					<NavLink to={generatePath("/org/templates/quote-email-templates")}>
						Quote Email
					</NavLink>
					<FeatureFlag flag="share_quote_pdf">
						<NavLink to={generatePath("/org/templates/quote-pdf-templates")}>
							Quote PDF
						</NavLink>
					</FeatureFlag>
				</TabsList>
				<TabContent>
					<Outlet />
				</TabContent>
			</Tabs>
		</Stack>
	)
}
