import { Box, Heading } from "@sembark-travel/ui/base"
import { useCallback } from "react"
import { NewItemForm, useCreateHotelVoucher } from "../../../HotelVouchers"
import { useNavigateToBack } from "@sembark-travel/ui/router"

export default function NewItem() {
	const create = useCreateHotelVoucher()
	const navigateBack = useNavigateToBack("..")
	const handleSubmit: typeof create = useCallback(
		async (...args) =>
			create(...args).then((resp) => {
				navigateBack()
				return resp
			}),
		[create, navigateBack]
	)
	return (
		<Box>
			<Box marginY="4">
				<Heading as="h1" fontSize="xl">
					Create Hotel Voucher
				</Heading>
			</Box>
			<Box bgColor="default" padding="4" boxShadow="base" rounded="md">
				<NewItemForm onSubmit={handleSubmit} onCancel={navigateBack} />
			</Box>
		</Box>
	)
}
