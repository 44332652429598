import {
	Icons,
	Box,
	Stack,
	Inline,
	Heading,
	Button,
	MoneySum,
	Money,
} from "@sembark-travel/ui/base"
import { Link } from "@sembark-travel/ui/router"
import { generatePath } from "../router-utils"
import { dateToUTCString, endOf, startOf } from "@sembark-travel/datetime-utils"
import { useTripSalesReportStats, type TStats } from "./types"
import { useState } from "react"
import { useFunctionalCurrencyOfTenant } from "../Currencies"

export function TripSalesReportsWidget() {
	const [intervalType, setIntervalType] = useState<"month" | "week" | "day">(
		"month"
	)
	const { stats } = useTripSalesReportStats({
		params: {
			after: dateToUTCString(startOf(new Date(), intervalType)),
			before: dateToUTCString(endOf(new Date(), intervalType)),
		},
	})
	return (
		<Stack bgColor="default" rounded="md" borderWidth="1">
			<Box paddingX="4" paddingY="2" borderBottomWidth="1">
				<Inline gap="4" justifyContent="between" flexWrap="wrap">
					<Inline gap="2" alignItems="center">
						<Heading fontSize="md" as="h3">
							Trip Sales Stats
						</Heading>
						<Box>
							<Link
								padding="2"
								to={generatePath("/trip-sales-reports")}
								color="accent"
							>
								<Icons.ArrowLeft rotate="180" />
							</Link>
						</Box>
					</Inline>
					<Inline gap="2">
						{(
							[
								["month", "Month"],
								["week", "Week"],
								["day", "Today"],
							] as const
						).map(([actionIntervalType, actionLabel]) => (
							<Button
								size="sm"
								key={actionIntervalType}
								status="primary"
								level={
									intervalType === actionIntervalType ? "secondary" : "tertiary"
								}
								onClick={() => setIntervalType(actionIntervalType)}
							>
								{actionLabel}
							</Button>
						))}
					</Inline>
				</Inline>
			</Box>
			<Box>
				<TripSalesReportStats stats={stats} />
			</Box>
		</Stack>
	)
}

function TripSalesReportStats({ stats }: { stats?: TStats }) {
	const { total_amount, total_conversion, total_dropped, total_queries } =
		stats || {}
	const functionalCurrency = useFunctionalCurrencyOfTenant()
	return (
		<Inline padding="4" gap="6" flexWrap="wrap">
			<Stack gap="1">
				<Box fontWeight="semibold" color="muted">
					Revenue
				</Box>
				<Box fontWeight="semibold" fontSize="lg">
					{!stats ? (
						"..."
					) : total_amount?.length ? (
						<MoneySum
							money={total_amount}
							separator={
								<>
									{" "}
									+<br />
								</>
							}
							roundOff
						/>
					) : (
						<Money amount={0} currency={functionalCurrency} />
					)}
				</Box>
			</Stack>
			<Inline gap="4" flexWrap="wrap">
				<Stack gap="1">
					<Box fontWeight="semibold" color="muted">
						Queries
					</Box>
					<Box fontWeight="semibold" fontSize="lg">
						{!stats ? "..." : total_queries}
					</Box>
				</Stack>
				<Stack gap="1">
					<Box fontWeight="semibold" color="muted">
						Conversion
					</Box>
					<Box fontWeight="semibold" fontSize="lg">
						{!stats ? "..." : total_conversion}
					</Box>
				</Stack>
				{total_dropped ? (
					<Stack gap="1">
						<Box fontWeight="semibold" color="muted">
							Dropped
						</Box>
						<Box fontWeight="semibold" fontSize="lg">
							{!stats ? "..." : total_dropped}
						</Box>
					</Stack>
				) : null}
			</Inline>
		</Inline>
	)
}
