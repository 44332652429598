import { Dialog } from "@sembark-travel/ui/dialog"
import { useXHR } from "@sembark-travel/xhr"
import React, { useCallback, useMemo } from "react"
import { AddContactForm } from "./AddContactForm"
import { IContact } from "./store"

interface IUpdateContactProps
	extends Omit<
		React.ComponentProps<typeof AddContactForm>,
		"onCreate" | "initialValues"
	> {
	contact: IContact
	onUpdate?: (contact: IContact) => void
}

export default function UpdateContact({
	contact,
	onUpdate,
	...props
}: IUpdateContactProps) {
	const xhr = useXHR()
	const initialValues = useMemo(() => {
		return {
			name: contact.name || "",
			email: contact.email || "",
			phone_numbers: contact.phone_numbers,
		}
	}, [contact])
	const handleUpdate = useCallback(
		async (data: unknown) => {
			const resp = await xhr.patch<{ data: IContact }>(
				`/contacts/${contact.id}`,
				data
			)
			const c = resp.data.data
			onUpdate?.(c)
			return c
		},
		[contact, xhr, onUpdate]
	)
	return (
		<AddContactForm
			onCreate={handleUpdate}
			initialValues={initialValues}
			{...props}
		/>
	)
}

export function UpdateContactInDialog({
	open,
	onSuccess,
	onCancel,
	...props
}: Omit<IUpdateContactProps, "onUpdate" | "onCancel"> & {
	open: boolean
	onSuccess: (contact: IContact) => void
	onCancel: () => void
}) {
	return (
		<Dialog open={open} onClose={onCancel} sm title="Edit Contact Details">
			<Dialog.Body>
				<UpdateContact
					{...props}
					onCancel={onCancel}
					onUpdate={onSuccess || onCancel}
				/>
			</Dialog.Body>
		</Dialog>
	)
}
