import {
	Table,
	AsyncSelect,
	AsyncSelectProps,
	Stack,
	RelativeTime,
} from "@sembark-travel/ui/base"
import {
	areAdvancedFiltersAppliedDefault,
	TSearchParams,
	Search,
	useSearch,
	ListView,
} from "@sembark-travel/ui/list"
import { ButtonLink, Link, useLocationQuery } from "@sembark-travel/ui/router"
import { IListResponse, useXHR, XHRInstance } from "@sembark-travel/xhr"
import { useEffect } from "react"
import { Omit } from "utility-types"
import { ITermsAndCondition } from "./store"
import { generatePath } from "../router-utils"
import { PERMISSIONS, useCheckPermissions } from "../Auth"
import { SwitchInputField } from "@sembark-travel/ui/form"

function XHR(xhr: XHRInstance) {
	return {
		async get(params?: unknown): Promise<IListResponse<ITermsAndCondition>> {
			return xhr
				.get("/terms-and-conditions", { params })
				.then((resp) => resp.data)
		},
	}
}

type TFilters = TSearchParams & {
	archived_only?: boolean
}

type TQueryFilters = TSearchParams & {
	archived_only?: 1
}

function filtersToLocationQuery(filters: TFilters): TQueryFilters {
	const { q, archived_only, page } = filters
	const query: TQueryFilters = {}
	if (q) query.q = q
	if (page) query.page = page
	if (archived_only) query.archived_only = 1
	return query
}

function queryToFilters(query: TQueryFilters): TFilters {
	const { q, page, archived_only } = query
	const filters: TFilters = {}
	if (q) filters.q = q
	if (page) filters.page = page
	if (archived_only) filters.archived_only = true
	return filters
}

export function TermsAndConditionsList() {
	const [query, setQuery] = useLocationQuery({
		toQuery: filtersToLocationQuery,
		fromQuery: queryToFilters,
	})
	const [params, setParams] = useSearch(query)
	const { hasPermission } = useCheckPermissions()
	useEffect(() => {
		setQuery(params)
	}, [setQuery, params])
	return (
		<Search
			initialParams={params}
			onSearch={(params) => setParams({ ...params, page: 1 })}
			title="Terms and Conditions"
			resetParams={{
				q: "",
				page: 1,
			}}
			Filters={Filters}
			areAdvancedFiltersApplied={(params) => {
				const { archived_only, ...otherParams } = params
				return (
					areAdvancedFiltersAppliedDefault(otherParams) ||
					Boolean(archived_only)
				)
			}}
			actions={
				hasPermission(PERMISSIONS.MANAGE_TRIP_OWNERS) ? (
					<ButtonLink to={generatePath("/terms-and-conditions/new")}>
						Add TnC
					</ButtonLink>
				) : null
			}
		>
			<ListView<ITermsAndCondition>
				pageKey="terms-and-conditions"
				params={params}
				fetch={(xhr, params) =>
					XHR(xhr).get({
						...params,
						include: "",
					})
				}
				onPageChange={(page) => setParams({ ...params, page })}
			>
				{({ items }) => (
					<Table
						headers={["Name", "By", "On"]}
						bordered
						hover
						responsive
						alignCols={{ 4: "right" }}
						rows={items.map((item) => [
							<Link
								to={generatePath("/terms-and-conditions/:tncId", {
									tncId: item.id.toString(),
								})}
								fontWeight="semibold"
								color="accent"
								anchored
							>
								{item.name}
							</Link>,
							item.created_by?.name,
							item.created_at ? (
								<RelativeTime timestamp={item.created_at} />
							) : null,
						])}
					/>
				)}
			</ListView>
		</Search>
	)
}

function Filters() {
	return (
		<Stack gap="4">
			<SwitchInputField label="Archived only" name="archived_only" />
		</Stack>
	)
}

export function SelectTermsAndConditions(
	props: Omit<AsyncSelectProps, "fetch">
) {
	const xhr = useXHR()
	return (
		<AsyncSelect
			{...props}
			fetch={(q) =>
				XHR(xhr)
					.get({ q })
					.then((resp) => resp.data)
			}
		/>
	)
}
