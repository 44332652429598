import {
	Box,
	Button,
	Divider,
	Heading,
	Icons,
	Inline,
	RelativeTime,
	Spinner,
	Stack,
	Text,
	joinAttributes,
	Alert,
	useId,
} from "@sembark-travel/ui/base"
import { useXHR } from "@sembark-travel/xhr"
import useSWR from "swr"
import { Dialog, useDialog } from "@sembark-travel/ui/dialog"
import { Markdown } from "@sembark-travel/ui/markdown"
import { PERMISSIONS, useCheckPermissions } from "../Auth"
import { TTripQuoteEmailTemplate } from "./store"
import { isTruthy } from "@sembark-travel/ui/form"
import { FontFamily } from "./FontFamily"
import {
	EditTripQuoteEmailTemplateForm,
	TEmailPdfTemplateCreatePayload,
} from "./EditTripQuoteEmailTemplateForm"

export function TripQuoteEmailTemplates() {
	const xhr = useXHR()
	const { data, mutate, error } = useSWR("/trip-quote-email-templates", () =>
		xhr
			.get<{
				data: Array<TTripQuoteEmailTemplate>
			}>("/trip-quote-email-templates")
			.then((resp) => resp.data)
	)
	const { hasPermission } = useCheckPermissions()
	const [isDialogOpen, openDialog, closeDialog] = useDialog()
	if (!data) {
		if (error) {
			return (
				<Alert status="error">
					{error.message || "Something went wrong. Please try after sometime."}
				</Alert>
			)
		}
		return <Spinner alignCenter padding="4" />
	}
	return (
		<Stack gap="4">
			<Inline justifyContent="between" gap="4" alignItems="center">
				<Inline gap="4" alignItems="center">
					<Inline
						size="12"
						rounded="full"
						bgColor="default"
						borderWidth="1"
						alignItems="center"
						justifyContent="center"
						color="muted"
					>
						<Icons.Mail size="6" />
					</Inline>
					<Stack gap="1">
						<Heading as="h3">Quote Email Template Configurations</Heading>
						<Text>
							These configuration will be applied to Quote Emails only.
						</Text>
					</Stack>
				</Inline>
				{hasPermission(PERMISSIONS.MANAGE_USERS) ? (
					<Box>
						<Button
							onClick={openDialog}
							level={!data.data.length ? "primary" : "secondary"}
							status="primary"
							size="sm"
						>
							<Icons.Pencil /> Edit
						</Button>
					</Box>
				) : null}
			</Inline>
			<Divider marginY="0" />
			<Box>
				{!data.data.length ? (
					<Box padding="4" bgColor="default" rounded="md" borderWidth="1">
						<Text>Using default quote email template configurations.</Text>
					</Box>
				) : (
					data.data.map((template) => (
						<Stack key={template.id} gap="4">
							<Text>
								<b>Font Family</b>: {template.settings.fontFamily.name}
							</Text>
							<Text>
								<b>Justified Text</b>:{" "}
								{isTruthy(template.settings.justifiedText)
									? "Justified"
									: "Default"}
							</Text>
							<Stack gap="1">
								<Text fontWeight="semibold">Greetings:</Text>
								<FontFamily family={template.settings.fontFamily}>
									<Box
										style={{
											textAlign: isTruthy(template.settings.justifiedText)
												? "justify"
												: undefined,
										}}
										paddingX="4"
										paddingY="2"
										borderWidth="1"
										rounded="md"
										bgColor="default"
									>
										<Markdown>{template.greetings}</Markdown>
									</Box>
								</FontFamily>
							</Stack>
							<Stack gap="1">
								<Text fontWeight="semibold">
									<Icons.Bus /> Settings for Transportation and Activity Section
								</Text>
								<Box padding="4" rounded="md" bgColor="default" borderWidth="1">
									<Stack gap="4">
										<Stack gap="px">
											<Text fontSize="sm" fontWeight="semibold" color="muted">
												Section Variant
											</Text>
											<Text>
												{template.transport_and_activity_section_settings
													.title || "Using default settings"}
											</Text>
										</Stack>
										<Stack gap="px">
											<Text fontSize="sm" fontWeight="semibold" color="muted">
												Section Variant
											</Text>
											<Text>
												{
													template.transport_and_activity_section_settings
														.variant
												}
											</Text>
										</Stack>
										<Stack gap="px">
											<Text fontSize="sm" fontWeight="semibold" color="muted">
												Hide Transport Summary if Same Cab Type is used?
											</Text>
											<Text>
												{isTruthy(
													template.transport_and_activity_section_settings
														.hide_transport_summary_for_same_cabs
												)
													? "Yes, Hide Transport Summary"
													: "No, Show Day Wise Transport Summary"}
											</Text>
										</Stack>
										<Stack gap="px">
											<Text fontSize="sm" fontWeight="semibold" color="muted">
												Specific Terms and Notes
											</Text>
											<Markdown>
												{template.transport_and_activity_section_settings
													.terms || "N/A"}
											</Markdown>
										</Stack>
									</Stack>
								</Box>
							</Stack>
							<Stack gap="1">
								<Text fontWeight="semibold">
									<Icons.SwitchHorizontal rotate="90" /> Day Wise Itinerary
									Settings
								</Text>
								<Stack
									gap="4"
									padding="4"
									rounded="md"
									bgColor="default"
									borderWidth="1"
								>
									<Stack gap="px">
										<Text fontSize="sm" fontWeight="semibold" color="muted">
											Section Title
										</Text>
										<Text>
											{template.daywise_schedule_section_settings?.title ||
												"N/A"}
										</Text>
									</Stack>
									<Stack gap="px">
										<Text fontSize="sm" fontWeight="semibold" color="muted">
											Section Order
										</Text>
										<Text>
											{template.daywise_schedule_section_settings
												?.show_itinerary_at_top
												? "Show Itinerary section at Top"
												: "Show Itinerary at end"}
										</Text>
									</Stack>
									<Stack gap="px">
										<Text fontSize="sm" fontWeight="semibold" color="muted">
											Travel Distance
										</Text>
										<Text>
											{isTruthy(
												template.daywise_schedule_section_settings
													?.hide_travel_distance
											)
												? "Always Hide"
												: "Always Show"}
										</Text>
									</Stack>
								</Stack>
							</Stack>
							<Stack gap="1">
								<Text fontWeight="semibold">
									<Icons.Support rotate="90" /> Terms Settings
								</Text>
								<Stack
									gap="4"
									padding="4"
									rounded="md"
									bgColor="default"
									borderWidth="1"
								>
									<Stack gap="px">
										<Text fontSize="sm" fontWeight="semibold" color="muted">
											Section Title
										</Text>
										<Text>{template.terms_section_settings?.title}</Text>
									</Stack>
								</Stack>
							</Stack>
							<Text color="muted" fontSize="sm">
								{joinAttributes(
									template.updated_by && template.updated_at ? (
										<>
											Updated by {template.updated_by.name},{" "}
											<RelativeTime timestamp={template.updated_at} />
										</>
									) : undefined,
									<>
										Created by {template.created_by?.name || "You"},{" "}
										<RelativeTime timestamp={template.created_at} />
									</>
								)}
							</Text>
						</Stack>
					))
				)}
			</Box>
			<Dialog
				open={isDialogOpen}
				onClose={closeDialog}
				title="Update Trip Quote Email Template"
				lg
			>
				<Dialog.Body>
					<EditTripQuoteEmailTemplate
						onCancel={() => {
							closeDialog()
						}}
						onSuccess={() => {
							closeDialog()
							mutate()
						}}
					/>
				</Dialog.Body>
			</Dialog>
		</Stack>
	)
}
function EditTripQuoteEmailTemplate({
	onCancel,
	onSuccess,
}: {
	onCancel: () => void
	onSuccess: () => void
}) {
	const xhr = useXHR()
	const id__ = useId()
	const { data } = useSWR(`/trip-quote-email-template/update?${id__}`, () =>
		xhr
			.get<{
				data: TEmailPdfTemplateCreatePayload
			}>("/trip-quote-email-templates/create")
			.then((resp) => resp.data.data)
	)
	if (!data) {
		return <Spinner alignCenter padding="4" />
	}
	return (
		<EditTripQuoteEmailTemplateForm
			initialValues={data}
			onCancel={onCancel}
			onSubmit={async (payload) => {
				await xhr.post("/trip-quote-email-templates", payload)
				onSuccess()
			}}
		/>
	)
}
