import { Helmet } from "react-helmet-async"
import { useParams } from "./../../../../router-utils"
import { CabScheduleItem } from "../../../../CabScheduling"

export default function CabScheduleDetailedPage(
	props: Omit<React.ComponentProps<typeof CabScheduleItem>, "scheduleId">
) {
	const { scheduleId } = useParams("/cab-schedules/:scheduleId")
	return (
		<>
			<Helmet>
				<title>Cab Schedule Details</title>
			</Helmet>
			<CabScheduleItem scheduleId={scheduleId} {...props} />
		</>
	)
}
