import { ForbidUnlessAuthorized, PERMISSIONS } from "../../../Auth"
import { Outlet } from "react-router-dom"

export default function Layout() {
	return (
		<ForbidUnlessAuthorized
			anyPermissions={[PERMISSIONS.MANAGE_USERS, PERMISSIONS.MANAGE_TENANTS]}
		>
			<Outlet />
		</ForbidUnlessAuthorized>
	)
}
