import { numberToLocalString } from "@sembark-travel/number-utils"
import {
	Alert,
	Box,
	Icons,
	Container,
	Inline,
	Heading,
	Dropdown,
	Stack,
	Spinner,
	Button,
	Popover,
	Text,
	joinAttributes,
	RelativeTime,
	Divider,
	Time,
} from "@sembark-travel/ui/base"
import { queryToSearch, Link, Breadcrumbs } from "@sembark-travel/ui/router"
import { useXHR } from "@sembark-travel/xhr"
import pluralize from "pluralize"
import useSWR from "swr"
import { PERMISSIONS, useCheckPermissions } from "../Auth"
import { generatePath } from "../router-utils"
import { EditTripDestinationInDialog } from "./EditItem"
import { TTripDestination } from "./store"
import { ImageMediaItem } from "./../Media"
import { PhoneNumber, PhoneNumbersForm } from "../Contacts"
import { parseDate } from "@sembark-travel/datetime-utils"

export function TripDestinationItemDetails({
	tripDestinationId,
}: {
	tripDestinationId: string | number
}) {
	const xhr = useXHR()
	const { hasPermission } = useCheckPermissions()
	const { data, error, mutate } = useSWR<TTripDestination>(
		`/trip-destinations/${tripDestinationId}`,
		() =>
			xhr
				.get(`/trip-destinations/${tripDestinationId}`, {
					params: {
						include:
							"trips_count,in_progress_trips_count,converted_trips_count",
					},
				})
				.then((resp) => resp.data.data)
	)
	if (!data && error) {
		return <Alert status="error">{error.message}</Alert>
	}
	if (!data) {
		return <Spinner padding="4" alignCenter />
	}
	const {
		id,
		name,
		short_name,
		trips_count = 0,
		latest_media,
		currency,
		phone_numbers,
		addresses,
		max_child_age,
		hotel_checkin_at,
		hotel_checkout_at,
		created_at,
		created_by,
		updated_at,
		updated_by,
		deleted_at,
		deleted_by,
	} = data
	const canUpdate = hasPermission(PERMISSIONS.ADD_TRIP_DESTINATIONS)
	return (
		<Box key={id}>
			<Breadcrumbs
				title="Details"
				items={[
					[generatePath("/org/trip-destinations"), "Trip Destinations"],
					["", name],
				]}
				actions={
					canUpdate ? (
						<Box>
							<EditTripDestinationInDialog
								destination={data}
								onSuccess={() => mutate()}
							>
								{({ onEdit }) => (
									<Dropdown alignRight>
										<Dropdown.ToggleButton level="tertiary">
											<Icons.DotsVertical />
										</Dropdown.ToggleButton>
										<Dropdown.Menu>
											<Dropdown.MenuItem onClick={() => onEdit()}>
												<Icons.Pencil /> Edit Details
											</Dropdown.MenuItem>
										</Dropdown.Menu>
									</Dropdown>
								)}
							</EditTripDestinationInDialog>
						</Box>
					) : null
				}
			/>
			<Container paddingY="6" bgColor="default" borderBottomWidth="1">
				<Inline collapseBelow="md" justifyContent="between" gap="6">
					<Stack gap="4">
						<Stack gap="2">
							<Heading as="h2">
								{name} ({short_name})
							</Heading>
							<Box>
								{joinAttributes(
									<Text as="span" title="Operational Currency">
										{currency}
									</Text>,
									<Link
										fontWeight="semibold"
										to={
											generatePath("/trips") +
											queryToSearch({
												destinations: [`${id}_${name}`],
												status: "all",
											})
										}
									>
										{numberToLocalString(trips_count)}{" "}
										{pluralize("Trip", trips_count)}
									</Link>
								)}
							</Box>
						</Stack>
						<Stack gap="2">
							<Inline gap="4" alignItems="center">
								<Inline gap="2" alignItems="center">
									<Text>Address(es):</Text>
									{addresses?.length ? (
										<Inline gap="4">
											{addresses.map((a) => (
												<Text
													key={a.id}
													title={
														a.is_billing ? "Billing Address" : "Contact Address"
													}
												>
													{a.is_billing ? (
														<Icons.BankNotes />
													) : (
														<Icons.LifeBouy />
													)}{" "}
													{a.name}
												</Text>
											))}
										</Inline>
									) : (
										<Text color="muted">Primary Address</Text>
									)}
								</Inline>
							</Inline>
							<Inline gap="2" alignItems="center">
								<Text>Max Child Age:</Text>
								<Text>{max_child_age} yrs</Text>
							</Inline>
							<Inline gap="2" alignItems="center">
								<Text>Default Hotel Checkin:</Text>
								<Text>
									<Time
										value={parseDate(hotel_checkin_at, "HH:mm:ss")}
										format="HH:mm"
									/>{" "}
									hrs
								</Text>
							</Inline>
							<Inline gap="2" alignItems="center">
								<Text>Default Hotel Checkout:</Text>
								<Text>
									<Time
										value={parseDate(hotel_checkout_at, "HH:mm:ss")}
										format="HH:mm"
									/>{" "}
									hrs
								</Text>
							</Inline>
							<Inline gap="4" alignItems="center">
								<Inline gap="2" alignItems="center">
									<Text>Contact Numbers:</Text>
									{phone_numbers?.length ? (
										<PhoneNumber value={phone_numbers} />
									) : (
										<Text color="muted">Not Set</Text>
									)}
								</Inline>
								{canUpdate ? (
									<Popover
										trigger="click"
										content={({ hide }) => (
											<Box padding="4">
												<Stack gap="4">
													<Text color="muted" maxWidth="sm">
														Add phone numbers that can be used in sharing of
														packages and bookings for this destination.
													</Text>
													<PhoneNumbersForm
														phoneNumbers={phone_numbers}
														onSubmit={async (values) => {
															await xhr.patch(
																`/trip-destinations/${id}/phone-numbers`,
																{
																	phone_numbers: values.phone_numbers,
																}
															)
															mutate()
															hide()
														}}
													/>
													<Text color="muted" maxWidth="sm">
														<Icons.Info opacity="50" /> Leaving empty will use
														contact details from your organization.
													</Text>
												</Stack>
											</Box>
										)}
									>
										<Button size="sm" level="tertiary">
											<Icons.Pencil />
										</Button>
									</Popover>
								) : null}
							</Inline>
							<Divider marginY="0" />
							<Inline gap="4" flexWrap="wrap">
								<Stack>
									<Text color="muted" fontSize="sm">
										Created By
									</Text>
									<Text>
										{created_by?.name}, <RelativeTime timestamp={created_at} />
									</Text>
								</Stack>
								{updated_at !== created_at && updated_by ? (
									<Stack>
										<Text color="muted" fontSize="sm">
											Last Updated By
										</Text>
										<Text>
											{updated_by?.name},{" "}
											<RelativeTime timestamp={updated_at} />
										</Text>
									</Stack>
								) : null}
								{deleted_at ? (
									<Stack color="warning">
										<Text fontSize="sm">Disabled By</Text>
										<Text>
											{deleted_by?.name},{" "}
											<RelativeTime timestamp={deleted_at} />
										</Text>
									</Stack>
								) : null}
							</Inline>
						</Stack>
					</Stack>
					<Stack gap="4" style={{ maxWidth: "700px" }}>
						<ImageMediaItem
							media={latest_media}
							canEdit={hasPermission(PERMISSIONS.MANAGE_TRIP_OWNERS)}
							help="Image must be 1200x600 JPEG/PNG file with less than 1 MB in size."
							canRemove
							crop={{
								aspect: 2 / 1,
								minHeight: 100,
							}}
							onSubmit={async (data) => {
								await xhr.patch(`/trip-destinations/${id}/image`, data)
								await mutate()
							}}
						/>
					</Stack>
				</Inline>
			</Container>
		</Box>
	)
}
