import { Helmet } from "react-helmet-async"
import { TravelActivityPricesList } from "./../../../TravelActivityPrices"

export default function TravelActivityPricesListPage() {
	return (
		<>
			<Helmet>
				<title>Travel Activity Prices</title>
			</Helmet>
			<TravelActivityPricesList />
		</>
	)
}
