import { Helmet } from "react-helmet-async"
import { TripSourcesList } from "../../../../TripSources"

export default function OrganizationTripSourcesPage() {
	return (
		<>
			<Helmet>
				<title>Trip Sources</title>
			</Helmet>
			<TripSourcesList />
		</>
	)
}
