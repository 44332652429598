import type { Omit } from "utility-types"
import type { IComment } from "../Comments"
import type { IHotel } from "../Hotels"
import type { IHotelVoucher } from "../HotelVouchers"
import type { IPaymentable, IPayment } from "../Payments"
import type { IRoomType } from "../RoomTypes"
import type { IUser } from "../Users"
import type { TChildrenArray } from "../Tourists"
import type { IQuoteHotel, IQuoteHotelExtras, ITrip } from "./../Trips/store"
import { IContact } from "../Contacts"

export type IBookingDetail = Omit<IQuoteHotel, "hotel"> & {
	room_configuration?: {
		description: string
		no_of_rooms: number
		persons_per_room: number
		adults_with_extra_bed: number
		children_with_extra_bed: number
		children_without_extra_bed: number
		per_room_details: string
		per_adult_with_extra_bed_details: string | null
		per_child_with_extra_bed_details: string | null
		per_child_without_extra_bed_details: string | null
	}
}

export type IExtraServiceBookingDetail = Omit<IQuoteHotelExtras, "hotel">

export interface IBooking extends IPaymentable {
	id: number
	checkin: string
	checkout: string
	no_of_nights: number
	no_of_adults: number
	children: TChildrenArray
	pax_string: string
	no_of_children: number
	hotel_id: number
	hotel: IHotel
	booking_stage: {
		id: number
		state:
			| "initialized"
			| "in_progress"
			| "changed"
			| "booked"
			| "dropped"
			| "trip_dropped"
		/**
		 * Title case/Display name for the stage
		 */
		title: string
		tag?: string
		created_by: IUser
		created_at: string
		comments?: string
		is_before_booked: boolean
		is_same_or_after_booked: boolean
	}
	trip_id: number
	trip?: ITrip
	booked_at: string
	booking_method?: string
	change_reason?: string
	drop_reason?: string
	currency: string
	details?: Array<IBookingDetail>
	room_nights?: Array<{
		room_type: IRoomType
		no_of_rooms: number
		dates: Array<string>
		nights: Array<number>
	}>
	extras?: Array<IExtraServiceBookingDetail>
	given_price: number
	booked_price?: number
	per_room_booked_price?: number
	per_adult_with_extra_bed_booked_price?: number
	per_child_with_extra_bed_booked_price?: number
	per_child_without_extra_bed_booked_price?: number
	booking_confirmation_id?: string
	voucher_sent_at?: string
	voucher_sent_by?: IUser
	voucher_id: string
	voucher?: IHotelVoucher
	hotel_contact?: IContact

	comments?: string
	is_shared?: boolean
	can_modify?: boolean
	can_view_prices?: boolean
	booked?: boolean
	payments?: Array<IPayment>
}

export interface ITripHotelBooking
	extends Pick<
		ITrip,
		| "id"
		| "children"
		| "destinations"
		| "start_date"
		| "end_date"
		| "days"
		| "nights"
		| "trip_source"
		| "no_of_adults"
		| "no_of_children"
		| "contact"
		| "tourist"
		| "reference_id"
		| "reservations_team"
		| "paid_payments_amount"
		| "payments_amount"
		| "cancellation_reason"
		| "converted_at"
		| "converted_by"
		| "on_hold_at"
		| "on_hold_by"
	> {
	package_price: number
	bookings: Array<IBooking>
	comments: Array<IComment>
	is_shared?: boolean
	can_modify?: boolean
	can_view_prices?: boolean
	total_payments_amount: number
	total_payments_amount_currency: string
}

export const stageToDisplayNameMapping: Record<
	IBooking["booking_stage"]["state"],
	string
> = {
	initialized: "Initialized",
	in_progress: "In Progress",
	booked: "Booked",
	changed: "Changed",
	dropped: "Dropped",
	trip_dropped: "Trip Dropped",
}

const stageToDescriptionNameMapping: Record<
	IBooking["booking_stage"]["state"],
	string
> = {
	initialized: "The boooking process is just initialized",
	in_progress: "The booking is in the process",
	booked: "Hotel has been booked",
	dropped: "Hotel dropped/canceled after booking",
	changed: "Hotel changed in the booking process",
	trip_dropped: "Booking canceled after trip drop",
}

export function getDisplayNameForStage(
	stage: keyof typeof stageToDisplayNameMapping
) {
	return stageToDisplayNameMapping[stage]
}

export function getDescriptionForStage(
	stage: keyof typeof stageToDescriptionNameMapping
) {
	return stageToDescriptionNameMapping[stage]
}
