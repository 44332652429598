import {
	addUnit,
	dateToQuery,
	dateToUTCString,
	endOf,
	formatDate,
	parseDateFromQuery,
	startOf,
	subtractUnit,
} from "@sembark-travel/datetime-utils"
import {
	Button,
	Container,
	Inline,
	Table,
	Text,
	Box,
	Icons,
	SelectInput,
	Stack,
} from "@sembark-travel/ui/base"
import {
	Search,
	useSearch,
	TSearchParams,
	areAdvancedFiltersAppliedDefault,
	ListView,
} from "@sembark-travel/ui/list"
import { useLocationQuery, Link } from "@sembark-travel/ui/router"
import { useNavigate, generatePath } from "../../../router-utils"
import { Fragment, useEffect, useMemo, useState } from "react"
import { SelectField, SelectInputField } from "@sembark-travel/ui/form"
import { Helmet } from "react-helmet-async"
import { SelectUsers, IUser } from "../../../Users"
import { PERMISSIONS, useCheckPermissions } from "../../../Auth"

const REDIRECTION_TIMEOUT_IN_SECONDS = 30

export default function TenantUsageStats() {
	const [query, setQuery] = useLocationQuery({
		toQuery: filtersToQuery,
		fromQuery: queryToFilters,
	})
	const [params, setParams] = useSearch<TFilters>(query)
	const { hasPermission } = useCheckPermissions()
	const canViewSupportUsers = hasPermission(PERMISSIONS.MANAGE_TENANTS)
	const requestParams = useMemo(() => {
		return {
			page: params.page || 1,
			q: params.q || null,
			after: dateToUTCString(params.after),
			before: dateToUTCString(params.before),
			status: params.status,
			supported_by_users: (params.supported_by_users || []).map((s) => s.id),
		}
	}, [params])
	useEffect(() => {
		setQuery(params)
	}, [params, setQuery])
	const [redirectingIn, setRedirectingIn] = useState(
		REDIRECTION_TIMEOUT_IN_SECONDS
	)
	useEffect(() => {
		const h = setInterval(() => {
			setRedirectingIn((t) => (t <= 0 ? t : t - 1))
		}, 1000)
		return () => {
			clearInterval(h)
			// reset the timer
			setRedirectingIn(REDIRECTION_TIMEOUT_IN_SECONDS)
		}
	}, [requestParams])
	const navigate = useNavigate()
	useEffect(() => {
		if (redirectingIn <= 0) {
			navigate("/dashboard")
		}
	}, [redirectingIn, navigate])
	return (
		<>
			<Helmet>
				<title>Click Usage</title>
			</Helmet>
			<Search
				initialParams={params}
				title="Click Usage"
				onSearch={setParams}
				resetParams={(params) => ({
					q: "",
					page: 1,
					after: params.after,
					before: params.before,
					intervalType: params.intervalType,
					status: "all" as const,
				})}
				Filters={Filters}
				areAdvancedFiltersApplied={(params) => {
					const { after, before, intervalType, status, ...others } = params
					return status !== "all" || areAdvancedFiltersAppliedDefault(others)
				}}
				actions={({ setSearchParams, searchParams }) => (
					<Inline alignItems="center">
						<Button
							onClick={() => {
								const after = startOf(
									subtractUnit(
										searchParams.after,
										1,
										searchParams.intervalType
									),
									searchParams.intervalType
								)
								setSearchParams({
									after,
									before: endOf(after, searchParams.intervalType),
									page: 1,
								})
							}}
						>
							<Icons.ChevronDown rotate="90" />
						</Button>
						<Box paddingX="2">
							<SelectInput
								value={searchParams.intervalType}
								onChange={(e) => {
									const type = (e.currentTarget.value ||
										"month") as typeof searchParams.intervalType
									setSearchParams({
										intervalType: type,
										after: startOf(new Date(), type),
										before: endOf(new Date(), type),
										page: 1,
									})
								}}
							>
								<option value="month">Month</option>
								<option value="week">Week</option>
								<option value="day">Day</option>
							</SelectInput>
						</Box>
						<Button
							onClick={() => {
								const after = startOf(
									addUnit(searchParams.after, 1, searchParams.intervalType),
									searchParams.intervalType
								)
								setSearchParams({
									after,
									before: endOf(after, searchParams.intervalType),
									page: 1,
								})
							}}
						>
							<Icons.ChevronDown rotate="270" />
						</Button>
					</Inline>
				)}
			>
				<Container paddingY="4">
					<Text fontWeight="semibold" fontSize="xl">
						{formatDate(params.after, "ddd D MMM")} -{" "}
						{formatDate(params.before, "ddd D MMM")}
					</Text>
					<Text fontSize="sm" color="warning">
						Redirecting in : {redirectingIn} secs
					</Text>
				</Container>
				<ListView<
					{
						id: number
						name: string
						enquiries: number
						enquiries_1: number
						enquiries_2: number
						quotes: number
						quotes_1: number
						quotes_2: number
						conversions: number
						conversions_1: number
						conversions_2: number
						users_count: number
						active_users_count: number
						supported_by_users?: Array<{ id: number; name: string }>
					},
					TFilters
				>
					params={params}
					pageKey="tenants-usage-stats"
					fetch={async (xhr) => {
						// reset the timer
						setRedirectingIn(REDIRECTION_TIMEOUT_IN_SECONDS)
						return xhr
							.get("/tenants/usage-stats", { params: requestParams })
							.then((resp) => resp.data)
					}}
					onPageChange={(page) => setParams({ ...params, page })}
				>
					{({ items }) => {
						return (
							<Table
								headers={[
									"Name",
									"Users",
									"Active",
									"Enquiries",
									"Quotes",
									"Conversions",
								]}
								alignCols={{
									1: "right",
									2: "right",
									3: "right",
									4: "right",
									5: "right",
								}}
								responsive
								bordered
								hover
								rows={items.map((d) => [
									<Stack gap="px">
										<Link
											to={generatePath("/admin/tenants/:tenantId", {
												tenantId: d.id.toString(),
											})}
											color={"accent"}
											fontWeight="semibold"
										>
											{d.name}
										</Link>
										{d.supported_by_users && canViewSupportUsers ? (
											<Text fontSize="sm" color="muted">
												{d.supported_by_users.map((s) => s.name).join(", ")}
											</Text>
										) : null}
									</Stack>,
									<Text>{d.users_count}</Text>,
									<Text>{d.active_users_count}</Text>,
									<Stats stats={[d.enquiries_2, d.enquiries_1, d.enquiries]} />,
									<Stats stats={[d.quotes_2, d.quotes_1, d.quotes]} />,
									<Stats
										stats={[d.conversions_2, d.conversions_1, d.conversions]}
									/>,
								])}
							/>
						)
					}}
				</ListView>
			</Search>
		</>
	)
}

function Filters() {
	const { hasPermission } = useCheckPermissions()
	return (
		<Stack gap="4">
			<SelectInputField label="Status" name="status">
				<option value="">All</option>
				<option value="paying">Paying</option>
				<option value="on_hold">On Hold</option>
				<option value="in_trail">In Trial</option>
			</SelectInputField>
			{hasPermission(PERMISSIONS.MANAGE_USERS) ? (
				<SelectField
					label="Supported By Members"
					select={SelectUsers}
					name="supported_by_users"
					multiple
					fetchOnMount
				/>
			) : null}
		</Stack>
	)
}

function Stats({ stats }: { stats: Array<number> }) {
	const goingDown = stats.reduce(
		(goingDown, stat, index, arr) =>
			index === 0 ? true : goingDown && stat < arr[index - 1],
		true
	)
	return (
		<Inline gap="2" alignItems="center" justifyContent="end">
			{goingDown ? (
				<Icons.AttentionSolid color="danger" title="Going Down" />
			) : null}
			{stats.map((stat, index, arr) => {
				const nextStat = arr.at(index + 1)
				return (
					<Fragment key={index}>
						<Text as="span">{stat}</Text>
						{nextStat !== undefined ? (
							nextStat > stat ? (
								<Icons.ArrowDownLeft rotate="180" color="success" />
							) : nextStat < stat ? (
								<Icons.ArrowDownLeft color="warning" rotate="270" />
							) : (
								<Icons.ArrowLeft rotate="180" color="muted" />
							)
						) : null}
					</Fragment>
				)
			})}
		</Inline>
	)
}

type TFilters = TSearchParams & {
	after: Date
	before: Date
	intervalType: "month" | "week" | "day"
	status?: "on_hold" | "paying" | "in_trial" | "all"
	supported_by_users?: Array<IUser>
}

type TFiltersInQuery = TSearchParams & {
	after?: string
	before?: string
	intervalType?: "month" | "week" | "day"
	status?: "on_hold" | "paying" | "in_trial" | "all"
	sps?: Array<string>
}

function filtersToQuery(filters: TFilters): TFiltersInQuery {
	const { q, after, before, page, intervalType, status, supported_by_users } =
		filters
	const query: TFiltersInQuery = {
		after: dateToQuery(after || startOf(new Date(), "month")),
		before: dateToQuery(before || endOf(new Date(), "month")),
		intervalType: intervalType || "week",
		status: status || "all",
	}
	if (q) {
		query.q = q
	}
	if (page) query.page = page
	if (supported_by_users?.length) {
		query.sps = supported_by_users.map((s) => `${s.id}#{s.name}`)
	}
	return query
}

function queryToFilters(query: TFiltersInQuery): TFilters {
	const { q, after, before, page, intervalType, status, sps } = query
	const filters: TFilters = {
		after: after
			? startOf(parseDateFromQuery(after), "day")
			: startOf(new Date(), intervalType || "week"),
		before: before
			? endOf(parseDateFromQuery(before), "day")
			: endOf(new Date(), intervalType || "week"),
		intervalType: intervalType || "week",
		status: status || "all",
	}
	if (q) {
		filters.q = q
	}
	if (page) filters.page = page
	if (sps?.length) {
		filters.supported_by_users = sps.map((s) => {
			const [id, name] = s.split("#")
			return { id, name } as never as IUser
		})
	}
	return filters
}
