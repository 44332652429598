import {
	Stack,
	Container,
	Heading,
	Text,
	RadioInput,
	Inline,
	Box,
	Badge,
} from "@sembark-travel/ui/base"
import { useSynedStorageState } from "../storage"
import { useEffect } from "react"

export function Apperance() {
	const [theme, setTheme] = useSynedStorageState<string>(
		"appearance-theme",
		"light",
		(t) => t,
		(t) => t
	)
	function switchTheme(theme: "light" | "dark") {
		const fn = () => setTheme(theme)
		if (!document.startViewTransition) {
			fn()
			return
		}
		document.startViewTransition(fn)
	}
	useEffect(() => {
		if (typeof document !== "undefined") {
			document.documentElement.setAttribute("data-color-theme", theme)
		}
	}, [theme])
	return (
		<Container paddingY="4">
			<Stack gap="4">
				<Heading>
					Theme Preference <Badge warning>BETA</Badge>
				</Heading>
				<Text color="muted">
					Select your prefered theme and choose how app looks to you.
				</Text>
				<Inline gap="4">
					<Box
						as="label"
						rounded="md"
						borderWidth="1"
						overflow="hidden"
						borderColor={theme === "light" ? "primary_emphasis" : "default"}
					>
						<Inline
							padding="4"
							bgColor="subtle"
							color="default"
							data-color-theme="light"
							gap="4"
						>
							<RadioInput
								name="theme"
								value="light"
								checked={theme === "light"}
								onChange={() => switchTheme("light")}
							/>
							<Stack gap="1" fontWeight="normal">
								<Text fontWeight="semibold">Light Theme</Text>
								<Text>
									Positive polar contrast with dark text on a light background
								</Text>
							</Stack>
						</Inline>
					</Box>
					<Box
						as="label"
						rounded="md"
						borderWidth="1"
						overflow="hidden"
						borderColor={theme === "dark" ? "primary_emphasis" : "default"}
					>
						<Inline
							padding="4"
							bgColor="subtle"
							color="default"
							data-color-theme="dark"
							gap="4"
						>
							<RadioInput
								name="theme"
								value="dark"
								checked={theme === "dark"}
								onChange={() => switchTheme("dark")}
							/>
							<Stack gap="1" fontWeight="normal">
								<Text fontWeight="semibold">Dark Theme</Text>
								<Text>
									Negative polar contrast with light (i.e. white) text on a dark
									background
								</Text>
							</Stack>
						</Inline>
					</Box>
				</Inline>
			</Stack>
		</Container>
	)
}
