import {
	Box,
	Container,
	Text,
	Stack,
	MoneySum,
	CheckboxInput,
	Inline,
} from "@sembark-travel/ui/base"
import { Breadcrumbs } from "@sembark-travel/ui/router"
import { useCallback, useState } from "react"
import { getTotalPriceForServices } from "../../../utils"
import { CalculatePriceForm } from "./../../../HotelPrices"
import { generatePath } from "../../../router-utils"
import { TMoney } from "@sembark-travel/money"
import { Helmet } from "react-helmet-async"
import { PERMISSIONS, useCheckPermissions } from "../../../Auth"

export default function CalculatePrice() {
	const [price, setPrice] = useState<Array<TMoney>>([])
	const handleChange = useCallback(
		(services: Parameters<typeof getTotalPriceForServices>[0]) => {
			setPrice(getTotalPriceForServices(services))
		},
		[]
	)
	const [showBookedPrices, setShowBookedPrices] = useState(false)
	const { hasPermission } = useCheckPermissions()
	return (
		<>
			<Helmet>
				<title>Calculate Hotel Prices</title>
			</Helmet>
			<Breadcrumbs
				title="Calculate Price"
				items={[
					[generatePath("/hotel-prices"), "Hotel Prices"],
					["", "Calculator"],
				]}
			/>
			<Container bgColor="default" paddingY="8">
				<Stack gap="4">
					<Text>
						Please enter the desired hotel configuration to get the updated
						rates.
					</Text>
					{hasPermission(PERMISSIONS.VIEW_HOTEL_BOOKINGS) ? (
						<Inline as="label" gap="1">
							<CheckboxInput
								name="show_booked_prices"
								checked={showBookedPrices}
								onChange={() => setShowBookedPrices((b) => !b)}
							/>
							<Text as="span">Show Booking Prices</Text>
						</Inline>
					) : null}
					<Box>
						<CalculatePriceForm
							onChange={handleChange}
							tripDestinations={[]}
							noOfAdults={null}
							children={null}
							showBookedPrices={showBookedPrices}
						/>
					</Box>
					<Box as="footer" textAlign="right">
						<Box
							as="mark"
							display="inlineBlock"
							fontSize="xl"
							fontWeight="semibold"
							rounded="md"
							paddingX="4"
							paddingY="2"
						>
							Total Price: <MoneySum money={price} />
						</Box>
					</Box>
				</Stack>
			</Container>
		</>
	)
}
