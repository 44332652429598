import { Helmet } from "react-helmet-async"
import {
	defer,
	LoaderFunctionArgs,
	useLoaderData,
	ShouldRevalidateFunction,
	Outlet,
	Await,
} from "react-router-dom"
import {
	detailsXHR,
	ITrip,
	getCacheKeyForTripDetails,
	useTrip,
	TripDetailsHeader,
} from "../../../../Trips"
import { xhr } from "@sembark-travel/xhr"
import { generatePath, redirect, useParams } from "../../../../router-utils"
import { Suspense } from "react"
import {
	Badge,
	Box,
	Spinner,
	Tabs,
	TabsList,
	TabContent,
	Alert,
} from "@sembark-travel/ui/base"
import { mutate } from "swr"
import { PERMISSIONS, useAuthUser, useCheckPermissions } from "../../../../Auth"
import {
	Breadcrumbs,
	NavLink,
	Link,
	ButtonLink,
} from "@sembark-travel/ui/router"

function swrLoaderData<T = unknown>(
	cacheKey: string,
	fetcher: () => Promise<T>
): Promise<T | undefined> {
	return mutate(
		cacheKey,
		async function getLoaderData(details?: T) {
			if (details) {
				// mutate the cache
				fetcher().then((data) => mutate(cacheKey, data, { revalidate: false }))
				// return the existing data
				return details
			}
			return fetcher()
		},
		{ revalidate: false }
	)
}

export async function Loader({ params }: LoaderFunctionArgs) {
	if (!params.tripId) {
		return redirect("/trips")
	}
	const { tripId } = params
	const cacheKey = getCacheKeyForTripDetails(tripId)
	return defer({
		trip: swrLoaderData(cacheKey, async () => detailsXHR(xhr).getTrip(tripId)),
	})
}

export const ShouldRevalidate: ShouldRevalidateFunction = () => {
	return false
}

export default function TripDetailsLayoutPage() {
	const { tripId } = useParams("/trips/:tripId")
	const { trip, fetchTrip: onChange } = useTrip(tripId, {
		revalidateOnMount: false,
	})
	const loader = useLoaderData() as { trip: Promise<ITrip> }
	return (
		<>
			<Helmet>
				<title>Trip Details</title>
			</Helmet>
			<Suspense fallback={<Skeleton />}>
				<Await resolve={loader.trip}>
					{trip ? (
						<TripDetailsLayout trip={trip} onChange={onChange}>
							<Outlet context={{ trip, onChange }} />
						</TripDetailsLayout>
					) : (
						<Skeleton />
					)}
				</Await>
			</Suspense>
		</>
	)
}

function Skeleton() {
	return (
		<Box>
			<Spinner padding="4" alignCenter />
		</Box>
	)
}

function TripDetailsLayout({
	trip,
	onChange,
	children,
}: {
	trip: ITrip
	onChange: () => void
	children: React.ReactNode
}) {
	const { user } = useAuthUser()
	const { hasAnyPermission } = useCheckPermissions()
	const canViewTripVoucher = Boolean(user?.tenant?.feature_flags?.trip_voucher)
	const { convertiable_shared_trip_quote, owner_quote_request, converted_at } =
		trip
	return (
		<>
			<Helmet>
				<title>Trip Details</title>
			</Helmet>
			<Breadcrumbs
				title="Trip Details"
				items={[
					[generatePath("/trips"), "Trips"],
					["", "Current"],
				]}
			/>
			<TripDetailsHeader trip={trip} onChange={onChange} />
			<Tabs layout="col" borderTopWidth="1">
				{trip.total_quotes ? (
					<TabsList>
						<NavLink
							to={generatePath("/trips/:tripId", { tripId: String(trip.id) })}
							end
						>
							Basic Details
						</NavLink>
						<NavLink
							to={generatePath("/trips/:tripId/quotes", {
								tripId: String(trip.id),
							})}
						>
							All Quotes <Badge outlined>{trip.total_quotes}</Badge>
						</NavLink>
						{trip.converted_at || trip.on_hold_at ? (
							<>
								<NavLink
									to={generatePath("/trips/:tripId/services-bookings", {
										tripId: String(trip.id),
									})}
								>
									Services Bookings
								</NavLink>
								<NavLink
									to={generatePath("/trips/:tripId/accounting", {
										tripId: String(trip.id),
									})}
								>
									Accounting
								</NavLink>
							</>
						) : null}
						{!trip.converted_at &&
						!trip.on_hold_at &&
						!trip.cancellation_reason &&
						!convertiable_shared_trip_quote &&
						hasAnyPermission(
							PERMISSIONS.CREATE_QUOTE,
							PERMISSIONS.VIEW_TENANT_CONNECTIONS
						) ? (
							<NavLink
								to={generatePath("/trips/:tripId/select-quote", {
									tripId: String(trip.id),
								})}
							>
								New Quote
							</NavLink>
						) : null}
						{(trip.converted_at || trip.on_hold_at) &&
						!trip.cancellation_reason &&
						canViewTripVoucher ? (
							<NavLink
								to={generatePath("/trips/:tripId/docs", {
									tripId: String(trip.id),
								})}
							>
								Docs
							</NavLink>
						) : null}
						<NavLink
							to={generatePath("/trips/:tripId/activities", {
								tripId: String(trip.id),
							})}
						>
							Activities
						</NavLink>
					</TabsList>
				) : null}
				<TabContent active>
					{!converted_at &&
					convertiable_shared_trip_quote &&
					owner_quote_request ? (
						<Alert type="assertive" status="success" title="Convertible">
							<Box as="p">
								The trip has been converted by{" "}
								{owner_quote_request.from.short_name} using your quote{" "}
								<Link
									to={generatePath("/trips/:tripId/quotes/:quoteId", {
										tripId: String(trip.id),
										quoteId: String(convertiable_shared_trip_quote.id),
									})}
								>
									#{convertiable_shared_trip_quote.id}
								</Link>
								. So you are requested to mark the trip as converted.
							</Box>
							<ButtonLink
								to={
									generatePath("/trips/:tripId/convert", {
										tripId: trip.id.toString(),
									}) + `?using=${convertiable_shared_trip_quote.id}`
								}
							>
								Convert Trip
							</ButtonLink>
						</Alert>
					) : null}
					{children}
				</TabContent>
			</Tabs>
		</>
	)
}
