import {
	Box,
	Button,
	Icons,
	Table,
	Dropdown,
	TabContent,
	TabItem,
	Tabs,
	TabsList,
} from "@sembark-travel/ui/base"
import { showSnackbar } from "@sembark-travel/ui/snackbar"
import {
	useSearch,
	TSearchParams,
	areAdvancedFiltersAppliedDefault,
	Search,
	ListView,
} from "@sembark-travel/ui/list"
import { Link, useLocationQuery } from "@sembark-travel/ui/router"
import { IListResponse, useXHR, XHRInstance } from "@sembark-travel/xhr"
import React, { Fragment, useEffect } from "react"
import { T_BankAccount as BankAccountType } from "../../BankAccounts"
import type { ITenant } from "./../Tenants"

type T_BankAccount = BankAccountType & {
	tenant: ITenant
	payment_gateway_linked_account?: {
		id: number
		gateway: "razorpay"
		gateway_account_id: string
	}
}

function XHR(xhr: XHRInstance) {
	return {
		async getBankAccounts(
			params?: unknown
		): Promise<IListResponse<T_BankAccount>> {
			return xhr
				.get("/accounting/bank-accounts", { params })
				.then((resp) => resp.data)
		},
	}
}

type TFilters = TSearchParams & {
	status?: "all" | "new" | "in_progress" | "verified" | "rejected"
}

export function BankAccountsList({ actions }: { actions?: React.ReactNode }) {
	const [query, setQuery] = useLocationQuery<TFilters>()
	const [params, setParams] = useSearch({
		...query,
		status: query.status || "new",
	})
	useEffect(() => {
		setQuery(params)
	}, [params, setQuery])
	const xhr = useXHR()
	return (
		<Fragment>
			<Search
				initialParams={params}
				onSearch={(params) => {
					setParams({ ...params, page: 1 })
				}}
				title="Bank Accounts"
				resetParams={(params) => ({
					q: "",
					status: params.status,
				})}
				areAdvancedFiltersApplied={(params) => {
					const { status, ...otherParams } = params
					return areAdvancedFiltersAppliedDefault(otherParams)
				}}
				actions={
					<>
						{actions}
						{params.status === "new" ? (
							<Button
								level="primary"
								onClick={() => {
									if (
										window.confirm(
											'An email will be sent to your email address with the file and all these accounts will be moved to the "In-Progress" stage. Are you sure you want to continue ?'
										)
									) {
										xhr
											.get(`/accounting/export-bank-accounts/`, { params })
											.then((resp) => {
												const data = resp.data
												showSnackbar(
													data.message ||
														"Please check your inbox for the export file."
												)
											})
											.catch((e) => {
												alert(
													e.message ||
														"Unable to export. Please try again later."
												)
											})
									}
								}}
							>
								Export as CSV
							</Button>
						) : null}
					</>
				}
			>
				{({ searchParams, setSearchParams }) => (
					<Tabs>
						<TabsList>
							<TabItem
								active={searchParams.status === "new"}
								onClick={() =>
									setSearchParams({
										...searchParams,
										status: "new",
									})
								}
							>
								New
							</TabItem>
							<TabItem
								active={searchParams.status === "in_progress"}
								onClick={() =>
									setSearchParams({
										...searchParams,
										status: "in_progress",
									})
								}
							>
								In-Progress
							</TabItem>
							<TabItem
								active={searchParams.status === "verified"}
								onClick={() =>
									setSearchParams({
										...searchParams,
										status: "verified",
									})
								}
							>
								Verified
							</TabItem>
							<TabItem
								active={searchParams.status === "rejected"}
								onClick={() =>
									setSearchParams({
										...searchParams,
										status: "rejected",
									})
								}
							>
								Rejected
							</TabItem>
							<TabItem
								active={searchParams.status === "all"}
								onClick={() =>
									setSearchParams({
										...searchParams,
										status: "all",
									})
								}
							>
								All
							</TabItem>
						</TabsList>
						<TabContent>
							<ListView<T_BankAccount>
								pageKey={`/admin/bank-accounts`}
								params={searchParams}
								fetch={(xhr, params) =>
									XHR(xhr).getBankAccounts({
										...params,
									})
								}
								onPageChange={(page) => setParams({ ...params, page })}
							>
								{({ items: bankAccounts, refresh }) => (
									<Table
										headers={["Name", "Account", "", ""]}
										hover
										bordered
										alignCols={{ 3: "right" }}
										rows={bankAccounts.map((r) => [
											<Name tenant={r.tenant} />,
											<Box>
												<Box>{r.account_number}</Box>
												<Box fontSize="sm">
													{r.beneficiary_name} - {r.branch_ifsc_code} -{" "}
													{r.branch_swift_code}
												</Box>
											</Box>,
											r.payment_gateway_linked_account ? (
												<Box>
													<Box>{r.payment_gateway_linked_account.gateway}</Box>
													{r.payment_gateway_linked_account.gateway_account_id}
												</Box>
											) : null,
											<ActionMenu bankAccount={r} onChange={refresh} />,
										])}
									/>
								)}
							</ListView>
						</TabContent>
					</Tabs>
				)}
			</Search>
		</Fragment>
	)
}

function Name({ tenant }: { tenant: ITenant }) {
	return (
		<Box title={tenant.description} display="flex" alignItems="center">
			{tenant.profile_image_url ? (
				<Box
					as="img"
					src={tenant.profile_image_url}
					marginRight="2"
					style={{ width: "24px", height: "24px" }}
				/>
			) : null}
			<Link to={tenant.id.toString()} anchored>
				{tenant.short_name || tenant.name}
			</Link>
		</Box>
	)
}

export function ActionMenu({
	bankAccount,
	onChange,
}: {
	bankAccount: T_BankAccount
	onChange: () => void
}) {
	const xhr = useXHR()
	const { status } = bankAccount
	return status.name !== "verified" && status.name !== "rejected" ? (
		<Dropdown alignRight>
			<Dropdown.Toggle>
				<Icons.DotsVertical />
			</Dropdown.Toggle>
			<Dropdown.Menu>
				{status.name === "new" || status.name === "in_progress" ? (
					<>
						{status.name === "new" ? (
							<Dropdown.MenuItem
								onClick={async () => {
									await xhr.post(`/accounting/bank-account-status`, {
										status: "in_progress",
										accounts: [bankAccount.id],
									})
									showSnackbar(
										`${bankAccount.beneficiary_name} - ${bankAccount.account_number} moved to In-Progress stage`
									)
									onChange()
								}}
							>
								<Icons.Refresh /> Move to In-Progress
							</Dropdown.MenuItem>
						) : null}
						<Dropdown.MenuItem
							color="success"
							onClick={async () => {
								const payment_gateway_linked_account_id = window.prompt(
									"Please provide the payment gateway account id."
								)
								if (payment_gateway_linked_account_id) {
									await xhr.post(
										`/accounting/bank-accounts/${bankAccount.id}/verify`,
										{
											payment_gateway_linked_account_id,
										}
									)
									showSnackbar(
										`${bankAccount.beneficiary_name} - ${bankAccount.account_number} have been verified`
									)
									onChange()
								}
							}}
						>
							<Icons.BadgeCheckSolid /> Mark as Verified
						</Dropdown.MenuItem>
						<Dropdown.MenuItemDivider />
						<Dropdown.MenuItem
							color="warning"
							onClick={async () => {
								await xhr.post(`/accounting/bank-account-status`, {
									status: "rejected",
									accounts: [bankAccount.id],
								})
								showSnackbar(
									`${bankAccount.beneficiary_name} - ${bankAccount.account_number} has been rejected`
								)
								onChange()
							}}
						>
							<Icons.Ban /> Reject Account
						</Dropdown.MenuItem>
					</>
				) : null}
			</Dropdown.Menu>
		</Dropdown>
	) : null
}
