import { Container, Box } from "@sembark-travel/ui/base"
import {
	useGetBackUrlFromLocation,
	Breadcrumbs,
} from "@sembark-travel/ui/router"
import { Helmet } from "react-helmet-async"
import { NewUser } from "../../../Users"
import { useNavigate, generatePath } from "../../../router-utils"
import { useAuthUser } from "../../../Auth"

export default function NewUserPage() {
	const { user } = useAuthUser()
	const navigate = useNavigate()
	const origin = window.location.origin || undefined
	const backFromUrl = useGetBackUrlFromLocation()
	return (
		<>
			<Helmet>
				<title>Invite User</title>
			</Helmet>
			<Breadcrumbs
				title="Invite Member"
				items={[
					[
						!user?.tenant_id
							? generatePath("/admin/users")
							: generatePath("/org/users"),
						"Users",
					],
					["", "New"],
				]}
			/>
			<Box paddingX={{ md: "4" }} paddingY={{ md: "8" }}>
				<Box maxWidth="3xl" marginX="auto">
					<Box borderWidth={{ md: "1" }} rounded="md" bgColor="default">
						<Container paddingY="6">
							<NewUser
								onSuccess={(user) =>
									navigate("/users/:userId", {
										params: {
											userId: user.id.toString(),
										},
									})
								}
								onCancel={() =>
									backFromUrl
										? navigate(backFromUrl as "/org/users")
										: navigate("/org/users")
								}
								origin={origin}
							/>
						</Container>
					</Box>
				</Box>
			</Box>
		</>
	)
}
