import { Col, Container, Grid, Stack, Heading } from "@sembark-travel/ui/base"
import { Helmet } from "react-helmet-async"
import { CommentsWidget } from "../../Comments"
import { InstalmentsWidget } from "../../Payments"
import { TripSalesReportsWidget } from "../../TripSalesReports"
import { PERMISSIONS, useCheckPermissions } from "../../Auth"
import {
	CheckinCheckoutTripsWidget,
	LiveTripsWithDuePaymentsWidget,
} from "../../Trips"
import { Navigate } from "../../router-utils"

export default function Dashboard() {
	const { hasPermission, hasAnyPermission } = useCheckPermissions()
	if (hasAnyPermission(PERMISSIONS.MANAGE_TENANTS, PERMISSIONS.VIEW_TENANTS)) {
		return <Navigate to={"/admin/tenants"} replace />
	}
	return (
		<>
			<Helmet>
				<title>Dashboard</title>
			</Helmet>
			<Container paddingY="6">
				<Stack gap="8">
					<Grid gap="4">
						{hasPermission(PERMISSIONS.VIEW_TRIP_SALES_REPORTS) ? (
							<Col md={12} lg>
								<TripSalesReportsWidget />
							</Col>
						) : null}
						<Col>
							<CommentsWidget />
						</Col>
					</Grid>
					<Grid gap={{ xs: "8", lg: "4" }}>
						<Col md={12} lg>
							<Stack gap="2">
								<Heading fontSize="md">Payments</Heading>
								<Grid gap="4">
									{hasAnyPermission(
										PERMISSIONS.MANAGE_ACCOUNTING,
										PERMISSIONS.CREATE_QUOTE
									) ? (
										<Col xs={12} sm>
											<InstalmentsWidget />
										</Col>
									) : null}
									{hasAnyPermission(
										PERMISSIONS.MANAGE_ACCOUNTING,
										PERMISSIONS.MODIFY_HOTEL_BOOKINGS,
										PERMISSIONS.MODIFY_CAB_SCHEDULES
									) ? (
										<Col>
											<InstalmentsWidget debit />
										</Col>
									) : null}
								</Grid>
							</Stack>
						</Col>
						{hasPermission(PERMISSIONS.VIEW_QUERIES) ? (
							<Col>
								<Stack gap="2">
									<Heading fontSize="md">Trip Starting and Endings</Heading>
									<Grid gap="4">
										<Col>
											<CheckinCheckoutTripsWidget />
										</Col>
										<Col>
											<CheckinCheckoutTripsWidget checkouts />
										</Col>
									</Grid>
								</Stack>
							</Col>
						) : null}
					</Grid>
					<Stack gap="2">
						<Heading fontSize="md">Live Trips with Due Payments</Heading>
						<LiveTripsWithDuePaymentsWidget />
					</Stack>
				</Stack>
			</Container>
		</>
	)
}
