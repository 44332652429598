import React from "react"
import { AsyncSelect } from "@sembark-travel/ui/base"
import { Optional } from "utility-types"
import { useXHR } from "@sembark-travel/xhr"
import { AccountTypesXHR } from "./store"

export function SelectAccountType(
	props: Optional<React.ComponentProps<typeof AsyncSelect>, "fetch">
) {
	const xhr = useXHR()
	return (
		<AsyncSelect
			fetch={(q) =>
				AccountTypesXHR(xhr)
					.get({ q })
					.then((resp) => resp.data)
			}
			{...props}
		/>
	)
}
