import {
	Heading,
	Text,
	Box,
	Button,
	Table,
	AsyncSelect,
	Time,
	Inline,
	Icons,
	Tabs,
	TabsList,
	TabContent,
	Stack,
	joinAttributes,
	RelativeTime,
	Dropdown,
	Component,
	Divider,
	Badge,
	Spinner,
	useBreakpoints,
} from "@sembark-travel/ui/base"
import { Link, useLocationQuery } from "@sembark-travel/ui/router"
import {
	ListView,
	areAdvancedFiltersAppliedDefault,
	Search,
	TSearchParams,
	useSearch,
} from "@sembark-travel/ui/list"
import { Dialog, useDialog } from "@sembark-travel/ui/dialog"
import { showSnackbar } from "@sembark-travel/ui/snackbar"
import { IListResponse, useXHR, XHRInstance } from "@sembark-travel/xhr"
import React, { Fragment, useEffect, useId } from "react"
import { Omit } from "utility-types"
import { SelectUsers } from "../Users"
import { ITripPlanRequest } from "./store"
import useSWR from "swr"
import { PhoneNumber, Email } from "../Contacts"
import { useCheckPermissions, PERMISSIONS, IUser } from "../Auth"
import {
	Form,
	withServerErrors,
	validateFormValues,
	SubmissionError,
	SelectField,
	parsePhoneNumber,
	DatePickerField,
	SwitchInputField,
	GetFieldValue,
	isTruthy,
	FormSpy,
} from "@sembark-travel/ui/form"
import * as Validator from "yup"
import { AddNewTrip, ITrip } from "../Trips"
import {
	dateToQuery,
	dateToUTCString,
	endOf,
	parseDate,
	parseDateFromQuery,
	startOf,
} from "@sembark-travel/datetime-utils"
import { useNavigate } from "../router-utils"
import { EditTagsInDialog, ITag, SelectTags } from "../Tags"
import { ITripSource } from "../TripSources"
import { TTripDestination } from "../TripDestinations"
import { IRole, SelectRoles } from "../Roles"

function XHR(xhr: XHRInstance) {
	return {
		async getTripPlanRequests(
			params?: unknown
		): Promise<IListResponse<ITripPlanRequest>> {
			return xhr
				.get("/trip-plan-requests", { params })
				.then((resp) => resp.data)
		},
		async getTripPlanRequestSources(): Promise<
			Array<{ id: string; name: string }>
		> {
			return xhr
				.get("/trip-plan-request-sources")
				.then((resp) => resp.data.data)
		},
		async archiveTripPlanRequest(
			id: string | number
		): Promise<{ message: string }> {
			return xhr.delete(`/trip-plan-requests/${id}`).then((resp) => resp.data)
		},
		async restoreTripPlanRequest(
			id: string | number
		): Promise<{ message: string }> {
			return xhr
				.patch(`/trip-plan-requests/${id}/restore`)
				.then((resp) => resp.data)
		},
		async assignTripRequestsOwner(
			requestIds: Array<number>,
			ownerId?: number | null
		): Promise<IListResponse<ITripPlanRequest>> {
			return xhr
				.post("/trip-plan-request-owners", {
					items: requestIds,
					owner_id: ownerId,
				})
				.then((resp) => resp.data)
		},
		async getPayloadForTrip(requestId: number | string) {
			return xhr
				.get<{
					data: {
						trip_source: ITripSource
						reference_id: string | null
						destinations: Array<TTripDestination> | null
						start_date: string | null
						no_of_days: number
						no_of_nights: number
						no_of_adults: number
						children: Array<{ age: number; count: number }> | null
						tourist: {
							name: string
							email: string | null
							phone_numbers: Array<string> | null
						}
						comments: string | null
					}
				}>(`/trip-plan-requests/${requestId}/trip/create`)
				.then((resp) => resp.data.data)
		},
		async attachTrip(
			requestId: number | string,
			data: unknown
		): Promise<ITripPlanRequest> {
			return xhr
				.post(`/trip-plan-requests/${requestId}/trip`, data)
				.then((resp) => resp.data)
		},
	}
}

type TFilters = TSearchParams & {
	source?: string
	tags?: Array<ITag>
	owners?: Array<IUser>
	created_after?: Date
	created_before?: Date
	unassigned_only?: boolean
}

type TFiltersInQuery = TSearchParams & {
	source: "all" | "archived_only" | "new_only" | string
	tags?: Array<string>
	owners?: Array<string>
	ca?: string
	cb?: string
	unassigned_only?: 1
}

function filtersToQuery(filters: TFilters): TFiltersInQuery {
	const {
		source,
		tags,
		q,
		page,
		created_after,
		created_before,
		unassigned_only,
		owners,
	} = filters
	const query: TFiltersInQuery = { source: source || "new_only" }
	if (tags?.length) {
		query.tags = tags.map((t) => `${t.id}-${t.name}`)
	}
	if (owners?.length) {
		query.owners = owners.map((t) => `${t.id}-${t.name}`)
	}
	if (q?.length) {
		query.q = q
	}
	if (page) {
		query.page = page
	}
	if (created_after) {
		query.ca = dateToQuery(created_after)
	}
	if (created_before) {
		query.cb = dateToQuery(created_before)
	}
	if (unassigned_only) query.unassigned_only = 1
	return query
}

function queryToFilter(query: TFiltersInQuery): TFilters {
	const { source, tags, q, page, ca, cb, unassigned_only, owners } = query
	const filters: TFilters = { source: source || "new_only" }
	if (tags?.length) {
		filters.tags = tags.map((t) => {
			const [id, ...name] = t.split("-")
			return {
				id,
				name: name.join("-"),
			} as never as ITag
		})
	}
	if (owners?.length) {
		filters.owners = owners.map((t) => {
			const [id, ...name] = t.split("-")
			return {
				id,
				name: name.join("-"),
			} as never as IUser
		})
	}
	if (q?.length) {
		filters.q = q
	}
	if (page) {
		filters.page = page
	}
	if (ca) {
		filters.created_after = parseDateFromQuery(ca)
	}
	if (cb) {
		filters.created_before = parseDateFromQuery(cb)
	}
	if (unassigned_only) filters.unassigned_only = true
	return filters
}

export function TripPlanRequestsList() {
	const xhr = useXHR()
	const { hasPermission } = useCheckPermissions()
	const [query, setQuery] = useLocationQuery<TFilters, TFiltersInQuery>({
		toQuery: filtersToQuery,
		fromQuery: queryToFilter,
	})
	const [params, setParams] = useSearch<TFilters>({
		...query,
		source: query.source || "new_only",
	})
	const canAssignTeam = hasPermission(PERMISSIONS.MANAGE_TRIP_PLAN_REQUESTS)
	useEffect(() => {
		setQuery(params)
	}, [params, setQuery])
	const { data: tripPlanRequestSources } = useSWR(
		`trip-plan-request-sources`,
		() => XHR(xhr).getTripPlanRequestSources()
	)
	const { xs } = useBreakpoints()
	return (
		<Fragment>
			<Search
				initialParams={params}
				onSearch={(params) => {
					setParams({ ...params, page: 1 })
				}}
				title="Trip Plan Requests"
				resetParams={({ source }) => ({
					source,
					page: 1,
				})}
				areAdvancedFiltersApplied={(params) => {
					const { source, unassigned_only, ...otherParams } = params
					return (
						areAdvancedFiltersAppliedDefault(otherParams) ||
						Boolean(unassigned_only)
					)
				}}
				actions={
					canAssignTeam ? (
						<Component initialState={false}>
							{({ state, setState }) => (
								<>
									<Button onClick={() => setState(true)}>
										<Icons.Cog />
									</Button>
									<Dialog
										open={state}
										onClose={() => setState(false)}
										title="Auto Distribution Preferences"
									>
										<Dialog.Body>
											<DistributionPreference
												onSuccess={() => setState(false)}
												onCancel={() => setState(false)}
											/>
										</Dialog.Body>
									</Dialog>
								</>
							)}
						</Component>
					) : null
				}
				Filters={Filters}
			>
				{({ searchParams, setSearchParams }) => (
					<Tabs>
						<TabsList>
							<TabLink
								params={searchParams}
								setParams={setSearchParams}
								source="new_only"
								key="new_only"
							>
								New
							</TabLink>
							<TabLink
								params={searchParams}
								setParams={setSearchParams}
								source="archived_only"
								key="archived_only"
							>
								Archived
							</TabLink>
							{tripPlanRequestSources?.map((a) => (
								<TabLink
									key={a.name}
									params={searchParams}
									setParams={setSearchParams}
									source={a.name}
								>
									<Box
										style={{ maxWidth: "100px" }}
										textOverflow="truncate"
										title={a.name}
									>
										{a.name}
									</Box>
								</TabLink>
							))}
							<TabLink
								params={searchParams}
								setParams={setSearchParams}
								source="all"
								key="all_all"
							>
								All
							</TabLink>
						</TabsList>
						<TabContent style={{ minHeight: "50vh" }}>
							<ListView<ITripPlanRequest, TFilters>
								params={params}
								pageKey="trip-plan-requests"
								swrConfig={{ revalidateOnFocus: true }}
								fetch={(xhr, params) => {
									const {
										source,
										tags,
										owners,
										created_after,
										created_before,
										...other
									} = params || {}
									return XHR(xhr).getTripPlanRequests({
										...other,
										sources:
											source === "archived_only" || source === "new_only"
												? ["all"]
												: source
													? [source]
													: null,
										archived_only: source === "archived_only" ? 1 : null,
										new_only: source === "new_only" ? 1 : null,
										tags: tags?.map((t) => t.id),
										owners: owners?.map((t) => t.id),
										created_after: created_after
											? dateToUTCString(startOf(created_after, "day"))
											: null,
										created_before: created_before
											? dateToUTCString(endOf(created_before, "day"))
											: null,
									})
								}}
								onPageChange={(page) => setParams({ ...params, page })}
							>
								{({ items, refresh }) =>
									xs ? (
										<Box>
											<Stack gap="2" as="ol">
												{items.map((r) => (
													<Box
														as="li"
														key={r.id}
														bgColor="default"
														padding="4"
														rounded="md"
														borderWidth="1"
													>
														<Box style={{ float: "right" }} paddingLeft="2">
															<Actions
																request={r}
																onChange={refresh}
																canAssignTeam={canAssignTeam}
															/>
														</Box>
														<Stack gap="2">
															<GuestDetails
																request={r}
																showFullDetailsInDialog
																showSource={
																	params.source === "all" &&
																	Number(tripPlanRequestSources?.length || 0) >
																		1
																}
															/>
															{canAssignTeam ? (
																<Inline gap="1">
																	<Box>
																		<Icons.User
																			title="Team Member"
																			color="muted"
																		/>
																	</Box>
																	<Box flex="1" minWidth="0">
																		{r.owner ? (
																			<Stack>
																				{r.owner.name}
																				{r.assigned_at ? (
																					<Box>
																						<RelativeTime
																							fontSize="sm"
																							timestamp={r.assigned_at}
																							color="muted"
																						/>
																					</Box>
																				) : null}
																			</Stack>
																		) : (
																			<Text color="muted">Unassigned</Text>
																		)}
																	</Box>
																</Inline>
															) : null}
															<Stack gap="1">
																<i>
																	- <RelativeTime timestamp={r.created_at} />
																</i>
																{r.deleted_at ? (
																	<Text color="warning">
																		<Icons.Trash title="Archived" />{" "}
																		{r.deleted_by?.name || "N/A"},{" "}
																		<RelativeTime timestamp={r.deleted_at} />
																	</Text>
																) : null}
															</Stack>
														</Stack>
													</Box>
												))}
											</Stack>
										</Box>
									) : (
										<Table
											headers={["Contact", "Travel Details", "Comments"].concat(
												canAssignTeam ? ["Team", ""] : [""]
											)}
											alignCols={{ 3: "right", 4: "right" }}
											hover
											bordered
											responsive
											rows={items.map((r) =>
												[
													<GuestDetails
														request={r}
														showFullDetailsInDialog
														showSource={
															params.source === "all" &&
															Number(tripPlanRequestSources?.length || 0) > 1
														}
													/>,
													<Stack gap="px" maxWidth="md" wordBreak="words">
														{r.destination ? (
															<Text fontWeight="semibold">{r.destination}</Text>
														) : null}
														<Inline gap="px">
															{joinAttributes(
																r.start_date ? (
																	<Time
																		value={parseDate(
																			r.start_date,
																			"YYYY-MM-DD"
																		)}
																	/>
																) : null,
																r.no_of_days
																	? `${r.no_of_days - 1}N, ${r.no_of_days}D`
																	: null
															)}
														</Inline>
														{r.hotel_preference ? (
															<Text maxWidth="xs" fontSize="sm">
																{r.hotel_preference}
															</Text>
														) : null}
														{r.extra_fields ? (
															<Stack>
																{Object.keys(r.extra_fields).map((key) => (
																	<Text key={key}>
																		{key}:{" "}
																		{
																			JSON.stringify(
																				(r.extra_fields?.[key] as never) || ""
																			) as string
																		}
																	</Text>
																))}
															</Stack>
														) : null}
													</Stack>,
													<Stack gap="2">
														{r.comments ? (
															<Box
																maxWidth="xs"
																whiteSpace="preserveLine"
																fontSize="sm"
															>
																{r.comments}
															</Box>
														) : null}
														<i>
															- <RelativeTime timestamp={r.created_at} />
														</i>
														{r.deleted_at ? (
															<Text color="warning">
																<Icons.Trash title="Archived" />{" "}
																{r.deleted_by?.name || "N/A"},{" "}
																<RelativeTime timestamp={r.deleted_at} />
															</Text>
														) : null}
													</Stack>,
												]
													.concat(
														canAssignTeam
															? [
																	<Box>
																		<Box>
																			{r.owner ? (
																				<Stack gap="1">
																					{r.owner.name}
																					{r.assigned_at ? (
																						<Box>
																							<RelativeTime
																								timestamp={r.assigned_at}
																							/>
																						</Box>
																					) : null}
																				</Stack>
																			) : (
																				<Box>-</Box>
																			)}
																		</Box>
																	</Box>,
																]
															: []
													)
													.concat([
														<Actions
															request={r}
															onChange={refresh}
															canAssignTeam={canAssignTeam}
														/>,
													])
											)}
										/>
									)
								}
							</ListView>
						</TabContent>
					</Tabs>
				)}
			</Search>
		</Fragment>
	)
}

function GuestDetails({
	request: r,
	showSource,
	showFullDetailsInDialog,
}: {
	request: ITripPlanRequest
	showSource?: boolean
	showFullDetailsInDialog?: boolean
}) {
	return (
		<Stack gap="2">
			<Stack gap="1">
				{showSource ? (
					<Inline alignItems="center" color="muted" gap="1" fontSize="sm">
						<Icons.ChevronDown rotate="270" title="Source" />
						<Text>{r.trip_source}</Text>
					</Inline>
				) : null}
				<Inline gap="1" alignItems="baseline">
					<Component initialState={false}>
						{({ state, setState }) => (
							<>
								<a
									href="#view-details"
									onClick={(e) => {
										e.preventDefault()
										if (showFullDetailsInDialog) {
											setState(true)
										}
									}}
								>
									<Heading
										as="h4"
										fontSize="md"
										color={showFullDetailsInDialog ? "accent" : undefined}
									>
										{r.name}
									</Heading>
								</a>
								<Dialog
									open={state}
									onClose={() => setState(false)}
									title="Trip Plan Request Details"
								>
									<Dialog.Body>
										<TripPlanRequestDetails request={r} />
									</Dialog.Body>
									<Dialog.Footer>
										<Button onClick={() => setState(false)}>Ok</Button>
									</Dialog.Footer>
								</Dialog>
							</>
						)}
					</Component>
					{r.no_of_adults || r.no_of_adults ? (
						<Text fontWeight="semibold">
							(
							{[
								r.no_of_adults ? `${r.no_of_adults}A` : null,
								r.no_of_children ? `${r.no_of_children}C` : null,
							]
								.filter(Boolean)
								.join(" + ")}
							)
						</Text>
					) : null}
				</Inline>
			</Stack>
			<Stack gap="px">
				{r.phone_number ? (
					<Box>
						<PhoneNumber value={r.phone_number} />
					</Box>
				) : null}
				{r.email ? (
					<Box>
						<Email value={r.email} />
					</Box>
				) : null}
			</Stack>
			{r.tags?.length ? (
				<Inline gap="1">
					<Box>
						<Icons.Tags color="muted" />
					</Box>
					<Inline gap="1" flexWrap="wrap">
						{r.tags.map((t) => (
							<Badge key={t.id}>{t.name}</Badge>
						))}
					</Inline>
				</Inline>
			) : null}
		</Stack>
	)
}

function Actions({
	request: r,
	onChange: refresh,
	canAssignTeam,
}: {
	request: ITripPlanRequest
	onChange: () => void
	canAssignTeam: boolean
}) {
	const xhr = useXHR()
	const navigate = useNavigate()
	return (
		<Inline justifyContent="end" alignItems="center" gap="2">
			{r.trip_id ? (
				<Link
					to={`/trips/${r.trip_id}`}
					color="accent"
					fontWeight="semibold"
					anchored
				>
					#&nbsp;{r.trip_id}
				</Link>
			) : !r.deleted_at ? (
				<CreateNewQueryFromRequestInDialog
					request={r}
					onSuccess={(trip) => {
						navigate("/trips/:tripId", {
							params: { tripId: trip.id.toString() },
						})
					}}
				>
					{({ create }) => (
						<Button title="Create New Query" size="sm" onClick={create}>
							<Icons.Plus />
						</Button>
					)}
				</CreateNewQueryFromRequestInDialog>
			) : null}
			<Box>
				<Component initialState={false}>
					{({ state: isEditing, setState: changeIsEditing }) => (
						<>
							<Dialog
								open={isEditing}
								onClose={() => changeIsEditing(false)}
								title="Assign Team Member"
								sm
							>
								<Dialog.Body>
									<Form<{ owner: typeof r.owner }>
										initialValues={{
											owner: r.owner,
										}}
										validate={validateFormValues(
											Validator.object().shape({
												owner: Validator.object()
													.nullable(true)
													.typeError("Please select a user to assign."),
											})
										)}
										onSubmit={withServerErrors(async (values) => {
											const { owner } = values
											await XHR(xhr).assignTripRequestsOwner([r.id], owner?.id)
											changeIsEditing(false)
											refresh()
											if (!owner) {
												showSnackbar("Team member remove from the request.")
											} else {
												showSnackbar("Team member assigned to the request.")
											}
										})}
										subscription={{
											submitting: true,
										}}
									>
										{({ submitting, handleSubmit }) => (
											<form noValidate onSubmit={handleSubmit}>
												<SelectField
													select={SelectUsers}
													name="owner"
													fetchOnMount
													multiple={false}
													label="Select Team Member"
												/>
												<Divider sm />
												<Stack gap="4">
													<SubmissionError />
													<Inline gap="4">
														<Button type="submit" disabled={submitting}>
															{submitting ? "Updating..." : "Assign"}
														</Button>
														<Button
															type="button"
															disabled={submitting}
															onClick={() => changeIsEditing(false)}
														>
															Cancel
														</Button>
													</Inline>
												</Stack>
											</form>
										)}
									</Form>
								</Dialog.Body>
							</Dialog>
							<EditTagsInDialog
								type="trip-plan-request"
								tags={r.tags}
								itemId={r.id}
								onSuccess={refresh}
							>
								{({ onEdit: editTags }) => (
									<Dropdown alignRight>
										<Dropdown.ToggleButton level="tertiary" size="sm">
											<Icons.DotsVertical />
										</Dropdown.ToggleButton>
										<Dropdown.Menu>
											<Dropdown.MenuItem onClick={() => editTags()}>
												<Icons.Tags /> Edit Tags
											</Dropdown.MenuItem>
											{!r.trip_id ? (
												<>
													{canAssignTeam ? (
														<Dropdown.MenuItem
															onClick={() => changeIsEditing(true)}
														>
															<Icons.User /> Assign Team
														</Dropdown.MenuItem>
													) : null}
													<Dropdown.MenuItemDivider />
													{!r.deleted_at ? (
														<Dropdown.MenuItem
															onClick={async () => {
																if (
																	window.confirm(
																		"Are you sure you want to archive this plan request ? You can restore it any time."
																	)
																) {
																	const { message } = await XHR(
																		xhr
																	).archiveTripPlanRequest(r.id)
																	refresh()
																	showSnackbar(
																		message || "Request archived successfully"
																	)
																}
															}}
														>
															<Icons.Trash color="danger" /> Archive Request
														</Dropdown.MenuItem>
													) : (
														<Dropdown.MenuItem
															onClick={async () => {
																if (
																	window.confirm(
																		"Are you sure you want to restore this archived trip plan request ?"
																	)
																) {
																	const { message } = await XHR(
																		xhr
																	).restoreTripPlanRequest(r.id)
																	refresh()
																	showSnackbar(
																		message || "Request restored successfully"
																	)
																}
															}}
														>
															<Icons.Refresh color="success" /> Restored Request
														</Dropdown.MenuItem>
													)}
												</>
											) : null}
										</Dropdown.Menu>
									</Dropdown>
								)}
							</EditTagsInDialog>
						</>
					)}
				</Component>
			</Box>
		</Inline>
	)
}

function Filters() {
	return (
		<Stack gap="4">
			<SelectField
				label="Tags"
				name="tags"
				select={SelectTags}
				type="trip-plan-request"
				fetchOnMount
				multiple
			/>
			<Stack gap="1">
				<label htmlFor="field_created_after">Created During</label>
				<Box display="flex" alignItems="center">
					<DatePickerField name="created_after" dateFormat="DD MMM, YYYY" />
					<Box>
						<Icons.SwitchHorizontal />
					</Box>
					<DatePickerField
						name="created_before"
						rightAlign
						dateFormat="DD MMM, YYYY"
					/>
				</Box>
			</Stack>
			<SelectField
				label="Team Members"
				name="owners"
				select={SelectUsers}
				multiple
			/>
			<SwitchInputField label="Unassigned Team" name="unassigned_only" />
		</Stack>
	)
}

function TripPlanRequestDetails({ request }: { request: ITripPlanRequest }) {
	return (
		<Inline gap="6" flexWrap="wrap">
			<Stack gap="1">
				<Heading fontSize="base" color="primary">
					Guest Details
				</Heading>
				<GuestDetails request={request} showSource={false} />
			</Stack>
			<Stack gap="1">
				<Heading fontSize="base" color="primary">
					Destination
				</Heading>
				<Stack gap="px">
					{request.destination ? (
						<Text fontWeight="semibold" fontSize="md">
							{request.destination}
						</Text>
					) : null}
					<Inline gap="px">
						{joinAttributes(
							request.start_date ? (
								<Time value={parseDate(request.start_date, "YYYY-MM-DD")} />
							) : null,
							request.no_of_days
								? `${request.no_of_days - 1}N,${request.no_of_days}D`
								: null
						)}
					</Inline>
				</Stack>
			</Stack>
			<Stack gap="1" maxWidth="sm" wordBreak="words">
				<Heading fontSize="base" color="primary">
					More Details
				</Heading>
				<Stack gap="px">
					{request.comments ? (
						<Box maxWidth="xs" whiteSpace="preserveLine">
							{request.comments}
						</Box>
					) : null}
					{request.hotel_preference ? (
						<Text>{request.hotel_preference}</Text>
					) : null}
					{request.extra_fields ? (
						<Stack>
							{Object.keys(request.extra_fields).map((key) => (
								<Text key={key}>
									{key}:{" "}
									{
										JSON.stringify(
											(request.extra_fields?.[key] as never) || ""
										) as string
									}
								</Text>
							))}
						</Stack>
					) : null}
					<Box>
						<Badge success>{request.trip_source}</Badge>
					</Box>
				</Stack>
			</Stack>
		</Inline>
	)
}

function CreateNewQueryFromRequestInDialog({
	children,
	request,
	onSuccess,
}: {
	request: ITripPlanRequest
	children: (props: { create: () => void }) => React.ReactNode
	onSuccess: (trip: ITrip) => void
}) {
	const [isOpenDialog, openDialog, closeDialog] = useDialog()
	return (
		<>
			{children({ create: openDialog })}
			<Dialog
				open={isOpenDialog}
				onClose={closeDialog}
				title="Create New Query from Request"
				lg
			>
				<Dialog.Body>
					<TripPlanRequestDetails request={request} />
					<Divider />
					<CreateNewQueryFromRequest
						request={request}
						onSuccess={(trip) => {
							closeDialog()
							onSuccess(trip)
						}}
						onCancel={closeDialog}
					/>
				</Dialog.Body>
			</Dialog>
		</>
	)
}

function CreateNewQueryFromRequest({
	request,
	onSuccess,
	onCancel,
}: {
	request: ITripPlanRequest
	onSuccess: (trip: ITrip) => void
	onCancel: () => void
}) {
	const xhr = useXHR()
	const requestId = useId()
	const { data } = useSWR(
		`trip-plan-request-to-trip/${request.id}?${requestId}`,
		() =>
			XHR(xhr)
				.getPayloadForTrip(request.id)
				.then((payload) => {
					const parsedPhoneNumbers = payload.tourist.phone_numbers?.length
						? payload.tourist.phone_numbers.map((number) =>
								parsePhoneNumber(number, "IN")
							)
						: []
					return {
						reference_id: payload.reference_id || "",
						start_date: payload.start_date
							? startOf(parseDate(payload.start_date, "YYYY-MM-DD"), "day")
							: undefined,
						no_of_days: payload.no_of_days,
						no_of_nights: payload.no_of_nights,
						destinations: payload.destinations || [],
						no_of_adults: payload.no_of_adults,
						children: payload.children || [],
						trip_source: payload.trip_source,
						comments: payload.comments || "",
						tourist: {
							name: request.name,
							email: request.email || "",
							phone_numbers: parsedPhoneNumbers
								.map((parsedPhoneNumber) =>
									parsedPhoneNumber?.nationalNumber
										? {
												is_primary: true,
												country_code: parsedPhoneNumber?.country,
												number: parsedPhoneNumber?.nationalNumber,
												phone_number: parsedPhoneNumber?.number,
											}
										: undefined
								)
								.filter((p): p is NonNullable<typeof p> => Boolean(p)),
						},
						trip_source_contact: undefined,
					}
				})
	)

	if (!data) {
		return <Spinner padding="4" alignCenter />
	}

	return (
		<AddNewTrip
			initialValues={data}
			onSuccess={async (trip) => {
				return XHR(xhr)
					.attachTrip(request.id, { trip_id: trip.id })
					.then(() => {
						onSuccess(trip)
						showSnackbar(`Query (ID: ${trip.id}) Initiated for this request.`)
					})
					.catch((e) => {
						alert(e.message || "Something went wrong")
					})
			}}
			onCancel={onCancel}
		/>
	)
}

export function SelectTripPlanRequests(
	props: Omit<React.ComponentProps<typeof AsyncSelect>, "fetch">
) {
	const xhr = useXHR()
	return (
		<AsyncSelect
			multiple
			fetch={(q) =>
				XHR(xhr)
					.getTripPlanRequests({ q })
					.then((resp) => resp.data)
			}
			{...props}
		/>
	)
}

function TabLink({
	params,
	setParams,
	children,
	source,
}: {
	params: TFilters
	setParams: (params: TFilters) => void
	children: React.ReactNode
	showCount?: boolean
	extraParams?: unknown
	source?: string
}) {
	return (
		<li className={(source ? params.source === source : true) ? "active" : ""}>
			<a
				href={`#${source}`}
				onClick={(e) => {
					e.preventDefault()
					const newParams = { ...params }
					if (source) {
						newParams.source = source
					}
					setParams(newParams)
				}}
			>
				{children}
			</a>
		</li>
	)
}

type TDistributionPreferenceFormData = {
	enabled?: 0 | 1
	preferences?: {
		included_roles?: Array<IRole>
		excluded_users?: Array<IUser>
	}
}
function DistributionPreference({
	onSuccess,
	onCancel,
}: {
	onSuccess: () => void
	onCancel: () => void
}) {
	const xhr = useXHR()
	const id = useId()
	const { data } = useSWR<{ data: TDistributionPreferenceFormData | null }>(
		`api/trip-plan-request-distribution-preference?${id}`,
		() =>
			xhr
				.get("/trip-plan-request-distribution-preference")
				.then((resp) => resp.data)
	)
	const { hasPermission } = useCheckPermissions()
	if (!data) {
		return <Spinner alignCenter padding="4" />
	}
	const initialValues = data.data
		? {
				enabled: 1 as const,
				preferences: data.data.preferences,
			}
		: {
				enabled: 0 as const,
				preferences: {
					included_roles: [],
					excluded_users: [],
				},
			}
	return (
		<Form<TDistributionPreferenceFormData>
			initialValues={initialValues}
			validate={validateFormValues(
				Validator.object().shape({
					preferences: Validator.object().nullable().shape({
						included_roles: Validator.array().nullable(),
						excluded_users: Validator.array().nullable(),
					}),
				})
			)}
			onSubmit={withServerErrors(async (values) => {
				if (
					isTruthy(values.enabled) &&
					!values.preferences?.included_roles?.length
				) {
					throw new Error("Please select atleast one role")
				}
				await xhr.post("/trip-plan-request-distribution-preference", {
					...values,
					preferences: !isTruthy(values.enabled)
						? null
						: {
								included_roles: values.preferences?.included_roles?.map(
									(r) => r.id
								),
								excluded_users: values.preferences?.excluded_users?.map(
									(r) => r.id
								),
							},
				})
				showSnackbar("Preferences updates.")
				onSuccess()
			})}
		>
			{({ submitting, handleSubmit }) => (
				<form onSubmit={handleSubmit}>
					<Stack gap="4">
						<Box padding="4" bgColor="primary" rounded="md">
							<SwitchInputField
								name="enabled"
								label="Enable Auto Distribution"
								help="Define how new trip plan requests should be auto distributed between your team members."
							/>
						</Box>
						<GetFieldValue<0 | 1> name="enabled">
							{({ value }) =>
								isTruthy(value) ? (
									<Stack gap="4">
										<Stack gap="px">
											<Heading fontSize="md">Configuration</Heading>
											<Text color="muted">
												Define rules to get the users, among which new requests
												should be distributed in a looped manner.
											</Text>
										</Stack>
										<Stack gap="4">
											<SelectField
												select={SelectRoles}
												name="preferences.included_roles"
												label="Users having role(s)"
												multiple
												fetchOnMount
												permissions={[PERMISSIONS.VIEW_TRIP_PLAN_REQUESTS]}
											/>
											<GetFieldValue<
												undefined | IRole[]
											> name="preferences.included_roles">
												{({ value }) => (
													<SelectField
														select={SelectUsers}
														name="preferences.excluded_users"
														label="Excluding Users"
														roles={value}
														multiple
													/>
												)}
											</GetFieldValue>
										</Stack>
									</Stack>
								) : null
							}
						</GetFieldValue>
						<Divider sm />
						<FormSpy<TDistributionPreferenceFormData>>
							{({ values }) =>
								isTruthy(values.enabled) &&
								values.preferences?.included_roles?.length ? (
									<Box
										padding="4"
										bgColor="primary"
										rounded="md"
										borderWidth="1"
										borderColor="primary"
									>
										<Stack gap="2">
											<Heading fontSize="md" color="primary">
												Configuration Summary
											</Heading>
											<Stack
												as="ol"
												listStyleType="decimal"
												paddingLeft="6"
												gap="1"
											>
												<Box as="li">
													New Requests will be auto-distributed among users who
													have one of the roles: "
													{values.preferences?.included_roles
														?.map((r) => r.name)
														?.join('", "')}
													"
												</Box>
												{values.preferences?.excluded_users?.length ? (
													<Box as="li">
														"
														{values.preferences?.excluded_users
															?.map((r) => r.name)
															?.join('", "')}
														" WILL NOT be auto assigned any requests.
													</Box>
												) : null}
												{hasPermission(PERMISSIONS.CREATE_TEAM) ? (
													<Box as="li">
														Destination wise leads will be assigned to Teams
														which have the destination attached.
													</Box>
												) : null}
											</Stack>
										</Stack>
									</Box>
								) : null
							}
						</FormSpy>
						<SubmissionError />
						<Inline gap="4">
							<Button type="submit" disabled={submitting}>
								{submitting ? "Saving..." : "Save Details"}
							</Button>
							<Button
								disabled={submitting}
								onClick={() => onCancel()}
								level="tertiary"
							>
								Cancel
							</Button>
						</Inline>
					</Stack>
				</form>
			)}
		</Form>
	)
}
