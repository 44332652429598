import {
	dateToUTCString,
	formatDate,
	utcTimestampToLocalDate,
} from "@sembark-travel/datetime-utils"
import {
	Button,
	Inline,
	Stack,
	Box,
	Divider,
	Icons,
} from "@sembark-travel/ui/base"
import { showSnackbar } from "@sembark-travel/ui/snackbar"
import { Dialog, useDialog } from "@sembark-travel/ui/dialog"
import {
	Form,
	withServerErrors,
	TextAreaInputField,
	arrayMutators,
	FieldArray,
	SubmissionError,
	DatePickerField,
	validateFormValues,
} from "@sembark-travel/ui/form"
import type { TArrivalDepartureDetail } from "./store"
import { useState } from "react"
import { useXHR } from "@sembark-travel/xhr"
import * as Validator from "yup"
import { Required } from "utility-types"
import config from "../config"

export function UpdateArrivalDepartureDetails({
	children,
	type,
	tripId,
	onSuccess,
	...props
}: {
	children: (props: { update: () => void }) => React.ReactNode
	type: "arrival" | "departure"
	tripId: number
	onSuccess?: () => void
} & Omit<
	React.ComponentProps<typeof UpdateArrivalDepartureDetailsForm>,
	"onCancel" | "onSubmit"
>) {
	const xhr = useXHR()
	const label = type === "arrival" ? "Arrival" : "Departure"
	const [isOpen, open, close] = useDialog()
	return (
		<>
			{children({ update: open })}
			<Dialog open={isOpen} onClose={close} title={`Update ${label} Details`}>
				<Dialog.Body>
					<UpdateArrivalDepartureDetailsForm
						{...props}
						onSubmit={async (values) => {
							await xhr.patch(`/trips/${tripId}/${type}-details`, values)
							onSuccess?.()
							close()
							showSnackbar(`${label} details updated`)
						}}
						onCancel={close}
					/>
				</Dialog.Body>
			</Dialog>
		</>
	)
}

const validate = validateFormValues(
	Validator.object().shape({
		data: Validator.array().of(
			Validator.object().shape({
				date_time: Validator.date().required(
					"Please provide a data and time for the details"
				),
				description: Validator.string()
					.required("Please provide description for details")
					.max(500, "Please use less then 500 characters"),
			})
		),
	})
)

function UpdateArrivalDepartureDetailsForm({
	details,
	onSubmit,
	onCancel,
	defaultUTCDate,
}: {
	details?: Array<TArrivalDepartureDetail> | null
	onSubmit: (data: {
		data: Array<{ date_time: string; description: string }>
	}) => Promise<void>
	onCancel: () => void
	defaultUTCDate: string
}) {
	type TFormData = {
		data?: Array<{
			date_time?: Date
			description?: string
		}>
	}
	const [initialValues] = useState<Required<TFormData, "data">>(() => ({
		data: details?.length
			? details.map((d) => ({
					date_time: utcTimestampToLocalDate(d.date_time),
					description: d.description,
				}))
			: [
					{
						date_time: utcTimestampToLocalDate(defaultUTCDate),
						description: "",
					},
				],
	}))
	return (
		<Form<TFormData>
			initialValues={initialValues}
			validate={validate}
			onSubmit={withServerErrors(async (values) =>
				onSubmit({
					data:
						values.data?.map((d) => ({
							date_time: dateToUTCString(d.date_time || new Date()),
							date_time_local: formatDate(
								d.date_time || new Date(),
								config.timestampDateFormat
							),
							description: d.description || "",
						})) || [],
				})
			)}
			mutators={{ ...arrayMutators }}
			subscription={{ submitting: true }}
		>
			{({ handleSubmit, submitting }) => (
				<form noValidate onSubmit={handleSubmit}>
					<Stack gap="4">
						<FieldArray<
							Required<TFormData, "data">["data"][number]
						> name="data">
							{({ fields }) => (
								<Stack gap="6">
									{fields.map((name, index) => (
										<Inline
											key={name}
											gap="4"
											borderBottomWidth="1"
											paddingBottom="4"
										>
											<Stack gap="4" flex="1">
												<DatePickerField
													name={`${name}.date_time`}
													dateFormat
													timeFormat
													label="Date and Time"
												/>
												<TextAreaInputField
													label="Details"
													name={`${name}.description`}
													placeholder={`Add details here.\nUse new lines to enter multiple details.`}
												/>
											</Stack>
											<Box paddingTop="2">
												<Button
													onClick={() => fields.remove(index)}
													size="sm"
													title="Remove"
													status="warning"
												>
													<Icons.Cancel />
												</Button>
											</Box>
										</Inline>
									))}
									<Box>
										<Button
											size="sm"
											level="secondary"
											onClick={() =>
												fields.push({
													date_time: utcTimestampToLocalDate(defaultUTCDate),
													description: "",
												})
											}
										>
											{fields.length === 0 ? "Add Details" : "Add More"}
										</Button>
									</Box>
								</Stack>
							)}
						</FieldArray>
						<Divider sm />
						<SubmissionError />
						<Inline gap="4">
							<Button type="submit" disabled={submitting}>
								{submitting ? "Updating..." : "Save Details"}
							</Button>
							<Button disabled={submitting} onClick={onCancel}>
								Cancel
							</Button>
						</Inline>
					</Stack>
				</form>
			)}
		</Form>
	)
}
