import {
	Box,
	Table,
	AsyncSelect,
	AsyncSelectProps,
	Dropdown,
	Icons,
	Text,
	RelativeTime,
} from "@sembark-travel/ui/base"
import { ButtonLink, useLocationQuery } from "@sembark-travel/ui/router"
import { ListView, Search, useSearch } from "@sembark-travel/ui/list"
import { useXHR } from "@sembark-travel/xhr"
import { Fragment, useEffect, useState } from "react"
import { Helmet } from "react-helmet-async"
import { Omit } from "utility-types"
import { generatePath } from "../router-utils"
import { AddHotelPaymentPreferenceDialog } from "./NewItem"
import {
	IHotelPaymentPreference,
	hotelPaymentPreferencesXHR as XHR,
} from "./store"
import { showSnackbar } from "@sembark-travel/ui/snackbar"
import pluralize from "pluralize"

export function List() {
	const [query, setQuery] = useLocationQuery()
	const [params, setParams] = useSearch(query)
	useEffect(() => {
		setQuery(params)
	}, [params, setQuery])
	return (
		<Fragment>
			<Helmet>
				<title>Hotel Payment Preferences List</title>
			</Helmet>
			<Search
				initialParams={params}
				onSearch={(params) => {
					setParams({ ...params, page: 1 })
				}}
				title="Hotel Payment Preferences"
				actions={
					<ButtonLink
						to={generatePath("/hotel-payment-preferences/new")}
						level="primary"
						status="primary"
						anchored
					>
						Add New
					</ButtonLink>
				}
			/>
			<ListView<IHotelPaymentPreference>
				pageKey="hotel-payment-preferences"
				fetch={(xhr, params) =>
					XHR(xhr).getHotelPaymentPreferences({
						...params,
						include: "hotels_count,created_by",
					})
				}
				params={params}
				onPageChange={(page) => setParams({ ...params, page })}
			>
				{({ items: hotelPaymentPreferences, refresh, xhr }) => (
					<Table
						headers={["Description", "Hotels", "Created By", ""]}
						bordered
						striped
						alignCols={{ 1: "center", 3: "right" }}
						rows={hotelPaymentPreferences.map((hotelPaymentPreference) => [
							hotelPaymentPreference.name,
							hotelPaymentPreference.hotels_count,
							<Text>
								{hotelPaymentPreference.created_by?.name || "You"}{" "}
								<RelativeTime
									timestamp={hotelPaymentPreference.created_at}
									color="muted"
									fontSize="sm"
								/>
							</Text>,
							<Dropdown alignRight>
								<Dropdown.ToggleButton level="tertiary" size="sm">
									<Icons.DotsVertical />
								</Dropdown.ToggleButton>
								<Dropdown.Menu>
									<Dropdown.MenuItem
										onClick={() => {
											if (
												window.confirm(
													`This is an IRREVERSIBLE action.${
														hotelPaymentPreference.hotels_count
															? ` It will also be removed from ${pluralize(
																	"hotel",
																	hotelPaymentPreference.hotels_count,
																	true
																)}.`
															: ""
													}\n\nAre you sure you want to delete this Payment Preference?`
												)
											) {
												xhr
													.delete(
														`/hotel-payment-preferences/${hotelPaymentPreference.id}`
													)
													.then(() => {
														showSnackbar("Deleted successfully.")
														refresh()
													})
													.catch((e) => {
														const error = e as Error
														alert(
															error.message ||
																"Something went wrong. Please try after sometime"
														)
													})
											}
										}}
										color="danger"
									>
										<Icons.Trash /> Delete
									</Dropdown.MenuItem>
								</Dropdown.Menu>
							</Dropdown>,
						])}
					/>
				)}
			</ListView>
		</Fragment>
	)
}

type SelectHotelPaymentPreferencesProps = Omit<AsyncSelectProps, "fetch">

export function SelectHotelPaymentPreferences({
	...otherProps
}: SelectHotelPaymentPreferencesProps) {
	const xhr = useXHR()
	const [newName, setNewName] = useState("")
	return (
		<Fragment>
			<AsyncSelect
				multiple
				onCreateNew={(query: string) => {
					setNewName(query)
				}}
				optionRenderer={({ option, created }) =>
					created ? <Box>Add "{option.name}"</Box> : <Box>{option.name}</Box>
				}
				{...otherProps}
				fetch={(q) =>
					XHR(xhr)
						.getHotelPaymentPreferences({ q })
						.then((resp) => resp.data)
				}
			/>
			<AddHotelPaymentPreferenceDialog
				open={!!newName}
				onClose={() => {
					setNewName("")
				}}
			/>
		</Fragment>
	)
}
