import { Helmet } from "react-helmet-async"
import { CountriesList } from "../../../../../Admin/World"

export default function CountriesListPage() {
	return (
		<>
			<Helmet>
				<title>Countries</title>
			</Helmet>
			<CountriesList />
		</>
	)
}
