import {
	Box,
	Button,
	Inline,
	Icons,
	Select,
	Stack,
	DateTimeInput,
	useTimeout,
	SROnly,
} from "@sembark-travel/ui/base"
import { withOrdinalSuffix } from "@sembark-travel/number-utils"
import { useCallback, useMemo, useRef, useEffect } from "react"
import { Required } from "utility-types"
import * as Validator from "yup"
import { getTotalPriceForServices, getRemainingDates } from "../utils"
import {
	addUnit,
	getDiff,
	formatDate,
	startOf,
	isSame,
	parseDate,
	isAfter,
	isBefore,
} from "@sembark-travel/datetime-utils"

import {
	EmptyNumberValidator,
	Form,
	validateFormValues,
	FieldGroup,
	TextInputField,
	GetFieldValue,
	FormSpy,
	useFieldValue,
	withServerErrors,
	useFormState,
	arrayMutators,
	FieldArray,
	addKeyToFieldArrayItem,
	getFieldArrayItemKey,
} from "@sembark-travel/ui/form"
import { type TTripDestination } from "../TripDestinations"
import { type TChildrenArray } from "../Tourists"
import { type TMoney } from "@sembark-travel/money"
import {
	TTravelActivityDatewisePricesInputFieldArrayValue,
	TravelActivityDatewisePricesInputFieldArray,
	TravelActivityDatewisePricesValidationSchema,
	TValidTravelActivityDatewisePricesInputFieldArrayValue,
} from "./TravelActivityDatewisePricesInputField"

const validationSchema = Validator.object().shape({
	travel_activities: Validator.array().of(
		Validator.object().shape({
			dates: Validator.array()
				.of(Validator.date().required("Please select a valid day/date"))
				.required("Days field id required")
				.min(1, "Days field is required"),
			no_of_day: EmptyNumberValidator()
				.positive("Days should be a positive integer")
				.typeError("Days should be a number"),
			travel_activities: Validator.array().of(
				TravelActivityDatewisePricesValidationSchema
			),
		})
	),
})

const validate = validateFormValues(validationSchema)

export type TCalculateTravelActivitiesPriceSchema = {
	travel_activities?: Array<{
		__id: number
		no_of_days?: number
		dates?: Array<Date>
		travel_activities?: TTravelActivityDatewisePricesInputFieldArrayValue
	}>
}

const INITIAL_VALUES: Required<
	TCalculateTravelActivitiesPriceSchema,
	"travel_activities"
> = {
	travel_activities: [
		addKeyToFieldArrayItem({
			no_of_days: 1,
			dates: [],
			travel_activities: [
				addKeyToFieldArrayItem({
					dates: [],
					activity: undefined,
					ticket_tourist_configurations: [],
				}),
			],
		}),
	],
}

type TValidData = {
	travel_activities: Array<{
		__id: number
		dates: Array<Date>
		travel_activities: TValidTravelActivityDatewisePricesInputFieldArrayValue
	}>
	given_price?: Array<TMoney>
}

interface CalculateTravelActivityPriceFormProps {
	initialValues?: TCalculateTravelActivitiesPriceSchema
	onChange?: (data: TValidData) => void
	bookingFrom?: string
	bookingTo?: string
	shouldEmptyInitialValues?: boolean
	tripDestinations: Array<TTripDestination>
	noOfAdults: number
	children: TChildrenArray
	showBookedPrices?: boolean
}

export function CalculateTravelActivityPriceForm({
	initialValues: initialValuesProp,
	shouldEmptyInitialValues = false,
	onChange,
	bookingFrom,
	bookingTo,
	tripDestinations,
	noOfAdults,
	children,
	showBookedPrices,
}: CalculateTravelActivityPriceFormProps) {
	const currency = useMemo(
		() => tripDestinations.map((d) => d.currency).at(0) || "INR",
		[tripDestinations]
	)
	const initialValues = useMemo(() => {
		const data: TCalculateTravelActivitiesPriceSchema =
			initialValuesProp ||
			(shouldEmptyInitialValues
				? {
						travel_activities: [],
					}
				: INITIAL_VALUES)
		return {
			...data,
		}
	}, [initialValuesProp, shouldEmptyInitialValues])
	const notifyOnChange = useCallback(
		(flattenValues: TCalculateTravelActivitiesPriceSchema) => {
			if (onChange) {
				const travel_activities = !flattenValues.travel_activities
					? []
					: flattenValues.travel_activities
							.map((c) => ({
								...c,
								travel_activities: (c.travel_activities || [])
									.map((t) => ({
										...t,
										ticket_tourist_configurations:
											t.ticket_tourist_configurations?.filter(
												(
													c
												): c is Required<
													typeof c,
													"configuration" | "quantity"
												> => Boolean(c.configuration && c.quantity)
											) || [],
									}))
									.filter(
										(
											t
										): t is Required<
											typeof t,
											"dates" | "activity" | "ticket_tourist_configurations"
										> =>
											Boolean(
												t.dates?.length &&
													t.activity &&
													t.ticket_tourist_configurations.length
											)
									),
							}))
							.filter(
								(c): c is Required<typeof c, "dates" | "travel_activities"> =>
									Boolean(c.travel_activities?.length && c.dates?.length)
							)

				const totalPrice = getTotalPriceForServices(travel_activities)

				onChange({
					travel_activities,
					given_price: travel_activities?.length ? totalPrice : [],
				})
			}
		},
		[onChange]
	)
	const bookingDates = useMemo(() => {
		const dates: Array<{ id: string; name: string; value: Date }> = []
		if (!bookingTo || !bookingFrom) return dates
		const days = getDiff(bookingTo, bookingFrom, "days")
		for (let i = 0; i <= days; i++) {
			const date = addUnit(bookingFrom, i, "day")
			dates.push({
				id: formatDate(date, "YYYY-MM-DD"),
				name: `${withOrdinalSuffix(i + 1)} Day (${formatDate(
					date,
					"ddd D MMM"
				)})`,
				value: startOf(date, "day"),
			})
		}
		return dates
	}, [bookingFrom, bookingTo])
	return (
		<Form<TCalculateTravelActivitiesPriceSchema>
			initialValues={initialValues}
			validate={validate}
			onSubmit={withServerErrors(console.log)}
			subscription={{}}
			mutators={{ ...arrayMutators }}
		>
			{({ form, handleSubmit }) => {
				return (
					<form noValidate onSubmit={handleSubmit}>
						<FieldArray<
							Required<
								TCalculateTravelActivitiesPriceSchema,
								"travel_activities"
							>["travel_activities"][number]
						> name="travel_activities">
							{({ fields }) => (
								<Stack gap="4">
									{fields.map((name, index) => (
										<Box
											key={getFieldArrayItemKey(form, name)}
											borderBottomWidth="4"
											borderColor="default"
										>
											<Inline
												gap="4"
												collapseBelow="md"
												position="relative"
												style={{
													zIndex: 10 * (Number(fields.length || 0) - index),
												}}
											>
												<Box position="sticky" top="0" zIndex="10">
													<Box
														position="sticky"
														top="0"
														bgColor="default"
														zIndex="10"
														paddingBottom="4"
													>
														<DaysAndDatesField
															name={name}
															bookingDates={
																bookingFrom && bookingTo
																	? bookingDates
																	: undefined
															}
														/>
													</Box>
												</Box>
												<Box flex="1" minWidth="0">
													<Box borderBottomWidth="1">
														<GetFieldValue<Array<Date>> name={`${name}.dates`}>
															{({ value: dates }) => (
																<TravelActivityDatewisePricesInputFieldArray
																	hideTitle
																	name={`${name}.travel_activities`}
																	currency={currency}
																	noOfAdults={noOfAdults}
																	children={children}
																	dates={dates}
																	tripDestinations={tripDestinations}
																	showBookedPrices={showBookedPrices}
																/>
															)}
														</GetFieldValue>
													</Box>
													<Inline gap="4" paddingY="4" justifyContent="end">
														<FormSpy<TCalculateTravelActivitiesPriceSchema>
															subscription={{ values: true }}
														>
															{({ values }) => {
																if (!values.travel_activities) return null
																const travelActivityItem =
																	values.travel_activities[index]
																let remainingDates: Array<Date> = []
																const selectedDates = travelActivityItem.dates
																if (bookingTo && selectedDates?.length) {
																	remainingDates = getRemainingDates(
																		bookingDates.map((d) => d.value),
																		values.travel_activities.reduce<
																			Array<Date>
																		>(
																			(dates, travelActivity) =>
																				dates.concat(
																					travelActivity.dates || []
																				),
																			[]
																		)
																	)
																}
																return selectedDates?.length &&
																	(!bookingTo || remainingDates.length) ? (
																	<Button
																		status="primary"
																		size="sm"
																		onClick={() => {
																			const dates = [
																				!bookingTo
																					? startOf(
																							addUnit(
																								selectedDates[
																									selectedDates.length - 1
																								],
																								1,
																								"day"
																							),
																							"day"
																						)
																					: remainingDates[0],
																			]
																			fields.push({
																				...travelActivityItem,
																				travel_activities: [
																					addKeyToFieldArrayItem({
																						dates,
																						activity: undefined,
																						ticket_tourist_configurations: [],
																					}),
																				],
																				dates,
																			})
																		}}
																	>
																		<Icons.Plus /> Next Day
																	</Button>
																) : null
															}}
														</FormSpy>
														<GetFieldValue<
															TCalculateTravelActivitiesPriceSchema["travel_activities"]
														> name="travel_activities">
															{({ value: travelActivities }) => (
																<GetFieldValue<
																	Required<TCalculateTravelActivitiesPriceSchema>["travel_activities"][number]
																>
																	name={name}
																>
																	{({ value: travelActivityItem }) => (
																		<Button
																			level="tertiary"
																			onClick={() => {
																				const addAt = index + 1
																				const newTravelActivities = [
																					...(travelActivities || []),
																				]
																				// can not use insert
																				// https://github.com/final-form/final-form-arrays/issues/44
																				newTravelActivities.splice(
																					addAt,
																					0,
																					addKeyToFieldArrayItem({
																						...travelActivityItem,
																					})
																				)
																				form.change(
																					"travel_activities",
																					newTravelActivities
																				)
																			}}
																			size="sm"
																		>
																			<Icons.Duplicate /> Duplicate
																		</Button>
																	)}
																</GetFieldValue>
															)}
														</GetFieldValue>
														<Button
															onClick={() => fields.remove(index)}
															size="sm"
															level="tertiary"
														>
															<Icons.Cancel /> Remove
														</Button>
													</Inline>
												</Box>
											</Inline>
										</Box>
									))}
									{!fields.length ? (
										<Box paddingTop="4">
											<Button
												level="secondary"
												status="primary"
												size="sm"
												data-testid="add_standalone_travel_activities"
												onClick={() =>
													fields.push(
														addKeyToFieldArrayItem(
															INITIAL_VALUES.travel_activities[0]
														)
													)
												}
											>
												<Icons.Plus /> Add Activities
											</Button>
										</Box>
									) : null}
								</Stack>
							)}
						</FieldArray>
						<SubmitOnChange onChange={notifyOnChange} />
						<SROnly>
							<Button type="submit">Get Prices</Button>
						</SROnly>
					</form>
				)
			}}
		</Form>
	)
}

function DaysAndDatesField({
	name,
	bookingDates,
}: {
	name: string
	bookingDates?: Array<{ id: string; name: string; value: Date }>
}) {
	const { value: selectedDates, onChange: setSelectedDates } = useFieldValue<
		Required<
			TCalculateTravelActivitiesPriceSchema,
			"travel_activities"
		>["travel_activities"][number]["dates"]
	>(`${name}.dates`)
	const { value: no_of_days, onChange: changeNoOfDays } = useFieldValue<number>(
		`${name}.no_of_days`
	)
	return bookingDates?.length ? (
		<Box style={{ maxWidth: "165px" }}>
			<FieldGroup<typeof selectedDates> name={`${name}.dates`} label="Days">
				{({ input }) => {
					const { value } = input
					return (
						<Select
							{...input}
							options={bookingDates}
							searchable={false}
							placeholder="Select day(s)..."
							required
							multiple
							value={
								value
									? bookingDates.filter((d) =>
											value.find((dd) => isSame(dd, d.value, "day"))
										)
									: undefined
							}
							onChange={(bookingDays: typeof bookingDates | undefined) => {
								const days =
									bookingDays && bookingDays.length
										? bookingDays.map((s) => s.value)
										: []
								days.sort((a, b) =>
									isAfter(a, b) ? 1 : isBefore(a, b) ? -1 : 0
								)
								input.onChange(days)
							}}
						/>
					)
				}}
			</FieldGroup>
		</Box>
	) : (
		<Inline gap="4">
			<Box>
				<FieldGroup<typeof selectedDates> label="Date" name={`${name}.dates`}>
					{({ input }) => (
						<DateTimeInput
							{...input}
							value={
								input.value?.length ? parseDate(input.value[0]) : undefined
							}
							onChange={(value) => {
								const days = parseInt(String(no_of_days || "0"))
								if (!days) {
									changeNoOfDays(1)
								}
								setSelectedDates(
									value ? createDatesFromStartDateAndDays(value, days || 1) : []
								)
							}}
						/>
					)}
				</FieldGroup>
			</Box>
			<Box style={{ maxWidth: "100px" }}>
				<TextInputField
					label="No of Days"
					type="number"
					min={1}
					max={1000}
					name={`${name}.no_of_days`}
					onChange={(e) => {
						const days = parseInt(String(Number(e.currentTarget.value)))
						changeNoOfDays(days)
						if (selectedDates?.length && days) {
							setSelectedDates(
								createDatesFromStartDateAndDays(
									selectedDates[0],
									days
								) as never as string[]
							)
						}
					}}
				/>
			</Box>
		</Inline>
	)
}

function createDatesFromStartDateAndDays(from: Date, nights = 1): Array<Date> {
	const dates = []
	for (let i = 0; i < nights; i++) {
		dates.push(startOf(addUnit(from, i, "days"), "day"))
	}
	return dates
}

function SubmitOnChange({
	onChange,
}: {
	onChange: (values: TCalculateTravelActivitiesPriceSchema) => void
}) {
	const { values } = useFormState<TCalculateTravelActivitiesPriceSchema>({
		subscription: { values: true, valid: true },
	})
	const { set: setNotifyParentTimeout, clear: clearNotifyParentTimeout } =
		useTimeout()

	const onChangeRef = useRef(onChange)
	onChangeRef.current = onChange

	// notify parent
	useEffect(() => {
		setNotifyParentTimeout(() => {
			onChangeRef.current(values)
		}, 500)
		return () => clearNotifyParentTimeout()
	}, [values, setNotifyParentTimeout, clearNotifyParentTimeout])

	return null
}
