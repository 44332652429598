import {
	AsyncSelect,
	AsyncSelectProps,
	Box,
	Button,
	Icons,
	Table,
	Inline,
	Stack,
	Text,
	Heading,
	TableDataCell,
	Select,
} from "@sembark-travel/ui/base"
import { useDialog } from "@sembark-travel/ui/dialog"
import { IListResponse, useXHR, XHRInstance } from "@sembark-travel/xhr"
import { useCallback, useState } from "react"
import { Email, PhoneNumber } from "../Contacts"
import { ITransportServiceProvider } from "../TransportServiceProviders"
import { AddDriverInDialog } from "./NewItem"
import { TDriver } from "./store"
import { UpdateDriverInDialog } from "./UpdateDriver"

function XHR(xhr: XHRInstance) {
	return {
		async get(params: unknown): Promise<IListResponse<TDriver>> {
			return xhr.get("/drivers", { params }).then((resp) => resp.data)
		},
	}
}

export function DriversList({
	drivers,
	onChange,
	transportServiceProvider,
}: {
	transportServiceProvider?: ITransportServiceProvider
	drivers?: Array<TDriver>
	onChange?: () => void
}) {
	const [isOpen, show, close] = useDialog()
	return (
		<Stack gap="4">
			<Inline gap="2" alignItems="center">
				<Box>
					<Icons.Driver color="muted" size="4" />
				</Box>
				<Heading as="h4" fontSize="md">
					Drivers
				</Heading>
			</Inline>
			{drivers?.length ? (
				<Table
					headers={["Name", "Phone Number(s)", "Email", ""]}
					hover
					bordered
					responsive
					alignCols={{ 3: "right" }}
					rows={drivers.map((driver) => [
						driver.name,
						driver.phone_numbers?.length ? (
							<PhoneNumber value={driver.phone_numbers} numberOnly />
						) : null,
						driver.email ? <Email value={driver.email} /> : null,
						<UpdateDriverInDialog
							transportServiceProvider={transportServiceProvider}
							driver={driver}
							onSuccess={() => {
								onChange?.()
							}}
						>
							{({ edit }) => (
								<Button
									size="sm"
									level="tertiary"
									onClick={() => edit()}
									title="Edit Driver Details"
								>
									<Icons.Pencil />
								</Button>
							)}
						</UpdateDriverInDialog>,
					])}
				>
					{transportServiceProvider?.solo_driver ? null : (
						<tfoot>
							<tr>
								<TableDataCell colSpan={4}>
									<Button data-testid="add_driver" onClick={show}>
										<Icons.Plus /> Add Driver
									</Button>
								</TableDataCell>
							</tr>
						</tfoot>
					)}
				</Table>
			) : (
				<Stack gap="2" alignItems="center">
					<Heading as="h5" fontSize="lg" color="muted" fontWeight="normal">
						No drivers added.
					</Heading>
					<Text fontSize="sm" color="muted">
						Add drivers for better communication and sharing across organisation
					</Text>
					<Box>
						<Button onClick={show} data-testid="add_driver" level="primary">
							<Icons.Plus /> Add Driver
						</Button>
					</Box>
				</Stack>
			)}
			<AddDriverInDialog
				transportServiceProvider={transportServiceProvider}
				open={isOpen}
				onCancel={close}
				onSuccess={() => {
					close()
					onChange?.()
				}}
			/>
		</Stack>
	)
}

export function SelectDriver({
	transportServiceProvider,
	options,
	...otherProps
}: Omit<AsyncSelectProps, "fetch"> & {
	transportServiceProvider?: ITransportServiceProvider
}) {
	const xhr = useXHR()
	const [newName, setNewName] = useState("")
	const handleDialogClose = useCallback(() => {
		setNewName("")
	}, [setNewName])
	return (
		<>
			{options ? (
				<Select
					onCreateNew={(query: string) => {
						setNewName(query)
					}}
					optionRenderer={({ option, created }) =>
						created ? <Box>Add "{option.name}"</Box> : <Box>{option.name}</Box>
					}
					options={options}
					{...otherProps}
				/>
			) : (
				<AsyncSelect
					onCreateNew={(query: string) => {
						setNewName(query)
					}}
					optionRenderer={({ option, created }) =>
						created ? <Box>Add "{option.name}"</Box> : <Box>{option.name}</Box>
					}
					{...otherProps}
					fetch={(q) =>
						XHR(xhr)
							.get({ q })
							.then((resp) => resp.data)
					}
				/>
			)}
			<AddDriverInDialog
				open={!!newName}
				initialValues={{
					name: newName,
				}}
				onCancel={handleDialogClose}
				transportServiceProvider={transportServiceProvider}
				onSuccess={(driver) => {
					handleDialogClose()
					otherProps.onChange?.(driver, otherProps.name || "field_name")
				}}
			/>
		</>
	)
}
