import {
	utcTimestampToLocalDateString,
	utcTimestampToLocalTimeString,
} from "@sembark-travel/datetime-utils"
import {
	Stack,
	joinAttributes,
	Box,
	Spinner,
	Heading,
	Inline,
} from "@sembark-travel/ui/base"
import { Link } from "@sembark-travel/ui/router"
import { useXHR } from "@sembark-travel/xhr"
import useSWR from "swr"
import { PERMISSIONS, useCheckPermissions } from "../Auth"
import { type IQuoteRequest } from "../QuoteRequests"
import { generatePath } from "../router-utils"
import { type IScheduledCab } from "./../CabScheduling"
import { PaymentDetails, IPayment } from "./../Payments"
import { type IBooking } from "./../HotelBookings"
import { type ITrip, tripPaymentsXHR } from "./store"
import { TTravelActiviytBooking } from "../TravelActivityBookings"

interface IPaymentsProps {
	payments: Array<IPayment<unknown>>
	onChange?: () => void
	disableLogging: boolean
}

export default function TripPayments({ trip }: { trip: ITrip }) {
	const xhr = useXHR()
	const { hasAnyPermission } = useCheckPermissions()
	const { data, mutate: revalidate } = useSWR(
		`/trips/${trip.id}/payments`,
		() => tripPaymentsXHR(xhr).payments(trip.id)
	)
	if (!data) {
		return <Spinner padding="4" alignCenter />
	}
	const {
		can_log_payments,
		customer_payments,
		hotel_payments,
		cab_payments,
		travel_activity_booking_payments,
		outgoing_payments_for_quote_request,
		incoming_payments_from_quote_request,
	} = data
	return (
		<Stack gap="8">
			{customer_payments ? (
				<CustomerPayments
					payments={customer_payments}
					onChange={revalidate}
					disableLogging={!can_log_payments}
				/>
			) : null}
			{incoming_payments_from_quote_request ? (
				<IncomingQuoteRequestPayments
					quoteRequest={incoming_payments_from_quote_request}
					onChange={revalidate}
					disableLogging={!can_log_payments}
				/>
			) : null}
			{outgoing_payments_for_quote_request ? (
				<OutgoingQuoteRequestPayments
					quoteRequest={outgoing_payments_for_quote_request}
					onChange={revalidate}
					disableLogging={!can_log_payments}
				/>
			) : null}
			{hotel_payments &&
			hasAnyPermission(
				PERMISSIONS.MODIFY_HOTEL_BOOKINGS,
				PERMISSIONS.MANAGE_ACCOUNTING
			) ? (
				<HotelPayments
					bookings={hotel_payments}
					onChange={revalidate}
					disableLogging={!can_log_payments}
				/>
			) : null}
			{cab_payments &&
			hasAnyPermission(
				PERMISSIONS.MODIFY_CAB_SCHEDULES,
				PERMISSIONS.MANAGE_ACCOUNTING
			) ? (
				<CabPayments
					bookings={cab_payments}
					onChange={revalidate}
					disableLogging={!can_log_payments}
				/>
			) : null}
			{travel_activity_booking_payments &&
			hasAnyPermission(
				PERMISSIONS.MODIFY_CAB_SCHEDULES,
				PERMISSIONS.MANAGE_ACCOUNTING
			) ? (
				<TravelActivityBookingPayments
					bookings={travel_activity_booking_payments}
					onChange={revalidate}
					disableLogging={!can_log_payments}
				/>
			) : null}
		</Stack>
	)
}

function CustomerPayments({
	payments,
	onChange,
	disableLogging,
}: IPaymentsProps) {
	const { hasAnyPermission } = useCheckPermissions()
	return payments && payments.length ? (
		<PaymentForEntity title="Payments from customer">
			<Stack gap="4">
				{payments.map((payment) => (
					<PaymentDetails
						payment={payment}
						onChange={onChange}
						key={payment.id}
						disableLogging={disableLogging}
						readOnlyAmount={
							!hasAnyPermission(
								PERMISSIONS.VIEW_QUERIES,
								PERMISSIONS.MANAGE_ACCOUNTING
							)
						}
					/>
				))}
			</Stack>
		</PaymentForEntity>
	) : null
}

function CabPayments({
	bookings,
	onChange,
	disableLogging,
}: {
	bookings: Array<IScheduledCab & { payments: Array<IPayment<IScheduledCab>> }>
	onChange?: () => void
	disableLogging: boolean
}) {
	return bookings && bookings.length ? (
		<PaymentForEntity title="Payments to Cabs">
			<Stack gap="6">
				{bookings.map(
					({
						payments,
						transport_service_provider,
						driver,
						cab_schedule,
						cab,
						cab_type,
						...b
					}) =>
						!payments.length ? null : (
							<Stack key={b.id} gap="6">
								{cab_schedule ? (
									<Stack gap="4">
										<Heading as="h4">
											{cab_schedule.transport_service.name}
										</Heading>
										<Inline gap="6" flexWrap="wrap">
											{transport_service_provider ? (
												<Stack>
													<Box
														fontSize="sm"
														fontWeight="semibold"
														color="muted"
													>
														Transporter
													</Box>
													<Link
														to={generatePath(
															"/transport-service-providers/:transportServiceProviderId",
															{
																transportServiceProviderId:
																	transport_service_provider.id.toString(),
															}
														)}
														color="accent"
														fontWeight="semibold"
													>
														{transport_service_provider.name}
													</Link>
												</Stack>
											) : null}
											{driver ? (
												<Stack>
													<Box
														fontSize="sm"
														fontWeight="semibold"
														color="muted"
													>
														Driver
													</Box>
													<Box>
														<Box>{driver.name}</Box>
														<Box fontSize="sm">
															{joinAttributes(
																driver.phone_number,
																driver.email
															)}
														</Box>
													</Box>
												</Stack>
											) : null}
											<Stack>
												<Box fontSize="sm" fontWeight="semibold" color="muted">
													Pickup
												</Box>
												<Box>
													<Box>
														{utcTimestampToLocalDateString(
															cab_schedule.start_date
														)}
													</Box>
													<Box fontSize="sm">
														{utcTimestampToLocalTimeString(
															cab_schedule.start_date
														)}
													</Box>
												</Box>
											</Stack>
											<Stack>
												<Box fontSize="sm" fontWeight="semibold" color="muted">
													Drop
												</Box>
												<Box>
													<Box>
														{utcTimestampToLocalTimeString(
															cab_schedule.end_date
														)}
													</Box>
													<Box fontSize="sm">
														{utcTimestampToLocalDateString(
															cab_schedule.end_date
														)}
													</Box>
												</Box>
											</Stack>
											<Stack>
												<Box fontSize="sm" fontWeight="semibold" color="muted">
													Vehicle
												</Box>
												<Box>
													<Box>{cab_type?.name}</Box>
													<Box fontSize="sm">{cab?.number_plate}</Box>
												</Box>
											</Stack>
										</Inline>
									</Stack>
								) : null}
								<Stack gap="4">
									{payments.map((payment) => (
										<PaymentDetails
											payment={payment}
											onChange={onChange}
											key={payment.id}
											disableLogging={disableLogging}
										/>
									))}
								</Stack>
							</Stack>
						)
				)}
			</Stack>
		</PaymentForEntity>
	) : null
}

function HotelPayments({
	bookings,
	onChange,
	disableLogging,
}: {
	bookings: Array<
		IBooking & {
			payments: Array<IPayment<IBooking>>
		}
	>
	onChange?: () => void
	disableLogging: boolean
}) {
	return bookings && bookings.length ? (
		<PaymentForEntity title="Payments to Hotels">
			<Stack gap="6">
				{bookings.map(
					({ payments, hotel, booking_confirmation_id, voucher_id, id }) =>
						!payments.length ? null : (
							<Stack key={id} id={`hb_${id}`} gap="4">
								<Stack gap="1">
									<Heading as="h4">{hotel.name}</Heading>
									<Box fontSize="sm">
										{joinAttributes(
											hotel.location.short_name,
											hotel.stars_string,
											voucher_id ? (
												<Box display="inline" title="Voucher Number">
													#{voucher_id}
												</Box>
											) : null,
											booking_confirmation_id ? (
												<Box
													display="inline"
													title="Booking Confirmation Number"
												>
													BCNF: {booking_confirmation_id}
												</Box>
											) : null
										)}
									</Box>
								</Stack>
								<Stack gap="4">
									{payments.map((payment) => (
										<PaymentDetails
											payment={payment}
											onChange={onChange}
											key={payment.id}
											disableLogging={disableLogging}
										/>
									))}
								</Stack>
							</Stack>
						)
				)}
			</Stack>
		</PaymentForEntity>
	) : null
}

function OutgoingQuoteRequestPayments({
	quoteRequest: { to, payments },
	onChange,
	disableLogging,
}: {
	onChange: () => void
	quoteRequest: IQuoteRequest & {
		payments: Array<IPayment>
	}
	disableLogging: boolean
}) {
	return payments && payments.length ? (
		<PaymentForEntity title={`Payments to ${to?.short_name}`}>
			{payments.map((payment) => (
				<PaymentDetails
					payment={payment}
					onChange={onChange}
					key={payment.id}
					readOnlyAmount
					disableLogging={disableLogging}
				/>
			))}
		</PaymentForEntity>
	) : null
}

function IncomingQuoteRequestPayments({
	quoteRequest: { from, payments },
	onChange,
	disableLogging,
}: {
	onChange: () => void
	quoteRequest: IQuoteRequest & {
		payments: Array<IPayment>
	}
	disableLogging: boolean
}) {
	return payments && payments.length ? (
		<PaymentForEntity title={`Payments from ${from?.short_name}`}>
			{payments.map((payment) => (
				<PaymentDetails
					payment={payment}
					onChange={onChange}
					key={payment.id}
					disableLogging={disableLogging}
				/>
			))}
		</PaymentForEntity>
	) : null
}

function TravelActivityBookingPayments({
	bookings,
	onChange,
	disableLogging,
}: {
	bookings: Array<
		TTravelActiviytBooking & {
			payments: Array<IPayment<TTravelActiviytBooking>>
		}
	>
	onChange?: () => void
	disableLogging: boolean
}) {
	return bookings && bookings.length ? (
		<PaymentForEntity title="Payments to Travel Activity Booking">
			<Stack gap="6">
				{bookings.map(
					({
						payments,
						supplier,
						title,
						ticket_tourist_configurations_full_name,
						start_date,
						end_date,
						start_time_formatted,
						duration_formatted,
						end_time_formatted,
						confirmation_details,
						remarks,
						...b
					}) =>
						!payments.length ? null : (
							<Stack key={b.id} gap="6">
								<Stack gap="4">
									<Heading as="h4">{title}</Heading>
									<Inline gap="6" flexWrap="wrap">
										<Stack>
											<Box fontSize="sm" fontWeight="semibold" color="muted">
												Supplies
											</Box>
											<Box>{ticket_tourist_configurations_full_name}</Box>
										</Stack>
										{supplier ? (
											<Stack>
												<Box fontSize="sm" fontWeight="semibold" color="muted">
													Supplier
												</Box>
												<Link
													to={generatePath(
														"/transport-service-providers/:transportServiceProviderId",
														{
															transportServiceProviderId:
																supplier.id.toString(),
														}
													)}
													color="accent"
													fontWeight="semibold"
												>
													{supplier.name}
												</Link>
											</Stack>
										) : null}
										<Stack>
											<Box fontSize="sm" fontWeight="semibold" color="muted">
												Starts
											</Box>
											<Box>
												<Box>{utcTimestampToLocalDateString(start_date)}</Box>
												{start_time_formatted ? (
													<Box fontSize="sm">{start_time_formatted}</Box>
												) : null}
											</Box>
										</Stack>
										<Stack>
											<Box fontSize="sm" fontWeight="semibold" color="muted">
												Ends
											</Box>
											<Box>
												{end_time_formatted ? (
													<Box>{end_time_formatted}</Box>
												) : duration_formatted ? (
													<Box>{duration_formatted}</Box>
												) : (
													"N/A Time"
												)}
												<Box fontSize="sm">
													{utcTimestampToLocalDateString(end_date)}
												</Box>
											</Box>
										</Stack>
										<Stack>
											<Box fontSize="sm" fontWeight="semibold" color="muted">
												Confirmation Details
											</Box>
											<Box whiteSpace="preserveLine">
												{confirmation_details || "N/A"}
											</Box>
										</Stack>
										<Stack>
											<Box fontSize="sm" fontWeight="semibold" color="muted">
												Remarks
											</Box>
											<Box whiteSpace="preserveLine">{remarks || "N/A"}</Box>
										</Stack>
									</Inline>
								</Stack>
								<Stack gap="4">
									{payments.map((payment) => (
										<PaymentDetails
											payment={payment}
											onChange={onChange}
											key={payment.id}
											disableLogging={disableLogging}
										/>
									))}
								</Stack>
							</Stack>
						)
				)}
			</Stack>
		</PaymentForEntity>
	) : null
}

function PaymentForEntity({
	title,
	children,
}: {
	title: string
	children: React.ReactNode
}) {
	return (
		<Stack gap="4">
			<Box as="header" padding="4" bgColor="default" borderBottomWidth="1">
				<Heading as="h3">{title}</Heading>
			</Box>
			<Box padding="4">{children}</Box>
		</Stack>
	)
}
