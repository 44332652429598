import {
	Alert,
	Button,
	Inline,
	Stack,
	Text,
	Col,
	Divider,
	Grid,
	Box,
} from "@sembark-travel/ui/base"
import { useDialog, Dialog } from "@sembark-travel/ui/dialog"
import * as Validator from "yup"
import { ChildrenInputField, type TChildrenArray } from "./../Tourists"
import {
	Form,
	DatePickerField,
	TextInputField,
	GetFieldValue,
	EmptyNumberValidator,
} from "@sembark-travel/ui/form"
import pluralize from "pluralize"
import { TTripDestination } from "../TripDestinations"

export interface IBasicInfo {
	startDate: Date
	days: number
	adults: number
	children?: TChildrenArray
}

const basicDetailsValidationSchema = Validator.object().shape({
	startDate: Validator.date().required("Start date is required"),
	days: EmptyNumberValidator()
		.positive("Number of days should be a positive integer")
		.integer("Number of days should be a positive integer")
		.required("Number of days is required"),
	adults: EmptyNumberValidator()
		.positive("Number of adults should be a positive integer")
		.integer("Number of adults should be a positive integer")
		.required("Number of adults field is required"),
	children: Validator.array().of(
		Validator.object().shape({
			count: EmptyNumberValidator()
				.positive("Number of children should be positive integer")
				.integer("Number of children should be positive integer"),
			age: EmptyNumberValidator().positive(
				"Child age should a positive number"
			),
		})
	),
})

export function EditBasicInfo({
	destinations,
	onChange,
	startDate,
	days,
	adults,
	children,
}: {
	destinations: Array<TTripDestination>
	startDate: Date
	days: number
	adults: number
	children: TChildrenArray | undefined
	onChange: (data: IBasicInfo) => void
}) {
	const [isDialogOpen, openDialog, closeDialog] = useDialog()
	return (
		<>
			<Button
				onClick={() => {
					openDialog()
				}}
				size="sm"
			>
				Edit Basic Details
			</Button>
			<Dialog
				open={isDialogOpen}
				onClose={closeDialog}
				title="Edit Quote's Basic Details"
				sm
			>
				<Dialog.Body>
					<Stack gap="2">
						<Alert>
							<Stack gap="2">
								<Text fontSize="md" fontWeight="semibold">
									Update these details to provide a quote for different
									configuration without changing the trip details.
								</Text>
								<Text>
									This update <b>will not</b> change the trip's basic details
									and is only applicable to this quote only.
								</Text>
							</Stack>
						</Alert>
					</Stack>
					<Divider sm />
					<Alert status="warning">
						Any changes made in this quote will be reset.
					</Alert>
					<Divider sm />
					<Form<IBasicInfo & { nights: number }>
						initialValues={{
							startDate: startDate,
							days,
							nights: days - 1,
							// ensure that we are not mutating the child objects as children are passed by reference
							children: (children || []).map((c) => ({ ...c })),
							adults,
						}}
						validationSchema={basicDetailsValidationSchema}
						onSubmit={({ nights, children, ...values }) => {
							onChange({
								...values,
								children: children?.filter((c) => c.age) || [],
							})
							closeDialog()
						}}
						subscription={{}}
					>
						{({ handleSubmit, form }) => (
							<form noValidate onSubmit={handleSubmit}>
								<Stack gap="4">
									<Grid gap="4">
										<Col>
											<DatePickerField label="Start Date" name="startDate" />
										</Col>
										<Col>
											<Box style={{ maxWidth: "150px" }}>
												<TextInputField
													name="nights"
													label="Number of Nights"
													type="number"
													max={10000}
													min={0}
													onChange={(e) => {
														form.batch(() => {
															form.change(
																"nights",
																e.currentTarget.value as never as undefined
															)
															form.change(
																"days",
																isNaN(parseInt(e.currentTarget.value))
																	? 0
																	: parseInt(e.currentTarget.value) + 1
															)
														})
													}}
													help={
														<GetFieldValue<number> name="days">
															{({ value }) => (
																<Box>{pluralize("Day", value, true)}</Box>
															)}
														</GetFieldValue>
													}
												/>
											</Box>
										</Col>
									</Grid>
									<GetFieldValue<number> name="days">
										{({ value }) =>
											Number(value) !== Number(days) ? (
												<Alert status="warning">
													Changing the duration may result in data lost or may
													required addition data.
													<br />
													<b>Please go through details after updation.</b>
												</Alert>
											) : null
										}
									</GetFieldValue>
									<Inline gap="4" flexWrap="wrap">
										<Box>
											<Box style={{ maxWidth: "150px" }}>
												<TextInputField
													name="adults"
													label="Number of adults"
													type="number"
													min={1}
													max={1000000}
													required
												/>
											</Box>
										</Box>
										<Box>
											<ChildrenInputField
												name="children"
												maxAge={
													destinations.length
														? Math.max(
																...destinations.map(
																	(d) => d.max_child_age || 12
																)
															)
														: undefined
												}
											/>
										</Box>
									</Inline>
								</Stack>
								<Divider />
								<Inline gap="4">
									<Button type="submit">Update Basic Details</Button>
									<Button
										onClick={() => {
											closeDialog()
										}}
										level="tertiary"
									>
										Cancel
									</Button>
								</Inline>
							</form>
						)}
					</Form>
				</Dialog.Body>
			</Dialog>
		</>
	)
}
