import {
	Text,
	Box,
	Icons,
	Stack,
	Inline,
	Heading,
	Spinner,
	Button,
	TimeDuration,
	Time,
	Money,
	joinAttributes,
	Badge,
	RelativeTime,
	Table,
} from "@sembark-travel/ui/base"
import {
	dateToUTCString,
	startOf,
	endOf,
	subtractUnit,
	addUnit,
	dateToQuery,
	utcTimestampToLocalDate,
	isBefore,
	duration,
	getDiff,
} from "@sembark-travel/datetime-utils"
import { Link } from "@sembark-travel/ui/router"
import { useXHR } from "@sembark-travel/xhr"
import { generatePath } from "../router-utils"
import { tripListXHR } from "./store"
import useSWR from "swr"
import { useMemo, useState } from "react"
import { PhoneNumber } from "../Contacts"
import { IFilters } from "./List"
import { withOrdinalSuffix } from "@sembark-travel/number-utils"

type TStatsProps =
	| {
			intervalType: "Today"
			checkouts: true | false
	  }
	| {
			intervalType: "Yesterday" | "Next 7 Days"
			checkouts: false
	  }
	| {
			intervalType: "Tomorrow" | "Prev 7 Days"
			checkouts: true
	  }
function useTripsCount({ intervalType, checkouts = false }: TStatsProps) {
	const xhr = useXHR()
	const params = useMemo(() => {
		const params: IFilters = {
			status: "converted",
		}
		let dateInterval = [new Date(), new Date()]
		switch (intervalType) {
			case "Today":
				params.status = "on_trip"
				dateInterval = [new Date(), new Date()]
				break
			case "Yesterday":
				params.status = "on_trip"
				dateInterval = [
					subtractUnit(new Date(), 1, "day"),
					subtractUnit(new Date(), 1, "day"),
				]
				break
			case "Tomorrow":
				params.status = "on_trip"
				dateInterval = [
					addUnit(new Date(), 1, "day"),
					addUnit(new Date(), 1, "day"),
				]
				break
			case "Next 7 Days":
				params.status = "converted"
				dateInterval = [
					addUnit(new Date(), 1, "day"),
					addUnit(new Date(), 7, "day"),
				]
				break
			case "Prev 7 Days":
				params.status = "past"
				dateInterval = [
					subtractUnit(new Date(), 7, "day"),
					subtractUnit(new Date(), 1, "day"),
				]
				break
			default:
				break
		}
		if (!checkouts) {
			params.starts_after = dateInterval[0]
			params.starts_before = dateInterval[1]
		} else {
			params.ends_after = dateInterval[0]
			params.ends_before = dateInterval[1]
		}
		if (!checkouts && intervalType === "Next 7 Days") {
			params.sort = "start_date"
		}
		return params
	}, [intervalType, checkouts])
	const { data } = useSWR(
		`trip-check-in-outs-count/?intervalType=${intervalType}&${checkouts ? "checkouts" : "checkins"}`,
		() => {
			return tripListXHR(xhr).getCount({
				limit: 1,
				status: params.status,
				sort: params.sort,
				starts_after: params.starts_after
					? dateToUTCString(startOf(params.starts_after, "day"))
					: null,
				starts_before: params.starts_before
					? dateToUTCString(endOf(params.starts_before, "day"))
					: null,
				ends_after: params.ends_after
					? dateToUTCString(startOf(params.ends_after, "day"))
					: null,
				ends_before: params.ends_before
					? dateToUTCString(endOf(params.ends_before, "day"))
					: null,
			})
		}
	)
	const detailsPageQueryParams = useMemo(() => {
		return {
			status: params.status,
			sa: params.starts_after ? dateToQuery(params.starts_after) : undefined,
			sb: params.starts_before ? dateToQuery(params.starts_before) : undefined,
			ea: params.ends_after ? dateToQuery(params.ends_after) : undefined,
			eb: params.ends_before ? dateToQuery(params.ends_before) : undefined,
			sort: params.sort,
		}
	}, [params])
	return { data, params, detailsPageQueryParams }
}

function Stats(props: TStatsProps) {
	const { data, detailsPageQueryParams } = useTripsCount(props)
	return (
		<Stack
			as={Link}
			to={generatePath("/trips")}
			query={detailsPageQueryParams}
			bgColor={{ hover: "subtle" }}
			padding="2"
			rounded="md"
			gap="1"
		>
			<Text fontWeight="semibold" color="muted">
				{props.intervalType} <Icons.ChevronDown rotate="270" />
			</Text>
			<Text fontSize="lg" fontWeight="semibold">
				{data ? data.data : "..."}
			</Text>
		</Stack>
	)
}

export function CheckinCheckoutTripsWidget({
	checkouts = false,
}: {
	checkouts?: boolean
}) {
	return (
		<Stack bgColor="default" rounded="md" borderWidth="1">
			<Box paddingX="4" paddingY="2" borderBottomWidth="1">
				<Inline gap="2" alignItems="center">
					<Heading fontSize="md" as="h3">
						Trips {!checkouts ? "Starting" : "Ending"}
					</Heading>
					<Box>
						<Link
							padding="2"
							to={generatePath("/trips")}
							color="accent"
							query={{ status: "on_trip" }}
						>
							<Icons.ArrowLeft rotate="180" />
						</Link>
					</Box>
				</Inline>
			</Box>
			<Inline padding="2" gap="1" flexWrap="wrap">
				<Stats intervalType="Today" checkouts={checkouts} />
				{!checkouts ? (
					<Stats intervalType="Yesterday" checkouts={false} />
				) : (
					<Stats intervalType="Tomorrow" checkouts={true} />
				)}
				{!checkouts ? (
					<Stats intervalType="Next 7 Days" checkouts={false} />
				) : (
					<Stats intervalType="Prev 7 Days" checkouts={true} />
				)}
			</Inline>
		</Stack>
	)
}

export function LiveTripsWithDuePaymentsWidget() {
	const xhr = useXHR()
	const [intervalType, setIntervalType] = useState<
		"Live" | "Ended Yesterday" | "Starts in 7 Days"
	>("Live")
	const params = useMemo(() => {
		const params: IFilters = {
			status: "converted",
			has_due_payments: 1,
		}
		switch (intervalType) {
			case "Live":
				params.status = "on_trip"
				params.live_start = new Date()
				params.live_end = new Date()
				break
			case "Ended Yesterday":
				params.status = "past"
				params.ends_after = subtractUnit(new Date(), 1, "day")
				params.ends_before = subtractUnit(new Date(), 1, "day")
				break
			case "Starts in 7 Days":
				params.status = "converted"
				params.starts_after = addUnit(new Date(), 1, "day")
				params.starts_before = addUnit(new Date(), 7, "day")
				break
		}
		return params
	}, [intervalType])
	const { data, isValidating } = useSWR(
		`live-trips-with-due-payments/?intervalType=${intervalType}`,
		() => {
			return tripListXHR(xhr).getTrips({
				status: params.status,
				has_due_payments: params.has_due_payments,
				starts_after: params.starts_after
					? dateToUTCString(startOf(params.starts_after, "day"))
					: null,
				starts_before: params.starts_before
					? dateToUTCString(endOf(params.starts_before, "day"))
					: null,
				ends_after: params.ends_after
					? dateToUTCString(startOf(params.ends_after, "day"))
					: null,
				ends_before: params.ends_before
					? dateToUTCString(endOf(params.ends_before, "day"))
					: null,
				live_start: params.live_start
					? dateToUTCString(startOf(params.live_start, "day"))
					: null,
				live_end: params.live_end
					? dateToUTCString(endOf(params.live_end, "day"))
					: null,
			})
		}
	)
	const detailsPageQueryParams = useMemo(() => {
		return {
			status: params.status,
			sa: params.starts_after ? dateToQuery(params.starts_after) : undefined,
			sb: params.starts_before ? dateToQuery(params.starts_before) : undefined,
			ea: params.ends_after ? dateToQuery(params.ends_after) : undefined,
			eb: params.ends_before ? dateToQuery(params.ends_before) : undefined,
			ls: params.live_start ? dateToQuery(params.live_start) : undefined,
			le: params.live_end ? dateToQuery(params.live_end) : undefined,
			hdp: params.has_due_payments ? 1 : undefined,
		}
	}, [params])
	return (
		<Stack bgColor="default" rounded="md" borderWidth="1">
			<Box paddingX="4" paddingY="2" borderBottomWidth="1">
				<Inline gap="4" justifyContent="between" flexWrap="wrap">
					<Inline gap="2" alignItems="center">
						<Heading fontSize="md" as="h3">
							Trips with Due Payments
						</Heading>
						<Box>
							<Link
								padding="2"
								to={generatePath("/trips")}
								color="accent"
								query={detailsPageQueryParams}
							>
								{data && isValidating ? (
									<Icons.Refresh spin />
								) : (
									<Icons.ArrowLeft rotate="180" />
								)}
							</Link>
						</Box>
					</Inline>
					<Inline gap="2">
						{([] as Array<typeof intervalType>)
							.concat(["Live", "Ended Yesterday", "Starts in 7 Days"])
							.map((actionLabel) => (
								<Button
									size="sm"
									key={actionLabel}
									status="primary"
									level={
										intervalType === actionLabel ? "secondary" : "tertiary"
									}
									onClick={() => setIntervalType(actionLabel)}
								>
									{actionLabel}
								</Button>
							))}
					</Inline>
				</Inline>
			</Box>
			<Box style={{ height: "300px" }} overflow="auto">
				{!data ? (
					<Box>
						<Spinner alignCenter padding="4" />
					</Box>
				) : !data.data.length ? (
					<Stack gap="2" paddingX="4" paddingY="8" alignItems="center">
						<Box>
							<Icons.OkCircleSolid size="8" color="muted" />
						</Box>
						<Text color="muted">All caught up!</Text>
						<Box>
							<Link
								to={generatePath("/trips")}
								color="accent"
								query={detailsPageQueryParams}
							>
								View All <Icons.ArrowLeft rotate="180" />
							</Link>
						</Box>
					</Stack>
				) : (
					<Stack>
						<Table
							responsive
							hover
							rows={data.data.map((trip) => {
								const {
									destinations,
									nights: no_of_nights,
									days,
									package_amount,
									due_payments,
									due_payments_amount,
									latest_given_quote,
								} = trip
								const start_date = utcTimestampToLocalDate(trip.start_date)
								const live_day = getDiff(new Date(), start_date, "days") + 1
								const isPaymentOverdue = due_payments?.some(
									(p) =>
										p.next_due_instalment &&
										isBefore(
											utcTimestampToLocalDate(p.next_due_instalment.due_at),
											new Date()
										)
								)
								const packageCurrency =
									latest_given_quote?.given_currency || "INR"
								return [
									<Stack gap="1">
										{trip.trip_source_contact ? (
											<Inline gap="2" alignItems="center">
												<Text fontWeight="semibold">
													{trip.trip_source_contact.name}
												</Text>
												{trip.trip_source_contact.phone_numbers ? (
													<PhoneNumber
														iconOnly
														value={trip.trip_source_contact.phone_numbers}
													/>
												) : null}
											</Inline>
										) : trip.tourist ? (
											<Inline gap="2" alignItems="center">
												<Text fontWeight="semibold">{trip.tourist.name}</Text>
												{trip.tourist.phone_numbers ? (
													<PhoneNumber
														iconOnly
														value={trip.tourist.phone_numbers}
													/>
												) : null}
											</Inline>
										) : null}
										<Box fontSize="sm">
											{joinAttributes(
												<Text as="span">{trip.trip_source.short_name}</Text>,
												<Link
													to={generatePath("/trips/:tripId", {
														tripId: trip.id.toString(),
													})}
													color="accent"
													fontWeight="semibold"
												>
													{trip.id}
												</Link>
											)}
										</Box>
									</Stack>,
									<Stack gap="1">
										<Text>{destinations.map((d) => d.name).join(", ")}</Text>
										<Box fontSize="sm">
											{joinAttributes(
												<Time value={start_date} format="DD MMM" />,
												live_day <= days ? (
													live_day === 1 ? (
														<Badge success>First Day</Badge>
													) : live_day === days ? (
														<Badge danger>Last Day</Badge>
													) : live_day > 0 ? (
														<Badge>{withOrdinalSuffix(live_day)} Day</Badge>
													) : (
														<Badge>
															<RelativeTime value={start_date} />
														</Badge>
													)
												) : null,
												<TimeDuration value={duration(days, "days")}>
													{no_of_nights}N, {days}D
												</TimeDuration>,
												[
													trip.no_of_adults + "A",
													trip.no_of_children
														? trip.no_of_children + "C"
														: undefined,
												]
													.filter(Boolean)
													.join("+")
											)}
										</Box>
									</Stack>,
									<Stack alignItems="end">
										{due_payments?.length && due_payments_amount ? (
											<Link
												to={generatePath("/trips/:tripId/accounting/payments", {
													tripId: trip.id.toString(),
												})}
												fontSize="md"
											>
												<Money
													fontWeight="semibold"
													color={isPaymentOverdue ? "danger" : "muted"}
													amount={due_payments_amount}
													currency={packageCurrency}
												/>
											</Link>
										) : (
											<Box color="success">
												<Icons.Ok /> Received
											</Box>
										)}
										{package_amount ? (
											<Box>
												/{" "}
												<Money
													amount={package_amount}
													currency={packageCurrency}
												/>
											</Box>
										) : (
											<Box title="Quote Created but selling price not set">
												-
											</Box>
										)}
									</Stack>,
								]
							})}
						/>
						{data.meta?.last_page !== 1 ? (
							<Box padding="4">
								<Link
									to={generatePath("/trips")}
									color="accent"
									query={detailsPageQueryParams}
								>
									View All ({data.meta.total}) <Icons.ArrowLeft rotate="180" />
								</Link>
							</Box>
						) : null}
					</Stack>
				)}
			</Box>
		</Stack>
	)
}
