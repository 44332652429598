import { TenantUsageStats } from "../../../../../Admin/Tenants"
import { Helmet } from "react-helmet-async"
import { useParams } from "../../../../../router"
import { ForbidUnlessAuthorized, PERMISSIONS } from "../../../../../Auth"

export default function TenantBillingDetailsPage() {
	const { tenantId } = useParams("/admin/tenants/:tenantId/stats")
	return (
		<ForbidUnlessAuthorized permission={PERMISSIONS.MANAGE_TENANTS}>
			<Helmet>
				<title>Tenant Usage Stats</title>
			</Helmet>
			<TenantUsageStats id={Number(tenantId)} />
		</ForbidUnlessAuthorized>
	)
}
