import { Helmet } from "react-helmet-async"
import { TripPlanRequestsList } from "./../../TripPlanRequests"
import { ForbidUnlessAuthorized, PERMISSIONS } from "../../Auth"

export default function TripPlanRequests() {
	return (
		<>
			<Helmet>
				<title>Trip Plan Requests</title>
			</Helmet>
			<ForbidUnlessAuthorized permission={PERMISSIONS.VIEW_TRIP_PLAN_REQUESTS}>
				<TripPlanRequestsList />
			</ForbidUnlessAuthorized>
		</>
	)
}
