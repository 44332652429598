import { Helmet } from "react-helmet-async"
import { TimezonesList } from "../../../../Admin/World"

export default function TimezonesListPage() {
	return (
		<>
			<Helmet>
				<title>Timezones</title>
			</Helmet>
			<TimezonesList />
		</>
	)
}
