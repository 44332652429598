import React from "react"
import { Omit } from "utility-types"
import { skipContentLinkClassName } from "./skipToContent.css"

const id = "start-to-content"

type ISkipContentLinkProps = Omit<React.HTMLProps<HTMLAnchorElement>, "href">

export function SkipContentLink({
	children = "Skip to main content",
	...props
}: ISkipContentLinkProps) {
	return (
		<a href={`#${id}`} {...props} className={skipContentLinkClassName}>
			{children}
		</a>
	)
}

export function MainContent(props: React.HTMLProps<HTMLDivElement>) {
	return <main {...props} id={id} />
}
