import { Alert, Box, Stack, Spinner, Text } from "@sembark-travel/ui/base"
import { showSnackbar } from "@sembark-travel/ui/snackbar"
import { Dialog } from "@sembark-travel/ui/dialog"
import { setReportingUser } from "@sembark-travel/logging"
import { XHRInstance } from "@sembark-travel/xhr"
import { Fragment, useEffect } from "react"
import useSWR from "swr"
import { LoginForm } from "./Login"
import {
	IUser,
	useReauthenticate,
	useAuthUser,
	useHasFeatureFlag,
} from "./store"
import { Navigate, useLocation } from "../router-utils"
import { subscribeToStorage } from "../storage"
import type { TFeatureFlags } from "../Admin/Tenants"
import { useSyncUserDevicePushNotificationsSubscriptionStatus } from "../Notifications"
import { useSearchParams } from "react-router-dom"
import { useCheckPermissions, PERMISSIONS } from "./Permissions"
import Forbidden from "./Forbidden"
import { $Values } from "utility-types"
import { NotFound } from "../NotFound"

export function AuthXHR(xhr: XHRInstance) {
	return {
		async getUser(): Promise<IUser> {
			return xhr.get("/me").then((resp: { data: { data: IUser } }) => {
				return resp.data.data
			})
		},
	}
}

export function AuthUserProvider({
	children = null,
}: {
	children:
		| React.ReactNode
		| ((props: { wait: boolean; user?: IUser }) => React.ReactNode)
}) {
	const { user, wait, noRequestYet, fetchUser, needsToReauthenticate } =
		useAuthUser()
	// add refresh for auth user
	// we don't need to handle the response here as actions will do there work
	useSWR(user ? `auth/user/${user.id}` : null, () => fetchUser(true), {
		fallbackData: user,
		revalidateOnFocus: true,
		focusThrottleInterval: 600000, // 10 Minutes
		revalidateOnMount: false, // we already have data on mount
	})
	useEffect(() => {
		if (noRequestYet && !wait) {
			fetchUser().catch(() => undefined) // ignore the errors
		}
	}, [fetchUser, noRequestYet, wait])
	useEffect(() => {
		if (user) {
			setReportingUser(user)
		}
	}, [user])
	useSyncUserDevicePushNotificationsSubscriptionStatus(user)
	useEffect(() => {
		if ((!user || needsToReauthenticate) && !wait) {
			const unsubscribe = subscribeToStorage((event) => {
				if (event === "LOGGED_IN") {
					fetchUser(true)
				}
			})
			return () => unsubscribe()
		}
	}, [fetchUser, needsToReauthenticate, wait, user])
	return (
		<Fragment>
			{typeof children === "function" ? children({ wait, user }) : children}
		</Fragment>
	)
}

function Wait() {
	return (
		<Box
			style={{ height: "256px" }}
			alignItems="center"
			display="flex"
			justifyContent="center"
			fontSize="3xl"
		>
			<Spinner />
		</Box>
	)
}

/**
 * Redirects the user if the user is not authenticated
 *
 * Use this component to redirect the user to login from protected routes
 */
export function RedirectUnlessAuthenticated({
	children = null,
}: {
	children?: React.ReactNode
}) {
	const { wait, user, needsToReauthenticate } = useAuthUser()
	const reauthenticate = useReauthenticate()
	const location = useLocation()
	if (wait) {
		return <Wait />
	}
	if (!user) {
		return (
			<Navigate
				to={{
					pathname: "/login",
					search: `?next=${encodeURIComponent(
						location.pathname === "/"
							? "/"
							: location.pathname + location.search
					)}`,
				}}
				replace
			/>
		)
	}
	const { email, name, id } = user
	return (
		<Fragment>
			{children}
			{needsToReauthenticate ? (
				<Box>
					<Dialog open sm>
						<Dialog.Header closeButton={false}>
							<Dialog.Title>Please verify it is you.</Dialog.Title>
						</Dialog.Header>
						<Dialog.Body>
							<Stack gap="8">
								<Alert status="warning">
									<Stack gap="1">
										<Text>
											To help keep your account secure, Sembark needs to verify
											it's you.
										</Text>
										<Text fontWeight="semibold">
											Please login again to continue.
										</Text>
									</Stack>
								</Alert>
								<LoginForm
									userId={id}
									email={email}
									readOnlyEmail
									onSubmit={(...args) =>
										reauthenticate(...args).then((resp) => {
											showSnackbar("Logged in. You can continue your work.")
											return resp
										})
									}
								/>
							</Stack>
						</Dialog.Body>
						<Dialog.Footer>
							Not <b>{name}</b> <small>&lt;{email}&gt;</small>?{" "}
							<a href={`${window.location.origin}/login`}>
								Click here to Login
							</a>
						</Dialog.Footer>
					</Dialog>
				</Box>
			) : null}
		</Fragment>
	)
}

/**
 * Redirects the user if the user is authenticated
 *
 * This is used to prevent users from navigate to routes that
 * should not be accessed when user is logged in e.g.
 * login, forgot password etc
 */
export function RedirectIfAuthenticated({
	children = null,
	to,
}: {
	children?: React.ReactNode
	to?: string
}) {
	const { wait, user, needsToReauthenticate } = useAuthUser()
	const [params] = useSearchParams()
	if (wait) {
		return <Wait />
	}
	const nextFromSeachParams = params.get("next")
	to = nextFromSeachParams ? nextFromSeachParams : decodeURIComponent(to || "/")
	if (user && !needsToReauthenticate) {
		return <Navigate to={to as unknown as "/"} replace />
	}
	return <Fragment>{children}</Fragment>
}

export function FeatureFlag({
	flag,
	children,
	show404,
}: {
	flag: keyof TFeatureFlags
	children: React.ReactNode | ((props: { enabled: boolean }) => React.ReactNode)
	show404?: boolean
}) {
	const enabled = useHasFeatureFlag(flag)
	if (typeof children === "function") {
		return <>{children({ enabled })}</>
	}
	if (!enabled) {
		if (show404) {
			return <NotFound />
		}
		return null
	}
	return <>{children}</>
}

export function ForbidUnlessAuthorized({
	permission,
	anyPermissions,
	children,
}: (
	| {
			permission: $Values<typeof PERMISSIONS>
			anyPermissions?: undefined
	  }
	| {
			permission?: undefined
			anyPermissions: Array<$Values<typeof PERMISSIONS>>
	  }
) & {
	children: React.ReactNode
}) {
	const { hasPermission, hasAnyPermission } = useCheckPermissions()
	if (permission && !hasPermission(permission)) {
		return <Forbidden />
	}
	if (anyPermissions?.length && !hasAnyPermission(...anyPermissions)) {
		return <Forbidden />
	}
	return <>{children}</>
}
