import React from "react"
import { Box } from "./../Box"
import classNames from "classnames"
import { useId } from "./../react-hooks"
import {
	OverlayTrigger,
	OverlayDelay,
	OverlayTriggerProps,
	OverlayTriggerRenderProps,
} from "./Overlay"
import {
	tooltipClassName,
	popoverClassName,
	arrowClassName,
} from "./overlay.css"

type Children =
	| ((props: OverlayTriggerRenderProps) => React.ReactNode)
	| string
	| React.ReactNode
type CommonProps = Pick<
	OverlayTriggerProps,
	"placement" | "trigger" | "interactive" | "delay"
> & {
	children: Children
	content: React.ReactNode | ((props: { hide: () => void }) => React.ReactNode)
}

const defaultDelay: OverlayDelay = { show: 250, hide: 250 }

export function Tooltip({
	content,
	children,
	interactive,
	delay = defaultDelay,
	...props
}: CommonProps) {
	const id = useId()
	if (interactive && !delay) {
		delay = defaultDelay
	}
	return (
		<OverlayTrigger
			delay={delay}
			interactive={interactive}
			{...props}
			overlay={({ props, show, arrowProps, onHide }) => (
				<Box
					id={id}
					className={classNames(tooltipClassName, { "tooltip--show": show })}
					role="tooltip"
					display={show ? "block" : "none"}
					margin="0"
					fontSize="sm"
					{...props}
				>
					<Box className={arrowClassName} {...arrowProps} />
					<Box
						maxWidth="sm"
						paddingX="3"
						paddingY="2"
						color="on_emphasis"
						bgColor="emphasis"
						rounded="md"
					>
						<>
							{typeof content === "function"
								? content({ hide: () => onHide?.() })
								: content}
						</>
					</Box>
				</Box>
			)}
		>
			{(props) => <Content props={props} children={children} />}
		</OverlayTrigger>
	)
}

export function Popover({
	content,
	children,
	...props
}: CommonProps & Pick<OverlayTriggerProps, "rootClose">) {
	const id = useId()
	return (
		<OverlayTrigger
			rootClose
			{...props}
			overlay={({ props, show, arrowProps, onHide }) => {
				return (
					<Box
						className={classNames(popoverClassName, { "popover--show": show })}
						role="tooltip"
						display={show ? "block" : "none"}
						id={id}
						{...props}
					>
						<Box className={arrowClassName} {...arrowProps} />
						<Box
							bgColor="default"
							color="default"
							rounded="lg"
							borderWidth="1"
							boxShadow="lg"
							maxWidth="sm"
						>
							<>
								{typeof content === "function"
									? content({ hide: () => onHide?.() })
									: content}
							</>
						</Box>
					</Box>
				)
			}}
		>
			{(props) => <Content props={props} children={children} />}
		</OverlayTrigger>
	)
}

function Content({
	props,
	children,
}: {
	props: OverlayTriggerRenderProps
	children:
		| ((props: OverlayTriggerRenderProps) => React.ReactNode)
		| React.ReactNode
}) {
	if (typeof children === "function") {
		return <>{children(props)}</>
	}
	if (React.isValidElement(children)) return React.cloneElement(children, props)
	return (
		<Box as="span" {...props}>
			{children}
		</Box>
	)
}
