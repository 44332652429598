import { Box, BoxProps } from "./Box"
import { useRef } from "react"

export function Summary(props: BoxProps<"summary">) {
	return (
		<Box
			as="summary"
			fontSize="lg"
			fontWeight="semibold"
			cursor="pointer"
			padding="2"
			bgColor={{ hover: "subtle" }}
			{...props}
		/>
	)
}

export function Details({
	initialOpen,
	...props
}: BoxProps<"details"> & {
	initialOpen?: boolean
}) {
	const open = useRef(initialOpen)
	return <Box as="details" open={open.current} {...props} />
}

Details.Summary = Summary
