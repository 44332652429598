import {
	ICursorListResponse,
	IListResponse,
	XHRInstance,
} from "@sembark-travel/xhr"
import { AxiosRequestConfig } from "axios"

export type TWorldCountry = {
	id: number
	name: string
	iso2: string
	iso3: string
	phone_code: number
	region?: string
	subregion?: string
	emoji: string
	emojiU: string
	latitude?: string
	longitude?: string
	states_count?: number
	cities_count?: number
}

export type TWorldState = {
	id: number
	name: string
	country_id: number
	synonyms_1: string | null
	synonyms_2: string | null
	synonyms_3: string | null
	country_code: string
	state_code: string
	country?: TWorldCountry
	latitude?: string
	longitude?: string
	cities_count?: number
}

export type TWorldCity = {
	id: number
	name: string
	country_id: number
	state_id: number
	synonyms_1: string | null
	synonyms_2: string | null
	synonyms_3: string | null
	country_code: string
	state_code: string
	state?: TWorldState
	country?: TWorldCountry
	latitude?: string
	longitude?: string
}

export type TWorldCurrency = {
	id: number
	name: string
	country_id: string
	code: string
	precision: number
	symbol: string
	symbol_native: string
	decimal_mark: string
	thousands_separator: string
	country?: TWorldCountry
}

export type TWorldAirport = {
	id: number
	icao: string
	iata?: string
	name: string
	synonyms_1?: string
	synonyms_2?: string
	synonyms_3?: string
	country_id: string
	state_id?: string
	city_id?: string
	timezone_id?: number
	latitude?: string
	longitude?: string
	elevation?: string
	country?: TWorldCountry
	state?: TWorldState
	city?: TWorldCity
	timezone?: TWorldTimezone
}

export type TWorldTimezone = {
	id: number
	name: string
	country_id: number
	country?: TWorldCountry
}

export type TWorldBank = {
	id: number
	name: string
	code: string
	country?: TWorldCountry
	branches_count?: number
}

export type TWorldBankBranch = {
	id: number
	bank_id: number
	bank: TWorldBank
	address: string | null
	name: string
	centre: string | null
	city_name: string | null
	state_name: string | null
	country_name: string | null
	swift_code?: string
	ifsc_code?: string
	micr_code?: string
	city?: TWorldCity
	state?: TWorldState
	country?: TWorldCountry
}

export function worldXHR(xhr: XHRInstance) {
	return {
		async getCountries<T extends TWorldCountry = TWorldCountry>(
			params: unknown
		) {
			return xhr
				.get<IListResponse<T>>("/world/countries", {
					params,
				})
				.then((resp) => resp.data)
		},
		async getCountry<T extends TWorldCountry = TWorldCountry>(
			id: number | string,
			params: unknown
		) {
			return xhr
				.get<{ data: T }>(`/world/countries/${id}`, {
					params: params,
				})
				.then((resp) => resp.data.data)
		},
		async getStates<T extends TWorldState = TWorldState>(params: unknown) {
			return xhr
				.get<IListResponse<T>>("/world/states", {
					params,
				})
				.then((resp) => resp.data)
		},
		async getState<T extends TWorldState = TWorldState>(
			id: number | string,
			params: unknown
		) {
			return xhr
				.get<{ data: T }>(`/world/states/${id}`, {
					params: params,
				})
				.then((resp) => resp.data.data)
		},
		async storeState(payload: unknown) {
			return xhr
				.post<{ data: TWorldState }>("/world/states", payload)
				.then((resp) => resp.data.data)
		},
		async updateState(id: number | string, payload: unknown) {
			return xhr
				.patch<{ data: TWorldState }>("/world/states/" + id, payload)
				.then((resp) => resp.data.data)
		},
		async getCities<T extends TWorldCity = TWorldCity>(params: unknown) {
			return xhr
				.get<IListResponse<T>>("/world/cities", {
					params,
				})
				.then((resp) => resp.data)
		},
		async storeCity(payload: unknown) {
			return xhr
				.post<{ data: TWorldCity }>("/world/cities", payload)
				.then((resp) => resp.data.data)
		},
		async updateCity(id: number | string, payload: unknown) {
			return xhr
				.patch<{ data: TWorldCity }>("/world/cities/" + id, payload)
				.then((resp) => resp.data.data)
		},
		async getTimezones<T extends TWorldTimezone = TWorldTimezone>(
			params: unknown
		) {
			return xhr
				.get<IListResponse<T>>("/world/timezones", {
					params,
				})
				.then((resp) => resp.data)
		},
		async getCurrencies<T extends TWorldCurrency = TWorldCurrency>(
			params: unknown
		) {
			return xhr
				.get<IListResponse<T>>("/world/currencies", {
					params,
				})
				.then((resp) => resp.data)
		},
		async getAirports<T extends TWorldAirport = TWorldAirport>(
			params?: Record<string, unknown>
		) {
			return xhr
				.get<IListResponse<T>>("/world/airports", {
					params: {
						...params,
						pagination: "simple",
					},
				})
				.then((resp) => resp.data)
		},
		async getAirportsWithPagination<T extends TWorldAirport = TWorldAirport>(
			params?: Record<string, unknown>
		) {
			return xhr
				.get<ICursorListResponse<T>>("/world/airports", {
					params: {
						...params,
						pagination: "cursor",
					},
				})
				.then((resp) => resp.data)
		},
		async storeAirport(payload: unknown) {
			return xhr
				.post<{ data: TWorldAirport }>("/world/airports", payload)
				.then((resp) => resp.data.data)
		},
		async updateAirport(id: number | string, payload: unknown) {
			return xhr
				.patch<{ data: TWorldAirport }>("/world/airports/" + id, payload)
				.then((resp) => resp.data.data)
		},
		async getBanks<T extends TWorldBank = TWorldBank>(params: unknown) {
			return xhr
				.get<IListResponse<T>>("/world/banks", {
					params,
				})
				.then((resp) => resp.data)
		},
		async getBank<T extends TWorldBank = TWorldBank>(
			id: number | string,
			params: unknown
		) {
			return xhr
				.get<{ data: T }>(`/world/banks/${id}`, {
					params: params,
				})
				.then((resp) => resp.data.data)
		},
		async storeBank(payload: unknown) {
			return xhr
				.post<{ data: TWorldBank }>("/world/banks", payload)
				.then((resp) => resp.data.data)
		},
		async updateBank(id: number | string, payload: unknown) {
			return xhr
				.patch<{ data: TWorldBank }>("/world/banks/" + id, payload)
				.then((resp) => resp.data.data)
		},
		async getBankBranches<T extends TWorldBankBranch = TWorldBankBranch>(
			params: unknown
		) {
			return xhr
				.get<IListResponse<T>>("/world/bank-branches", {
					params,
				})
				.then((resp) => resp.data)
		},
		async getBankBranch<T extends TWorldBankBranch = TWorldBankBranch>(
			id: number | string,
			params: unknown
		) {
			return xhr
				.get<{ data: T }>(`/world/bank-branches/${id}`, {
					params: params,
				})
				.then((resp) => resp.data.data)
		},
		async storeBankBranch(payload: unknown) {
			return xhr
				.post<{ data: TWorldBankBranch }>("/world/bank-branches", payload)
				.then((resp) => resp.data.data)
		},
		async updateBankBranch(id: number | string, payload: unknown) {
			return xhr
				.patch<{
					data: TWorldBankBranch
				}>("/world/bank-branches/" + id, payload)
				.then((resp) => resp.data.data)
		},
		async getBankBranchForCode(
			type: "ifsc" | "swift_code",
			code: string,
			config?: AxiosRequestConfig
		) {
			type TDetails = {
				data: Array<TWorldBankBranch>
			}
			switch (type) {
				case "ifsc":
					if (code.length !== 11) {
						return Promise.reject("Invalid Code")
					}
					return xhr
						.get<TDetails>(`/world/bank-branches/find-by-ifsc/${code}`, config)
						.then((resp) => resp.data.data)

				case "swift_code":
					if (code.length < 8 || code.length > 11) {
						return Promise.reject("Invalid Code")
					}
					return xhr
						.get<TDetails>(
							`/world/bank-branches/find-by-swift-code/${code}`,
							config
						)
						.then((resp) => resp.data.data)

				default:
					return Promise.reject("Invalid Code/Type")
			}
		},
	}
}
