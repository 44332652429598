import {
	Box,
	joinAttributes,
	Icons,
	RelativeTime,
	Component,
	Button,
	Stack,
} from "@sembark-travel/ui/base"
import { NewImageMediaForm } from "./NewItem"
import { TMedia } from "./store"

export function ImageMediaItem({
	media,
	canEdit = true,
	updateLabel,
	initiallyInEditingState,
	canRemove,
	hidePlaceholderIcon,
	...props
}: {
	media?: TMedia | null
	canEdit?: boolean
	updateLabel?: string
	initiallyInEditingState?: boolean
	hidePlaceholderIcon?: boolean
} & Omit<React.ComponentProps<typeof NewImageMediaForm>, "onCancel">) {
	if (!media && !canEdit) return null
	if (!updateLabel) {
		updateLabel = media ? "Update Image" : "Add Image"
	}
	return (
		<Stack gap="4">
			{media ? (
				<Box>
					<Box borderWidth="1" rounded="md" overflow="hidden">
						<img
							src={media.url}
							style={{ height: "auto", width: "100%", maxWidth: "100%" }}
							alt="Display"
						/>
						<Box padding="2" borderTopWidth="1">
							{joinAttributes(
								<>
									<Icons.User /> {media.created_by?.name || "You"}
								</>,
								<>
									<Icons.Calendar />{" "}
									<RelativeTime timestamp={media.created_at} />
								</>
							)}
						</Box>
					</Box>
				</Box>
			) : hidePlaceholderIcon ? null : (
				<Box
					style={{ width: "200px", height: "200px" }}
					display="flex"
					justifyContent="center"
					alignItems="center"
					bgColor="subtle"
					as="label"
					htmlFor="file"
				>
					<Icons.Photograph style={{ width: "100px", height: "100px" }} />
				</Box>
			)}
			{canEdit ? (
				<Box>
					<Component initialState={!media && initiallyInEditingState}>
						{({ state, setState }) =>
							!state ? (
								<Button
									onClick={() => setState(true)}
									size={media ? "sm" : "base"}
								>
									{updateLabel}
								</Button>
							) : (
								<NewImageMediaForm
									{...props}
									onSubmit={async (data) => {
										await props.onSubmit(data)
										setState(false)
									}}
									canRemove={canRemove && Boolean(media)}
									onCancel={() => setState(false)}
								/>
							)
						}
					</Component>
				</Box>
			) : null}
		</Stack>
	)
}
