import { Outlet } from "react-router-dom"
import { ForbidUnlessAuthorized, PERMISSIONS } from "../../../Auth"
import { useTriggerOnHotkey } from "@sembark-travel/ui/hotkey"
import { useNavigate } from "../../../router"

export default function Layout() {
	const navigate = useNavigate()
	useTriggerOnHotkey(function () {
		navigate("/admin/usage-stats")
	}, "Shift+G c")
	return (
		<ForbidUnlessAuthorized
			anyPermissions={[PERMISSIONS.MANAGE_TENANTS, PERMISSIONS.VIEW_TENANTS]}
		>
			<Outlet />
		</ForbidUnlessAuthorized>
	)
}
