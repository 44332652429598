import { Box } from "./Box"
import { StarIcon } from "./Icons"
import { useMemo } from "react"

export function Stars({
	stars,
	totalStars = 5,
}: {
	stars: string | number
	totalStars?: number
}) {
	const numericStar = parseInt(String(stars))
	const isNumeric = String(numericStar) === String(stars)
	const { filledStars, unfilledStars } = useMemo(() => {
		const filledStars: Array<number> = []
		const unfilledStars: Array<number> = []
		if (!isNumeric) return { filledStars, unfilledStars }
		for (let i = 0; i < numericStar; i++) filledStars.push(i)
		for (let i = 0; i < totalStars - numericStar; i++) unfilledStars.push(i)
		return { filledStars, unfilledStars }
	}, [totalStars, isNumeric, numericStar])
	return (
		<Box
			as="span"
			display="inline"
			whiteSpace="preserveAndWrap"
			color="muted"
			title={isNumeric ? `${stars} Stars` : String(stars)}
		>
			{isNumeric ? (
				<>
					{filledStars.map((i) => (
						<StarIcon key={`filled_${i}`} />
					))}
					{unfilledStars.map((i) => (
						<StarIcon key={`empty_${i}`} opacity="20" />
					))}
				</>
			) : (
				stars
			)}
		</Box>
	)
}
