import { parse, stringify } from "qs"
import { Optional } from "utility-types"

export function searchToQuery<
	T extends Record<string, unknown> = Record<string, unknown>,
>(search = "?", options: qs.IParseOptions = {}): Optional<T> {
	return parse(search, {
		ignoreQueryPrefix: true,
		...options,
	}) as Optional<T>
}

export function queryToSearch(
	query?: Record<string, unknown>,
	options: qs.IStringifyOptions = {}
): string {
	return stringify(query, { addQueryPrefix: true, ...options })
}
