import { Box, Spinner } from "@sembark-travel/ui/base"
import { setReportingUser } from "@sembark-travel/logging"
import { useAuthUser, useLogout } from "./../../Auth"
import { useSearchParams } from "react-router-dom"
import { useSWRConfig } from "swr"
import { useEffect } from "react"
import { unsubscribeUserDeviceForPushNotifications } from "../../Notifications"
import { Navigate } from "../../router-utils"
import { Helmet } from "react-helmet-async"

export default function LogoutPage() {
	// get the `from` query parameter from the logout props
	// and redirect back to `from` if present
	const [searchParams] = useSearchParams()
	const { cache } = useSWRConfig()
	const from = searchParams.get("from")
	const logout = useLogout()
	const { user } = useAuthUser()
	useEffect(() => {
		logout()
			.then(() => {
				setReportingUser(null)
				// cleanup the cache
				for (const key of cache.keys()) {
					cache.delete(key)
				}
				// unregister this device and user
				unsubscribeUserDeviceForPushNotifications()
			})
			.catch(() => undefined)
	}, [logout, cache])
	if (!user) {
		return <Navigate to={from as never as "/"} replace />
	}
	return (
		<>
			<Helmet>
				<title>Logout</title>
			</Helmet>
			<Box textAlign="center" paddingY="16" fontSize="lg">
				<Spinner alignCenter />
				<Box marginTop="4">Logging out...</Box>
			</Box>
		</>
	)
}
