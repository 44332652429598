import { Helmet } from "react-helmet-async"
import { TripSalesReportsList } from "../../../TripSalesReports"

export default function TripSalesReportsListPage() {
	return (
		<>
			<Helmet>
				<title>Trip Sales Report</title>
			</Helmet>
			<TripSalesReportsList />
		</>
	)
}
