import {
	Box,
	Button,
	Icons,
	Divider,
	Inline,
	Stack,
	useTimeout,
	SROnly,
	Col,
	Grid,
} from "@sembark-travel/ui/base"
import {
	dateToUTCString,
	startOf,
	endOf,
	addUnit,
} from "@sembark-travel/datetime-utils"
import { useXHR, XHRInstance } from "@sembark-travel/xhr"
import { useCallback, useMemo, useRef, useEffect } from "react"
import { $PropertyType, Required } from "utility-types"
import * as Validator from "yup"
import { type TChildrenArray } from "../Tourists"
import { SelectLocations, ILocation } from "./../Locations"
import { SelectAirline } from "./Airlines"
import { categoryClasses } from "./Classes"
import { IAirline, TClass } from "./store"
import {
	Form,
	TextInputField,
	SelectField,
	DatePickerField,
	validateFormValues,
	arrayMutators,
	FieldArray,
	GetFieldValue,
	SelectInputField,
	FormProps,
	useFormState,
	EmptyNumberValidator,
	MoneyInputField,
} from "@sembark-travel/ui/form"
import { TTripDestination } from "../TripDestinations"
import { useCurrenciesOfTenant } from "../Currencies"

function XHR(_: XHRInstance) {
	return {
		async getPrice<T>(flights: T) {
			return Promise.resolve({ flights })
			// return xhr
			//   .get("/prices", { params: { flights } })
			//   .then(resp => resp.data)
		},
	}
}

export interface FlightServicesParams {
	flights?: Array<{
		source?: ILocation
		destination?: ILocation
		departs_at?: Date
		arrives_at?: Date
		airline?: IAirline
		number_plate: string
		category_class: TClass
		no_of_adults: number
		children: number
		infants: number
		currency: string
		calculated_price?: number
		given_price?: number
		comments?: string
	}>
}

const validationSchema = Validator.object().shape({
	flights: Validator.array().of(
		Validator.object().shape({
			source: Validator.object().required(
				"Please select the source/departure airport"
			),
			destination: Validator.object().required(
				"Please select the destination/arrival airport"
			),
			departs_at: Validator.date().required(
				"Please select the departure date/time"
			),
			arrives_at: Validator.date().required(
				"Please select the arrival date/time"
			),
			airline: Validator.object().required(
				"Please select the provided airline"
			),
			number_plate: Validator.string().required(
				"Please provide the numner plate details of the flight"
			),
			category_class: Validator.string().required(
				"Please select the airline class"
			),
			no_of_adults: EmptyNumberValidator()
				.typeError("Number of persons should be a positive integer")
				.required("Please provide number adult passengers")
				.positive("Number of persons should be a positive integer")
				.integer("Number of persons should be a positive integer"),
			children: EmptyNumberValidator()
				.typeError("Number of persons should be a non-negative integer")
				.integer("Number of children should be a non-negative integer"),
			infants: EmptyNumberValidator()
				.typeError("Number of infants should be a non-negative integer")
				.integer("Number of infants should be a non-negative integer"),
			calculated_price: EmptyNumberValidator()
				.typeError("Price should be a positive number")
				.positive("Price should be a positive number"),
			given_price: EmptyNumberValidator()
				.typeError("Price should be a positive number")
				.required("Please provide the a tentative price")
				.positive("Price should be a positive number"),
			comments: Validator.string(),
		})
	),
})

const validate = validateFormValues(validationSchema)

interface FlightServicesFormProps {
	initialValues?: FlightServicesParams
	onChange?: (data: $PropertyType<FlightServicesParams, "flights">) => void
	bookingFrom?: string
	bookingTo?: string
	shouldEmptyInitialValues?: boolean
	no_of_adults?: number
	children?: TChildrenArray
	tripDestinations: Array<TTripDestination>
}
export function FlightServicesForm({
	initialValues: initialValuesProp,
	shouldEmptyInitialValues = true,
	onChange,
	bookingFrom,
	bookingTo,
	no_of_adults = 2,
	children = [],
	tripDestinations,
}: FlightServicesFormProps) {
	const xhr = useXHR()
	const [infants, no_of_children, childrenWithMoreAge] = useMemo(
		() => getChildrenDistribution(children),
		[children]
	)
	no_of_adults = no_of_adults + childrenWithMoreAge
	const currency = useMemo(
		() => tripDestinations.map((d) => d.currency).at(0) || "INR",
		[tripDestinations]
	)
	const allCurrencies = useCurrenciesOfTenant()
	const INITIAL_VALUES: Required<FlightServicesParams, "flights"> = useRef({
		flights: [
			{
				source: undefined,
				destination: undefined,
				departs_at: undefined,
				arrives_at: undefined,
				airline: undefined,
				number_plate: "",
				category_class: "Economy Class" as TClass,
				no_of_adults: 2,
				children: 0,
				infants: 0,
				currency,
				calculated_price: 0,
				given_price: 0,
				comments: "",
			},
		],
	}).current
	const initialValues = useMemo(() => {
		const initialValues = {
			...(initialValuesProp ||
				(shouldEmptyInitialValues ? { flights: [] } : INITIAL_VALUES)),
		}
		initialValues.flights =
			initialValues.flights?.map((f) => ({
				...f,
				no_of_adults: no_of_adults,
				children: no_of_children,
				infants: infants,
			})) || []
		return initialValues
	}, [
		shouldEmptyInitialValues,
		initialValuesProp,
		no_of_adults,
		no_of_children,
		infants,
		INITIAL_VALUES,
	])
	const notifyOnChange = useCallback(
		(flattenValues: FlightServicesParams) => {
			onChange &&
				onChange(
					flattenValues.flights?.filter(
						(service) =>
							service.source &&
							service.destination &&
							service.departs_at &&
							service.arrives_at &&
							service.airline &&
							service.number_plate &&
							service.category_class &&
							service.no_of_adults &&
							service.given_price
					) || []
				)
		},
		[onChange]
	)
	const onSubmit: FormProps<FlightServicesParams>["onSubmit"] = useCallback(
		async (values) => {
			const flights: Array<
				Omit<
					Required<typeof values, "flights">["flights"][number],
					"departs_at" | "arrives_at" | "source" | "destination" | "airline"
				> & {
					source: string
					destination: string
					departs_at: string
					arrives_at: string
					airline: string
					price?: number
					booked_price?: number
				}
			> = []
			// flatten values so that we can show the prices for each row
			const flattenValues: Required<FlightServicesParams, "flights"> = {
				flights: [],
			}
			values.flights?.forEach((values) => {
				const {
					source,
					destination,
					departs_at,
					arrives_at,
					airline,
					category_class,
					number_plate,
					calculated_price,
					given_price,
					no_of_adults,
					...otherData
				} = values
				if (
					source &&
					destination &&
					departs_at &&
					arrives_at &&
					airline &&
					category_class &&
					number_plate &&
					given_price
				) {
					flattenValues.flights.push({
						...values,
					})
					flights.push({
						...otherData,
						departs_at: dateToUTCString(departs_at),
						arrives_at: dateToUTCString(arrives_at),
						source: source.name,
						destination: destination.name,
						airline: airline.name,
						category_class,
						number_plate,
						given_price,
						no_of_adults,
					})
				}
			})
			return XHR(xhr)
				.getPrice(flights)
				.then((data) => {
					flattenValues.flights = flattenValues.flights.map((hotel, i) => ({
						...hotel,
						price: data.flights[i].price,
						booked_price: data.flights[i].booked_price || data.flights[i].price,
					}))
					notifyOnChange(flattenValues)
				})
		},
		[xhr, notifyOnChange]
	)
	return (
		<Form<FlightServicesParams>
			initialValues={initialValues}
			validate={validate}
			onSubmit={onSubmit}
			subscription={{}}
			mutators={{ ...arrayMutators }}
		>
			{({ handleSubmit }) => (
				<form noValidate onSubmit={handleSubmit}>
					<FieldArray<
						Required<FlightServicesParams>["flights"][number]
					> name="flights">
						{({ fields }) => (
							<Box>
								{fields.map((name, index) => (
									<Box
										key={name}
										paddingY="2"
										marginBottom="4"
										borderColor="default"
										borderBottomWidth="4"
									>
										<Grid>
											<Col sm={12} md={8} lg={9} paddingLeft="0" paddingY="3">
												<Stack>
													<Grid gap="4">
														<Col>
															<SelectField
																name={`${name}.source`}
																select={SelectLocations}
																label="From"
																placeholder="Source airport..."
																required
																creatable
																multiple={false}
															/>
														</Col>
														<Col>
															<SelectField
																name={`${name}.destination`}
																select={SelectLocations}
																label="Destination"
																placeholder="Destination airport..."
																required
																creatable
																multiple={false}
															/>
														</Col>
														<Col>
															<DatePickerField
																label="Departure Time"
																name={`${name}.departs_at`}
																required
																dateFormat
																timeFormat
															/>
														</Col>
														<Col>
															<DatePickerField
																label="Arrival Time"
																name={`${name}.arrives_at`}
																required
																dateFormat
																timeFormat
															/>
														</Col>
													</Grid>
													<Divider sm />
													<Grid gap="4">
														<Col>
															<SelectField
																name={`${name}.airline`}
																select={SelectAirline}
																label="Airline"
																placeholder="Select airline"
																required
																creatable
															/>
														</Col>
														<Col>
															<SelectInputField
																label="Class"
																name={`${name}.category_class`}
																placeholder="India"
															>
																{categoryClasses.map((c) => (
																	<option key={c} value={c}>
																		{c}
																	</option>
																))}
															</SelectInputField>
														</Col>
														<Col>
															<TextInputField
																label="Flight Number"
																name={`${name}.number_plate`}
																type="text"
																placeholder="AB 1234"
																maxLength={20}
																minLength={6}
															/>
														</Col>
													</Grid>
													<Divider sm />
													<Grid gap="4">
														<Col style={{ maxWidth: "100px" }}>
															<TextInputField
																label="No. of Adults"
																name={`${name}.no_of_adults`}
																type="number"
																placeholder="1"
																min={1}
																max={10000}
															/>
														</Col>
														<Col style={{ maxWidth: "120px" }}>
															<TextInputField
																label="Children"
																secondaryLabel="2-12 yrs"
																name={`${name}.children`}
																type="number"
																placeholder="0"
																min={0}
																max={10000}
															/>
														</Col>
														<Col style={{ maxWidth: "140px" }}>
															<TextInputField
																label="Infants"
																secondaryLabel="below 2 yrs"
																name={`${name}.infants`}
																type="number"
																placeholder="0"
																min={0}
																max={10000}
															/>
														</Col>
													</Grid>
												</Stack>
											</Col>
											<Col
												paddingY="3"
												paddingRight="0"
												borderLeftWidth={{ xs: "0", md: "1" }}
												paddingLeft={{ xs: "0", md: "4" }}
											>
												<Stack gap="4">
													<MoneyInputField
														label={`Cost Price`}
														name={`${name}.calculated_price`}
														currencyFieldName={`${name}.currency`}
														currencies={allCurrencies}
													/>
													<MoneyInputField
														label={`Given/Selling Price`}
														name={`${name}.given_price`}
														currencyFieldName={`${name}.currency`}
														currencies={allCurrencies}
														required
													/>
													<TextInputField
														type="text"
														name={`${name}.comments`}
														label="Comments"
														placeholder="Any comments regarding service"
													/>
													<Box paddingTop="4" borderTopWidth="1">
														<Inline gap="4" flexWrap="wrap">
															<Button
																onClick={() => fields.remove(index)}
																size="sm"
																level="tertiary"
															>
																<Icons.Cancel /> Remove
															</Button>
														</Inline>
													</Box>
												</Stack>
											</Col>
										</Grid>
									</Box>
								))}
								<Box marginTop="4">
									<GetFieldValue<
										FlightServicesParams["flights"]
									> name="flights">
										{({ value: flights }) =>
											!flights?.length ? (
												<Inline gap="2">
													<Button
														level="secondary"
														status="primary"
														onClick={() => {
															fields.push({
																...INITIAL_VALUES.flights[0],
																departs_at: bookingFrom
																	? startOf(bookingFrom, "day")
																	: undefined,
																arrives_at: bookingFrom
																	? addUnit(
																			startOf(bookingFrom, "day"),
																			2,
																			"hours"
																		)
																	: undefined,
																no_of_adults,
																children: no_of_children,
																infants,
															})
															fields.push({
																...INITIAL_VALUES.flights[0],
																departs_at: bookingTo
																	? endOf(bookingTo, "day")
																	: undefined,
																arrives_at: bookingTo
																	? addUnit(endOf(bookingTo, "day"), 2, "hours")
																	: undefined,
																no_of_adults,
																children: no_of_children,
																infants,
															})
														}}
													>
														<Icons.SwitchHorizontal /> Add Round trip
													</Button>
													<Button
														onClick={() =>
															fields.push({
																...INITIAL_VALUES.flights[0],
																departs_at: bookingFrom
																	? startOf(bookingFrom, "day")
																	: undefined,
																arrives_at: bookingFrom
																	? addUnit(
																			startOf(bookingFrom, "day"),
																			2,
																			"hours"
																		)
																	: undefined,
																no_of_adults,
																children: no_of_children,
																infants,
															})
														}
													>
														<Icons.Plus /> Add One way
													</Button>
												</Inline>
											) : (
												<Button
													status="primary"
													onClick={() =>
														fields.push({
															...INITIAL_VALUES.flights[0],
															no_of_adults,
															children: no_of_children,
															infants,
														})
													}
												>
													<Icons.Plus /> Add More Flight
												</Button>
											)
										}
									</GetFieldValue>
								</Box>
							</Box>
						)}
					</FieldArray>
					<SubmitOnChange onChange={notifyOnChange} />
					<SROnly>
						<Button type="submit">Get Prices</Button>
					</SROnly>
				</form>
			)}
		</Form>
	)
}

function SubmitOnChange({
	onChange,
}: {
	onChange: (values: FlightServicesParams) => void
}) {
	const { values } = useFormState<FlightServicesParams>({
		subscription: { values: true },
	})
	const { set: setNotifyParentTimeout, clear: clearNotifyParentTimeout } =
		useTimeout()

	const onChangeRef = useRef(onChange)
	onChangeRef.current = onChange

	// notify parent
	useEffect(() => {
		setNotifyParentTimeout(() => {
			onChangeRef.current(values)
		}, 500)
		return () => clearNotifyParentTimeout()
	}, [values, setNotifyParentTimeout, clearNotifyParentTimeout])
	return null
}

function getChildrenDistribution(children: TChildrenArray): [
	/**
	 * Infants, age within (0,2]
	 */
	number,
	/**
	 * Children, age within (2, 12]
	 */
	number,
	/**
	 * Adults with age greater than 12
	 */
	number,
] {
	const totalCount = (arr: number[]) =>
		arr.reduce<number>((sum, n) => sum + n, 0)
	return [
		totalCount(children.filter((c) => c.age <= 2).map((c) => c.count)),
		totalCount(
			children.filter((c) => c.age > 2 && c.age <= 12).map((c) => c.count)
		),
		totalCount(children.filter((c) => c.age > 12).map((c) => c.count)),
	]
}
