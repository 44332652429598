import { AsyncSelect, AsyncSelectProps } from "@sembark-travel/ui/base"
import { useXHR } from "@sembark-travel/xhr"
import { transportServicesXHR as XHR } from "./store"

export function SelectTransportServiceLocationPoints(
	props: Omit<AsyncSelectProps, "fetch">
) {
	const xhr = useXHR()
	return (
		<AsyncSelect
			{...props}
			fetch={(q) =>
				XHR(xhr)
					.getTransportServiceLocationPoints({ q })
					.then((resp) => resp.data)
			}
		/>
	)
}
