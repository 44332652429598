import { Helmet } from "react-helmet-async"
import { OperationalBookingsCalendarWithFilters } from "./../../../TripOperationalBookings"

export default function SchedulesCalendarModule() {
	return (
		<>
			<Helmet>
				<title>Trip Operational Calendar</title>
			</Helmet>
			<OperationalBookingsCalendarWithFilters />
		</>
	)
}
