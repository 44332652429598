import { Helmet } from "react-helmet-async"
import { ITenant, TenantModules } from "../../../../../Admin/Tenants"
import { useOutletContext } from "react-router-dom"

export default function TenantModulesPage() {
	const { tenant, refresh } = useOutletContext<{
		tenant: ITenant
		refresh: () => void
	}>()
	return (
		<>
			<Helmet>
				<title>Tenant Modules</title>
			</Helmet>
			<TenantModules tenant={tenant} onChange={refresh} />
		</>
	)
}
