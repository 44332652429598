import { IUser } from "../Auth"
import { useXHR } from "@sembark-travel/xhr"
import { dateToUTCString, endOf, startOf } from "@sembark-travel/datetime-utils"
import useSWR from "swr"

export type TCommentableType = "trips" | "instalments" | "tenants"

export interface IComment {
	id: number
	body: string
	due_at?: string
	resolved_at?: string
	resolved_by?: IUser
	is_resolved: boolean
	needs_resolution: boolean
	assigned_to?: IUser
	created_by: IUser
	created_at: string
	commentable_id: number
	commentable_type: TCommentableType
}

export function useDemandingCommentsCount(from: Date, to: Date) {
	const xhr = useXHR()
	const status = "demanding"
	const due_after = dateToUTCString(startOf(from, "day"))
	const due_before = dateToUTCString(endOf(to, "day"))
	const { data } = useSWR(
		`comments-count?from=${due_after}&to=${due_before}`,
		() =>
			xhr
				.get<{ data: number }>("/comments-count", {
					params: {
						status: status,
						due_after,
						due_before,
					},
				})
				.then((resp) => resp.data)
	)
	return { data }
}
