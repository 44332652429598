import { Container, Box } from "@sembark-travel/ui/base"
import {
	useGetBackUrlFromLocation,
	Breadcrumbs,
} from "@sembark-travel/ui/router"
import { Helmet } from "react-helmet-async"
import { generatePath, useNavigate } from "../../../router-utils"
import { NewTripSource } from "../../../TripSources"
import { ForbidUnlessAuthorized, PERMISSIONS } from "../../../Auth"

export default function NewTripSourcePage() {
	const navigate = useNavigate()
	const backFromUrl = useGetBackUrlFromLocation()
	return (
		<>
			<Helmet>
				<title>New Trip Source</title>
			</Helmet>
			<ForbidUnlessAuthorized permission={PERMISSIONS.ADD_TRIP_SOURCES}>
				<Breadcrumbs
					title="New Trip Source"
					items={[
						[generatePath("/org/trip-sources"), "Trip Sources"],
						["", "New"],
					]}
				/>
				<Box paddingX={{ md: "4" }} paddingY={{ md: "8" }}>
					<Box maxWidth="4xl" marginX="auto">
						<Box borderWidth={{ md: "1" }} rounded="md" bgColor="default">
							<Container paddingY="6">
								<NewTripSource
									onSuccess={(tripSource) =>
										navigate("/trip-sources/:tripSourceId", {
											params: {
												tripSourceId: tripSource.id.toString(),
											},
											replace: true,
										})
									}
									onCancel={() =>
										backFromUrl
											? navigate(backFromUrl as "/org/trip-sources", {
													replace: true,
											  })
											: navigate("/org/trip-sources", { replace: true })
									}
								/>
							</Container>
						</Box>
					</Box>
				</Box>
			</ForbidUnlessAuthorized>
		</>
	)
}
