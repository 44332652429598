import { AsyncSelect } from "@sembark-travel/ui/base"
import { useXHR } from "@sembark-travel/xhr"
import React from "react"

type AsyncProps = React.ComponentProps<typeof AsyncSelect>

export function SelectHotelExtraServices({
	...otherProps
}: Omit<AsyncProps, "fetch">) {
	const xhr = useXHR()
	return (
		<AsyncSelect
			{...otherProps}
			fetch={(q) =>
				xhr
					.get("/hotel-extra-services", { params: { q } })
					.then((resp) => resp.data.data)
			}
		/>
	)
}

export function SelectTransportExtraServices({
	...otherProps
}: Omit<AsyncProps, "fetch">) {
	const xhr = useXHR()
	return (
		<AsyncSelect
			{...otherProps}
			fetch={(q) =>
				xhr
					.get("/transport-extra-services", { params: { q } })
					.then((resp) => resp.data.data)
			}
		/>
	)
}

export function SelectOtherExtraServices({
	...otherProps
}: Omit<AsyncProps, "fetch">) {
	const xhr = useXHR()
	return (
		<AsyncSelect
			{...otherProps}
			fetch={(q) =>
				xhr
					.get("/other-extra-services", { params: { q } })
					.then((resp) => resp.data.data)
			}
		/>
	)
}
