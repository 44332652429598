import { Box, Container } from "@sembark-travel/ui/base"
import { Breadcrumbs } from "@sembark-travel/ui/router"
import { Helmet } from "react-helmet-async"
import { AddNewTravelActivityItem } from "./../../../TravelActivities"
import { generatePath, useNavigate } from "../../../router-utils"

export default function NewTravelActivityPage() {
	const navigate = useNavigate()
	function navigateBackToList() {
		return navigate("/travel-activities", { replace: true })
	}
	return (
		<>
			<Helmet>
				<title>Add Travel Activity</title>
			</Helmet>
			<Breadcrumbs
				title="New Travel Activity"
				items={[
					[generatePath("/travel-activities"), "Travel Activities"],
					["", "New"],
				]}
			/>
			<Box paddingX="4" paddingY="8">
				<Box maxWidth="6xl" marginX="auto">
					<Box borderWidth="1" rounded="md" bgColor="default">
						<Container paddingY="6">
							<AddNewTravelActivityItem
								onSuccess={navigateBackToList}
								onCancel={navigateBackToList}
							/>
						</Container>
					</Box>
				</Box>
			</Box>
		</>
	)
}
