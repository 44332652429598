import { Helmet } from "react-helmet-async"
import { Transactions } from "./../../../../Accounting"

export default function TransactionsPage(
	props: React.ComponentProps<typeof Transactions>
) {
	return (
		<>
			<Helmet>
				<title>Transactions</title>
			</Helmet>
			<Transactions {...props} />
		</>
	)
}
