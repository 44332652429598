import {
	Box,
	Icons,
	Heading,
	Text,
	Stack,
	Divider,
	Container,
	Inline,
} from "@sembark-travel/ui/base"
import { logError } from "@sembark-travel/logging"
import { stringify } from "qs"
import React, { useEffect } from "react"
import {
	CopyToClipboard,
	CopyToClipboardButton,
} from "@sembark-travel/ui/copy-to-clipboard"
import { isApiResponseError } from "@sembark-travel/xhr"

const config = {
	appVersion: "0.0.0" as string,
}

export function bootstrap(c: typeof config) {
	config.appVersion = c.appVersion
}

type ErrorHandler = (error: Error, info: React.ErrorInfo) => void
type ErrorHandlingComponent<Props> = (
	props: Props,
	error?: Error
) => React.ReactNode

type ErrorState = { error?: Error }

export function Catch<Props extends Record<string, unknown>>(
	component: ErrorHandlingComponent<Props>,
	errorHandler?: ErrorHandler
): React.ComponentType<Props> {
	return class extends React.Component<Props, ErrorState> {
		override state: ErrorState = {
			error: undefined,
		}
		static getDerivedStateFromError(error: Error) {
			return { error }
		}
		override componentDidCatch(error: Error, info: React.ErrorInfo) {
			if (errorHandler) {
				errorHandler(error, info)
			}
		}
		override render() {
			return component(this.props, this.state.error)
		}
	}
}

export const ErrorBoundary = Catch(function ErrorBoundary(
	props: { children: React.ReactNode },
	error?: Error
) {
	if (error) {
		return <ReportAndRenderError error={error} />
	}
	return <>{props.children}</>
})

export function ReportAndRenderError({ error }: { error: Error }) {
	// log the error
	useEffect(() => {
		// only log non-api response errors as they gets logged in out backend
		if (!isApiResponseError(error)) {
			logError(error)
		}
	}, [error])
	const errorMessage = error.message
	if (isApiResponseError(error)) {
		const response = error.response
		return (
			<Container paddingY="8">
				<Box role="alert" aria-live="assertive" maxWidth="4xl" marginX="auto">
					<Stack gap="2">
						<Heading as="h2" color="danger">
							<Icons.Attention size="8" /> {response?.status || "Opps..."}{" "}
							{response?.statusText || " - Something went wrong!"}
						</Heading>
						<Box
							padding="4"
							borderLeftWidth="4"
							borderColor="danger"
							bgColor="danger"
							color="danger"
							whiteSpace="preserveLine"
							overflow="auto"
							style={{ maxHeight: "200px" }}
							boxShadow="inner"
						>
							<Text>{errorMessage}</Text>
						</Box>
						<Text as="p" fontWeight="semibold" fontSize="lg">
							But please don't worry. We have been notified and the issue will
							be fixed soon.
						</Text>
						<Text>
							In the mean time, please try to{" "}
							<Text
								as="a"
								href="#refresh"
								color="primary"
								onClick={() => {
									window.location.reload()
								}}
							>
								refresh the page
							</Text>{" "}
							or use other modules.
						</Text>
					</Stack>
				</Box>
			</Container>
		)
	}
	return (
		<Container paddingY="8">
			<Box role="alert" aria-live="assertive" maxWidth="4xl" marginX="auto">
				<Stack gap="2">
					<Heading as="h2" color="danger">
						<Icons.Attention size="8" /> Opps... Something went wrong!
					</Heading>
					<Box
						padding="4"
						borderLeftWidth="4"
						borderColor="danger"
						bgColor="danger"
						color="danger"
						whiteSpace="preserveLine"
						overflow="auto"
						style={{ maxHeight: "200px" }}
						boxShadow="inner"
					>
						<Text>{errorMessage}</Text>
					</Box>
					<Text as="blockquote">
						<b>Page Url</b>: {window.location.href}
					</Text>
					<Text as="p" fontWeight="semibold" fontSize="md">
						But please don't worry. We have been notified and the issue will be
						fixed soon.
					</Text>
					<Text>
						In the mean time, please try to{" "}
						<Text
							as="a"
							href="#refresh"
							color="primary"
							onClick={() => {
								window.location.reload()
							}}
						>
							refresh the page
						</Text>{" "}
						or use other modules.
					</Text>
				</Stack>
				<Divider />
				<Stack gap="4">
					<Heading as="h3">
						<Icons.Support opacity="70" size="6" /> Need Help ?
					</Heading>
					<Text>
						If you need any further assistance, please contact our support at{" "}
						<a
							href={`mailto:support@sembark.com${stringify(
								{
									subject: `[${config.appVersion}]: Issue: ${errorMessage}`,
								},
								{ addQueryPrefix: true }
							)}`}
						>
							support@sembark.com
						</a>{" "}
						with the error details.
					</Text>
					<CopyToClipboard<HTMLDivElement>>
						{({ nodeToCopy, copy, copyNodeTextToClipboard }) => (
							<Stack gap="4">
								<Inline gap="4" justifyContent="end" alignItems="center">
									<Text fontWeight="semibold">Copy As:</Text>
									<CopyToClipboardButton onClick={() => copy()} size="sm">
										<Icons.Mail /> Email
									</CopyToClipboardButton>
									<CopyToClipboardButton
										onClick={() => copyNodeTextToClipboard()}
										size="sm"
									>
										<Icons.ClipboardCopy /> Text
									</CopyToClipboardButton>
								</Inline>
								<Box<"div">
									ref={nodeToCopy}
									style={{ maxHeight: "200px", overflow: "auto" }}
									borderWidth="1"
									padding="4"
									rounded="md"
									bgColor="default"
								>
									<Stack>
										<Text>Hi,</Text>
										<Text>
											I am facing an issue in the dashboard. Here are more
											details.
										</Text>
										<Divider sm />
										<Text>
											<b>App Version</b>: {config.appVersion}
										</Text>
										<Text>
											<b>Message</b>: {errorMessage}
										</Text>
										<Text>
											<b>Page Url</b>: {window.location.href}
										</Text>
										<Divider sm />
										<Text whiteSpace="preserveLine">{error.stack}</Text>
									</Stack>
								</Box>
							</Stack>
						)}
					</CopyToClipboard>
				</Stack>
				<Divider />
				<Box>
					<Stack gap="3">
						<Heading as="h3">
							<Icons.Info opacity="70" size="6" /> Common cause and solutions
						</Heading>
						<Box as="p">
							Here are some common problems where can cause these type of
							issues.
						</Box>
					</Stack>
					<Box as="dl" marginLeft="6" listStyleType="disc" marginBottom="4">
						<Box as="dt" marginBottom="1">
							Your are using an older version of application.
						</Box>
						<Box as="dd" marginBottom="4">
							<Icons.Ok /> Update your application by hard refreshing (hold{" "}
							<kbd>shift</kbd> on keyboard while reloading) the page.
						</Box>
						<Box as="dt" marginBottom="1">
							You are accessing the application while we are upgrading.
						</Box>
						<Box as="dd" marginBottom="4">
							<Icons.Ok /> Please wait while we update the application and retry
							after some time.
						</Box>
						<Box as="dt" marginBottom="1">
							There are some problems with data transfer over network.
						</Box>
						<Box as="dd" marginBottom="4">
							<Icons.Ok /> Please check your internet connection.
						</Box>
					</Box>
				</Box>
			</Box>
		</Container>
	)
}
