import {
	Box,
	Stack,
	Button,
	Icons,
	Inline,
	Table,
	joinAttributes,
	Time,
	TimeDuration,
	Tooltip,
	SelectInput,
	Container,
	Text,
	Money,
	MoneySum,
	Alert,
} from "@sembark-travel/ui/base"
import { Link, useLocationQuery, XHRLink } from "@sembark-travel/ui/router"
import {
	Search,
	TSearchParams,
	useSearch,
	areAdvancedFiltersAppliedDefault,
	ListView,
} from "@sembark-travel/ui/list"
import {
	utcTimestampToLocalDate,
	dateToQuery,
	dateToUTCString,
	parseDateFromQuery,
	startOf,
	endOf,
	subtractUnit,
	formatDate,
	addUnit,
	isAfter,
} from "@sembark-travel/datetime-utils"
import { IListResponse, XHRInstance } from "@sembark-travel/xhr"
import { useEffect, useMemo } from "react"
import { $PropertyType } from "utility-types"
import config from "../config"
import { Email, PhoneNumber } from "../Contacts"
import { generatePath } from "../router-utils"
import { SelectTripDestination } from "../TripDestinations"
import { TTripDestination } from "../TripDestinations"
import { SelectTripOwners } from "../Trips"
import { SelectTripSources } from "../TripSources"
import { ITripSource } from "../TripSources"
import { IUser } from "../Users"
import { TTripCheckoutReport } from "./types"
import {
	SelectField,
	SwitchInputField,
	isTruthy,
} from "@sembark-travel/ui/form"
import { TFormattedMoney } from "@sembark-travel/money"

type TMeta = {
	total_checkouts: number
	total_package_money: Array<TFormattedMoney>
	total_bookings_money: Array<TFormattedMoney>
}

function XHR(xhr: XHRInstance) {
	return {
		async get(params?: unknown) {
			return xhr
				.get<
					IListResponse<TTripCheckoutReport> & {
						meta: TMeta
					}
				>("/trip-checkout-reports", { params })
				.then(({ data }) => data)
		},
	}
}

type TFilters = TSearchParams & {
	after: Date
	before: Date
	destinations?: Array<TTripDestination>
	trip_sources?: Array<ITripSource>
	sales_team?: Array<IUser>
	intervalType: "month" | "week" | "day"
	include_incompleted?: 0 | 1
}

type TFiltersInQuery = TSearchParams & {
	after?: string
	before?: string
	destinations?: Array<string>
	trip_sources?: Array<string>
	sales_team?: Array<string>
	intervalType: "month" | "week" | "day"
	include_incompleted?: 0 | 1
}

function filtersToQuery(filters: TFilters): TFiltersInQuery {
	const {
		q,
		after,
		before,
		intervalType,
		page,
		destinations,
		trip_sources,
		sales_team,
		include_incompleted,
	} = filters
	const query: TFiltersInQuery = {
		after: dateToQuery(after || startOf(new Date(), "month")),
		before: dateToQuery(before || endOf(new Date(), "month")),
		intervalType: intervalType || "month",
		include_incompleted: isTruthy(include_incompleted) ? 1 : undefined,
	}
	if (q) {
		query.q = q
	}
	if (page) query.page = page
	if (destinations) {
		query.destinations = destinations.map((d) => `${d.id}_${d.name}`)
	}
	if (trip_sources) {
		query.trip_sources = trip_sources.map((d) => `${d.id}_${d.name}`)
	}
	if (sales_team) {
		query.sales_team = sales_team.map((d) => `${d.id}_${d.name}`)
	}
	return query
}

function queryToFilters(query: TFiltersInQuery): TFilters {
	const {
		q,
		after,
		before,
		intervalType,
		page,
		destinations,
		trip_sources,
		sales_team,
		include_incompleted,
	} = query
	const filters: TFilters = {
		after: after
			? startOf(parseDateFromQuery(after), "day")
			: startOf(new Date(), intervalType || "month"),
		before: before
			? endOf(parseDateFromQuery(before), "day")
			: endOf(new Date(), intervalType || "month"),
		intervalType: intervalType || "month",
		include_incompleted: isTruthy(include_incompleted) ? 1 : 0,
	}
	if (q) {
		filters.q = q
	}
	if (page) filters.page = page
	if (destinations) {
		filters.destinations = destinations.map((d) => {
			const [id, ...name] = d.split("_")
			return {
				id,
				name: name.join("_"),
			}
		}) as unknown as $PropertyType<TFilters, "destinations">
	}
	if (trip_sources) {
		filters.trip_sources = trip_sources.map((d) => {
			const [id, ...name] = d.split("_")
			return {
				id,
				name: name.join("_"),
			}
		}) as unknown as $PropertyType<TFilters, "trip_sources">
	}
	if (sales_team) {
		filters.sales_team = sales_team.map((d) => {
			const [id, ...name] = d.split("_")
			return {
				id,
				name: name.join("_"),
			}
		}) as unknown as $PropertyType<TFilters, "sales_team">
	}
	return filters
}

export function TripCheckoutReportsList() {
	const [query, setQuery] = useLocationQuery({
		toQuery: filtersToQuery,
		fromQuery: queryToFilters,
	})
	const [params, setParams] = useSearch<TFilters>(query)
	const requestParams = useMemo(() => {
		return {
			q: params.q || null,
			after: dateToUTCString(params.after),
			before: dateToUTCString(params.before),
			destinations: (params.destinations || []).map((d) => d.id),
			sources: (params.trip_sources || []).map((d) => d.id),
			sales_team: (params.sales_team || []).map((d) => d.id),
			include_incompleted: isTruthy(params.include_incompleted) ? 1 : 0,
		}
	}, [params])
	useEffect(() => {
		setQuery(params)
	}, [params, setQuery])
	return (
		<Box>
			<Search
				title="Trip Checkout Report"
				initialParams={params}
				onSearch={setParams}
				Filters={Filters}
				resetParams={(params) => ({
					q: "",
					after: params.after,
					before: params.before,
					intervalType: params.intervalType,
				})}
				areAdvancedFiltersApplied={(params) => {
					const {
						before,
						after,
						intervalType,
						include_incompleted,
						...others
					} = params
					return (
						isTruthy(include_incompleted) ||
						areAdvancedFiltersAppliedDefault(others)
					)
				}}
				actions={({ setSearchParams, searchParams }) => (
					<Inline alignItems="center">
						<Button
							onClick={() => {
								const after = startOf(
									subtractUnit(
										searchParams.after,
										1,
										searchParams.intervalType
									),
									searchParams.intervalType
								)
								setSearchParams({
									after,
									before: endOf(after, searchParams.intervalType),
								})
							}}
						>
							<Icons.ChevronDown rotate="90" />
						</Button>
						<Box paddingX="2">
							<SelectInput
								value={searchParams.intervalType}
								onChange={(e) => {
									const type = (e.currentTarget.value ||
										"month") as typeof searchParams.intervalType
									setSearchParams({
										intervalType: type,
										after: startOf(new Date(), type),
										before: endOf(new Date(), type),
									})
								}}
							>
								<option value="month">Month</option>
								<option value="week">Week</option>
								<option value="day">Day</option>
							</SelectInput>
						</Box>
						<Button
							onClick={() => {
								const after = startOf(
									addUnit(searchParams.after, 1, searchParams.intervalType),
									searchParams.intervalType
								)
								setSearchParams({
									after,
									before: endOf(after, searchParams.intervalType),
									include_incompleted: isAfter(searchParams.before, new Date())
										? 1
										: searchParams.include_incompleted,
								})
							}}
						>
							<Icons.ChevronDown rotate="270" />
						</Button>
					</Inline>
				)}
			>
				{() => (
					<Stack gap="2" paddingY="4">
						<Container>
							<Text fontWeight="semibold" fontSize="xl">
								{formatDate(params.after, "ddd D MMM")} -{" "}
								{formatDate(params.before, "ddd D MMM")}
							</Text>
						</Container>
						<Container>
							{isTruthy(params.include_incompleted) ? (
								<Alert status="warning" inline>
									Including data from Incompleted/Future Trips
								</Alert>
							) : null}
						</Container>
						<ListView<TTripCheckoutReport, TFilters>
							pageKey="trip-checkout-reports"
							params={params}
							onPageChange={(page) => setParams({ ...params, page })}
							fetch={(xhr, { before, after, trip_sources, ...params }) => {
								return XHR(xhr).get({
									...params,
									after: dateToUTCString(after),
									before: dateToUTCString(before),
									destinations: (params.destinations || []).map((d) => d.id),
									sources: (trip_sources || []).map((d) => d.id),
									sales_team: (params.sales_team || []).map((d) => d.id),
								})
							}}
						>
							{({ items, meta: unsafe_meta }) => {
								const meta: TMeta & IListResponse<TTripCheckoutReport>["meta"] =
									unsafe_meta as never
								return (
									<Stack gap="8">
										<Inline
											bgColor="default"
											roundedRight="lg"
											marginBottom="8"
											borderLeftWidth="4"
											borderColor="accent"
											paddingY="2"
											justifyContent="between"
											alignItems="stretch"
											collapseBelow="md"
											gap="4"
										>
											<Inline alignItems="stretch" collapseBelow="md">
												<Box paddingX="8" paddingY="4">
													<Box fontWeight="semibold" color="muted">
														Total Checkouts
													</Box>
													<Box fontWeight="semibold" fontSize="3xl">
														{meta.total}
													</Box>
												</Box>
												<Inline alignItems="stretch" flexWrap="wrap">
													<Box paddingX="8" paddingY="4">
														<Box fontWeight="semibold" color="muted">
															Total Packages{" "}
															{meta.last_page > 1 ? (
																<Icons.Attention
																	color="warning"
																	title="This page data only"
																/>
															) : null}
														</Box>
														<Box fontWeight="semibold" fontSize="3xl">
															<MoneySum
																money={meta.total_package_money}
																roundOff
															/>
														</Box>
													</Box>
													<Box paddingX="8" paddingY="4">
														<Box fontWeight="semibold" color="muted">
															Total Bookings{" "}
															{meta.last_page > 1 ? (
																<Icons.Attention
																	color="warning"
																	title="This page data only"
																/>
															) : null}
														</Box>
														<Box fontWeight="semibold" fontSize="3xl">
															<MoneySum
																money={meta.total_bookings_money}
																roundOff
															/>
														</Box>
													</Box>
												</Inline>
											</Inline>
											<Box>
												<Box padding="4" textAlign="right">
													<Button
														as={XHRLink}
														href="/trip-checkout-reports/download"
														title="Download Full Report as Excel/CSV File"
														level="primary"
														query={{
															...requestParams,
															timezone_offset: config.timezoneOffset,
														}}
														download
													>
														<Icons.DocumentDownload /> Full Report
													</Button>
												</Box>
											</Box>
										</Inline>
										<Table
											hover
											responsive
											headers={[
												"Id",
												"Guest",
												"Basic Details",
												"Start",
												"End",
												"Package",
												"Tax",
												"Bookings",
												"Profit (Est.)",
												"Profit %",
											]}
											bordered
											alignCols={{
												3: "center",
												4: "center",
												5: "right",
												6: "right",
												7: "right",
												8: "right",
												9: "right",
											}}
											rows={items.map((trip) => [
												<Link
													color="accent"
													to={generatePath("/trips/:tripId", {
														tripId: trip.id.toString(),
													})}
												>
													{trip.id}{" "}
													{trip.status === "Dropped" ? (
														<Icons.Ban color="warning" title="Dropped" />
													) : null}
												</Link>,
												trip.tourist ? (
													<Box>
														{trip.tourist.name}
														<PhoneNumber
															value={trip.tourist.phone_numbers}
															iconOnly
															marginLeft="2"
														/>
														<Email
															value={trip.tourist.email}
															iconOnly
															marginLeft="2"
														/>
													</Box>
												) : (
													"N/A"
												),
												<Box>
													{joinAttributes(
														trip.destinations
															.map((d) => d.short_name)
															.join(", "),
														<Box as="span" title={trip.trip_source.name}>
															{trip.trip_source.short_name}
														</Box>,
														<TimeDuration days={trip.days}>
															{trip.days}D
														</TimeDuration>
													)}
												</Box>,
												<Time
													value={utcTimestampToLocalDate(trip.start_date)}
													format="DD MMM"
													whiteSpace="preserve"
												/>,
												<Time
													value={utcTimestampToLocalDate(trip.end_date)}
													format="DD MMM"
													whiteSpace="preserve"
												/>,
												<Money
													money={trip.package_money}
													showCurrency
													roundOff
												/>,
												trip.latest_given_quote ? (
													!trip.latest_given_quote.gst_included ? (
														"exc"
													) : (
														<Money
															money={trip.tax_money}
															showCurrency
															roundOff
															title={
																trip.latest_given_quote.tax_percentage
																	? `${trip.latest_given_quote.tax_percentage}%`
																	: "inc"
															}
														/>
													)
												) : null,
												<Link
													to={generatePath("/trips/:tripId/services-bookings", {
														tripId: String(trip.id),
													})}
												>
													<Inline
														gap="1"
														alignItems="center"
														justifyContent="end"
													>
														{trip.pending_bookings_count ? (
															<Tooltip content="Some bookings are still pending">
																<Box>
																	<Icons.AttentionSolid color="warning" />
																</Box>
															</Tooltip>
														) : null}
														<Box>
															<MoneySum
																money={trip.total_booking_prices}
																roundOff
																separator={
																	<>
																		<br />
																		+&nbsp;
																	</>
																}
															/>
														</Box>
													</Inline>
												</Link>,
												<Link
													to={generatePath(
														"/trips/:tripId/accounting/transactions",
														{
															tripId: String(trip.id),
														}
													)}
												>
													{trip.profit_so_far ? (
														<Money
															money={trip.profit_so_far}
															showCurrency
															roundOff
															color={
																trip.profit_so_far.amount <= 0
																	? "danger"
																	: undefined
															}
														/>
													) : null}
												</Link>,
												trip.profit_percentage !== null ? (
													<Percentage value={trip.profit_percentage} />
												) : null,
											])}
										/>
									</Stack>
								)
							}}
						</ListView>
					</Stack>
				)}
			</Search>
		</Box>
	)
}

function Filters() {
	return (
		<Stack gap="4">
			<SelectField
				select={SelectTripDestination}
				name="destinations"
				label="Destinations"
				multiple
				fetchOnMount
			/>
			<SelectField
				select={SelectTripSources}
				name="trip_sources"
				label="Trip Sources"
				multiple
				fetchOnMount
			/>
			<SelectField
				select={SelectTripOwners}
				name="sales_team"
				label="Sales Team"
				multiple
				fetchOnMount
			/>
			<SwitchInputField
				name="include_incompleted"
				label="Include Incompleted/Future Trips"
			/>
		</Stack>
	)
}

function Percentage({
	value,
	fixedPoint = true,
}: {
	value: number
	fixedPoint?: boolean
}) {
	value = Number(value)
	return (
		<Text as="span">
			{fixedPoint ? value.toFixed(2) : value}
			<Box as="span" fontSize="xs" color="muted">
				%
			</Box>
		</Text>
	)
}
