import { Helmet } from "react-helmet-async"
import { TripVoucher } from "../../../../../Trips"
import { useTripDetailsOutletContext } from "../_trip-details-context"

export default function TripVoucherPage() {
	const { trip, hasChangesBetweenQuoteAndBookings } =
		useTripDetailsOutletContext()
	return (
		<>
			<Helmet>
				<title>Trip Voucher Docs</title>
			</Helmet>
			<TripVoucher
				trip={trip}
				disabledSharing={hasChangesBetweenQuoteAndBookings}
			/>
		</>
	)
}
