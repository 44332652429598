import {
	Box,
	Button,
	Inline,
	Icons,
	Select,
	Stack,
	DateTimeInput,
} from "@sembark-travel/ui/base"
import { Required } from "utility-types"
import { getRemainingDates } from "../utils"
import {
	addUnit,
	startOf,
	isSame,
	parseDate,
	isAfter,
	isBefore,
} from "@sembark-travel/datetime-utils"

import {
	FieldGroup,
	TextInputField,
	GetFieldValue,
	FormSpy,
	useFieldValue,
	FieldArray,
	isTruthy,
	addKeyToFieldArrayItem,
	getFieldArrayItemKey,
	useForm,
	getFormValueForKey,
} from "@sembark-travel/ui/form"
import { TravelActivityDatewisePricesInputField } from "../TravelActivityPrices"
import { useHasFeatureFlag } from "../Auth"
import { TUsedCabTypes } from "./UsedCabTypesInputField"
import { TransportServiceDatewisePricesInputField } from "./TransportServiceDatewisePricesInputField"
import {
	createDatesFromStartDateAndDays,
	TDateswiseTransportsAndActivitiesInputFieldValue,
	TValidDateswiseTransportsAndActivitiesInputFieldValue,
} from "./utils"

export {
	type TDateswiseTransportsAndActivitiesInputFieldValue,
	type TValidDateswiseTransportsAndActivitiesInputFieldValue,
}

const INITIAL_VALUES: TDateswiseTransportsAndActivitiesInputFieldValue = [
	addKeyToFieldArrayItem({
		dates: [],
		no_of_days: 1,
		services: [
			addKeyToFieldArrayItem({
				type: "transport_service",
				service: {
					transport_service: undefined,
					transport_service_location: undefined,
					cabs: undefined,
				},
			}),
		],
	}),
]

export function DateswiseTransportAndActivityServicesInputFieldArray({
	name: fieldName,
	bookingDates,
	selectedCabTypes,
	add_cab_prices_at_once,
	tripDestinations,
	currency,
	noOfAdults,
	children,
	defaultValue,
	showBookedPrices,
	transportServiceTitle = "Transport Service",
	travelActivityServiceTitle = "Activity/Ticket",
}: {
	name: string
	bookingDates?: React.ComponentProps<typeof DaysAndDatesField>["bookingDates"]
	selectedCabTypes?: TUsedCabTypes
	add_cab_prices_at_once?: boolean
	defaultValue?: TDateswiseTransportsAndActivitiesInputFieldValue
	showBookedPrices?: boolean
	transportServiceTitle?: string
	travelActivityServiceTitle?: string
} & Pick<
	React.ComponentProps<typeof TravelActivityDatewisePricesInputField>,
	"tripDestinations" | "currency" | "noOfAdults" | "children"
>) {
	const form = useForm()
	const hasActivitiesEnabled = useHasFeatureFlag("travel_activities")

	return (
		<FieldArray<TDateswiseTransportsAndActivitiesInputFieldValue[number]>
			name={fieldName}
		>
			{({ fields }) => (
				<Stack gap="4">
					{fields.map((daywiseServicesFieldName, index) => {
						const key = getFieldArrayItemKey(form, daywiseServicesFieldName)
						return (
							<Box key={key} borderBottomWidth="4" borderColor="default">
								<Inline
									gap="4"
									collapseBelow="md"
									position="relative"
									style={{
										zIndex: 10 * (Number(fields.length || 0) - index),
									}}
								>
									<Box position="sticky" top="0" zIndex="10">
										<Box
											position="sticky"
											top="0"
											bgColor="default"
											zIndex="10"
											paddingBottom="4"
										>
											<DaysAndDatesField
												daywiseServicesFieldName={daywiseServicesFieldName}
												bookingDates={bookingDates}
											/>
										</Box>
									</Box>
									<Box flex="1" minWidth="0">
										<GetFieldValue<Array<Date>>
											name={`${daywiseServicesFieldName}.dates`}
										>
											{({ value: dates }) => {
												return (
													<FieldArray<
														Required<
															TDateswiseTransportsAndActivitiesInputFieldValue[number]
														>["services"][number]
													>
														name={`${daywiseServicesFieldName}.services`}
													>
														{({ fields }) => (
															<Stack gap="4">
																{fields.map((serviceFieldName, index) => {
																	const key = getFieldArrayItemKey(
																		form,
																		serviceFieldName
																	)
																	return (
																		<Box key={key}>
																			<GetFieldValue<
																				"transport_service" | "travel_activity"
																			>
																				name={`${serviceFieldName}.type`}
																			>
																				{({ value: serviceType }) =>
																					serviceType ===
																					"transport_service" ? (
																						<Stack gap="1">
																							{hasActivitiesEnabled ? (
																								<Inline>
																									<Box
																										color="primary"
																										fontSize="sm"
																										fontWeight="semibold"
																										borderBottomWidth="2"
																										borderBottomColor={
																											"primary"
																										}
																									>
																										<Icons.Taxi />{" "}
																										{transportServiceTitle}
																									</Box>
																								</Inline>
																							) : null}
																							<TransportServiceDatewisePricesInputField
																								name={`${serviceFieldName}.service`}
																								tripDestinations={
																									tripDestinations
																								}
																								noOfAdults={noOfAdults}
																								children={children}
																								selectedCabTypes={
																									selectedCabTypes
																								}
																								addPricesAtOnce={isTruthy(
																									add_cab_prices_at_once
																								)}
																								dates={dates}
																								showBookedPrices={
																									showBookedPrices
																								}
																								onRemove={() =>
																									fields.remove(index)
																								}
																								onDuplicate={(value) =>
																									fields.push(
																										addKeyToFieldArrayItem({
																											type: "transport_service",
																											service: {
																												...value,
																											},
																										})
																									)
																								}
																							/>
																						</Stack>
																					) : serviceType ===
																					  "travel_activity" ? (
																						<Stack gap="2">
																							{hasActivitiesEnabled ? (
																								<Inline>
																									<Box
																										color="primary"
																										fontSize="sm"
																										borderBottomWidth="2"
																										borderBottomColor={
																											"primary"
																										}
																										fontWeight="semibold"
																									>
																										<Icons.Ticket />{" "}
																										{travelActivityServiceTitle}
																									</Box>
																								</Inline>
																							) : null}
																							<TravelActivityDatewisePricesInputField
																								name={`${serviceFieldName}.service`}
																								tripDestinations={
																									tripDestinations
																								}
																								currency={currency}
																								noOfAdults={noOfAdults}
																								children={children}
																								dates={dates}
																								showBookedPrices={
																									showBookedPrices
																								}
																								onRemove={() =>
																									fields.remove(index)
																								}
																								onDuplicate={(value) =>
																									fields.push(
																										addKeyToFieldArrayItem({
																											type: "travel_activity",
																											service: {
																												...value,
																											},
																										})
																									)
																								}
																							/>
																						</Stack>
																					) : null
																				}
																			</GetFieldValue>
																		</Box>
																	)
																})}
																<Inline gap="4">
																	<Button
																		size="sm"
																		status="primary"
																		onClick={() => {
																			const existingServicesForThisDay:
																				| Required<
																						TDateswiseTransportsAndActivitiesInputFieldValue[number],
																						"services"
																				  >["services"]
																				| undefined = getFormValueForKey(
																				form.getState().values || {},
																				`${daywiseServicesFieldName}.services`
																			)
																			// try to get the transport services from same day
																			let existingTransportServices =
																				existingServicesForThisDay
																					? existingServicesForThisDay
																							.map((s) =>
																								s.type === "transport_service"
																									? s.service
																									: undefined
																							)
																							.filter(
																								(
																									s
																								): s is Exclude<
																									typeof s,
																									undefined
																								> => Boolean(s)
																							)
																							.filter((s) =>
																								s.cabs?.some((s) =>
																									Boolean(
																										s.cab_type && s.no_of_cabs
																									)
																								)
																							)
																					: []
																			if (!existingTransportServices.length) {
																				// now get the transport services from all services
																				const existingServices:
																					| TDateswiseTransportsAndActivitiesInputFieldValue
																					| undefined = getFormValueForKey(
																					form.getState().values || {},
																					`${fieldName}`
																				)
																				existingTransportServices =
																					existingServices
																						? existingServices
																								.flatMap((s) =>
																									s.services
																										? s.services.map((s) =>
																												s.type ===
																												"transport_service"
																													? s.service
																													: undefined
																											)
																										: []
																								)
																								.filter(
																									(
																										s
																									): s is Exclude<
																										typeof s,
																										undefined
																									> => Boolean(s)
																								)
																						: []
																			}
																			let existingCabs: (typeof existingTransportServices)[number]["cabs"] =
																				[]
																			// remove the no-cabs services
																			existingTransportServices =
																				existingTransportServices.filter((s) =>
																					s.cabs?.some((s) =>
																						Boolean(s.no_of_cabs && s.cab_type)
																					)
																				)
																			if (existingTransportServices?.length) {
																				const lastService =
																					existingTransportServices[
																						existingTransportServices.length - 1
																					]
																				existingCabs = (
																					lastService.cabs || []
																				).filter(
																					(c) => c.no_of_cabs && c.cab_type
																				)
																			}
																			fields.push(
																				addKeyToFieldArrayItem({
																					type: "transport_service",
																					service: {
																						transport_service_location:
																							undefined,
																						transport_service: undefined,
																						comments: "",
																						cabs: existingCabs.map((c) => ({
																							...c,
																							date_wise_prices:
																								c.date_wise_prices
																									? c.date_wise_prices.map(
																											(p) => ({
																												...p,
																												per_quantity_given_price: 0,
																												given_price: 0,
																												per_quantity_booked_price: 0,
																												booked_price: 0,
																												edited_booked_price:
																													true,
																												edited_given_price:
																													true,
																											})
																										)
																									: undefined,
																						})),
																					},
																				})
																			)
																		}}
																	>
																		<Icons.Plus />{" "}
																		{hasActivitiesEnabled
																			? "Transport Service"
																			: "Add More"}
																	</Button>
																	{hasActivitiesEnabled ? (
																		<Button
																			size="sm"
																			status="primary"
																			onClick={() =>
																				fields.push(
																					addKeyToFieldArrayItem({
																						type: "travel_activity",
																						service: {
																							comments: "",
																						},
																					})
																				)
																			}
																		>
																			<Icons.Plus /> Activity/Ticket
																		</Button>
																	) : null}
																</Inline>
															</Stack>
														)}
													</FieldArray>
												)
											}}
										</GetFieldValue>
									</Box>
								</Inline>
								<Inline gap="4" paddingY="4" justifyContent="end">
									<FormSpy<{
										[fieldName: string]:
											| TDateswiseTransportsAndActivitiesInputFieldValue
											| undefined
									}>
										subscription={{ values: true }}
									>
										{({ values }) => {
											const data = values[fieldName]
											if (!data) return null
											const cab = data[index]
											let remainingDates: Array<Date> = []
											const dates = cab.dates || []
											if (bookingDates?.length && dates.length) {
												remainingDates = getRemainingDates(
													bookingDates.map((d) => d.value),
													data.reduce<Array<Date>>(
														(dates, cab) => dates.concat(cab.dates || []),
														[]
													)
												)
											}
											return dates.length &&
												(!bookingDates?.length || remainingDates.length) ? (
												<Button
													status="primary"
													size="sm"
													onClick={() => {
														fields.push(
															addKeyToFieldArrayItem({
																...cab,
																services: !hasActivitiesEnabled
																	? [
																			addKeyToFieldArrayItem({
																				type: "transport_service",
																				service: {
																					transport_service_location: undefined,
																					transport_service: undefined,
																				},
																			}),
																		]
																	: [],
																dates: [
																	!bookingDates?.length
																		? startOf(
																				addUnit(
																					dates[dates.length - 1],
																					1,
																					"day"
																				),
																				"day"
																			)
																		: remainingDates[0],
																],
															})
														)
													}}
												>
													<Icons.Plus /> Next Day
												</Button>
											) : null
										}}
									</FormSpy>
									<Button
										onClick={() => fields.remove(index)}
										size="sm"
										level="tertiary"
									>
										<Icons.Cancel /> Remove
									</Button>
								</Inline>
							</Box>
						)
					})}
					{!fields.length ? (
						<Box paddingTop="4">
							<Button
								level="primary"
								data-testid="add_transport_services"
								onClick={() =>
									fields.push(
										addKeyToFieldArrayItem(
											defaultValue
												? { ...defaultValue[0] }
												: { ...INITIAL_VALUES[0] }
										)
									)
								}
							>
								<Icons.Plus /> Add Services
							</Button>
						</Box>
					) : null}
				</Stack>
			)}
		</FieldArray>
	)
}

function DaysAndDatesField({
	daywiseServicesFieldName,
	bookingDates,
}: {
	daywiseServicesFieldName: string
	bookingDates?: Array<{ id: string; name: string; value: Date }>
}) {
	const { value: selectedDates, onChange: setSelectedDates } = useFieldValue<
		Required<TDateswiseTransportsAndActivitiesInputFieldValue[number]>["dates"]
	>(`${daywiseServicesFieldName}.dates`)
	const { value: no_of_days, onChange: changeNoOfDays } = useFieldValue<number>(
		`${daywiseServicesFieldName}.no_of_days`
	)
	return bookingDates?.length ? (
		<Box style={{ maxWidth: "165px" }}>
			<FieldGroup<typeof selectedDates>
				name={`${daywiseServicesFieldName}.dates`}
				label="Days"
			>
				{({ input, meta, ...others }) => {
					const { value } = input
					return (
						<Select
							{...others}
							{...input}
							options={bookingDates}
							searchable={false}
							placeholder="Select day(s)..."
							required
							multiple
							value={
								value
									? bookingDates.filter((d) =>
											value.find((dd) => isSame(dd, d.value, "day"))
										)
									: undefined
							}
							onChange={(bookingDays: typeof bookingDates | undefined) => {
								const days =
									bookingDays && bookingDays.length
										? bookingDays.map((s) => s.value)
										: []
								days.sort((a, b) =>
									isAfter(a, b) ? 1 : isBefore(a, b) ? -1 : 0
								)
								input.onChange(days)
							}}
						/>
					)
				}}
			</FieldGroup>
		</Box>
	) : (
		<Stack gap="4" style={{ maxWidth: "160px" }}>
			<Box>
				<FieldGroup<typeof selectedDates>
					label="Date"
					name={`${daywiseServicesFieldName}.dates`}
				>
					{({ input }) => {
						return (
							<DateTimeInput
								{...input}
								value={
									input.value?.length ? parseDate(input.value[0]) : undefined
								}
								onChange={(value) => {
									console.log({
										n: value
											? createDatesFromStartDateAndDays(
													value,
													Number(no_of_days) || 1
												)
											: [],
									})
									setSelectedDates(
										value
											? createDatesFromStartDateAndDays(
													value,
													Number(no_of_days) || 1
												)
											: []
									)
								}}
							/>
						)
					}}
				</FieldGroup>
			</Box>
			<Box style={{ maxWidth: "100px" }}>
				<TextInputField
					label="No of Days"
					type="number"
					min={1}
					max={1000}
					name={`${daywiseServicesFieldName}.no_of_days`}
					onChange={(e) => {
						const value = e.currentTarget.value
						changeNoOfDays(value as unknown as number)
						if (selectedDates?.length && value) {
							setSelectedDates(
								createDatesFromStartDateAndDays(
									selectedDates[0],
									parseInt(value)
								) as never as Date[]
							)
						}
					}}
				/>
			</Box>
		</Stack>
	)
}
