import { useId } from "react"
import { type TIntegration } from "./store"

export function PlatformIcon({
	platform,
	size = "24px",
}: {
	platform: TIntegration["platform"]
	size?: string
}) {
	switch (platform) {
		case "google_ads":
			return <GoogleAds size={size} />
		case "meta":
			return <Facebook size={size} />
		case "api":
			return <API size={size} />
		default:
			break
	}
	return null
}

function GoogleAds({ size = "24px" }: { size?: string }) {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			version="1.1"
			width={size}
			height={size}
			viewBox="0 0 192 192"
			enable-background="new 0 0 192 192"
		>
			<rect
				x="8"
				y="62.52"
				transform="matrix(0.5 -0.866 0.866 0.5 -46.2127 103.666)"
				fill="#FBBC04"
				width="117.33"
				height="58.67"
			/>
			<path
				fill="#4285F4"
				d="M180.07,127.99L121.4,26.38c-8.1-14.03-26.04-18.84-40.07-10.74c-14.03,8.1-18.84,26.04-10.74,40.07   l58.67,101.61c8.1,14.03,26.04,18.83,40.07,10.74C183.36,159.96,188.16,142.02,180.07,127.99z"
			/>
			<circle fill="#34A853" cx="37.34" cy="142.66" r="29.33" />
		</svg>
	)
}

function Facebook({ size = "24px" }: { size?: string }) {
	const id = useId()
	return (
		<svg
			viewBox="0 0 36 36"
			fill={`url(#fb_${id}_color)`}
			height={size}
			width={size}
		>
			<defs>
				<linearGradient
					x1="50%"
					x2="50%"
					y1="97.0782153%"
					y2="0%"
					id={`fb_${id}_color`}
				>
					<stop offset="0%" stopColor="#0062E0"></stop>
					<stop offset="100%" stopColor="#19AFFF"></stop>
				</linearGradient>
			</defs>
			<path d="M15 35.8C6.5 34.3 0 26.9 0 18 0 8.1 8.1 0 18 0s18 8.1 18 18c0 8.9-6.5 16.3-15 17.8l-1-.8h-4l-1 .8z"></path>
			<path
				fill="white"
				d="M25 23l.8-5H21v-3.5c0-1.4.5-2.5 2.7-2.5H26V7.4c-1.3-.2-2.7-.4-4-.4-4.1 0-7 2.5-7 7v4h-4.5v5H15v12.7c1 .2 2 .3 3 .3s2-.1 3-.3V23h4z"
			></path>
		</svg>
	)
}

function API({ size = "24px" }: { size?: string }) {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 24 24"
			fill="currentColor"
			width={size}
			height={size}
		>
			<path
				fillRule="evenodd"
				d="M3 6a3 3 0 0 1 3-3h12a3 3 0 0 1 3 3v12a3 3 0 0 1-3 3H6a3 3 0 0 1-3-3V6Zm14.25 6a.75.75 0 0 1-.22.53l-2.25 2.25a.75.75 0 1 1-1.06-1.06L15.44 12l-1.72-1.72a.75.75 0 1 1 1.06-1.06l2.25 2.25c.141.14.22.331.22.53Zm-10.28-.53a.75.75 0 0 0 0 1.06l2.25 2.25a.75.75 0 1 0 1.06-1.06L8.56 12l1.72-1.72a.75.75 0 1 0-1.06-1.06l-2.25 2.25Z"
				clipRule="evenodd"
			/>
		</svg>
	)
}
