import {
	Button,
	Inline,
	Stack,
	Divider,
	Grid,
	Col,
	Heading,
	Box,
	Icons,
	Text,
} from "@sembark-travel/ui/base"
import {
	Form,
	validateFormValues,
	TextInputField,
	SubmissionError,
	withServerErrors,
	FieldArray,
	arrayMutators,
} from "@sembark-travel/ui/form"
import { useXHR } from "@sembark-travel/xhr"
import { useMemo } from "react"
import { Optional } from "utility-types"
import * as Validator from "yup"
import { TInclusionExclusionPreset } from "./store"

export function NewInclusionExclusionPresetItem({
	onSuccess,
	onCancel,
}: {
	onSuccess: (data: TInclusionExclusionPreset) => void
	onCancel?: () => void
}) {
	const xhr = useXHR()
	return (
		<NewItemForm
			onCancel={onCancel}
			onSubmit={async (values: NewItemCredentials) => {
				const resp = await xhr.post("/inclusion-exclusion-presets", values)
				onSuccess(resp.data.data)
			}}
		/>
	)
}

const validationSchema = Validator.object().shape({
	name: Validator.string()
		.required("Please provide name for the preset group")
		.max(191, "Please use 191 or fewer characters"),
	inclusions: Validator.array().of(
		Validator.string().max(255, "Please use 255 or fewer characters")
	),
	exclusions: Validator.array().of(
		Validator.string().max(255, "Please use 255 or fewer characters")
	),
})

const validate = validateFormValues(validationSchema)

type NewItemCredentials = {
	name: string
	inclusions?: Array<string>
	exclusions?: Array<string>
}

export function NewItemForm({
	onSubmit,
	onCancel,
	initialValues: propInitialValues,
}: {
	onSubmit: (data: NewItemCredentials) => Promise<unknown>
	onCancel?: () => void
	initialValues?: Optional<NewItemCredentials>
}) {
	const initialValues = useMemo(() => {
		return {
			name: propInitialValues?.name || "",
			inclusions: propInitialValues?.inclusions?.length
				? propInitialValues.inclusions
				: [""],
			exclusions: propInitialValues?.exclusions?.length
				? propInitialValues.exclusions
				: [""],
		}
	}, [propInitialValues])
	return (
		<Form<NewItemCredentials>
			initialValues={initialValues}
			validate={validate}
			onSubmit={withServerErrors(async (values) => {
				return await onSubmit(values)
			})}
			mutators={{ ...arrayMutators }}
			subscription={{ submitting: true }}
		>
			{({ submitting, form }) => (
				<form noValidate>
					<Stack gap="4">
						<Box maxWidth="xl">
							<TextInputField
								label="Preset Name"
								name="name"
								type="text"
								placeholder="e.g. Hotels Only, Package Type etc."
								maxLength={191}
								required
							/>
						</Box>
						<Divider sm />
						<Grid gap="6">
							<Col sm={12} md={6}>
								<Stack gap="4">
									<Stack gap="1">
										<Heading fontSize="md" color="success">
											Inclusions
										</Heading>
										<Text fontSize="sm" color="muted">
											List of inclusions in this preset
										</Text>
									</Stack>
									<PresetsFields name="inclusions" />
								</Stack>
							</Col>
							<Col>
								<Stack gap="4">
									<Stack gap="1">
										<Heading fontSize="md" color="danger">
											Exclusions
										</Heading>
										<Text fontSize="sm" color="muted">
											List of exclusions in this preset
										</Text>
									</Stack>
									<PresetsFields name="exclusions" />
								</Stack>
							</Col>
						</Grid>
						<Divider sm />
						<Stack gap="4">
							<SubmissionError />
							<Inline gap="4">
								<Button
									disabled={submitting}
									onClick={() => form.submit()}
									level="primary"
									status="primary"
								>
									Save Details
								</Button>
								{onCancel ? (
									<Button
										onClick={onCancel}
										level="tertiary"
										disabled={submitting}
									>
										Cancel
									</Button>
								) : null}
							</Inline>
						</Stack>
					</Stack>
				</form>
			)}
		</Form>
	)
}

function PresetsFields(props: { name: "inclusions" | "exclusions" }) {
	const { name } = props
	return (
		<FieldArray<string> name={name}>
			{({ fields }) => (
				<Stack gap="4">
					{fields.map((name, index) => (
						<Inline key={name} gap="2" alignItems="center">
							<Box flex="1">
								<TextInputField
									name={name}
									type="text"
									maxLength={255}
									onKeyUp={(e) => {
										if (e.key?.toLowerCase() === "enter" || e.keyCode === 13) {
											// add new item
											fields.push("")
											setTimeout(() => {
												const newItem =
													document.querySelector<HTMLInputElement>(
														`[name="${props.name}[${fields.length}]"]`
													)
												newItem?.focus()
											}, 100)
										}
									}}
								/>
							</Box>
							<Box>
								{Number(fields.length) > 1 ? (
									<Button
										size="sm"
										onClick={() => fields.remove(index)}
										level="tertiary"
									>
										<Icons.Cancel />
									</Button>
								) : null}
							</Box>
						</Inline>
					))}
					<Box>
						<Button size="sm" onClick={() => fields.push("")}>
							Add More
						</Button>
					</Box>
				</Stack>
			)}
		</FieldArray>
	)
}
