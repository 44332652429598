import { AsyncSelect } from "@sembark-travel/ui/base"
import { useXHR } from "@sembark-travel/xhr"
import { currenciesXHR, useCurrenciesOfTenant } from "./store"
import {
	InlineSelectInputField,
	SelectInputField,
	useFieldValue,
	MoneyInputField,
} from "@sembark-travel/ui/form"
import { useEffect } from "react"

export function SelectCurrencyFromAll(
	props: Omit<React.ComponentProps<typeof AsyncSelect>, "fetch">
) {
	const xhr = useXHR()
	return (
		<AsyncSelect
			{...props}
			fetch={async (q) => {
				return currenciesXHR(xhr)
					.getAllAvailableCurrencies({ q })
					.then((resp) => resp.data)
			}}
		/>
	)
}

export function SelectTenantCurrencyInputField({
	clearable,
	...props
}: Omit<React.ComponentProps<typeof SelectInputField>, "children"> & {
	clearable?: boolean
}) {
	const currencies = useCurrenciesOfTenant()
	return (
		<SelectInputField
			disabled={!clearable && currencies.length <= 1}
			{...props}
		>
			{clearable ? <option value="">-</option> : null}
			{currencies.map((c) => (
				<option key={c} value={c}>
					{c}
				</option>
			))}
		</SelectInputField>
	)
}

export function InlineSelectTenantCurrencyInputField({
	clearable,
	defaultValue,
	...props
}: Omit<React.ComponentProps<typeof InlineSelectInputField>, "children"> & {
	clearable?: boolean
}) {
	const currencies = useCurrenciesOfTenant()
	const { value, onChange } = useFieldValue(props.name)
	useEffect(() => {
		if (!value && defaultValue && !clearable) {
			setTimeout(() => {
				onChange(defaultValue)
			})
		}
	}, [value, defaultValue, clearable, onChange])
	const disabled = !clearable && currencies.length <= 1
	return (
		<InlineSelectInputField
			disabled={disabled}
			{...props}
			noCaret={props.noCaret || disabled}
		>
			{clearable ? <option value="">-</option> : null}
			{currencies.map((c) => (
				<option key={c} value={c}>
					{c}
				</option>
			))}
		</InlineSelectInputField>
	)
}

export function MoneyInputWithTenantCurrenciesField(
	props: Omit<React.ComponentProps<typeof MoneyInputField>, "currencies">
) {
	const currencies = useCurrenciesOfTenant()
	return <MoneyInputField {...props} currencies={currencies} />
}
