export function ltrim(str: string, trimWhat: string) {
	return str.replace(new RegExp(`^${trimWhat}+`), "")
}

export function rtrim(str: string, trimWhat: string) {
	return str.replace(new RegExp(`${trimWhat}+$`), "")
}

export function arrayFill<T>(length: number, fillWith: T) {
	if (length < 0) {
		throw new Error("Array length can not be negative to fill")
	}
	return Array.from({ length }, () => fillWith)
}

export function getMaximumFractionDigits(formatter: Intl.NumberFormat): number {
	return formatter.resolvedOptions().maximumFractionDigits || 0
}
