import {
	Alert,
	Box,
	Button,
	Icons,
	Stack,
	Text,
	Spinner,
	Inline,
	Component,
} from "@sembark-travel/ui/base"
import { useEffect, useState } from "react"
import {
	isUserDeviceSubscribedForPushNotifications,
	askNotificationPermission,
	getDeviceNotificationPermissionsStatus,
	doesDeviceSupportNotifications,
	subscribeUserDeviceForPushNotifications,
	unsubscribeUserDeviceForPushNotifications,
} from "./PushNotifications"
import { useAuthUser } from "../Auth"
import { useXHR } from "@sembark-travel/xhr"

export function NotificationPreferences() {
	const [permissionsStatus, setPermissionsStatus] = useState<
		"pending" | NotificationPermission
	>(() => getDeviceNotificationPermissionsStatus())
	const [isDesktopNotificationSupported] = useState(() =>
		doesDeviceSupportNotifications()
	)
	return (
		<Box>
			{isDesktopNotificationSupported ? (
				<Box>
					{permissionsStatus === "default" ? (
						<Stack gap="4">
							<Text>
								We strongly recommend enabling notifications so that you'll know
								when important activity happens in your Sembark workspace.
							</Text>
							<Box>
								<Button
									level="primary"
									onClick={() => {
										askNotificationPermission((status) => {
											setPermissionsStatus(status)
										})
										setPermissionsStatus("pending")
									}}
								>
									<Icons.Bell /> Enable Desktop Notifications
								</Button>
							</Box>
						</Stack>
					) : permissionsStatus === "pending" ? (
						<Box>
							<Alert status="info">
								Click <b>Allow</b> in your browsers's pop-up to enable
								notifications.
							</Alert>
						</Box>
					) : permissionsStatus === "denied" ? (
						<Alert status="warning">
							You've disallowed notifications in your browser. You'll need to
							open your browser preferences to change that.
						</Alert>
					) : permissionsStatus === "granted" ? (
						<DeviceRegistrationStatus />
					) : null}
				</Box>
			) : null}
		</Box>
	)
}

function DeviceRegistrationStatus() {
	const xhr = useXHR()
	const [registrationStatus, setRegistrationStatus] = useState<
		undefined | boolean
	>(undefined)
	const { user } = useAuthUser()
	const { uid: userId } = user || {}
	useEffect(() => {
		if (!userId) return
		isUserDeviceSubscribedForPushNotifications({ uid: userId })
			.then((yes) => {
				setRegistrationStatus(yes)
			})
			.catch(() => setRegistrationStatus(false))
		// set a timer incase
		setTimeout(() => {
			setRegistrationStatus((status) => (status === undefined ? false : status))
		}, 10000)
	}, [userId])
	if (!user) return null
	return (
		<Stack gap="4">
			{registrationStatus === undefined ? (
				<Box>
					<Spinner />
				</Box>
			) : registrationStatus ? (
				<Stack gap="4">
					<Text>This device is registered to receive notifications.</Text>
					<Inline gap="4">
						<Button
							onClick={() => xhr.post("/push-notifications/test")}
							size="sm"
						>
							Send a Test Notification
						</Button>
						<Button
							onClick={() => {
								unsubscribeUserDeviceForPushNotifications().then((status) =>
									setRegistrationStatus(status)
								)
								// also delete other subscriptions
								xhr.delete("/push-notifications/subscriptions")
							}}
							size="sm"
						>
							Unsubscribe
						</Button>
					</Inline>
					<Stack gap="2">
						<Text color="muted">Not receiving notifications?</Text>
						<Text>
							Please check{" "}
							<b>if your browser is allowed to show notifications</b> from your
							computer/device settings.
						</Text>
					</Stack>
				</Stack>
			) : (
				<Stack gap="4">
					<Text fontWeight="semibold" color="success">
						Desktop Notifications have been enabled.
					</Text>
					<Text>
						To receive notifications on this device, please click following
						button to register it.
					</Text>
					<Box>
						<Component initialState={false}>
							{({ state, setState }) => (
								<Button
									level="primary"
									disabled={state}
									onClick={() => {
										setState(true)
										// subscribe for push notifications
										subscribeUserDeviceForPushNotifications(user)
											.then(() => {
												// subscribed successfully
												alert("Successfull registered")
												setRegistrationStatus(true)
											})
											.catch((error) => {
												const e = error as Error
												window.alert(e.message || "Something went wrong")
												setRegistrationStatus(false)
											})
											.then(() => {
												setState(false)
											})
									}}
								>
									{state ? (
										<>Registering Device. Please wait...</>
									) : (
										<>
											<Icons.OkCircleSolid /> Register This Device
										</>
									)}
								</Button>
							)}
						</Component>
					</Box>
				</Stack>
			)}
		</Stack>
	)
}
