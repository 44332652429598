import { Box, BoxProps } from "./Box"
import { Button, ButtonProps } from "./Button"
import type { ElementType } from "react"
import classNames from "classnames"
import { Divider } from "./Divider"

import {
	dropdownCaretClassName,
	dropdownClassName,
	dropdownMenuClassName,
	dropdownMenuSectionHeaderClassName,
	dropdownTogglerClassName,
} from "./dropdown.css"

const DefaultDropdownElement = "div"

export type DropdownProps<
	T extends ElementType = typeof DefaultDropdownElement,
> = BoxProps<T> & {
	alignRight?: boolean | "sm"
	noCaret?: boolean
}
export function Dropdown<
	T extends ElementType = typeof DefaultDropdownElement,
>({
	className,
	alignRight = false,
	children,
	noCaret,
	as,
	...otherProps
}: DropdownProps<T>) {
	const Element: ElementType = as || DefaultDropdownElement
	return (
		<Box
			as={Element}
			className={classNames(
				dropdownClassName,
				{
					"align-right": alignRight === true,
					"sm:align-right": alignRight === "sm",
				},
				className
			)}
			{...otherProps}
		>
			{!noCaret ? <span className={dropdownCaretClassName} /> : null}
			{children}
		</Box>
	)
}

function DropdownMenu({ className, ...props }: BoxProps<"ul">) {
	return (
		<Box
			as="ul"
			className={classNames(dropdownMenuClassName, className)}
			{...props}
		/>
	)
}

const DefaultDropdownMenuItemElement = "a"
function MenuItem<
	T extends ElementType = typeof DefaultDropdownMenuItemElement,
>({ className, color, as, ...props }: BoxProps<T>) {
	const Element: ElementType = as || DefaultDropdownMenuItemElement
	return (
		<Box as="li" color={color}>
			<Box as={Element} href="#" {...props} />
		</Box>
	)
}

function MenuItemDivider(props: React.ComponentProps<typeof Divider>) {
	return <Divider sm {...props} />
}

function MenuSectionHeader({
	className,
	children,
	as,
	...props
}: BoxProps<"li">) {
	return (
		<Box
			as="li"
			color="muted"
			className={classNames(dropdownMenuSectionHeaderClassName, className)}
			fontWeight="semibold"
			fontSize="sm"
			{...props}
		>
			{children}
		</Box>
	)
}

const DefaultDropdownTogglerElement = "a"

function DropdownToggler<
	T extends ElementType = typeof DefaultDropdownTogglerElement,
>({ className, as, ...props }: BoxProps<T>) {
	const Element: ElementType = as || DefaultDropdownTogglerElement
	return (
		<Box
			as={Element}
			className={classNames(dropdownTogglerClassName, className)}
			{...props}
		/>
	)
}

function DropdownTogglerButton({
	className,
	children,
	as,
	...props
}: ButtonProps<typeof Button>) {
	return (
		<Button
			className={classNames(dropdownTogglerClassName, className)}
			{...props}
		>
			{children}
		</Button>
	)
}

Dropdown.Toggle = DropdownToggler
Dropdown.ToggleButton = DropdownTogglerButton
Dropdown.Menu = DropdownMenu
Dropdown.MenuItem = MenuItem
Dropdown.MenuItemDivider = MenuItemDivider
Dropdown.MenuSectionHeader = MenuSectionHeader
