import { Helmet } from "react-helmet-async"
import { List } from "./../../../TransportServicePrices"

export default function TransportServicePricesList() {
	return (
		<>
			<Helmet>
				<title>Transport Service Prices</title>
			</Helmet>
			<List />
		</>
	)
}
