import { Helmet } from "react-helmet-async"
import { useParams, Link } from "../../../router-utils"
import { CabDetails } from "./../../../Cabs"

export default function Cab() {
	const { cabId } = useParams("/cabs/:cabId")
	return (
		<>
			<Helmet>
				<title>Cab Details</title>
			</Helmet>
			<Link to="/cabs">Back to list</Link>
			<CabDetails cabId={cabId} />
		</>
	)
}
