import { Channel, TChannelConfig } from "./contract"
import posthog from "posthog-js"
import type { Properties } from "posthog-js"

export type TPosthogChannelConfig = TChannelConfig & {
	api_key?: string
	api_host?: string
}

const client: typeof posthog = posthog

const createPosthogChannel: Channel = function createPosthogChannel(
	config: TPosthogChannelConfig
) {
	const { api_key, api_host, appVersion, appEnv } = config
	if (!api_key || !api_host) {
		throw new Error("Missing configuration for posthog")
	}
	client.init(api_key, {
		api_host: api_host,
		person_profiles: "identified_only", // or 'always' to create profiles for anonymous users as well
		autocapture: false,
		disable_session_recording: true,
	})
	return {
		page() {
			client.capture("$pageview")
		},
		track(action, data) {
			client.capture(action, data as never as Properties)
		},
		resetIdentity() {
			client.reset()
		},
		identify(userId, { tenantName }) {
			client.identify(String(userId), {
				tenantName,
				appVersion,
				appEnv,
			})
		},
	}
}

export default createPosthogChannel
