import {
	Box,
	Button,
	Stack,
	Inline,
	Icons,
	AsyncSelect,
	AsyncSelectProps,
	Col,
	Divider,
	Grid,
} from "@sembark-travel/ui/base"
import { IListResponse, useXHR, XHRInstance } from "@sembark-travel/xhr"
import {
	Form,
	validateFormValues,
	withServerErrors,
	SubmissionError,
	FieldArray,
	SelectField,
	TextInputField,
	arrayMutators,
	GetFieldValue,
} from "@sembark-travel/ui/form"
import { useState } from "react"
import * as Validator from "yup"
import {
	SelectTenantCurrencyInputField,
	useFunctionalCurrencyOfTenant,
} from "../Currencies"
import { TTripDestination } from "./store"
import { Required } from "utility-types"

function XHR(xhr: XHRInstance) {
	return {
		async getRepositoryList(
			params?: unknown
		): Promise<IListResponse<TTripDestination>> {
			const list = await xhr.get("/trip-destinations-repository", { params })
			return list.data
		},
		async store(data: unknown): Promise<Array<TTripDestination>> {
			const destinationsData = await xhr.post("/trip-destinations", data)
			return destinationsData.data.data
		},
	}
}

export function NewTripDestination({
	onSuccess,
	onCancel,
}: {
	onSuccess: (destinations: Array<TTripDestination>) => void
	onCancel?: () => void
}) {
	const xhr = useXHR()
	return (
		<NewTripDestinationForm
			onSubmit={async (data) => {
				const destinations = await XHR(xhr).store(data)
				return await Promise.resolve(onSuccess(destinations))
			}}
			onCancel={onCancel}
		/>
	)
}

type TFormParams = {
	data?: Array<{
		name?: TTripDestination
		short_name: string
		description?: string
		currency: string
	}>
}

const validationSchema = Validator.object().shape({
	data: Validator.array()
		.required("Please provide atleast one destination details")
		.min(1, "Please provide atleast one destination details.")
		.of(
			Validator.object().shape({
				name: Validator.mixed().required(
					"Please provide a name for the destination"
				),
				short_name: Validator.string().required(
					"Please provide a short name for the destination"
				),
				destination: Validator.string().nullable(),
			})
		),
})

export function NewTripDestinationForm({
	onCancel,
	onSubmit,
}: {
	onSubmit: (data: unknown) => Promise<void>
	onCancel?: () => void
}) {
	const functionalCurrency = useFunctionalCurrencyOfTenant()
	const [initialValues] = useState<Required<TFormParams, "data">>(() => {
		return {
			data: [
				{
					name: undefined,
					short_name: "",
					description: "",
					currency: functionalCurrency,
				},
			],
		}
	})
	return (
		<Form<TFormParams>
			initialValues={initialValues}
			validate={validateFormValues(validationSchema)}
			onSubmit={withServerErrors(async (values) => {
				return await onSubmit({
					data:
						values.data?.map((d) => ({
							...d,
							name: d.name?.name,
						})) || [],
				})
			})}
			subscription={{ submitting: true }}
			mutators={{ ...arrayMutators }}
		>
			{({ submitting, handleSubmit, form }) => {
				return (
					<form noValidate onSubmit={handleSubmit}>
						<FieldArray name="data">
							{({ fields }) => {
								const isBulk = Number(fields.length) > 1
								return (
									<Box>
										{fields.map((name, index) => (
											<Box key={name}>
												<Inline gap="4">
													<Box flex="1" minWidth="0">
														<Grid gap="4">
															<Col sm>
																<SelectField
																	label="Destination Name"
																	placeholder="Select destination or add new one..."
																	select={SelectTripDestinationFromRepository}
																	name={`${name}.name`}
																	creatable
																	fetchOnMount
																	autoComplete="no_please"
																	createOptionLabel={(q) =>
																		q
																			? q.charAt(0).toUpperCase() + q.slice(1)
																			: ""
																	}
																	onChange={(value?: TTripDestination) => {
																		if (!value) {
																			form.change(
																				`${name}.name` as never,
																				value
																			)
																		} else {
																			// capitalize the first character
																			form.change(
																				`${name}.name` as never,
																				{
																					...value,
																					name:
																						value.name.charAt(0).toUpperCase() +
																						value.name.slice(1),
																				} as never
																			)
																			if (value.short_name) {
																				form.change(
																					`${name}.short_name` as never,
																					value.short_name as never
																				)
																			}
																			if (value.description) {
																				form.change(
																					`${name}.description` as never,
																					value.description as never
																				)
																			}
																		}
																	}}
																/>
															</Col>
															<Col sm>
																<Inline gap="4">
																	<Box>
																		<TextInputField
																			name={`${name}.short_name`}
																			label="Short Name"
																			required
																			type="text"
																			placeholder="SK"
																			help="Used for displaying this destination across app"
																			onChange={(e) => {
																				form.change(
																					e.currentTarget.name as never,
																					(e.currentTarget.value || "")
																						.toUpperCase()
																						.replace(/ /g, "") as never
																				)
																			}}
																		/>
																	</Box>
																	<SelectTenantCurrencyInputField
																		name={`${name}.currency`}
																		label="Currency"
																		required
																		help="Operations currency on this destination"
																	/>
																</Inline>
															</Col>
														</Grid>
													</Box>
													{isBulk ? (
														<Box textAlign="right" paddingTop="6">
															<Button
																onClick={() => fields.remove(index)}
																size="sm"
															>
																<Icons.Cancel title="Remove" />
															</Button>
														</Box>
													) : null}
												</Inline>
												<Divider />
											</Box>
										))}
									</Box>
								)
							}}
						</FieldArray>
						<Stack gap="4">
							<SubmissionError />
							<Inline gap="4">
								<GetFieldValue<typeof initialValues.data> name="data">
									{({ value: destinations }) => (
										<Button
											disabled={submitting}
											status="primary"
											onClick={() =>
												form.change(
													"data",
													destinations.concat(initialValues.data)
												)
											}
										>
											<Icons.Plus /> Add More
										</Button>
									)}
								</GetFieldValue>
								<Button disabled={submitting} type="submit">
									<Icons.Ok /> {submitting ? "Save..." : "Save"}
								</Button>
								{onCancel ? (
									<Button
										disabled={submitting}
										onClick={onCancel}
										level="tertiary"
									>
										Cancel
									</Button>
								) : null}
							</Inline>
						</Stack>
					</form>
				)
			}}
		</Form>
	)
}

export function SelectTripDestinationFromRepository(
	props: Omit<AsyncSelectProps, "fetch">
) {
	const xhr = useXHR()
	return (
		<AsyncSelect
			{...props}
			fetch={(q) =>
				XHR(xhr)
					.getRepositoryList({ q })
					.then((resp) => resp.data)
			}
		/>
	)
}
