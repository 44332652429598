import {
	Box,
	Button,
	Icons,
	Inline,
	ButtonProps,
	Stack,
	Divider,
} from "@sembark-travel/ui/base"
import { Dialog, useDialog } from "@sembark-travel/ui/dialog"
import { dateToUTCString } from "@sembark-travel/datetime-utils"
import { useXHR } from "@sembark-travel/xhr"
import {
	Form,
	TextAreaInputField,
	withServerErrors,
	SubmissionError,
	validateFormValues,
	DatePickerField,
	SwitchInputField,
	GetFieldValue,
} from "@sembark-travel/ui/form"
import * as Validator from "yup"
import { TCommentableType } from "./store"

const validationSchema = validateFormValues(
	Validator.object().shape({
		body: Validator.string().required(
			"Please provide some body content for the comment"
		),
	})
)

export function AddCommentInDialog({
	commentableType,
	commentableId,
	onChange,
	children = (
		<Box as="span">
			<Icons.Annotation /> Add
		</Box>
	),
	...props
}: Omit<ButtonProps, "children"> & {
	commentableType: TCommentableType
	commentableId: number
	onChange?: () => void
	children?:
		| React.ReactNode
		| ((props: { onAdd: () => void }) => React.ReactNode)
}) {
	const [isOpen, open, close] = useDialog()
	const xhr = useXHR()
	return (
		<>
			{typeof children === "function" ? (
				children({ onAdd: open })
			) : (
				<Button
					{...props}
					onClick={(e: React.SyntheticEvent) => {
						e.preventDefault()
						open()
					}}
				>
					{children}
				</Button>
			)}
			<Dialog open={isOpen} onClose={close} title="Add Follow-up Comment">
				<Dialog.Body>
					<CommentForm
						onSubmit={(data) =>
							xhr
								.post(`/${commentableType}/${commentableId}/comments`, data)
								.then(close)
								.then(onChange)
						}
						onCancel={close}
					/>
				</Dialog.Body>
			</Dialog>
		</>
	)
}

interface IInitialValues {
	body?: string
	due_at?: string | Date
	actionable?: boolean
}

export function CommentForm({
	initialValues = { body: "", actionable: true },
	onSubmit,
	onCancel,
}: {
	initialValues?: IInitialValues
	onSubmit: (data: {
		body: string
		due_at?: string | null
		actionable: number
	}) => Promise<void>
	onCancel?: () => void
}) {
	return (
		<Form<typeof initialValues>
			initialValues={initialValues}
			validate={validationSchema}
			onSubmit={withServerErrors(async (values) => {
				const { body, due_at, actionable } = values
				if (!body) {
					throw Error("Comment body is required")
				}
				await onSubmit({
					body,
					actionable: Number(actionable),
					due_at: due_at ? dateToUTCString(due_at) : null,
				})
			})}
			subscription={{ submitting: true }}
		>
			{({ submitting, handleSubmit, form }) => (
				<form noValidate onSubmit={handleSubmit}>
					<Stack gap="4">
						<SubmissionError />
						<TextAreaInputField
							label="Comment"
							name="body"
							required
							rows={4}
							placeholder="Body of the comment here..."
						/>
						<Box padding="4" bgColor="subtle" rounded="md">
							<SwitchInputField
								label="Mark it as actionable comment"
								help="This will make it showup in the demanding comments section"
								name="actionable"
							/>
						</Box>
						<DatePickerField
							label="Due Date"
							secondaryLabel="if it needs to be resolved before a date"
							name="due_at"
							dateFormat
							timeFormat
						/>
					</Stack>
					<Divider />
					<GetFieldValue<IInitialValues["actionable"]> name="actionable">
						{({ value }) =>
							!Number(value) ? (
								<GetFieldValue<IInitialValues["due_at"]> name="due_at">
									{({ value }) => {
										if (value) {
											form.change("actionable", true)
										}
										return null
									}}
								</GetFieldValue>
							) : null
						}
					</GetFieldValue>
					<Inline gap="4">
						<Button type="submit" disabled={submitting}>
							{submitting ? "Saving..." : "Save"}
						</Button>
						<Button onClick={onCancel} level="tertiary" disabled={submitting}>
							Cancel
						</Button>
					</Inline>
				</form>
			)}
		</Form>
	)
}
