import { HotelGroupPayments } from "../../../../HotelGroups/Payments"
import { IHotelGroup } from "../../../../HotelGroups"
import { useOutletContext } from "react-router-dom"
import { Helmet } from "react-helmet-async"

export default function HotelGroupPaymentsPage() {
	const { group } = useOutletContext<{ group: IHotelGroup }>()
	return (
		<>
			<Helmet>
				<title>Payments | Hotel Group</title>
			</Helmet>
			<HotelGroupPayments group={group} />
		</>
	)
}
