import {
	Box,
	Button,
	Inline,
	Icons,
	Stack,
	Text,
	Heading,
	Col,
	Divider,
	Grid,
	Tip,
} from "@sembark-travel/ui/base"
import { Dialog } from "@sembark-travel/ui/dialog"
import { Link, ButtonLink, queryToSearch } from "@sembark-travel/ui/router"
import { dateToUTCString } from "@sembark-travel/datetime-utils"
import { useXHR, type XHRInstance } from "@sembark-travel/xhr"
import {
	DatePickerField,
	FieldArray,
	Form,
	GetFieldValue,
	SelectField,
	SubmissionError,
	TextInputField,
	withServerErrors,
	EmptyNumberValidator,
	arrayMutators,
	validateFormValues,
} from "@sembark-travel/ui/form"
import { Fragment, useMemo } from "react"
import { $PropertyType, Optional, Required } from "utility-types"
import * as Validator from "yup"
import { SelectAccount } from "../Accounting"
import { generatePath } from "../router-utils"
import { IAccount, IJournal } from "./store"
import {
	MoneyInputWithTenantCurrenciesField,
	useFunctionalCurrencyOfTenant,
} from "../Currencies"

function XHR(xhr: XHRInstance) {
	return {
		async saveTransactions(data: unknown): Promise<Array<IJournal>> {
			return xhr
				.post("/accounting/save-bulk-transactions", data)
				.then((resp) => resp.data.data)
		},
	}
}

interface ILogTransactionProps {
	onCancel?: () => void
	onSuccess: () => void
	initialValues?: Optional<
		$PropertyType<Required<ILogTransactionParams>, "data">[0]
	>
	hideTip?: boolean
}

export function LogTransaction({ onSuccess, ...props }: ILogTransactionProps) {
	const xhr = useXHR()
	return (
		<>
			<LogTransactionForm
				onSubmit={async (data) => {
					await XHR(xhr).saveTransactions(data)
					onSuccess()
				}}
				{...props}
			/>
		</>
	)
}

interface ILogData {
	data: Array<{
		amount: number
		asset: string
		paid_at: string
		narration: string
		reference_id?: string
		debit_account_id: number
		credit_account_id: number
	}>
}

interface ILogTransactionParams {
	data?: Array<{
		amount: number
		asset: string
		paid_at: Date | string
		narration: string
		reference_id?: string
		debit_account?: IAccount
		credit_account?: IAccount
	}>
}

export function LogTransactionForm({
	onSubmit,
	onCancel,
	hideTip,
	initialValues: initialValuesProps,
	disableBulk,
	readOnlyAmount,
}: Pick<ILogTransactionProps, "onCancel" | "hideTip" | "initialValues"> & {
	onSubmit: (data: ILogData) => Promise<void>
	disableBulk?: boolean
	readOnlyAmount?: boolean
}) {
	const functionalCurrency = useFunctionalCurrencyOfTenant()
	const initialValues: Required<ILogTransactionParams, "data"> = useMemo(() => {
		return {
			data: [
				{
					amount: 0,
					asset: functionalCurrency,
					paid_at: new Date(),
					reference_id: "",
					narration: "",
					debit_account: undefined,
					credit_account: undefined,
					...(initialValuesProps || {}),
				},
			],
		}
	}, [initialValuesProps, functionalCurrency])
	return (
		<Form<ILogTransactionParams>
			initialValues={initialValues}
			validate={validateFormValues(
				Validator.object().shape({
					data: Validator.array()
						.min(1, "Please provide atleast one transaction details to log")
						.of(
							Validator.object()
								.required()
								.shape({
									amount: EmptyNumberValidator()
										.positive("Amount should be positive")
										.required("Please enter the amount of transaction"),
									paid_at: Validator.date().required(
										"Please provide a date when the payment was made"
									),
									narration: Validator.string()
										.required(
											"Please provide the details/narration for this transaction"
										)
										.max(
											191,
											"Please use 191 or fewer characters for narration"
										),
									reference_id: Validator.string().max(
										191,
										"Please use 191 or fewer characters for reference id"
									),
									debit_account: Validator.object().required(
										"Please select a debit account from which the payment is made"
									),
									credit_account: Validator.object().required(
										"Please select a credit account where the payment is made"
									),
								})
						),
				})
			)}
			onSubmit={withServerErrors(async (values) => {
				const data: ILogData = {
					data:
						values.data?.map(
							({
								amount,
								asset,
								narration,
								reference_id,
								debit_account,
								credit_account,
								paid_at,
							}) => {
								if (!debit_account || !credit_account) {
									throw Error(
										"Please fill the required data e.g. debit/credit accounts."
									)
								}
								return {
									asset,
									amount,
									paid_at: paid_at
										? dateToUTCString(paid_at)
										: dateToUTCString(new Date()),
									narration,
									reference_id,
									debit_account_id: debit_account.id,
									credit_account_id: credit_account.id,
								}
							}
						) || [],
				}
				return await onSubmit(data)
			})}
			subscription={{ submitting: true }}
			mutators={{ ...arrayMutators }}
		>
			{({ submitting, handleSubmit }) => {
				return (
					<form noValidate onSubmit={handleSubmit}>
						<FieldArray<
							Required<ILogTransactionParams>["data"][number]
						> name="data">
							{({ fields }) => {
								const isBulk = Number(fields.length || 0) > 1
								return (
									<>
										{fields.map((name, index) => {
											return (
												<Fragment key={name}>
													<Grid gap={isBulk ? "4" : undefined}>
														<Col xs={12} sm={isBulk ? true : 12}>
															<Grid gap="4">
																{!isBulk ? (
																	<Col sm={12} md={4}>
																		<Stack gap="1">
																			<Heading as="h4">
																				<Icons.SwitchHorizontal size="6" />{" "}
																				Accounts Involved
																			</Heading>
																			<Text color="muted">
																				Please provide the debit and credit
																				accounts for this transaction.
																			</Text>
																		</Stack>
																	</Col>
																) : null}
																<Col>
																	<Stack gap="4">
																		<SelectField
																			label="Debit Account"
																			select={SelectAccount}
																			name={`${name}.debit_account`}
																			creatable
																		/>
																		<SelectField
																			label="Credit Account"
																			select={SelectAccount}
																			name={`${name}.credit_account`}
																			creatable
																		/>
																	</Stack>
																</Col>
															</Grid>
														</Col>
														{!isBulk ? (
															<Col xs={12}>
																<Divider />
															</Col>
														) : null}
														<Col xs={12} sm={isBulk ? true : 12}>
															<Grid gap="4">
																{!isBulk ? (
																	<Col sm={12} md={4}>
																		<Stack gap="1">
																			<Heading as="h4">
																				<Icons.BankNotes size="6" /> Amount and
																				Dates
																			</Heading>
																			<Text color="muted">
																				Please provide payment amount and the
																				dates of payment along with other
																				informations.
																			</Text>
																		</Stack>
																	</Col>
																) : null}
																<Col>
																	<Stack gap="4">
																		<Grid gap="4">
																			<Col>
																				<MoneyInputWithTenantCurrenciesField
																					label="Amount"
																					name={`${name}.amount`}
																					currencyFieldName={`${name}.asset`}
																					required
																					readOnly={readOnlyAmount}
																				/>
																			</Col>
																			<Col>
																				<DatePickerField
																					label="Paid on"
																					name={`${name}.paid_at`}
																					dateFormat
																					timeFormat
																					required
																				/>
																			</Col>
																		</Grid>
																		<TextInputField
																			name={`${name}.narration`}
																			label="Details/Narration"
																			type="text"
																			placeholder="TXN-123212-UPI"
																			max={191}
																			required
																		/>
																		<TextInputField
																			name={`${name}.reference_id`}
																			label="Reference Id"
																			type="text"
																			secondaryLabel="optional"
																			placeholder="Reference Id of the payment"
																			max={191}
																		/>
																	</Stack>
																	{isBulk ? (
																		<Box marginTop="4">
																			<Inline gap="2">
																				<GetFieldValue<
																					Required<ILogTransactionParams>["data"][number]
																				>
																					name={`${name}`}
																				>
																					{({ value }) => (
																						<Button
																							onClick={() => fields.push(value)}
																							size="sm"
																						>
																							<Icons.Duplicate /> Duplicate
																						</Button>
																					)}
																				</GetFieldValue>
																				<Button
																					onClick={() => fields.remove(index)}
																					size="sm"
																					level="tertiary"
																				>
																					<Icons.Cancel /> Remove
																				</Button>
																			</Inline>
																		</Box>
																	) : null}
																</Col>
															</Grid>
														</Col>
													</Grid>
													<Divider />
												</Fragment>
											)
										})}
										<Grid>
											<Col sm={12} md={isBulk ? 12 : { offset: 4, span: 8 }}>
												<Stack gap="4">
													<SubmissionError />
													<Inline gap="4">
														<Button disabled={submitting} type="submit">
															{submitting ? "Saving..." : "Save Details"}
														</Button>
														{!disableBulk && !submitting ? (
															<Button
																onClick={() =>
																	fields.push(initialValues.data[0])
																}
																level="secondary"
																status="primary"
															>
																<Icons.Plus /> Add More
															</Button>
														) : null}
														{onCancel ? (
															<Button
																onClick={onCancel}
																disabled={submitting}
																level="tertiary"
															>
																Cancel
															</Button>
														) : null}
													</Inline>
												</Stack>
											</Col>
										</Grid>
									</>
								)
							}}
						</FieldArray>
						{!hideTip ? (
							<Tip marginTop="16">
								To log instalment's payments, use{" "}
								<Link to={generatePath("/payments/incoming")} color="accent">
									Incoming
								</Link>{" "}
								or{" "}
								<Link to={generatePath("/payments/outgoing")} color="accent">
									Outgoing
								</Link>{" "}
								payments page for convenient and better accounting.
							</Tip>
						) : null}
					</form>
				)
			}}
		</Form>
	)
}

export function LogTransactionDialog({
	open,
	...props
}: ILogTransactionProps & { open: boolean }) {
	return (
		<Dialog open={open} onClose={props.onCancel} lg>
			<Dialog.Header>
				<Inline
					gap="4"
					justifyContent="between"
					paddingRight="6"
					alignItems="center"
				>
					<Dialog.Title>Add Transaction(s)</Dialog.Title>
					<ButtonLink
						status="primary"
						size="sm"
						to={
							generatePath("/accounting/transactions/csv-upload") +
							(props.initialValues?.debit_account
								? queryToSearch({
										account: {
											id: props.initialValues?.debit_account?.id,
											name: props.initialValues?.debit_account?.name,
										},
								  })
								: "")
						}
					>
						<Icons.Upload /> CSV
					</ButtonLink>
				</Inline>
			</Dialog.Header>
			<Dialog.Body position="relative">
				<LogTransaction hideTip {...props} />
			</Dialog.Body>
		</Dialog>
	)
}
