import {
	Alert,
	AsyncSelect,
	Badge,
	Box,
	Button,
	Icons,
	Table,
	Tooltip,
	Inline,
	Text,
	Heading,
	Stack,
	Col,
	Component,
	Dropdown,
	Grid,
	TabContent,
	Tabs,
	TabsList,
	useBreakpoints,
} from "@sembark-travel/ui/base"
import {
	ButtonLink,
	Link,
	XHRLink,
	useLocationQuery,
} from "@sembark-travel/ui/router"
import { Dialog, useDialog } from "@sembark-travel/ui/dialog"
import { showSnackbar } from "@sembark-travel/ui/snackbar"
import {
	areAdvancedFiltersAppliedDefault,
	ListView,
	Search,
	TSearchParams,
	useSearch,
} from "@sembark-travel/ui/list"
import { utcTimestampToLocalDateTimeString } from "@sembark-travel/datetime-utils"
import { numberToLocalString } from "@sembark-travel/number-utils"
import { IListResponse, useXHR, XHRInstance } from "@sembark-travel/xhr"
import {
	Form,
	validateFormValues,
	withServerErrors,
	TextInputField,
	SubmissionError,
	SelectField,
	SwitchInputField,
} from "@sembark-travel/ui/form"
import React, { useEffect, useState } from "react"
import useSWR from "swr"
import { $PropertyType } from "utility-types"
import * as Validator from "yup"
import { SelectTags } from "../Tags"
import { ITag } from "../Tags"
import { generatePath } from "../router-utils"
import { LogTransactionDialog } from "./LogTransaction"
import { AddAccountDialog } from "./NewItem"
import { type IAccount, type TEntities, AccountTypesXHR } from "./store"
import config from "../config"

function filtersToRequestParams(params: TFilters) {
	const { tags = [], disabled, ...otherParams } = params
	return {
		...otherParams,
		disabled: disabled ? 1 : undefined,
		tags: tags.map((t) => t.name),
	}
}

async function fetchAccounts(
	xhr: XHRInstance,
	params: TFilters
): Promise<IListResponse<IAccount>> {
	return xhr
		.get("/accounting/accounts", {
			params: filtersToRequestParams(params),
		})
		.then((resp) => resp.data)
}

type TFilters = TSearchParams & {
	entity_type?: TEntities
	account_type?: string
	disabled?: boolean
	tags?: Array<ITag>
}

type TQueryParams = TSearchParams & {
	entity_type?: TEntities
	account_type?: string
	disabled?: 1
	tags?: Array<string>
}

function filtersToQueryParams(filters: TFilters): TQueryParams {
	const { q, entity_type, disabled, tags, account_type } = filters
	const query: TQueryParams = {}
	if (q) {
		query.q = q
	}
	if (entity_type) {
		query.entity_type = entity_type
	}
	if (disabled) {
		query.disabled = 1
	}
	if (tags) {
		query.tags = tags.map((s) => `${s.id}_${s.name}`)
	}
	if (account_type) {
		query.account_type = account_type
	}
	return query
}

function queryParamsToFilters(query: TQueryParams): TFilters {
	const { q, entity_type, disabled, tags, account_type } = query
	const filters: TFilters = {}
	if (q) {
		filters.q = q
	}
	if (entity_type) {
		filters.entity_type = entity_type
	}
	if (disabled) {
		filters.disabled = true
	}
	if (account_type) {
		filters.account_type = account_type
	}
	if (tags) {
		filters.tags = tags.map((s: string) => {
			const [id, ...name] = s.split("_")
			return {
				id: isNaN(parseInt(id)) ? id : parseInt(id),
				name: name.join("_"),
			}
		}) as unknown as $PropertyType<TFilters, "tags">
	}
	return filters
}

export function Accounts() {
	const [query, setQuery] = useLocationQuery<TFilters>({
		toQuery: filtersToQueryParams,
		fromQuery: queryParamsToFilters,
	})
	const [params, setParams] = useSearch<TFilters>({
		...query,
		entity_type: query.entity_type || "all",
		account_type: query.account_type || "all",
	})
	const xhr = useXHR()
	const { data: accountTypes } = useSWR(`accounting/account-types`, () =>
		AccountTypesXHR(xhr)
			.get({ has: { accounts: 1 } })
			.then((resp) => resp.data)
	)
	useEffect(() => {
		setQuery(params)
	}, [params, setQuery])
	return (
		<Search
			initialParams={params}
			onSearch={(params) => setParams({ ...params, page: 1 })}
			title="Accounts"
			resetParams={(params) => ({
				q: "",
				page: 1,
				entity_type: params.entity_type,
				account_type: params.account_type,
			})}
			Filters={Filters}
			areAdvancedFiltersApplied={(params) => {
				const { disabled, entity_type, account_type, ...otherParams } = params
				if (Number(disabled) === 1) return true
				return areAdvancedFiltersAppliedDefault(otherParams)
			}}
			actions={
				<Inline gap="2">
					<ButtonLink
						to={generatePath("/accounting/accounts/new")}
						level="primary"
					>
						<Icons.Plus /> Account
					</ButtonLink>
					<Component initialState={false}>
						{({
							state: isLogginTransaction,
							setState: setShowLogTransaction,
						}) => (
							<>
								<Button onClick={() => setShowLogTransaction(true)}>
									<Icons.Plus /> Transaction
								</Button>
								<LogTransactionDialog
									open={isLogginTransaction}
									onSuccess={() => {
										setShowLogTransaction(false)
									}}
									onCancel={() => {
										setShowLogTransaction(false)
									}}
								/>
							</>
						)}
					</Component>
					{params.entity_type !== "all" || params.account_type !== "all" ? (
						<Dropdown alignRight="sm">
							<Dropdown.ToggleButton>
								<Icons.DotsVertical />
							</Dropdown.ToggleButton>
							<Dropdown.Menu>
								<Dropdown.MenuItem
									as={XHRLink}
									href="/accounting/accounts/download"
									title="Download as Excel"
									query={{
										...filtersToQueryParams(params),
										timezone_offset: config.timezoneOffset,
									}}
									download
								>
									<Icons.DocumentDownload /> Download
								</Dropdown.MenuItem>
							</Dropdown.Menu>
						</Dropdown>
					) : null}
				</Inline>
			}
		>
			{({ searchParams, setSearchParams }) => (
				<Tabs>
					<TabsList>
						<TabLink
							params={searchParams}
							setParams={setSearchParams}
							accountType="all"
							entityType="all"
							key="all_all"
						>
							All
						</TabLink>
						<TabLink
							params={searchParams}
							setParams={setSearchParams}
							accountType="all"
							entityType="tenants"
							key="all_tenants"
						>
							Company
						</TabLink>
						{accountTypes?.map((a) => (
							<TabLink
								key={a.name}
								params={searchParams}
								setParams={setSearchParams}
								accountType={a.name}
								entityType="all"
							>
								{a.name}
							</TabLink>
						))}
					</TabsList>
					<TabContent style={{ minHeight: "50vh" }}>
						<ListView<IAccount>
							params={params}
							pageKey="accounting/accounts"
							fetch={fetchAccounts}
							onPageChange={(page) => setParams({ ...params, page })}
						>
							{({ items, refresh }) => (
								<AccountsList accounts={items} onChange={refresh} />
							)}
						</ListView>
					</TabContent>
				</Tabs>
			)}
		</Search>
	)
}

function TabLink({
	params,
	setParams,
	children,
	accountType,
	entityType,
}: {
	params: TFilters
	setParams: (params: TFilters) => void
	children: React.ReactNode
	showCount?: boolean
	extraParams?: unknown
	accountType?: string
	entityType?: TEntities
}) {
	return (
		<li
			className={
				(accountType ? params.account_type === accountType : true) &&
				(entityType ? params.entity_type === entityType : true)
					? "active"
					: ""
			}
		>
			<a
				href={`#${accountType}`}
				onClick={(e) => {
					e.preventDefault()
					const newParams = { ...params }
					if (accountType) {
						newParams.account_type = accountType
					}
					if (entityType) {
						newParams.entity_type = entityType
					}
					setParams(newParams)
				}}
			>
				{children}
			</a>
		</li>
	)
}

export function AccountsList({
	accounts,
	onChange,
}: {
	accounts: Array<IAccount>
	onChange?: (account: IAccount) => void
}) {
	const { xs } = useBreakpoints()
	if (!xs)
		return (
			<Table
				bordered
				striped
				responsive
				headers={["Name", "Type", "Tags", "Assets", ""]}
				alignCols={{ 3: "right", 4: "right" }}
				rows={accounts.map((p) => [
					<Box>
						<Inline gap="4">
							<Link
								anchored
								color="accent"
								fontWeight="semibold"
								to={generatePath("/accounting/accounts/:accountId", {
									accountId: p.id.toString(),
								})}
							>
								{p.name}
							</Link>
							<Inline gap="2" fontSize="sm">
								{p.entity_type === "users" ? (
									<Tooltip content="Employee account">
										<Box>
											<Badge outlined primary>
												Employee
											</Badge>
										</Box>
									</Tooltip>
								) : null}
								{p.is_public ? (
									<Tooltip content="Everyone in the company can log transactions to this account">
										<Box>
											<Icons.UserGroup color="muted" />
										</Box>
									</Tooltip>
								) : null}
								{p.disabled_at ? (
									<Tooltip
										content={`Disabled by ${
											p.updated_by?.name
										} on ${utcTimestampToLocalDateTimeString(p.disabled_at)}`}
									>
										<Box>
											<Icons.Ban color="warning" />
										</Box>
									</Tooltip>
								) : null}
							</Inline>
						</Inline>
						{p.description ? (
							<Text color="muted" fontSize="sm">
								{p.description}
							</Text>
						) : null}
					</Box>,
					<Box>
						<Inline gap="1">
							{p.types?.map((t) => (
								<Badge primary outlined key={t.id}>
									{t.name}
								</Badge>
							))}
						</Inline>
					</Box>,
					<Box>
						<Inline gap="1">
							{p.tags?.map((t) => <Badge key={t.id}>{t.name}</Badge>)}
						</Inline>
					</Box>,
					p.assets?.length ? (
						<Inline gap="2" justifyContent="end">
							{p.assets.map((p) => (
								<Stack key={p.type}>
									<Text>{numberToLocalString(p.amount)}</Text>
									<Text fontSize="xs" color="muted">
										{p.type}
									</Text>
								</Stack>
							))}
						</Inline>
					) : (
						<Box>
							<Text color="muted" title="No accounting activities yet">
								n/a
							</Text>
						</Box>
					),
					<Box>
						<AccountActionsMenu account={p} onChange={onChange} />
					</Box>,
				])}
			/>
		)
	return (
		<Box as="ol">
			{accounts.map((account) => {
				const {
					id,
					name,
					description,
					is_public,
					disabled_at,
					assets,
					updated_by,
					types,
					tags,
				} = account
				return (
					<Box
						as="li"
						key={id}
						padding="4"
						borderWidth="1"
						rounded="md"
						bgColor="default"
						boxShadow="base"
						marginBottom="6"
					>
						<Box>
							<Inline gap="4" justifyContent="between">
								<Inline
									fontSize="lg"
									fontWeight="semibold"
									alignItems="baseline"
									gap="2"
								>
									<Link
										anchored
										to={generatePath("/accounting/accounts/:accountId", {
											accountId: id.toString(),
										})}
									>
										{name}
									</Link>
									<Inline gap="2" fontSize="sm">
										{types?.length ? (
											<Text as="span">
												({types?.map((t) => t.name).join(", ")})
											</Text>
										) : null}
										{is_public ? (
											<Icons.UserGroup
												title="Everyone in the company can log transactions to this account"
												color="muted"
											/>
										) : null}
										{disabled_at ? (
											<Icons.Ban
												color="warning"
												title={`Disabled by ${updated_by?.name} on ${utcTimestampToLocalDateTimeString(
													disabled_at
												)}`}
											/>
										) : null}
									</Inline>
								</Inline>
								<Box>
									<AccountActionsMenu account={account} onChange={onChange} />
								</Box>
							</Inline>
							{description ? (
								<Box marginTop="1">
									<Text color="muted" fontSize="sm">
										{description}
									</Text>
								</Box>
							) : null}
						</Box>
						<Box display="flex" justifyContent="between">
							<Box display="flex" marginTop="2">
								<Heading as="h5">Assets:</Heading>
								{assets?.length ? (
									<Inline gap="2">
										{assets.map((p) => (
											<Inline key={p.type} gap="1">
												<Box>{numberToLocalString(p.amount)}</Box>
												<Box fontSize="xs" color="muted">
													{p.type}
												</Box>
											</Inline>
										))}
									</Inline>
								) : (
									<Box marginLeft="2">
										<Text color="muted" title="No accounting activities yet">
											n/a
										</Text>
									</Box>
								)}
							</Box>
							{tags?.length ? (
								<Box>
									{tags.map((t) => (
										<Badge>{t.name}</Badge>
									))}
								</Box>
							) : null}
						</Box>
					</Box>
				)
			})}
		</Box>
	)
}

function AccountActionsMenu({
	account,
	onChange,
}: {
	account: IAccount
	onChange?: (account: IAccount) => void
}) {
	const { id, name, can_delete, can_disable, can_enable, can_edit } = account
	const xhr = useXHR()
	return (
		<Component initialState={false}>
			{({ state: isLogginTransaction, setState: setShowLogTransaction }) => (
				<>
					<Dropdown alignRight>
						<Dropdown.ToggleButton size="sm" level="tertiary">
							<Icons.DotsVertical />
						</Dropdown.ToggleButton>
						<Dropdown.Menu>
							<Dropdown.MenuItem onClick={() => setShowLogTransaction(true)}>
								<Icons.Plus /> Transaction
							</Dropdown.MenuItem>
							{can_edit ? (
								<Dropdown.MenuItem
									as={Link}
									to={generatePath("/accounting/accounts/:accountId/edit", {
										accountId: id.toString(),
									})}
								>
									<Icons.Pencil /> Edit Details
								</Dropdown.MenuItem>
							) : null}
							{can_enable ? (
								<Dropdown.MenuItem
									onClick={async () => {
										if (
											window.confirm(
												"Are you sure you want to enable this account ?"
											)
										) {
											try {
												const resp = await xhr.delete(
													`/accounting/disabled-accounts/${id}`
												)
												onChange && onChange(account)
												showSnackbar(
													resp.data.message ||
														`${name} account enabled successfully`
												)
											} catch (e) {
												const error = e as Error
												window.alert(error.message)
											}
										}
									}}
								>
									<Icons.Ok /> Enable
								</Dropdown.MenuItem>
							) : null}
							{can_disable ? (
								<DisableAccount account={account} onSuccess={onChange}>
									{({ onDisable }) => (
										<Dropdown.MenuItem onClick={onDisable}>
											<Icons.Ban /> Disable
										</Dropdown.MenuItem>
									)}
								</DisableAccount>
							) : null}
							{can_delete ? (
								<DeleteAccount account={account} onSuccess={onChange}>
									{({ onDelete }) => (
										<Dropdown.MenuItem onClick={onDelete}>
											<Icons.Trash /> Delete
										</Dropdown.MenuItem>
									)}
								</DeleteAccount>
							) : null}
						</Dropdown.Menu>
					</Dropdown>
					<LogTransactionDialog
						initialValues={{
							debit_account: account,
						}}
						open={isLogginTransaction}
						onSuccess={() => {
							onChange && onChange(account)
							setShowLogTransaction(false)
						}}
						onCancel={() => {
							setShowLogTransaction(false)
						}}
					/>
				</>
			)}
		</Component>
	)
}

export function SelectAccount({
	params,
	...props
}: Omit<React.ComponentProps<typeof AsyncSelect>, "fetch"> & {
	params?: TFilters
}) {
	const xhr = useXHR()
	const [newName, setNewName] = useState("")
	return (
		<>
			<AsyncSelect
				createOptionLabel={(q) => `Create "${q}" account`}
				{...props}
				optionRenderer={({ option, created }) => {
					if (created) return <Box>{option.name}</Box>
					const account: IAccount = option
					return (
						<Box>
							<Box>{account.name}</Box>
							{account.tags?.length ? (
								<Box>
									{account.tags.map((t) => (
										<Badge key={t.id}>{t.name}</Badge>
									))}
								</Box>
							) : null}
							{account.description ? (
								<Text color="muted" fontSize="sm">
									{account.description}
								</Text>
							) : null}
						</Box>
					)
				}}
				fetch={(q) =>
					fetchAccounts(xhr, { q, ...(params || {}) }).then((resp) =>
						resp.data.map((d) => ({
							...d,
							disabled: Boolean(d.disabled_at),
						}))
					)
				}
				onCreateNew={(query: string) => {
					setNewName(query)
				}}
			/>
			<AddAccountDialog
				open={!!newName}
				onClose={() => {
					setNewName("")
				}}
			/>
		</>
	)
}

export function DeleteAccount({
	children,
	account,
	onSuccess,
}: {
	children: (props: { onDelete: () => void }) => React.ReactNode
	account: IAccount
	onSuccess?: (account: IAccount) => void
}) {
	const xhr = useXHR()
	return (
		<Component initialState={false}>
			{({ state, setState }) => (
				<>
					{children({ onDelete: () => setState(true) })}
					<Dialog
						open={state}
						onClose={() => setState(false)}
						title="Deletion Confirmation"
						sm
					>
						<Form<{ account_id: number; confirmed_account_name: string }>
							initialValues={{
								account_id: account.id,
								confirmed_account_name: "",
							}}
							validate={validateFormValues(
								Validator.object().shape({
									account_id: Validator.number().required(
										"Please select an account"
									),
									confirmed_account_name: Validator.string()
										.equals(
											[account.name],
											`Please type "${account.name}" to confirm the deletion`
										)
										.required(
											"Please type the account name to confirm the deletion"
										),
								})
							)}
							onSubmit={withServerErrors(async (values) => {
								const resp = await xhr.delete(
									`/accounting/accounts/${values.account_id}`
								)
								setState(false)
								showSnackbar(
									resp.data.message ||
										`"${account.name}" account deleted successfully`
								)
								onSuccess && onSuccess(account)
							})}
							subscription={{ submitting: true }}
						>
							{({ submitting, handleSubmit }) => (
								<form noValidate onSubmit={handleSubmit}>
									<Dialog.Body padding="8">
										<SubmissionError />
										<Text fontSize="lg" fontWeight="semibold">
											Please review details before deletion
										</Text>
										<Box
											padding="4"
											borderWidth="1"
											borderColor="warning"
											rounded="lg"
											marginBottom="4"
										>
											<Grid marginBottom="4">
												<Col>
													<Box marginBottom="4">
														<Heading as="h5" fontSize="sm" color="muted">
															Name
														</Heading>
														<Text whiteSpace="preserve">{account.name}</Text>
													</Box>
												</Col>
												<Col>
													<Box marginBottom="4">
														<Heading as="h5" fontSize="sm" color="muted">
															Assets
														</Heading>
														<Box>
															{account.assets?.length ? (
																<Inline gap="2">
																	{account.assets.map((p) => (
																		<Stack key={p.type}>
																			<Text>
																				{numberToLocalString(p.amount)}
																			</Text>
																			<Text fontSize="xs" color="muted">
																				{p.type}
																			</Text>
																		</Stack>
																	))}
																</Inline>
															) : (
																<Box>
																	<Text
																		color="muted"
																		title="No accounting activities yet"
																	>
																		n/a
																	</Text>
																</Box>
															)}
														</Box>
													</Box>
												</Col>
												<Col>
													<Box marginBottom="4">
														<Heading as="h5" fontSize="sm" color="muted">
															Transactions
														</Heading>
														<Text>{account.postings_count}</Text>
													</Box>
												</Col>
											</Grid>
										</Box>
										<Text color="warning">
											<Icons.AttentionSolid /> Once deleted, this account can
											not be restored.
										</Text>
										<Box marginTop="8">
											<TextInputField
												label={`Type "${account.name}" to confirm`}
												name="confirmed_account_name"
												type="text"
												aria-label="Type in the name of the account to confirm that you want to delete the account"
												required
											/>
										</Box>
									</Dialog.Body>
									<Dialog.Footer>
										<Button
											disabled={submitting}
											type="submit"
											status="danger"
											level="primary"
										>
											{submitting ? (
												"Deleting..."
											) : (
												<>
													<Icons.Ok /> I understand, delete the account
												</>
											)}
										</Button>
										<Button
											disabled={submitting}
											onClick={() => setState(false)}
											level="tertiary"
										>
											Cancel
										</Button>
									</Dialog.Footer>
								</form>
							)}
						</Form>
					</Dialog>
				</>
			)}
		</Component>
	)
}

export function DisableAccount({
	children,
	account,
	onSuccess,
}: {
	children: (props: { onDisable: () => void }) => React.ReactNode
	account: IAccount
	onSuccess?: (account: IAccount) => void
}) {
	const xhr = useXHR()
	const [isOpen, openDialog, closeDialog] = useDialog()
	return (
		<>
			{children({ onDisable: () => openDialog() })}
			<Dialog open={isOpen} onClose={closeDialog} title="Disable Account" sm>
				<Form<{ account_id: number; confirmed_account_name: string }>
					initialValues={{
						account_id: account.id,
						confirmed_account_name: "",
					}}
					validate={validateFormValues(
						Validator.object().shape({
							account_id: Validator.number().required(
								"Please select an account"
							),
							confirmed_account_name: Validator.string()
								.equals(
									[account.name],
									`Please type "${account.name}" to confirm the disabling`
								)
								.required(
									"Please type the account name to confirm the disabling"
								),
						})
					)}
					onSubmit={withServerErrors(async (values) => {
						const resp = await xhr.post(
							`/accounting/disabled-accounts/${values.account_id}`
						)
						closeDialog()
						showSnackbar(
							resp.data.message ||
								`${account.name} account disabled successfully`
						)
						onSuccess && onSuccess(account)
					})}
					subscription={{ submitting: true }}
				>
					{({ submitting, handleSubmit }) => (
						<form noValidate onSubmit={handleSubmit}>
							<Dialog.Body padding="8">
								<SubmissionError />
								<Box as="p" fontSize="lg" fontWeight="semibold">
									Please review details before disabling.
								</Box>
								<Box padding="4" borderWidth="1" rounded="md" marginBottom="4">
									<Grid marginBottom="4">
										<Col>
											<Box marginBottom="4">
												<Heading as="h5" fontSize="sm" color="muted">
													Name
												</Heading>
												<Text whiteSpace="preserve">{account.name}</Text>
											</Box>
										</Col>
										<Col>
											<Box marginBottom="4">
												<Heading as="h5" fontSize="sm" color="muted">
													Assets
												</Heading>
												<Box>
													{account.assets?.length ? (
														<Inline gap="2">
															{account.assets.map((p) => (
																<Stack key={p.type}>
																	<Text>{numberToLocalString(p.amount)}</Text>
																	<Text fontSize="xs" color="muted">
																		{p.type}
																	</Text>
																</Stack>
															))}
														</Inline>
													) : (
														<Box>
															<Text
																color="muted"
																title="No accounting activities yet"
															>
																n/a
															</Text>
														</Box>
													)}
												</Box>
											</Box>
										</Col>
										<Col>
											<Box marginBottom="4">
												<Heading as="h5" fontSize="sm" color="muted">
													Transactions
												</Heading>
												<Text>{account.postings_count}</Text>
											</Box>
										</Col>
									</Grid>
								</Box>
								<Alert status="warning" title="Disabling the account will:">
									<Box as="ul" listStyleType="disc" marginLeft="4">
										<li>
											Disable any future transactions from/to this account.
										</li>
										<li>
											Hide the account from the listing pages. However, you can
											view this account in{" "}
											<Box
												as="em"
												title="Home > Accounting > Accounts > All"
												borderBottomWidth="1"
											>
												All Accounts
											</Box>{" "}
											listing page.
										</li>
									</Box>
								</Alert>
								<Box marginTop="8">
									<TextInputField
										label={`Type "${account.name}" to confirm`}
										name="confirmed_account_name"
										type="text"
										aria-label="Type in the name of the account to confirm that you want to disable the account"
										required
									/>
								</Box>
							</Dialog.Body>
							<Dialog.Footer>
								<Button
									disabled={submitting}
									type="submit"
									status="warning"
									level="primary"
								>
									{submitting ? (
										"Disabling..."
									) : (
										<>
											<Icons.Ok /> I understand, disable this account
										</>
									)}
								</Button>
								<Button
									disabled={submitting}
									onClick={() => closeDialog()}
									level="tertiary"
								>
									Cancel
								</Button>
							</Dialog.Footer>
						</form>
					)}
				</Form>
			</Dialog>
		</>
	)
}

function Filters() {
	return (
		<Stack gap="4">
			<SelectField
				select={SelectTags}
				label="Tags"
				type="accounts"
				name="tags"
				fetchOnMount
				multiple
				cacheKey="accounts-tags"
			/>
			<SwitchInputField name="disabled" label="Disabled Accounts" />
		</Stack>
	)
}
