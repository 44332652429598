import { Box, Container, Spinner } from "@sembark-travel/ui/base"
import { showSnackbar } from "@sembark-travel/ui/snackbar"
import { Helmet } from "react-helmet-async"
import { Breadcrumbs } from "@sembark-travel/ui/router"
import { UpdateTrip, ITrip } from "../../../Trips"
import {
	defer,
	LoaderFunctionArgs,
	useLoaderData,
	Await,
} from "react-router-dom"
import {
	redirect,
	useNavigate,
	useParams,
	generatePath,
} from "../../../router-utils"
import { xhr } from "@sembark-travel/xhr"
import { Suspense } from "react"

export async function Loader({ params }: LoaderFunctionArgs) {
	if (!params.tripId) {
		return redirect("/trips")
	}
	const { tripId } = params
	return defer({
		trip: xhr
			.get<{ data: ITrip }>(`/trips/${tripId}/edit`)
			.then((resp) => resp.data.data),
	})
}

export default function EditTripPage() {
	const navigate = useNavigate()
	const { tripId } = useParams("/trips/:tripId/edit")
	const loader = useLoaderData() as { trip: Promise<ITrip> }
	if (!tripId) return null
	return (
		<>
			<Helmet>
				<title>Edit Trip</title>
			</Helmet>
			<Suspense fallback={<Spinner padding="4" alignCenter />}>
				<Await resolve={loader.trip}>
					{(trip: ITrip) => (
						<>
							<Breadcrumbs
								title="Update Details"
								items={[
									[generatePath("/trips"), "Trips"],
									[generatePath("/trips/:tripId", { tripId }), tripId],
									["", "Update"],
								]}
							/>
							<Box paddingX="4" paddingY="8">
								<Box maxWidth="6xl" marginX="auto">
									<Box borderWidth="1" rounded="md" bgColor="default">
										<Container paddingY="6">
											<UpdateTrip
												trip={trip}
												onSuccess={() => {
													navigate("/trips/:tripId", {
														params: {
															tripId: trip.id.toString(),
														},
														replace: true,
													})
													showSnackbar("Trip details updated")
												}}
												onCancel={() =>
													navigate("/trips/:tripId", {
														params: {
															tripId: trip.id.toString(),
														},
														replace: true,
													})
												}
											/>
										</Container>
									</Box>
								</Box>
							</Box>
						</>
					)}
				</Await>
			</Suspense>
		</>
	)
}
