import { Navigate, useParams } from "./../../../../../../router-utils"
import {
	OverdueComment,
	DemandingComment,
	ActionableComment,
} from "../../../../../../Admin/Tenants"
import { Helmet } from "react-helmet-async"

export default function TenantCommentsForStatusPage() {
	const { status, tenantId } = useParams(
		"/admin/tenants/:tenantId/comments/:status"
	)
	switch (status) {
		case "overdue":
			return (
				<>
					<Helmet>
						<title>Overdue Comments | Tenant</title>
					</Helmet>
					<OverdueComment tenantId={Number(tenantId)} />
				</>
			)
		case "demanding":
			return (
				<>
					<Helmet>
						<title>Demanding Comments | Tenant</title>
					</Helmet>
					<DemandingComment tenantId={Number(tenantId)} />
				</>
			)
		case "actionable":
			return (
				<>
					<Helmet>
						<title>Actionable Comments | Tenant</title>
					</Helmet>
					<ActionableComment tenantId={Number(tenantId)} />
				</>
			)
	}
	return (
		<Navigate
			to="/admin/tenants/:tenantId/comments"
			params={{ tenantId }}
			replace
		/>
	)
}
