import { Navigate, useParams } from "./../../../../../router-utils"
import {
	OverdueComment,
	DemandingComment,
	ActionableComment,
} from "../../../../../Trips"
import { Helmet } from "react-helmet-async"

export default function TripCommentsForStatusPage() {
	const { status, tripId } = useParams("/trips/:tripId/comments/:status")
	switch (status) {
		case "overdue":
			return (
				<>
					<Helmet>
						<title>Overdue Comments | Trip</title>
					</Helmet>
					<OverdueComment tripId={Number(tripId)} />
				</>
			)
		case "demanding":
			return (
				<>
					<Helmet>
						<title>Demanding Comments | Trip</title>
					</Helmet>
					<DemandingComment tripId={Number(tripId)} />
				</>
			)
		case "actionable":
			return (
				<>
					<Helmet>
						<title>Actionable Comments | Trip</title>
					</Helmet>
					<ActionableComment tripId={Number(tripId)} />
				</>
			)
	}
	return <Navigate to="/trips/:tripId/comments" params={{ tripId }} replace />
}
