import {
	Badge,
	Text,
	Box,
	Button,
	Inline,
	Icons,
	Stack,
	Heading,
	Col,
	Component,
	Divider,
	Grid,
	joinAttributes,
	Table,
} from "@sembark-travel/ui/base"
import { showSnackbar } from "@sembark-travel/ui/snackbar"
import { Dialog } from "@sembark-travel/ui/dialog"
import { useXHR } from "@sembark-travel/xhr"
import {
	Form,
	withServerErrors,
	PhoneInputField,
	SelectField,
	TextInputField,
	SubmissionError,
	validateFormValues,
	PhoneNumberValidator,
	PincodeValidator,
	isTruthy,
	TextAreaInputField,
	GetFieldValue,
} from "@sembark-travel/ui/form"
import { useMemo, useState } from "react"
import * as Validator from "yup"
import { IPhoneNumber } from "../Contacts"
import { SelectCities, SelectCountries, SelectStates } from "../Locations"
import { ICity, ICountry, ICountryState } from "../Locations"
import { SelectTripDestination, TTripDestination } from "../TripDestinations"
import { TTenantBrand, TTenantBrandAddress } from "./store"
import { Email, PhoneNumber } from "../Contacts"
import { collect } from "../utils"
import { AnchoredSection } from "../shared/AnchoredSection"

export function TenantBrandAddresses({
	tenantBrand,
	onChange,
}: {
	tenantBrand: TTenantBrand
	onChange: () => void
}) {
	const { addresses } = tenantBrand
	const [billingAddresses, contactAddresses] = useMemo(() => {
		return collect(addresses).partition((a) => isBillingAddress(a.type))
	}, [addresses])
	const xhr = useXHR()
	const [addAddressType, setAddAddressType] = useState<
		"contact" | "billing" | undefined
	>(undefined)
	return (
		<Stack gap="12">
			<Stack gap="4">
				<Inline gap="4" justifyContent="between">
					<Stack gap="2" flex="1">
						<Heading as="h4">
							<Icons.LifeBouy size="6" color="muted" /> Contact Addresses
						</Heading>
						<Text color="muted">
							Here you can manage different addresses used for contact and
							support purposes.
						</Text>
					</Stack>
					<Box>
						<Button
							onClick={() => setAddAddressType("contact")}
							level={contactAddresses.length ? "tertiary" : "secondary"}
							status="primary"
						>
							<Icons.Plus /> Add New
						</Button>
					</Box>
				</Inline>
				{!contactAddresses?.length ? (
					<Box padding="4" bgColor="default" rounded="lg">
						<Text color="muted">No contact or support address added.</Text>
					</Box>
				) : (
					<Table
						headers={["Label", "Address", "Contact", "Trip Destinations", ""]}
						bordered
						responsive
						hover
						alignCols={{ 4: "right" }}
						rows={contactAddresses.map((a) => [
							<Stack gap="1">
								<Text>{a.name}</Text>
								{a.is_primary ? (
									<Box>
										<Badge primary>Primary</Badge>
									</Box>
								) : null}
							</Stack>,
							<Address address={a} hideContact hideBilling hideDestinations />,
							<Stack gap="2">
								{a.phone_numbers?.length ? (
									<Box>
										<PhoneNumber value={a.phone_numbers} />
									</Box>
								) : null}
								{a.email ? (
									<Box>
										<Email value={a.email} />
									</Box>
								) : null}
							</Stack>,
							a.trip_destinations?.length ? (
								<Stack gap="px">
									{a.trip_destinations.map((t) => (
										<Text key={t.id} fontSize="sm">
											{t.name}
										</Text>
									))}
								</Stack>
							) : (
								"-"
							),
							<Inline justifyContent="end">
								<AddressActions
									tenantBrand={tenantBrand}
									address={a}
									onChange={onChange}
								/>
							</Inline>,
						])}
					/>
				)}
			</Stack>
			<Stack gap="4">
				<Inline gap="4" justifyContent="between">
					<Stack gap="2" flex="1">
						<Heading as="h4">
							<Icons.BankNotes size="6" color="muted" /> Billing Addresses
						</Heading>
						<Text color="muted">
							Here you can manage different addresses used for billing purposes.
						</Text>
					</Stack>
					<Box>
						<Button
							onClick={() => setAddAddressType("billing")}
							level={billingAddresses?.length ? "tertiary" : "secondary"}
							status="primary"
						>
							<Icons.Plus /> Add New
						</Button>
					</Box>
				</Inline>
				{!billingAddresses?.length ? (
					<Box padding="4" bgColor="default" rounded="lg">
						<Text color="muted">No billing address added.</Text>
					</Box>
				) : (
					<Table
						headers={[
							"Label",
							"Address",
							"Contact",
							"Billing Details",
							"Trip Destinations",
							"",
						]}
						bordered
						responsive
						hover
						alignCols={{ 5: "right" }}
						rows={billingAddresses.map((a) => [
							<AnchoredSection id={`address${a.id}`}>
								<Stack gap="1">
									<Text>{a.name}</Text>
									{a.is_primary ? (
										<Box>
											<Badge primary>Primary</Badge>
										</Box>
									) : null}
								</Stack>
							</AnchoredSection>,
							<Address address={a} hideContact hideBilling hideDestinations />,
							<Stack gap="1">
								{a.phone_numbers?.length ? (
									<Box>
										<PhoneNumber value={a.phone_numbers} />
									</Box>
								) : null}
								{a.email ? (
									<Box>
										<Email value={a.email} />
									</Box>
								) : null}
							</Stack>,
							<Stack gap="1">
								<Text whiteSpace="preserveLine">{a.billing_details}</Text>
							</Stack>,
							a.trip_destinations?.length ? (
								<Stack gap="px">
									{a.trip_destinations.map((t) => (
										<Text key={t.id} fontSize="sm">
											{t.name}
										</Text>
									))}
								</Stack>
							) : (
								"-"
							),
							<Inline justifyContent="end">
								<AddressActions
									tenantBrand={tenantBrand}
									address={a}
									onChange={onChange}
								/>
							</Inline>,
						])}
					/>
				)}
			</Stack>
			<Dialog
				open={addAddressType !== undefined}
				onClose={() => setAddAddressType(undefined)}
				title={`Add New ${
					addAddressType === "billing" ? "Billing" : "Contact"
				} Address`}
			>
				<Dialog.Body>
					<TenantBrandAddressForm
						brandId={tenantBrand.id}
						type={addAddressType || "contact"}
						addressingName={
							addAddressType === "billing"
								? tenantBrand.name
								: tenantBrand.short_name
						}
						isPrimary={
							/* First Address is always primary */
							addAddressType === "billing"
								? !billingAddresses.length
								: !contactAddresses.length
						}
						onSubmit={async (data) => {
							await xhr.post(`/tenant-brands/${tenantBrand.id}/addresses`, {
								...data,
								city: data.city?.name,
								state: data.state?.name,
								country: data.country?.name,
								trip_destinations: data.trip_destinations?.map((d) => d.id),
							})
							setAddAddressType(undefined)
							onChange()
						}}
						onCancel={() => setAddAddressType(undefined)}
					/>
				</Dialog.Body>
			</Dialog>
		</Stack>
	)
}

function AddressActions({
	tenantBrand,
	address,
	onChange,
}: {
	tenantBrand: TTenantBrand
	address: TTenantBrandAddress
	onChange: () => void
}) {
	const xhr = useXHR()
	return (
		<Inline gap="2">
			<Component initialState={false}>
				{({ state, setState }) => (
					<>
						<Button
							size="sm"
							level="tertiary"
							onClick={() => setState(true)}
							title="Edit Address"
						>
							<Icons.Pencil />
						</Button>
						<Dialog
							open={state}
							onClose={() => setState(false)}
							title="Update Address"
						>
							<Dialog.Body>
								<TenantBrandAddressForm
									brandId={tenantBrand.id}
									addressingName={
										isBillingAddress(address.type)
											? tenantBrand.name
											: tenantBrand.short_name
									}
									type={isBillingAddress(address.type) ? "billing" : "contact"}
									isPrimary={isTruthy(address.is_primary)}
									initialValues={{
										...address,
										is_primary: address.is_primary ? "1" : "0",
										city: address.location?.city,
										state: address.location?.state,
										country: address.location?.country,
										phone_numbers: address.phone_numbers,
										email: address.email?.email || "",
									}}
									onSubmit={async (data) => {
										await xhr.patch(
											`/tenant-brands/${tenantBrand.id}/addresses/${address.id}`,
											{
												...data,
												city: data.city?.name,
												state: data.state?.name,
												country: data.country?.name,
												trip_destinations: data.trip_destinations?.map(
													(d) => d.id
												),
											}
										)
										setState(false)
										onChange()
									}}
									onCancel={() => setState(false)}
								/>
							</Dialog.Body>
						</Dialog>
					</>
				)}
			</Component>
			{!isTruthy(address.is_primary) ? (
				<Component initialState={false}>
					{({ state, setState }) => (
						<>
							<Button
								size="sm"
								level="tertiary"
								status="warning"
								title="Remove Address"
								onClick={() => setState(true)}
							>
								<Icons.Trash />
							</Button>
							<Dialog
								open={state}
								onClose={() => setState(false)}
								title="Confirm Address Removal"
								sm
							>
								<Dialog.Body>
									<Form<{ name: string }>
										initialValues={{ name: "" }}
										validate={validateFormValues(
											Validator.object().shape({
												name: Validator.string()
													.required(
														`Please type "${address.name}" to confirm the deletion.`
													)
													.equals(
														[address.name],
														`This value should  be equal to "${address.name}" (without quotes)`
													),
											})
										)}
										onSubmit={withServerErrors(async (values) => {
											await xhr.delete(
												`/tenant-brands/${tenantBrand.id}/addresses/${address.id}`,
												{
													params: values,
												}
											)
											setState(false)
											onChange()
											showSnackbar(
												`Address "${address.name}" deleted successfully.`
											)
										})}
										subscription={{ submitting: true }}
									>
										{({ submitting, handleSubmit }) => (
											<form noValidate onSubmit={handleSubmit}>
												<Box as="p" fontSize="lg" fontWeight="semibold">
													Are you sure you want to delete the following address:
												</Box>
												<Box
													borderWidth="1"
													rounded="lg"
													bgColor="warning"
													borderColor="warning"
												>
													<Box
														padding="4"
														borderBottomWidth="1"
														borderColor="warning"
													>
														<Box fontWeight="semibold">{address.name}</Box>
														<Box
															textTransform="capitalize"
															fontSize="sm"
															color="muted"
														>
															{address.type_display_name}
														</Box>
													</Box>
													<Box padding="4">
														<Address address={address} />
													</Box>
												</Box>
												<Divider />
												<TextInputField
													label={`Please type "${address.name}" to confirm`}
													name="name"
													required
													type="text"
												/>
												<Divider />
												<SubmissionError />
												<Inline gap="4">
													<Button
														type="submit"
														status="warning"
														disabled={submitting}
													>
														<Icons.Trash />{" "}
														{submitting ? "Deleting..." : "Yes, Delete Address"}
													</Button>
													<Button
														disabled={submitting}
														onClick={() => setState(false)}
													>
														<Icons.Cancel /> Cancel
													</Button>
												</Inline>
											</form>
										)}
									</Form>
								</Dialog.Body>
							</Dialog>
						</>
					)}
				</Component>
			) : null}
		</Inline>
	)
}

function Address({
	address,
	hideContact,
	hideBilling,
	hideDestinations,
}: {
	address: TTenantBrandAddress
	hideContact?: boolean
	hideBilling?: boolean
	hideDestinations?: boolean
}) {
	const {
		line_1,
		line_2,
		landmark,
		location,
		postcode,
		phone_numbers,
		email,
		trip_destinations,
		addressing_name,
		billing_details,
	} = address
	return (
		<Stack gap="4">
			<Box as="address" whiteSpace="preserveLine">
				{[line_1, line_2].filter(Boolean).join(", ")}{" "}
				{landmark ? (
					<>
						<br />
						{landmark}
					</>
				) : null}
				<br />
				{location.city?.name}, {location.state?.name},{" "}
				{location.country?.short_name} - {postcode} <br />
			</Box>
			{hideContact ? null : (
				<Stack gap="1">
					{phone_numbers?.length ? (
						<Box>
							<PhoneNumber value={phone_numbers} />
						</Box>
					) : null}
					{email ? (
						<Box>
							<Email value={email} />
						</Box>
					) : null}
				</Stack>
			)}
			{(addressing_name || billing_details) && !hideBilling ? (
				<Stack gap="1">
					<Text fontSize="sm" color="muted" fontWeight="semibold">
						Aditional Billing Details
					</Text>
					<Text>{addressing_name}</Text>
					<Text fontSize="sm" whiteSpace="preserveLine">
						{billing_details}
					</Text>
				</Stack>
			) : null}
			{trip_destinations?.length && !hideDestinations ? (
				<Stack gap="1" borderTopWidth="1" paddingTop="2">
					<Text fontSize="sm" color="muted" fontWeight="semibold">
						Trip Destinations
					</Text>
					<Text>{joinAttributes(...trip_destinations.map((t) => t.name))}</Text>
				</Stack>
			) : null}
		</Stack>
	)
}

const tenantAddressValidationSchema = Validator.object()
	.shape({
		name: Validator.string().required("Please provide a name for this address"),
		type: Validator.string().required("Please provide the address type"),
		city: Validator.mixed().required("Please provide the city for address"),
		state: Validator.mixed().required(
			"Please provide the state / province / region for address"
		),
		country: Validator.mixed().required(
			"Please provide the country for address"
		),
		line_1: Validator.string().nullable(),
		line_2: Validator.string().when("line_1", {
			is: (line: string) => !line,
			then: Validator.string().required(
				"Please provide flat/building or Colony/Locality details"
			),
		}),
		postcode: PincodeValidator().required(
			"Please provide the pincode for the region"
		),
		landmark: Validator.string(),
		phone_numbers: Validator.array()
			.of(
				PhoneNumberValidator().required("Please provide a contact phone number")
			)
			.min(1, "Please provide atleast on phone number"),
		email: Validator.string()
			.email("Please provide a valid email address")
			.required("Please provide an email address"),
		trip_destinations: Validator.array().of(Validator.mixed()),
		addressing_name: Validator.string()
			.required("Please provide a addressing/billing name")
			.max(100, "Please use 100 or fewer characters"),
		billing_details: Validator.string()
			.nullable()
			.max(500, "Please use 500 or fewer characters"),
	})
	.required()

type TTenantAddressFormParams = {
	name: string
	type: "billing" | "contact"
	line_1?: string
	line_2?: string
	city?: ICity
	state?: ICountryState
	country?: ICountry
	postcode: string
	phone_numbers?: Array<
		Pick<IPhoneNumber<TTenantBrandAddress>, "number" | "country_code">
	>
	email: string
	landmark?: string
	trip_destinations?: Array<TTripDestination>
	addressing_name?: string
	billing_details?: string
	is_primary?: "0" | "1"
}

function isBillingAddress(type: string) {
	return type === "billing"
}

function TenantBrandAddressForm({
	onSubmit,
	onCancel,
	initialValues: propInitialValues,
	type,
	isPrimary,
	addressingName,
	brandId,
}: {
	initialValues?: Partial<TTenantAddressFormParams>
	onSubmit: (data: TTenantAddressFormParams) => Promise<void>
	onCancel: () => void
	type: "contact" | "billing"
	isPrimary: boolean
	addressingName: string
	brandId: number
}) {
	const initialValues: TTenantAddressFormParams = useMemo(() => {
		return {
			name: propInitialValues?.name || "",
			type: isBillingAddress(type) ? "billing" : "contact",
			line_1: propInitialValues?.line_1 || "",
			line_2: propInitialValues?.line_2 || "",
			city: propInitialValues?.city,
			state: propInitialValues?.state,
			country: propInitialValues?.country,
			postcode: propInitialValues?.postcode || "",
			phone_numbers: propInitialValues?.phone_numbers || [
				{
					number: "",
					country_code: "IN",
				},
			],
			email: propInitialValues?.email || "",
			landmark: propInitialValues?.landmark || "",
			trip_destinations: propInitialValues?.trip_destinations || [],
			addressing_name: addressingName,
			billing_details: propInitialValues?.billing_details || "",
		}
	}, [propInitialValues, type, addressingName])
	return (
		<Form<typeof initialValues>
			initialValues={initialValues}
			validate={validateFormValues(tenantAddressValidationSchema)}
			onSubmit={withServerErrors(async (data) => {
				await onSubmit({
					...data,
					addressing_name: addressingName,
					is_primary: data.trip_destinations?.length ? "0" : "1",
					trip_destinations: data.trip_destinations?.length
						? data.trip_destinations
						: undefined,
				})
			})}
			subscription={{ submitting: true }}
		>
			{({ form, submitting, handleSubmit }) => (
				<form noValidate onSubmit={handleSubmit}>
					<Grid gap="4">
						<Col xs={12} sm={4}>
							<Stack gap="1">
								<Heading as="h4">
									<Icons.Identification opacity="70" size="6" /> Label
								</Heading>
								<Text color="muted">
									Please provide label/short name for address for your internal
									references.
								</Text>
							</Stack>
						</Col>
						<Col>
							<Stack gap="4">
								<TextInputField
									name="name"
									label="Label/Short Name for Address"
									secondaryLabel="used for internal references"
									required
									placeholder="Sikkim Office"
									type="text"
									autoComplete="no_please"
								/>
							</Stack>
						</Col>
					</Grid>
					<Divider sm />
					<Stack gap="6">
						<Grid gap="4">
							<Col xs={12} sm={4}>
								<Stack gap="1">
									<Heading as="h4">
										<Icons.LocationMarker opacity="70" size="6" /> Address
										Details
									</Heading>
									<Text color="muted">
										Please provide city, state and country along with street
										details for the address.
									</Text>
								</Stack>
							</Col>
							<Col>
								<Stack gap="4">
									<Grid gap="4">
										<Col sm={6}>
											<SelectField
												select={SelectCities}
												name="city"
												label="City / Town / District"
												multiple={false}
												creatable
												onChange={(value?: ICity) => {
													form.change("city", value)
													if (value?.state) {
														form.change("state", value.state)
														if (value.state.country) {
															form.change("country", value.state.country)
														}
													}
												}}
											/>
										</Col>
										<Col>
											<SelectField
												select={SelectStates}
												name="state"
												label="State / Province / Region"
												multiple={false}
												creatable
											/>
										</Col>
									</Grid>
									<Grid gap="4">
										<Col sm={6}>
											<SelectField
												select={SelectCountries}
												name="country"
												label="Country"
												multiple={false}
											/>
										</Col>
										<Col>
											<TextInputField
												label="Pin Code"
												name={`postcode`}
												type="text"
												placeholder="e.g. Area Code"
												autoComplete="no_please"
											/>
										</Col>
									</Grid>
									<TextInputField
										label="Street Address"
										name={`line_1`}
										type="text"
										placeholder="Flat / House No. / Floor / Building"
										autoComplete="no_please"
									/>
									<TextInputField
										name={`line_2`}
										type="text"
										placeholder="Colony / Street / Locality"
										autoComplete="no_please"
									/>
									<TextInputField
										label="Landmark"
										name={`landmark`}
										secondaryLabel="optional"
										type="text"
										placeholder="E.g. Behind Cinema"
									/>
								</Stack>
							</Col>
						</Grid>
						<GetFieldValue<"contact" | "billing"> name="type">
							{({ value }) =>
								isBillingAddress(value) ? (
									<Grid gap="4">
										<Col xs={12} sm={4}>
											<Stack gap="1">
												<Heading as="h5" fontSize="md">
													<Icons.BankNotes size="5" /> Billing Details
												</Heading>
												<Text color="muted">
													Please provide any aditional billing details e.g. CIN,
													PAN, GST etc.
												</Text>
											</Stack>
										</Col>
										<Col>
											<Stack gap="4">
												<TextAreaInputField
													name="billing_details"
													label="Aditional Details"
													placeholder={
														"e.g. PAN: SSSSS1111A\nGSTIN: GYDBSGH123123HKKG"
													}
												/>
											</Stack>
										</Col>
									</Grid>
								) : null
							}
						</GetFieldValue>
					</Stack>
					<Divider sm />
					<Grid gap="4">
						<Col xs={12} sm={4}>
							<Stack gap="1">
								<Heading as="h4">
									<Icons.Phone opacity="70" size="6" /> Contact Details
								</Heading>
								<Text color="muted">
									Please provide phone number and email address for online
									contact.
								</Text>
							</Stack>
						</Col>
						<Col>
							<Stack gap="4">
								<PhoneInputField
									name="phone_numbers"
									label="Contact Number(s)"
									required
									multi
								/>
								<TextInputField
									name="email"
									label="Contact Email"
									required
									type="email"
									placeholder="contact@domain.com"
								/>
							</Stack>
						</Col>
					</Grid>
					{!isPrimary ? (
						<>
							<Divider sm />
							<Grid gap="4">
								<Col xs={12} sm={4}>
									<Stack gap="1">
										<Heading as="h4">
											<Icons.Map opacity="70" size="6" /> Trip Destinations
										</Heading>
										<Text color="muted">
											Please select destinations to attach the address to
											specific destinations.
										</Text>
									</Stack>
								</Col>
								<Col>
									<Stack gap="4">
										<SelectField
											select={SelectTripDestination}
											name="trip_destinations"
											label="Trip Destinations"
											tenantBrands={[brandId]}
											multiple
										/>
									</Stack>
								</Col>
							</Grid>
						</>
					) : null}
					<Divider sm />
					<Grid>
						<Col xs={12} sm={{ offset: 4, span: 8 }}>
							<Stack gap="4">
								<SubmissionError />
								<Inline gap="4">
									<Button type="submit" disabled={submitting}>
										{submitting ? "Saving..." : "Save Details"}
									</Button>
									{onCancel ? (
										<Button
											type="button"
											disabled={submitting}
											onClick={onCancel}
											level="tertiary"
										>
											Cancel
										</Button>
									) : null}
								</Inline>
							</Stack>
						</Col>
					</Grid>
				</form>
			)}
		</Form>
	)
}
