import { Table } from "@sembark-travel/ui/base"
import { ListView, Search, useSearch } from "@sembark-travel/ui/list"
import { useLocationQuery } from "@sembark-travel/ui/router"
import { useEffect } from "react"
import { TWorldCurrency, worldXHR } from "../../World"

export function CurrenciesList({ countryId }: { countryId?: number | string }) {
	const [query, setQuery] = useLocationQuery()
	const [searchParams, setSearchParams] = useSearch(query)
	useEffect(() => {
		setQuery(searchParams)
	}, [searchParams, setQuery])
	return (
		<Search
			title="Currencies"
			initialParams={searchParams}
			onSearch={(params) => setSearchParams(params)}
		>
			{() => (
				<ListView<TWorldCurrency>
					pageKey="admin/world/currencies"
					params={searchParams}
					onPageChange={(page) => setSearchParams({ ...searchParams, page })}
					fetch={(xhr, params) =>
						worldXHR(xhr).getCurrencies({
							country_id: countryId || null,
							...params,
							include: "country",
						})
					}
				>
					{({ items }) => (
						<Table
							headers={[
								"Name",
								"Code",
								"Symbol",
								"Native",
								"Precision",
								"Decimal",
								"Thousand",
							].concat(!countryId ? ["Country"] : [])}
							responsive
							hover
							bordered
							rows={items.map((item) =>
								([] as Array<React.ReactNode>)
									.concat([
										item.name,
										item.code,
										item.symbol,
										item.symbol_native,
										item.precision,
										item.decimal_mark,
										item.thousands_separator,
									])
									.concat(!countryId ? [item.country?.name] : [])
							)}
						/>
					)}
				</ListView>
			)}
		</Search>
	)
}
