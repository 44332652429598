import { Box, Container } from "@sembark-travel/ui/base"
import { Breadcrumbs } from "@sembark-travel/ui/router"
import { Helmet } from "react-helmet-async"
import { EditIntegrationItem } from "./../../../../Integrations"
import { generatePath, useParams, useNavigate } from "../../../../router-utils"

export default function EditIntegrationPage() {
	const navigate = useNavigate()
	const { integrationId } = useParams("/integrations/:integrationId")
	return (
		<>
			<Helmet>
				<title>New Integration</title>
			</Helmet>
			<Breadcrumbs
				title="New Integration"
				items={[
					[generatePath("/org/integrations"), "Integrations"],
					[
						generatePath("/integrations/:integrationId", {
							integrationId: integrationId,
						}),
						"Item",
					],
					["", "New"],
				]}
			/>
			<Box paddingX={{ md: "4" }} paddingY={{ md: "8" }}>
				<Box maxWidth="3xl" marginX="auto">
					<Box borderWidth={{ md: "1" }} rounded="md" bgColor="default">
						<Container paddingY="6">
							<EditIntegrationItem
								integrationId={integrationId}
								onSuccess={(integration) =>
									navigate("/integrations/:integrationId", {
										params: {
											integrationId: integration.uid,
										},
										replace: true,
									})
								}
								onCancel={() =>
									navigate("/integrations/:integrationId", {
										params: {
											integrationId,
										},
										replace: true,
									})
								}
							/>
						</Container>
					</Box>
				</Box>
			</Box>
		</>
	)
}
