import { Helmet } from "react-helmet-async"
import { Schedules } from "./../../../CabScheduling"
import { Alert, Stack, Text } from "@sembark-travel/ui/base"
import { Link } from "@sembark-travel/ui/router"
import { generatePath } from "../../../router-utils"
import { useSearchParams } from "react-router-dom"

export default function SchedulesCalendarModule() {
	const [params] = useSearchParams()
	return (
		<>
			<Helmet>
				<title>Cab Schedules Calendar</title>
			</Helmet>
			<Alert status="warning" title="Deprecated" rounded="none">
				<Stack gap="1">
					<Text>
						Cab scheduling have been moved to{" "}
						<Link
							to={generatePath("/operational-bookings/calendar")}
							query={{ date: params.get("date") }}
							fontWeight="semibold"
							textDecoration={"underline"}
						>
							Operational Bookings
						</Link>
						. This section will be removed in upcoming updates.
					</Text>
					<Text>Please provide your feedback in our support channel.</Text>
				</Stack>
			</Alert>
			<Schedules />
		</>
	)
}
