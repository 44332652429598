import { Helmet } from "react-helmet-async"
import { QuoteRequests } from "../../../QuoteRequests"

export default function QuoteRequestsListPage(
	props: Omit<React.ComponentProps<typeof QuoteRequests>, "title" | "incoming">
) {
	return (
		<>
			<Helmet>
				<title>Quote Requests</title>
			</Helmet>
			<QuoteRequests {...props} title="Incoming Quote Requests" incoming />
		</>
	)
}
