import {
	Icons,
	Box,
	Stack,
	Inline,
	Heading,
	Text,
} from "@sembark-travel/ui/base"
import { Link } from "@sembark-travel/ui/router"
import { generatePath } from "../router-utils"
import { addUnit, subtractUnit } from "@sembark-travel/datetime-utils"
import { useMemo } from "react"
import { useDemandingCommentsCount } from "./store"

function Stats({ label }: { label: "Today" | "Yesterday" | "Next 7 Days" }) {
	const [startDate, endDate] = useMemo(() => {
		switch (label) {
			case "Today":
				return [new Date(), new Date()]
			case "Yesterday":
				return [
					subtractUnit(new Date(), 1, "day"),
					subtractUnit(new Date(), 1, "day"),
				]
			case "Next 7 Days":
				return [addUnit(new Date(), 1, "day"), addUnit(new Date(), 7, "day")]
		}
	}, [label])
	const { data } = useDemandingCommentsCount(startDate, endDate)
	return (
		<Stack
			as={Link}
			to={generatePath("/follow-ups")}
			query={{
				activeTab:
					label === "Today"
						? "today"
						: label === "Yesterday"
							? "yesterday"
							: "upcoming",
			}}
			bgColor={{ hover: "subtle" }}
			padding="2"
			rounded="md"
			gap="1"
		>
			<Text fontWeight="semibold" color="muted">
				{label} <Icons.ChevronDown rotate="270" />
			</Text>
			<Text fontSize="lg" fontWeight="semibold">
				{data ? (
					data.data ? (
						data.data
					) : (
						<Icons.Ok color="success" size="6" title="All Done" />
					)
				) : (
					"..."
				)}
			</Text>
		</Stack>
	)
}

export function CommentsWidget() {
	return (
		<Stack bgColor="default" rounded="md" borderWidth="1">
			<Box paddingX="4" paddingY="2" borderBottomWidth="1">
				<Inline gap="4" justifyContent="between" flexWrap="wrap">
					<Inline gap="2" alignItems="center">
						<Heading fontSize="md" as="h3">
							Pending Follow-ups
						</Heading>
						<Box>
							<Link padding="2" to={generatePath("/follow-ups")} color="accent">
								<Icons.ArrowLeft rotate="180" />
							</Link>
						</Box>
					</Inline>
				</Inline>
			</Box>
			<Inline padding="2" gap="1" flexWrap="wrap">
				<Stats label="Today" />
				<Stats label="Yesterday" />
				<Stats label="Next 7 Days" />
			</Inline>
		</Stack>
	)
}
