import { Fragment } from "react"
import { Helmet } from "react-helmet-async"
import { TransportServicesList } from "./../../../TransportServices"

export default function TransportServicesListPage() {
	return (
		<Fragment>
			<Helmet>
				<title>Transport Services</title>
			</Helmet>
			<TransportServicesList />
		</Fragment>
	)
}
