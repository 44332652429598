import { Alert, Box, Tabs, TabsList, TabContent } from "@sembark-travel/ui/base"
import { Outlet } from "react-router-dom"
import { NavLink } from "@sembark-travel/ui/router"
import { PERMISSIONS, useCheckPermissions } from "../../../../../Auth"
import { useTripDetailsOutletContext } from "../_trip-details-context"

export default function TripAccountingLayoutPage() {
	const { trip, onChange } = useTripDetailsOutletContext()
	const { hasPermission } = useCheckPermissions()
	const manageQueries = hasPermission(PERMISSIONS.VIEW_QUERIES)
	const managesAccounting = hasPermission(PERMISSIONS.MANAGE_ACCOUNTING)
	if (!trip.converted_at && !trip.on_hold_at) {
		return (
			<Alert
				status="warning"
				title="Please convert the trip to view the accounting"
			/>
		)
	}
	return (
		<Box paddingTop="4">
			<Tabs>
				<TabsList>
					<NavLink to="payments/*">Payments</NavLink>
					{manageQueries || managesAccounting ? (
						<NavLink to="invoices/*">Invoices</NavLink>
					) : null}
					{trip.converted_at && managesAccounting ? (
						<NavLink to="transactions/*">Profit Report</NavLink>
					) : null}
				</TabsList>
				<TabContent>
					<Outlet context={{ trip, onChange }} />
				</TabContent>
			</Tabs>
		</Box>
	)
}
