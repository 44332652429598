import { utcTimestampToLocalDate } from "@sembark-travel/datetime-utils"
import { ITenant, TenantLoginActivities } from "../../../../../Admin/Tenants"
import { useOutletContext } from "react-router-dom"
import { Required } from "utility-types"
import { Helmet } from "react-helmet-async"

export default function TenantLoginActivitiesPage() {
	const { tenant } = useOutletContext<{
		tenant: Required<ITenant, "signup_at">
		refresh: () => void
	}>()
	return (
		<>
			<Helmet>
				<title>Tenant Billings</title>
			</Helmet>
			<TenantLoginActivities
				tenantUid={tenant.uid}
				signupAt={utcTimestampToLocalDate(tenant.signup_at)}
			/>
		</>
	)
}
