import { Box, Container } from "@sembark-travel/ui/base"
import { Breadcrumbs } from "@sembark-travel/ui/router"
import { Helmet } from "react-helmet-async"
import { NewTenantForm } from "../../../../Admin/Tenants"
import { generatePath, joinPaths, useNavigate } from "../../../../router-utils"

export default function NewTenant() {
	const navigate = useNavigate()
	const signupUrl = joinPaths(
		window.location.origin || "",
		generatePath("/tenant-signup")
	)
	return (
		<>
			<Helmet>
				<title>Invite Tenant</title>
			</Helmet>
			<Breadcrumbs
				title="Invite Tenant"
				items={[
					[generatePath("/admin/tenants"), "Tenants"],
					["", "Invite"],
				]}
			/>
			<Box paddingX="4" paddingY="8">
				<Box maxWidth="4xl" marginX="auto">
					<Box borderWidth="1" rounded="md" bgColor="default">
						<Container paddingY="6">
							<NewTenantForm
								onSuccess={(tenant) =>
									navigate &&
									navigate("/admin/tenants/:tenantId", {
										params: {
											tenantId: tenant.id.toString(),
										},
									})
								}
								onCancel={() => navigate("/admin/tenants")}
								signupUrl={signupUrl}
							/>
						</Container>
					</Box>
				</Box>
			</Box>
		</>
	)
}
