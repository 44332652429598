import { TFormattedMoney } from "@sembark-travel/money"
import type { IAccount, IJournal } from "../Accounting"
import type { IUser } from "../Auth"
import type { IComment } from "../Comments"
import type { IActivityLog } from "./../ActivityLogs"

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export interface IInstalment<TPaymentable = any> {
	id: number
	currency: string
	amount: number
	functional_currency?: string
	amount_in_functional_currency?: number
	amount_in_functional_currency_money?: TFormattedMoney
	due_at: string
	paid_at?: string
	paid_by?: IUser
	paid_by_id?: number
	comments?: Array<IComment>
	reference_id?: string
	payment_id: number
	activity_logs?: Array<IActivityLog>
	verified_at?: string
	verified_by?: IUser
	payment: IPayment<TPaymentable>
	is_overdue: boolean
	journals?: Array<IJournal>
	debit_accounts?: Array<IAccount>
	credit_accounts?: Array<IAccount>
	can_revert_payment: boolean
	can_verify_payment: boolean
	can_unverify_payment: boolean
	can_delete: boolean
	can_log_payment: boolean
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export interface IPayment<TPaymentable = any> {
	id: number
	currency: string
	amount: number
	is_credit: boolean
	comments: string
	paymentable_id: number
	paymentable_type: string
	paymentable: TPaymentable
	instalments: IInstalment<TPaymentable>[]
	next_due_instalment?: IInstalment<TPaymentable>
	next_due_at?: string
	paid_amount?: number
	due_amount?: number
	created_at: string
	created_by: IUser
	updated_at?: string
	updated_by?: IUser
	narration: string
	is_refund: boolean
}

export interface IPaymentable {
	/**
	 * Total non-refunding payments amount
	 */
	payments_amount?: number
	/**
	 * Total paid amount of non-refunding payments
	 */
	paid_payments_amount?: number
	/**
	 * Total refunding/canceled payments amount
	 */
	refunding_payments_amount?: number
	/**
	 * Total paid amount of refunding/canceled payments
	 */
	paid_refunding_payments_amount?: number
	/**
	 * Non-Refunding payments amount - Refunding payments amount
	 */
	cancellation_charges?: number
	/**
	 * Same as cancellation charges
	 */
	total_payments_amount?: number
	/**
	 * Total number of due payments (non-refunding/refunding)
	 */
	due_payments_count?: number
}

export const PAYMENTABLE_TYPES = {
	trips: "Trips",
	hotel_bookings: "Hotels",
	scheduled_cabs: "Cabs",
	travel_activity_bookings: "Travel Activity Booking",
} as const
