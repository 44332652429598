import { Box, Text, Heading, Stack } from "@sembark-travel/ui/base"
import { Link } from "@sembark-travel/ui/router"
import config from "./config"
import { generatePath, useLocation } from "./router-utils"

export function NotFound() {
	const location = useLocation()
	return (
		<Box paddingY="16" maxWidth="4xl" marginX="auto">
			<Stack
				gap="4"
				bgColor="danger"
				padding="4"
				rounded="md"
				borderWidth="1"
				borderColor="danger"
			>
				<Heading
					as="h3"
					fontWeight="semibold"
					color="muted"
					textTransform="uppercase"
				>
					Error 404!
				</Heading>
				<Heading as="h2" fontSize="3xl" fontWeight="normal">
					Page Not Found!
				</Heading>
				<Text as="blockquote">{location.pathname}</Text>
				<Text>
					The page you are looking for does not exists. It may be renamed, moved
					or never existed. If this is an error, please contact us at{" "}
					<a href={`mailto:${config.supportEmail}`}>{config.supportEmail}</a>
				</Text>
				<Text>
					If you are here by mistake, please{" "}
					<Link to={generatePath("/")} color="accent">
						visit your Dashboard
					</Link>
					.
				</Text>
			</Stack>
		</Box>
	)
}
