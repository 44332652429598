import { TTravelActivityTicketTouristConfiguration } from "./store"
import { collect } from "../utils"
import { Required } from "utility-types"

export function parseTouristConfigurationsString(
	str: string
): Array<Omit<TTravelActivityTicketTouristConfiguration, "id">> {
	const configurations = str
		.replace(/\s/g, "") // remove all spaces
		.split(",")
		.map((t) => {
			let tourist_type:
				| TTravelActivityTicketTouristConfiguration["tourist_type"]
				| undefined = undefined
			let age_start: number | null = null
			let age_end: number | null = null
			let quantity = 1
			const childRegex = /child\(([\d]+)-([\d]+)\)/i
			const childMatch = childRegex.exec(t)
			if (childMatch) {
				tourist_type = "child"
				if (childMatch[1]) {
					age_start = isNaN(Number(childMatch[1]))
						? null
						: Number(childMatch[1])
				}
				if (childMatch[2]) {
					age_end = isNaN(Number(childMatch[2])) ? null : Number(childMatch[2])
				}
			} else {
				const groupRegex = /(pax|adults|group)(\((\d+)\))?/i
				const groupMatch = groupRegex.exec(t)
				if (groupMatch) {
					tourist_type = "group"
					const quantityRegex = /\((\d+)pax\)?/i
					const quantityMatch = quantityRegex.exec(t)
					quantity = 1
					age_start = 1
					if (quantityMatch) {
						quantity = isNaN(Number(quantityMatch[1]))
							? 1
							: Number(quantityMatch[1])
					}
					const ageStartRegex = /\((\d+)y\+\)?/i
					const ageStartMatch = ageStartRegex.exec(t)
					if (ageStartMatch) {
						age_start = isNaN(Number(ageStartMatch[1]))
							? 1
							: Number(ageStartMatch[1])
					}
				} else {
					if (t.toLowerCase() === "adult") {
						tourist_type = "adult"
					}
				}
			}
			if (!tourist_type) return undefined
			let name = "Adult"
			if (tourist_type === "child") {
				name = `Child (${age_start}-${age_end})`
			}
			if (tourist_type === "group") {
				name = ["Group", `(${quantity} Pax)`, `(${age_start}y+)`]
					.filter(Boolean)
					.join(" ")
			}
			return {
				name,
				tourist_type,
				quantity,
				age_start,
				age_end,
			} as Omit<TTravelActivityTicketTouristConfiguration, "id">
		})
		.filter((t): t is Omit<TTravelActivityTicketTouristConfiguration, "id"> =>
			Boolean(t)
		)
	return collect(configurations)
		.unique((t) => t.name)
		.toArray()
}

export function getTouristConfigurationsForPax(
	availableConfigurations: Array<TTravelActivityTicketTouristConfiguration>,
	noOfAdults: number,
	children: Array<{ age: number; count: number }>
): Array<{
	quantity: number
	configuration: TTravelActivityTicketTouristConfiguration
}> {
	const configurations: Array<{
		quantity: number
		configuration: TTravelActivityTicketTouristConfiguration
	}> = []

	const availableConfigurationForAdults = availableConfigurations.find(
		(t) => t.tourist_type.toLowerCase() === "adult"
	)

	const availableConfigurationsForChildren = availableConfigurations.filter(
		(t) => t.tourist_type.toLowerCase() === "child"
	)

	const availableConfigurationsForGroup = availableConfigurations.filter(
		(t) => t.tourist_type.toLowerCase() === "group"
	)

	const paxAllocations = (
		[] as Array<{
			type: "adult" | "child"
			count: number
			age?: number
			configuration?: TTravelActivityTicketTouristConfiguration
			is_complimentary?: boolean
		}>
	)
		.concat(
			Array.from({ length: noOfAdults }).map(() => ({
				type: "adult",
				count: 1,
				age: undefined,
			}))
		)
		.concat(
			children.map((c) => ({
				...c,
				type: "child",
			}))
		)

	if (availableConfigurationForAdults && noOfAdults) {
		configurations.push({
			quantity: noOfAdults,
			configuration: availableConfigurationForAdults,
		})
		paxAllocations.forEach((p) => {
			if (p.type === "adult") {
				p.configuration = availableConfigurationForAdults
			}
		})
	}

	if (children.length && availableConfigurationsForChildren.length) {
		configurations.push(
			...paxAllocations
				.filter((p): p is Required<typeof p, "age"> => p.type === "child")
				.map((child) => {
					const { age, count } = child
					const configuration = availableConfigurationsForChildren.find(
						({ age_start, age_end }) =>
							age_start !== null &&
							age_end !== null &&
							age_start <= age &&
							age_end >= age
					)
					if (configuration) {
						// update the configuration
						child.configuration = configuration
					}
					return { quantity: count, configuration }
				})
				.reduce<
					Array<{
						quantity: number
						configuration: TTravelActivityTicketTouristConfiguration
					}>
				>((configurations, { configuration, quantity }) => {
					if (configuration) {
						const index = configurations.findIndex(
							(t) => t.configuration.name === configuration.name
						)
						if (index < 0) {
							configurations.push({ quantity, configuration: configuration })
						} else {
							configurations[index].quantity =
								configurations[index].quantity + quantity
						}
					}
					return configurations
				}, [])
		)

		// allocate the remaining children if possible
		if (availableConfigurationsForChildren.length) {
			paxAllocations
				.filter((p) => p.type === "child" && !p.configuration)
				.forEach((child) => {
					const { age } = child
					const isComplimentary = !availableConfigurationsForChildren.find(
						({ age_start }) => age_start !== null && age && age >= age_start
					)
					child.is_complimentary = isComplimentary
				})
		}
	}

	const remainingPaxAllocation = paxAllocations.filter(
		(p) => !p.configuration && !p.is_complimentary
	)

	if (remainingPaxAllocation.length && availableConfigurationsForGroup.length) {
		// Select the first config
		const groupConfig = availableConfigurationsForGroup[0]
		let groups = 0 // total number of groups
		let numberOfPaxAllocated = 0 // number of pax allocated
		remainingPaxAllocation.forEach((p) => {
			p.configuration = groupConfig
			if (!p.age || !groupConfig.age_start || p.age >= groupConfig.age_start) {
				numberOfPaxAllocated++
			}
			if (p.age && groupConfig.age_start && p.age < groupConfig.age_start) {
				// complimentary
				p.is_complimentary = true
			}
			if (numberOfPaxAllocated === groupConfig.quantity) {
				groups++
				numberOfPaxAllocated = 0
			}
		})
		// if some quantity is remaining, create a new group
		if (numberOfPaxAllocated) {
			groups++
		}
		configurations.push({
			quantity: groups,
			configuration: groupConfig,
		})
	}
	const remainingChildAllocation = paxAllocations.filter(
		(p) => !p.configuration && !p.is_complimentary && p.type === "child"
	)
	if (remainingChildAllocation.length) {
		// try to allocate the child into the adults tickets if available
		if (availableConfigurationForAdults) {
			remainingChildAllocation.forEach((a) => {
				a.configuration = availableConfigurationForAdults
			})
			const existingAdultConfiguration = configurations.find(
				(c) => c.configuration.id === availableConfigurationForAdults.id
			)
			if (existingAdultConfiguration) {
				existingAdultConfiguration.quantity =
					existingAdultConfiguration.quantity + remainingChildAllocation.length
			} else {
				configurations.push({
					quantity: remainingChildAllocation.length,
					configuration: availableConfigurationForAdults,
				})
			}
		}
	}

	return configurations
}
