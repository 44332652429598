import { ICursorListResponse, XHRInstance } from "@sembark-travel/xhr"
import { IUser } from "../Auth/store"

export interface IActivityLog {
	id: number
	name: "quote_created" | "quote_given" | "trip_stage_change" | string
	description: string
	causer?: IUser
	subject?: unknown
	properties?: { [key: string]: unknown }
	created_at: string
	ip_address?: string
	user_agent?: {
		platform?: string
		browser?: string
		browser_version?: string
		is_desktop?: boolean
	}
}

export function activityLogsXHR(xhr: XHRInstance) {
	return {
		async get(params: unknown) {
			return xhr
				.get<ICursorListResponse<IActivityLog>>(`/activity-logs`, { params })
				.then((resp) => resp.data)
		},
	}
}
