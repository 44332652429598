import { Navigate, useParams } from "./../../../../../router-utils"

export default function TripAccountingIndexPage() {
	const { tripId } = useParams("/trips/:tripId/accounting")
	return (
		<Navigate
			to="/trips/:tripId/accounting/payments"
			replace
			params={{ tripId }}
		/>
	)
}
