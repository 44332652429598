import {
	Box,
	Container,
	Heading,
	Stack,
	Text,
	Inline,
	Button,
	Component,
	Icons,
	SwitchInput,
} from "@sembark-travel/ui/base"
import { ChangePasswordForm } from "./ChangePassword"
import { MultiFactorAuths } from "./MFAs"
import { AuthSessionsList } from "./AuthSessions"
import { Dialog } from "@sembark-travel/ui/dialog"
import { useAuthUser } from "../Auth"
import { useXHR } from "@sembark-travel/xhr"

export function SecuritySettings() {
	const { user, fetchUser } = useAuthUser()
	const xhr = useXHR()
	if (!user) return null
	return (
		<Stack gap="8" paddingY="8">
			<Container>
				<Stack gap="2">
					<Inline justifyContent="between" gap="4" alignItems="center">
						<Heading as="h4">Password</Heading>
						<Component initialState={false}>
							{({ state, setState }) => (
								<form>
									<Inline gap="2" as="label">
										<SwitchInput
											name="can_password_login"
											disabled={state}
											checked={!user.password_login_disabled_at}
											onChange={async (checked) => {
												if (
													!window.confirm(
														`Are you sure you want to ${
															checked ? "Enabled" : "Disable"
														} login via Password.${
															!checked
																? "\n\nOnly Admins will be able to enable it back."
																: ""
														}`
													)
												) {
													return
												}
												setState(true)
												try {
													await xhr.patch(
														`/users/${user.id}/toggle-password-login`
													)
													fetchUser(true)
												} catch (e) {
													const error = e as Error
													alert(
														error.message ||
															"Something went wrong. Please try after sometime."
													)
												}
												setState(false)
											}}
										/>
										<Text as="span">
											Password Login:{" "}
											{user.password_login_disabled_at ? "Disabled" : "Enabled"}
										</Text>
									</Inline>
								</form>
							)}
						</Component>
					</Inline>
					<Inline
						gap="4"
						padding="4"
						alignItems="center"
						borderWidth="1"
						rounded="md"
						bgColor="default"
					>
						<Text color="muted">Current Password</Text>
						<Text>***************</Text>
						{!user.password_login_disabled_at ? (
							<Component initialState={false}>
								{({ state, setState }) => (
									<>
										<Button
											onClick={() => setState(true)}
											size="sm"
											level="tertiary"
										>
											<Icons.Pencil />
										</Button>
										<Dialog
											sm
											open={state}
											onClose={() => setState(false)}
											title="Change Password"
										>
											<Dialog.Body>
												<ChangePasswordForm onSuccess={() => setState(false)} />
											</Dialog.Body>
										</Dialog>
									</>
								)}
							</Component>
						) : null}
					</Inline>
				</Stack>
			</Container>
			<Box>
				<MultiFactorAuths />
			</Box>
			<Container>
				<Stack gap="2">
					<Heading as="h4">Active Sessions and Devices</Heading>
					<Text color="muted">
						Manage your active sessions on other browsers and devices.
					</Text>
					<Box>
						<AuthSessionsList />
					</Box>
				</Stack>
			</Container>
		</Stack>
	)
}
