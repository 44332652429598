import { Box, Text, Stack, Container } from "@sembark-travel/ui/base"
import {
	useGetBackUrlFromLocation,
	Breadcrumbs,
} from "@sembark-travel/ui/router"
import { Helmet } from "react-helmet-async"
import { InviteTenantToConnect } from "../../../ConnectionRequests"
import { generatePath, useNavigate } from "../../../router-utils"

export default function InviteTenantToConnectPage() {
	const navigate = useNavigate()
	const backFromUrl = useGetBackUrlFromLocation()
	const listingUrl = generatePath("/org/connections")
	const signupUrl =
		(window.location?.origin || "") + generatePath("/connection-requests/new")
	return (
		<>
			<Helmet>
				<title>Invite B2B Connection</title>
			</Helmet>
			<Breadcrumbs
				title="Invite Business"
				items={[
					[listingUrl, "Connections"],
					["", "Invite"],
				]}
			/>
			<Box paddingX="4" paddingY="8">
				<Stack gap="4" maxWidth="4xl" marginX="auto">
					<Text color="muted">
						You can invite your connection on Sembark to connect and grow your
						business by sharing quotes, streamline accounting and more.
					</Text>
					<Box borderWidth="1" rounded="md" bgColor="default">
						<Container paddingY="6">
							<InviteTenantToConnect
								signupUrl={signupUrl}
								onSuccess={() => navigate("/org/connections")}
								onCancel={() =>
									backFromUrl
										? navigate(backFromUrl as "/org/connections")
										: navigate("/org/connections")
								}
							/>
						</Container>
					</Box>
				</Stack>
			</Box>
		</>
	)
}
