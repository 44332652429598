import { isSame, isAfter } from "@sembark-travel/datetime-utils"

export function sortInstalmentsByDueDate<
	T extends { due_at: string | Date } = { due_at: string | Date },
>(instalments: Array<T>): Array<T> {
	return instalments.concat([]).sort((a, b) => {
		return isSame(a.due_at, b.due_at, "day")
			? 0
			: isAfter(a.due_at, b.due_at)
			? 1
			: -1
	})
}
