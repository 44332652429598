import { RedirectUnlessAuthenticated, useHasFeatureFlag } from "../../Auth"
import {
	Box,
	Text,
	Container,
	MainContent,
	Alert,
	Button,
	Heading,
	Stack,
	Inline,
	Icons,
	Divider,
	Dropdown,
	RelativeTime,
	Popover,
	joinAttributes,
	Badge,
} from "@sembark-travel/ui/base"
import { Link, NavLink } from "@sembark-travel/ui/router"
import { Dialog } from "@sembark-travel/ui/dialog"
import { ErrorBoundary } from "@sembark-travel/ui/error-boundary"
import { trackEvent, TrackingEvents } from "@sembark-travel/tracking"
import { useCallback, useEffect, useMemo, useRef, useState } from "react"
import config from "./../../config"
import { useLocation, Outlet, useNavigate } from "react-router-dom"
import { dateToUTCString } from "@sembark-travel/datetime-utils"
import { useXHR } from "@sembark-travel/xhr"
import {
	FeatureFlag,
	PERMISSIONS,
	useAuthUser,
	useCheckPermissions,
} from "../../Auth"
import { GlobalSearch } from "../../GlobalSearch"
import Logo from "../../images/Logo"
import {
	INotification,
	Notification,
	notificationsXHR,
} from "./../../Notifications"
import {
	AppUpdater,
	ShouldHighlightLatestReleaseNotesAction,
} from "./../../AppInstaller"
import useSWR from "swr"
import pluralize from "pluralize"
import { showSnackbar } from "@sembark-travel/ui/snackbar"
import { generatePath } from "./../../router-utils"
import { useDemandingCommentsCount } from "../../Comments"

export default function Layout() {
	return (
		<RedirectUnlessAuthenticated>
			<RootLayout />
		</RedirectUnlessAuthenticated>
	)
}

function RootLayout() {
	const location = useLocation()
	return (
		<>
			{location.pathname.startsWith("/onboarding") ? (
				<Box height="12">&nbsp;</Box>
			) : (
				<Header />
			)}
			<MainContent>
				<ErrorBoundary>
					<Outlet />
				</ErrorBoundary>
			</MainContent>
		</>
	)
}

function TimezoneMismatchError() {
	const { user } = useAuthUser()
	const tenant = user?.tenant
	const timezone_offset = tenant?.timezone_offset
	const hasMultiTimezoneUsageEnable = useHasFeatureFlag("multi_timezone")
	const [deviceTimezoneOffset, setDeviceTimezoneOffset] = useState(() =>
		new Date().getTimezoneOffset()
	)
	useEffect(() => {
		const interval = setInterval(
			() => setDeviceTimezoneOffset(new Date().getTimezoneOffset()),
			5000
		)
		return () => clearInterval(interval)
	}, [])
	const deviceTenantTimezoneMismatch = useMemo(() => {
		return Boolean(
			tenant && deviceTimezoneOffset !== -1 * tenant.timezone_offset
		)
	}, [tenant, deviceTimezoneOffset])

	useEffect(() => {
		if (deviceTenantTimezoneMismatch) {
			trackEvent(TrackingEvents.incorrect_timezone, {
				user_timezone_offset: -1 * deviceTimezoneOffset,
				tenant_timezone_offset: timezone_offset || 0,
			})
		}
	}, [timezone_offset, deviceTimezoneOffset, deviceTenantTimezoneMismatch])
	return tenant ? (
		<Dialog
			title="Incorrect Timezone"
			open={deviceTenantTimezoneMismatch && !hasMultiTimezoneUsageEnable}
			onClose={() => {
				window.alert(
					"Please fix the Timezone settings and refresh the page to close this warning."
				)
			}}
		>
			<Dialog.Body>
				<Alert status="error">
					<Stack gap="2">
						<Text>
							Your device/system Timezone settings seems incorrect with your
							Organization Timezone settings. Incorrect Timezone leads to{" "}
							<b>data corruption and inconsistencies</b> in the Organization.
						</Text>
						<Inline gap="8" color="default">
							<Stack gap="1">
								<Heading fontSize="md">Expected</Heading>
								<Text fontSize="lg" color="success" fontWeight="semibold">
									GMT{tenant.timezone_offset_string}
								</Text>
								<Text>{tenant.timezone}</Text>
							</Stack>
							<Stack gap="1">
								<Heading fontSize="md">Your Device/System</Heading>
								<Text fontSize="lg" color="danger" fontWeight="semibold">
									GMT{deviceTimezoneOffset > 0 ? "-" : "+"}
									{dateToUTCString(
										new Date(
											Date.UTC(0, 0, 0, 0, Math.abs(deviceTimezoneOffset))
										),
										"HH:mm"
									)}
								</Text>
							</Stack>
						</Inline>
						<Text fontSize="md">
							Please fix the Timezone Settings before using the software
							application.
						</Text>
					</Stack>
				</Alert>
				<Divider sm />
				<Stack gap="4">
					<Stack gap="1">
						<Heading fontSize="md">Timezone Fix Guidelines</Heading>
						<Text color="muted">
							Here are some guidelines and references to fix Timezone settings
							on your device.
						</Text>
					</Stack>
					<Text color="accent" fontWeight="semibold">
						{joinAttributes(
							<a
								href="https://support.microsoft.com/en-us/windows/how-to-set-your-time-and-time-zone-dfaa7122-479f-5b98-2a7b-fa0b6e01b261"
								target="_blank"
								rel="noopener noreferrer"
							>
								Windows <Icons.ArrowTopRightOnSquare />
							</a>,
							<a
								href="https://support.apple.com/en-in/guide/mac-help/mchlp2996/mac"
								target="_blank"
								rel="noopener noreferrer"
							>
								macOS <Icons.ArrowTopRightOnSquare />
							</a>,
							<a
								href="https://support.google.com/android/answer/2841106"
								target="_blank"
								rel="noopener noreferrer"
							>
								Android <Icons.ArrowTopRightOnSquare />
							</a>,
							<a
								href="https://support.apple.com/en-in/HT203483"
								target="_blank"
								rel="noopener noreferrer"
							>
								iPhone or iPad <Icons.ArrowTopRightOnSquare />
							</a>
						)}
					</Text>
				</Stack>
				<Divider sm />
				<Text>
					If you have tried and are unable to fix, please contact our support.
				</Text>
			</Dialog.Body>
		</Dialog>
	) : null
}

function Header() {
	const { user } = useAuthUser()
	const { hasPermission, hasAnyPermission } = useCheckPermissions()
	const location = useLocation()
	const tenant = user?.tenant
	if (!user) return null
	const { name, profile_thumb_image_url } = user
	return (
		<Box as="header" position="relative" zIndex="50" borderBottomWidth="1">
			<TimezoneMismatchError />
			{hasPermission(PERMISSIONS.MANAGE_USERS) && tenant?.is_in_trial ? (
				<Box height="1" bgColor="warning"></Box>
			) : null}
			<AppUpdater>
				{({
					updateApp,
					isUpdateAvailable,
					updateRequiredWithMinimumVersion,
					isUpdating,
					isDownloadingUpdate,
				}) =>
					isUpdateAvailable ? (
						<Box paddingX="4" paddingY="2" bgColor="warning">
							<Inline gap="2" alignItems="center" justifyContent="center">
								<Box>
									<Icons.Info size="6" color="warning" />
								</Box>
								<Inline
									collapseBelow="sm"
									gap="2"
									alignItems={{ sm: "center" }}
								>
									<Box>
										<Text fontSize="md" fontWeight="semibold" color="warning">
											An update is available to your Sembark Software.
										</Text>
									</Box>
									<Box>
										<Button
											level="primary"
											status="warning"
											onClick={() => updateApp()}
											size="sm"
											disabled={isUpdating}
										>
											{isUpdating ? "Updating..." : "Update Now"}
										</Button>
									</Box>
								</Inline>
							</Inline>
						</Box>
					) : updateRequiredWithMinimumVersion ? (
						<Box paddingX="4" paddingY="2" bgColor="danger">
							<Inline gap="4" alignItems="center" justifyContent="center">
								<Box>
									<Icons.Attention size="8" color="danger" />
								</Box>
								<Inline
									collapseBelow="sm"
									gap={{ xs: "2", sm: "4" }}
									alignItems={{ sm: "center" }}
								>
									<Stack gap="1">
										<Text fontSize="md" fontWeight="semibold" color="danger">
											Your software version "{config.appVersion}" is outdated.
											Please update to &gt;= {updateRequiredWithMinimumVersion}.
										</Text>
										<Text fontSize="sm">
											To update, please close all tabs and open sembark in new
											tab. You will receive notification to update the app.
										</Text>
									</Stack>
									<Box>
										<Button
											level="primary"
											status="danger"
											onClick={() => {
												updateApp()
											}}
											size="sm"
											disabled={isDownloadingUpdate || isUpdating}
										>
											{isDownloadingUpdate ? "Downloading..." : "Download Now"}
										</Button>
									</Box>
								</Inline>
							</Inline>
						</Box>
					) : null
				}
			</AppUpdater>
			<Box
				bgColor="black"
				color="white"
				className="app-header"
				position="relative"
				zIndex="10"
			>
				<Container fluid>
					<Inline as="nav" className="nav" alignItems="stretch">
						<Box display="inlineFlex" alignItems="center">
							<Box
								as={Link}
								to={generatePath("/")}
								display="inlineFlex"
								alignItems="center"
								style={{ color: "inherit", height: "3.5rem" }}
							>
								<Logo width="32" height="32" />
							</Box>
						</Box>
						<Inline
							flex="1"
							as="ul"
							alignItems="center"
							width="full"
							marginLeft={{ sm: "4" }}
							justifyContent={{ xs: "end", sm: "start" }}
						>
							{!hasAnyPermission(
								PERMISSIONS.VIEW_TENANTS,
								PERMISSIONS.MANAGE_TENANTS
							) ? (
								<>
									{hasAnyPermission(
										PERMISSIONS.VIEW_QUERIES,
										PERMISSIONS.VIEW_TRIP_SALES_REPORTS
									) ? (
										<Dropdown as="li">
											{hasPermission(PERMISSIONS.VIEW_QUERIES) ? (
												<Link
													to={generatePath("/trips")}
													display="inlineBlock"
													data-testid="top_nav_trips_list"
												>
													Trips
												</Link>
											) : (
												<Link
													to={generatePath("/trip-sales-reports")}
													display="inlineBlock"
												>
													Trips
												</Link>
											)}
											<Dropdown.Menu>
												{hasPermission(PERMISSIONS.VIEW_QUERIES) ? (
													<>
														<NavLink
															to={generatePath("/trips")}
															data-testid="top_nav_dropdown_trips_list"
														>
															Trips
														</NavLink>
														{hasPermission(
															PERMISSIONS.VIEW_TENANT_CONNECTIONS
														) ? (
															<NavLink to={generatePath("/quote-requests")}>
																Quote Requests
															</NavLink>
														) : null}
													</>
												) : null}
												{hasPermission(PERMISSIONS.VIEW_TRIP_SALES_REPORTS) ? (
													<NavLink to={generatePath("/trip-sales-reports")}>
														Sales Reports
													</NavLink>
												) : null}
												{hasAnyPermission(
													PERMISSIONS.VIEW_TRIP_PLAN_REQUESTS,
													PERMISSIONS.MANAGE_TRIP_PLAN_REQUESTS
												) ? (
													<NavLink to={generatePath("/trip-plan-requests")}>
														Trip Plan Requests
													</NavLink>
												) : null}
												{hasPermission(PERMISSIONS.VIEW_QUERIES) &&
												!hasAnyPermission(
													PERMISSIONS.VIEW_CAB_SCHEDULES,
													PERMISSIONS.VIEW_HOTEL_BOOKINGS
												) ? (
													<NavLink
														to={generatePath("/trip-quote-bookings-diff")}
													>
														Quote Bookings Diff
													</NavLink>
												) : null}
											</Dropdown.Menu>
										</Dropdown>
									) : null}
									{hasAnyPermission(
										PERMISSIONS.VIEW_HOTEL_BOOKINGS,
										PERMISSIONS.VIEW_CAB_SCHEDULES
									) ? (
										<Dropdown as="li">
											<Link
												to={
													hasPermission(PERMISSIONS.VIEW_HOTEL_BOOKINGS)
														? generatePath("/hotel-bookings")
														: generatePath("/cab-schedules/calendar")
												}
												className="inline-block"
											>
												<Box as="span" display={{ xs: "none", sm: "inline" }}>
													Bookings
												</Box>
												<Box as="span" display={{ xs: "inline", sm: "none" }}>
													<Icons.Calendar />
												</Box>
											</Link>
											<Dropdown.Menu>
												{hasPermission(PERMISSIONS.VIEW_HOTEL_BOOKINGS) ? (
													<NavLink to={generatePath("/hotel-bookings")}>
														Hotel Bookings
													</NavLink>
												) : null}
												{hasPermission(PERMISSIONS.VIEW_CAB_SCHEDULES) ? (
													<NavLink
														to={generatePath("/operational-bookings/calendar")}
													>
														Operational Bookings
													</NavLink>
												) : null}
												{hasAnyPermission(
													PERMISSIONS.VIEW_CAB_SCHEDULES,
													PERMISSIONS.VIEW_HOTEL_BOOKINGS
												) ? (
													<NavLink
														to={generatePath("/trip-quote-bookings-diff")}
													>
														Quote Bookings Diff
													</NavLink>
												) : null}
											</Dropdown.Menu>
										</Dropdown>
									) : null}
									{hasAnyPermission(
										PERMISSIONS.MANAGE_ACCOUNTING,
										PERMISSIONS.VIEW_INCOMING_PAYMENTS,
										PERMISSIONS.VIEW_OUTGOING_PAYMENTS
									) ? (
										<Dropdown as="li">
											<Link to={generatePath("/payments/incoming")}>
												<Box as="span" display={{ xs: "none", sm: "inline" }}>
													Accounting
												</Box>
												<Box as="span" display={{ xs: "inline", sm: "none" }}>
													<Icons.BankNotes />
												</Box>
											</Link>
											<Dropdown.Menu>
												{hasPermission(PERMISSIONS.VIEW_INCOMING_PAYMENTS) ? (
													<NavLink to={generatePath("/payments/incoming")}>
														Incoming Payments
													</NavLink>
												) : null}
												{hasPermission(PERMISSIONS.VIEW_OUTGOING_PAYMENTS) ? (
													<NavLink to={generatePath("/payments/outgoing")}>
														Outgoing Payments
													</NavLink>
												) : null}
												{hasPermission(
													PERMISSIONS.VIEW_TRIP_CHECKOUT_REPORTS
												) ? (
													<NavLink to={generatePath("/trip-checkout-reports")}>
														Trip Checkout Report
													</NavLink>
												) : null}
												{hasPermission(PERMISSIONS.MANAGE_ACCOUNTING) ? (
													<>
														<Divider sm />
														<NavLink to={generatePath("/accounting/accounts")}>
															Accounts
														</NavLink>
														<NavLink
															to={generatePath("/accounting/transactions")}
														>
															Transactions
														</NavLink>
														{/* hasPermission(
                              PERMISSIONS.VIEW_BANK_ACCOUNT_STATEMENTS
                            ) ? (
                              <NavLink
                                to={generatePath("/accounting/bank-statements")}
                              >
                                Bank Statements
                              </NavLink>
                            ) : null */}
													</>
												) : null}
											</Dropdown.Menu>
										</Dropdown>
									) : null}
									<TodaysPendingFollowups />
									<Box
										as="li"
										paddingRight="4"
										flex="1"
										width="full"
										display={{ xs: "none", sm: "flex" }}
										alignItems="center"
										justifyContent="end"
									>
										<Box maxWidth="lg" width="full">
											<GlobalSearch />
										</Box>
									</Box>
									{hasPermission(PERMISSIONS.VIEW_HOTELS) ? (
										<Dropdown as="li" alignRight>
											<Link to={generatePath("/hotels")}>
												<Box as="span" display={{ xs: "none", sm: "inline" }}>
													Hotels
												</Box>
												<Box as="span" display={{ xs: "inline", sm: "none" }}>
													<Icons.Bed title="Hotels" />
												</Box>
											</Link>
											<Dropdown.Menu>
												<NavLink to={generatePath("/hotels")}>Hotels</NavLink>
												<NavLink to={generatePath("/hotel-prices")}>
													Hotel Prices
												</NavLink>
											</Dropdown.Menu>
										</Dropdown>
									) : null}
									{hasAnyPermission(
										PERMISSIONS.VIEW_TRANSPORT_SERVICES,
										PERMISSIONS.VIEW_TRANSPORT_SERVICE_PROVIDERS
									) ? (
										<Dropdown as="li" alignRight>
											<Link to={generatePath("/transport-services")}>
												<Box as="span" display={{ xs: "none", sm: "inline" }}>
													Transport
												</Box>
												<Box as="span" display={{ xs: "inline", sm: "none" }}>
													<Icons.Bus title="Transport" />
												</Box>
											</Link>
											<Dropdown.Menu>
												{hasPermission(PERMISSIONS.VIEW_TRANSPORT_SERVICES) ? (
													<>
														<NavLink to={generatePath("/transport-services")}>
															Transport Services
														</NavLink>
														<NavLink
															to={generatePath("/transport-service-prices")}
														>
															Transport Service Prices
														</NavLink>
													</>
												) : null}
												<FeatureFlag flag="travel_activities">
													<Dropdown.MenuItemDivider />
													<NavLink to={generatePath("/travel-activities")}>
														Travel Activities
													</NavLink>
													<NavLink to={generatePath("/travel-activity-prices")}>
														Travel Activity Prices
													</NavLink>
												</FeatureFlag>
												<Dropdown.MenuItemDivider />
												{hasPermission(
													PERMISSIONS.VIEW_TRANSPORT_SERVICE_PROVIDERS
												) ? (
													<>
														<NavLink
															to={generatePath("/transport-service-providers")}
														>
															Transport &amp; Activity Suppliers
														</NavLink>
													</>
												) : null}
											</Dropdown.Menu>
										</Dropdown>
									) : null}
								</>
							) : (
								<>
									<Box as="li" flex="1">
										<Box as="ul" display={"flex"}>
											<Dropdown as="li" display="inlineBlock">
												<Link to={generatePath("/admin/tenants")}>Tenants</Link>
												<Dropdown.Menu>
													<NavLink to={generatePath("/admin/tenants")}>
														Tenants
													</NavLink>
													{hasPermission(PERMISSIONS.MANAGE_TENANTS) ? (
														<>
															<NavLink
																to={generatePath("/admin/bank-accounts")}
															>
																Bank Accounts
															</NavLink>
															<NavLink
																to={generatePath("/admin/webhook-client-calls")}
															>
																Webhook Client Calls
															</NavLink>
														</>
													) : null}
												</Dropdown.Menu>
											</Dropdown>
											<TodaysPendingFollowups />
										</Box>
									</Box>

									{hasPermission(PERMISSIONS.MANAGE_TENANTS) ? (
										<Box as="li">
											<Box as="ul">
												<Dropdown as="li" display="inlineBlock">
													<Link to={generatePath("/admin/world/countries")}>
														World
													</Link>
													<Dropdown.Menu>
														<NavLink
															to={generatePath("/admin/world/countries")}
														>
															Countries
														</NavLink>
														<NavLink to={generatePath("/admin/world/airports")}>
															Airports
														</NavLink>
														<NavLink
															to={generatePath("/admin/world/timezones")}
														>
															Timezones
														</NavLink>
														<NavLink to={generatePath("/admin/world/banks")}>
															Banks
														</NavLink>
													</Dropdown.Menu>
												</Dropdown>
											</Box>
										</Box>
									) : null}
								</>
							)}
							<NotificationList />
							<ShouldHighlightLatestReleaseNotesAction>
								{({ shouldHighlight }) =>
									shouldHighlight ? (
										<NavLink
											to={generatePath("/latest-release-notes")}
											title="Checkout Latest Release Notes"
											display={{ xs: "none", md: "inlineBlock" }}
										>
											<Box>
												<Badge warning>What's New</Badge>
											</Box>
										</NavLink>
									) : null
								}
							</ShouldHighlightLatestReleaseNotesAction>
							<NavLink
								to={generatePath("/dashboard")}
								title="Dashboard"
								display={{ xs: "none", md: "inlineBlock" }}
							>
								<Icons.Home />
							</NavLink>
							<Dropdown as="li" display="inlineBlock" alignRight>
								<a
									className="toggler"
									href="#profile-and-settings"
									style={{ paddingRight: "0" }}
								>
									{profile_thumb_image_url ? (
										<Box
											as="img"
											size="8"
											src={profile_thumb_image_url}
											alt="Avatar"
											borderWidth="1"
											borderColor="default"
											rounded="md"
										/>
									) : (
										<Icons.User title={`Hi ${name}`} />
									)}
								</a>
								<Dropdown.Menu>
									{hasPermission(PERMISSIONS.MANAGE_ROLES) ? (
										<NavLink to={generatePath("/roles")}>
											<Icons.Info /> Roles
										</NavLink>
									) : null}
									<NavLink to={generatePath("/settings")}>
										<Icons.Cog /> My Profile
									</NavLink>
									{!tenant && hasPermission(PERMISSIONS.MANAGE_TENANTS) ? (
										<NavLink to={generatePath("/admin/users")}>
											<Icons.UserGroup /> Users
										</NavLink>
									) : null}
									{tenant &&
									hasAnyPermission(
										PERMISSIONS.MANAGE_ACCOUNTING,
										PERMISSIONS.VIEW_QUERIES
									) ? (
										<NavLink to={generatePath("/org")}>
											<Icons.UserGroup /> Organization
										</NavLink>
									) : null}
									<Dropdown.MenuItemDivider />
									<NavLink to={generatePath("/dashboard")}>
										<Icons.Home /> Dashboard
									</NavLink>
									<NavLink to={generatePath("/follow-ups")}>
										<Icons.RectangleStack /> Follow Ups
									</NavLink>
									<NavLink to={generatePath("/notifications")}>
										<Icons.Bell /> Notifications
									</NavLink>
									<NavLink
										to={generatePath("/latest-release-notes")}
										color="warning"
									>
										<Icons.SparklesSolid /> What's New
									</NavLink>
									<Dropdown.MenuItemDivider />
									<NavLink
										to={`${generatePath("/logout")}?from=${encodeURIComponent(
											location.pathname + location.search
										)}`}
									>
										<Icons.Logout /> Logout
									</NavLink>
								</Dropdown.Menu>
							</Dropdown>
						</Inline>
					</Inline>
				</Container>
			</Box>
		</Box>
	)
}

function TodaysPendingFollowups() {
	const { data } = useDemandingCommentsCount(new Date(), new Date())
	if (!data || !data.data) {
		return null
	}
	return (
		<NavLink
			to={generatePath("/follow-ups")}
			title="Today's Pending Follow Ups"
		>
			<Badge>
				<Icons.RectangleStack /> {data.data}
			</Badge>
		</NavLink>
	)
}

function NotificationList() {
	const { user } = useAuthUser()
	const xhr = useXHR()
	const { data, mutate: revalidateNotifications } = useSWR(
		`api/notifications/app`,
		() => notificationsXHR(xhr).getNotifications({ unread: 1 })
	)

	const { data: unseenCount, mutate: revalidateUnseenCount } = useSWR(
		`api/notifications/unseen-count`,
		() => notificationsXHR(xhr).getUnseenCount(),
		{
			focusThrottleInterval: 300000, // 5 minutes
			revalidateOnFocus: true,
		}
	)

	const lastUnseenCount = useRef<number | undefined>(unseenCount || undefined)

	const navigate = useNavigate()

	const notifications = data?.data
	const meta = data?.meta
	const markAsRead = useCallback(
		async (
			notifications: INotification | Array<INotification>,
			shouldMarkAll = false
		) => {
			let toBeMarkedNotifications: Array<INotification> = []
			if (!Array.isArray(notifications)) {
				toBeMarkedNotifications = [notifications]
			} else {
				toBeMarkedNotifications = notifications
			}
			if (data) {
				await revalidateNotifications(async () => {
					const { data: notifications, ...otherData } = data
					const updatedNotifications = notifications.map((n) => {
						if (toBeMarkedNotifications.indexOf(n) !== -1) {
							return {
								...n,
								read_at: dateToUTCString(new Date()),
							}
						}
						return n
					})
					return { data: updatedNotifications, ...otherData }
				}, false)
			}
			await notificationsXHR(xhr).markAsRead(
				toBeMarkedNotifications,
				shouldMarkAll
			)
		},
		[xhr, revalidateNotifications, data]
	)
	const markAllAsRead = useCallback(async () => {
		return markAsRead(data?.data || [], true)
	}, [markAsRead, data])
	const markAllAsSeen = useCallback(async () => {
		if (unseenCount) {
			notificationsXHR(xhr)
				.markAllAsSeen()
				.then(() => revalidateUnseenCount())
		}
	}, [xhr, unseenCount, revalidateUnseenCount])

	useEffect(() => {
		if (lastUnseenCount.current !== undefined && unseenCount) {
			// values have been set and there are some new notifications
			if (lastUnseenCount.current !== unseenCount) {
				showSnackbar(
					`You have ${unseenCount} unseen ${pluralize(
						"notification",
						unseenCount
					)}`,
					"View",
					{
						id: "notifications/unseen-count",
						onClick: () => {
							markAllAsSeen()
							navigate("/notifications")
						},
						timeout: 10000,
					}
				)

				// also revalidate the unread notifications
				revalidateNotifications()
			}
		}
		lastUnseenCount.current = unseenCount
	}, [unseenCount, navigate, markAllAsSeen, revalidateNotifications])
	if (!user) return null
	return (
		<Box as="li">
			<Popover
				placement="bottom-end"
				trigger="click"
				content={
					<>
						<Box
							style={{
								maxHeight: "40vh",
								minWidth: "250px",
								overflow: "auto",
								padding: "0",
							}}
							rounded="md"
							position="relative"
						>
							<Inline
								as="header"
								paddingX="3"
								paddingY="2"
								fontSize="sm"
								borderBottomWidth="1"
								justifyContent="between"
								position="sticky"
								top="0"
								zIndex="10"
								bgColor="default"
								roundedTop="md"
								boxShadow="base"
							>
								<Link to={generatePath("/notifications")} color="accent">
									<Heading as="h5" fontSize="base">
										Notifications <Icons.ChevronDown rotate="270" />
									</Heading>
								</Link>
								{meta?.total ? (
									<Button inline onClick={markAllAsRead}>
										Mark All Read
									</Button>
								) : null}
							</Inline>
							{!notifications?.length ? (
								<Box key="empty-list" borderTopWidth="1" paddingY="4">
									<Text textAlign="center" color="muted">
										All caught up!
									</Text>
								</Box>
							) : (
								notifications.map((n) => (
									<Box key={n.id} borderTopWidth="1">
										<Stack
											padding="3"
											bgColor={!n.read_at ? "inset" : undefined}
											gap="1"
										>
											<Box onClick={() => markAsRead(n)}>
												<Notification notification={n} />
											</Box>
											<Box fontSize="sm" color="muted">
												<RelativeTime timestamp={n.created_at} /> •{" "}
												{n.read_at ? (
													<Box display="inline">Read</Box>
												) : (
													<Button
														inline
														level="tertiary"
														onClick={() => markAsRead(n)}
													>
														<Icons.Ok title="Mark as Read" />
													</Button>
												)}
											</Box>
										</Stack>
									</Box>
								))
							)}
							<Box
								key="all"
								borderTopWidth="1"
								color="accent"
								fontWeight="semibold"
								paddingX="4"
								paddingY="2"
							>
								<Link to={generatePath("/notifications")}>
									View All Notifications <Icons.ChevronDown rotate="270" />
								</Link>
							</Box>
						</Box>
					</>
				}
			>
				<a
					href="#view-notifications"
					onClick={() => {
						markAllAsSeen()
					}}
					onMouseEnter={() => {
						markAllAsSeen()
					}}
				>
					{unseenCount ? (
						<Box
							position="relative"
							title={`${unseenCount} New ${pluralize(
								"Notification",
								unseenCount
							)}`}
						>
							<Box>
								<Icons.BellAlertSolid />
							</Box>
							<Box
								position="absolute"
								width="2"
								height="2"
								bgColor="danger_emphasis"
								rounded="full"
								overflow="hidden"
								style={{
									left: "95%",
									bottom: "70%",
								}}
							>
								&nbsp;
							</Box>
						</Box>
					) : (
						<Box>
							<Icons.Bell />
						</Box>
					)}
				</a>
			</Popover>
		</Box>
	)
}
