import {
	init as initSentry,
	captureMessage,
	captureException,
	globalHandlersIntegration,
	breadcrumbsIntegration,
	getCurrentScope,
} from "@sentry/browser"

const loggerConfig = {
	env: "development",
	dsn: "",
	appName: "",
	appVersion: "",
}

export function configureLogger(config: typeof loggerConfig) {
	loggerConfig.env = config.env
	loggerConfig.dsn = config.dsn
	loggerConfig.appName = config.appName
	loggerConfig.appVersion = config.appVersion
	init()
}

function init() {
	// don't enable in development mode
	if (loggerConfig.env !== "development") {
		initSentry({
			environment: loggerConfig.env,
			dsn: loggerConfig.dsn,
			release: `${loggerConfig.appName.replace("@", "").replace("/", "_")}@${
				loggerConfig.appVersion
			}`,
			ignoreErrors: [
				// https://forum.sentry.io/t/resizeobserver-loop-limit-exceeded/8402/5
				"ResizeObserver loop limit exceeded",
				"AxiosError",
			],
			autoSessionTracking: false,
			integrations: [
				// disable the promise rejection error reporting
				globalHandlersIntegration({
					onunhandledrejection: false,
					onerror: true,
				}),
				// disable the console error in breadcrumbs
				breadcrumbsIntegration({
					console: false,
				}),
			],
		})
	}
}

export const logError: typeof captureException = (...args) => {
	if (loggerConfig.env === "development") {
		console.error("Error::", ...args)
		return new Date().getTime().toString()
	}
	return captureException(...args)
}

export const logInfo: typeof captureMessage = (...args) => {
	if (loggerConfig.env === "development") {
		console.log("Info::", ...args)
		return new Date().getTime().toString()
	}
	return captureMessage(...args)
}

/**
 * Set the reporting user
 */
export function setReportingUser(
	user: { id: number; name: string; email: string } | null
) {
	const scope = getCurrentScope()
	if (user) {
		const { id, name, email } = user
		scope.setUser({ id: id.toString(), name, email })
	} else {
		scope.setUser(null)
	}
}
