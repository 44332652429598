import { Helmet } from "react-helmet-async"
import { Container } from "@sembark-travel/ui/base"
import { TripQuotePdfTemplates } from "../../../../../EmailPdfTemplates"

export default function OrganizationTemplatesPage() {
	return (
		<>
			<Helmet>
				<title>Quote PDF Templates</title>
			</Helmet>
			<Container paddingY="4">
				<TripQuotePdfTemplates />
			</Container>
		</>
	)
}
