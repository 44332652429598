import { Helmet } from "react-helmet-async"
import { useParams } from "../../../../router-utils"
import { HotelDetails } from "./../../../../Hotels"
import { Outlet } from "react-router-dom"

export default function Hotel() {
	const { hotelId } = useParams("/hotels/:hotelId")
	return (
		<>
			<Helmet>
				<title>Hotel Details</title>
			</Helmet>
			<HotelDetails hotelId={hotelId}>
				{({ hotel, onChange }) => (
					<Outlet context={{ hotel, revalidate: onChange }} />
				)}
			</HotelDetails>
		</>
	)
}
