import { Helmet } from "react-helmet-async"
import { TenantConnectionRequests } from "../../../../ConnectionRequests"

export default function OrganizationConnectionsPage() {
	return (
		<>
			<Helmet>
				<title>B2B Connections</title>
			</Helmet>
			<TenantConnectionRequests />
		</>
	)
}
