import { Stack, Text } from "@sembark-travel/ui/base"
import { utcTimestampToLocalDateString } from "@sembark-travel/datetime-utils"
import { Spinner } from "@sembark-travel/ui/base"
import { useXHR, XHRInstance } from "@sembark-travel/xhr"
import useSWR from "swr"
import { ICab } from "./store"
import { Navigate } from "react-router-dom"

function XHR(xhr: XHRInstance) {
	return {
		async getCab(id: string): Promise<ICab> {
			return xhr.get(`/cabs/${id}`).then(({ data }) => data.data)
		},
	}
}

function useCab(cabId?: string) {
	const xhr = useXHR()
	const {
		data,
		error,
		mutate: revalidate,
		isValidating,
	} = useSWR(cabId ? `/api/cabs/${cabId}` : null, () =>
		cabId ? XHR(xhr).getCab(cabId) : Promise.reject()
	)
	const isFetching = !data && !error
	const isRefreshing = isValidating
	return {
		cab: data,
		fetchCab: revalidate,
		isFetching: isFetching,
		error,
		isRefreshing,
	}
}

export function CabDetails({ cabId }: { cabId: string | number }) {
	const { cab, isFetching } = useCab(String(cabId))
	if (isFetching) {
		return <Spinner alignCenter padding="4" />
	}
	if (cab) {
		return (
			<Stack gap="1">
				<Text>{cab.full_name}</Text>
				<Text fontSize="sm">
					Created at: {utcTimestampToLocalDateString(cab.created_at)}
				</Text>
			</Stack>
		)
	}
	return <Navigate to="/cabs" />
}
