import { Helmet } from "react-helmet-async"
import { BanksList } from "../../../../../Admin/World"

export default function BanksListPage() {
	return (
		<>
			<Helmet>
				<title>Banks</title>
			</Helmet>
			<BanksList />
		</>
	)
}
