import React, { useState, useEffect, useSyncExternalStore } from "react"
import { createRoot } from "react-dom/client"
import type { Root } from "react-dom/client"
import classNames from "classnames"
import { useTransition, animated, config } from "@react-spring/web"
import { ownerDocument, Icons } from "@sembark-travel/ui/base"
import { Optional, Omit } from "utility-types"
import styles from "./snackbar.module.css"

type TId = number | string

interface ISnackbarConfig {
	/**
	 * Should the label and actions be stacked ? Should be set when we have a longer label
	 * @default false
	 */
	stacked?: boolean
	/**
	 * Timeout for the visibility of snackbar in milliseconds
	 * @default 5000
	 */
	timeout?: number
	/**
	 * Actions text
	 */
	actionText?: string
	/**
	 * On Click
	 */
	onClick?: () => void
	/**
	 * Id of the snackbar
	 * This can be used in cases where we have a predefined id want
	 * to know if a snackbar is already visible for it or not e.g. Network Errors
	 */
	id: TId
	/**
	 * Handler when the snackbar is closed
	 */
	onClosed?: () => void
	disableClose?: boolean
}

interface ISnackbarProps
	extends ISnackbarConfig,
		Omit<React.HTMLProps<HTMLDivElement>, keyof ISnackbarConfig | "ref"> {
	/**
	 * Label on the snackbar
	 */
	children: React.ReactNode
}

/**
 * Open a snackbar with an id
 */
export function showSnackbar(
	label: React.ReactNode,
	actionText?: string,
	config: Optional<ISnackbarConfig, "onClick" | "id"> = {}
) {
	setupSnackbarRootIfNotAlready()
	const id = config.id || getId()
	store.push({
		onClosed: () => hideSnackbar(id),
		children: label,
		actionText,
		id,
		...config,
	})
	return () => {
		hideSnackbar(id)
	}
}

/**
 * Hide a snackbar with an id
 */
function hideSnackbar(id: TId) {
	store.remove(id)
}

function Snackbars() {
	const items = useSyncExternalStore(store.subscribe, store.getSnapshot)
	if (items.length === 0) return null
	const snackbar = items[0]
	return (
		<Snackbar key={snackbar.id} {...snackbar}>
			{snackbar.children}
		</Snackbar>
	)
}

export function Snackbar({
	stacked,
	children,
	actionText,
	onClick,
	id,
	onClosed,
	timeout = 5000,
	className,
	disableClose,
	...otherProps
}: ISnackbarProps) {
	const [isOpen, setIsOpen] = useState(true)
	const transitions = useTransition(isOpen, {
		config: config.stiff,
		from: { opacity: 0, transform: "translateY(15px)" },
		enter: { opacity: 1, transform: "translateY(0)" },
		leave: { opacity: 0, transform: "translateY(0)" },
		onDestroyed: onClosed,
	})
	// auto hide after the timeout
	useEffect(() => {
		if (timeout !== -1) {
			const timer = setTimeout(() => {
				setIsOpen(false)
			}, timeout)
			return () => clearTimeout(timer)
		}
		return () => undefined
	}, [timeout])
	return (
		<>
			{transitions((style, item) =>
				item ? (
					<animated.div
						{...otherProps}
						key={Number(item)}
						style={style}
						className={classNames(
							styles["snackbar"],
							{ [styles["snackbar--stacked"]]: stacked },
							className
						)}
						id={id ? String(id) : undefined}
					>
						<div
							role="status"
							aria-live="polite"
							className={styles["snackbar__label"]}
						>
							{children}
						</div>
						<div className={styles["snackbar__actions"]}>
							{actionText && onClick ? (
								<button
									type="button"
									onClick={() => {
										onClick()
										setIsOpen(false)
									}}
								>
									{actionText}
								</button>
							) : null}
							{!disableClose ? (
								<button
									type="button"
									className={styles["snackbar__actions__close"]}
									onClick={() => {
										setIsOpen(false)
									}}
								>
									<Icons.Cancel />
								</button>
							) : null}
						</div>
					</animated.div>
				) : null
			)}
		</>
	)
}

let snackbarCount = 0

function getId(): string {
	snackbarCount++
	return `snackbar_${snackbarCount}`
}

function createStore() {
	let snackbars: Array<ISnackbarProps> = []
	const listeners: Array<() => void> = []
	function notifyListeners() {
		listeners.forEach((l) => l())
	}
	return {
		subscribe(fn: () => void) {
			listeners.push(fn)
			return () => {
				const index = listeners.indexOf(fn)
				if (index >= 0) {
					listeners.splice(index, 1)
				}
			}
		},
		getSnapshot() {
			return snackbars
		},
		push(item: ISnackbarProps) {
			// only push to the queue if not already present
			if (!snackbars.some((s) => s.id === item.id)) {
				snackbars = snackbars.concat([item])
				notifyListeners()
			}
		},
		remove(id: TId) {
			snackbars = snackbars.filter((config) => config.id !== id)
			notifyListeners()
		},
	}
}

const store = createStore()

let snackbarReactRoot: Root | undefined = undefined

function setupSnackbarRootIfNotAlready(): Root {
	if (snackbarReactRoot) return snackbarReactRoot
	const document = ownerDocument()
	if (!document) {
		throw Error("can not find a document to append the snackbar")
	}
	const snackbarContainer = document.createElement("div")
	snackbarContainer.setAttribute("class", styles["snackbar-container"])
	const body = document.body
	body.appendChild(snackbarContainer)
	snackbarReactRoot = createRoot(snackbarContainer)
	snackbarReactRoot.render(<Snackbars />)
	return snackbarReactRoot
}
