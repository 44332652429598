import { Select } from "@sembark-travel/ui/base"
import React from "react"
import { IHotelBookingStage } from "./store"

const stages: Array<IHotelBookingStage> = [
	["initialized", "Initialized"],
	["in_progress", "In Progress"],
	["booked", "Booked"],
	["changed", "Changed"],
	["dropped", "Dropped"],
].map(([id, name]) => ({ id, name }))

export function SelectHotelBookingStages(
	props: React.ComponentProps<typeof Select>
) {
	return <Select multiple options={stages} {...props} />
}
