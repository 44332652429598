import type { IAccount } from "../Accounting"
import type { IAddress } from "../Addresses"
import type { IContact } from "../Contacts"
import type { ITenant } from "../Admin/Tenants"
import type { IListResponse, XHRInstance } from "@sembark-travel/xhr"
import { IUser } from "../Auth"

export interface ITripSource {
	id: number
	name: string
	short_name: string
	accounting_accounts?: Array<IAccount>
	contacts?: Array<IContact>
	trips_count?: number
	connected_tenant_id?: number
	connected_tenant: ITenant
	address?: IAddress
	/**
	 * Is this a B2B partner
	 */
	is_agent: boolean
	conversion_count?: number
	created_at: string
	created_by?: IUser
	updated_at?: string
	updated_by?: IUser
}

export function tripSourcesXHR(xhr: XHRInstance) {
	return {
		async getTripSources(
			params?: unknown
		): Promise<IListResponse<ITripSource>> {
			return xhr.get("/trip-sources", { params }).then((resp) => resp.data)
		},
	}
}
