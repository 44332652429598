import { CallbackRef, useCallbackRef } from "@sembark-travel/ui/base"
import { useEffect } from "react"

export function useDisableNavigationOnSlide<T extends HTMLElement>(): [
	T | null,
	CallbackRef<T>,
] {
	const [ref, setRef] = useCallbackRef<T>()
	// Stop the history navigation gesture on touch devices
	useEffect(() => {
		const preventNavigation = (event: TouchEvent) => {
			// Center point of the touch area
			const touchXPosition = event.touches[0].pageX
			// Size of the touch area
			const touchXRadius = event.touches[0].radiusX || 0

			// We set a threshold (10px) on both sizes of the screen,
			// if the touch area overlaps with the screen edges
			// it's likely to trigger the navigation. We prevent the
			// touchstart event in that case.
			if (
				touchXPosition - touchXRadius < 10 ||
				touchXPosition + touchXRadius > window.innerWidth - 10
			)
				event.preventDefault()
		}

		const container = ref

		if (container) {
			container.addEventListener("touchstart", preventNavigation)
		}

		return () => {
			if (container) {
				container.removeEventListener("touchstart", preventNavigation)
			}
		}
	}, [ref])

	return [ref, setRef]
}
