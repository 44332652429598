import { Box, Text, Card, CardBody } from "@sembark-travel/ui/base"
import { Link, queryToSearch } from "@sembark-travel/ui/router"
import { Helmet } from "react-helmet-async"
import Logo from "../../images/Logo"
import { generatePath, useNavigate } from "../../router-utils"
import { TenantSignupForm } from "./../../Auth"
import { useSearchParams } from "react-router-dom"
import { trackSignup } from "@sembark-travel/tracking"

export default function TenantSignup() {
	const [searchParams] = useSearchParams()
	const navigate = useNavigate()
	const invite_token = searchParams.get("ref") || ""
	const email = searchParams.get("email") || ""
	const name = searchParams.get("name") || ""
	const tenantName = searchParams.get("tenant-name") || ""
	const shortName = searchParams.get("short-name") || ""
	return (
		<>
			<Helmet>
				<title>Complete Your Registration</title>
			</Helmet>
			<Box paddingY="8">
				<Box textAlign="center" marginBottom="16">
					<Box>
						<Box as={Logo} marginBottom="2" display="inline" />
						<Box as="h1" fontSize="xl" fontWeight="normal" margin="0">
							Complete your registration
						</Box>
					</Box>
				</Box>
				<Box maxWidth="4xl" marginX="auto">
					<Card>
						<CardBody paddingX="8" paddingY="8">
							<TenantSignupForm
								companyName={tenantName}
								companyShortName={shortName}
								inviteToken={invite_token}
								name={name}
								email={email}
								addressEmail={email}
								emailVerifiedUrl={`${window.location.origin}/email-verified`}
								onSucess={(values) => {
									trackSignup("tenant")
									navigate(
										{
											pathname: "/login",
											search: queryToSearch({
												email: values.email,
												next: generatePath("/onboarding"),
											}),
										},
										{ replace: true }
									)
								}}
							/>
						</CardBody>
					</Card>
				</Box>
				<Box marginTop="8" textAlign="center" fontSize="sm">
					<Box as="b">Registered user?</Box>{" "}
					<Link to="/" color="accent">
						Visit your dashboard
					</Link>
					. OR{" "}
					<Text as="a" href="mailto:contact@sembark.com" color="accent">
						contact us
					</Text>{" "}
					if you are having facing issues.
				</Box>
			</Box>
		</>
	)
}
