import {
	Box,
	Button,
	Inline,
	Icons,
	Stack,
	Heading,
	Text,
	Col,
	Divider,
	Grid,
} from "@sembark-travel/ui/base"
import { Dialog } from "@sembark-travel/ui/dialog"
import { useXHR } from "@sembark-travel/xhr"
import {
	Form,
	PhoneInputField,
	TextInputField,
	withServerErrors,
	SubmissionError,
	RadioInputField,
	validateFormValues,
	GetFieldValue,
	useFieldValue,
	PhoneNumberValidator,
	SwitchInputField,
	isTruthy,
} from "@sembark-travel/ui/form"
import React, { useState } from "react"
import { $PropertyType, Optional } from "utility-types"
import * as Validator from "yup"
import { ITransportServiceProvider } from "./store"
import { FeatureFlag, useHasFeatureFlag } from "../Auth"

const validationSchema = Validator.object().shape({
	provider_type: Validator.string().required("Please select a provider type"),
	name: Validator.string()
		.required("Please provide a name for the service provider")
		.max(191, "Value can not be longer then 191 characters"),
	contact_name: Validator.string()
		.when(
			"provider_type",
			(providerType: string, schema: Validator.StringSchema) => {
				if (providerType === "company") {
					return schema.required("Please provider the name of the contact")
				}
				return schema.nullable()
			}
		)
		.max(191, "Value can not be longer then 191 characters"),
	contact_email: Validator.string()
		.email("Please provide a valid email address")
		.nullable()
		.max(191, "Value can not be longer then 191 characters"),
	contact_phone_numbers: Validator.array()
		.of(PhoneNumberValidator())
		.min(1, "Please provide atleast one phone number"),
})

const validate = validateFormValues(validationSchema)

type TProviderType = "solo_driver" | "company"

type NewItemCredentials = {
	name: string
	contact_name?: string
	contact_phone_numbers: Array<{
		number: string
		country_code: string
		phone_number: string
	}>
	contact_email: string
	provider_type: TProviderType
	supplies_cabs: 0 | 1
	supplies_travel_activities: 0 | 1
}
const INITIAL_VALUES: NewItemCredentials = {
	name: "",
	contact_name: "",
	contact_phone_numbers: [
		{
			number: "",
			country_code: "IN",
			phone_number: "+91",
		},
	],
	contact_email: "",
	provider_type: "company",
	supplies_cabs: 0,
	supplies_travel_activities: 0,
}

interface NewItemProps {
	onCancel?: () => void
	onSuccess: (data: ITransportServiceProvider) => void
	initialValues?: Optional<NewItemCredentials>
	keepInitialSuppliesSelected?: boolean
}

export function NewItemForm({
	onCancel,
	onSuccess,
	initialValues: propsInitialValue,
	keepInitialSuppliesSelected,
}: NewItemProps) {
	const xhr = useXHR()
	const hasTravelActivities = useHasFeatureFlag("travel_activities")
	const [initialValues] = useState(() => {
		let initialValues: NewItemCredentials = INITIAL_VALUES
		if (propsInitialValue) {
			initialValues = {
				...initialValues,
				...propsInitialValue,
			}
		}
		return {
			...initialValues,
			supplies_cabs: (initialValues.supplies_cabs || !hasTravelActivities
				? 1
				: 0) as 0 | 1,
			supplies_travel_activities: (initialValues.supplies_travel_activities
				? 1
				: 0) as 0 | 1,
		}
	})
	return (
		<Form<NewItemCredentials>
			initialValues={initialValues}
			validate={validate}
			onSubmit={withServerErrors(
				async ({
					provider_type,
					supplies_cabs,
					supplies_travel_activities,
					...data
				}: NewItemCredentials) => {
					if (
						!isTruthy(supplies_travel_activities) &&
						!isTruthy(supplies_cabs)
					) {
						throw new Error(
							"Please select atleast one of the supplied services by this supplier"
						)
					}
					const resp = await xhr.post("/transport-service-providers", {
						...data,
						supplies_cabs,
						supplies_travel_activities,
						solo_driver:
							provider_type === "solo_driver" && isTruthy(supplies_cabs)
								? 1
								: 0,
					})
					onSuccess(resp.data.data)
				}
			)}
			subscription={{ submitting: true }}
		>
			{({ submitting, handleSubmit }) => (
				<form noValidate onSubmit={handleSubmit}>
					<FeatureFlag flag="travel_activities">
						<Grid gap="4">
							<Col sm={12} md={4}>
								<Stack gap="1">
									<Heading as="h4">
										<Icons.Cog size="6" /> Supplied Services
									</Heading>
									<Text color="muted">
										Select the types of services supplied by this supplier.
									</Text>
								</Stack>
							</Col>
							<Col>
								<Inline gap="4" flexWrap="wrap">
									<Box
										paddingY="2"
										paddingX="4"
										borderWidth="1"
										rounded="md"
										bgColor="subtle"
									>
										<SwitchInputField
											name="supplies_cabs"
											label="Cabs"
											readOnly={Boolean(
												keepInitialSuppliesSelected &&
													initialValues.supplies_cabs
											)}
										/>
									</Box>
									<Box
										paddingY="2"
										paddingX="4"
										borderWidth="1"
										rounded="md"
										bgColor="subtle"
									>
										<SwitchInputField
											name="supplies_travel_activities"
											label="Travel Activities"
											readOnly={Boolean(
												keepInitialSuppliesSelected &&
													initialValues.supplies_travel_activities
											)}
										/>
									</Box>
								</Inline>
							</Col>
						</Grid>
						<Divider />
					</FeatureFlag>
					<GetFieldValue<
						(typeof initialValues)["supplies_cabs"]
					> name="supplies_cabs">
						{({ value }) =>
							!isTruthy(value) ? null : (
								<>
									<Grid gap="4">
										<Col sm={12} md={4}>
											<Stack gap="1">
												<Heading as="h4">
													<Icons.Tags size="6" /> Cab Supplier Type
												</Heading>
												<Text color="muted">
													Please select if this is a transporter company or a
													single driver.
												</Text>
											</Stack>
										</Col>
										<Col>
											<Grid gap="4">
												<Col xs={12} sm>
													<ProviderTypeOption
														option="company"
														name="provider_type"
														label={
															<>
																<Box display="inlineBlock">
																	Transporter Company
																</Box>{" "}
																<Icons.UserGroup size="5" color="muted" />
															</>
														}
														help="Please select this if this a multi driver/cab transporter company."
													/>
												</Col>
												<Col xs={12} sm>
													<ProviderTypeOption
														option="solo_driver"
														name="provider_type"
														label={
															<>
																<Box display="inlineBlock">Single Driver</Box>{" "}
																<Icons.SteeringWheel size="5" color="muted" />
															</>
														}
														help="Please select this if the driver is the only person in this company."
													/>
												</Col>
											</Grid>
										</Col>
									</Grid>
									<Divider />
								</>
							)
						}
					</GetFieldValue>
					<GetFieldValue<
						NewItemCredentials["provider_type"]
					> name="provider_type">
						{({ value: provider_type }) =>
							provider_type === "company" ? (
								<>
									<Grid gap="4">
										<Col sm={12} md={4}>
											<Stack gap="1">
												<Heading as="h4">
													<Icons.UserGroup size="6" /> Company Details
												</Heading>
												<Text color="muted">
													Please provide the company/agent details e.g. name.
												</Text>
											</Stack>
										</Col>
										<Col>
											<TextInputField
												maxWidth="sm"
												label="Name"
												name="name"
												placeholder="Sample Transportations"
												required
												type="text"
											/>
										</Col>
									</Grid>
									<Divider />
									<Grid gap="4">
										<Col sm={12} md={4}>
											<Stack gap="1">
												<Heading as="h4">
													<Icons.User size="6" /> Contact Details
												</Heading>
												<Text color="muted">
													Please provide a contact's details for the service
													provide. You can add more contact later.
												</Text>
											</Stack>
										</Col>
										<Col>
											<Stack gap="4">
												<TextInputField
													maxWidth="sm"
													label="Contact Name"
													name="contact_name"
													placeholder="Sample Name"
													required
													type="text"
												/>
												<Grid gap="4">
													<Col>
														<PhoneInputField
															label="Contact Phone Number(s)"
															name="contact_phone_numbers"
															required
															multi
														/>
													</Col>
													<Col>
														<TextInputField
															maxWidth="xs"
															label="Contact Email"
															secondaryLabel="optional"
															name="contact_email"
															placeholder="domain@example.com"
															type="email"
															style={{ minWidth: "200px" }}
														/>
													</Col>
												</Grid>
											</Stack>
										</Col>
									</Grid>
								</>
							) : (
								<Grid gap="4">
									<Col sm={12} md={4}>
										<Stack gap="1">
											<Heading as="h4">
												<Icons.User size="6" /> Driver Details
											</Heading>
											<Text color="muted">
												Please provide name and contact details of provider.
											</Text>
										</Stack>
									</Col>
									<Col>
										<Stack gap="4">
											<Grid gap="4">
												<Col xs={12} sm>
													<TextInputField
														label="Name"
														name="name"
														placeholder="Mahendra Singh G"
														required
														type="text"
														maxWidth="sm"
													/>
												</Col>
												<Col>
													<PhoneInputField
														label="Contact Phone Number(s)"
														name="contact_phone_numbers"
														required
														multi
													/>
												</Col>
											</Grid>
											<TextInputField
												label="Contact Email"
												secondaryLabel="optional"
												name="contact_email"
												placeholder="domain@example.com"
												type="email"
												maxWidth="xs"
											/>
										</Stack>
									</Col>
								</Grid>
							)
						}
					</GetFieldValue>
					<Divider sm />
					<Grid gap="4">
						<Col sm={12} md={{ offset: 4, span: 8 }}>
							<Stack gap="4" paddingLeft={{ md: "4" }}>
								<SubmissionError />
								<Inline gap="4">
									<Button type="submit" disabled={submitting} size="lg">
										{submitting ? "Saving..." : "Save Details"}
									</Button>
									{onCancel ? (
										<Button
											onClick={onCancel}
											size="lg"
											level="tertiary"
											disabled={submitting}
										>
											Cancel
										</Button>
									) : null}
								</Inline>
							</Stack>
						</Col>
					</Grid>
				</form>
			)}
		</Form>
	)
}

function ProviderTypeOption({
	name,
	option,
	label,
	...props
}: {
	name: string
	label: React.ReactNode
	help?: React.ReactNode
	option: TProviderType
}) {
	const { value } = useFieldValue<NewItemCredentials["provider_type"]>(name)
	const isSelected = value === option
	return (
		<Box
			padding="4"
			borderWidth="1"
			rounded="lg"
			boxShadow={isSelected ? "lg" : "inner"}
			bgColor="subtle"
			as="label"
			cursor="pointer"
			display="block"
			borderColor={isSelected ? "primary" : "default"}
			marginBottom="4"
		>
			<RadioInputField
				name={name}
				value={option}
				label={label as never}
				{...props}
			/>
		</Box>
	)
}

export function AddTransportServiceProviderDialog({
	open,
	onClose,
	onSuccess,
	...props
}: Omit<NewItemProps, "onCancel"> & {
	open: boolean
	onClose: () => void
	onSuccess?: (tsp: ITransportServiceProvider) => void
	initialValues?: $PropertyType<NewItemProps, "initialValues">
}) {
	return (
		<Dialog open={open} onClose={onClose} title="Add New Supplier">
			<Dialog.Body>
				<NewItemForm
					{...props}
					onSuccess={onSuccess || onClose}
					onCancel={onClose}
				/>
			</Dialog.Body>
		</Dialog>
	)
}
