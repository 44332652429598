import { Helmet } from "react-helmet-async"
import { useNavigate } from "../../../router-utils"
import { NewRoleForm } from "../../../Roles"

export default function NewRole() {
	const navigate = useNavigate()
	return (
		<>
			<Helmet>
				<title>New Role</title>
			</Helmet>
			<NewRoleForm
				onSuccess={(role) =>
					navigate("/roles/:roleId", { params: { roleId: role.id.toString() } })
				}
				onCancel={() => navigate("/roles")}
			/>
		</>
	)
}
