import { Box, Container } from "@sembark-travel/ui/base"
import { Breadcrumbs } from "@sembark-travel/ui/router"
import { Helmet } from "react-helmet-async"
import { useNavigate, generatePath } from "../../../router-utils"
import { MergeTripSources } from "../../../TripSources"
import { ForbidUnlessAuthorized, PERMISSIONS } from "../../../Auth"

export default function MergeTripSourcesPage() {
	const navigate = useNavigate()
	return (
		<>
			<Helmet>
				<title>Merge Trip Sources</title>
			</Helmet>
			<ForbidUnlessAuthorized permission={PERMISSIONS.ADD_TRIP_SOURCES}>
				<Breadcrumbs
					title="Merge Trip Sources"
					items={[
						[generatePath("/org/trip-sources"), "Trip Source"],
						["", "Merge"],
					]}
				/>
				<Box paddingX={{ md: "4" }} paddingY={{ md: "8" }}>
					<Box maxWidth="6xl" marginX="auto">
						<Box borderWidth={{ md: "1" }} rounded="md" bgColor="default">
							<Container paddingY="6">
								<MergeTripSources
									onSuccess={() => navigate("/org/trip-sources")}
									onCancel={() => navigate("/org/trip-sources")}
								/>
							</Container>
						</Box>
					</Box>
				</Box>
			</ForbidUnlessAuthorized>
		</>
	)
}
