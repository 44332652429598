import {
	DateTimeInput,
	FileInput,
	Input,
	RadioInput,
	CheckboxInput,
	SwitchInput,
	SelectInput,
	TextArea,
	InputGroup,
	Box,
	Inline,
	Stack,
	AsyncSelectProps,
	useId,
	Text,
	Icons,
	DurationInput,
	InlineSelectInput,
	Dropdown,
} from "@sembark-travel/ui/base"
import { Optional } from "utility-types"
import type { AnySchema, ValidationError } from "yup"
import { mixed as mixedValidator } from "yup"
import {
	Form as ReactFinalForm,
	Field,
	useField,
	FormSpy,
	useForm,
	useFormState,
} from "react-final-form"
import type {
	FormProps,
	FieldRenderProps,
	FormRenderProps,
} from "react-final-form"
import arrayMutators from "final-form-arrays"
import {
	FieldArray as ReactFinalFormFieldArray,
	FieldArrayProps,
	useFieldArray,
} from "react-final-form-arrays"
import { ErrorMessage } from "./ErrorMessage"

import { FORM_ERROR, setIn, getIn, type FormApi } from "final-form"
import { MarkdownEditor } from "@sembark-travel/ui/markdown"
import { useRef, useEffect } from "react"
import type { AxiosError } from "axios"
import { TCurrency, currencyExchangeRatioToString } from "@sembark-travel/money"
import {
	addUnit,
	endOf,
	startOf,
	subtractUnit,
} from "@sembark-travel/datetime-utils"

export type { FormProps, FormRenderProps, FormApi }

const FIELD_ARRAY_KEY = "__id" as const

export {
	Field,
	useField,
	FormSpy,
	useForm,
	useFormState,
	arrayMutators,
	getIn as getFormValueForKey,
}

const POWER_10_OF_10 = Math.pow(10, 10)

function generateID(): number {
	let id = 0
	do {
		id = Math.floor(Math.random() * POWER_10_OF_10)
	} while (!id)
	return id
}

/**
 * Append key to an item in a field array to track the keys
 */
export function addKeyToFieldArrayItem<T extends object>(value: T) {
	return {
		...value,
		[FIELD_ARRAY_KEY]: generateID(),
	}
}

/**
 * Get the key for an item in a field array for given name
 */
export function getFieldArrayItemKey(form: FormApi, name: string) {
	const key = getIn(form.getState().values, `${name}.${FIELD_ARRAY_KEY}`)
	if (!key) {
		console.error({ name, values: form.getState().values })
		// fallback to name
		return `${name}.${FIELD_ARRAY_KEY}`
	}
	return key
}

export function useFieldArrayLength(name: string): number {
	const { fields } = useFieldArray(name, {
		subscription: { length: true },
	})
	return fields.length || 0
}

export function FieldArray<
	FieldValue = unknown,
	T extends HTMLElement = HTMLElement,
>(props: FieldArrayProps<FieldValue, T>) {
	const length = useFieldArrayLength(props.name)
	return (
		<>
			<ReactFinalFormFieldArray subscription={{}} {...props} />
			{!length ? <ErrorMessage name={props.name} /> : null}
		</>
	)
}

export function Form<FormValues = Record<string, unknown>>(
	props: FormProps<FormValues>
) {
	return <ReactFinalForm<FormValues> keepDirtyOnReinitialize {...props} />
}

export function TextInputField({
	name,
	...props
}: Omit<React.ComponentProps<typeof FieldGroup>, "children"> &
	React.ComponentProps<typeof Input>) {
	return (
		<FieldGroup<string | number> name={name} {...props}>
			{({ input, meta, ...otherProps }) => <Input {...input} {...otherProps} />}
		</FieldGroup>
	)
}

export function useCurrencyPairRatioFieldValue(
	name: string,
	baseCurrency: TCurrency,
	counterCurrency: TCurrency
) {
	const { value, onChange } = useFieldValue<number | "">(
		`${name}.${baseCurrency}.${counterCurrency}`
	)
	useEffect(() => {
		if (baseCurrency === counterCurrency && Number(value) !== 1) {
			setTimeout(() => {
				onChange(1)
			})
		}
	}, [baseCurrency, counterCurrency, value, onChange])
	return { value, onChange }
}

export function GetCurrencyPairRatioFieldValue({
	name,
	baseCurrency,
	counterCurrency,
	children,
}: {
	name: string
	baseCurrency: TCurrency
	counterCurrency: TCurrency
	children: (
		props: ReturnType<typeof useCurrencyPairRatioFieldValue>
	) => React.ReactNode
}) {
	const props = useCurrencyPairRatioFieldValue(
		name,
		baseCurrency,
		counterCurrency
	)
	return <>{children(props)}</>
}

export function CurrencyPairInputField({
	name,
	baseCurrency,
	counterCurrency,
	label,
	defaultValue,
	...props
}: Omit<React.ComponentProps<typeof FieldGroup>, "children"> &
	React.ComponentProps<typeof Input> & {
		baseCurrency: TCurrency
		counterCurrency: TCurrency
	}) {
	if (label !== "") {
		label = `${baseCurrency}/${counterCurrency}`
	}
	const rateFieldName = `${name}.${baseCurrency}.${counterCurrency}`
	const { value: rate, onChange: changeRate } = useCurrencyPairRatioFieldValue(
		name,
		baseCurrency,
		counterCurrency
	)
	const initialRate = useRef(rate)
	useEffect(() => {
		if (!initialRate.current && defaultValue) {
			initialRate.current = Number(defaultValue as string)
			setTimeout(() => {
				changeRate(initialRate.current)
			})
		}
	}, [initialRate, defaultValue, changeRate])
	return (
		<FieldGroup<string | number>
			name={rateFieldName}
			{...props}
			label={label}
			secondaryLabel={
				defaultValue ? (
					<Box display="inlineBlock">
						<Text
							as="a"
							color="accent"
							href="#"
							title="Click to reset to defaults"
							onClick={
								((e) => {
									e.preventDefault()
									changeRate(Number(defaultValue as string))
								}) as React.MouseEventHandler<HTMLAnchorElement>
							}
						>
							Default/Rate: {defaultValue}
						</Text>{" "}
						{Number(rate || 0) !== Number(defaultValue || 0) ? (
							<Icons.AttentionSolid
								color="warning"
								title="Current and Default Rate Mismatch"
							/>
						) : null}
					</Box>
				) : null
			}
			help={({ value }) => (
				<Text>
					1 {baseCurrency} ={" "}
					{value !== ""
						? currencyExchangeRatioToString(Number(value || 0))
						: "?"}{" "}
					{counterCurrency}
				</Text>
			)}
		>
			{({ input, meta, ...otherProps }) => (
				<Input min={0} {...input} {...otherProps} type="number" />
			)}
		</FieldGroup>
	)
}

export function MoneyInputField({
	name,
	currencyFieldName,
	currencies,
	...props
}: Omit<React.ComponentProps<typeof FieldGroup>, "children"> &
	React.ComponentProps<typeof Input> & {
		currencyFieldName: string
		currencies?: Array<string>
	}) {
	return (
		<FieldGroup<string | number>
			name={`${name}`}
			{...props}
			secondaryLabel={
				currencies?.length ? (
					<InlineSelectInputField name={currencyFieldName} noCaret>
						{currencies.map((o) => (
							<option value={o} key={o}>
								{o}
							</option>
						))}
					</InlineSelectInputField>
				) : null
			}
		>
			{({ input, meta, ...otherProps }) => (
				<Input
					min={0}
					placeholder="e.g. 3000"
					{...input}
					{...otherProps}
					type="number"
				/>
			)}
		</FieldGroup>
	)
}

export function PercentageInputField({
	name,
	style,
	...props
}: Omit<React.ComponentProps<typeof FieldGroup>, "children"> &
	Omit<React.ComponentProps<typeof Input>, "type">) {
	return (
		<FieldGroup<string | number>
			name={name}
			type="number"
			{...props}
			style={{ width: "90px", ...style }}
		>
			{({ input, meta, ...otherProps }) => (
				<Inline alignItems="center">
					<Input
						placeholder="00.00"
						min={0}
						max={100}
						{...input}
						{...otherProps}
					/>
					<Box paddingX="1">%</Box>
				</Inline>
			)}
		</FieldGroup>
	)
}

export function TextAreaInputField({
	name,
	...props
}: Omit<React.ComponentProps<typeof FieldGroup>, "children"> &
	React.ComponentProps<typeof TextArea>) {
	return (
		<FieldGroup<string> name={name} {...props}>
			{({ input, meta, ...otherProps }) => (
				<TextArea {...input} {...otherProps} />
			)}
		</FieldGroup>
	)
}

/**
 * Select Input Field, as inline with Text Visibility
 */
export function InlineSelectInputField({
	name,
	children,
	...props
}: Omit<
	React.ComponentProps<typeof FieldGroup>,
	"children" | "label" | "help" | "secondaryLabel"
> &
	React.ComponentProps<typeof InlineSelectInput>) {
	return (
		<Box display="inlineBlock">
			<FieldGroup<string> name={name} {...props}>
				{({ input, meta, ...otherProps }) => (
					<InlineSelectInput {...input} {...otherProps}>
						{children}
					</InlineSelectInput>
				)}
			</FieldGroup>
		</Box>
	)
}

export function SelectInputField({
	name,
	children,
	...props
}: Omit<React.ComponentProps<typeof FieldGroup>, "children"> &
	React.ComponentProps<typeof SelectInput>) {
	return (
		<FieldGroup<string> name={name} {...props}>
			{({ input, meta, ...otherProps }) => (
				<SelectInput {...input} {...otherProps}>
					{children}
				</SelectInput>
			)}
		</FieldGroup>
	)
}

export function DatePickerField({
	name,
	...props
}: Omit<React.ComponentProps<typeof FieldGroup>, "children"> &
	React.ComponentProps<typeof DateTimeInput>) {
	return (
		<FieldGroup<Date | undefined> name={name} {...props}>
			{({ input, meta, ...otherProps }) => (
				<DateTimeInput {...input} {...otherProps} />
			)}
		</FieldGroup>
	)
}

type TDateRangePreset =
	| "Today"
	| "Yesterday"
	| "This Week"
	| "This Month"
	| "Last Month"
	| "Next 7 Days"
	| "Prev 7 Days"

function getRangeForPreset(preset: TDateRangePreset): [Date, Date] {
	const interval: [Date, Date] = [
		startOf(new Date(), "day"),
		endOf(new Date(), "day"),
	]
	switch (preset) {
		case "Today":
			interval[0] = startOf(new Date(), "day")
			interval[1] = endOf(new Date(), "day")
			break
		case "Yesterday":
			interval[0] = startOf(subtractUnit(new Date(), 1, "day"), "day")
			interval[1] = endOf(interval[0], "day")
			break
		case "This Week":
			interval[0] = startOf(new Date(), "week")
			interval[1] = endOf(new Date(), "week")
			break
		case "This Month":
			interval[0] = startOf(new Date(), "month")
			interval[1] = endOf(new Date(), "month")
			break
		case "Last Month":
			interval[0] = subtractUnit(startOf(new Date(), "month"), 1, "month")
			interval[1] = endOf(interval[0], "month")
			break
		case "Next 7 Days":
			interval[0] = addUnit(startOf(new Date(), "day"), 1, "day")
			interval[1] = addUnit(interval[0], 6, "day")
			break
		case "Prev 7 Days":
			interval[1] = subtractUnit(startOf(new Date(), "day"), 1, "day")
			interval[0] = subtractUnit(interval[1], 6, "day")
			break
	}
	return interval
}

const DEFAULT_DATE_RANGE_PRESETS: Array<TDateRangePreset> = [
	"Today",
	"Yesterday",
	"This Week",
	"This Month",
	"Last Month",
]

export function DateRangePickerField({
	fromName,
	toName,
	label,
	secondaryLabel,
	rangePresets = DEFAULT_DATE_RANGE_PRESETS,
	dateFormat = "DD MMM'YY",
	...props
}: Omit<React.ComponentProps<typeof FieldGroup>, "children" | "name" | "help"> &
	Omit<React.ComponentProps<typeof DateTimeInput>, "name"> & {
		fromName: string
		toName: string
		rangePresets?: Array<TDateRangePreset>
	}) {
	const id = `field_${fromName}`
	const form = useForm()
	return (
		<Stack gap="1">
			{label ? (
				<Inline justifyContent="between">
					<Inline gap="2" alignItems="baseline">
						<label htmlFor={id}>{label}</label>
						{secondaryLabel ? (
							<Box fontSize="sm" color="muted">
								({secondaryLabel})
							</Box>
						) : null}
					</Inline>
					{rangePresets?.length ? (
						<Dropdown alignRight>
							<Dropdown.ToggleButton inline>
								<Icons.Calendar />
							</Dropdown.ToggleButton>
							<Dropdown.Menu>
								{rangePresets.map((label) => (
									<Dropdown.MenuItem
										key={label}
										onClick={() => {
											const interval = getRangeForPreset(label)
											form.batch(() => {
												form.change(fromName, interval[0])
												form.change(toName, interval[1])
											})
										}}
									>
										{label}
									</Dropdown.MenuItem>
								))}
							</Dropdown.Menu>
						</Dropdown>
					) : null}
				</Inline>
			) : null}
			<Inline alignItems="center">
				<FieldGroup<Date | undefined> name={fromName} {...props}>
					{({ input, meta, ...otherProps }) => (
						<DateTimeInput {...input} dateFormat={dateFormat} {...otherProps} />
					)}
				</FieldGroup>
				<Box>
					<Icons.SwitchHorizontal />
				</Box>
				<FieldGroup<Date | undefined> name={toName} {...props}>
					{({ input, meta, ...otherProps }) => (
						<DateTimeInput
							{...input}
							dateFormat={dateFormat}
							{...otherProps}
							rightAlign
						/>
					)}
				</FieldGroup>
			</Inline>
		</Stack>
	)
}

export function FileInputField({
	name,
	...props
}: Omit<React.ComponentProps<typeof FieldGroup>, "children"> &
	React.ComponentProps<typeof FileInput>) {
	const id = useId()
	const fileNameInputName = `file_name_${id}`
	return (
		<FieldGroup<FileList | null> name={name} {...props}>
			{({ input, meta, ...otherProps }) => {
				const { value, onChange, ...inputProps } = input
				return (
					<FileInput
						{...inputProps}
						{...otherProps}
						name={fileNameInputName}
						onBlur={() => {
							input.onBlur()
						}}
						onChange={(files) => {
							const file = props.multiple ? files : files ? files[0] : null
							props.onChange ? props.onChange(files || null) : onChange(file)
						}}
					/>
				)
			}}
		</FieldGroup>
	)
}

export function SelectField<
	SelectProps extends Optional<AsyncSelectProps, "fetch">,
>({
	name,
	select,
	multiple,
	...props
}: Omit<React.ComponentProps<typeof FieldGroup>, "children"> &
	SelectProps & { select: React.ComponentType<SelectProps> }) {
	const Component: React.ComponentType<SelectProps> = select
	return (
		<FieldGroup name={name} {...props}>
			{({ input, meta, ...otherProps }) => {
				const props = {
					multiple,
					...input,
					...otherProps,
				} as never as SelectProps
				return <Component {...props} />
			}}
		</FieldGroup>
	)
}

function numberParser(value: unknown) {
	if (value === "") return undefined
	if (typeof value === "string") {
		return Number(value)
	}
	return value
}

export function FieldGroup<FieldValue = unknown>({
	hideErrors,
	help,
	label,
	secondaryLabel,
	...props
}: {
	name: string
	hideErrors?: boolean
	help?: React.ReactNode | ((props: { value: FieldValue }) => React.ReactNode)
	label?: React.ReactNode
	secondaryLabel?: React.ReactNode
	required?: boolean
	type?: string
	children: (props: FieldRenderProps<FieldValue>) => React.ReactNode
	style?: React.CSSProperties
}) {
	const { name } = props
	const {
		meta: { error, touched, submitError },
	} = useField<FieldValue>(name, {
		subscription: { error: true, touched: true, submitError: true },
	})
	const id = `field_${name}`
	return (
		<InputGroup hasError={!!(touched && error) || submitError}>
			<Stack gap="1">
				{label ? (
					<Inline gap="2" alignItems="baseline">
						<label htmlFor={id}>{label}</label>
						{secondaryLabel ? (
							<Box fontSize="sm" color="muted">
								({secondaryLabel})
							</Box>
						) : null}
					</Inline>
				) : null}
				<Field<FieldValue>
					subscription={{ value: true }}
					id={id}
					data-testid={id}
					{...(props.type === "number"
						? {
								parse: numberParser as never,
							}
						: {})}
					{...props}
				/>
				{help ? <Help<FieldValue> name={name} help={help} /> : null}
				{!hideErrors ? <ErrorMessage name={name} /> : null}
			</Stack>
		</InputGroup>
	)
}

function Help<FieldValue = unknown>({
	help,
	name,
}: {
	name: string
	help: React.ReactNode | ((props: { value: FieldValue }) => React.ReactNode)
}) {
	return typeof help === "function" ? (
		<GetFieldValue<FieldValue> name={name}>
			{({ value }) => (
				<Box
					color="muted"
					fontSize="sm"
					letterSpacing="wide"
					fontWeight="normal"
				>
					{help({ value })}
				</Box>
			)}
		</GetFieldValue>
	) : (
		<Box color="muted" fontSize="sm" letterSpacing="wide" fontWeight="normal">
			{help}
		</Box>
	)
}

export function RadioInputField({
	hideErrors,
	help,
	label,
	secondaryLabel,
	data,
	id,
	value,
	...props
}: Omit<React.ComponentProps<typeof FieldGroup>, "children"> &
	React.ComponentProps<typeof RadioInput>) {
	const { name } = props
	const {
		meta: { error, touched, submitError },
	} = useField(name, {
		subscription: { error: true, touched: true, submitError: true },
	})
	id = id || `field_${name}_${value ? String(value) : "set_id"}`
	return (
		<InputGroup hasError={!!(touched && error) || submitError}>
			<Stack gap="1">
				<Inline gap="2">
					<Box>
						<Field
							type="radio"
							subscription={{ value: true }}
							id={id}
							data-testid={id}
							value={value}
							{...props}
						>
							{({ input, meta, ...otherProps }) => (
								<RadioInput {...input} {...otherProps} />
							)}
						</Field>
					</Box>
					{label || help ? (
						<Stack gap="1">
							{label ? (
								<Inline gap="2">
									<label htmlFor={id}>{label}</label>
									{secondaryLabel ? (
										<Box fontSize="sm" color="muted">
											({secondaryLabel})
										</Box>
									) : null}
								</Inline>
							) : null}
							{help ? <Help name={name} help={help} /> : null}
						</Stack>
					) : null}
				</Inline>
				{!hideErrors ? <ErrorMessage name={name} /> : null}
			</Stack>
		</InputGroup>
	)
}

export function CheckboxInputField({
	hideErrors,
	help,
	label,
	secondaryLabel,
	data,
	id,
	value,
	...props
}: Omit<React.ComponentProps<typeof FieldGroup>, "children"> &
	React.ComponentProps<typeof CheckboxInput>) {
	const { name } = props
	const {
		meta: { error, touched, submitError },
	} = useField(name, {
		subscription: { error: true, touched: true, submitError: true },
	})
	id = id || `field_${name}_${value ? String(value) : "set_id"}`
	return (
		<InputGroup hasError={!!(touched && error) || submitError}>
			<Stack gap="1">
				<Inline gap="2">
					<Box>
						<Field
							type="checkbox"
							subscription={{ value: true }}
							id={id}
							data-testid={id}
							value={value}
							{...props}
						>
							{({ input, meta, ...otherProps }) => (
								<CheckboxInput {...input} {...otherProps} />
							)}
						</Field>
					</Box>
					{label || help ? (
						<Stack gap="1">
							{label ? (
								<Inline gap="2">
									<label htmlFor={id}>{label}</label>
									{secondaryLabel ? (
										<Box fontSize="sm" color="muted">
											({secondaryLabel})
										</Box>
									) : null}
								</Inline>
							) : null}

							{help ? <Help name={name} help={help} /> : null}
						</Stack>
					) : null}
				</Inline>
				{!hideErrors ? <ErrorMessage name={name} /> : null}
			</Stack>
		</InputGroup>
	)
}

export function isTruthy(value: unknown): boolean {
	if (!value) return false
	if (Array.isArray(value)) return value.length > 0
	const str = String(value).toLowerCase()
	return str === "true" || str === "1" || str === "on"
}

export function switchValidator() {
	return mixedValidator().oneOf(
		[0, "0", false, "false", "off", 1, true, "1", "true", "on"],
		"Invalid selected value"
	)
}

export function SwitchInputField({
	hideErrors,
	help,
	label,
	secondaryLabel,
	data,
	id,
	value,
	...props
}: Omit<React.ComponentProps<typeof FieldGroup>, "children"> &
	React.ComponentProps<typeof SwitchInput>) {
	const { name } = props
	const {
		meta: { error, touched, submitError },
	} = useField(name, {
		subscription: { error: true, touched: true, submitError: true },
	})
	id = id || `field_${name}_${value ? String(value) : "set_id"}`
	return (
		<InputGroup hasError={!!(touched && error) || submitError}>
			<Stack gap="1">
				<Inline gap="2">
					<Box>
						<Field
							subscription={{ value: true }}
							id={id}
							data-testid={id}
							{...props}
						>
							{({ input, meta, ...otherProps }) => {
								return (
									<SwitchInput
										{...input}
										{...otherProps}
										checked={isTruthy(input.value)}
									/>
								)
							}}
						</Field>
					</Box>
					<Stack gap="1">
						{label ? (
							<Inline gap="2">
								<Text
									as="label"
									htmlFor={id}
									cursor={props.disabled ? "disabled" : undefined}
								>
									{label}
								</Text>
								{secondaryLabel ? (
									<Box fontSize="sm" color="muted">
										({secondaryLabel})
									</Box>
								) : null}
							</Inline>
						) : null}
						{help ? <Help name={name} help={help} /> : null}
					</Stack>
				</Inline>
				{!hideErrors ? <ErrorMessage name={name} /> : null}
			</Stack>
		</InputGroup>
	)
}

// To be passed to React Final Form
export function validateFormValues<Type>(schema: AnySchema<Type>) {
	return async function validate<Values>(values: Values) {
		try {
			await schema.validate(values, { abortEarly: false })
			return
		} catch (e: unknown) {
			const err = e as ValidationError
			if (!err || !err.inner) {
				return
			}
			const errors = err.inner.reduce((formError, innerError) => {
				if (innerError.path && innerError.message) {
					return setIn(formError, innerError.path, innerError.message)
				}
				return formError
			}, {})
			return errors
		}
	}
}

export const withServerErrors: <FormValues>(
	arg: FormProps<FormValues>["onSubmit"]
) => FormProps<FormValues>["onSubmit"] = function withServerErrors(
	handleSubmit
) {
	return async (values, ...args) => {
		return new Promise((resolve) => {
			Promise.resolve(handleSubmit(values, ...args))
				.then(resolve)
				.catch((err) => {
					const error = err as AxiosError
					const e = (error.response && error.response.data) as
						| undefined
						| { message: string; errors?: { [key: string]: Array<string> } }
					const message = !e ? error.message : e.message
					const fieldErrors = e?.errors
						? Object.keys(e.errors).reduce(
								(errors: { [key: string]: string }, name: string) => {
									if (e.errors) {
										errors[name] = e.errors[name].join(", ")
									}
									return errors
								},
								{}
							)
						: {}
					resolve({
						[FORM_ERROR]: message,
						...(fieldErrors || {}),
					})
				})
		})
	}
}

export function useFieldValue<FieldValue = unknown>(name: string) {
	const {
		input: { value, onChange },
	} = useField<FieldValue>(name, {
		subscription: { value: true },
	})
	return { value, onChange }
}

export function GetFieldValue<FieldValue = unknown>({
	name,
	children,
}: {
	name: string
	children: (props: {
		value: FieldValue
		onChange: (val: FieldValue) => void
	}) => React.ReactNode
}) {
	const { value, onChange } = useFieldValue<FieldValue>(name)
	return <>{children({ value, onChange })}</>
}

export function MarkdownInputField({
	name,
	...props
}: Omit<React.ComponentProps<typeof FieldGroup>, "children"> &
	React.ComponentProps<typeof MarkdownEditor>) {
	return (
		<FieldGroup<string | undefined> name={name} {...props}>
			{({ input, meta, ...otherProps }) => (
				<MarkdownEditor {...input} {...otherProps} name={name} />
			)}
		</FieldGroup>
	)
}

export function DurationInputField({
	name,
	...props
}: Omit<React.ComponentProps<typeof FieldGroup>, "children"> &
	Omit<React.ComponentProps<typeof DurationInput>, "onChange">) {
	return (
		<FieldGroup<string> name={name} {...props}>
			{({ input, meta, ...otherProps }) => (
				<DurationInput {...input} {...otherProps} />
			)}
		</FieldGroup>
	)
}
