import { ButtonLink } from "@sembark-travel/ui/router"
import { Helmet } from "react-helmet-async"
import { BankAccountStatementList } from "./../../../../BankAccountStatements"
import { generatePath } from "./../../../../router-utils"
import { ForbidUnlessAuthorized, PERMISSIONS } from "../../../../Auth"

export default function BankAccountStatements(
	props: Omit<React.ComponentProps<typeof BankAccountStatementList>, "actions">
) {
	return (
		<>
			<Helmet>
				<title>Bank Account Statement</title>
			</Helmet>
			<ForbidUnlessAuthorized
				permission={PERMISSIONS.VIEW_BANK_ACCOUNT_STATEMENTS}
			>
				<BankAccountStatementList
					{...props}
					actions={
						<ButtonLink
							to={generatePath("/accounting/transactions/csv-upload")}
							level="primary"
							status="primary"
						>
							Add New
						</ButtonLink>
					}
				/>
			</ForbidUnlessAuthorized>
		</>
	)
}
