import { Stack, Tabs, TabsList, TabContent } from "@sembark-travel/ui/base"
import { LatestQuoteDiffFromBookingsForTrip } from "../../../../../Trips"
import { NavLink } from "@sembark-travel/ui/router"
import { useTripDetailsOutletContext } from "../_trip-details-context"
import { Outlet } from "react-router-dom"
import { Helmet } from "react-helmet-async"

export default function TripServicesBookingsPage() {
	const { trip, onChange } = useTripDetailsOutletContext()
	return (
		<>
			<Helmet>
				<title>Services Bookings | Trip</title>
			</Helmet>
			<Stack gap="2">
				<Tabs>
					{trip.converted_at ? (
						<TabsList>
							<NavLink to="hotels#hotel_bookings">Hotels</NavLink>
							<NavLink
								to="operational#operational_bookings"
								onClick={() => {
									document
										.getElementById("operational_bookings")
										?.scrollIntoView()
								}}
							>
								Operational
							</NavLink>
							<NavLink to="flights#flight_bookings">Flights</NavLink>
						</TabsList>
					) : null}
					<TabContent>
						<Stack gap="4">
							{!trip.cancellation_reason ? (
								<LatestQuoteDiffFromBookingsForTrip
									containerPadding="4"
									tripId={trip.id}
								/>
							) : null}
							<Outlet context={{ trip, onChange }} />
						</Stack>
					</TabContent>
				</Tabs>
			</Stack>
		</>
	)
}
