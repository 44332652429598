import { Container } from "@sembark-travel/ui/base"
import { Breadcrumbs } from "@sembark-travel/ui/router"
import { Helmet } from "react-helmet-async"
import { UploadTrips } from "../../../Trips"
import { useNavigate, generatePath } from "../../../router-utils"
import { ForbidUnlessAuthorized, PERMISSIONS } from "../../../Auth"

export default function UploadTripsPage() {
	const navigate = useNavigate()
	return (
		<>
			<Helmet>
				<title>Upload Trips</title>
			</Helmet>
			<ForbidUnlessAuthorized permission={PERMISSIONS.CREATE_QUERIES}>
				<Breadcrumbs
					title="Upload Queries"
					items={[
						[generatePath("/trips"), "Trips"],
						["", "Upload CSV"],
					]}
				/>
				<Container paddingY="8">
					<UploadTrips
						onSuccess={() => navigate("/trips")}
						onCancel={() => navigate("/trips")}
					/>
				</Container>
			</ForbidUnlessAuthorized>
		</>
	)
}
