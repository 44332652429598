import { ListView, Search, useSearch } from "@sembark-travel/ui/list"
import { tripQuoteBookingsDiffXHR } from "./store"
import { Icons, RelativeTime, Table } from "@sembark-travel/ui/base"
import { Link } from "@sembark-travel/ui/router"
import { generatePath } from "../router-utils"

export function TripQuoteBookingsDiffList() {
	const [params, setParams] = useSearch()
	return (
		<Search
			title="Trip Quote & Booking Diffs"
			onSearch={(params) =>
				setParams({
					...params,
					page: 1,
				})
			}
		>
			<ListView
				pageKey="trip-quote-bookings-diff"
				params={params}
				fetch={(xhr, params) => tripQuoteBookingsDiffXHR(xhr).get(params)}
				onPageChange={(page) => setParams({ ...params, page })}
			>
				{({ items }) => (
					<Table
						bordered
						striped
						headers={["Trip ID", "Hotels", "Cabs", "Last Change"]}
						rows={items.map((i) => [
							<Link
								to={generatePath("/trips/:tripId/services-bookings", {
									tripId: i.trip_id.toString(),
								})}
								color="accent"
							>
								{i.trip_id}
							</Link>,
							i.hotels_changed ? <Icons.Cancel color="warning" /> : "-",
							i.cabs_changed ? <Icons.Cancel color="warning" /> : "-",
							<RelativeTime timestamp={i.updated_at || i.created_at} />,
						])}
					/>
				)}
			</ListView>
		</Search>
	)
}
