import {
	Box,
	Button,
	Icons,
	Table,
	Heading,
	Stack,
	Text,
	Component,
	TableDataCell,
	Inline,
	Select,
	joinAttributes,
	AsyncSelect,
} from "@sembark-travel/ui/base"
import { showSnackbar } from "@sembark-travel/ui/snackbar"
import { Dialog, useDialog } from "@sembark-travel/ui/dialog"
import { useRef, useState } from "react"
import AddContactForm from "./AddContactForm"
import { Email } from "./Email"
import { PhoneNumber } from "./PhoneNumber"
import { IContact, IPhoneNumber } from "./store"
import { UpdateContactInDialog } from "./UpdateContact"
import { ISimpleListResponse, useXHR } from "@sembark-travel/xhr"

export function ContactsList({
	contacts,
	onCreate,
	onChange,
	phoneNumberRequired,
	emailRequired,
}: {
	contacts?: Array<IContact>
	phoneNumberRequired?: boolean
	emailRequired?: boolean
	onChange?: () => void
	onCreate: (data: {
		name: string
		email?: string
		phone_numbers?: Array<IPhoneNumber<unknown>>
	}) => Promise<IContact>
}) {
	const [isOpen, show, close] = useDialog()
	return (
		<Stack gap="4">
			<Inline gap="2" alignItems="center">
				<Box>
					<Icons.Users color="muted" size="4" />
				</Box>
				<Heading as="h4" fontSize="md">
					Contacts
				</Heading>
			</Inline>
			{contacts?.length ? (
				<Table
					headers={["Name", "Phone Number", "Email", ""]}
					hover
					bordered
					responsive
					alignCols={{ 3: "right" }}
					rows={contacts.map((contact) => [
						contact.name,
						<PhoneNumber numberOnly value={contact.phone_numbers} />,
						<Email emailOnly value={contact.email} />,
						<Component initialState={false}>
							{({ state, setState }) => (
								<>
									<Button
										onClick={() => setState(true)}
										title="Edit Contact Details"
										size="sm"
										level="tertiary"
									>
										<Icons.Pencil />
									</Button>
									<UpdateContactInDialog
										open={state}
										contact={contact}
										onCancel={() => setState(false)}
										phoneNumberRequired={phoneNumberRequired}
										emailRequired={emailRequired}
										onSuccess={() => {
											setState(false)
											showSnackbar("Contact details updated.")
											onChange?.()
										}}
									/>
								</>
							)}
						</Component>,
					])}
				>
					<tfoot>
						<tr>
							<TableDataCell colSpan={4}>
								<Button data-testid="add_contact" onClick={show} size="sm">
									<Icons.Plus /> Add Contact
								</Button>
							</TableDataCell>
						</tr>
					</tfoot>
				</Table>
			) : (
				<Stack gap="2">
					<Text fontWeight="semibold" color="muted" fontSize="md">
						No contacts added.
					</Text>
					<Text color="muted" fontSize="sm">
						Add contacts like spokesperson for better communication and sharing
						across organisation
					</Text>
					<Box>
						<Button
							onClick={show}
							data-testid="add_contact"
							status="primary"
							size="sm"
						>
							<Icons.Plus /> Add Contact
						</Button>
					</Box>
				</Stack>
			)}
			<Dialog open={isOpen} onClose={close} title="Add Contact">
				<Dialog.Body>
					<AddContactForm
						onCancel={close}
						phoneNumberRequired={phoneNumberRequired}
						emailRequired={emailRequired}
						onCreate={async (data) => {
							const contact = await onCreate(data)
							showSnackbar("Contact added.")
							return contact
						}}
					/>
				</Dialog.Body>
			</Dialog>
		</Stack>
	)
}
function AddContactDialog({
	isOpen,
	onClose,
	onCreate,
	...props
}: { isOpen: boolean; onClose: () => void } & Omit<
	React.ComponentProps<typeof AddContactForm>,
	"onCancel"
>) {
	return (
		<Dialog open={isOpen} onClose={onClose} title="Add Contact">
			<Dialog.Body>
				<AddContactForm
					onCancel={onClose}
					onCreate={async (data) => {
						const contact = await onCreate(data)
						showSnackbar("Contact added.")
						return contact
					}}
					{...props}
				/>
			</Dialog.Body>
		</Dialog>
	)
}

export function SelectContact({
	onCreate,
	phoneNumberRequired,
	emailRequired,
	...props
}: Pick<
	React.ComponentProps<typeof AddContactDialog>,
	"onCreate" | "phoneNumberRequired" | "emailRequired"
> &
	React.ComponentProps<typeof Select>) {
	const [newName, setNewName] = useState("")
	return (
		<>
			<Select
				{...props}
				onCreateNew={(query: string) => {
					setNewName(query)
				}}
				optionRenderer={({ option, created }) => {
					if (created) {
						return <Text>Add "{option.name}"</Text>
					}
					return (
						<Stack gap="1">
							<Text>{option.name}</Text>
							<Text fontSize="sm" color="muted">
								{joinAttributes(option.phone_number, option.email)}
							</Text>
						</Stack>
					)
				}}
			/>
			<AddContactDialog
				isOpen={!!newName}
				initialValues={{
					name: newName,
				}}
				onClose={() => {
					setNewName("")
				}}
				onCreate={onCreate}
				phoneNumberRequired={phoneNumberRequired}
				emailRequired={emailRequired}
			/>
		</>
	)
}

export function SelectContactForContactables({
	onCreate,
	phoneNumberRequired,
	emailRequired,
	contactables,
	...props
}: Pick<
	React.ComponentProps<typeof AddContactDialog>,
	"onCreate" | "phoneNumberRequired" | "emailRequired"
> &
	Omit<React.ComponentProps<typeof AsyncSelect>, "fetch"> & {
		contactables: {
			hotels?: Array<number>
		}
	}) {
	const [newName, setNewName] = useState("")
	const xhr = useXHR()
	const onSuccessRef = useRef<((value: unknown) => void) | undefined>(undefined)
	return (
		<>
			<AsyncSelect
				fetch={(q, { page }) =>
					xhr
						.get<ISimpleListResponse<IContact>>("/contacts", {
							params: {
								q,
								limit: 30,
								page,
								contactable_types: [contactables.hotels ? "hotels" : undefined],
								contactable_ids: { hotels: contactables.hotels },
							},
						})
						.then((resp) => resp.data.data)
				}
				{...props}
				onCreateNew={(query: string) => {
					setNewName(query)
					return new Promise((resolve) => {
						onSuccessRef.current = resolve
					})
				}}
				optionRenderer={({ option, created }) => {
					if (created) {
						return <Text>Add "{option.name}"</Text>
					}
					return (
						<Stack gap="1">
							<Text>{option.name}</Text>
							<Text fontSize="sm" color="muted">
								{joinAttributes(option.phone_number, option.email)}
							</Text>
						</Stack>
					)
				}}
			/>
			<AddContactDialog
				isOpen={!!newName}
				initialValues={{
					name: newName,
				}}
				onClose={() => {
					setNewName("")
				}}
				onCreate={async (...args) => {
					const contact = await onCreate(...args)
					onSuccessRef.current?.(contact)
					onSuccessRef.current = undefined
					return contact
				}}
				phoneNumberRequired={phoneNumberRequired}
				emailRequired={emailRequired}
			/>
		</>
	)
}
