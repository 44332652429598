import {
	Alert,
	Box,
	Icons,
	Stack,
	Inline,
	Spinner,
	Heading,
	Text,
	Money,
	MoneySum,
	RadioInput,
} from "@sembark-travel/ui/base"
import { ButtonLink, Link } from "@sembark-travel/ui/router"
import { useXHR } from "@sembark-travel/xhr"
import { useCallback, useMemo, useState } from "react"
import useSWR from "swr"
import { LogTransactionForm, Transactions } from "../Accounting"
import { generatePath, useNavigate } from "../router-utils"
import { TFormattedMoney } from "@sembark-travel/money"

export function TripAccountingsDetails({
	tripId,
	...props
}: { tripId: string } & Omit<
	React.ComponentProps<typeof Transactions>,
	"params" | "disableLogging" | "pageKey"
>) {
	const xhr = useXHR()
	const { data, error } = useSWR<{
		tax_percentage: number | null
		// amounts in package/given/selling currency
		package_money: TFormattedMoney
		profit_so_far_in_package_currency: TFormattedMoney | null
		profit_percentage_in_package_currency: number | null
		tax_money_in_package_currency: TFormattedMoney | null
		// amounts in costing currency
		package_money_in_costing_currency: TFormattedMoney
		tax_money: TFormattedMoney | null
		profit_so_far: TFormattedMoney | null
		profit_percentage: number | null
		total_booking_price_in_package_currency: TFormattedMoney | null
		// amounts in their own currencies
		total_booking_prices: Array<TFormattedMoney>
		pending_hotel_bookings_count: number
		pending_cab_schedules_count: number
		pending_flight_bookings_count: number
		pending_travel_activity_bookings_count: number
		pending_bookings_count: number
		hotel_bookings_booked_prices: Array<TFormattedMoney>
		cab_schedules_booked_prices: Array<TFormattedMoney>
		travel_activity_bookings_booked_prices: Array<TFormattedMoney>
		flight_bookings_booked_prices: Array<TFormattedMoney>
		extras_quoted_prices: Array<TFormattedMoney>
		same_package_and_costing_currencies: boolean
	}>(tripId ? `/trips/${tripId}/accounting` : null, (key: string) =>
		xhr.get(key).then((resp) => resp.data.data)
	)
	const transactionParams = useMemo(() => {
		return {
			attached_to_type: "trips",
			attached_to_id: tripId,
		}
	}, [tripId])
	const [showInPackageCurrency, setShowInPackageCurrency] = useState(false)
	if (!tripId) return null
	return (
		<Stack gap="8">
			<Stack gap="4">
				<Inline
					justifyContent="between"
					gap="8"
					alignItems="center"
					bgColor="default"
					padding="4"
					borderBottomWidth="1"
				>
					<Heading as="h3">Profit Report</Heading>
					<Box>
						<ButtonLink
							to={generatePath("/trips/:tripId/accounting/transactions/new", {
								tripId,
							})}
							level="secondary"
							status="primary"
						>
							<Icons.Plus /> Log Payment
						</ButtonLink>
					</Box>
				</Inline>
				{error ? (
					<Box paddingX="4">
						<Alert status="error">
							{error.message || "Something went wrong. Please try again later."}
						</Alert>
					</Box>
				) : null}
				<Box padding="4">
					{!data ? (
						<Spinner alignCenter padding="4" />
					) : (
						<Stack
							rounded="lg"
							boxShadow="lg"
							bgColor="default"
							padding="8"
							gap="4"
						>
							{!data.same_package_and_costing_currencies ? (
								<Inline gap="2">
									<label>
										<RadioInput
											name="show_in_package_currency"
											value="0"
											checked={!showInPackageCurrency}
											onChange={() =>
												setShowInPackageCurrency(!showInPackageCurrency)
											}
										/>{" "}
										{data.package_money_in_costing_currency.currency}
									</label>
									<label>
										<RadioInput
											name="show_in_package_currency"
											value="1"
											checked={showInPackageCurrency}
											onChange={() =>
												setShowInPackageCurrency(!showInPackageCurrency)
											}
										/>{" "}
										{data.package_money.currency}
									</label>
								</Inline>
							) : null}
							<Inline gap={{ xs: "8", md: "12" }} flexWrap="wrap">
								<Stack gap="px">
									<Text
										fontWeight="semibold"
										whiteSpace="preserve"
										fontSize="md"
										color="muted"
									>
										Package Amount
									</Text>
									<Text fontSize="2xl" fontWeight="semibold">
										{showInPackageCurrency ? (
											<Money money={data.package_money} showCurrency />
										) : (
											<Money
												money={data.package_money_in_costing_currency}
												showCurrency
											/>
										)}
									</Text>
								</Stack>
								<Stack gap="2">
									<Stack gap="px">
										<Text
											fontWeight="semibold"
											whiteSpace="preserve"
											fontSize="md"
											color="muted"
										>
											Bookings
										</Text>
										{data.total_booking_price_in_package_currency &&
										showInPackageCurrency ? (
											<Text fontSize="2xl" fontWeight="semibold">
												<Money
													money={data.total_booking_price_in_package_currency}
													showCurrency
												/>
											</Text>
										) : (
											<Text fontSize="2xl" fontWeight="semibold">
												<MoneySum money={data.total_booking_prices} />
											</Text>
										)}
									</Stack>
									{data.total_booking_prices.length ? (
										<Inline gap="4">
											{data.hotel_bookings_booked_prices?.length ? (
												<Stack gap="px">
													<Text
														whiteSpace="preserve"
														color="muted"
														fontSize="sm"
													>
														Hotels
													</Text>
													<Text>
														<MoneySum
															money={data.hotel_bookings_booked_prices}
														/>
													</Text>
												</Stack>
											) : null}
											{data.cab_schedules_booked_prices?.length ? (
												<Stack gap="px">
													<Text
														whiteSpace="preserve"
														color="muted"
														fontSize="sm"
													>
														Cabs
													</Text>
													<Text>
														<MoneySum
															money={data.cab_schedules_booked_prices}
														/>
													</Text>
												</Stack>
											) : null}
											{data.flight_bookings_booked_prices?.length ? (
												<Stack gap="px">
													<Text
														whiteSpace="preserve"
														color="muted"
														fontSize="sm"
													>
														Flights
													</Text>
													<Text>
														<MoneySum
															money={data.flight_bookings_booked_prices}
														/>
													</Text>
												</Stack>
											) : null}
											{data.travel_activity_bookings_booked_prices?.length ? (
												<Stack gap="px">
													<Text
														whiteSpace="preserve"
														color="muted"
														fontSize="sm"
													>
														Activities
													</Text>
													<Text>
														<MoneySum
															money={
																data.travel_activity_bookings_booked_prices
															}
														/>
													</Text>
												</Stack>
											) : null}
											{data.extras_quoted_prices?.length ? (
												<Stack gap="px">
													<Text
														whiteSpace="preserve"
														color="muted"
														fontSize="sm"
													>
														Others
													</Text>
													<Text>
														<MoneySum money={data.extras_quoted_prices} />
													</Text>
												</Stack>
											) : null}
										</Inline>
									) : null}
									{data.pending_bookings_count ? (
										<Link
											to={generatePath("/trips/:tripId/services-bookings", {
												tripId: String(tripId),
											})}
										>
											<Text color="warning" fontSize="sm">
												<Icons.Attention /> Some bookings are still pending.
											</Text>
										</Link>
									) : null}
								</Stack>
								{data.tax_money?.amount ? (
									<Stack gap="px">
										<Text
											fontWeight="semibold"
											whiteSpace="preserve"
											fontSize="md"
											color="muted"
										>
											Tax ({data.tax_percentage}%)
										</Text>
										{showInPackageCurrency &&
										data.tax_money_in_package_currency ? (
											<Text fontSize="2xl" fontWeight="semibold">
												<Money
													money={data.tax_money_in_package_currency}
													showCurrency
												/>
											</Text>
										) : (
											<Text fontSize="2xl" fontWeight="semibold">
												<Money money={data.tax_money} showCurrency />
											</Text>
										)}
									</Stack>
								) : null}
								<Stack gap="px">
									<Text
										fontWeight="semibold"
										whiteSpace="preserve"
										fontSize="md"
										color="muted"
									>
										Profit So-Far
									</Text>
									<Text fontSize="2xl" fontWeight="semibold">
										{data.profit_so_far_in_package_currency &&
										showInPackageCurrency ? (
											<Money
												money={data.profit_so_far_in_package_currency}
												showCurrency
											/>
										) : data.profit_so_far && !showInPackageCurrency ? (
											<Money money={data.profit_so_far} showCurrency />
										) : (
											"N/A"
										)}
									</Text>
								</Stack>
								<Stack gap="px">
									<Text
										fontWeight="semibold"
										whiteSpace="preserve"
										fontSize="md"
										color="muted"
									>
										Profit %
									</Text>
									<Text fontSize="2xl" fontWeight="semibold">
										{showInPackageCurrency &&
										data.profit_so_far_in_package_currency &&
										data.profit_percentage_in_package_currency !== null
											? data.profit_percentage_in_package_currency
											: !showInPackageCurrency &&
												  data.profit_so_far &&
												  data.profit_percentage !== null
												? data.profit_percentage
												: "N/A"}
										<Box as="span" fontSize="sm" color="muted">
											%
										</Box>
									</Text>
								</Stack>
							</Inline>
						</Stack>
					)}
				</Box>
			</Stack>
			{data ? (
				<Box>
					<Transactions
						{...props}
						params={transactionParams}
						disableLogging
						pageKey={`/trips/${tripId}/accounting/transactions`}
					/>
				</Box>
			) : null}
		</Stack>
	)
}

export function LogTripTransaction({ tripId }: { tripId: string }) {
	const navigate = useNavigate()
	const xhr = useXHR()
	const back = useCallback(() => {
		navigate("/trips/:tripId/accounting/transactions", {
			params: { tripId },
			replace: true,
		})
	}, [navigate, tripId])
	return (
		<Stack>
			<Stack
				paddingX={{ xs: "4", xl: "6" }}
				paddingY="4"
				as="header"
				bgColor="default"
				borderBottomWidth="1"
			>
				<Heading as="h3">Log Payment for Trip</Heading>
			</Stack>
			<Box>
				<Stack padding="4" maxWidth="6xl" marginX="auto" gap="10">
					<Alert status="warning">
						<Box>
							To log instalment's payments (e.g. guest, hotel payments etc), use{" "}
							<Link to="../../payments" color="accent">
								Payments page
							</Link>{" "}
							for better and convenient accounting.
						</Box>
					</Alert>
					<LogTransactionForm
						hideTip
						onSubmit={async (data) => {
							await xhr.post(`/trips/${tripId}/accounting`, data)
							back()
						}}
						onCancel={back}
					/>
				</Stack>
			</Box>
		</Stack>
	)
}
