import type { ElementType, ForwardedRef } from "react"
import { forwardRef } from "react"
import type {
	PolymorphicForwardRefExoticComponent,
	PolymorphicPropsWithoutRef,
	PolymorphicPropsWithRef,
} from "./polymorphic-types"
import { Box } from "./Box"
import { TBtnSizes, btnHeightClassName } from "./button.css"

const DefaultElement = "button"

export type ButtonOwnProps = {
	size?: TBtnSizes
	status?: "warning" | "primary" | "danger" | "success"
	level?: "primary" | "secondary" | "tertiary"
	inline?: boolean
	fullWidth?: boolean
	type?: "button" | "submit" | "reset"
	children: React.ReactNode
	disabled?: boolean
	align?: "center" | "left"
}

export type ButtonProps<T extends React.ElementType = typeof DefaultElement> =
	PolymorphicPropsWithRef<ButtonOwnProps, T>

export const Button: PolymorphicForwardRefExoticComponent<
	ButtonOwnProps,
	typeof DefaultElement
> = forwardRef(function Button<T extends ElementType = typeof DefaultElement>(
	{
		as,
		size: propSize,
		status,
		level,
		inline,
		fullWidth,
		type,
		disabled,
		align = "center",
		className,
		...restProps
	}: PolymorphicPropsWithoutRef<ButtonOwnProps, T>,
	ref: ForwardedRef<Element>
) {
	if (!type && !as) {
		type = "button"
	}
	const Element: ElementType = as || DefaultElement
	level =
		inline && !level
			? "tertiary"
			: level || (type === "submit" ? "primary" : "secondary")
	const size = propSize || "base"
	return (
		<Box
			{...restProps}
			type={type}
			fontWeight="semibold"
			display={!fullWidth ? "inlineFlex" : "flex"}
			width={fullWidth ? "full" : undefined}
			justifyContent={align === "left" ? "start" : "center"}
			alignItems="center"
			rounded="md"
			disabled={disabled}
			cursor={disabled ? "disabled" : "pointer"}
			gap={size === "sm" ? "1" : "2"}
			borderWidth={!inline ? "1" : "0"}
			paddingX={!inline ? (size === "sm" ? "2" : "4") : "0"}
			as={Element}
			ref={ref}
			whiteSpace="preserve"
			className={!inline ? btnHeightClassName[size] : undefined}
			color={
				level === "primary"
					? status === "warning"
						? "default"
						: "white"
					: level === "secondary" || level === "tertiary"
						? status === "danger"
							? "danger"
							: status === "success"
								? "success"
								: status === "warning"
									? "warning"
									: level === "secondary"
										? "primary"
										: "muted"
						: undefined
			}
			bgColor={
				level === "primary"
					? status === "danger"
						? { default: "danger_emphasis" }
						: status === "success"
							? { default: "success_emphasis" }
							: status === "warning"
								? { default: "warning_emphasis" }
								: { default: "primary_emphasis" }
					: level === "secondary"
						? status === "danger"
							? { default: "red1", hover: "red2" }
							: status === "success"
								? { default: "green1", hover: "green2" }
								: status === "warning"
									? { default: "yellow1", hover: "yellow2" }
									: status === "primary"
										? {
												default: "primary",
												hover: "primary",
											}
										: { default: "default", hover: "subtle" }
						: { default: "transparent", hover: "default" }
			}
			borderColor={
				level === "primary"
					? status === "danger"
						? "danger_emphasis"
						: status === "success"
							? "success_emphasis"
							: status === "warning"
								? "warning_emphasis"
								: "primary_emphasis"
					: level === "secondary"
						? status === "danger"
							? "danger"
							: status === "success"
								? "success"
								: status === "warning"
									? "warning"
									: status === "primary"
										? "primary"
										: "default"
						: { default: "transparent", hover: "default" }
			}
			transition="1"
		/>
	)
})
