import {
	Button,
	Stack,
	AsyncSelect,
	Inline,
	Box,
	Divider,
	Heading,
	Icons,
	Text,
	Grid,
	Col,
	Alert,
	joinAttributes,
} from "@sembark-travel/ui/base"
import React, { useRef, useState } from "react"
import {
	Form,
	TextInputField,
	withServerErrors,
	SubmissionError,
	SelectInputField,
	GetFieldValue,
	arrayMutators,
	FieldArray,
	SelectField,
	validateFormValues,
	EmptyNumberValidator,
} from "@sembark-travel/ui/form"
import { Dialog } from "@sembark-travel/ui/dialog"
import { TTripDestination } from "../TripDestinations"
import {
	cabPriceCalculationMetricsXHR,
	cabPriceCalculationMetricPresetsXHR,
	TCabPriceCalculationMetric,
	TCabPriceCalculationMetricPreset,
	LABEL_FOR_CAB_PRICE_CALCULATION_SYSTEM_QUANTITY_METRIC,
} from "./store"
import * as Validator from "yup"
import { useXHR } from "@sembark-travel/xhr"

export function SelectCabPriceCalculationMetric({
	tripDestinations,
	...props
}: Omit<React.ComponentProps<typeof AsyncSelect>, "fetch"> & {
	tripDestinations?: Array<TTripDestination>
}) {
	const xhr = useXHR()
	const [createItemName, setCreateName] = useState<undefined | string>(
		undefined
	)
	const waitingPromise = useRef<
		((val: TCabPriceCalculationMetric) => void) | undefined
	>(undefined)
	const isDialogOpen = createItemName !== undefined
	function closeDialog() {
		setCreateName(undefined)
	}
	return (
		<>
			<AsyncSelect
				cacheKey={`cab_price_calculation_metrics`}
				optionRenderer={({
					option,
					created,
				}: {
					option: TCabPriceCalculationMetric
					created?: boolean
				}) =>
					created ? (
						<Text>Add New '{option.name}'</Text>
					) : (
						<Stack gap="px">
							<Box>{option.name}</Box>
							{option.system_quantity_metric || option.min_quantity_per_day ? (
								<Text fontSize="sm" color="muted">
									{joinAttributes(
										option.system_quantity_metric
											? LABEL_FOR_CAB_PRICE_CALCULATION_SYSTEM_QUANTITY_METRIC[
													option.system_quantity_metric
											  ]
											: null,
										option.min_quantity_per_day
											? `Min ${option.min_quantity_per_day}/D`
											: null
									)}
								</Text>
							) : null}
							<Box></Box>
						</Stack>
					)
				}
				{...props}
				fetch={async (q) =>
					cabPriceCalculationMetricsXHR(xhr)
						.get({ q })
						.then((resp) => resp.data)
				}
				onCreateNew={async (q) => {
					setCreateName(q || "")
					return new Promise((resolve) => {
						waitingPromise.current = resolve
					})
				}}
			/>
			{props.value && props.value.deleted_at ? (
				<Alert status="warning" inline>
					Archived Metric.
				</Alert>
			) : null}
			<Dialog
				open={isDialogOpen}
				onClose={closeDialog}
				title="Add New Metric"
				sm
			>
				<Dialog.Body>
					<AddCabPriceCalculationMetric
						onSuccess={(item) => {
							waitingPromise.current?.(item)
							closeDialog()
						}}
						onCancel={closeDialog}
						initialValues={{
							name: createItemName || "",
							trip_destinations: tripDestinations,
						}}
					/>
				</Dialog.Body>
			</Dialog>
		</>
	)
}

export function SelectCabPriceCalculationMetricPreset({
	tripDestinations,
	...props
}: Omit<React.ComponentProps<typeof AsyncSelect>, "fetch"> & {
	tripDestinations?: Array<TTripDestination>
}) {
	const [createItemName, setCreateName] = useState<undefined | string>(
		undefined
	)
	const xhr = useXHR()
	const waitingPromise = useRef<
		((val: TCabPriceCalculationMetric) => void) | undefined
	>(undefined)
	const isDialogOpen = createItemName !== undefined
	function closeDialog() {
		setCreateName(undefined)
	}
	return (
		<>
			<AsyncSelect
				cacheKey="cab_price_calculation_metric_presets"
				{...props}
				fetch={async (q) =>
					cabPriceCalculationMetricPresetsXHR(xhr)
						.get({ q })
						.then((resp) => resp.data)
				}
				onCreateNew={async (q) => {
					setCreateName(q || "")
					return new Promise((resolve) => {
						waitingPromise.current = resolve
					})
				}}
			/>
			<Dialog
				open={isDialogOpen}
				onClose={closeDialog}
				title="Create New Preset"
				sm
			>
				<Dialog.Body>
					<AddCabPriceCalculationMetricPreset
						onSuccess={(item) => {
							waitingPromise.current?.(item)
							closeDialog()
						}}
						onCancel={closeDialog}
					/>
				</Dialog.Body>
			</Dialog>
		</>
	)
}

export function AddCabPriceCalculationMetricPreset({
	onSuccess,
	...props
}: Omit<
	React.ComponentProps<typeof CabPriceCalculationMetricPresetForm>,
	"onSubmit"
> & {
	onSuccess: (data: TCabPriceCalculationMetricPreset) => void
}) {
	const xhr = useXHR()
	return (
		<CabPriceCalculationMetricPresetForm
			onSubmit={async (payload) => {
				const item =
					await cabPriceCalculationMetricPresetsXHR(xhr).store(payload)
				onSuccess(item)
			}}
			{...props}
		/>
	)
}

export function EditCabPriceCalculationMetricPreset({
	preset,
	onSuccess,
	...props
}: Omit<
	React.ComponentProps<typeof CabPriceCalculationMetricPresetForm>,
	"onSubmit" | "initialValues"
> & {
	preset: TCabPriceCalculationMetricPreset
	onSuccess: (data: TCabPriceCalculationMetricPreset) => void
}) {
	const xhr = useXHR()
	const [initialValues] = useState(() => ({
		name: preset.name,
		metrics: preset.metrics,
	}))
	return (
		<CabPriceCalculationMetricPresetForm
			initialValues={initialValues}
			onSubmit={async (payload) => {
				const item = await cabPriceCalculationMetricPresetsXHR(xhr).update(
					preset.id,
					payload
				)
				onSuccess(item)
			}}
			{...props}
		/>
	)
}

type TAddCabPriceCalculationMetricPresetFormData = {
	name: string
	metrics?: Array<TCabPriceCalculationMetric>
}

function CabPriceCalculationMetricPresetForm({
	initialValues: propInitialValues,
	onSubmit,
	onCancel,
}: {
	initialValues?: Partial<TAddCabPriceCalculationMetricPresetFormData>
	onSubmit: (
		payload: Omit<TAddCabPriceCalculationMetricPresetFormData, "metrics"> & {
			metrics: Array<number>
		}
	) => Promise<void>
	onCancel: () => void
}) {
	const [initialValues] = useState<TAddCabPriceCalculationMetricPresetFormData>(
		() => ({
			name: propInitialValues?.name || "",
			metrics: propInitialValues?.metrics || ([undefined, undefined] as never),
		})
	)
	return (
		<Form<TAddCabPriceCalculationMetricPresetFormData>
			initialValues={initialValues}
			validate={validateFormValues(
				Validator.object().shape({
					name: Validator.string()
						.required("Please provide a name")
						.max(100, "Please use 100 or less characters."),
					metrics: Validator.array()
						.min(2, "Please provide atleast two metric")
						.of(Validator.mixed().required("Please select a metric")),
				})
			)}
			onSubmit={withServerErrors(async (values) => {
				const payload = {
					name: values.name,
					metrics:
						values.metrics?.filter((m) => Boolean(m)).map((m) => m.id) || [],
				}
				await onSubmit(payload)
			})}
			subscription={{ submitting: true }}
			mutators={{ ...arrayMutators }}
		>
			{({ handleSubmit, submitting }) => (
				<form onSubmit={handleSubmit} noValidate>
					<Grid gap="4">
						<Col xs={12} sm={5}>
							<Stack gap="1">
								<Heading fontSize="md">Metrics</Heading>
								<Text color="muted">
									Select metrics to be included into the preset.
								</Text>
							</Stack>
						</Col>
						<Col>
							<FieldArray<
								TCabPriceCalculationMetric | undefined
							> name="metrics">
								{({ fields }) => (
									<Stack gap="4">
										{fields.map((name, index) => (
											<Inline key={name} gap="2">
												<Box flex="1" minWidth="0">
													<SelectField
														select={SelectCabPriceCalculationMetric}
														creatable
														name={name}
														required
														fetchOnMount
														autoFocus={index === 0}
														fullWidth
													/>
												</Box>
												{Number(fields.length || 0) > 2 ? (
													<Button
														onClick={() => fields.remove(index)}
														level="tertiary"
													>
														<Icons.Cancel />
													</Button>
												) : null}
											</Inline>
										))}
										<Box>
											<Button onClick={() => fields.push(undefined)} size="sm">
												Add More
											</Button>
										</Box>
									</Stack>
								)}
							</FieldArray>
						</Col>
					</Grid>
					<Divider sm />
					<TextInputField
						name="name"
						type="text"
						required
						label="Name"
						placeholder="e.g. Kms-Parking"
						maxWidth="xs"
					/>
					<Divider sm />
					<Stack>
						<SubmissionError />
						<Inline gap="4">
							<Button type="submit" disabled={submitting}>
								{submitting ? "Please wait..." : "Save"}
							</Button>
							<Button onClick={onCancel} disabled={submitting} level="tertiary">
								Cancel
							</Button>
						</Inline>
					</Stack>
				</form>
			)}
		</Form>
	)
}

type TAddCabPriceCalculationMetricFormData = Pick<
	TCabPriceCalculationMetric,
	"name" | "system_quantity_metric" | "min_quantity_per_day"
> & {
	trip_destinations?: Array<TTripDestination>
}

const validate = validateFormValues(
	Validator.object().shape({
		name: Validator.string()
			.required("Please provide the Name")
			.max(50, "Name should not be greater then 50 characters"),
		min_quantity_per_day: EmptyNumberValidator()
			.nullable()
			.positive("The quantity should be a positive integer value")
			.integer("The quantity should be a positive integer value"),
	})
)

export function AddCabPriceCalculationMetric({
	onSuccess,
	...props
}: Omit<
	React.ComponentProps<typeof CabPriceCalculationMetricForm>,
	"onSubmit"
> & {
	onSuccess: (item: TCabPriceCalculationMetric) => void
}) {
	const xhr = useXHR()
	return (
		<CabPriceCalculationMetricForm
			onSubmit={async (payload) => {
				const data = await cabPriceCalculationMetricsXHR(xhr).store(payload)
				onSuccess(data)
			}}
			{...props}
		/>
	)
}

const SYSTEM_QUANTITY_METRIC_OPTIONS: Array<{
	id: "" | TCabPriceCalculationMetric["system_quantity_metric"]
	name: string
	description: string
}> = [
	{ id: "", name: "-", description: "" },
	{
		id: "kms",
		name: LABEL_FOR_CAB_PRICE_CALCULATION_SYSTEM_QUANTITY_METRIC["kms"],
		description: "Kms",
	},
	{
		id: "days",
		name: LABEL_FOR_CAB_PRICE_CALCULATION_SYSTEM_QUANTITY_METRIC["days"],
		description: "Days",
	},
	{
		id: "nights",
		name: LABEL_FOR_CAB_PRICE_CALCULATION_SYSTEM_QUANTITY_METRIC["nights"],
		description: "Nights",
	},
	{
		id: "extra_kms",
		name: LABEL_FOR_CAB_PRICE_CALCULATION_SYSTEM_QUANTITY_METRIC["extra_kms"],
		description: "Extra Kms",
	},
	{
		id: "total",
		name: LABEL_FOR_CAB_PRICE_CALCULATION_SYSTEM_QUANTITY_METRIC["total"],
		description: "Total Tour",
	},
]

function CabPriceCalculationMetricForm({
	initialValues: propInitialValues,
	onSubmit,
	onCancel,
}: {
	initialValues?: Partial<TAddCabPriceCalculationMetricFormData>
	onSubmit: (payload: TAddCabPriceCalculationMetricFormData) => Promise<void>
	onCancel: () => void
}) {
	const [initialValues] = useState<TAddCabPriceCalculationMetricFormData>(
		() => ({
			name: "",
			system_quantity_metric: undefined,
			trip_destinations: [],
			...propInitialValues,
		})
	)
	return (
		<Form<TAddCabPriceCalculationMetricFormData>
			initialValues={initialValues}
			validate={validate}
			onSubmit={withServerErrors(async (values) => {
				const payload: TAddCabPriceCalculationMetricFormData = {
					name: values.name,
					system_quantity_metric: values.system_quantity_metric,
					min_quantity_per_day:
						values.system_quantity_metric === "kms" ||
						values.system_quantity_metric === "extra_kms"
							? values.min_quantity_per_day
							: undefined,
				}
				await onSubmit(payload)
			})}
			subscription={{ submitting: true }}
			mutators={{ ...arrayMutators }}
		>
			{({ handleSubmit, submitting }) => (
				<form onSubmit={handleSubmit} noValidate>
					<Stack gap="4">
						<Inline gap="4" collapseBelow="sm">
							<Box flex="1" maxWidth="xs">
								<TextInputField
									label="Metric Name"
									type="text"
									name="name"
									required
									placeholder="Per Km."
								/>
							</Box>
							<Box style={{ maxWidth: "200px" }}>
								<SelectInputField
									label="System Metric Mapping"
									help="This will allow system to suggest total quantity of the metric."
									name="system_quantity_metric"
								>
									{SYSTEM_QUANTITY_METRIC_OPTIONS.map(
										({ id, name, description }) => (
											<option value={id} key={id} title={description}>
												{name}
											</option>
										)
									)}
								</SelectInputField>
							</Box>
						</Inline>
						<GetFieldValue<
							TAddCabPriceCalculationMetricFormData["system_quantity_metric"]
						> name="system_quantity_metric">
							{({ value }) =>
								value === "kms" || value === "extra_kms" ? (
									<TextInputField
										name="min_quantity_per_day"
										label="Minimum Quantity per Day"
										type="number"
										style={{ maxWidth: "150px" }}
										placeholder="240"
									/>
								) : null
							}
						</GetFieldValue>
					</Stack>
					<Divider sm />
					<Stack gap="4">
						<SubmissionError />
						<Inline gap="4">
							<Button type="submit" disabled={submitting}>
								{submitting ? "Please wait..." : "Save Details"}
							</Button>
							<Button
								type="button"
								disabled={submitting}
								onClick={onCancel}
								level="tertiary"
							>
								Cancel
							</Button>
						</Inline>
					</Stack>
				</form>
			)}
		</Form>
	)
}

export function CabPriceCalculationSystemQuantityMetricLabel({
	metric,
}: {
	metric: TCabPriceCalculationMetric["system_quantity_metric"]
}) {
	if (!metric) return null
	return <>{LABEL_FOR_CAB_PRICE_CALCULATION_SYSTEM_QUANTITY_METRIC[metric]}</>
}
