import { Box, Container } from "@sembark-travel/ui/base"
import { Breadcrumbs } from "@sembark-travel/ui/router"
import { Helmet } from "react-helmet-async"
import { AddCabPriceCalculationMetricPreset } from "../../../CabPriceCalculationMetrics"
import { generatePath, useNavigate } from "../../../router-utils"

export default function CreateCabPriceCalculationMetricPresetPage() {
	const navigate = useNavigate()
	const listUrl = generatePath("/cab-price-calculation-metric-presets")
	function navigateToList() {
		return navigate("/cab-price-calculation-metric-presets")
	}
	return (
		<>
			<Helmet>
				<title>Add Cab Price Calculation Metric Preset</title>
			</Helmet>
			<Breadcrumbs
				title="Add Metric Preset"
				items={[
					[listUrl, "Metrics Presets"],
					["", "New"],
				]}
			/>
			<Box paddingX={{ md: "4" }} paddingY={{ md: "8" }}>
				<Box maxWidth="4xl" marginX="auto">
					<Box borderWidth={{ md: "1" }} rounded="md" bgColor="default">
						<Container paddingY="6">
							<AddCabPriceCalculationMetricPreset
								onSuccess={navigateToList}
								onCancel={navigateToList}
							/>
						</Container>
					</Box>
				</Box>
			</Box>
		</>
	)
}
