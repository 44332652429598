import { Box, Card, CardBody } from "@sembark-travel/ui/base"
import { Helmet } from "react-helmet-async"
import Logo from "../../images/Logo"
import { InvitedSignupForm } from "./../../Auth"
import { useNavigate } from "./../../router-utils"
import { useSearchParams } from "react-router-dom"
import { trackSignup } from "@sembark-travel/tracking"

export default function UserSignup() {
	const [searchParams] = useSearchParams()
	const navigate = useNavigate()
	const invite_token = searchParams.get("ref") || ""
	const email = searchParams.get("email") || ""
	const name = searchParams.get("name") || ""
	return (
		<>
			<Helmet>
				<title>Complete Your Registration</title>
			</Helmet>
			<Box paddingTop="4">
				<Box>
					<Box as={Logo} display="block" marginX="auto" />
				</Box>
				<Box
					as="h1"
					textAlign="center"
					marginY="8"
					fontWeight="normal"
					fontSize="xl"
				>
					Complete Your Registration
				</Box>
				<Box maxWidth="lg" marginX="auto" marginBottom="16">
					<Card>
						<CardBody paddingX="8" paddingY="8">
							<InvitedSignupForm
								inviteToken={invite_token}
								email={email}
								name={name}
								onSucess={(values) => {
									trackSignup("user")
									navigate(
										{
											pathname: "/login",
											search: `?email=${encodeURIComponent(values.email)}`,
										},
										{}
									)
								}}
							/>
						</CardBody>
					</Card>
				</Box>
			</Box>
		</>
	)
}
