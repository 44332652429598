import { Box, Container, Spinner, useId } from "@sembark-travel/ui/base"
import { Breadcrumbs } from "@sembark-travel/ui/router"
import { Helmet } from "react-helmet-async"
import {
	cabPriceCalculationMetricPresetsXHR,
	EditCabPriceCalculationMetricPreset,
} from "../../../../CabPriceCalculationMetrics"
import { useParams, useNavigate, generatePath } from "../../../../router-utils"
import { useXHR } from "@sembark-travel/xhr"
import useSWR from "swr"

export default function EditCabPriceCalculationMetricPresetItemPage() {
	const { presetId } = useParams(
		"/cab-price-calculation-metric-presets/:presetId"
	)
	return (
		<>
			<Helmet>
				<title>Update Cab Price Calculation Metric Preset</title>
			</Helmet>
			<Update presetId={presetId} />
		</>
	)
}

export function Update({ presetId }: { presetId: number | string }) {
	const xhr = useXHR()
	const refreshId = useId()
	const { data } = useSWR(
		`/api/cab-price-calculation-metric-presets/${presetId}?${refreshId}`,
		() => cabPriceCalculationMetricPresetsXHR(xhr).show(presetId)
	)
	const navigate = useNavigate()
	if (!data) {
		return <Spinner alignCenter padding="4" />
	}
	const preset = data.data
	const { name } = preset
	function navigateToList() {
		return navigate("/cab-price-calculation-metric-presets/:presetId", {
			params: {
				presetId: String(presetId),
			},
		})
	}
	return (
		<>
			<Breadcrumbs
				title="Update Metric Preset"
				items={[
					[
						generatePath("/cab-price-calculation-metric-presets"),
						"Metric Presets",
					],
					[
						generatePath("/cab-price-calculation-metric-presets/:presetId", {
							presetId: String(presetId),
						}),
						name,
					],
					["", "Update"],
				]}
			/>
			<Box paddingX={{ md: "4" }} paddingY={{ md: "8" }}>
				<Box maxWidth="4xl" marginX="auto">
					<Box borderWidth={{ md: "1" }} rounded="md" bgColor="default">
						<Container paddingY="6">
							<EditCabPriceCalculationMetricPreset
								preset={preset}
								onSuccess={navigateToList}
								onCancel={navigateToList}
							/>
						</Container>
					</Box>
				</Box>
			</Box>
		</>
	)
}
