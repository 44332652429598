import {
	Alert,
	Box,
	Spinner,
	Heading,
	Stack,
	Text,
	Icons,
	Dropdown,
} from "@sembark-travel/ui/base"
import { NavLink, ButtonLink } from "@sembark-travel/ui/router"
import { PERMISSIONS, useCheckPermissions } from "../Auth"
import { generatePath } from "../router-utils"
import MatchingQuotes from "./MatchingQuotes"
import { ITrip } from "./store"

export function SelectQuoteCreationFlow({
	trip,
	isFetching,
}: {
	trip: ITrip
	isFetching: boolean
}) {
	const { hasPermission } = useCheckPermissions()
	const canRequestQuote = hasPermission(PERMISSIONS.VIEW_TENANT_CONNECTIONS)
	const canCreateQuote = hasPermission(PERMISSIONS.CREATE_QUOTE)
	if (isFetching) {
		return <Spinner padding="4" alignCenter />
	}
	if (trip.cancellation_reason) {
		return (
			<Alert status="error" title="Trip Canceled">
				Quotes can not be created for canceled trips.
			</Alert>
		)
	}
	return (
		<Box paddingY="8" textAlign="center">
			<Box>
				<Box marginBottom="16" position="relative">
					<Box
						display="inlineBlock"
						borderBottomWidth="4"
						borderColor="default"
					>
						<Heading as="h3" fontSize="xl">
							New Quote
						</Heading>
					</Box>
					{canRequestQuote ? (
						<Box position="absolute" right="0" top="0" paddingRight="4">
							<Dropdown alignRight>
								<Dropdown.ToggleButton level="tertiary">
									<Icons.DotsVertical />
								</Dropdown.ToggleButton>
								<Dropdown.Menu style={{ width: "250px" }}>
									<NavLink
										to={generatePath("/trips/:tripId/quote-requests/new", {
											tripId: trip.id.toString(),
										})}
									>
										<Stack gap="1">
											<Box>Request Quote</Box>
											<Text color="muted" whiteSpace="normal" fontSize="sm">
												Request quotes from your connection to easily manage
												sales whereas your connection will handle the operation.
											</Text>
										</Stack>
									</NavLink>
								</Dropdown.Menu>
							</Dropdown>
						</Box>
					) : null}
				</Box>
				<Box display={{ xs: "block", sm: "flex" }}>
					{canCreateQuote ? (
						<Stack
							gap="10"
							flex="1"
							padding="4"
							marginBottom="4"
							borderRightWidth="1"
						>
							<Stack gap="4" maxWidth="2xl" marginX="auto" textAlign="center">
								{canRequestQuote ? (
									<Heading as="h4" fontSize="xl">
										Create Quote
									</Heading>
								) : null}
								<Text color="muted">
									Provide and manage hotel bookings and activities by creating
									quote.
								</Text>
							</Stack>
							<Box width="full" maxWidth="3xl" textAlign="left" marginX="auto">
								<MatchingQuotes trip={trip} />
							</Box>
							<Box>
								<ButtonLink
									to={generatePath("/trips/:tripId/new-quote", {
										tripId: trip.id.toString(),
									})}
									level="primary"
									size="lg"
								>
									Create Quote
								</ButtonLink>
							</Box>
						</Stack>
					) : null}
				</Box>
			</Box>
		</Box>
	)
}
