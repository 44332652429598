import { useEffect, useRef, useState } from "react"
import { createPortal } from "react-dom"

export const shadowRootSupported =
	typeof window !== "undefined" &&
	window.Element &&
	typeof window.Element.prototype?.attachShadow === "function"

export function ShadowRoot({
	delegatesFocus = false,
	mode = "open",
	children,
	getShadowRoot,
}: {
	delegatesFocus?: boolean
	mode?: ShadowRootMode
	children: React.ReactNode
	getShadowRoot?: (shadow: ShadowRoot | null) => void
}) {
	const placeholderRef = useRef<HTMLDivElement>(null)
	const [shadowRoot, setShadowRoot] = useState<ShadowRoot | undefined | null>()
	const key = `node_${mode}${delegatesFocus}`

	useEffect(() => {
		if (placeholderRef.current && !placeholderRef.current.shadowRoot) {
			const root = placeholderRef.current.attachShadow({
				delegatesFocus,
				mode,
			})
			setShadowRoot(root)
		}
	}, [delegatesFocus, mode])
	useEffect(() => {
		getShadowRoot?.(shadowRoot || null)
	}, [shadowRoot, getShadowRoot])

	return (
		<div ref={placeholderRef} key={key}>
			{!shadowRoot ? (
				<template {...{ shadowroot: "open" }}>{children}</template>
			) : (
				createPortal(children, shadowRoot)
			)}
		</div>
	)
}
