import { searchToQuery, Breadcrumbs } from "@sembark-travel/ui/router"
import { Helmet } from "react-helmet-async"
import { IAccount } from "../../../../Accounting/store"
import { UploadTransactionsCsv } from "./../../../../Accounting"
import {
	generatePath,
	useLocation,
	useNavigate,
} from "../../../../router-utils"
import { ForbidUnlessAuthorized, PERMISSIONS } from "../../../../Auth"

export default function TransactionsCsvUploadPage() {
	const location = useLocation()
	const navigate = useNavigate()
	const { account } = searchToQuery<{ account: IAccount }>(location?.search)
	const transactionsUrl = account?.id
		? generatePath("/accounting/accounts/:accountId", {
				accountId: account.id.toString(),
		  })
		: generatePath("/accounting/transactions")
	return (
		<>
			<Helmet>
				<title>Log Transactions via CSV Upload</title>
			</Helmet>
			<ForbidUnlessAuthorized
				permission={PERMISSIONS.VIEW_BANK_ACCOUNT_STATEMENTS}
			>
				<Breadcrumbs
					title="Upload Transaction"
					items={[
						[transactionsUrl, "Transactions"],
						["", "Upload CSV"],
					]}
				/>
				<UploadTransactionsCsv
					account={account}
					onSuccess={() => {
						navigate(transactionsUrl as "/accounting/transactions")
					}}
					onCancel={() => {
						navigate(transactionsUrl as "/accounting/transactions")
					}}
				/>
			</ForbidUnlessAuthorized>
		</>
	)
}
