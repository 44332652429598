import { useEffect, useRef, useState } from "react"
import { formatDate, setUnit } from "@sembark-travel/datetime-utils"
import {
	switchBtnClassName,
	counterItemClass,
	counterItemLabelClass,
	counterItemSelectClassName,
	timeClassName,
	countersClass,
} from "./datetime.css"
import { useDateTimeContext } from "./DateTimeContext"
import {
	VIEWS,
	formatHasAmPm,
	formatHasHours,
	formatHasMinutes,
	formatHasSeconds,
} from "./utils"

function Header() {
	const {
		dateFormat,
		value: selectedDate,
		viewDate,
		showView,
	} = useDateTimeContext()
	if (!dateFormat) return null

	const date = selectedDate || viewDate
	return (
		<thead key="h">
			<tr>
				<th
					className={switchBtnClassName}
					colSpan={4}
					onClick={() => showView(VIEWS["DAYS"])}
				>
					{formatDate(date, dateFormat)}
				</th>
			</tr>
		</thead>
	)
}

function Hours({ dayPart }: { dayPart?: string }) {
	const { value, onChange, viewDate, timeFormat } = useDateTimeContext()
	const date = value || viewDate
	const dateRef = useRef(date)
	dateRef.current = date
	const onChangeRef = useRef(onChange)
	onChangeRef.current = onChange
	useEffect(() => {
		if (dayPart && onChangeRef.current) {
			const hours = Number(formatDate(dateRef.current, "HH"))
			// PM => AM, we need to strip the 12+ hours
			if (dayPart === "AM" && hours >= 12) {
				onChangeRef.current(setUnit(dateRef.current, "hours", hours - 12))
				// AM => PM, we need to strip the 12- hours
			} else if (dayPart === "PM" && hours < 12) {
				onChangeRef.current(setUnit(dateRef.current, "hours", hours + 12))
			}
		}
	}, [dayPart])
	if (!timeFormat || !formatHasHours(timeFormat)) return null
	const isAmPm = Boolean(dayPart)
	const divisor = isAmPm ? 12 : 24
	let hours = parseInt(formatDate(date, "H"), 10) % divisor
	if (isAmPm && !hours) {
		// we may also get 0 and so, when in amPm mode, this should 12
		hours = divisor
	}
	return (
		<div className={counterItemClass}>
			<label className={counterItemLabelClass} htmlFor="tpdt_time_select_hours">
				Hours
			</label>
			<select
				id="tpdt_time_select_hours"
				value={hours}
				className={counterItemSelectClassName}
				onChange={(e) => {
					let hours = (divisor + parseInt(e.target.value)) % divisor
					hours = isAmPm && dayPart === "PM" ? hours + 12 : hours
					onChange && onChange(setUnit(date, "hours", hours))
				}}
			>
				{Array.from({ length: divisor }, (_, i) => (isAmPm ? i + 1 : i)).map(
					(i) => (
						<option key={i} value={i}>
							{i}
						</option>
					)
				)}
			</select>
		</div>
	)
}

function Minutes() {
	const { value, onChange, viewDate, timeFormat } = useDateTimeContext()
	if (!timeFormat || !formatHasMinutes(timeFormat)) return null
	const date = value || viewDate
	const minutes = parseInt(formatDate(date, "mm"), 10)
	const divisor = 60
	return (
		<div className={counterItemClass}>
			<label
				htmlFor="tpdt_time_select_minutes"
				className={counterItemLabelClass}
			>
				Minutes
			</label>
			<select
				id="tpdt_time_select_minutes"
				className={counterItemSelectClassName}
				value={minutes}
				onChange={(e) => {
					const minutes = Number(e.target.value)
					onChange && onChange(setUnit(date, "minutes", minutes))
				}}
			>
				{Array.from({ length: divisor }, (_, i) => i).map((i) => (
					<option key={i} value={i}>
						{i}
					</option>
				))}
			</select>
		</div>
	)
}

function Seconds() {
	const { value, onChange, viewDate, timeFormat } = useDateTimeContext()
	if (!timeFormat || !formatHasSeconds(timeFormat)) return null
	const date = value || viewDate
	const divisor = 60
	const seconds = parseInt(formatDate(date, "ss"), 10)
	return (
		<div className={counterItemClass}>
			<label
				className={counterItemLabelClass}
				htmlFor="tpdt_time_select_seconds"
			>
				Seconds
			</label>
			<select
				value={seconds}
				className={counterItemSelectClassName}
				id="tpdt_time_select_seconds"
				onChange={(e) => {
					const seconds = Number(e.target.value)
					onChange && onChange(setUnit(date, "seconds", seconds))
				}}
			>
				{Array.from({ length: divisor }, (_, i) => i).map((i) => (
					<option key={i} value={i}>
						{i}
					</option>
				))}
			</select>
		</div>
	)
}

function DayPart({
	dayPart,
	onChange,
}: {
	dayPart: string
	onChange: (dayPart: string) => void
}) {
	const { timeFormat } = useDateTimeContext()
	if (!timeFormat || !formatHasAmPm(timeFormat)) return null
	return (
		<div className={counterItemClass}>
			<label className={counterItemLabelClass} htmlFor="tpdt_time_select_am_pm">
				AM / PM
			</label>
			<select
				id="tpdt_time_select_am_pm"
				value={dayPart}
				className={counterItemSelectClassName}
				onChange={(e) => onChange(e.target.value || "AM")}
			>
				<option value="AM">AM</option>
				<option value="PM">PM</option>
			</select>
		</div>
	)
}

export default function TimesView() {
	const { value, viewDate, timeFormat } = useDateTimeContext()
	const date = value || viewDate
	const isAmPm = timeFormat && formatHasAmPm(timeFormat)
	const [dayPart, changeDayPart] = useState(isAmPm ? formatDate(date, "A") : "")
	if (!timeFormat) return null

	return (
		<div className={timeClassName}>
			<table>
				<Header />
				<tbody key="b">
					<tr>
						<td>
							<div className={countersClass}>
								<Hours dayPart={dayPart} />
								<Minutes />
								<Seconds />
								<DayPart dayPart={dayPart} onChange={changeDayPart} />
							</div>
						</td>
					</tr>
				</tbody>
			</table>
		</div>
	)
}
