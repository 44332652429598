import { Box } from "./Box"
import { SCREENS as screens } from "@sembark-travel/ui/theme"
import raf from "raf"
import {
	createContext,
	Fragment,
	useCallback,
	useContext,
	useEffect,
	useState,
} from "react"

function withoutUnits(withUnits: string): number {
	const value = withUnits.replace(/px/g, "")
	return Number(value)
}

const defaultBreakpoints = {
	sm: withoutUnits(screens.sm),
	md: withoutUnits(screens.md),
	lg: withoutUnits(screens.lg),
	xl: withoutUnits(screens.xl),
}

export function useBreakpoints(
	breakpoints: typeof defaultBreakpoints = defaultBreakpoints
) {
	const vw = useVW()
	return {
		vw: vw,
		xs: vw < breakpoints.sm,
		sm: vw >= breakpoints.sm && vw < breakpoints.md,
		md: vw >= breakpoints.md && vw < breakpoints.lg,
		lg: vw >= breakpoints.lg && vw < breakpoints.xl,
		xl: vw >= breakpoints.xl,
	}
}

const VWContext = createContext<number>(0)

export function VWProvider({ children }: { children: React.ReactNode }) {
	const [width, setWidth] = useState(
		typeof window !== "undefined" ? window.innerWidth : 0
	)
	useEffect(() => {
		const updateWidth = () => {
			raf(() => {
				setWidth(window.innerWidth)
			})
		}
		if (window) {
			setWidth(window.innerWidth)
			window.addEventListener("resize", updateWidth)
		}
		return () => {
			if (window) {
				return window.removeEventListener("resize", updateWidth)
			}
		}
	}, [])
	return <VWContext.Provider value={width}>{children}</VWContext.Provider>
}

export function useVW() {
	return useContext(VWContext)
}

/**
 * Join multiple attribute with •. A condition can also be passed
 */
export function joinAttributes(
	...args: Array<string | React.ReactNode | [boolean, string | React.ReactNode]>
): React.ReactNode {
	const elms = args
		.map((item) => {
			if (Array.isArray(item)) {
				const [shouldAdd, attribute] = item
				if (shouldAdd) return attribute
				return undefined
			}
			return item
		})
		.filter((item) => item !== undefined && item !== "" && item !== null)
	return (
		<>
			{elms.reduce((children: Array<React.ReactNode>, item, index) => {
				return children
					.concat([<Fragment key={index}>{item}</Fragment>])
					.concat(
						index !== elms.length - 1
							? [
									<Box
										as="span"
										display="inline"
										key={`${item}-seperator-${index}`}
										paddingX="2"
										color="muted"
									>
										•
									</Box>,
							  ]
							: []
					)
			}, [])}
		</>
	)
}

export function useSwitch(
	initialy = false
): [boolean, { on: () => void; off: () => void; toggle: () => void }] {
	const [isOn, onOrOff] = useState<boolean>(initialy)
	const on = useCallback(() => {
		onOrOff(true)
	}, [onOrOff])
	const off = useCallback(() => {
		onOrOff(false)
	}, [onOrOff])
	const toggle = useCallback(() => {
		onOrOff((isOn) => !isOn)
	}, [onOrOff])
	return [isOn, { on, off, toggle }]
}
