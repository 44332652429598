import { Helmet } from "react-helmet-async"
import { useParams } from "../../../../../../router-utils"
import { AllComments } from "../../../../../../Admin/Tenants"

export default function TenantAllCommentsPage() {
	const { tenantId } = useParams("/admin/tenants/:tenantId/comments")
	return (
		<>
			<Helmet>
				<title>Tenant Comments</title>
			</Helmet>
			<AllComments tenantId={Number(tenantId)} />
		</>
	)
}
