import {
	Client,
	TokenProvider,
	RegistrationState,
} from "@pusher/push-notifications-web"
import config from "../config"
import { getXSRFToken, xhr } from "@sembark-travel/xhr"
import { logError } from "@sembark-travel/logging"
import { useEffect } from "react"

type TUser = { uid: string }

let beamsClient: undefined | Client = undefined

let waitForServiceWorker = (_: ServiceWorkerRegistration) => {
	// this will be replaced by a resolver, so DO NOT PUT anything here
}

export function setCurrentSWRegistrationForNotications(
	registration: ServiceWorkerRegistration
) {
	waitForServiceWorker(registration)
}

const serviceWorkerIsReady = new Promise<ServiceWorkerRegistration>(
	(resolve) => {
		waitForServiceWorker = resolve
	}
)

async function getClient() {
	const swRegistration = await serviceWorkerIsReady
	if (!beamsClient) {
		beamsClient = new Client({
			instanceId: config.services.pusher_beams.instanceId,
			serviceWorkerRegistration: swRegistration,
		})
	}
	return beamsClient
}

export async function unsubscribeUserDeviceForPushNotifications() {
	try {
		const client = await getClient()
		await client.stop()
	} catch (e) {
		const error = e as Error
		console.error(error)
		alert(
			error.message ||
				"Unable to unsubscribe the device. Please try after sometime."
		)
	}
	setLastSubscriberUserId("")
	return false
}

const LAST_SUBSCRIBER_STORAGE_KEY = "bpn_subscriber_user"

function setLastSubscriberUserId(id: string) {
	try {
		window.localStorage.setItem(LAST_SUBSCRIBER_STORAGE_KEY, id)
	} catch (e) {
		console.log(e)
	}
}

function getLastSubscriberUserId(): string | null {
	try {
		const id = String(window.localStorage.getItem(LAST_SUBSCRIBER_STORAGE_KEY))
		if (!id) return null
		return id
	} catch (e) {
		console.log(e)
	}
	return null
}

export function useSyncUserDevicePushNotificationsSubscriptionStatus(
	user?: TUser
) {
	const { uid: userId } = user || {}
	useEffect(() => {
		if (userId) {
			syncUserDevicePushNotificationsSubscriptionStatus({ uid: userId })
		}
	}, [userId])
}

async function syncUserDevicePushNotificationsSubscriptionStatus(user: TUser) {
	try {
		await unsubscribeIfIncorrectUserForPushNotifications(user).catch(
			() => undefined
		)
		const lastSubscriberId = getLastSubscriberUserId()
		if (
			lastSubscriberId &&
			getUserIdForNotification(user) === lastSubscriberId
		) {
			console.info("Auto-Subscribed last subscriber.")
			await subscribeUserDeviceForPushNotifications(user)
		}
		// sync the stored subscriber id
		const subscriber_id = await getCurrentSubscribedUserId()
		if (subscriber_id) {
			setLastSubscriberUserId(subscriber_id)
		} else {
			// keep the last subscriber id
		}
	} catch (e) {
		console.error(e)
	}
}

async function unsubscribeIfIncorrectUserForPushNotifications(user: TUser) {
	const client = await getClient()
	// Checking the browser is associated with the correct user
	const userId = await client.getUserId()
	// Check if the Beams user matches the current user
	if (userId && userId !== getUserIdForNotification(user)) {
		console.warn("Incorrect user registered with device!")
		// Unregister for notifications
		try {
			await client.stop()
		} catch (e) {
			console.log(e)
		}
	}
}

function getUserIdForNotification(user: TUser) {
	return "users." + user.uid
}

async function getCurrentSubscribedUserId(): Promise<undefined | string> {
	try {
		const beamsClient = await getClient()
		// Checking the browser is associated with the correct user
		return beamsClient.getUserId()
	} catch (e) {
		console.log(e)
	}
	return undefined
}

export async function subscribeUserDeviceForPushNotifications(user: TUser) {
	const beamsClient = await getClient()
	if (await isUserDeviceSubscribedForPushNotifications(user)) {
		// already setup
		return
	}

	const subscriptionUserId = getUserIdForNotification(user)

	// Checking the browser is associated with the correct user
	try {
		await unsubscribeIfIncorrectUserForPushNotifications(user)
		await beamsClient.start() // start and register the device
		const { headerName: xsrfHeaderName, value: xsrfValue } = getXSRFToken(xhr)
		// register the user
		const beamsTokenProvider = new TokenProvider({
			url: xhr.defaults.baseURL + "/push-notifications/auth",
			credentials: "include",
			headers: {
				"X-App-Version": config.appVersion,
				Accept: "application/json",
				[xsrfHeaderName]: xsrfValue,
				"content-type": "application/x-www-form-urlencoded",
			},
		})
		setLastSubscriberUserId(subscriptionUserId)
		await beamsClient.setUserId(subscriptionUserId, beamsTokenProvider)
	} catch (e) {
		// stop if anything went wrong
		beamsClient.stop().catch(() => undefined)
		logError(e)
		throw e
	}
	console.log("Successfully registered device and subscribed!")
}

export async function getUserDeviceSubscriptionStateForPushNotifications(
	user: TUser
): Promise<RegistrationState> {
	const client = await getClient()
	if (!client) {
		return RegistrationState.PERMISSION_DENIED
	}
	const subscriber_id = await getCurrentSubscribedUserId()
	if (subscriber_id !== getUserIdForNotification(user)) {
		return RegistrationState.PERMISSION_DENIED
	}
	return client.getRegistrationState()
}

export async function isUserDeviceSubscribedForPushNotifications(
	user: TUser
): Promise<boolean> {
	try {
		return (
			(await getUserDeviceSubscriptionStateForPushNotifications(user)) ===
			RegistrationState.PERMISSION_GRANTED_REGISTERED_WITH_BEAMS
		)
	} catch (e) {
		console.log(e)
	}

	return false
}

export function askNotificationPermission(
	onSuccess: (status: NotificationPermission) => void
) {
	if (hasDeviceAllowedNotifications()) {
		onSuccess("granted")
		return
	}
	function handlePermission(permission: NotificationPermission) {
		// Whaeger the user ansers, we make sure Chrome stores the information
		if (!Notification.permission) {
			;(Notification as { permission: NotificationPermission }).permission =
				permission
		}

		onSuccess(permission || "default")
	}

	// Check whether browser supports the promise version of requestPermission()
	// Safari only supports the old callback-based version
	function checkNotificationPromise() {
		try {
			Notification.requestPermission().then()
		} catch (e) {
			return false
		}
		return true
	}

	if (!doesDeviceSupportNotifications()) {
		console.log("This browser/device does not support notifications")
		handlePermission("default")
	} else {
		// use promise based or callback based request
		if (checkNotificationPromise()) {
			Notification.requestPermission().then(handlePermission)
		} else {
			Notification.requestPermission(handlePermission)
		}
	}
}

// Check if the browser supports notifications
export function doesDeviceSupportNotifications(): boolean {
	return typeof window !== "undefined" && Boolean(window.Notification)
}

export function getDeviceNotificationPermissionsStatus(): NotificationPermission {
	return (
		(doesDeviceSupportNotifications() ? Notification.permission : undefined) ||
		"default"
	)
}

export function hasDeviceAllowedNotifications(): boolean {
	return getDeviceNotificationPermissionsStatus() === "granted"
}
