import { Helmet } from "react-helmet-async"
import { PaymentsList } from "./../../../Payments"
import { ForbidUnlessAuthorized, PERMISSIONS } from "../../../Auth"

export default function IncomingPaymentsRoute(
	props: Omit<React.ComponentProps<typeof PaymentsList>, "title" | "credit">
) {
	return (
		<>
			<Helmet>
				<title>Incoming Payments</title>
			</Helmet>
			<ForbidUnlessAuthorized permission={PERMISSIONS.VIEW_INCOMING_PAYMENTS}>
				<PaymentsList {...props} title="Incoming Payments" credit />
			</ForbidUnlessAuthorized>
		</>
	)
}
