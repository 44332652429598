import {
	Box,
	Button,
	Icons,
	Inline,
	Heading,
	Stack,
	Text,
	Divider,
} from "@sembark-travel/ui/base"
import { Dialog } from "@sembark-travel/ui/dialog"
import { Col, Grid, joinAttributes } from "@sembark-travel/ui/base"
import { useXHR } from "@sembark-travel/xhr"
import pluralize from "pluralize"
import React, { useRef } from "react"
import { $PropertyType, Optional, Required } from "utility-types"
import * as Validator from "yup"
import { SelectHotelPaymentReferences } from "./References"
import {
	Form,
	FieldArray,
	withServerErrors,
	validateFormValues,
	SubmissionError,
	TextInputField,
	SelectField,
	arrayMutators,
	GetFieldValue,
	PercentageInputField,
} from "@sembark-travel/ui/form"

interface IBreakdown {
	reference?: { id: number; name: string }
	day_offset: number
	amount_share: number
}

interface NewItemCredentials {
	breakdowns?: IBreakdown[]
}
const validationSchema = Validator.object().shape({
	breakdowns: Validator.array()
		.of(
			Validator.object().shape({
				reference: Validator.object().required("Reference field is required"),
				day_offset: Validator.number()
					.integer()
					.required("Day offset field is required"),
				amount_share: Validator.number()
					.integer()
					.positive("Amount share should be positive integer")
					.max(100, "Amount share can not be greater then 100")
					.required("Amount share field is required"),
			})
		)
		.min(1, "Atleast on breakdown is required."),
})
const INITIAL_VALUES: Required<NewItemCredentials, "breakdowns"> = {
	breakdowns: [
		{
			reference: undefined,
			day_offset: 0,
			amount_share: 50,
		},
	],
}

interface NewItemProps {
	onCancel?: () => void
	onSuccess: () => void
	initialValues?: Optional<NewItemCredentials>
}

export function NewItemForm({
	onCancel,
	onSuccess,
	initialValues: defaultInitialValues = {},
}: NewItemProps) {
	const xhr = useXHR()
	const initialValues = useRef({
		...INITIAL_VALUES,
		...defaultInitialValues,
	}).current
	return (
		<Form<NewItemCredentials>
			initialValues={initialValues}
			validate={validateFormValues(validationSchema)}
			onSubmit={withServerErrors(async (values) => {
				const totalAmountShare = getTotalAmountShare(values.breakdowns)
				if (totalAmountShare !== 100) {
					throw Error(
						`Total amount share should be 100%, currently ${totalAmountShare}% only`
					)
				}
				await xhr.post("/hotel-payment-preferences", {
					breakdowns: values.breakdowns
						? values.breakdowns.map((breakdown) => ({
								...breakdown,
								reference: breakdown.reference ? breakdown.reference.id : null,
							}))
						: [],
				})
				onSuccess()
			})}
			subscription={{ submitting: true }}
			mutators={{ ...arrayMutators }}
		>
			{({ submitting, handleSubmit }) => (
				<form noValidate onSubmit={handleSubmit}>
					<Stack gap="4">
						<Stack gap="2">
							<Heading as="h5" fontSize="md">
								Please provide the details for the new payment preference that
								you need for a hotel.
							</Heading>
							<Text>
								For example: <b>100% 2 days before Checkin</b>. Here Checkin is{" "}
								<em>Reference</em>, 2 Days is the <em>offset (negative)</em> and
								100% is the <em>Amount Share</em>.
							</Text>
						</Stack>
						<FieldArray<IBreakdown> name="breakdowns">
							{({ fields }) => (
								<Stack as="ol">
									{fields.map((name, index) => (
										<Box as="li" key={name} paddingY="2" borderBottomWidth="1">
											<Grid gap="4">
												<Col xs={12} sm>
													<SelectField
														select={SelectHotelPaymentReferences}
														name={`${name}.reference`}
														label="Reference Event"
														required
														fetchOnMount
														multiple={false}
														placeholder="Select a reference..."
													/>
												</Col>
												<Col xs={12} sm>
													<TextInputField
														label="Day offset from reference"
														name={`${name}.day_offset`}
														required
														type="number"
													/>
												</Col>
												<Col xs={12} sm>
													<PercentageInputField
														label="Amount share from total amount"
														name={`${name}.amount_share`}
														required
														type="number"
														min={1}
														max={10000}
													/>
												</Col>
												<Col xs={12} sm="auto" marginTop="6">
													{Number(fields.length) > 1 ? (
														<Button
															level="tertiary"
															onClick={() => fields.remove(index)}
															size="sm"
														>
															<Icons.Cancel /> Remove
														</Button>
													) : null}
												</Col>
											</Grid>
										</Box>
									))}
									<GetFieldValue<Array<IBreakdown>> name="breakdowns">
										{({ value: breakdowns }) => {
											const totalAmountShare = getTotalAmountShare(breakdowns)
											return totalAmountShare < 100 ? (
												<Box as="li" key="add_more" paddingTop="4">
													<Button
														size="sm"
														onClick={() =>
															fields.push({
																amount_share: 100 - totalAmountShare,
																day_offset: 0,
															})
														}
													>
														<Icons.Plus /> Add More
													</Button>
												</Box>
											) : null
										}}
									</GetFieldValue>
								</Stack>
							)}
						</FieldArray>
						<GetFieldValue<Array<IBreakdown>> name="breakdowns">
							{({ value: breakdowns }) => (
								<PreferenceName breakdowns={breakdowns} />
							)}
						</GetFieldValue>
						<SubmissionError />
						<Divider sm />
						<Inline gap="4">
							<Button type="submit" disabled={submitting}>
								{submitting ? "Saving..." : "Save Preference"}
							</Button>
							{onCancel ? (
								<Button onClick={onCancel} level="tertiary">
									Cancel
								</Button>
							) : null}
						</Inline>
					</Stack>
				</form>
			)}
		</Form>
	)
}

function getTotalAmountShare(
	breakdowns: $PropertyType<NewItemCredentials, "breakdowns">
) {
	return (breakdowns || []).reduce(
		(total, { amount_share }) => Number(total) + Number(amount_share),
		0
	)
}

export function AddHotelPaymentPreferenceDialog({
	open,
	onClose,
	initialValues,
}: {
	open: boolean
	onClose: () => void
	initialValues?: Optional<NewItemCredentials>
}) {
	return (
		<Dialog
			open={open}
			onClose={onClose}
			title="Add new Hotel Payment Preference"
		>
			<Dialog.Body>
				<NewItemForm
					initialValues={initialValues}
					onSuccess={onClose}
					onCancel={onClose}
				/>
			</Dialog.Body>
		</Dialog>
	)
}

function getBreakdownName(breakdown: IBreakdown): React.ReactNode {
	const { reference, day_offset, amount_share } = breakdown
	if (!reference || isNaN(day_offset) || isNaN(amount_share)) return null
	return (
		<Box display="inline">
			<span>{amount_share}%</span>{" "}
			{day_offset !== 0 ? (
				<span>{pluralize("day", Math.abs(day_offset), true)}</span>
			) : null}{" "}
			<em>{day_offset < 0 ? " before" : day_offset > 0 ? " after" : " on"}</em>{" "}
			<span>{reference.name}</span>
		</Box>
	)
}

function PreferenceName({ breakdowns }: { breakdowns: Array<IBreakdown> }) {
	const parts = breakdowns.map(getBreakdownName).filter((i) => i)
	if (!parts.length) return null
	return (
		<Box
			boxShadow="lg"
			rounded="lg"
			borderWidth="1"
			padding="4"
			bgColor="inset"
			marginBottom="4"
		>
			<Box
				as="h5"
				marginBottom="3"
				textTransform="uppercase"
				fontWeight="semibold"
				letterSpacing="wider"
				color="muted"
				fontSize="sm"
			>
				Summary
			</Box>
			<Box fontWeight="semibold" fontSize="xl">
				{joinAttributes(...parts)}
			</Box>
		</Box>
	)
}
