import { useParams } from "../../../../router-utils"
import { TransportServiceProviderDetails } from "./../../../../TransportServiceProviders"
import { Outlet } from "react-router-dom"

export default function TransportServiceProvider() {
	const { transportServiceProviderId } = useParams(
		"/transport-service-providers/:transportServiceProviderId"
	)
	return (
		<TransportServiceProviderDetails
			transportServiceProviderId={transportServiceProviderId}
		>
			{({ transportServiceProvider, revalidate }) => (
				<Outlet
					context={{
						transportServiceProvider,
						revalidate,
					}}
				/>
			)}
		</TransportServiceProviderDetails>
	)
}
