import { Box, Button } from "@sembark-travel/ui/base"
import { Dialog } from "@sembark-travel/ui/dialog"
import { FileInputField, useFieldValue } from "./Form"
import { useState, useRef } from "react"
import type { Crop } from "react-image-crop"
import ReactCrop, { makeAspectCrop, convertToPixelCrop } from "react-image-crop"

import "react-image-crop/dist/ReactCrop.css"

export type ImageCropInputFieldProps = React.ComponentProps<
	typeof FileInputField
> & {
	crop?:
		| {
				aspect?: number
				minWidth?: number
				maxWidth?: number
				minHeight?: number
				maxHeight?: number
				keepSelection?: boolean
				disabled?: boolean
				locked?: boolean
				circularCrop?: boolean
		  }
		| false
}

export function ImageCropInputField({
	name,
	crop: initialCrop = {},
	...props
}: ImageCropInputFieldProps) {
	// holds the dataurl of image
	const [src, setSrc] = useState<string | null>(null)
	// needs to reset the file input on crop cancellation
	const [fileResetKey, setFileResetKey] = useState<number>(0)
	const imgRef = useRef<HTMLImageElement | null>(null)
	function readFile(file: File) {
		const reader = new FileReader()
		reader.addEventListener("load", () => setSrc(reader.result as string))
		reader.readAsDataURL(file)
	}
	const [crop, setCrop] = useState<Crop>()
	const { onChange: changeFile } = useFieldValue<File | undefined | null>(name)
	function cancelCrop() {
		setFileResetKey((key) => key + 1)
		setSrc(null)
		changeFile(null)
	}
	return (
		<>
			<FileInputField
				name={name}
				key={fileResetKey}
				{...props}
				onChange={(files) => {
					setCrop(undefined)
					const file = files?.[0] || null
					changeFile(file)
					if (file) {
						readFile(file)
					} else {
						setSrc(null)
					}
				}}
			/>
			{initialCrop !== false ? (
				<Dialog
					open={Boolean(src)}
					sm={initialCrop?.aspect === 1}
					onClose={cancelCrop}
					title="Crop Image"
				>
					<Dialog.Body>
						{!src ? (
							<Box>Please select a valid image file.</Box>
						) : (
							<ReactCrop
								aspect={initialCrop.aspect}
								crop={crop}
								ruleOfThirds
								onChange={setCrop}
								minWidth={initialCrop.minWidth}
								maxWidth={initialCrop.maxWidth}
								minHeight={initialCrop.minHeight}
								maxHeight={initialCrop.maxHeight}
								keepSelection={initialCrop.keepSelection}
								disabled={initialCrop.disabled}
								locked={initialCrop.locked}
								circularCrop={initialCrop.circularCrop}
							>
								<img
									src={src}
									ref={imgRef}
									alt="Croppable"
									onLoad={(e) => {
										if (initialCrop.aspect) {
											const { width, height } = e.currentTarget
											setCrop(
												aspectPixelCrop(width, height, initialCrop.aspect)
											)
										}
									}}
								/>
							</ReactCrop>
						)}
					</Dialog.Body>
					<Dialog.Footer>
						<Button
							level="primary"
							type="button"
							onClick={async () => {
								if (imgRef.current && crop) {
									const file = await getCroppedImg(imgRef.current, crop)
									changeFile(props.multiple ? [file] : file)
									setSrc(null)
								} else {
									cancelCrop()
								}
							}}
						>
							Done
						</Button>
						<Button type="button" onClick={cancelCrop}>
							Cancel
						</Button>
					</Dialog.Footer>
				</Dialog>
			) : null}
		</>
	)
}

export function getCroppedImg(
	image: HTMLImageElement,
	crop: Crop,
	scale = 1,
	rotate = 0
): Promise<Blob> {
	const canvas = document.createElement("canvas")
	const ctx = canvas.getContext("2d")
	if (!ctx) {
		throw new Error("Unable to draw image to canvas")
	}
	const scaleX = image.naturalWidth / image.width
	const scaleY = image.naturalHeight / image.height
	const pixelRatio = window.devicePixelRatio || 1
	canvas.width = Math.floor(crop.width * scaleX * pixelRatio)
	canvas.height = Math.floor(crop.height * scaleY * pixelRatio)

	ctx.scale(pixelRatio, pixelRatio)
	ctx.imageSmoothingQuality = "high"

	const TO_RADIANS = Math.PI / 180

	const cropX = crop.x * scaleX
	const cropY = crop.y * scaleY
	const rotateRads = rotate * TO_RADIANS
	const centerX = image.naturalWidth / 2
	const centerY = image.naturalHeight / 2
	ctx.save()
	// 5) Move the crop origin to the canvas origin (0,0)
	ctx.translate(-cropX, -cropY)
	// 4) Move the origin to the center of the original position
	ctx.translate(centerX, centerY)
	// 3) Rotate around the origin
	ctx.rotate(rotateRads)
	// 2) Scale the image
	ctx.scale(scale, scale)
	// 1) Move the center of the image to the origin (0,0)
	ctx.translate(-centerX, -centerY)
	ctx.drawImage(
		image,
		0,
		0,
		image.naturalWidth,
		image.naturalHeight,
		0,
		0,
		image.naturalWidth,
		image.naturalHeight
	)

	ctx.restore()

	const src = image.src

	let fileType = "image/jpeg"
	if (src.indexOf(";base64") !== -1) {
		fileType = src.split(";base64")[0]
		fileType = fileType.replace("data:", "")
	}

	if (!HTMLCanvasElement.prototype.toBlob) {
		// polyfill the canvas.toBlob
		Object.defineProperty(HTMLCanvasElement.prototype, "toBlob", {
			value: function (callback: BlobCallback, type: string, quality?: number) {
				const binStr = atob(this.toDataURL(type, quality).split(",")[1]),
					len = binStr.length,
					arr = new Uint8Array(len)
				for (let i = 0; i < len; i++) {
					arr[i] = binStr.charCodeAt(i)
				}
				callback(new Blob([arr], { type: type || "image/png" }))
			},
		})
	}

	// As a blob
	return new Promise((resolve, reject) => {
		canvas.toBlob((blob) => {
			if (!blob) {
				return reject("Unable to convert canvas to image.")
			}
			resolve(blob)
		}, fileType)
	})
}

function aspectPixelCrop(
	mediaWidth: number,
	mediaHeight: number,
	aspect: number
) {
	return convertToPixelCrop(
		makeAspectCrop(
			{
				unit: "%",
				width: 90,
				x: 0,
				y: 0,
			},
			aspect,
			mediaWidth,
			mediaHeight
		),
		mediaWidth,
		mediaHeight
	)
}
