import { QuoteRoute } from "../../../../../../Trips"
import { useTripDetailsOutletContext } from "../../_trip-details-context"
import { useParams } from "../../../../../../router-utils"
import { Helmet } from "react-helmet-async"

export default function QuoteDetailsPage() {
	const { trip, onChange } = useTripDetailsOutletContext()
	const { quoteId } = useParams("/trips/:tripId/quotes/:quoteId")
	return (
		<>
			<Helmet>
				<title>Quote Details</title>
			</Helmet>
			<QuoteRoute
				quoteId={quoteId}
				readOnly={Boolean(trip.cancellation_reason)}
				canBeConverted={!trip.converted_at}
				canSendToHolding={!trip.on_hold_at}
				onChange={onChange}
			/>
		</>
	)
}
