import {
	Button,
	Inline,
	Stack,
	Col,
	Divider,
	Grid,
	Heading,
	Text,
	Icons,
} from "@sembark-travel/ui/base"
import { Dialog } from "@sembark-travel/ui/dialog"
import { showSnackbar } from "@sembark-travel/ui/snackbar"
import {
	Form,
	TextInputField,
	SubmissionError,
	withServerErrors,
	validateFormValues,
	GetFieldValue,
	isTruthy,
	switchValidator,
	RadioInputField,
	PhoneNumberValidator,
	TextAreaInputField,
	PhoneInputField,
} from "@sembark-travel/ui/form"
import { useXHR } from "@sembark-travel/xhr"
import { useMemo, useRef } from "react"
import {
	string as stringValidator,
	object as objectValidator,
	array as arrayValidator,
	AnyObjectSchema,
} from "yup"
import { AddressInputField, TAddressInputFieldValue } from "../Addresses"
import { ITripSource } from "./store"
import { Optional } from "utility-types"
import { IPhoneNumber } from "../Contacts"

export function NewItem({
	onSuccess,
	...props
}: {
	onSuccess: (tripSource: ITripSource) => void
} & Omit<
	React.ComponentProps<typeof NewItemForm>,
	"onSubmit" | "includeContactPerson"
>) {
	const xhr = useXHR()
	return (
		<NewItemForm
			{...props}
			includeContactPerson
			onSubmit={async (values: unknown) => {
				const tripSource = await xhr.post("/trip-sources", values)
				showSnackbar("New source added successfully.")
				onSuccess(tripSource.data.data)
				return tripSource
			}}
		/>
	)
}

type NewItemCredentials = {
	name: string
	short_name: string
	address: TAddressInputFieldValue
	is_agent: "1" | "0"
	contact?: {
		name?: string
		email?: string
		phone_numbers?: Array<IPhoneNumber<unknown>>
	}
}

const defaultInitialValues: NewItemCredentials = {
	name: "",
	short_name: "",
	is_agent: "1",
	address: {
		line_1: "",
		line_2: "",
		landmark: "",
		postcode: "",
		phone_numbers: [
			{
				country_code: "IN",
				phone_number: "+91",
				number: "",
			},
		],
		location: undefined,
		city: undefined,
		state: undefined,
		country: undefined,
		email: "",
		type: "",
	},
}

export function NewItemForm({
	onSubmit,
	onCancel,
	initialValues: propInitialValues = {},
	minimalAddress,
	includeContactPerson,
	hideTypeToggle,
}: {
	onSubmit: (data: unknown) => Promise<unknown>
	onCancel?: () => void
	initialValues?: Partial<NewItemCredentials>
	minimalAddress?: boolean
	includeContactPerson?: boolean
	/**
	 * Hide the type toggle options
	 */
	hideTypeToggle?: boolean
}) {
	const initialValuesRef = useRef({
		name: propInitialValues?.name || "",
		short_name: propInitialValues?.short_name || "",
		is_agent:
			propInitialValues?.is_agent !== undefined
				? propInitialValues.is_agent
				: "1",
		address: {
			...defaultInitialValues.address,
			...propInitialValues.address,
			phone_numbers:
				propInitialValues.address?.phone_numbers ||
				defaultInitialValues.address.phone_numbers,
		},
	})
	const validate = useMemo(() => {
		const validationSchema = objectValidator().shape({
			name: stringValidator()
				.required("Name field is required")
				.max(100, "Please use 100 or less characters for name"),
			short_name: stringValidator()
				.required("Short name field is required")
				.min(5, "Please use atleast 5 characters for short name")
				.max(15, "Please use between 5 to 15 characters for short name"),
			is_agent: switchValidator().required(
				"Please select or deselect the checkbox"
			),
			address: objectValidator().when(
				"is_agent",
				(value: boolean, schema: AnyObjectSchema) => {
					if (isTruthy(value)) {
						return schema.shape({
							city: objectValidator().required("Please provide a city"),
							state: objectValidator().required("Please provide a state"),
							country: objectValidator().required("Please select a country"),
							phone_numbers: arrayValidator()
								.of(PhoneNumberValidator("Phone Number", true))
								.nullable(true),
						})
					}
					return schema
				}
			),
			contact: !includeContactPerson
				? objectValidator().nullable()
				: objectValidator().when(
						"is_agent",
						(value: boolean, schema: AnyObjectSchema) => {
							if (isTruthy(value)) {
								return schema.shape({
									name: stringValidator().required(
										"Please provide contact person's name"
									),
									email: stringValidator()
										.required("Please provide contact person's email")
										.email("Please provide a valid email address"),
									phone_numbers: arrayValidator()
										.of(PhoneNumberValidator("Phone Number"))
										.nullable(false),
								})
							}
							return schema.nullable()
						}
				  ),
		})
		return validateFormValues(validationSchema)
	}, [includeContactPerson])
	return (
		<Form<NewItemCredentials>
			initialValues={initialValuesRef.current}
			validate={validate}
			onSubmit={withServerErrors(async (values) => {
				const { address, contact, is_agent, ...otherData } = values
				const data = {
					...otherData,
					is_agent: isTruthy(is_agent) ? 1 : 0,
					contact:
						isTruthy(is_agent) && contact
							? {
									...contact,
									phone_numbers: contact.phone_numbers
										?.filter((p) => p.number)
										.map((p) => ({
											...p,
											number: String(p.number).replace(/[^\d]/gi, ""),
										})),
							  }
							: null,
					address: isTruthy(is_agent)
						? {
								...address,
								phone_numbers: address?.phone_numbers
									?.filter((p) => p.number)
									.map((p) => ({
										...p,
										number: String(p.number).replace(/[^\d]/gi, ""),
									})),
								location: address.location?.name,
								city: address.city?.name,
								state: address.state?.name,
								country: address.country?.name,
						  }
						: null,
				}
				return await onSubmit(data)
			})}
			subscription={{ submitting: true }}
		>
			{({ submitting, handleSubmit }) => (
				<form noValidate onSubmit={handleSubmit}>
					<Stack gap="4">
						{hideTypeToggle ? null : (
							<GetFieldValue<boolean> name="is_agent">
								{({ value }) => (
									<Inline
										rounded="md"
										overflow="hidden"
										borderWidth="1"
										collapseBelow="sm"
									>
										<Stack
											padding="4"
											bgColor={isTruthy(value) ? "primary" : "default"}
											as="label"
											cursor="pointer"
										>
											<RadioInputField
												name="is_agent"
												label="B2B Agent / Online Marketplace"
												help={
													<Stack gap="1">
														<Text>
															The source provides leads directly or via an
															Online Marketplace. Accounting should be managed
															for both guest and the source. The source acts as
															an agent/intermediator till the end of trip
														</Text>
														<Text fontWeight="semibold">
															Ex: ABC Holidays, Travel Triangle, Thrillophilia
															etc.
														</Text>
													</Stack>
												}
												value="1"
											/>
										</Stack>
										<Stack
											padding="4"
											bgColor={!isTruthy(value) ? "primary" : undefined}
											as="label"
											cursor="pointer"
										>
											<RadioInputField
												name="is_agent"
												label="Direct / Website / Referrals / Ads / Lead Sellers"
												help={
													<Stack gap="1">
														<Text>
															Leads from these sources are received directly or
															via portal but they don't act as
															agent/intermediator after lead generation. All the
															accounting should be managed for Guest only.
														</Text>
														<Text fontWeight="semibold">
															Ex: Direct Call / WhatsApp / Email, Website /
															Landing Pages, Referrals, TripCrafters etc.
														</Text>
													</Stack>
												}
												value="0"
											/>
										</Stack>
									</Inline>
								)}
							</GetFieldValue>
						)}
						<Grid gap="4">
							<Col sm={12} md={3}>
								<Stack gap="1">
									<Heading as="h3">Basic Details</Heading>
									<Text color="muted">
										Provide basic details such as name and a short name.
									</Text>
								</Stack>
							</Col>
							<Col>
								<Stack gap="6">
									<Grid gap="4">
										<Col xs={12} sm>
											<GetFieldValue<boolean> name="is_agent">
												{({ value }) => (
													<TextInputField
														label={
															isTruthy(value)
																? "Agency / Company Full Name"
																: "Full Name"
														}
														name="name"
														type="text"
														placeholder={
															isTruthy(value)
																? "e.g. XYZ Travel Agency Pvt. Ltd."
																: "e.g. Landing Page"
														}
														required
														maxWidth="xs"
													/>
												)}
											</GetFieldValue>
										</Col>
										<Col>
											<TextInputField
												label="Short Name"
												name="short_name"
												placeholder="e.g. XYZ Travels"
												type="text"
												required
												min={5}
												style={{ maxWidth: "200px" }}
											/>
										</Col>
									</Grid>
								</Stack>
							</Col>
						</Grid>
						<GetFieldValue<boolean> name="is_agent">
							{({ value }) =>
								!isTruthy(value) ? null : (
									<>
										{includeContactPerson ? (
											<>
												<Divider sm />
												<Grid gap="4">
													<Col sm={12} md={3}>
														<Stack gap="1">
															<Heading as="h3">Contact Person</Heading>
															<Text color="muted">
																Please provide the details of Contact/Enquiry
																Person
															</Text>
														</Stack>
													</Col>
													<Col>
														<Stack gap="4">
															<Grid gap="4">
																<Col>
																	<TextInputField
																		name="contact.name"
																		label="Name"
																		type="text"
																		required
																		placeholder="e.g. John doe"
																	/>
																</Col>
																<Col>
																	<TextInputField
																		name="contact.email"
																		label="Email"
																		type="email"
																		placeholder="e.g. user@domain.com"
																	/>
																</Col>
															</Grid>
															<PhoneInputField
																name="contact.phone_numbers"
																label="Phone Number(s)"
																multi
																required
															/>
														</Stack>
													</Col>
												</Grid>
											</>
										) : null}
										<Divider sm />
										<Grid gap="4">
											<Col sm={12} md={3}>
												<Stack gap="1">
													<Heading as="h3">Address</Heading>
													<Text color="muted">
														Please provide address details of the business.
													</Text>
												</Stack>
											</Col>
											<Col>
												<AddressInputField
													name="address"
													onlyCityStateCountry={minimalAddress}
													hideContactDetails={includeContactPerson}
												/>
											</Col>
										</Grid>
										{!minimalAddress ? (
											<>
												<Divider sm />
												<Grid gap="4">
													<Col xs={12} sm={3}>
														<Stack gap="1">
															<Heading as="h5" fontSize="md">
																<Icons.BankNotes size="5" /> Billing Details
															</Heading>
															<Text color="muted">
																Please Provide any aditional billing details
																e.g. Billing Name, CIN, PAN, GST etc.
															</Text>
														</Stack>
													</Col>
													<Col>
														<Stack gap="4">
															<TextInputField
																name="address.addressing_name"
																label="Addressing Name on Bills/Invoices"
																type="text"
																required
																placeholder="e.g. ACME Travel Holidays Pvt. Ltd."
															/>
															<TextAreaInputField
																name="address.billing_details"
																label="Aditional Details"
																placeholder={
																	"e.g. PAN: SSSSS1111A\nGSTIN: GYDBSGH123123HKKG"
																}
															/>
														</Stack>
													</Col>
												</Grid>
											</>
										) : null}
									</>
								)
							}
						</GetFieldValue>
						<Divider sm />
						<Grid gap="4">
							<Col sm={12} md={{ offset: 3, span: 9 }}>
								<Stack gap="4" paddingLeft={{ md: "4" }}>
									<SubmissionError />
									<Inline gap="4">
										<Button type="submit" disabled={submitting}>
											{submitting ? "Saving..." : "Save Details"}
										</Button>
										{onCancel ? (
											<Button
												onClick={onCancel}
												level="tertiary"
												disabled={submitting}
											>
												Cancel
											</Button>
										) : null}
									</Inline>
								</Stack>
							</Col>
						</Grid>
					</Stack>
				</form>
			)}
		</Form>
	)
}

export function AddTripSourceDialog({
	open,
	onClose,
	onSuccess,
	...props
}: {
	open: boolean
	onClose: () => void
} & Optional<
	Omit<React.ComponentProps<typeof NewItem>, "onCancel">,
	"onSuccess"
>) {
	return (
		<Dialog open={open} onClose={onClose} lg title={`Add Trip Source Details`}>
			<Dialog.Body>
				<NewItem
					{...props}
					onSuccess={async (tripSource) => {
						if (onSuccess) {
							onSuccess(tripSource)
						} else {
							onClose()
						}
					}}
					onCancel={onClose}
				/>
			</Dialog.Body>
		</Dialog>
	)
}
