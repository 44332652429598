import useSWR from "swr"
import { useXHR, XHRInstance } from "@sembark-travel/xhr"
import { IAddress } from "./../Addresses"
import { TTripDestination } from "../TripDestinations"
import { IPhoneNumber } from "../Contacts/store"
import { T_BankAccount } from "../BankAccounts"
import { TMedia } from "../Media"

export type TTenantBrandAddress = IAddress & {
	trip_destinations?: Array<TTripDestination>
}

export type TTenantBrand = {
	id: number
	name: string
	short_name: string
	color_theme?: string
	helpline_remarks: string
	is_primary: boolean
	profile_image_url?: string
	created_at: string
	updated_at?: string
	deleted_at?: string
	addresses: Array<TTenantBrandAddress>
	phone_numbers?: Array<IPhoneNumber<TTenantBrand>>
	trip_destinations?: Array<TTripDestination>
	bank_accounts?: Array<T_BankAccount>
	header_image_url?: string
	footer_image_url?: string
	header_media?: TMedia
	footer_media?: TMedia
}

function XHR(xhr: XHRInstance) {
	return {
		async show(id: number | string): Promise<TTenantBrand> {
			return xhr
				.get(`/tenant-brands/${id}`, {
					params: {
						include: "addresses,trip_destinations",
					},
				})
				.then((resp) => resp.data.data)
		},
	}
}

export function useTenantBrand(tenantBrandId?: number | string) {
	const xhr = useXHR()
	const {
		data,
		error,
		mutate: revalidate,
		isValidating,
	} = useSWR(
		tenantBrandId ? `/api/tenant-brands/${tenantBrandId}` : null,
		() =>
			tenantBrandId
				? XHR(xhr).show(tenantBrandId)
				: Promise.reject("Please select an id")
	)
	const isFetching = !data && !error
	return {
		tenantBrand: data,
		isFetching,
		isValidating,
		refresh: revalidate,
		error,
	}
}
