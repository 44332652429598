import {
	Stack,
	Text,
	Alert,
	Spinner,
	Container,
	Heading,
	Table,
	Grid,
	Col,
	Icons,
	Inline,
	Button,
} from "@sembark-travel/ui/base"
import { ButtonLink, Link, Breadcrumbs } from "@sembark-travel/ui/router"
import { useXHR } from "@sembark-travel/xhr"
import useSWR from "swr"
import { XHR } from "./store"
import { generatePath, useNavigate } from "./../router-utils"
import {
	ConfirmationDialog,
	ConfirmationDialogProvider,
} from "@sembark-travel/ui/dialog"
import { showSnackbar } from "@sembark-travel/ui/snackbar"

export function TeamItem({ teamId }: { teamId: string | number }) {
	const xhr = useXHR()
	const { data, error } = useSWR(`/api/teams/${teamId}`, () =>
		XHR(xhr).show(teamId)
	)
	const navigate = useNavigate()
	if (!data) {
		if (!error) {
			return <Spinner alignCenter padding="8" />
		}
		return (
			<Alert status="error">{error.message || "Something went wrong"}</Alert>
		)
	}
	return (
		<>
			<Breadcrumbs
				title="Teams"
				items={[
					[generatePath("/org/teams"), "Teams"],
					["", data.name],
				]}
				actions={
					<Inline gap="4">
						<ButtonLink
							to={generatePath("/teams/:teamId/edit", {
								teamId: data.id.toString(),
							})}
							size="sm"
						>
							<Icons.Pencil /> Edit
						</ButtonLink>
						<ConfirmationDialogProvider>
							{({ confirm }) => (
								<>
									<Button
										status="warning"
										level="tertiary"
										size="sm"
										onClick={async () => {
											if (await confirm()) {
												const { message } = await XHR(xhr).destroy(data.id)
												showSnackbar(message)
												navigate("/org/teams")
											}
										}}
									>
										<Icons.Trash /> Delete
									</Button>
									<ConfirmationDialog
										destructiveAction
										title="Delete Team"
										acceptActionLabel="Delete It"
									>
										<Stack gap="4">
											<Text fontWeight="semibold" fontSize="md">
												Once deleted, this Team can not be restored.
											</Text>
											<Text>Are you sure you want to delete this Team ?</Text>
										</Stack>
									</ConfirmationDialog>
								</>
							)}
						</ConfirmationDialogProvider>
					</Inline>
				}
			/>
			<Container paddingY="6" bgColor="default">
				<Stack gap="2">
					<Heading as="h2" fontSize="base" color="muted">
						Name
					</Heading>
					<Text fontSize="lg" fontWeight="semibold">
						{data.name}
					</Text>
				</Stack>
			</Container>
			<Container paddingY="8">
				<Grid gap="6">
					<Col sm={12} md>
						<Stack gap="2">
							<Heading fontSize="md">
								Team Members ({data.users?.length})
							</Heading>
							<Table
								responsive
								bordered
								headers={["Name", "Role(s)", "Email"]}
								rows={data.users?.map((d) => [
									<Link
										to={generatePath("/users/:userId", {
											userId: d.id.toString(),
										})}
										color="accent"
										fontWeight="semibold"
										anchored
									>
										{d.name}
									</Link>,
									d.roles?.map((r) => r.name).join(", ") || "-",
									d.email,
								])}
							/>
						</Stack>
					</Col>
					<Col>
						<Stack gap="4">
							{data.managers?.length ? (
								<Stack gap="2">
									<Heading fontSize="md">
										Team Managers ({data.managers?.length})
									</Heading>
									<Table
										responsive
										bordered
										headers={["Name", "Email"]}
										rows={data.managers?.map((d) => [
											<Link
												to={generatePath("/users/:userId", {
													userId: d.id.toString(),
												})}
												color="accent"
												fontWeight="semibold"
												anchored
											>
												{d.name}
											</Link>,
											d.email,
										])}
									/>
								</Stack>
							) : null}
							{data.trip_destinations?.length ? (
								<Stack gap="2">
									<Heading fontSize="md">
										Trip Destinations ({data.trip_destinations.length})
									</Heading>
									<Table
										responsive
										bordered
										headers={["Name"]}
										rows={data.trip_destinations?.map((d) => [
											<Link
												to={generatePath(
													"/trip-destinations/:tripDestinationId",
													{
														tripDestinationId: d.id.toString(),
													}
												)}
												color="accent"
												fontWeight="semibold"
												anchored
											>
												{d.name}
											</Link>,
										])}
									/>
								</Stack>
							) : null}
						</Stack>
					</Col>
				</Grid>
			</Container>
		</>
	)
}
