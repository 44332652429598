import { Navigate } from "./../../../../../router-utils"
import { useTripDetailsOutletContext } from "../_trip-details-context"

export default function TripDocumentsIndexPage() {
	const { trip } = useTripDetailsOutletContext()
	if (trip.converted_at) {
		return (
			<Navigate
				to={"/trips/:tripId/docs/trip-voucher"}
				replace
				params={{ tripId: trip.id.toString() }}
			/>
		)
	}
	return (
		<Navigate
			to={"/trips/:tripId/docs/trip-holding-email"}
			replace
			params={{ tripId: trip.id.toString() }}
		/>
	)
}
