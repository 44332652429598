import { Box, Container } from "@sembark-travel/ui/base"
import {
	queryToSearch,
	searchToQuery,
	Breadcrumbs,
} from "@sembark-travel/ui/router"
import { useCallback } from "react"
import { Helmet } from "react-helmet-async"
import { NewAccount, TAccountsTypes } from "../../../../Accounting"
import { useNavigate, useLocation } from "../../../../router-utils"

export default function AccountDetails() {
	const location = useLocation()
	const navigate = useNavigate()
	const query = searchToQuery<{ entity_type: TAccountsTypes; user_id: number }>(
		location?.search
	)
	const { entity_type } = query
	const back = useCallback(() => {
		navigate("/accounting/accounts")
	}, [navigate])
	const onSuccess = useCallback(
		({ entity_type }: { entity_type: string }) => {
			navigate(
				{
					pathname: "/accounting/accounts",
					search: queryToSearch({ entity_type }, { addQueryPrefix: false }),
				},
				{}
			)
		},
		[navigate]
	)
	return (
		<>
			<Helmet>
				<title>Add Account</title>
			</Helmet>
			<Breadcrumbs
				title="New Account"
				items={[
					["/accounting/accounts", "Accounting Accounts"],
					["", "New"],
				]}
			/>
			<Box paddingX={{ md: "4" }} paddingY={{ md: "8" }}>
				<Box maxWidth="6xl" marginX="auto">
					<Box borderWidth={{ md: "1" }} rounded="md" bgColor="default">
						<Container paddingY="6">
							<NewAccount
								onCancel={back}
								onSuccess={onSuccess}
								entityType={entity_type}
							/>
						</Container>
					</Box>
				</Box>
			</Box>
		</>
	)
}
