/* eslint react-refresh/only-export-components: 0 */
/* eslint react/jsx-pascal-case: 0 */

// Generouted + React Router Custom Integration Example
// What's different from the default integrations
// - Custom integration added at ./src/routes.tsx
// - Copied from generouted/src/react-router.tsx
import { Fragment, Suspense } from "react"
import { Outlet, useLocation, useRouteError } from "react-router-dom"
import type {
	ActionFunction,
	RouteObject,
	LoaderFunction,
	ShouldRevalidateFunction,
} from "react-router-dom"
import { ReportAndRenderError } from "@sembark-travel/ui/error-boundary"

import {
	generateModalRoutes,
	generatePreservedRoutes,
	generateRegularRoutes,
} from "@generouted/react-router/core"

type Element = () => JSX.Element
type Module = {
	default: Element
	Loader?: LoaderFunction
	Action?: ActionFunction
	Catch?: Element
	Pending?: Element
	ShouldRevalidate?: ShouldRevalidateFunction
}

const PRESERVED = import.meta.glob<Module>("/src/pages/(_app|404).{jsx,tsx}", {
	eager: true,
})
const MODALS = import.meta.glob<Pick<Module, "default">>(
	"/src/pages/**/[+]*.{jsx,tsx}",
	{ eager: true }
)
const ROUTES = import.meta.glob<Module>(
	["/src/pages/**/[\\w[-]*.{jsx,tsx}", "!**/(_app|404).*"],
	{ eager: true }
)

const preservedRoutes =
	generatePreservedRoutes<Omit<Module, "Action">>(PRESERVED)
const modalRoutes = generateModalRoutes<Element>(MODALS)

const regularRoutes = generateRegularRoutes<RouteObject, Partial<Module>>(
	ROUTES,
	(module, key) => {
		const index =
			/index\.(jsx|tsx)$/.test(key) && !key.includes("pages/index")
				? { index: true }
				: {}
		const Element = module?.default || Fragment
		const ErrorBoundary = module?.Catch || RouteErrorElement
		const Page = () =>
			module?.Pending ? (
				<Suspense fallback={<module.Pending />} children={<Element />} />
			) : (
				<Element />
			)
		return {
			...index,
			Component: Page,
			ErrorBoundary: ErrorBoundary,
			loader: module?.Loader,
			action: module?.Action,
			shouldRevalidate: module?.ShouldRevalidate,
		}
	}
)

const _app = preservedRoutes?.["_app"]
const _404 = preservedRoutes?.["404"]

const Element = _app?.default || Fragment
const ErrorBoundary = _app?.Catch || RouteErrorElement
const App = () =>
	_app?.Pending ? (
		<Suspense fallback={<_app.Pending />} children={<Element />} />
	) : (
		<Element />
	)

const app = {
	Component: _app?.default ? App : Outlet,
	ErrorBoundary: ErrorBoundary,
	loader: _app?.Loader,
	shouldRevalidate: _app?.ShouldRevalidate,
}
const fallback = { path: "*", Component: _404?.default || Fragment }

export const routes: RouteObject[] = [
	{ ...app, children: [...regularRoutes, fallback] },
]

export const Modals = () => {
	const Modal = modalRoutes[useLocation().state?.modal] || Fragment
	return <Modal />
}

function RouteErrorElement() {
	let error = useRouteError()
	if (!error) {
		error = new Error("Something went wrong!")
	}
	return <ReportAndRenderError error={error as never} />
}
