import { Box, Stack, Text, Container } from "@sembark-travel/ui/base"
import {
	useGetBackUrlFromLocation,
	Breadcrumbs,
} from "@sembark-travel/ui/router"
import { Helmet } from "react-helmet-async"
import { NewTenantConnectionRequest } from "../../../ConnectionRequests"
import { generatePath, useNavigate } from "../../../router-utils"

export default function NewTenantConnectionRequestPage() {
	const navigate = useNavigate()
	const backFromUrl = useGetBackUrlFromLocation()
	const listingUrl = generatePath("/org/connections")

	function navigateBackOrToList() {
		if (backFromUrl) {
			return navigate(backFromUrl as "/org/connections")
		}
		return navigate("/org/connections")
	}
	return (
		<>
			<Helmet>
				<title>Connect with Businesses</title>
			</Helmet>
			<Breadcrumbs
				title="New B2B Connect"
				items={[
					[listingUrl, "Connections"],
					["", "New"],
				]}
			/>
			<Box paddingX={{ md: "4" }} paddingY={{ md: "8" }}>
				<Box borderWidth={{ md: "1" }} rounded="md" bgColor="default">
					<Container paddingY="6">
						<Stack gap="8" maxWidth="4xl" marginX="auto">
							<Text color="muted">
								Connect with other travel businesses (e.g. operators, agencies
								etc.) to share packages and quotes, streamline accounting and
								much more.
							</Text>
							<NewTenantConnectionRequest
								onSuccess={navigateBackOrToList}
								onCancel={navigateBackOrToList}
							/>
						</Stack>
					</Container>
				</Box>
			</Box>
		</>
	)
}
