import { TenantUsersLayout } from "../../../../TenantProfile"
import { Outlet } from "react-router-dom"
import { ForbidUnlessAuthorized, PERMISSIONS } from "../../../../Auth"

export default function Layout() {
	return (
		<ForbidUnlessAuthorized permission={PERMISSIONS.MANAGE_USERS}>
			<TenantUsersLayout>
				<Outlet />
			</TenantUsersLayout>
		</ForbidUnlessAuthorized>
	)
}
