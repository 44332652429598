import {
	Box,
	Button,
	Stack,
	Card,
	CardBody,
	CardFooter,
} from "@sembark-travel/ui/base"
import { useXHR, XHRInstance } from "@sembark-travel/xhr"
import * as Validator from "yup"
import {
	Form,
	validateFormValues,
	withServerErrors,
	SubmissionError,
	TextInputField,
} from "@sembark-travel/ui/form"
import { useRef } from "react"

// schemas
export interface IResetPasswordCredentials {
	email: string
	token: string
	password: string
	password_confirmation: string
}
const resetPasswordSchema = Validator.object().shape({
	email: Validator.string()
		.required("Email field is required")
		.email("Invalid email address"),
	token: Validator.string().required(
		"Missing token to reset values. Please check for valid url from sent email"
	),
	password: Validator.string().required("Password field is required"),
	password_confirmation: Validator.string().required(
		"Password confirmation field is required"
	),
})

// actions
function XHR(xhr: XHRInstance) {
	return {
		async resetPassword(data: IResetPasswordCredentials): Promise<void> {
			return xhr.delete("/passwords/reset", { data })
		},
	}
}

export function ResetPasswordForm({
	email,
	token,
	onSucess,
}: {
	email: string
	token: string
	onSucess: () => void
}) {
	const xhr = useXHR()
	const initialValues: IResetPasswordCredentials = useRef({
		email,
		token,
		password: "",
		password_confirmation: "",
	}).current
	return (
		<Form<IResetPasswordCredentials>
			initialValues={initialValues}
			validate={validateFormValues(resetPasswordSchema)}
			onSubmit={withServerErrors(async (values) => {
				await XHR(xhr).resetPassword(values)
				alert(
					"Your passwords updated successfully. You can now log in with the new password"
				)
				onSucess()
			})}
			subscription={{ submitting: true }}
		>
			{({ submitting, handleSubmit }) => (
				<form noValidate onSubmit={handleSubmit}>
					<SubmissionError />
					<Card>
						<CardBody>
							<input
								type="hidden"
								hidden
								name="email"
								value={initialValues.email}
							/>
							<input
								type="hidden"
								hidden
								value={initialValues.token}
								name="token"
							/>
							<Stack gap="4">
								<TextInputField
									label="Password"
									name="password"
									type="password"
									id="password"
									autoFocus
									autoComplete="new-password"
									required
								/>
								<TextInputField
									label="Confirm Password"
									name="password_confirmation"
									type="password"
									id="password_confirmation"
									autoComplete="new-password"
									required
								/>
							</Stack>
						</CardBody>
						<CardFooter>
							<Box>
								<Button type="submit" disabled={submitting}>
									{submitting ? "Resetting..." : "Reset Password"}
								</Button>
							</Box>
						</CardFooter>
					</Card>
				</form>
			)}
		</Form>
	)
}
