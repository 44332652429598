import { AsyncSelect, AsyncSelectProps, Table } from "@sembark-travel/ui/base"
import {
	ISimpleListResponse,
	IListResponse,
	useXHR,
	XHRInstance,
} from "@sembark-travel/xhr"
import { Omit } from "utility-types"
import { IMealPlan } from "./store"
import { TTripDestination } from "../TripDestinations"
import { ListView, Search, useSearch } from "@sembark-travel/ui/list"

function XHR(xhr: XHRInstance) {
	return {
		async getMealPlans(
			params?: unknown
		): Promise<ISimpleListResponse<IMealPlan>> {
			return xhr.get("/meal-plans", { params }).then((resp) => resp.data)
		},
		async getMealPlansWithPagination(
			params?: Record<string, unknown>
		): Promise<IListResponse<IMealPlan>> {
			return xhr
				.get("/meal-plans", { params: { ...params, pagination: "page" } })
				.then((resp) => resp.data)
		},
	}
}

export function MealPlansList() {
	const [params, setParams] = useSearch()
	return (
		<Search
			title="Meal Plans"
			initialParams={params}
			onSearch={(params) => setParams({ ...params, page: 1 })}
		>
			<ListView
				params={params}
				pageKey={`meal-plans-list-page`}
				fetch={(xhr, params) => XHR(xhr).getMealPlansWithPagination(params)}
				onPageChange={(page) => setParams({ ...params, page })}
			>
				{({ items }) => (
					<Table
						headers={["Name", "Short Description"]}
						bordered
						striped
						rows={items.map((item) => [item.name, item.description])}
					/>
				)}
			</ListView>
		</Search>
	)
}

export function SelectMealPlans({
	tripDestinations,
	...props
}: Omit<AsyncSelectProps, "fetch"> & {
	tripDestinations?: Array<TTripDestination>
}) {
	const xhr = useXHR()
	return (
		<AsyncSelect
			multiple
			placeholder="Select meals..."
			{...props}
			cacheKey={`meal-plans-${tripDestinations?.map((t) => t.id).join("-")}`}
			fetch={(q) =>
				XHR(xhr)
					.getMealPlans({
						q,
						trip_destinations: tripDestinations?.length
							? tripDestinations.map((t) => t.id)
							: null,
					})
					.then((resp) => resp.data)
			}
		/>
	)
}
