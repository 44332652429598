import { useSearch } from "@sembark-travel/ui/list"
import { ButtonLink, useLocationQuery } from "@sembark-travel/ui/router"
import { useEffect } from "react"
import { Helmet } from "react-helmet-async"
import { TransportServiceProvidersList } from "./../../../TransportServiceProviders"
import { generatePath } from "./../../../router-utils"
import { Icons } from "@sembark-travel/ui/base"

export default function TransportServiceProviders() {
	const [query, setQuery] = useLocationQuery()
	const [params, setParams] = useSearch(query)
	useEffect(() => {
		setQuery(params)
	}, [params, setQuery])
	return (
		<>
			<Helmet>
				<title>Transport Service Providers</title>
			</Helmet>
			<TransportServiceProvidersList
				params={params}
				setParams={setParams}
				actions={
					<ButtonLink
						to={generatePath("/transport-service-providers/new")}
						level="primary"
						status="primary"
					>
						<Icons.Plus /> Add Supplier
					</ButtonLink>
				}
			/>
		</>
	)
}
