import {
	AsyncSelect,
	Box,
	Icons,
	Table,
	Stack,
	Text,
	Component,
	Dropdown,
} from "@sembark-travel/ui/base"
import { Dialog } from "@sembark-travel/ui/dialog"
import {
	useSearch,
	ListView,
	Search,
	TSearchParams,
	areAdvancedFiltersAppliedDefault,
} from "@sembark-travel/ui/list"
import { ButtonLink, Link, useLocationQuery } from "@sembark-travel/ui/router"
import { useXHR } from "@sembark-travel/xhr"
import { useEffect } from "react"
import { Omit } from "utility-types"
import { PERMISSIONS, useCheckPermissions } from "../Auth"
import { generatePath } from "../router-utils"
import { DisableTripDestination, EditTripDestination } from "./EditItem"
import { TTripDestination, tripDestinationsXHR } from "./store"
import { utcTimestampToLocalDateString } from "@sembark-travel/datetime-utils"
import { SwitchInputField, isTruthy } from "@sembark-travel/ui/form"

type TFilters = TSearchParams & {
	disabled_only?: 1
}

type TFiltersInQuery = TSearchParams & {
	disabled_only?: 1
}

function filtersToQuery(filters: TFilters): TFiltersInQuery {
	const { disabled_only, ...others } = filters
	const query: TFiltersInQuery = { ...others }
	if (isTruthy(disabled_only)) {
		query.disabled_only = 1
	}
	return query
}

function queryToFilters(query: TFiltersInQuery): TFilters {
	const { disabled_only, ...others } = query
	const filters: TFilters = { ...others }
	if (isTruthy(disabled_only)) {
		filters.disabled_only = 1
	}
	return filters
}

export function TripDestinationsList() {
	const [query, setQuery] = useLocationQuery({
		fromQuery: filtersToQuery,
		toQuery: queryToFilters,
	})
	const [params, setParams] = useSearch(query)
	const { hasPermission } = useCheckPermissions()
	useEffect(() => {
		setQuery(params)
	}, [setQuery, params])
	return (
		<Search
			initialParams={params}
			onSearch={(params) => setParams({ ...params, page: 1 })}
			title="Destinations"
			Filters={Filters}
			resetParams={{
				q: "",
				page: 1,
			}}
			areAdvancedFiltersApplied={(filters) => {
				const { disabled_only, ...otherFilters } = filters
				return (
					isTruthy(disabled_only) ||
					areAdvancedFiltersAppliedDefault(otherFilters)
				)
			}}
			actions={
				hasPermission(PERMISSIONS.ADD_TRIP_DESTINATIONS) ? (
					<>
						<ButtonLink
							to={generatePath("/trip-destinations/new")}
							level="primary"
							status="primary"
						>
							New Destination
						</ButtonLink>
					</>
				) : null
			}
		>
			<ListView<TTripDestination>
				pageKey="trip-destinations"
				params={params}
				fetch={(xhr, params) =>
					tripDestinationsXHR(xhr).get({
						...params,
						include: "created_by,thumbs",
						sort: "name",
					})
				}
				onPageChange={(page) => setParams({ ...params, page })}
			>
				{({ items, refresh }) => (
					<Table
						headers={[
							"Image",
							"Name",
							"Short",
							"Currency",
							params.disabled_only ? "Disabled By" : "Created By",
							"",
						]}
						bordered
						hover
						responsive
						alignCols={{ 5: "right" }}
						rows={items.map((item) => [
							item.thumb_image_url ? (
								<Box
									as="img"
									src={item.thumb_image_url}
									borderWidth="1"
									alt="Display"
									rounded="md"
									style={{ width: "100px", minWidth: "100px" }}
								/>
							) : null,
							<Stack>
								<Box>
									<Link
										anchored
										to={generatePath("/trip-destinations/:tripDestinationId", {
											tripDestinationId: item.id.toString(),
										})}
										color="accent"
										fontWeight="semibold"
									>
										{item.name}
									</Link>
								</Box>
							</Stack>,
							<Text fontSize="sm">{item.short_name}</Text>,
							<Text fontSize="sm">{item.currency}</Text>,
							params.disabled_only ? (
								<Text
									color="warning"
									title={
										item.deleted_at
											? utcTimestampToLocalDateString(item.deleted_at)
											: undefined
									}
								>
									{item.deleted_by?.name}
								</Text>
							) : (
								<Text title={utcTimestampToLocalDateString(item.created_at)}>
									{item.created_by?.name}
								</Text>
							),
							<Box>
								{hasPermission(PERMISSIONS.ADD_TRIP_DESTINATIONS) &&
								!item.deleted_at ? (
									<Dropdown alignRight>
										<Dropdown.ToggleButton size="sm" level="tertiary">
											<Icons.DotsVertical />
										</Dropdown.ToggleButton>
										<Dropdown.Menu>
											<Component initialState={false}>
												{({ state, setState }) => (
													<>
														<Dropdown.MenuItem onClick={() => setState(true)}>
															<Icons.Pencil /> Edit Details
														</Dropdown.MenuItem>
														<Dialog
															open={state}
															onClose={() => setState(false)}
															title="Edit Destination Details"
														>
															<Dialog.Body>
																<EditTripDestination
																	destination={item}
																	onSuccess={() => {
																		setState(false)
																		refresh()
																	}}
																	onCancel={() => setState(false)}
																/>
															</Dialog.Body>
														</Dialog>
													</>
												)}
											</Component>
											<Dropdown.MenuItemDivider />
											<Component initialState={false}>
												{({ state, setState }) => (
													<>
														<Dropdown.MenuItem
															onClick={() => setState(true)}
															color="warning"
														>
															<Icons.Ban /> Disable
														</Dropdown.MenuItem>
														<Dialog
															open={state}
															onClose={() => setState(false)}
															title="Disable Trip Destination"
															sm
														>
															<Dialog.Body>
																<DisableTripDestination
																	destination={item}
																	onSuccess={() => {
																		setState(false)
																		refresh()
																	}}
																	onCancel={() => setState(false)}
																/>
															</Dialog.Body>
														</Dialog>
													</>
												)}
											</Component>
										</Dropdown.Menu>
									</Dropdown>
								) : null}
							</Box>,
						])}
					/>
				)}
			</ListView>
		</Search>
	)
}

function Filters() {
	return (
		<Stack>
			<SwitchInputField name="disabled_only" label="Disabled Only" />
		</Stack>
	)
}

export function SelectTripDestination({
	tenantBrands,
	...props
}: Omit<React.ComponentProps<typeof AsyncSelect>, "fetch"> & {
	tenantBrands?: Array<number>
}) {
	const xhr = useXHR()
	return (
		<AsyncSelect
			{...props}
			fetch={(q) =>
				tripDestinationsXHR(xhr)
					.get({ q, tenant_brands: tenantBrands })
					.then((resp) => resp.data)
			}
		/>
	)
}
