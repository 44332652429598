import { Box } from "@sembark-travel/ui/base"
import { Divider } from "@sembark-travel/ui/base"
import { Helmet } from "react-helmet-async"
import { RoomTypeForm } from "../../../RoomTypes"
import { useNavigate } from "../../../router-utils"

export default function NewRoomType() {
	const navigate = useNavigate()
	return (
		<>
			<Helmet>
				<title>New Room Type</title>
			</Helmet>
			<Box maxWidth="xl" marginX="auto">
				<Box as="h2">New Room Type</Box>
				<Divider />
				<Box marginTop="8" marginBottom="16">
					<RoomTypeForm
						onSuccess={() => navigate("/room-types")}
						onCancel={() => navigate("/room-types")}
					/>
				</Box>
			</Box>
		</>
	)
}
