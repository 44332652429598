/* eslint react-refresh/only-export-components: 0 */
import { Stack, Grid, Col, Inline, Box } from "@sembark-travel/ui/base"
import {
	SelectField,
	TextInputField,
	useForm,
	PhoneInputField,
	GetFieldValue,
} from "@sembark-travel/ui/form"
import {
	SelectCities,
	SelectCountries,
	SelectLocations,
	SelectStates,
} from "../Locations"
import { ICity, ICountryState, ILocation } from "../Locations"
import { IAddress } from "./store"

export type TAddressInputFieldValue = {
	line_1?: string
	line_2?: string
	postcode?: string
	landmark?: string
	location?: ILocation
	city?: ILocation["city"]
	state?: ILocation["state"]
	country?: ILocation["country"]
	email?: string
	phone_numbers?: Array<{
		country_code: string
		phone_number: string
		number: string
	}>
	type?: string
	name?: string
	addressing_name?: string
	billing_details?: string
}

export function transformAddressToEditableValue(
	address?: IAddress
): TAddressInputFieldValue {
	const { id, text, location, email, ...otherAddressData } = address || {}
	return {
		...otherAddressData,
		location: location,
		city: location?.city,
		state: location?.state,
		country: location?.country,
		email: email?.email || "",
	}
}

export function transformAddressToStorableValue(
	address?: TAddressInputFieldValue
) {
	if (!address) return {}
	return {
		...address,
		phone_numbers: address?.phone_numbers?.filter((p) => p.number),
		location: address.location?.name,
		city: address.city?.name,
		state: address.state?.name,
		country: address.country?.name,
	}
}

export function AddressInputField({
	name,
	identifiable,
	onlyLocation,
	onlyCityStateCountry,
	label,
	hidePreviewForLocation,
	hideContactDetails,
}: {
	name: string
	/**
	 * Allow address to be identified (give name)
	 */
	identifiable?: boolean
	onlyLocation?: boolean
	label?: string
	onlyCityStateCountry?: boolean
	hidePreviewForLocation?: boolean
	hideContactDetails?: boolean
}) {
	const form = useForm()
	return (
		<Stack gap="4">
			{identifiable ? (
				<TextInputField
					label="Name"
					name={`${name}.name`}
					type="text"
					placeholder="Head office"
				/>
			) : null}
			{onlyLocation ? (
				<Stack gap="4">
					<SelectField
						select={SelectLocations}
						name={`${name}.location`}
						label={label || "Location"}
						multiple={false}
						placeholder="e.g. City, State, Country"
						onChange={(value: ILocation | undefined) => {
							form.batch(() => {
								form.change(`${name}.location`, value)
								form.change(`${name}.city`, value?.city)
								form.change(`${name}.state`, value?.state)
								form.change(`${name}.country`, value?.country)
								form.change(`${name}.pincode`, undefined)
								form.change(`${name}.line_1`, undefined)
								form.change(`${name}.line_2`, undefined)
								form.change(`${name}.landmark`, undefined)
							})
						}}
					/>
					{hidePreviewForLocation ? null : (
						<GetFieldValue<TAddressInputFieldValue | null> name={name}>
							{({ value: address }) => {
								if (!address) return null
								const {
									line_1,
									line_2,
									postcode,
									city,
									state,
									country,
									location,
									email,
									phone_numbers,
								} = address
								return [
									[line_1, line_2].filter(Boolean).join(","),
									city
										? [city, state, country]
												.map((c) => c?.name)
												.filter(Boolean)
												.join(", ")
										: location?.name,
									postcode,
									phone_numbers?.filter((p) => p.number)?.length
										? `PH: ${phone_numbers
												.filter((p) => p.number)
												.map((p) => p.number)
												.join(" / ")}`
										: undefined,
									email ? `Email: ${email}` : undefined,
								]
									.filter(Boolean)
									.join(", ")
							}}
						</GetFieldValue>
					)}
				</Stack>
			) : (
				<>
					<Grid gap="4">
						<Col sm={6}>
							<SelectField
								select={SelectCities}
								name={`${name}.city`}
								label="City / Town / District"
								multiple={false}
								creatable
								onChange={(value?: ICity) => {
									form.change(`${name}.city`, value)
									if (value?.state) {
										form.change(`${name}.state`, value.state)
										if (value.state.country) {
											form.change(`${name}.country`, value.state.country)
										}
									}
								}}
							/>
						</Col>
						<Col>
							<SelectField
								select={SelectStates}
								name={`${name}.state`}
								label="State / Province / Region"
								multiple={false}
								creatable
								onChange={(value?: ICountryState) => {
									form.change(`${name}.state`, value)
									if (value?.country) {
										form.change(`${name}.country`, value.country)
									}
								}}
							/>
						</Col>
					</Grid>
					<Grid gap="4">
						<Col xs={12} sm>
							<SelectField
								select={SelectCountries}
								name={`${name}.country`}
								label="Country"
								multiple={false}
								maxWidth="xs"
							/>
						</Col>
						<Col>
							{onlyCityStateCountry ? null : (
								<TextInputField
									label="Pin Code"
									name={`${name}.postcode`}
									type="text"
									placeholder="e.g. Area Code"
									autoComplete="no_please"
									style={{ maxWidth: "150px" }}
								/>
							)}
						</Col>
					</Grid>
					{onlyCityStateCountry ? null : (
						<>
							<Inline flexWrap="wrap" gap="4" collapseBelow="sm">
								<Box flex="1">
									<TextInputField
										label="Street Address"
										name={`${name}.line_1`}
										type="text"
										placeholder="Flat / House No. / Floor / Building"
										autoComplete="no_please"
									/>
								</Box>
								<Box flex="1">
									<TextInputField
										label="Locality / Area"
										name={`${name}.line_2`}
										type="text"
										placeholder="Colony / Street / Locality"
										autoComplete="no_please"
									/>
								</Box>
							</Inline>
							<TextInputField
								label="Landmark"
								name={`${name}.landmark`}
								secondaryLabel="optional"
								type="text"
								placeholder="E.g. Behind Cinema"
								maxWidth="sm"
							/>
							{!hideContactDetails ? (
								<Grid gap="4">
									<Col xs={12} sm md={12} lg>
										<PhoneInputField
											name={`${name}.phone_numbers`}
											label="Contact Number(s)"
											required
											multi
										/>
									</Col>
									<Col>
										<TextInputField
											label="Email"
											name={`${name}.email`}
											type="email"
											placeholder="contact@example.com"
											maxWidth="xs"
										/>
									</Col>
								</Grid>
							) : null}
						</>
					)}
				</>
			)}
		</Stack>
	)
}
