import { Box, BoxProps } from "./Box"
import { pingClassName } from "./ping.css"

export function Ping(
	props: Pick<BoxProps, "fontSize" | "color"> & { title?: string }
) {
	return (
		<Box color="accent" fontSize="base" className={pingClassName} {...props} />
	)
}
