import { XHRInstance, IListResponse } from "@sembark-travel/xhr"

export interface INotification {
	id: number
	type:
		| "App\\Notifications\\TripConverted"
		| "App\\Notifications\\FailedUpdateAmountForPaymentableNotification"
		| "App\\Notifications\\NewTripPlanRequestNotification"
		| "App\\Notifications\\TripPlanRequestsAssignedNotification"
		| "App\\Notifications\\TripPlanRequestsUpdatedNotification"
		| "App\\IntegratedApps\\Facebook\\Notifications\\AccessTokenExpiredNotification"
	data: { title: string; description: string; [key: string]: unknown }
	read_at: string | null
	created_at: string
}

export function XHR(xhr: XHRInstance) {
	return {
		async getNotifications(
			params?: unknown
		): Promise<IListResponse<INotification>> {
			return xhr.get("/notifications", { params }).then((resp) => resp.data)
		},
		async markAsRead(
			items: Array<INotification> = [],
			markAll = false
		): Promise<unknown> {
			return xhr.patch("/notifications/mark-as-read", {
				items: (items || []).map((n) => n.id),
				all: Number(markAll),
			})
		},
		async markAllAsSeen(): Promise<unknown> {
			return xhr.patch("/notifications/mark-as-seen", {
				all: 1,
			})
		},
		async getUnseenCount() {
			return xhr
				.get<{ data: number }>("/notifications/unseen-count")
				.then((resp) => resp.data.data)
		},
	}
}
