import {
	Box,
	Icons,
	Table,
	AsyncSelect,
	Dropdown,
	Stack,
	Text,
	Inline,
	joinAttributes,
	Time,
} from "@sembark-travel/ui/base"
import {
	NavLink,
	queryToSearch,
	useLocationQuery,
	ButtonLink,
	Link,
	XHRLink,
} from "@sembark-travel/ui/router"
import {
	ListView,
	TSearchParams,
	useSearch,
	Search,
} from "@sembark-travel/ui/list"
import { useXHR } from "@sembark-travel/xhr"
import { useEffect, useState } from "react"
import { Omit } from "utility-types"
import { PERMISSIONS, useCheckPermissions } from "../Auth"
import { DateRangePickerField, SelectField } from "@sembark-travel/ui/form"
import config from "../config"
import { generatePath } from "../router-utils"
import { EditItemInDialog } from "./EditItem"
import { AddTripSourceDialog } from "./NewItem"
import { ITripSource, tripSourcesXHR } from "./store"
import {
	dateToQuery,
	dateToUTCString,
	endOf,
	parseDateFromQuery,
	startOf,
} from "@sembark-travel/datetime-utils"
import { ILocation, SelectLocations } from "../Locations"

type TFilters = TSearchParams & {
	stats_start?: Date
	stats_end?: Date
	created_after?: Date
	created_before?: Date
	locations?: Array<ILocation>
}

type TFiltersInQuery = TSearchParams & {
	ss?: string
	se?: string
	ca?: string
	cb?: string
	locations?: Array<string>
}

function filtersToQuery(filters: TFilters): TFiltersInQuery {
	const {
		q,
		page,
		stats_start,
		stats_end,
		created_after,
		created_before,
		locations,
	} = filters
	const query: TFiltersInQuery = {}
	if (page) {
		query.page = page
	}
	if (q) {
		query.q = q
	}
	if (stats_start) {
		query.ss = dateToQuery(stats_start)
	}
	if (stats_end) {
		query.se = dateToQuery(stats_end)
	}
	if (created_after) {
		query.ca = dateToQuery(created_after)
	}
	if (created_before) {
		query.cb = dateToQuery(created_before)
	}
	if (locations?.length) {
		query.locations = locations.map((l) => `${l.id}__${l.full_name}`)
	}
	return query
}

function queryToFilters(query: TFiltersInQuery): TFilters {
	const { q, page, ss, se, ca, cb, locations } = query
	const filters: TFilters = {}
	if (page) {
		filters.page = page
	}
	if (q) {
		filters.q = q
	}
	if (ss) {
		filters.stats_start = parseDateFromQuery(ss)
	}
	if (se) {
		filters.stats_end = parseDateFromQuery(se)
	}
	if (ca) {
		filters.created_after = parseDateFromQuery(ca)
	}
	if (cb) {
		filters.created_before = parseDateFromQuery(cb)
	}
	if (locations?.length) {
		filters.locations = locations.map((l) => {
			const [id, ...name] = l.split("__")
			return {
				id,
				full_name: name.join("__"),
			} as never as ILocation
		})
	}
	return filters
}

function filtersToRequest({
	stats_start,
	stats_end,
	created_after,
	created_before,
	locations,
	...params
}: TFilters) {
	return {
		...params,
		stats_start: stats_start
			? dateToUTCString(startOf(stats_start, "day"))
			: null,
		stats_end: stats_end ? dateToUTCString(endOf(stats_end, "day")) : null,
		created_after: created_after
			? dateToUTCString(startOf(created_after, "day"))
			: null,
		created_before: created_before
			? dateToUTCString(endOf(created_before, "day"))
			: null,
		locations: locations?.length ? locations.map((l) => l.id) : [],
	}
}

export function List({ title = "Trip Sources" }: { title?: string }) {
	const [query, setQuery] = useLocationQuery<TFilters, TFiltersInQuery>({
		toQuery: filtersToQuery,
		fromQuery: queryToFilters,
	})
	const [params, setParams] = useSearch<TFilters>(query)
	const { hasPermission } = useCheckPermissions()
	const canViewAllSources = hasPermission(PERMISSIONS.ADD_TRIP_DESTINATIONS)
	useEffect(() => {
		setQuery(params)
	}, [params, setQuery])
	return (
		<Search
			initialParams={params}
			onSearch={(params) => {
				setParams({ ...params, page: 1 })
			}}
			title={title}
			Filters={canViewAllSources ? Filters : undefined}
			actions={
				hasPermission(PERMISSIONS.ADD_TRIP_SOURCES) ? (
					<Inline gap="4">
						<ButtonLink
							to={generatePath("/trip-sources/new")}
							level="primary"
							status="primary"
							anchored
						>
							<Icons.Plus title="Add" /> New Source
						</ButtonLink>
						<Dropdown alignRight="sm">
							<Dropdown.ToggleButton>
								<Icons.DotsVertical />
							</Dropdown.ToggleButton>
							<Dropdown.Menu>
								<Dropdown.MenuItem
									as={Link}
									to={generatePath("/trip-sources/merge")}
								>
									Merge Duplicate Sources
								</Dropdown.MenuItem>
								{canViewAllSources ? (
									<Dropdown.MenuItem
										as={XHRLink}
										href="/trip-sources/download"
										title="Download as CSV/Excel"
										query={{
											...filtersToRequest(params),
											timezone_offset: config.timezoneOffset,
										}}
										download
									>
										<Icons.DocumentDownload /> Download as CSV/Excel
									</Dropdown.MenuItem>
								) : null}
							</Dropdown.Menu>
						</Dropdown>
					</Inline>
				) : null
			}
		>
			<ListView<ITripSource, TFilters>
				pageKey="trip-sources"
				fetch={(xhr, params) =>
					tripSourcesXHR(xhr).getTripSources({
						...filtersToRequest(params),
						include: "connected_tenant,address,created_by",
						sort: "-created_at",
						page: canViewAllSources ? params.page : 1,
					})
				}
				onPageChange={(page) => setParams({ ...params, page })}
				params={params}
				paginationDisabled={!canViewAllSources}
			>
				{({ items: tripSources, refresh }) => (
					<Table
						striped
						bordered
						headers={["Name", "Created By", "Connection", ""]}
						responsive
						alignCols={{ 3: "right" }}
						rows={tripSources.map((tripSource) => [
							<Link
								to={generatePath("/trip-sources/:tripSourceId", {
									tripSourceId: tripSource.id.toString(),
								})}
								color="accent"
								fontWeight="semibold"
								anchored
							>
								<Stack gap="px">
									<Text>
										{tripSource.name} ({tripSource.short_name})
									</Text>
									<Inline fontSize="sm">
										{joinAttributes(
											tripSource.address
												? tripSource.address.location.name
												: null,
											tripSource.is_agent ? "B2B Agent" : null
										)}
									</Inline>
								</Stack>
							</Link>,
							<Stack gap="px">
								<Text>{tripSource.created_by?.name || "You"}</Text>
								<Text color="muted" fontSize="sm">
									<Time timestamp={tripSource.created_at} />
								</Text>
							</Stack>,
							tripSource.connected_tenant ? (
								<Box display="flex">
									{tripSource.connected_tenant.profile_image_url ? (
										<Box
											as="img"
											src={tripSource.connected_tenant.profile_image_url}
											alt="Logo"
											width="8"
											height="8"
											marginRight="2"
										/>
									) : null}
									{tripSource.connected_tenant.name}
								</Box>
							) : null,
							<Box>
								{hasPermission(PERMISSIONS.ADD_TRIP_SOURCES) ? (
									<Dropdown alignRight>
										<Dropdown.ToggleButton level="tertiary" size="sm">
											<Icons.DotsVertical />
										</Dropdown.ToggleButton>
										<Dropdown.Menu>
											<EditItemInDialog
												tripSource={tripSource}
												onSuccess={() => {
													refresh()
												}}
											>
												{({ edit }) => (
													<Dropdown.MenuItem onClick={() => edit()}>
														<Icons.Pencil /> Edit Details
													</Dropdown.MenuItem>
												)}
											</EditItemInDialog>
											<NavLink
												to={
													generatePath("/trips") +
													queryToSearch({
														sources: [`${tripSource.id}_${tripSource.name}`],
														status: "all",
													})
												}
											>
												View Queries
											</NavLink>
										</Dropdown.Menu>
									</Dropdown>
								) : null}
							</Box>,
						])}
					/>
				)}
			</ListView>
		</Search>
	)
}

function Filters() {
	return (
		<Stack gap="4">
			<DateRangePickerField
				fromName="stats_start"
				toName="stats_end"
				label="Stats Between"
				dateFormat="DD MMM, YYYY"
			/>
			<SelectField
				select={SelectLocations}
				name="locations"
				multiple
				label="Cities/Locations"
				creatable={false}
				addressableType="trip_sources"
			/>
			<DateRangePickerField
				fromName="created_after"
				toName="created_before"
				label="Created Between"
				dateFormat="DD MMM, YYYY"
			/>
		</Stack>
	)
}

export function SelectTripSources({
	includeContacts,
	onCreateSuccess,
	...props
}: {
	includeContacts?: boolean
	onCreateSuccess?: (tripSource: ITripSource) => void
} & Omit<React.ComponentProps<typeof AsyncSelect>, "fetch">) {
	const xhr = useXHR()
	const [newName, setNewName] = useState("")
	return (
		<>
			<AsyncSelect
				cacheKey="trip_sources"
				multiple
				{...props}
				fetch={(q) =>
					tripSourcesXHR(xhr)
						.getTripSources({
							q,
							include: ["address", includeContacts ? "contacts" : ""]
								.filter(Boolean)
								.join(","),
							sort: "name",
						})
						.then((resp) => resp.data)
				}
				onCreateNew={(query: string) => {
					setNewName(query)
				}}
				optionRenderer={({ option, created }) =>
					created ? (
						<Box>Add new Source "{option.name}"</Box>
					) : (
						<Box>
							<Box>{option.name}</Box>
							{option.address ? (
								<Text as="span" fontSize="sm" color="muted">
									{option.address.location.name}
								</Text>
							) : null}
						</Box>
					)
				}
			/>
			<AddTripSourceDialog
				open={!!newName}
				initialValues={{
					name: newName,
				}}
				minimalAddress
				onClose={() => {
					setNewName("")
				}}
				onSuccess={(tripSource) => {
					onCreateSuccess?.(tripSource)
					setNewName("")
				}}
			/>
		</>
	)
}
