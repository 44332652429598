import React from "react"
import {
	getUnit,
	setUnit,
	addUnit,
	clone,
	getDaysInYear,
} from "@sembark-travel/datetime-utils"
import {
	switchBtnClassName,
	nextPrevBtnClassName,
	monthYearClassName,
} from "./datetime.css"

import { useDateTimeContext } from "./DateTimeContext"
import { VIEWS } from "./utils"

function Header() {
	const { navigateBack, navigateForward, viewDate } = useDateTimeContext()
	const year = parseInt((getUnit(viewDate, "year") / 10).toString(), 10) * 10
	return (
		<thead>
			<tr>
				<th key="year" className={switchBtnClassName} colSpan={2}>
					{year}-{year + 9}
				</th>
				<th
					key="prev"
					className={nextPrevBtnClassName}
					onClick={() => navigateBack(10, "years")}
				>
					<span>‹</span>
				</th>
				<th
					key="next"
					className={nextPrevBtnClassName}
					onClick={() => navigateForward(10, "years")}
				>
					<span>›</span>
				</th>
			</tr>
		</thead>
	)
}

function Year({
	disabled,
	isActive,
	...props
}: {
	key: number
	onClick?: (year: number) => void
	year: number
	selectedDate?: Date
	disabled?: boolean
	isActive?: boolean
}) {
	const { year, onClick, selectedDate, ...otherProps } = props
	return (
		<td
			data-value={year}
			onClick={() => onClick && onClick(year)}
			data-disabled={Boolean(disabled || false)}
			data-active={Boolean(isActive || false)}
			className={monthYearClassName}
			{...otherProps}
		>
			{year}
		</td>
	)
}

function Years() {
	const { viewDate, value, isValidDate, setViewDate, showView } =
		useDateTimeContext()
	const selectedDate = value
	const isValid = isValidDate
	let years = [],
		i = -1
	const rows = []

	let year = parseInt((getUnit(viewDate, "year") / 10).toString(), 10) * 10
	year--

	while (i < 11) {
		// Month and date are irrelevant here because
		// we're only interested in the year
		const currentYear = setUnit(
			setUnit(setUnit(viewDate, "year", i), "month", 1),
			"date",
			1
		)

		const noOfDaysInYear = getDaysInYear(currentYear)

		let aDayOfYear = currentYear

		let isDisabled = true
		for (let j = 0; j < noOfDaysInYear; j++) {
			isDisabled = !isValid(aDayOfYear)
			if (!isDisabled) break
			aDayOfYear = addUnit(aDayOfYear, 1, "day")
		}

		years.push(
			<Year
				key={year}
				year={year}
				selectedDate={selectedDate && clone(selectedDate)}
				disabled={isDisabled}
				isActive={Boolean(
					selectedDate && getUnit(selectedDate, "year") === year
				)}
				onClick={
					!isDisabled
						? (year: number) => {
								setViewDate(setUnit(viewDate, "year", year))
								showView(VIEWS["MONTHS"])
						  }
						: undefined
				}
			/>
		)
		if (years.length === 4) {
			rows.push(React.createElement("tr", { key: i }, years))
			years = []
		}
		year++
		i++
	}

	return <React.Fragment>{rows}</React.Fragment>
}

export default function YearsView() {
	return (
		<div className="tpdt-years">
			<table>
				<Header />
				<tbody>
					<Years />
				</tbody>
			</table>
		</div>
	)
}
