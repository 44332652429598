import {
	Stack,
	Box,
	Button,
	Inline,
	Icons,
	Col,
	Divider,
	Grid,
} from "@sembark-travel/ui/base"
import {
	dateToUTCString,
	startOf,
	endOf,
	formatDate,
} from "@sembark-travel/datetime-utils"
import { useXHR } from "@sembark-travel/xhr"
import {
	Form,
	validateFormValues,
	withServerErrors,
	FieldArray,
	arrayMutators,
	DatePickerField,
	TextInputField,
	SelectField,
	SubmissionError,
	GetFieldValue,
} from "@sembark-travel/ui/form"
import * as Validator from "yup"
import {
	SelectHotels,
	IHotel,
	IHotelMealPlan,
	IHotelRoomType,
} from "./../Hotels"
import { SelectMealPlans } from "./../MealPlans"
import { SelectRoomTypes } from "./../RoomTypes"
import {
	SelectTenantCurrencyInputField,
	useFunctionalCurrencyOfTenant,
} from "../Currencies"
import { useState } from "react"
import { Required } from "utility-types"

type NewPriceCredentials = {
	prices?: {
		start_date: string
		end_date: string
		currency: string
		base_price: number
		adult_with_extra_bed_price: number
		child_with_extra_bed_price: number
		child_without_extra_bed_price: number
		hotel?: IHotel
		meal_plan?: IHotelMealPlan
		room_type?: IHotelRoomType
		persons: number
	}[]
}

const validationSchema = Validator.object().shape({
	prices: Validator.array().of(
		Validator.object().shape({
			hotel: Validator.object().required("Hotel is required"),
			start_date: Validator.date().required("Start date field is required"),
			end_date: Validator.date().required("End date field is required"),
			currency: Validator.mixed().required("Please select a currency"),
			base_price: Validator.number()
				.required("Base price field is required")
				.positive("Price should be positive"),
			persons: Validator.number()
				.required("Persons field is required")
				.integer()
				.positive("Persons should be positive number"),
			adult_with_extra_bed_price: Validator.number()
				.required("Price for adult with extra bed is required")
				.min(0, "Price should not be negative"),
			child_with_extra_bed_price: Validator.number()
				.required("Price for child with extra bed is required")
				.min(0, "Price should not be negative"),
			child_without_extra_bed_price: Validator.number()
				.nullable(true)
				.min(0, "Price should not be negative")
				.required("Price for child without extra bed is required"),
			meal_plan: Validator.object().required("Meal plan should be selected"),
			room_type: Validator.object().required("Room type should be selected"),
		})
	),
})

export function AddPricesForm({
	onSuccess,
	onCancel,
}: {
	onSuccess: () => void
	onCancel?: () => void
}) {
	const xhr = useXHR()
	const functionalCurrency = useFunctionalCurrencyOfTenant()
	const [initialValues] = useState<Required<NewPriceCredentials, "prices">>(
		() => ({
			prices: [
				{
					start_date: "",
					end_date: "",
					currency: functionalCurrency,
					base_price: 0,
					persons: 2,
					adult_with_extra_bed_price: 0,
					child_with_extra_bed_price: 0,
					child_without_extra_bed_price: 0,
					hotel: undefined,
					meal_plan: undefined,
					room_type: undefined,
				},
			],
		})
	)
	return (
		<Form<NewPriceCredentials>
			initialValues={initialValues}
			validate={validateFormValues(validationSchema)}
			onSubmit={withServerErrors(async (values) => {
				await xhr.post(`/hotel-prices`, {
					prices: values.prices?.reduce<
						Array<
							Omit<
								(typeof values.prices)[number],
								"start_date" | "end_date" | "meal_plan" | "room_type" | "hotel"
							> & {
								start_date: string
								start_date_local: string
								end_date: string
								end_date_local: string
								hotel_id?: number
								meal_plan_id?: number
								room_type_id?: number
							}
						>
					>(
						(
							carry,
							{
								meal_plan: mealPlan,
								room_type: roomType,
								hotel,
								start_date,
								end_date,
								...otherValues
							}
						) => {
							carry.push({
								...otherValues,
								start_date: dateToUTCString(startOf(start_date, "day")),
								start_date_local: formatDate(
									startOf(start_date, "day"),
									"YYYY-MM-DD"
								),
								end_date: dateToUTCString(endOf(end_date, "day")),
								end_date_local: formatDate(
									endOf(end_date, "day"),
									"YYYY-MM-DD"
								),
								hotel_id: hotel && hotel.id,
								meal_plan_id: mealPlan && mealPlan.id,
								room_type_id: roomType && roomType.id,
							})
							return carry
						},
						[]
					),
				})
				onSuccess()
			})}
			subscription={{ submitting: true }}
			mutators={{ ...arrayMutators }}
		>
			{({ submitting, handleSubmit, form }) => (
				<form noValidate onSubmit={handleSubmit}>
					<FieldArray<
						Required<NewPriceCredentials>["prices"][number]
					> name="prices">
						{({ fields }) => (
							<Box as="ol" className="list">
								{fields.map((name, index) => (
									<Box as="li" key={name}>
										<Stack gap="4">
											<Grid gap="4">
												<Col>
													<DatePickerField
														label="Start Date"
														name={`${name}.start_date`}
													/>
												</Col>
												<Col>
													<DatePickerField
														label="End Date"
														name={`${name}.end_date`}
													/>
												</Col>
												<Col>
													<SelectField
														select={SelectHotels}
														name={`${name}.hotel`}
														multiple={false}
														label="Hotel"
													/>
												</Col>
												<Col>
													<GetFieldValue<IHotel | undefined>
														name={`${name}.hotel`}
													>
														{({ value: hotel }) => (
															<SelectField
																select={SelectMealPlans}
																name={`${name}.meal_plan`}
																label="Meal Plan"
																searchable={false}
																multiple={false}
																options={(hotel && hotel.meal_plans) || []}
															/>
														)}
													</GetFieldValue>
												</Col>
												<Col>
													<GetFieldValue<IHotel | undefined>
														name={`${name}.hotel`}
													>
														{({ value: hotel }) => (
															<SelectField
																select={SelectRoomTypes}
																name={`${name}.room_type`}
																label="Room Type"
																searchable={false}
																multiple={false}
																options={(hotel && hotel.room_types) || []}
																onChange={(value: IHotelRoomType, name) => {
																	form.change(name as never, value as never)
																	if (!value || !value.allowed_extra_beds) {
																		form.change(
																			`${name}.adult_with_extra_bed_price` as never,
																			0 as never
																		)
																		form.change(
																			`${name}.child_with_extra_bed_price` as never,
																			0 as never
																		)
																	}
																}}
															/>
														)}
													</GetFieldValue>
												</Col>
											</Grid>
											<Grid gap="4">
												<Col>
													<TextInputField
														label="Number of persons"
														name={`${name}.persons`}
														type="number"
													/>
												</Col>
												<Col>
													<Inline>
														<SelectTenantCurrencyInputField
															name={`${name}.currency`}
															label="Currency"
															required
														/>
														<TextInputField
															label="Base Price"
															name={`${name}.base_price`}
															type="number"
														/>
													</Inline>
												</Col>
												<GetFieldValue<IHotelRoomType | undefined>
													name={`${name}.room_type`}
												>
													{({ value: roomType }) => (
														<>
															<Col>
																<TextInputField
																	label="Adult with extra bed price"
																	name={`${name}.adult_with_extra_bed_price`}
																	type="number"
																	title={
																		!roomType
																			? "Please select a room type"
																			: !roomType.allowed_extra_beds
																				? "No extra bed allowed"
																				: undefined
																	}
																	disabled={
																		!roomType || !roomType.allowed_extra_beds
																	}
																/>
															</Col>
															<Col>
																<TextInputField
																	label="Child with extra bed price"
																	name={`${name}.child_with_extra_bed_price`}
																	type="number"
																	title={
																		!roomType
																			? "Please select a room type"
																			: !roomType.allowed_extra_beds
																				? "No extra bed allowed"
																				: undefined
																	}
																	disabled={
																		!roomType || !roomType.allowed_extra_beds
																	}
																/>
															</Col>
															<Col>
																<TextInputField
																	label="Child without extra bed price"
																	name={`${name}.child_without_extra_bed_price`}
																	type="number"
																/>
															</Col>
														</>
													)}
												</GetFieldValue>
											</Grid>
										</Stack>
										<Divider sm />
										<Inline marginBottom="4" gap="1">
											<GetFieldValue<
												Required<NewPriceCredentials>["prices"][number]
											>
												name={`${name}`}
											>
												{({ value: price }) => (
													<Button
														level="secondary"
														onClick={() => fields.push(price)}
													>
														<Icons.Duplicate /> Duplicate
													</Button>
												)}
											</GetFieldValue>
											{Number(fields.length) > 1 ? (
												<Button
													level="secondary"
													onClick={() => fields.remove(index)}
												>
													<Icons.Cancel /> Remove
												</Button>
											) : null}
										</Inline>
									</Box>
								))}
								<Divider />
								<Box marginBottom="2">
									<Button onClick={() => fields.push(initialValues.prices[0])}>
										<Icons.Plus /> Add More
									</Button>
								</Box>
							</Box>
						)}
					</FieldArray>
					<Divider />
					<Stack gap="4">
						<SubmissionError />
						<Inline gap="4">
							<Button type="submit" disabled={submitting}>
								{submitting ? "Saving..." : "Save Hotel Prices"}
							</Button>
							{onCancel ? <Button onClick={onCancel}>Cancel</Button> : null}
						</Inline>
					</Stack>
				</form>
			)}
		</Form>
	)
}
