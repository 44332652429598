import { Helmet } from "react-helmet-async"
import { TransportServiceProviderPayments } from "./../../../../TransportServiceProviders"
import { useParams } from "./../../../../router-utils"

export default function TransportServiceProviderPaymentsPage() {
	const { transportServiceProviderId } = useParams(
		"/transport-service-providers/:transportServiceProviderId/payments"
	)
	return (
		<>
			<Helmet>
				<title>Payments | TSP</title>
			</Helmet>
			<TransportServiceProviderPayments
				transportServiceProviderId={transportServiceProviderId}
			/>
		</>
	)
}
