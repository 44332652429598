import {
	Alert,
	Box,
	Text,
	Inline,
	Stack,
	CheckboxInput,
	Divider,
} from "@sembark-travel/ui/base"
import { useDialog, Dialog } from "@sembark-travel/ui/dialog"
import { useXHR } from "@sembark-travel/xhr"
import React, { useCallback, useMemo, useState } from "react"
import {
	NewDriverDataSchema,
	NewDriverForm,
	NewDriverFormProps,
} from "./NewItem"
import { TDriver } from "./store"

type UpdateDriverProps = Omit<
	NewDriverFormProps,
	"initialValues" | "onSubmit"
> & {
	driver: TDriver
	onSuccess?: (driver: TDriver) => void
}

export default function UpdateDriver({
	driver,
	onSuccess,
	...props
}: UpdateDriverProps) {
	const xhr = useXHR()
	const initialValues = useMemo(() => {
		return {
			name: driver.name || "",
			email: driver.email || "",
			phone_numbers: driver.phone_numbers,
		}
	}, [driver])
	const [confirmed, setConfirmed] = useState(false)
	const handleUpdate = useCallback(
		async ({ transport_service_provider, ...data }: NewDriverDataSchema) => {
			if (!confirmed) {
				return Promise.reject({
					message: "Please select the confirmation checkbox.",
				})
			}
			const resp = await xhr.post(`/drivers/${driver.id}`, {
				...data,
				transport_service_provider: transport_service_provider?.id,
			})
			onSuccess?.(resp.data.data)
		},
		[driver, xhr, onSuccess, confirmed]
	)
	return (
		<Stack gap="8">
			<Alert
				status={!confirmed ? "warning" : "info"}
				title="Existing Data Change"
			>
				<Stack gap="2">
					<Text>
						Updating the driver details will also update the existing data (e.g.
						cab services, accounting etc.) where this driver is referenced.{" "}
						<b>Please only update the driver details if they are incorrect.</b>
					</Text>
					<Text>
						If you want to add new driver, please us the <b>Add Driver</b>{" "}
						option.
					</Text>
					<Divider sm />
					<Inline as="label" alignItems="center" gap="2">
						<CheckboxInput
							name="confirmed"
							onChange={() => setConfirmed((c) => !c)}
						/>
						<Text as="span" fontWeight="semibold">
							Driver details are incorrect and should be updated on all places.
						</Text>
					</Inline>
				</Stack>
			</Alert>
			<Box>
				<NewDriverForm
					onSubmit={handleUpdate}
					initialValues={initialValues}
					{...props}
				/>
			</Box>
		</Stack>
	)
}

type UpdateDriverInDialogProps = Omit<UpdateDriverProps, "onCancel"> & {
	children: (props: { edit: () => void }) => React.ReactNode
}

export function UpdateDriverInDialog({
	onSuccess,
	children,
	...props
}: UpdateDriverInDialogProps) {
	const [isOpen, open, close] = useDialog()
	return (
		<>
			{children({ edit: open })}
			<Dialog open={isOpen} onClose={close} title="Edit Driver Details">
				<Dialog.Body>
					<UpdateDriver
						{...props}
						onCancel={close}
						onSuccess={(...args) => {
							onSuccess?.(...args)
							close()
						}}
					/>
				</Dialog.Body>
			</Dialog>
		</>
	)
}
