import { Helmet } from "react-helmet-async"
import { useParams } from "../../../router-utils"
import { TripDestinationItemDetails } from "./../../../TripDestinations"

export default function TripDestinationItemDetailsPage(
	props: Omit<
		React.ComponentProps<typeof TripDestinationItemDetails>,
		"tripDestinationId"
	>
) {
	const { tripDestinationId } = useParams(
		"/trip-destinations/:tripDestinationId"
	)
	return (
		<>
			<Helmet>
				<title>Trip Destination Details</title>
			</Helmet>
			<TripDestinationItemDetails
				tripDestinationId={tripDestinationId}
				{...props}
			/>
		</>
	)
}
