import {
	Spinner,
	Table,
	Stack,
	Text,
	Heading,
	Container,
	Button,
	Component,
	Inline,
	Icons,
	joinAttributes,
} from "@sembark-travel/ui/base"
import { ListView, Search, useSearch } from "@sembark-travel/ui/list"
import { Breadcrumbs, useLocationQuery } from "@sembark-travel/ui/router"
import { useXHR } from "@sembark-travel/xhr"
import { useEffect, useState } from "react"
import useSWR from "swr"
import { generatePath } from "../../router-utils"
import pluralize from "pluralize"
import { Dialog } from "@sembark-travel/ui/dialog"
import {
	Form,
	SubmissionError,
	TextInputField,
	withServerErrors,
} from "@sembark-travel/ui/form"
import { Optional } from "utility-types"
import {
	LatLongInputFields,
	MapLink,
	SynonumsNamesInputField,
} from "./FormInputFields"
import { EditState } from "./CountryDetails"
import { worldXHR, TWorldCity, TWorldCountry, TWorldState } from "../../World"

export function StateDetails({ stateId }: { stateId: string | number }) {
	const xhr = useXHR()
	const { data, mutate: refresh } = useSWR(`/admin/states/${stateId}`, () =>
		worldXHR(xhr).getState<
			TWorldState & { country: TWorldCountry; cities_count: number }
		>(stateId, { include: "cities_count " })
	)
	if (!data) {
		return <Spinner alignCenter padding="4" />
	}
	const {
		id,
		name,
		state_code,
		country_id,
		country,
		cities_count,
		synonyms_1,
		synonyms_2,
		synonyms_3,
	} = data
	return (
		<>
			<Breadcrumbs
				title={`${name}`}
				items={[
					[generatePath("/admin/world/countries"), "Countries"],
					[
						generatePath("/admin/world/countries/:countryId", {
							countryId: country_id.toString(),
						}),
						country.name,
					],
					["", "States"],
				]}
			/>
			<Stack gap="4">
				<Container paddingY="4" bgColor="default">
					<Inline gap="4">
						<Stack gap="1">
							<Heading>
								{name} ({state_code}), {country.name} ({data.country_code})
							</Heading>
							<Text>
								{joinAttributes(
									`Synonyms: ${
										[synonyms_1, synonyms_2, synonyms_3]
											.filter(Boolean)
											.join(", ") || "N/A"
									}`,
									pluralize("City", cities_count, true),
									data.latitude && data.longitude ? (
										<MapLink lat={data.latitude} long={data.longitude}>
											<Icons.LocationMarker />
										</MapLink>
									) : null
								)}
							</Text>
						</Stack>
						<Component initialState={false}>
							{({ state, setState }) => (
								<>
									<Button
										onClick={() => setState(true)}
										size="sm"
										level="tertiary"
									>
										<Icons.Pencil />
									</Button>
									<Dialog
										open={state}
										onClose={() => setState(false)}
										sm
										title={`Edit State "${data.name}"`}
									>
										<Dialog.Body>
											<EditState
												state={data}
												onCancel={() => setState(false)}
												onSuccess={() => {
													refresh()
													setState(false)
												}}
											/>
										</Dialog.Body>
									</Dialog>
								</>
							)}
						</Component>
					</Inline>
				</Container>
				<StateCitiesList stateId={id} />
			</Stack>
		</>
	)
}

function StateCitiesList({ stateId }: { stateId: string | number }) {
	const [query, setQuery] = useLocationQuery()
	const [searchParams, setSearchParams] = useSearch(query)
	useEffect(() => {
		setQuery(searchParams)
	}, [searchParams, setQuery])
	return (
		<Search
			title="Cities"
			initialParams={searchParams}
			onSearch={(params) => setSearchParams(params)}
			actions={() => (
				<Component initialState={false}>
					{({ state, setState }) => (
						<>
							<Button onClick={() => setState(true)}>New City</Button>
							<Dialog
								open={state}
								onClose={() => setState(false)}
								sm
								title="Add New City"
							>
								<Dialog.Body>
									<AddNewCity
										stateId={stateId}
										onCancel={() => setState(false)}
										onSuccess={() => setState(false)}
									/>
								</Dialog.Body>
							</Dialog>
						</>
					)}
				</Component>
			)}
		>
			{() => (
				<ListView<TWorldCity>
					pageKey={`admin/world/states/${stateId}/cities`}
					params={searchParams}
					onPageChange={(page) => setSearchParams({ ...searchParams, page })}
					fetch={(xhr, params) =>
						worldXHR(xhr).getCities({
							...params,
							state_id: stateId,
						})
					}
				>
					{({ items, refresh }) => (
						<Table
							headers={["Name", "State", "Country", "Map", ""]}
							responsive
							hover
							bordered
							alignCols={{ 4: "right" }}
							rows={items.map((item) => [
								<Stack>
									<Text>{item.name}</Text>
									{[item.synonyms_1, item.synonyms_2, item.synonyms_3]
										.filter(Boolean)
										.map((t) => (
											<Text key={t} fontSize="sm">
												{t}
											</Text>
										))}
								</Stack>,
								item.state_code,
								item.country_code,
								<MapLink lat={item.latitude} long={item.longitude}>
									<Icons.LocationMarker />
								</MapLink>,
								<Component initialState={false}>
									{({ state, setState }) => (
										<>
											<Button
												onClick={() => setState(true)}
												size="sm"
												level="tertiary"
											>
												<Icons.Pencil />
											</Button>
											<Dialog
												open={state}
												onClose={() => setState(false)}
												sm
												title={`Edit City "${item.name}"`}
											>
												<Dialog.Body>
													<EditCity
														city={item}
														onCancel={() => setState(false)}
														onSuccess={() => {
															refresh()
															setState(false)
														}}
													/>
												</Dialog.Body>
											</Dialog>
										</>
									)}
								</Component>,
							])}
						/>
					)}
				</ListView>
			)}
		</Search>
	)
}

function AddNewCity(props: Omit<NewCityFormProps, "onSubmit">) {
	const xhr = useXHR()
	return (
		<NewCityForm
			onSubmit={(payload) => worldXHR(xhr).storeCity(payload)}
			{...props}
		/>
	)
}

function EditCity({
	city,
	...props
}: Omit<NewCityFormProps, "onSubmit" | "stateId"> & { city: TWorldCity }) {
	const xhr = useXHR()
	return (
		<NewCityForm
			stateId={city.state_id}
			initialValues={{
				name: city.name,
				synonyms_1: city.synonyms_1,
				synonyms_2: city.synonyms_2,
				synonyms_3: city.synonyms_3,
				latitude: city.latitude,
				longitude: city.longitude,
			}}
			onSubmit={(payload) => worldXHR(xhr).updateCity(city.id, payload)}
			{...props}
		/>
	)
}
type NewCityFormData = {
	name: string
	synonyms_1?: string | null
	synonyms_2?: string | null
	synonyms_3?: string | null
	state_id: number | string
	latitude?: string
	longitude?: string
}

type NewCityFormProps = {
	stateId: string | number
	onSubmit: (payload: object) => Promise<unknown>
	onSuccess?: () => void
	onCancel: () => void
	initialValues?: Optional<NewCityFormData>
}

function NewCityForm({
	stateId,
	onSubmit,
	onCancel,
	onSuccess,
	initialValues: propInitialValues,
}: NewCityFormProps) {
	const [initialValues] = useState<NewCityFormData>(() => ({
		name: "",
		synonyms_1: "",
		synonyms_2: "",
		synonyms_3: "",
		state_id: stateId,
		latitude: "",
		longitude: "",
		...propInitialValues,
	}))
	return (
		<Form<NewCityFormData>
			initialValues={initialValues}
			onSubmit={withServerErrors(async (values) => {
				await onSubmit(values)
				onSuccess?.()
			})}
			subscription={{ submitting: true }}
		>
			{({ submitting, handleSubmit }) => (
				<form onSubmit={handleSubmit} noValidate>
					<Stack gap="4">
						<TextInputField name="name" type="text" label="Name" required />
						<LatLongInputFields />
						<SynonumsNamesInputField />
						<SubmissionError />
						<Inline gap="4">
							<Button type="submit" disabled={submitting}>
								{submitting ? "Please wait.." : "Save Details"}
							</Button>
							<Button disabled={submitting} onClick={() => onCancel()}>
								Cancel
							</Button>
						</Inline>
					</Stack>
				</form>
			)}
		</Form>
	)
}
