import { createContext, useContext } from "react"
import axios, { AxiosResponse } from "axios"
import type {
	AxiosRequestConfig,
	AxiosInstance,
	AxiosError,
	AxiosStatic,
} from "axios"
import { compose } from "redux"
import { stringify, parse } from "qs"
import { trackApiResponseTime } from "@sembark-travel/tracking"

export const xhrConfig = {
	apiBaseUrl: "/api",
	serverBase: "/",
	appVersion: "1.0.0",
}

export function configureXHR(config: typeof xhrConfig) {
	xhrConfig.serverBase = config.serverBase
	xhrConfig.apiBaseUrl = config.apiBaseUrl
	xhrConfig.appVersion = config.appVersion
	axios.defaults.baseURL = config.apiBaseUrl
	axios.defaults.withXSRFToken = function (config) {
		return Boolean(
			config.baseURL && config.baseURL.startsWith(xhrConfig.apiBaseUrl)
		)
	}
}

/**
 * Attach the client application version
 * @return AxiosRequestConfig
 */
function attachAppVersionInterceptor(
	config: AxiosRequestConfig
): AxiosRequestConfig {
	if (!config.headers) {
		config.headers = {}
	}
	config.headers["X-App-Version"] = xhrConfig.appVersion
	return config
}

/**
 * Request interceptor for update the content type to x-www-form-urlencoded
 *
 * This interceptor will change the request content type to `x-ww-form-urlencoded` which is not the default in axios.
 * Axios's default is `application/json` which causes pre-flight request for CORS
 */
function contentTypeXWWWFormUrlencodedInterceptor(
	config: AxiosRequestConfig
): AxiosRequestConfig {
	const data = config.data
	const params = config.params
	if (!config.headers) {
		config.headers = {}
	}
	const existingContentType = config.headers["Content-Type"]
	if (existingContentType !== "multipart/form-data") {
		config.headers["Content-Type"] = "application/x-www-form-urlencoded"
	}
	// if it is already FormData, nothing is required
	if (data instanceof FormData) {
		return config
	}
	// else stringify the data and update it
	config.data = stringify(data, { addQueryPrefix: false })
	config.url = config.url + stringify(params, { addQueryPrefix: true })
	config.params = undefined
	return config
}

/**
 * Intercept the request to change the method type (put, patch, delete) to supported method type
 *
 * DELETE, PUT, PATCH methods are not support in the XHR requests, but our backend endpoints accept these method types.
 * Larave/Lumen request interceptors will resolve a request type via `_method` property in the request data,
 * i.e. delete, put and patch requests, we will send as a post request with `_method = delete | put | patch` key in the
 * request data
 */
function methodTypeInterceptor(config: AxiosRequestConfig) {
	const method = (config.method || "").toUpperCase()
	const data = config.data || {}
	switch (method) {
		case "DELETE":
		case "PATCH":
		case "PUT":
			if (data instanceof FormData) {
				data.append("_method", method)
			} else {
				data["_method"] = method
			}
			config.method = "POST"
	}
	config.data = data
	return config
}

/**
 * Intercept the request and attach the current time
 */
function attachRequestStartTimeInterception(
	config: AxiosRequestConfig & { meta?: Record<string, string | number> }
) {
	config.meta = config.meta || {}
	config.meta.requestStartedAt = new Date().getTime()
	return config
}

/**
 * Intercept the response and log the duration
 */
function extractResponseTimeInterception(
	response: AxiosResponse
): AxiosResponse {
	const meta = (
		response.config as never as { meta?: Record<string, string | number> }
	).meta
	const responseHeaders = response.headers
	const backendAppTime = responseHeaders["x-app-time"] || ""
	const backendServerTime = responseHeaders["x-request-time"] || backendAppTime
	if (meta) {
		const requestStartedAt = Number(meta.requestStartedAt)
		let url = response.config.url || "/"
		const queryIndex = url.indexOf("?")
		let queryString = ""
		if (queryIndex !== -1) {
			queryString = url.substring(queryIndex + 1)
			url = url.substring(0, queryIndex)
			queryString = stringify(parse(queryString), { encode: false })
		}
		trackApiResponseTime({
			method: (response.config.method || "get").toLowerCase(),
			totalTime: (new Date().getTime() - requestStartedAt) / 1000,
			url,
			search: queryString,
			backendAppTime,
			backendServerTime,
		})
	}
	return response
}

/**
 * Handle the maintaince error response
 */
function maintainceErrorInterceptor(error: AxiosError): AxiosError {
	const response = error.response
	if (response) {
		const e = response.data as
			| { message: string; status_code: number }
			| undefined
		if (e && e.status_code === 503) {
			if (response.headers && response.headers["retry-after"]) {
				const retryAfter = response.headers["retry-after"]
				const l = window.location
				setTimeout(
					() => {
						window.location = l
					},
					parseInt(retryAfter) * 1000
				)
			}
			alert(e.message)
		}
	}
	return error
}

/**
 * Handle the maintaince error response
 */
function rateLimitErrorInterceptor(error: AxiosError): AxiosError {
	const response = error.response
	if (response) {
		const e = response.data as
			| { message: string; status_code: number }
			| undefined
		if (e && e.status_code === 429) {
			if (response.headers && response.headers["retry-after"]) {
				const retryAfter = response.headers["retry-after"]
				const lastLocation = window.location
				setTimeout(
					() => {
						window.location = lastLocation
					},
					parseInt(retryAfter) * 1000
				)
			}
			alert(e.message)
		}
	}
	return error
}

/**
 * Base url for requests
 *
 * This is simply a helper for requests so that we don't have to use the env all over the places.
 * If in any case, we need to disabled this behaviour, we can write the
 * full uri (https://apis.sembark.com/login) instead of path (/login)
 */
axios.defaults.baseURL = xhrConfig.apiBaseUrl

/**
 * Pass the credentials
 */
axios.defaults.withCredentials = true

const globalRequestSuccessInterceptors = [
	attachRequestStartTimeInterception,
	attachAppVersionInterceptor,
	methodTypeInterceptor,
	contentTypeXWWWFormUrlencodedInterceptor,
]

const globalResponseSuccessInterceptors = [extractResponseTimeInterception]

const globalResponseErrorInterceptors = [
	maintainceErrorInterceptor,
	rateLimitErrorInterceptor,
]
function addGlobalInterceptors<T extends AxiosInstance>(instance: T) {
	// inject the interceptors for request and response
	instance.interceptors.request.use(
		compose(...globalRequestSuccessInterceptors.concat().reverse())
	)

	instance.interceptors.response.use(
		compose(...globalResponseSuccessInterceptors.concat().reverse()),
		(error: AxiosError) => {
			compose(...globalResponseErrorInterceptors.concat().reverse())(error)
			// change error message if possible
			error.message =
				(error.response?.data as { message: string } | undefined)?.message ||
				error.message
			return Promise.reject(error)
		}
	)
	return instance
}

addGlobalInterceptors(axios)

export function extendXHRInstance(
	base: AxiosStatic,
	config: AxiosRequestConfig
) {
	const instance = base.create(config)
	return addGlobalInterceptors(instance)
}

export type T_ErrorResponseInterceptor = typeof rateLimitErrorInterceptor

export function addGlobalResponseErrorInterceptor(
	interceptor: T_ErrorResponseInterceptor
) {
	globalResponseErrorInterceptors.push(interceptor)
	;(
		axios.interceptors.request as never as { handlers: Array<unknown> }
	).handlers = []
	;(
		axios.interceptors.response as never as { handlers: Array<unknown> }
	).handlers = []
	addGlobalInterceptors(axios)
}

export function addGlobalResponseSuccessInterceptor(
	interceptor: typeof extractResponseTimeInterception
) {
	globalResponseSuccessInterceptors.push(interceptor)
	;(
		axios.interceptors.request as never as { handlers: Array<unknown> }
	).handlers = []
	;(
		axios.interceptors.response as never as { handlers: Array<unknown> }
	).handlers = []
	addGlobalInterceptors(axios)
}

export async function refreshCSRFToken() {
	await axios.get("/sanctum/csrf-cookie", { baseURL: xhrConfig.serverBase })
}

function readCookie(name: string) {
	if (typeof document === "undefined") return null
	const match = document.cookie.match(
		new RegExp("(^|;\\s*)(" + name + ")=([^;]*)")
	)
	return match ? decodeURIComponent(match[3]) : null
}

export function getXSRFToken(xhr: AxiosInstance) {
	return {
		headerName: String(xhr.defaults.xsrfHeaderName),
		cookieName: String(xhr.defaults.xsrfCookieName),
		value: readCookie(String(xhr.defaults.xsrfCookieName)) || "",
	}
}

/**
 * Context for XHR
 *
 * Usage: Wrap a component with withXHR (dont forget to extends the XHRProps in the swapped components)
 */
export const XHRContext = createContext<AxiosStatic>(axios)
export type XHRProps = { xhr: AxiosStatic }

export function useXHR() {
	return useContext(XHRContext)
}

/**
 * Page wise listing with total count and next/prev pages
 * Slowest: as it needs to calculate the total
 * Usage: Used in listing page where total cousting is helpful
 */
export interface IListResponse<Item> {
	data: Array<Item>
	meta: {
		from: number
		to: number
		total: number
		current_page: number
		last_page: number
		per_page: number
	}
}

/**
 * Cursor paginated list with next/prev pages, without total
 * Faster: Reduces the total counting and usage database cursor
 * Usage: Used in listing pages where total counting is not helpful
 */
export interface ICursorListResponse<Item> {
	data: Array<Item>
	meta: {
		next_cursor: string | null
		prev_cursor: string | null
		per_page: number
	}
}

/**
 * Simple listing with a offset and limit, without any next/previous/total details
 * Fastest: Reduces the total counting and doesn't use database cursor, simple limit and offset
 * Usage: Used in selection dropdowns
 * Issue: You can not tell if you reached at the last page.
 * Needs an extra call to check if there are any more items left
 */
export interface ISimpleListResponse<Item> {
	data: Array<Item>
	meta: {
		from: number
		to: number
		current_page: number
		per_page: number
	}
}
export const xhr = axios

export function isAbortError(error: unknown) {
	if (error instanceof DOMException && error.name === "AbortError") {
		return true
	}
	if (axios.isCancel(error)) return true
	return false
}

export type XHRInstance = AxiosInstance

export function isApiResponseError(payload: unknown): payload is AxiosError {
	return axios.isAxiosError(payload)
}
