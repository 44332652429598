import { createContext, useContext } from "react"

import { TDateTimeContext } from "./types"

const DateTimeContext = createContext<TDateTimeContext | undefined>(undefined)

export const DateTimeProvider = DateTimeContext.Provider
export const DateTimeConsumer = DateTimeContext.Consumer

export function useDateTimeContext() {
	const dateTime = useContext(DateTimeContext)
	if (!dateTime) {
		throw new Error(
			"DateTime Context is undefined. Please verify that you are calling useDateTimeContext() as child of <Datetime> component."
		)
	}
	return dateTime
}
