import { IAddress } from "../Addresses"
import { IPhoneNumber } from "../Contacts"
import { TMedia } from "../Media"
import { IListResponse, XHRInstance } from "@sembark-travel/xhr"
import { IUser } from "../Users"

export type TTripDestination = {
	id: number
	name: string
	short_name: string
	description?: string
	trips_count?: number
	converted_trips_count?: number
	in_progress_trips_count?: number
	image_url?: string | null
	thumb_image_url?: string | null
	latest_media?: TMedia | null
	currency: string
	phone_numbers?: Array<IPhoneNumber<TTripDestination>>
	addresses?: Array<IAddress>
	created_at: string
	created_by?: IUser
	deleted_at?: string
	deleted_by?: IUser
	updated_at?: string
	updated_by?: IUser
	max_child_age: number
	hotel_checkin_at: string
	hotel_checkout_at: string
}

export function tripDestinationsXHR(xhr: XHRInstance) {
	return {
		async get(params?: unknown): Promise<IListResponse<TTripDestination>> {
			return xhr.get("/trip-destinations", { params }).then((resp) => resp.data)
		},
	}
}
