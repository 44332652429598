import { useParams, Navigate } from "./../../../../../router-utils"

export default function RedirectToHotels() {
	const { tripId } = useParams("/trips/:tripId/services-bookings")
	return (
		<Navigate
			to="/trips/:tripId/services-bookings/:serviceType"
			params={{
				tripId,
				serviceType: "hotels",
			}}
			replace
		/>
	)
}
