import { Box, Container } from "@sembark-travel/ui/base"
import { Breadcrumbs } from "@sembark-travel/ui/router"
import { Helmet } from "react-helmet-async"
import { useSearchParams } from "react-router-dom"
import { NewIntegrationItem } from "./../../../../Integrations"
import { generatePath, useNavigate } from "../../../../router-utils"

export default function NewIntegrationPage() {
	const navigate = useNavigate()
	const [searchParams] = useSearchParams()
	return (
		<>
			<Helmet>
				<title>New Integration</title>
			</Helmet>
			<Breadcrumbs
				title="New API Integration"
				items={[
					[generatePath("/org/integrations"), "Integrations"],
					["", "API Integration"],
				]}
			/>
			<Box paddingX={{ md: "4" }} paddingY={{ md: "8" }}>
				<Box maxWidth="3xl" marginX="auto">
					<Box borderWidth={{ md: "1" }} rounded="md" bgColor="default">
						<Container paddingY="6">
							<NewIntegrationItem
								initialValues={{
									name: searchParams.get("name") || undefined,
									description: searchParams.get("description") || undefined,
								}}
								onSuccess={({ integration, lastCreatedToken }) =>
									navigate("/integrations/:integrationId", {
										params: {
											integrationId: integration.uid,
										},
										state: lastCreatedToken,
										replace: true,
									})
								}
								onCancel={() =>
									navigate("/org/integrations", { replace: true })
								}
							/>
						</Container>
					</Box>
				</Box>
			</Box>
		</>
	)
}
