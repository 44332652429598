import { Helmet } from "react-helmet-async"
import { HotelsBookingList } from "./../../../HotelBookings/List"

export default function HotelBookingsListPage() {
	return (
		<>
			<Helmet>
				<title>Hotel Bookings</title>
			</Helmet>
			<HotelsBookingList />
		</>
	)
}
