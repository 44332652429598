import {
	dateToUTCString,
	startOf,
	isSame,
} from "@sembark-travel/datetime-utils"
import {
	Box,
	Button,
	Icons,
	Select,
	Inline,
	Stack,
	Col,
	Grid,
	useTimeout,
	SROnly,
} from "@sembark-travel/ui/base"
import { useXHR, XHRInstance } from "@sembark-travel/xhr"
import { useCallback, useRef, useEffect, useMemo } from "react"
import { Required } from "utility-types"
import * as Validator from "yup"
import { SelectHotelExtraServices, IExtraService } from "./../ExtraServices"
import { SelectHotels, IHotel } from "./../Hotels"
import { useGetBookingDates } from "./utils"
import {
	Form,
	TextInputField,
	FieldGroup,
	SelectField,
	DatePickerField,
	validateFormValues,
	arrayMutators,
	FieldArray,
	FormProps,
	useFormState,
	EmptyNumberValidator,
	MoneyInputField,
} from "@sembark-travel/ui/form"
import { TTripDestination } from "../TripDestinations"
import { useCurrenciesOfTenant } from "../Currencies"

function XHR(_: XHRInstance) {
	return {
		async getPrice<T>(hotel_extras: T) {
			return Promise.resolve({ hotel_extras })
			// return xhr
			//   .get("/prices", { params: { hotel_extras } })
			//   .then(resp => resp.data)
		},
	}
}

export interface ExtraServicesParams {
	hotel_extras?: Array<{
		currency: string
		service?: IExtraService
		price?: number
		date?: Date
		hotel?: IHotel
		comments?: string
	}>
}

const validationSchema = Validator.object().shape({
	hotel_extras: Validator.array().of(
		Validator.object().shape({
			service: Validator.object()
				.typeError("Service field is required")
				.required("Service field is required"),
			hotel: Validator.object().required("Please select a hotel."),
			date: Validator.date().required("Please select a night."),
			price: EmptyNumberValidator().positive("Price should be positive"),
			comments: Validator.string(),
		})
	),
})

const validate = validateFormValues(validationSchema)

interface ExtraServicesFormProps {
	initialValues?: ExtraServicesParams
	onChange?: (
		hotels: Required<ExtraServicesParams, "hotel_extras">["hotel_extras"]
	) => void
	bookingFrom?: string
	bookingTo?: string
	shouldEmptyInitialValues?: boolean
	showBookedPrices?: boolean
	hotels?: Array<IHotel>
	tripDestinations: Array<TTripDestination>
}

export function ExtraServicesForm({
	initialValues: initialValuesProp,
	shouldEmptyInitialValues = true,
	onChange,
	bookingFrom,
	bookingTo,
	showBookedPrices,
	hotels,
	tripDestinations,
}: ExtraServicesFormProps) {
	const xhr = useXHR()
	const currency = useMemo(
		() => tripDestinations.map((d) => d.currency).at(0) || "INR",
		[tripDestinations]
	)
	const allCurrencies = useCurrenciesOfTenant()
	const INITIAL_VALUES: Required<ExtraServicesParams, "hotel_extras"> = useRef({
		hotel_extras: [
			{
				currency: currency,
				service: undefined,
				date: undefined,
				hotel: undefined,
				price: undefined,
				comments: "",
			},
		],
	}).current

	const initialValues = useRef(
		initialValuesProp ||
			(shouldEmptyInitialValues ? { hotel_extras: [] } : INITIAL_VALUES)
	).current
	const notifyOnChange = useCallback(
		(flattenValues: ExtraServicesParams) => {
			onChange &&
				onChange(
					(flattenValues.hotel_extras || []).filter(
						(he) => he.date && he.service && he.hotel
					)
				)
		},
		[onChange]
	)
	const bookingDates = useGetBookingDates(bookingFrom, bookingTo)
	const onSubmit: FormProps<ExtraServicesParams>["onSubmit"] = useCallback(
		async (values) => {
			const hotel_extras: Array<
				Omit<
					Required<ExtraServicesParams>["hotel_extras"][number],
					"date" | "service" | "hotel"
				> & {
					date: string
					service?: string
					hotel_id?: number
					booked_price?: number
				}
			> = []
			// flatten values so that we can show the prices for each row
			const flattenValues: Required<ExtraServicesParams, "hotel_extras"> = {
				hotel_extras: [],
			}
			values.hotel_extras?.forEach((values) => {
				const { date, service, hotel, ...otherData } = values
				if (service) {
					flattenValues.hotel_extras.push({
						...values,
					})
					hotel_extras.push({
						...otherData,
						date: date ? dateToUTCString(startOf(date, "day")) : "",
						hotel_id: hotel && hotel.id,
						service: service && service.name,
					})
				}
			})
			return XHR(xhr)
				.getPrice(hotel_extras)
				.then((data) => {
					flattenValues.hotel_extras = flattenValues.hotel_extras.map(
						(hotel, i) => ({
							...hotel,
							price: data.hotel_extras[i].price,
							booked_price:
								data.hotel_extras[i].booked_price === undefined
									? data.hotel_extras[i].price
									: data.hotel_extras[i].booked_price,
						})
					)
					notifyOnChange(flattenValues)
				})
		},
		[xhr, notifyOnChange]
	)
	return (
		<Form<ExtraServicesParams>
			initialValues={initialValues}
			validate={validate}
			onSubmit={onSubmit}
			subscription={{}}
			mutators={{
				...arrayMutators,
			}}
		>
			{({ handleSubmit }) => (
				<form noValidate onSubmit={handleSubmit}>
					<FieldArray<
						Required<ExtraServicesParams>["hotel_extras"][number]
					> name="hotel_extras">
						{({ fields }) => (
							<Stack gap="4">
								{fields.map((name, index) => (
									<Box
										key={name}
										borderBottomWidth="4"
										borderColor="default"
										paddingBottom="4"
									>
										<Grid gap="4">
											<Col>
												<SelectField
													select={SelectHotelExtraServices}
													name={`${name}.service`}
													label="Service"
													placeholder="Select or add a service..."
													required
													creatable
													fetchOnMount
												/>
											</Col>
											<Col>
												{hotels?.length ? (
													<SelectField
														select={Select}
														name={`${name}.hotel`}
														label="Hotel"
														multiple={false}
														options={hotels}
													/>
												) : (
													<SelectField
														select={SelectHotels}
														name={`${name}.hotel`}
														label="Hotel"
														multiple={false}
													/>
												)}
											</Col>
											<Col>
												{bookingFrom && bookingTo ? (
													<FieldGroup<
														Required<ExtraServicesParams>["hotel_extras"][number]["date"]
													>
														name={`${name}.date`}
													>
														{({ input }) => {
															const { value } = input
															return (
																<Select
																	{...input}
																	label="Night"
																	options={bookingDates}
																	searchable={false}
																	placeholder="Select night..."
																	required
																	value={
																		value
																			? bookingDates.find((d) =>
																					isSame(d.value, value, "day")
																			  )
																			: undefined
																	}
																	onChange={(startDate) => {
																		input.onChange(
																			startDate ? startDate.value : undefined
																		)
																	}}
																/>
															)
														}}
													</FieldGroup>
												) : (
													<DatePickerField
														label="Date"
														name={`${name}.date`}
														required
													/>
												)}
											</Col>
											<Col>
												<MoneyInputField
													label={`Price`}
													name={`${name}.price`}
													currencyFieldName={`${name}.currency`}
													currencies={allCurrencies}
												/>
											</Col>
											{showBookedPrices ? (
												<Col>
													<MoneyInputField
														label="Booking Price"
														name={`${name}.booked_price`}
														currencyFieldName={`${name}.currency`}
														currencies={allCurrencies}
													/>
												</Col>
											) : null}
											<Col>
												<TextInputField
													name={`${name}.comments`}
													label="Comments"
													placeholder="Any comments regarding service"
												/>
											</Col>
											<Col paddingTop="6">
												<Inline gap="2">
													<Button
														onClick={() => fields.remove(index)}
														title="Remove"
														size="sm"
														level="tertiary"
													>
														<Icons.Cancel />
													</Button>
												</Inline>
											</Col>
										</Grid>
									</Box>
								))}
								<Box>
									<Button
										onClick={() => {
											fields.push(
												hotels?.length === 1
													? {
															...INITIAL_VALUES.hotel_extras[0],
															hotel: hotels[0],
													  }
													: INITIAL_VALUES.hotel_extras[0]
											)
										}}
									>
										<Icons.Plus /> Add Service
									</Button>
								</Box>
							</Stack>
						)}
					</FieldArray>
					<SubmitOnChange onChange={notifyOnChange} />
					<SROnly>
						<Button type="submit">Get Prices</Button>
					</SROnly>
				</form>
			)}
		</Form>
	)
}

function SubmitOnChange({
	onChange,
}: {
	onChange: (values: ExtraServicesParams) => void
}) {
	const { values } = useFormState<ExtraServicesParams>({
		subscription: { values: true },
	})
	const { set: setNotifyParentTimeout, clear: clearNotifyParentTimeout } =
		useTimeout()

	const onChangeRef = useRef(onChange)
	onChangeRef.current = onChange

	// notify parent
	useEffect(() => {
		setNotifyParentTimeout(() => {
			onChangeRef.current(values)
		}, 500)
		return () => clearNotifyParentTimeout()
	}, [values, setNotifyParentTimeout, clearNotifyParentTimeout])
	return null
}
