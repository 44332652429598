import raf from "raf"
import React, { useEffect, useState } from "react"
import { Spinner } from "./Spinner"

export function DeferRender({
	children,
	by = 500,
	noLoader,
}: {
	children: React.ReactNode
	/**
	 * Number of milliseconds to defer by
	 */
	by?: number
	noLoader?: boolean
}) {
	const [render, setRender] = useState(false)
	useEffect(() => {
		// nothing to do if already rendered
		if (render) return () => undefined
		let rafHandler: number
		const timeoutToken = setTimeout(() => {
			rafHandler = raf(() => {
				setRender(true)
			})
		}, by)
		return () => {
			if (rafHandler) {
				raf.cancel(rafHandler)
			}
			clearTimeout(timeoutToken)
		}
	}, [by, render])
	if (!render) {
		if (noLoader) return null
		return <Spinner padding="2" alignCenter />
	}
	return <>{children}</>
}
