import { useXHR } from "@sembark-travel/xhr"
import { hotelsXHR as XHR } from "./store"
import { SelectContactForContactables } from "../Contacts"
import { showSnackbar } from "@sembark-travel/ui/snackbar"

export function SelectHotelContact({
	hotelId,
	...props
}: Omit<
	React.ComponentProps<typeof SelectContactForContactables>,
	"onCreate" | "contactables"
> & {
	hotelId: number
}) {
	const xhr = useXHR()
	return (
		<SelectContactForContactables
			phoneNumberRequired
			{...props}
			cacheKey={`hotel-contacts-${hotelId}`}
			contactables={{ hotels: [hotelId] }}
			onCreate={async (data) => {
				const contact = await XHR(xhr).createContact(hotelId, data)
				showSnackbar("Contact added successfully")
				return contact
			}}
		/>
	)
}
