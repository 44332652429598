import {
	Box,
	Button,
	Inline,
	Table,
	Stack,
	Heading,
	Text,
	Divider,
	Icons,
	Grid,
	Col,
	Component,
} from "@sembark-travel/ui/base"
import { useXHR, XHRInstance } from "@sembark-travel/xhr"
import * as Validator from "yup"
import {
	Form,
	FileInputField,
	withServerErrors,
	SubmissionError,
	validateFormValues,
	SelectField,
} from "@sembark-travel/ui/form"
import { SelectTripSources, ITripSource } from "./../TripSources"
import {
	CopyToClipboard,
	CopyToClipboardButton,
} from "@sembark-travel/ui/copy-to-clipboard"
import { SelectUsers } from "../Users"
import { IUser } from "../Auth"

function XHR(xhr: XHRInstance) {
	return {
		async uploadTrips(data: unknown) {
			return xhr.post("/upload-trips", data, {
				headers: {
					"Content-Type": "multipart/form-data",
				},
			})
		},
	}
}

interface IData {
	timezone_offset: number
	file: File | null
	trip_source?: ITripSource
	sales_team?: Array<IUser>
}

const initialValues: IData = {
	timezone_offset: new Date().getTimezoneOffset(),
	file: null,
	trip_source: undefined,
	sales_team: [],
}

const validationSchema = validateFormValues(
	Validator.object().shape({
		timezone_offset: Validator.string().required(),
		file: Validator.mixed().required("File field is required"),
		trip_source: Validator.object().required("Trip Source is required"),
		sales_team: Validator.array().nullable(),
	})
)

const THIS_YEAR = new Date().getFullYear()

export function UploadTrips({
	onSuccess,
	onCancel,
}: {
	onSuccess: () => void
	onCancel?: () => void
}) {
	const xhr = useXHR()
	return (
		<>
			<Form<typeof initialValues>
				initialValues={initialValues}
				validate={validationSchema}
				onSubmit={withServerErrors(async (values) => {
					if (!values.trip_source || !values.file) {
						throw Error("Trip Source and File fields are required.")
					}
					const data = new FormData()
					data.set("timezone_offset", values.timezone_offset.toString())
					data.set("file", values.file)
					data.set("trip_source", String(values.trip_source.id))
					values.sales_team?.forEach((user, index) => {
						data.set(`sales_team[${index}]`, user.id.toString())
					})
					await XHR(xhr).uploadTrips(data)
					onSuccess()
				})}
				subscription={{ submitting: true }}
			>
				{({ submitting, handleSubmit }) => (
					<form
						noValidate
						encType="multipart/form-data"
						onSubmit={handleSubmit}
					>
						<Stack gap="4">
							<SubmissionError />
							<FileInputField
								label="Select a csv file"
								name="file"
								accept=".csv"
							/>
							<Grid gap="4">
								<Col xs={12} sm="auto">
									<SelectField
										select={SelectTripSources}
										name="trip_source"
										label="Trip Source"
										placeholder="Select a Trip Source"
										required
										fetchOnMount
										creatable
										multiple={false}
									/>
								</Col>
								<Col xs={12} sm="auto">
									<Component initialState={false}>
										{({ state, setState }) => (
											<Box>
												{!state ? (
													<Box>
														<Stack gap="1">
															<Text fontWeight="semibold">Sales Team</Text>
															<Box>
																<Button
																	onClick={() => setState(true)}
																	level="secondary"
																	title="Click to Edit"
																>
																	You <Icons.Pencil opacity="50" />
																</Button>
															</Box>
														</Stack>
													</Box>
												) : (
													<SelectField
														name="sales_team"
														select={SelectUsers}
														label="Sales Team"
														multiple
														autoFocus
														fetchOnMount
													/>
												)}
											</Box>
										)}
									</Component>
								</Col>
							</Grid>
							<Divider sm />
							<Inline gap="4">
								<Button type="submit" disabled={submitting}>
									{submitting
										? "Uploading. Please wait..."
										: "Upload Queries CSV"}
								</Button>
								{onCancel ? (
									<Button level="tertiary" onClick={onCancel}>
										Cancel
									</Button>
								) : null}
							</Inline>
						</Stack>
					</form>
				)}
			</Form>
			<Divider />
			<CopyToClipboard<HTMLDivElement>>
				{({ copy, nodeToCopy }) => (
					<Stack gap="4">
						<Heading as="h4">CSV File Format</Heading>
						<Text maxWidth="4xl">
							When creating your CSV file for queries, please follow this format
							only. You can find more information about each column by hovering
							over the words with{" "}
							<b>
								<abbr title="You will get information like this. Please read each information carefully for best and accurate results.">
									dotted underlines
								</abbr>
							</b>
							. If you are unsure about what/why a column is, please feel free
							to contact our support team.
						</Text>
						<Box ref={nodeToCopy}>
							<Table
								aria-label="CSV file format for trips"
								responsive
								className="excel-style-table"
							>
								<Box as="thead" whiteSpace="preserve">
									<tr>
										<th>
											<abbr title="Destination of the Trip.">Destination</abbr>
										</th>
										<th>
											<abbr
												title={`Trip's start date. Must follow 'DD-MM-YYYY' format e.g. 25-07-${THIS_YEAR}`}
											>
												Start Date
											</abbr>
										</th>
										<th>
											<abbr title="Trip duration in nights (days - 1)">
												No of Nights
											</abbr>
										</th>
										<th>
											<abbr title="Any custom Reference ID for this trip. Leave blank if not applicable">
												Trip ID
											</abbr>
										</th>
										<th>
											<abbr title="Name of the guest on the trip">
												Guest Name
											</abbr>
										</th>
										<th>
											<abbr title="Guest' 10 digit phone number. India specific only (exclude +91). Leave blank if not available">
												Phone Number
											</abbr>
										</th>
										<th>
											<abbr title="Guest's email address. Leave blank if not available">
												Email
											</abbr>
										</th>
										<th>
											<abbr title="Number of adults for a trip">
												No of Adults
											</abbr>
										</th>
										<th>
											<abbr title="Children ages in the trip, separated by comma (','). Leave blank when there are not children on a trip">
												Children
											</abbr>
										</th>
										<th>
											<abbr title="Any useful comments/aditional details for query [optional]">
												Comments
											</abbr>
										</th>
									</tr>
								</Box>
								<tbody>
									<tr>
										<td rowSpan={2}>Rajasthan</td>
										<td>
											<abbr title={`20 December, ${THIS_YEAR}`}>
												20-12-{THIS_YEAR}
											</abbr>
										</td>
										<td>9</td>
										<td>1231312</td>
										<td>Sudhir M</td>
										<td>
											<abbr title="Please don't add +91 to the phone number">
												9999999999
											</abbr>
										</td>
										<td>sudhir@example.com</td>
										<td>4</td>
										<td>
											<abbr title="Here we have 4 children of 3, 3, 5 and 8 years old">
												3,3,5,8
											</abbr>
										</td>
										<td></td>
									</tr>
									<tr>
										<td>
											<abbr title={`13 January, ${THIS_YEAR}`}>
												13-01-{THIS_YEAR}
											</abbr>
										</td>
										<td>5</td>
										<td></td>
										<td>Anand SS</td>
										<td></td>
										<td></td>
										<td>4</td>
										<td></td>
										<td>Budget:56,000</td>
									</tr>
									<tr>
										<td>Sikkim</td>
										<td>07-12-{THIS_YEAR}</td>
										<td>5</td>
										<td>AB123232</td>
										<td>Rajat Sharma</td>
										<td></td>
										<td>rajat@example.com</td>
										<td>6</td>
										<td>1,2</td>
										<td>5 star Hotels</td>
									</tr>
									<tr>
										<td>Kerala</td>
										<td>07-12-{THIS_YEAR}</td>
										<td>5</td>
										<td></td>
										<td>Guest Name</td>
										<td>8888888888</td>
										<td></td>
										<td>6</td>
										<td></td>
									</tr>
								</tbody>
							</Table>
						</Box>
						<Box>
							<CopyToClipboardButton onClick={() => copy()} level="primary">
								<Icons.ClipboardCopy />
								Copy Template Format
							</CopyToClipboardButton>
						</Box>
					</Stack>
				)}
			</CopyToClipboard>
		</>
	)
}
