import { moneyConfig } from "./config"
import { TMoney } from "./types"
import { getMaximumFractionDigits } from "./utils"

export function formatMoneyByIntl(
	money: TMoney,
	config: {
		keepTrailingZero?: boolean
		showCurrency?: boolean
		roundOff?: boolean
	} = {
		keepTrailingZero: false,
		showCurrency: false,
		roundOff: false,
	}
): string {
	// TODO: Remove the fallback and ensure that a currency is passed
	const currency = money.currency || moneyConfig.defaultCurrency
	const formatter = new Intl.NumberFormat("en-IN", {
		style: "currency",
		currency,
		currencyDisplay: "code",
	})
	const maximumFractionDigits = getMaximumFractionDigits(formatter)
	let amount = money.amount / Math.pow(10, maximumFractionDigits)
	if (config?.roundOff) {
		amount = Math.round(amount)
	}
	let formattedAmount = formatter.format(amount)
	if (!config?.keepTrailingZero) {
		if (parseInt(amount.toString()) === Number(amount)) {
			// remove the decimal points
			if (maximumFractionDigits) {
				formattedAmount = formattedAmount.slice(
					0,
					-1 * (maximumFractionDigits + 1)
				)
			}
		}
	}
	if (!config?.showCurrency) {
		formattedAmount = formattedAmount.replace(currency, "").trim()
	}
	return formattedAmount
}

export function formatMoneyByDecimal(
	money: TMoney,
	config: { keepTrailingZero?: boolean } = { keepTrailingZero: false }
): string {
	// TODO: Remove the fallback and ensure that a currency is passed
	const currency = money.currency || moneyConfig.defaultCurrency
	const formatter = new Intl.NumberFormat("en-IN", {
		style: "currency",
		currency: currency,
	})
	const maximumFractionDigits = getMaximumFractionDigits(formatter)
	const amount = money.amount / Math.pow(10, maximumFractionDigits)
	if (!config.keepTrailingZero && Math.ceil(amount) == Math.floor(amount)) {
		return Number(amount).toFixed(0)
	}
	return Number(amount).toFixed(maximumFractionDigits)
}
