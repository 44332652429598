import { Box, BoxOwnProps } from "./Box"
import classNames from "classnames"
import type { ElementType, ForwardedRef } from "react"
import { forwardRef } from "react"
import type {
	PolymorphicForwardRefExoticComponent,
	PolymorphicPropsWithoutRef,
	PolymorphicPropsWithRef,
} from "./polymorphic-types"
import "./layout.css"

const DefaultElement = "div"

export type GridOwnProps = Omit<BoxOwnProps, "display"> & {
	noGutters?: boolean
	fluid?: boolean
}

export type GridProps<T extends ElementType = typeof DefaultElement> =
	PolymorphicPropsWithRef<GridOwnProps, T>

export const Grid: PolymorphicForwardRefExoticComponent<
	GridOwnProps,
	typeof DefaultElement
> = forwardRef(function Grid<T extends ElementType = typeof DefaultElement>(
	{
		as,
		className,
		noGutters,
		fluid = true,
		...restProps
	}: PolymorphicPropsWithoutRef<GridOwnProps, T>,
	ref: ForwardedRef<Element>
) {
	const Element: ElementType = as || DefaultElement
	noGutters = noGutters || restProps.gap !== undefined
	return (
		<Box
			paddingX={noGutters ? "0" : "4"}
			width="full"
			className={classNames("row-container", {
				"container--fixed-width": !fluid,
			})}
		>
			<Box
				{...restProps}
				className={classNames(
					"row",
					{
						"no-gutters": noGutters,
					},
					className
				)}
				as={Element}
				ref={ref}
			/>
		</Box>
	)
})

type NumberAttr = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12
type ColSize = true | "auto" | NumberAttr
type ColBase = { span?: ColSize; offset?: NumberAttr; order?: NumberAttr }
type ColSpec = ColSize | ColBase

export type ColOwnProps = BoxOwnProps & {
	xs?: ColSpec
	sm?: ColSpec
	md?: ColSpec
	lg?: ColSpec
	xl?: ColSpec
}

export type ColProps<T extends ElementType = typeof DefaultElement> =
	PolymorphicPropsWithRef<ColOwnProps, T>

export const Col: PolymorphicForwardRefExoticComponent<
	ColOwnProps,
	typeof DefaultElement
> = forwardRef(function Col<T extends ElementType = typeof DefaultElement>(
	{
		as,
		className,
		xs,
		sm,
		md,
		lg,
		xl,
		...restProps
	}: PolymorphicPropsWithoutRef<ColOwnProps, T>,
	ref: ForwardedRef<Element>
) {
	const Element: ElementType = as || DefaultElement
	const prefix = "col"
	const classes: Array<string> = []
	const spans: Array<string> = []
	const deviceSize = [
		["xs", xs],
		["sm", sm],
		["md", md],
		["lg", lg],
		["xl", xl],
	]
	deviceSize.forEach(([breakPoint, value]) => {
		let span, offset, order
		if (value !== null && typeof value === "object") {
			span = value.span
			offset = value.offset
			order = value.order
		} else {
			span = value
		}
		const infix = breakPoint !== "xs" ? `-${breakPoint}` : ""
		if (span != null)
			spans.push(
				span === true ? `${prefix}${infix}` : `${prefix}${infix}-${span}`
			)

		if (order != null) classes.push(`order${infix}-${order}`)
		if (offset != null) classes.push(`offset${infix}-${offset}`)
	})
	if (!spans.length) {
		spans.push("col") // plain 'col'
	}
	return (
		<Box
			paddingX="4"
			{...restProps}
			className={classNames(className, ...classes, ...spans)}
			as={Element}
			ref={ref}
		/>
	)
})
