import {
	Box,
	Icons,
	Heading,
	Stack,
	Inline,
	Text,
	Col,
	Grid,
} from "@sembark-travel/ui/base"
import { Link, queryToSearch } from "@sembark-travel/ui/router"
import { Helmet } from "react-helmet-async"
import Logo from "../../images/Logo"
import { generatePath, useNavigate } from "../../router-utils"
import { RegisterationForm, useAuthUserFetch } from "./../../Auth"
import { useSearchParams } from "react-router-dom"

export default function Register() {
	const [searchParams] = useSearchParams()
	const navigate = useNavigate()
	const email = searchParams.get("email") || ""
	const fetchAuthUser = useAuthUserFetch()
	return (
		<Box paddingY={{ xs: "8", md: "16" }}>
			<Helmet>
				<title>Register</title>
			</Helmet>
			<Box maxWidth="6xl" marginX="auto">
				<Grid gap="8">
					<Col paddingY={{ md: "16" }} xs={12} md>
						<Box
							as="h2"
							fontWeight="semibold"
							fontSize="2xl"
							marginBottom="12"
							display="flex"
							alignItems="center"
							justifyContent={{ xs: "center", md: "start" }}
						>
							<Inline gap="4" alignItems="center">
								<Logo width="32" height="32" />
								<Text as="span">Sembark</Text>
							</Inline>
						</Box>
						<Box display={{ xs: "none", md: "block" }}>
							<Stack as="ul" gap="8">
								{[
									{
										title: "Get started quickly",
										description:
											"Start using right away and build your data as you use.",
									},
									{
										title: "Support any business model",
										description:
											"Travel agencies, Tour operators, Destination Management companies, and more all within a unified platform.",
									},
									{
										title: "Join growing community of businesses",
										description:
											"Sembark is trusted by ambitious startups and enterprises of every size.",
									},
								].map(({ title, description }) => (
									<Inline as="li" gap="4" key={title}>
										<Box paddingTop="1">
											<Icons.OkCircleSolid color="accent" size="8" />
										</Box>
										<Stack gap="1">
											<Heading as="h3" fontSize="md">
												{title}
											</Heading>
											<Text>{description}</Text>
										</Stack>
									</Inline>
								))}
							</Stack>
						</Box>
					</Col>
					<Col>
						<Box
							paddingX="6"
							paddingY="10"
							rounded="lg"
							boxShadow="lg"
							borderWidth="1"
							bgColor="default"
							maxWidth="xl"
							marginX="auto"
						>
							<Stack gap="8">
								<Box>
									<Heading as="h1" fontSize="xl">
										Create your Sembark account
									</Heading>
								</Box>
								<Box>
									<RegisterationForm
										email={email}
										onSuccess={async () => {
											await fetchAuthUser()
											navigate("/onboarding")
										}}
									/>
								</Box>
							</Stack>
						</Box>
						<Box marginTop="8">
							Already have an account?{" "}
							<Link
								to={generatePath("/login") + queryToSearch({ email })}
								color="accent"
							>
								Login
							</Link>
						</Box>
					</Col>
				</Grid>
			</Box>
		</Box>
	)
}
