import { Helmet } from "react-helmet-async"
import { TripInvoices } from "../../../../../Trips"
import { useTripDetailsOutletContext } from "../_trip-details-context"

export default function TripInvoicesPage() {
	const { trip, onChange } = useTripDetailsOutletContext()
	return (
		<>
			<Helmet>
				<title>Trip Invoices</title>
			</Helmet>
			<TripInvoices trip={trip} onChange={() => onChange()} />
		</>
	)
}
