import {
	Box,
	Button,
	Inline,
	Stack,
	Icons,
	Table,
	Heading,
	Badge,
	Component,
	joinAttributes,
	RelativeTime,
	TabContent,
	TabItem,
	Tabs,
	TabsList,
	Time,
	TimeDuration,
	useBreakpoints,
	Money,
	Text,
} from "@sembark-travel/ui/base"
import { Dialog } from "@sembark-travel/ui/dialog"
import {
	utcTimestampToLocalDate,
	utcTimestampToLocalDateTimeString,
	dateToUTCString,
	dateToQuery,
	parseDateFromQuery,
	startOf,
} from "@sembark-travel/datetime-utils"
import { ButtonLink, Link, useLocationQuery } from "@sembark-travel/ui/router"
import {
	ListView,
	Search,
	useSearch,
	TSearchParams,
	areAdvancedFiltersAppliedDefault,
	TSortOptions,
} from "@sembark-travel/ui/list"
import { IListResponse, XHRInstance } from "@sembark-travel/xhr"
import { Fragment, useEffect } from "react"
import { PERMISSIONS, useCheckPermissions } from "../Auth"
import { SelectHotelGroup, IHotelGroup } from "../HotelGroups"
import { SelectHotels, IHotel } from "../Hotels"
import { GeneratedVoucherAsset, MarkVoucherAsSent } from "../HotelVouchers"
import { ILocation } from "../Locations"
import { SelectTripSources } from "../TripSources"
import { ITripSource } from "../TripSources"
import { SelectUsers, IUser } from "../Users"
import { EditTripOwners } from "./../TripOwners"
import {
	ComposeHotelBookingEmail,
	GenerateVoucher,
	HotelBookingItemInDialog,
} from "./Item"
import {
	HotelBookingCurrentStage,
	SelectHotelBookingStageTags,
	UpdateBookingTagInDialog,
} from "./BookingStatus"
import { ITripHotelBooking } from "./store"
import { EditHotelBookingDetailsInDialog } from "./ItemForm"
import { SelectField, DatePickerField } from "@sembark-travel/ui/form"
import { useTripQuoteBookingsDiffCount } from "../TripQuoteBookingsDiff"
import { generatePath } from "../router-utils"

function XHR(xhr: XHRInstance) {
	return {
		async getHotelBookings(
			params?: unknown
		): Promise<IListResponse<ITripHotelBooking>> {
			return xhr
				.get("/trip-hotel-bookings", { params })
				.then((resp) => resp.data)
		},
	}
}

const sortOptions: TSortOptions = [
	{
		label: "Start Date",
		name: "start_date",
		type: "date",
		order: "asc",
	},
]

type TBookingStatus =
	| "new"
	| "in_progress"
	| "booked"
	| "on_trip"
	| "past"
	| "dropped"
	| "all"

interface IFilters extends TSearchParams {
	booking_tags?: Array<{ id: number; name: string }>
	trip_sources?: Array<ITripSource>
	owners?: Array<IUser>
	locations?: Array<ILocation>
	page?: number
	stay_after?: Date
	stay_before?: Date
	hotels?: Array<IHotel>
	hotel_groups?: Array<IHotelGroup>
	status?: TBookingStatus
	sort?: string
}

interface IFiltersInLocation extends TSearchParams {
	booking_tags?: string[]
	trip_sources?: string[]
	owners?: string[]
	locations?: string[]
	page?: number
	sa?: string
	sb?: string
	hotels?: string[]
	hotel_groups?: string[]
	status?: TBookingStatus
	sort?: string
}

function filtersToLocationQuery(filters: IFilters): IFiltersInLocation {
	const {
		q,
		locations,
		page,
		stay_after,
		stay_before,
		hotels,
		hotel_groups,
		booking_tags,
		trip_sources,
		owners,
		status,
		sort,
	} = filters
	const query: IFiltersInLocation = { status: status || "new" }
	if (q) query.q = q
	if (booking_tags && booking_tags.length)
		query.booking_tags = booking_tags.map((t) => `${t.id}_${t.name}`)
	if (trip_sources && trip_sources.length)
		query.trip_sources = trip_sources.map((t) => `${t.id}_${t.name}`)
	if (owners && owners.length)
		query.owners = owners.map((t) => `${t.id}_${t.name}`)
	if (locations && locations.length)
		query.locations = locations.map((t) => `${t.id}_${t.name}`)
	if (stay_after) {
		query.sa = dateToQuery(stay_after)
	}
	if (stay_before) {
		query.sb = dateToQuery(stay_before)
	}
	if (hotels?.length) query.hotels = hotels.map((t) => `${t.id}_${t.name}`)
	if (hotel_groups?.length)
		query.hotel_groups = hotel_groups.map((t) => `${t.id}_${t.name}`)
	if (page) query.page = page
	if (sort) query.sort = sort
	return query
}

function locationQueryToFilters(query: IFiltersInLocation): IFilters {
	const {
		q,
		locations,
		page,
		sa,
		sb,
		hotels,
		hotel_groups,
		status,
		booking_tags,
		trip_sources,
		owners,
		sort,
	} = query
	const filters: IFilters = { status: status || "new" }
	if (q) filters.q = q
	if (sa) filters.stay_after = parseDateFromQuery(sa)
	if (sb) filters.stay_before = parseDateFromQuery(sb)
	if (booking_tags && booking_tags.length)
		filters.booking_tags = booking_tags.map((t) => {
			const slices = t.split("_")
			return { id: parseInt(slices[0]), name: slices.slice(1).join("_") }
		}) as unknown as Array<{ id: number; name: string }>
	if (trip_sources && trip_sources.length)
		filters.trip_sources = trip_sources.map((t) => {
			const slices = t.split("_")
			return { id: parseInt(slices[0]), name: slices.slice(1).join("_") }
		}) as unknown as Array<ITripSource>
	if (locations && locations.length)
		filters.locations = locations.map((t) => {
			const slices = t.split("_")
			return { id: parseInt(slices[0]), name: slices.slice(1).join("_") }
		}) as unknown as Array<ILocation>
	if (hotels && hotels.length)
		filters.hotels = hotels.map((t) => {
			const slices = t.split("_")
			return { id: parseInt(slices[0]), name: slices.slice(1).join("_") }
		}) as unknown as Array<IHotel>
	if (hotel_groups && hotel_groups.length)
		filters.hotel_groups = hotel_groups.map((t) => {
			const slices = t.split("_")
			return { id: parseInt(slices[0]), name: slices.slice(1).join("_") }
		}) as unknown as Array<IHotelGroup>
	if (owners && owners.length)
		filters.owners = owners.map((t) => {
			const slices = t.split("_")
			return { id: parseInt(slices[0]), name: slices.slice(1).join("_") }
		}) as unknown as Array<IUser>
	if (page) filters.page = page
	if (sort) filters.sort = sort
	return filters
}

function filtersToRequestParams(filters: IFilters) {
	const {
		booking_tags = [],
		trip_sources = [],
		locations = [],
		page = 1,
		stay_after,
		stay_before,
		hotels = [],
		hotel_groups = [],
		owners = [],
		sort,
		...otherParams
	} = filters
	return {
		booking_tags: booking_tags.map((t) => t.name),
		trip_sources: trip_sources.map((t) => t.id),
		locations: locations.map((t) => t.name),
		hotels: hotels.map((t) => t.id),
		hotel_groups: hotel_groups.map((t) => t.id),
		stay_after: stay_after ? dateToUTCString(startOf(stay_after, "day")) : null,
		stay_before: stay_before
			? dateToUTCString(startOf(stay_before, "day"))
			: null,
		page,
		owners: owners.map((o) => o.id),
		sort,
		...otherParams,
		limit: 10,
	}
}

export function HotelsBookingList() {
	const [queryFilters, setQueryFilters] = useLocationQuery<
		IFilters,
		IFiltersInLocation
	>({
		toQuery: filtersToLocationQuery,
		fromQuery: locationQueryToFilters,
	})
	const [params, setParams] = useSearch<IFilters>(queryFilters)
	const { hasPermission } = useCheckPermissions()
	useEffect(() => {
		setQueryFilters(params)
	}, [params, setQueryFilters])
	const { xs, sm } = useBreakpoints()
	const tripQuoteBookingsDiffCount = useTripQuoteBookingsDiffCount({
		hotels_changed: true,
	})
	return (
		<Fragment>
			<Search
				title="Hotel Bookings"
				placeholder="Search by id, destination..."
				Filters={Filters}
				initialParams={params}
				areAdvancedFiltersApplied={(params) => {
					const { status, ...otherParams } = params
					return areAdvancedFiltersAppliedDefault(otherParams)
				}}
				resetParams={(params) => ({
					q: "",
					status: params.status,
					page: 1,
				})}
				onSearch={(newParams) => {
					setParams({ ...newParams, page: 1 })
				}}
				actions={
					tripQuoteBookingsDiffCount
						? () => {
								return (
									<ButtonLink
										to={generatePath("/trip-quote-bookings-diff")}
										status="warning"
										title="Trip Quote & Hotel Bookings Diff"
									>
										<Inline alignItems="center" as="span" gap="1">
											<Box as="span">
												<Icons.Attention />
											</Box>
											<Text as="span">{tripQuoteBookingsDiffCount}</Text>
										</Inline>
									</ButtonLink>
								)
							}
						: null
				}
			>
				{({ searchParams, setSearchParamValue }) => (
					<Tabs>
						<TabsList>
							<TabItem
								onClick={() => {
									setSearchParamValue("status", "new")
								}}
								active={!searchParams.status || searchParams.status === "new"}
							>
								New
							</TabItem>
							<TabItem
								onClick={() => {
									setSearchParamValue("status", "in_progress")
								}}
								active={searchParams.status === "in_progress"}
							>
								In Progress
							</TabItem>
							<TabItem
								onClick={() => {
									setSearchParamValue("status", "booked")
									setSearchParamValue("sort", undefined)
								}}
								active={searchParams.status === "booked"}
							>
								Booked
							</TabItem>
							<TabItem
								onClick={() => {
									setSearchParamValue("status", "on_trip")
									setSearchParamValue("sort", undefined)
								}}
								active={searchParams.status === "on_trip"}
							>
								On Trip
							</TabItem>
							<TabItem
								onClick={() => {
									setSearchParamValue("status", "past")
									setSearchParamValue("sort", undefined)
								}}
								active={searchParams.status === "past"}
							>
								Past
							</TabItem>
							<TabItem
								onClick={() => {
									setSearchParamValue("status", "dropped")
									setSearchParamValue("sort", undefined)
								}}
								active={searchParams.status === "dropped"}
							>
								Dropped
							</TabItem>
							<TabItem
								onClick={() => {
									setSearchParamValue("status", "all")
									setSearchParamValue("sort", undefined)
								}}
								active={searchParams.status === "all"}
							>
								All
							</TabItem>
						</TabsList>
						<TabContent>
							<ListView<ITripHotelBooking, IFilters>
								pageKey="/hotel-bookings-listing"
								params={params}
								sortOptions={
									searchParams.status === "new" ||
									searchParams.status === "in_progress"
										? sortOptions
										: undefined
								}
								fetch={(xhr, params) => {
									return XHR(xhr).getHotelBookings(
										filtersToRequestParams(params)
									)
								}}
								onPageChange={(page, sort) =>
									setParams({ ...params, page, sort })
								}
							>
								{({ items: trips, refresh }) =>
									!sm && !xs ? (
										<Table className="excel-style-table" responsive>
											<thead>
												<tr>
													<th>Basic Details</th>
													<th>Hotel</th>
													<th>Duration</th>
													<th>Status</th>
													<th>Tag</th>
													<th>Comments</th>
												</tr>
											</thead>
											<tbody>
												{trips.map((trip) => {
													const {
														id: tripId,
														trip_source,
														reference_id,
														destinations,
														no_of_adults,
														children,
														contact,
														days,
														converted_at,
														converted_by,
														on_hold_at,
														on_hold_by,
														total_payments_amount,
														total_payments_amount_currency,
														bookings,
													} = trip
													const start_date = utcTimestampToLocalDate(
														trip.start_date
													)
													const end_date = utcTimestampToLocalDate(
														trip.end_date
													)
													// + 1 for overview row
													const bookingRowsCount = bookings.length + 1
													return (
														<Fragment key={`trip_${tripId}`}>
															<Box as="tr">
																<Box as="td" rowSpan={bookingRowsCount}>
																	<Stack
																		maxWidth="xs"
																		gap="1"
																		style={{ minWidth: "200px" }}
																	>
																		<Heading fontSize="md" as="h3">
																			<Link
																				to={`/trips/${tripId}/services-bookings/hotels`}
																				anchored
																				color="accent"
																			>
																				{contact.name} •{" "}
																				<Box display="inline" as="span">
																					{destinations
																						.map((l) => l.short_name)
																						.join(", ")}
																				</Box>
																			</Link>
																		</Heading>
																		<Box fontSize="sm">
																			{joinAttributes(
																				<Box display="inline">
																					<Box
																						display="inline"
																						marginRight="1"
																						color="muted"
																					>
																						#
																					</Box>
																					{tripId}
																				</Box>,
																				<Box
																					display="inline"
																					title={trip_source.name}
																				>
																					{trip_source.short_name}
																					{reference_id
																						? `-${reference_id}`
																						: ``}
																				</Box>,
																				<Money
																					amount={total_payments_amount}
																					currency={
																						total_payments_amount_currency
																					}
																					showCurrency
																				/>
																			)}
																		</Box>
																		<Box>
																			{joinAttributes(
																				<Time value={start_date} />,
																				<TimeDuration days={days}>
																					{days}D
																				</TimeDuration>,
																				`${no_of_adults}A${
																					children.length
																						? `, ${children.length}C`
																						: ``
																				}`
																			)}
																		</Box>
																		{converted_at && converted_by ? (
																			<Box fontSize="sm" color="muted">
																				<Icons.ChevronDown
																					rotate="270"
																					title="Conversion"
																				/>{" "}
																				<RelativeTime
																					value={utcTimestampToLocalDate(
																						converted_at
																					)}
																				/>{" "}
																				by {converted_by.name}
																			</Box>
																		) : on_hold_at && on_hold_by ? (
																			<Box fontSize="sm" color="muted">
																				<Badge>On-Hold Trip</Badge>{" "}
																				<RelativeTime
																					value={utcTimestampToLocalDate(
																						on_hold_at
																					)}
																				/>{" "}
																				by {on_hold_by.name}
																			</Box>
																		) : (
																			<Box>
																				<Badge>On-Hold Trip</Badge>
																			</Box>
																		)}
																		<Box marginTop="4">
																			<Box
																				display="inlineBlock"
																				marginRight="1"
																			>
																				<Icons.Users />
																			</Box>
																			{trip.reservations_team?.length ? (
																				trip.reservations_team
																					.map((user) => user.name)
																					.join(", ")
																			) : (
																				<Box
																					color="muted"
																					fontSize="sm"
																					display="inline"
																				>
																					Not Set
																				</Box>
																			)}
																			{hasPermission(
																				PERMISSIONS.MANAGE_TRIP_OWNERS
																			) ? (
																				<Component initialState={false}>
																					{({
																						state: isEditing,
																						setState: setIsEditing,
																					}) => (
																						<Box
																							marginLeft="2"
																							display="inlineBlock"
																						>
																							{!isEditing ? (
																								<Button
																									onClick={() => {
																										setIsEditing(true)
																									}}
																									inline
																									title="Edit operation teams"
																								>
																									<Icons.Pencil />
																								</Button>
																							) : null}
																							<Dialog
																								title="Edit Reservations Team"
																								open={isEditing}
																								onClose={() =>
																									setIsEditing(false)
																								}
																								sm
																							>
																								<Dialog.Body>
																									<EditTripOwners
																										type="reservations_team"
																										users={
																											trip.reservations_team
																										}
																										itemId={trip.id}
																										onSuccess={() => {
																											setIsEditing(false)
																											refresh()
																										}}
																										onCancel={() => {
																											setIsEditing(false)
																										}}
																									/>
																								</Dialog.Body>
																							</Dialog>
																						</Box>
																					)}
																				</Component>
																			) : null}
																		</Box>
																	</Stack>
																</Box>
																<Box as="td" colSpan={5} bgColor="subtle">
																	<Box display="flex" justifyContent="between">
																		<Box paddingY="1" fontWeight="semibold">
																			{joinAttributes(
																				<Box as="span">
																					<Box
																						as="span"
																						fontWeight="semibold"
																						fontSize="lg"
																					>
																						{
																							bookings.filter((b) => b.booked)
																								.length
																						}
																					</Box>
																					<Box as="span" marginLeft="1">
																						/ {bookings.length} Booked
																					</Box>
																				</Box>,
																				<Box as="span">
																					<Box
																						as="span"
																						fontWeight="semibold"
																						fontSize="lg"
																					>
																						{
																							bookings.filter(
																								(b) => b.voucher_sent_at
																							).length
																						}
																					</Box>
																					<Box as="span" marginLeft="1">
																						/ {bookings.length} Voucher Sent
																					</Box>
																				</Box>
																			)}
																		</Box>
																	</Box>
																</Box>
															</Box>
															{bookings.map((booking) => {
																const {
																	id,
																	hotel,
																	booking_stage,
																	booked,
																	voucher_sent_at,
																	checkin,
																	no_of_nights,
																	voucher,
																	booked_at,
																	booking_confirmation_id,
																} = booking
																return (
																	<Box as="tr" key={id}>
																		{/* Columns for bookings -- Start --*/}
																		{/* Note: Adding a column should update the header rowSpan */}
																		<Box as="td" verticalAlign="middle">
																			<Box
																				display="flex"
																				alignItems="center"
																				justifyContent="between"
																			>
																				<Box
																					display="flex"
																					alignItems="baseline"
																				>
																					<Box
																						fontWeight="semibold"
																						style={{ maxWidth: "150px" }}
																						title={hotel?.name}
																						textOverflow="truncate"
																					>
																						<HotelBookingItemInDialog
																							hotelBookingId={booking.id}
																						>
																							{({ showDetails }) => (
																								<Button
																									inline
																									onClick={showDetails}
																									level="secondary"
																								>
																									{hotel.name}
																								</Button>
																							)}
																						</HotelBookingItemInDialog>
																					</Box>
																					<Box
																						fontWeight="semibold"
																						style={{ maxWidth: "150px" }}
																						marginLeft="1"
																						fontSize="xs"
																						title={hotel?.location?.name}
																						textOverflow="truncate"
																					>
																						{hotel?.location?.short_name}
																					</Box>
																					{hotel?.stars_string ? (
																						<>
																							,
																							<Box
																								whiteSpace="preserve"
																								marginLeft="1"
																								fontSize="xs"
																								title={`${hotel?.stars_string}`}
																							>
																								{hotel?.stars_string}
																							</Box>
																						</>
																					) : null}
																				</Box>
																				<Box marginLeft="1">
																					<Inline gap="2" alignItems="center">
																						<EditHotelBookingDetailsInDialog
																							tripDestinations={destinations}
																							booking={booking}
																							startDate={start_date}
																							endDate={end_date}
																							onSuccess={() => refresh()}
																							minimal
																						/>
																						<ComposeHotelBookingEmail
																							tripDestinations={destinations}
																							booking={booking}
																							tripStartDate={start_date}
																							tripEndDate={end_date}
																							onChange={() => refresh()}
																							minimal
																						/>
																						{booked ? (
																							<>
																								{!voucher ? (
																									<GenerateVoucher
																										hotel={hotel}
																										booking={booking}
																										isBooked={!!booked_at}
																										bookingConfirmationId={
																											booking_confirmation_id
																										}
																										onGenerate={() => refresh()}
																										minimal
																									>
																										<Box
																											as="span"
																											color="warning"
																										>
																											<Icons.Document title="Create Voucher" />
																										</Box>
																									</GenerateVoucher>
																								) : (
																									<GeneratedVoucherAsset
																										id={voucher.id}
																										fileUrl={voucher.file_url}
																										isGenerating={
																											voucher.is_generating
																										}
																										onChange={() => refresh()}
																										fontSize="sm"
																									/>
																								)}
																								{voucher_sent_at ? (
																									<Inline
																										title={`Voucher sent on ${utcTimestampToLocalDateTimeString(
																											voucher_sent_at
																										)}`}
																										alignItems="center"
																									>
																										<Icons.OkCircleSolid color="success" />
																									</Inline>
																								) : voucher ? (
																									<MarkVoucherAsSent
																										voucherId={voucher.id}
																										onSuccess={() => refresh()}
																										minimal
																									>
																										<Icons.AttentionSolid color="warning" />
																									</MarkVoucherAsSent>
																								) : null}
																							</>
																						) : null}
																					</Inline>
																				</Box>
																			</Box>
																		</Box>
																		<Box
																			as="td"
																			whiteSpace="preserve"
																			verticalAlign="middle"
																		>
																			{joinAttributes(
																				<Time
																					value={utcTimestampToLocalDate(
																						checkin
																					)}
																					format="DD MMM"
																				/>,
																				`${no_of_nights}N`
																			)}
																		</Box>
																		<Box
																			as="td"
																			title={utcTimestampToLocalDateTimeString(
																				booking_stage.created_at
																			)}
																			verticalAlign="middle"
																		>
																			<Box display="flex">
																				<HotelBookingCurrentStage
																					tripDestinations={destinations}
																					booking={booking}
																					tripStartDate={start_date}
																					tripEndDate={end_date}
																					onChangeSuccess={() => refresh()}
																					minimal
																				/>
																			</Box>
																		</Box>
																		<Box
																			as="td"
																			whiteSpace="preserve"
																			verticalAlign="middle"
																		>
																			<Box
																				display="flex"
																				justifyContent="between"
																			>
																				<Box>{booking_stage.tag || null}</Box>
																				<Box>
																					<UpdateBookingTagInDialog
																						booking={booking}
																						onSuccess={() => refresh()}
																					>
																						{({ onEdit }) => (
																							<Button
																								onClick={() => onEdit()}
																								level="tertiary"
																								size="sm"
																							>
																								<Icons.Pencil />
																							</Button>
																						)}
																					</UpdateBookingTagInDialog>
																				</Box>
																			</Box>
																		</Box>
																		<Box
																			as="td"
																			fontSize="sm"
																			style={{ maxWidth: "200px" }}
																			verticalAlign="middle"
																		>
																			{booking_stage.comments ? (
																				<Box
																					textOverflow="truncate"
																					title={booking_stage.comments}
																				>
																					{booking_stage.comments}
																				</Box>
																			) : null}
																		</Box>
																		{/* Columns for bookings -- End --*/}
																	</Box>
																)
															})}
														</Fragment>
													)
												})}
											</tbody>
										</Table>
									) : (
										<Box as="ol">
											{trips.map((trip) => {
												const {
													id: tripId,
													trip_source,
													reference_id: trip_id,
													destinations,
													no_of_adults,
													children,
													contact,
													days,
													total_payments_amount,
													total_payments_amount_currency,
													bookings,
												} = trip
												const start_date = utcTimestampToLocalDate(
													trip.start_date
												)
												const end_date = utcTimestampToLocalDate(trip.end_date)
												return (
													<Box
														as="li"
														key={trip.id}
														marginBottom="8"
														rounded="md"
														borderWidth="1"
														bgColor="default"
														boxShadow="base"
													>
														<Box padding="4" borderBottomWidth="1">
															<Box
																display="flex"
																flexWrap="wrap"
																alignItems="baseline"
															>
																<Box
																	fontSize="lg"
																	as="h3"
																	whiteSpace="preserve"
																	marginBottom="0"
																	fontWeight="semibold"
																	paddingRight="2"
																>
																	<Link
																		to={`/trips/${tripId}/services-bookings/hotels`}
																		anchored
																	>
																		{contact.name} •{" "}
																		<Box display="inline" as="span">
																			{destinations
																				.map((l) => l.short_name)
																				.join(", ")}
																		</Box>
																	</Link>
																</Box>
																<Box fontSize="sm">
																	{joinAttributes(
																		<Box display="inline">
																			<Box
																				display="inline"
																				marginRight="1"
																				color="muted"
																			>
																				#
																			</Box>
																			{tripId}
																		</Box>,
																		<Box
																			display="inline"
																			title={trip_source.name}
																		>
																			{trip_source.short_name}
																			{trip_id ? `-${trip_id}` : ``}
																		</Box>
																	)}
																</Box>
															</Box>
															<Box
																display="flex"
																flexWrap="wrap"
																justifyContent="between"
																alignItems="center"
																fontSize="sm"
															>
																<Box
																	marginY="1"
																	whiteSpace="preserve"
																	paddingRight="2"
																>
																	{joinAttributes(
																		<Money
																			amount={total_payments_amount}
																			currency={total_payments_amount_currency}
																			showCurrency
																		/>,
																		<Time value={start_date} />,
																		<TimeDuration days={days}>
																			{days}D
																		</TimeDuration>,
																		`${no_of_adults}A${
																			children.length
																				? `, ${children.length}C`
																				: ``
																		}`
																	)}
																</Box>
															</Box>
														</Box>
														<Box as="ul">
															<Box
																as="li"
																paddingX="4"
																paddingY="2"
																bgColor="subtle"
																display="flex"
																alignItems="center"
															>
																{joinAttributes(
																	<Box as="span">
																		<Box
																			as="span"
																			fontWeight="semibold"
																			fontSize="lg"
																		>
																			{bookings.filter((b) => b.booked).length}
																		</Box>
																		<Box as="span" marginLeft="1">
																			/ {bookings.length} Booked
																		</Box>
																	</Box>,
																	<Box as="span">
																		<Box
																			as="span"
																			fontWeight="semibold"
																			fontSize="lg"
																		>
																			{
																				bookings.filter(
																					(b) => b.voucher_sent_at
																				).length
																			}
																		</Box>
																		<Box as="span" marginLeft="1">
																			/ {bookings.length} Voucher Sent
																		</Box>
																	</Box>,
																	<Box display="inlineFlex" alignItems="center">
																		<Box marginRight="1">
																			<Icons.Users title="Reservations Team" />
																		</Box>
																		{trip.reservations_team?.length ? (
																			trip.reservations_team
																				.map((user) => user.name)
																				.join(", ")
																		) : (
																			<Box
																				color="muted"
																				fontSize="sm"
																				display="inline"
																			>
																				Not Set
																			</Box>
																		)}
																		{hasPermission(
																			PERMISSIONS.MANAGE_TRIP_OWNERS
																		) ? (
																			<Component initialState={false}>
																				{({
																					state: isEditing,
																					setState: setIsEditing,
																				}) => (
																					<>
																						{!isEditing ? (
																							<Box
																								as="button"
																								fontSize="sm"
																								color="muted"
																								display="inline"
																								onClick={() => {
																									setIsEditing(true)
																								}}
																								marginLeft="2"
																								title="Edit operation teams"
																							>
																								<Icons.Pencil />
																							</Box>
																						) : null}
																						<Dialog
																							title="Edit Operations Team"
																							open={isEditing}
																							onClose={() =>
																								setIsEditing(false)
																							}
																							sm
																						>
																							<Dialog.Body>
																								<EditTripOwners
																									type="reservations_team"
																									users={trip.reservations_team}
																									itemId={trip.id}
																									onSuccess={() => {
																										setIsEditing(false)
																										refresh()
																									}}
																									onCancel={() => {
																										setIsEditing(false)
																									}}
																								/>
																							</Dialog.Body>
																						</Dialog>
																					</>
																				)}
																			</Component>
																		) : null}
																	</Box>
																)}
															</Box>
															{bookings.map((booking) => {
																const {
																	id,
																	hotel,
																	booking_stage,
																	booked,
																	voucher_sent_at,
																	no_of_nights,
																	voucher,
																	booked_at,
																	booking_confirmation_id,
																} = booking
																return (
																	<Box
																		key={id}
																		as="li"
																		borderTopWidth="1"
																		paddingX="4"
																		paddingY="2"
																	>
																		<Box
																			display="flex"
																			alignItems="center"
																			justifyContent="between"
																		>
																			<Box>
																				{joinAttributes(
																					<Box
																						display="inlineFlex"
																						alignItems="baseline"
																					>
																						<Box
																							fontWeight="semibold"
																							style={{ maxWidth: "150px" }}
																							title={hotel?.name}
																							textOverflow="truncate"
																						>
																							{hotel?.name}
																						</Box>
																						<Box
																							fontWeight="semibold"
																							style={{ maxWidth: "150px" }}
																							marginLeft="1"
																							fontSize="xs"
																							title={hotel?.location?.name}
																							textOverflow="truncate"
																						>
																							{hotel?.location?.short_name}
																						</Box>
																						{hotel?.stars_string ? (
																							<>
																								,
																								<Box
																									whiteSpace="preserve"
																									marginLeft="1"
																									fontSize="xs"
																									title={`${hotel?.stars_string} Star`}
																								>
																									{hotel?.stars_string}
																								</Box>
																							</>
																						) : null}
																					</Box>,
																					`${no_of_nights}N`
																				)}
																			</Box>
																			<Box>
																				<Inline gap="4">
																					<ComposeHotelBookingEmail
																						tripDestinations={destinations}
																						booking={booking}
																						tripStartDate={start_date}
																						tripEndDate={end_date}
																						minimal
																						onChange={refresh}
																					/>
																					<EditHotelBookingDetailsInDialog
																						tripDestinations={destinations}
																						booking={booking}
																						startDate={start_date}
																						endDate={end_date}
																						onSuccess={refresh}
																						minimal
																					/>
																					{booked ? (
																						<Box
																							display="inlineFlex"
																							alignItems="center"
																						>
																							{!voucher ? (
																								<GenerateVoucher
																									hotel={hotel}
																									booking={booking}
																									isBooked={!!booked_at}
																									bookingConfirmationId={
																										booking_confirmation_id
																									}
																									onGenerate={refresh}
																									minimal
																								>
																									<Box
																										as="span"
																										color="warning"
																									>
																										<Icons.Document title="Create Voucher" />
																									</Box>
																								</GenerateVoucher>
																							) : (
																								<>
																									<GeneratedVoucherAsset
																										id={voucher.id}
																										fileUrl={voucher.file_url}
																										isGenerating={
																											voucher.is_generating
																										}
																										onChange={refresh}
																										fontSize="sm"
																									/>
																								</>
																							)}
																							{voucher_sent_at ? (
																								<Box
																									title={`Voucher sent on ${utcTimestampToLocalDateTimeString(
																										voucher_sent_at
																									)}`}
																									color="success"
																									marginLeft="1"
																									display="inline"
																								>
																									<Icons.OkCircleSolid />
																								</Box>
																							) : voucher ? (
																								<>
																									<Box marginLeft="1">
																										<MarkVoucherAsSent
																											voucherId={voucher.id}
																											onSuccess={refresh}
																											minimal
																										>
																											<Box
																												as="span"
																												color="warning"
																											>
																												<Icons.AttentionSolid />
																											</Box>
																										</MarkVoucherAsSent>
																									</Box>
																								</>
																							) : null}
																						</Box>
																					) : null}
																				</Inline>
																			</Box>
																		</Box>
																		<Box
																			title={utcTimestampToLocalDateTimeString(
																				booking_stage.created_at
																			)}
																			verticalAlign="middle"
																		>
																			<Box display="flex" fontSize="sm">
																				{joinAttributes(
																					<Box fontWeight="semibold">
																						<HotelBookingCurrentStage
																							tripDestinations={destinations}
																							booking={booking}
																							tripStartDate={start_date}
																							tripEndDate={end_date}
																							onChangeSuccess={refresh}
																							minimal
																						/>
																					</Box>,

																					<Box display="inlineFlex">
																						<Box marginRight="1">
																							{booking_stage.tag || (
																								<Box as="span" color="muted">
																									No Tags
																								</Box>
																							)}
																						</Box>
																						<UpdateBookingTagInDialog
																							booking={booking}
																							onSuccess={() => refresh()}
																						>
																							{({ onEdit }) => (
																								<Button
																									onClick={() => onEdit()}
																									level="tertiary"
																									size="sm"
																								>
																									<Icons.Pencil />
																								</Button>
																							)}
																						</UpdateBookingTagInDialog>
																					</Box>
																				)}
																			</Box>
																		</Box>
																		{booking_stage.comments ? (
																			<Box
																				fontSize="sm"
																				style={{ maxWidth: "100%" }}
																				color="muted"
																			>
																				<Box
																					textOverflow="truncate"
																					title={booking_stage.comments}
																				>
																					{booking_stage.comments}
																				</Box>
																			</Box>
																		) : null}
																	</Box>
																)
															})}
														</Box>
													</Box>
												)
											})}
										</Box>
									)
								}
							</ListView>
						</TabContent>
					</Tabs>
				)}
			</Search>
		</Fragment>
	)
}

export function Filters() {
	return (
		<Stack gap="4">
			<SelectField
				label="Trip Sources"
				select={SelectTripSources}
				name="trip_sources"
				multiple
				fetchOnMount
			/>
			<SelectField
				label="Booking Tags"
				select={SelectHotelBookingStageTags}
				name="booking_tags"
				multiple
				fetchOnMount
			/>
			<SelectField
				label="Team"
				select={SelectUsers}
				name="owners"
				multiple
				fetchOnMount
			/>
			<Stack>
				<label htmlFor="stay_after">Stay During</label>
				<Box display="flex" alignItems="center">
					<DatePickerField name="stay_after" dateFormat="DD MMM, YYYY" />
					<Box>
						<Icons.SwitchHorizontal />
					</Box>
					<DatePickerField
						name="stay_before"
						rightAlign
						dateFormat="DD MMM, YYYY"
					/>
				</Box>
			</Stack>
			<SelectField
				label="Hotels"
				name="hotels"
				select={SelectHotels}
				multiple
			/>
			<SelectField
				label="Hotel Groups"
				name="hotel_groups"
				select={SelectHotelGroup}
				multiple
			/>
		</Stack>
	)
}
