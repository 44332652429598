// Generouted, changes to this file will be overriden
/* eslint-disable */

import { components, hooks, utils } from "@generouted/react-router/client"

export type Path =
	| `/`
	| `/accounting/accounts`
	| `/accounting/accounts/:accountId`
	| `/accounting/accounts/:accountId/edit`
	| `/accounting/accounts/new`
	| `/accounting/bank-statements`
	| `/accounting/suspense-transactions`
	| `/accounting/suspense-transactions/new`
	| `/accounting/transactions`
	| `/accounting/transactions/csv-upload`
	| `/accounting/transactions/new`
	| `/admin/bank-accounts`
	| `/admin/teams`
	| `/admin/tenants`
	| `/admin/tenants/:tenantId`
	| `/admin/tenants/:tenantId/billing`
	| `/admin/tenants/:tenantId/comments`
	| `/admin/tenants/:tenantId/comments/:status`
	| `/admin/tenants/:tenantId/configuration`
	| `/admin/tenants/:tenantId/login-activites`
	| `/admin/tenants/:tenantId/modules`
	| `/admin/tenants/:tenantId/stats`
	| `/admin/tenants/:tenantId/subscription-details`
	| `/admin/tenants/new`
	| `/admin/usage-stats`
	| `/admin/users`
	| `/admin/webhook-client-calls`
	| `/admin/world/airports`
	| `/admin/world/banks`
	| `/admin/world/banks/:bankId`
	| `/admin/world/countries`
	| `/admin/world/countries/:countryId`
	| `/admin/world/states/:stateId`
	| `/admin/world/timezones`
	| `/cab-price-calculation-metric-presets`
	| `/cab-price-calculation-metric-presets/:presetId`
	| `/cab-price-calculation-metric-presets/:presetId/edit`
	| `/cab-price-calculation-metric-presets/new`
	| `/cab-price-calculation-metrics`
	| `/cab-price-calculation-metrics/:metricId`
	| `/cab-price-calculation-metrics/new`
	| `/cab-schedules`
	| `/cab-schedules/:scheduleId`
	| `/cab-schedules/:scheduleId/edit`
	| `/cab-schedules/calendar`
	| `/cab-schedules/date/:date`
	| `/cab-schedules/new`
	| `/cabs`
	| `/cabs/:cabId`
	| `/cabs/new`
	| `/connection-requests/invite`
	| `/connection-requests/new`
	| `/connection-tenant-signup`
	| `/dashboard`
	| `/email-verified`
	| `/follow-ups`
	| `/forgot-password`
	| `/hotel-bookings`
	| `/hotel-groups`
	| `/hotel-groups/:groupId`
	| `/hotel-groups/:groupId/accountings`
	| `/hotel-groups/:groupId/bookings`
	| `/hotel-groups/:groupId/payments`
	| `/hotel-payment-preferences/new`
	| `/hotel-prices`
	| `/hotel-prices/calculate-price`
	| `/hotel-prices/new`
	| `/hotel-prices/upload-prices`
	| `/hotel-vouchers`
	| `/hotel-vouchers/new`
	| `/hotels`
	| `/hotels/:hotelId`
	| `/hotels/:hotelId/accounting`
	| `/hotels/:hotelId/bookings`
	| `/hotels/:hotelId/payments`
	| `/hotels/merge`
	| `/hotels/new`
	| `/hotels/upload`
	| `/inclusion-exclusion-presets/:presetId`
	| `/inclusion-exclusion-presets/:presetId/edit`
	| `/inclusion-exclusion-presets/new`
	| `/integrations/:integrationId`
	| `/integrations/:integrationId/edit`
	| `/integrations/api/new`
	| `/integrations/fb/new`
	| `/integrations/google-ads/new`
	| `/integrations/new`
	| `/invited-signup`
	| `/latest-release-notes`
	| `/locations`
	| `/locations/new`
	| `/login`
	| `/logout`
	| `/meal-plans`
	| `/notifications`
	| `/onboarding`
	| `/onboarding/add-destinations`
	| `/onboarding/done`
	| `/onboarding/invite-users`
	| `/onboarding/upload-logo`
	| `/operational-bookings`
	| `/operational-bookings/calendar`
	| `/operational-bookings/date/:date`
	| `/org`
	| `/org/bank-accounts`
	| `/org/cab-types`
	| `/org/connections`
	| `/org/currencies`
	| `/org/hotel-payment-preferences`
	| `/org/inclusion-exclusion-presets`
	| `/org/integrations`
	| `/org/profile`
	| `/org/profile/:id`
	| `/org/teams`
	| `/org/templates`
	| `/org/templates/quote-email-templates`
	| `/org/templates/quote-pdf-templates`
	| `/org/terms-and-conditions`
	| `/org/tourists`
	| `/org/trip-destinations`
	| `/org/trip-sources`
	| `/org/users`
	| `/payments/incoming`
	| `/payments/instalments/:instalmentId`
	| `/payments/outgoing`
	| `/quote-requests`
	| `/register`
	| `/reset-password`
	| `/roles`
	| `/roles/:roleId`
	| `/roles/:roleId/edit`
	| `/roles/:roleId/edit-permissions`
	| `/roles/new`
	| `/room-types`
	| `/room-types/new`
	| `/search`
	| `/settings`
	| `/settings/apperance`
	| `/settings/security`
	| `/settings/security-logs`
	| `/teams/:teamId`
	| `/teams/:teamId/edit`
	| `/teams/new`
	| `/tenant-signup`
	| `/terms-and-conditions/:tncId`
	| `/terms-and-conditions/:tncId/edit`
	| `/terms-and-conditions/new`
	| `/transport-service-prices`
	| `/transport-service-prices/calculate-price`
	| `/transport-service-prices/new`
	| `/transport-service-prices/upload-prices`
	| `/transport-service-providers`
	| `/transport-service-providers/:transportServiceProviderId`
	| `/transport-service-providers/:transportServiceProviderId/accounting`
	| `/transport-service-providers/:transportServiceProviderId/bookings`
	| `/transport-service-providers/:transportServiceProviderId/payments`
	| `/transport-service-providers/:transportServiceProviderId/travel-activity-bookings`
	| `/transport-service-providers/new`
	| `/transport-services`
	| `/transport-services/:serviceLocationId`
	| `/transport-services/new`
	| `/travel-activities`
	| `/travel-activities/:travelActivityId`
	| `/travel-activities/:travelActivityId/edit`
	| `/travel-activities/new`
	| `/travel-activity-prices`
	| `/travel-activity-prices/calculate-price`
	| `/travel-activity-prices/upload-prices`
	| `/trip-checkout-reports`
	| `/trip-destinations/:tripDestinationId`
	| `/trip-destinations/new`
	| `/trip-plan-requests`
	| `/trip-quote-bookings-diff`
	| `/trip-sales-reports`
	| `/trip-sources/:tripSourceId`
	| `/trip-sources/merge`
	| `/trip-sources/new`
	| `/trips`
	| `/trips/:tripId`
	| `/trips/:tripId/accounting`
	| `/trips/:tripId/accounting/invoices`
	| `/trips/:tripId/accounting/payments`
	| `/trips/:tripId/accounting/transactions`
	| `/trips/:tripId/accounting/transactions/new`
	| `/trips/:tripId/activities`
	| `/trips/:tripId/cancel`
	| `/trips/:tripId/comments`
	| `/trips/:tripId/comments/:status`
	| `/trips/:tripId/convert`
	| `/trips/:tripId/docs`
	| `/trips/:tripId/docs/hotels-voucher`
	| `/trips/:tripId/docs/trip-holding-email`
	| `/trips/:tripId/docs/trip-voucher`
	| `/trips/:tripId/drop`
	| `/trips/:tripId/edit`
	| `/trips/:tripId/new-quote`
	| `/trips/:tripId/quote-requests/new`
	| `/trips/:tripId/quotes`
	| `/trips/:tripId/quotes/:quoteId`
	| `/trips/:tripId/quotes/:quoteId/edit-itinerary`
	| `/trips/:tripId/select-quote`
	| `/trips/:tripId/services-bookings`
	| `/trips/:tripId/services-bookings/:serviceType`
	| `/trips/:tripId/services-bookings/operational/new`
	| `/trips/new`
	| `/trips/upload`
	| `/users/:userId`
	| `/users/:userId/edit`
	| `/users/:userId/edit-roles`
	| `/users/new`

export type Params = {
	"/accounting/accounts/:accountId": { accountId: string }
	"/accounting/accounts/:accountId/edit": { accountId: string }
	"/admin/tenants/:tenantId": { tenantId: string }
	"/admin/tenants/:tenantId/billing": { tenantId: string }
	"/admin/tenants/:tenantId/comments": { tenantId: string }
	"/admin/tenants/:tenantId/comments/:status": {
		tenantId: string
		status: string
	}
	"/admin/tenants/:tenantId/configuration": { tenantId: string }
	"/admin/tenants/:tenantId/login-activites": { tenantId: string }
	"/admin/tenants/:tenantId/modules": { tenantId: string }
	"/admin/tenants/:tenantId/stats": { tenantId: string }
	"/admin/tenants/:tenantId/subscription-details": { tenantId: string }
	"/admin/world/banks/:bankId": { bankId: string }
	"/admin/world/countries/:countryId": { countryId: string }
	"/admin/world/states/:stateId": { stateId: string }
	"/cab-price-calculation-metric-presets/:presetId": { presetId: string }
	"/cab-price-calculation-metric-presets/:presetId/edit": { presetId: string }
	"/cab-price-calculation-metrics/:metricId": { metricId: string }
	"/cab-schedules/:scheduleId": { scheduleId: string }
	"/cab-schedules/:scheduleId/edit": { scheduleId: string }
	"/cab-schedules/date/:date": { date: string }
	"/cabs/:cabId": { cabId: string }
	"/hotel-groups/:groupId": { groupId: string }
	"/hotel-groups/:groupId/accountings": { groupId: string }
	"/hotel-groups/:groupId/bookings": { groupId: string }
	"/hotel-groups/:groupId/payments": { groupId: string }
	"/hotels/:hotelId": { hotelId: string }
	"/hotels/:hotelId/accounting": { hotelId: string }
	"/hotels/:hotelId/bookings": { hotelId: string }
	"/hotels/:hotelId/payments": { hotelId: string }
	"/inclusion-exclusion-presets/:presetId": { presetId: string }
	"/inclusion-exclusion-presets/:presetId/edit": { presetId: string }
	"/integrations/:integrationId": { integrationId: string }
	"/integrations/:integrationId/edit": { integrationId: string }
	"/operational-bookings/date/:date": { date: string }
	"/org/profile/:id": { id: string }
	"/payments/instalments/:instalmentId": { instalmentId: string }
	"/roles/:roleId": { roleId: string }
	"/roles/:roleId/edit": { roleId: string }
	"/roles/:roleId/edit-permissions": { roleId: string }
	"/teams/:teamId": { teamId: string }
	"/teams/:teamId/edit": { teamId: string }
	"/terms-and-conditions/:tncId": { tncId: string }
	"/terms-and-conditions/:tncId/edit": { tncId: string }
	"/transport-service-providers/:transportServiceProviderId": {
		transportServiceProviderId: string
	}
	"/transport-service-providers/:transportServiceProviderId/accounting": {
		transportServiceProviderId: string
	}
	"/transport-service-providers/:transportServiceProviderId/bookings": {
		transportServiceProviderId: string
	}
	"/transport-service-providers/:transportServiceProviderId/payments": {
		transportServiceProviderId: string
	}
	"/transport-service-providers/:transportServiceProviderId/travel-activity-bookings": {
		transportServiceProviderId: string
	}
	"/transport-services/:serviceLocationId": { serviceLocationId: string }
	"/travel-activities/:travelActivityId": { travelActivityId: string }
	"/travel-activities/:travelActivityId/edit": { travelActivityId: string }
	"/trip-destinations/:tripDestinationId": { tripDestinationId: string }
	"/trip-sources/:tripSourceId": { tripSourceId: string }
	"/trips/:tripId": { tripId: string }
	"/trips/:tripId/accounting": { tripId: string }
	"/trips/:tripId/accounting/invoices": { tripId: string }
	"/trips/:tripId/accounting/payments": { tripId: string }
	"/trips/:tripId/accounting/transactions": { tripId: string }
	"/trips/:tripId/accounting/transactions/new": { tripId: string }
	"/trips/:tripId/activities": { tripId: string }
	"/trips/:tripId/cancel": { tripId: string }
	"/trips/:tripId/comments": { tripId: string }
	"/trips/:tripId/comments/:status": { tripId: string; status: string }
	"/trips/:tripId/convert": { tripId: string }
	"/trips/:tripId/docs": { tripId: string }
	"/trips/:tripId/docs/hotels-voucher": { tripId: string }
	"/trips/:tripId/docs/trip-holding-email": { tripId: string }
	"/trips/:tripId/docs/trip-voucher": { tripId: string }
	"/trips/:tripId/drop": { tripId: string }
	"/trips/:tripId/edit": { tripId: string }
	"/trips/:tripId/new-quote": { tripId: string }
	"/trips/:tripId/quote-requests/new": { tripId: string }
	"/trips/:tripId/quotes": { tripId: string }
	"/trips/:tripId/quotes/:quoteId": { tripId: string; quoteId: string }
	"/trips/:tripId/quotes/:quoteId/edit-itinerary": {
		tripId: string
		quoteId: string
	}
	"/trips/:tripId/select-quote": { tripId: string }
	"/trips/:tripId/services-bookings": { tripId: string }
	"/trips/:tripId/services-bookings/:serviceType": {
		tripId: string
		serviceType: string
	}
	"/trips/:tripId/services-bookings/operational/new": { tripId: string }
	"/users/:userId": { userId: string }
	"/users/:userId/edit": { userId: string }
	"/users/:userId/edit-roles": { userId: string }
}

export type ModalPath = never

export const { Link, Navigate } = components<Path, Params>()
export const { useModals, useNavigate, useParams } = hooks<
	Path,
	Params,
	ModalPath
>()
export const { redirect } = utils<Path, Params>()
