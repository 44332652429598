import {
	Badge,
	Box,
	Button,
	Icons,
	CheckboxInput,
	useId,
	Inline,
	Stack,
	Heading,
	Text,
	joinAttributes,
	Spinner,
	TabContent,
	TabItem,
	Tabs,
	TabsList,
	ShadowRoot,
} from "@sembark-travel/ui/base"
import { useDialog, Dialog } from "@sembark-travel/ui/dialog"
import {
	CopyToClipboardButton,
	CopyToClipboard,
} from "@sembark-travel/ui/copy-to-clipboard"
import { ShareViaWhatsApp } from "@sembark-travel/ui/share"
import {
	utcTimestampToLocalDate,
	utcTimestampToLocalDateString,
	getDiff,
	dateToUTCString,
	startOf,
	endOf,
} from "@sembark-travel/datetime-utils"
import {
	numberToLocalString,
	withOrdinalSuffix,
} from "@sembark-travel/number-utils"
import { useXHR } from "@sembark-travel/xhr"
import {
	arrayMutators,
	CheckboxInputField,
	FieldArray,
	Form,
	GetFieldValue,
} from "@sembark-travel/ui/form"
import pluralize from "pluralize"
import React, {
	Fragment,
	useCallback,
	useEffect,
	useMemo,
	useReducer,
	useState,
} from "react"
import useSWR from "swr"
import { $PropertyType, Required } from "utility-types"
import { ICab } from "../Cabs"
import { ICabType } from "../CabTypes"
import { TDriver } from "../Drivers"
import type { ITransportServiceProvider } from "../TransportServiceProviders"
import { collect } from "../utils"
import type {
	ICabSchedule,
	IScheduledCab,
	ITripCabSchedule,
	TCabScheduleWithMeta,
} from "./store"
import type { TArrivalDepartureDetail } from "../Trips"
import { Markdown } from "@sembark-travel/ui/markdown"

type TSharableCabSchedule = Omit<ICabSchedule, "trip">

const EMPTY_SCHEDULES: Array<TSharableCabSchedule> = []

const TrackingShareContentType = "cab_schedules"

type ShareWith = Array<
	"guest" | "transportServiceProvider" | "driver" | "booking"
>

const DEFAULT_SHARE_WITH: ShareWith = [
	"guest",
	"transportServiceProvider",
	"driver",
	"booking",
]

export function ShareSchedules({
	tripId,
	children,
	schedules = EMPTY_SCHEDULES,
	canShareWith = DEFAULT_SHARE_WITH,
}: {
	tripId?: number
	onSuccess?: () => void
	children: (props: { share: () => void }) => React.ReactNode
	schedules?: Array<TSharableCabSchedule>
	canShareWith?: ShareWith
}) {
	const [isOpen, open, close] = useDialog()
	const [{ selectedSchedules, selectedTripId }, dispatch] = useReducer<
		React.Reducer<
			{
				selectedSchedules: Array<TSharableCabSchedule>
				selectedTripId?: number
			},
			{
				selectedSchedules?: Array<TSharableCabSchedule>
				selectedTripId?: number
			}
		>
	>((state, action) => ({ ...state, ...action }), {
		selectedSchedules: schedules,
		selectedTripId: undefined,
	})
	const onCancel = useCallback(() => {
		close()
		if (tripId) {
			dispatch({ selectedSchedules: [] })
		} else {
			dispatch({ selectedSchedules: schedules, selectedTripId: undefined })
		}
	}, [close, tripId, schedules])
	return (
		<>
			{children({
				share: () => {
					open()
				},
			})}
			{isOpen ? (
				<Dialog open={isOpen} onClose={onCancel} lg>
					<Dialog.Header>
						<Box display={{ sm: "flex" }} justifyContent="between">
							<Dialog.Title>Share Schedule</Dialog.Title>
							{!selectedTripId && selectedSchedules.length === 1 ? (
								<Box marginRight="8" marginTop={{ xs: "2", sm: "0" }}>
									<Button
										level="primary"
										onClick={() => {
											dispatch({
												selectedTripId: selectedSchedules[0].trip_id,
												selectedSchedules: [],
											})
										}}
									>
										Share in Bulk
									</Button>
								</Box>
							) : null}
						</Box>
					</Dialog.Header>
					{selectedSchedules.length ? (
						<Box>
							<ShareSelectedCabSchedules
								schedules={selectedSchedules}
								canShareWith={canShareWith}
							/>
						</Box>
					) : tripId || selectedTripId ? (
						<Dialog.Body>
							<SelectTripCabSchedules
								tripId={(tripId || selectedTripId) as number}
								onCancel={onCancel}
								onSelect={(schedules) =>
									dispatch({
										selectedSchedules: schedules,
										selectedTripId: undefined,
									})
								}
							/>
						</Dialog.Body>
					) : (
						<Box>Please select a trip or a schedule to share</Box>
					)}
				</Dialog>
			) : null}
		</>
	)
}

type TInclusionOptions = {
	prices: boolean
	itinerary: boolean
	arrivalDepartures: boolean
}

function ShareSelectedCabSchedules({
	schedules,
	canShareWith = DEFAULT_SHARE_WITH,
}: {
	schedules: Array<TSharableCabSchedule>
	canShareWith?: ShareWith
}) {
	const xhr = useXHR()
	const id = useId()
	const [shareWithTab, setShareWithTab] = useState<ShareWith[number]>(
		canShareWith[0]
	)
	const [shareVia, setShareVia] = useState<"whatsapp" | "email">("whatsapp")
	const [shareWithDriver, setShareWithDriver] = useState<TDriver | undefined>()
	const [shareWithTrip, setShareWithTrip] = useState<
		ITripCabSchedule | undefined
	>()
	const [
		{
			prices: includePrices,
			itinerary: includeItinerary,
			arrivalDepartures: includeArrivalDepartures,
		},
		toggleInclusions,
	] = useReducer(
		(state: TInclusionOptions, actions: Partial<TInclusionOptions>) => ({
			...state,
			...actions,
		}),
		{
			prices: true,
			itinerary: false,
			arrivalDepartures: true,
		}
	)
	const [
		shareWithTransportServiceProvider,
		setShareWithTransportServiceProvider,
	] = useState<ITransportServiceProvider | undefined>()
	const { data } = useSWR<Array<TCabScheduleWithMeta>>(
		`/api/cab-schedules/${schedules.map((s) => s.id).join("-")}?${id}`,
		() =>
			Promise.all(
				schedules.map((s) =>
					xhr.get(`/cab-schedules/${s.id}`, {
						params: {
							start_date: dateToUTCString(
								startOf(utcTimestampToLocalDate(s.start_date), "day")
							),
							end_date: dateToUTCString(
								endOf(utcTimestampToLocalDate(s.end_date), "day")
							),
						},
					})
				)
			).then((schedules) => {
				return schedules.map((s) => s.data)
			})
	)
	// filter out the schedules that the user can modify
	const modifiableSchedules = useMemo(
		() => (!data ? [] : data.filter((s) => s.data.can_modify)),
		[data]
	)
	const whatsappTextForBooking = useMemo(
		() =>
			getWhatsAppMessageContentForBooking(modifiableSchedules, {
				includePrices,
				includeItinerary,
			}),
		[modifiableSchedules, includePrices, includeItinerary]
	)
	const whatsappTextForGuestPerTrip = useMemo(
		() =>
			!data
				? {}
				: getWhatsAppMessageContentForGuestPerTrip(data, { includeItinerary }),
		[data, includeItinerary]
	)
	const whatsappTextPerDriver: { [driverId: string]: string } = useMemo(
		() =>
			getWhatsAppMessageContentForDrivers(modifiableSchedules, {
				itinerary: includeItinerary,
				arrivalDepartures: includeArrivalDepartures,
			}),
		[modifiableSchedules, includeItinerary, includeArrivalDepartures]
	)
	const whatsappTextPerTransportServiceProvider: {
		[tspId: string]: string
	} = useMemo(
		() =>
			getWhatsAppMessageContentForTransportServiceProviders(
				modifiableSchedules,
				{
					prices: includePrices,
					itinerary: includeItinerary,
					arrivalDepartures: includeArrivalDepartures,
				}
			),
		[
			modifiableSchedules,
			includePrices,
			includeItinerary,
			includeArrivalDepartures,
		]
	)
	const trips = useMemo(() => {
		const trips: Array<ITripCabSchedule> = []
		if (!data) return trips
		data.forEach(function (schedule) {
			const { trip } = schedule.data
			if (trip?.tourist && trips.findIndex((d) => d.id === trip.id) === -1) {
				trips.push(trip)
			}
		})
		return trips
	}, [data])
	const drivers = useMemo(() => {
		const drivers: Array<TDriver> = []
		modifiableSchedules.forEach(function (schedule) {
			schedule.data.cabs.forEach((cab) => {
				const { driver } = cab
				if (driver && drivers.findIndex((d) => d.id === driver.id) === -1) {
					drivers.push(driver)
				}
			})
		})
		return drivers
	}, [modifiableSchedules])
	const transportServiceProviders = useMemo(() => {
		const transportServiceProviders: Array<ITransportServiceProvider> = []
		modifiableSchedules.forEach(function (schedule) {
			schedule.data.cabs.forEach((cab) => {
				const { transport_service_provider } = cab
				if (
					transport_service_provider &&
					transportServiceProviders.findIndex(
						(d) => d.id === transport_service_provider.id
					) === -1
				) {
					transportServiceProviders.push(transport_service_provider)
				}
			})
		})
		return transportServiceProviders
	}, [modifiableSchedules])
	const bookedSchedules = useMemo(() => {
		if (!data) return []
		return data.filter((data) => data.data.cabs_booked)
	}, [data])
	useEffect(() => {
		// set the first booked trip when sharing with guest and
		// trip is not already set
		if (
			data &&
			trips.length !== 0 &&
			shareWithTab === "guest" &&
			shareWithTrip === undefined
		) {
			setShareWithTrip(trips[0])
		}
	}, [trips, data, shareWithTab, shareWithTrip])
	useEffect(() => {
		// set the first tsp when sharing with transportServiceProvider and
		// tsp is not already set
		if (
			data &&
			transportServiceProviders.length !== 0 &&
			shareWithTab === "transportServiceProvider" &&
			shareWithTransportServiceProvider === undefined
		) {
			setShareWithTransportServiceProvider(transportServiceProviders[0])
		}
	}, [
		transportServiceProviders,
		data,
		shareWithTab,
		shareWithTransportServiceProvider,
	])
	useEffect(() => {
		// set the first driver when sharing with driver and
		// driver is not already set
		if (
			data &&
			drivers.length !== 0 &&
			shareWithTab === "driver" &&
			shareWithDriver === undefined
		) {
			setShareWithDriver(drivers[0])
		}
	}, [drivers, data, shareWithTab, shareWithDriver])
	useEffect(() => {
		// default active is `Share with Guest`
		// Set the active tab to `booking` if no schedules are booked
		if (
			data &&
			bookedSchedules.length === 0 &&
			shareWithTab === "guest" &&
			modifiableSchedules.length > 0
		) {
			setShareWithTab("booking")
			setShareVia("whatsapp")
		}
	}, [bookedSchedules, data, shareWithTab, modifiableSchedules])
	if (!data) {
		return (
			<Box>
				<Spinner alignCenter padding="4" />
			</Box>
		)
	}
	if (bookedSchedules.length === 0 && modifiableSchedules.length === 0) {
		return (
			<Box padding="8" textAlign="center">
				<Box fontWeight="semibold" marginBottom="4">
					No Schedules to Share
				</Box>
				<Box as="p" color="muted">
					Selected schedule(s) have not been booked, hence can not be shared
					with guest.
				</Box>
			</Box>
		)
	}
	return (
		<Tabs layout="col" gap="0" position="relative">
			<TabsList>
				{bookedSchedules.length && canShareWith.indexOf("guest") !== -1 ? (
					<TabItem
						active={shareWithTab === "guest"}
						onClick={() => {
							setShareWithTab("guest")
							setShareWithTrip(trips[0])
							setShareVia("whatsapp")
						}}
					>
						{pluralize("Guests", trips.length)}
					</TabItem>
				) : null}
				{transportServiceProviders.length > 0 &&
				canShareWith.indexOf("transportServiceProvider") !== -1 ? (
					<TabItem
						active={shareWithTab === "transportServiceProvider"}
						onClick={() => {
							setShareWithTab("transportServiceProvider")
							setShareWithTransportServiceProvider(transportServiceProviders[0])
							setShareVia("whatsapp")
						}}
					>
						{pluralize("Service Provider", transportServiceProviders.length)}
					</TabItem>
				) : null}
				{drivers.length > 0 && canShareWith.indexOf("driver") !== -1 ? (
					<TabItem
						active={shareWithTab === "driver"}
						onClick={() => {
							setShareWithTab("driver")
							setShareWithDriver(drivers[0])
							setShareVia("whatsapp")
						}}
					>
						{pluralize("Driver", drivers.length)}
					</TabItem>
				) : null}
				{modifiableSchedules.length &&
				canShareWith.indexOf("booking") !== -1 ? (
					<TabItem
						active={shareWithTab === "booking"}
						onClick={() => {
							setShareWithTab("booking")
							setShareVia("whatsapp")
						}}
					>
						Booking Enquiry
					</TabItem>
				) : null}
			</TabsList>
			<Box
				padding="4"
				bgColor="subtle"
				position="sticky"
				top="0"
				zIndex="10"
				borderBottomWidth="1"
				boxShadow="sm"
			>
				<Inline gap="4">
					{shareWithTab !== "guest" && shareWithTab !== "driver" ? (
						<Inline as="label" gap="2" alignItems="center">
							<CheckboxInput
								checked={includePrices}
								onChange={() => toggleInclusions({ prices: !includePrices })}
							/>
							<Text>Price</Text>
						</Inline>
					) : null}
					<Inline as="label" gap="2" alignItems="center">
						<CheckboxInput
							checked={includeItinerary}
							onChange={() =>
								toggleInclusions({ itinerary: !includeItinerary })
							}
						/>
						<Text>Itinerary</Text>
					</Inline>
				</Inline>
			</Box>
			<TabContent active={shareWithTab === "booking"} key="booking_whatsapp">
				<Box paddingX="4" paddingY="2">
					<Text color="muted">
						Please share it with your respective{" "}
						<b>transport service provider</b>.
					</Text>
				</Box>
				<Box roundedBottom="md" overflow="hidden">
					<Tabs layout="col" gap="0">
						<TabsList>
							<TabItem
								onClick={() => setShareVia("whatsapp")}
								active={shareVia === "whatsapp"}
							>
								WhatsApp
							</TabItem>
							<TabItem
								onClick={() => setShareVia("email")}
								active={shareVia === "email"}
							>
								Email
							</TabItem>
						</TabsList>
						<TabContent active={shareVia === "whatsapp"}>
							<ShareViaWhatsApp
								text={whatsappTextForBooking}
								trackingContentType={TrackingShareContentType}
								trackingContentId="booking_enquiry"
							/>
						</TabContent>
						<TabContent active={shareVia === "email"}>
							<EmailShare shareWith="booking_enquiry">
								<EmailContentForBooking
									schedules={data}
									includePrices={includePrices}
									includeItinerary={includeItinerary}
								/>
							</EmailShare>
						</TabContent>
					</Tabs>
				</Box>
			</TabContent>
			{trips.length > 1 && shareWithTab === "guest" ? (
				<Stack>
					<Box paddingX="4" paddingY="2">
						<Text>Please select the guest you want to share details with:</Text>
					</Box>
					<Tabs layout="col">
						<TabsList>
							{trips.map((trip) => (
								<TabItem
									key={trip.id}
									active={shareWithTrip?.id === trip.id}
									onClick={() => {
										setShareWithTrip(trip)
									}}
								>
									{trip.contact.name}
								</TabItem>
							))}
						</TabsList>
					</Tabs>
				</Stack>
			) : null}
			{transportServiceProviders.length > 1 &&
			shareWithTab === "transportServiceProvider" ? (
				<Stack>
					<Box paddingX="4" paddingY="2">
						<Text>
							Please select the service provider you want to share details with:
						</Text>
					</Box>
					<Tabs layout="col">
						<TabsList>
							{transportServiceProviders.map((d) => (
								<TabItem
									key={d.id}
									active={shareWithTransportServiceProvider?.id === d.id}
									onClick={() => {
										setShareWithTransportServiceProvider(d)
									}}
								>
									{d.name}
								</TabItem>
							))}
						</TabsList>
					</Tabs>
				</Stack>
			) : null}
			{drivers.length > 1 && shareWithTab === "driver" ? (
				<Stack>
					<Box paddingX="4" paddingY="2">
						<Text>
							Please select the driver you want to share details with:
						</Text>
					</Box>
					<Tabs layout="col">
						<TabsList>
							{drivers.map((d) => (
								<TabItem
									key={d.id}
									active={shareWithDriver?.id === d.id}
									onClick={() => {
										setShareWithDriver(d)
									}}
								>
									{d.name}
								</TabItem>
							))}
						</TabsList>
					</Tabs>
				</Stack>
			) : null}
			{trips.map((trip) => (
				<TabContent
					active={
						shareWithTab === "guest" &&
						shareVia === "whatsapp" &&
						shareWithTrip?.id === trip.id
					}
					key={`guest_${trip.id}_whatsapp`}
				>
					<ShareViaWhatsApp
						text={whatsappTextForGuestPerTrip[trip.id]}
						phoneNumber={trip.contact.phone_numbers}
						trackingContentType={TrackingShareContentType}
						trackingContentId="guest"
					/>
				</TabContent>
			))}
			<TabContent
				active={shareWithTab === "transportServiceProvider"}
				key={`tsp_whatsapp`}
			>
				<Tabs layout="col">
					<TabsList>
						<TabItem
							onClick={() => setShareVia("whatsapp")}
							active={shareVia === "whatsapp"}
						>
							WhatsApp
						</TabItem>
						<TabItem
							onClick={() => setShareVia("email")}
							active={shareVia === "email"}
						>
							Email
						</TabItem>
					</TabsList>
					<TabContent active={shareVia === "whatsapp"}>
						{transportServiceProviders.map((tsp) => (
							<TabContent
								active={shareWithTransportServiceProvider?.id === tsp.id}
								key={`tsp_${tsp.id}_whatsapp`}
							>
								<ShareViaWhatsApp
									text={whatsappTextPerTransportServiceProvider[tsp.id]}
									phoneNumber={
										tsp.contacts?.length
											? tsp.contacts[0].phone_numbers
											: undefined
									}
									trackingContentType={TrackingShareContentType}
									trackingContentId="transport_service_provider"
								/>
							</TabContent>
						))}
					</TabContent>
					<TabContent active={shareVia === "email"}>
						<EmailShare
							shareWith="transport_service_provider"
							subject={
								shareWithTransportServiceProvider ? (
									<Text>
										Trip ID
										{": "}
										{collect(data.map((d) => d.data.trip_id))
											.unique()
											.toArray()
											.join("_")}{" "}
										:: Transport Service Details to{" "}
										{shareWithTransportServiceProvider.name}
									</Text>
								) : undefined
							}
						>
							<EmailContentForTransportServiceProviders
								schedules={data}
								selectedTsp={shareWithTransportServiceProvider}
								includePrices={includePrices}
								includeItinerary={includeItinerary}
							/>
						</EmailShare>
					</TabContent>
				</Tabs>
			</TabContent>
			{drivers.map((driver) => (
				<TabContent
					active={
						shareWithTab === "driver" &&
						shareVia === "whatsapp" &&
						shareWithDriver?.id === driver.id
					}
					key={`driver_${driver.id}_whatsapp`}
				>
					<ShareViaWhatsApp
						text={whatsappTextPerDriver[driver.id]}
						phoneNumber={driver.phone_number}
						trackingContentType={TrackingShareContentType}
						trackingContentId="driver"
					/>
				</TabContent>
			))}
		</Tabs>
	)
}

function getWhatsAppMessageContentForBooking(
	schedules: Array<TCabScheduleWithMeta>,
	{
		includePrices,
		includeItinerary,
	}: { includePrices: boolean; includeItinerary: boolean }
) {
	if (!schedules.length)
		return `There are not schedules you can share via bookings.`
	const groupedByTrip = collect(schedules)
		.groupBy((s) => s.data.trip_id)
		.toArray()
	let totalPrice = 0
	let currency = "INR"
	return `Hi,
We have a new booking request with following details:
${groupedByTrip
	.map((schedules) => {
		const schedule = schedules[0]
		const { trip } = schedule.data
		const hasSameCabTypeForAll =
			collect(
				schedules.map((s) => {
					const { cabs } = s.data
					return cabs
						.map((c) => c.cab_type.id)
						.sort((a, b) => (a > b ? 1 : 0))
						.join("-")
				})
			)
				.unique()
				.count() === 1
		let cabTypeWithCount: Array<{ count: number; name: string }> = []
		if (hasSameCabTypeForAll) {
			const cabsCountByName = schedule.data.cabs.reduce<{
				[cab: string]: number
			}>((cabTypeWithCount, cab) => {
				if (!cabTypeWithCount[cab.cab_type.name]) {
					cabTypeWithCount[cab.cab_type.name] = 0
				}
				cabTypeWithCount[cab.cab_type.name] += 1
				return cabTypeWithCount
			}, {})
			cabTypeWithCount = Object.keys(cabsCountByName).map((name) => ({
				name,
				count: cabsCountByName[name],
			}))
		}
		if (!trip) return ""
		return `\`\`\`-----------\`\`\`
\`\`\`Trip Id   :\`\`\` ${trip.id}
\`\`\`Pax       :\`\`\` *${pluralize("Adult", trip.no_of_adults, true)}${
			trip.children.length
				? ` with ${pluralize(
						"Child",
						trip.children.length,
						true
					)}(${trip.children.map((c) => `${c.age}y`).join(",")})`
				: ``
		}*${
			hasSameCabTypeForAll
				? `\n\`\`\`Cab${
						cabTypeWithCount.length > 1 ? "s" : " "
					}      :\`\`\` ${cabTypeWithCount
						.map(({ count, name }) => `*${count}-${name}*`)
						.join(" + ")}`
				: ``
		}
\`\`\`-----------\`\`\`
${schedules
	.map((schedule) => {
		const { cabs, start_date, transport_service } = schedule.data
		const { itinerary } = schedule.meta
		if (!hasSameCabTypeForAll) {
			const cabsCountByName = cabs.reduce<{ [cab: string]: number }>(
				(cabTypeWithCount, cab) => {
					if (!cabTypeWithCount[cab.cab_type.name]) {
						cabTypeWithCount[cab.cab_type.name] = 0
					}
					cabTypeWithCount[cab.cab_type.name] += 1
					return cabTypeWithCount
				},
				{}
			)
			cabTypeWithCount = Object.keys(cabsCountByName).map((name) => ({
				name,
				count: cabsCountByName[name],
			}))
		}
		const price = getBookingPriceForCabs(cabs)
		totalPrice += price
		currency = cabs.at(0)?.currency || currency
		return `*${utcTimestampToLocalDateString(start_date, "dddd DD MMMM")}*
\`\`\`-\`\`\` *${transport_service.from_to}*
\`\`\` |\`\`\` ${transport_service.service}${
			includeItinerary && itinerary?.description
				? `
${itinerary.description}`
				: ``
		}
${
	!hasSameCabTypeForAll
		? `\`\`\` :\`\`\` ${cabTypeWithCount
				.map(({ name, count }) => `*${count}-${name}*`)
				.join(" + ")}${
				includePrices && price ? ` (${numberToLocalString(price)} /-)` : ``
			}`
		: includePrices && price
			? `\`\`\` :\`\`\` ${numberToLocalString(price)} /-`
			: ``
}`
	})
	.join("\n\n")}`
	})
	.join("\n\n")}${
		includePrices && totalPrice
			? `

*Total Price (${currency}): ${numberToLocalString(totalPrice)} /-*`
			: ``
	}

*Please confirm this booking.*
`
}

function EmailContentForBooking({
	schedules,
	includePrices,
	includeItinerary,
}: {
	schedules: Array<TCabScheduleWithMeta>
	includePrices: boolean
	includeItinerary: boolean
}) {
	const groupedByTrip = collect(schedules)
		.groupBy((s) => s.data.trip_id)
		.toArray()
	let totalPrice = 0
	let currency = "INR"
	const tenantBrand = schedules[0]?.data?.trip?.tenant_brand
	return (
		<>
			<p>Hi,</p>
			{!tenantBrand ? null : (
				<>
					<br />
					<p>
						Greetings from{" "}
						<b>
							{tenantBrand.short_name}
							{tenantBrand.name
								.toLowerCase()
								.indexOf(tenantBrand.short_name.toLowerCase()) === -1
								? ` (${tenantBrand.name})`
								: null}
						</b>
						.
					</p>
				</>
			)}
			<br />
			<p>We have a new booking request with following details:</p>
			<br />
			<table>
				{groupedByTrip.map((schedules, index) => {
					const schedule = schedules[0]
					const { trip } = schedule.data
					const hasSameCabTypeForAll =
						collect(
							schedules.map((s) => {
								const { cabs } = s.data
								return cabs
									.map((c) => c.cab_type.id)
									.sort((a, b) => (a > b ? 1 : 0))
									.join("-")
							})
						)
							.unique()
							.count() === 1
					let cabTypeWithCount: Array<{ count: number; name: string }> = []
					if (hasSameCabTypeForAll) {
						const cabsCountByName = schedule.data.cabs.reduce<{
							[cab: string]: number
						}>((cabTypeWithCount, cab) => {
							if (!cabTypeWithCount[cab.cab_type.name]) {
								cabTypeWithCount[cab.cab_type.name] = 0
							}
							cabTypeWithCount[cab.cab_type.name] += 1
							return cabTypeWithCount
						}, {})
						cabTypeWithCount = Object.keys(cabsCountByName).map((name) => ({
							name,
							count: cabsCountByName[name],
						}))
					}
					if (!trip) return null
					const cols = (hasSameCabTypeForAll ? 0 : 2) + (includePrices ? 1 : 0)
					return (
						<tbody key={trip.id}>
							{index > 0 ? (
								<>
									<tr>
										<td colSpan={Math.max(3, cols + 2)}></td>
									</tr>
									<tr>
										<td colSpan={Math.max(3, cols + 2)}>
											+++++++++++++++++++++++++
										</td>
									</tr>
								</>
							) : null}
							<tr>
								<th>Trip Id</th>
								<td colSpan={cols}>Pax</td>
								{hasSameCabTypeForAll ? (
									<td>{pluralize("Cab", cabTypeWithCount.length)}</td>
								) : null}
							</tr>
							<tr>
								<th>{trip.id}</th>
								<th colSpan={cols}>
									{pluralize("Adult", trip.no_of_adults, true)}
									{trip.children.length
										? ` with ${pluralize(
												"Child",
												trip.children.length,
												true
											)} (${trip.children.map((c) => `${c.age}y`).join(",")})`
										: ``}
								</th>
								{hasSameCabTypeForAll ? (
									<td>
										<b>
											{cabTypeWithCount
												.map(({ name, count }) => `${count}-${name}`)
												.join(" + ")}
										</b>
									</td>
								) : null}
							</tr>
							<tr>
								<td colSpan={Math.max(3, cols + 2)}>
									<small>Here are the service details:</small>
								</td>
							</tr>
							<tr key="heading">
								<th>Date</th>
								<th colSpan={!cols ? 2 : 1}>Service</th>
								{!hasSameCabTypeForAll ? <th>Cabs</th> : null}
								{includePrices ? (
									<th style={{ textAlign: "right" }}>Price</th>
								) : null}
							</tr>
							<>
								{schedules.map((schedule) => {
									const { cabs, start_date, transport_service } = schedule.data
									const { itinerary } = schedule.meta
									if (!hasSameCabTypeForAll) {
										const cabsCountByName = cabs.reduce<{
											[cab: string]: number
										}>((cabTypeWithCount, cab) => {
											if (!cabTypeWithCount[cab.cab_type.name]) {
												cabTypeWithCount[cab.cab_type.name] = 0
											}
											cabTypeWithCount[cab.cab_type.name] += 1
											return cabTypeWithCount
										}, {})
										cabTypeWithCount = Object.keys(cabsCountByName).map(
											(name) => ({ name, count: cabsCountByName[name] })
										)
									}
									const price = getBookingPriceForCabs(cabs)
									totalPrice += price
									currency = cabs.at(0)?.currency || currency
									return (
										<Fragment key={schedule.data.id}>
											<tr>
												<td>
													<b>
														{utcTimestampToLocalDateString(
															start_date,
															"DD MMM"
														)}
													</b>
													<br />
													<small>
														{utcTimestampToLocalDateString(start_date, "ddd")}
													</small>
												</td>
												<td colSpan={!cols ? 2 : 1}>
													<b>{transport_service.from_to}</b>
													<br />
													<small>{transport_service.service}</small>
												</td>
												{!hasSameCabTypeForAll ? (
													<td>
														{cabTypeWithCount
															.map(({ name, count }) => `${count}-${name}`)
															.join(" + ")}
													</td>
												) : null}
												{includePrices ? (
													<td style={{ textAlign: "right" }}>
														{price ? `${numberToLocalString(price)}` : null}
													</td>
												) : null}
											</tr>
											{includeItinerary && itinerary?.description ? (
												<tr>
													<td
														colSpan={Math.max(3, cols + 2)}
														style={{ whiteSpace: "pre-line" }}
													>
														<Markdown className="tsp-email">
															{itinerary.description}
														</Markdown>
													</td>
												</tr>
											) : null}
										</Fragment>
									)
								})}
							</>
						</tbody>
					)
				})}
			</table>
			{includePrices && totalPrice ? (
				<>
					<br />
					<p>
						<b>
							Total Price ({currency}): {numberToLocalString(totalPrice)} /-
						</b>
					</p>
				</>
			) : null}
			<br />
			<p>
				<b>Please confirm this booking.</b>
			</p>
		</>
	)
}

function getWhatsAppMessageContentForGuestPerTrip(
	schedules: Array<TCabScheduleWithMeta>,
	{ includeItinerary }: { includeItinerary: boolean }
) {
	const groupedByTrip = collect(schedules)
		.groupBy((s) => s.data.trip_id)
		.toArray()
	return groupedByTrip.reduce<{ [TripId: string]: string }>(
		(messagePerTrip, schedulesPerTrip) => {
			const schedule = schedulesPerTrip[0].data
			const { trip } = schedule
			if (!trip) return messagePerTrip
			const { id, contact, no_of_adults, children } = trip
			const schedulesPerDriverCabs = schedulesPerTrip.reduce<
				Array<{
					id: string
					driversAndCabs: Array<{
						driver: TDriver
						cab?: ICab
						cab_type: ICabType
					}>
					schedules: Array<TCabScheduleWithMeta>
				}>
			>((schedulesPerDriverCabs, scheduleData) => {
				const driversAndCabs = collect(
					scheduleData.data.cabs
						.filter((c): c is Required<IScheduledCab, "driver"> =>
							Boolean(c.driver)
						)
						.map((c) => ({
							driver: c.driver,
							cab: c.cab,
							cab_type: c.cab_type,
						}))
				)
					.unique((c) => `d${c.driver.id}-c${c.cab?.id || "0"}`)
					.toArray()
				if (!driversAndCabs.length) return schedulesPerDriverCabs
				const id = driversAndCabs
					.reduce<
						[drivers: Array<{ id: number }>, cabs: Array<{ id: number }>]
					>(
						([drivers, cabs], d) => {
							drivers.push(d.driver)
							cabs.push(d.cab || d.cab_type)
							return [drivers, cabs]
						},
						[[], []]
					)
					.map((d) =>
						d
							.sort((a, b) => (a.id > b.id ? 1 : 0))
							.map((a) => a.id)
							.join(",")
					)
					.join("-")
				const existingDataIndex = schedulesPerDriverCabs.findIndex(
					(d) => d.id === id
				)
				if (existingDataIndex === -1) {
					schedulesPerDriverCabs.push({
						id,
						driversAndCabs,
						schedules: [scheduleData],
					})
				} else {
					schedulesPerDriverCabs[existingDataIndex].schedules.push(scheduleData)
				}
				return schedulesPerDriverCabs
			}, [])
			if (!schedulesPerDriverCabs.length) {
				messagePerTrip[id] = `Missing driver and cab details.
Please assign a driver and a cab to this schedule to share.
`
				return messagePerTrip
			}
			messagePerTrip[id] = `Dear ${contact.name},
Here are the cab details for your *Trip Id ${id}*.

\`\`\`Pax Details :\`\`\` *${pluralize("Adult", no_of_adults, true)}*${
				children.length
					? ` with *${pluralize("Child", children.length, true)} (${children
							.map((c) => `${c.age}y`)
							.join(",")})*`
					: ""
			}

${schedulesPerDriverCabs
	.map(({ driversAndCabs, schedules }) => {
		return `*Driver/Cab*
${driversAndCabs
	.map(
		({ driver, cab, cab_type }) =>
			`\`\`\`-\`\`\` *${driver.name}*${
				driver.phone_number ? ` (${driver.phone_number})` : `*`
			}
\`\`\` :\`\`\` *${
				cab ? `${cab.name} - ${cab.number_plate}` : `${cab_type.name}`
			}*`
	)
	.join("\n")}
_will handle following ${pluralize("service", schedules.length)}:_

${schedules
	.map((schedule) => {
		const { start_date, transport_service } = schedule.data
		const { itinerary } = schedule.meta
		return `*${withOrdinalSuffix(
			getDiff(
				utcTimestampToLocalDate(start_date),
				utcTimestampToLocalDate(trip.start_date),
				"days"
			) + 1
		)} Day* (${utcTimestampToLocalDateString(start_date, "ddd DD MMM")})
\`\`\`-\`\`\` *${transport_service.from_to}* - ${transport_service.service}${
			showPickup(start_date)
				? `
\`\`\` :\`\`\` _Pickup at ${utcTimestampToLocalDateString(
						start_date,
						"HH:mm [hrs]"
					)}_`
				: ``
		}${
			includeItinerary && itinerary?.description
				? `
${itinerary.description}`
				: ``
		}`
	})
	.join("\n\n")}`
	})
	.join(`\n\`\`\`------------\`\`\`\n\n`)}

_For any queries, please contact the trip coordinator._`
			return messagePerTrip
		},
		{}
	)
}

function getWhatsAppMessageContentForDrivers(
	schedules: Array<TCabScheduleWithMeta>,
	{
		itinerary: includeItinerary,
	}: Omit<TInclusionOptions, "prices" | "verifiedItineraryInclusion">
) {
	const drivers: Array<TDriver> = []
	schedules.forEach(function (schedule) {
		schedule.data.cabs.forEach((cab) => {
			const { driver } = cab
			if (driver && drivers.findIndex((d) => d.id === driver.id) === -1) {
				drivers.push(driver)
			}
		})
	})
	const schedulesPerDriver = drivers.map((driver) => {
		return {
			driver,
			schedules: schedules
				.map((s) => ({
					...s,
					data: {
						...s.data,
						cabs: s.data.cabs.filter((cab) => cab.driver?.id === driver.id),
					},
				}))
				.filter((s) => s.data.cabs.length),
		}
	})
	return schedulesPerDriver
		.map(function getMessagePerDriver({
			driver,
			schedules,
		}): [driver: TDriver, message: string] {
			const groupedByTrip = collect(schedules)
				.groupBy((s) => s.data.trip_id)
				.toArray()
			return [
				driver,
				`Dear ${driver.name},
Here are the details of your upcoming ${pluralize("service", schedules.length)}.

${groupedByTrip
	.map((schedules) => {
		const schedule = schedules[0]
		const { trip } = schedule.data
		const hasSameCabsForAll =
			collect(
				schedules
					.map((s) => {
						const { cabs } = s.data
						return cabs
							.reduce<Array<string>>((cabs, cab) => {
								if (cab.cab) {
									cabs.push(`${cab.cab.id}C`)
								} else {
									cabs.push(`${cab.cab_type.id}CT`)
								}
								return cabs
							}, [])
							.filter(Boolean)
							.sort()
							.join("_")
					})
					.filter(Boolean)
			)
				.unique()
				.count() === 1
		const sameCabsForAll = schedule.data.cabs
		if (!trip) return ""
		return `\`\`\`-----------\`\`\`
\`\`\`Trip Id :\`\`\` ${trip.id}
\`\`\`Guest   :\`\`\` *${trip.tourist?.name || "N/A"}*${
			trip.tourist?.phone_numbers?.length
				? `
\`\`\`        |\`\`\` ${trip.tourist.phone_numbers
						.map((p) => p.phone_number)
						.join(", ")}`
				: ``
		}
\`\`\`Pax     :\`\`\` *${pluralize("Adult", trip.no_of_adults, true)}${
			trip.children.length
				? ` with ${pluralize(
						"Child",
						trip.children.length,
						true
					)} (${trip.children.map((c) => `${c.age}y`).join(",")})`
				: ``
		}*${
			hasSameCabsForAll
				? `\n\`\`\`Cab     :\`\`\` ${sameCabsForAll
						.map(({ cab, cab_type }) => `*${cab?.full_name || cab_type.name}*`)
						.join(`\n\`\`\`        |\`\`\` `)}`
				: ``
		}

${schedules
	.map((schedule, index, schedules) => {
		const { cabs, start_date, transport_service } = schedule.data
		const { itinerary } = schedule.meta
		const {
			checkin_hotels,
			checkout_hotels,
			stay_hotels,
			arrival_details,
			departure_details,
		} = schedule.meta
		const whatsappForHotels = getWhatsAppContentForHotels(
			{ checkout_hotels, checkin_hotels, stay_hotels },
			index > 0 ? schedules[index - 1].meta : undefined
		)
		return `---------------------------------
*${utcTimestampToLocalDateString(start_date, "ddd DD MMM")}*${
			showPickup(start_date)
				? ` (Pickup at ${utcTimestampToLocalDateString(start_date, "HH:mm [hrs]")})`
				: ``
		}${
			arrival_details?.length
				? `

*Guest Arrival Details:*
${arrival_details
	.map(
		(d) =>
			`\`\`\`- ${utcTimestampToLocalDateString(
				d.date_time,
				"ddd DD MMM [at] HH:mm [hrs]"
			)}\`\`\`\n_${d.description}_`
	)
	.join("\n")}`
				: ``
		}

\`\`\`-\`\`\` *${transport_service.from_to}*
\`\`\` |\`\`\` ${transport_service.service}${
			includeItinerary && itinerary?.description
				? `
${itinerary.description}`
				: ``
		}${
			!hasSameCabsForAll
				? `
\`\`\`  :\`\`\` ${cabs
						.map((cab) =>
							[cab.cab?.full_name || cab.cab_type.name]
								.filter(Boolean)
								.join(" • ")
						)
						.join(`\n\`\`\`  +\`\`\` `)}`
				: ``
		}${
			whatsappForHotels
				? `

${whatsappForHotels}`
				: ``
		}${
			departure_details?.length
				? `
*Guest Departure Details:*
${departure_details
	.map(
		(d) =>
			`\`\`\`- ${utcTimestampToLocalDateString(
				d.date_time,
				"ddd DD MMM [at] HH:mm [hrs]"
			)}\`\`\`\n_${d.description}_`
	)
	.join("\n")}`
				: ``
		}`
	})
	.join("\n\n")}`
	})
	.join("\n\n")}

`,
			]
		})
		.reduce<{ [driverId: string]: string }>(
			(messageByDriver, [driver, message]) => {
				messageByDriver[driver.id] = message
				return messageByDriver
			},
			{}
		)
}

function getWhatsAppMessageContentForTransportServiceProviders(
	schedules: Array<TCabScheduleWithMeta>,
	{
		prices: includePrices,
		itinerary: includeItinerary,
	}: Omit<TInclusionOptions, "verifiedItineraryInclusion">
) {
	const transportServiceProviders: Array<ITransportServiceProvider> = []
	schedules.forEach(function (schedule) {
		schedule.data.cabs.forEach((cab) => {
			const { transport_service_provider } = cab
			if (
				transport_service_provider &&
				transportServiceProviders.findIndex(
					(d) => d.id === transport_service_provider.id
				) === -1
			) {
				transportServiceProviders.push(transport_service_provider)
			}
		})
	})
	const schedulesPerTsp = transportServiceProviders.map((tsp) => {
		return {
			transport_service_provider: tsp,
			schedules: schedules
				.map((s) => ({
					...s,
					data: {
						...s.data,
						cabs: s.data.cabs.filter(
							(cab) => cab.transport_service_provider?.id === tsp.id
						),
					},
				}))
				.filter((s) => s.data.cabs.length),
		}
	})
	return schedulesPerTsp
		.map(function getMessagePerTsp({
			transport_service_provider,
			schedules,
		}): [tsp: ITransportServiceProvider, message: string] {
			const groupedByTrip = collect(schedules)
				.groupBy((s) => s.data.trip_id)
				.toArray()
			let totalPrice = 0
			let currency = "INR"
			return [
				transport_service_provider,
				`Hi ${transport_service_provider.name},
Here are the details of your upcoming ${pluralize("service", schedules.length)}.

${groupedByTrip
	.map((schedules) => {
		const schedule = schedules[0]
		const { trip } = schedule.data
		const hasSameCabsForAll = getHasSameCabDriverForSchedules(schedules)
		const sameCabsForAll = schedule.data.cabs
		const hasDriverOrCabAssigned = sameCabsForAll.some((s) => s.driver || s.cab)
		let cabTypeWithCount: Array<{ count: number; name: string }> = []
		if (hasSameCabsForAll && !hasDriverOrCabAssigned) {
			const cabsCountByName = schedule.data.cabs.reduce<{
				[cab: string]: number
			}>((cabTypeWithCount, cab) => {
				if (!cabTypeWithCount[cab.cab_type.name]) {
					cabTypeWithCount[cab.cab_type.name] = 0
				}
				cabTypeWithCount[cab.cab_type.name] += 1
				return cabTypeWithCount
			}, {})
			cabTypeWithCount = Object.keys(cabsCountByName).map((name) => ({
				name,
				count: cabsCountByName[name],
			}))
		}
		if (!trip) return ""
		return `\`\`\`-----------\`\`\`
\`\`\`Trip Id :\`\`\` ${trip.id}
\`\`\`Guest   :\`\`\` *${trip.tourist?.name || "N/A"}*${
			trip.tourist?.phone_numbers?.length
				? `
\`\`\`        |\`\`\` ${trip.tourist.phone_numbers
						.map((p) => p.phone_number)
						.join(", ")}`
				: ``
		}
\`\`\`Pax     :\`\`\` *${pluralize("Adult", trip.no_of_adults, true)}${
			trip.children.length
				? ` with ${pluralize(
						"Child",
						trip.children.length,
						true
					)} (${trip.children.map((c) => `${c.age}y`).join(",")})`
				: ``
		}*${
			hasSameCabsForAll
				? `\n----\n*${hasDriverOrCabAssigned ? "Driver/Cab" : "Cabs"}*
${
	hasDriverOrCabAssigned
		? sameCabsForAll
				.map(
					({ cab, driver, cab_type }) =>
						`\`\`\`- \`\`\` *${cab?.full_name || cab_type.name}*${
							!driver
								? ``
								: `
\`\`\`  :\`\`\` _${driver?.name} (${driver?.phone_number})_`
						}`
				)
				.join(`\n`)
		: `\`\`\`- \`\`\` ${cabTypeWithCount
				.map((c) => `${c.count}-${c.name}`)
				.join(" • ")}`
}`
				: ``
		}

${schedules
	.map((schedule, index, schedules) => {
		const { cabs, start_date, transport_service } = schedule.data
		const { itinerary } = schedule.meta
		const {
			checkin_hotels,
			checkout_hotels,
			stay_hotels,
			arrival_details,
			departure_details,
		} = schedule.meta
		const price = getBookingPriceForCabs(cabs)
		totalPrice += price
		currency = cabs.at(0)?.currency || currency
		const whatsappForHotels = getWhatsAppContentForHotels(
			{ checkin_hotels, checkout_hotels, stay_hotels },
			index > 0 ? schedules[index - 1].meta : undefined
		)
		return `---------------------------------
*${utcTimestampToLocalDateString(start_date, "ddd DD MMM")}*${
			showPickup(start_date)
				? ` (Pickup at ${utcTimestampToLocalDateString(start_date, "HH:mm [hrs]")})`
				: ``
		}${
			arrival_details?.length
				? `

*Guest Arrival Details:*
${arrival_details
	.map(
		(d) =>
			`\`\`\`- ${utcTimestampToLocalDateString(
				d.date_time,
				"ddd DD MMM [at] HH:mm [hrs]"
			)}\`\`\`\n_${d.description}_`
	)
	.join("\n")}`
				: ``
		}

\`\`\`-\`\`\` *${transport_service.from_to}*
\`\`\` |\`\`\` ${transport_service.service}${
			includeItinerary && itinerary?.description
				? `
${itinerary.description}`
				: ``
		}${
			includePrices && price
				? `
\`\`\`-\`\`\` *${numberToLocalString(price)}* /-`
				: ``
		}${
			!hasSameCabsForAll
				? `
\`\`\` :\`\`\` ${cabs
						.map((cab) =>
							[
								cab.cab ? `*${cab.cab.full_name}*` : cab.cab_type.name,
								cab.driver
									? `${cab.driver.name} (${cab.driver.phone_number})`
									: ``,
							]
								.filter(Boolean)
								.join(`\n\`\`\`  - \`\`\``)
						)
						.join(`\n\`\`\` +\`\`\` `)}`
				: ``
		}${
			whatsappForHotels
				? `

${whatsappForHotels}
`
				: ``
		}${
			departure_details?.length
				? `
*Guest Departure Details:*
${departure_details
	.map(
		(d) =>
			`\`\`\`- ${utcTimestampToLocalDateString(
				d.date_time,
				"ddd DD MMM [at] HH:mm [hrs]"
			)}\`\`\`\n_${d.description}_`
	)
	.join("\n")}`
				: ``
		}`
	})
	.join("\n\n")}`
	})
	.join("\n\n")}${
					includePrices && totalPrice
						? `

*Total Price (${currency}): ${numberToLocalString(totalPrice)} /-*`
						: ``
				}
`,
			]
		})
		.reduce<{ [tspId: string]: string }>((messageByTsp, [tsp, message]) => {
			messageByTsp[tsp.id] = message
			return messageByTsp
		}, {})
}

function EmailContentForTransportServiceProviders({
	schedules,
	selectedTsp,
	includePrices,
	includeItinerary,
}: {
	schedules: Array<TCabScheduleWithMeta>
	selectedTsp?: ITransportServiceProvider
	includePrices: boolean
	includeItinerary: boolean
}) {
	const transportServiceProviders = useMemo(() => {
		const transportServiceProviders: Array<ITransportServiceProvider> = []
		schedules.forEach(function (schedule) {
			schedule.data.cabs.forEach((cab) => {
				const { transport_service_provider } = cab
				if (
					transport_service_provider &&
					transportServiceProviders.findIndex(
						(d) => d.id === transport_service_provider.id
					) === -1
				) {
					transportServiceProviders.push(transport_service_provider)
				}
			})
		})
		return transportServiceProviders
	}, [schedules])
	const schedulesPerTsp = useMemo(() => {
		return transportServiceProviders.reduce<{
			[tspId: number]: typeof schedules
		}>((schedulesByTspId, tsp) => {
			schedulesByTspId[tsp.id] = schedules
				.map((s) => ({
					...s,
					data: {
						...s.data,
						cabs: s.data.cabs.filter(
							(cab) => cab.transport_service_provider?.id === tsp.id
						),
					},
				}))
				.filter((s) => s.data.cabs.length)
			return schedulesByTspId
		}, {})
	}, [transportServiceProviders, schedules])
	if (!selectedTsp) return null
	const schedulesForSelectedTsp = schedulesPerTsp[selectedTsp.id]
	const groupedByTrip = collect(schedulesForSelectedTsp)
		.groupBy((s) => s.data.trip_id)
		.toArray()
	let totalPrice = 0
	let currency = "INR"
	const tenantBrand = schedulesForSelectedTsp[0]?.data?.trip?.tenant_brand
	return (
		<>
			<Box as="p">Hi {selectedTsp.name},</Box>

			{!tenantBrand ? null : (
				<>
					<br />
					<Box as="p">
						Greetings from{" "}
						<b>
							{tenantBrand.short_name}
							{tenantBrand.name
								.toLowerCase()
								.indexOf(tenantBrand.short_name.toLowerCase()) === -1
								? ` (${tenantBrand.name})`
								: null}
						</b>
						.
					</Box>
				</>
			)}
			<br />
			<Box as="p">
				Here are the details of your upcoming{" "}
				{pluralize("service", schedules.length)}.
			</Box>
			<br />
			<table style={{ width: "100%" }}>
				{groupedByTrip.map((schedules, index) => {
					const schedule = schedules[0]
					const { trip } = schedule.data
					if (!trip) return null
					const hasSameCabsForAll = getHasSameCabDriverForSchedules(schedules)
					const sameCabsForAll = schedule.data.cabs
					const hasDriverOrCabAssigned = sameCabsForAll.some(
						(s) => s.driver || s.cab
					)
					let cabTypeWithCount: Array<{ count: number; name: string }> = []
					if (hasSameCabsForAll && !hasDriverOrCabAssigned) {
						const cabsCountByName = schedule.data.cabs.reduce<{
							[cab: string]: number
						}>((cabTypeWithCount, cab) => {
							if (!cabTypeWithCount[cab.cab_type.name]) {
								cabTypeWithCount[cab.cab_type.name] = 0
							}
							cabTypeWithCount[cab.cab_type.name] += 1
							return cabTypeWithCount
						}, {})
						cabTypeWithCount = Object.keys(cabsCountByName).map((name) => ({
							name,
							count: cabsCountByName[name],
						}))
					}
					const cols = (hasSameCabsForAll ? 3 : 5) + (includePrices ? 1 : 0)
					const arrival_details: TArrivalDepartureDetail[] = []
					for (const schedule of schedules) {
						if (schedule.meta.arrival_details) {
							schedule.meta.arrival_details.forEach((s) => {
								if (arrival_details.findIndex((d) => d.id !== s.id) < 0) {
									arrival_details.push(s)
								}
							})
						}
					}
					const departure_details: TArrivalDepartureDetail[] = []
					for (const schedule of schedules) {
						if (schedule.meta.departure_details) {
							schedule.meta.departure_details.forEach((s) => {
								if (departure_details.findIndex((d) => d.id !== s.id) < 0) {
									departure_details.push(s)
								}
							})
						}
					}
					return (
						<tbody key={trip.id}>
							{index > 0 ? (
								<>
									<tr>
										<td colSpan={cols + 1}></td>
									</tr>
									<tr>
										<td colSpan={cols + 1}>+++++++++++++++++++++++++</td>
									</tr>
								</>
							) : null}
							<tr>
								<th>Trip Id</th>
								<th>Guest</th>
								<th>Contact No.</th>
								<th colSpan={cols - 2}>Pax</th>
							</tr>
							<tr>
								<th>{trip.id}</th>
								<th>
									{trip.tourist ? trip.tourist.name : "Name - To be updated"}
								</th>
								<td>
									{trip.tourist?.phone_numbers?.length
										? trip.tourist.phone_numbers.map((p, idx) => (
												<Fragment key={p.phone_number}>
													{idx >= 1 ? <span>, </span> : null}
													<a href={`tel:${p.phone_number}`}>{p.phone_number}</a>
												</Fragment>
											))
										: null}
								</td>
								<th colSpan={cols - 2}>
									{pluralize("Adult", trip.no_of_adults, true)}
									{trip.children.length
										? ` with ${pluralize(
												"Child",
												trip.children.length,
												true
											)} (${trip.children.map((c) => `${c.age}y`).join(",")})`
										: ``}
								</th>
							</tr>
							{arrival_details.length || departure_details.length ? (
								<>
									{arrival_details.map((d, index, arr) => (
										<tr key={d.id}>
											{index === 0 ? (
												<th rowSpan={arr.length}>Arrival Details</th>
											) : null}
											<td>
												{utcTimestampToLocalDateString(
													d.date_time,
													"ddd DD MMM [at] HH:mm [hrs]"
												)}
											</td>
											<td colSpan={cols - 1}>{d.description}</td>
										</tr>
									))}
									{departure_details.map((d, index, arr) => (
										<tr key={d.id}>
											{index === 0 ? (
												<th rowSpan={arr.length}>Departure Details</th>
											) : null}
											<td>
												{utcTimestampToLocalDateString(
													d.date_time,
													"ddd DD MMM [at] HH:mm [hrs]"
												)}
											</td>
											<td colSpan={cols - 1}>{d.description}</td>
										</tr>
									))}
								</>
							) : null}
							{hasSameCabsForAll ? (
								<>
									{!hasDriverOrCabAssigned ? (
										<tr>
											<th>Cab Details</th>
											<th colSpan={cols}>
												{cabTypeWithCount
													.map((c) => `${c.count}-${c.name}`)
													.join(", ")}
											</th>
										</tr>
									) : (
										<>
											<tr>
												<td colSpan={cols + 1} style={{ paddingTop: "16px" }}>
													<small>Cab and Driver Details</small>
												</td>
											</tr>
											<tr>
												<th>Sr.</th>
												<th>Cab</th>
												<th>Driver</th>
												<th colSpan={cols - 1}>Contact No.</th>
											</tr>
											{sameCabsForAll.map(
												({ id, cab, driver, cab_type }, index) => (
													<tr key={id}>
														<td>{index + 1}.</td>
														<td>
															{cab?.name || cab_type.name}
															{cab ? ` - ${cab?.number_plate}` : null}
														</td>
														<td>{driver?.name}</td>
														<td colSpan={cols - 1}>
															{driver?.phone_number ? (
																<a href={`tel:${driver.phone_number}`}>
																	{driver.phone_number}
																</a>
															) : null}
														</td>
													</tr>
												)
											)}
										</>
									)}
								</>
							) : null}
							<tr>
								<td colSpan={cols + 1} style={{ paddingTop: "16px" }}>
									<small>
										Here are the date-wise services with check-in/check-out
										hotel details
									</small>
								</td>
							</tr>
							<tr>
								<th>Date</th>
								<th>Service</th>
								{!hasSameCabsForAll ? (
									<>
										<th>Cab</th>
										<th>Driver</th>
									</>
								) : null}
								{includePrices ? (
									<Box as="th" textAlign="right">
										Price
									</Box>
								) : null}
								<th>Check-Out</th>
								<th>Check-In/Stay</th>
							</tr>
							{schedules.map((schedule) => {
								const { cabs, start_date, transport_service } = schedule.data
								const {
									checkin_hotels,
									checkout_hotels,
									stay_hotels,
									itinerary,
								} = schedule.meta
								const price = getBookingPriceForCabs(cabs)
								totalPrice += price
								currency = cabs.at(0)?.currency || currency
								return (
									<React.Fragment key={schedule.data.id}>
										{cabs.map((cab, index) => {
											if (hasSameCabsForAll && index > 0) return null
											const cabsRows = hasSameCabsForAll ? 1 : cabs.length
											return (
												<tr key={cab.id}>
													{index === 0 ? (
														<>
															<td rowSpan={cabsRows}>
																<Box
																	as="time"
																	whiteSpace="preserve"
																	dateTime={utcTimestampToLocalDate(
																		start_date
																	).toISOString()}
																>
																	<b>
																		{utcTimestampToLocalDateString(
																			start_date,
																			"DD MMM"
																		)}
																	</b>
																	<br />
																	<small>
																		{utcTimestampToLocalDateString(
																			start_date,
																			"ddd"
																		)}
																	</small>
																	{showPickup(start_date) ? (
																		<>
																			<br />
																			<b>
																				<small>
																					{utcTimestampToLocalDateString(
																						start_date,
																						"HH:mm [hrs]"
																					)}
																				</small>
																			</b>
																		</>
																	) : null}
																</Box>
															</td>
															<td rowSpan={cabsRows}>
																<Box as="b" whiteSpace="preserve">
																	{transport_service.from_to}
																</Box>
																<br />
																<small>{transport_service.service}</small>
															</td>
														</>
													) : null}
													{!hasSameCabsForAll ? (
														<>
															<td>
																{cab.cab ? (
																	<Box>
																		<b>{cab.cab.number_plate}</b>
																		<br />
																		<small>{cab.cab.name}</small>
																	</Box>
																) : (
																	<Box>{cab.cab_type.name}</Box>
																)}
															</td>
															<td>
																{cab.driver ? (
																	<Box>
																		{cab.driver.name}
																		{cab.driver.phone_number ? (
																			<>
																				<br />
																				<a
																					href={`tel:${cab.driver.phone_number}`}
																				>
																					<small>
																						{cab.driver.phone_number}
																					</small>
																				</a>
																			</>
																		) : null}
																	</Box>
																) : null}
															</td>
														</>
													) : null}
													{index === 0 ? (
														<>
															{includePrices ? (
																<Box
																	as="td"
																	rowSpan={cabsRows}
																	textAlign="right"
																>
																	{price ? numberToLocalString(price) : null}
																</Box>
															) : null}
															{stay_hotels.length ? (
																<>
																	<td rowSpan={cabsRows}></td>
																	<td rowSpan={cabsRows}>
																		{stay_hotels.length
																			? stay_hotels.map((h) => (
																					<Box key={h.id}>
																						<small>
																							<b>{h.hotel.name}</b>
																						</small>
																						<br />
																						<small>
																							{h.hotel.location.short_name}
																						</small>
																					</Box>
																				))
																			: null}
																	</td>
																</>
															) : (
																<>
																	<td rowSpan={cabsRows}>
																		{checkout_hotels.length
																			? checkout_hotels.map((h) => (
																					<Box key={h.id}>
																						<small>
																							<b>{h.hotel.name}</b>
																						</small>
																						<br />
																						<small>
																							{h.hotel.location.short_name}
																						</small>
																					</Box>
																				))
																			: null}
																	</td>
																	<td rowSpan={cabsRows}>
																		{checkin_hotels.length
																			? checkin_hotels.map((h) => (
																					<Box key={h.id}>
																						<small>
																							<b>{h.hotel.name}</b>
																						</small>
																						<br />
																						<small>
																							{h.hotel.location.short_name}
																						</small>
																					</Box>
																				))
																			: null}
																	</td>
																</>
															)}
														</>
													) : null}
												</tr>
											)
										})}
										{includeItinerary && itinerary ? (
											<tr>
												<td colSpan={cols + 1}>
													<Markdown className="tsp-email">
														{itinerary.description}
													</Markdown>
												</td>
											</tr>
										) : null}
									</React.Fragment>
								)
							})}
						</tbody>
					)
				})}
			</table>
			{includePrices && totalPrice ? (
				<>
					<br />
					<Box>
						<b>
							Total Price ({currency}): {numberToLocalString(totalPrice)} /-{" "}
						</b>
					</Box>
				</>
			) : null}
		</>
	)
}

function getHasSameCabDriverForSchedules(
	schedules: Array<TCabScheduleWithMeta>
) {
	return (
		collect(
			schedules
				.map((s) => {
					const { cabs } = s.data
					return cabs
						.reduce<[drivers: Array<string>, cabs: Array<string>]>(
							([drivers, cabs], cab) => {
								if (cab.cab) {
									cabs.push(`${cab.cab.id}C`)
									if (!cab.driver) {
										drivers.push(`${cab.cab_type.id}CT`)
									}
								}
								if (cab.driver) {
									drivers.push(`${cab.driver.id}D`)
									if (!cab.cab) {
										drivers.push(`${cab.cab_type.id}CT`)
									}
								}
								if (!cab.driver && !cab.cab) {
									drivers.push(`${cab.cab_type.id}CT`)
									cabs.push(`${cab.cab_type.id}CT`)
								}
								return [drivers, cabs]
							},
							[[], []]
						)
						.map((d) => d.sort().join(","))
						.filter(Boolean)
						.join("_")
				})
				.filter(Boolean)
		)
			.unique()
			.count() === 1
	)
}

function getBookingPriceForCabs(cabs: Array<IScheduledCab>) {
	return cabs.reduce<number>(
		(price, cab) => price + Number(cab.booked_price || 0),
		0
	)
}

function getWhatsAppContentForHotels(
	{
		stay_hotels,
		checkout_hotels,
		checkin_hotels,
	}: Pick<
		$PropertyType<TCabScheduleWithMeta, "meta">,
		"stay_hotels" | "checkout_hotels" | "checkin_hotels"
	>,
	hotelsFromPreviousSchedule:
		| $PropertyType<TCabScheduleWithMeta, "meta">
		| undefined
) {
	const checkinsFromPreviousSchedule = hotelsFromPreviousSchedule
		? hotelsFromPreviousSchedule.checkin_hotels
		: []
	const staysFromPreviousSchedule = hotelsFromPreviousSchedule
		? hotelsFromPreviousSchedule.stay_hotels
		: []
	const previousStaysIds = collect(
		checkinsFromPreviousSchedule
			.concat(staysFromPreviousSchedule)
			.map((b) => b.hotel.id)
			.sort((a, b) => (a > b ? 1 : -1))
	)
		.unique()
		.toArray()
	const currentCheckoutsIds = collect(
		checkout_hotels.map((b) => b.hotel.id).sort((a, b) => (a > b ? 1 : -1))
	)
		.unique()
		.toArray()
	const areCheckinsFromPreivousSameAsCurrentCheckouts =
		previousStaysIds.join("-") === currentCheckoutsIds.join("-")
	return (
		[
			[stay_hotels as typeof checkin_hotels, `\`\`\`Stay    :\`\`\`` as string],
			[
				// don't render checkouts if we have checkin from previous sames as current checkout
				// And there are we have some checkins/staus for current
				// check fo current checkins/staus is needed to show checkouts on the last schedule
				areCheckinsFromPreivousSameAsCurrentCheckouts &&
				(checkin_hotels.length > 0 || stay_hotels.length > 0)
					? []
					: checkout_hotels,
				`\`\`\`Checkout:\`\`\``,
			],
			[checkin_hotels, `\`\`\`Checkin :\`\`\``],
		] as Array<[typeof checkin_hotels, "string"]>
	)
		.map(([hotels, label]) =>
			!hotels.length
				? ``
				: `${label} ${hotels
						.map(
							(h) =>
								`${h.hotel.location.short_name}
\`\`\`        |\`\`\` _${h.hotel.name}_`
						)
						.join(`\n\`\`\`        :\`\`\` `)}`
		)
		.filter(Boolean)
		.join("\n")
}

function SelectTripCabSchedules({
	tripId,
	onCancel,
	onSelect,
}: {
	tripId: number
	onCancel: () => void
	onSelect: (selectedSchedules: Array<ICabSchedule>) => void
}) {
	const xhr = useXHR()
	const id = useId()
	const { data } = useSWR<ITripCabSchedule>(
		`/trips/${tripId}/cab-schedules?${id}`,
		() =>
			xhr.get(`/trip-cab-schedules/${tripId}`).then((resp) => resp.data.data)
	)
	const initialValues = useMemo(() => {
		if (!data) {
			return {
				schedules: [],
			}
		}
		return {
			schedules: data.schedules.map((s) => ({ ...s, selected: false })),
		}
	}, [data])
	if (!data) {
		return <Spinner padding="4" alignCenter />
	}
	const { schedules } = data
	if (!schedules.length) {
		return (
			<Box textAlign="center" padding="4">
				No cab schedules for this trip
			</Box>
		)
	}
	return (
		<Stack gap="4">
			<Stack gap="2">
				<Heading as="h4">Select Schedules to Share</Heading>
				<Text color="muted">
					Please select the schedules that you want to share with yours
					customers or service providers.
				</Text>
			</Stack>
			<Form<typeof initialValues>
				initialValues={initialValues}
				onSubmit={async (values) => {
					onSelect(values.schedules.filter((s) => s.selected))
				}}
				subscription={{ submitting: true }}
				mutators={{ ...arrayMutators }}
			>
				{({ handleSubmit, form }) => (
					<form noValidate onSubmit={handleSubmit}>
						<FieldArray<
							(typeof initialValues)["schedules"][number]
						> name="schedules">
							{({ fields }) => (
								<Box>
									<GetFieldValue<
										(typeof initialValues)["schedules"]
									> name="schedules">
										{({ value: schedules }) => (
											<Box
												as="label"
												paddingX="4"
												paddingBottom="2"
												marginBottom="4"
												display="flex"
												alignItems="center"
												borderBottomWidth="2"
												borderColor="default"
												cursor="pointer"
											>
												<CheckboxInput
													checked={schedules.every((s) => s.selected)}
													onChange={(e) => {
														if (e.currentTarget.checked) {
															// select all the cabs
															form.change(
																"schedules",
																schedules.map((s) => ({
																	...s,
																	selected: true,
																}))
															)
														} else {
															// unselect all the cabs
															form.change(
																"schedules",
																schedules.map((s) => ({
																	...s,
																	selected: false,
																}))
															)
														}
													}}
												/>
												<Box marginLeft="2" fontWeight="semibold">
													Select All Schedules
												</Box>
											</Box>
										)}
									</GetFieldValue>
									<Box as="ul">
										{fields.map((name) => (
											<Box as="li" key={name}>
												<GetFieldValue<
													(typeof initialValues)["schedules"][number]
												>
													name={name}
												>
													{({ value: schedule }) => (
														<>
															<Box
																display="flex"
																as="label"
																paddingX="4"
																paddingY="2"
																rounded="lg"
																marginBottom="4"
																backgroundColor={{
																	default: schedule.selected
																		? "primary"
																		: "subtle",
																	hover: "primary",
																}}
																cursor="pointer"
															>
																<Box>
																	<CheckboxInputField
																		name={`${name}.selected`}
																		type="checkbox"
																	/>
																</Box>
																<Box marginLeft="4" flex="1" minWidth="0">
																	<Box
																		display={{ sm: "flex" }}
																		justifyContent="between"
																	>
																		<Box>
																			<Box
																				fontWeight="semibold"
																				display="inlineBlock"
																			>
																				{schedule.transport_service.from_to}
																			</Box>
																			<Box display="inline" paddingX="2">
																				-
																			</Box>
																			<Box display="inlineBlock">
																				{schedule.transport_service.service}
																			</Box>
																		</Box>
																		<Box>
																			{utcTimestampToLocalDateString(
																				schedule.start_date,
																				"ddd, D MMM"
																			)}
																		</Box>
																	</Box>
																	<Box
																		as="ul"
																		fontWeight="normal"
																		marginTop="1"
																	>
																		{schedule.cabs.map((cab) => {
																			return (
																				<Box
																					key={cab.id}
																					as="li"
																					marginTop="1"
																					display={{ sm: "flex" }}
																					alignItems="center"
																					fontSize="sm"
																				>
																					<Box
																						marginRight="4"
																						paddingRight="2"
																						borderRightWidth={
																							cab.transport_service_provider
																								? "4"
																								: "0"
																						}
																					>
																						{cab.cab_type.name}
																					</Box>
																					{cab.transport_service_provider ? (
																						<Box display="flex" flexWrap="wrap">
																							{joinAttributes(
																								<Box>
																									{
																										cab
																											.transport_service_provider
																											.name
																									}
																								</Box>,
																								cab.driver &&
																									!cab
																										.transport_service_provider
																										.solo_driver ? (
																									<Box>{cab.driver.name}</Box>
																								) : null,
																								cab.cab ? (
																									<Box>{cab.cab.name}</Box>
																								) : null,
																								cab.cab ? (
																									<Box>
																										{cab.cab.number_plate}
																									</Box>
																								) : null
																							)}
																						</Box>
																					) : null}
																					<Box display="flex">
																						{cab.booked ? (
																							<Box marginLeft="2">
																								<Badge success outlined>
																									Booked
																								</Badge>
																							</Box>
																						) : cab.transport_service_provider ? (
																							<Box marginLeft="2">
																								<Badge outlined warning>
																									Assigned
																								</Badge>
																							</Box>
																						) : null}
																					</Box>
																				</Box>
																			)
																		})}
																	</Box>
																</Box>
															</Box>
														</>
													)}
												</GetFieldValue>
											</Box>
										))}
									</Box>
								</Box>
							)}
						</FieldArray>
						<Box
							textAlign="right"
							marginTop="8"
							borderTopWidth="1"
							paddingTop="8"
						>
							<Inline flexDirection="rowReverse" gap="4">
								<GetFieldValue<
									(typeof initialValues)["schedules"]
								> name="schedules">
									{({ value: schedules }) => (
										<Button
											type="submit"
											disabled={!schedules.some((s) => s.selected)}
										>
											Next <Icons.ChevronDown rotate="270" />
										</Button>
									)}
								</GetFieldValue>
								<Button onClick={onCancel} level="tertiary">
									Cancel
								</Button>
							</Inline>
						</Box>
					</form>
				)}
			</Form>
		</Stack>
	)
}

function EmailShare({
	shareWith,
	children,
	subject,
	disabled,
}: {
	shareWith: "booking_enquiry" | "transport_service_provider"
	children: React.ReactNode
	subject?: React.ReactNode
	disabled?: boolean
}) {
	return (
		<CopyToClipboard<HTMLDivElement>>
			{({ nodeToCopy, copy }) => (
				<Box position="relative">
					{subject ? (
						<Stack gap="4" paddingX="4" paddingTop="4">
							<CopyToClipboard<HTMLDivElement>>
								{({ nodeToCopy, copyNodeTextToClipboard }) => (
									<Stack gap="1">
										<Inline justifyContent="between" alignItems="center">
											<Text fontWeight="semibold">Subject</Text>
											{!disabled ? (
												<CopyToClipboardButton
													size="sm"
													level="primary"
													onClick={async () => {
														return copyNodeTextToClipboard()
													}}
												>
													<Icons.ClipboardCopy /> Copy Subject
												</CopyToClipboardButton>
											) : null}
										</Inline>
										<Box
											paddingY="2"
											paddingX="4"
											borderWidth="1"
											rounded="md"
											bgColor="subtle"
										>
											<Box ref={nodeToCopy}>{subject}</Box>
										</Box>
									</Stack>
								)}
							</CopyToClipboard>
							<Text fontWeight="semibold">Body</Text>
						</Stack>
					) : null}
					{!disabled ? (
						<Box position="sticky" top="0">
							<Box position="absolute" right="0" padding="4">
								<CopyToClipboardButton
									level="primary"
									disabled={disabled}
									onClick={async () => {
										return copy()
									}}
								>
									<Icons.ClipboardCopy /> Copy
								</CopyToClipboardButton>
							</Box>
						</Box>
					) : null}
					<Box padding="4" overflowX="auto">
						<ShadowRoot>
							<Box ref={nodeToCopy}>
								<Box style={{ width: "100%" }}>
									<style>{`* { border: none; margin: 0; }
									td, th, span, p, b, body, li, h1, h2, h3, h4, h5, h6, div, strong, a { font-family: Verdana; font-size: 10pt; }
									table { border-collapse: collapse;}
									td, th { border: 1px solid #dfe3e6; padding: 4px 8px; vertical-align: top; text-align: left;}
									a { color: black; text-decoration: none;}
									.tsp-email td, .tsp-email th, .tsp-email span, .tsp-email p, .tsp-email b, .tsp-email body, .tsp-email li, .tsp-email h1, .tsp-email h2, .tsp-email h3, .tsp-email h4, .tsp-email h5, .tsp-email h6, .tsp-email div, .tsp-email strong, .tsp-email a { font-size: 9pt; }
								`}</style>
									{children}
								</Box>
							</Box>
						</ShadowRoot>
					</Box>
				</Box>
			)}
		</CopyToClipboard>
	)
}

function showPickup(pickup_at: string) {
	return utcTimestampToLocalDateString(pickup_at, "HH:mm") !== "00:01"
}
