import { XHRInstance } from "@sembark-travel/xhr"
import { ILocation } from "../Locations"
import { IUser } from "../Auth"
import { IAddress } from "../Addresses"

export type TInvoice = {
	id: number
	status: "draft" | "new" | "processed"
	number?: string
	reference_id?: string
	due_at: string
	created_at: string
	currency: string
	total_amount: number
	seller_name: string
	seller_address?: IAddress
	seller_functional_currency: string
	buyer_name: string
	buyer_address?: IAddress
	location_of_supply?: ILocation
	created_by: IUser
	updated_at?: string
	updated_by: IUser
	items: Array<{
		description: string
		currency: string
		rate_per_quantity: number
		taxes: Array<{
			name: string
			short_name: string
			percentage: number
		}>
	}>
	pdf_file_url?: string
	is_generating_pdf?: boolean
}

export function invoicesXHR(xhr: XHRInstance) {
	return {
		async destroy(invoiceId: number | string) {
			return xhr
				.delete<{ message: string }>(`/invoices/${invoiceId}`)
				.then((resp) => resp.data)
		},
		async getLocationsOfSupply(params: unknown) {
			return xhr
				.get<{ data: Array<ILocation> }>(`/invoices/locations-of-supply`, {
					params,
				})
				.then((resp) => resp.data.data)
		},
	}
}
