import { useMemo } from "react"
import { $Values } from "utility-types"
import { useAuthUser } from "./store"

export const PERMISSIONS = {
	MANAGE_ROLES: "manage_roles",
	MANAGE_TENANTS: "manage_tenants",
	VIEW_TENANTS: "view_tenants",
	MANAGE_TRIP_OWNERS: "manage_trip_owners",
	MANAGE_TRIP_PLAN_REQUESTS: "manage_trip_plan_requests",
	MANAGE_USERS: "manage_users",
	MANAGE_ACCOUNTING: "manage_accounting",
	VIEW_TRIP_PLAN_REQUESTS: "view_trip_plan_requests",
	VIEW_QUERIES: "view_queries",
	CREATE_QUERIES: "create_queries",
	VIEW_HOTEL_BOOKINGS: "view_hotel_bookings",
	MODIFY_HOTEL_BOOKINGS: "modify_hotel_bookings",
	VIEW_CAB_SCHEDULES: "view_cab_schedules",
	MODIFY_CAB_SCHEDULES: "modify_cab_schedules",
	CONNECT_WITH_TENANTS: "connect_with_tenants",
	VIEW_TENANT_CONNECTIONS: "view_tenant_connections",
	CREATE_QUOTE: "create_quote",
	CREATE_ACCOUNTING_ACCOUNT: "create_accounting_account",
	VIEW_HOTELS: "view_hotels",
	VIEW_TRANSPORT_SERVICES: "view_transport_services",
	VIEW_TRANSPORT_SERVICE_PROVIDERS: "view_transport_service_providers",
	VIEW_BANK_ACCOUNT_STATEMENTS: "view_bank_account_statements",
	ADD_TRIP_SOURCES: "add_trip_sources",
	ADD_TRIP_DESTINATIONS: "add_trip_destinations",
	VIEW_TRIP_SALES_REPORTS: "view_trip_sales_reports",
	VIEW_TRIP_CHECKOUT_REPORTS: "trip_checkout_reports.read",
	VIEW_INCOMING_PAYMENTS: "view_incoming_payments",
	VIEW_OUTGOING_PAYMENTS: "view_outgoing_payments",
	UPLOAD_BULK_HOTELS: "upload_bulk_hotels",
	UPLOAD_BULK_TRANSPORT_SERVICES: "upload_bulk_transport_services",
	MANAGE_INTEGRATIONS: "manage_integrations",
	CREATE_TEAM: "teams.create",
}

export function useCheckPermissions() {
	const { user } = useAuthUser()
	const permissionsByName = useMemo<{ [key: string]: string }>(() => {
		if (!user) return {}
		const { permissions } = user
		return permissions.reduce(
			(byName: { [key: string]: string }, permission) => {
				byName[permission] = permission
				return byName
			},
			{}
		)
	}, [user])
	return {
		/**
		 * Check if the user has all the required permissions
		 */
		hasPermission(...permissions: Array<$Values<typeof PERMISSIONS>>): boolean {
			if (typeof permissions === "string") {
				permissions = [permissions]
			}
			return permissions.every((permission) => permissionsByName[permission])
		},
		/**
		 * Check if the user has any of the checked permissions
		 */
		hasAnyPermission(
			...permissions: Array<$Values<typeof PERMISSIONS>>
		): boolean {
			return permissions.some((permission) => permissionsByName[permission])
		},
	}
}
