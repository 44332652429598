import { Box } from "@sembark-travel/ui/base"
import { useMemo } from "react"
import { PaymentsList } from "../Payments"
import { IHotelGroup } from "./store"

export function HotelGroupPayments({ group }: { group: IHotelGroup }) {
	const hotels = useMemo(() => {
		return group.hotels?.map((h) => h.id) || []
	}, [group])
	if (hotels.length === 0) {
		return (
			<Box paddingX="4" paddingY="8" bgColor="default">
				<Box as="h4" fontSize="lg">
					No Hotels in this group
				</Box>
				<Box as="p">
					Please add some hotel in this group to view their payments
				</Box>
			</Box>
		)
	}
	return (
		<Box paddingTop="8">
			<PaymentsList hotels={hotels} debit />
		</Box>
	)
}
