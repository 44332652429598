import { useParams } from "../../../../../router-utils"
import { BankDetails } from "../../../../../Admin/World"
import { Helmet } from "react-helmet-async"

export default function BankDetailsPage() {
	const { bankId } = useParams("/admin/world/banks/:bankId")
	return (
		<>
			<Helmet>
				<title>Bank Details</title>
			</Helmet>
			<BankDetails bankId={bankId} />
		</>
	)
}
