/* eslint-disable */
export default function createCache() {
	let cache: { [key: string]: any } = {}
	const inflight: { [key: string]: Promise<any> } = {}
	return {
		set<T = any>(key: string, value: T) {
			cache[key] = value
			return value
		},
		get<T = any>(key: string): T {
			return cache[key] || undefined
		},
		clear() {
			cache = {}
		},
		async resource<T = any>(
			key: string,
			getPromise: () => Promise<T>,
			shouldRevalidate?: boolean
		): Promise<T> {
			function revalidate() {
				if (inflight[key] === undefined) {
					inflight[key] = getPromise().then((val) => {
						delete inflight[key]
						cache[key] = val
						return val
					})
				}
				return inflight[key]
			}
			if (cache[key] !== undefined) {
				return Promise.resolve<T>(cache[key]).then((options) => {
					if (shouldRevalidate) {
						revalidate()
					}
					return options
				})
			}
			return revalidate()
		},
	}
}
