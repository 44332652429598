import {
	Stack,
	Button,
	Inline,
	Table,
	Text,
	Alert,
	Icons,
	Spinner,
	Container,
	Heading,
	Divider,
	RelativeTime,
	Box,
	Dropdown,
	Component,
	joinAttributes,
	Time,
	Tabs,
	TabsList,
	TabItem,
	TabContent,
	Ping,
} from "@sembark-travel/ui/base"
import { showSnackbar } from "@sembark-travel/ui/snackbar"
import { Breadcrumbs, Link } from "@sembark-travel/ui/router"
import {
	CopyToClipboardButton,
	CopyToClipboard,
} from "@sembark-travel/ui/copy-to-clipboard"
import { XHRInstance, useXHR } from "@sembark-travel/xhr"
import useSWR from "swr"
import { TFBIntegratedApp, TFBPageLead, XHR } from "./store"
import { generatePath, useNavigate } from "../router-utils"
import { Dialog, useDialog } from "@sembark-travel/ui/dialog"
import { Required } from "utility-types"
import { TIntegration } from "./store"
import { CursorListView, TSearchParams } from "@sembark-travel/ui/list"
import { Fragment, useMemo, useState } from "react"
import { logError } from "@sembark-travel/logging"
import { PlatformIcon } from "./PlatformIcon"
import {
	dateToUTCString,
	startOf,
	subtractUnit,
} from "@sembark-travel/datetime-utils"
import {
	Form,
	SubmissionError,
	withServerErrors,
} from "@sembark-travel/ui/form"
import pluralize from "pluralize"

export function IntegrationItem({
	lastCreatedToken,
	integrationId,
	setLastCreatedToken,
}: {
	lastCreatedToken?: string
	integrationId: string | number
	setLastCreatedToken: (token: string | undefined) => void
}) {
	const xhr = useXHR()
	const { data, error, mutate } = useSWR(
		`/api/integrations/${integrationId}`,
		() => XHR(xhr).show(integrationId)
	)
	const navigate = useNavigate()
	if (!data) {
		if (!error) {
			return <Spinner alignCenter padding="8" />
		}
		return (
			<Alert status="error">{error.message || "Something went wrong"}</Alert>
		)
	}
	return (
		<>
			<Breadcrumbs
				title="Integrations"
				items={[
					[generatePath("/org/integrations"), "Integrations"],
					["", data.name],
				]}
				actions={
					<Inline gap="4">
						{/* <ButtonLink
							to={generatePath("/integrations/:integrationId/edit", { integrationId: data.uid })}
							size="sm"
						>
							<Icons.Pencil /> Edit
						</ButtonLink> */}
						<Dropdown alignRight>
							<Dropdown.ToggleButton size="sm" level="tertiary">
								<Icons.DotsVertical />
							</Dropdown.ToggleButton>
							<Dropdown.Menu>
								<Dropdown.MenuItem
									color="danger"
									onClick={async () => {
										if (
											window.confirm(`Any applications or scripts using this integration will no longer be able to access the Sembark API. You cannot undo this action.

Are you sure to delete this token ?`)
										) {
											const message = await XHR(xhr).destroy(data.id)
											showSnackbar(
												message || "Integration deleted successfully"
											)
											navigate("/org/integrations")
										}
									}}
								>
									<Icons.Trash /> Delete
								</Dropdown.MenuItem>
							</Dropdown.Menu>
						</Dropdown>
					</Inline>
				}
			/>
			<Container paddingY="8" bgColor="default">
				<Stack gap="2">
					<Heading as="h2" fontSize="base" color="muted">
						Overview
					</Heading>
					<Text fontSize="lg" fontWeight="semibold">
						{data.name}
					</Text>
					<Text>{data.description}</Text>
				</Stack>
				<Divider />
				{data.platform === "meta" ? (
					isFBIntegration(data) ? (
						<Box>
							<FacebookIntegratedApp integration={data} />
						</Box>
					) : null
				) : data.platform === "google_ads" ? (
					<Stack gap="4">
						<Inline gap="4">
							<PlatformIcon platform="google_ads" size="32" />
							<Stack>
								<Heading as="h4">Google Ads Lead Form</Heading>
								<Text color="muted">
									Receive leads directly from Google Ads Lead Form into
									Dashboard
								</Text>
							</Stack>
						</Inline>
						<Text>
							Please follow the instructions from{" "}
							<Text
								as="a"
								href="https://developers.google.com/google-ads/webhook/docs/testing"
								target="_blank"
								rel="noopener noreferrer"
								color="accent"
							>
								official guidelines <Icons.ArrowTopRightOnSquare />
							</Text>{" "}
							on how to setup Webhook for Lead Forms on Google Ads.
						</Text>
						{data.google_ads_lead_form_webhook_config ? (
							<Inline gap="4" flexWrap="wrap">
								<CopyToClipboard<HTMLDivElement>>
									{({ nodeToCopy, copyNodeTextToClipboard }) => (
										<Stack gap="1">
											<Text fontWeight="semibold">Webhook URL</Text>
											<Inline
												gap="2"
												alignItems="center"
												paddingY="1"
												paddingLeft="4"
												paddingRight="1"
												borderWidth="1"
												rounded="md"
												bgColor="subtle"
											>
												<Box ref={nodeToCopy}>
													{data.google_ads_lead_form_webhook_config?.url}
												</Box>
												<CopyToClipboardButton
													size="sm"
													onClick={async () => {
														return copyNodeTextToClipboard()
													}}
												>
													<Icons.ClipboardCopy />
												</CopyToClipboardButton>
											</Inline>
										</Stack>
									)}
								</CopyToClipboard>
								<CopyToClipboard<HTMLDivElement>>
									{({ nodeToCopy, copyNodeTextToClipboard }) => (
										<Stack gap="1">
											<Text fontWeight="semibold">Key</Text>
											<Inline
												gap="2"
												alignItems="center"
												paddingY="1"
												paddingLeft="4"
												paddingRight="1"
												borderWidth="1"
												rounded="md"
												bgColor="subtle"
											>
												<Box ref={nodeToCopy}>
													{data.google_ads_lead_form_webhook_config?.key}
												</Box>
												<CopyToClipboardButton
													size="sm"
													onClick={async () => {
														return copyNodeTextToClipboard()
													}}
												>
													<Icons.ClipboardCopy />
												</CopyToClipboardButton>
											</Inline>
										</Stack>
									)}
								</CopyToClipboard>
							</Inline>
						) : null}
					</Stack>
				) : (
					<Stack gap="4">
						<Inline
							justifyContent="between"
							collapseBelow="sm"
							alignItems="center"
						>
							<Heading as="h3">Access Tokens</Heading>
							{!data.tokens?.length && !lastCreatedToken ? (
								<Box>
									<Component initialState={false}>
										{({ state, setState }) => (
											<Button
												level="primary"
												disabled={state}
												onClick={async () => {
													setState(true)
													const { token } = await XHR(xhr).generateToken(
														data.id
													)
													setLastCreatedToken(token)
													mutate()
												}}
											>
												{state ? "Generating..." : "Generate Access Token"}
											</Button>
										)}
									</Component>
								</Box>
							) : null}
						</Inline>
						{lastCreatedToken ? (
							<Alert
								status="success"
								title="Token Created. You should copy it to a safe place."
							>
								<Stack gap="2">
									<CopyToClipboard>
										{({ copyTextToClipboard }) => (
											<Inline gap="2" flexWrap="wrap">
												<Inline
													bgColor="default"
													paddingX="2"
													paddingY="1"
													alignItems="center"
												>
													<Text color="default" fontWeight="semibold">
														{lastCreatedToken}
													</Text>
												</Inline>
												<CopyToClipboardButton
													onClick={() => copyTextToClipboard(lastCreatedToken)}
													title="Copy to Clipboard"
													size="sm"
													level="primary"
												>
													<Icons.Duplicate />
												</CopyToClipboardButton>
											</Inline>
										)}
									</CopyToClipboard>
									<Text>
										Make sure to copy your access token now. You won't be able
										to see it again!
									</Text>
								</Stack>
							</Alert>
						) : null}
						{!data.tokens?.length ? (
							<Box padding="6" textAlign="center" borderWidth="1" rounded="md">
								<Text>No access tokens generated for this integration.</Text>
							</Box>
						) : (
							<Stack gap="4">
								<Alert
									title="Never expose your Tokens publicly"
									status="warning"
								>
									<Text>
										Though these tokens have limited access permissions, if
										someone gets your token, they can access Sembark APIs as if
										your organization is accessing them.
									</Text>
								</Alert>
								<Text>
									Use these tokens to access{" "}
									<Text
										as="a"
										href="https://sembark.com/travel-software/apis"
										target="_blank"
										rel="noopener noreferrer"
										color="accent"
									>
										Sembark APIs
									</Text>{" "}
									and integrate your workflows.
								</Text>
								<Table
									headers={["Name", "Last Used", ""]}
									alignCols={{ 2: "right" }}
									responsive
									bordered
									hover
									rows={data.tokens?.map((item) => [
										"**************",
										item.last_used_at ? (
											<RelativeTime timestamp={item.last_used_at} />
										) : (
											"Never used"
										),
										<Button
											status="danger"
											level="secondary"
											size="sm"
											onClick={async () => {
												if (
													window.confirm(`Any applications or scripts using this token will no longer be able to access the Sembark API. You cannot undo this action.

Are you sure to delete this token ?`)
												) {
													setLastCreatedToken(undefined)
													try {
														const message = await XHR(xhr).destroyToken(
															data.id,
															item.id
														)
														showSnackbar(
															message || "Token deleted successfully"
														)
														mutate()
													} catch (e) {
														const error = e as Error
														alert(
															error.message ||
																"Something went wrong. Please try after some time."
														)
													}
												}
											}}
										>
											Delete
										</Button>,
									])}
								/>
							</Stack>
						)}
					</Stack>
				)}
			</Container>
		</>
	)
}

type TFBIntegration = Required<
	Omit<TIntegration, "integrated_app_type">,
	"integrated_app_id"
> & { integrated_app_type: "fb_integrated_apps" }

function isFBIntegration(
	integration: TIntegration
): integration is TFBIntegration {
	return Boolean(
		integration.integrated_app_id &&
			integration.integrated_app_type === "fb_integrated_apps"
	)
}

function FacebookIntegratedApp({
	integration,
}: {
	integration: TFBIntegration
}) {
	const xhr = useXHR()
	const { integrated_app_id: integratedAppId } = integration
	const { data, error, isValidating } = useSWR(
		`/api/fb/${integratedAppId || -1}`,
		() =>
			xhr
				.get<{
					data: TFBIntegratedApp
					meta: {
						exception?: Record<string, unknown>
						is_custom_app: boolean
						auth_url: string
						reauth_url: string
						valid_auth_callback_url: string
						app_uninstall_callback: string
						app_data_deletion_callback: string
						webhook_client_url: string
					}
				}>(`integrations/fb/${integratedAppId}`)
				.then((resp) => resp.data),
		{ revalidateOnFocus: true }
	)
	if (!data) {
		if (error) {
			return (
				<Alert status="error">
					{error?.message || "Something went wrong. Please try after sometime."}
				</Alert>
			)
		}
		return <Spinner alignCenter padding="4" />
	}

	const {
		data: integratedApp,
		meta: {
			auth_url,
			reauth_url,
			is_custom_app,
			valid_auth_callback_url,
			webhook_client_url,
			app_uninstall_callback,
			app_data_deletion_callback,
			exception,
		},
	} = data

	return (
		<Stack gap="8" cursor={isValidating ? "wait" : undefined}>
			<Inline justifyContent="between">
				<Inline gap="4">
					<Box paddingTop="1">
						<PlatformIcon platform="meta" size="36" />
					</Box>
					<Stack gap="1">
						<Heading>
							Facebook Integration{" "}
							{isValidating ? <Icons.Refresh spin /> : null}
						</Heading>
						<Text color="muted" fontSize="sm">
							{joinAttributes(`App ID: ${integratedApp.app_id}`)}
						</Text>
					</Stack>
				</Inline>
				<Inline gap="4">
					{is_custom_app ? (
						<Component initialState={false}>
							{({ state, setState }) => (
								<>
									<Button
										onClick={() => setState(true)}
										level="secondary"
										status="primary"
									>
										Configurations
									</Button>
									<Dialog
										open={state}
										onClose={() => setState(false)}
										title="Facebook App Configurations"
										lg
									>
										<Dialog.Body paddingX="0" paddingY="0">
											<Component<
												| "basic"
												| "webhook"
												| "login"
												| "live"
												| "connect"
												| "test"
											> initialState="basic">
												{({ state, setState }) => (
													<Tabs layout="row" collapseBelowXs>
														<TabsList>
															<TabItem
																active={state === "basic"}
																onClick={() => setState("basic")}
															>
																<Stack gap="1">
																	<Text fontSize="xs" textTransform="uppercase">
																		Step 1
																	</Text>
																	<Text>Basic Settings</Text>
																</Stack>
															</TabItem>
															<TabItem
																active={state === "webhook"}
																onClick={() => setState("webhook")}
															>
																<Stack gap="1">
																	<Text fontSize="xs" textTransform="uppercase">
																		Step 2
																	</Text>
																	<Text>Webhook</Text>
																</Stack>
															</TabItem>
															<TabItem
																active={state === "login"}
																onClick={() => setState("login")}
															>
																<Stack gap="1">
																	<Text fontSize="xs" textTransform="uppercase">
																		Step 3
																	</Text>
																	<Text>FB Login</Text>
																</Stack>
															</TabItem>
															<TabItem
																active={state === "live"}
																onClick={() => setState("live")}
															>
																<Stack gap="1">
																	<Text fontSize="xs" textTransform="uppercase">
																		Step 4
																	</Text>
																	<Text>Live Mode</Text>
																</Stack>
															</TabItem>
															<TabItem
																active={state === "connect"}
																onClick={() => setState("connect")}
															>
																<Stack gap="1">
																	<Text fontSize="xs" textTransform="uppercase">
																		Step 5
																	</Text>
																	<Text>Connect</Text>
																</Stack>
															</TabItem>
															<TabItem
																active={state === "test"}
																onClick={() => setState("test")}
															>
																<Stack gap="1">
																	<Text fontSize="xs" textTransform="uppercase">
																		Step 6
																	</Text>
																	<Text>Test Leads</Text>
																</Stack>
															</TabItem>
														</TabsList>
														<TabContent active={state === "basic"} padding="6">
															<Stack gap="4">
																<Text fontSize="md">
																	Webhook allows Real-Time notifications of new
																	Leads from Facebook.
																</Text>
																<Text color="muted">
																	Please follow these instructions to setup
																	webhook in your application.
																</Text>
																<Stack
																	gap="2"
																	as="ol"
																	listStyleType="decimal"
																	paddingLeft="4"
																>
																	<Box as="li">
																		Visit your{" "}
																		<Text
																			as="a"
																			href={`https://developers.facebook.com/apps/${integratedApp.app_id}/`}
																			target="_blank"
																			rel="noopener noreferrer"
																			color="accent"
																		>
																			App's Dashboard{" "}
																			<Icons.ArrowTopRightOnSquare />
																		</Text>
																	</Box>
																	<Box as="li">
																		Open the <b>Settings &gt; Basic</b> from the
																		sidebar navigation.
																	</Box>
																	<Box as="li">
																		<Stack gap="2">
																			<Text>
																				Update following fields with their
																				respective values.
																			</Text>
																			<Stack gap="1">
																				<Text fontWeight="semibold">
																					App Domain
																				</Text>
																				<CopyToClipboardTextInput
																					value={window.location.host}
																				/>
																			</Stack>
																			<Stack gap="1">
																				<Text fontWeight="semibold">
																					Privacy Policy URL
																				</Text>
																				<CopyToClipboardTextInput
																					value={
																						"https://sembark.com/legal/privacy/"
																					}
																				/>
																			</Stack>
																			<Stack gap="1">
																				<Text fontWeight="semibold">
																					Terms of Service URl
																				</Text>
																				<CopyToClipboardTextInput
																					value={
																						"https://sembark.com/legal/terms/"
																					}
																				/>
																			</Stack>
																		</Stack>
																	</Box>
																	<Box as="li">
																		Finally click on <mark>Save changes</mark>.
																	</Box>
																</Stack>
																<Box paddingTop="4">
																	<Button
																		onClick={() => setState("webhook")}
																		status="primary"
																	>
																		Next: Webhook{" "}
																		<Icons.ChevronDown rotate="270" />
																	</Button>
																</Box>
															</Stack>
														</TabContent>
														<TabContent
															active={state === "webhook"}
															padding="6"
														>
															<Stack gap="4">
																<Text fontSize="md">
																	Webhook allows Real-Time notifications of new
																	Leads from Facebook.
																</Text>
																<Text color="muted">
																	Please follow these instructions to setup
																	webhook in your application.
																</Text>
																<Stack
																	gap="2"
																	as="ol"
																	listStyleType="decimal"
																	paddingLeft="4"
																>
																	<Box as="li">
																		Visit your{" "}
																		<Text
																			as="a"
																			href={`https://developers.facebook.com/apps/${integratedApp.app_id}/`}
																			target="_blank"
																			rel="noopener noreferrer"
																			color="accent"
																		>
																			App's Dashboard{" "}
																			<Icons.ArrowTopRightOnSquare />
																		</Text>
																	</Box>
																	<Box as="li">
																		Click on <mark>Add Product</mark> from left
																		sidebar
																	</Box>
																	<Box as="li">
																		From the list of Products, select the{" "}
																		<b>Webhooks</b> product and click{" "}
																		<mark>Set up</mark>
																	</Box>
																	<Box as="li">
																		Next, select the <mark>Page</mark> from the
																		dropdown instead of `User` and click on{" "}
																		<mark>Subscribe to this object</mark>.
																	</Box>
																	<Box as="li">
																		<Stack gap="2">
																			<Text>
																				Use the following details for{" "}
																				<b>Callback URL</b> and{" "}
																				<b>Verify Token</b>.
																			</Text>
																			<Stack gap="1">
																				<Text fontWeight="semibold">
																					Callback URL
																				</Text>
																				<CopyToClipboardTextInput
																					value={webhook_client_url}
																				/>
																			</Stack>
																			<Stack gap="1">
																				<Text fontWeight="semibold">
																					Verify Token
																				</Text>
																				<CopyToClipboardTextInput
																					value={integratedApp.webhook_token}
																				/>
																			</Stack>
																			<Text>
																				The Webhook URL will be verified and
																				saved successfully.
																			</Text>
																		</Stack>
																	</Box>
																	<Box as="li">
																		Finally, search for <b>leadgen</b> field and
																		click on <mark>Subscribe</mark> to receive
																		Real-Time leadgen notifications.
																	</Box>
																</Stack>
																<Box paddingTop="4">
																	<Button
																		onClick={() => setState("login")}
																		status="primary"
																	>
																		Next: FB Login{" "}
																		<Icons.ChevronDown rotate="270" />
																	</Button>
																</Box>
															</Stack>
														</TabContent>
														<TabContent active={state === "login"} padding="6">
															<Stack gap="4">
																<Text fontSize="md">
																	Facebook Login is necessary for Leads
																	retrieval with full details from your managed
																	Facebook Pages.
																</Text>
																<Text color="muted">
																	Please follow these instructions to setup
																	Facebook Login in your application.
																</Text>
																<Stack
																	gap="2"
																	as="ol"
																	listStyleType="decimal"
																	paddingLeft="4"
																>
																	<Box as="li">
																		Visit your{" "}
																		<Text
																			as="a"
																			href={`https://developers.facebook.com/apps/${integratedApp.app_id}/`}
																			target="_blank"
																			rel="noopener noreferrer"
																			color="accent"
																		>
																			App's Dashboard{" "}
																			<Icons.ArrowTopRightOnSquare />
																		</Text>
																	</Box>
																	<Box as="li">
																		Click on <mark>Add Product</mark> from left
																		sidebar
																	</Box>
																	<Box as="li">
																		From the list of Products, select the{" "}
																		<b>Facebook Login</b> product and click{" "}
																		<mark>Set up</mark>
																	</Box>
																	<Box as="li">
																		<Stack gap="2">
																			<Text>
																				Now update the following fields with
																				their respective values in the{" "}
																				<b>Settings</b> of your Facebook Login.
																			</Text>
																			<Stack gap="2">
																				<Stack gap="1">
																					<Text fontWeight="semibold">
																						Valid OAuth Redirect URIs
																					</Text>
																					<CopyToClipboardTextInput
																						value={valid_auth_callback_url}
																					/>
																				</Stack>
																				<Stack gap="1">
																					<Text fontWeight="semibold">
																						Deauthorize callback URL
																					</Text>
																					<CopyToClipboardTextInput
																						value={app_uninstall_callback}
																					/>
																				</Stack>
																				<Stack gap="1">
																					<Text fontWeight="semibold">
																						Data Deletion Request URL
																					</Text>
																					<CopyToClipboardTextInput
																						value={app_data_deletion_callback}
																					/>
																				</Stack>
																			</Stack>
																		</Stack>
																	</Box>
																	<Box as="li">
																		After setting these field-values, click on{" "}
																		<b>Save Changes</b>.
																	</Box>
																</Stack>
																<Box paddingTop="4">
																	<Button
																		onClick={() => setState("live")}
																		status="primary"
																	>
																		Next: Live Mode{" "}
																		<Icons.ChevronDown rotate="270" />
																	</Button>
																</Box>
															</Stack>
														</TabContent>
														<TabContent active={state === "live"} padding="6">
															<Stack gap="4">
																<Text fontSize="md">
																	Before we can use our application, it must be
																	in the <mark>Live</mark> mode.
																</Text>
																<Text color="muted">
																	To make your application live, follow these
																	instructions.
																</Text>
																<Stack
																	gap="2"
																	as="ol"
																	listStyleType="decimal"
																	paddingLeft="4"
																>
																	<Box as="li">
																		Visit your{" "}
																		<Text
																			as="a"
																			href={`https://developers.facebook.com/apps/${integratedApp.app_id}/`}
																			target="_blank"
																			rel="noopener noreferrer"
																			color="accent"
																		>
																			App's Dashboard{" "}
																			<Icons.ArrowTopRightOnSquare />
																		</Text>
																	</Box>
																	<Box as="li">
																		Click on the toggle button next to{" "}
																		<b>"App Mode:"</b> to change it to{" "}
																		<mark>Live</mark>. Please wait as it
																		switches the application mode.
																	</Box>
																	<Box as="li">
																		We are now ready to connect our application
																		and test the integration.
																	</Box>
																</Stack>
																<Box paddingTop="4">
																	<Button
																		onClick={() => setState("connect")}
																		status="primary"
																	>
																		Next: Connect Apps{" "}
																		<Icons.ChevronDown rotate="270" />
																	</Button>
																</Box>
															</Stack>
														</TabContent>
														<TabContent
															active={state === "connect"}
															padding="6"
														>
															{!integratedApp.user_access_token_issued_at ? (
																<Stack gap="4" alignItems="center">
																	<Text fontSize="lg">
																		Now, let's Connect your Facebook App with
																		Sembark
																	</Text>
																	<Alert status="warning">
																		<Text>
																			All earlier steps MUST be completed before
																			connecting.
																		</Text>
																	</Alert>
																	<Button
																		as="a"
																		href={auth_url}
																		size="lg"
																		target="_blank"
																		level="primary"
																		rel="noreferrer noopener"
																	>
																		Connect with Facebook
																	</Button>
																	<Text>
																		Please follow the screens in the workflow on
																		Facebook and select the Pages when
																		requested.
																	</Text>
																</Stack>
															) : (
																<Stack gap="4" alignItems="center">
																	<Box>
																		<Icons.OkCircleSolid
																			color="success"
																			size="12"
																		/>
																	</Box>
																	<Text
																		fontSize="lg"
																		color="success"
																		fontWeight="semibold"
																	>
																		Connected with your Facebook Application.
																	</Text>
																	{!integratedApp.pages?.length ? (
																		<Alert
																			status="error"
																			title="No Authorized Pages"
																		>
																			<Stack gap="4">
																				<Text>
																					The application is connected but there
																					are no pages which have been
																					authorized.
																				</Text>
																				<Box>
																					<Button
																						as="a"
																						href={auth_url}
																						size="lg"
																						target="_blank"
																						level="primary"
																						rel="noreferrer noopener"
																					>
																						Reconnect with Facebook
																					</Button>
																				</Box>
																			</Stack>
																		</Alert>
																	) : (
																		<>
																			<Text color="muted">
																				Now, test if the connection is working
																				as expected.
																			</Text>
																			<Box paddingTop="4">
																				<Button
																					onClick={() => setState("test")}
																					status="primary"
																				>
																					Next: Test Integration{" "}
																					<Icons.ChevronDown rotate="270" />
																				</Button>
																			</Box>
																		</>
																	)}
																</Stack>
															)}
														</TabContent>
														<TabContent active={state === "test"} padding="6">
															{!integratedApp.user_access_token_issued_at ? (
																<Alert
																	status="error"
																	title="Facebook App Not Connected"
																>
																	<Stack gap="4">
																		<Text color="danger">
																			Your Facebook App is not connected yet.
																			Please connect before testing the
																			integration.
																		</Text>
																		<Box>
																			<Button
																				onClick={() => setState("connect")}
																				status="primary"
																			>
																				<Icons.ChevronDown rotate="90" />
																				Prev: Connect App
																			</Button>
																		</Box>
																	</Stack>
																</Alert>
															) : !integratedApp.pages?.length ? (
																<Alert status="error" title="No Pages Found">
																	<Stack gap="4">
																		<Text>
																			Your Facebook App is connected but there
																			are no pages which were authorized. Please
																			try to reconnect the App and select the
																			Pages when requested.
																		</Text>
																		<Box>
																			<Button
																				onClick={() => setState("connect")}
																				status="primary"
																			>
																				<Icons.ChevronDown rotate="90" />
																				Prev: Reconnect App
																			</Button>
																		</Box>
																	</Stack>
																</Alert>
															) : (
																<Stack gap="4">
																	<Text fontSize="md">
																		Using the <b>Lead Ads Testing Tool</b> by
																		Facebook, we can test if the leads
																		integration with CRM.
																	</Text>
																	<Text color="muted">
																		Please follow these instructions for
																		testing.
																	</Text>
																	<Stack
																		gap="2"
																		as="ol"
																		listStyleType="decimal"
																		paddingLeft="4"
																	>
																		<Box as="li">
																			Open{" "}
																			<Text
																				as="a"
																				href={`https://developers.facebook.com/tools/lead-ads-testing/`}
																				target="_blank"
																				rel="noopener noreferrer"
																				color="accent"
																			>
																				Lead Ads Testing Tool{" "}
																				<Icons.ArrowTopRightOnSquare />
																			</Text>
																			.
																		</Box>
																		<Box as="li">
																			You should see your <b>Pages</b> and their
																			corresponding <b>Lead Forms</b> in the
																			dropdown menus.
																		</Box>
																		<Box as="li">
																			From the <mark>Pages</mark> dropdown,
																			select any of the follwing page(s):{" "}
																			{integratedApp.pages.map((page) => (
																				<b key={page.page_id}>{page.name}</b>
																			))}
																		</Box>
																		<Box as="li">
																			Now, you should see the{" "}
																			<b>App ID : {integratedApp.app_id}</b> in
																			the webhook subscriptions list and{" "}
																			<b>leadgen</b> in the{" "}
																			<mark>Subscribed Fields</mark>. If it's
																			not, then you must reconnect the app and
																			ensure that webhooks step was completed.
																		</Box>
																		<Box as="li">
																			<Stack gap="2">
																				<Text>
																					Now let's submit Test Lead Form and
																					the verify the delivery.
																				</Text>
																				<Stack
																					gap="2"
																					as="ol"
																					listStyleType="decimal"
																					paddingLeft="4"
																				>
																					<Box as="li">
																						First, click on{" "}
																						<mark>Delete lead</mark> to delete
																						any test lead. Also, please ignore
																						the warnings if any.
																					</Box>
																					<Box as="li">
																						Next, click on{" "}
																						<mark>Create lead</mark>. It will
																						fill the selected <b>Form</b> with
																						test data and will send the leads to
																						the software. You can also click on{" "}
																						<mark>Preview Form</mark> and
																						fill-out the test data by yourself.
																					</Box>
																					<Box as="li">
																						Now click on{" "}
																						<mark>Track Status</mark>. You
																						should see the{" "}
																						<b>Status: success</b> after few
																						clicks on this button. It means, we
																						are receiving leads from the
																						selected Facebook Page.
																					</Box>
																					<Box as="li">
																						<b>
																							Let's check the leads in our
																							Sembark Dashboard
																						</b>
																						. Open the{" "}
																						<Link
																							to={`/trip-plan-requests?source=${encodeURIComponent(
																								integration.name
																							)}`}
																							color="accent"
																						>
																							Trip Plan Requests
																						</Link>{" "}
																						page. Here, you should see the test
																						lead that we send from the Facebook
																						Page.
																					</Box>
																				</Stack>
																			</Stack>
																		</Box>
																	</Stack>
																</Stack>
															)}
														</TabContent>
													</Tabs>
												)}
											</Component>
										</Dialog.Body>
										<Dialog.Footer>
											<Button onClick={() => setState(false)}>Done</Button>
										</Dialog.Footer>
									</Dialog>
								</>
							)}
						</Component>
					) : null}
					{integratedApp.user_access_token_issued_at ? (
						<Dropdown alignRight>
							<Dropdown.ToggleButton level="tertiary">
								<Icons.DotsVertical />
							</Dropdown.ToggleButton>
							<Dropdown.Menu>
								<li>
									<a
										href={reauth_url}
										target="_blank"
										rel="noreferrer noopener"
									>
										<Icons.SwitchHorizontal /> Reconnect Facebook Account
									</a>
								</li>
							</Dropdown.Menu>
						</Dropdown>
					) : null}
				</Inline>
			</Inline>
			{exception ? (
				<Stack gap="4">
					<Stack gap="1">
						<Heading fontSize="md" color="danger">
							<Icons.Attention size="6" /> Connection Failure{" "}
							<Ping color="danger" />
						</Heading>
						<Text color="muted">
							Something went wrong when connecting with your facebook account /
							pages. Please try to reconnect with your account to fix any
							issues.
						</Text>
					</Stack>
					<Box>
						<Button
							as="a"
							href={reauth_url}
							target="_blank"
							rel="noreferrer noopener"
							level="primary"
						>
							<Icons.SwitchHorizontal /> Reconnect Facebook Account
						</Button>
					</Box>
					<JSONPreview
						data={exception}
						bgColor="danger"
						color="danger"
						borderColor="danger"
					/>
				</Stack>
			) : null}
			{!integratedApp.user_access_token_issued_at ? (
				<Stack gap="2">
					<Text>
						Connect your Facebook Pages to bring Leads from Ads, directly into
						your Sembark Dashboard.
					</Text>
					<Box>
						<Button
							as="a"
							href={auth_url}
							size="lg"
							target="_blank"
							level="primary"
							rel="noreferrer noopener"
						>
							Connect with Facebook
						</Button>
					</Box>
				</Stack>
			) : (
				<Stack gap="4">
					<Heading as="h4" fontSize="md">
						Pages
					</Heading>
					{!integratedApp.pages?.length ? (
						<Text>
							Looks like you have not selected any pages during authorization.
						</Text>
					) : (
						<Stack gap="4">
							<Text color="muted">
								Here are the pages which has been subscribed for Leads
							</Text>
							<Table
								bordered
								headers={["Page", "Subscribed At", ""]}
								alignCols={{ 2: "right" }}
								rows={integratedApp.pages.map((page) => [
									<Stack gap="1">
										<Text>{page.name}</Text>
										<Box>
											<Box
												as="a"
												href={`https://www.facebook.com/${page.page_id}/`}
												target="_blank"
												rel="noopener noreferrer"
												color="accent"
												display="inlineFlex"
												gap="2"
												alignItems="center"
												fontSize="sm"
											>
												{page.page_id} <Icons.ArrowTopRightOnSquare />
											</Box>
										</Box>
									</Stack>,
									page.subscribed_at ? (
										<Stack gap="1">
											<Box>
												<Time timestamp={page.subscribed_at} />
											</Box>
											{page.forms_count ? (
												<Text color="muted" fontSize="sm">
													{pluralize("Form", page.forms_count, true)}
												</Text>
											) : null}
										</Stack>
									) : (
										"Not Subscribed"
									),
									page.exception ? (
										<JSONPreviewInDialog
											data={page.exception}
											bgColor="danger"
											color="danger"
											borderColor="danger"
											title="Error in the Page Integration"
										>
											{({ preview }) => (
												<Button
													onClick={() => preview()}
													level="tertiary"
													status="danger"
													size="sm"
												>
													<Icons.AttentionSolid />
												</Button>
											)}
										</JSONPreviewInDialog>
									) : null,
								])}
							/>
						</Stack>
					)}
				</Stack>
			)}
			<Stack gap="4">
				<Stack gap="2">
					<Inline gap="4" justifyContent="between">
						<Stack gap="2">
							<Heading as="h4" fontSize="md">
								Leads
							</Heading>
							<Text color="muted">
								Here are the leads attached from this integration.
							</Text>
						</Stack>
						{!exception ? (
							<Component<undefined | "sync-leads"> initialState={undefined}>
								{({ state, setState: setActiveMenu }) => (
									<>
										<Dropdown alignRight>
											<Dropdown.ToggleButton level="tertiary">
												<Icons.DotsVertical />
											</Dropdown.ToggleButton>
											<Dropdown.Menu>
												<Dropdown.MenuItem
													onClick={() => {
														setActiveMenu("sync-leads")
													}}
												>
													Refetch (Last 7 Days)
												</Dropdown.MenuItem>
											</Dropdown.Menu>
										</Dropdown>

										<Dialog
											open={state === "sync-leads"}
											title="Request for Refetch Leads"
											onClose={() => setActiveMenu(undefined)}
										>
											<Dialog.Body>
												<Form<object>
													initialValues={{}}
													onSubmit={withServerErrors(async () => {
														await xhr.post(
															`fb-page-sync-leads/${integration.uid}`,
															{
																created_after: dateToUTCString(
																	startOf(
																		subtractUnit(new Date(), 7, "days"),
																		"day"
																	)
																),
															}
														)
														window.alert(
															"Please check back after sometime for the updated leads."
														)
														setActiveMenu(undefined)
													})}
													subscription={{ submitting: true }}
												>
													{({ handleSubmit, submitting }) => (
														<form onSubmit={handleSubmit}>
															<Stack gap="4">
																<Text>
																	This will initiate a background process to
																	fetch the leads for this integration which
																	created after{" "}
																	<Time
																		fontWeight="semibold"
																		value={startOf(
																			subtractUnit(new Date(), 7, "days"),
																			"day"
																		)}
																	/>
																	.
																</Text>
																<Text>
																	If a lead was not fetched earlier or had an
																	error in fetching, it will be refetched and a
																	new lead will be created, otherwise no action
																	will be taken.
																</Text>
																<Divider sm />
																<SubmissionError />
																<Inline gap="4">
																	<Button type="submit" disabled={submitting}>
																		{submitting
																			? "Requesting..."
																			: "Request Refetch"}
																	</Button>
																	<Button
																		onClick={() => setActiveMenu(undefined)}
																	>
																		Cancel
																	</Button>
																</Inline>
															</Stack>
														</form>
													)}
												</Form>
											</Dialog.Body>
										</Dialog>
									</>
								)}
							</Component>
						) : null}
					</Inline>
					<Text color="muted" fontSize="sm">
						Only meant to be used for testing and counting. Visit{" "}
						<Link
							to={`/trip-plan-requests?source=${encodeURIComponent(
								integration.name
							)}`}
							color="accent"
						>
							Trip Plan Request
						</Link>{" "}
						modules for full details.
					</Text>
				</Stack>
				<Component<TSearchParams> initialState={{ cursor: null }}>
					{({ state: params, setState: setParams }) => (
						<CursorListView<TFBPageLead>
							pageKey={`${integration.uid}-fb-page-leads`}
							params={params}
							removeContainerPadding
							swrConfig={{ revalidateOnFocus: true }}
							onCursorChange={(cursor) => setParams({ ...params, cursor })}
							fetch={(xhr, params) =>
								xhr
									.get(`/fb-page-leads`, {
										params: {
											...params,
											integration_uid: integration.uid,
										},
									})
									.then((resp) => resp.data)
							}
						>
							{({ items, refresh }) => (
								<Table
									bordered
									hover
									responsive
									headers={["Date-Time", "Lead ID", "Page ID", ""]}
									alignCols={{ 3: "right" }}
									rows={items.map((lead) => [
										<RelativeTime timestamp={lead.created_at} />,
										<Link
											to={`/trip-plan-requests?q=${encodeURIComponent(
												"FBL" + lead.leadgen_id
											)}`}
											color="accent"
										>
											{lead.leadgen_id}
										</Link>,
										lead.page_id,
										<Component initialState={false}>
											{({ state, setState }) => (
												<Fragment key={lead.leadgen_id}>
													<Button
														title="Show Details"
														onClick={() => setState(true)}
														size="sm"
														level="tertiary"
													>
														{lead.exception ? (
															<Icons.AttentionSolid
																color="danger"
																title="Some error in fetching this lead."
															/>
														) : !lead.fetched_at ? (
															<Icons.Refresh spin />
														) : (
															<Icons.ArrowExpand />
														)}
													</Button>
													<Dialog
														open={state}
														onClose={() => setState(false)}
														title="Lead Details from Facebook"
														key={lead.id}
													>
														<Dialog.Body>
															<Stack gap="4">
																<Stack gap="1">
																	<Text fontWeight="semibold">Overview</Text>
																	<Inline
																		gap="4"
																		padding="2"
																		borderWidth="1"
																		bgColor="inset"
																		rounded="md"
																		flexWrap="wrap"
																	>
																		<Stack gap="1">
																			<Text fontSize="sm" color="muted">
																				Page ID
																			</Text>
																			<Text>{lead.page_id}</Text>
																		</Stack>
																		<Stack gap="1">
																			<Text fontSize="sm" color="muted">
																				Leadgen ID
																			</Text>
																			<Text>{lead.leadgen_id}</Text>
																		</Stack>
																		<Stack gap="1">
																			<Text fontSize="sm" color="muted">
																				Form ID
																			</Text>
																			<Text>{lead.form_id}</Text>
																		</Stack>
																	</Inline>
																</Stack>
																{lead.exception ? (
																	<Stack gap="1">
																		<Inline gap="1">
																			<Text fontWeight="semibold">
																				Last Exception
																			</Text>
																		</Inline>
																		<JSONPreview
																			data={lead.exception}
																			bgColor="danger"
																			color="danger"
																			borderColor="danger"
																		/>
																		<Box>
																			<Batch onFinish={() => refresh()}>
																				{({ init, xhr, inProgress }) => (
																					<Button
																						status="warning"
																						level="primary"
																						disabled={inProgress}
																						onClick={async () => {
																							init(
																								await xhr
																									.post<{
																										data: TBatch
																									}>(
																										`fb-page-leads/${lead.id}/refetch`
																									)
																									.then(
																										(resp) => resp.data.data
																									)
																							)
																						}}
																					>
																						<Icons.Refresh spin={inProgress} />{" "}
																						Retry
																					</Button>
																				)}
																			</Batch>
																		</Box>
																	</Stack>
																) : null}
																<Stack gap="1">
																	<Inline gap="1">
																		<Text fontWeight="semibold">
																			Leadgen API Response
																		</Text>
																		<Box>
																			(
																			<RelativeTime
																				timestamp={lead.fetched_at}
																				color="muted"
																			/>
																			)
																		</Box>
																	</Inline>
																	<JSONPreview data={lead.fetched_data} />
																</Stack>
																<Stack gap="1">
																	<Inline gap="1">
																		<Text fontWeight="semibold">
																			Webhook Payload
																		</Text>
																		<Box>
																			(
																			<RelativeTime
																				timestamp={lead.webhook_at}
																				color="muted"
																			/>
																			)
																		</Box>
																	</Inline>
																	<JSONPreview data={lead.webhook_payload} />
																</Stack>
															</Stack>
														</Dialog.Body>
														<Dialog.Footer>
															<Button onClick={() => setState(false)}>
																Close
															</Button>
														</Dialog.Footer>
													</Dialog>
												</Fragment>
											)}
										</Component>,
									])}
								/>
							)}
						</CursorListView>
					)}
				</Component>
			</Stack>
		</Stack>
	)
}

function CopyToClipboardTextInput({ value }: { value: string }) {
	return (
		<CopyToClipboard>
			{({ copyNodeTextToClipboard, nodeToCopy }) => (
				<Box
					paddingY="2"
					paddingX="4"
					bgColor="subtle"
					borderWidth="1"
					borderColor="default"
					rounded="md"
					position="relative"
				>
					<Box
						style={{
							maxWidth: "100%",
							overflow: "auto",
						}}
					>
						<Text ref={nodeToCopy as never} whiteSpace="preserve">
							{value}
						</Text>
					</Box>
					<Inline
						position="absolute"
						right="0"
						top="0"
						bottom="0"
						alignItems="center"
						paddingRight="2"
					>
						<CopyToClipboardButton
							onClick={copyNodeTextToClipboard}
							size="sm"
							iconOnly
						>
							<Icons.ClipboardCopy />
						</CopyToClipboardButton>
					</Inline>
				</Box>
			)}
		</CopyToClipboard>
	)
}

function JSONPreviewInDialog({
	children,
	title,
	...props
}: React.ComponentProps<typeof JSONPreview> & {
	children: (props: { preview: () => void }) => React.ReactNode
	title: string
}) {
	const [isOpen, openDialog, closeDialog] = useDialog()
	return (
		<>
			{children({ preview: openDialog })}
			<Dialog open={isOpen} onClose={closeDialog} title={title}>
				<Dialog.Body>
					<JSONPreview {...props} />
				</Dialog.Body>
			</Dialog>
		</>
	)
}

function JSONPreview({
	data,
	...styleProps
}: { data?: Record<string, unknown> } & Pick<
	React.ComponentProps<typeof Box>,
	"bgColor" | "color" | "borderColor"
>) {
	const stringified = useMemo(() => {
		try {
			return JSON.stringify(data || {}, undefined, 2)
				.replace(/\\n/g, "\n")
				.replace(/\\"/g, '"')
		} catch (e) {
			logError(e)
			return undefined
		}
	}, [data])
	return (
		<Box position="relative">
			{stringified ? (
				<Box position="absolute" top="0" right="0" padding="2">
					<CopyToClipboard>
						{({ copyTextToClipboard }) => (
							<CopyToClipboardButton
								size="sm"
								level="tertiary"
								onClick={() => copyTextToClipboard(stringified)}
							>
								<Icons.ClipboardCopy />
							</CopyToClipboardButton>
						)}
					</CopyToClipboard>
				</Box>
			) : null}
			<Box
				as="pre"
				maxWidth="full"
				overflow="auto"
				padding="4"
				rounded="md"
				bgColor="inset"
				borderWidth="1"
				fontFamily="mono"
				style={{ maxHeight: "250px" }}
				{...styleProps}
			>
				{stringified || "Missing Data"}
			</Box>
		</Box>
	)
}

type TBatch = {
	id: string
	name: string
	totalJobs: number
	pendingJobs: number
	processedJobs: number
	progress: number
	failedJobs: number
	createdAt: string
	cancelledAt: string | null
	finishedAt: string | null
}

function Batch({
	children,
	onFinish,
}: {
	onFinish: () => void
	children: (props: {
		init: (batch: TBatch) => void
		xhr: XHRInstance
		inProgress: boolean
	}) => React.ReactNode
}) {
	const [batch, setBatch] = useState<null | TBatch>(null)
	const xhr = useXHR()
	const { data: currentBatch } = useSWR(
		batch ? `/batch/${batch.id}` : null,
		() =>
			xhr
				.get<{ data: TBatch }>(`batches/${batch?.id}`)
				.then((resp) => resp.data.data)
				.then((batch) => {
					if (batch.finishedAt) {
						onFinish()
					}
					return batch
				}),
		{
			refreshInterval: 1000,
		}
	)
	return (
		<>
			{children({
				xhr,
				init: (batch) => setBatch(batch),
				inProgress: Boolean(currentBatch && !currentBatch.finishedAt),
			})}
		</>
	)
}
