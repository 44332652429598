import { Table, Inline } from "@sembark-travel/ui/base"
import { ListView, Search, useSearch } from "@sembark-travel/ui/list"
import { ButtonLink, Link } from "@sembark-travel/ui/router"
import {
	TCabPriceCalculationMetricPreset,
	cabPriceCalculationMetricPresetsXHR,
} from "../../../CabPriceCalculationMetrics"
import { generatePath } from "../../../router-utils"
import { Helmet } from "react-helmet-async"

export default function CabPriceCalculationMetricPresetsPage() {
	const [params, setParams] = useSearch()
	return (
		<>
			<Helmet>Cab Price Calculation Metric Presets</Helmet>
			<Search
				initialParams={params}
				title="Cab Price Metric Presets"
				onSearch={(params) => setParams({ ...params, page: 1 })}
				actions={
					<Inline gap="4">
						<ButtonLink to={generatePath("/cab-price-calculation-metrics")}>
							Metrics
						</ButtonLink>
						<ButtonLink
							to={generatePath("/cab-price-calculation-metric-presets/new")}
							level="primary"
						>
							Add New
						</ButtonLink>
					</Inline>
				}
			>
				{() => (
					<ListView<TCabPriceCalculationMetricPreset>
						pageKey="cab-price-calculation-metric-presets"
						params={params}
						onPageChange={(page) => setParams({ page })}
						fetch={(xhr, params) =>
							cabPriceCalculationMetricPresetsXHR(xhr).get(params)
						}
					>
						{({ items }) => (
							<Table
								headers={["Name", "Metrics"]}
								bordered
								striped
								responsive
								rows={items.map((item) => [
									<Link
										to={generatePath(
											"/cab-price-calculation-metric-presets/:presetId",
											{
												presetId: item.id.toString(),
											}
										)}
										color="accent"
									>
										{item.name}
									</Link>,
									item.metrics.map((m) => m.name).join(", "),
								])}
							/>
						)}
					</ListView>
				)}
			</Search>
		</>
	)
}
