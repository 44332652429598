import { Helmet } from "react-helmet-async"
import { IHotelGroup } from "../../../../HotelGroups"
import { HotelGroupItemBasicDetails } from "../../../../HotelGroups/ItemBasicDetails"
import { useOutletContext } from "react-router-dom"

export default function BasicDetails() {
	const { group } = useOutletContext<{ group: IHotelGroup }>()
	return (
		<>
			<Helmet>
				<title>Hotel Group Details</title>
			</Helmet>
			<HotelGroupItemBasicDetails group={group} />
		</>
	)
}
