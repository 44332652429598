import { TFormattedMoney } from "@sembark-travel/money"
import { ITrip } from "../Trips"
import { useXHR } from "@sembark-travel/xhr"
import useSWR from "swr"
import { queryToSearch } from "@sembark-travel/ui/router"

export type TTripSalesReport = ITrip & {
	is_canceled_between_duration: boolean
	is_converted_between_duration: boolean
	revenue_amount: TFormattedMoney
	tax_amount: TFormattedMoney | null
	flights_amount?: TFormattedMoney
}

export type TTripSalesReportByType = {
	id: number
	name: string
	deleted_at?: string | null
	total_conversion: number
	total_dropped: number
	total_pax: number
	total_queries: number
	total_quotes: number
	total_amount: Array<TFormattedMoney>
	total_profit: Array<TFormattedMoney>
	profit_percentage?: number | null
	conversion_percentage?: number | null
	average_quotes?: number | null
}

export type TStats = {
	total_amount: Array<TFormattedMoney>
	total_conversion: number
	total_dropped: number
	total_queries: number
}

export function useTripSalesReportStats({
	params,
}: {
	params: Record<string, unknown>
}) {
	const xhr = useXHR()
	const { page, ...otherParams } = params
	const { data: stats, error } = useSWR(
		`/trip-sales-reports-stats?${queryToSearch(otherParams)}`,
		() =>
			xhr
				.get<{ data: TStats }>("/trip-sales-reports/stats", {
					params: {
						...params,
					},
				})
				.then((resp) => resp.data.data)
	)
	return { stats, error }
}
