import { Container, Box } from "@sembark-travel/ui/base"
import {
	useGetBackUrlFromLocation,
	Breadcrumbs,
} from "@sembark-travel/ui/router"
import { Helmet } from "react-helmet-async"
import { generatePath, useNavigate } from "../../../router-utils"
import { NewInclusionExclusionPresetItem } from "../../../InclusionExclusionPresets"
import { ForbidUnlessAuthorized, PERMISSIONS } from "../../../Auth"

export default function NewInclusionExclusionPresetItemPage() {
	const navigate = useNavigate()
	const backFromUrl = useGetBackUrlFromLocation()
	const listingPage = generatePath("/org/inclusion-exclusion-presets")
	return (
		<>
			<Helmet>
				<title>New Inc/Exclusion Preset</title>
			</Helmet>
			<ForbidUnlessAuthorized permission={PERMISSIONS.MANAGE_TRIP_OWNERS}>
				<Breadcrumbs
					title="New Inclusion/Exclusion Group"
					items={[
						[listingPage, "Inc/Exclusions"],
						["", "New"],
					]}
				/>
				<Box paddingX={{ md: "4" }} paddingY={{ md: "8" }}>
					<Box borderWidth={{ md: "1" }} rounded="md" bgColor="default">
						<Container paddingY="6">
							<NewInclusionExclusionPresetItem
								onSuccess={(item) =>
									navigate("/inclusion-exclusion-presets/:presetId", {
										params: {
											presetId: item.id.toString(),
										},
										replace: true,
									})
								}
								onCancel={() =>
									backFromUrl
										? navigate(
												backFromUrl as "/org/inclusion-exclusion-presets",
												{ replace: true }
										  )
										: navigate("/org/inclusion-exclusion-presets", {
												replace: true,
										  })
								}
							/>
						</Container>
					</Box>
				</Box>
			</ForbidUnlessAuthorized>
		</>
	)
}
