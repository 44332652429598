import { Config, UsePopperOptions, Modifiers, Obj } from "./popper"

/**
 * Merge a given config with popper configuration with shorthand support
 */
export function mergeOptionsWithPopperConfig({
	enabled,
	enableEvents,
	placement,
	flip,
	offset,
	containerPadding,
	arrowElement,
	popperConfig,
}: Config): UsePopperOptions {
	const modifiers = toModifierMap(popperConfig?.modifiers)
	return {
		...popperConfig,
		placement,
		enabled,
		modifiers: toModifierArray({
			...modifiers,
			eventListeners: {
				enabled: enableEvents,
			},
			preventOverflow: {
				...modifiers["preventOverflow"],
				options: containerPadding
					? {
							padding: containerPadding,
							...modifiers["preventOverflow"]?.options,
					  }
					: modifiers["preventOverflow"]?.options,
			},
			offset: {
				options: {
					offset,
					...modifiers["offset"]?.options,
				},
			},
			arrow: {
				...modifiers["arrow"],
				enabled: !!arrowElement,
				options: {
					...modifiers["arrow"]?.options,
					element: arrowElement,
				},
			},
			flip: {
				enabled: !!flip,
				...modifiers["flip"],
			},
		}),
	}
}

/**
 * Given a modifiers array, create a mapping of modifiers with name as key
 */
function toModifierMap<TName, TOptions extends Obj>(
	modifiers: Modifiers<TName, TOptions> | undefined
) {
	const result: Modifiers<TName, TOptions> = {}
	if (!Array.isArray(modifiers)) {
		return modifiers || result
	}
	modifiers?.forEach((m) => {
		// eslint-disable-next-line
		result[m.name] = m
	})
	return result
}

/**
 * Given a modifiers mapped object, create an array that can be passed to popper options
 */
function toModifierArray<TName, TOptions extends Obj>(
	map: Modifiers<TName, TOptions> | undefined = {}
) {
	if (Array.isArray(map)) return map
	return Object.keys(map).map((k) => {
		map[k].name = k as unknown as TName
		return map[k]
	})
}
