import { Box, Container } from "@sembark-travel/ui/base"
import { Breadcrumbs } from "@sembark-travel/ui/router"
import { EditTravelActivityItem } from "./../../../TravelActivities"
import { Helmet } from "react-helmet-async"
import { generatePath, useNavigate, useParams } from "../../../router-utils"

export default function EditTravelActivityItemPage() {
	const { travelActivityId } = useParams(
		"/travel-activities/:travelActivityId/edit"
	)
	const navigate = useNavigate()
	return (
		<>
			<Helmet>
				<title>Edit Travel Activity</title>
			</Helmet>
			<Breadcrumbs
				title="Edit Travel Activity"
				items={[
					[generatePath("/travel-activities"), "Travel Activities"],
					["", "Edit"],
				]}
			/>
			<Box paddingX="4" paddingY="8">
				<Box maxWidth="6xl" marginX="auto">
					<Box borderWidth="1" rounded="md" bgColor="default">
						<Container paddingY="6">
							<EditTravelActivityItem
								travelActivityId={travelActivityId}
								onSuccess={() =>
									navigate("/travel-activities/:travelActivityId", {
										replace: true,
										params: {
											travelActivityId,
										},
									})
								}
								onCancel={() =>
									navigate("/travel-activities/:travelActivityId", {
										replace: true,
										params: {
											travelActivityId,
										},
									})
								}
							/>
						</Container>
					</Box>
				</Box>
			</Box>
		</>
	)
}
