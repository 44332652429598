import {
	Stack,
	Button,
	Inline,
	Divider,
	Grid,
	Col,
	Heading,
	Text,
	Select,
} from "@sembark-travel/ui/base"
import { showSnackbar } from "@sembark-travel/ui/snackbar"
import { useXHR } from "@sembark-travel/xhr"
import { useRef } from "react"
import { Optional } from "utility-types"
import * as Validator from "yup"
import { TTeam, XHR } from "./store"
import {
	Form,
	validateFormValues,
	SubmissionError,
	withServerErrors,
	TextInputField,
	SelectField,
	GetFieldValue,
} from "@sembark-travel/ui/form"
import { SelectUsers } from "../Users"
import { SelectTripDestination } from "../TripDestinations"

export function AddNewTeam({
	onSuccess,
	...props
}: {
	onSuccess: (team: TTeam) => void
} & Omit<React.ComponentProps<typeof NewTeamForm>, "onSubmit">) {
	const xhr = useXHR()
	return (
		<NewTeamForm
			{...props}
			onSubmit={async (values) => {
				const { data } = await XHR(xhr).store(values)
				onSuccess(data)
				showSnackbar("Team successfully created")
			}}
		/>
	)
}

const validate = validateFormValues(
	Validator.object().shape({
		name: Validator.string()
			.required("Please give a name to your team")
			.max(50, "Please use 50 or fewer characters for the name"),
		users: Validator.array().min(
			1,
			"Please select atleast one memebr to add into the team"
		),
		managers: Validator.array(),
		trip_destinations: Validator.array(),
	})
)

type TNewTeamSchema = {
	name: string
	users: TTeam["users"]
	managers: TTeam["users"]
	trip_destinations: TTeam["trip_destinations"]
}

export function NewTeamForm({
	onSubmit,
	onCancel,
	initialValues: propInitialValues,
}: {
	initialValues?: Optional<TNewTeamSchema>
	onSubmit: (
		values: Omit<TNewTeamSchema, "users" | "trip_destinations" | "managers"> & {
			users: Array<number>
			managers: Array<number>
			trip_destinations: Array<number>
		}
	) => Promise<unknown>
	onCancel: () => void
}) {
	const initialValuesRef = useRef({
		name: propInitialValues?.name || "",
		users: propInitialValues?.users || [],
		managers: propInitialValues?.managers || [],
		trip_destinations: propInitialValues?.trip_destinations || [],
	})
	return (
		<Form<TNewTeamSchema>
			initialValues={initialValuesRef.current}
			validate={validate}
			onSubmit={withServerErrors(async (values) => {
				await onSubmit({
					...values,
					users: values.users?.map((u) => u.id) || [],
					managers: values.managers?.map((u) => u.id) || [],
					trip_destinations: values.trip_destinations?.map((u) => u.id) || [],
				})
			})}
			subscription={{ submitting: true }}
		>
			{({ submitting, handleSubmit }) => (
				<form noValidate onSubmit={handleSubmit}>
					<Stack gap="4">
						<Grid gap="4">
							<Col sm={12} md={4}>
								<Stack gap="1">
									<Heading as="h3">Basic Details</Heading>
									<Text color="muted">Please provide the name of the team</Text>
								</Stack>
							</Col>
							<Col>
								<Stack gap="4">
									<TextInputField
										label="Name"
										name="name"
										type="text"
										required
										autoComplete="false"
										placeholder="e.g. North Sales Wing"
									/>
								</Stack>
							</Col>
						</Grid>
						<Divider sm />
						<Grid gap="4">
							<Col sm={12} md={4}>
								<Stack gap="1">
									<Heading as="h3">Team Members</Heading>
									<Text color="muted">
										Please select the members of this team.
									</Text>
								</Stack>
							</Col>
							<Col>
								<Stack gap="4">
									<SelectField
										name="users"
										select={SelectUsers}
										multiple
										fetchOnMount
									/>
								</Stack>
							</Col>
						</Grid>
						<Divider sm />
						<Grid gap="4">
							<Col sm={12} md={4}>
								<Stack gap="1">
									<Heading as="h3">Team Managers</Heading>
									<Text color="muted">Please select the team managers</Text>
								</Stack>
							</Col>
							<Col>
								<Stack gap="4">
									<GetFieldValue<TNewTeamSchema["users"]> name="users">
										{({ value: users }) => (
											<SelectField
												label="Managers"
												secondaryLabel="optional"
												name="managers"
												select={Select}
												options={users}
												multiple
											/>
										)}
									</GetFieldValue>
								</Stack>
							</Col>
						</Grid>
						<Divider sm />
						<Grid gap="4">
							<Col sm={12} md={4}>
								<Stack gap="1">
									<Heading as="h3">Trip Destinations</Heading>
									<Text color="muted">
										The team members will have access to only selected
										destinations.
									</Text>
								</Stack>
							</Col>
							<Col>
								<Stack gap="4">
									<SelectField
										name="trip_destinations"
										select={SelectTripDestination}
										multiple
										fetchOnMount
										label="Select Destinations"
										secondaryLabel="optional"
									/>
								</Stack>
							</Col>
						</Grid>
						<Divider sm />
						<SubmissionError />
						<Inline gap="4">
							<Button type="submit" disabled={submitting}>
								{submitting ? "Saving..." : "Save Details"}
							</Button>
							<Button disabled={submitting} onClick={onCancel} level="tertiary">
								Cancel
							</Button>
						</Inline>
					</Stack>
				</form>
			)}
		</Form>
	)
}
