import {
	Box,
	Inline,
	Table,
	AsyncSelect,
	AsyncSelectProps,
	useBreakpoints,
} from "@sembark-travel/ui/base"
import { ButtonLink, Link, useLocationQuery } from "@sembark-travel/ui/router"
import { CursorListView, Search, useSearch } from "@sembark-travel/ui/list"
import { useXHR } from "@sembark-travel/xhr"
import { Fragment, useEffect, useState } from "react"
import { Omit } from "utility-types"
import { generatePath } from "../router-utils"
import { TTripDestination } from "../TripDestinations"
import { AddTransportServiceDialog } from "./NewItem"
import {
	ITransportService,
	TTransportServiceLocation,
	transportServicesXHR as XHR,
} from "./store"

export function List() {
	const [query, setQuery] = useLocationQuery()
	const [params, setParams] = useSearch(query)
	useEffect(() => {
		setQuery(params)
	}, [params, setQuery])
	const { xs, sm } = useBreakpoints()
	return (
		<Fragment>
			<Search
				initialParams={params}
				resetParams={{ q: "" }}
				onSearch={(params) => {
					setParams({ ...params, cursor: null })
				}}
				title="Transport Services"
				actions={
					<Inline gap="4">
						<ButtonLink to={generatePath("/transport-service-prices")}>
							Prices
						</ButtonLink>
						<ButtonLink
							to={generatePath("/transport-services/new")}
							level="secondary"
						>
							New Service
						</ButtonLink>
					</Inline>
				}
			/>
			<CursorListView<TTransportServiceLocation>
				pageKey="/transport-services-locations-list"
				params={params}
				fetch={(xhr, params) =>
					XHR(xhr).getTransportServiceLocations({
						...params,
						limit: 30,
						include: "thumbs",
					})
				}
				onCursorChange={(cursor) => setParams({ ...params, cursor })}
			>
				{({ items: transportLocations }) =>
					!xs && !sm ? (
						<Table
							responsive
							bordered
							hover
							headers={["", "From", "To", "Services"]}
							rows={transportLocations.map(
								({ id, short_code, from, to, services, thumb_image_url }) => [
									thumb_image_url ? (
										<Box
											as="img"
											src={thumb_image_url}
											alt="Display"
											borderWidth="1"
											rounded="md"
											style={{ width: "100px", minWidth: "100px" }}
										/>
									) : null,
									<Link
										anchored
										color="accent"
										fontWeight="semibold"
										to={generatePath("/transport-services/:serviceLocationId", {
											serviceLocationId: id.toString(),
										})}
									>
										{from.name}
										{short_code ? <Box fontSize="sm">{short_code}</Box> : null}
									</Link>,
									to?.name,
									<Inline gap="2" flexWrap="wrap">
										{services?.map((s) => (
											<Box
												key={s.id}
												paddingX="2"
												borderWidth="1"
												borderColor="default"
												rounded="lg"
											>
												{s.service}
											</Box>
										))}
									</Inline>,
								]
							)}
						/>
					) : (
						<Box as="ol">
							{transportLocations.map(
								({ id, name, services, thumb_image_url }) => (
									<Box
										as="li"
										key={id}
										padding="4"
										borderWidth="1"
										rounded="lg"
										marginBottom="4"
										bgColor="subtle"
									>
										<Box marginBottom="4">
											<Link
												fontSize="md"
												to={generatePath(
													"/transport-services/:serviceLocationId",
													{
														serviceLocationId: id.toString(),
													}
												)}
												color="accent"
												fontWeight="semibold"
											>
												<Inline gap="4">
													{thumb_image_url ? (
														<Box
															as="img"
															src={thumb_image_url}
															alt="Display"
															rounded="md"
															borderWidth="1"
															style={{ width: "100px", minWidth: "100px" }}
														/>
													) : null}
													<Box>{name}</Box>
												</Inline>
											</Link>
										</Box>
										<Inline gap="2" flexWrap="wrap">
											{services?.map((s) => (
												<Box
													key={s.id}
													display="inlineBlock"
													paddingX="2"
													borderWidth="1"
													borderColor="default"
													rounded="lg"
												>
													{s.service}
												</Box>
											))}
										</Inline>
									</Box>
								)
							)}
						</Box>
					)
				}
			</CursorListView>
		</Fragment>
	)
}

export function SelectTransportServices({
	tripDestinations,
	...props
}: Omit<AsyncSelectProps, "fetch"> & {
	tripDestinations?: Array<TTripDestination>
}) {
	const xhr = useXHR()
	const [newName, setNewName] = useState("")
	return (
		<Fragment>
			<AsyncSelect
				createOptionLabel={(q) => `Add "${q}"`}
				multiple
				optionRenderer={({
					option,
					created,
				}: {
					option: ITransportService
					created?: boolean
				}) =>
					created ? (
						<Box>Add "{option.name}"</Box>
					) : (
						<Box>
							<Box>
								{option.from_to}
								{option.locations?.short_code ? (
									<Box as="code" marginLeft="1">
										- {option.locations.short_code}
									</Box>
								) : null}
							</Box>
							<Box fontSize="sm">{option.service}</Box>
						</Box>
					)
				}
				onCreateNew={(query: string) => {
					setNewName(query)
				}}
				{...props}
				fetch={(q) =>
					XHR(xhr)
						.getTransportServices({
							q,
							trip_destinations: tripDestinations?.length
								? tripDestinations.map((t) => t.id)
								: null,
						})
						.then((resp) => resp.data)
				}
			/>
			<AddTransportServiceDialog
				open={!!newName}
				onClose={() => {
					setNewName("")
				}}
			/>
		</Fragment>
	)
}

export function SelectTransportServiceLocations({
	tripDestinations,
	...props
}: Omit<AsyncSelectProps, "fetch"> & {
	tripDestinations?: Array<TTripDestination>
}) {
	const xhr = useXHR()
	const [newName, setNewName] = useState("")
	return (
		<Fragment>
			<AsyncSelect
				createOptionLabel={(q) => `Add "${q}"`}
				onCreateNew={(query: string) => {
					setNewName(query)
				}}
				{...props}
				perFetchLimit={30}
				fetch={(q, { page }) =>
					XHR(xhr)
						.getTransportServiceLocations({
							limit: 30,
							q,
							trip_destinations: tripDestinations?.length
								? tripDestinations.map((t) => t.id)
								: null,
							pagination: "simple",
							page,
						})
						.then((resp) => resp.data)
				}
				optionRenderer={({
					option,
					created,
				}: {
					option: TTransportServiceLocation
					created?: boolean
				}) =>
					created ? (
						<Box>Add "{option.name}"</Box>
					) : (
						<Box>
							{option.name}
							{option.short_code ? (
								<Box as="code" marginLeft="1">
									- {option.short_code}
								</Box>
							) : null}
						</Box>
					)
				}
			/>
			<AddTransportServiceDialog
				open={!!newName}
				initialValues={{
					trip_destinations: tripDestinations,
				}}
				onClose={() => {
					setNewName("")
				}}
			/>
		</Fragment>
	)
}
