import { Box, Button, BoxOwnProps } from "@sembark-travel/ui/base"
import {
	Link as RouterLink,
	useResolvedPath,
	useMatch,
	useLocation,
	useNavigate,
	LinkProps as TLinkProps,
} from "react-router-dom"
import classNames from "classnames"
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react"
import { searchToQuery, queryToSearch } from "./utils"
import { xhr, xhrConfig } from "@sembark-travel/xhr"
import { stringify } from "qs"

export type LinkProps = BoxOwnProps &
	Omit<Omit<TLinkProps, "to">, keyof BoxOwnProps> & {
		to: string
		/**
		 * Append the current location to the link
		 */
		anchored?: boolean
		/**
		 * Link to back from location if available
		 * It fallback to provided `to` prop
		 */
		toBack?: boolean
		/**
		 * Search query to append to the link
		 */
		search?: string
		/**
		 * Query search to create the search
		 */
		query?: { [key: string]: unknown }
	}

export function useNavigateToBack(fallback: string): (fallback?: string) => void
export function useNavigateToBack(): (fallback: string) => void
export function useNavigateToBack(fallback?: string) {
	const backFromUrl = useGetBackUrlFromLocation()
	const navigate = useNavigate()
	const navigateToBack = useCallback(
		(to?: string) => {
			if (backFromUrl) {
				navigate(backFromUrl, { replace: true })
				return
			}
			navigate(to || fallback || "..", { replace: true })
		},
		[navigate, backFromUrl, fallback]
	)
	return navigateToBack
}

export function Link({
	anchored,
	to,
	query,
	search = "",
	toBack,
	...props
}: LinkProps) {
	const location = useLocation()
	const backFromUrl = useGetBackUrlFromLocation()
	// if we have a back from url, replace the `to` prop
	if (toBack && backFromUrl) {
		to = backFromUrl
	}
	// for anchored paths
	const searchWithBack = useMemo(() => {
		const searchWithoutMark = search.replace("?", "")
		const searchWithBack =
			queryToSearch({
				...query,
				...(anchored ? navigateBackQuery(location) : {}),
			}) + searchWithoutMark
		// make sure to prepend the "?"
		if (searchWithBack[0] !== "?") return "?" + searchWithBack
		return searchWithBack
	}, [query, anchored, location, search])
	const toWithBack = useMemo(() => {
		if (searchWithBack === "?") return to
		// check if there is a search query already exists in url
		const searchIndexInTo = to.indexOf("?")
		if (searchIndexInTo !== -1) {
			// remove the duplicate keys from the "searchWithBack" that exists in "to"
			const newSearch = queryToSearch({
				...searchToQuery(searchWithBack),
				...searchToQuery(to.slice(searchIndexInTo)),
			})
			return to.slice(0, searchIndexInTo) + newSearch
		}
		return to + searchWithBack
	}, [searchWithBack, to])

	return <Box as={RouterLink} to={toWithBack} {...props} />
}

export function NavLink({
	to,
	children,
	className = "",
	anchored,
	query,
	search,
	active,
	end = false,
	"aria-current": ariaCurrentProp = "page",
	replace,
	...props
}: React.ComponentProps<typeof Box> &
	Pick<LinkProps, "to" | "anchored" | "query" | "search" | "replace"> & {
		active?: boolean
		end?: boolean
		children: React.ReactNode
		className?: string
	}) {
	const resolved = useResolvedPath(to)
	const match = useMatch({
		path: resolved.pathname,
		end: end,
		caseSensitive: false,
	})
	const ariaCurrent = match ? ariaCurrentProp : undefined
	return (
		<Box
			as="li"
			className={classNames(className, match || active ? "active" : undefined)}
			{...props}
		>
			<Link
				to={to.replace(/\/\*$/, "")}
				anchored={anchored}
				query={query}
				search={search}
				aria-current={ariaCurrent}
				replace={replace}
			>
				{children}
			</Link>
		</Box>
	)
}

export function ButtonLink(
	props: React.ComponentProps<typeof Button> & Omit<LinkProps, "size" | "type">
) {
	return <Button as={Link} {...props} />
}

export function useGetBackUrlFromLocation(): string | undefined {
	const location = useLocation()
	const back = searchToQuery<{ back?: string | string[] }>(
		location?.search
	).back
	const backUrl = useRef<string | undefined>(
		!back ? undefined : typeof back === "string" ? back : back[0]
	)
	return backUrl.current
}

/**
 * Utility hook to set/get filters/any values in url
 */
const emptyObject: unknown = {}

type TSearchParams = {
	q?: string
	page?: number
	limit?: number
	cursor?: string | null
}

export function useLocationQuery<
	TParams extends TSearchParams = TSearchParams,
	TQuery extends TSearchParams = TSearchParams,
>(
	{
		toQuery,
		fromQuery,
	}: {
		toQuery: (params: TParams) => TQuery
		fromQuery: (query: TQuery) => TParams
	} = {
		toQuery: (t: TParams) => t as unknown as TQuery,
		fromQuery: (q: TQuery) => q as unknown as TParams,
	}
): [TParams, (params: TParams) => void] {
	const location = useLocation()
	const navigate = useNavigate()
	// get the search from the location
	const search = location ? location.search : ""
	// get the params from the location's state if there is any
	let stateParams: TParams = emptyObject as unknown as TParams
	if (location && location.state && typeof location.state === "object") {
		stateParams = ((location.state as unknown as { params?: TParams }).params ||
			emptyObject) as unknown as TParams
	}
	// hold a local state for search query in location
	const [query, setQuery] = useState<TQuery>({
		...toQuery(stateParams || emptyObject),
		...searchToQuery<TQuery>(search),
	})
	// because MOST of the toQuery are arrow functions.
	const toQueryRef = useRef(toQuery)
	toQueryRef.current = toQuery
	const setParams = useCallback(
		(params: TParams) => {
			setQuery(toQueryRef.current(params))
		},
		[setQuery]
	)
	// update the url whenever the query changes
	useEffect(() => {
		// don't push the cursor to url
		const { cursor, ...q } = query
		const newQuery = queryToSearch(q, {
			skipNulls: true,
		})
		const locationSearch = location?.search
		if (newQuery !== locationSearch) {
			navigate &&
				navigate(newQuery, {
					replace: true,
					state:
						(location?.state as undefined | Record<string, unknown>) ||
						undefined,
				})
		}
	}, [query, location?.search, location?.state, navigate])
	const params = useMemo(() => fromQuery(query), [query, fromQuery])

	return [params, setParams]
}

export function navigateBackQuery(location?: {
	pathname: string
	search?: string
}) {
	return { back: (location?.pathname || "") + (location?.search || "") }
}

/**
 * XHR Get Link
 */
export function XHRLink({
	href = "",
	query,
	children,
	skipAuth,
	...props
}: React.HTMLProps<HTMLAnchorElement> & {
	query?: {
		[key: string]:
			| string
			| number
			| Array<string>
			| Array<number>
			| undefined
			| null
	}
	skipAuth?: boolean
}) {
	return (
		<a
			href={`${xhr.defaults.baseURL}${href}${href.indexOf("?") === -1 ? "?" : "&"}${stringify(
				{
					...(query || {}),
					app_version: xhrConfig.appVersion,
				},
				{ addQueryPrefix: false }
			)}`}
			{...props}
		>
			{children}
		</a>
	)
}
