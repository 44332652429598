import { TripsList } from "./../../../Trips"
import { ForbidUnlessAuthorized, PERMISSIONS } from "../../../Auth"
import { Helmet } from "react-helmet-async"

export default function Trips() {
	return (
		<>
			<Helmet>
				<title>Trips List</title>
			</Helmet>
			<ForbidUnlessAuthorized permission={PERMISSIONS.VIEW_QUERIES}>
				<TripsList />
			</ForbidUnlessAuthorized>
		</>
	)
}
