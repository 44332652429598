import {
	Alert,
	Box,
	Button,
	Icons,
	Tooltip,
	Stack,
	Text,
	Inline,
	joinAttributes,
	Spinner,
	useBreakpoints,
} from "@sembark-travel/ui/base"
import { Dialog } from "@sembark-travel/ui/dialog"
import { useSearchContext } from "@sembark-travel/ui/list"
import {
	utcTimestampToLocalDate,
	dateToQuery,
	dateToUTCString,
	subtractUnit,
	addUnit,
	startOf,
	endOf,
	isBetween,
	formatDate,
	getUnit,
	getDiff,
	isSame,
} from "@sembark-travel/datetime-utils"
import { useSwipe } from "@sembark-travel/ui/slider"
import { useXHR, XHRInstance } from "@sembark-travel/xhr"
import { getColorForString } from "generate-colors"
import pluralize from "pluralize"
import { Fragment, useCallback, useEffect, useMemo, useState } from "react"
import useSWR from "swr"
import { generatePath } from "../router-utils"
import { ITransportServiceProvider } from "../TransportServiceProviders"
import { collect } from "./../utils"
import { ScheduleDetails } from "./Item"
import { ICabSchedule } from "./store"
import { PERMISSIONS, useCheckPermissions } from "../Auth"
import {
	navigateBackQuery,
	Link,
	queryToSearch,
} from "@sembark-travel/ui/router"
import { arrangeSchedulesInCalendar, type ICabSchedulesFilters } from "./utils"

function XHR(xhr: XHRInstance) {
	return {
		async getSchedules(params: unknown): Promise<Array<ICabSchedule>> {
			return xhr
				.get("/cab-schedules", { params })
				.then((resp) => resp.data.data)
		},
	}
}

function getDaysForIntervalWindow(
	intervalWindow: number,
	base: Date = new Date(),
	alignDateToStart = false
) {
	const min = alignDateToStart
		? 0
		: intervalWindow % 2 === 0
			? intervalWindow / 2 - 1
			: Math.floor(intervalWindow / 2)
	const max = alignDateToStart ? intervalWindow : Math.floor(intervalWindow / 2)
	const interval: Date[] = []
	for (let i = min; i >= 0; i--) {
		interval.push(subtractUnit(base, i, "days"))
	}
	for (let i = 1; i <= max; i++) {
		interval.push(addUnit(base, i, "days"))
	}
	return interval.slice(0, intervalWindow)
}

export function SchedulesCalendar({
	params,
	onChangeParams,
	date,
	hasCompactFit,
	alignDateToStart,
	transitionWindowSize,
	showOperationsTeam,
}: {
	params: ICabSchedulesFilters
	onChangeParams: (params: ICabSchedulesFilters) => void
	date: Date
	hasCompactFit: boolean
	alignDateToStart?: boolean
	transitionWindowSize: number
	showOperationsTeam: boolean
}) {
	const xhr = useXHR()
	const searchContext = useSearchContext()
	const { xs, sm, md, lg } = useBreakpoints()
	let additionalInterval = 2
	if (searchContext) {
		if (!searchContext.areFilterVisible && !xs && !sm) {
			additionalInterval += 3
		}
	}
	const intervalWindow =
		(xs ? 2 : sm ? 4 : md ? 4 : lg ? 6 : 7) + additionalInterval
	const interval = useMemo(() => {
		return getDaysForIntervalWindow(intervalWindow, date, alignDateToStart)
	}, [date, intervalWindow, alignDateToStart])
	const [selectedSchedule, showDetails] = useState<ICabSchedule | undefined>(
		undefined
	)
	const filters = useMemo(() => {
		const {
			q,
			transport_locations,
			hide_past_trips,
			transport_service_providers,
			transport_service_location_points,
			cab_types,
			status,
			trip_destinations,
			owners,
		} = params
		return {
			q: q ? q.trim() : null,
			transport_locations:
				transport_locations && transport_locations.length
					? transport_locations.map((t) => t.name)
					: [],
			start_date: dateToUTCString(startOf(interval[0], "day")),
			end_date: dateToUTCString(endOf(interval[interval.length - 1], "day")),
			hide_past_trips: hide_past_trips ? 1 : undefined,
			transport_service_providers: transport_service_providers?.length
				? transport_service_providers.map((t) => t.id)
				: [],
			transport_service_location_points:
				transport_service_location_points?.length
					? transport_service_location_points.map((t) => t.id)
					: [],
			cab_types: cab_types?.length ? cab_types.map((t) => t.id) : [],
			trip_destinations: trip_destinations?.length
				? trip_destinations.map((t) => t.id)
				: [],
			owners: owners?.length ? owners.map((o) => o.id) : [],
			status: status?.id,
		}
	}, [params, interval])

	const {
		data,
		mutate: revalidate,
		isValidating,
	} = useSWR<Array<ICabSchedule>>(
		`cab_schedules_${JSON.stringify(filters)}`,
		() => XHR(xhr).getSchedules(filters),
		{
			refreshInterval: 60000,
		}
	)
	const isFetching = !data
	// cache the fetched data
	const [schedules, setSchedules] = useState<Array<ICabSchedule>>([])
	useEffect(() => {
		if (data) {
			setSchedules(data)
		}
	}, [data])
	// these are the schedules, grouped by each day
	// used to show the overview of services for each day
	const schedulesPerDaySlot = useMemo(() => {
		return interval.map((slot) =>
			schedules.filter((schedule) =>
				isBetween(
					slot,
					utcTimestampToLocalDate(schedule.start_date),
					utcTimestampToLocalDate(schedule.end_date),
					"day",
					"[]"
				)
			)
		)
	}, [schedules, interval])
	// these are the schedules, grouped for a day for each trip
	// we use it to show the schedules so that schedules for a
	// trip are in a single row
	const schedulesPerTripPerSlot = useMemo(() => {
		return arrangeSchedulesInCalendar(schedules, interval, hasCompactFit)
	}, [schedules, interval, hasCompactFit])
	const now = useMemo(() => new Date(), [])
	const prevWindow = useCallback(() => {
		onChangeParams({
			...params,
			date: subtractUnit(params.date, transitionWindowSize, "days"),
		})
	}, [transitionWindowSize, params, onChangeParams])
	const nextWindow = useCallback(() => {
		onChangeParams({
			...params,
			date: addUnit(params.date, transitionWindowSize, "days"),
		})
	}, [transitionWindowSize, params, onChangeParams])
	const handleSwipe = useCallback(
		(direction: number) => {
			if (direction === -1) {
				// prev
				prevWindow()
			} else {
				// next
				nextWindow()
			}
		},
		[prevWindow, nextWindow]
	)
	const [setSwipeRef] = useSwipe(handleSwipe)
	const { hasPermission } = useCheckPermissions()
	const showOpsTeam =
		showOperationsTeam && hasPermission(PERMISSIONS.MANAGE_TRIP_OWNERS)
	return (
		<Box
			paddingBottom="4"
			position="relative"
			className="calendar"
			cursor={isValidating ? "wait" : undefined}
		>
			{isFetching ? (
				<Box
					display="flex"
					alignItems="center"
					justifyContent="center"
					position="absolute"
					inset="0"
					pointerEvents="none"
					zIndex="50"
				>
					<Spinner />
				</Box>
			) : null}
			<Box ref={setSwipeRef}>
				<Box
					as="table"
					width="full"
					maxWidth="full"
					fontSize="sm"
					style={{
						tableLayout: "fixed",
					}}
					position="relative"
				>
					<thead>
						<Box as="tr" textAlign="center">
							{interval.map((day, i) => {
								const isToday = isSame(now, day, "day")
								const isSelected = isSame(params.date, day, "day")
								return (
									<Box
										as="td"
										key={i}
										borderWidth="1"
										borderColor="transparent"
										position="sticky"
										top="0"
										bgColor="inset"
									>
										<Box
											as={Link}
											display="block"
											width="full"
											paddingY="2"
											boxShadow={{ focus: "outline" }}
											bgColor={{ hover: "subtle" }}
											title={`Click to View schedules for ${formatDate(
												day,
												"ddd D MMM, YYYY"
											)}`}
											to={
												generatePath("/cab-schedules/date/:date", {
													date: dateToQuery(day),
												}) + queryToSearch(navigateBackQuery(window.location))
											}
											color={{
												default: getUnit(day, "day") === 0 ? "accent" : "muted",
												hover: getUnit(day, "day") === 0 ? "accent" : "default",
											}}
										>
											<Box textAlign="center">
												<Box display="inlineBlock" fontWeight="semibold">
													{formatDate(day, "ddd")}
												</Box>
											</Box>
											<Box fontSize="xl" fontWeight="semibold">
												<Box
													display="inlineFlex"
													width="10"
													height="10"
													alignItems="center"
													justifyContent="center"
													rounded="full"
													backgroundColor={
														isToday ? "primary_emphasis" : undefined
													}
													color={isToday ? "white" : undefined}
													title={isToday ? "Today" : ""}
												>
													{getUnit(day, "date")}
												</Box>
											</Box>
											<Box fontSize="xs">{formatDate(day, "MMM, YYYY")}</Box>
										</Box>
										{isSelected ? (
											<Box
												height="px"
												bgColor="emphasis"
												position="absolute"
												bottom="0"
												left="0"
												right="0"
											/>
										) : null}
									</Box>
								)
							})}
						</Box>
						{schedules.length ? (
							<Box as="tr" textAlign="center">
								{schedulesPerDaySlot.map((schedules, i) => {
									const totalCabs = schedules.reduce<number>(
										(n, s) => n + s.cabs.length,
										0
									)
									const bookedCabsCount = schedules.reduce<number>(
										(n, s) => n + s.booked_cabs_count,
										0
									)
									const notBookedCabsCount = totalCabs - bookedCabsCount
									const diffInDayFromThisDay = schedules.length
										? getDiff(
												utcTimestampToLocalDate(schedules[0].start_date),
												startOf(new Date(), "day"),
												"days"
											)
										: 0
									const alert =
										notBookedCabsCount &&
										diffInDayFromThisDay >= 0 &&
										diffInDayFromThisDay < 2
									const allBooked = totalCabs > 0 && notBookedCabsCount === 0
									return (
										<Box
											as="td"
											key={i}
											paddingY="2"
											fontSize="lg"
											borderWidth="1"
											textAlign="center"
											backgroundColor={
												allBooked ? "success" : alert ? "danger" : "default"
											}
										>
											<Box
												display="flex"
												justifyContent="center"
												flexWrap="wrap"
											>
												{schedules.length ? (
													<Fragment>
														<Box
															fontWeight="semibold"
															color="success"
															title={`${pluralize(
																"Cab",
																bookedCabsCount,
																true
															)} Booked`}
														>
															{bookedCabsCount}
														</Box>
														{!allBooked ? (
															<Fragment>
																<Box color="muted" paddingX="1">
																	+
																</Box>
																<Box
																	fontWeight="semibold"
																	color={alert ? "danger" : "warning"}
																	title={`${pluralize(
																		"Cab",
																		notBookedCabsCount,
																		true
																	)} Not Booked`}
																>
																	{notBookedCabsCount}
																</Box>
																<Box color="muted" paddingX="1">
																	=
																</Box>
																<Box fontWeight="semibold">{totalCabs}</Box>
															</Fragment>
														) : null}
													</Fragment>
												) : null}
											</Box>
										</Box>
									)
								})}
							</Box>
						) : null}
					</thead>
					<tbody>
						{isFetching && schedules.length === 0 ? (
							<tr>
								<Box
									as="td"
									colSpan={interval.length}
									bgColor="default"
									borderWidth="1"
									paddingX="4"
									paddingY="12"
									color="muted"
									fontSize="lg"
									textAlign="center"
								/>
							</tr>
						) : schedules.length === 0 ? (
							<tr>
								<Box
									as="td"
									colSpan={interval.length}
									bgColor="default"
									borderWidth="1"
									padding="4"
									color="muted"
									fontSize="lg"
									textAlign="center"
								>
									No schedules for this interval
								</Box>
							</tr>
						) : (
							<>
								{schedulesPerTripPerSlot.map((schedulesForRow, i) => (
									<tr key={i}>
										{schedulesForRow.map((schedules, i, arr) => {
											const startOfTrip =
												i > 0 &&
												arr[i - 1].length &&
												schedules.length &&
												arr[i - 1][0].trip.id !== schedules[0].trip.id
											const endOfTrip =
												i < arr.length - 1 &&
												arr[i + 1].length &&
												schedules.length &&
												arr[i + 1][0].trip.id !== schedules[0].trip.id
											return (
												<Box
													as="td"
													paddingY="1"
													borderLeftWidth="1"
													borderRightWidth="1"
													bgColor="default"
													verticalAlign="top"
													paddingLeft={startOfTrip ? "1" : "0"}
													paddingRight={endOfTrip ? "1" : "0"}
													key={i}
												>
													{schedules.map((schedule) => (
														<Box
															as="button"
															type="button"
															data-testid="view-details"
															onClick={() => showDetails(schedule)}
															key={schedule.id}
															boxShadow={{ focus: "outline" }}
															display="block"
															textAlign="left"
															width="full"
															style={{
																background: `rgba(${getColorForString(
																	schedule.trip
																		? `${schedule.trip.id}${schedule.trip.reference_id}${schedule.trip.start_date}`
																		: schedule.transport_service.name,
																	{
																		contrast: 90,
																	}
																).join(",")}, .2)`,
															}}
														>
															{xs || sm ? (
																<ScheduleInfoCell
																	schedule={schedule}
																	showOpsTeam={showOpsTeam}
																/>
															) : (
																<Tooltip
																	content={
																		<Stack gap="2">
																			<Stack gap="1">
																				<Text fontWeight="semibold">
																					{schedule.transport_service.from_to}
																				</Text>
																				<Text
																					fontWeight="semibold"
																					fontSize="sm"
																				>
																					{schedule.transport_service.service}
																				</Text>
																			</Stack>
																			<Box>
																				{joinAttributes(
																					collect(schedule.cabs)
																						.unique((cab) => cab.cab_type.name)
																						.count() === 1
																						? `${schedule.cabs.length} ${schedule.cabs[0].cab_type.name}`
																						: pluralize(
																								"Cab",
																								schedule.cabs.length,
																								true
																							),
																					schedule.cabs_booked ? (
																						<>
																							<Icons.OkCircleSolid color="success" />{" "}
																							Booked
																						</>
																					) : (
																						<>
																							{schedule.booked_cabs_count}{" "}
																							Booked
																						</>
																					),
																					formatDate(
																						utcTimestampToLocalDate(
																							schedule.start_date
																						),
																						"HH:mm"
																					) !== "00:01" ? (
																						<Box as="span">
																							Pickup{" "}
																							{formatDate(
																								utcTimestampToLocalDate(
																									schedule.start_date
																								),
																								"h:mm A"
																							)}
																						</Box>
																					) : null
																				)}
																			</Box>
																			<Text fontSize="sm">
																				Trip ID: {schedule.trip.id}
																			</Text>
																		</Stack>
																	}
																>
																	<Box>
																		<ScheduleInfoCell
																			schedule={schedule}
																			showOpsTeam={showOpsTeam}
																		/>
																	</Box>
																</Tooltip>
															)}
														</Box>
													))}
												</Box>
											)
										})}
									</tr>
								))}
							</>
						)}
					</tbody>
				</Box>
			</Box>
			{params.hide_past_trips ? (
				<Box marginTop="8">
					<Alert title="Schedules Hidden" status="warning">
						Schedules from old trips (i.e. trips completed before{" "}
						{formatDate(new Date(), "Do MMM, YYYY")}) are hidden from the view.
						<Box marginTop="2">
							<Button
								onClick={() => {
									onChangeParams({ ...params, hide_past_trips: false })
								}}
							>
								Show All
							</Button>
						</Box>
					</Alert>
				</Box>
			) : null}
			{selectedSchedule ? (
				<Dialog
					open={Boolean(selectedSchedule)}
					onClose={() => showDetails(undefined)}
					lg
					title="Schedule Details"
				>
					<Fragment>
						<Dialog.Body>
							<ScheduleDetails
								schedule={selectedSchedule}
								onRefresh={() => {
									revalidate()
								}}
								actions={
									<Button
										level="tertiary"
										onClick={() => showDetails(undefined)}
									>
										<Icons.Cancel /> Close
									</Button>
								}
							/>
						</Dialog.Body>
					</Fragment>
				</Dialog>
			) : null}
		</Box>
	)
}

function ScheduleInfoCell({
	schedule,
	showOpsTeam,
}: {
	schedule: ICabSchedule
	showOpsTeam?: boolean
}) {
	const { cabs } = schedule
	const serviceProviders: Array<ITransportServiceProvider> = useMemo(() => {
		if (!cabs?.length) return []
		const serviceProviders = cabs
			.map((cab) => cab.transport_service_provider)
			.filter((tsp): tsp is ITransportServiceProvider => Boolean(tsp))
		// get the unique
		return collect(serviceProviders)
			.unique((item) => item.id)
			.toArray()
	}, [cabs])
	return (
		<Stack padding="2" gap="1">
			<Box fontWeight="semibold" textOverflow="truncate">
				<Box
					style={{
						borderRadius: "50%",
					}}
					display="inlineBlock"
					size="2"
					borderWidth="1"
					bgColor={
						schedule.cabs_booked ? "success_emphasis" : "warning_emphasis"
					}
					borderColor={
						schedule.cabs_booked ? "success_emphasis" : "warning_emphasis"
					}
					marginRight="1"
				/>
				{schedule.transport_service.from_to_short}
			</Box>
			{schedule.trip ? (
				<Box textOverflow="truncate" fontSize="sm">
					{schedule.trip.contact.name}
				</Box>
			) : null}
			{serviceProviders.length ? (
				<Box textOverflow="truncate" fontWeight="semibold">
					{serviceProviders.map((tsp) => tsp.name).join(", ")}
				</Box>
			) : null}
			<Box fontSize="xs" textOverflow="truncate">
				{collect(schedule.cabs)
					.unique((cab) => cab.cab_type.name)
					.count() === 1
					? `${schedule.cabs.length} ${schedule.cabs[0].cab_type.name}`
					: pluralize("Cab", schedule.cabs.length, true)}
			</Box>
			{showOpsTeam ? (
				<Inline gap="1" alignItems="center">
					<Box>
						<Icons.User size="3" />
					</Box>
					{schedule.trip.operations_team?.length ? (
						<Text fontSize="xs" textOverflow="truncate">
							{schedule.trip.operations_team
								.map((t, _, arr) =>
									arr.length > 1 ? t.name.slice(0, 4) : t.name
								)
								.join(", ")}
						</Text>
					) : (
						<Text fontSize="xs">N/A</Text>
					)}
				</Inline>
			) : null}
		</Stack>
	)
}
