import { Box, BoxProps } from "./Box"

export function Divider({
	sm,
	...props
}: Omit<BoxProps<"hr">, "children"> & {
	sm?: boolean
}) {
	return (
		<Box<"hr"> as="hr" marginY={sm ? "4" : "8"} borderTopWidth="1" {...props} />
	)
}
