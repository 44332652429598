import { Box, Icons, Container } from "@sembark-travel/ui/base"
import { Breadcrumbs, ButtonLink } from "@sembark-travel/ui/router"
import { Helmet } from "react-helmet-async"
import { AddNewTrip } from "../../../Trips"
import { useNavigate, generatePath } from "../../../router-utils"
import { ForbidUnlessAuthorized, PERMISSIONS } from "../../../Auth"

export default function NewTripPage() {
	const navigate = useNavigate()
	return (
		<>
			<Helmet>
				<title>Add Trip</title>
			</Helmet>
			<ForbidUnlessAuthorized permission={PERMISSIONS.CREATE_QUERIES}>
				<Breadcrumbs
					title="New Query"
					items={[
						[generatePath("/trips"), "Trips"],
						["", "New Query"],
					]}
					actions={
						<Box display={{ xs: "none", md: "block" }}>
							<ButtonLink
								to={generatePath("/trips/upload")}
								tabIndex={-1}
								level="tertiary"
							>
								<Icons.Upload /> Upload Via CSV
							</ButtonLink>
						</Box>
					}
				/>
				<Box paddingX={{ md: "4" }} paddingY={{ md: "8" }}>
					<Box maxWidth="6xl" marginX="auto">
						<Box borderWidth={{ md: "1" }} rounded="md" bgColor="default">
							<Container paddingY="6">
								<AddNewTrip
									onSuccess={(trip) =>
										navigate("/trips/:tripId", {
											params: {
												tripId: `${trip.id}`,
											},
										})
									}
									onCancel={() => navigate("/trips")}
								/>
							</Container>
						</Box>
					</Box>
				</Box>
			</ForbidUnlessAuthorized>
		</>
	)
}
