import { useDidMount } from "./react-hooks"
import { useState } from "react"

export interface ComponentProps<
	TState,
	TChildProps = { state: TState; setState: (state: TState) => void },
> {
	initialState: TState
	children?: React.ReactNode | ((props: TChildProps) => React.ReactNode)
	render?: (props: TChildProps) => React.ReactNode
	onMount?: (args: TChildProps) => void
}

export function Component<TState>({
	initialState,
	render,
	children,
	onMount,
}: ComponentProps<TState>) {
	const [state, updateState] = useState<TState>(initialState)
	const childProps = {
		state,
		setState: updateState,
	}
	useDidMount(() => {
		onMount?.(childProps)
	})
	return (
		<>
			{render
				? render(childProps)
				: typeof children === "function"
				? children(childProps)
				: children || null}
		</>
	)
}
