import { Helmet } from "react-helmet-async"
import { useNavigate } from "../../../router-utils"
import { NewCab } from "../../../Cabs"

export default function NewCabPage() {
	const navigate = useNavigate()
	return (
		<>
			<Helmet>
				<title>New Cab</title>
			</Helmet>
			<NewCab
				onSuccess={() => navigate("/cabs")}
				onCancel={() => navigate("/cabs")}
			/>
		</>
	)
}
