import {
	Box,
	Text,
	Tabs,
	TabsList,
	TabItem,
	TabContent,
} from "@sembark-travel/ui/base"
import { type ITrip } from "./store"
import { useXHR } from "@sembark-travel/xhr"
import { useAuthUser } from "../Auth"
import { type IBooking as IHotelBooking } from "./../HotelBookings"
import { useCallback, useState } from "react"
import { utcTimestampToLocalDateString } from "@sembark-travel/datetime-utils"
import {
	EmailContent,
	type TEmailInclusionOptions,
} from "../shared/EmailContent"
import { queryToSearch } from "@sembark-travel/ui/router"

const PREF_STORAGE_KEY = "trip_voucher_toggle_prefs"

export function TripVoucher({
	trip,
	disabledSharing,
}: {
	trip: ITrip
	disabledSharing?: boolean
}) {
	const xhr = useXHR()
	const { user } = useAuthUser()
	const canViewTripVoucher = Boolean(user?.tenant?.feature_flags?.trip_voucher)
	const tripId = trip.id
	const fetchContent = useCallback(
		async (params?: object) => {
			return xhr
				.get<{
					content: string
					options: TEmailInclusionOptions
				}>(`/trips/${tripId}/voucher`, {
					params,
				})
				.then((resp) => resp.data)
		},
		[xhr, tripId]
	)
	if (!canViewTripVoucher) return null
	return (
		<EmailContent
			maxWidth="900px"
			inclusionOptionsCacheKey={`${PREF_STORAGE_KEY}-source_${trip.trip_source.id}`}
			contentCacheKey={`/trips/${tripId}/voucher`}
			fetchContent={fetchContent}
			copyTrackId="trip_voucher"
			disabled={disabledSharing}
			pdfUrl={`/trips/${tripId}/voucher`}
		/>
	)
}

export function TripHotelsVoucher({
	trip,
	disabledSharing,
}: {
	trip: ITrip
	disabledSharing?: boolean
}) {
	const xhr = useXHR()
	const { user } = useAuthUser()
	const [bookings, setBookings] = useState<Array<IHotelBooking>>([])
	const [selectedBooking, setSelectedBooking] = useState<undefined | number>(
		undefined
	)
	const canViewTripVoucher = Boolean(user?.tenant?.feature_flags?.trip_voucher)
	const tripId = trip.id
	const fetchContent = useCallback(
		async (params: object) => {
			return xhr
				.get<{
					data: {
						content: string
						options: Array<{ name: string; included: 1 | 0 }>
						bookings: Array<IHotelBooking>
						warning?: string
					}
				}>(`/trips/${tripId}/hotels-voucher`, {
					params: {
						...params,
						hotel_bookings: selectedBooking ? [selectedBooking] : [],
					},
				})
				.then((resp) => resp.data.data)
				.then((data) => {
					setBookings(data.bookings || [])
					return data
				})
		},
		[tripId, selectedBooking, xhr]
	)
	if (!canViewTripVoucher) return null
	return (
		<Box bgColor="default" position="relative" key="hotels-voucher">
			<Tabs collapseBelow="md">
				{bookings.length > 0 ? (
					<TabsList>
						<TabItem
							key="all"
							active={!selectedBooking}
							onClick={() => setSelectedBooking(undefined)}
						>
							All Bookings
						</TabItem>
						{bookings.map((booking) => (
							<TabItem
								key={booking.id}
								active={selectedBooking === booking.id}
								onClick={() => setSelectedBooking(booking.id)}
							>
								{booking.hotel.name}
								<Text fontSize="sm" fontWeight="normal">
									{booking.hotel.location.short_name},{" "}
									{utcTimestampToLocalDateString(booking.checkin, "D MMM")}-{" "}
									{booking.no_of_nights}N
								</Text>
							</TabItem>
						))}
					</TabsList>
				) : null}
				<TabContent>
					<EmailContent
						inclusionOptionsCacheKey={`${PREF_STORAGE_KEY}_hotels_voucher-source_${trip.trip_source.id}`}
						copyTrackId="trip_hotels_voucher"
						fetchContent={fetchContent}
						contentCacheKey={`/trips/${trip.id}/hotels-voucher?booking=${
							selectedBooking || "all"
						}`}
						disabled={disabledSharing}
						pdfUrl={
							`/trips/${tripId}/hotels-voucher?` +
							queryToSearch(
								{
									hotel_bookings: selectedBooking ? [selectedBooking] : [],
								},
								{ addQueryPrefix: false }
							)
						}
					/>
				</TabContent>
			</Tabs>
		</Box>
	)
}

export function TripHoldingEmail({
	trip,
	disabledSharing,
}: {
	trip: ITrip
	disabledSharing?: boolean
}) {
	const xhr = useXHR()
	const { user } = useAuthUser()
	const canViewTripVoucher = Boolean(user?.tenant?.feature_flags?.trip_voucher)
	const tripId = trip.id
	const fetchContent = useCallback(
		async (params: object) => {
			return xhr
				.get<{
					data: {
						content: string
						options: TEmailInclusionOptions
						warning?: string
					}
				}>(`/trips/${tripId}/holding-email`, {
					params,
				})
				.then((resp) => resp.data.data)
		},
		[xhr, tripId]
	)
	if (!canViewTripVoucher) return null
	return (
		<EmailContent
			maxWidth="1000px"
			inclusionOptionsCacheKey={`${PREF_STORAGE_KEY}_holding_voucher-source_${trip.trip_source.id}`}
			contentCacheKey={`/trips/${tripId}/holding-email`}
			fetchContent={fetchContent}
			copyTrackId="trip_holding_email"
			disabled={disabledSharing}
			pdfUrl={`/trips/${tripId}/holding-email`}
		/>
	)
}
