import { useParams } from "./../../../../../../router-utils"
import { TripAccountingsDetails } from "../../../../../../Trips"
import { Helmet } from "react-helmet-async"

export default function TripAccountingDetailsPage() {
	const { tripId } = useParams("/trips/:tripId/accounting/transactions")
	return (
		<>
			<Helmet>
				<title>Trip Profit Report</title>
			</Helmet>
			<TripAccountingsDetails tripId={tripId} />
		</>
	)
}
