import { Container, Heading } from "@sembark-travel/ui/base"
import { InstalmentItem } from "../../../../Payments"
import { useParams } from "../../../../router"
import { Helmet } from "react-helmet-async"

export default function InstalmentItemDetailsPage() {
	const { instalmentId } = useParams("/payments/instalments/:instalmentId")
	return (
		<>
			<Helmet>
				<title>Instalment Details</title>
			</Helmet>
			<Container paddingY="4" borderBottomWidth="1">
				<Heading>Instalment Details</Heading>
			</Container>
			<Container paddingY="4" bgColor="default">
				<InstalmentItem instalmentId={instalmentId} showSiblings />
			</Container>
		</>
	)
}
