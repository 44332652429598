import { Outlet } from "react-router-dom"
import { NavLink } from "@sembark-travel/ui/router"
import { ForbidUnlessAuthorized, PERMISSIONS } from "../../../../Auth"
import { TabContent, Tabs, TabsList } from "@sembark-travel/ui/base"
import { generatePath } from "../../../../router-utils"

export default function Layout() {
	return (
		<ForbidUnlessAuthorized permission={PERMISSIONS.MANAGE_USERS}>
			<Tabs>
				<TabsList>
					<NavLink to={generatePath("/admin/users")}>Users</NavLink>
				</TabsList>
				<TabContent>
					<Outlet />
				</TabContent>
			</Tabs>
		</ForbidUnlessAuthorized>
	)
}
