import { Helmet } from "react-helmet-async"
import { useParams } from "./../../../router-utils"
import { ServiceLocationDetails } from "./../../../TransportServices"

export default function ServiceLocationDetailPage() {
	const { serviceLocationId } = useParams(
		"/transport-services/:serviceLocationId"
	)
	return (
		<>
			<Helmet>
				<title>Transport Service Details</title>
			</Helmet>
			<ServiceLocationDetails serviceLocationId={serviceLocationId} />
		</>
	)
}
