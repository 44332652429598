import { useState, useEffect, createContext, useContext } from "react"

const NetworkStatusContext = createContext<boolean>(true)

export function NetworkStatusProvider({
	children,
}: {
	children: React.ReactNode
}) {
	const [online, updateOnlineStatus] = useState<boolean>(isOnline)
	useEffect(() => {
		function handleNetworkStatusChange() {
			updateOnlineStatus(isOnline())
		}
		if (typeof window !== "undefined") {
			window.addEventListener("online", handleNetworkStatusChange)
			window.addEventListener("offline", handleNetworkStatusChange)
		}
		return () => {
			if (typeof window !== "undefined") {
				window.removeEventListener("online", handleNetworkStatusChange)
				window.removeEventListener("offline", handleNetworkStatusChange)
			}
		}
	}, [])
	useEffect(() => {
		if (!online) {
			document.documentElement.classList.add("offline")
		} else {
			document.documentElement.classList.remove("offline")
		}
	}, [online])
	return (
		<NetworkStatusContext.Provider value={online}>
			{children}
		</NetworkStatusContext.Provider>
	)
}

export function useNetworkStatus() {
	return useContext(NetworkStatusContext)
}

export function isOnline(): boolean {
	if (typeof window === "undefined" || !window.navigator) {
		return false
	}
	return window.navigator.onLine
}
