import {
	Box,
	Spinner,
	Button,
	Stack,
	Inline,
	Icons,
	Heading,
	Text,
	ButtonProps,
	RelativeTime,
} from "@sembark-travel/ui/base"
import { useXHR } from "@sembark-travel/xhr"
import { TInvoice, invoicesXHR } from "./store"
import useSWR from "swr"
import { useCallback, useEffect, useRef, useState } from "react"
import { EmailContent, TEmailInclusionOptions } from "../shared/EmailContent"
import { showSnackbar } from "@sembark-travel/ui/snackbar"

export function InvoiceItemDetails({
	invoice,
	onChange,
	onDelete,
	canChange,
	actions,
}: {
	invoice: TInvoice
	canChange: boolean
	onChange: (invoice: TInvoice) => void
	onDelete: () => void
	actions?: (props: { refresh: () => unknown }) => React.ReactNode
}) {
	const xhr = useXHR()
	const invoiceId = invoice.id
	const fetchEmailContent = useCallback(
		async (params: object) => {
			return xhr
				.get<{ data: { content: string; options: TEmailInclusionOptions } }>(
					`/invoices/${invoiceId}/as-rich-view`,
					{
						params,
					}
				)
				.then((resp) => resp.data.data)
		},
		[invoiceId, xhr]
	)
	return (
		<Box key={invoice.id}>
			<Inline gap="4" padding="4">
				<Stack>
					<Heading fontSize="sm" color="muted">
						Created By
					</Heading>
					<Text fontWeight="semibold">{invoice.created_by?.name || "You"}</Text>
				</Stack>
				<Stack>
					<Heading fontSize="sm" color="muted">
						Created On
					</Heading>
					<Text fontWeight="semibold">
						<RelativeTime timestamp={invoice.created_at} />
					</Text>
				</Stack>
				{invoice.updated_by &&
				invoice.updated_at &&
				invoice.created_at !== invoice.updated_at ? (
					<>
						<Stack>
							<Heading fontSize="sm" color="muted">
								Updated By
							</Heading>
							<Text fontWeight="semibold">
								{invoice.created_by?.name || "You"}
							</Text>
						</Stack>
						<Stack>
							<Heading fontSize="sm" color="muted">
								Updated On
							</Heading>
							<RelativeTime timestamp={invoice.updated_at} />
						</Stack>
					</>
				) : null}
			</Inline>
			<EmailContent
				paddingY="12"
				fetchContent={fetchEmailContent}
				contentCacheKey={`/invoices/${invoice.id}/as-rich-view`}
				copyLabel=""
				copyTrackId="invoices"
				copyBtnLevel="secondary"
				actions={
					!canChange
						? undefined
						: ({ refresh }) => (
								<Inline gap="2" alignItems="center">
									<GeneratedInvoiceAsset
										id={invoice.id}
										size="sm"
										level="primary"
										onChange={(url) =>
											onChange({
												...invoice,
												pdf_file_url: url,
												is_generating_pdf: false,
											})
										}
										fileUrl={invoice.pdf_file_url}
										isGenerating={Boolean(invoice.is_generating_pdf)}
									/>
									{actions ? actions({ refresh }) : null}
									<Button
										status="warning"
										level="tertiary"
										onClick={() => {
											if (
												window.confirm(
													"This CAN NOT BE undone.\nAre you sure you want to delete this invoice? "
												)
											)
												invoicesXHR(xhr)
													.destroy(invoice.id)
													.then((resp) => {
														showSnackbar(
															resp.message || "Invoice deleted successfully"
														)
														onDelete()
													})
													.catch((error) => {
														const e = error as Error
														alert(
															e.message ||
																"Something went wrong. Please try after some time"
														)
													})
										}}
									>
										<Icons.Trash />
									</Button>
								</Inline>
							)
				}
			/>
		</Box>
	)
}

function GeneratedInvoiceAsset({
	id,
	isGenerating,
	fileUrl,
	onChange,
	downloadLabel = (
		<>
			<Icons.DocumentDownload /> PDF
		</>
	),
	regenerateLabel = (
		<Box display="inline">
			<Icons.Refresh title="File not Generated" /> PDF
		</Box>
	),
	...props
}: {
	id: number
	isGenerating?: boolean
	fileUrl?: string
	downloadLabel?: React.ReactNode
	regenerateLabel?: React.ReactNode
	onChange: (url?: string) => void
} & Omit<ButtonProps<"a">, "id" | "onChange" | "children">) {
	const xhr = useXHR()
	const [refreshInterval, setRefreshInterval] = useState(5000)
	const { data } = useSWR(
		isGenerating ? `/invoices/${id}/pdf-generation-status` : null,
		(key) =>
			xhr
				.get<{
					data: {
						done: boolean
						in_progress: boolean
						failed: boolean
						url?: string
					}
				}>(key)
				.then((resp) => {
					if (!resp.data.data.done) {
						setRefreshInterval((interval) => interval + 1000)
					}
					return resp.data.data
				}),
		{
			refreshInterval: isGenerating ? refreshInterval : 0,
		}
	)
	const onChangeRef = useRef(onChange)
	onChangeRef.current = onChange
	useEffect(() => {
		if (data) {
			if (data.in_progress === false && isGenerating) {
				onChangeRef.current(data.url)
			}
		}
	}, [data, isGenerating])
	return isGenerating ? (
		<Button title="Generating PDF..." disabled {...props}>
			<Spinner />
		</Button>
	) : fileUrl ? (
		<Button
			as="a"
			href={fileUrl}
			title="Download Invoice"
			target="_blank"
			rel="noopener noreferrer"
			{...props}
		>
			{downloadLabel}
		</Button>
	) : null
}
