import {
	Table,
	Stack,
	Button,
	Component,
	Inline,
	Divider,
	Icons,
	Box,
} from "@sembark-travel/ui/base"
import { ListView, Search, useSearch } from "@sembark-travel/ui/list"
import { Link, useLocationQuery } from "@sembark-travel/ui/router"
import { useXHR } from "@sembark-travel/xhr"
import { useEffect, useState } from "react"
import { generatePath } from "../../router-utils"
import { Dialog } from "@sembark-travel/ui/dialog"
import {
	Form,
	SelectField,
	SubmissionError,
	TextInputField,
	withServerErrors,
} from "@sembark-travel/ui/form"
import { Optional } from "utility-types"
import { worldXHR, TWorldBank, TWorldCountry } from "../../World"
import { SelectWorldCountries } from "../../World/List"

export function BanksList() {
	const [query, setQuery] = useLocationQuery()
	const [searchParams, setSearchParams] = useSearch(query)
	useEffect(() => {
		setQuery(searchParams)
	}, [searchParams, setQuery])
	return (
		<Search
			title="Banks"
			initialParams={searchParams}
			onSearch={(params) => setSearchParams(params)}
			actions={() => (
				<Component initialState={false}>
					{({ state, setState }) => (
						<>
							<Button onClick={() => setState(true)}>Add Bank</Button>
							<Dialog
								open={state}
								onClose={() => setState(false)}
								sm
								title="Add New Bank"
							>
								<Dialog.Body>
									<AddNewBank
										onCancel={() => setState(false)}
										onSuccess={() => setState(false)}
									/>
								</Dialog.Body>
							</Dialog>
						</>
					)}
				</Component>
			)}
		>
			{() => (
				<ListView<TWorldBank>
					pageKey={`admin/world/banks`}
					params={searchParams}
					onPageChange={(page) => setSearchParams({ ...searchParams, page })}
					fetch={(xhr, params) =>
						worldXHR(xhr).getBanks({
							...params,
							include: "branches_count,country",
						})
					}
				>
					{({ items, refresh }) => (
						<Table
							headers={["Code", "Name", "Country", "Branches", ""]}
							responsive
							hover
							bordered
							alignCols={{ 4: "right" }}
							rows={items.map((item) => [
								<Link
									color="accent"
									to={generatePath("/admin/world/banks/:bankId", {
										bankId: item.id.toString(),
									})}
								>
									{item.code}
								</Link>,
								item.name,
								item.country?.iso3,
								item.branches_count,
								<Component initialState={false}>
									{({ state, setState }) => (
										<>
											<Button
												title="Edit Details"
												onClick={() => setState(true)}
												size="sm"
												level="tertiary"
											>
												<Icons.Pencil />
											</Button>
											<Dialog
												open={state}
												onClose={() => setState(false)}
												sm
												title={`Edit Bank "${item.name}"`}
											>
												<Dialog.Body>
													<EditBank
														bank={item}
														onCancel={() => setState(false)}
														onSuccess={() => {
															refresh()
															setState(false)
														}}
													/>
												</Dialog.Body>
											</Dialog>
										</>
									)}
								</Component>,
							])}
						/>
					)}
				</ListView>
			)}
		</Search>
	)
}

function AddNewBank(props: Omit<NewBankFormProps, "onSubmit">) {
	const xhr = useXHR()
	return (
		<NewBankForm
			onSubmit={(payload) => worldXHR(xhr).storeBank(payload)}
			{...props}
		/>
	)
}

export function EditBank({
	bank: branch,
	...props
}: Omit<NewBankFormProps, "onSubmit"> & {
	bank: TWorldBank
}) {
	const xhr = useXHR()
	return (
		<NewBankForm
			initialValues={{
				name: branch.name,
				code: branch.code,
				country: branch.country,
			}}
			onSubmit={(payload) => worldXHR(xhr).updateBank(branch.id, payload)}
			{...props}
		/>
	)
}
type NewBankData = {
	name: string
	code: string
	country?: TWorldCountry
}

type NewBankFormProps = {
	onSubmit: (payload: object) => Promise<unknown>
	onSuccess?: () => void
	onCancel: () => void
	initialValues?: Optional<NewBankData>
}

function NewBankForm({
	onSubmit,
	onCancel,
	onSuccess,
	initialValues: propInitialValues,
}: NewBankFormProps) {
	const [initialValues] = useState<NewBankData>(() => ({
		name: "",
		code: "",
		...propInitialValues,
	}))
	return (
		<Form<NewBankData>
			initialValues={initialValues}
			onSubmit={withServerErrors(async ({ country, ...values }) => {
				await onSubmit({
					...values,
					country: country?.id,
				})
				onSuccess?.()
			})}
			subscription={{ submitting: true }}
		>
			{({ submitting, handleSubmit }) => (
				<form onSubmit={handleSubmit} noValidate>
					<Stack gap="4">
						<Inline gap="4" collapseBelow="sm">
							<Box flex="1">
								<TextInputField
									name="name"
									type="text"
									label="Name"
									placeholder="e.g. HDFC Bank"
									required
								/>
							</Box>
							<TextInputField
								name="code"
								type="text"
								label="Code"
								placeholder="e.g. HDFC"
								required
								style={{ maxWidth: "150px" }}
							/>
						</Inline>
						<SelectField
							select={SelectWorldCountries}
							label="Country"
							name="country"
						/>
						<Divider marginY="0" />
						<SubmissionError />
						<Inline gap="4">
							<Button type="submit" disabled={submitting}>
								{submitting ? "Please wait.." : "Save Details"}
							</Button>
							<Button disabled={submitting} onClick={() => onCancel()}>
								Cancel
							</Button>
						</Inline>
					</Stack>
				</form>
			)}
		</Form>
	)
}
