import {
	Alert,
	Text,
	Box,
	Stack,
	Spinner,
	Container,
	Heading,
	Component,
	Inline,
	Button,
	Icons,
	Divider,
} from "@sembark-travel/ui/base"
import { useAuthUser } from "../Auth"
import { useTenant } from "./store"
import {
	Form,
	SelectField,
	SubmissionError,
	withServerErrors,
} from "@sembark-travel/ui/form"
import { useXHR } from "@sembark-travel/xhr"
import { CurrencyExchangeRatesList, SelectCurrencyFromAll } from "../Currencies"
import { collect } from "../utils"

export function Currencies() {
	const { user } = useAuthUser()
	const { tenant, isFetching, error, refresh } = useTenant(user?.tenant?.id)
	const xhr = useXHR()
	if (!user || !user.tenant) {
		return null
	}
	if (isFetching) {
		return <Spinner padding="4" alignCenter />
	}
	if (!tenant) {
		if (error) {
			return <Alert status="error">{error.message}</Alert>
		}
		return null
	}
	const { all_currencies, functional_currency } = tenant
	return (
		<Stack gap="4">
			<Box borderBottomWidth="1" bgColor="default">
				<Container paddingY="4">
					<Heading as="h3">Currencies</Heading>
				</Container>
			</Box>
			<Container>
				<Component initialState={false}>
					{({ state: editing, setState }) =>
						!editing ? (
							<Stack>
								<Inline gap="4">
									<Text fontWeight="semibold" color="muted">
										Currencies Used
									</Text>
									<Button inline onClick={() => setState(true)}>
										<Icons.Pencil />
									</Button>
								</Inline>
								<Text fontSize="md">
									{all_currencies.map((c) => c).join(", ")}
								</Text>
							</Stack>
						) : (
							<Box
								padding="4"
								bgColor="default"
								maxWidth="md"
								rounded="md"
								borderWidth="1"
							>
								<Form
									initialValues={{
										currencies: all_currencies?.map((c) => ({
											id: c,
											name: c,
											iso: c,
										})) || [
											{
												id: functional_currency,
												name: functional_currency,
												iso: functional_currency,
											},
										],
									}}
									onSubmit={withServerErrors(
										async ({ currencies, ...values }) => {
											await xhr.post(`/tenants/${tenant.id}/currencies`, {
												...values,
												currencies: collect(
													(currencies?.map((c) => c.iso) || []).concat([
														functional_currency,
													])
												)
													.unique()
													.toArray(),
											})
											refresh()
											setState(false)
										}
									)}
								>
									{({ submitting, handleSubmit }) => (
										<form noValidate onSubmit={handleSubmit}>
											<Stack gap="4">
												<SelectField
													name="currencies"
													select={SelectCurrencyFromAll}
													label="Currencies"
													multiple
													required
													fetchOnMount
												/>
												<Divider marginY="0" />
												<SubmissionError />
												<Inline gap="4">
													<Button type="submit" disabled={submitting}>
														{submitting ? "Saving..." : "Save"}
													</Button>
													<Button
														level="tertiary"
														onClick={() => setState(false)}
														disabled={submitting}
													>
														Cancel
													</Button>
												</Inline>
											</Stack>
										</form>
									)}
								</Form>
							</Box>
						)
					}
				</Component>
			</Container>
			{all_currencies.length > 1 ? <CurrencyExchangeRatesList /> : null}
		</Stack>
	)
}
