import { Helmet } from "react-helmet-async"
import { IHotel } from "../../../../Hotels"
import { HotelBasicDetails } from "../../../../Hotels/Item"
import { useOutletContext } from "react-router-dom"

export default function HotelBasicDetailsPage() {
	const { hotel, revalidate } = useOutletContext<{
		hotel: IHotel
		revalidate: () => void
	}>()
	return (
		<>
			<Helmet>
				<title>Hotel Details</title>
			</Helmet>
			<HotelBasicDetails hotel={hotel} onChange={revalidate} />
		</>
	)
}
