import { Box, Container, Stack, Text, MoneySum } from "@sembark-travel/ui/base"
import { Breadcrumbs } from "@sembark-travel/ui/router"
import { useState } from "react"
import { CalculatePriceForm } from "./../../../TransportServicePrices"
import { generatePath } from "../../../router-utils"
import { TMoney } from "@sembark-travel/money"
import { Helmet } from "react-helmet-async"

const children: Array<never> = []
const tripDestinations: Array<never> = []

export default function CalculatePrice() {
	const [price, setPrice] = useState<Array<TMoney>>([])
	return (
		<>
			<Helmet>
				<title>Calculate Transport Prices</title>
			</Helmet>
			<Breadcrumbs
				title="Calculate Price"
				items={[
					[
						generatePath("/transport-service-prices"),
						"Transport Service Prices",
					],
					["", "Calculator"],
				]}
			/>
			<Container bgColor="default" paddingY="8">
				<Stack gap="8">
					<Text>
						Please enter your transportation services and cabs to get the
						updated prices.
					</Text>
					<Box>
						<CalculatePriceForm
							noOfAdults={1}
							children={children}
							onChange={(services) => setPrice(services.given_price)}
							tripDestinations={tripDestinations}
						/>
					</Box>
					{price ? (
						<Box as="footer" textAlign="right">
							<Box
								as="mark"
								display="inlineBlock"
								fontSize="xl"
								fontWeight="semibold"
								rounded="md"
								paddingX="4"
								paddingY="2"
							>
								Total Price: <MoneySum money={price} />
							</Box>
						</Box>
					) : null}
				</Stack>
			</Container>
		</>
	)
}
