import {
	Badge,
	Stack,
	Icons,
	Text,
	RelativeTime,
	Tabs,
	TabsList,
	TabContent,
	TabItem,
	Table,
	Button,
	Component,
} from "@sembark-travel/ui/base"
import { Link, useLocationQuery } from "@sembark-travel/ui/router"
import React, { useEffect } from "react"
import { INotification, XHR } from "./store"
import {
	ListView,
	Search,
	TSearchParams,
	areAdvancedFiltersAppliedDefault,
	useSearch,
} from "@sembark-travel/ui/list"
import { useXHR } from "@sembark-travel/xhr"
import { useHasFeatureFlag } from "../Auth"
import { Dialog } from "@sembark-travel/ui/dialog"
import { NotificationPreferences } from "./Preferences"

export function Notification({
	notification,
}: {
	notification: INotification
}) {
	// content of the notification
	let NotificationRenderer: React.ComponentType<TNotificationRendererProps>
	let url = ""
	switch (notification.type) {
		case "App\\Notifications\\TripConverted":
			NotificationRenderer = TripConvertedNotificationRenderer
			break
		case "App\\Notifications\\FailedUpdateAmountForPaymentableNotification":
			NotificationRenderer = DefaultNotificationRenderer
			break
		case "App\\Notifications\\NewTripPlanRequestNotification":
		case "App\\Notifications\\TripPlanRequestsUpdatedNotification":
		case "App\\Notifications\\TripPlanRequestsAssignedNotification":
			url = `/trip-plan-requests?source=${encodeURIComponent(
				String(notification.data.source || "all")
			)}`
			NotificationRenderer = DefaultNotificationRenderer
			break
		case "App\\IntegratedApps\\Facebook\\Notifications\\AccessTokenExpiredNotification":
			url = `/integrations/${notification.data.integration}`
			NotificationRenderer = DefaultNotificationRenderer
			break
		default:
			console.warn(`No renderer for '${notification.type}' type notification`)
			return null
	}
	return <NotificationRenderer notification={notification} url={url} />
}

type TNotificationRendererProps = {
	notification: INotification
	url: string
}

function TripConvertedNotificationRenderer({
	notification,
}: TNotificationRendererProps) {
	const { data } = notification
	const { id, price, converted_by } = data
	return (
		<Link to={`/trips/${id}`} anchored>
			<Stack gap="1">
				<Text fontWeight="semibold">Trip Conversion</Text>
				<Text fontSize="sm">
					<Badge>{String(price)}</Badge> by {String(converted_by)}
				</Text>
			</Stack>
		</Link>
	)
}

function DefaultNotificationRenderer({
	notification,
	url,
}: TNotificationRendererProps) {
	const { data } = notification
	const { title, description } = data
	return (
		<Link to={url} anchored>
			<Stack gap="1">
				<Text fontWeight="semibold">{title}</Text>
				{description ? (
					<Text fontSize="sm" color="muted" whiteSpace="preserveLine">
						{description}
					</Text>
				) : null}
			</Stack>
		</Link>
	)
}

type TFilters = TSearchParams & {
	status: "all" | "unread"
}

export function NotificationList() {
	const [query, setQuery] = useLocationQuery<TFilters, TFilters>()
	const [params, setParams] = useSearch<TFilters>({
		...query,
		status: query.status || "unread",
	})
	useEffect(() => setQuery(params), [params, setQuery])
	const xhr = useXHR()
	// mark all as seen
	useEffect(() => {
		XHR(xhr).markAllAsSeen()
	}, [xhr])
	const hasPushNotifications = useHasFeatureFlag("push_notifications")
	return (
		<Search
			title="Notifications"
			initialParams={params}
			onSearch={(params) => setParams({ ...params, page: 1 })}
			areAdvancedFiltersApplied={({ status, ...params }) =>
				areAdvancedFiltersAppliedDefault(params)
			}
			actions={
				hasPushNotifications ? (
					<Component initialState={false}>
						{({ state, setState }) => (
							<>
								<Button onClick={() => setState(true)}>
									<Icons.Cog />
								</Button>
								<Dialog
									open={state}
									onClose={() => setState(false)}
									title="Notification Settings"
									sm
								>
									<Dialog.Body>
										<NotificationPreferences />
									</Dialog.Body>
								</Dialog>
							</>
						)}
					</Component>
				) : null
			}
		>
			{({ searchParams, setSearchParamValue }) => (
				<Tabs>
					<TabsList>
						<TabItem
							active={searchParams.status === "unread"}
							onClick={() => setSearchParamValue("status", "unread")}
						>
							Unread
						</TabItem>
						<TabItem
							active={searchParams.status === "all"}
							onClick={() => setSearchParamValue("status", "all")}
						>
							All
						</TabItem>
					</TabsList>
					<TabContent active>
						<ListView<INotification, TFilters>
							pageKey="notifications"
							params={params}
							onPageChange={(page) => setParams({ ...params, page })}
							swrConfig={{ revalidateOnFocus: true }}
							fetch={(xhr, { status, ...params }) =>
								XHR(xhr).getNotifications({
									...params,
									unread: status === "unread" ? 1 : null,
								})
							}
						>
							{({ items: notifications, xhr, refresh }) => (
								<Table
									headers={["Details", "Created", ""]}
									bordered
									hover
									alignCols={{ 2: "right" }}
									rows={notifications.map((n) => [
										<Notification notification={n} />,
										<RelativeTime timestamp={n.created_at} />,
										!n.read_at ? (
											<Button
												size="sm"
												onClick={() =>
													XHR(xhr)
														.markAsRead([n])
														.then(() => {
															refresh()
														})
												}
											>
												<Icons.Ok />
											</Button>
										) : null,
									])}
								/>
							)}
						</ListView>
					</TabContent>
				</Tabs>
			)}
		</Search>
	)
}
