import React from "react"
import {
	getUnit,
	setUnit,
	format,
	clone,
	addUnit,
	getDaysInMonth,
} from "@sembark-travel/datetime-utils"

import { alwaysValidDate, VIEWS, DEFAULT_DATE_FORMAT } from "./utils"
import { useDateTimeContext } from "./DateTimeContext"
import {
	monthYearClassName,
	nextPrevBtnClassName,
	switchBtnClassName,
} from "./datetime.css"

function Header() {
	const { navigateBack, navigateForward, viewDate, showView } =
		useDateTimeContext()
	return (
		<thead>
			<tr>
				<th
					key="year"
					className={switchBtnClassName}
					onClick={() => showView("years")}
					colSpan={2}
					role="button"
					data-value={getUnit(viewDate, "year")}
				>
					{getUnit(viewDate, "year")}
				</th>
				<th
					key="prev"
					role="button"
					className={nextPrevBtnClassName}
					onClick={() => navigateBack(1, "years")}
				>
					<span>‹</span>
				</th>
				<th
					key="next"
					className={nextPrevBtnClassName}
					role="button"
					onClick={() => navigateForward(1, "years")}
				>
					<span>›</span>
				</th>
			</tr>
		</thead>
	)
}

function Month({
	disabled,
	isActive,
	...props
}: {
	key: string | number
	onSelect?: () => void
	month: Date
	disabled?: boolean
	isActive?: boolean
}) {
	const { month, onSelect, ...otherProps } = props
	const { viewDate, dateFormat } = useDateTimeContext()
	const localMoment = viewDate
	const monthStr = format(
		setUnit(localMoment, "month", getUnit(month, "month")),
		"MMMM"
	)
	const strLength = 3
	// Because some months are up to 5 characters long, we want to
	// use a fixed string length for consistency
	const monthStrFixedLength = monthStr.substring(0, strLength)
	return (
		<td
			data-value={month}
			onClick={onSelect}
			data-disabled={Number(disabled || false)}
			data-active={Number(isActive || false)}
			title={format(
				month,
				(dateFormat || DEFAULT_DATE_FORMAT).replace(/d/gi, "")
			)}
			style={{ textTransform: "capitalize" }}
			className={monthYearClassName}
			{...otherProps}
		>
			<span>{monthStrFixedLength}</span>
		</td>
	)
}

function Months() {
	const {
		value: selectedDate,
		viewDate,
		isValidDate,
		setViewDate,
		showView,
	} = useDateTimeContext()
	const date = selectedDate
	const month = getUnit(viewDate, "month")
	const year = getUnit(viewDate, "year")
	const rows = []
	const isValid = isValidDate || alwaysValidDate
	let months = []
	let i = 0

	while (i < 12) {
		const currentMonth = setUnit(
			setUnit(setUnit(viewDate, "year", year), "month", i),
			"date",
			1
		)

		const noOfDaysInMonth = getDaysInMonth(currentMonth)
		let aDayOfMonth = currentMonth
		let isDisabled = true
		for (let j = 0; j < noOfDaysInMonth; j++) {
			isDisabled = !isValid(aDayOfMonth)
			if (!isDisabled) break
			aDayOfMonth = addUnit(currentMonth, 1, "day")
		}

		months.push(
			<Month
				key={i}
				disabled={isDisabled}
				isActive={Boolean(
					date && i === getUnit(date, "month") && year === getUnit(date, "year")
				)}
				month={clone(currentMonth)}
				onSelect={
					!isDisabled
						? () => {
								setViewDate(
									setUnit(viewDate, "month", getUnit(currentMonth, "month"))
								)
								showView(VIEWS["DAYS"])
						  }
						: undefined
				}
			/>
		)
		// we create 4 months per row
		if (months.length === 4) {
			rows.push(<tr key={`${month}-${rows.length}`}>{months}</tr>)
			months = []
		}
		i++
	}
	return <React.Fragment>{rows}</React.Fragment>
}

export default function MonthsView() {
	return (
		<div className="tpdt-months">
			<table>
				<Header />
				<tbody>
					<Months />
				</tbody>
			</table>
		</div>
	)
}
