import { Helmet } from "react-helmet-async"
import { Accounts } from "./../../../../Accounting"

export default function AccountsPage() {
	return (
		<>
			<Helmet>
				<title>Accounts</title>
			</Helmet>
			<Accounts />
		</>
	)
}
