import {
	Inline,
	Button,
	Icons,
	Stack,
	Text,
	Heading,
	Col,
	Divider,
	Grid,
	Table,
	Box,
	Time,
	Money,
	RelativeTime,
} from "@sembark-travel/ui/base"
import { useDialog, Dialog } from "@sembark-travel/ui/dialog"
import { showSnackbar } from "@sembark-travel/ui/snackbar"
import {
	utcTimestampToLocalDate,
	dateToUTCString,
	startOf,
	endOf,
	isSameOrAfter,
	dateToQuery,
	parseDateFromQuery,
} from "@sembark-travel/datetime-utils"
import { useXHR } from "@sembark-travel/xhr"
import React, { useEffect, useState } from "react"
import { Omit } from "utility-types"
import * as Validator from "yup"
import { ITenant, TConversionDetail, TenantsXHR } from "./store"
import {
	EmptyNumberValidator,
	Form,
	GetFieldValue,
	SelectField,
	SubmissionError,
	TextAreaInputField,
	TextInputField,
	validateFormValues,
	withServerErrors,
	DatePickerField,
	MoneyInputField,
	isTruthy,
	SwitchInputField,
} from "@sembark-travel/ui/form"
import { SelectCurrencyFromAll } from "../../Currencies"
import { formatMoneyByDecimal, isZeroMoney } from "@sembark-travel/money"
import {
	CursorListView,
	Search,
	TSearchParams,
	useSearch,
} from "@sembark-travel/ui/list"
import { useLocationQuery } from "@sembark-travel/ui/router"
import pluralize from "pluralize"

const pricingPlans = [
	{
		plan: "base",
		name: "Standard",
		base_amount: 2000,
		amount_per_user: 500,
		base_users: 3,
	},
	{
		plan: "pro",
		name: "Premium",
		base_amount: 3000,
		amount_per_user: 800,
		base_users: 3,
	},
]

const tenantSubscriptionDetailsValidationSchema = Validator.object().shape({
	currency: Validator.mixed().required("Please select the costing currency"),
	minimum_amount: Validator.number()
		.required("Please provide the base amount charged")
		.min(0, "Please enter a non-negative value"),
	base_amount: EmptyNumberValidator()
		.nullable()
		.min(0, "Please enter a non-negative value"),
	base_users: EmptyNumberValidator()
		.nullable()
		.min(0, "Please use a non-negative value"),
	amount_per_unit: Validator.number()
		.required("Please provide amount charged per user after base users")
		.positive("Please enter a positive value"),
	users_per_unit: Validator.number()
		.required("Please enter the user quantity for per-user costing")
		.integer("Please enter an integer value")
		.min(1, "Please enter a positive integer"),
	valid_from_date: Validator.date().required(
		"Please select a validity start date of this price"
	),
	valid_till_date: Validator.date().required(
		"Please select a validity ending date of this price"
	),
	valid_till_users: EmptyNumberValidator()
		.nullable()
		.integer("Please enter a positive integer value")
		.min(0, "Please enter a positive integer value"),
	comments: Validator.string().nullable(),
})

export function StoreTenantSubscriptionDetailsInDialog({
	tenant,
	onSuccess,
	children = ({ convert }) => (
		<Button onClick={convert}>
			<Icons.BankNotes /> Add Subscription Details
		</Button>
	),
	...props
}: Omit<
	React.ComponentProps<typeof StoreTenantSubscriptionDetails>,
	"onCancel"
> & {
	children?: (props: { convert: () => void }) => React.ReactNode
}) {
	const { signup_at, is_disabled } = tenant
	const [isOpen, open, close] = useDialog()
	if (!signup_at || is_disabled) return null
	return (
		<>
			{children({ convert: () => open() })}
			<Dialog
				open={isOpen}
				onClose={close}
				title={`Subscription details for "${tenant.name}"`}
			>
				<StoreTenantSubscriptionDetails
					{...props}
					tenant={tenant}
					onSuccess={() => {
						onSuccess()
						close()
					}}
					onCancel={close}
				/>
			</Dialog>
		</>
	)
}

export function UpdateTenantSubscriptionDetailsInDialog({
	tenant,
	onSuccess,
	children = ({ convert }) => (
		<Button onClick={convert}>
			<Icons.BankNotes /> Add Subscription Details
		</Button>
	),
	...props
}: Omit<
	React.ComponentProps<typeof UpdateTenantSubscriptionDetails>,
	"onCancel"
> & {
	children?: (props: { convert: () => void }) => React.ReactNode
}) {
	const { signup_at, is_disabled } = tenant
	const [isOpen, open, close] = useDialog()
	if (!signup_at || is_disabled) return null
	return (
		<>
			{children({ convert: () => open() })}
			<Dialog
				open={isOpen}
				onClose={close}
				title={`Subscription details for "${tenant.name}"`}
			>
				<UpdateTenantSubscriptionDetails
					{...props}
					tenant={tenant}
					onSuccess={() => {
						onSuccess()
						close()
					}}
					onCancel={close}
				/>
			</Dialog>
		</>
	)
}

type TSubscriptionDetailFormData = {
	selected_plan: string
	tenant_id: number
	monthly_payment_date: number
	currency?: { id: string; name: string; iso: string }
	minimum_amount: number
	base_amount?: number
	base_users?: number
	amount_per_unit: number
	users_per_unit: number
	valid_from_date?: Date
	valid_till_date?: Date
	valid_till_users?: number
	comments?: string
}

function StoreTenantSubscriptionDetails({
	tenant,
	onSuccess,
	...props
}: Pick<
	React.ComponentProps<typeof StoreTenantSubscriptionDetailsForm>,
	"onCancel" | "initialValues"
> & {
	tenant: ITenant
	onSuccess: () => void
}) {
	const xhr = useXHR()
	return (
		<StoreTenantSubscriptionDetailsForm
			{...props}
			tenantId={tenant.id}
			defaultCurrency={tenant.functional_currency}
			onSubmit={async (payload) => {
				if (
					window.confirm(
						`Are you sure you want to mark "${tenant.name}" as converted ?`
					)
				) {
					const { data } = await xhr.post(`/tenant-conversion-details`, payload)
					showSnackbar(
						data.message ||
							(tenant.conversion_details?.length
								? `New subscription details added successfully.`
								: `${tenant.name} is now our paying customer`)
					)
					onSuccess()
				}
			}}
		/>
	)
}

function UpdateTenantSubscriptionDetails({
	tenant,
	subscriptionDetail,
	onSuccess,
	onCancel,
}: {
	tenant: ITenant
	subscriptionDetail: TConversionDetail
	onSuccess: () => void
	onCancel: () => void
}) {
	const { id } = tenant
	const xhr = useXHR()
	const [initialValues] = useState<TSubscriptionDetailFormData>(() => {
		return {
			selected_plan: pricingPlans[pricingPlans.length - 1].plan,
			tenant_id: id,
			monthly_payment_date: subscriptionDetail.monthly_payment_date,
			currency: {
				id: subscriptionDetail.currency,
				name: subscriptionDetail.currency,
				iso: subscriptionDetail.currency,
			},
			minimum_amount: Number(
				formatMoneyByDecimal(subscriptionDetail.minimum_amount)
			),
			base_amount: subscriptionDetail.base_amount
				? Number(formatMoneyByDecimal(subscriptionDetail.base_amount))
				: undefined,
			base_users: subscriptionDetail.base_users,
			amount_per_unit: Number(
				formatMoneyByDecimal(subscriptionDetail.amount_per_unit)
			),
			users_per_unit: subscriptionDetail.users_per_unit,
			valid_from_date: subscriptionDetail.valid_from_date
				? utcTimestampToLocalDate(subscriptionDetail.valid_from_date)
				: new Date(),
			valid_till_date: subscriptionDetail.valid_till_date
				? utcTimestampToLocalDate(subscriptionDetail.valid_till_date)
				: undefined,
			valid_till_users: subscriptionDetail.valid_till_users,
			comments: subscriptionDetail.comments,
		}
	})
	return (
		<StoreTenantSubscriptionDetailsForm
			tenantId={tenant.id}
			defaultCurrency={tenant.functional_currency}
			initialValues={initialValues}
			onCancel={onCancel}
			onSubmit={async (payload) => {
				if (
					window.confirm(
						`Are you sure you want to update subscription details for ${tenant.name}?`
					)
				) {
					const { data } = await xhr.patch(
						`/tenant-conversion-details/${subscriptionDetail.id}`,
						payload
					)
					showSnackbar(
						data.message ||
							`Subscription details for ${tenant.name} have been updated.`
					)
					onSuccess()
				}
			}}
		/>
	)
}

function StoreTenantSubscriptionDetailsForm({
	tenantId,
	defaultCurrency,
	onSubmit,
	onCancel,
	initialValues: propInitialValues,
}: {
	tenantId: number
	defaultCurrency: string
	onSubmit: (data: unknown) => Promise<unknown>
	onCancel: () => void
	initialValues?: Partial<TSubscriptionDetailFormData>
}) {
	const [initialValues] = useState<TSubscriptionDetailFormData>(() => {
		return {
			selected_plan: pricingPlans[pricingPlans.length - 1].plan,
			tenant_id: tenantId,
			monthly_payment_date: 5,
			currency: {
				id: defaultCurrency || "INR",
				name: defaultCurrency || "INR",
				iso: defaultCurrency || "INR",
			},
			minimum_amount: 0,
			amount_per_unit: 0,
			users_per_unit: 1,
			base_amount: 0,
			base_users: 0,
			comments: "",
			valid_from_date: new Date(),
			...propInitialValues,
		}
	})
	return (
		<Form<TSubscriptionDetailFormData>
			initialValues={initialValues}
			validate={validateFormValues(tenantSubscriptionDetailsValidationSchema)}
			onSubmit={withServerErrors(async (values) => {
				const payload = {
					...values,
					currency: values.currency?.id,
					valid_from_date: dateToUTCString(
						startOf(values.valid_from_date || new Date(), "day")
					),
					valid_till_date: values.valid_till_date
						? dateToUTCString(endOf(values.valid_till_date, "day"))
						: undefined,
				}
				await onSubmit(payload)
			})}
			subscription={{ submitting: true }}
		>
			{({ submitting, handleSubmit }) => (
				<form noValidate onSubmit={handleSubmit}>
					<Dialog.Body>
						<Grid gap="4">
							<Col xs={12} sm={4}>
								<Stack gap="2">
									<Heading as="h4" fontSize="lg">
										Basic Setup
									</Heading>
									<Text color="muted">
										Setup currency, minimum amount and billing type.
									</Text>
								</Stack>
							</Col>
							<Col>
								<Stack>
									<Inline gap="4">
										<SelectField
											name="currency"
											select={SelectCurrencyFromAll}
											label="Currency"
											fetchOnMount
										/>
										<TextInputField
											name="minimum_amount"
											label="Minimum Amount"
											type="number"
											help="Minimum amount chargable, irrespective of users"
											secondaryLabel="optional"
											placeholder="e.g. 4000"
										/>
									</Inline>
								</Stack>
							</Col>
						</Grid>
						<Divider sm />
						<Grid gap="4">
							<Col xs={12} sm={4}>
								<Stack gap="2">
									<Heading as="h4" fontSize="lg">
										Base Users Setup
									</Heading>
									<Text color="muted">
										Setup for charges where a lumpsum is charged till N number
										of active users.
									</Text>
								</Stack>
							</Col>
							<Col>
								<Inline gap="4">
									<TextInputField
										label="Base users"
										name="base_users"
										type="number"
										min={0}
										style={{ maxWidth: "100px" }}
										placeholder="e.g. 25"
									/>
									<GetFieldValue<number | ""> name="base_users">
										{({ value: baseAmount }) => (
											<MoneyInputField
												currencyFieldName="currency"
												width="full"
												name="base_amount"
												label="Amount till Base Users"
												secondaryLabel={
													!Number(baseAmount || 0) ? "optional" : null
												}
												type="number"
												placeholder="e.g. 20000"
											/>
										)}
									</GetFieldValue>
								</Inline>
							</Col>
						</Grid>
						<Divider sm />
						<Grid gap="4">
							<Col xs={12} sm={4}>
								<Stack gap="2">
									<Heading as="h4" fontSize="lg">
										User wise Charges
									</Heading>
									<Text color="muted">
										Setup the per (n) charges, applied after base users.
									</Text>
								</Stack>
							</Col>
							<Col>
								<Inline gap="4">
									<TextInputField
										name="amount_per_unit"
										label="Amount per Unit"
										type="number"
										required
										placeholder="e.g. 1500"
									/>
									<TextInputField
										width="full"
										name="users_per_unit"
										label="Users per Unit"
										type="number"
										placeholder="e.g. 1"
									/>
								</Inline>
							</Col>
						</Grid>
						<Divider sm />
						<Grid gap="4">
							<Col xs={12} sm={4}>
								<Stack gap="2">
									<Heading as="h4" fontSize="lg">
										Validity and Remarks
									</Heading>
									<Text color="muted">
										Enter a date till this billing is valid, along with any
										remarks for future reference.
									</Text>
								</Stack>
							</Col>
							<Col>
								<Stack gap="4">
									<Inline gap="4" collapseBelow="sm">
										<DatePickerField
											name="valid_from_date"
											dateFormat
											label="Valid From Date"
											required
											clearable={false}
										/>
										<GetFieldValue<Date | undefined> name="valid_from_date">
											{({ value }) => (
												<DatePickerField
													name="valid_till_date"
													dateFormat
													label="Valid Till Date"
													required
													isValidDate={(date) =>
														value ? isSameOrAfter(date, value, "day") : false
													}
												/>
											)}
										</GetFieldValue>
									</Inline>
									<TextInputField
										name="valid_till_users"
										label="Valid Till Users"
										secondaryLabel="optional"
										type="number"
										min={0}
										placeholder="e.g. 200"
										style={{ maxWidth: "200px" }}
									/>
									<TextAreaInputField
										name="comments"
										label="Any comments"
										secondaryLabel="optional"
										placeholder="The tenant will start payments after 20 Jul, 2021"
									/>
								</Stack>
							</Col>
						</Grid>
					</Dialog.Body>
					<Dialog.Footer>
						<Grid gap="0">
							<Col xs={12} sm={{ offset: 4, span: 8 }}>
								<Stack gap="4" paddingLeft={{ sm: "4" }}>
									<SubmissionError />
									<Inline gap="4">
										<Button type="submit" disabled={submitting}>
											{submitting ? "Saving..." : "Save Details"}
										</Button>
										<Button disabled={submitting} onClick={() => onCancel()}>
											Cancel
										</Button>
									</Inline>
								</Stack>
							</Col>
						</Grid>
					</Dialog.Footer>
				</form>
			)}
		</Form>
	)
}

type TFilters = TSearchParams & {
	include_deleted?: 0 | 1
	valid_on_date?: Date
	valid_for_users?: number
}

type TFiltersInQuery = TSearchParams & {
	include_deleted?: 0 | 1
	valid_on_date?: string
	valid_for_users?: number
}

function filtersToQuery(filters: TFilters): TFiltersInQuery {
	const { q, include_deleted, valid_on_date, valid_for_users } = filters
	const query: TFiltersInQuery = {}
	if (valid_for_users) {
		query.valid_for_users = valid_for_users
	}
	if (include_deleted) {
		query.include_deleted = 1
	}
	if (valid_on_date) {
		query.valid_on_date = dateToQuery(valid_on_date)
	}
	if (q) {
		query.q = q
	}
	return query
}

function queryToFilters(query: TFiltersInQuery): TFilters {
	const { q, include_deleted, valid_on_date, valid_for_users } = query
	const filters: TFilters = {}
	if (isTruthy(include_deleted)) {
		filters.include_deleted = 1
	}
	if (Number(valid_for_users)) {
		filters.valid_for_users = Number(valid_for_users)
	}
	if (valid_on_date) {
		filters.valid_on_date = parseDateFromQuery(valid_on_date)
	}
	if (q) {
		filters.q = q
	}
	return filters
}

export function SubscriptionDetails({
	tenant,
	onChange,
}: {
	tenant: ITenant
	onChange?: () => unknown
}) {
	const tenantUid = tenant.uid
	const [query, setQuery] = useLocationQuery({
		toQuery: filtersToQuery,
		fromQuery: queryToFilters,
	})
	const [params, setParams] = useSearch<TFilters>(query)
	useEffect(() => {
		setQuery(params)
	}, [params, setQuery])

	return (
		<Search
			title="Subscription Details"
			initialParams={params}
			onSearch={(params) => setParams({ ...params, cursor: undefined })}
			Filters={Filters}
			actions={
				<>
					<StoreTenantSubscriptionDetailsInDialog
						tenant={tenant}
						onSuccess={() => {
							onChange?.()
						}}
					>
						{({ convert }) => (
							<Button onClick={convert} level="secondary" status="primary">
								<Icons.Plus /> Add
							</Button>
						)}
					</StoreTenantSubscriptionDetailsInDialog>
				</>
			}
		>
			<CursorListView<TConversionDetail, TFilters>
				pageKey="tenant-subscription-details"
				params={params}
				onCursorChange={(cursor) => setParams({ ...params, cursor })}
				fetch={(xhr, params) =>
					TenantsXHR(xhr).getSubscriptionDetails({
						...filterToRequestParams(params),
						tenant_uid: tenantUid,
					})
				}
			>
				{({ items, refresh, xhr }) => (
					<Table
						headers={[
							"Validity",
							"Cy.",
							"Min",
							"Base",
							"Per Unit",
							"Created",
							"",
						]}
						bordered
						hover
						responsive
						rows={items.map((detail) => {
							return [
								<Stack gap="px">
									<Box>
										<Time timestamp={detail.valid_from_date} format="DD MMM" />
										{" - "}
										{detail.valid_till_date ? (
											<Time
												timestamp={detail.valid_till_date}
												format="DD MMM, YYYY"
											/>
										) : (
											<Text as="span" color="warning">
												Future
											</Text>
										)}
										{detail.valid_till_users ? (
											<Text as="span">
												{" ("}
												<Icons.UserGroup /> {detail.valid_till_users})
											</Text>
										) : null}
									</Box>
									{detail.is_valid_today &&
									(detail.valid_till_users && tenant.users_count
										? detail.valid_till_users >= tenant.users_count
										: true) ? (
										<Text fontSize="sm" fontWeight="semibold" color="success">
											<Icons.OkCircleSolid /> Active Now
										</Text>
									) : null}
								</Stack>,
								detail.currency,
								isZeroMoney(detail.minimum_amount) ? (
									"-"
								) : (
									<Money money={detail.minimum_amount} />
								),
								detail.base_users ? (
									<Stack gap="px">
										<Box>
											{detail.base_amount ? (
												<Money money={detail.amount_per_unit} />
											) : (
												0
											)}
										</Box>
										<Text fontSize="sm" color="muted">
											till {pluralize("User", detail.base_users, true)}
										</Text>
									</Stack>
								) : (
									`-`
								),
								<Stack gap="px">
									<Box>
										<Money money={detail.amount_per_unit} />
									</Box>
									<Text fontSize="sm" color="muted">
										/ {pluralize("User", detail.users_per_unit, true)}
									</Text>
								</Stack>,
								<Stack gap="px">
									<Box>
										<Time timestamp={detail.created_at} />
									</Box>
									{detail.created_by ? (
										<Text color="muted" fontSize="sm">
											by {detail.created_by.name}
										</Text>
									) : null}
								</Stack>,
								detail.deleted_at ? (
									<Stack alignItems={"end"} gap="px" color="warning">
										<Text>
											Deleted <RelativeTime timestamp={detail.deleted_at} />
										</Text>
										{detail.deleted_by ? (
											<Text fontSize="sm">by {detail.deleted_by.name}</Text>
										) : null}
									</Stack>
								) : (
									<Inline gap="2" justifyContent={"end"}>
										<UpdateTenantSubscriptionDetailsInDialog
											tenant={tenant}
											onSuccess={() => {
												refresh()
												onChange?.()
											}}
											subscriptionDetail={detail}
										>
											{({ convert }) => (
												<Button onClick={convert} size="sm">
													<Icons.Pencil />
												</Button>
											)}
										</UpdateTenantSubscriptionDetailsInDialog>
										<Button
											status="warning"
											size="sm"
											onClick={() => {
												if (
													window.confirm(
														"Are you sure you want to delete this subscription detail?"
													)
												) {
													xhr
														.delete(`/tenant-conversion-details/${detail.id}`)
														.then(() => {
															refresh()
															onChange?.()
														})
												}
											}}
										>
											<Icons.Trash color="warning" />
										</Button>
									</Inline>
								),
							]
						})}
					/>
				)}
			</CursorListView>
		</Search>
	)
}

function Filters() {
	return (
		<Stack gap="4">
			<DatePickerField name="valid_on_date" label="Valid on Date" />
			<TextInputField
				name="valid_for_users"
				label="Valid for Users"
				type="number"
				min={1}
				placeholder="e.g. 5"
			/>
			<SwitchInputField name="include_deleted" label="Include Deleted" />
		</Stack>
	)
}

function filterToRequestParams(params: TFilters) {
	return {
		include_deleted: params.include_deleted,
		cursor: params.cursor,
		valid_on_date: params.valid_on_date
			? dateToUTCString(params.valid_on_date)
			: undefined,
		valid_for_users: params.valid_for_users
			? params.valid_for_users
			: undefined,
	}
}
