import { Box } from "@sembark-travel/ui/base"
import { Link, Navigate } from "@sembark-travel/ui/router"
import { utcTimestampToLocalDateTimeString } from "@sembark-travel/datetime-utils"
import { Spinner } from "@sembark-travel/ui/base"
import { useRole } from "./store"

export function RoleDetails({ roleId }: { roleId: number | string }) {
	const { role, isFetching } = useRole(roleId.toString())
	if (isFetching) {
		return <Spinner alignCenter padding="4" />
	}
	if (role) {
		return (
			<Box>
				<h3>
					{role.name}{" "}
					<Link to="edit" title="Edit Role">
						&#9998;
					</Link>
				</h3>
				<Box>
					Created at: {utcTimestampToLocalDateTimeString(role.created_at)}
				</Box>
				<p>
					<b>Permissions</b>:{" "}
					{(role.permissions || []).map((p, i, arr) => (
						<span key={p.id}>
							{p.name}
							{i !== arr.length - 1 ? " • " : ""}
						</span>
					))}{" "}
					<Link to="edit-permissions" title="Edit Permissions">
						&#9998;
					</Link>
				</p>
			</Box>
		)
	}
	return <Navigate to="/users" replace />
}
