import { logError } from "@sembark-travel/logging"
import { useCallback, useState } from "react"

interface StorageDriver {
	put(key: string, value: string): void
	get(key: string): string | null
	remove(key: string): boolean
}

class LocalStorage implements StorageDriver {
	storage?: typeof window.localStorage
	constructor() {
		try {
			this.storage =
				typeof window !== "undefined" ? window.localStorage : undefined
		} catch (e) {
			logError(e)
		}
	}
	put(key: string, value: string) {
		if (this.storage) {
			this.storage.setItem(key, value)
		}
	}
	get(key: string) {
		if (this.storage) {
			return this.storage.getItem(key)
		}
		return null
	}
	remove(key: string) {
		if (this.storage) {
			this.storage.removeItem(key)
			return true
		}
		return false
	}
}

export default class Storage {
	/**
	 * Storage drive for the storage
	 */
	protected static driver: StorageDriver = new LocalStorage()

	/**
	 * Storage a value into the storage
	 */
	static put(key: string, value: string | number | boolean) {
		Storage.driver.put(key, String(value))
	}

	/**
	 * Get value of a key
	 */
	static get(
		key: string,
		defaultValue?: string | number | null
	): string | null | undefined {
		const value = Storage.driver.get(key) || defaultValue
		return value === null ? value : String(value)
	}
}

function stringifyForStorage<T>(value: T): string {
	return JSON.stringify(value)
}

function parseFromStorage<T>(value: string, defaultValue: T): T {
	try {
		return JSON.parse(value)
	} catch (e) {
		return defaultValue
	}
}

export function useSynedStorageState<T>(
	key: string | undefined | null,
	defaultValue: T,
	stringify: (value: T) => string = stringifyForStorage,
	parse: (value: string, defaultValue: T) => T = parseFromStorage
): [state: T, setState: (state: T) => void] {
	const [state, setState] = useState<T>(() => {
		if (!key) return defaultValue
		const savedPreferences = Storage.get(key)
		if (!savedPreferences) return defaultValue
		return parse(savedPreferences, defaultValue)
	})

	const updateState = useCallback(
		(state: T) => {
			if (!key) return
			setState(state)
			Storage.put(key, stringify(state))
		},
		[key, stringify]
	)

	return [state, updateState]
}

export function subscribeToStorage<T = unknown>(
	fn: (event: string, payload?: T) => void
) {
	function subscriber(event: StorageEvent) {
		if (event.key === "message" && event.newValue) {
			try {
				const data = JSON.parse(event.newValue)
				if (data) {
					fn(data.event, data.payload)
				}
			} catch (e) {
				console.log(e)
			}
		}
	}
	window.addEventListener("storage", subscriber)
	return () => window.removeEventListener("storage", subscriber as never)
}

export function broadcastMessageToStorage(
	event: string,
	payload?: Record<string, unknown>
) {
	try {
		window.localStorage.setItem("message", JSON.stringify({ event, payload }))
		window.localStorage.removeItem("message")
	} catch (e) {
		console.log(e)
	}
}
