import { Link, useParams } from "../../../../router-utils"
import { Helmet } from "react-helmet-async"
import { RoleDetails } from "./../../../../Roles"

export default function Role() {
	const { roleId } = useParams("/roles/:roleId")
	return (
		<>
			<Helmet>
				<title>Role Details</title>
			</Helmet>
			<Link to="/roles">Back to list</Link>
			<RoleDetails roleId={roleId} />
		</>
	)
}
