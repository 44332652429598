import { Table, Inline } from "@sembark-travel/ui/base"
import { ListView, Search, useSearch } from "@sembark-travel/ui/list"
import { ButtonLink, Link } from "@sembark-travel/ui/router"
import {
	TCabPriceCalculationMetric,
	cabPriceCalculationMetricsXHR,
	CabPriceCalculationSystemQuantityMetricLabel,
} from "../../../CabPriceCalculationMetrics"
import { generatePath } from "../../../router-utils"
import { Helmet } from "react-helmet-async"

export default function CabPriceCalculationMetricPage() {
	const [params, setParams] = useSearch()
	return (
		<>
			<Helmet>
				<title>Cab Price Metrics</title>
			</Helmet>
			<Search
				initialParams={params}
				title="Cab Price Metrics"
				onSearch={(params) => setParams({ ...params, page: 1 })}
				actions={
					<Inline gap="4">
						<ButtonLink
							to={generatePath("/cab-price-calculation-metric-presets")}
						>
							Presets
						</ButtonLink>
						<ButtonLink
							to={generatePath("/cab-price-calculation-metrics/new")}
							level="primary"
						>
							Add New
						</ButtonLink>
					</Inline>
				}
			>
				{() => (
					<ListView<TCabPriceCalculationMetric>
						pageKey="cab-price-calculation-metrics"
						params={params}
						onPageChange={(page) => setParams({ page })}
						fetch={(xhr, params) =>
							cabPriceCalculationMetricsXHR(xhr).get(params)
						}
					>
						{({ items }) => (
							<Table
								headers={["Name", "Sys. Metric", "Min. /Day"]}
								bordered
								striped
								responsive
								rows={items.map((item) => [
									<Link
										to={generatePath(
											"/cab-price-calculation-metrics/:metricId",
											{
												metricId: item.id.toString(),
											}
										)}
										color="accent"
									>
										{item.name}
									</Link>,
									<CabPriceCalculationSystemQuantityMetricLabel
										metric={item.system_quantity_metric}
									/>,
									item.min_quantity_per_day,
								])}
							/>
						)}
					</ListView>
				)}
			</Search>
		</>
	)
}
