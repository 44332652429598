import { Helmet } from "react-helmet-async"
import { CabTypesList } from "../../../../CabTypes"
import { ForbidUnlessAuthorized, PERMISSIONS } from "../../../../Auth"

export default function OrganizationCabTypesPage() {
	return (
		<>
			<Helmet>
				<title>Cab Types</title>
			</Helmet>
			<ForbidUnlessAuthorized permission={PERMISSIONS.MANAGE_TRIP_OWNERS}>
				<CabTypesList />
			</ForbidUnlessAuthorized>
		</>
	)
}
