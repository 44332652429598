import { Alert, useId, Component, Spinner } from "@sembark-travel/ui/base"
import { showSnackbar } from "@sembark-travel/ui/snackbar"
import { Dialog } from "@sembark-travel/ui/dialog"
import { useXHR } from "@sembark-travel/xhr"
import React from "react"
import useSWR from "swr"
import { NewItemForm } from "./NewItem"
import { ITripSource } from "./store"
import { isTruthy } from "@sembark-travel/ui/form"

export function EditItem({
	onSuccess,
	tripSourceId: propTripSourceId,
	tripSource,
	...props
}: Omit<
	React.ComponentProps<typeof NewItemForm>,
	"onSubmit" | "initialValues"
> & {
	onSuccess: (tripSource: ITripSource) => void
	hideTypeToggle?: boolean
} & (
		| {
				tripSourceId?: number | string
				tripSource: ITripSource
		  }
		| {
				tripSourceId: number | string
				tripSource?: ITripSource
		  }
	)) {
	const tripSourceId = tripSource?.id || propTripSourceId
	const xhr = useXHR()
	const id = useId()
	const { data, error } = useSWR<ITripSource>(
		`/trip-sources/${tripSourceId}?${id}`,
		() =>
			tripSource
				? Promise.resolve(tripSource)
				: xhr
						.get(`/trip-sources/${tripSourceId}`)
						.then((resp) => resp.data.data)
	)
	if (!data && error) {
		return <Alert status="error">{error.message}</Alert>
	}
	if (!data) {
		return <Spinner padding="4" alignCenter />
	}
	return (
		<NewItemForm
			initialValues={{
				name: data.name,
				short_name: data.short_name,
				is_agent: isTruthy(data.is_agent) ? "1" : "0",
				address: data.address
					? {
							...data.address,
							location: data.address.location,
							city: data.address.location?.city,
							state: data.address.location?.state,
							country: data.address.location?.country,
							email: data.address.email?.email || "",
							addressing_name: data.address.addressing_name || data.name,
					  }
					: undefined,
			}}
			onSubmit={async (values) => {
				const tripSource = await xhr.post(
					`/trip-sources/${tripSourceId}`,
					values
				)
				showSnackbar("Source details updated successfully.")
				onSuccess(tripSource.data.data)
				return tripSource
			}}
			{...props}
		/>
	)
}

export function EditItemInDialog({
	tripSource,
	onSuccess,
	children,
}: {
	tripSource: ITripSource
	onSuccess: () => void
	children: (props: { edit: () => void }) => React.ReactNode
}) {
	return (
		<Component initialState={false}>
			{({ state, setState }) => (
				<>
					{children({ edit: () => setState(true) })}
					<Dialog
						open={state}
						onClose={() => setState(false)}
						title={`Edit "${tripSource.name}"'s Details`}
					>
						<Dialog.Body>
							<EditItem
								tripSource={tripSource}
								onSuccess={async () => {
									onSuccess()
									setState(false)
								}}
								onCancel={() => setState(false)}
							/>
						</Dialog.Body>
					</Dialog>
				</>
			)}
		</Component>
	)
}
