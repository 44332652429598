import { Helmet } from "react-helmet-async"
import { TripQuoteBookingsDiffList } from "../../../TripQuoteBookingsDiff"

export default function TripQuoteBookingsDiffListPage() {
	return (
		<>
			<Helmet>
				<title>Trip Quote Bookings Diff</title>
			</Helmet>
			<TripQuoteBookingsDiffList />
		</>
	)
}
