import { Box, Container } from "@sembark-travel/ui/base"
import { Breadcrumbs } from "@sembark-travel/ui/router"
import { Helmet } from "react-helmet-async"
import { AddNewTeam } from "./../../../Teams"
import { generatePath, useNavigate } from "./../../../router-utils"

export default function NewTeamPage() {
	const navigate = useNavigate()
	return (
		<>
			<Helmet>
				<title>New Team</title>
			</Helmet>
			<Breadcrumbs
				title="New Team"
				items={[
					[generatePath("/org/teams"), "Teams"],
					["", "New"],
				]}
			/>
			<Box paddingX={{ md: "4" }} paddingY={{ md: "8" }}>
				<Box maxWidth="3xl" marginX="auto">
					<Box borderWidth={{ md: "1" }} rounded="md" bgColor="default">
						<Container paddingY="6">
							<Box>
								<AddNewTeam
									onSuccess={(team) =>
										navigate("/teams/:teamId", {
											params: {
												teamId: team.id.toString(),
											},
										})
									}
									onCancel={() => navigate("/org/teams", { replace: true })}
								/>
							</Box>
						</Container>
					</Box>
				</Box>
			</Box>
		</>
	)
}
