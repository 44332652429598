import { Table, AsyncSelect, AsyncSelectProps } from "@sembark-travel/ui/base"
import { Link, ButtonLink } from "@sembark-travel/ui/router"
import { ListView, Search, TSearchParams } from "@sembark-travel/ui/list"
import { IListResponse, useXHR, XHRInstance } from "@sembark-travel/xhr"
import { Fragment } from "react"
import { Omit } from "utility-types"
import { generatePath } from "../router-utils"
import { IHotelGroup } from "./store"

function XHR(xhr: XHRInstance) {
	return {
		async getList(params?: unknown): Promise<IListResponse<IHotelGroup>> {
			return xhr.get("/hotel-groups", { params }).then((resp) => resp.data)
		},
	}
}

export function List<TParams extends TSearchParams>({
	params,
	setParams,
}: {
	params: TParams
	setParams: (data: TParams) => void
}) {
	return (
		<Fragment>
			<Search
				initialParams={params}
				onSearch={(params) => {
					setParams({ ...params, page: 1 })
				}}
				title="Hotel Groups"
				actions={
					<>
						<ButtonLink to={generatePath("/hotels")}>All Hotels</ButtonLink>
					</>
				}
			/>
			<ListView<IHotelGroup, TParams>
				pageKey="hotel-groups"
				fetch={(xhr, params) =>
					XHR(xhr).getList({ ...params, with: { hotels_count: 1 } })
				}
				onPageChange={(page) => setParams({ ...params, page })}
				params={params}
			>
				{({ items }) => (
					<Table
						striped
						bordered
						headers={["Name", "Hotels"]}
						rows={items.map((group) => [
							<Link
								color="accent"
								fontWeight="semibold"
								to={generatePath("/hotel-groups/:groupId", {
									groupId: group.id.toString(),
								})}
							>
								{group.name}
							</Link>,
							group.hotels_count || 0,
						])}
					/>
				)}
			</ListView>
		</Fragment>
	)
}

export function SelectHotelGroup(props: Omit<AsyncSelectProps, "fetch">) {
	const xhr = useXHR()
	return (
		<AsyncSelect
			{...props}
			fetch={(q) =>
				XHR(xhr)
					.getList({ q })
					.then((resp) => resp.data)
			}
		/>
	)
}
