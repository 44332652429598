import { XHRInstance, IListResponse } from "@sembark-travel/xhr"
import { IUser } from "../Auth"

export type TToken = {
	id: number
	name: string
	abilities: Array<string>
	last_used_at: string | null
	created_at: string
	updated_at: string | null
}

export type TIntegration = {
	id: number
	uid: string
	name: string
	description: string
	abilities: Array<string>
	created_at: string
	created_by?: IUser
	platform?: "google_ads" | "meta" | "api"
	tokens?: Array<TToken> | null
	integrated_app_type: null | "fb_integrated_apps"
	integrated_app_id: null | number
	google_ads_lead_form_webhook_config?: {
		url: string
		key: string
	}
}

export type TNewIntegrationSchema = {
	name: string
	description: string
	abilities: Array<string>
}

export type TFBIntegratedApp = {
	id: number
	app_id: string
	user_id: string
	integration_uid: string
	user_access_token_issued_at?: string
	webhook_token: string
	pages?: Array<TFBIntegratedPage>
}

export type TFBIntegratedPage = {
	id: number
	page_id: string
	name: string
	subscribed_at?: string
	access_token_issued_at?: string
	exception?: Record<string, unknown>
	forms_count?: number
}

export type TFBPageLead = {
	id: number
	leadgen_id: string
	page_id: string
	created_at: string
	webhook_at?: string
	webhook_payload?: Record<string, unknown>
	fetched_data?: Record<string, unknown>
	fetched_at?: string
	batch_id?: string
	form_id?: string
	ad_id?: string
	adgroup_id: string
	exception?: Record<string, unknown>
}

// actions
export function XHR(xhr: XHRInstance) {
	return {
		async store(data: TNewIntegrationSchema): Promise<{
			data: TIntegration
			meta?: {
				last_created_token: string
			}
		}> {
			return xhr.post("/integrations", data).then((resp) => resp.data)
		},
		async get(params: unknown): Promise<IListResponse<TIntegration>> {
			return xhr.get("/integrations", { params }).then((resp) => resp.data)
		},
		async show(integrationId: string | number): Promise<TIntegration> {
			return xhr
				.get(`/integrations/${integrationId}`)
				.then((resp) => resp.data.data)
		},
		async update(
			integrationId: string | number,
			payload: unknown
		): Promise<TIntegration> {
			return xhr
				.patch(`/integrations/${integrationId}`, payload)
				.then((resp) => resp.data.data)
		},
		async destroy(id: number): Promise<string | undefined> {
			return xhr
				.delete(`/integrations/${id}`)
				.then((resp) => resp.data?.data?.message)
		},
		async generateToken(id: number): Promise<{ token: string }> {
			return xhr
				.post(`/integrations/${id}/tokens`)
				.then((resp) => resp.data.data)
		},
		async destroyToken(
			integrationId: number,
			tokenId: number
		): Promise<string | undefined> {
			return xhr
				.delete(`/integrations/${integrationId}/tokens/${tokenId}`)
				.then((resp) => resp.data?.data?.message)
		},
	}
}
