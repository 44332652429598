import { Box, Container } from "@sembark-travel/ui/base"
import { useMemo } from "react"
import { Helmet } from "react-helmet-async"
import { CreateSchedule } from "../../../CabScheduling"
import { generatePath, useLocation } from "../../../router-utils"
import { dateToQuery, parseDateFromQuery } from "@sembark-travel/datetime-utils"
import {
	useNavigateToBack,
	queryToSearch,
	searchToQuery,
	Breadcrumbs,
} from "@sembark-travel/ui/router"
import { ForbidUnlessAuthorized, PERMISSIONS } from "../../../Auth"

export default function NewCabSchedulePage() {
	const location = useLocation()
	const { date } = searchToQuery<{ date: string }>(location?.search)
	const selectedDate = useMemo(() => {
		return (date ? parseDateFromQuery(date) : new Date()) || new Date()
	}, [date])
	const goToBack = useNavigateToBack(
		generatePath("/cab-schedules/calendar") +
			queryToSearch({ date: dateToQuery(selectedDate) })
	)
	return (
		<>
			<Helmet>
				<title>Create Cab Schedule</title>
			</Helmet>
			<ForbidUnlessAuthorized permission={PERMISSIONS.MODIFY_CAB_SCHEDULES}>
				<Breadcrumbs
					title="New Schedule Cab"
					items={[
						[
							generatePath("/cab-schedules/calendar") +
								queryToSearch({
									date: dateToQuery(selectedDate, "YYYY-MM-DD"),
								}),
							"Calendar",
						],
						["", "New"],
					]}
				/>
				<Box paddingX={{ md: "4" }} paddingY={{ md: "8" }}>
					<Box maxWidth="6xl" marginX="auto">
						<Box borderWidth={{ md: "1" }} rounded="md" bgColor="default">
							<Container paddingY="6">
								<CreateSchedule
									date={selectedDate}
									onSuccess={goToBack}
									onCancel={goToBack}
								/>
							</Container>
						</Box>
					</Box>
				</Box>
			</ForbidUnlessAuthorized>
		</>
	)
}
