import {
	Box,
	Icons,
	Stack,
	Inline,
	Text,
	Heading,
	Spinner,
	RelativeTime,
	joinAttributes,
	Dropdown,
} from "@sembark-travel/ui/base"
import {
	utcTimestampToLocalDate,
	fromNow,
} from "@sembark-travel/datetime-utils"
import { TSearchParams } from "@sembark-travel/ui/list"
import { queryToSearch } from "@sembark-travel/ui/router"
import { IListResponse, useXHR } from "@sembark-travel/xhr"
import React, { useMemo } from "react"
import useSWR from "swr"
import { AddCommentInDialog as AddComment } from "./NewItem"
import { IComment, TCommentableType } from "./store"

type TParams = TSearchParams & {
	demanding?: number
	limit?: number
	actionable?: number
	overdue?: number
}

function useFetchComments(
	commentableType: TCommentableType,
	commentableId: number,
	params: TParams = {}
) {
	const xhr = useXHR()
	const url = `/${commentableType}/${commentableId}/comments`
	const key = `${url}${queryToSearch(params)}`
	return useSWR<IListResponse<IComment>>(key, () =>
		xhr.get(url, { params }).then((resp) => resp.data)
	)
}

interface ICommentProps {
	/**
	 * Entity type to which comments are associated
	 */
	commentableType: TCommentableType
	/**
	 * Entity id to which comments are associated
	 */
	commentableId: number
	/**
	 * Fetch comments that requires attention
	 */
	demanding?: boolean
	/**
	 * Fetch comments that requires attention
	 */
	actionable?: boolean
	/**
	 * Limit the number of visible items
	 */
	limit?: number
	/**
	 * Show all comments link
	 */
	viewAllLink?: React.ReactNode
	/**
	 * Show comments that are overdue
	 */
	overdue?: boolean
	/**
	 * Any other params
	 */
	params?: TParams
}

export function CommentableComments({
	commentableType,
	commentableId,
	demanding,
	actionable,
	overdue,
	limit,
	viewAllLink,
	params: propParams,
}: ICommentProps) {
	const params = useMemo(() => {
		const params: TParams = propParams ? { ...propParams } : {}
		if (demanding) {
			params.demanding = 1
		}
		if (actionable) {
			params.actionable = 1
		}
		if (overdue) {
			params.overdue = 1
		}
		if (limit) {
			params.limit = limit
		}
		return params
	}, [demanding, actionable, overdue, limit, propParams])
	const { data: resp, mutate: revalidate } = useFetchComments(
		commentableType,
		commentableId,
		params
	)
	if (!resp) return <Spinner alignCenter padding="4" />
	const { data, meta } = resp
	return meta.total === 0 ? (
		<Stack paddingY="1" alignItems="center" gap="3">
			<Heading as="h5" fontSize="md">
				All caught up!
			</Heading>
			<Text color="muted" fontSize="sm" textAlign="center">
				Add comments such as follow ups, required actions etc for better trip
				flow
			</Text>
			<AddComment
				commentableId={commentableId}
				commentableType={commentableType}
				onChange={() => revalidate()}
				size="sm"
			>
				Add New
			</AddComment>
		</Stack>
	) : (
		<Stack gap="4">
			<CommentableCommentsList comments={data} onChange={() => revalidate()} />
			{meta.total > data.length ? (
				<Text fontSize="sm" color="muted">
					<span>
						Showing {meta.from} - {meta.to} of {meta.total} comments
					</span>
					{viewAllLink ? <span> • {viewAllLink}</span> : null}
				</Text>
			) : null}
			<Box>
				<AddComment
					commentableId={commentableId}
					commentableType={commentableType}
					onChange={() => revalidate()}
					size="sm"
				>
					<Icons.Plus /> Add
				</AddComment>
			</Box>
		</Stack>
	)
}

export function CommentableCommentsList({
	comments,
	onChange,
}: {
	comments: Array<IComment>
	onChange?: () => void
}) {
	return (
		<Stack as="ol" gap="2">
			{comments.map((comment) => (
				<Box as="li" key={comment.id}>
					<CommentableCommentItem comment={comment} onChange={onChange} />
				</Box>
			))}
		</Stack>
	)
}

export function CommentableCommentItem({
	comment,
	onChange,
}: {
	comment: IComment
	onChange?: () => void
}) {
	const xhr = useXHR()
	const {
		body,
		created_at,
		created_by,
		due_at,
		resolved_at,
		resolved_by,
		needs_resolution,
	} = comment
	return (
		<Inline
			bgColor={needs_resolution ? { hover: "subtle" } : undefined}
			gap="2"
		>
			<Stack gap="1" flex="1">
				<Text whiteSpace="preserveLine" fontWeight="semibold">
					{body}
				</Text>
				<Text color="muted" fontSize="sm">
					{joinAttributes(
						<Text as="span">
							<RelativeTime value={utcTimestampToLocalDate(created_at)} /> by{" "}
							{created_by.name}
						</Text>,
						due_at ? (
							<RelativeTime
								timestamp={due_at}
								color={needs_resolution ? "danger" : "muted"}
							>
								{({ value: due }) => `Due ${fromNow(due)}`}
							</RelativeTime>
						) : null
					)}
				</Text>
				{resolved_by && resolved_at ? (
					<Inline gap="2" fontSize="sm">
						<Icons.Ok title="Resolved" />
						<Text>
							{" "}
							{resolved_by.name},{" "}
							<RelativeTime value={utcTimestampToLocalDate(resolved_at)} />
						</Text>
					</Inline>
				) : null}
			</Stack>
			{needs_resolution ? (
				<Box>
					<Dropdown alignRight noCaret>
						<Dropdown.ToggleButton
							size="sm"
							level="tertiary"
							href="#more-options"
						>
							<Icons.DotsVertical color="muted" />
						</Dropdown.ToggleButton>
						<Dropdown.Menu>
							<Dropdown.MenuItem
								onClick={() => {
									if (window.confirm("Please confirm that this is resolved.")) {
										xhr
											.post("/resolved-comments", { items: [comment.id] })
											.then(() => onChange?.())
											.catch((error) => {
												const e = error as Error
												alert(
													e.message ||
														"Something went wrong. Please try after sometime"
												)
											})
									}
								}}
							>
								Mark as Resolved
							</Dropdown.MenuItem>
						</Dropdown.Menu>
					</Dropdown>
				</Box>
			) : null}
		</Inline>
	)
}
