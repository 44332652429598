import {
	Badge,
	Box,
	Button,
	Inline,
	Icons,
	Text,
	Heading,
	Stack,
	Col,
	Dropdown,
	Grid,
	joinAttributes,
	Spinner,
	Time,
	Component,
	Money,
	Alert,
} from "@sembark-travel/ui/base"
import {
	utcTimestampToLocalDate,
	dateToQuery,
	utcTimestampToLocalDateString,
} from "@sembark-travel/datetime-utils"
import { isNumeric, withOrdinalSuffix } from "@sembark-travel/number-utils"
import { NavLink, queryToSearch, Link } from "@sembark-travel/ui/router"
import { useXHR } from "@sembark-travel/xhr"
import useSWR from "swr"
import { PERMISSIONS, useCheckPermissions } from "../Auth"
import { Email, PhoneNumber } from "../Contacts"
import { generatePath } from "../router-utils"
import {
	AssignServiceProvidersInDialog,
	ScheduleDetailsInDialog,
} from "./../CabScheduling"
import { TTripOperationalBooking } from "./store"
import {
	AssignTravelActivityBookingProvidersInDialog,
	TravelActivityBookingDetailsInDialog,
} from "../TravelActivityBookings"
import {
	ReorderServicesInDialog,
	TripOperationalBookingsItemForDateInDialog,
} from "./ForDateItem"
import { ShareTripOperationalBookings } from "./ShareOperationalBookings"
import { showSnackbar } from "@sembark-travel/ui/snackbar"
import { useMemo } from "react"
import { IPaymentable } from "../Payments"

type TOperationalBookingProps = {
	tripId: number | string
}

export function TripOperationalBookingsItem(props: TOperationalBookingProps) {
	const { tripId } = props
	const xhr = useXHR()
	const { hasPermission } = useCheckPermissions()
	const {
		data: tripDetails,
		mutate: revalidate,
		isValidating,
	} = useSWR<TTripOperationalBooking>(
		`/trip-operational-bookings/${tripId}`,
		() =>
			xhr
				.get(`/trip-operational-bookings/${tripId}`)
				.then((resp) => resp.data.data)
	)
	const travelActivityBookings = useMemo(() => {
		if (!tripDetails) return []
		return tripDetails?.daywise_services
			.flatMap((d) => d.services || [])
			.map((s) => s.travel_activity_booking)
			.filter((b): b is Exclude<typeof b, undefined> => Boolean(b))
	}, [tripDetails])
	if (!tripDetails) return <Spinner padding="4" alignCenter />
	const {
		daywise_services,
		cab_schedules: schedules,
		is_shared,
		can_modify,
		cancellation_reason,
		can_view_prices,
	} = tripDetails
	const showPrices = Boolean(!is_shared && can_view_prices)
	return (
		<Stack gap="4" cursor={isValidating ? "wait" : undefined}>
			<Inline
				gap="4"
				collapseBelow="md"
				justifyContent="between"
				position="relative"
				zIndex="50"
			>
				<Heading as="h4">Operational Services</Heading>
				<Inline gap="2" justifyContent="end" flexWrap={"wrap"}>
					{tripDetails.travel_activity_bookings?.length ||
					tripDetails.cab_schedules?.length ? (
						<>
							{!is_shared && can_modify ? (
								<>
									{schedules?.length !== 0 ? (
										<AssignServiceProvidersInDialog
											tripId={tripDetails.id}
											onSuccess={() => revalidate()}
										>
											{({ assign }) => (
												<Button
													onClick={() => assign()}
													status="primary"
													size="sm"
												>
													<Icons.Driver /> Update Cab Supplier(s)
												</Button>
											)}
										</AssignServiceProvidersInDialog>
									) : null}
									{travelActivityBookings.length ? (
										<AssignTravelActivityBookingProvidersInDialog
											bookings={travelActivityBookings}
											onSuccess={() => revalidate()}
										>
											{({ assign }) => (
												<Button
													onClick={() => assign()}
													status="primary"
													size="sm"
												>
													<Icons.Driver /> Update Activity Supplier(s)
												</Button>
											)}
										</AssignTravelActivityBookingProvidersInDialog>
									) : null}
								</>
							) : null}
							{!is_shared ? (
								<ShareTripOperationalBookings trip={tripDetails}>
									{({ share }) => (
										<Button
											onClick={() => share()}
											level="secondary"
											title="Share Bookings"
											size="sm"
										>
											<Icons.Share />
										</Button>
									)}
								</ShareTripOperationalBookings>
							) : null}
						</>
					) : null}
					{hasPermission(PERMISSIONS.VIEW_CAB_SCHEDULES) &&
					!cancellation_reason ? (
						<Dropdown alignRight>
							<Dropdown.ToggleButton level="tertiary" size="sm">
								<Icons.DotsVertical />
							</Dropdown.ToggleButton>
							<Dropdown.Menu>
								<NavLink
									to={`${generatePath(
										"/operational-bookings/calendar"
									)}${queryToSearch({
										date: dateToQuery(
											utcTimestampToLocalDate(tripDetails.start_date)
										),
									})}`}
								>
									<Icons.Calendar /> View on Calendar
								</NavLink>
								<NavLink
									to={generatePath(
										"/trips/:tripId/services-bookings/operational/new",
										{
											tripId: tripDetails.id.toString(),
										}
									)}
								>
									<Icons.Plus /> Add More Bookings
								</NavLink>
								<Dropdown.MenuItemDivider />
								<Component initialState={false}>
									{({ state: isUpdating, setState: setIsUpdating }) => (
										<Dropdown.MenuItem
											onClick={(e: React.MouseEvent) => {
												e.preventDefault()
												if (isUpdating) {
													return false
												}
												if (
													window.confirm(
														"Operational bookings will be updated using the latest Quote from this Trip.\n\nAre you sure you want to continue?"
													)
												) {
													setIsUpdating(true)
													xhr
														.post(
															`/trip-operational-bookings/${tripDetails.id}/sync-with-latest-quote`
														)
														.then(() => {
															revalidate()
															showSnackbar(
																"Operational bookings have been synced with Latest Quote"
															)
														})
														.catch((error) => {
															const e = error as Error
															alert(
																e.message ||
																	"Something went wrong. Please try after sometime."
															)
														})
														.then(() => {
															setIsUpdating(false)
														})
												}
											}}
										>
											<Icons.Refresh spin={isUpdating} /> Sync Bookings with
											Quote
										</Dropdown.MenuItem>
									)}
								</Component>
							</Dropdown.Menu>
						</Dropdown>
					) : null}
				</Inline>
			</Inline>
			{!daywise_services.length ? (
				<Text>No Operational Services for this Trip</Text>
			) : (
				daywise_services.map(
					({
						date,
						day,
						services,
						services_needs_reordering,
						services_ordered_by_start_time,
					}) => (
						<Inline position="relative" gap="4" collapseBelow="sm" key={date}>
							<Box position="sticky" top="0" zIndex="10">
								<Box position="sticky" top="0" zIndex="10">
									<TripOperationalBookingsItemForDateInDialog
										tripId={tripId}
										date={utcTimestampToLocalDate(date)}
										onRefresh={() => revalidate()}
									>
										{({ show }) => (
											<Button inline onClick={() => show()} fullWidth>
												<Box
													bgColor="default"
													textAlign="center"
													paddingX="4"
													paddingY="1"
													rounded="md"
													borderWidth={"1"}
													style={{ minWidth: "110px" }}
													width="full"
												>
													<Text fontWeight="semibold" color="accent">
														{withOrdinalSuffix(day)} Day
													</Text>
													<Time
														timestamp={date}
														format="ddd, D MMM"
														whiteSpace="preserve"
														fontSize="sm"
													/>
												</Box>
											</Button>
										)}
									</TripOperationalBookingsItemForDateInDialog>
								</Box>
							</Box>
							<Box flex="1">
								<Stack gap="4">
									{services_needs_reordering ? (
										<Alert status="warning" title="Services needs Re-Ordering">
											<Stack gap="1">
												<Text>
													Some services are not aligned with the provided
													timings of the services.
												</Text>
												<Box>
													<ReorderServicesInDialog
														tripId={tripDetails.id}
														services={services}
														initialOrdering={services_ordered_by_start_time}
														onSuccess={() => {
															revalidate()
														}}
													>
														{({ onReorder }) => (
															<Button
																onClick={() => onReorder()}
																status="warning"
																size="sm"
																level="primary"
															>
																<Icons.SwitchHorizontal rotate="90" /> Re-Order
																Now
															</Button>
														)}
													</ReorderServicesInDialog>
												</Box>
											</Stack>
										</Alert>
									) : null}
									<DayBookingServicesOverview
										services={services}
										onRefresh={() => revalidate()}
										showPrices={showPrices}
									/>
								</Stack>
							</Box>
						</Inline>
					)
				)
			)}
		</Stack>
	)
}

export function DayBookingServicesOverview({
	services,
	onRefresh,
	showPrices,
	compactView,
}: {
	services: TTripOperationalBooking["daywise_services"][number]["services"]
	onRefresh: () => unknown
	showPrices: boolean
	compactView?: boolean
}) {
	return (
		<Stack bgColor="default" rounded="md" borderWidth="1">
			{services.map(({ cab_schedule, travel_activity_booking }, i) => {
				if (cab_schedule) {
					return (
						<Box
							key={`cab-schedule-${cab_schedule.id}`}
							padding={compactView ? "2" : "4"}
							borderTopWidth={i > 0 ? "1" : undefined}
						>
							<CabScheduleListItem
								cabSchedule={cab_schedule}
								onRefresh={() => onRefresh()}
								showPrices={showPrices}
								compactView={compactView}
							/>
						</Box>
					)
				} else if (travel_activity_booking) {
					return (
						<Box
							padding={compactView ? "2" : "4"}
							key={`travel-activity-booking-${travel_activity_booking.id}`}
							borderTopWidth={i > 0 ? "1" : undefined}
						>
							<TravelActivityBookingListItem
								activityBooking={travel_activity_booking}
								hidePrice={!showPrices}
								onRefresh={() => onRefresh()}
								compactView={compactView}
							/>
						</Box>
					)
				}
				return null
			})}
		</Stack>
	)
}

function CabScheduleListItem({
	cabSchedule,
	onRefresh,
	showPrices,
	compactView,
}: {
	cabSchedule: Required<
		TTripOperationalBooking["daywise_services"][number]["services"][number]
	>["cab_schedule"]
	onRefresh: () => unknown
	showPrices?: boolean
	compactView?: boolean
}) {
	const {
		transport_service,
		cabs,
		start_date,
		end_date,
		duration_formatted,
		remarks,
	} = cabSchedule
	const showStartTime = useMemo(
		() => utcTimestampToLocalDateString(start_date, "HH:mm") !== "00:01",
		[start_date]
	)
	return (
		<Grid gap="4">
			<Col xs={12} sm={4}>
				<Stack gap="px">
					<ShareTripOperationalBookings
						trip={{ id: cabSchedule.trip_id }}
						cabSchedules={[cabSchedule]}
					>
						{({ share }) => (
							<ScheduleDetailsInDialog
								schedule={cabSchedule}
								onRefresh={onRefresh}
								onShare={share}
							>
								{({ onShow }) => (
									<Text<"a">
										as="a"
										href="#schedule-details"
										fontWeight="semibold"
										color="accent"
										onClick={(e) => {
											e.preventDefault()
											onShow()
										}}
									>
										{transport_service.name}
										{transport_service.deleted_at ? (
											<Box>
												<Badge warning>
													<Icons.Ban />
												</Badge>
											</Box>
										) : null}
									</Text>
								)}
							</ScheduleDetailsInDialog>
						)}
					</ShareTripOperationalBookings>
					{(showStartTime || duration_formatted) && !compactView ? (
						<Inline gap="1" alignItems="center">
							<Icons.Clock size="3" />
							<Box flex="1" minWidth="0">
								<Text fontSize="sm">
									{showStartTime ? (
										<Time timestamp={start_date} format="HH:mm [hrs]" />
									) : null}{" "}
									{duration_formatted ? (
										<>
											({duration_formatted}{" "}
											{showStartTime ? (
												<>
													- Ends{" "}
													<Time timestamp={end_date} format="HH:mm [hrs]" />
												</>
											) : null}
											)
										</>
									) : null}
								</Text>
							</Box>
						</Inline>
					) : null}
					{compactView ? null : remarks ? (
						<Box as="blockquote">{remarks}</Box>
					) : null}
				</Stack>
			</Col>
			<Col>
				<Stack
					style={{
						// negative of every list item
						margin: "-.5rem -1rem -.5rem 0",
					}}
				>
					{cabs.map(
						(
							{
								id,
								cab,
								cab_type,
								driver,
								transport_service_provider,
								booked,
								confirmation_details,
								...cabSchedule
							},
							index
						) => (
							<Inline
								key={id}
								borderTopWidth={index > 0 ? "1" : undefined}
								paddingY="2"
								paddingRight="4"
								gap="4"
								justifyContent="between"
							>
								<Grid gap="4">
									<Col xs={12} lg={5}>
										<Stack gap="2">
											<Inline gap="1">
												{booked ? (
													<Box>
														<Icons.OkCircleSolid color="success" />
													</Box>
												) : null}
												<Inline gap="1" flexWrap="wrap" minWidth="0">
													<Text fontWeight="semibold">
														{cab?.name || cab_type.name}
													</Text>
													{cab?.number_plate && !compactView ? (
														<Text>
															<Badge warning outlined>
																{cab.number_plate}
															</Badge>
														</Text>
													) : null}
												</Inline>
											</Inline>
											{compactView ? null : confirmation_details ? (
												<Inline>
													<Box
														whiteSpace="preserveAndWrap"
														borderWidth="1"
														paddingY="1"
														paddingX="2"
														rounded="md"
														fontSize="sm"
														bgColor="inset"
													>
														{confirmation_details}
													</Box>
												</Inline>
											) : null}
										</Stack>
									</Col>
									<Col>
										<Grid gap="2">
											{transport_service_provider ? (
												<Col xs={12} md={6}>
													<Inline gap="1" flexWrap="wrap">
														<Box fontWeight="semibold">
															<Link
																to={generatePath(
																	"/transport-service-providers/:transportServiceProviderId",
																	{
																		transportServiceProviderId:
																			transport_service_provider.id.toString(),
																	}
																)}
															>
																{transport_service_provider.name}
															</Link>
														</Box>
														<Box>
															{transport_service_provider.contacts?.length
																? joinAttributes(
																		transport_service_provider.contacts[0]
																			.phone_numbers?.length ? (
																			<PhoneNumber
																				value={
																					transport_service_provider.contacts[0]
																						.phone_numbers
																				}
																				iconOnly
																			/>
																		) : null,
																		transport_service_provider.contacts[0]
																			.email ? (
																			<Email
																				value={
																					transport_service_provider.contacts[0]
																						.email
																				}
																				iconOnly
																			/>
																		) : null
																	)
																: null}
														</Box>
													</Inline>
												</Col>
											) : null}
											{driver ? (
												<Col>
													<Inline gap="2" alignItems="center">
														<Box>
															<Icons.SteeringWheel />
														</Box>
														<Box fontWeight="semibold">{driver.name}</Box>
														<Box color="muted">
															{joinAttributes(
																driver.phone_number ? (
																	<PhoneNumber
																		value={driver.phone_number}
																		iconOnly
																	/>
																) : null,
																driver.email ? (
																	<Email value={driver.email} iconOnly />
																) : null
															)}
														</Box>
													</Inline>
												</Col>
											) : null}
										</Grid>
									</Col>
								</Grid>
								{showPrices ? <BookingAmount booking={cabSchedule} /> : null}
							</Inline>
						)
					)}
				</Stack>
			</Col>
		</Grid>
	)
}

function TravelActivityBookingListItem({
	activityBooking,
	hidePrice,
	onRefresh,
	compactView,
}: {
	activityBooking: Required<
		TTripOperationalBooking["daywise_services"][number]["services"][number]
	>["travel_activity_booking"]
	hidePrice?: boolean
	onRefresh?: () => void
	compactView?: boolean
}) {
	const {
		start_time_formatted,
		end_time_formatted,
		activity,
		ticket_type,
		duration_formatted,
		ticket_tourist_configurations,
		supplier,
		supplier_contact,
		confirmation_details,
		remarks,
		is_booked,
	} = activityBooking

	return (
		<Grid gap="4">
			<Col xs={12} sm={4}>
				<Stack gap="px">
					<ShareTripOperationalBookings
						trip={{ id: activityBooking.trip_id }}
						travelActivityBookings={[activityBooking]}
					>
						{({ share }) => (
							<TravelActivityBookingDetailsInDialog
								booking={activityBooking}
								onRefresh={onRefresh}
								onShare={share}
							>
								{({ onShow }) => (
									<Text<"a">
										as="a"
										href="travel-activity-booking-details"
										fontWeight="semibold"
										color="accent"
										onClick={(e) => {
											e.preventDefault()
											onShow()
										}}
									>
										{activity.name}{" "}
										{activity.deleted_at ? (
											<Badge warning>
												<Icons.Ban /> Disabled
											</Badge>
										) : null}{" "}
										{ticket_type ? (
											<Text as="span">
												- {ticket_type.name}
												{ticket_type.deleted_at ? (
													<Badge warning>
														<Icons.Ban /> Disabled
													</Badge>
												) : null}
											</Text>
										) : null}
									</Text>
								)}
							</TravelActivityBookingDetailsInDialog>
						)}
					</ShareTripOperationalBookings>
					{!compactView && (start_time_formatted || duration_formatted) ? (
						<Inline gap="1" alignItems="center">
							<Icons.Clock size="3" />
							<Box flex="1" minWidth="0">
								<Text fontSize="sm">
									{joinAttributes(
										start_time_formatted,
										duration_formatted
											? `${duration_formatted}${end_time_formatted ? ` (Ends: ${end_time_formatted})` : ``}`
											: null
									)}
								</Text>
							</Box>
						</Inline>
					) : null}
					{compactView ? null : remarks ? (
						<Box as="blockquote">{remarks}</Box>
					) : null}
				</Stack>
			</Col>
			<Col>
				<Stack gap="2">
					<Inline justifyContent="between" gap="4">
						<Grid gap="4">
							<Col xs={12} lg={5}>
								<Inline gap="1">
									{is_booked ? (
										<Box title="Booked">
											<Icons.OkCircleSolid color="success" />
										</Box>
									) : null}
									<Inline gap="4" flexWrap="wrap">
										{ticket_tourist_configurations.map(
											(
												{
													configuration,
													quantity,
													calculated_price,
													per_quantity_booked_price,
													booked_price,
													currency,
												},
												index
											) => (
												<Stack key={`${activity.id}-${index}`}>
													<Text fontWeight="semibold">
														{quantity} {configuration.name}
													</Text>
													{hidePrice || compactView ? null : (
														<Box fontSize="sm">
															{per_quantity_booked_price ? (
																<Text>
																	<Money
																		color={
																			calculated_price &&
																			Number(calculated_price) ===
																				Number(booked_price)
																				? "success"
																				: "muted"
																		}
																		amount={per_quantity_booked_price}
																		currency={currency}
																	/>{" "}
																	* {quantity}
																</Text>
															) : (
																<Text color="warning">N/A</Text>
															)}
														</Box>
													)}
												</Stack>
											)
										)}
									</Inline>
									{is_booked && compactView && !confirmation_details ? (
										<Box title="Missing Confirmation Details">
											<Icons.AttentionSolid color="warning" />
										</Box>
									) : null}
								</Inline>
							</Col>
							{supplier || supplier_contact ? (
								<Col>
									<Grid gap="2">
										<Col xs={12} md={6}>
											{supplier ? (
												<Inline gap="1" flexWrap="wrap">
													<Box fontWeight="semibold">
														<Link
															to={generatePath(
																"/transport-service-providers/:transportServiceProviderId",
																{
																	transportServiceProviderId:
																		supplier.id.toString(),
																}
															)}
														>
															{supplier.name}
														</Link>
													</Box>
													<Box>
														{supplier.contacts?.length
															? joinAttributes(
																	supplier.contacts[0].phone_numbers?.length ? (
																		<PhoneNumber
																			value={supplier.contacts[0].phone_numbers}
																			iconOnly
																		/>
																	) : null,
																	supplier.contacts[0].email ? (
																		<Email
																			value={supplier.contacts[0].email}
																			iconOnly
																		/>
																	) : null
																)
															: null}
													</Box>
												</Inline>
											) : null}
										</Col>
										{supplier_contact ? (
											<Col>
												<Inline gap="2" flexWrap="wrap">
													<Box>
														<Icons.User />
													</Box>
													<Box fontWeight="semibold">
														{supplier_contact.name}
													</Box>
													<Box>
														{supplier_contact.phone_numbers?.length ||
														supplier_contact.email
															? joinAttributes(
																	supplier_contact.phone_numbers?.length ? (
																		<PhoneNumber
																			value={supplier_contact.phone_numbers}
																			iconOnly
																		/>
																	) : null,
																	supplier_contact.email ? (
																		<Email
																			value={supplier_contact.email}
																			iconOnly
																		/>
																	) : null
																)
															: null}
													</Box>
												</Inline>
											</Col>
										) : null}
									</Grid>
								</Col>
							) : null}
						</Grid>
						{!hidePrice ? (
							<Box textAlign={"right"}>
								<BookingAmount booking={activityBooking} />
							</Box>
						) : null}
					</Inline>
					{compactView ? null : confirmation_details ? (
						<Inline>
							<Box
								whiteSpace="preserveAndWrap"
								borderWidth="1"
								paddingY="1"
								paddingX="2"
								rounded="md"
								fontSize="sm"
								bgColor="inset"
							>
								{confirmation_details}
							</Box>
						</Inline>
					) : is_booked ? (
						<Inline>
							<Alert inline status="warning">
								Missing confirmation details
							</Alert>
						</Inline>
					) : null}
				</Stack>
			</Col>
		</Grid>
	)
}

export function BookingAmount({
	booking,
}: {
	booking: IPaymentable & {
		currency: string
		booked_price?: number
	}
}) {
	const {
		currency,
		booked_price,
		payments_amount,
		refunding_payments_amount,
		cancellation_charges,
		paid_payments_amount,
		paid_refunding_payments_amount,
	} = booking
	if (
		!isNumeric(booked_price) &&
		!isNumeric(payments_amount) &&
		!isNumeric(paid_payments_amount) &&
		!isNumeric(refunding_payments_amount)
	) {
		return null
	}
	return (
		<Stack alignItems={"end"}>
			{isNumeric(booked_price) ? (
				<Box fontWeight="semibold">
					<Money amount={booked_price} currency={currency} showCurrency />
				</Box>
			) : null}
			{isNumeric(payments_amount) ? (
				<Box fontWeight="semibold">
					{isNumeric(refunding_payments_amount) &&
					isNumeric(cancellation_charges) ? (
						<Inline alignItems="center" gap="1">
							<Text fontSize="sm">Chargable:</Text>
							<Money amount={cancellation_charges} currency={currency} />
						</Inline>
					) : isNumeric(booked_price) &&
					  Number(payments_amount || 0) ===
							Number(booked_price || 0) ? null : (
						<Inline alignItems="center" gap="1">
							<Text fontSize="sm">Chargable:</Text>
							<Money amount={payments_amount} currency={currency} />
						</Inline>
					)}
				</Box>
			) : null}
			{isNumeric(paid_payments_amount) ? (
				<Inline fontWeight="semibold" alignItems="center" gap="1">
					<Text fontSize="sm">Paid:</Text>
					<Money amount={paid_payments_amount} currency={currency} />
				</Inline>
			) : null}
			{isNumeric(refunding_payments_amount) ? (
				<Inline fontWeight="semibold" alignItems="center" gap="1">
					<Text fontSize="sm">Refund:</Text>
					<Inline alignItems="center" gap="px">
						<Money
							amount={paid_refunding_payments_amount || 0}
							currency={currency}
						/>
						<Text>/</Text>
						<Money amount={refunding_payments_amount} currency={currency} />
					</Inline>
				</Inline>
			) : null}
		</Stack>
	)
}
