import React from "react"
import {
	getUnit,
	format,
	startOf,
	endOf,
	isBefore,
	clone,
	addUnit,
	setUnit,
	isSame,
} from "@sembark-travel/datetime-utils"

import { getDaysOfWeek, VIEWS, DEFAULT_DATE_FORMAT } from "./utils"
import { useDateTimeContext } from "./DateTimeContext"
import {
	dayClassName,
	dayOfWeekClassName,
	nextPrevBtnClassName,
	switchBtnClassName,
	timeToggleBtnClassName,
} from "./datetime.css"

function Header() {
	const { navigateForward, navigateBack, showView, viewDate } =
		useDateTimeContext()
	const date = viewDate
	const dow = React.useMemo(() => getDaysOfWeek(), [])
	return (
		<thead key="th">
			<tr key="h">
				<th
					key="s"
					role="button"
					className={switchBtnClassName}
					onClick={() => showView(VIEWS["MONTHS"])}
					colSpan={5}
					data-value={getUnit(viewDate, "month")}
				>
					<span>{format(date, "MMM") + " " + format(date, "YYYY")}</span>
				</th>
				<th
					key="p"
					className={nextPrevBtnClassName}
					role="button"
					onClick={() => navigateBack(1, "months")}
					data-testid="prev_month"
				>
					<span>‹</span>
				</th>
				<th
					key="n"
					className={nextPrevBtnClassName}
					role="button"
					onClick={() => navigateForward(1, "months")}
					data-testid="next_month"
				>
					<span>›</span>
				</th>
			</tr>
			<tr key="d">
				{dow.map((day, index) => (
					<th
						key={day.full + index}
						title={day.full}
						aria-label={day.full}
						className={dayOfWeekClassName}
					>
						{day.short}
					</th>
				))}
			</tr>
		</thead>
	)
}

function Footer() {
	const { timeFormat, value, viewDate, showView } = useDateTimeContext()
	const date = value || viewDate
	if (!timeFormat || !date) return null
	return (
		<tfoot key="tf">
			<tr>
				<td
					onClick={() => showView(VIEWS["TIME"])}
					colSpan={7}
					className={timeToggleBtnClassName}
				>
					{format(date, timeFormat)}
				</td>
			</tr>
		</tfoot>
	)
}

function Day(props: {
	key: string
	date: Date
	onSelect?: () => void
	past?: boolean
	future?: boolean
	isActive?: boolean
	disabled?: boolean
	isToday?: boolean
	"data-testid"?: string
}) {
	const {
		onSelect,
		date,
		past,
		future,
		isActive,
		disabled,
		isToday,
		...otherProps
	} = props
	const { dateFormat } = useDateTimeContext()
	return (
		<td
			aria-label={format(date, dateFormat || DEFAULT_DATE_FORMAT)}
			role="button"
			onClick={onSelect}
			className={dayClassName}
			data-today={Number(isToday)}
			data-active={Number(isActive)}
			data-old={Number(past)}
			data-new={Number(future)}
			data-disabled={Number(disabled)}
			{...otherProps}
		>
			{getUnit(date, "date")}
		</td>
	)
}

function Days() {
	const { isValidDate, viewDate, onChange, value } = useDateTimeContext()
	const date = viewDate
	const selected = value
	const currentYear = getUnit(date, "year")
	const currentMonth = getUnit(date, "month")

	const weeks: Array<React.ReactNode> = []
	let days: Array<React.ReactNode> = []

	// go to the first day of week in which this month starts
	// e.g. if the month starts on Tuesday, we do the Sunday (prev month)
	let currentDay = startOf(startOf(date, "month"), "week")

	const lastDay = endOf(endOf(date, "month"), "week")
	let testid = 0
	while (isBefore(currentDay, lastDay)) {
		const isDisabled = !isValidDate(currentDay, selected)
		const day = clone(currentDay)
		testid++
		days.push(
			<Day
				key={format(currentDay, "M_D")}
				date={clone(currentDay)}
				data-testid={`day_${testid}`}
				onSelect={
					!isDisabled
						? () => {
								// update the date/month/year in the selected or view Date
								onChange &&
									onChange(
										setUnit(
											setUnit(
												setUnit(
													clone(selected || viewDate),
													"month",
													getUnit(day, "month")
												),
												"year",
												getUnit(day, "year")
											),
											"date",
											getUnit(day, "date")
										)
									)
						  }
						: undefined
				}
				past={Boolean(
					(getUnit(currentDay, "year") === currentYear &&
						getUnit(currentDay, "month") < currentMonth) ||
						getUnit(currentDay, "year") < currentYear
				)}
				future={Boolean(
					(getUnit(currentDay, "year") === currentYear &&
						getUnit(currentDay, "month") > currentMonth) ||
						getUnit(currentDay, "year") > currentYear
				)}
				isActive={Boolean(selected && isSame(currentDay, selected, "day"))}
				isToday={isSame(currentDay, new Date(), "day")}
				disabled={isDisabled}
			/>
		)

		// create a new row at each seven days
		if (days.length === 7) {
			weeks.push(<tr key={format(currentDay, "M_D")}>{days}</tr>)
			days = []
		}
		currentDay = addUnit(currentDay, 1, "d")
	}

	return <React.Fragment>{weeks}</React.Fragment>
}

export default function DaysView() {
	return (
		<div className="tpdt-days">
			<table>
				<Header />
				<tbody>
					<Days />
				</tbody>
				<Footer />
			</table>
		</div>
	)
}
