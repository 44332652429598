import {
	Box,
	Button,
	Inline,
	Icons,
	Heading,
	Input,
	useBreakpoints,
	useSwitch,
	useTimeout,
} from "@sembark-travel/ui/base"
import { Dialog } from "@sembark-travel/ui/dialog"
import {
	Form,
	useFormState,
	useForm,
	FormSpy,
	GetFieldValue,
} from "@sembark-travel/ui/form"
import React, {
	createContext,
	useContext,
	useMemo,
	useRef,
	useState,
	useEffect,
} from "react"
import { Optional } from "utility-types"

export type TSearchParams = {
	q?: string
	page?: number
	cursor?: string | null
	limit?: number
	sort?: string
}

const defaultInitialParams: TSearchParams = {
	q: "",
}

interface ChildrenProps<T extends TSearchParams> {
	searchParams: T
	/**
	 * Set the search params. This will call the onSearch prop
	 * CAUTION: Don't use it to change the page
	 */
	setSearchParams: (params: Partial<T>) => void
	/**
	 * Set the a particular search param
	 * CAUTION: Don't use it to change the page
	 */
	setSearchParamValue: <K extends keyof T>(param: K, value: T[K]) => void
}

export interface SearchProps<T extends TSearchParams = TSearchParams> {
	initialParams?: T
	onSearch: (params: T) => void
	placeholder?: string
	// any more filters
	Filters?: React.ComponentType
	// rendered search results
	children?: React.ReactNode | ((props: ChildrenProps<T>) => React.ReactNode)
	// reset params
	resetParams?: T | ((params: T) => T)
	title?: string
	actions?: React.ReactNode | ((props: ChildrenProps<T>) => React.ReactNode)
	/**
	 * Wether or not to show the advance filters on mount
	 * By default, if filters has any value except q, page and limit, advance filters will be visible
	 */
	areAdvancedFiltersApplied?: (
		params: Optional<Omit<T, "q" | "limit" | "page" | "sort" | "cursor">>
	) => boolean

	/**
	 * Wether or not autoFocus the search
	 */
	autoFocus?: boolean
}

export function useSearch<T extends TSearchParams = TSearchParams>(
	initialValues: T | (() => T) = {} as T
) {
	return useState<T>(initialValues)
}

type TSearchContext = null | { areFilterVisible: boolean }

const SearchContext = createContext<TSearchContext>(null)

export function useSearchContext() {
	return useContext(SearchContext)
}

export function areAdvancedFiltersAppliedDefault<T extends TSearchParams>(
	filters: Optional<T>
) {
	const nonEmptyFilterKeys = Object.keys(filters).filter((key: string) => {
		const value = filters[key as keyof T]
		if (value === null || value === undefined) {
			return false
		}
		if (typeof value === "string" && value.trim() === "") {
			return false
		}
		if (Array.isArray(value) && value.length === 0) {
			return false
		}
		return true
	})
	return nonEmptyFilterKeys.length > 0
}

export function Search<T extends TSearchParams = TSearchParams>({
	initialParams = defaultInitialParams as T,
	onSearch,
	placeholder = "Search...",
	children = null,
	Filters,
	resetParams,
	title,
	actions,
	autoFocus,
	areAdvancedFiltersApplied = areAdvancedFiltersAppliedDefault,
}: SearchProps<T>) {
	const { xs, sm } = useBreakpoints()
	const { q, limit, page, sort, cursor, ...otherParams } = initialParams
	const areAdvancedFiltersAppliedOnMount = useRef<boolean>(
		areAdvancedFiltersApplied(otherParams)
	)
	const initialParamsRef = useRef(initialParams)
	const [
		areFilterVisible,
		{ off: hideFilters, toggle: toggleFiltersVisibility },
	] = useSwitch(areAdvancedFiltersAppliedOnMount.current)
	const context = useMemo(() => {
		return {
			areFilterVisible,
		}
	}, [areFilterVisible])
	return (
		<SearchContext.Provider value={context}>
			<Form<T>
				initialValues={initialParamsRef.current}
				onSubmit={onSearch}
				subscription={{}}
				keepDirtyOnReinitialize={false}
			>
				{(props) => {
					return (
						<Box position="relative">
							<Box
								borderBottomWidth="1"
								display={{ md: "flex" }}
								justifyContent="between"
								alignItems="center"
								paddingX={{ xs: "4", xl: "8" }}
								paddingY="3"
							>
								{title ? (
									<Box
										width={{ md: "1/3" }}
										marginBottom={{ xs: "3", md: "0" }}
									>
										<Heading as="h2" fontSize="lg">
											{title}
										</Heading>
									</Box>
								) : null}
								<Inline
									flex="1"
									gap="4"
									collapseBelow="sm"
									alignItems={{ sm: "center" }}
									justifyContent={{ sm: "end" }}
								>
									<Box flex="1" minWidth="0">
										<form
											onSubmit={props.handleSubmit}
											noValidate
											role="search"
											aria-label="Primary"
											name="search-form"
										>
											<FormSpy<T> subscription={{ values: true }}>
												{({ values }) => {
													const {
														q,
														limit,
														page,
														sort,
														cursor,
														...otherParams
													} = values
													const advancedFiltersApplied =
														areAdvancedFiltersApplied(otherParams)
													return (
														<Box
															position="relative"
															width="full"
															maxWidth={{ md: "md" }}
															marginLeft="auto"
														>
															<Inline
																position="absolute"
																top="0"
																left="0"
																height="full"
																alignItems="center"
																width="10"
																justifyContent="center"
															>
																<Icons.Search color="muted" />
															</Inline>
															<GetFieldValue<string | undefined> name="q">
																{({ value, onChange }) => (
																	<Input
																		type="search"
																		placeholder={placeholder}
																		autoFocus={autoFocus}
																		paddingLeft="10"
																		style={{
																			paddingRight:
																				!advancedFiltersApplied &&
																				!value &&
																				!Filters
																					? "16px"
																					: `${
																							(Filters ? 40 : 0) +
																							(advancedFiltersApplied || q
																								? 40
																								: 0)
																					  }px`,
																		}}
																		value={value || ""}
																		onChange={(e) =>
																			onChange(e.currentTarget.value || "")
																		}
																	/>
																)}
															</GetFieldValue>
															{advancedFiltersApplied || q ? (
																<Inline
																	as="button"
																	type="reset"
																	title="Reset Filters"
																	position="absolute"
																	top="0"
																	height="full"
																	alignItems="center"
																	justifyContent="center"
																	borderWidth="1"
																	borderColor="transparent"
																	bgColor="transparent"
																	style={{
																		right: Filters ? "40px" : 0,
																		width: "40px",
																	}}
																	onClick={() => {
																		if (typeof resetParams === "function") {
																			resetParams = resetParams(values)
																		}
																		props.form.reset(resetParams || ({} as T))
																	}}
																>
																	<Icons.Cancel />
																</Inline>
															) : null}
															{Filters ? (
																<Inline
																	as="button"
																	type="button"
																	title="Advanced Filters"
																	position="absolute"
																	top="0"
																	right="0"
																	style={{
																		right: "0px",
																		width: "40px",
																	}}
																	height="full"
																	alignItems="center"
																	justifyContent="center"
																	borderWidth="1"
																	borderColor="transparent"
																	bgColor="transparent"
																	onClick={() => {
																		toggleFiltersVisibility()
																	}}
																	gap="px"
																>
																	<Icons.Sliders />
																	{advancedFiltersApplied ? (
																		<Box
																			color="warning"
																			title="Advanced Filters Applied"
																		>
																			<Icons.InfoSolid size="3" />
																		</Box>
																	) : null}
																</Inline>
															) : null}
														</Box>
													)
												}}
											</FormSpy>
										</form>
									</Box>
									{actions ? (
										<Box>
											{typeof actions === "function" ? (
												<FormSpy<T> subscription={{ values: true }}>
													{(props) => (
														<>
															{actions({
																searchParams: props.values,
																setSearchParams: (params) => {
																	props.form.batch(() => {
																		Object.keys(params).forEach((key) =>
																			props.form.change(
																				key as never as keyof T,
																				params[key as never as keyof T]
																			)
																		)
																	})
																},
																setSearchParamValue: props.form.change,
															})}
														</>
													)}
												</FormSpy>
											) : (
												actions
											)}
										</Box>
									) : null}
								</Inline>
							</Box>
							<Box display="flex">
								<Box flex="1" minWidth="0">
									{typeof children === "function" ? (
										<FormSpy<T> subscription={{ values: true }}>
											{(props) => (
												<>
													{children({
														searchParams: props.values,
														setSearchParams: (params) => {
															props.form.batch(() => {
																Object.keys(params).forEach((key) =>
																	props.form.change(
																		key as never as keyof T,
																		params[key as never as keyof T]
																	)
																)
															})
														},
														setSearchParamValue: props.form.change,
													})}
												</>
											)}
										</FormSpy>
									) : (
										children
									)}
								</Box>
								{Filters ? (
									xs || sm ? (
										<Dialog open={areFilterVisible} onClose={hideFilters} sm>
											<form
												noValidate
												onSubmit={props.handleSubmit}
												role="search"
												aria-label="Primary"
												name="search-form"
											>
												<Dialog.Header closeButton>
													<Dialog.Title>Advanced Filters</Dialog.Title>
												</Dialog.Header>
												<Dialog.Body>
													<Box
														style={{
															width: "320px",
														}}
													>
														<Filters />
													</Box>
												</Dialog.Body>
												<Dialog.Footer>
													<Inline gap="4">
														<Button type="submit" onClick={hideFilters}>
															<Icons.Ok /> Apply Filters
														</Button>
														<FormSpy<T> subscription={{ values: true }}>
															{({ values, form }) => (
																<Button
																	type="button"
																	level="tertiary"
																	onClick={() => {
																		if (typeof resetParams === "function") {
																			resetParams = resetParams(values)
																		}
																		form.reset(resetParams || ({} as T))
																	}}
																>
																	<Icons.Cancel /> Reset Filters
																</Button>
															)}
														</FormSpy>
													</Inline>
												</Dialog.Footer>
											</form>
										</Dialog>
									) : areFilterVisible ? (
										<>
											<Box
												borderLeftWidth="1"
												style={{
													width: "300px",
												}}
											>
												<form
													onSubmit={props.handleSubmit}
													noValidate
													role="search"
													aria-label="Primary"
													name="search-form"
												>
													<Inline
														justifyContent="between"
														alignItems="center"
														paddingX="4"
														paddingY="2"
													>
														<Heading
															as="h5"
															fontSize="base"
															fontWeight="normal"
														>
															Advanced Filters
														</Heading>
														<Button
															level="tertiary"
															onClick={hideFilters}
															size="sm"
															title="Hide Advanced Filters"
														>
															<Icons.ChevronDown rotate="270" />
														</Button>
													</Inline>
													<Box
														padding="4"
														borderTopWidth="1"
														borderBottomWidth="1"
													>
														<Filters />
													</Box>
													<Inline gap="4" padding="4">
														<Button
															type="submit"
															level="primary"
															onClick={hideFilters}
														>
															Apply Filters
														</Button>
														<FormSpy<T> subscription={{ values: true }}>
															{({ values, form }) => (
																<Button
																	type="button"
																	level="tertiary"
																	onClick={() => {
																		if (typeof resetParams === "function") {
																			resetParams = resetParams(values)
																		}
																		form.reset(resetParams || ({} as T))
																	}}
																>
																	Reset Filters
																</Button>
															)}
														</FormSpy>
													</Inline>
												</form>
											</Box>
										</>
									) : null
								) : null}
								<SearchOnChange<T> onChange={onSearch} />
							</Box>
						</Box>
					)
				}}
			</Form>
		</SearchContext.Provider>
	)
}

function SearchOnChange<T extends object>({
	onChange,
}: {
	onChange: (params: T) => void
}) {
	const { values } = useFormState<T>({
		subscription: { values: true },
	})
	const previousValuesRef = useRef<T | null>(values)
	const form = useForm<T>()
	const { set: setSubmitterTimeout, clear: clearSubmitterTimeout } =
		useTimeout()

	const onChangeRef = useRef(onChange)
	onChangeRef.current = onChange

	// fetch prices
	useEffect(() => {
		const previousValues = { ...previousValuesRef.current }
		setSubmitterTimeout(() => {
			previousValuesRef.current = values
			const previousKeys = Object.keys(previousValues || ({} as T))
			const newKeys = Object.keys(values || ({} as T))
			if (
				previousKeys.length !== newKeys.length ||
				previousKeys.some(
					(k) => !Object.is(previousValues[k as never], values[k as never])
				)
			) {
				form.submit()
			}
		}, 1000)
		return () => clearSubmitterTimeout()
	}, [values, form, setSubmitterTimeout, clearSubmitterTimeout])
	return null
}
