import type { ComponentProps } from "react"
import { lazy, Suspense } from "react"
import { markdownClassName } from "./markdown.css"
import { Pulse } from "@sembark-travel/ui/base"
import { ErrorBoundary } from "@sembark-travel/ui/error-boundary"

const ReactMarkdown = lazy(() => import("react-markdown"))

export function Markdown({
	children,
	className,
	...props
}: ComponentProps<typeof ReactMarkdown>) {
	return (
		<ErrorBoundary>
			<Suspense
				fallback={
					<Pulse className={markdownClassName} whiteSpace="preserveAndWrap">
						{children}
					</Pulse>
				}
			>
				<ReactMarkdown
					className={`${markdownClassName} ${className || ""}`}
					{...props}
				>
					{children}
				</ReactMarkdown>
			</Suspense>
		</ErrorBoundary>
	)
}
