import {
	Button,
	Component,
	Inline,
	Table,
	Stack,
	Divider,
	RelativeTime,
	Box,
	Text,
	Icons,
} from "@sembark-travel/ui/base"
import { Dialog } from "@sembark-travel/ui/dialog"
import {
	CurrencyPairInputField,
	Form,
	GetFieldValue,
	SubmissionError,
	TextInputField,
	withServerErrors,
} from "@sembark-travel/ui/form"
import {
	ListView,
	Search,
	TSearchParams,
	useSearch,
} from "@sembark-travel/ui/list"
import { useXHR } from "@sembark-travel/xhr"
import { SelectTenantCurrencyInputField } from "./List"
import { showSnackbar } from "@sembark-travel/ui/snackbar"
import { useState } from "react"
import { TCurrencyExchangeRate, currenciesExchangeRatesXHR } from "./store"
import useSWR from "swr"

export function CurrencyExchangeRatesList() {
	const [params, setParams] = useSearch<TSearchParams>()
	const xhr = useXHR()
	return (
		<Search
			initialParams={params}
			onSearch={(params) => setParams({ ...params, page: 1 })}
			title="Exchange Rates"
			actions={() => (
				<Inline>
					<Component initialState={false}>
						{({ state, setState }) => (
							<>
								<Button status="primary" onClick={() => setState(true)}>
									Add Rates
								</Button>
								<Dialog
									open={state}
									onClose={() => setState(false)}
									title="Set/Update Exchange Rates"
									sm
								>
									<Dialog.Body>
										<AddCurrencyExchangeRateForm
											onCancel={() => setState(false)}
											onSubmit={async (values) => {
												await currenciesExchangeRatesXHR(xhr).store(values)
												showSnackbar("Exchagne rates saved successfully.")
												setState(false)
											}}
										/>
									</Dialog.Body>
								</Dialog>
							</>
						)}
					</Component>
				</Inline>
			)}
		>
			{() => (
				<ListView<TCurrencyExchangeRate>
					params={params}
					onPageChange={(page) => setParams({ ...params, page })}
					pageKey="currrency-exchange-rates"
					fetch={(xhr, params) =>
						currenciesExchangeRatesXHR(xhr).get({
							...params,
							include: "created_by",
						})
					}
				>
					{({ items, refresh }) => (
						<Table
							responsive
							bordered
							hover
							headers={["From", "To", "Rate", "Last Updated", ""]}
							alignCols={{ 4: "right" }}
							rows={items.map((pair) => [
								pair.base_currency,
								pair.counter_currency,
								pair.rate,
								<Stack>
									<Box>
										<RelativeTime timestamp={pair.created_at} />
									</Box>
									<Text color="muted">by {pair.created_by?.name || "You"}</Text>
								</Stack>,
								<Inline gap="4" justifyContent="end">
									<Component initialState={false}>
										{({ state, setState }) => (
											<>
												<Button size="sm" onClick={() => setState(true)}>
													<Icons.Pencil />
												</Button>
												<Dialog
													open={state}
													onClose={() => setState(false)}
													title="Update Exchange Rates"
													sm
												>
													<Dialog.Body>
														<AddCurrencyExchangeRateForm
															initialValues={{
																base_currency: pair.base_currency,
																counter_currency: pair.counter_currency,
																rate: pair.rate,
															}}
															onCancel={() => setState(false)}
															onSubmit={async (values) => {
																await currenciesExchangeRatesXHR(xhr).store(
																	values
																)
																showSnackbar(
																	"Exchagne rates saved successfully."
																)
																setState(false)
																refresh()
															}}
														/>
													</Dialog.Body>
												</Dialog>
											</>
										)}
									</Component>
									<Button
										level="tertiary"
										size="sm"
										status="warning"
										onClick={async () => {
											if (
												window.confirm(
													"Are you sure you want to delete this exchange rate ?"
												)
											) {
												await currenciesExchangeRatesXHR(xhr).destroy(pair.id)
												refresh()
												showSnackbar("Exchange rate deleted successfully.")
											}
										}}
									>
										<Icons.Trash />
									</Button>
								</Inline>,
							])}
						/>
					)}
				</ListView>
			)}
		</Search>
	)
}

type TCurrencyExchangeRateFormData = {
	base_currency: string
	counter_currency: string
	rate: "" | number
}

function AddCurrencyExchangeRateForm({
	initialValues: propInitialValues,
	onSubmit,
	onCancel,
}: {
	initialValues?: Partial<TCurrencyExchangeRateFormData>
	onSubmit: (payload: TCurrencyExchangeRateFormData) => Promise<void>
	onCancel: () => void
}) {
	const [initialValues] = useState<TCurrencyExchangeRateFormData>(() => ({
		base_currency: "",
		counter_currency: "",
		rate: "",
		...propInitialValues,
	}))
	return (
		<Form
			initialValues={initialValues}
			onSubmit={withServerErrors(async (values) => {
				await onSubmit(values)
			})}
			subscription={{ submitting: true }}
		>
			{({ submitting, handleSubmit }) => (
				<form onSubmit={handleSubmit} noValidate>
					<Stack gap="4">
						<Inline gap="4">
							<SelectTenantCurrencyInputField
								name="base_currency"
								label="From/Base Currency"
								clearable
								help="The currency in which you have prices/costing available"
							/>
							<SelectTenantCurrencyInputField
								name="counter_currency"
								label="To/Counter Currency"
								clearable
								help="The currency into which you want to convert from the base Currency"
							/>
						</Inline>
						<TextInputField
							name="rate"
							label="Rate"
							type="number"
							placeholder="0.044140112"
							maxWidth="xs"
							help={({ value: rate }) => (
								<GetFieldValue<string> name="base_currency">
									{({ value: base_currency }) => (
										<GetFieldValue<string> name="counter_currency">
											{({ value: counter_currency }) =>
												base_currency && counter_currency ? (
													<Text>
														1 {base_currency} = {!rate ? "?" : Number(rate)}{" "}
														{counter_currency} (
														<Text
															as="a"
															rel="noopener noreferrer"
															color="accent"
															href={`https://www.xe.com/currencyconverter/convert/?Amount=1&From=${base_currency}&To=${counter_currency}`}
															target="_blank"
														>
															xe.com <Icons.ArrowTopRightOnSquare />
														</Text>
														)
													</Text>
												) : null
											}
										</GetFieldValue>
									)}
								</GetFieldValue>
							)}
						/>
						<Divider marginY="0" />
						<SubmissionError />
						<Inline gap="4">
							<Button type="submit" disabled={submitting}>
								{submitting ? "Saving..." : "Save Rates"}
							</Button>
							<Button disabled={submitting} onClick={() => onCancel()}>
								Cancel
							</Button>
						</Inline>
					</Stack>
				</form>
			)}
		</Form>
	)
}

export function CurrencyPairInputFieldWithRates({
	baseCurrency,
	counterCurrency,
	...props
}: React.ComponentProps<typeof CurrencyPairInputField>) {
	const xhr = useXHR()
	const { data } = useSWR(
		`/currency-pair-exchange-rate/${baseCurrency}/${counterCurrency}`,
		() =>
			currenciesExchangeRatesXHR(xhr).getRatesForPairs([
				[baseCurrency, counterCurrency],
			])
	)
	const defaultValue = data
		? data?.[baseCurrency]?.[counterCurrency]
		: undefined
	return (
		<CurrencyPairInputField
			baseCurrency={baseCurrency}
			counterCurrency={counterCurrency}
			{...props}
			defaultValue={defaultValue}
		/>
	)
}
