import {
	Spinner,
	Table,
	Stack,
	Text,
	Heading,
	Container,
	Button,
	Component,
	Inline,
	joinAttributes,
	Divider,
	Icons,
} from "@sembark-travel/ui/base"
import { ListView, Search, useSearch } from "@sembark-travel/ui/list"
import { Breadcrumbs, useLocationQuery } from "@sembark-travel/ui/router"
import { useXHR } from "@sembark-travel/xhr"
import pluralize from "pluralize"
import { useEffect, useState } from "react"
import useSWR from "swr"
import { generatePath } from "../../router-utils"
import { Dialog } from "@sembark-travel/ui/dialog"
import {
	Form,
	GetFieldValue,
	SelectField,
	SubmissionError,
	TextInputField,
	withServerErrors,
} from "@sembark-travel/ui/form"
import { Optional } from "utility-types"
import {
	worldXHR,
	TWorldBank,
	TWorldBankBranch,
	TWorldCountry,
	TWorldState,
	TWorldCity,
} from "../../World"
import {
	SelectWorldCities,
	SelectWorldCountries,
	SelectWorldStates,
} from "../../World/List"

export function BankDetails({ bankId }: { bankId: string | number }) {
	const xhr = useXHR()
	const { data } = useSWR(`/admin/banks/${bankId}`, () =>
		worldXHR(xhr).getBank<TWorldBank & { branches_count: number }>(bankId, {
			include: "branches_count",
		})
	)
	if (!data) {
		return <Spinner alignCenter padding="4" />
	}
	const { id, name, code, branches_count, country } = data
	return (
		<>
			<Breadcrumbs
				title={`${name}`}
				items={[
					[generatePath("/admin/world/banks"), "Banks"],
					["", code],
				]}
			/>
			<Stack gap="4">
				<Container paddingY="4" bgColor="default">
					<Stack gap="1">
						<Heading>
							{name} ({code})
						</Heading>
						<Text>
							{joinAttributes(pluralize("Branches", branches_count, true))}
						</Text>
					</Stack>
				</Container>
				<BankBankBranchesList bankId={id} bankCountry={country} />
			</Stack>
		</>
	)
}

function BankBankBranchesList({
	bankId,
	bankCountry,
}: {
	bankId: string | number
	bankCountry?: TWorldCountry
}) {
	const [query, setQuery] = useLocationQuery()
	const [searchParams, setSearchParams] = useSearch(query)
	useEffect(() => {
		setQuery(searchParams)
	}, [searchParams, setQuery])
	return (
		<Search
			title="Bank Branches"
			initialParams={searchParams}
			onSearch={(params) => setSearchParams(params)}
			actions={() => (
				<Component initialState={false}>
					{({ state, setState }) => (
						<>
							<Button onClick={() => setState(true)}>Add Branch</Button>
							<Dialog
								open={state}
								onClose={() => setState(false)}
								sm
								title="Add New Branch"
							>
								<Dialog.Body>
									<AddNewBankBranch
										bankId={bankId}
										bankCountry={bankCountry}
										onCancel={() => setState(false)}
										onSuccess={() => setState(false)}
									/>
								</Dialog.Body>
							</Dialog>
						</>
					)}
				</Component>
			)}
		>
			{() => (
				<ListView<TWorldBankBranch>
					pageKey={`admin/world/banks/${bankId}/branches`}
					params={searchParams}
					onPageChange={(page) => setSearchParams({ ...searchParams, page })}
					fetch={(xhr, params) =>
						worldXHR(xhr).getBankBranches({
							...params,
							bank_id: bankId,
						})
					}
				>
					{({ items, refresh }) => (
						<Table
							headers={["Name", "SWIFT", "IFSC", "MICR", "City", "State", ""]}
							responsive
							hover
							bordered
							alignCols={{ 6: "right" }}
							rows={items.map((item) => [
								<Stack gap="1">
									<Text>{item.name}</Text>
									<Text fontSize="sm" color="muted" maxWidth="xs">
										{item.address}
									</Text>
								</Stack>,
								item.swift_code,
								item.ifsc_code,
								item.micr_code,
								item.city_name,
								item.state_name,
								<Component initialState={false}>
									{({ state, setState }) => (
										<>
											<Button
												title="Edit Branch Details"
												onClick={() => setState(true)}
												size="sm"
												level="tertiary"
											>
												<Icons.Pencil />
											</Button>
											<Dialog
												open={state}
												onClose={() => setState(false)}
												sm
												title={`Edit Branch "${item.name}"`}
											>
												<Dialog.Body>
													<EditBankBranch
														branch={item}
														bankCountry={bankCountry}
														onCancel={() => setState(false)}
														onSuccess={() => {
															refresh()
															setState(false)
														}}
													/>
												</Dialog.Body>
											</Dialog>
										</>
									)}
								</Component>,
							])}
						/>
					)}
				</ListView>
			)}
		</Search>
	)
}

function AddNewBankBranch(props: Omit<NewBankBranchFormProps, "onSubmit">) {
	const xhr = useXHR()
	return (
		<NewBrankBranchForm
			onSubmit={(payload) => worldXHR(xhr).storeBankBranch(payload)}
			{...props}
		/>
	)
}

export function EditBankBranch({
	branch,
	...props
}: Omit<NewBankBranchFormProps, "onSubmit" | "bankId"> & {
	branch: TWorldBankBranch
}) {
	const xhr = useXHR()
	return (
		<NewBrankBranchForm
			bankId={branch.bank_id}
			initialValues={{
				name: branch.name,
				ifsc_code: branch.ifsc_code,
				swift_code: branch.swift_code,
				micr_code: branch.micr_code,
				country: branch.country || props.bankCountry,
				state: branch.state,
				city: branch.city,
				centre: branch.centre || "",
				address: branch.address || "",
			}}
			onSubmit={(payload) => worldXHR(xhr).updateBankBranch(branch.id, payload)}
			{...props}
		/>
	)
}
type NewBankBranchData = {
	name: string
	bank_id: number | string
	country?: TWorldCountry
	state?: TWorldState
	city?: TWorldCity
	swift_code: string
	ifsc_code: string
	micr_code: string
	centre: string
	address: string
}

type NewBankBranchFormProps = {
	bankId: string | number
	bankCountry?: TWorldCountry
	onSubmit: (payload: object) => Promise<unknown>
	onSuccess?: () => void
	onCancel: () => void
	initialValues?: Optional<NewBankBranchData>
}

function NewBrankBranchForm({
	bankId,
	bankCountry,
	onSubmit,
	onCancel,
	onSuccess,
	initialValues: propInitialValues,
}: NewBankBranchFormProps) {
	const [initialValues] = useState<NewBankBranchData>(() => ({
		name: "",
		bank_id: bankId,
		swift_code: "",
		ifsc_code: "",
		micr_code: "",
		address: "",
		centre: "",
		country: bankCountry,
		...propInitialValues,
	}))
	return (
		<Form<NewBankBranchData>
			initialValues={initialValues}
			onSubmit={withServerErrors(
				async ({ country, state, city, ...values }) => {
					await onSubmit({
						country: country?.id,
						state: state?.id,
						city: city?.id,
						...values,
					})
					onSuccess?.()
				}
			)}
			subscription={{ submitting: true }}
		>
			{({ submitting, handleSubmit }) => (
				<form onSubmit={handleSubmit} noValidate>
					<Stack gap="4">
						<Stack gap="4">
							<Stack gap="px">
								<Heading fontSize="md">Identity Details</Heading>
								<Text color="muted">
									Please provide the identification codes of the branch
								</Text>
							</Stack>
							<Inline gap="4" collapseBelow="sm">
								<TextInputField
									name="swift_code"
									type="text"
									label="BIC/SWIFT Code"
									required
									placeholder="e.g. HDFCINBBXXX"
								/>
								<TextInputField
									name="micr_code"
									type="number"
									label="MICR Code"
									placeholder="e.g. 282240006"
								/>
								<TextInputField
									name="ifsc_code"
									type="text"
									label="IFSC"
									secondaryLabel="optional"
									placeholder="e.g. HDFC0001934"
								/>
							</Inline>
						</Stack>
						<Divider marginY="0" />
						<Stack gap="px">
							<Heading fontSize="md">Location Details</Heading>
							<Text color="muted">
								Please provide the city/state/country details of the Branch
							</Text>
						</Stack>
						<Inline gap="4" collapseBelow="sm">
							<SelectField
								select={SelectWorldCountries}
								name="country"
								label="Country"
							/>
							<GetFieldValue<TWorldCountry | undefined> name="country">
								{({ value: country }) =>
									country ? (
										<SelectField
											countryId={country.id}
											select={SelectWorldStates}
											name="state"
											label="State"
										/>
									) : null
								}
							</GetFieldValue>
							<GetFieldValue<TWorldState | undefined> name="state">
								{({ value: state }) =>
									state ? (
										<SelectField
											stateId={state.id}
											select={SelectWorldCities}
											name="city"
											label="City / District"
										/>
									) : null
								}
							</GetFieldValue>
						</Inline>
						<Inline collapseBelow="sm" gap="4">
							<TextInputField
								name="name"
								type="text"
								label="Branch Name"
								placeholder="e.g. Vaishali Nagar"
							/>
							<TextInputField
								name="centre"
								type="text"
								label="Branch Centre"
								placeholder="e.g. Jaipur"
							/>
						</Inline>
						<TextInputField
							name="address"
							type="text"
							label="Address"
							placeholder="e.g. Ground Floor, Shop No.1, Amarpali Circle"
						/>
						<Divider marginY="0" />
						<SubmissionError />
						<Inline gap="4">
							<Button type="submit" disabled={submitting}>
								{submitting ? "Please wait.." : "Save Details"}
							</Button>
							<Button disabled={submitting} onClick={() => onCancel()}>
								Cancel
							</Button>
						</Inline>
					</Stack>
				</form>
			)}
		</Form>
	)
}
